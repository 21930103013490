import React from 'react';

import Divider from '@material-ui/core/Divider';

import { AimTypography, theme, styled } from '@aim/components';

import { DateRangesList } from './DateRangesList';

const GeneralInfoRow = styled('div')({
  display: 'flex',
});

const QuoteContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: 20,
  backgroundColor: theme.colors.greyScale.backgroundGrey,
});

const DateRangeContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginTop: 10,
});

const ProfileFeeCard = ({
  i18n,
  profile,
  isReadOnly,
  control,
  copyFromRowChildren,
  fieldArrayPrefix,
}) => {
  return (
    <>
      <Divider />
      <GeneralInfoRow>
        <div style={{ display: 'flex', width: '50%' }}>
          <AimTypography variant={'textBold'}>
            {i18n.page.info.cluster.label}:
          </AimTypography>
          <AimTypography variant={'text'}>{profile?.cluster}</AimTypography>
          <AimTypography variant={'textBold'}>
            {i18n.page.info.name.label}:
          </AimTypography>
          <AimTypography variant={'text'}>{profile?.name}</AimTypography>
        </div>
        <div style={{ display: 'flex' }}>
          <AimTypography variant={'textBold'}>
            {i18n.page.info.category.label}:
          </AimTypography>
          <AimTypography variant={'text'}>
            {profile?.category?.name}
          </AimTypography>
        </div>
      </GeneralInfoRow>
      <QuoteContainer>
        {copyFromRowChildren}
        <Divider />
        <DateRangeContainer>
          {!isReadOnly && (
            <AimTypography variant={'text'}>
              {i18n.page.info.participantQuoteInfo.label}
            </AimTypography>
          )}
          <DateRangesList
            {...{
              control,
              i18n,
              isReadOnly,
              profile,
              fieldArrayPrefix,
            }}
          />
        </DateRangeContainer>
      </QuoteContainer>
    </>
  );
};

export default ProfileFeeCard;
