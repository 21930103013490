import React from 'react';
import { CustomIntl, BillingInformations } from '@aim/components';
import { useHistory, useParams } from 'react-router-dom';
import { constants } from '@aim/common';
import { useIntl } from 'react-intl';
import translation from './sponsorbillinginformations/translation';
import { isMobile } from 'react-device-detect';

const SponsorBillingInformations = () => {
  const { sponsorId } = useParams();
  const history = useHistory();
  const intl = CustomIntl(useIntl());
  const i18n = translation.billingInformations(intl);

  return (
    <div
      style={{
        flex: 1,
        minWidth: 'calc(100vw - 480px)',
        margin: isMobile ? '0px 20px' : '0px 180px',
      }}
    >
      <BillingInformations
        type={constants.Clusters.Sponsor.id}
        customerId={sponsorId}
        history={history}
        intl={intl}
        pageTitle={i18n.pageTitle}
        showSubTitle
        showSnackbar
      />
    </div>
  );
};

export default SponsorBillingInformations;
