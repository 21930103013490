import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { format } from 'date-fns';
import { useHistory, useParams } from 'react-router-dom';
import useI18n from './reviewersAbstracts/i18n';
import { isMobile } from 'react-device-detect';
import Tooltip from '@material-ui/core/Tooltip';
import Visibility from '@material-ui/icons/Visibility';
import ArrowBack from '@material-ui/icons/ArrowBack';

import { appState, constants } from '@aim/common';
import {
  styled,
  AimIconButton,
  AimTableRow,
  AimTableCell,
  AimTable,
  AimCard,
  AimTitleAndButtons,
  AimIconAndTextButton,
  CustomIntl,
  theme,
  AimCheckbox,
} from '@aim/components';

import { getReviewerReviewsList } from './shared/participationGqlHelper';

const StyledCard = styled(AimCard)({
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  height: 160,
  textAlign: 'center',
  backgroundColor: theme.colors.greyScale.backgroundGrey,
});

const CardTitle = styled('div')({
  fontWeight: 'bold',
  textAlign: 'center',
  padding: '20px 10px',
});

const CardValue = styled('div')({
  fontSize: 30,
  fontWeight: 'lighter',
});

const CardLink = styled('div')({
  marginTop: 15,
  color: theme.colors.secondary.lightBlue,
  fontWeight: 'bold',
  cursor: 'pointer',
});

const StyleTable = styled(AimTable)({
  '& .MuiTableCell-stickyHeader': {
    backgroundColor: `${theme.colors.greyScale.white} !important`,
  },
});

export default function ReviewersAbstracts() {
  const [abstractService, setAbstractService] = useState([]);
  const [abstractDocuments, setAbstractDocuments] = useState([]);
  const [processingReviewsCount, setProcessingReviewsCount] = useState(0);
  const [evaluatedReviewsCount, setEvaluatedReviewsCount] = useState(0);
  const [declinedReviewsCount, setDeclinedReviewsCount] = useState(0);
  const [reviewerCategories, setReviewerCategories] = useState(0);
  const [showProcessingOnly, setShowProcessingOnly] = useState(false);

  const getFilteredAbstracts = () => {
    return showProcessingOnly
      ? abstractDocuments.filter((doc) => doc.status === 'processing')
      : abstractDocuments;
  };

  const intl = CustomIntl(useIntl());
  const i18n = useI18n(intl);
  const history = useHistory();
  const { eventId } = useParams();

  const initValues = async () => {
    try {
      const participationId = appState.user.getValue()?.userAndParticipation
        ?.participation?.id;
      const abstractsData = await getReviewerReviewsList(participationId);

      setAbstractService(abstractsData);

      const reviewer = abstractsData?.event.abstractService.reviewers?.items.find(
        (r) => r.participant.id === participationId
      );

      const reviews = reviewer?.reviews.items;
      if (reviews) {
        const validReviews = reviews.filter(
          (review) => review.abstractDocument?.isReadyToReview
        );
        setReviewerCategories(reviewer.reviewerCategories.items.length);
        setProcessingReviewsCount(
          validReviews.filter(
            (r) => !r.scores && r.status !== constants.reviewStatus.REJECTED
          ).length
        );
        setEvaluatedReviewsCount(
          validReviews.filter(
            (r) => r.scores && r.status !== constants.reviewStatus.REJECTED
          ).length
        );
        setDeclinedReviewsCount(
          reviews.filter((r) => r.status === constants.reviewStatus.REJECTED)
            .length
        );
        setAbstractDocuments(validReviews);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    initValues();
    appState.selectedMenuItem.next('congress');
  }, []);

  // Columns
  const headCells = [
    {
      id: 'title',
      numeric: false,
      disablePadding: false,
      label: i18n.table.columnsTitles.title,
    },
    {
      id: 'presentationType',
      numeric: false,
      disablePadding: false,
      label: i18n.table.columnsTitles.presentationType,
    },
    {
      id: 'category',
      numeric: false,
      disablePadding: false,
      label: i18n.table.columnsTitles.category,
    },
    {
      id: 'subCategory',
      numeric: false,
      disablePadding: false,
      label: i18n.table.columnsTitles.subCategory,
    },
    // {
    //   id: 'preferred',
    //   numeric: false,
    //   disablePadding: false,
    //   label: i18n.table.columnsTitles.preferred,
    // },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: i18n.table.columnsTitles.status,
    },
    {
      id: 'finalScore',
      numeric: false,
      disablePadding: false,
      label: i18n.table.columnsTitles.finalScore,
    },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: '',
    },
  ];

  const Row = ({ row }) => {
    return (
      <>
        <AimTableRow
          hover
          role="checkbox"
          tabIndex={-1}
          style={{ backgroundColor: theme.colors.greyScale.backgroundGrey }}
        >
          <AimTableCell>{row.abstractDocument.title}</AimTableCell>
          <AimTableCell>
            {row.abstractDocument.proposedPresentationTypology?.name || '-'}
          </AimTableCell>
          <AimTableCell>
            {row.abstractDocument.category?.name || '-'}
          </AimTableCell>
          <AimTableCell>
            {row.abstractDocument.subCategory?.name || '-'}
          </AimTableCell>
          {/* <AimTableCell>
            {row.abstractDocument.preferredExposure || '-'}
          </AimTableCell> */}

          <AimTableCell>
            {row.status || i18n.table.reviewStatus.toBeprocessed}
          </AimTableCell>
          <AimTableCell>{row.scores || '-'}</AimTableCell>
          <AimTableCell>
            <Tooltip title={i18n.table.viewRowButton}>
              <AimIconButton
                variant="lightBlueFill"
                onClick={() =>
                  history.push(
                    `/events/${eventId}/abstracts-detail/${row.abstractDocument.id}/review`
                  )
                }
              >
                <Visibility />
              </AimIconButton>
            </Tooltip>
          </AimTableCell>
        </AimTableRow>
      </>
    );
  };

  const TitleAndButton = () => {
    return (
      <AimTitleAndButtons
        title={intl.formatMessage({
          description: 'abstracts list',
          defaultMessage: 'Abstracts list',
        })}
      />
    );
  };

  const AbstractValues = () => {
    return (
      <div style={{ display: 'flex', gap: 0 }}>
        <StyledCard>
          <CardTitle>{i18n.page.categoriesCardTitle}</CardTitle>
          <CardValue>{reviewerCategories}</CardValue>
          <CardLink
            onClick={() =>
              history.push(`/events/${eventId}/abstracts-reviewers-categories`)
            }
          >
            {i18n.page.showAllCategories}
          </CardLink>
        </StyledCard>
        <StyledCard>
          <CardTitle>{i18n.page.deadlineReview}</CardTitle>
          <CardValue>
            {abstractService?.event?.abstractService?.reviewTerm &&
              format(
                new Date(abstractService?.event?.abstractService?.reviewTerm),
                'dd/MM/yyyy'
              )}
          </CardValue>
        </StyledCard>
        <StyledCard>
          <CardTitle>{i18n.page.nAbstractToBeEvaluated}</CardTitle>
          <CardValue>{processingReviewsCount}</CardValue>
        </StyledCard>
        <StyledCard>
          <CardTitle>{i18n.page.nAbstractEvaluted}</CardTitle>
          <CardValue>{evaluatedReviewsCount}</CardValue>
        </StyledCard>
        <StyledCard>
          <CardTitle>{i18n.page.abstractDeclined}</CardTitle>
          <CardValue>{declinedReviewsCount}</CardValue>
        </StyledCard>
      </div>
    );
  };

  return (
    <>
      <div style={{ flex: 1, minWidth: 'calc(100vw - 120px)' }}>
        <Tooltip
          title={intl.formatMessage({
            description: 'back to previous page',
            defaultMessage: 'Back to previous page',
          })}
        >
          <AimIconAndTextButton
            // isUpperCase
            variant="none"
            text={intl.formatMessage({
              description: 'back',
              defaultMessage: 'back',
            })}
            style={{
              padding: 0,
              marginLeft: 20,
            }}
            onClick={() => {
              history.push(`/events/${eventId}/landing`);
            }}
          >
            <ArrowBack />
          </AimIconAndTextButton>
        </Tooltip>
        <TitleAndButton />
        <AbstractValues />
        <AimCheckbox
          formControLabelStyle={{ flexDirection: 'row' }}
          labelVariant="textRegular"
          label={i18n.checkbox}
          checked={showProcessingOnly}
          onChange={(e) => setShowProcessingOnly(e.target.checked)}
        />

        <StyleTable
          headCells={headCells}
          rows={getFilteredAbstracts()}
          rowKey="id"
          padding={true}
          notCheckable
          tableContainerMaxHeight={
            isMobile ? 'calc(100vh - 300px)' : 'calc(100vh - 320px)'
          }
        >
          <Row />
        </StyleTable>
      </div>
    </>
  );
}
