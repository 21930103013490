import { aws, appState } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

export const getSymposiumServices = /* GraphQL */ `
  query GetSymposiumServices($id: ID!) {
    getEvent(id: $id) {
      id
      sponsors(filter: { isDeleted: { ne: true } }) {
        items {
          id
          isDeleted
          name
        }
      }
      sponsorServiceBookingDuration
      symposiumServicesService {
        id
        extraPriceDate
        extraPriceRate
        vatRate
        symposiumServicesItems {
          items {
            id
            name
            code
            description
            price
            quantity
            purchase {
              items {
                id
                quantity
                symposiumServicesItem {
                  id
                  name
                  code
                  description
                  price
                  quantity
                }
                sponsorPackageItem {
                  id
                  quantity
                  package {
                    buyOperations {
                      items {
                        id
                      }
                    }
                  }
                }
                buyOperation {
                  date
                  id
                  unitaryPrice
                  unitaryVatRate
                  purchase {
                    id
                    date
                    purchaseId
                    paymentType
                  }
                  sponsor {
                    id
                    name
                  }
                  symposiumServicesPurchase {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const createSymposiumServicesPurchaseMutation = /* GraphQL */ `
  mutation CreateSymposiumServicesPurchase(
    $input: CreateSymposiumServicesPurchaseInput!
    $condition: ModelSymposiumServicesPurchaseConditionInput
  ) {
    createSymposiumServicesPurchase(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateSymposiumServicesPurchaseMutation = /* GraphQL */ `
  mutation UpdateSymposiumServicesPurchase(
    $input: UpdateSymposiumServicesPurchaseInput!
    $condition: ModelSymposiumServicesPurchaseConditionInput
  ) {
    updateSymposiumServicesPurchase(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateSymposiumServicesItem = /* GraphQL */ `
  mutation UpdateSymposiumServices(
    $input: UpdateSymposiumServicesItemInput!
    $condition: ModelSymposiumServicesItemConditionInput
  ) {
    updateSymposiumServicesItem(input: $input, condition: $condition) {
      id
    }
  }
`;

const deleteSymposiumServicesItem = /* GraphQL */ `
  mutation DeleteSymposiumServicesItem(
    $input: DeleteSymposiumServicesItemInput!
    $condition: ModelSymposiumServicesItemConditionInput
  ) {
    deleteSymposiumServicesItem(input: $input, condition: $condition) {
      id
    }
  }
`;

const deleteSymposiumServicesPurchaseMutation = /* GraphQL */ `
  mutation DeleteSymposiumServicesPurchase(
    $input: DeleteSymposiumServicesPurchaseInput!
    $condition: ModelSymposiumServicesPurchaseConditionInput
  ) {
    deleteSymposiumServicesPurchase(input: $input, condition: $condition) {
      id
    }
  }
`;

const createSymposiumServicesItem = /* GraphQL */ `
  mutation CreateSymposiumServicesItem(
    $input: CreateSymposiumServicesItemInput!
    $condition: ModelSymposiumServicesItemConditionInput
  ) {
    createSymposiumServicesItem(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateSymposiumServicesServiceMutation = /* GraphQL */ `
  mutation UpdateSymposiumServicesService(
    $input: UpdateSymposiumServicesServiceInput!
    $condition: ModelSymposiumServicesServiceConditionInput
  ) {
    updateSymposiumServicesService(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateService = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: updateSymposiumServicesServiceMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.updateSymposiumServicesService))
      .catch((e) => {
        console.error('event-update-symposium-services-service', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const list = (id, useLoader = true) =>
  new Promise((resolve, reject) => {
    showLoader();

    API.graphql({ query: getSymposiumServices, variables: { id } })
      .then((response) => resolve(response.data.getEvent))
      .catch((e) => {
        console.error('event-list-symposium-services-service', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const createItem = (input) =>
  new Promise((resolve, reject) => {
    showLoader();
    API.graphql({ query: createSymposiumServicesItem, variables: { input } })
      .then((response) => {
        resolve(response.data.createSymposiumServicesItem);
      })
      .catch((e) => {
        console.error('create-symposium-services-item', e);
        reject();
      })
      .finally(hideLoader);
  });

export const updateItem = (input) =>
  new Promise((resolve, reject) => {
    showLoader();

    API.graphql({ query: updateSymposiumServicesItem, variables: { input } })
      .then((response) => resolve(response.data.updateSymposiumServicesItem))
      .catch((e) => {
        console.error('update-symposium-services-item', e);
        reject();
      })
      .finally(hideLoader);
  });

export const removeItem = (input) =>
  new Promise((resolve, reject) => {
    showLoader();

    API.graphql({ query: deleteSymposiumServicesItem, variables: { input } })
      .then(async (response) => {
        resolve(response.data.deleteSymposiumServicesItem);
      })
      .catch((e) => {
        console.error('delete-symposium-services-item', e);
        reject();
      })
      .finally(hideLoader);
  });

export const createSymposiumServicesPurchase = (input) =>
  new Promise((resolve, reject) => {
    showLoader();
    API.graphql({
      query: createSymposiumServicesPurchaseMutation,
      variables: { input },
    })
      .then((response) => {
        resolve(response.data.createSymposiumServicesPurchase);
      })
      .catch((e) => {
        console.error('create-symposium-services-purchase', e);
        reject();
      })
      .finally(hideLoader);
  });

export const updateSymposiumServicesPurchase = (input) =>
  new Promise((resolve, reject) => {
    showLoader();
    API.graphql({
      query: updateSymposiumServicesPurchaseMutation,
      variables: { input },
    })
      .then((response) => {
        resolve(response.data.updateSymposiumServicesPurchase);
      })
      .catch((e) => {
        console.error('update-symposium-services-purchase', e);
        reject();
      })
      .finally(hideLoader);
  });

export const deleteSymposiumServicesPurchase = (id) =>
  new Promise((resolve, reject) => {
    showLoader();

    API.graphql({
      query: deleteSymposiumServicesPurchaseMutation,
      variables: { input: { id } },
    })
      .then(async (response) => {
        resolve(response.data.deleteSymposiumServicesPurchase);
      })
      .catch((e) => {
        console.error('delete-symposium-services-purchase', e);
        reject();
      })
      .finally(hideLoader);
  });
