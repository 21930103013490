import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { isAfter, format } from 'date-fns';
import translation from './presenterAbstracts/translation';

import ArrowForward from '@material-ui/icons/ArrowForward';
import AddIcon from '@material-ui/icons/Add';
import ViewIcon from '@material-ui/icons/Visibility';

import { appState, constants } from '@aim/common';
import { AimTypography, CustomIntl, AimCardPage, theme } from '@aim/components';

const getAbstracts = /* GraphQL */ `
  query getUserParticipationsAbstracts($id: ID!, $eventId: ID!) {
    getParticipation(id: $id) {
      isReviewer
      abstractDocuments(filter: { isDeleted: { ne: true } }) {
        items {
          cod0
          cod1
          cod2
          createdAt
          images
          id
          isDeleted
          isReadyToReview
          preferredExposure
          processingStatus
          abstractService {
            id
            maxAbstractsPerAuthor
          }
          status
          tags
          title
          url
          updatedAt
          acceptedPresentationTypology {
            createdAt
            description
            name
            id
            presentationMode
            presentationRequired
            updatedAt
          }
          proposedPresentationTypology {
            createdAt
            description
            id
            name
            presentationMode
            presentationRequired
            updatedAt
          }
          reviews {
            items {
              id
              updatedAt
            }
            nextToken
          }
          keywords
          presenter {
            participant {
              id
            }
          }
        }
      }
    }
    getEvent(id: $eventId) {
      id
      abstractService {
        id
        instructions
        isPublished
        reviewers {
          items {
            id
            participant {
              id
              email
              givenName
            }
            reviewerCategories {
              items {     
                reviewerCategoryReviewerId
                category {   
                  id
                  name
                }
                reviewerCategoryCategoryId
              }
            }
          }
        }
        abstractDocuments {
          items {
            id 
            presenter {
              participant {
                id
              }
            }
          }
        }
        presentationTypologies {
          items {
            id
            name
            presentationMode
            validationDate
            expirationDate
            categories {
              items {
                id
                category {
                  id
                  name
                  subCategories {
                    items {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
        categories {
          items {
            id
            name
            subCategories {
              items {
                id
                name
              }
            }
          }
        }
        tags
        agreement
        event {
          services(filter: { serviceName: { eq: "${constants.Services.ABSTRACT.key}" } }) {
            items {
              id
              serviceName
              customFields(filter: { isDeleted: { ne: true } }) {
                items {
                  contextsOfUse {
                    items {
                      isRequired
                      position
                      isHidden
                      contextName
                      id
                    }
                  }
                  label
                  options
                  id
                  placeholder
                }
              }
            }
          }
        }
        abstractTerm
        lateBreakersTerm
        maxAbstractsPerAuthor
        maxCategoriesPerReviewer
        isKeywordAddEnabled
        minNumberKeyword
        maxNumberKeyword
        isImageUploadEnabled
        maxTitleCharacters
        maxBodyCharacters
        isExternalUploadEnabled
        maxNumberExternalUpload
        extensionsExternalUpload
        maxDimensionExternalUpload
        isRichTextUploadEnabled
        maxNumberRichTextUpload
        extensionsRichTextUpload
        maxDimensionRichTextUpload
      }
    }
  }
`;

const PaxAbstracts = () => {
  const intl = CustomIntl(useIntl());
  const i18n = translation.presenterAbstracts(intl);
  const history = useHistory();
  const { eventId } = useParams();

  const renderDeadlines = (tData, data) => {
    if (data?.getEvent?.abstractService) {
      const {
        maxAbstractsPerAuthor,
        lateBreakersTerm,
        abstractTerm,
        maxDimensionExternalUpload,
      } = data?.getEvent?.abstractService;
      const renderMaxAbstracts = (
        <AimTypography variant="text">
          {`${i18n.subtitles.maxAbstracts} ${maxAbstractsPerAuthor} abstracts`}
        </AimTypography>
      );
      const maxSizeUploads =
        maxDimensionExternalUpload > 0 ? (
          <AimTypography variant="text">
            {i18n.subtitles.maxSize + ' ' + maxDimensionExternalUpload + ' MB'}
          </AimTypography>
        ) : null;
      return (
        <div
          style={{ backgroundColor: 'white', padding: 20, margin: '20px 0px' }}
        >
          {lateBreakersTerm ? (
            <>
              <AimTypography variant="textBold">
                {i18n.subtitles.lateBreakersTerm}
              </AimTypography>
              <AimTypography variant="textBold">
                {`${i18n.subtitles.deadline}: ${format(
                  new Date(lateBreakersTerm),
                  'P'
                )}`}
              </AimTypography>
              {renderMaxAbstracts}
              {maxSizeUploads}
            </>
          ) : (
            <>
              <AimTypography variant="textBold">
                {`${i18n.subtitles.abstractsTerm} ${format(
                  new Date(abstractTerm),
                  'P'
                )}`}
              </AimTypography>
              {renderMaxAbstracts}
              {maxSizeUploads}
            </>
          )}
        </div>
      );
    }
  };

  const isAbstractTermPassed = (abstractTerm) => {
    return isAfter(Date.now(), new Date(abstractTerm));
  };

  const isLateBreakersTermPassed = (lateBreakersTerm) => {
    return isAfter(Date.now(), new Date(lateBreakersTerm));
  };

  const model = (loadData) => ({
    header: {
      backButton: {
        onClick: () => history.replace(`/events/${eventId}/landing`),
      },
      action: {
        add: {
          icon: <AddIcon />,
          variant: 'secondary',
          onClick: () => history.push(`/events/${eventId}/abstracts/create`),
          disabledWhen: (rows, data, tData) => {
            if (data?.getEvent?.abstractService) {
              const {
                isPublished: isAbstractServicePublished,
                maxAbstractsPerAuthor,
                lateBreakersTerm,
                abstractTerm,
              } = data.getEvent.abstractService;

              const isMaxAbsPerAuthor = maxAbstractsPerAuthor === tData?.length;
              const isDisabled =
                (isAbstractTermPassed(abstractTerm) &&
                  isLateBreakersTermPassed(lateBreakersTerm)) ||
                !isAbstractServicePublished ||
                isMaxAbsPerAuthor;
              return isDisabled;
            }
          },
        },
      },
    },
    preTableComponent: (tData, data) => renderDeadlines(tData, data),
    dataLoader: {
      query: getAbstracts,
      variables: {
        // id: participation.id,
        id: appState.user.getValue().userAndParticipation.participation.id,
        eventId,
      },
      transform: (data) => {
        const abstractDocuments = data.getParticipation.abstractDocuments;
        const participationData = appState.user.getValue().userAndParticipation
          .participation;
        const presenterAbstracts = abstractDocuments.items.filter(
          (ab) => ab.presenter?.participant?.id === participationData.id
        );
        // const {
        //   lateBreakersTerm,
        //   abstractTerm,
        // } = data.getEvent.abstractService;
        // const isDeleteDisabled =
        //   isAbstractTermPassed(abstractTerm) &&
        //   isLateBreakersTermPassed(lateBreakersTerm);

        return presenterAbstracts.map((abstract) => {
          return {
            ...abstract,
            proposedPresentationTypology:
              abstract.proposedPresentationTypology?.name || '',
            acceptedAs: abstract.acceptedPresentationTypology?.name || '',
            // isDeleteDisabled,
            prepareYourPresentation: {
              variant: 'secondary',
              hiddenWhen: (row) => !row?.acceptedPresentationTypology,
              link: () =>
                // todo: route missing
                history.push(
                  `/events/${eventId}/abstracts/${abstract.id}/presentation`
                ),
              icon: <ArrowForward />,
            },
          };
        });
      },
    },
    card: {
      isRowSelectable: true,
      cardsTemplate: [
        'status',
        'proposedPresentationTypology',
        'acceptedAs',
        { buttons: ['prepareYourPresentation'] },
      ],
      filters: [
        {
          column: 'title',
          type: 'string',
        },
      ],
      cardAction: {
        view: {
          icon: <ViewIcon />,
          variant: 'primary',
          onClick: (row) =>
            history.push({
              pathname: `/events/${eventId}/abstracts/${row.id}/view`,
              state: {
                backPath: `/events/${eventId}/presenter-abstracts`,
              },
            }),
        },
      },
    },
    dialog: {},
    snackbar: {
      delete: {},
    },
  });

  return (
    <>
      <div
        style={{
          flex: 1,
          padding: 10,
          minWidth: 'calc(100vw - 120px)',
          backgroundColor: theme.colors.greyScale.backgroundGrey,
        }}
      >
        <AimCardPage model={model} translation={i18n.aimTablePage} />
      </div>
    </>
  );
};

export default PaxAbstracts;
