import { aws, appState, constants } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

const getEventQuery = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      name
      start
      end
      abstractService {
        id
        abstractDocuments(filter: { isDeleted: { ne: true } }) {
          items {
            id
            presenter {
              participant {
                id
              }
            }
          }
        }
      }
      services(filter: { serviceName: { eq: "${constants.Services.SPONSOR.key}" } }) {
        items {
          serviceName
          id
          customFields(filter: { isDeleted: { ne: false } }) {
            items {
              id
            }
          }
        }
      }
      agency: agencyService {
        id
        instructions
        cover {
          id
          originalName
          size
          extension
        }
        policies {
          items {
            id
            originalName
            size
            extension
          }
        }
      }
      configuration {
        countdown
        eventActivationDate
        eventDeactivationDate
      }
      sponsor: sponsorWelcomePage {
        id
        title
        subtitle
        content
        cover {
          id
          originalName
          size
          extension
        }
        document {
          id
          originalName
          size
          extension
        }
        gallery {
          items {
            id
            originalName
            size
            extension
          }
        }
      }
    }
  }
`;

const getEventGrantsQuery = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      grant(filter: { isDeleted: { ne: true } }, limit: 10000) {
        items {
          id
          type
          title
          closingDate
          participationGrant(limit: 10000) {
            items {
              id
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;

const getEventLetterLayoutTemplateQuery = /* GraphQL */ `
  query GetEventLetterLayoutTemplate($id: ID!) {
    getEvent(id: $id) {
      id
      letterLayoutTemplate {
        id
        name
        header {
          items {
            id
            row
            size
            alignment
            image {
              id
              size
              extension
              originalName
            }
          }
        }
        footer {
          items {
            id
            row
            size
            alignment
            image {
              id
              size
              extension
              originalName
            }
          }
        }
      }
    }
  }
`;

export const getEventData = (id) =>
  new Promise((resolve, reject) => {
    showLoader();

    API.graphql({ query: getEventQuery, variables: { id } })
      .then(({ data }) => resolve(data.getEvent))
      .catch((e) => {
        console.error('event-get-welcome-page', e);
        reject();
      })
      .finally(hideLoader);
  });

export const getEventGrants = (id) =>
  new Promise((resolve, reject) => {
    showLoader();

    API.graphql({ query: getEventGrantsQuery, variables: { id } })
      .then(({ data }) => resolve(data.getEvent))
      .catch((e) => {
        console.error('event-get-welcome-page', e);
        reject();
      })
      .finally(hideLoader);
  });

export const getEventLetterLayoutTemplate = (id, isAuthenticated = true) =>
  new Promise((resolve, reject) => {
    showLoader();

    API.graphql({
      query: getEventLetterLayoutTemplateQuery,
      variables: { id },
      ...(isAuthenticated ? {} : { authMode: 'AWS_IAM' }),
    })
      .then(({ data }) => resolve(data.getEvent.letterLayoutTemplate))
      .catch((e) => {
        console.error('get-event-letter-layout-template', e);
        reject();
      })
      .finally(hideLoader);
  });
