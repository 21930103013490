import React, { useRef, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import { constants } from '@aim/common';

import {
  AimTypography,
  AimSelect,
  AimSelectMenuItem,
  AimNumericInput,
  styled,
  theme,
  AimDialog,
  AimIconAndTextButton,
} from '@aim/components';

import { agencyGqlHelper } from '../shared/agencyGqlHelper';
import { format, isEqual, isWithinInterval } from 'date-fns';

import { formatNumber, decodeDbNumber } from '../shared/utility';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'white',
  padding: '10px',
  minWidth: '450px',
});

const formControlStyle = {
  width: 'calc(100% - 20px)',
};

export const AgencyCalcBudgetDialog = ({
  isDialogOpen,
  style,
  eventId,
  i18n,
  intl,
  onDisagree,
  ...rest
}) => {
  const [profiles, setProfiles] = useState([]);
  const [profile, setProfile] = useState();
  const [feeDateRanges, setFeeDateRanges] = useState([]);
  const [total, setTotal] = useState();
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [eventType, setEventType] = useState();
  const agency = agencyGqlHelper();

  const gridContainerRef = useRef();
  const feeBracketModelOptionsRef = useRef([]);

  const getData = async () => {
    const eventData = await agency.getEventData(eventId);
    setEventType(eventData.type);
    console.log('eventData', eventData);

    // if (eventData.profiles.length === 1) setProfile(eventData.profiles[0].id);
    setProfiles(eventData.profiles);
    // if (eventData.feeDateRanges.length === 1)
    //   setFeeDateRange(eventData.feeDateRanges[0].id);
    const nextFeeDateRanges = eventData.feeDateRanges.map(
      ({ id, start, end, label }) => {
        const eventDateStart = format(new Date(start), 'PP');
        const eventDateEnd = format(new Date(end), 'PP');
        const nextLabel =
          eventDateStart === eventDateEnd
            ? `${label} - ${eventDateStart}`
            : `${label} - ${eventDateStart} - ${eventDateEnd}`;
        return {
          id,
          start: new Date(start),
          end: new Date(end),
          label: nextLabel,
        };
      }
    );
    setFeeDateRanges(nextFeeDateRanges);

    const now = new Date();
    feeBracketModelOptionsRef.current = eventData.profiles.reduce(
      (res, curr) => ({
        ...res,
        [curr.id]: [
          ...(res[curr.id] || []),
          ...curr.profileFeeBrackets.items
            .filter((i) =>
              isWithinInterval(now, {
                start: new Date(i.feeBracket?.feeBracketModel.start),
                end: new Date(i.feeBracket?.feeBracketModel.end),
              })
            )
            .filter((i) => i.profileFeeConfiguration?.isActive),
        ],
      }),
      {}
    );
    // console.log('current ', feeBracketModelOptionsRef.current);
  };

  const onClose = () => {
    setSelectedProfiles([]);
    setProfile('');
    setTotal(0);
    onDisagree();
  };

  useEffect(() => {
    if (eventId) {
      getData();
    }
    // setTotal(parseInt(quantity, 10) * price);
  }, [eventId]);

  useEffect(() => {
    if (selectedProfiles.length > 0) {
      const objSum = selectedProfiles.reduce(
        (res, curr) => {
          return {
            total: res.total + curr.price * parseInt(curr.quantity, 10),
          };
        },
        { total: 0 }
      );
      setTotal(objSum.total);
    }
  }, [selectedProfiles]);

  const onChangeParticipationType = (value, rowIndex) => {
    const selectedProfilesNext = [...selectedProfiles];
    const selected = selectedProfilesNext[rowIndex];
    selected.participationType = value;
    setSelectedProfiles(selectedProfilesNext);
  };

  const onChangeFeeDateRange = (value, rowIndex) => {
    const selectedProfilesNext = [...selectedProfiles];
    const selected = selectedProfilesNext[rowIndex];
    const price = setPriceValue(
      selected.profile,
      selected.participationType,
      value
    );
    selected.feeDateRange = value;
    selected.price = price;
    setSelectedProfiles(selectedProfilesNext);
  };

  const setPriceValue = (profile, participationType, feeDateRange) => {
    if (!profile || !feeDateRange) return;
    const baseDate = new Date();
    const profileObj = profiles.find((p) => p.id === profile);
    const feeDateRangeObj = feeDateRanges.find(
      (fdr) => fdr.id === feeDateRange
    );
    const bracket = profileObj.profileFeeBrackets.items.find(
      (profileFeeFracket) => {
        // find feebracket (con il daterange del partecipante)
        const isStartEqual = isEqual(
          feeDateRangeObj.start,
          new Date(profileFeeFracket.feeBracket.feeDateRange.start)
        );
        const isEndEqual = isEqual(
          feeDateRangeObj.end,
          new Date(profileFeeFracket.feeBracket.feeDateRange.end)
        );
        // find feebracket (in base allo scaglione)
        const isInDatesInterval = isWithinInterval(baseDate, {
          start: new Date(profileFeeFracket.feeBracket.feeBracketModel.start),
          end: new Date(profileFeeFracket.feeBracket.feeBracketModel.end),
        });
        return isStartEqual && isEndEqual && isInDatesInterval;
      }
    );
    if (bracket) {
      if (participationType === constants.EventTypes.PHYSICAL.id) {
        return bracket.priceOnSite;
      } else if (participationType === constants.EventTypes.VIRTUAL.id) {
        return bracket.priceOnAir;
      }
    }
    return 0;
    // return bracket ? bracket.priceOnAir || bracket.priceOnSite : 0;
    // setPrice(bracket ? bracket.priceOnAir || bracket.priceOnSite : 0);
  };

  const updateQuantity = (value, rowIndex, isClick) => {
    const selectedProfilesNext = [...selectedProfiles];
    const selected = selectedProfilesNext[rowIndex];
    const nextQuantity = isClick ? selected.quantity + value : value;
    selected.quantity = nextQuantity;
    setSelectedProfiles(selectedProfilesNext);
  };

  const handleQuantityChange = (value, rowIndex) => {
    updateQuantity(value, rowIndex, false);
    // setQuantity(value);
    // setTotal(parseInt(value, 10) * price);
  };

  const handleClick = (value, rowIndex) => {
    // const nextQuantity = quantity + value;
    updateQuantity(value, rowIndex, true);
  };

  const addProfile = () => {
    //setSelectedProfiles(...selectedProfiles, selectedProfileRef);
    const participationType = Object.values(constants.EventTypes).find(
      (type) =>
        type.id === eventType && type.id !== constants.EventTypes.HYBRID.id
    )?.id;

    const newProfiles = [
      ...selectedProfiles,
      {
        profile,
        participationType,
        feeDateRange: undefined,
        price: 0,
        quantity: 0,
      },
    ];
    setSelectedProfiles(newProfiles);
    setProfile('');
  };

  const gridRow = (p, rowIndex) => {
    return (
      <Grid
        container
        alignItems="center"
        style={{
          margin: '0px 10px 10px 10px',
          padding: '0px',
          backgroundColor: theme.colors.greyScale.backgroundGrey,
          width: '100%',
        }}
      >
        <Grid item xs={3}>
          {/* <AimSelect
            value={selectedProfiles[rowIndex].profile}
            onChange={(e) => setProfile(e.target.value)}
            selectPlaceholder={i18n.modal.calculator.grid.profile.placeholder}
            formControlStyle={formControlStyle}
          >
            {profiles.map((item) => (
              <AimSelectMenuItem key={item.id} value={item.id}>
                {item.name}
              </AimSelectMenuItem>
            ))}
          </AimSelect> */}
          <AimTypography variant={'text'} boxStyle={{ fontSize: 16 }}>
            {
              profiles.find(
                (item) => item.id === selectedProfiles[rowIndex].profile
              ).name
            }
          </AimTypography>
        </Grid>
        <Grid item xs={3}>
          <AimSelect
            value={selectedProfiles[rowIndex].participationType}
            // {...(eventType !== constants.EventTypes.HYBRID.id && {
            //   value: selectedProfiles[rowIndex].participationType,
            // })}
            onChange={(e) =>
              onChangeParticipationType(e.target.value, rowIndex)
            }
            selectPlaceholder={
              i18n.modal.calculator.grid.participation.placeholder
            }
            formControlStyle={formControlStyle}
            disabled={eventType !== constants.EventTypes.HYBRID.id}
          >
            {Object.values(constants.EventTypes)
              .filter(
                (eventType) => eventType.id !== constants.EventTypes.HYBRID.id
              )
              .map((eventType) => (
                <AimSelectMenuItem key={eventType.id} value={eventType.id}>
                  {eventType.label(intl)}
                </AimSelectMenuItem>
              ))}
          </AimSelect>
        </Grid>
        <Grid item xs={3}>
          <AimSelect
            value={selectedProfiles[rowIndex].feeDateRange}
            onChange={(e) => onChangeFeeDateRange(e.target.value, rowIndex)} // setFeeDateRange(e.target.value)}
            selectPlaceholder={
              i18n.modal.calculator.grid.feeDateRange.placeholder
            }
            formControlStyle={formControlStyle}
          >
            {feeBracketModelOptionsRef.current?.[p.profile].map(
              ({ feeBracket }) => (
                <AimSelectMenuItem
                  key={feeBracket.feeDateRange.id}
                  value={feeBracket.feeDateRange.id}
                >
                  {feeBracket.feeDateRange.label}
                </AimSelectMenuItem>
              )
            )}
          </AimSelect>
        </Grid>
        <Grid item xs={1}>
          <AimTypography variant={'text'}>
            {decodeDbNumber(selectedProfiles[rowIndex].price)}
          </AimTypography>
        </Grid>
        <Grid item xs={2}>
          {/* <AimTextField
            type="number"
            displayLabel={false}
            placeholder={i18n.modal.calculator.grid.quantity.placeholder}
            value={quantity || 0}
            onChange={(e) => handleQuantityChange(e.target.value)}
            formControlStyle={{ minWidth: '80px' }}
          /> */}
          <AimNumericInput
            value={selectedProfiles[rowIndex].quantity}
            editableInput
            onChange={(value) => handleQuantityChange(value, rowIndex)}
            onClickPlus={() => handleClick(1, rowIndex)}
            onClickMinus={() =>
              handleClick(
                (selectedProfiles[rowIndex].quantity > 0 && -1) || 0,
                rowIndex
              )
            }
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <AimDialog
      disableEnforceFocus
      fullWidth
      maxWidth={'md'}
      open={isDialogOpen}
      title={i18n.modal.calculator.title}
      onClose={onClose}
      hideCancelButton
      disagreeText={i18n.modal.calculator.buttons.close}
      // onDisagree={onDisagree}
      agreeText={i18n.modal.calculator.buttons.close}
      // agreeText={i18n.modal.calculator.buttons.buy}
      // onAgree={() => onPurchaseClick()}
    >
      <Container style={style} {...rest}>
        <AimTypography variant="text">
          {i18n.modal.calculator.description.label}
        </AimTypography>

        <Grid container>
          <Grid xs={10}>
            <AimSelect
              value={profile}
              onChange={(e) => setProfile(e.target.value)}
              selectPlaceholder={i18n.modal.calculator.grid.profile.placeholder}
              formControlStyle={formControlStyle}
            >
              {profiles
                .filter((p) =>
                  p.profileFeeBrackets.items.some(
                    (i) => i.profileFeeConfiguration?.isActive
                  )
                )
                .map((item) => (
                  <AimSelectMenuItem
                    key={item.id}
                    value={item.id}
                    // disabled={selectedProfiles.find((x) => x.profile === item.id)}
                  >
                    {item.name}
                  </AimSelectMenuItem>
                ))}
            </AimSelect>
          </Grid>
          <Grid xs={2}>
            <AimIconAndTextButton
              variant="lightBlueFill"
              onClick={() => addProfile()}
              text={i18n.modal.calculator.addProfile.title}
              style={{ margin: 10, whiteSpace: 'nowrap' }}
              disabled={!profile || profile === 0}
            >
              <AddIcon />
            </AimIconAndTextButton>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={3}>
            <AimTypography variant={'h6'} style={{ marginLeft: '30px' }}>
              {i18n.modal.calculator.grid.profile.label}
            </AimTypography>
          </Grid>
          <Grid item xs={3}>
            <AimTypography variant={'h6'} style={{ marginLeft: '30px' }}>
              {i18n.modal.calculator.grid.participation.label}
            </AimTypography>
          </Grid>
          <Grid item xs={3}>
            <AimTypography variant={'h6'} style={{ marginLeft: '30px' }}>
              {i18n.modal.calculator.grid.feeDateRange.label}
            </AimTypography>
          </Grid>
          <Grid item xs={1}>
            <AimTypography variant={'h6'}>
              {i18n.modal.calculator.grid.price}
            </AimTypography>
          </Grid>
          <Grid item xs={2}>
            <AimTypography variant={'h6'}>
              {i18n.modal.calculator.grid.quantity.label}
            </AimTypography>
          </Grid>
        </Grid>
        <Grid container ref={gridContainerRef}>
          {selectedProfiles && selectedProfiles.map(gridRow)}
        </Grid>
        <Grid
          container
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: '8px',
            marginBottom: '10px',
          }}
        >
          <AimTypography variant={'h6'}>
            {i18n.modal.calculator.grid.total.label}
          </AimTypography>
          <AimTypography variant={'text'}>
            {decodeDbNumber(total || 0)}
          </AimTypography>
          {/* <AimTypography variant={'text'}>{price * quantity}</AimTypography> */}
        </Grid>
        <Grid
          container
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: '8px',
            marginBottom: '10px',
          }}
        ></Grid>
      </Container>
    </AimDialog>
  );
};
