import React, { useState, useEffect } from 'react';
// import { isMobile, isMobileOnly } from 'react-device-detect';
import { useHistory, useParams } from 'react-router-dom';
import { appState, constants } from '@aim/common';
import { theme, useAimMediaQuery } from '@aim/components';

import {
  HomeContainer,
  SectionContainer,
  TitleHome,
  AgendaMobileSection,
  SectionContainerAgenda,
} from './home/style';

import PreFooter from './home/PreFooter/PreFooter';
import AgendaList from '../shared/agenda/AgendaList';
import Activity from './home/Activity/Activity';
import Sponsors from './home/Sponsors/Sponsors';
import { activities } from './home/mocks';
import EventMapSvg from './home/Map/EventMapSvg';
import Speakers from './home/Speakers/Speakers';

const homeContainerStyle = {
  backgroundColor: theme.colors.greyScale.white,
};

const WelcomeMessage = ({ event }) => (
  <TitleHome>{event.configuration.homepageWelcomeMessageText}</TitleHome>
);

const Activities = ({ history, eventId, isMobile }) => (
  <>
    <TitleHome>Activities</TitleHome>
    <Activity {...{ activities, eventId, history, isMobile }} />
  </>
);

const Sponsor = ({ event }) => (
  <>
    <TitleHome>Sponsors</TitleHome>
    <Sponsors event={event} />
  </>
);

const Speaker = ({ event }) => (
  <>
    <TitleHome>Speakers</TitleHome>
    <Speakers event={event} />
  </>
);

const Agenda = ({ isMobile }) => {
  if (isMobile) {
    return (
      <SectionContainerAgenda>
        <AgendaMobileSection>
          <AgendaList />
        </AgendaMobileSection>
      </SectionContainerAgenda>
    );
  }

  return (
    <SectionContainerAgenda>
      <AgendaList />
    </SectionContainerAgenda>
  );
};

const Map = ({ event, isMobileOnly }) => (
  <SectionContainer>
    <EventMapSvg
      s3Folder={`events/${event.id}/configuration/generalInfo/homepage`}
      input={
        isMobileOnly
          ? event.configuration.homepageMapMobileModel
          : event.configuration.homepageMapDesktopModel
      }
    />
  </SectionContainer>
);

const PreFooterModel = ({ event }) => <PreFooter event={event} />;

// speaker sono i partecipanti di tipo speaker / vedere solo 4 + pulsante che rimanda a speakers - grafiche gia' dentro speakers.js le card

const componentsMap = {
  welcome_message: WelcomeMessage,
  map: Map,
  activity: Activities,
  sponsor: Sponsor,
  speakers: Speaker,
  agenda: Agenda,
  pre_footer: PreFooterModel,
};

const Home = () => {
  const history = useHistory();
  const { eventId } = useParams();
  const [event] = useState({
    ...appState.eventInfo.getValue(),
    configuration: appState.eventConfiguration.getValue(),
  });
  const [pageItems, setPageItems] = useState([]);

  const { isMobile, isMobileOnly } = useAimMediaQuery();

  useEffect(() => {
    event.configuration.homepageItemsOrder.forEach((name) => {
      const itemData = Object.values(constants.EventHomepageItems).find(
        (i) => i.id === name
      );

      event.configuration[itemData.databaseFlagName] &&
        setPageItems((prev) => [...prev, name]);
    });
  }, []);

  const renderItems = () =>
    pageItems.map((name) =>
      componentsMap[name]
        ? React.cloneElement(
            componentsMap[name]({
              history,
              eventId,
              event,
              isMobile,
              isMobileOnly,
            })
          )
        : null
    );

  return (
    <HomeContainer style={homeContainerStyle}>{renderItems()}</HomeContainer>
  );
};

export default Home;
