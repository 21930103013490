import React from 'react';
import { sortBy } from 'lodash';
import { useIntl } from 'react-intl';

import translation from './../shared/translation';
import {
  CustomIntl,
  AimIconAndTextButton,
  AimRichText,
  AimTypography,
  AimSnackbar,
  AimSnackbarSeverity,
} from '@aim/components';

import { appState } from '@aim/common';

import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Visibility from '@material-ui/icons/Visibility';

import {
  MainContainer,
  InnerContainer,
} from '../shared/SponsorPagesContainers';
import DocumentsList from '../shared/documentsList/DocumentsList';
import { useHistory, useParams } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { listOtherSponsorizationsService } from '../shared/otherSponsorizationsServiceGqlHelper';
import { useSponsors } from '../shared/sponsorGqlHelper';

import {
  OtherSponsRow,
  OtherSponsIconButton,
} from './otherSponsorizations/OtherSponsorizations.styles';

const OtherSponsorizations = () => {
  // Hooks
  const history = useHistory();
  const intl = CustomIntl(useIntl());
  const i18n = translation.otherSponsorizations(intl);
  const { eventId, sponsorId } = useParams();
  const { get: getSponsor, update: updateSponsor } = useSponsors();

  // State
  const [
    otherSponsorizationsService,
    setOtherSponsorizationsService,
  ] = useState();
  const [sponsorData, setSponsorData] = useState();
  const [snackbar, setSnackbar] = useState({ isOpen: false });

  const servicesCategories = sortBy(
    otherSponsorizationsService?.itemCategories.items,
    'name'
  );
  const serviceDocuments = otherSponsorizationsService?.documents.items;
  const hasCategories = servicesCategories?.length;
  const mandatoryDocs = serviceDocuments?.filter((d) => d.isMandatory);
  const downloadPath = `events/${eventId}/sponsor/other-sponsorizations/documents/`;

  const hasReadMandatoryDocs =
    !mandatoryDocs?.length ||
    mandatoryDocs?.every(
      (md) => !!sponsorData?.requiredDocumentsIds.find((rd) => rd === md.id)
    );

  // Effects
  useEffect(() => {
    fetchSponsorAndService();
  }, []);

  // API
  const fetchSponsorAndService = async () =>
    Promise.all([fetchSponsor(), fetchService()]);

  const fetchSponsor = async () => {
    const userAndParticipation = appState.user.getValue()?.userAndParticipation;
    const sponsor =
      userAndParticipation?.participation?.sponsor ||
      userAndParticipation?.participation?.sponsorStaff?.sponsor;

    const sponsorData = await getSponsor(sponsor.id);
    setSponsorData(sponsorData);
  };

  const fetchService = async () => {
    const result = await listOtherSponsorizationsService(eventId);
    setOtherSponsorizationsService(result.otherSponsorizationsService);
  };

  // Helpers
  const onDownload = async (fileData) => {
    const documentId = fileData.id;
    if (
      fileData.isMandatory &&
      !sponsorData?.requiredDocumentsIds.includes(documentId)
    ) {
      const nextRequiredDocumentsIds = [
        ...sponsorData?.requiredDocumentsIds,
        documentId,
      ];
      await updateSponsor({
        id: sponsorData.id,
        requiredDocumentsIds: nextRequiredDocumentsIds,
      });

      fetchSponsor();
    }
  };

  const handleCategoryNavigation = (categoryId) => {
    if (!hasReadMandatoryDocs)
      return setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.snackbar.missingDownloadsError,
      });

    history.push(
      `/events/${eventId}/${sponsorId}/my-services/other-sponsorizations/${categoryId}`
    );
  };

  // Renders
  const renderPageHeading = () => (
    <>
      <div style={{ display: 'flex-root', marginTop: 20 }}>
        <Tooltip title={i18n.page.back.tooltip.label}>
          <AimIconAndTextButton
            variant="text"
            text={i18n.page.back.button.label}
            onClick={() =>
              history.push(`/events/${eventId}/${sponsorId}/my-services`)
            }
          >
            <ArrowBack />
          </AimIconAndTextButton>
        </Tooltip>
      </div>
      <Grid container>
        <Grid item xs={12}>
          <AimTypography variant={'h1'}>{i18n.page.title.label}</AimTypography>
        </Grid>
      </Grid>
    </>
  );

  const renderPageBody = () => (
    <Grid container>
      {renderDescription()}
      <DocumentsList
        dirPath={downloadPath}
        documents={serviceDocuments}
        onFileDownloaded={onDownload}
      />
      {renderCategories()}
    </Grid>
  );

  const renderDescription = () => (
    <Grid item xs={12}>
      {otherSponsorizationsService?.description && (
        <AimRichText
          label={i18n.page.instructions.label}
          value={JSON.parse(otherSponsorizationsService.description)}
          readOnly
          editorStyleOverride={{
            minHeight: 'auto',
            maxHeight: 'fit-content',
            overflow: 'hidden !important',
          }}
        />
      )}
    </Grid>
  );

  const renderCategories = () =>
    sponsorData &&
    otherSponsorizationsService && (
      <Grid item xs={12} style={{ marginTop: 16 }}>
        <AimTypography variant="h6" style={{ marginLeft: 16 }}>
          {i18n.page.services.label}
        </AimTypography>
        <div style={{ margin: 4 }}>
          {hasCategories ? (
            servicesCategories?.map((c, idx) => (
              <OtherSponsRow key={idx}>
                {c.name}

                <OtherSponsIconButton
                  small
                  variant="lightBlueFill"
                  onClick={() => handleCategoryNavigation(c.id)}
                >
                  <Visibility />
                </OtherSponsIconButton>
              </OtherSponsRow>
            ))
          ) : (
            <AimTypography variant="text" style={{ marginLeft: 12 }}>
              {i18n.page.services.noServicesFound}
            </AimTypography>
          )}
        </div>
      </Grid>
    );

  const renderSnackbar = () => (
    <AimSnackbar
      open={snackbar.isOpen}
      onClose={() => setSnackbar({ isOpen: false })}
      severity={snackbar.severity}
    >
      {snackbar.message}
    </AimSnackbar>
  );

  return (
    <MainContainer>
      <InnerContainer grow bottomSpacing>
        {renderPageHeading()}
        {renderPageBody()}
      </InnerContainer>
      {renderSnackbar()}
    </MainContainer>
  );
};

export default OtherSponsorizations;
