import { aws, appState, utilities } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const getAdditionalServicesQuery = /* GraphQL */ `
  query GetAdditionalService($id: ID!) {
    getAdditionalServices(id: $id) {
      id
      typology
      participationType
      configuration {
        id
        rule
      }
      additionalServiceSessions {
        items {
          id
          additionalServiceId
          agendaSessionId
          agendaSession {
            date
            end
            name
            start
          }
        }
      }
      amount1
      amount2
      categories
      closingDate
      dateService
      startTime
      description
      images {
        items {
          id
          extension
          size
          originalName
        }
      }
      locationAddress
      locationName
      maxSubscription
      minSubscription
      priceIncluded
      requiredRegistration
      reservedToFaculty
      reservedToProfiles
      reservedToTipologies
      subPolicyDescription
      typology
      title
      vat1
      vat2
      additionaServiceVat1 {
        id
      }
      additionaServiceVat2 {
        id
      }
      isAvailabilityHidden
    }
  }
`;

const getEventGatewaysQuery = /* GraphQL */ `
  query GetEvent($id: ID!, $serviceType: String) {
    getEvent(id: $id) {
      id
      gateways(filter: { service: { eq: $serviceType } }) {
        items {
          id
          service
          paymentType
          iban
          ibanCausal
          addressedTo
          shop {
            id
            gatewayType
          }
        }
      }
    }
  }
`;

const getProductsByClientIdServiceIdQuery = /* GraphQL */ `
  query getProductsByClientIdServiceId(
    $serviceId: String
    $serviceType: String
  ) {
    productByServiceIdServiceType(
      serviceId: $serviceId
      serviceType: { eq: $serviceType }
    ) {
      items {
        id
        productId
        clientId
        clientType
        serviceId
        serviceType
        payment {
          id
          paymentId
          paymentType
          paymentIdMonetaOnLine
          paymentIdIngenico
          paymentIdGpWebpay
          paymentError
        }
      }
    }
  }
`;

const getParticipationBillingInformationQuery = /* GraphQL */ `
  query GetParticipationBillingInformation($id: ID!) {
    getParticipation(id: $id) {
      billingInformation {
        id
        invoiceTo
        name
        address
        city
        postalCode
        region
        province
        country
        countryIsoCode
        vatCode
        taxCode
        reference
        email
        pec
        phone
        invoiceToPublicAuthority
        uniqueCode
        ipaCode
        isSplitPayment
        isVatEvent
      }
    }
  }
`;

const updateParticipationMutation = /* GraphQL */ `
  mutation UpdateParticipation(
    $input: UpdateParticipationInput!
    $condition: ModelParticipationConditionInput
  ) {
    updateParticipation(input: $input, condition: $condition) {
      id
    }
  }
`;

const createBillingInformationMutation = /* GraphQL */ `
  mutation CreateBillingInformation(
    $input: CreateBillingInformationInput!
    $condition: ModelBillingInformationConditionInput
  ) {
    createBillingInformation(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateBillingInformationMutation = /* GraphQL */ `
  mutation UpdateBillingInformation(
    $input: UpdateBillingInformationInput!
    $condition: ModelBillingInformationConditionInput
  ) {
    updateBillingInformation(input: $input, condition: $condition) {
      id
    }
  }
`;

export const getAdditionalService = (id, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: getAdditionalServicesQuery,
      variables: { id },
    })
      .then((response) => {
        resolve(response.data.getAdditionalServices);
      })
      .catch((e) => {
        console.error('get-additional-services', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const getEventGateway = (input) =>
  new Promise((resolve, reject) => {
    showLoader();
    aws.API.graphql({
      query: getEventGatewaysQuery,
      variables: { ...input },
    })
      .then((response) => {
        const { data } = response;
        resolve(data.getEvent.gateways.items[0]);
      })
      .catch((e) => {
        console.error('event-gateways', e);
        reject();
      })
      .finally(hideLoader);
  });

export const getProductsByClientIdServiceId = (
  serviceId,
  serviceType,
  useLoader = true
) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: getProductsByClientIdServiceIdQuery,
      variables: {
        serviceId,
        serviceType,
      },
    })
      .then((response) => {
        const validProducts = response.data.productByServiceIdServiceType.items.filter(
          (product) => utilities.isValidPayment(product.payment)
        );
        resolve(validProducts);
      })
      .catch((e) => {
        console.error('products-by-service-id-service-type', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const getBillingInformations = (participationId, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    API.graphql({
      query: getParticipationBillingInformationQuery,
      variables: { id: participationId },
    })
      .then((response) =>
        resolve(response.data.getParticipation?.billingInformation)
      )
      .catch((e) => {
        console.error('get-participation-billing-information', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const updateParticipation = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    API.graphql({
      query: updateParticipationMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.updateParticipation))
      .catch((e) => {
        console.error('update-participation', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const createBillingInformation = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    API.graphql({
      query: createBillingInformationMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.createBillingInformation.id))
      .catch((e) => {
        console.error('create-billing-information', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const updateBillingInformation = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    API.graphql({
      query: updateBillingInformationMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.updateBillingInformation.id))
      .catch((e) => {
        console.error('update-billing-information', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });
