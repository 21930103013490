import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import {
  AimIconAndTextButton,
  AimTitleAndButtons,
  CustomIntl,
  TicketInfo,
  styled,
} from '@aim/components';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowBack from '@material-ui/icons/ArrowBack';
import translation from './translation';
import { constants, utilities } from '@aim/common';

import {
  getParticipationGuestsHotelRoomReservation,
  getProduct,
  getHotelReservation,
} from '../shared/gqlHelper';

const MyAllotmentTicketContainer = styled('div')({
  width: '80%',
  marginBottom: '5%',
});

const CustomPageHeader = styled('div')({
  display: 'flex',
  flex: 1,
  [`@media print`]: {
    display: 'none',
  },
});

const MyAllotmentTicketWrapper = () => {
  const { eventId, participationId, productId } = useParams();
  const history = useHistory();

  const intl = CustomIntl(useIntl());
  const i18n = translation.myAllotment(intl);
  const { PaymentTypesWithTranslations, PaymentStatuses, Services } = constants;

  const [allotmentData, setAllotmentData] = useState();

  const fetchData = async () => {
    const participationResponse = await getParticipationGuestsHotelRoomReservation(
      participationId
    );
    const responseProduct = await getProduct(productId);

    const nextProduct = {
      ...responseProduct,
      productOwner: {
        id: participationResponse.id,
        givenName: participationResponse.givenName,
        familyName: participationResponse.familyName,
        email: participationResponse.email,
        phone: participationResponse.phone,
      },
      productGuests: responseProduct.guests?.items.map((guest) => ({
        id: guest.id,
        givenName: guest.givenName,
        familyName: guest.familyName,
        country: guest.country,
      })),
    };

    const guestReservations = participationResponse.guests.items
      .map((g) => ({
        ...g.hotelRoomReservation?.hotelReservation,
        id: g.hotelRoomReservation?.hotelReservation?.id,
        guestId: g.id,
      }))
      .filter(
        (hr) =>
          hr?.id && hr.isDeleted !== 'true' && nextProduct.productId !== hr.id
        // groupedProducts?.ALLOTMENT?.findIndex((x) => x.productId === hr.id) <
        //   0
      )
      .map((hr) => ({
        ...hr,
        productId: hr.id,
        //for guests product.id is guestId
        id: hr.guestId,
        isGuest: true,
      }));

    // const nextGroupedProducts = { ...groupedProducts, guestReservations };
    // const nextProductsWithGuestsReservations = {
    //   reservation: nextProduct,
    //   guestReservations,
    // };
    const responseHotelReservation = await getHotelReservation(
      nextProduct.productId
    );

    const result = [
      {
        ...nextProduct,
        hotel: responseHotelReservation || {},
        gateway:
          participationResponse.event.gateways.items.find(
            (g) => g.service === 'REGISTRATION'
          ) || {},
      },
    ];

    const ALLOTMENT_data = {
      title: Services['ALLOTMENT'].label(intl),
      selected: false,
      blockKey: 'ALLOTMENT',
      data: result.map((product) => ({
        ...product,
        hotelName:
          product?.hotel?.hotelRoomReservation?.items?.[0].hotelRoom?.hotel
            ?.name || '-',
        hotelAddress:
          `${
            product?.hotel?.hotelRoomReservation?.items?.[0].hotelRoom?.hotel
              ?.address
          } ${
            product?.hotel?.hotelRoomReservation?.items?.[0].hotelRoom?.hotel
              ?.streetNumber || ''
          }` || '-',
        hotelStars:
          product?.hotel?.hotelRoomReservation?.items?.[0].hotelRoom?.hotel
            ?.stars || '-',
        hotelPhone:
          product?.hotel?.hotelRoomReservation?.items?.[0].hotelRoom?.hotel
            ?.phone || '-',
        hotelEmail:
          product?.hotel?.hotelRoomReservation?.items?.[0].hotelRoom?.hotel
            ?.email || '-',
        hotelRoomReservation:
          product?.hotel?.hotelRoomReservation?.items || '-',
      })),
    };

    setAllotmentData(ALLOTMENT_data);
  };

  useEffect(() => {
    fetchData();
  }, [participationId]);

  const backUrl = `/events/${eventId}/user/${participationId}/my-allotment/`;

  const getBasicData = ({ product, intl }) => {
    const { givenName, familyName, email, phone } = product?.productOwner || {};
    // product?.payment?.BillingInformation || {};

    const clientInformations = {
      givenName,
      familyName,
      email,
      phone,
    };

    const billingInformations =
      //skip payment details in free payments
      product?.payment?.BillingInformation && product?.payment?.amount > 0
        ? {
            ...(product?.payment?.BillingInformation || {}),
            isVATExent: product?.payment?.BillingInformation?.isVatEvent,
            invoiceTo: product?.payment?.BillingInformation?.invoiceTo
              ? Object.values(constants.InvoiceToType)
                  .find(
                    (it) =>
                      it.id === product?.payment?.BillingInformation?.invoiceTo
                  )
                  ?.label(intl)
              : null,
            reference:
              product?.payment?.BillingInformation?.invoiceTo ===
              constants.InvoiceToType.COMPANY.id
                ? product?.payment?.BillingInformation.reference
                : givenName,
          }
        : null;

    const paymentDetails =
      //skip payment details in free payments
      product?.payment && product?.payment?.amount > 0
        ? {
            // ...(product?.payment || {}),
            total: utilities.formatNumber(
              utilities.decodeDbNumber(product?.payment?.amount)
            ),
            paymentMethod:
              PaymentTypesWithTranslations.find(
                (x) => x.key === product?.payment?.paymentType
              ).label(intl) || product?.payment?.paymentType,
            paymentStatus: product?.payment?.paymentStatus
              ? Object.values(PaymentStatuses)
                  .find((x) => x.key === product?.payment?.paymentStatus)
                  .label(intl) || product?.payment?.paymentStatus
              : null,
            paymentReason: product?.gateway?.ibanCausal,
            IBAN: product?.gateway?.iban,
            addressedTo: product?.gateway?.addressedTo,
            paymentObject: product?.payment,
          }
        : null;

    return { clientInformations, billingInformations, paymentDetails };
  };

  return (
    <MyAllotmentTicketContainer>
      <CustomPageHeader>
        <Tooltip title={i18n.page.back}>
          <AimIconAndTextButton
            variant="text"
            style={{ padding: 0 }}
            text={i18n.page.back}
            onClick={() => history.push(backUrl)}
          >
            <ArrowBack />
          </AimIconAndTextButton>
        </Tooltip>
      </CustomPageHeader>
      <AimTitleAndButtons title={i18n.page.ticketTitle} />

      {allotmentData &&
        allotmentData.data.map((hotel, ndx) => {
          const {
            clientInformations,
            billingInformations,
            paymentDetails,
          } = getBasicData({ product: hotel, intl });
          return (
            <TicketInfo
              key={ndx}
              {...{
                intl,
                clientInformations,
                billingInformations,
                paymentDetails,
                allotmentInformations: {
                  allotmentInformations: allotmentData,
                  ndx: 0,
                },
                module: 'allotment',
                i18n,
              }}
            />
          );
        })}
    </MyAllotmentTicketContainer>
  );
};

export default MyAllotmentTicketWrapper;
