export default function (intl) {
  return {
    page: {
      categories: intl.formatMessage({
        description: 'categories label',
        defaultMessage: 'Categories',
      }),
      title: intl.formatMessage({
        description: 'abstracts list',
        defaultMessage: 'Abstracts list',
      }),
      backButton: intl.formatMessage({
        description: 'abstracts list',
        defaultMessage: 'Abstracts',
      }),
      analyticsCards: {
        deliveryDate: intl.formatMessage({
          description: 'abstracts list',
          defaultMessage: 'Delivery date',
        }),
        abstractAssigned: intl.formatMessage({
          description: 'abstracts list',
          defaultMessage: 'N° Abstract Assigned',
        }),
        abstractEvaluated: intl.formatMessage({
          description: 'abstracts list',
          defaultMessage: 'N° Abstract Evaluated',
        }),
        abstractDeclined: intl.formatMessage({
          description: 'abstracts list',
          defaultMessage: 'N° Abstract "Declined" ',
        }),
      },
      list: {
        title: intl.formatMessage({
          description: 'current list',
          defaultMessage: 'Current state',
        }),
        rowTitle: intl.formatMessage({
          description: 'topic',
          defaultMessage: 'Topic',
        }),
      },
    },
    toolbar: {
      buttons: {
        goToWall: intl.formatMessage({
          description: 'go to the wall',
          defaultMessage: 'Go to the wall',
        }),
      },
    },
    card: {
      presentationButton: intl.formatMessage({
        description: 'setup presentation',
        defaultMessage: 'Setup your presentation',
      }),
    },
    dialog: {
      text: intl.formatMessage({
        description: 'setup presentation',
        defaultMessage: 'Are you sure you want to delete the astract document?',
      }),
      title: intl.formatMessage({
        description: 'setup presentation',
        defaultMessage: 'Delete abstract document',
      }),
    },
    subtitles: {
      subtitleData: intl.formatMessage({
        description: 'setup presentation',
        defaultMessage: 'You can send your abstract untill ',
      }),
      subtitleMax1: intl.formatMessage({
        description: 'setup presentation',
        defaultMessage: 'You can send at least ',
      }),
      subtitleMax2: intl.formatMessage({
        description: 'setup presentation',
        defaultMessage: ' abstract',
      }),
    },
    table: {
      rowButton: intl.formatMessage({
        description: 'detail',
        defaultMessage: 'Detail ',
      }),
      columnsTitles: {
        title: intl.formatMessage({
          description: 'title',
          defaultMessage: 'Title ',
        }),
        category: intl.formatMessage({
          description: 'category',
          defaultMessage: 'Category',
        }),
        subCategory: intl.formatMessage({
          description: 'subCategory',
          defaultMessage: 'Subcategory',
        }),
        preferred: intl.formatMessage({
          description: 'preferred',
          defaultMessage: 'Preferred',
        }),
        status: intl.formatMessage({
          description: 'status',
          defaultMessage: 'Status',
        }),
        finalScore: intl.formatMessage({
          description: 'finalScore',
          defaultMessage: 'Final Score',
        }),
      },
    },
  };
}
