export const translation = {
  allotment: (intl) => ({
    page: {
      dateError: intl.formatMessage({
        description: 'filter search date error',
        defaultMessage: 'Check out date must come after check in',
      }),
      title: intl.formatMessage({
        description: 'front office allotment page title',
        defaultMessage: 'Allotment',
      }),
      back: intl.formatMessage({
        description: 'back',
        defaultMessage: 'back',
      }),
      filterNoResults: intl.formatMessage({
        description: 'filter with no results label',
        defaultMessage: 'No results found for this search.',
      }),
      address: intl.formatMessage({
        description: 'address label',
        defaultMessage: 'Address',
      }),
      description: intl.formatMessage({
        description: 'description label',
        defaultMessage: 'Description',
      }),
    },
    availability: {
      title: intl.formatMessage({
        description: 'availability title',
        defaultMessage: 'Availability',
      }),
      cancel: intl.formatMessage({
        description: 'cancel button label',
        defaultMessage: 'cancel',
      }),
      confirm: intl.formatMessage({
        description: 'confirm button label',
        defaultMessage: 'confirm',
      }),
      confirmError: intl.formatMessage({
        description: 'confirm but no data label',
        defaultMessage: 'Please select at least one room',
      }),
      roomsType: intl.formatMessage({
        description: 'availability rooms type label',
        defaultMessage: 'Room type',
      }),
      guestsMaxNumber: intl.formatMessage({
        description: 'availability max guests label',
        defaultMessage: 'Max n° of guests',
      }),
      roomsPrice: intl.formatMessage({
        description: 'availability rooms price label',
        defaultMessage: 'Price for all nights (€)',
      }),
      roomsNumber: intl.formatMessage({
        description: 'availability rooms number label',
        defaultMessage: 'Select n° of rooms',
      }),
    },
  }),
};
