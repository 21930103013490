import React from 'react';

import { AimTypography } from '@aim/components';

import BookingTable from './BookingTable';

const Bookings = ({
  bookingsCreditCard,
  bookingsBankTransfer,
  bookingsBothPayments,
  i18n,
  onClickBuy,
  onClickDelete,
}) => {
  return (
    <>
      {bookingsCreditCard?.length ? (
        <BookingTable
          title={i18n.bookingTable.titles.creditCard}
          bookings={bookingsCreditCard}
          i18n={i18n}
          onClickBuy={onClickBuy}
          onClickDelete={onClickDelete}
        />
      ) : null}
      {bookingsBankTransfer?.length ? (
        <BookingTable
          title={i18n.bookingTable.titles.bankTransfer}
          bookings={bookingsBankTransfer}
          i18n={i18n}
          onClickBuy={onClickBuy}
          onClickDelete={onClickDelete}
        />
      ) : null}
      {bookingsBothPayments?.length ? (
        <BookingTable
          title={i18n.bookingTable.titles.bothPayments}
          bookings={bookingsBothPayments}
          i18n={i18n}
          onClickBuy={onClickBuy}
          onClickDelete={onClickDelete}
        />
      ) : null}
      {!bookingsCreditCard?.length &&
      !bookingsBankTransfer?.length &&
      !bookingsBothPayments?.length ? (
        <div style={{ marginTop: 20 }}>
          <AimTypography>{i18n.page.messages.noBookings.label}</AimTypography>
        </div>
      ) : null}
    </>
  );
};

export default Bookings;
