import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import format from 'date-fns/format';
import { useHistory, useParams } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import ArrowBack from '@material-ui/icons/ArrowBack';
import PublishIcon from '@material-ui/icons/Publish';
import CloseIcon from '@material-ui/icons/Close';

import {
  styled,
  AimTypography,
  AimIconAndTextButton,
  AimSnackbar,
  AimDragDropFileUploader,
  AimAutocompleteForm,
  AimDialog,
  // AimRichText,
  CustomIntl,
  theme,
  AimSnackbarSeverity,
  richTextToHTML,
} from '@aim/components';

import { appState, fileHelper } from '@aim/common';

import {
  getAbstractDocument,
  updateAbstractDocument,
} from './shared/presentationGqlHelper';

import {
  listParticipations,
  createAbstractPresenter,
} from './shared/participationGqlHelper';

const extRegex = /(?:\.([^.]+))?$/;

const TextButton = styled(AimIconAndTextButton)({
  padding: 0,
  margin: 0,
  height: 'auto',
  color:
    appState.eventConfiguration.getValue().secondaryColor ||
    theme.colors.secondary.lightBlue,
  '& div': {
    margin: 0,
    padding: 0,
    fontWeight: 'bold',
  },
});

const GreyUglyPanel = styled('div')({
  background: '#ECEAF1',
  width: '100%',
  paddingLeft: 33,
  paddingRight: 33,
  paddingTop: 29,
  paddingBottom: 15,
  borderRadius: 3,
});

const WhiteUglyPanel = styled('div')({
  background: '#fff',
  width: '100%',
  paddingLeft: 33,
  paddingRight: 33,
  paddingTop: 29,
  paddingBottom: 15,
  borderRadius: 3,
});

// cannot style with styled somehow
const Line = ({ backgroundColor }) => (
  <Divider
    style={{
      backgroundColor,
      marginLeft: 5,
      marginRight: 5,
    }}
  />
);

const translation = (intl) => ({
  saveSuccess: intl.formatMessage({
    description: '',
    defaultMessage: 'File uploaded successfully',
  }),
  saveError: intl.formatMessage({
    description: '',
    defaultMessage: 'Error, file not saved',
  }),
  changePresenterSuccess: intl.formatMessage({
    description: 'Change presenter success snackbar message',
    defaultMessage: 'Presenter updated successfully',
  }),
  changePresenterError: intl.formatMessage({
    description: 'Change presenter error snackbar message',
    defaultMessage: 'Error: cannot change presenter',
  }),
  panelDetails: {
    status: intl.formatMessage({
      description: '',
      defaultMessage: 'Status',
    }),
    acceptedPresentationTypology: intl.formatMessage({
      description: '',
      defaultMessage: 'Accepted as',
    }),
    proposedPresentationTypology: intl.formatMessage({
      description: '',
      defaultMessage: 'Presentation method',
    }),
  },
  panelSubscribe: {
    subscribe1: intl.formatMessage({
      description: 'you have not registered yet',
      defaultMessage: 'You have not registered yet!',
    }),
    subscribe2: intl.formatMessage({
      description: 'click here to complete your registration',
      defaultMessage: 'Click here to complete your registration!',
    }),
  },
  panelWithdraw: {
    withdraw1: intl.formatMessage({
      description: `Can't participate`,
      defaultMessage: `Can't participate?`,
    }),
    withdraw2: intl.formatMessage({
      description: 'click here to indicate another presenter',
      defaultMessage: 'Click here to indicate another presenter',
    }),
  },
  panelInstructions: {
    title: intl.formatMessage({
      description: '',
      defaultMessage: `Presentation instructions`,
    }),
    descriptionMaterial: intl.formatMessage({
      escription: '',
      defaultMessage:
        'This is the code he prints to identify his exhibit material:',
    }),
    linkToSession: intl.formatMessage({
      escription: '',
      defaultMessage: 'Link to session:',
    }),
    btnUpload: intl.formatMessage({
      description: '',
      defaultMessage: 'Upload presentation',
    }),
  },
  panelExposure: {
    title: intl.formatMessage({
      description: '',
      defaultMessage: 'Exposure instructions',
    }),
    codeDescription: intl.formatMessage({
      description: '',
      defaultMessage:
        'Questo il codice stampa per identificare il suo materiale espositivo:',
    }),
  },
  cannotParticipate: intl.formatMessage({
    description: `Can't participate text`,
    defaultMessage: `Can't participate?`,
  }),
  clickHereTo: intl.formatMessage({
    description: 'click here to text',
    defaultMessage: 'Click here to',
  }),
  indicatePresenter: intl.formatMessage({
    description: 'indicate another text',
    defaultMessage: 'indicate another presenter',
  }),
  presenterOr: intl.formatMessage({
    description: 'Indicate another presenter or text',
    defaultMessage: 'or',
  }),
  withdrawAbstract: intl.formatMessage({
    description: 'Withdraw your abstract text',
    defaultMessage: 'withdraw your abstract',
  }),
  dialog: {
    presenter: {
      title: intl.formatMessage({
        description: 'Presenter dialog title',
        defaultMessage: 'Indicate a presenter',
      }),
      subtitle: intl.formatMessage({
        description: 'Presenter dialog subtitle',
        defaultMessage:
          'Enter the details of the presenter who will replace you',
      }),
    },
  },
  presentationDialog: {
    title: intl.formatMessage({
      description: 'presentationDialog title',
      defaultMessage: 'Upload presentation',
    }),
    buttons: {
      cancel: intl.formatMessage({
        description: 'cancel',
        defaultMessage: 'Cancel',
      }),
      send: intl.formatMessage({
        description: 'send',
        defaultMessage: 'Upload',
      }),
    },
  },
});

const PaxAbstractsPresentation = () => {
  const { eventId, abstractId } = useParams();
  const history = useHistory();
  const [abstractDocument, setAbstractDocument] = useState();
  const [participants, setParticipants] = useState([]);
  const [file, setFile] = useState();
  const [presenterDialog, setPresenterDialog] = useState({ isOpen: false });
  const [participationId, setParticipationId] = useState();
  const [isPresenter, setIsPresenter] = useState(false);
  const [presenterSwitchVisible, setPresenterSwitchVisible] = useState(true);
  const [participantSwitchVisible, setParticipantSwitchVisible] = useState(
    true
  );
  const [snackbar, setSnackbar] = useState({ isOpen: false });

  const { control, handleSubmit, register } = useForm({
    defaultValues: {
      presenter: null,
    },
  });

  const { downloadFromS3, createFile } = fileHelper;
  const s3Folder = `/events/${eventId}/abstracts/${abstractId}/`;
  const hasTicket = appState.user.getValue().userAndParticipation.participation
    .hasTicket;

  useEffect(() => {
    getAbstractDocument(abstractId).then((abstractData) => {
      if (participationId) {
        const nextPresenter =
          participationId === abstractData?.presenter?.participant?.id &&
          abstractData?.status === 'accepted';

        const speech = abstractData?.speeches.items?.[0];
        const session = speech?.subSession?.session;
        const nextAbstractData = {
          ...abstractData,
          session,
          speech,
          acceptedPresentationTypology: {
            ...abstractData.acceptedPresentationTypology,
            description: abstractData.acceptedPresentationTypology?.description
              ? JSON.parse(
                  abstractData.acceptedPresentationTypology.description
                )
              : '',
          },
        };
        setIsPresenter(nextPresenter);
        console.log('nextAbstractData', nextAbstractData);
        setAbstractDocument(nextAbstractData);
        setFile(abstractData.presentationFile);
      }
    });
  }, [eventId, abstractId, participationId]);

  useEffect(() => {
    fetchParticipations();
    getParticipationId();
  }, []);

  const intl = CustomIntl(useIntl());
  const i18n = translation(intl);

  // const handleFile = (event) => setFile(event.target.files[0]);

  const getParticipationId = async () => {
    setParticipationId(
      appState.user.getValue()?.userAndParticipation?.participation?.id
    );
  };

  const fetchParticipations = async () => {
    const participations = await listParticipations(eventId);
    setParticipants(
      participations.map((p) => ({
        value: p.id,
        label: `${p.givenName} ${p.familyName} <${p.email}>`,
      }))
    );
  };

  const saveNewPresenter = async (data) => {
    const participationId = data.presenter;

    const newPresenter = await createAbstractPresenter({
      abstractPresenterParticipantId: participationId,
    });
    const newPresenterId = newPresenter?.id;

    if (newPresenterId) {
      try {
        await updateAbstractDocument({
          id: abstractDocument.id,
          abstractDocumentPresenterId: newPresenterId,
        });
        setIsPresenter(false);
        setSnackbar({
          isOpen: true,
          severity: AimSnackbarSeverity.success,
          message: i18n.changePresenterSuccess,
        });
      } catch (err) {
        console.error(err);
        setSnackbar({
          isOpen: true,
          severity: AimSnackbarSeverity.error,
          message: i18n.changePresenterError,
        });
      }
    }
  };

  const handleSave = () => {
    if (!file) return;

    const extension = extRegex.exec(file.name)[0] || '';
    const fileData = {
      originalName: file.name,
      size: Number(file.size) || 0,
      extension,
    };

    createFile({
      dirPath: s3Folder,
      fileData,
      localFile: file,
      useLoader: false,
      isPublic: true,
    }).then((result) =>
      UpdateDocument({
        abstractDocumentPresentationFileId: result.id,
      })
    );
  };

  const UpdateDocument = (input) => {
    updateAbstractDocument({
      ...input,
      id: abstractId,
    })
      .then(() => history.push(`/events/${eventId}/presenter-abstracts`))
      .catch((e) => {
        console.error(e);
        setSnackbar({
          isOpen: true,
          message: i18n.saveError,
        });
      });
  };

  const downloadFile = async (tempfile) => {
    if (file.id) {
      await downloadFromS3({
        dirPath: s3Folder,
        fileData: file,
      });
    } else {
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(tempfile);
      link.download = tempfile.name;
      link.click();
      link.remove();
    }
  };

  // const TitleAndButton = () => (
  //   <AimTitleAndButtons title={abstractDocument.title}>
  //     <Tooltip title="Save">
  //       <AimIconButton variant="green" onClick={handleSave} disabled={!file}>
  //         <SaveIcon />
  //       </AimIconButton>
  //     </Tooltip>
  //   </AimTitleAndButtons>
  // );

  const formattedTime = (date) => {
    if (date) return format(new Date(date), 'HH:mm');
  };
  const formattedData = (date) => {
    if (date) return format(new Date(date), 'dd MMMM yyyy');
  };

  // const formattedInTimeZone = (date) => {
  //   return formatTz(date, "zzz '(UTC' x 'h)'", {
  //     timeZone: currentTimezone,
  //     locale: enGB,
  //   });
  // };

  return (
    <div
      style={{
        overflow: 'visible',
        flex: 1,
        maxWidth: 'calc(100vw - 120px)',
        paddingBottom: 80,
      }}
    >
      <AimIconAndTextButton
        isUpperCase
        variant="none"
        text={intl.formatMessage({
          description: 'back to abstract list',
          defaultMessage: 'Abstracts',
        })}
        style={{ padding: 0, marginLeft: 20 }}
        onClick={() => history.push(`/events/${eventId}/presenter-abstracts`)}
      >
        <ArrowBack />
      </AimIconAndTextButton>
      {/* {abstractDocument && <TitleAndButton />} */}
      {abstractDocument && (
        <AimTypography variant="h2" boxStyle={{ fontWeight: 'bold' }}>
          {abstractDocument.title}
        </AimTypography>
      )}
      <Grid container spacing={2}>
        {/* <Grid item xs={12}>
          <GreyUglyPanel>
            <Grid container>
              <Grid item xs={12} md={6}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <AimTypography variant="textBold">
                    {i18n.panelDetails.status}
                  </AimTypography>
                  <AimTypography variant="text">
                    {abstractDocument && abstractDocument.status}
                  </AimTypography>
                </div>
                <Line backgroundColor="#fff" />
              </Grid>
              <Grid item xs={12} md={6}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <AimTypography variant="textBold">
                    {i18n.panelDetails.proposedPresentationTypology}
                  </AimTypography>
                  <AimTypography variant="text">
                    {abstractDocument &&
                      abstractDocument.proposedPresentationTypology?.name}
                  </AimTypography>
                </div>
                <Line backgroundColor="#fff" />
              </Grid>
              <Grid item xs={12} md={6}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <AimTypography variant="textBold">
                    {i18n.panelDetails.acceptedPresentationTypology}
                  </AimTypography>
                  <AimTypography variant="text">
                    {abstractDocument &&
                      abstractDocument.acceptedPresentationTypology?.name}
                  </AimTypography>
                </div>
                <Line backgroundColor="#fff" />
              </Grid>
            </Grid>
          </GreyUglyPanel>
        </Grid> */}
        {!hasTicket && participantSwitchVisible && (
          <Grid
            container
            justify="space-between"
            alignItems="center"
            item
            xs={12}
            style={{
              backgroundColor: theme.colors.greyScale.grey2,
              margin: 8,
              padding: '10px 34px',
            }}
          >
            <div style={{ display: 'flex' }}>
              <AimTypography variant="text" boxStyle={{ marginRight: 5 }}>
                {i18n.panelSubscribe.subscribe1}
              </AimTypography>
              <TextButton
                variant="none"
                text={i18n.panelSubscribe.subscribe2}
                onClick={() =>
                  history.push(`/events/${eventId}/tickets/profile`)
                }
              />
            </div>
            <CloseIcon
              onClick={() => setParticipantSwitchVisible(false)}
              style={{ height: 20, cursor: 'pointer' }}
            />
          </Grid>
        )}
        {isPresenter && presenterSwitchVisible && (
          <Grid
            container
            justify="space-between"
            alignItems="center"
            item
            xs={12}
            style={{
              backgroundColor: theme.colors.greyScale.grey2,
              margin: 8,
              padding: '10px 34px',
            }}
          >
            <div style={{ display: 'flex' }}>
              <AimTypography variant="text" boxStyle={{ marginRight: 5 }}>
                {i18n.cannotParticipate} {i18n.clickHereTo}
              </AimTypography>
              <TextButton
                variant="none"
                text={i18n.indicatePresenter}
                onClick={() => setPresenterDialog({ isOpen: true })}
              />
              {/* <span
                style={{
                  marginLeft: 6,
                }}
              >
                {i18n.presenterOr}
              </span> */}
              {/* <span
                onClick={handleWithdraw}
                style={{
                  marginLeft: 6,
                  fontWeight: 'bold',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
              >
                {i18n.withdrawAbstract}
              </span> */}
            </div>

            <CloseIcon
              onClick={() => setPresenterSwitchVisible(false)}
              style={{ height: 20, cursor: 'pointer' }}
            />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          style={{
            backgroundColor: theme.colors.greyScale.backgroundGrey,
            // padding: 10,
            margin: 8,
          }}
        >
          <WhiteUglyPanel>
            <AimTypography variant="h6">
              {i18n.panelInstructions.title}
            </AimTypography>
            <Line />
            <AimTypography variant="text">
              {i18n.panelInstructions.description1}
            </AimTypography>
            {/* <AimTypography variant="text">
              {abstractDocument?.acceptedPresentationTypology.name}
            </AimTypography> */}
            {console.log(
              'abstractDocument?.acceptedPresentationTypology?.description',
              abstractDocument?.acceptedPresentationTypology?.description
            )}
            {abstractDocument?.acceptedPresentationTypology?.description ? (
              <div
                className="content"
                dangerouslySetInnerHTML={{
                  __html: richTextToHTML(
                    abstractDocument?.acceptedPresentationTypology?.description
                  ),
                }}
              ></div>
            ) : null}
            {/* <AimTypography variant="text">
              {i18n.panelInstructions.subDescription1}
            </AimTypography>
            <AimTypography variant="text" style={{ marginTop: 20 }}>
              {i18n.panelInstructions.description2}
            </AimTypography>
            <AimTypography variant="text">
              {i18n.panelInstructions.subDescription2}
            </AimTypography>
            <AimTypography variant="text">
              {i18n.panelInstructions.subDescription3}
            </AimTypography> */}

            {/* code to print */}
            {/* <AimTypography variant="text">
              {i18n.panelInstructions.descriptionMaterial}
            </AimTypography>
            <GreyUglyPanel
              style={{
                paddingTop: 15,
                paddingRight: 15,
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              <AimTypography variant="h6">P1. S2. 18</AimTypography>
            </GreyUglyPanel> */}
            {abstractDocument?.session && (
              <div style={{ display: 'flex' }}>
                <AimTypography variant="text">
                  {i18n.panelInstructions.linkToSession}
                </AimTypography>
                {/* session name / room / start - end */}
                {/* dc1b805c-a4a4-4d3b-ba52-bbb8b58e9a62/agenda/sessions/e6a48a06-284a-47a5-b8aa-a001f09dd4f3 */}
                <TextButton
                  variant="none"
                  text={`${abstractDocument?.session?.name} ${formattedData(
                    abstractDocument.session.start
                  )}, ${formattedTime(
                    abstractDocument.session.start
                  )} - ${formattedTime(abstractDocument.session.end)} ${
                    abstractDocument.session.room.name
                  }`}
                  //   <AimTypography variant="text" margin="0">
                  //     {`${formattedData(
                  //       session.start
                  //     )}, ${formattedTime(
                  //       session.start
                  //     )} - ${formattedTime(session.end)} - `}
                  //   </AimTypography>
                  // </>
                  // <AimTypography
                  //   variant="textBold"
                  //   margin="0"
                  //   style={{ marginLeft: isMobile ? 0 : 5 }}
                  // >
                  //   {`${session.room}`}
                  // </AimTypography>
                  onClick={() =>
                    history.push(
                      `/events/${eventId}/agenda/sessions/${abstractDocument.session.id}`
                    )
                  }
                />
              </div>
            )}
          </WhiteUglyPanel>
        </Grid>
        <Grid item xs={12}>
          <AimDragDropFileUploader
            dragTitle={i18n.panelInstructions.btnUpload}
            onLoad={() => console.log('onload')}
            onUploadFile={([f]) => setFile(f)}
            onDownload={(file) => downloadFile(file)}
            onRemove={() => setFile(null)}
            files={file ? [file] : []}
          />
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <AimIconAndTextButton
              variant="primary"
              text={i18n.panelInstructions.btnUpload}
              onClick={handleSave}
              disabled={!file}
            >
              <PublishIcon />
            </AimIconAndTextButton>
          </div>
        </Grid>
        {/* <Grid item xs={12}>
          <WhiteUglyPanel>
            <AimTypography variant="h6">
              {i18n.panelExposure.title}
            </AimTypography>
            <Line />
            <AimTypography variant="text">
              {i18n.panelExposure.codeDescription}
            </AimTypography>
            <GreyUglyPanel
              style={{
                paddingTop: 15,
                paddingRight: 15,
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              <AimTypography variant="h6">
                H3. P7. 9U
              </AimTypography>
            </GreyUglyPanel>
          </WhiteUglyPanel>
        </Grid> */}
        {/* <Grid item xs={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              paddingRight: 20,
            }}
          >
            <AimIconAndTextButton
              variant="greenFill"
              text="Save"
              onClick={handleSave}
              disabled={!file}
            >
              <SaveIcon />
            </AimIconAndTextButton>
          </div>
        </Grid> */}
      </Grid>
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>

      <AimDialog
        open={presenterDialog.isOpen}
        title={i18n.dialog.presenter.title}
        onAgree={handleSubmit(saveNewPresenter)}
        onClose={() => setPresenterDialog({ isOpen: false })}
      >
        <AimTypography variant="h5Regular">
          {i18n.dialog.presenter.subtitle}
        </AimTypography>

        <AimAutocompleteForm
          register={register}
          variant="white"
          control={control}
          placeholder={'write name, surname or email'}
          name="presenter"
          isRequired
          options={participants}
          getOptionLabel={(option) => option?.label || ''}
          getOptionSelected={(opt, value) => opt.value === value}
          formControlStyle={{ width: 'calc(100% - 16px)' }}
          autoSelect={false}
        ></AimAutocompleteForm>
      </AimDialog>
    </div>
  );
};

export default PaxAbstractsPresentation;
