import React, { useState, useEffect } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import useI18n from './../shared/translation';

import { addDays } from 'date-fns';

import { aws, appState, constants } from '@aim/common';

import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';

import {
  MainContainer,
  InnerContainer,
} from './../shared/SponsorPagesContainers';

import {
  AimIconAndTextButton,
  AimTypography,
  AimRichText,
  AimFileList,
  CustomIntl,
  AimIconButton,
  AimSnackbar,
  AimSnackbarSeverity,
} from '@aim/components';

import { usePublicPageService } from './../shared/publicPageServiceGqlHelper';
import { usePublicPage } from './../shared/publicPageGqlHelper';
import { useBuyOperations } from './../shared/buyOperationsGqlHelper';
import { useSponsors } from '../shared/sponsorGqlHelper';

const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

const PublicPage = () => {
  //Hooks
  const history = useHistory();
  const { eventId, sponsorId, service } = useParams();
  const intl = CustomIntl(useIntl());
  const i18n = useI18n.sponsorPublicPageService(intl);
  const publicPageServiceHelper = usePublicPageService();
  const publicPageHelper = usePublicPage();
  const buyOperationsHelper = useBuyOperations();
  const { get: getSponsor, update: updateSponsor } = useSponsors();

  //State
  const [sponsorData, setSponsorData] = useState();
  const [publicPageService, setPublicPageService] = useState();
  const [participation, setParticipation] = useState();
  const [bookingDuration, setBookingDuration] = useState();
  const [paymentType, setPaymentType] = useState();
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [lastUpdate, setLastUpdate] = useState(new Date());

  const mandatoryDocs = publicPageService?.documents.items?.filter(
    (d) => d.isMandatory
  );
  const hasReadMandatoryDocs =
    !mandatoryDocs?.length ||
    mandatoryDocs?.every(
      (md) => !!sponsorData?.requiredDocumentsIds.find((rd) => rd === md.id)
    );

  // Effects
  useEffect(() => {
    getPublicPageService();
    getUserParticipation();
    fetchSponsor();
  }, [lastUpdate]);

  const fetchSponsor = async () => {
    const userAndParticipation = appState.user.getValue()?.userAndParticipation;
    const sponsor =
      userAndParticipation?.participation?.sponsor ||
      userAndParticipation?.participation?.sponsorStaff?.sponsor;

    const sponsorData = await getSponsor(sponsor.id);
    setSponsorData(sponsorData);
  };

  // Functions
  const getPublicPageService = () => {
    publicPageServiceHelper.get(eventId).then((res) => {
      console.log('publicPageService', res.publicPageService);
      setPublicPageService(res.publicPageService);
      setBookingDuration(res.sponsorServiceBookingDuration || 10);

      const paymentType =
        res.publicPageService.isCreditCardEnabled &&
        res.publicPageService.isBankTransferEnabled
          ? 'both'
          : (res.publicPageService.isCreditCardEnabled &&
              constants.PaymentTypes.CreditCard) ||
            (res.publicPageService.isBankTransferEnabled &&
              constants.PaymentTypes.BankTransfer);
      setPaymentType(paymentType);
    });
  };

  const getUserParticipation = async () => {
    try {
      const nextParticipation = appState.user.getValue()?.userAndParticipation
        ?.participation;
      setParticipation(nextParticipation);
    } catch (err) {
      console.error(err);
    }
  };

  // Helpers
  const onDownload = async (fileData) => {
    const documentId = fileData.id;
    if (
      fileData.isMandatory &&
      !sponsorData?.requiredDocumentsIds.includes(documentId)
    ) {
      const nextRequiredDocumentsIds = [
        ...sponsorData?.requiredDocumentsIds,
        documentId,
      ];
      await updateSponsor({
        id: sponsorData.id,
        requiredDocumentsIds: nextRequiredDocumentsIds,
      });

      fetchSponsor();
    }
  };

  const downloadFile = async (item) => {
    const s3Folder = `events/${eventId}/sponsor/${service}/documents/`;
    const link = `${s3Folder}${item.id}${item.extension}`;

    var res = await aws.Storage.get(link);
    window.open(res);
    onDownload(item);
  };

  const onAddToCart = async (purchaseNow = false) => {
    if (!hasReadMandatoryDocs)
      return setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.snackbar.missingDownloadsError,
      });
    showLoader();
    try {
      const publicPageCreateResponse = await publicPageHelper.create(
        {
          publicPagePublicPageServiceId: publicPageService.id,
          publicPageSponsorId: sponsorId,
        },
        false
      );

      const bookingDate = new Date();
      const bookingExpiringDate = addDays(bookingDate, bookingDuration);
      const inputBuyOperation = {
        eventId: eventId,
        buyOperationPublicPageId: publicPageCreateResponse.id,
        buyOperationSponsorId: sponsorId,
        date: bookingDate,
        expiringDate: bookingExpiringDate,
        paymentType: paymentType,
        isExpired: 'false',
        isDeleted: 'false',
      };

      const response = await buyOperationsHelper.create(
        inputBuyOperation,
        false
      );

      await publicPageHelper.update(
        {
          id: publicPageCreateResponse.id,
          publicPageBuyOperationId: response.id,
        },
        false
      );

      const destUrl = `/events/${eventId}/${sponsorId}/cart`;
      if (purchaseNow) {
        history.push({
          pathname: destUrl,
          state: {
            bookings: [response],
          },
        });
      } else {
        setLastUpdate(new Date());
      }
    } catch (e) {
      console.error(e);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.snackbar.addToCartFail.label,
      });
    } finally {
      hideLoader();
    }
  };

  const disablePurchase = () => {
    console.log(
      'sponsorData',
      sponsorData,
      publicPageService.publicPages.items
    );
    return (
      (sponsorData?.publicPage &&
        sponsorData?.publicPage?.buyOperation &&
        sponsorData?.publicPage?.buyOperation?.isExpired !== 'true' &&
        sponsorData?.publicPage?.buyOperation?.isDeleted !== 'true') ||
      publicPageService.publicPages.items.find(
        (p) =>
          p?.buyOperation?.sponsor?.id === sponsorId &&
          p?.buyOperation?.isDeleted !== 'true' &&
          p?.buyOperation?.isExpired !== 'true'
      )
    );
  };

  return (
    <MainContainer>
      <InnerContainer grow bottomSpacing>
        <div style={{ display: 'flex-root', marginTop: 20 }}>
          <Tooltip title={i18n.page.back.tooltip.label}>
            <AimIconAndTextButton
              variant="text"
              text={i18n.page.back.button.label}
              onClick={() =>
                history.push(`/events/${eventId}/${sponsorId}/my-services`)
              }
            >
              <ArrowBack />
            </AimIconAndTextButton>
          </Tooltip>
        </div>
        <Grid container>
          <Grid item xs={12}>
            <AimTypography variant={'h1'}>
              {i18n.page.title.label}
            </AimTypography>
          </Grid>
          <Grid item xs={12}>
            {publicPageService?.description && (
              <AimRichText
                label={i18n.page.instructions.label}
                value={JSON.parse(publicPageService.description)}
                readOnly
                editorStyleOverride={{
                  minHeight: 'auto',
                  maxHeight: 'fit-content',
                  overflow: 'hidden !important',
                }}
              />
            )}
          </Grid>
        </Grid>
        {publicPageService?.documents?.items?.length ? (
          <Grid container spacing={2} style={{ marginTop: 20 }}>
            <Grid
              item
              xs={12}
              style={{
                backgroundColor: 'white',
              }}
            >
              <AimFileList
                files={publicPageService.documents.items}
                onDownload={downloadFile}
              />
            </Grid>
          </Grid>
        ) : null}
        {publicPageService ? (
          <Grid
            container
            spacing={2}
            style={{ marginTop: 20, backgroundColor: 'white' }}
          >
            {/* Headers */}
            <Grid item xs={3}>
              <AimTypography variant="columnHeader">
                {i18n.columns.name.label}
              </AimTypography>
            </Grid>
            <Grid item xs={2}>
              <AimTypography variant="columnHeader">
                {i18n.columns.netPrice.label}
              </AimTypography>
            </Grid>
            <Grid item xs={2}>
              <AimTypography variant="columnHeader">
                {i18n.columns.vatRate.label}
              </AimTypography>
            </Grid>
            <Grid item xs={2}>
              <AimTypography variant="columnHeader">
                {i18n.columns.taxedPrice.label}
              </AimTypography>
            </Grid>
            <Grid item xs={3}>
              {/* Actions column */}
            </Grid>
            {/* Columns */}
            <Grid item xs={3}>
              <AimTypography variant="text">
                {i18n.columns.name.message}
              </AimTypography>
            </Grid>
            <Grid item xs={2}>
              <AimTypography variant="text">
                {`${(publicPageService.netPrice ?? 0) / 100} €`}
              </AimTypography>
            </Grid>
            <Grid item xs={2}>
              <AimTypography variant="text">
                {`${(publicPageService.vatRate ?? 100) / 100} %`}
              </AimTypography>
            </Grid>
            <Grid item xs={2}>
              <AimTypography variant="text">
                {`${(
                  (publicPageService.netPrice ?? 0) / 100 +
                  (((publicPageService.netPrice ?? 0) / 100) *
                    ((publicPageService.vatRate ?? 100) / 100)) /
                    100
                ).toFixed(2)} €`}
              </AimTypography>
            </Grid>
            <Grid item xs={2} style={{ textAlign: 'right' }}>
              <AimIconButton
                variant="yellowFill"
                small
                onClick={() => onAddToCart(true)}
                disabled={disablePurchase()}
              >
                <SwapHorizIcon />
              </AimIconButton>
              <AimIconButton
                variant="violetFill"
                small
                onClick={() => onAddToCart()}
                disabled={disablePurchase()}
              >
                <ShoppingCartIcon />
              </AimIconButton>
            </Grid>
            <Grid item xs={1}>
              {/* empty space */}
            </Grid>
          </Grid>
        ) : null}
      </InnerContainer>

      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </MainContainer>
  );
};

export default PublicPage;
