import React, { useEffect, useState } from 'react';
import {
  AimDialog,
  AimTypography,
  AimIconAndTextButton,
  AimDragDropFileUploader,
} from '@aim/components';
import CheckIcon from '@material-ui/icons/Check';
import ReplayIcon from '@material-ui/icons/Replay';

import { fileHelper, constants } from '@aim/common';
import Grid from '@material-ui/core/Grid';
import { updateParticipationServices } from '../facultyGqlHelper';

const ScientificCommitmentDialog = ({
  dialogState,
  onClose,
  onSuccess,
  onError,
  i18n,
}) => {
  const _i18n = i18n.dialogs.scientificCommitment;
  const { downloadFromS3, createFile, deleteFile } = fileHelper;

  const [files, setFiles] = useState({
    paxReport: [],
    paxPresentation: [],
  });

  const [dataToDelete, setDataToDelete] = useState({
    paxReport: [],
    paxPresentation: [],
  });
  const formControlStyle = { width: 'calc(100% - 20px)' };

  // Effects
  useEffect(() => {
    if (!dialogState.isOpen) return;
    if (dialogState) {
      // const raw = dialogState?.data?.scientificCommitmentFacultyResponse;
      const raw = dialogState?.data?.participationFacultyResponse;
      let facultyResponse;
      if (raw) facultyResponse = JSON.parse(raw);
      const filesKey = ['paxReport', 'paxPresentation'];

      if (facultyResponse && Object.keys(facultyResponse)?.length > 0) {
        const foundFiles = Object.keys(facultyResponse)
          .filter((key) => filesKey.includes(key))
          .reduce((obj, key) => {
            obj[key] = facultyResponse[key];
            return obj;
          }, {});
        setFiles(foundFiles);
      }
    }
  }, [dialogState]);

  // Helpers
  const uploadFilesToS3 = async (f, key) => {
    const originalName = f?.name || new Date().getTime().toString();

    const matchExtension = originalName.match(/\.[0-9a-z]+$/i);
    const extension = matchExtension ? matchExtension[0] : '';

    const fileSize = f?.size;

    const input = {
      originalName: originalName,
      extension: extension,
      size: Number(fileSize) || 0,
    };
    let id = await createFile({
      dirPath: `events/${dialogState?.eventId}/faculty/scientificCommitment/${key}`,
      fileData: input,
      localFile: f,
    });
    return {
      ...input,
      name: originalName,
      id,
      key,
    };
  };

  const deleteFilesFromS3 = async () => {
    Object.entries(dataToDelete).forEach(([key, value]) => {
      const dirPath = `events/${dialogState?.eventId}/faculty/scientificCommitment/${key}`;
      value.forEach(
        async (f) => await deleteFile({ dirPath, fileData: f }, false)
      );
    });
  };

  const onSubmit = async () => {
    const prepareData = Object.entries(files)
      .map(([key, value]) => {
        if (value) {
          let files = value?.map(async (f) => await uploadFilesToS3(f, key));
          return files;
        }
      })
      .flat();
    let finalResult = await Promise.all(prepareData);
    let dataToSend = {};
    Object.keys(files).forEach((k) => {
      dataToSend[k] = finalResult
        .filter((i) => i.key === k)
        .map((i) => {
          let remapped = { ...i };
          delete remapped.key;
          return remapped;
        });
    });
    // participationFacultyResponse contains data of SCIENTIFIC COMMITMENT and PARTICIPATION, in backOffice are in the same page
    const prevData =
      JSON.parse(dialogState?.data?.participationFacultyResponse) || '';
    if (Object.keys(dataToSend)?.length > 0)
      dataToSend = {
        ...prevData,
        ...dataToSend,
        responseStatus: constants.FacultyResponseStatus.PUBLISHED,
      };
    let participationFacultyResponse = JSON.stringify(dataToSend);

    try {
      await deleteFilesFromS3();
      await updateParticipationServices(
        {
          id: dialogState?.data.id,
          // participationReportUpload: paxReportUpload,
          // participationPresentationUpload: paxPresentationUpload,
          participationFacultyResponse,
        },
        true
      );
      onSuccess();
    } catch (err) {
      console.error('err', err);
      onError();
    }
  };

  const uploadFiles = async (f, key) => {
    const file = f[0];
    setFiles({ ...files, [key]: [...files[key], file] });
  };

  const removeFile = async (f, key) => {
    let fileToDelete = files[key].filter((i) => i === f);
    setDataToDelete({
      ...dataToDelete,
      [key]: [...dataToDelete[key], ...fileToDelete],
    });
    const newfile = files[key].filter((i) => i !== f);
    setFiles({ ...files, [key]: [...newfile] });
  };

  const downloadFile = async (item, key) => {
    if (item.id) {
      await downloadFromS3({
        dirPath: `events/${dialogState?.eventId}/faculty/scientificCommitment/${key}`,
        fileData: item,
      });
    } else {
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(item);
      link.download = item.name;
      link.click();
      link.remove();
    }
  };

  const renderButtons = () => {
    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '20px auto -44px',
            ...formControlStyle,
          }}
        >
          <div>
            <AimIconAndTextButton
              variant={'greyFill'}
              text={_i18n.buttons.cancel}
              onClick={onClose}
            >
              <ReplayIcon />
            </AimIconAndTextButton>
          </div>
          <div>
            <AimIconAndTextButton
              variant={'greenFill'}
              text={_i18n.buttons.saveAndSend}
              onClick={() => {
                onSubmit();
                onClose();
              }}
            >
              <CheckIcon />
            </AimIconAndTextButton>
          </div>
        </div>
      </>
    );
  };

  if (!dialogState) return null;

  return (
    <AimDialog
      open={dialogState.isOpen || false}
      title={_i18n.title}
      onClose={onClose}
      disableEnforceFocus
      hideAgreeButton={true}
      hideCancelButton={true}
      style={{
        width: '85%',
        minWidth: '300px',
        maxWidth: '100%',
        margin: '0 auto',
      }}
      PaperProps={{
        style: {
          paddingRight: 30,
          paddingLeft: 30,
          paddingTop: 20,
          paddingBottom: 30,
          maxWidth: '100%',
          minWidth: 600,
        },
      }}
    >
      <Grid container>
        {dialogState?.data?.participationReportUpload && (
          <Grid item xs={12}>
            <AimTypography variant="h4">
              {_i18n.sectionTitles.report}
            </AimTypography>
            <div style={{ backgroundColor: 'white', margin: 5 }}>
              <AimDragDropFileUploader
                key="paxr"
                dragTitle={_i18n.dragAndDrop.title}
                dragSubTitle={_i18n.dragAndDrop.subTitle}
                clickText={_i18n.dragAndDrop.clickText}
                onUploadFile={(files) => uploadFiles(files, 'paxReport')}
                onRemove={(file) => removeFile(file, 'paxReport')}
                onDownload={(file) => downloadFile(file, 'paxReport')}
                files={files.paxReport}
              />
            </div>
          </Grid>
        )}

        {dialogState?.data?.participationPresentationUpload && (
          <Grid item xs={12}>
            <AimTypography variant="h4">
              {_i18n.sectionTitles.presentation}
            </AimTypography>
            <div style={{ backgroundColor: 'white', margin: 5 }}>
              <AimDragDropFileUploader
                key="paxp"
                dragTitle={_i18n.dragAndDrop.title}
                dragSubTitle={_i18n.dragAndDrop.subTitle}
                clickText={_i18n.dragAndDrop.clickText}
                onUploadFile={(files) => uploadFiles(files, 'paxPresentation')}
                onRemove={(file) => removeFile(file, 'paxPresentation')}
                onDownload={(file) => downloadFile(file, 'paxPresentation')}
                files={files.paxPresentation}
              />
            </div>
          </Grid>
        )}
      </Grid>
      {renderButtons()}
    </AimDialog>
  );
};

export default ScientificCommitmentDialog;
