import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { format } from 'date-fns';
import { useParams, useHistory } from 'react-router-dom';

import { useAgenda } from './shared/agendaGqlHelper';

import Tooltip from '@material-ui/core/Tooltip';
import ArrowBack from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import PinIcon from '@material-ui/icons/BookmarkBorder';
import PlayIcon from '@material-ui/icons/PlayArrow';

import {
  AimIconAndTextButton,
  CustomIntl,
  AimSnackbar,
  AimSnackbarSeverity,
  AgendaSessionTemplate,
  AimTypography,
  AbstractDetailPage,
  styled,
} from '@aim/components';

import { fileHelper } from '@aim/common';

import useI18n from './agendaEventDetail/i18n';
import { useFavouriteAgendaSession } from './shared/favouriteAgendaSessionGqlHelper';

const StyledRow = styled('div')({
  display: 'flex',
  flexWrap: 'nowrap',
  flex: 1,
  marginTop: 5,
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
});

const StyledLabel = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const StyledValue = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  alignItems: 'flex-start',
  flexWrap: 'wrap',
  textAlign: 'left',
  marginLeft: 2,
});

const PosterDetail = () => {
  const intl = CustomIntl(useIntl());
  const i18n = useI18n(intl);
  const { getS3Link } = fileHelper;
  const { sessionId, posterId, eventId } = useParams();
  const { getAgendaPosterSession, parseSession } = useAgenda();
  const [agendaSession, setAgendaSession] = useState(null);
  const [poster, setPoster] = useState();

  const [snackbar, setSnackbar] = useState({ isOpen: false });

  // const favouriteAgenda = useFavouriteAgendaSession();
  const history = useHistory();

  const getAttachmentURL = async (path, fileData) =>
    await getS3Link(path, fileData);

  const formattedData = (date) => {
    if (date) return format(new Date(date), 'dd MMMM yyyy');
  };

  const formattedTime = (date) => {
    if (date) return format(new Date(date), 'HH:mm');
  };

  const fetchAgendaSession = async () => {
    const fetchedAgendaPosterSession = await getAgendaPosterSession(sessionId);
    const fetchedPoster = fetchedAgendaPosterSession?.posters?.items?.find(
      (p) => p.id === posterId
    );

    const values = await parseSession(fetchedAgendaPosterSession, eventId);
    setAgendaSession(values);
    setPoster(fetchedPoster);
  };

  useEffect(() => {
    if (!agendaSession) {
      fetchAgendaSession();
    }
  }, [agendaSession]);

  const handleClick = async (promiseArray) => {
    const res = await Promise.all(promiseArray);
    window.open(res[0], '_blank');
  };

  const Attachments = () => {
    if (!poster.abstract?.attachments?.items.length) return null;

    const attachments = poster.abstract?.attachments?.items;

    return attachments.map((att) => {
      switch (att.extension) {
        case 'jpg':
        case 'jpeg':
        case 'png':
          return (
            <img
              src={getAttachmentURL(
                `events/${eventId}/abstracts/${poster.abstract.id}/${att.id}${att.extension}`,
                att
              )}
            />
          );
          break;
        default:
          return <span></span>;
      }
    });
  };

  if (!agendaSession || !poster) return null;

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          overflowY: 'auto',
          width: 'calc(100vw - 20px)',
        }}
      >
        <div
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            backgroundColor: '#FFF',
            paddingBottom: '20px',
          }}
        >
          <div style={{ maxWidth: '80vw', margin: '0 auto' }}>
            <div
              style={{
                width: '100%',
              }}
            >
              <Tooltip title={i18n.page.backButton.tooltip}>
                <AimIconAndTextButton
                  variant="text"
                  style={{ padding: 0 }}
                  text={i18n.page.backButton.posterDetailLabel}
                  onClick={() =>
                    history.replace(
                      `/events/${eventId}/agenda/poster-session/${sessionId}`
                    )
                  }
                >
                  <ArrowBack />
                </AimIconAndTextButton>
              </Tooltip>
              <div>
                <StyledRow>
                  <StyledLabel>
                    <AimTypography
                      variant="textBold"
                      margin="0"
                      style={{
                        fontWeight: '200',
                        color: 'orange',
                      }}
                    >
                      {`${'Poster Session'} ${agendaSession.initial}`}
                    </AimTypography>
                  </StyledLabel>
                </StyledRow>
                <StyledRow>
                  <StyledLabel>
                    <AimTypography
                      variant="pageTitle"
                      margin="0"
                      style={{ fontWeight: '200' }}
                    >
                      {agendaSession.name || agendaSession.title}
                    </AimTypography>
                  </StyledLabel>
                </StyledRow>
                {agendaSession.symposium?.buyOperation && (
                  <StyledRow>
                    <StyledLabel>
                      <AimTypography variant="text" margin="0">
                        {`${i18n.dialogs.previewDialog.sponsoredBy} ${agendaSession.symposium.buyOperation.sponsor.name}`}
                      </AimTypography>
                    </StyledLabel>
                  </StyledRow>
                )}
                <StyledRow>
                  <StyledValue>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <>
                        {agendaSession.sessionSlots.items
                          .sort((a, b) => new Date(a.date) - new Date(b.date))
                          .map((slot, idx) => (
                            <>
                              <AimTypography
                                margin="0"
                                variant="textBold"
                                style={{ marginRight: 5 }}
                              >
                                Day {idx + 1}
                                {' - '}
                              </AimTypography>
                              <AimTypography
                                variant="text"
                                margin="0"
                                style={{ marginRight: 5 }}
                              >
                                {`${formattedData(slot.date)}, ${formattedTime(
                                  slot.start
                                )} - ${formattedTime(slot.end)} -`}
                              </AimTypography>
                            </>
                          ))}
                      </>
                      <AimTypography
                        variant="textBold"
                        style={{ marginLeft: -3 }}
                      >
                        {`${agendaSession.room}`}
                      </AimTypography>
                    </div>
                  </StyledValue>
                </StyledRow>
                <AbstractDetailPage
                  intl={intl}
                  abstractDocument={poster.abstract}
                  maxHeight={'unset'}
                  hideAbstractInfo
                  onDownloadAttachments={(fileData) =>
                    handleClick([
                      getAttachmentURL(
                        `events/${eventId}/abstracts/${poster.abstract.id}/`,
                        {
                          id: fileData.id + fileData.extension,
                          extension: '',
                          originalName:
                            poster.abstract.title.replace(/\s+/g, '_') +
                            fileData.extension,
                        }
                      ),
                    ])
                  }
                />

                <Attachments />
              </div>
            </div>
          </div>
        </div>

        <AimSnackbar
          open={snackbar.isOpen}
          onClose={() => setSnackbar({ isOpen: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </AimSnackbar>
      </div>
    </>
  );
};

export default PosterDetail;
