import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useFieldArray, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { AimIconAndTextButton, CustomIntl } from '@aim/components';
import { useProfiles } from './agency/fee/profileGqlHelper';
import ProfileFeeCard from './agency/fee/ProfileFeeCard';
import { translation } from './agency/fee/translation';
import { useFeeBrackets } from './agency/fee/feeBracketsGqlHelper';

import { MainContainer, InnerContainer } from './agency/shared/Containers';

const AgencyFeeList = () => {
  const { eventId, agencyId } = useParams();
  const history = useHistory();

  //Hooks
  const intl = CustomIntl(useIntl());
  const i18n = translation.fee(intl);
  const profileHelper = useProfiles();
  const feeBracketsHelper = useFeeBrackets();

  //State

  const { control, reset } = useForm({
    defaultValues: {
      profiles: [],
    },
  });

  const { fields: profiles } = useFieldArray({
    control,
    name: 'profiles',
    keyName: '_id',
  });

  useEffect(() => {
    fetchProfiles();
  }, []);

  //Functions
  const fetchProfiles = async () => {
    if (eventId && agencyId) {
      const res = await profileHelper.listByClusters(agencyId, eventId);
      const { fee } = await feeBracketsHelper.list(eventId);
      if (res) {
        reset({
          profiles: res
            .map((p) => ({
              ...p,
              dateRanges: fee?.feeDateRanges.items.map((dateRange) => ({
                ...dateRange,
                isActive: p.profileFeeBrackets.items.find(
                  (pfb) =>
                    pfb.feeBracket.feeDateRange.id === dateRange.id &&
                    pfb.profileFeeConfiguration?.isActive
                ),
                feeBrackets: dateRange.feeBrackets.items.map((feeBracket) => ({
                  ...p.profileFeeBrackets.items.find(
                    (pfb) => feeBracket.id === pfb.feeBracket.id
                  ),
                  start: feeBracket.feeBracketModel.start,
                  end: feeBracket.feeBracketModel.end,
                  name: feeBracket.feeBracketModel.name,
                })),
              })),
            }))
            .sort((a, b) => {
              if (a.cluster < b.cluster) {
                return -1;
              }
              if (a.cluster > b.cluster) {
                return 1;
              }
              return 0;
            }),
        });
      }
    }
  };

  return (
    <MainContainer>
      <div>
        <InnerContainer>
          <div>
            <Tooltip title={i18n.page.back.tooltip}>
              <AimIconAndTextButton
                style={{
                  padding: 0,
                }}
                variant="text"
                text={i18n.page.back.button}
                onClick={() =>
                  history.push(`/events/${eventId}/agency/${agencyId}`)
                }
              >
                <ArrowBack />
              </AimIconAndTextButton>
            </Tooltip>
          </div>
          {profiles.map((profile, idx) => (
            <ProfileFeeCard
              fieldArrayPrefix={`profiles[${idx}].`}
              key={profile.id}
              i18n={i18n}
              profile={profile}
              isReadOnly={true}
              control={control}
            ></ProfileFeeCard>
          ))}
        </InnerContainer>
      </div>
    </MainContainer>
  );
};

export default AgencyFeeList;
