import React, { useEffect } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import {
  AimDialog,
  AimTextFieldForm,
  AimTypography,
  AimIconAndTextButton,
  styled,
} from '@aim/components';
import CheckIcon from '@material-ui/icons/Check';
import ReplayIcon from '@material-ui/icons/Replay';

import { constants } from '@aim/common';
import Grid from '@material-ui/core/Grid';

import ServiceCard from './ServiceCard';
import { updateParticipationServices } from '../facultyGqlHelper';

const TextArea = styled(AimTextFieldForm)({
  height: 'unset',
  fontSize: '0.875rem !mportant',
  lineHeight: '1rem !important',
});

const defaultValues = {
  speakerNotes: '',
};

const ButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  margin: 10,
});

const AdditionalServicesDialog = ({
  dialogState,
  onClose,
  onSuccess,
  onError,
  onConflictingEvents,
  i18n,
}) => {
  const _i18n = i18n.dialogs.additionalServices;

  const { control, handleSubmit, errors, reset } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    shouldUnregister: false,
    defaultValues,
  });

  const { fields } = useFieldArray({
    control,
    name: 'additionalServiceOptions',
  });

  const formControlStyle = { width: 'calc(100% - 20px)' };

  // Effects
  useEffect(() => {
    if (dialogState) {
      const rawFacultyResponse =
        dialogState?.data?.participationServices
          ?.additionalServicesFacultyResponse;

      let facultyResponse;
      if (rawFacultyResponse) facultyResponse = JSON.parse(rawFacultyResponse);

      const availableServices = dialogState?.data?.availableAdditionalServices;

      const selectedAdditionalServices =
        facultyResponse?.selectedServices || availableServices;

      const formattedAdditionalServices = availableServices?.map((as) => ({
        ...as,
        value: selectedAdditionalServices.includes(as.id) ? 'yes' : 'no',
      }));
      reset({
        speakerNotes: facultyResponse?.speakerNotes,
        additionalServiceOptions: formattedAdditionalServices,
      });
    }
  }, [dialogState]);

  // Helpers
  const onSubmit = async (data, responseStatus) => {
    const { speakerNotes, additionalServiceOptions } = data;

    let selectedServices = additionalServiceOptions.filter(
      (as) => as.value === 'yes'
    );

    const conflictingServices = selectedServices
      .map((as) =>
        selectedServices.filter(
          (ss) =>
            ss.id !== as.id &&
            ss.dateService === as.dateService &&
            ss.startTime === as.startTime
        )
      )
      .flat();

    if (conflictingServices.length > 0) return onConflictingEvents();

    selectedServices = selectedServices.map((as) => as.id);

    const additionalServicesFacultyResponse = JSON.stringify({
      responseStatus,
      selectedServices,
      speakerNotes,
    });

    try {
      if (dialogState.data?.participationServices?.id) {
        await updateParticipationServices(
          {
            id: dialogState.data.participationServices.id,
            additionalServicesFacultyResponse,
          },
          true
        );
      }
      onClose();
      onSuccess();
    } catch (err) {
      console.error('err');
      onError();
    }
  };

  const renderButtons = () => {
    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '20px auto -44px',
            ...formControlStyle,
          }}
        >
          <div>
            <AimIconAndTextButton
              variant={'greyFill'}
              text={_i18n.buttons.cancel}
              onClick={onClose}
            >
              <ReplayIcon />
            </AimIconAndTextButton>
          </div>
          <ButtonContainer>
            <AimIconAndTextButton
              variant={'greenFill'}
              text={_i18n.buttons.saveAndSend}
              onClick={handleSubmit((data) =>
                onSubmit(data, constants.FacultyResponseStatus.PUBLISHED)
              )}
            >
              <CheckIcon />
            </AimIconAndTextButton>
            <AimIconAndTextButton
              text={i18n.buttons.saveDraft}
              variant="greenFill"
              onClick={handleSubmit((data) =>
                onSubmit(data, constants.FacultyResponseStatus.DRAFT)
              )}
            >
              <CheckIcon />
            </AimIconAndTextButton>
          </ButtonContainer>
        </div>
      </>
    );
  };

  const renderServices = () =>
    fields.map((as, idx) => (
      <ServiceCard
        key={as.id}
        i18n={_i18n}
        control={control}
        data={as}
        eventId={dialogState?.eventId}
        cardIdx={idx}
      />
    ));

  if (!dialogState) return null;

  return (
    <AimDialog
      open={dialogState.isOpen || false}
      title={_i18n.title}
      onClose={onClose}
      disableEnforceFocus
      hideAgreeButton={true}
      hideCancelButton={true}
      style={{
        width: '85%',
        minWidth: '300px',
        maxWidth: '100%',
        margin: '0 auto',
      }}
      PaperProps={{
        style: {
          paddingRight: 30,
          paddingLeft: 30,
          paddingTop: 20,
          paddingBottom: 30,
          maxWidth: '100%',
          minWidth: 600,
        },
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <AimTypography variant="textBold">
            {_i18n.sectionTitles.detailsCommunication}
          </AimTypography>
          <AimTypography variant="text">
            {
              dialogState?.data?.participationServices
                .additionalServicesDetailsCommunication
            }
          </AimTypography>
        </Grid>

        <Grid item xs={12}>
          <AimTypography variant="textBold">
            {_i18n.sectionTitles.selectServices}
          </AimTypography>

          {renderServices()}
        </Grid>

        <Grid item xs={12}>
          <AimTypography variant="textBold">
            {_i18n.sectionTitles.notes}
          </AimTypography>
        </Grid>

        <Grid item xs={12}>
          <TextArea
            control={control}
            errors={errors}
            name={'speakerNotes'}
            placeholder={_i18n.placeholders.notes}
            formControlStyle={formControlStyle}
            multiline
            rows={3}
          />
        </Grid>
      </Grid>
      {renderButtons()}
    </AimDialog>
  );
};

export default AdditionalServicesDialog;
