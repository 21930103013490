import styled from 'styled-components';

const getFlexPosition = (position) => {
  switch (position) {
    case 'left':
      return 'flex-start';
    case 'right':
      return 'flex-end';
    case 'center':
      return 'center';

    default:
      return 'flex-start';
  }
};

export const FooterContainer = styled.div`
  width: 100%;
  min-height: 5.6rem;
  background-color: ${(props) => props.footerBackgroundColor};
  padding: 1rem;
  color: ${(props) => props.footerTextColor};
  @media (max-width: 768px) {
    height: auto;
  }
`;

export const FooterFlexer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  height: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;

export const FooterColumn = styled.div`
  flex: 1;
  height: 100%;
`;

export const InfoCompanyFooterContainer = styled.div`
  justify-content: ${({ position }) => getFlexPosition(position)};
  display: flex;
  height: 100%;
  align-items: center;
`;

export const SocialFooterContainer = styled.div`
  justify-content: ${({ position }) => getFlexPosition(position)};
  display: flex;
  gap: 3px;
  height: 100%;
  align-items: center;
`;

export const LinkFooterContainer = styled.div`
  align-items: ${({ position }) => getFlexPosition(position)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export const LinkFooter = styled.span`
  cursor: pointer;
`;

export const SocialIconContainer = styled.span`
  cursor: pointer;
`;
