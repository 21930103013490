import React, { useEffect, useState } from 'react';

import { useIntl } from 'react-intl';
import { isMobileOnly } from 'react-device-detect';
import { useHistory, useParams } from 'react-router-dom';

import {
  styled,
  CustomIntl,
  AimTablePage,
  AimIconAndTextButton,
  AimTitleAndButtons,
} from '@aim/components';
import Grid from '@material-ui/core/Grid';

import ArrowBack from '@material-ui/icons/ArrowBack';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';

import translation from './myInvoices/translation';
import {
  listBillingDocsByClientIdQuery,
  listBillingDocsByClientId,
} from '../shared/billingGqlHelper';

const MainContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  width: isMobileOnly ? '100vw' : '80%',
  padding: '10px',
});

const MyInvoices = () => {
  const { eventId, participationId } = useParams();

  const intl = CustomIntl(useIntl());
  const i18n = translation.myInvoices(intl);
  const history = useHistory();

  const [myBillingDocs, setMyBillingDocs] = useState([]);

  useEffect(() => {
    if (isMobileOnly)
      listBillingDocsByClientId(participationId).then(setMyBillingDocs);
  }, []);

  const model = {
    header: {
      title: i18n.header.title,
      backButton: {
        onClick: () =>
          history.push(`/events/${eventId}/user/${participationId}/services`),
      },
      action: {},
    },
    dataLoader: {
      query: listBillingDocsByClientIdQuery,
      variables: { id: participationId },
      transform: ({ productByClientId }) =>
        productByClientId.items
          .filter((p) => !!p.billingDocument)
          .map(({ billingDocument }) => ({
            ...billingDocument,
            mailStatus: getMailStatus(billingDocument.mailStatus),
            docType: billingDocument.billingFlow?.docType,
          })),
    },
    table: {
      columnsTemplate: [
        'docNumber',
        'docType',
        'billingCustomer',
        'mailStatus',
      ],
      filters: [
        {
          column: 'docNumber',
          type: 'number',
        },
        {
          column: 'docType',
          type: 'select',
        },
        {
          column: 'billingCustomer',
          type: 'string',
        },
        {
          column: 'mailStatus',
          type: 'select',
        },
      ],
      rowAction: {
        download: {
          icon: <VerticalAlignBottomIcon />,
          variant: 'lightBlueFill',
          disabledWhen: () => true,
          onClick: async (row) =>
            await downloadFromS3({
              dirPath: 'TODO',
              fileData: 'TODO',
            }),
        },
      },
    },
  };

  const getMailStatus = (status) =>
    status === 'sent' ? '🟢 Sent' : '🟡  To send';

  // Renders
  const renderMobile = () => (
    <>
      <AimIconAndTextButton
        style={{ justifyContent: 'flex-start' }}
        variant="none"
        text={i18n.header.back}
        onClick={() =>
          history.push(`/events/${eventId}/user/${participationId}/services`)
        }
      >
        <ArrowBack />
      </AimIconAndTextButton>
      <AimTitleAndButtons title={i18n.header.title} />
      <div
        style={{
          backgroundColor: '#F3F2F6',
          padding: '15px 15px 15px 15px',
        }}
      >
        {myBillingDocs.map(({ billingDocument }) => (
          <Grid item xs={12} key={billingDocument.id}>
            <div
              style={{
                padding: 10,
                textAlign: 'center',
                backgroundColor: 'white',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                marginBottom: 12,
              }}
            >
              <Grid item container>
                <Grid
                  item
                  xs={12}
                  style={{
                    justifyContent: 'flex-start',
                    display: 'flex',
                    fontWeight: 'bold',
                    paddingBottom: 10,
                  }}
                >
                  {`${i18n.table.columns.docNumber} ${billingDocument.docNumber}`}
                </Grid>
                <Grid item xs={12} style={{ paddingBottom: 10 }}>
                  <hr
                    style={{
                      backgroundColor: '#e2e2e2',
                      height: 0.5,
                      width: '100%',
                      borderWidth: 0,
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    justifyContent: 'flex-start',
                    display: 'flex',
                    fontWeight: 'bold',
                    paddingBottom: 10,
                  }}
                >
                  {i18n.table.columns.docType}
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    justifyContent: 'flex-start',
                    display: 'flex',
                  }}
                >
                  {billingDocument.billingFlow?.docType}
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    justifyContent: 'flex-start',
                    display: 'flex',
                    fontWeight: 'bold',
                    paddingBottom: 10,
                  }}
                >
                  {i18n.table.columns.billingCustomer}
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    justifyContent: 'flex-start',
                    display: 'flex',
                  }}
                >
                  {billingDocument.billingCustomer}
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    justifyContent: 'flex-start',
                    display: 'flex',
                    fontWeight: 'bold',
                    paddingBottom: 10,
                  }}
                >
                  {i18n.table.columns.mailStatus}
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'left' }}>
                  {getMailStatus(billingDocument.mailStatus)}
                </Grid>
                <Grid item xs={12} style={{ paddingTop: 10 }}>
                  <AimIconAndTextButton
                    text={i18n.card.download}
                    style={{
                      backgroundColor: '#c7f2f2',
                      color: 'black',
                      width: '90%',
                    }}
                    disabled
                    onClick={() => {}}
                  >
                    <VerticalAlignBottomIcon />
                  </AimIconAndTextButton>
                </Grid>
              </Grid>
            </div>
          </Grid>
        ))}
      </div>
    </>
  );

  return (
    <MainContainer>
      {!isMobileOnly ? (
        <AimTablePage model={model} translation={i18n} intl={intl} />
      ) : (
        renderMobile()
      )}
    </MainContainer>
  );
};

export default MyInvoices;
