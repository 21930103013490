export default function (intl) {
  return {
    page: {
      title: intl.formatMessage({
        description: 'speaker list title',
        defaultMessage: 'Speakers',
      }),
      backButton: intl.formatMessage({
        description: 'back to participation text',
        defaultMessage: 'Participation',
      }),
      view: intl.formatMessage({
        description: 'view text',
        defaultMessage: 'View',
      }),
    },
    filter: {
      name: {
        label: intl.formatMessage({
          description: 'filter list by name',
          defaultMessage: 'Name',
        }),
      },
    },
    sortBy: {
      label: intl.formatMessage({
        description: 'sort speakers by',
        defaultMessage: 'Sort by',
      }),
      sortByName: {
        label: intl.formatMessage({
          description: 'sort speakers by name asc/desc',
          defaultMessage: 'Sort by name',
        }),
      },
      sortByNameAz: {
        label: intl.formatMessage({
          description: 'sort speakers by name asc',
          defaultMessage: 'Sort by name asc',
        }),
      },
      sortByNameZa: {
        label: intl.formatMessage({
          description: 'sort speakers by name desc',
          defaultMessage: 'Sort by name desc',
        }),
      },
    },
  };
}
