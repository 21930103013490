import React, { useState, useEffect } from 'react';

import { useIntl } from 'react-intl';
import useI18n from './shared/translation';

import { useParams } from 'react-router-dom';
import { sortBy } from 'lodash';

import { constants } from '@aim/common';
import {
  AimTypography,
  RegistrationForm,
  theme,
  styled,
  CustomIntl,
} from '@aim/components';

import {
  getEventSponsorFields,
  createFieldValue,
} from './shared/registerFormFieldGqlHelper';

const {
  extractProfilesFromQuotes,
  FieldDefitionRegistrationFieldFlat,
  FormViewer,
} = RegistrationForm;

const Container = styled('div')({
  marginTop: 80,
  marginBottom: 80,
  marginLeft: 100,
  marginRight: 100,
  padding: 50,
  backgroundColor: theme.colors.greyScale.white,
});

const FormContainer = styled('div')({
  height: '40vh',
  overflowY: 'auto',
  overflowX: 'hidden',
  paddingRight: '20px',
});

const SponsorRegistrationForm = ({ sponsor, onSave }) => {
  // Hooks
  const { eventId } = useParams();
  const intl = CustomIntl(useIntl());
  const i18n = useI18n.sponsorRegistrationForm(intl);
  // States
  const [fields, setFields] = useState();
  const [eventName, setEventName] = useState();

  // Effects
  useEffect(() => {
    if (eventId) loadFields(eventId);
  }, [eventId]);

  // Functions
  const loadFields = async (eventId) => {
    const res = await getEventSponsorFields(eventId);
    setEventName(res.name);

    const flatFields = FieldDefitionRegistrationFieldFlat(
      res.fields || [],
      constants.Clusters.Sponsor.id
    );
    setFields(sortBy(flatFields, ['position']));
  };

  const onSubmit = async (data) => {
    const fieldEntries = Object.entries(data);
    const promises = [];
    for (let i = 0; i < fieldEntries.length; i++) {
      const fieldDefId = fieldEntries[i][0];
      const fieldValue = fieldEntries[i][1];
      promises.push(
        createFieldValue({
          fieldValueFieldDefinitionId: fieldDefId,
          value: JSON.stringify(fieldValue),
          sponsorFieldValuesId: sponsor.id,
        })
      );
    }
    await Promise.all(promises);
    onSave();
  };

  return (
    <Container>
      <AimTypography variant="h1">{i18n.page.title}</AimTypography>
      {eventName ? (
        <AimTypography variant="text">
          {i18n.page.messages.firstPart}
          {` ${eventName} `}
          {i18n.page.messages.secondPart}
        </AimTypography>
      ) : null}
      {fields && (
        <FormContainer>
          <FormViewer
            fields={fields}
            onSubmit={onSubmit}
            eventId={eventId}
            variant="grey"
            intl={intl}
          />
        </FormContainer>
      )}
    </Container>
  );
};

export default SponsorRegistrationForm;
