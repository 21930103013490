import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import useI18n from './paxAbstractsRoleSelection/i18n';

import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import { makeStyles } from '@material-ui/core/styles';

import { appState } from '@aim/common';

import {
  AimCard,
  AimTypography,
  AimIconAndTextButton,
  CustomIntl,
  theme,
} from '@aim/components';

import { getEventAbstractService } from './shared/participationGqlHelper';
import { createReviewer } from './shared/paxReviewerGqlHelper';
import { updateHasAcceptedToBeReviewer } from '../pages/paxAbstractsRoleSelection/gqlHelper';

const useStyles = makeStyles(() => ({
  card: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 400,
    height: 200,
    borderRadius: 4,
    textTransform: 'uppercase',
    cursor: 'pointer',

    '&:hover': {
      border: `3px solid ${theme.colors.primary.yellow}`,
    },
  },
}));

const primaryColor =
  appState.eventConfiguration.getValue().primaryColor || 'black';

const acceptOrDenyToBeReviewer = async ({
  decision,
  participationId,
  setHasAcceptedToBeReviewer,
}) => {
  if (!participationId) {
    return;
  }

  const result = await updateHasAcceptedToBeReviewer({
    id: participationId,
    hasAcceptedToBeReviewer: decision,
  });

  if (result) {
    setHasAcceptedToBeReviewer(decision);
  }
};

const TheQuestion = ({ i18n, participationId, setHasAcceptedToBeReviewer }) => (
  <Grid container>
    <Grid xs={12} style={{ marginTop: '5vh' }}>
      <AimTypography
        variant={`h5regular`}
        boxStyle={{ whiteSpace: 'pre-line' }}
      >
        {`${i18n.page.theQuestionWelcome}
          ${i18n.page.theQuestionText}`}
      </AimTypography>
    </Grid>
    <Grid xs={12}>
      <AimIconAndTextButton
        style={{ backgroundColor: '#FFCEDB', color: 'grey' }}
        text={i18n.page.reject}
        onClick={() =>
          acceptOrDenyToBeReviewer({
            decision: false,
            participationId,
            setHasAcceptedToBeReviewer,
          })
        }
      >
        <ClearIcon />
      </AimIconAndTextButton>
      <AimIconAndTextButton
        style={{ backgroundColor: '#5AC75A33', color: 'grey' }}
        text={i18n.page.accept}
        onClick={() =>
          acceptOrDenyToBeReviewer({
            decision: true,
            participationId,
            setHasAcceptedToBeReviewer,
          })
        }
      >
        <CheckIcon />
      </AimIconAndTextButton>
    </Grid>
  </Grid>
);

const PresenterBlock = ({
  card,
  eventId,
  presenter,
  blockSelected,
  setBlockSelected,
}) => (
  <AimCard
    shadow
    className={card}
    onClick={() => {
      if (blockSelected && blockSelected.name === 'presenter') {
        setBlockSelected(null);
      } else {
        setBlockSelected({
          name: 'presenter',
          link: `/events/${eventId}/presenter-abstracts`,
        });
      }
    }}
    {...(blockSelected &&
      blockSelected.name === 'presenter' && {
        style: { border: `1.5px ${primaryColor} solid` },
      })}
  >
    <AimTypography variant="h3">{presenter}</AimTypography>
  </AimCard>
);

const ReviewerBlock = ({
  card,
  handleReviewerClick,
  reviewer,
  blockSelected,
}) => (
  <AimCard
    shadow
    className={card}
    onClick={handleReviewerClick}
    {...(blockSelected &&
      blockSelected.name === 'reviewer' && {
        style: { border: `1.5px ${primaryColor} solid` },
      })}
  >
    <AimTypography variant="h3">{reviewer}</AimTypography>
  </AimCard>
);

const ParticipantBlock = ({
  card,
  participant,
  eventId,
  blockSelected,
  setBlockSelected,
}) => (
  <AimCard
    shadow
    className={card}
    onClick={() => {
      if (blockSelected && blockSelected.name === 'participant') {
        setBlockSelected(null);
      } else {
        setBlockSelected({
          name: 'participant',
          link: `/events/${eventId}/abstracts`,
        });
      }
    }}
    {...(blockSelected &&
      blockSelected.name === 'participant' && {
        style: { border: `1.5px ${primaryColor} solid` },
      })}
  >
    <AimTypography variant="h3">{participant}</AimTypography>
  </AimCard>
);

const PaxAbstractsRoleSelection = () => {
  const classes = useStyles();
  const intl = CustomIntl(useIntl());
  const i18n = useI18n(intl);
  const history = useHistory();
  const { eventId } = useParams();

  const [hasReviewerCategories, setHasReviewerCategories] = useState(null);
  const [isReviewer, setIsReviewer] = useState(false);
  const [isPresenter, setIsPresenter] = useState(false);
  const [hasAcceptedToBeReviewer, setHasAcceptedToBeReviewer] = useState(null);
  const [participation, setParticipation] = useState(null);

  const [blockSelected, setBlockSelected] = useState(null); //null or object with: name: 'presenter', 'reviewer', 'participant' + link

  useEffect(() => {
    initData(eventId);
  }, []);

  const initData = async () => {
    try {
      const participationData = appState.user.getValue().userAndParticipation
        .participation;

      console.log('participationData', participationData);

      const abstractService = await getEventAbstractService(eventId);
      let currentUserAsReviewerData = abstractService.reviewers.items.find(
        (r) => r.participant.id === participationData.id
      );

      if (!currentUserAsReviewerData && participationData.isReviewer) {
        currentUserAsReviewerData = await createReviewer({
          reviewerParticipantId: participationData.id,
          reviewerAbstractServiceId: abstractService.id,
        });
      }

      const hasReviewerCategories =
        currentUserAsReviewerData?.reviewerCategories?.items?.length > 0 ||
        false;

      const isPresenter = abstractService.abstractDocuments.items.some(
        (ab) => ab.presenter?.participant?.id === participationData.id
      );

      setHasReviewerCategories(hasReviewerCategories);
      setIsReviewer(participationData.isReviewer);
      setHasAcceptedToBeReviewer(participationData.hasAcceptedToBeReviewer);
      setParticipation(participationData);

      setIsPresenter(isPresenter);

      if (!isPresenter && !participationData.isReviewer) {
        history.push(`/events/${eventId}/abstracts`);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleReviewerClick = () => {
    if (hasReviewerCategories === null) return;
    const targetRoute = hasReviewerCategories
      ? `/events/${eventId}/abstracts-reviewers`
      : `/events/${eventId}/abstracts-reviewers-categories`;
    // history.push(targetRoute);

    if (blockSelected && blockSelected.name === 'reviewer') {
      setBlockSelected(null);
    } else {
      setBlockSelected({
        name: 'reviewer',
        link: targetRoute,
      });
    }
  };

  return (
    <>
      <div style={{ flex: 1, minWidth: 'calc(100vw - 120px)' }}>
        {isReviewer && hasAcceptedToBeReviewer === null ? (
          <TheQuestion
            {...{
              i18n,
              participationId: participation ? participation.id : null,
              setHasAcceptedToBeReviewer,
            }}
          />
        ) : (
          <>
            <Tooltip
              title={intl.formatMessage({
                description: 'back to webcast list',
                defaultMessage: 'Back to Homepage',
              })}
            >
              <AimIconAndTextButton
                isUpperCase
                variant="none"
                text={intl.formatMessage({
                  description: 'back',
                  defaultMessage: 'back',
                })}
                style={{
                  padding: 0,
                  marginLeft: 20,
                }}
                onClick={() => history.replace(`/events/${eventId}/landing`)}
              >
                <ArrowBack />
              </AimIconAndTextButton>
            </Tooltip>

            <div style={{ margin: '50px auto' }}>
              <AimTypography variant="h2Regular" style={{ fontSize: 20 }}>
                {i18n.page.title}
              </AimTypography>
              <AimTypography variant="h2Regular" style={{ fontSize: 20 }}>
                {i18n.page.subtitle}
              </AimTypography>
            </div>

            <Grid
              container
              style={{
                marginTop: 50,
                maxHeight: 'calc(100vh - 260px)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
              }}
              // spacing={1}
            >
              {participation && (
                <ParticipantBlock
                  {...{
                    eventId,
                    push: history.push,
                    participant: i18n.cards.participant,
                    card: classes.card,
                    blockSelected,
                    setBlockSelected,
                  }}
                />
              )}
              {isReviewer && hasAcceptedToBeReviewer && (
                <ReviewerBlock
                  {...{
                    card: classes.card,
                    reviewer: i18n.cards.reviewer,
                    handleReviewerClick,
                    blockSelected,
                    setBlockSelected,
                  }}
                />
              )}
              {isPresenter && (
                <PresenterBlock
                  {...{
                    eventId,
                    push: history.push,
                    presenter: i18n.cards.presenter,
                    card: classes.card,
                    blockSelected,
                    setBlockSelected,
                  }}
                />
              )}

              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: 20,
                }}
              >
                <div
                  style={{
                    border: `1.5px ${
                      blockSelected ? primaryColor : 'transparent'
                    } solid`,
                    padding: '10px 20px 10px 20px',
                    cursor: blockSelected ? 'pointer' : 'default',
                    borderRadius: 3,
                    color: blockSelected ? 'black' : 'lightgrey',
                  }}
                  onClick={() => {
                    history.push(blockSelected.link);
                  }}
                >{`CONTINUE`}</div>
              </Grid>
            </Grid>
          </>
        )}
      </div>
    </>
  );
};

export default PaxAbstractsRoleSelection;
