import React, { useState, useEffect } from 'react';
import { addDays } from 'date-fns';
import { useHistory, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import ArrowBack from '@material-ui/icons/ArrowBack';

import { appState, aws, constants, utilities } from '@aim/common';
import {
  AimIconAndTextButton,
  AimTypography,
  AimRichText,
  AimFileList,
  CustomIntl,
  AimSnackbar,
  AimSnackbarSeverity,
} from '@aim/components';

import {
  MainContainer,
  InnerContainer,
} from '../shared/SponsorPagesContainers';
import { translation } from './SymposiumServices/index';
import { getSymposiumServicesService } from './SymposiumServices/symposiumServiceGqlHelper';
import SymposiumServicesList from './SymposiumServices/SymposiumServicesList';
import { useBuyOperations } from '../shared/buyOperationsGqlHelper';
import { createSymposiumServicesPurchase } from '../shared/symposiumServicesGqlHelper';

const { priceWithExtraCostCalc, decodeDbNumber, isValidPurchase } = utilities;

const SymposiumServices = () => {
  const history = useHistory();
  const { eventId, sponsorId, service } = useParams();
  const intl = CustomIntl(useIntl());
  const i18n = translation.symposiumServices(intl);
  const {
    create: createBuyOperation,
    updateSymposiumServicesPurchase,
  } = useBuyOperations();
  const [symposiumServicesService, setSymposiumServicesService] = useState();
  const [paymentType, setPaymentType] = useState();
  const [snackbar, setSnackbar] = useState({ isOpen: false });

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const result = await getSymposiumServicesService(eventId);
    setSymposiumServicesService({
      ...result,
      symposiumServicesItems: result.symposiumServicesItems.items?.map((s) => {
        const priceData = priceWithExtraCostCalc({
          netPrice: decodeDbNumber(s.price),
          vatRate: decodeDbNumber(result.vatRate),
          extraPriceRate: decodeDbNumber(result.extraPriceRate),
          extraPriceDate: result.extraPriceDate,
        });

        const bookedQty = s.purchase.items.reduce((prev, curr) => {
          const normalValidBookings =
            curr.buyOperation &&
            curr.buyOperation.isDeleted !== 'true' &&
            curr.buyOperation.isExpired !== 'true';
          const packageValidBookings =
            curr.sponsorPackageItem?.package.items?.filter(
              (p) =>
                p.buyOperation.isDeleted !== 'true' &&
                p.buyOperation.isExpired !== 'true'
            ).length || 0;
          prev =
            prev +
            (curr.sponsorPackageItem
              ? curr.sponsorPackageItem.quantity ||
                1 * packageValidBookings ||
                0
              : normalValidBookings
              ? curr.quantity
              : 0 || 0);
          return prev;
        }, 0);

        const bookableQuantity = s.quantity - bookedQty;
        const purchaseDisabled = s.quantity && bookableQuantity < 1;
        return {
          ...s,
          bookedQty,
          availability: s.quantity,
          bookableQuantity,
          purchaseDisabled,
          netPrice: priceData.applyExtraPrice
            ? priceData.extraNetPrice
            : priceData.netPrice,
          vatRate: priceData.vatRate,
          taxedPrice: priceData.applyExtraPrice
            ? priceData.extraVatPrice
            : priceData.vatPrice,
          // purchasedBy: s?.buyOperation?.sponsor?.name,
          // buyOperation: s?.buyOperation,
          // note: s?.note,
        };
      }),
    });

    const paymentType =
      result.isCreditCardEnabled && result.isBankTransferEnabled
        ? 'both'
        : (result.isCreditCardEnabled && constants.PaymentTypes.CreditCard) ||
          (result.isBankTransferEnabled && constants.PaymentTypes.BankTransfer);
    setPaymentType(paymentType);
  };

  const onAddToCart = async (
    symposiumServicesItemId,
    quantity,
    purchaseNow = false
  ) => {
    try {
      appState.isLoader.next(true);
      const bookingDate = new Date();
      const bookingExpiringDate = addDays(
        bookingDate,
        symposiumServicesService.sponsorServiceBookingDuration
      );
      const symposiumServicesPurchase = await createSymposiumServicesPurchase({
        quantity,
        symposiumServicesPurchaseSymposiumServicesItemId: symposiumServicesItemId,
      });

      const inputBuyOperation = {
        eventId: eventId,
        buyOperationSponsorId: sponsorId,
        date: bookingDate,
        expiringDate: bookingExpiringDate,
        paymentType: paymentType,
        buyOperationSymposiumServicesPurchaseId: symposiumServicesPurchase.id,
        isExpired: 'false',
        isDeleted: 'false',
      };

      const buyOperation = await createBuyOperation(inputBuyOperation, false);

      await updateSymposiumServicesPurchase({
        id: symposiumServicesPurchase.id,
        symposiumServicesPurchaseBuyOperationId: buyOperation.id,
      });
      const destUrl = `/events/${eventId}/${sponsorId}/cart`;
      if (purchaseNow) {
        history.push({
          pathname: destUrl,
          state: {
            bookings: [buyOperation],
          },
        });
      } else {
        await loadData();
      }
    } catch (e) {
      console.error(e);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.snackbar.addToCartFail.label,
      });
    } finally {
      appState.isLoader.next(false);
    }
  };

  const downloadFile = async (item) => {
    const s3Folder = `events/${eventId}/sponsor/${service}/documents/`;
    const link = `${s3Folder}${item.id}${item.extension}`;

    var res = await aws.Storage.get(link);
    window.open(res);
  };

  return (
    <MainContainer>
      <InnerContainer grow bottomSpacing>
        <div style={{ display: 'flex-root', marginTop: 20 }}>
          <AimIconAndTextButton
            variant="text"
            text={i18n.back}
            onClick={() =>
              history.push(`/events/${eventId}/${sponsorId}/my-services`)
            }
          >
            <ArrowBack />
          </AimIconAndTextButton>
        </div>
        <Grid container>
          <Grid item xs={12}>
            <AimTypography variant={'h1'}>{i18n.page.title}</AimTypography>
          </Grid>
          <Grid item xs={12}>
            {symposiumServicesService?.description && (
              <AimRichText
                label={i18n.page.instructions}
                value={JSON.parse(symposiumServicesService.description)}
                readOnly
                editorStyleOverride={{
                  minHeight: 'auto',
                  maxHeight: 'fit-content',
                  overflow: 'hidden !important',
                }}
              />
            )}
          </Grid>
        </Grid>

        {symposiumServicesService?.documents?.items?.length ? (
          <Grid container spacing={2} style={{ marginTop: 20 }}>
            <Grid
              item
              xs={12}
              style={{
                backgroundColor: 'white',
                maxWidth: '98%',
              }}
            >
              <Grid
                item
                container
                alignItems={'center'}
                style={{
                  backgroundColor: 'white',
                }}
              >
                <Grid
                  item
                  xs={10}
                  style={{
                    paddingLeft: 15,
                  }}
                >
                  <AimTypography variant={'h3'}>
                    {i18n.page.fileList.fileName}
                  </AimTypography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{
                    paddingLeft: 60,
                  }}
                >
                  <AimTypography variant={'h3'}>
                    {i18n.page.fileList.fileDimension}
                  </AimTypography>
                </Grid>
              </Grid>
              <AimFileList
                files={symposiumServicesService.documents.items}
                onDownload={downloadFile}
              />
            </Grid>
          </Grid>
        ) : null}

        <Grid container spacing={2}>
          <Grid item xs={12}>
            {symposiumServicesService && (
              <SymposiumServicesList
                symposiumServices={
                  symposiumServicesService.symposiumServicesItems
                }
                onAddToCart={onAddToCart}
              />
            )}
          </Grid>
        </Grid>
        <AimSnackbar
          open={snackbar.isOpen}
          onClose={() => setSnackbar({ isOpen: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </AimSnackbar>
      </InnerContainer>
    </MainContainer>
  );
};

export default SymposiumServices;
