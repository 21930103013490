import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { isMobileOnly } from 'react-device-detect';

import Grid from '@material-ui/core/Grid';
import ArrowForward from '@material-ui/icons/ArrowForward';
import ArrowBack from '@material-ui/icons/ArrowBack';

// import // aws,
// // appState,
// // constants,
// '@aim/common';

import Tooltip from '@material-ui/core/Tooltip';
import {
  AimIconAndTextButton,
  CustomIntl,
  AimTypography,
} from '@aim/components';

import translation from './grants/translation';
import { getGrants } from './grants/gqlHelper';
import { appState } from '@aim/common';
import { endOfDay, isAfter, parseISO } from 'date-fns';

const MainContainer = ({ children }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      alignItems: 'center',
    }}
  >
    {children}
  </div>
);

const yellow = '#fff3b8';
const grey = '#c7f2f2';

const Grants = () => {
  const { grantType, eventId } = useParams();
  const history = useHistory();
  const [grants, setGrants] = useState([]);

  const intl = CustomIntl(useIntl());
  const i18n = translation.landing(intl);
  const participationId = appState.user.getValue().userAndParticipation
    .participation.id;

  const pageTitle = `${grantType} ${i18n.grants.grant}`.replace(
    /\w\S*/g,
    (w) => w.replace(/^\w/, (c) => c.toUpperCase()) //capitalize each word, didnt have much time
  );

  useEffect(() => {
    const loadGrants = () => {
      getGrants(eventId, grantType).then((nextGrants) => {
        const filteredGrants = nextGrants.filter((grant) =>
          // !grant.closingDate ||
          isAfter(endOfDay(parseISO(grant.closingDate)), new Date())
        );
        setGrants(filteredGrants);
      });
    };

    loadGrants();
  }, []);

  const userParticipationGrants = appState.user.getValue().userAndParticipation
    .participation?.participationGrant?.items;

  return (
    <MainContainer>
      <div
        style={{
          width: '80%',
          marginBottom: 20,
        }}
      >
        <Tooltip title={i18n.page.back}>
          <AimIconAndTextButton
            variant="text"
            style={{ padding: 0 }}
            text={i18n.page.back}
            onClick={() => {
              history.push(`/events/${eventId}/landing`);
            }}
          >
            <ArrowBack />
          </AimIconAndTextButton>
        </Tooltip>
        <AimTypography variant={`h4`}>{pageTitle}</AimTypography>
        <AimTypography variant={`text`}>
          {`${i18n.page.choose[0].toUpperCase()}${i18n.page.choose.slice(
            1,
            i18n.page.choose.length
          )}`}
        </AimTypography>
        <Grid container spacing={1} style={{ marginTop: 20 }}>
          {grants
            .filter((y) => y.type.toLowerCase() === grantType.toLowerCase())
            .map((grant, ndx) => {
              const userHasGrant =
                userParticipationGrants.findIndex(
                  (pg) => pg.participationGrantGrantId === grant.id
                ) > -1;
              return (
                <Grid
                  item
                  xs={isMobileOnly ? 12 : 3}
                  key={ndx}
                  onClick={() => {
                    // history.push({
                    //   pathname: userHasGrant
                    //     ? `/events/${eventId}/user/${participationId}/grants/${grant.id}`
                    //     : `/events/${eventId}/grants/${grant.id}/subscribe`,
                    //   state: {
                    //     backUrl: `/events/${eventId}/grants/${grantType}`,
                    //     grantType: grantType,
                    //   },
                    // });
                  }}
                >
                  <div
                    style={{
                      padding: 10,
                      textAlign: 'center',
                      backgroundColor: '#F3F2F6',
                      minHeight: isMobileOnly ? undefined : 219,
                      alignItems: 'center',
                      justifyContent: 'center',
                      display: 'flex',
                    }}
                  >
                    <Grid item container>
                      <Grid
                        item
                        xs={12}
                        style={{
                          justifyContent: 'center',
                          display: 'flex',
                          textAlign: 'center',
                        }}
                      >
                        <AimTypography
                          variant="h2"
                          boxStyle={{
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                          }}
                        >
                          {grant.title.trim()}
                        </AimTypography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          justifyContent: 'center',
                          display: 'flex',
                        }}
                      >
                        {grant.instruction}
                      </Grid>
                      <Grid item xs={12} style={{ paddingTop: 10 }}>
                        <AimIconAndTextButton
                          onClick={() => {
                            history.push({
                              pathname: userHasGrant
                                ? `/events/${eventId}/user/${participationId}/grants/${grant.id}`
                                : `/events/${eventId}/grants/${grant.id}/subscribe`,
                              state: {
                                back: `/events/${eventId}/grants/${grantType}`,
                                abstractId:
                                  history?.location?.state?.abstractId,
                              },
                            });
                          }}
                          text={userHasGrant ? 'View detail' : 'Subscribe'}
                          // variant="primary"
                          // onClick={() => previous()}
                          // disabled={??}
                          style={{
                            backgroundColor: userHasGrant ? grey : yellow,
                            color: 'black',
                            width: isMobileOnly ? '80%' : '50%',
                          }}
                        >
                          <ArrowForward />
                        </AimIconAndTextButton>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              );
            })}
        </Grid>
      </div>
    </MainContainer>
  );
};

export default Grants;
