import React, { useState, useEffect } from 'react';

import { useParams, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import useI18n from './sponsorBookingAppointment/i18n';
import { format, isAfter, isBefore, addSeconds, startOfDay } from 'date-fns';
import { appState } from '@aim/common';
import { uniq } from 'lodash';
import { utcToZonedTime } from 'date-fns-tz';

import {
  MainContainer,
  Container,
} from './sponsorServiceConfigurationWall/serviceList/BreakoutRoomList';

import {
  CustomIntl,
  AimIconAndTextButton,
  AimTypography,
  AimTextField,
  AimSnackbarSeverity,
  AimSnackbar,
  theme,
  styled,
} from '@aim/components';

import { useSponsors } from './shared/sponsorGqlHelper';
import { useBreakoutRoomUtils } from '../hooks/useBreakoutRoomUtils';

import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';

import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import TitleAndBackButton from './shared/TitleAndBackButton';

const currentTimezone = appState.eventInfo.getValue().timezone;

const BookContainer = styled('div')({
  width: '100%',
  display: 'flex',
  padding: 15,
  minHeight: 360,
  background: theme.colors.greyScale.backgroundGrey,
});

const CustomDateCard = styled('div')({
  borderRadius: 4,
  height: 40,
  margin: 4,
  padding: 12,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const SlotCard = styled('div')(({ isdisabled, isselected, isMine }) => ({
  width: 295,
  height: 58,
  display: 'flex',
  backgroundColor: isMine ? '#FFEF9F' : !isselected ? 'white' : '#FFD400',
  border: isdisabled ? 'none' : '1px solid #FFD400',
  cursor: isdisabled ? 'arrow' : 'pointer',
  color: isMine || isdisabled ? 'rgba(132, 129, 154, 0.67)' : 'inherit',
  padding: 12,
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  marginLeft: 7,
  marginBottom: 7,
  borderRadius: 3,
}));

const borderColor = (selected) => {
  return `${selected ? 3 : 3}px solid ${
    selected ? '#FFD400' : theme.colors.greyScale.grey3
  }`;
};

const SponsorBookingAppointment = () => {
  // Hooks
  const intl = CustomIntl(useIntl());
  const i18n = useI18n(intl);
  const { eventId, sponsorId } = useParams();
  const history = useHistory();
  const { get } = useSponsors();
  const { states, getDate, getHours } = useBreakoutRoomUtils();

  // States
  const [participation, setParticipation] = useState();
  const [breakoutRoomsPurchased, setBreakoutRoomsPurchased] = useState([]);
  const [breakoutRoomsAvailable, setBreakoutRoomsAvailable] = useState(true);
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [availableDates, setAvailableDates] = useState([]);
  const [buttons, setButtons] = useState([]);
  const [selectedBr, setSelectedBr] = useState();
  const [notes, setNotes] = useState('');
  const [tabs, setTabs] = useState([]);
  const [myBr, setMyBr] = useState([]);

  // Effects
  useEffect(() => {
    if (!participation) {
      getUserParticipation();
    } else {
      setMyBr(participation.myBreakoutRoom?.items);
      fetchBreakoutRooms();
    }
  }, [participation]);

  useEffect(() => {
    if (breakoutRoomsPurchased.length > 0) generateFormData();
  }, [breakoutRoomsPurchased]);

  useEffect(() => {
    if (tabs.length > 0) {
      const tabSelected = tabs.find((tab) => tab.selected);
      checkSlotAvailabilityInBr(
        breakoutRoomsPurchased.find((br) => br.id === tabSelected.id)
      );
    }
  }, [tabs]);

  // Functions
  const getUserParticipation = async () => {
    try {
      const _participant = appState.user.getValue()?.userAndParticipation
        ?.participation;
      setParticipation(_participant);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchBreakoutRooms = async () => {
    const result = await get(sponsorId);
    if (result.breakoutRooms.items) {
      const purchasedBr = result.breakoutRooms.items.filter(
        (br) => br.sponsor.id === sponsorId && !!br.buyOperation?.purchase?.id
      );
      setBreakoutRoomsAvailable(purchasedBr.length > 0);
      setBreakoutRoomsPurchased(purchasedBr);
    }
  };

  const generateFormData = () => {
    const n = new Date().toISOString();
    const allAvailableDates = breakoutRoomsPurchased
      .filter((br) => isBefore(new Date(n), new Date(br.end2)))
      .map((br) => ({ date: br.date, id: br.id }))
      .sort((a, b) => new Date(a.date) - new Date(b.date));
    setAvailableDates(allAvailableDates);
    const onlyDates = allAvailableDates.map((obj) => obj.date);
    const uniqArray = uniq(onlyDates);
    const btnSelection = uniqArray.map((btn, idx) => ({
      title: btn,
      selected: idx === 0,
    }));
    setButtons(btnSelection);
    triggerTabs(btnSelection?.[0]?.title, allAvailableDates);
  };

  const handleChange = (date) => {
    const _btn = buttons.map((btn) => ({
      ...btn,
      selected: btn.title === date,
    }));
    setButtons(_btn);
    triggerTabs(date);
  };

  const triggerTabs = (date, allDates) => {
    const dates = allDates || availableDates;
    const idForDateSelected = dates.filter((value) => value.date === date);
    if (idForDateSelected.length > 1) {
      // significa che ci sono piu br nella stessa data, più tab
      const _tabs = idForDateSelected.map((value, idx) => ({
        id: value.id,
        title: `Breakout Room #${idx + 1}`,
        selected: idx === 0,
      }));
      setTabs(_tabs);
    } else {
      // c'è una sola br, la tab sarà solo una
      setTabs([
        {
          id: idForDateSelected?.[0]?.id,
          title: 'Breakout Room',
          selected: true,
        },
      ]);
    }
  };

  const checkSlotAvailabilityInBr = (br) => {
    setSelectedBr(br);
    if (availableDates.length === 0) return;
    const myBrOrderBySelected = myBr.filter(
      (attending) =>
        startOfDay(new Date(attending.slot.start)).toString() ===
          startOfDay(new Date(br.date)).toString() &&
        attending.state === states.ACCEPTED
    );
    // devo checkare se lo slot mappata ha uno start che è before la end di un altro slot con stato accepted
    const slotsParsed = br.slots.items.map((slot) => {
      const isTooLate = isAfter(new Date(), addSeconds(new Date(slot.end), 1));
      const alreadyInAnotherSlot = [];
      let isMine = false;
      if (!isTooLate)
        myBrOrderBySelected.map((attending) => {
          const flag =
            isAfter(
              new Date(slot.start),
              addSeconds(new Date(attending.slot.end), -1)
            ) ||
            isBefore(
              new Date(slot.end),
              addSeconds(new Date(attending.slot.start), 1)
            );
          if (!flag) {
            alreadyInAnotherSlot.push(attending.slot);
            isMine = slot.id === attending.slot.id;
          }
        });
      const load = slot.invitations.items.filter(
        (i) => i.state === states.ACCEPTED
      ).length;
      const maxReached = load >= (slot.isOneToOne ? 1 : br.maxAttendants);
      const res = {
        ...slot,
        isMine: isMine,
        isFull: maxReached,
        isTooLate: isTooLate,
        otherSlot: alreadyInAnotherSlot,
        isSelected: false,
      };

      return res;
    });
    setSelectedBr({ ...br, slots: slotsParsed });
  };

  const handeClickTab = (tabSelected) => {
    const _tabs = tabs.map((tab) => ({
      ...tab,
      selected: tab.id === tabSelected.id,
    }));
    setTabs(_tabs);
  };

  const handleSlotClick = (slot) => {
    setSelectedBr({
      ...selectedBr,
      slots: [
        ...selectedBr.slots.map((s) => ({
          ...s,
          isSelected: s.id === slot.id,
        })),
      ],
    });
  };

  const getFormData = () => {
    const selectedSlot = selectedBr.slots.find((slot) => slot.isSelected);
    if (selectedSlot && selectedBr) {
      history.push({
        pathname: `/events/${eventId}/sponsors/${sponsorId}/book/confirm`,
        state: {
          breakoutRoom: selectedBr,
          breakoutRoomSlot: selectedSlot,
          notes: notes,
          participation: participation,
        },
      });
    } else if (!selectedSlot) {
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.snackbar.noSlotSelected,
      });
    } else {
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.snackbar.genericError,
      });
    }
  };

  // Renders
  const renderBookForm = () => {
    return (
      <div>
        <AimTypography variant="h3">{i18n.form.title}</AimTypography>
        <Divider />
        <div style={{ marginTop: 8, display: 'flex', flexWrap: 'wrap' }}>
          {buttons &&
            buttons.map((btn, idx) => (
              <CustomDateCard
                key={idx}
                style={{
                  backgroundColor:
                    btn.selected === true
                      ? theme.colors.primary.yellow
                      : 'transparent',
                  border: '1px solid ' + theme.colors.primary.yellow,
                }}
                onClick={() => handleChange(btn.title)}
              >
                <AimTypography variant="text" margin={0}>
                  {format(
                    utcToZonedTime(btn.title, currentTimezone),
                    'dd-MM-yyyy',
                    {
                      timeZone: currentTimezone,
                    }
                  )}
                </AimTypography>
              </CustomDateCard>
            ))}
        </div>
        <div
          style={{ display: 'flex', justifyContent: 'stretch', marginTop: 20 }}
        >
          {tabs &&
            tabs?.map((tab) => (
              <AimIconAndTextButton
                key={tab.id}
                variant="none"
                onClick={() => {
                  handeClickTab(tab);
                }}
                style={{
                  borderBottom: borderColor(tab.selected),
                  flex: 1,
                }}
                text={tab.title}
              />
            ))}
        </div>
        <BookContainer>
          <div style={{ width: '50%', padding: 15 }}>
            <AimTypography variant="h6" margin={0}>
              {i18n.form.firstRange}
            </AimTypography>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexWrap: 'wrap',
                marginTop: 15,
              }}
            >
              {selectedBr &&
                selectedBr?.slots
                  .sort((a, b) => new Date(a.start) - new Date(b.start))
                  .map((slot) =>
                    isBefore(new Date(slot.end), new Date(selectedBr.start2))
                      ? renderSlotCards(slot)
                      : null
                  )}
            </div>
          </div>
          <div style={{ width: '50%', padding: 15 }}>
            <AimTypography variant="h6" margin={0}>
              {i18n.form.secondRange}
            </AimTypography>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexWrap: 'wrap',
                marginTop: 15,
              }}
            >
              {selectedBr &&
                selectedBr?.slots
                  .sort((a, b) => new Date(a.start) - new Date(b.start))
                  .map((slot) =>
                    isAfter(new Date(slot.start), new Date(selectedBr.end1))
                      ? renderSlotCards(slot)
                      : null
                  )}
            </div>
          </div>
        </BookContainer>
        <BookContainer
          style={{
            marginTop: 15,
            marginBottom: 10,
            padding: 30,
            flexDirection: 'column',
            minHeight: 220,
          }}
        >
          <AimTypography variant="h6" margin={0}>
            {i18n.form.notes}
          </AimTypography>
          <AimTextField
            type="text"
            style={{ backgroundColor: 'white', minHeight: 220 }}
            onChange={(e) => setNotes(e.target.value)}
            multiline
            rowsMax={10}
          ></AimTextField>
        </BookContainer>
      </div>
    );
  };

  const renderSlotCards = (slot) => {
    if (slot.isOneToOne) return null;
    const disabledFlag =
      slot.isMine || slot.isFull || slot.isTooLate || slot.otherSlot.length;
    const message = slot.isMine
      ? 'already booked'
      : slot.isFull
      ? 'full'
      : slot.isTooLate
      ? 'too late'
      : slot.otherSlot.length
      ? 'collide with: ' +
        slot.otherSlot
          .map(
            (s) =>
              `${getDate(s.start)} [${getHours(s.start)} - ${getHours(s.end)}]`
          )
          .join(', ')
      : '';
    return (
      <Tooltip title={message}>
        <SlotCard
          key={slot.id}
          isdisabled={disabledFlag}
          isselected={slot.isSelected}
          onClick={() => {
            disabledFlag ? null : handleSlotClick(slot);
          }}
          isMine={slot.isMine}
        >
          <div>
            <AimTypography margin={0} variant="text">
              {format(utcToZonedTime(slot.start, currentTimezone), 'HH:mm', {
                timeZone: currentTimezone,
              })}{' '}
              -{' '}
              {format(
                utcToZonedTime(slot.end, currentTimezone, {
                  timeZone: currentTimezone,
                }),
                'HH:mm'
              )}
            </AimTypography>
            {slot.title && (
              <AimTypography margin={0} variant="text">
                {slot.title}
              </AimTypography>
            )}
          </div>
          {selectedBr.maxAttendants === 1 || slot.isOneToOne ? (
            <PersonIcon style={{ color: '#84819A' }} />
          ) : (
            <GroupIcon style={{ color: '#84819A' }} />
          )}
        </SlotCard>
      </Tooltip>
    );
  };

  const renderNoBrAvailable = () => {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <AimTypography variant="h3">{i18n.page.noBrAvailable}</AimTypography>
      </div>
    );
  };

  const renderButtons = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <AimIconAndTextButton
          variant="yellow"
          text={i18n.page.backButton.label}
          onClick={() =>
            history.push(`/events/${eventId}/sponsors/${sponsorId}`)
          }
        >
          <ArrowBack />
        </AimIconAndTextButton>
        <AimIconAndTextButton
          variant="greenFill"
          text={i18n.page.forwardButton.label}
          onClick={() => getFormData()}
        >
          <ArrowForward />
        </AimIconAndTextButton>
      </div>
    );
  };

  return (
    <MainContainer>
      <TitleAndBackButton
        backOnClickFunction={() =>
          history.push(`/events/${eventId}/sponsors/${sponsorId}`)
        }
        backOnHoverText={i18n.page.backButton.tooltip}
        title={i18n.page.title}
        subtitle={i18n.page.subTitle}
      />
      <Container>
        {breakoutRoomsAvailable && availableDates.length > 0
          ? renderBookForm()
          : renderNoBrAvailable()}
        {renderButtons()}
      </Container>
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </MainContainer>
  );
};

export default SponsorBookingAppointment;
