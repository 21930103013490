import { isAfter, isSameDay } from 'date-fns';
import { startOfToday } from 'date-fns';
import { constants } from '@aim/common';

export const safeNum = (value) => (isNaN(Number(value)) ? 0 : Number(value));

export const encodeDbNumber = (value) =>
  safeNum((safeNum(value) * 100).toFixed(2));

export const decodeDbNumber = (value) => safeNum(value) / 100;
export const decodeDbHours = (value) => {
  const hd = safeNum(value) / 60;
  const hi = Math.floor(hd);
  const md = hd - hi;
  const mi = md * 60;
  return `${hi}h ${mi}min`;
};

export const percentageCalc = (p, v) => {
  return Number(p) + (Number(p) * Number(v)) / 100;
};

export const vatCalc = (p, v) => {
  const taxedPrice = percentageCalc(p, v);
  return roundTo2Decimal(taxedPrice);
};

export const roundTo2Decimal = (number) =>
  Math.round((number + Number.EPSILON) * 100) / 100;

export const formatNumber = (number) =>
  new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(
    number
  );

export const physicalStandSpacePriceCalc = ({
  netPrice,
  vatRate,
  noVat,
  extraPriceDisabled,
  extraPriceRate,
  extraPriceDate,
}) => {
  const extraNetPrice = roundTo2Decimal(
    percentageCalc(netPrice, extraPriceDisabled ? 0 : extraPriceRate)
  );
  const newVatRate = noVat ? 0 : vatRate;

  return {
    applyExtraPrice: extraPriceDate
      ? isAfter(startOfToday(), new Date(extraPriceDate)) ||
        isSameDay(startOfToday(), new Date(extraPriceDate))
      : false,
    vatRate: newVatRate,
    netPrice,
    extraNetPrice,
    vatPrice: vatCalc(netPrice, newVatRate),
    extraVatPrice: vatCalc(extraNetPrice, newVatRate),
  };
};

export const sortBy = (array, fieldName) => {
  return array
    ? array.sort((a, b) => {
        if (a[fieldName] < b[fieldName]) return -1;
        if (b[fieldName] > a[fieldName]) return 1;
        return 0;
      })
    : [];
};

export const isValidPurchase = (purchase) => {
  return (
    (purchase?.paymentType === constants.PaymentTypes.CreditCard &&
      (purchase?.payment?.paymentIdMonetaOnLine ||
        purchase?.payment?.paymentIdIngenico ||
        purchase?.payment?.paymentIdGpWebpay)) ||
    purchase?.paymentType === constants.PaymentTypes.BankTransfer
  );
};
