import { aws, appState, constants } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

const listCertificatesQuery = /* GraphQL */ `
  query listCertification($id: ID!) {
    getEvent(id: $id) {
      id
      papers(filter: { isDeleted: { ne: true }, type: { contains: "${constants.PapersTypologies.GENERIC.id}" } }) {
        items {
          id
          name
          isGeneric
          publishDate
          filledPapers {
            items {
              id
              file {
                extension
                id
                originalName
                size
              }
            }
          }
          template {
            extension
            id
            originalName
            size
          }
          profile {
            cluster
            id
            isPublic
            name
          }
          mappings {
            items {
              id
              name
              valueString
              value {
                id
                controlType
                label
                placeholder
                options
                isDeleted
              }
            }
          }
        }
      }
    }
  }
`;

export const listCertifications = (eventId, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    API.graphql({ query: listCertificatesQuery, variables: { id: eventId } })
      .then((response) => resolve(response.data.getEvent))
      .catch((e) => {
        console.error('list-certifications-error', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });
