import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import CheckIcon from '@material-ui/icons/Check';
import ArrowBack from '@material-ui/icons/ArrowBack';

import {
  AimIconAndTextButton,
  AimTypography,
  CustomIntl,
  ParticipationForm,
  defaultSaveData,
  theme,
} from '@aim/components';
import { appState } from '@aim/common';
import translation from './profileRegistrationDataEdit/translation';
import { getEventDetails } from './profileRegistrationDataEdit/gqlHelper';

const toSkipBaseFields = [
  'typology',
  'feeDateRange',
  'type',
  'status',
  'isSponsorAdmin',

  // 'phone',
];
const toDisableBaseFields = ['profile'];

const ProfileRegistrationDataEdit = () => {
  const { eventId, participationId } = useParams();
  const intl = CustomIntl(useIntl());
  const i18n = translation.participationEdit(intl);
  const [user, setUser] = useState();
  const [profiles, setProfiles] = useState([]);
  const [feeDateRanges] = useState([]);
  const [typologies] = useState([]);
  const [currentParticipation, setCurrentParticipation] = useState();
  const [preTitles, setPreTitles] = useState([]);
  const saveDataRef = useRef();

  const history = useHistory();
  useEffect(() => {
    const userSubscription = appState.user.subscribe((nextUser) => {
      setUser(nextUser?.userAndParticipation?.participation);
    });
    return () => userSubscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (!user) return;

    const getData = async () => {
      showLoader();

      const evDetails = await getEventDetails(eventId, user.cluster, false);

      const nextProfiles = evDetails.profiles?.items
        .map((p) => ({
          label: p.name + (p?.category?.name ? ` - ${p.category.name}` : ''),
          value: p.id,
          maxAccompanyingPersons: p.maxAccompanyingPersons,
        }))
        .sort((a, b) => a.label?.localeCompare(b.label));
      setProfiles(nextProfiles);

      const nextPreTitles = evDetails.preTitles.items
        .filter((p) => p.isActive)
        .map((p) => ({
          label: p.title,
          value: p.key,
        }));

      setPreTitles(nextPreTitles);

      let p = {
        cluster: user.cluster,
        event: { id: eventId },
        id: user.id,
        cognitoUserId: user.cognitoUserId,
        givenName: user.givenName,
        familyName: user.familyName,
        email: user.email,
        profile: user.profile,
        phone: user.phone,
        fieldValues: user.fieldValues,
        guests: user.guests,
        title: user.title,
      };
      setCurrentParticipation(p);
      hideLoader();
    };

    getData();
  }, [user]);

  const showLoader = () => appState.isLoader.next(true);

  const hideLoader = () => appState.isLoader.next(false);

  const sendData = async (submittedData, dirtyFields) => {
    showLoader();
    await defaultSaveData({
      participation: currentParticipation,
      submittedData: {
        ...submittedData,
        isParsonalDetailsEditedAtLeastOnce: true,
      },
      dirtyFields,
    });
    hideLoader();
    history.push(`/events/${eventId}/user/${participationId}`);
  };

  const handleSave = () => {
    if (!saveDataRef.current) return;
    saveDataRef.current.click();
  };

  return (
    <div
      style={{ flex: 1, minWidth: 'calc(100vw - 480px)', margin: '0px 180px' }}
    >
      <AimIconAndTextButton
        variant="none"
        text={i18n.actions.backButton}
        style={{
          padding: 0,
        }}
        onClick={() =>
          history.replace(`/events/${eventId}/user/${participationId}`)
        }
      >
        <ArrowBack />
      </AimIconAndTextButton>
      <AimTypography variant="h1">{i18n.page.title}</AimTypography>
      <AimTypography variant="text">{i18n.page.subtitle}</AimTypography>
      {user && profiles && currentParticipation && (
        <div
          style={{
            height: '50vh',
            overflowY: 'auto',
            overflowX: 'hidden',
            paddingRight: '20px',
          }}
        >
          <div
            style={{ background: theme.colors.greyScale.grey2, padding: 10 }}
          >
            <ParticipationForm
              {...{
                intl,
                participation: currentParticipation,
                profiles,
                typologies,
                preTitles,
                feeDateRanges,
                refButton: saveDataRef,
                onSaveDataFn: sendData,
                showAdminFields: false,
                showHiddenFields: false,
                toSkipBaseFieldsIds: toSkipBaseFields,
                requiredBaseFields: ['email'],
                toHideBaseFields: ['status'],
                // isReadOnly: true,
                // excludePaxAnagraphicFields: true,
                variant: 'white',
                toDisableBaseFields,
                showBlocks: true,
              }}
            >
              {/* {other form} */}
            </ParticipationForm>
          </div>
          <div
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'flex-end',
              marginTop: 20,
            }}
          >
            <AimIconAndTextButton
              variant="yellowFill"
              text={i18n.actions.save}
              onClick={handleSave}
            >
              <CheckIcon />
            </AimIconAndTextButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileRegistrationDataEdit;
