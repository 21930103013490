export const translation = {
  virtualBag: (intl) => ({
    snackbar: {
      errors: {
        general: intl.formatMessage({
          description: 'general error',
          defaultMessage: 'An error occured downloading files',
        }),
      },
    },
    page: {
      title: intl.formatMessage({
        description: 'Virtual bag label',
        defaultMessage: 'Virtual Bag',
      }),
      buttons: {
        downloadAll: intl.formatMessage({
          description: 'DOWNLOAD all description',
          defaultMessage: 'download all',
        }),
        download: intl.formatMessage({
          description: 'DOWNLOAD  description',
          defaultMessage: 'download',
        }),
      },
    },
  }),
};
