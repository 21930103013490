import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';

import { useHistory, useParams } from 'react-router-dom';

import { useIntl } from 'react-intl';
import translation from './../shared/translation';

import { addDays } from 'date-fns';

import { aws, appState, constants, utilities } from '@aim/common';

import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import ArrowBack from '@material-ui/icons/ArrowBack';
import CartIcon from '@material-ui/icons/ShoppingCart';

import {
  MainContainer,
  InnerContainer,
} from './../shared/SponsorPagesContainers';

import { useSponsorStaffService } from '../shared/sponsorStaffServiceGqlHelper';
import { useBuyOperations } from './../shared/buyOperationsGqlHelper';
import SponsorStaffCard from './sponsorStaff/SponsorStaffCard';
import SponsorStaffDialog from './sponsorStaff/SponsorStaffDialog';

const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

import {
  AimIconAndTextButton,
  AimTypography,
  AimSnackbarSeverity,
  AimSnackbar,
  AimRichText,
  AimFileList,
  CustomIntl,
} from '@aim/components';

// const showLoader = () => appState.isLoader.next(true);
// const hideLoader = () => appState.isLoader.next(false);

const SponsorStaff = () => {
  //Hooks
  const history = useHistory();
  const { eventId, sponsorId, service } = useParams();
  const intl = CustomIntl(useIntl());
  const i18n = translation.sponsorStaff(intl);
  const sponsorStaffServiceHelper = useSponsorStaffService();
  const buyOperationsHelper = useBuyOperations();

  //State
  const [sponsorStaffData, setSponsorStaffData] = useState([]);
  // const [selectedAreas, setSelectedAreas] = useState([]);
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [paymentType, setPaymentType] = useState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [bookingDuration, setBookingDuration] = useState();

  //Effects
  useEffect(() => {
    if (!isDialogOpen) {
      fetchSponsorStaff();
    }
  }, []);

  // Functions
  const fetchSponsorStaff = async () => {
    const nextSponsorStaffData = await sponsorStaffServiceHelper.get({
      id: sponsorId,
      eventId,
    });
    setSponsorStaffData(nextSponsorStaffData);
    setBookingDuration(
      nextSponsorStaffData.event.sponsorServiceBookingDuration || 10
    );

    const paymentType =
      nextSponsorStaffData.event.sponsorStaffService.isCreditCardEnabled &&
      nextSponsorStaffData.event.sponsorStaffService.isBankTransferEnabled
        ? 'both'
        : (nextSponsorStaffData.event.sponsorStaffService.isCreditCardEnabled &&
            constants.PaymentTypes.CreditCard) ||
          (nextSponsorStaffData.event.sponsorStaffService
            .isBankTransferEnabled &&
            constants.PaymentTypes.BankTransfer);
    setPaymentType(paymentType);
  };

  const downloadFile = async (item) => {
    const s3Folder = `events/${eventId}/sponsor/${service}/documents/`;
    const link = `${s3Folder}${item.id}${item.extension}`;

    var res = await aws.Storage.get(link);
    window.open(res);
  };

  const addToCart = async (data, purchaseNow = false) => {
    showLoader();
    // add netPrice for cart

    try {
      const responsePromise = await Promise.all(
        data.map(async (item) => {
          const sponsorStaffPurchase = await sponsorStaffServiceHelper.createSponsorStaffPurchase(
            {
              netPrice: utilities.encodeDbNumber(item.price),
              purchasedSubscriptions: item.quantity,
              quantity: item.quantity,
              sponsorStaffPurchaseSponsorStaffId: sponsorStaffData.id,
              sponsorStaffPurchaseProfileId: item.profile,
              sponsorStaffPurchaseFeeDateRangeId: item.feeDateRange,
              participationMode: item.participationMode,
            },
            false
          );

          const bookingDate = new Date();
          const bookingExpiringDate = addDays(bookingDate, bookingDuration);
          const inputBuyOperation = {
            eventId: eventId,
            buyOperationSponsorStaffPurchaseId: sponsorStaffPurchase.id,
            buyOperationSponsorId: sponsorId,
            date: bookingDate,
            expiringDate: bookingExpiringDate,
            paymentType: paymentType,
            isExpired: 'false',
            isDeleted: 'false',
          };

          const response = await buyOperationsHelper.create(
            inputBuyOperation,
            false
          );

          await sponsorStaffServiceHelper.updateSponsorStaffPurchase(
            {
              id: sponsorStaffPurchase.id,
              sponsorStaffPurchaseBuyOperationId: response.id,
            },
            false
          );
          return response;
        })
      );

      const destUrl = `/events/${eventId}/${sponsorId}/cart`;
      if (purchaseNow) {
        history.push({
          pathname: destUrl,
          state: {
            bookings: responsePromise,
          },
        });
      }
    } catch (e) {
      console.error(e);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.snackbar.addToCartFail.label,
      });
    } finally {
      hideLoader();
    }
  };

  const handleClose = () => setIsDialogOpen(false);

  return (
    <MainContainer>
      <InnerContainer grow bottomSpacing>
        <div style={{ display: 'flex-root', marginTop: 20 }}>
          <Tooltip title={i18n.page.back.tooltip.label}>
            <AimIconAndTextButton
              variant="text"
              text={i18n.page.back.button.label}
              onClick={() =>
                history.push(`/events/${eventId}/${sponsorId}/my-services`)
              }
            >
              <ArrowBack />
            </AimIconAndTextButton>
          </Tooltip>
        </div>
        <Grid container>
          <Grid item xs={12}>
            <AimTypography variant={'h2'}>
              {i18n.page.title.label}
            </AimTypography>
          </Grid>
          <Grid item xs={12}>
            {sponsorStaffData.event?.sponsorStaffService?.description && (
              <AimRichText
                label={i18n.page.instructions.label}
                value={JSON.parse(
                  sponsorStaffData.event.sponsorStaffService.description
                )}
                readOnly
                editorStyleOverride={{
                  minHeight: 'auto',
                  maxHeight: 'fit-content',
                  overflow: 'hidden !important',
                }}
              />
            )}
          </Grid>
        </Grid>
        <div
          style={{
            display: 'flex',
            marginTop: 20,
            flexDirection: 'row',
            height: '140px',
            justifyContent: 'space-around',
          }}
        >
          {/* <SponsorStaffCard
            title={i18n.page.freeStaffMembers.label}
            style={{ whiteSpace: 'nowrap' }}
          >
            <AimTypography
              variant="h1"
              boxStyle={{ fontWeight: 'normal', textAlign: 'center' }}
            >
              {sponsorStaffData?.freeStaffMembers || 0}
            </AimTypography>
          </SponsorStaffCard> */}
          <SponsorStaffCard title={i18n.page.addSpots.label}>
            {/* <div
              style={{ width: '50%', display: 'flex', alignItems: 'center' }}
            > */}
            <Tooltip
              title={i18n.page.purchaseButton.tooltip.label}
              style={{
                minWidth: 170,
                display: 'flex',
                alignSelf: 'center',
                margin: '20px 0px',
              }}
            >
              <AimIconAndTextButton
                variant="yellowFill"
                onClick={() => setIsDialogOpen(true)}
                text={i18n.page.purchaseButton.title.label}
              >
                <CartIcon />
              </AimIconAndTextButton>
            </Tooltip>
            {/* </div> */}
          </SponsorStaffCard>
          <SponsorStaffCard title={i18n.page.deadline.label}>
            <AimTypography
              variant="h1"
              boxStyle={{ fontWeight: 'normal', textAlign: 'center' }}
            >
              {sponsorStaffData?.deadlines?.deadline &&
                format(
                  new Date(sponsorStaffData?.deadlines.deadline),
                  'dd/MM/yyyy'
                )}
            </AimTypography>
          </SponsorStaffCard>
        </div>

        <SponsorStaffDialog
          isDialogOpen={isDialogOpen}
          onAgree={(data) => addToCart(data, true)}
          onDisagree={handleClose}
          i18n={i18n}
          intl={intl}
          eventId={eventId}
        />

        {sponsorStaffData.event?.sponsorStaffService?.documents?.items
          ?.length ? (
          <Grid container spacing={2} style={{ marginTop: 20 }}>
            <Grid
              item
              xs={12}
              style={{
                backgroundColor: 'white',
                maxWidth: '98%',
              }}
            >
              <Grid
                item
                container
                alignItems={'center'}
                style={{
                  backgroundColor: 'white',
                }}
              >
                <Grid
                  item
                  xs={10}
                  style={{
                    paddingLeft: 15,
                  }}
                >
                  <AimTypography variant={'h3'}>
                    {i18n.page.fileList.fileName.label}
                  </AimTypography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{
                    paddingLeft: 60,
                  }}
                >
                  <AimTypography variant={'h3'}>
                    {i18n.page.fileList.fileDimension.label}
                  </AimTypography>
                </Grid>
              </Grid>
              <AimFileList
                files={
                  sponsorStaffData.event.sponsorStaffService.documents.items
                }
                onDownload={downloadFile}
              />
            </Grid>
          </Grid>
        ) : null}

        <AimSnackbar
          open={snackbar.isOpen}
          onClose={() => setSnackbar({ isOpen: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </AimSnackbar>
      </InnerContainer>
    </MainContainer>
  );
};

export default SponsorStaff;
