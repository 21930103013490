export default {
  myInvoices: (intl) => ({
    header: {
      title: intl.formatMessage({
        description: 'my invoices page title',
        defaultMessage: 'My Invoices',
      }),
      back: intl.formatMessage({
        description: 'my invoices Page back button label',
        defaultMessage: 'back',
      }),
    },
    table: {
      empty: intl.formatMessage({
        description: 'Table empty',
        defaultMessage: 'No data',
      }),
      columns: {
        docNumber: intl.formatMessage({
          description: 'Table column invoice number',
          defaultMessage: 'Invoice n°',
        }),
        docType: intl.formatMessage({
          description: 'Table column document type',
          defaultMessage: 'Document type',
        }),
        billingCustomer: intl.formatMessage({
          description: 'Table column billing customer',
          defaultMessage: 'Billing customer',
        }),
        mailStatus: intl.formatMessage({
          description: 'Table column status',
          defaultMessage: 'Status',
        }),
      },
      rowAction: {
        download: {
          tooltip: intl.formatMessage({
            description: 'download button tooltip',
            defaultMessage: 'Download',
          }),
          disabledTooltip: intl.formatMessage({
            description: 'download button disabled tooltip',
            defaultMessage: 'Disabled download',
          }),
        },
      },
      filters: {
        docNumber: {
          label: intl.formatMessage({
            description: 'filter docNumber label',
            defaultMessage: 'Filter invoice n°',
          }),
          placeholder: intl.formatMessage({
            description: 'filter docNumber placeholder',
            defaultMessage: 'Invoice n°',
          }),
        },
        docType: {
          label: intl.formatMessage({
            description: 'filter docType label',
            defaultMessage: 'Filter Document type',
          }),
          placeholder: intl.formatMessage({
            description: 'filter docType placeholder',
            defaultMessage: 'Document type',
          }),
        },
        mailStatus: {
          label: intl.formatMessage({
            description: 'filter status label',
            defaultMessage: 'Filter Status',
          }),
          placeholder: intl.formatMessage({
            description: 'filter status placeholder',
            defaultMessage: 'Status',
          }),
        },
        billingCustomer: {
          label: intl.formatMessage({
            description: 'filter billingCustomer',
            defaultMessage: 'Filter Billing customer',
          }),
          placeholder: intl.formatMessage({
            description: 'filter billingCustomer placeholder',
            defaultMessage: 'Billing customer',
          }),
        },
      },
    },
    card: {
      download: intl.formatMessage({
        description: 'Mobile card billing doc download button',
        defaultMessage: 'Download document',
      }),
      noBillingDocPresent: intl.formatMessage({
        description: 'Mobile card no billing doc present',
        defaultMessage: "You don't have any billing document at the moment",
      }),
    },
  }),
};
