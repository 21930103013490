import React from 'react';
import { useIntl } from 'react-intl';

import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import VisibilityIcon from '@material-ui/icons/Visibility';

import {
  AimTableNoPaging,
  AimTableRow,
  AimTableCell,
  AimIconButton,
  CustomIntl,
} from '@aim/components';

import { constants } from '@aim/common';

import { useDataHelper } from '../../shared/dataHelper';

const OtherSponsorizationsItemsListTable = ({
  i18n,
  items,
  showInfoDialog,
  onAddToCart,
}) => {
  //Hooks
  const intl = CustomIntl(useIntl());
  const { formatNumber, decodeDbNumber, vatCalc } = useDataHelper();

  // Columns
  const headCells = [
    {
      id: 'item',
      numeric: false,
      disablePadding: false,
      label: i18n.table.columns.item.label,
    },
    {
      id: 'type',
      numeric: false,
      disablePadding: false,
      label: i18n.table.columns.type.label,
    },
    {
      id: 'availability',
      numeric: false,
      disablePadding: false,
      label: i18n.table.columns.availability.label,
    },
    {
      id: 'netPrice',
      numeric: false,
      disablePadding: false,
      label: i18n.table.columns.netPrice.label,
    },
    {
      id: 'vatRate',
      numeric: false,
      disablePadding: false,
      label: i18n.table.columns.vatRate.label,
    },
    {
      id: 'totalAmount',
      numeric: false,
      disablePadding: false,
      label: i18n.table.columns.totalAmount.label,
    },
    {
      id: 'purchasedBy',
      numeric: false,
      disablePadding: false,
      label: i18n.table.columns.purchasedBy.label,
    },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: '',
    },
  ];

  //Functions
  const Row = ({ row }) => {
    const { purchaseDisabled } = row;

    const formattedRow = {
      ...row,
      // unlimited if no quantity, soldout if bookable is 0 or less and bookable otherwise
      quantityLabel: !row.availability
        ? i18n.table.unlimitedQuantity
        : row.bookableQuantity <= 0
        ? i18n.table.soldOutQuantity
        : row.bookableQuantity,
      type: constants.OtherStandServiceItemType[row.type].label(intl),
      netPrice: formatNumber(decodeDbNumber(row?.netPrice)),
      vatRate: decodeDbNumber(row.vatRate),
      totalAmount: formatNumber(
        vatCalc(decodeDbNumber(row?.netPrice), decodeDbNumber(row?.vatRate))
      ),
    };

    return (
      <>
        <AimTableRow hover>
          <AimTableCell>{formattedRow.name}</AimTableCell>
          <AimTableCell>{formattedRow.type}</AimTableCell>
          <AimTableCell>{formattedRow.quantityLabel}</AimTableCell>

          <AimTableCell>{formattedRow.netPrice}</AimTableCell>
          <AimTableCell>{`${formattedRow.vatRate} %`}</AimTableCell>

          <AimTableCell>{formattedRow.totalAmount}</AimTableCell>

          <AimTableCell>
            {formattedRow.otherSponsorizationsBookingItems?.items?.length ===
              1 && row.availability === 1
              ? formattedRow.otherSponsorizationsBookingItems.items?.[0]
                  ?.sponsor?.name
              : ''}
          </AimTableCell>

          <AimTableCell>
            <AimIconButton
              variant="primary"
              small
              onClick={() => showInfoDialog(formattedRow)}
            >
              <VisibilityIcon />
            </AimIconButton>
            <AimIconButton
              variant="primary"
              small
              onClick={() => onAddToCart(row, true)}
              disabled={purchaseDisabled}
            >
              <SwapHorizIcon />
            </AimIconButton>
            <AimIconButton
              variant="primary"
              small
              onClick={() => onAddToCart(row)}
              disabled={purchaseDisabled}
            >
              <ShoppingCartIcon />
            </AimIconButton>
          </AimTableCell>
        </AimTableRow>
      </>
    );
  };

  return (
    <>
      <AimTableNoPaging
        headCells={headCells}
        rows={items}
        notCheckable={true}
        rowKey="id"
      >
        <Row />
      </AimTableNoPaging>
    </>
  );
};

export { OtherSponsorizationsItemsListTable };
