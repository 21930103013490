import { aws, appState } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const createSponsorVirtualStandMutation = /* GraphQL */ `
  mutation CreateSponsorVirtualStand(
    $input: CreateSponsorVirtualStandInput!
    $condition: ModelSponsorVirtualStandConditionInput
  ) {
    createSponsorVirtualStand(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateSponsorVirtualStandMutation = /* GraphQL */ `
  mutation UpdateSponsorVirtualStand(
    $input: UpdateSponsorVirtualStandInput!
    $condition: ModelSponsorVirtualStandConditionInput
  ) {
    updateSponsorVirtualStand(input: $input, condition: $condition) {
      id
    }
  }
`;

const deleteSponsorVirtualStandMutation = /* GraphQL */ `
  mutation DeleteSponsorVirtualStand(
    $input: DeleteSponsorVirtualStandInput!
    $condition: ModelSponsorVirtualStandConditionInput
  ) {
    deleteSponsorVirtualStand(input: $input, condition: $condition) {
      id
    }
  }
`;

export function useSponsorVirtualStand() {
  const create = (input, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: createSponsorVirtualStandMutation,
        variables: { input },
      })
        .then((response) => resolve(response.data.createSponsorVirtualStand))
        .catch((e) => {
          console.error('create-sponsor-virtual-stand', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const update = (input, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: updateSponsorVirtualStandMutation,
        variables: { input },
      })
        .then((response) => resolve(response.data.updateSponsorVirtualStand))
        .catch((e) => {
          console.error('update-sponsor-virtual-stand', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const remove = (input, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: deleteSponsorVirtualStandMutation,
        variables: { input },
      })
        .then((response) => resolve(response.data.deleteSponsorVirtualStand))
        .catch((e) => {
          console.error('delete-sponsor-virtual-stand', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  return { create, update, remove };
}
