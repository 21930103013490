import React from 'react';

import { AimTypography, styled } from '@aim/components';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  // justifyContent: 'flex-end',
  // marginRight: 20,
});

export const AgencyCard = ({
  title,
  description,
  style,
  textStyle,
  variant,
  children,
  noBorder,
  ...rest
}) => {
  return (
    <Container
      style={{
        ...style,
        ...(style.backgroundColor
          ? { backgroundColor: style.backgroundColor }
          : { backgroundColor: 'white' }),
      }}
      {...rest}
    >
      <div
        style={{
          borderBottom: noBorder ? 'none' : '1px solid #84819A47',
          margin: '10px 10px',
          padding: '0px 10px',
          minWidth: '300px',
        }}
      >
        <AimTypography variant={variant || 'h3'} boxStyle={{ ...textStyle }}>
          {title}
        </AimTypography>
        {description && (
          <AimTypography
            variant={variant || 'text'}
            boxStyle={{ ...textStyle }}
          >
            {description}
          </AimTypography>
        )}
      </div>
      {children}
    </Container>
  );
};
