import { aws, appState } from '@aim/common';
const { API } = aws;

const getVirtualBagsQuery = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      virtualBagItems {
        items {
          id
          documentPreviewImage {
            id
            originalName
            size
            extension
            createdAt
            updatedAt
          }
          documentTitle
          documentFile {
            id
            originalName
            size
            extension
            createdAt
            updatedAt
          }
          documentSubtitle
          documentData
          documentPosition
        }
      }
    }
  }
`;

export const getVirtualBags = (eventId) =>
  new Promise((resolve, reject) => {
    showLoader();
    API.graphql({
      query: getVirtualBagsQuery,
      variables: {
        id: eventId,
      },
    })
      .then((response) => {
        resolve(response.data.getEvent.virtualBagItems);
      })
      .catch((e) => {
        console.error('get-virtual-bag-items', e);
        reject();
      })
      .finally(hideLoader);
  });

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);
