import React, { useState, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import { makeStyles } from '@material-ui/core/styles';

import { constants, appState } from '@aim/common';
import {
  CustomIntl,
  AimTablePage,
  AimDialog,
  AimTypography,
  DataGridPro,
  styled,
  AimIconAndTextButton,
} from '@aim/components';

import { useDataHelper } from './shared/dataHelper';
import translation from './sponsorStaffDetail/translations';

const cluster = constants.Clusters.SponsorStaff.id;

import {
  deleteStaffMember,
  closeStaffGroup,
} from './sponsorStaffDetail/sponsorStaffDetailGqlHelper';

import { format, isAfter } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { parseISO } from 'date-fns';

const getSponsorStaffQuery = /* GraphQL */ `
  query GetSponsor($id: ID!, $eventId: ID!, $cluster: String!) {
    getDeadline(externalId: $eventId, type: $cluster) {
      value
    }
    getSponsor(id: $id) {
      id
      name
      priceRange {
        id
        freeStaffMembers
        freeStaffTicket {
          id
          start
          end
          label
        }
        freeStaffProfile {
          id
          name
        }
      }
      freeStaffMembers
      freeStaffTicket {
        id
        start
        end
        label
      }
      freeStaffProfile {
        id
        name
      }
      sponsorStaff {
        id
        name
        closingDate
        purchases(filter: { isDeleted: { ne: true } }) {
          items {
            id
            isDeleted
            purchasedSubscriptions
            participationMode
            feeDateRange {
              id
              start
              end
              label
            }
            profile {
              id
              name
            }
            buyOperation {
              id
              purchase {
                id
                payment {
                  id
                  paymentId
                  paymentIdIngenico
                  paymentIdGpWebpay
                  paymentIdMonetaOnLine
                  paymentError
                  paymentType
                }
                paymentType
              }
            }
          }
        }
        participations(filter: { isDeleted: { ne: true } }) {
          items {
            id
            username
            isDeleted
            sponsor {
              id
            }
            givenName
            familyName
            email
            profile {
              id
              name
            }
            feeDateRange {
              id
              start
              end
              label
            }
            type
            createdAt
            isParticipant
            isSpeaker
            isReviewer
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
      display: 'none',
    },
  },
}));

const StyledAgreeButton = styled(AimIconAndTextButton)({
  justifyContent: 'center',
});

const StyledCancelButton = styled(AimIconAndTextButton)({
  justifyContent: 'center',
});

const AddMemberDialogContent = ({
  intl,
  i18n,
  allPlacesRef,
  onClose,
  onAgree,
  history,
  eventId,
  sponsorId,
}) => {
  const classes = useStyles();
  const [selectionModel, setSelectionModel] = useState([]);

  const rows = Object.entries(allPlacesRef.current)
    .map(([key, value]) => ({
      id: key,
      ...value,
    }))
    .filter((i) => i.remainingPlaces > 0);
  const columns = [
    {
      field: 'profile',
      headerName: i18n.addMemberDialog.columns.profile,
      valueGetter: ({ row }) => row.profile.name,
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'ticket',
      headerName: i18n.addMemberDialog.columns.ticket,
      valueGetter: ({ row }) => row.ticket.label,
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'participationMode',
      headerName: i18n.addMemberDialog.columns.participationMode,
      valueGetter: ({ row }) =>
        row.participationMode
          ? Object.values(constants.EventTypes)
              .find(({ id }) => id === row.participationMode)
              ?.label(intl)
          : '',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'available',
      type: 'number',
      headerName: i18n.addMemberDialog.columns.available,
      valueGetter: ({ row }) => row.remainingPlaces,
      flex: 1,
      minWidth: 100,
    },
  ];
  return (
    <>
      {rows.length ? (
        <DataGridPro
          className={classes.root}
          rows={rows}
          columns={columns}
          style={{ height: 300 }}
          //disableMultipleSelection
          checkboxSelection
          disableSelectionOnClick
          onSelectionModelChange={(model) =>
            setSelectionModel((prevModel) =>
              model.filter((i) => !prevModel.includes(i))
            )
          }
          selectionModel={selectionModel}
        />
      ) : (
        <AimTypography variant="text">
          {i18n.addMemberDialog.purchaseTicketsMessage}
        </AimTypography>
      )}
      <div
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'flex-end',
          paddingTop: 20,
        }}
      >
        <StyledCancelButton
          isLowerCase
          onClick={onClose}
          variant="secondary"
          text={i18n.addMemberDialog.buttons.cancel}
        ></StyledCancelButton>
        {rows.length ? (
          <StyledAgreeButton
            isLowerCase
            onClick={() =>
              onAgree(rows.find(({ id }) => id === selectionModel?.[0]))
            }
            variant="primary"
            disabled={!selectionModel.length}
            autoFocus
            text={i18n.addMemberDialog.buttons.insert}
          ></StyledAgreeButton>
        ) : (
          <StyledAgreeButton
            isLowerCase
            onClick={() =>
              history.push(
                `/events/${eventId}/${sponsorId}/my-services/sponsor-staff`
              )
            }
            variant="primary"
            autoFocus
            text={i18n.addMemberDialog.buttons.buy}
          ></StyledAgreeButton>
        )}
      </div>
    </>
  );
};

const SponsorStaffDetail = () => {
  const intl = CustomIntl(useIntl());
  const i18n = translation.sponsorStaffDetail(intl);

  const history = useHistory();
  const { eventId, sponsorId } = useParams();

  const [sponsorStaff, setSponsorStaff] = useState();
  const [deadline, setDeadline] = useState();
  const [staffMembers, setStaffMembers] = useState([]);

  const remainingPlacesRef = useRef(0);
  const freeMembersRef = useRef(0);
  const allPlacesRef = useRef({});
  const [addMemberDialog, setAddMemberDialog] = useState({ isOpen: false });
  const currentTimezone = appState.eventInfo.getValue().timezone;

  const { isValidPurchase } = useDataHelper();

  const handleClick = (id) =>
    history.push(
      `/events/${eventId}/participation/sponsorstaff/${sponsorStaff.id}/${id}`
    );

  const deleteSingleMember = async (id) =>
    await deleteStaffMember(sponsorStaff.id, id);

  const isAfterDeadline = () => {
    if (!deadline?.deadline) return false;

    // add a day so if deadline date is today return true (button is active)
    var nextDate = new Date(deadline?.deadline);
    nextDate.setDate(nextDate.getDate() + 1);

    return isAfter(new Date(), new Date(parseISO(nextDate)));
  };

  const model = (loadData) => ({
    dataLoader: {
      query: getSponsorStaffQuery,
      variables: {
        id: sponsorId,
        eventId,
        cluster: constants.Clusters.SponsorStaff.id,
      },
      transform: (data) => {
        const { priceRange, sponsorStaff, ...sponsor } = data.getSponsor;
        const nextDeadline = data.getDeadline?.value
          ? JSON.parse(data.getDeadline.value)
          : {};
        setDeadline(nextDeadline);
        setSponsorStaff({ ...sponsorStaff, sponsor });
        setStaffMembers(sponsorStaff.participations.items);

        const currentMembersTickets = sponsorStaff.participations.items.reduce(
          (prev, curr) => {
            if (
              prev[
                `${curr?.profile?.id}-${curr?.feeDateRange?.id}-${curr.type}`
              ]
            ) {
              prev[
                `${curr?.profile?.id}-${curr?.feeDateRange?.id}-${curr.type}`
              ] += 1;
            } else {
              prev[
                `${curr?.profile?.id}-${curr?.feeDateRange?.id}-${curr.type}`
              ] = 1;
            }
            return prev;
          },
          {}
        );
        const freeMembers =
          sponsor?.freeStaffMembers || priceRange?.freeStaffMembers || 0;
        const freeMembersProfile =
          sponsor?.freeStaffProfile || priceRange?.freeStaffProfile;
        const freeMembersTicket =
          sponsor.freeStaffTicket || priceRange?.freeStaffTicket;

        const freePlaces =
          freeMembers > 0
            ? {
                [`${freeMembersProfile?.id}-${freeMembersTicket?.id}-${constants.EventTypes.PHYSICAL.id}`]: {
                  purchasedSubscriptions: freeMembers,
                  ticket: freeMembersTicket,
                  profile: freeMembersProfile,
                  participationMode: constants.EventTypes.PHYSICAL.id,
                },
              }
            : {};

        const allPlaces = sponsorStaff.purchases.items.reduce((prev, curr) => {
          if (
            curr?.buyOperation?.purchase &&
            isValidPurchase(curr.buyOperation.purchase)
          ) {
            if (
              prev[
                `${curr?.profile?.id}-${curr?.feeDateRange?.id}-${curr.participationMode}`
              ]
            ) {
              prev[
                `${curr?.profile?.id}-${curr?.feeDateRange?.id}-${curr.participationMode}`
              ].purchasedSubscriptions += curr.purchasedSubscriptions;
            } else {
              prev[
                `${curr?.profile?.id}-${curr?.feeDateRange?.id}-${curr.participationMode}`
              ] = {
                purchasedSubscriptions: curr.purchasedSubscriptions,
                ticket: curr?.feeDateRange,
                profile: curr?.profile,
                participationMode: curr?.participationMode,
              };
            }
          }
          return prev;
        }, freePlaces);

        // i dont neeto to check type of tickets because we check tickets on participations add
        const allPlacesWithRemaining = Object.entries(allPlaces).reduce(
          (prev, [key, curr]) => {
            const remainingPlaces =
              curr.purchasedSubscriptions - (currentMembersTickets?.[key] || 0);

            prev[key] = { ...curr, remainingPlaces };
            return prev;
          },
          {}
        );
        freeMembersRef.current = freeMembers;
        remainingPlacesRef.current = Object.values(
          allPlacesWithRemaining
        ).reduce((prev, curr) => (prev += curr.remainingPlaces), 0);
        allPlacesRef.current = allPlacesWithRemaining;

        const items = data?.getSponsor?.sponsorStaff?.participations.items.map(
          (item) => {
            return {
              ...item,
              feeDateRange: item?.feeDateRange?.label,
              statusLabel:
                constants.ParticipantStatus.find(
                  (ps) => item.status === ps.key
                )?.label(intl) || '-',
              profile: item?.profile?.name,
            };
          }
        );
        return items;
      },
      bigStats: [
        {
          title: 'remainingPlaces',
          value: () => remainingPlacesRef.current || 0,
        },
        {
          title: 'currentMembers',
          value: (tData) => tData.length,
        },
      ],
      stats: [
        {
          title: 'status',
          value: (_, data) =>
            data?.getSponsor?.sponsorStaff?.closingDate
              ? i18n.page.statuses.closed
              : i18n.page.statuses.open,
        },
        {
          title: 'freeMembers',
          value: () => freeMembersRef.current || 0,
        },
      ],
    },
    header: {
      backButton: {
        hiddenWhen: () => true,
      },
      title: () => sponsorStaff?.sponsor?.name,
      action: {
        add: {
          icon: <AddIcon />,
          variant: 'primary',
          onClick: (checkedItems, tData) =>
            setAddMemberDialog({ isOpen: true, rows: tData }),
          disabledWhen: () => sponsorStaff?.closingDate,
        },
        deleteAll: {
          icon: <DeleteIcon />,
          variant: 'primary',
          onClick: 'dialog',
          disabledWhen: (checkedItems) =>
            !checkedItems.length || sponsorStaff?.closingDate,
          dialog: 'deleteAll',
          snackbar: 'delete',
        },
        // close: {
        //   icon: <DoneIcon />,
        //   variant: 'primary',
        //   onClick: 'dialog',
        //   dialog: 'close',
        //   disabledWhen: () => sponsorStaff?.closingDate,
        // },
      },
    },
    table: {
      isRowSelectable: true,
      columnsTemplate: [
        {
          id: 'username',
        },
        {
          id: 'givenName',
        },
        {
          id: 'familyName',
        },
        {
          id: 'email',
        },
        {
          id: 'profile',
        },
        { id: 'feeDateRange' },
        {
          id: 'createdAt',
          valueGetter: ({ row }) => new Date(row.createdAt),
          type: 'dateTime',
          valueFormatter: ({ value }) => format(value, 'dd/MM/yyyy HH:mm:ss'),
        },
      ],
      rowAction: {
        edit: {
          icon: <EditIcon />,
          variant: 'primary',
          onClick: (row) => handleClick(row.id),
          disabledWhen: () => sponsorStaff?.closingDate, // || isAfterDeadline(),
        },
        delete: {
          icon: <DeleteIcon />,
          disabledWhen: (row) => row?.sponsor?.id || sponsorStaff?.closingDate, // || isAfterDeadline(),
          variant: 'redFill',
          onClick: 'dialog',
          dialog: 'delete',
          snackbar: 'delete',
        },
      },
    },
    dialog: {
      delete: {
        onAgree: async (row) => {
          await deleteSingleMember(row.id);
          await loadData();
        },
        onDisagree: () => {},
      },
      deleteAll: {
        onAgree: async (checkedItems) => {
          await deleteSelected(checkedItems);
          await loadData();
        },
        onDisagree: () => {},
      },
      // close: {
      //   onAgree: async () => {
      //     await handleCloseGroup();
      //     await loadData();
      //   },
      //   onDisagree: () => {},
      // },
    },
    snackbar: {
      delete: {},
      // close: {},
    },
  });

  const deleteSelected = async (checkedItems) => {
    const promises = checkedItems.map(async ({ id }) => {
      await deleteSingleMember(id);
      return id;
    });

    await Promise.all(promises);
  };

  const handleAdd = ({ ticket, profile, participationMode }) => {
    history.push({
      pathname: `/events/${eventId}/participation/${cluster}/${sponsorStaff.id}/new`,
      state: {
        newPaxTicketInfo: { feeDateRange: ticket, profile, participationMode },
      },
    });
  };

  const handleCloseGroup = async () => {
    await closeStaffGroup(
      {
        id: sponsorStaff.id,
        closingDate: new Date(),
      },
      staffMembers.map((p) => p.id)
    );
  };

  return (
    <div style={{ width: '90%', margin: 20 }}>
      {sponsorStaff?.closingDate && (
        <AimTypography
          variant="h2"
          style={{ marginLeft: -8 }}
          boxStyle={{ color: 'red' }}
        >
          {i18n.page.groupClosingMessage}
        </AimTypography>
      )}
      <AimTablePage
        model={model}
        translation={i18n.aimTablePage}
        intl={intl}
        isStatsOpenOnInit
      />
      <AimDialog
        fullWidth
        maxWidth="md"
        open={addMemberDialog.isOpen}
        title={i18n.addMemberDialog.title}
        hideAgreeButton
        hideCancelButton
        onClose={() => setAddMemberDialog({ isOpen: false })}
      >
        <AddMemberDialogContent
          {...addMemberDialog}
          intl={intl}
          i18n={i18n}
          allPlacesRef={allPlacesRef}
          onAgree={handleAdd}
          onClose={() => setAddMemberDialog({ isOpen: false })}
          eventId={eventId}
          sponsorId={sponsorId}
          history={history}
        />
      </AimDialog>
    </div>
    // <MainContainer>
    //   <div style={{ flex: 1 }}>
    //     <InnerContainer>
    //       <AimTitleAndButtons
    //         title={`${sponsorName} - ${sponsorStaff?.name || ''}`}
    //       >
    //         <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
    //           <Tooltip title={i18n.page.headerButton.addMember.tooltip}>
    //             <AimIconButton
    //               onClick={handleAdd}
    //               disabled={sponsorStaff && sponsorStaff.closingDate}
    //             >
    //               <AddIcon />
    //             </AimIconButton>
    //           </Tooltip>
    //           <Tooltip title={i18n.page.headerButton.deleteSelected.tooltip}>
    //             <AimIconButton
    //               onClick={handleDeleteSelected}
    //               variant="red"
    //               disabled={
    //                 !checkedItems.length ||
    //                 (sponsorStaff && sponsorStaff.closingDate)
    //               }
    //             >
    //               <DeleteIcon />
    //             </AimIconButton>
    //           </Tooltip>
    //           <AimIconAndTextButton
    //             variant="lightBlue"
    //             disabled={!!sponsorStaff?.closingDate}
    //             onClick={handleCloseGroup}
    //             text={i18n.page.headerButton.closeGroup.button}
    //           >
    //             <DoneIcon />
    //           </AimIconAndTextButton>
    //         </div>
    //       </AimTitleAndButtons>

    //       <div
    //         style={{
    //           backgroundColor: theme.colors.greyScale.grey2,
    //           padding: '20px 20px 30px',
    //           margin: '10px 0px',
    //           borderRadius: 3,
    //         }}
    //       >
    //         <AimBigStats data={bigStatsData} containerStyle={{}} />
    //         <AimStats data={statsData} containerStyle={{}} />
    //       </div>

    //       <Table
    //         eventId={eventId}
    //         data={staffMembers}
    //         onClick={handleClick}
    //         onCancel={handleDelete}
    //         checkedItems={checkedItems}
    //         setCheckedItems={setCheckedItems}
    //         deadline={deadline}
    //         sponsorStaff={sponsorStaff}
    //       />
    //     </InnerContainer>
    //   </div>

    //   <AimDialog
    //     open={dialog.isOpen}
    //     title={i18n.dialog.title}
    //     agreeText={i18n.dialog.agreeText}
    //     onAgree={deleteMember}
    //     disagreeText={i18n.dialog.disagreeText}
    //     onDisagree={() => setDialog({ isOpen: false })}
    //     onClose={() => setDialog({ isOpen: false })}
    //   >
    //     {deletingMember && (
    //       <AimTypography variant="text">
    //         {`${i18n.dialog.message} "${deletingMember.email}"?`}
    //       </AimTypography>
    //     )}
    //   </AimDialog>

    //   <AimDialog
    //     open={dialogBulk.isOpen}
    //     title={i18n.dialogBulk.title}
    //     agreeText={i18n.dialogBulk.agreeText}
    //     onAgree={deleteSelected}
    //     disagreeText={i18n.dialogBulk.disagreeText}
    //     onDisagree={() => setDialogBulk({ isOpen: false })}
    //     onClose={() => setDialogBulk({ isOpen: false })}
    //   >
    //     <AimTypography variant="text">{i18n.dialogBulk.message}</AimTypography>
    //   </AimDialog>

    //   <AimDialog
    //     open={dialogBuy.isOpen}
    //     title={i18n.dialogBuy.title}
    //     agreeText={i18n.dialogBuy.agreeText}
    //     onAgree={gotoBuyNewPlaces}
    //     disagreeText={i18n.dialogBuy.disagreeText}
    //     onDisagree={() => setDialogBuy({ isOpen: false })}
    //     onClose={() => setDialogBuy({ isOpen: false })}
    //   >
    //     {deletingMember && (
    //       <AimTypography variant="text">{i18n.dialogBuy.message}</AimTypography>
    //     )}
    //   </AimDialog>

    //   <AimSnackbar
    //     open={snackbar.isOpen}
    //     onClose={() => setSnackbar({ isOpen: false })}
    //     severity={snackbar.severity}
    //   >
    //     {snackbar.message}
    //   </AimSnackbar>
    // </MainContainer>
  );
};

export default SponsorStaffDetail;
