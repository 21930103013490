import { aws, appState, constants } from '@aim/common';
const { API } = aws;
const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

export const getEventFieldsQuery = /* GraphQL */ `
  query GetEvent($id: ID! $filter: ModelFieldContextFilterInput) {
    getEvent(id: $id) {
      id
      standardFields(filter: { isDeleted: { ne: true } }) {
        items {
          controlType
          id
          isDeleted
          label
          options
          placeholder
          contextsOfUse(filter: $filter) {
            items {
              id
              position
              contextName
              isRequired
              isHidden
            }
          }
        }
      }
      services(filter: { serviceName: { eq: "${constants.Services.GRANT.key}" } }) {
        items {
          serviceName
          id
          customFields(filter: { isDeleted: { ne: true } }) {
            items {
              controlType
              id
              key
              isDeleted
              label
              options
              placeholder
              contextsOfUse(filter: $filter) {
                items {
                  id
                  position
                  contextName
                  isRequired
                  isHidden
                }
              }
            }
          }
        }
      }
    }
  }
`;

const listParticipationFieldValues = /* GraphQL */ `
  query listParticipationFieldValues($id: ID!) {
    getParticipation(id: $id) {
      id
      givenName
      familyName
      email
      fieldValues {
        items {
          id
          value
          fieldDefinition {
            controlType
            id
            label
            options
            placeholder
          }
        }
      }
    }
  }
`;

export const createFieldValueMutation = /* GraphQL */ `
  mutation CreateFieldValue(
    $input: CreateFieldValueInput!
    $condition: ModelFieldValueConditionInput
  ) {
    createFieldValue(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateFieldValueMutation = /* GraphQL */ `
  mutation UpdateFieldValue(
    $input: UpdateFieldValueInput!
    $condition: ModelFieldValueConditionInput
  ) {
    updateFieldValue(input: $input, condition: $condition) {
      id
    }
  }
`;

const deleteFieldValueMutation = /* GraphQL */ `
  mutation DeleteFieldValue(
    $input: DeleteFieldValueInput!
    $condition: ModelFieldValueConditionInput
  ) {
    deleteFieldValue(input: $input, condition: $condition) {
      id
    }
  }
`;

const createParticipationGrantQuery = /* GraphQL */ `
  mutation CreateParticipationGrant(
    $input: CreateParticipationGrantInput!
    $condition: ModelParticipationGrantConditionInput
  ) {
    createParticipationGrant(input: $input, condition: $condition) {
      id
    }
  }
`;

const getParticipationGrantsQuery = /* GraphQL */ `
  query getParticipationGrant($id: ID!) {
    getParticipation(id: $id) {
      participationGrant(filter: { isDeleted: { ne: true } }) {
        items {
          id
          participationGrantGrantId
          participationGrantParticipationId
          abstractId
          grant {
            id
            instruction
            title
          }
        }
      }
    }
  }
`;

const getGrantQuery = /* GraphQL */ `
  query getGrant($id: ID!) {
    getGrant(id: $id) {
      id
      instruction
      title
      type
    }
  }
`;

const getParticipationAbstractsQuery = /* GraphQL */ `
  query getParticipationAbstracts($id: ID!) {
    getParticipation(id: $id) {
      abstractDocuments(filter: { isDeleted: { ne: true } }) {
        items {
          id
          title
          isDeleted
        }
      }
    }
  }
`;

export const getEventFieldsByContext = ({
  eventId,
  grantId,
  contextsOfUse,
  isLoader = true,
}) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();

    aws.API.graphql({
      query: getEventFieldsQuery,
      variables: {
        id: eventId,
        filter: { contextName: { eq: `grant|${grantId}` } },
        // filter: { and: contextsOfUse.map((c) => ({ contextName: { eq: c } })) },
      },
    })
      .then((response) => {
        resolve({
          standardFields: response.data.getEvent.standardFields?.items,
          customFields:
            response.data.getEvent.services?.items[0]?.customFields?.items,
        });
      })
      .catch((e) => {
        console.error('event-fields-list', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });

const unionToContextOfUse = (fields, contexts) => {
  const outFields = fields
    .filter((f) => f.contextsOfUse.items.length)
    .map((field) => {
      let fieldFirstContextOfUse;
      for (let i = 0; i < contexts.length; i++) {
        fieldFirstContextOfUse = field.contextsOfUse.items.find(
          (cu) => cu.contextName === contexts[i]
        );
        if (fieldFirstContextOfUse) break;
      }
      return {
        ...field,
        contextsOfUse: fieldFirstContextOfUse,
        isReadOnly:
          fieldFirstContextOfUse.contextName !== contexts[contexts.length - 1],
      };
    });
  return outFields;
};

export const getDynamicFields = async (eventId, grantId) => {
  if (!eventId) return;

  const contextsOfUse = [`grant|${grantId}`];
  const {
    standardFields: sFields,
    customFields: cFields,
  } = await getEventFieldsByContext({
    eventId,
    grantId,
    isLoader: false,
  });

  const standardFields = unionToContextOfUse(sFields, contextsOfUse);
  const customFields = unionToContextOfUse(cFields, contextsOfUse);

  return { contextsOfUse, fields: [standardFields, customFields] };
};

export const createFieldValue = (input, isLoader = true) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();
    API.graphql({
      query: createFieldValueMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.createFieldValue))
      .catch((e) => {
        console.error('update-field-value', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });

export const updateFieldValue = (input, isLoader = true) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();
    API.graphql({
      query: updateFieldValueMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.updateFieldValue))
      .catch((e) => {
        console.error('update-field-value', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });

export const deleteFieldValue = (input, isLoader = true) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();
    API.graphql({
      query: deleteFieldValueMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.deleteFieldValue))
      .catch((e) => {
        console.error('delete-field-value', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });

export const getParticipationGrantFieldValues = (
  participationId,
  isLoader = true
) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();
    API.graphql({
      query: listParticipationFieldValues,
      variables: { id: participationId },
    })
      .then((response) =>
        resolve(response.data.getParticipation.fieldValues?.items)
      )
      .catch((e) => {
        console.error('get-field-values', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });

export const createParticipationGrant = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: createParticipationGrantQuery,
      variables: { input },
    })
      .then((response) => {
        resolve(response.data.createParticipationGrant);
      })
      .catch((e) => {
        console.error('create-participation-grant', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const getParticipationGrants = (participationId, isLoader = true) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();
    API.graphql({
      query: getParticipationGrantsQuery,
      variables: { id: participationId },
    })
      .then((response) =>
        resolve(response.data.getParticipation.participationGrant?.items)
      )
      .catch((e) => {
        console.error('get-participation-grant', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });

export const getGrant = (grantId, isLoader = true) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();
    API.graphql({
      query: getGrantQuery,
      variables: { id: grantId },
    })
      .then((response) => resolve(response.data.getGrant))
      .catch((e) => {
        console.error('get-grant', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });

export const getParticipationAbstracts = (participationId, isLoader = true) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();
    API.graphql({
      query: getParticipationAbstractsQuery,
      variables: { id: participationId },
    })
      .then((response) =>
        resolve(response.data.getParticipation.abstractDocuments?.items)
      )
      .catch((e) => {
        console.error('get-participation-abstracts', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });
