import { aws, appState } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const getEventOrderQuery = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      eventOrder {
        items {
          id
          code
          codeName
          sponsor {
            id
            name
            isDeleted
          }
        }
      }
    }
  }
`;

export const updateEventOrderQuery = /* GraphQL */ `
  mutation UpdateEventOrder(
    $input: UpdateEventOrderInput!
    $condition: ModelEventOrderConditionInput
  ) {
    updateEventOrder(input: $input, condition: $condition) {
      id
    }
  }
`;

export const getEventOrder = (id) =>
  new Promise((resolve, reject) => {
    showLoader();

    API.graphql({ query: getEventOrderQuery, variables: { id } })
      .then((response) =>
        resolve({
          ...response?.data?.getEvent?.eventOrder,
        })
      )
      .catch((e) => {
        console.error('get-event-order', e);
        reject();
      })
      .finally(hideLoader);
  });

export const updateEventOrder = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    API.graphql({
      query: updateEventOrderQuery,
      variables: { input },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        console.error('update-event-order', e);
        reject();
      })
      .finally(hideLoader());
  });
