import React, { useEffect, useState } from 'react';

import {
  AimTypography,
  AimSelectForm,
  AimTextFieldForm,
  AimSelectMenuItem,
  theme,
} from '@aim/components';
import Grid from '@material-ui/core/Grid';
import { constants } from '@aim/common';
import translation from './translation';

export const Accomodation = ({ formControlStyle, control, intl, data }) => {
  const i18n = translation.allotment(intl);

  return (
    <>
      <AimTypography variant="text" boxStyle={{ margin: '10px 0px 0px 50px' }}>
        {i18n.page.accomodation.pleaseEnterAccomodationRequirements}
      </AimTypography>
      <div
        style={{
          backgroundColor: theme.colors.greyScale.backgroundGrey,
          margin: '10px 50px',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <AimTextFieldForm
              textfieldVariant={'secondary'}
              control={control}
              type="date"
              name="prefsAllotmentCheckInDate"
              placeholder="dd/mm/yyyy"
              label={i18n.page.accomodation.checkInDate}
              formControlStyle={formControlStyle}
              inputProps={{
                min: data?.allotmentDateRange?.start,
                max: data?.allotmentDateRange?.end,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AimTextFieldForm
              textfieldVariant={'secondary'}
              control={control}
              name="prefsAllotmentCheckOutDate"
              type="date"
              placeholder="dd/mm/yyyy"
              label={i18n.page.accomodation.checkOutDate}
              formControlStyle={formControlStyle}
              inputProps={{
                min: data?.allotmentDateRange?.start,
                max: data?.allotmentDateRange?.end,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AimSelectForm
              style={{
                backgroundColor: 'white',
              }}
              control={control}
              name="prefsAllotmentRoomType"
              label={i18n.page.accomodation.roomTypePrefs}
              formControlStyle={formControlStyle}
            >
              {data?.hotelAndRooms?.hotel?.rooms.items?.map((room) => (
                <AimSelectMenuItem key={room.id} value={room.id}>
                  {room.frontofficeName}
                </AimSelectMenuItem>
              ))}
            </AimSelectForm>
          </Grid>
          <Grid item xs={12}>
            <AimTextFieldForm
              textfieldVariant={'secondary'}
              control={control}
              name="prefsAllotmentNotes"
              label={i18n.page.accomodation.notes}
              formControlStyle={formControlStyle}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
