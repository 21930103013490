import React, { useState, useEffect } from 'react';
import { isMobileOnly } from 'react-device-detect';

import { useHistory, useParams } from 'react-router-dom';

import { useIntl } from 'react-intl';
import { sortBy } from 'lodash';
import Grid from '@material-ui/core/Grid';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

import { aws, appState } from '@aim/common';
import {
  AimTypography,
  AimIconButton,
  theme,
  styled,
  CustomIntl,
  AimSelect,
  AimSelectMenuItem,
  AimTextField,
} from '@aim/components';

import { HomeContainer } from './home/style';
// import SponsorFilter from './sponsor/SponsorFilter';
import { getSponsors } from './sponsor/gqlHelper';
import { useDataHelper } from './shared/dataHelper';
import { SponsorCard } from './sponsor/components/SponsorCard';
import { getEventConfigurationPageItem } from '../shared/utilities';

const formControlStyle = { width: '100%' };
const PageContainer = styled('div')({
  // height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const PageContent = styled('div')({
  // width: isMobile ? 'auto' : 1280,
  width: isMobileOnly ? '95vw' : 'calc(99vw * 0.7)',
});

const Sponsors = () => {
  // Hooks
  const history = useHistory();
  const { eventId } = useParams();
  const intl = CustomIntl(useIntl());
  const { isValidPurchase } = useDataHelper();

  // States
  const [sponsorships, setSponsorships] = useState([]);
  const [sponsors, setSponsors] = useState([]);
  const [displaySponsors, setDisplaySponsors] = useState([]);
  const [filter, setFilter] = useState({ name: '', sponsorship: '' });
  const [eventConfiguration] = useState(appState.eventConfiguration.getValue());
  const [genericMenuItem, setGenericMenuItem] = useState();

  // Effects
  useEffect(() => {
    if (eventId) {
      getSponsors({ eventId })
        .then(async (res) => {
          // filter only on sponsor that bought and published a personal page
          let filteredSponsors = res.sponsors.items.filter(
            (sponsor) =>
              isPublicPagePaid(sponsor) && sponsor?.publicPage?.isPublic
          );
          // let filteredSponsors = res.sponsors.items;
          filteredSponsors = await extractSponsorInfo(filteredSponsors);
          filteredSponsors = filteredSponsors.map((sponsor) => {
            const sponsorPriceRangeOrder = res.sponsorPriceRanges?.items.find(
              (x) => x.name === sponsor.sponsorship.name
            )?.order;

            return {
              ...sponsor,
              sponsorPriceRangeOrder,
            };
          });
          const sortedSponsors = sortBy(filteredSponsors, [
            (x) => x.sponsorPriceRangeOrder,
            (x) => x.name.toLowerCase(),
          ]);

          setSponsors(sortedSponsors);

          let sponsorships = extractSponsorShips(res.sponsorPriceRanges.items);
          sponsorships = sponsorships.filter(
            (x) =>
              filteredSponsors.findIndex((y) => y.sponsorship.name === x) > -1
          );
          setSponsorships(sponsorships);
        })
        .catch(console.error);
    }
  }, [eventId]);

  useEffect(() => {
    if (sponsors && sponsors.length > 0) {
      setDisplaySponsors(sponsors);
    }
  }, [sponsors]);

  useEffect(() => {
    const menuItem = getEventConfigurationPageItem(
      eventConfiguration?.eventGenericMenuItems?.items || [],
      intl,
      'sponsor'
    );
    setGenericMenuItem(menuItem);
  }, [eventConfiguration]);

  // Functions
  const extractSponsorInfo = async (sponsorsList) => {
    const normalizedSponsorList = sponsorsList.map(async (s) => {
      const s3FolderProfileImage = `events/${eventId}/sponsor/frontoffice/${s?.id}/profileImage/`;
      console.log('s', s);
      const profileImage = s?.profileImage?.id
        ? await aws.Storage.get(
            `${s3FolderProfileImage}${s?.profileImage?.id}${s?.profileImage?.extension}`
          )
        : null;
      return {
        id: s?.id,
        name: s?.name,
        category: s?.therapeuticArea,
        profileImage,
        price: s?.priceRange?.price || '',
        sponsorship: {
          // order: s?.priceRange?.order,
          name: s?.priceRange?.name || '',
          // color: s?.priceRange?.color,
          color: {
            colorBckgr: s?.priceRange?.backgroundColor || '#ffffff',
            colorIcon: s?.priceRange?.frontendNameColor || '#000000',
          },
        },
      };
    });

    const result = await Promise.all(normalizedSponsorList);

    return result;
  };

  const extractSponsorShips = (sponsorshipList) => {
    return [...new Set(sponsorshipList.map((s) => s.name))];
  };

  const isPublicPagePaid = (sponsor) => {
    return isValidPurchase(sponsor?.publicPage?.buyOperation?.purchase);
  };

  const handleClearFilter = async () => {
    setFilter({ name: '', sponsorship: '' });
    setDisplaySponsors(sponsors);
  };

  const handleSelectSponsorship = async (e) => {
    setFilter({ ...filter, sponsorship: e.target.value });
  };

  const updateDisplaySponsors = (e) => {
    e.preventDefault();

    const toDisplaySponsors = sponsors
      .filter(
        (sponsor) =>
          !filter.sponsorship ||
          sponsor.sponsorship?.name?.toLowerCase() ===
            filter.sponsorship?.toLowerCase()
      )
      .filter((sponsor) => {
        return sponsor.name?.toLowerCase().includes(filter.name?.toLowerCase());
      });

    setDisplaySponsors(toDisplaySponsors);
  };

  console.log('displaySponsors', displaySponsors);

  return (
    <HomeContainer>
      <PageContainer>
        <PageContent>
          <AimTypography variant="h1">{genericMenuItem?.label}</AimTypography>
          <div style={{ marginRight: 30 }}>
            {/* <SponsorFilter
              i18n={i18n.filter}
              onFilterResult={setDisplaySponsors}
              rows={sponsors}
              sponsorships={sponsorships}
              isWidthOfFiltersEqual
            /> */}
            <form onSubmit={(e) => updateDisplaySponsors(e)}>
              <Grid container alignItems="center">
                <Grid item xs={12} sm={6}>
                  <AimTextField
                    formControlStyle={formControlStyle}
                    value={filter.name}
                    onChange={(e) =>
                      setFilter({ ...filter, name: e.target.value })
                    }
                    placeholder="Name"
                    displayLabel={false}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div
                    style={{
                      display: 'flex',
                      flex: 1,
                      alignItems: 'center',
                      padding: isMobileOnly ? '0px 3px' : '0px 0px',
                    }}
                  >
                    {genericMenuItem?.showPremiumRangesFlagChecked ? (
                      <AimSelect
                        formControlStyle={{ flex: 1 }}
                        value={filter.sponsorship || ''}
                        hasNoneValue
                        displayLabel={false}
                        selectPlaceholder="Select sponsorship"
                        onChange={(e) => handleSelectSponsorship(e)}
                      >
                        {sponsorships.map((item, idx) => {
                          return (
                            <AimSelectMenuItem key={idx} value={item}>
                              {item}
                            </AimSelectMenuItem>
                          );
                        })}
                      </AimSelect>
                    ) : null}
                    {/* </Grid> */}
                    {/* <Grid item sm={1}> */}
                    <AimIconButton type="submit" variant="primary">
                      <CheckIcon />
                    </AimIconButton>
                    {/* </Grid> */}
                    {/* <Grid item sm={1}> */}
                    <AimIconButton
                      variant="secondary"
                      aria-label="reset"
                      onClick={handleClearFilter}
                    >
                      <ClearIcon />
                    </AimIconButton>
                  </div>
                </Grid>
              </Grid>
            </form>
          </div>
          <Grid container>
            {displaySponsors.map((s) => {
              return (
                <Grid
                  item
                  key={s.id}
                  xs={12}
                  sm={6}
                  lg={4}
                  xl={3}
                  style={
                    isMobileOnly
                      ? {
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection: 'column',
                          padding: '6px',
                        }
                      : { padding: '6px' }
                  }
                >
                  <SponsorCard
                    onClick={() => {
                      history.push({
                        pathname: `/events/${eventId}/sponsors/${s.id}`,
                        state: {
                          fromSponsorList: true,
                        },
                      });
                    }}
                    style={{
                      cursor: 'pointer',
                      // flex: '0 0 21%',
                      background: theme.colors.greyScale.backgroundGrey,
                      boxShadow: '0 12px 16px rgb(0 0 0 / 12%)',
                    }}
                    title={s.name}
                    subTitle={s.category}
                    img={s.profileImage}
                    range={
                      genericMenuItem?.showPremiumRangesFlagChecked
                        ? s.sponsorship
                        : null
                    }
                    priceRange={s.priceRange}
                  />
                </Grid>
              );
            })}
          </Grid>
        </PageContent>
      </PageContainer>
    </HomeContainer>
  );
};

export default Sponsors;
