export default {
  PublicLandingPage: (intl) => ({
    page: {
      register: intl.formatMessage({
        description: 'Register button public landing page',
        defaultMessage: 'REGISTER',
      }),
      login: intl.formatMessage({
        description: 'Login button public landing page',
        defaultMessage: 'LOGIN',
      }),
      survey: intl.formatMessage({
        description: 'Survey button public landing page',
        defaultMessage: 'SURVEY',
      }),
    },
  }),
};
