import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import useI18n from './reviewersAbstractsWall/i18n';
import { isMobile } from 'react-device-detect';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Visibility from '@material-ui/icons/Visibility';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import AnalyticsCard from './reviewersAbstractsWall/AnalyticsCard';

import { appState } from '@aim/common';
import {
  AimTitleAndButtons,
  AimIconButton,
  AimIconAndTextButton,
  AimTypography,
  AimListItem,
  CustomIntl,
} from '@aim/components';

const ReviewersAbstractsWall = () => {
  const history = useHistory();
  const intl = CustomIntl(useIntl());
  const i18n = useI18n(intl);
  const { eventId } = useParams();
  const [participation, setParticipation] = useState();
  const [abstractsDeclined, setAbstractsDeclined] = useState();
  const [deletedCounter, setDeletedCounter] = useState();
  const [suspendedCounter, setSuspendedCounter] = useState();
  const [deliveryDate, setDeliveryDate] = useState();

  const analyticsCalculations = async (abstract) => {
    //abstracts declined
    let arrayFiltered = abstract.filter((elem) => elem.status === 'declined');
    setAbstractsDeclined(arrayFiltered.length);
    //deleted abstracts counter
    arrayFiltered = abstract.filter((elem) => elem.isDeleted);
    setDeletedCounter(arrayFiltered.length);
    //suspended counter
    arrayFiltered = abstract.filter((elem) => elem.status === 'suspended');
    setSuspendedCounter(arrayFiltered.length);
    //delivery date
    setDeliveryDate('00/00/0000');
  };

  const initValues = async () => {
    try {
      const participationData = appState.user.getValue()?.userAndParticipation
        ?.participation;
      setParticipation(participationData);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    initValues();
    appState.selectedMenuItem.next('congress');
  }, []);

  // Columns
  const headCells = [
    {
      id: 'title',
      numeric: false,
      disablePadding: false,
      label: i18n.table.columnsTitles.title,
    },
    {
      id: 'category',
      numeric: false,
      disablePadding: false,
      label: i18n.table.columnsTitles.category,
    },
    {
      id: 'subCategory',
      numeric: false,
      disablePadding: false,
      label: i18n.table.columnsTitles.subCategory,
    },
    {
      id: 'preferred',
      numeric: false,
      disablePadding: false,
      label: i18n.table.columnsTitles.preferred,
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: i18n.table.columnsTitles.status,
    },
    {
      id: 'finalScore',
      numeric: false,
      disablePadding: false,
      label: i18n.table.columnsTitles.finalScore,
    },
  ];

  const Row = () => {
    return (
      <AimListItem rowvariant="white">
        <div
          style={{
            flex: 1,
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <AimTypography variant="columnHeader">
              {i18n.page.categories}
            </AimTypography>
            <div
              style={{
                flex: 1,
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <AimTypography>
                {participation?.reviewer?.favoriteCategory?.name || '-'}
              </AimTypography>
              <AimTypography>
                {participation?.reviewer?.favoriteSubCategory?.name || '-'}
              </AimTypography>
            </div>
          </div>
          <Tooltip title={i18n.table.rowButton}>
            <AimIconButton
              variant="lightBlueFill"
              onClick={() =>
                history.push({
                  pathname: `/events/${eventId}/reviewer-category`,
                  state: {
                    reviewer: participation?.reviewer,
                  },
                })
              }
            >
              <Visibility />
            </AimIconButton>
          </Tooltip>
        </div>
      </AimListItem>
    );
  };

  const AnalyticsCardsGrid = () => {
    return (
      <Grid
        container
        spacing={1}
        alignItems="center"
        style={{
          display: isMobile ? 'inline-block' : 'flex',
        }}
      >
        <AnalyticsCard
          title={i18n.page.analyticsCards.deliveryDate}
          subtitle={deliveryDate || '00/00/00'}
        />
        <AnalyticsCard
          title={i18n.page.analyticsCards.abstractAssigned}
          subtitle={suspendedCounter || 0}
        />
        <AnalyticsCard
          title={i18n.page.analyticsCards.abstractEvaluated}
          subtitle={deletedCounter || 0}
        />
        <AnalyticsCard
          title={i18n.page.analyticsCards.abstractDeclined}
          subtitle={abstractsDeclined || 0}
        />
      </Grid>
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        maxHeight: 'calc(100vh - 120px)',
        minWidth: '80vw',
        overflow: 'auto',
      }}
    >
      <div>
        <Tooltip title={i18n.page.backButton}>
          <AimIconAndTextButton
            isUpperCase
            variant="none"
            text={i18n.page.backButton.tooltip}
            style={{
              padding: 0,
            }}
            onClick={() =>
              history.push(`/events/${eventId}/abstracts-reviewers`)
            }
          >
            <ArrowBack />
          </AimIconAndTextButton>
        </Tooltip>
      </div>
      <div style={{ flex: 1 }}>
        <AimTitleAndButtons
          title={`${participation?.givenName} ${participation?.familyName}`}
        />
        {/* <AimList
          rows={reviews}
          deletable={false}
          rowKey="id"
          childrenProps={{ i18n }}
          HeaderRow={
            <AimTypography variant="columnHeader">
              {i18n.page.list.title}
            </AimTypography>
          }
        > */}
        <Row />
        {/* </AimList> */}
        <AnalyticsCardsGrid />
      </div>
    </div>
  );
};

export default ReviewersAbstractsWall;
