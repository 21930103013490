import React from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

import {
  AimIconAndTextButton,
  AgencyWelcomePageTemplate,
} from '@aim/components';

import { DataContainer } from './DataContainer';

export const AgencyLanding = ({ eventId, data }) => {
  const history = useHistory();

  return (
    <DataContainer>
      <AgencyWelcomePageTemplate
        data={data}
        actionButtons={
          <Grid container alignItems="center" style={{ height: '100%' }}>
            <Grid item xs>
              <AimIconAndTextButton
                isUpperCase
                text="Access to groups"
                onClick={() =>
                  history.push(`/events/${eventId}/agency/${data.id}`)
                }
              />
            </Grid>
            {/* <Grid item xs>
              <AimIconAndTextButton
                isUpperCase
                text="Access to services"
                onClick={() =>
                  history.push(`/events/${eventId}/${data.id}/price-ranges`)
                }
              />
            </Grid> */}
            {/* <Grid item xs>
              <AimIconAndTextButton
                isUpperCase
                text="Access to event"
                onClick={() => history.push(`/events/${eventId}/homepage`)}
              />
            </Grid> */}
          </Grid>
        }
      />
    </DataContainer>
  );
};
