export default function (intl) {
  return {
    page: {
      backButton: {
        label: intl.formatMessage({
          description: 'backLabel',
          defaultMessage: 'back',
        }),
        tooltip: intl.formatMessage({
          description: 'backTooltip',
          defaultMessage: 'Back',
        }),
      },
      forwardButton: {
        label: intl.formatMessage({
          description: 'forwardLabel',
          defaultMessage: 'forward',
        }),
        tooltip: intl.formatMessage({
          description: 'forwardTooltip',
          defaultMessage: 'Forward',
        }),
      },
      saveButton: {
        label: intl.formatMessage({
          description: 'saveLabel',
          defaultMessage: 'save and close',
        }),
        tooltip: intl.formatMessage({
          description: 'saveTooltip',
          defaultMessage: 'save and close',
        }),
      },
      eventButton: {
        label: intl.formatMessage({
          description: 'eventLabel',
          defaultMessage: 'Go to Event',
        }),
        tooltip: intl.formatMessage({
          description: 'eventTooltip',
          defaultMessage: 'go to event',
        }),
      },
      title: intl.formatMessage({
        description: 'breakoutRoomAppointmentTitle',
        defaultMessage: 'Book an appointment',
      }),
      subTitle: intl.formatMessage({
        description: 'breakoutRoomAppointmentSubTitle',
        defaultMessage:
          'Book an appointment for an available slot with the Sponsor',
      }),
      noBrAvailable: intl.formatMessage({
        description: 'breakoutRoomAppointmentTitleNoBr',
        defaultMessage:
          'There are no breakout rooms available for booking an appointment',
      }),
    },
    form: {
      title: intl.formatMessage({
        description: 'formTitle',
        defaultMessage: 'Select date and time to book your appointment',
      }),
      dateSubtitle: intl.formatMessage({
        description: 'formdateSubtitle',
        defaultMessage: 'Available dates',
      }),
      firstRange: intl.formatMessage({
        description: 'formdateFirstRange',
        defaultMessage: 'First Slots Group',
      }),
      secondRange: intl.formatMessage({
        description: 'formdateSecondRange',
        defaultMessage: 'Second Slots Group',
      }),
      notes: intl.formatMessage({
        description: 'formatNotes',
        defaultMessage: 'Notes',
      }),
    },
    snackbar: {
      noSlotSelected: intl.formatMessage({
        description: 'noSlot',
        defaultMessage: 'You Need to select an available slot',
      }),
      sendSuccess: intl.formatMessage({
        description: 'genericError',
        defaultMessage: 'You have succesfully booked an appointment',
      }),
      genericError: intl.formatMessage({
        description: 'genericError',
        defaultMessage: 'Something went wrong, please try again',
      }),
    },
    confirmPage: {
      exhibitor: intl.formatMessage({
        description: 'exhibitor',
        defaultMessage: 'Exhibitor',
      }),
      summary: intl.formatMessage({
        description: 'summary',
        defaultMessage: 'Summary',
      }),
      typology: intl.formatMessage({
        description: 'visitTypology',
        defaultMessage: 'Visit Typology',
      }),
      date: intl.formatMessage({
        description: 'date',
        defaultMessage: 'Date',
      }),
      time: intl.formatMessage({
        description: 'time',
        defaultMessage: 'Time',
      }),
      notes: intl.formatMessage({
        description: 'formatNotesConfirmPage',
        defaultMessage: 'Notes',
      }),
      thanks: intl.formatMessage({
        description: 'thanksConfirmPage',
        defaultMessage: 'Thank You',
      }),
      longMessage: intl.formatMessage({
        description: 'longMessageConfirmPage',
        defaultMessage:
          'You have confirmed you would like to participate at the appointment with',
      }),
      theDay: intl.formatMessage({
        description: 'theDayConfirmPage',
        defaultMessage: 'in date',
      }),
      from: intl.formatMessage({
        description: 'from',
        defaultMessage: 'from',
      }),
      to: intl.formatMessage({
        description: 'toConfirmPage',
        defaultMessage: 'to',
      }),
      endMessage: intl.formatMessage({
        description: 'endMessageConfirmPage',
        defaultMessage:
          'You will be able to enter the Breakout Room from the link in Agenda',
      }),
    },
  };
}
