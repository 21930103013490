import React from 'react';

import { constants } from '@aim/common';

import { AimNumericInput, AimTypography } from '@aim/components';

const AddToCartDialogContent = ({
  i18n,
  addToCartDialog,
  setAddToCartDialog,
  isPlusDisabled,
}) => {
  //Constants
  const itemTypes = constants.OtherStandServiceItemType;
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <AimTypography variant="textBold">{i18n.dialog.quantity}</AimTypography>
        <AimNumericInput
          value={addToCartDialog?.quantity}
          editableInput
          onClickPlus={() =>
            setAddToCartDialog({
              ...addToCartDialog,
              quantity: addToCartDialog.quantity + 1,
            })
          }
          onClickMinus={() =>
            setAddToCartDialog({
              ...addToCartDialog,
              quantity: addToCartDialog.quantity - 1,
            })
          }
          onChange={(value) => {
            setAddToCartDialog({
              ...addToCartDialog,
              quantity: value,
            });
          }}
          disabledPlus={isPlusDisabled}
          disabledMinus={addToCartDialog?.quantity <= 1}
        />
      </div>
      {addToCartDialog?.item?.type === itemTypes.doubleCost.id && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <AimTypography variant="textBold">
            {addToCartDialog?.item?.amountPerQuantityLabel}
          </AimTypography>
          <AimNumericInput
            value={addToCartDialog?.amountPerQuantity}
            editableInput
            onClickPlus={() =>
              setAddToCartDialog({
                ...addToCartDialog,
                amountPerQuantity: addToCartDialog.amountPerQuantity + 1,
              })
            }
            onClickMinus={() =>
              setAddToCartDialog({
                ...addToCartDialog,
                amountPerQuantity: addToCartDialog.amountPerQuantity - 1,
              })
            }
            onChange={(value) => {
              setAddToCartDialog({
                ...addToCartDialog,
                quantity: value,
              });
            }}
            disabledPlus={false}
            disabledMinus={addToCartDialog?.amountPerQuantity <= 1}
          />
        </div>
      )}
    </>
  );
};

export default AddToCartDialogContent;
