import { aws, appState } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const getSymposiumsSessions = /* GraphQL */ `
  query GetSymposiumsSessions($id: ID!) {
    getEvent(id: $id) {
      sponsorServiceBookingDuration
      symposiumService {
        description
        deadline
        vatRate
        isCreditCardEnabled
        isBankTransferEnabled
        documents {
          items {
            id
            name: originalName
            size
            extension
          }
        }
        symposiums(filter: { price: { gt: 0 } }) {
          items {
            agendaSession {
              date
              end
              id
              name
              start
              room {
                id
                name
              }
            }
            sponsorPackageItem {
              package {
                buyOperations {
                  items {
                    id
                    isDeleted
                    isExpired
                  }
                }
              }
            }
            sponsor {
              id
            }
            buyOperation {
              id
              isDeleted
              isExpired
            }
            id
            price
          }
        }
      }
    }
  }
`;

const updateSymposium = /* GraphQL */ `
  mutation UpdateSymposium(
    $input: UpdateSymposiumInput!
    $condition: ModelSymposiumConditionInput
  ) {
    updateSymposium(input: $input, condition: $condition) {
      id
    }
  }
`;

const onUpdateSymposium = /* GraphQL */ `
  subscription OnUpdateSymposium {
    onUpdateSymposium {
      id
    }
  }
`;

const onDeleteSymposium = /* GraphQL */ `
  subscription OnDeleteSymposium {
    onDeleteSymposium {
      id
    }
  }
`;

export function useSymposiums() {
  const list = (id, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({ query: getSymposiumsSessions, variables: { id } })
        .then((response) => {
          const nextEventData = {
            ...response.data.getEvent,
            symposiumService: {
              ...response.data.getEvent?.symposiumService,
              symposiums: {
                items: response.data.getEvent?.symposiumService?.symposiums?.items?.map(
                  (symposium) => {
                    const bo =
                      symposium.buyOperation ||
                      symposium.sponsorPackageItem?.package?.buyOperations
                        ?.items?.[0];
                    return {
                      ...symposium,
                      buyOperation:
                        bo && bo.isDeleted !== 'true' && bo.isExpired !== 'true'
                          ? bo
                          : undefined,
                    };
                  }
                ),
              },
            },
          };
          resolve(nextEventData);
        })
        .catch((e) => {
          console.error('event-list-agenda-sessions-list', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const update = (input, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({ query: updateSymposium, variables: { input } })
        .then((response) => resolve(response.data.updateSymposium))
        .catch((e) => {
          console.error('event-update-sponsor-symposium', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const onUpdate = (callback) =>
    aws.standardAPI.graphql({ query: onUpdateSymposium }).subscribe({
      next: ({ value }) => callback(value.data.onUpdateSymposium),
      error: (e) => {
        console.error('on-update-symposium', e);
      },
    });

  const onDelete = (callback) =>
    aws.standardAPI.graphql({ query: onDeleteSymposium }).subscribe({
      next: ({ value }) => callback(value.data.onDeleteSymposium),
      error: (e) => {
        console.error('on-delete-symposium', e);
      },
    });

  return { list, update, onUpdate, onDelete };
}
