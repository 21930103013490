import React, { useEffect, useState } from 'react';

import { isMobileOnly } from 'react-device-detect';
import { useHistory, useParams, useLocation } from 'react-router-dom';

import RoomsAndParticipants from './allotmentBookingData/RoomsAndParticipants';

import { appState } from '@aim/common';

import {
  AimIconAndTextButton,
  // AimSnackbarSeverity,
  AimTitleAndButtons,
  AimTypography,
  // AimSnackbar,
  // AimRichText,
  CustomIntl,
  styled,
} from '@aim/components';

import { format } from 'date-fns';

import Grid from '@material-ui/core/Grid';
// import ClearIcon from '@material-ui/icons/Clear';
import ArrowBack from '@material-ui/icons/ArrowBack';

import { useIntl } from 'react-intl';
import { translation } from './allotmentBookingData/translation';
import {
  getHotelEventVentureInformations,
  getParticipantsForTheEvent,
} from './allotmentBookingData/gqlHelper';

//--- CUSTOM COMPONENTS
const HotelDetailPage = styled('div')({
  // width: '80%',
  width: isMobileOnly ? '90%' : '80%',
  height: '100%',
  // margin: 'auto',
  overflow: 'hidden',
});

const priceInformationGrid = {
  marginBottom: '1%',
  borderBottom: '1px solid black',
};

// ALLOTMENT BOOKING COMPONENT
const AllotmentBookingData = () => {
  // const { register, control, handleSubmit, watch } = useForm({
  //   reValidateMode: 'onChange',
  //   shouldUnregister: false,
  //   defaultValues: {
  //     rooms: [],
  //     // test: [{ firstName: 'Bill', lastName: 'Luo' }],
  //   },
  // });

  // const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [eventVentureDetails, setEventVentureDetails] = useState(null);

  const [whoAmI, setWhoAmI] = useState({});

  const intl = CustomIntl(useIntl());
  const i18n = translation.bookingData(intl);
  const history = useHistory();
  const {
    eventId,
    // hotelId
    hotelEventVentureId,
  } = useParams();
  const { state } = useLocation();

  const [participantsForTheEvent, setParticipantsForTheEvent] = useState([]);

  useEffect(() => {
    if (participantsForTheEvent.length && JSON.stringify(whoAmI) === '{}') {
      var _user = participantsForTheEvent.find(
        (x) =>
          appState.user.getValue().userAndParticipation.participation.id ===
          x.value
      );
      setWhoAmI(_user);
    }
  }, [participantsForTheEvent]);

  useEffect(() => {
    const hotelEventVentureInformations = async () => {
      const hotelEventVenture = await getHotelEventVentureInformations(
        state,
        hotelEventVentureId
      );
      setEventVentureDetails(hotelEventVenture);
    };

    getParticipantsForTheEvent({ participationEventId: eventId }).then(
      (res) => {
        setParticipantsForTheEvent(
          res.length
            ? res.map((participant) => {
                return {
                  value: participant.id,
                  familyName: participant.familyName,
                  givenName: participant.givenName,
                  email: participant.email,
                  label: `${participant.givenName} ${participant.familyName} <${participant.email}>`,
                  phone: participant.phone,
                };
              })
            : []
        );
      }
    );

    hotelEventVentureInformations();
  }, []);

  // const onClickGoToPayment = (formData) => {
  //   console.log('Form data: ', formData);
  // };

  return (
    <>
      <HotelDetailPage>
        <AimIconAndTextButton
          // isUpperCase
          variant="none"
          text={i18n.page.back}
          style={{
            padding: 0,
          }}
          onClick={() => {
            // const stateData = {
            //   checkIn: state.checkIn,
            //   checkOut: state.checkOut,
            //   hotelEventVentureId: state.hotelEventVentureId,
            // };
            history.push({
              pathname: `/events/${eventId}/allotment/${hotelEventVentureId}`,
              state: { ...state },
            });
          }}
        >
          <ArrowBack />
        </AimIconAndTextButton>
        <AimTitleAndButtons title={i18n.page.title} />

        <Grid container direction="column">
          <Grid
            container
            style={{
              backgroundColor: 'white',
              padding: isMobileOnly ? '3%' : '1%',
              marginBottom: '1%',
            }}
            direction="column"
            spacing={1}
          >
            <Grid item container>
              <Grid item xs={2} sm={2} md={1}>
                <b>{i18n.page.hotelName}:</b>
              </Grid>
              <Grid item xs={10} sm={10} md={11}>
                {state?.hotelDetails?.name}
              </Grid>
            </Grid>
            <Grid item container>
              <Grid item xs={2} sm={2} md={1}>
                <b>{i18n.page.checkIn}:</b>
              </Grid>
              <Grid item xs={10} sm={10} md={11}>
                {format(new Date(state?.checkIn), 'dd/MM/YYY')}
              </Grid>
            </Grid>
            <Grid item container>
              <Grid item xs={2} sm={2} md={1}>
                <b>{i18n.page.checkOut}:</b>
              </Grid>
              <Grid item xs={10} sm={10} md={11}>
                {format(new Date(state?.checkOut), 'dd/MM/YYY')}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              backgroundColor: 'white',
              padding: isMobileOnly ? '3%' : '1%',
              marginBottom: isMobileOnly ? '3%' : '1%',
            }}
            direction="column"
          >
            <Grid item style={{ marginBottom: isMobileOnly ? '3%' : '1%' }}>
              <AimTypography
                variant="h4"
                style={{ borderBottom: '1px solid black' }}
              >
                {i18n.page.roomsChoice}
              </AimTypography>
            </Grid>
            <Grid container direction="column" style={{ marginBottom: '1%' }}>
              <RoomsAndParticipants
                data={state?.rooms}
                VATPercentage={eventVentureDetails?.event?.allotmentVat}
                vatId={eventVentureDetails?.event?.allotmentEventVat?.id}
                startDate={state?.checkIn}
                endDate={state?.checkOut}
                {...{
                  participantsForTheEvent,
                  isMobileOnly,
                  eventVentureDetails,
                  i18n,
                  priceInformationGrid,
                  whoAmI,
                  eventId,
                }}
                onClickSubmit={() =>
                  // go to billing
                  history.push(
                    `/events/${eventId}/allotment/${hotelEventVentureId}/billing-info`
                  )
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </HotelDetailPage>
      {/* <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar> */}
    </>
  );
};

export default AllotmentBookingData;
