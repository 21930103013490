import React, { useState, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import LoginLayout from './shared/LoginLayout';
import Grid from '@material-ui/core/Grid';
// import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import {
  AimTextField,
  AimTypography,
  AimIconAndTextButton,
  AimSnackbarSeverity,
  AimSnackbar,
  CustomIntl,
} from '@aim/components';
import { translations } from './shared/translations';
import { useIntl } from 'react-intl';

import { appState, aws } from '@aim/common';

const formControlStyle = { width: 'calc(100% - 10px)' };

const ForgotUsername = () => {
  const intl = CustomIntl(useIntl());
  const i18n = translations.forgotUsername(intl);
  const { eventId } = useParams();
  const history = useHistory();
  const [snackbar, setSnackbar] = useState({ isOpen: false });

  const { isLoader } = appState;

  const setIsWorking = (state) => {
    isLoader.next(state);
  };

  const emailRef = useRef(null);

  const forgotUsername = async ({ emailRef }) => {
    const email = emailRef?.current?.value;
    setIsWorking(true);

    if (!email) {
      setIsWorking(false);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: `${i18n.snackbar.fill}`,
      });
      return false;
    }

    const input = {
      body: {
        recoverUsernamesEmail: [email],
        trigger: 'recover_usernames',
        eventId,
      },
    };
    const apiName = 'apiSendMail';
    const path = '/recoverUsername';
    try {
      await aws.standardAPI.post(apiName, path, input);
      window.dataLayer.push({
        event: 'forgot-username',
        event_id: eventId,
        email: email,
      });
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.success,
        message: `${i18n.snackbar.success}`,
      });
    } catch (e) {
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: `${i18n.snackbar.error}`,
      });
      console.log(e, 'error');
    }

    setIsWorking(false);
  };

  const backToLogin = () => {
    history.push(`/events/${eventId}/login`);
  };

  return (
    <LoginLayout {...{ title: i18n.pageTitle }}>
      <AimTypography {...{ variant: 'text', style: { marginTop: -5 } }}>
        {i18n.pageSubtitle}
      </AimTypography>
      <form
        {...{
          autoComplete: 'off',
          onSubmit: (e) => {
            e.preventDefault();
            forgotUsername({ emailRef });
          },
        }}
      >
        <Grid {...{ container: true, style: { marginTop: 20 } }}>
          <Grid {...{ item: true, xs: 12 }}>
            <AimTextField
              {...{
                textfieldVariant: 'grey',
                placeholder: i18n.email,
                formControlStyle,
                inputRef: emailRef,
              }}
            />
          </Grid>

          <Grid {...{ item: true, xs: 12 }}>
            <AimTypography>
              {i18n.backTo}
              <b
                {...{
                  style: { cursor: 'pointer' },
                  onClick: backToLogin,
                }}
              >
                {` ${i18n.login} `}
              </b>
            </AimTypography>
          </Grid>
          <Grid
            {...{
              item: true,
              xs: 12,
              md: 6,
              style: {
                display: 'flex',
                justifyContent: 'flex-end',
                margin: '20px 14px 0px',
              },
            }}
          >
            <AimIconAndTextButton
              {...{
                text: i18n.confirm,
                type: 'submit',
                isUpperCase: true,
                style: { width: '100%' },
              }}
            />
          </Grid>
        </Grid>
      </form>
      <AimSnackbar
        {...{
          open: snackbar.isOpen,
          onClose: () => setSnackbar({ isOpen: false }),
          severity: snackbar.severity,
        }}
      >
        {snackbar.message}
      </AimSnackbar>
    </LoginLayout>
  );
};
export default ForgotUsername;
