import { aws, appState } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

export const createSponsorAreaMutation = /* GraphQL */ `
  mutation CreateSponsorArea(
    $input: CreateSponsorAreaInput!
    $condition: ModelSponsorAreaConditionInput
  ) {
    createSponsorArea(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateSponsorAreaMutation = /* GraphQL */ `
  mutation UpdateSponsorArea(
    $input: UpdateSponsorAreaInput!
    $condition: ModelSponsorAreaConditionInput
  ) {
    updateSponsorArea(input: $input, condition: $condition) {
      id
    }
  }
`;

const deleteSponsorAreaMutation = /* GraphQL */ `
  mutation DeleteSponsorArea(
    $input: DeleteSponsorAreaInput!
    $condition: ModelSponsorAreaConditionInput
  ) {
    deleteSponsorArea(input: $input, condition: $condition) {
      id
    }
  }
`;

export function useSponsorArea() {
  const create = (input, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({ query: createSponsorAreaMutation, variables: { input } })
        .then(async (response) => {
          resolve(response.data.createSponsorArea);
        })
        .catch((e) => {
          console.error('create-sponsor-area', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const update = (input, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({ query: updateSponsorAreaMutation, variables: { input } })
        .then(async (response) => {
          resolve(response.data.updateSponsorArea);
        })
        .catch((e) => {
          console.error('update-sponsor-area', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const remove = (input, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({ query: deleteSponsorAreaMutation, variables: { input } })
        .then(async (response) => {
          resolve(response.data.deleteSponsorArea);
        })
        .catch((e) => {
          console.error('delete-sponsor-area', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  return { create, update, remove };
}
