import React, { useState, useEffect } from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import ArrowBack from '@material-ui/icons/ArrowBack';

import { useParams, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { usePhysicalStandService } from '../../shared/physicalStandGqlHelper';
import { useDataHelper } from '../../shared/dataHelper';
import translation from '../../shared/translation';
import { BoughtSpacesTable } from './physicalStandSpaces/BoughtSpacesTable';

import {
  AimIconAndTextButton,
  AimTitleAndButtons,
  AimTypography,
  AimSnackbar,
  CustomIntl,
} from '@aim/components';

import {
  MainContainer,
  InnerContainer,
} from '../../shared/SponsorPagesContainers';

const PhysicalStandSpaces = () => {
  //Hooks
  const { eventId, sponsorId } = useParams();
  const history = useHistory();
  const physicalStandServiceHelper = usePhysicalStandService();
  const { isValidPurchase } = useDataHelper();

  const intl = CustomIntl(useIntl());
  const i18n = translation.physicalStandSpaces(intl);

  //State
  const [displaySpaces, setDisplaySpaces] = useState([]);
  const [snackbar, setSnackbar] = useState({ isOpen: false });

  //Effects
  useEffect(() => {
    fetchBoughtPhysicalStandSpaces();
  }, []);

  //Functions
  const fetchBoughtPhysicalStandSpaces = async () => {
    const fetchedSpaces = await physicalStandServiceHelper.listBySponsor(
      sponsorId
    );

    // solo gli spaces con purchases in modo da tornare solo quelli
    // realmente acquistati
    const nextSpaces = fetchedSpaces
      .map((space) => ({
        ...space,
        buyOperation:
          space.buyOperation ||
          space.sponsorPackageItem?.package?.buyOperations.items?.[0],
        physicalStandSpacesItems: space.physicalStandSpacesItems.items.map(
          (item) => ({
            ...item,
            buyOperation:
              item.buyOperation ||
              item.sponsorPackageItem?.package?.buyOperations.items?.[0],
          })
        ),
      }))
      .filter((i) => isValidPurchase(i?.buyOperation?.purchase))
      .map((i) => {
        return {
          ...i,
          physicalStandSpacesItems: {
            items: i.physicalStandSpacesItems.filter((si) =>
              isValidPurchase(si?.buyOperation?.purchase)
            ),
          },
        };
      });
    setDisplaySpaces(nextSpaces);
  };

  return (
    <MainContainer>
      <div style={{ flex: 1 }}>
        <InnerContainer>
          <div style={{ display: 'flex', flex: 1 }}>
            <Tooltip title={i18n.page.back.tooltip.label}>
              <AimIconAndTextButton
                style={{
                  padding: 0,
                }}
                variant="text"
                text={i18n.page.back.button.label}
                onClick={() =>
                  history.push(`/events/${eventId}/${sponsorId}/my-services`)
                }
              >
                <ArrowBack />
              </AimIconAndTextButton>
            </Tooltip>
          </div>
          <AimTitleAndButtons title={i18n.page.title.label} />

          <Grid
            container
            // style={{
            //   backgroundColor: theme.colors.greyScale.backgroundGrey,
            //   padding: 15,
            // }}
          >
            {displaySpaces.length > 0 ? (
              <>
                <BoughtSpacesTable spaces={displaySpaces} i18n={i18n} />
              </>
            ) : (
              <>
                <AimTypography>{i18n.page.noSpaces.label}</AimTypography>
              </>
            )}
          </Grid>
        </InnerContainer>
      </div>
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </MainContainer>
  );
};

export { PhysicalStandSpaces };
