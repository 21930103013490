import { aws, appState } from '@aim/common';
import { breakoutRoomSchema } from './breakoutRoomGqlHelper';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

export const breakoutRoomServiceSchema = `
id
name
description
vatRate
isCreditCardEnabled
isBankTransferEnabled
publicationDate
isSponsorEditable
maxAvailableHours
price
breakoutRooms {
    items {
        ${breakoutRoomSchema}
    }
}
documents {
  items {
    id
    extension
    originalName
    size
    isMandatory
  }
}
`;

const getEventBreakoutRoomService = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      end
      sponsorServiceBookingDuration
      breakoutRoomService {
        ${breakoutRoomServiceSchema}
      }
    }
  }
`;

export function useBreakoutRoomService() {
  const get = (eventId, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: getEventBreakoutRoomService,
        variables: { id: eventId },
      })
        .then((response) => {
          const nextGetEvent = {
            ...response.data.getEvent,
            breakoutRoomService: {
              ...response.data.getEvent?.breakoutRoomService,
              breakoutRooms: {
                items: response.data.getEvent?.breakoutRoomService?.breakoutRooms.items.map(
                  (br) => ({
                    ...br,
                    buyOperation: br?.buyOperation
                      ? br.buyOperation
                      : br.sponsorPackageItem?.package?.buyOperations
                          .items?.[0],
                  })
                ),
              },
            },
          };
          resolve(nextGetEvent);
        })
        .catch((e) => {
          console.error('get-event-breakout-room-service', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  return { get };
}
