import React, { useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { format, isWithinInterval, isAfter } from 'date-fns';

import PublishIcon from '@material-ui/icons/Publish';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';

import { constants, utilities } from '@aim/common';
import { CustomIntl, AimTablePage, MassiveImportDialog } from '@aim/components';

import {
  translation,
  updateParticipationIsDeleted,
  closeListGroup,
} from './sponsorListDetails/index';

import { MainContainer, InnerContainer } from './shared/SponsorPagesContainers';

const cluster = constants.Clusters.SponsorList.id;
const formattedDate = (date) => date && format(new Date(date), 'dd/MM/yyyy');

// const { callLambdaConflictsOnCreate } = aws.ConflictHelper;

const getSponsorListQuery = /* GraphQL */ `
  query GetSponsor($id: ID!, $eventId: ID!, $cluster: String!) {
    getSponsor(id: $id) {
      id
      name
      priceRange {
        id
        freeStaffMembers
      }
      sponsorList {
        id
        name
        closingDate
        modificationRequests {
          items {
            modificationRequest {
              id
              requestStatus
              type
              paymentId
            }
          }
        }
        purchases(filter: { isDeleted: { ne: true } }) {
          items {
            id
            isDeleted
            purchasedSubscriptions
            buyOperation {
              id
              purchase {
                id
                paymentType
                payment {
                  id
                  paymentId
                  paymentIdIngenico
                  paymentIdGpWebpay
                  paymentIdMonetaOnLine
                  paymentError
                }
              }
            }
          }
        }
        participations(filter: { isDeleted: { ne: true } }) {
          items {
            id
            username
            givenName
            familyName
            email
            profile {
              id
              name
            }
            feeDateRange {
              id
              end
              label
              start
            }
            feeBracketModel {
              id
            }
            isInvited
            status
            isParticipant
            isSpeaker
            isReviewer
            type
            participationHistorical {
              items {
                modificationRequest {
                  requestStatus
                  paymentId
                }
              }
            }
            createdAt
            updatedAt
          }
        }
      }
    }
    getDeadline(externalId: $eventId, type: $cluster) {
      type
      createdAt
      value
      externalId
    }
  }
`;

const SponsorListDetails = () => {
  var result = new Date(Date.now());
  result.setDate(result.getDate() - 100);
  const { eventId, sponsorId } = useParams();
  const history = useHistory();
  const deadlinesRef = useRef();
  const remainingPlacesRef = useRef();
  const sponsorListRef = useRef();
  const modificationRequestsRefs = useRef();
  const listMembersRef = useRef();

  const [massiveDialog, setMassiveDialog] = useState({
    isOpen: false,
  });
  const [rowsLoaded, setRowsLoaded] = useState(false);

  // get translated objects for this route
  const intl = CustomIntl(useIntl());
  const i18n = translation.sponsorListDetails(intl);

  const importBackUrl = `/events/${eventId}/${sponsorId}/services-configuration/list`;
  const model = (loadData) => ({
    header: {
      title: () => sponsorListRef.current?.name,
      backButton: {
        onClick: () =>
          history.push(`/events/${eventId}/${sponsorId}/my-services`),
      },
      action: {
        /* Excel: {
          icon: <CallMadeIcon />,
          variant: 'green',
          onClick: (checkedItems, tData) =>
            handleExportExcel(checkedItems.length ? checkedItems : tData),
        }, */
        import: {
          icon: <PublishIcon />,
          variant: 'violet',
          onClick: () =>
            setMassiveDialog({
              isOpen: true,
              deadlinePath: addDeadlinePath(),
            }),
        },
        deleteAll: {
          icon: <ClearIcon />,
          variant: 'red',
          onClick: 'dialog',
          disabledWhen: (checkedItems) =>
            !checkedItems.length || sponsorListRef.current?.closingDate,
          dialog: 'deleteAll',
          snackbar: 'delete',
        },
        add: {
          icon: <AddIcon />,
          variant: 'yellow',
          onClick: () =>
            handleAdd({
              isPostDeadline: !!sponsorListRef.current?.closingDate,
            }),
          hiddenWhen: () =>
            remainingPlacesRef.current === undefined ||
            remainingPlacesRef.current <= 0,
          disabledWhen: () =>
            (sponsorListRef.current?.closingDate &&
              !isBetweenDeadlineAndFreezeDeadline()) ||
            (!sponsorListRef.current?.closingDate && isAfterDeadline()),
        },
        addDialog: {
          icon: <AddIcon />,
          variant: 'yellow',
          onClick: 'dialog',
          dialog: 'buyTickets',
          hiddenWhen: () =>
            remainingPlacesRef.current === undefined ||
            remainingPlacesRef.current > 0,
          disabledWhen: () =>
            (sponsorListRef.current?.closingDate &&
              !isBetweenDeadlineAndFreezeDeadline()) ||
            (!sponsorListRef.current?.closingDate && isAfterDeadline()),
        },
        close: {
          icon: <DoneIcon />,
          variant: 'green',
          // onClick: () => handleCloseGroup(),
          onClick: 'dialog',
          disabledWhen: () => sponsorListRef.current?.closingDate,
          dialog: 'close',
          snackbar: 'close',
        },
      },
    },
    dataLoader: {
      query: getSponsorListQuery,
      variables: {
        id: sponsorId,
        eventId,
        cluster: constants.Clusters.SponsorList.id,
      },
      transform: (data) => {
        sponsorListRef.current = data?.getSponsor?.sponsorList;
        modificationRequestsRefs.current =
          data?.getSponsor?.sponsorList?.modificationRequests.items
            .filter(
              (r) =>
                r?.modificationRequest?.requestStatus ===
                constants.ModificationRequestStatus.PENDING
            )
            .map((pr) => pr.modificationRequest) || [];
        remainingPlacesRef.current =
          data.getSponsor?.sponsorList?.purchases?.items.reduce(
            (res, curr) =>
              curr?.buyOperation?.purchase &&
              utilities.isValidPurchase(curr.buyOperation.purchase)
                ? res + curr.purchasedSubscriptions
                : res,
            0
          ) -
            data.getSponsor?.sponsorList?.participations?.items.length -
            modificationRequestsRefs.current.filter(
              (mr) => mr.type === constants.ModificationRequestTypes.CREATE
            ).length || 0;

        // for massive import dialog
        setRowsLoaded(true);

        const items = data?.getSponsor?.sponsorList?.participations.items.map(
          (item) => {
            // setDeadlines(
            deadlinesRef.current = data?.getDeadline?.value
              ? JSON.parse(data.getDeadline.value)
              : {};
            // );
            return {
              ...item,
              statusLabel:
                constants.ParticipantStatus.find(
                  (ps) => item.status === ps.key
                )?.label(intl) || '-',
              profile: item?.profile?.name,
              // isInvitedLabel: item.isInvited
              //   ? i18n.page.invitationStatuses.invited
              //   : i18n.page.invitationStatuses.toInvite,
            };
          }
        );
        listMembersRef.current = items;
        return items;
      },
      bigStats: [
        {
          title: 'remainingPlaces',
          value: () => remainingPlacesRef.current,
        },
        {
          title: 'currentMembers',
          value: (tData) => tData.length,
        },
      ],
      stats: [
        {
          title: 'status',
          value: (_, data) =>
            data?.getSponsor?.sponsorList?.closingDate
              ? i18n.page.statuses.closed
              : i18n.page.statuses.open,
        },
        {
          title: 'agency',
          value: () => '-',
        },
        {
          title: 'deadline',
          value: () =>
            deadlinesRef.current?.deadline
              ? formattedDate(deadlinesRef.current.deadline)
              : '-',
        },
        {
          title: 'freezeDeadline',
          value: () =>
            deadlinesRef.current?.freezeDeadline
              ? formattedDate(deadlinesRef.current.freezeDeadline)
              : '-',
        },
        {
          title: 'pendingRequests',
          value: () => modificationRequestsRefs.current?.length,
        },
      ],
    },
    table: {
      isRowSelectable: true,
      columnsTemplate: [
        {
          id: 'username',
          numeric: false,
          disablePadding: false,
          isCheckbox: false,
          isDisabled: false,
        },
        {
          id: 'givenName',
          numeric: false,
          disablePadding: false,
          isCheckbox: false,
          isDisabled: false,
        },
        {
          id: 'familyName',
          numeric: false,
          disablePadding: false,
          isCheckbox: false,
          isDisabled: false,
        },
        {
          id: 'email',
          numeric: false,
          disablePadding: false,
          isCheckbox: false,
          isDisabled: false,
        },
        {
          id: 'profile',
          numeric: false,
          disablePadding: false,
          isCheckbox: false,
          isDisabled: false,
        },
        {
          id: 'statusLabel',
          numeric: false,
          disablePadding: false,
          isCheckbox: false,
          isDisabled: false,
        },
      ],
      filters: [
        {
          column: 'username',
          type: 'string',
        },
        {
          column: 'givenName',
          type: 'string',
        },
        {
          column: 'familyName',
          type: 'string',
        },
        {
          column: 'email',
          type: 'string',
        },
        {
          column: 'statusLabel',
          type: 'select',
        },
      ],
      rowActionText: (row) =>
        row?.participationHistorical.items.filter(
          (pr) =>
            pr?.modificationRequest?.requestStatus ===
            constants.ModificationRequestStatus.PENDING
        ).length,
      rowAction: {
        edit: {
          icon: <EditIcon />,
          variant: 'lightBlueFill',
          onClick: (row) =>
            handleEdit({
              id: row.id,
              isPostDeadline: !!sponsorListRef.current?.closingDate,
            }),
          disabledWhen: () =>
            (sponsorListRef.current?.closingDate &&
              !isBetweenDeadlineAndFreezeDeadline()) ||
            (!sponsorListRef.current?.closingDate && isAfterDeadline()),
        },
        delete: {
          icon: <ClearIcon />,
          hiddenWhen: () => sponsorListRef.current?.closingDate,
          variant: 'redFill',
          onClick: 'dialog',
          dialog: 'delete',
          snackbar: 'delete',
        },
      },
    },
    dialog: {
      delete: {
        onAgree: async (row) => {
          await handleDelete(row.id);
          await loadData();
        },
        onDisagree: () => {},
      },
      deleteAll: {
        onAgree: async (checkedItems) => {
          await handleDeleteAll(checkedItems);
          await loadData();
        },
        onDisagree: () => {},
      },
      close: {
        onAgree: async () => {
          await handleCloseGroup();
          await loadData();
        },
        onDisagree: () => {},
      },
      buyTickets: {
        onAgree: async () =>
          history.push(
            `/events/${eventId}/${sponsorId}/my-services/sponsor-list`
          ),
        onDisagree: () => {},
      },
    },
    snackbar: {
      delete: {},
      close: {},
    },
  });

  const isAfterDeadline = () => {
    if (!deadlinesRef.current?.deadline) return false;
    return isAfter(new Date(), new Date(deadlinesRef.current.deadline));
  };

  const isBetweenDeadlineAndFreezeDeadline = () => {
    if (
      !deadlinesRef.current?.deadline ||
      !deadlinesRef.current?.freezeDeadline
    )
      return false;
    return isWithinInterval(new Date(), {
      start: new Date(deadlinesRef.current.deadline),
      end: new Date(deadlinesRef.current.freezeDeadline),
    });
  };

  const handleAdd = ({ isPostDeadline }) => {
    if (remainingPlacesRef.current > 0) {
      history.push(
        `/events/${eventId}/participation/${cluster}/${
          sponsorListRef.current.id
        }/new${isPostDeadline ? '/post-deadline' : ''}`
      );
      return;
    }
  };

  const handleEdit = ({ id, isPostDeadline }) => {
    history.push(
      `/events/${eventId}/participation/${cluster}/${
        sponsorListRef.current.id
      }/${id}${isPostDeadline ? '/post-deadline' : ''}`
    );
  };

  const handleCloseGroup = async () => {
    await closeListGroup(
      {
        id: sponsorListRef.current.id,
        closingDate: new Date(),
      },
      listMembersRef.current.map((p) => p.id)
    );
    // callLambdaConflictsOnCreate(eventId);
  };

  const handleDeleteAll = async (checkedItems) =>
    await Promise.all(
      checkedItems?.map(async (p) => await updateParticipationIsDeleted(p.id))
    );

  const handleDelete = async (id) => await updateParticipationIsDeleted(id);

  const addDeadlinePath = () => {
    return isDeadlinePassed() ? '/post-deadline' : '';
  };

  const isDeadlinePassed = () => {
    return isAfter(Date.now(), new Date(deadlinesRef.current?.deadline));
  };

  return (
    <MainContainer>
      <div style={{ flex: 1 }}>
        <InnerContainer>
          <AimTablePage
            model={model}
            translation={i18n.aimTablePage}
            intl={intl}
          />
        </InnerContainer>
      </div>

      {rowsLoaded && (
        <MassiveImportDialog
          state={{
            remainingPlaces: remainingPlacesRef.current,
            sponsorId,
            backUrl: importBackUrl,
          }}
          eventId={eventId}
          cluster={cluster}
          clusterId={sponsorListRef.current.id}
          massiveDialog={massiveDialog}
          setMassiveDialog={setMassiveDialog}
          intl={intl}
          history={history}
        />
      )}
    </MainContainer>
  );
};

export default SponsorListDetails;
