import { aws, appState } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const listPhysicalStandItemsSubcategoriesQuery = /* GraphQL */ `
  query GetSubcategories($id: ID!) {
    getItemCategory(id: $id) {
      id
      name
      itemSubcategories {
        items {
          id
          name
          name
          childItems {
            items {
              id
            }
          }
        }
      }
    }
  }
`;

export const listPhysicalStandItemsSubcategories = (
  itemCategoryId,
  useLoader = true
) =>
  new Promise((resolve, reject) => {
    showLoader();

    API.graphql({
      query: listPhysicalStandItemsSubcategoriesQuery,
      variables: { id: itemCategoryId },
    })
      .then((response) => resolve(response.data?.getItemCategory))
      .catch((e) => {
        console.error('event-physical-stand-items-subcategories-list', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });
