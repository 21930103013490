import { aws, appState } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

const createBillingInformationMutation = /* GraphQL */ `
  mutation createBillingInformation(
    $input: CreateBillingInformationInput!
    $condition: ModelBillingInformationConditionInput
  ) {
    createBillingInformation(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updatePaymentMutation = /* GraphQL */ `
  mutation UpdatePayment(
    $input: UpdatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    updatePayment(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createBillingInformation = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: createBillingInformationMutation,
      variables: {
        input: { ...input },
      },
    })
      .then((response) => resolve(response.data.createBillingInformation))
      .catch((e) => {
        console.error('create-billing-information', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const updatePayment = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: updatePaymentMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.updatePayment))
      .catch((e) => {
        console.error('update-payment-components-paymentcart', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const getBuyOperations = async (buyOperations) => {
  const createSingleQuery = buyOperations.map(({ id }) => {
    if (!id) return '';

    return `
  alias_${id.replace(/-/g, '_')}: getBuyOperation(id: "${id}") {
    id
    buyOperationPurchaseId
  }
    `;
  });

  const queries = createSingleQuery.join('');

  if (!queries) return {};

  const query = `query GetAllBuytOperations { ${queries} }`;

  return new Promise((resolve, reject) => {
    API.graphql({
      query,
      variables: {},
    })
      .then((response) => resolve(response.data))
      .catch((e) => {
        console.error('getBuyOperations', e);
        reject();
      });
  });
};
