const translation = {
  allotment: (intl) => ({
    page: {
      checkInNotSelected: intl.formatMessage({
        description: 'filter search check in date not selected',
        defaultMessage: 'Check in date is missing',
      }),
      checkOutNotSelected: intl.formatMessage({
        description: 'filter search check out date not selected',
        defaultMessage: 'Check out date is missing',
      }),
      dateError: intl.formatMessage({
        description: 'filter search date error',
        defaultMessage: 'Check out date must come after check in',
      }),
      title: intl.formatMessage({
        description: 'front office allotment page title',
        defaultMessage: 'Allotment',
      }),
      back: intl.formatMessage({
        description: 'back',
        defaultMessage: 'back',
      }),
      filter: intl.formatMessage({
        description: 'filter',
        defaultMessage: 'filter',
      }),
      filterNoResults: intl.formatMessage({
        description: 'filter with no results label',
        defaultMessage: 'No results found for this search.',
      }),
    },
    filter: {
      filterTitle: intl.formatMessage({
        description: 'filter title',
        defaultMessage: 'Insert your preferences',
      }),
      search: intl.formatMessage({
        description: 'filter search button',
        defaultMessage: 'search',
      }),
      checkIn: intl.formatMessage({
        description: 'check in filter label',
        defaultMessage: 'Check In',
      }),
      checkOut: intl.formatMessage({
        description: 'check out filter label',
        defaultMessage: 'Check Out',
      }),
      roomsNumber: intl.formatMessage({
        description: 'rooms number filter label',
        defaultMessage: 'N° rooms',
      }),
      guestsNumber: intl.formatMessage({
        description: 'participants numbre filter label',
        defaultMessage: 'N° people',
      }),
    },
    card: {
      price: intl.formatMessage({
        description: 'price label',
        defaultMessage: 'Price from',
      }),
    },
  }),
  billingInformations: (intl) => ({
    title: intl.formatMessage({
      description: 'front office allotment title',
      defaultMessage: 'Allotment',
    }),
    actions: {
      continue: intl.formatMessage({
        description: 'send data billing informations button',
        defaultMessage: 'continue',
      }),
      backButton: intl.formatMessage({
        description: 'back to event fee list',
        defaultMessage: 'Back',
      }),
    },
    page: {
      title: intl.formatMessage({
        description: 'resume buy page title',
        defaultMessage: 'Resume',
      }),
      generalInfo: intl.formatMessage({
        description: 'general info billing informations card title',
        defaultMessage: 'General info',
      }),
      billingInformations: intl.formatMessage({
        description: 'billing informations billing informations card title',
        defaultMessage: 'Billing Information',
      }),
      copyEvent: intl.formatMessage({
        description: 'Copy event billing informations card title',
        defaultMessage: 'COPY FROM LAST EVENT',
      }),
      labels: {
        yes: intl.formatMessage({
          description: 'Yes label billing informations form',
          defaultMessage: 'Yes',
        }),
        no: intl.formatMessage({
          description: 'No label billing informations form',
          defaultMessage: 'No',
        }),
        company: intl.formatMessage({
          description: 'Company label billing informations form',
          defaultMessage: 'Company',
        }),
        individual: intl.formatMessage({
          description: 'Individual label billing informations form',
          defaultMessage: 'Individual',
        }),
        daysIncluded: intl.formatMessage({
          description: 'daya included billing informations table',
          defaultMessage: 'Days included',
        }),
        profile: intl.formatMessage({
          description: 'profile billing informations table',
          defaultMessage: 'Profile',
        }),
        participationMode: intl.formatMessage({
          description: 'participation mode billing informations table',
          defaultMessage: 'Participation mode',
        }),
        purchasedBy: intl.formatMessage({
          description: 'purchased by billing informations table',
          defaultMessage: 'Purchased by',
        }),
        unitaryPrice: intl.formatMessage({
          description: 'price without vat rate billing informations table',
          defaultMessage: 'Price (without VAT rate)',
        }),
        vatRate: intl.formatMessage({
          description: 'vat rate billing informations table',
          defaultMessage: 'VAT (%)',
        }),
        price: intl.formatMessage({
          description: 'price vat included billing informations table',
          defaultMessage: 'Price (VAT included)',
        }),

        address: intl.formatMessage({
          description: 'address billing informations form',
          defaultMessage: 'Address',
        }),
        city: intl.formatMessage({
          description: 'city billing informations form',
          defaultMessage: 'City',
        }),
        email: intl.formatMessage({
          description: 'email billing informations form',
          defaultMessage: 'Email',
        }),
        pec: intl.formatMessage({
          description: 'pec billing informations form',
          defaultMessage: 'Pec',
        }),
        phone: intl.formatMessage({
          description: 'phone billing informations form',
          defaultMessage: 'Phone',
        }),
        companyName: intl.formatMessage({
          description: 'company name billing informations form',
          defaultMessage: "Company's name",
        }),
        name: intl.formatMessage({
          description: 'name billing informations form',
          defaultMessage: 'Name',
        }),
        surname: intl.formatMessage({
          description: 'surname billing informations form',
          defaultMessage: 'Surname',
        }),
        postalCode: intl.formatMessage({
          description: 'Postal code/Zip billing informations form',
          defaultMessage: 'Postal code/Zip',
        }),
        regionProvinceState: intl.formatMessage({
          description: 'Region/Province/State billing informations form',
          defaultMessage: 'Region/Province/State',
        }),
        region: intl.formatMessage({
          description: 'Region billing informations form',
          defaultMessage: 'Region',
        }),
        province: intl.formatMessage({
          description: 'Province billing informations form',
          defaultMessage: 'Province',
        }),
        country: intl.formatMessage({
          description: 'Country* billing informations form',
          defaultMessage: 'Country',
        }),
        vatCode: intl.formatMessage({
          description: 'VAT Number billing informations form',
          defaultMessage: 'VAT Number',
        }),
        taxCode: intl.formatMessage({
          description: 'Fiscal Code billing informations form',
          defaultMessage: 'Fiscal code',
        }),
        reference: intl.formatMessage({
          description: ' billing informations form',
          defaultMessage: 'Reference',
        }),
        invoiceToPublicAuthority: intl.formatMessage({
          description: 'Invoice to public authority billing informations form',
          defaultMessage: 'Invoice to public authority',
        }),
        invoiceTo: intl.formatMessage({
          description: 'Invoice to  billing informations form',
          defaultMessage: 'Invoice to:',
        }),
        uniqueCode: intl.formatMessage({
          description: 'Unique Code billing informations form',
          defaultMessage: 'Unique Code',
        }),
        ipaCode: intl.formatMessage({
          description: 'IPA code billing informations form',
          defaultMessage: 'IPA code',
        }),
        isSplitPayment: intl.formatMessage({
          description: 'Split Payment? billing informations form',
          defaultMessage: 'Split Payment?',
        }),
        isVatEvent: intl.formatMessage({
          description:
            'Is VAT Exent? (Art.10 DPR N. 633/72) billing informations form',
          defaultMessage: 'Is VAT Exent? (Art.10 DPR N. 633/72)*',
        }),
      },
    },
    snackbar: {
      buyTicketSuccess: intl.formatMessage({
        description: 'allotment successfully purchased',
        defaultMessage: 'Allotment successfully purchased',
      }),
      gatewayNotExist: intl.formatMessage({
        description: 'gateway not exist',
        defaultMessage: 'Gateway not exist',
      }),
      paymentTypeNotExist: intl.formatMessage({
        description: 'payment Type Not Exist',
        defaultMessage: 'Payment Type Not Exist',
      }),
    },
    checkout: {
      errors: {
        error1: intl.formatMessage({
          description: 'order data request processing error',
          defaultMessage: 'Order data request processing error',
        }),
        error2: intl.formatMessage({
          description: 'order data processing error',
          defaultMessage: 'Order data processing error',
        }),
        error3: intl.formatMessage({
          description: 'order payment initialization error',
          defaultMessage: 'Order payment initialization error',
        }),
        error4: intl.formatMessage({
          description: 'order payment error',
          defaultMessage: 'Order payment error',
        }),
        paymentExist: intl.formatMessage({
          description: 'payment exist',
          defaultMessage: 'Payment exist',
        }),
      },
    },
  }),
};

export default translation;
