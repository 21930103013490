export const translations = {
  controlCode: (intl) => ({
    pageTitle: intl.formatMessage({
      description: 'Frontoffice control code page title',
      defaultMessage: 'Control Code',
    }),
    subtitle: intl.formatMessage({
      description: 'Frontoffice control code form subtitle text',
      defaultMessage:
        'Before the platform access, intert the secret code below',
    }),
    code: intl.formatMessage({
      description: 'Frontoffice control code form insert code label',
      defaultMessage: 'Insert code',
    }),
    confirm: intl.formatMessage({
      description: 'confirm',
      defaultMessage: 'Confirm',
    }),
    invalidCodeMessage: intl.formatMessage({
      description: 'Frontoffice control code form invalid code error text',
      defaultMessage: 'Incorrect Code',
    }),
  }),
  signIn: (intl) => ({
    pageTitle: intl.formatMessage({
      description: 'Frontoffice login page title',
      defaultMessage: 'Sign in',
    }),
    username: intl.formatMessage({
      description: 'Frontoffice login form username label',
      defaultMessage: 'Username',
    }),
    password: intl.formatMessage({
      description: 'Frontoffice login form password label',
      defaultMessage: 'Password',
    }),
    confirm: intl.formatMessage({
      description: 'confirm',
      defaultMessage: 'Confirm',
    }),
    userNotExist: intl.formatMessage({
      description: 'Frontoffice login user not exists message',
      defaultMessage: 'User does not exist.',
    }),
    forgotPassword: intl.formatMessage({
      description: 'Frontoffice login Forgot password text',
      defaultMessage: 'Forgot password?',
    }),
    forgotUsername: intl.formatMessage({
      description: 'Frontoffice login Forgot username text',
      defaultMessage: 'Forgot username?',
    }),
    newUser: intl.formatMessage({
      description: 'Frontoffice login new user text',
      defaultMessage: 'New user?',
    }),
    registerNow: intl.formatMessage({
      description: 'Frontoffice login register now text',
      defaultMessage: 'Register now',
    }),
  }),
  forceChangePassword: (intl) => ({
    pageTitle: intl.formatMessage({
      description: 'Frontoffice force Change password page title',
      defaultMessage: 'Change password',
    }),
    password: intl.formatMessage({
      description: 'Frontoffice force Change password form password label',
      defaultMessage: 'New password',
    }),
    repeatpassword: intl.formatMessage({
      description:
        'Frontoffice force Change password form repeat password label',
      defaultMessage: 'Repeat password',
    }),
    passwordRequired: intl.formatMessage({
      description:
        'Frontoffice force Change password form password required text',
      defaultMessage: 'Password is required',
    }),
    passwordMustMatch: intl.formatMessage({
      description:
        'Frontoffice force Change password form Passwords must match text',
      defaultMessage: 'Passwords must match',
    }),
    confirm: intl.formatMessage({
      description: 'confirm',
      defaultMessage: 'Confirm',
    }),
  }),
  forgotPassword: (intl) => ({
    pageTitle: intl.formatMessage({
      description: 'Frontoffice Reset password page title',
      defaultMessage: 'Reset Password',
    }),
    pageSubtitle: intl.formatMessage({
      description: 'Frontoffice Reset password page subtitle',
      defaultMessage: 'Enter your Username to retrieve your Password',
    }),
    username: intl.formatMessage({
      description: 'Frontoffice force Change password form username label',
      defaultMessage: 'Username',
    }),
    code: intl.formatMessage({
      description: 'Frontoffice force Change password form code label',
      defaultMessage: 'Code',
    }),
    newPassword: intl.formatMessage({
      description: 'Frontoffice force Change password form New password label',
      defaultMessage: 'New password',
    }),
    repeatpassword: intl.formatMessage({
      description:
        'Frontoffice force Change password form repeat password label',
      defaultMessage: 'Repeat password',
    }),
    passwordRequired: intl.formatMessage({
      description:
        'Frontoffice force Change password form password required text',
      defaultMessage: 'Password is required',
    }),
    passwordMustMatch: intl.formatMessage({
      description:
        'Frontoffice force Change password form Passwords must match text',
      defaultMessage: 'Passwords must match',
    }),
    confirm: intl.formatMessage({
      description: 'confirm',
      defaultMessage: 'Confirm',
    }),
    backTo: intl.formatMessage({
      description: 'back to',
      defaultMessage: 'Back to',
    }),
    login: intl.formatMessage({
      description: 'login',
      defaultMessage: 'Login',
    }),
    codeSentMessage: intl.formatMessage({
      description:
        'Frontoffice Forgot password code send text ends with user email',
      defaultMessage: 'Code sent to',
    }),
  }),
  forgotUsername: (intl) => ({
    pageTitle: intl.formatMessage({
      description: 'Frontoffice username recovery page title',
      defaultMessage: 'Retrieve Username(s)',
    }),
    pageSubtitle: intl.formatMessage({
      description: 'Frontoffice username recovery page subtitle',
      defaultMessage: 'Enter your email address to retrieve your Username(s)',
    }),
    email: intl.formatMessage({
      description: 'Frontoffice username recovery email label',
      defaultMessage: 'Email',
    }),
    validEmail: intl.formatMessage({
      description: 'Frontoffice username recovery validEmail error',
      defaultMessage: 'Insert a valid email address',
    }),
    confirm: intl.formatMessage({
      description: 'confirm',
      defaultMessage: 'Confirm',
    }),
    backTo: intl.formatMessage({
      description: 'back to',
      defaultMessage: 'Back to',
    }),
    login: intl.formatMessage({
      description: 'login',
      defaultMessage: 'Login',
    }),
    emailSentMessage: intl.formatMessage({
      description: 'Frontoffice username recovery confirmation email sent',
      defaultMessage: 'Username(s) sent to',
    }),
    snackbar: {
      success: intl.formatMessage({
        description:
          'snackbar retrieve usernames login email sent success message',
        defaultMessage: 'Email sent',
      }),
      error: intl.formatMessage({
        description:
          "snackbar retrieve usernames login  error , generic error, this shouldn't happen",
        defaultMessage: 'Error',
      }),
      fill: intl.formatMessage({
        description: 'snackbar retrieve usernames login fill an email address ',
        defaultMessage: 'Please fill in your email address',
      }),
    },
  }),
  signUp: (intl) => ({
    pageTitle: intl.formatMessage({
      description: 'Frontoffice Sign Up page title',
      defaultMessage: 'Sign Up',
    }),
    subtitle: intl.formatMessage({
      description: 'Frontoffice Sign Up page subtitle text',
      defaultMessage:
        'New user? Register and access to all services linked to each event. Add your e-mail and choose a new password to complete registration',
    }),
    email: intl.formatMessage({
      description: 'Frontoffice Sign Up form email label',
      defaultMessage: 'Email',
    }),
    password: intl.formatMessage({
      description: 'Frontoffice Sign Up form password label',
      defaultMessage: 'Password',
    }),
    repeatpassword: intl.formatMessage({
      description: 'Frontoffice Sign Up form repeat password label',
      defaultMessage: 'Repeat password',
    }),
    emailRequired: intl.formatMessage({
      description: 'Frontoffice Sign Up form email required text',
      defaultMessage: 'Email is required',
    }),
    passwordRequired: intl.formatMessage({
      description: 'Frontoffice Sign Up form password required text',
      defaultMessage: 'Password is required',
    }),
    alreadyRegistered: intl.formatMessage({
      description: 'Frontoffice Sign Up form Are you already registered? text',
      defaultMessage: 'Are you already registered?',
    }),
    userAlreadyExists: intl.formatMessage({
      description: 'Frontoffice Sign Up form user already exists text',
      defaultMessage: 'User already exists in Aim Group.',
    }),
    login: intl.formatMessage({
      description: 'Frontoffice Sign Up form Login text',
      defaultMessage: 'Login',
    }),
    or: intl.formatMessage({
      description: 'Frontoffice Sign Up form or text',
      defaultMessage: 'or',
    }),
    resetPassword: intl.formatMessage({
      description: 'Frontoffice Sign Up form Reset your password text',
      defaultMessage: 'Reset your password',
    }),
    ifDontRemember: intl.formatMessage({
      description: "Frontoffice Sign Up form if you don't remember that text",
      defaultMessage: "if you don't remember that",
    }),
    confirm: intl.formatMessage({
      description: 'confirm',
      defaultMessage: 'Confirm',
    }),
  }),
  resetTemporaryPassword: (intl) => ({
    pageTitle: intl.formatMessage({
      description: 'Frontoffice temporary password expired page title',
      defaultMessage: 'Password expired',
    }),
    subtitle1: intl.formatMessage({
      description:
        'Frontoffice temporary password expired page subtitle part 1 (before email)',
      defaultMessage: 'Dear',
    }),
    subtitle2: intl.formatMessage({
      description:
        'Frontoffice temporary password expired page subtitle part 2 (after email)',
      defaultMessage: ', your temporary password has expired',
    }),
    subtitle3: intl.formatMessage({
      description:
        'Frontoffice temporary password expired page subtitle part 3',
      defaultMessage: 'we will send a new temporary password to your email',
    }),
    send: intl.formatMessage({
      description: 'Frontoffice temporary password expired send button text',
      defaultMessage: 'Send',
    }),
  }),
  identityVerification: (intl) => ({
    pageTitle: intl.formatMessage({
      description: 'Frontoffice temporary Verify your identity page title',
      defaultMessage: 'Verify your identity',
    }),
    codeRequired: intl.formatMessage({
      description: 'Frontoffice Verify your identity code required text',
      defaultMessage: 'Code is required',
    }),
    subtitle1: intl.formatMessage({
      description:
        'Frontoffice Verify your identity page subtitle part 1 (before email)',
      defaultMessage: 'Dear',
    }),
    subtitle2: intl.formatMessage({
      description:
        'Frontoffice Verify your identity page subtitle part 2 (after email)',
      defaultMessage: ', please verify your identity',
    }),
    subtitle3: intl.formatMessage({
      description: 'Frontoffice Verify your identity page subtitle part 3',
      defaultMessage: 'Insert code you received in your welcome mail',
    }),
    send: intl.formatMessage({
      description: 'Frontoffice temporary password expired send button text',
      defaultMessage: 'Send',
    }),
    codeSent: intl.formatMessage({
      description: 'Frontoffice Verify your identity code send text',
      defaultMessage: 'code sent',
    }),
    code: intl.formatMessage({
      description: 'Frontoffice Verify your identity form code label',
      defaultMessage: 'Code',
    }),
    notReceivedCode: intl.formatMessage({
      description: 'Frontoffice Verify your identity not receive code text',
      defaultMessage: "you didn't receive the code?",
    }),
    sendAgain: intl.formatMessage({
      description: 'Frontoffice Verify your identity send again code text',
      defaultMessage: 'Send again',
    }),
    welcomeToOurPlatform: intl.formatMessage({
      description:
        'Frontoffice Verify your identity Welcome in our platform text',
      defaultMessage: 'Welcome in our platform',
    }),
    login: intl.formatMessage({
      description: 'Frontoffice Sign Up form Login button text',
      defaultMessage: 'Login',
    }),
    confirm: intl.formatMessage({
      description: 'confirm',
      defaultMessage: 'Confirm',
    }),
  }),
};
