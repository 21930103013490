import { aws, appState } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const getEventPriceRanges = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      sponsorPriceRanges {
        items {
          id
          name
          price
          description
          start
          freeStaffMembers
          sponsors {
            items {
              id
            }
            nextToken
          }
        }
      }
    }
  }
`;

export function usePriceRanges() {
  const list = (id) =>
    new Promise((resolve, reject) => {
      showLoader();

      API.graphql({ query: getEventPriceRanges, variables: { id } })
        .then((response) => resolve(response.data.getEvent))
        .catch((e) => {
          console.error('event-list-sponsor-price-ranges', e);
          reject();
        })
        .finally(hideLoader);
    });

  return { list };
}
