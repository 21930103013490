import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { isMobileOnly } from 'react-device-detect';
import Grid from '@material-ui/core/Grid';

import ArrowBack from '@material-ui/icons/ArrowBack';
// import Info from '@material-ui/icons/Info';
import CheckIcon from '@material-ui/icons/Check';
import {
  styled,
  theme,
  AimTypography,
  AimIconAndTextButton,
  // AimIconButton,
  CustomIntl,
} from '@aim/components';
import useI18n from './i18n';
import { listCertifications } from '../shared/papersGqlHelper';
import { listBillingDocsByClientId as fetchMyInvoices } from '../shared/billingGqlHelper';

import {
  getOthersParticipantAvailableServices,
  getProductsByClientIdServiceId,
} from './profileHelper';
import { appState, constants, utilities } from '@aim/common';

const MainContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  width: isMobileOnly ? '100%' : '80%',
  padding: '10px',
  background: theme.colors.greyScale.white,
});

const Container = styled('div')({
  // display: 'flex',
  // flexWrap: 'wrap',
  // flex: 1,
  // width: '100%',
  // margin: '0 auto',
  backgroundColor: theme.colors.greyScale.backgroundGrey,
  // padding: '16px',
});

const Card = styled('div')({
  background: theme.colors.greyScale.white,
  boxShadow: '0 0 black !important',
  borderRadius: '0px !important',
  width: '100%',
  // maxHeight: '200px',
  margin: 10,
  padding: 10,
});

const CardHeader = styled('div')({
  borderBottom: `1px solid ${theme.colors.greyScale.black}`,
  paddingBottom: 2,
  paddingLeft: 2,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const CardStatus = styled('div')({
  width: 25,
  height: 25,
  backgroundColor: '#29E129',
  borderRadius: 100,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#FFF',
});

// const isDisabledFunc = async (participationId) => {
//   const participationHelper = useParticipations();
//   const res = await participationHelper.getParticipation(participationId);
//   let isDisabled = true;
//   if (res?.filledPapers) {
//     isDisabled = !!res.filledPapers?.items?.find((p) => p.type === 'visa|pax')
//       ?.id;
//   }
//   return isDisabled;
// };

const ServicesOld = () => {
  const intl = CustomIntl(useIntl());
  const i18n = useI18n(intl);
  const { eventId, participationId } = useParams();
  const history = useHistory();
  // const location = useLocation();
  const [ticket, setTicket] = useState();
  const [, setProducts] = useState();
  const [certifications, setCertifications] = useState([]);
  const [servicesTypeInProds, setServicesTypeInProds] = useState([]);
  const [myInvoices, setMyInvoices] = useState([]);

  useEffect(() => {
    // const fetchProduct = async () => {
    //   try {
    //     const product = await getProduct(
    //       participationId,
    //       eventId,
    //       constants.Services.REGISTRATION.key
    //     );
    //     if (product?.id) {
    //       setTicket(true);
    //     }
    //   } catch (err) {
    //     console.error(err);
    //   }
    // };
    // fetchProduct();
    const fetchProductsByClientIdServiceId = async () => {
      try {
        const products = await getProductsByClientIdServiceId(
          participationId,
          eventId
        );
        // mapped otherAvailableServices with serviceType to leave next code unique
        const otherAvailableServices = await getOthersParticipantAvailableServices(
          participationId
        );
        if (products) {
          // get ticket
          const ticketProduct = products
            .filter(
              (prod) => prod.serviceType === constants.Services.REGISTRATION.key
            )
            .filter((product) => utilities.isValidPayment(product.payment));
          setTicket((ticketProduct.length && ticketProduct[0].id) || false);
          setProducts(products);

          const uniqueServiceTypes = [
            ...new Set(
              [...products, ...otherAvailableServices].map(
                (item) => item.serviceType
              )
            ),
          ];

          setServicesTypeInProds(uniqueServiceTypes);
        }
      } catch (err) {
        console.error(err);
      }
    };
    const fetchCertificationsData = async () => {
      const event = await listCertifications(eventId, participationId);
      console.log('papers', event.papers);
      console.log(
        'papers',
        appState.user.getValue()?.userAndParticipation?.participation
          ?.filledPapers?.items
      );
      const nextPapers = [
        ...event.papers.items,
        ...(appState.user.getValue()?.userAndParticipation?.participation
          ?.filledPapers?.items || []),
      ];
      if (nextPapers.length) {
        setCertifications(nextPapers);
      }
    };
    fetchProductsByClientIdServiceId();
    fetchMyInvoices(participationId).then(setMyInvoices);

    //this part must be uniformed with grant but not now
    fetchCertificationsData();
  }, []);

  const listServices = (i18n) => [
    {
      ...(servicesTypeInProds.includes(constants.Services.REGISTRATION.key) && {
        title: i18n.services.myTicketTitle,
        description: i18n.services.myTicketDesc,
        ticket,
        disableFunc: () => !ticket,
        onClick: () =>
          // history.push(`/events/${eventId}/user/${participationId}/my-ticket`),
          history.push(`/events/${eventId}/user/${participationId}/my-tickets`),
      }),
    },
    // {
    //   title: i18n.services.myAbstractTitle,
    //   description: i18n.services.myAbstractDesc,
    //   //onClick: () => false,
    // },
    {
      ...(servicesTypeInProds.includes(constants.GrantType.ABSTRACT.key) && {
        title: i18n.services.abstractGrantTitle,
        description: i18n.services.abstractGrantDesc,
        onClick: () =>
          history.push(
            `/events/${eventId}/user/${participationId}/my-grants/${constants.GrantType.ABSTRACT.key}`
          ),
      }),
    },
    {
      ...(servicesTypeInProds.includes(constants.GrantType.EDUCATIONAL.key) && {
        title: i18n.services.educationalGrantTitle,
        description: i18n.services.educationalGrantDesc,
        onClick: () =>
          history.push(
            `/events/${eventId}/user/${participationId}/my-grants/${constants.GrantType.EDUCATIONAL.key}`
          ),
      }),
    },
    {
      ...(servicesTypeInProds.includes(constants.Services.ALLOTMENT.key) && {
        title: i18n.services.allotmentTitle,
        description: i18n.services.allotmentDesc,
        ticket,
        disableFunc: () => !ticket,
        onClick: () =>
          history.push(
            `/events/${eventId}/user/${participationId}/my-allotment`
          ),
      }),
    },
    // {
    //   title: i18n.services.travelTitle,
    //   description: i18n.services.travelDesc,
    //   //onClick: () => false,
    // },
    {
      ...((servicesTypeInProds.includes(constants.Services.SOCIAL_EVENT.key) ||
        servicesTypeInProds.includes(
          constants.Services.SCIENTIFIC_EVENT.key
        )) && {
        title: i18n.services.additionalServiceTitle,
        description: i18n.services.additionalServiceDesc,
        ticket,
        disableFunc: () => !ticket,
        onClick: () =>
          history.push(
            `/events/${eventId}/user/${participationId}/additional-services`
          ),
      }),
    },
    // {
    //   title: i18n.services.visaTitle,
    //   description: i18n.services.visaDesc,
    //   disableFunc: () => {
    //     isDisabledFunc(participationId).then((res) => res);
    //   },
    //   onClick: () =>
    //     history.push({
    //       pathname: `/events/${eventId}/visa`,
    //       state: { back: location.pathname },
    //     }),
    // },
    {
      ...(certifications.length > 0 && {
        title: i18n.services.certificateListTitle,
        description: i18n.services.certificateListDesc,
        // ticket,
        // disableFunc: () => !ticket,
        onClick: () =>
          history.push(
            `/events/${eventId}/user/${participationId}/certifications`
          ),
      }),
    },
    // {
    //   ...(myInvoices.length > 0 && {
    //     title: i18n.services.invoicesListTitle,
    //     description: i18n.services.invoicesListDesc,
    //     onClick: () =>
    //       history.push(`/events/${eventId}/user/${participationId}/invoices`),
    //   }),
    // },
  ];

  return (
    <MainContainer>
      <div style={{ width: '100%' }}>
        <AimIconAndTextButton
          variant="none"
          onClick={() => {
            history.push(`/events/${eventId}/user/${participationId}`);
          }}
        >
          <ArrowBack style={{ color: theme.colors.greyScale.grey3 }} />
          <AimTypography variant="text">{i18n.actions.back}</AimTypography>
        </AimIconAndTextButton>
        <AimTypography variant="h1Regular">{i18n.page.myArea}</AimTypography>
      </div>
      <Container>
        <Grid container xs={12}>
          {listServices(i18n).map(
            (i, key) =>
              Object.keys(i).length > 0 && (
                <Grid item container xs={isMobileOnly ? 12 : 4}>
                  <Card
                    key={key}
                    onClick={i.onClick}
                    style={{
                      cursor: 'pointer',
                    }}
                  >
                    <CardHeader>
                      <AimTypography variant="textBold">
                        {i.title}
                      </AimTypography>
                      {i.ticket && (
                        <CardStatus>
                          <CheckIcon />
                        </CardStatus>
                      )}
                    </CardHeader>
                    <AimTypography>{i.description}</AimTypography>
                  </Card>
                </Grid>
              )
          )}
        </Grid>
      </Container>
    </MainContainer>
  );
};

export default ServicesOld;
