import React, { useState } from 'react';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import xIcon from '../../../../assets/images/X.svg';

import {
  FooterContainer,
  FooterFlexer,
  LinkFooter,
  LinkFooterContainer,
  InfoCompanyFooterContainer,
  FooterColumn,
  SocialFooterContainer,
  SocialIconContainer,
} from './style';

import { AimTypography } from '@aim/components';
import { appState } from '@aim/common';

const renderCompany = ({ eventConfiguration }) => (
  <InfoCompanyFooterContainer>
    <AimTypography>
      <pre style={{ fontFamily: 'inherit', fontSize: 'inherit' }}>
        {eventConfiguration?.footerInfoCompanyDescription}
      </pre>
    </AimTypography>
  </InfoCompanyFooterContainer>
);

const renderSocial = ({ eventConfiguration }) => (
  <SocialFooterContainer position={eventConfiguration?.footerSocialPosition}>
    {eventConfiguration?.footerSocialFacebookLink ? (
      <SocialIconContainer
        onClick={() =>
          window.open(
            `${eventConfiguration?.footerSocialFacebookLink}`,
            '_blank'
          )
        }
        variant="none"
      >
        <FacebookIcon fontSize="small" />
      </SocialIconContainer>
    ) : null}
    {eventConfiguration?.footerSocialLinkedinLink ? (
      <SocialIconContainer
        onClick={() =>
          window.open(
            `${eventConfiguration?.footerSocialLinkedinLink}`,
            '_blank'
          )
        }
      >
        <LinkedInIcon fontSize="small" />
      </SocialIconContainer>
    ) : null}
    {eventConfiguration?.footerSocialInstagramLink ? (
      <SocialIconContainer
        onClick={() =>
          window.open(
            `${eventConfiguration?.footerSocialInstagramLink}`,
            '_blank'
          )
        }
        variant="none"
      >
        <InstagramIcon fontSize="small" />
      </SocialIconContainer>
    ) : null}
    {eventConfiguration?.footerSocialTwitterLink ? (
      <SocialIconContainer
        onClick={() =>
          window.open(
            `${eventConfiguration?.footerSocialTwitterLink}`,
            '_blank'
          )
        }
        variant="none"
      >
        <img src={xIcon} style={{ height: 15 }} />
      </SocialIconContainer>
    ) : null}
  </SocialFooterContainer>
);

const renderLink = ({ eventConfiguration }) => (
  <AimTypography>
    <LinkFooterContainer position={eventConfiguration?.footerLinkPosition}>
      {JSON.parse(eventConfiguration?.footerLinkData || '[]').map((i) => {
        return (
          <LinkFooter key={i.id} onClick={(e) => globalThis.open(i?.link, '')}>
            {i.title}
          </LinkFooter>
        );
      })}
    </LinkFooterContainer>
  </AimTypography>
);
const findElementAtPosition = (eventConfiguration, position) => {
  const element =
    eventConfiguration?.footerInfoCompanyEnabled &&
    eventConfiguration?.footerInfoCompanyPosition === position
      ? renderCompany({ eventConfiguration })
      : eventConfiguration?.footerSocialEnabled &&
        eventConfiguration?.footerSocialPosition === position
      ? renderSocial({ eventConfiguration })
      : eventConfiguration?.footerLinkEnabled &&
        eventConfiguration?.footerLinkPosition === position
      ? renderLink({ eventConfiguration })
      : null;
  return element ? [element] : [];
};

const Footer = () => {
  const [eventConfiguration] = useState(appState.eventConfiguration.getValue());

  const footerElements = [
    ...findElementAtPosition(eventConfiguration, 'left'),
    ...findElementAtPosition(eventConfiguration, 'center'),
    ...findElementAtPosition(eventConfiguration, 'right'),
  ];
  return eventConfiguration?.footerInfoCompanyEnabled ||
    eventConfiguration?.footerSocialEnabled ||
    eventConfiguration?.footerLinkEnabled ? (
    <FooterContainer
      footerTextColor={eventConfiguration?.footerTextColor}
      footerBackgroundColor={eventConfiguration?.footerBackgroundColor}
    >
      <FooterFlexer>
        {footerElements.map((el, idx) => (
          <FooterColumn key={idx}>{el}</FooterColumn>
        ))}
      </FooterFlexer>
    </FooterContainer>
  ) : null;
};

export default Footer;
