import { aws, appState } from '@aim/common';
const { API, graphql } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const getAbstractDocumentQuery = /* GraphQL */ `
  query GetAbstractDocument($id: ID!) {
    getAbstractDocument(id: $id) {
      id
      title
      status
      cod0
      cod1
      cod2
      url
      preferredExposure
      proposedPresentationTypology {
        id
        name
      }
      acceptedPresentationTypology {
        id
        name
        description
      }
      speeches {
        items {
          id
          title
          code
          start
          end
          type
          subSession {
            id
            session {
              id
              name
              start
              end
              room {
                id
                name
                type
              }
            }
          }
        }
      }
      processingStatus
      presentationFile {
        id
        name: originalName
        originalName
        size
        extension
      }
      isDeleted
      presenter {
        id
        participant {
          id
        }
      }
    }
  }
`;

const updateAbstractDocumentMutation = /* GraphQL */ `
  mutation UpdateAbstractDocument(
    $input: UpdateAbstractDocumentInput!
    $condition: ModelAbstractDocumentConditionInput
  ) {
    updateAbstractDocument(input: $input, condition: $condition) {
      id
    }
  }
`;

const createFileQuery = /* GraphQL */ `
  mutation CreateFile(
    $input: CreateFileInput!
    $condition: ModelFileConditionInput
  ) {
    createFile(input: $input, condition: $condition) {
      id
    }
  }
`;

export const getAbstractDocument = (id) =>
  new Promise((resolve, reject) => {
    showLoader();

    API.graphql({
      query: getAbstractDocumentQuery,
      variables: { id },
    })
      .then((response) => {
        resolve(response.data.getAbstractDocument);
      })
      .catch((e) => {
        console.error('get-abstract-document', e);
        reject();
      })
      .finally(hideLoader);
  });

export const updateAbstractDocument = (input) =>
  new Promise((resolve, reject) => {
    showLoader();

    API.graphql({
      query: updateAbstractDocumentMutation,
      variables: { input },
    })
      .then((response) => {
        resolve(response.data.updateAbstractDocument);
      })
      .catch((e) => {
        console.error('update-abstract-document', e);
        reject();
      })
      .finally(hideLoader);
  });

export const createFile = (input, filePath, extension, file) =>
  new Promise((resolve, reject) => {
    showLoader();

    API.graphql({ query: createFileQuery, variables: { input } })
      .then(async (response) => {
        const fileId = response.data.createFile.id;

        try {
          await aws.Storage.put(`${filePath}${fileId}${extension}`, file);
        } catch (e) {
          console.error('create-file', e);
          reject();
        }

        resolve(response.data.createFile);
      })
      .catch((e) => {
        console.error('create-file', e);
        reject();
      })
      .finally(hideLoader);
  });
