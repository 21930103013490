import React, { useState, useEffect } from 'react';
import { sortBy } from 'lodash';
import { useIntl } from 'react-intl';

import { addDays } from 'date-fns';

import { useHistory, useParams } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowBack from '@material-ui/icons/ArrowBack';

import {
  MainContainer,
  InnerContainer,
} from '../../shared/SponsorPagesContainers';

import { constants, appState } from '@aim/common';
import { useBuyOperations } from '../../shared/buyOperationsGqlHelper';

import {
  AimSnackbar,
  AimTypography,
  AimSnackbarSeverity,
  AimDialog,
  CustomIntl,
  AimIconAndTextButton,
} from '@aim/components';

import { StandItemDialogInfoContent } from './physicalStandItemsList/StandItemDialogInfoContent';
import { StandItemAddToCartDialogContent } from './physicalStandItemsList/StandItemAddToCartDialogContent';
import { PhysicalStandItemsTable } from './physicalStandItemsList/PhysicalStandItemsTable';

import { useOtherStandServiceItems } from '../../shared/otherStandServiceItemsGqlHelper';
// import { useDataHelper } from '../../shared/dataHelper';
import translation from '../../shared/translation';

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

//Constants
const itemTypes = constants.OtherStandServiceItemType;

const PhysicalStandItemsList = () => {
  //Hooks
  const history = useHistory();
  const {
    eventId,
    sponsorId,
    spaceId,
    categoryId,
    subCategoryId,
  } = useParams();

  const buyOperationsHelper = useBuyOperations();
  const otherStandServiceHelper = useOtherStandServiceItems();

  const intl = CustomIntl(useIntl());
  const i18n = translation.physicalStandItemsList(intl);

  //State
  const [displayItems, setDisplayItems] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [dialog, setDialog] = useState({ isOpen: false });
  const [addToCartDialog, setAddToCartDialog] = useState({ isOpen: false });
  const [bookingDuration, setBookingDuration] = useState();
  const [paymentType, setPaymentType] = useState();

  const isDoubleCost = addToCartDialog?.item?.type === itemTypes.doubleCost.id;
  const isPlusDisabled =
    addToCartDialog?.item?.availability &&
    addToCartDialog?.quantity *
      (isDoubleCost ? addToCartDialog?.amountPerQuantity : 1) >
      addToCartDialog?.item?.bookableQuantity;

  //Effects
  useEffect(() => {
    fetchData();
  }, []);

  //Functions
  //fetch data from server for avalaible category items and category info
  const fetchData = async () => {
    console.log('subCategoryId', subCategoryId);
    const result = await otherStandServiceHelper.listBySubCategory(
      eventId,
      subCategoryId
    );

    const sortedItems = sortBy(result.items, 'name');

    const nextItems =
      sortedItems.map((i) => {
        const validBookings = i.physicalStandSpaceItems.items.filter(
          (booking) =>
            booking.buyOperation &&
            booking.buyOperation.isDeleted !== 'true' &&
            booking.buyOperation.isExpired !== 'true'
        );
        // Detect still bookable quantity by summing directly booking quantities (from other sponsorizations service...)
        const bookedQtyOtherSpons = validBookings
          .filter((i) => i.buyOperation)
          .reduce((sum, curr) => {
            //filter not valid booking rows
            const currQuantity =
              curr.quantity && curr.quantity >= 0 ? curr.quantity : 1;
            const currApQ =
              curr.amountPerQuantity && curr.amountPerQuantity >= 0
                ? curr.amountPerQuantity
                : 1;

            return sum + currQuantity * currApQ;
          }, 0);

        // ...and the ones from sponsor packages
        const bookedInPackagesQty = i.physicalStandSpaceItems.items
          .filter((i) => i.sponsorPackageItem)
          .reduce((prev, curr) => {
            prev =
              prev +
              (curr?.sponsorPackageItem?.package?.buyOperations?.items.filter(
                (bo) => bo.isDeleted !== 'true' && bo.isExpired !== 'true'
              ).length || 0) *
                (curr?.quantity || 1);
            return prev;
          }, 0);
        console.log('bookedInPackagesQty', bookedInPackagesQty);

        const bookedQty = bookedQtyOtherSpons + bookedInPackagesQty;

        const bookableQuantity = i.availability - bookedQty;
        // If we have a booking for a single choice product or if we booked all availabilities purchaes is disabled
        const purchaseDisabled = i.availability && bookableQuantity < 1;

        return {
          ...i,
          physicalStandSpaceItems: {
            items: i.physicalStandSpaceItems.items.filter(
              (i) => i.buyOperation
            ),
          },
          purchaseDisabled,
          bookedQty,
          bookableQuantity,
        };
      }) || [];

    setDisplayItems(nextItems);
    setSubCategory(result.subCategory);
    setBookingDuration(
      result.otherStandServicesService.event?.sponsorServiceBookingDuration ||
        10
    );

    const paymentType =
      result.otherStandServicesService.isCreditCardEnabled &&
      result.otherStandServicesService.isBankTransferEnabled
        ? 'both'
        : (result.otherStandServicesService.isCreditCardEnabled &&
            constants.PaymentTypes.CreditCard) ||
          (result.otherStandServicesService.isBankTransferEnabled &&
            constants.PaymentTypes.BankTransfer);

    setPaymentType(paymentType || constants.PaymentTypes.BankTransfer);
  };

  const onAddToCart = (item, purchaseNow = false) => {
    const isNoteEnabled = item.isNoteEnabled;
    const nextDialog = { isOpen: true, item, purchaseNow };

    switch (item.type) {
      case itemTypes.unitaryCost.id:
        setAddToCartDialog({
          ...nextDialog,
          quantity: 1,
        });
        break;
      case itemTypes.doubleCost.id:
        setAddToCartDialog({
          ...nextDialog,
          quantity: 1,
          amountPerQuantity: 1,
        });
        break;
      case itemTypes.singleChoice.id:
      case itemTypes.typeTextService.id:
        isNoteEnabled
          ? setAddToCartDialog(nextDialog)
          : createBuyOperation(item.id, purchaseNow);
        break;

      default:
        break;
    }
  };

  const createBuyOperation = async (
    itemId,
    purchaseNow = false,
    quantity = 1,
    amountPerQuantity,
    note
  ) => {
    showLoader();
    try {
      const itemInput = {
        physicalStandSpaceItemsPhysicalStandSpaceId: spaceId,
        physicalStandSpaceItemsItemId: itemId,
        physicalStandSpaceItemsSponsorId: sponsorId,
        quantity: quantity,
        amountPerQuantity: amountPerQuantity,
      };

      const physicalStandItem = await otherStandServiceHelper.create(
        itemInput,
        false
      );

      const bookingDate = new Date();
      const bookingExpiringDate = addDays(bookingDate, bookingDuration);
      const inputBuyOperation = {
        eventId: eventId,
        buyOperationPhysicalStandSpaceItemsId: physicalStandItem.id,
        buyOperationSponsorId: sponsorId,
        date: bookingDate,
        expiringDate: bookingExpiringDate,
        paymentType: paymentType,
        type: constants.SponsorServicesType.OTHER_STAND_SERVICE.id,
        note: note,
        isExpired: 'false',
        isDeleted: 'false',
      };

      const response = await buyOperationsHelper.create(
        inputBuyOperation,
        false
      );

      const itemInputUpdate = {
        id: physicalStandItem.id,
        physicalStandSpaceItemsBuyOperationId: response.id,
      };

      await otherStandServiceHelper.update(itemInputUpdate, false);

      const destUrl = `/events/${eventId}/${sponsorId}/cart`;
      if (purchaseNow) {
        history.push({
          pathname: destUrl,
          state: {
            bookings: [response],
            fromPhysicalStand: true, // issue reference #383
          },
        });
      } else {
        await fetchData();
      }
    } catch (e) {
      console.error(e);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.snackbar.addToCartFail.label,
      });
    } finally {
      hideLoader();
    }
  };

  const showInfoDialog = async (item) => {
    console.log('subCategory', subCategory);
    setDialog({
      isOpen: true,
      item: { ...item, subCategory: subCategory.name },
    });
  };

  return (
    <MainContainer>
      <InnerContainer grow bottomSpacing>
        <div style={{ display: 'flex-root', marginTop: 20 }}>
          <Tooltip title={i18n.page.back.tooltip.label}>
            <AimIconAndTextButton
              variant="text"
              text={i18n.page.back.button.label}
              onClick={() =>
                history.push(
                  `/events/${eventId}/${sponsorId}/services-configuration/physical-stands/${spaceId}/category/${categoryId}`
                )
              }
            >
              <ArrowBack />
            </AimIconAndTextButton>
          </Tooltip>
        </div>
        <Grid item xs={4}>
          <AimTypography
            variant="h6"
            boxStyle={{ fontSize: '2rem' }}
            style={{ marginLeft: 16 }}
          >
            {subCategory?.name}
          </AimTypography>
        </Grid>

        <PhysicalStandItemsTable
          intl={intl}
          i18n={i18n}
          items={displayItems}
          showInfoDialog={showInfoDialog}
          onAddToCart={onAddToCart}
        />

        <AimSnackbar
          open={snackbar.isOpen}
          onClose={() => setSnackbar({ isOpen: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </AimSnackbar>
      </InnerContainer>

      <AimDialog
        open={dialog.isOpen}
        title={dialog?.item?.name || i18n.dialog.title.label}
        agreeText={i18n.dialog.closeButton.label}
        hideCancelButton
        onClose={() => setDialog({ isOpen: false, item: null })}
        maxWidth={'xl'}
      >
        <StandItemDialogInfoContent
          item={dialog?.item}
          i18n={i18n}
          intl={intl}
        />
      </AimDialog>
      <AimDialog
        open={addToCartDialog.isOpen}
        title={addToCartDialog?.item?.name || i18n.addToCartDialog.title.label}
        agreeText={i18n.addToCartDialog.buttons.add.label}
        disagreeText={i18n.addToCartDialog.buttons.close.label}
        onClose={() => setAddToCartDialog({ isOpen: false })}
        maxWidth={'xl'}
        onAgree={() =>
          createBuyOperation(
            addToCartDialog?.item?.id,
            addToCartDialog?.purchaseNow,
            addToCartDialog?.quantity,
            addToCartDialog?.amountPerQuantity,
            addToCartDialog?.note
          )
        }
        onAgreeDisabled={
          (addToCartDialog.item?.isNoteRequired && !addToCartDialog.note) ||
          isPlusDisabled
        }
      >
        <StandItemAddToCartDialogContent
          {...{
            i18n,
            addToCartDialog,
            setAddToCartDialog,
            isPlusDisabled,
          }}
        />
      </AimDialog>
    </MainContainer>
  );
};

export default PhysicalStandItemsList;
