import { aws } from '@aim/common';
import { useLoaderHelper } from '@aim/components';
import {
  useBreakoutRoomAttending,
  breakoutRoomAttendingSchema,
} from './breakoutRoomAttendingGqlHelper';
import {
  useBreakoutRoomInvitationUnregistered,
  breakoutRoomInvitationUnregisteredSchema,
} from './breakoutRoomInvitationUnregisteredGqlHelper';

const { API } = aws;

export const getBreakoutRoomSlotQuery = /* GraphQL */ `
  query GetBreakoutRoomSlot($id: ID!) {
    getBreakoutRoomSlot(id: $id) {
      id
      title
      start
      end
      note
      emails {
        items {
          ${breakoutRoomInvitationUnregisteredSchema}
        }
      }
      isOneToOne
      vonageSessionId
      createdAt
      updatedAt
      invitations {
        items {
          ${breakoutRoomAttendingSchema}
        }
      }
      breakoutRoom {
        sponsor {
          id
          name
          profileImage {
            createdAt
            extension
            id
            originalName
            size
            updatedAt
          }
          contacts {
            items {
              surname
              name
            }
          }
        }
      }
    }
  }
`;

const createBreakoutRoomSlot = /* GraphQL */ `
  mutation CreateBreakoutRoomSlot($input: CreateBreakoutRoomSlotInput!) {
    createBreakoutRoomSlot(input: $input) {
      id
    }
  }
`;

const deleteBreakoutRoomSlot = /* GraphQL */ `
  mutation DeleteBreakoutRoomSlot(
    $input: DeleteBreakoutRoomSlotInput!
    $condition: ModelBreakoutRoomSlotConditionInput
  ) {
    deleteBreakoutRoomSlot(input: $input, condition: $condition) {
      id
      invitations {
        items {
          id
        }
      }
      emails {
        items {
          id
        }
      }
    }
  }
`;

const updateBreakoutRoomSlot = /* GraphQL */ `
  mutation UpdateBreakoutRoomSlot(
    $input: UpdateBreakoutRoomSlotInput!
    $condition: ModelBreakoutRoomSlotConditionInput
  ) {
    updateBreakoutRoomSlot(input: $input, condition: $condition) {
      id
    }
  }
`;

export function useBreakoutRoomSlots(useLoader = false) {
  const { showLoader, hideLoader } = useLoaderHelper();
  const create = (input) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({ query: createBreakoutRoomSlot, variables: { input } })
        .then((response) => resolve(response.data.createBreakoutRoomSlot))
        .catch((e) => {
          console.error('event-create-sponsor-breakout-room-slot', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const update = (input) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({ query: updateBreakoutRoomSlot, variables: { input } })
        .then((response) => resolve(response.data.updateBreakoutRoomSlot))
        .catch((e) => {
          console.error('event-update-sponsor-breakout-room-slot', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const remove = (id) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();
      API.graphql({
        query: deleteBreakoutRoomSlot,
        variables: { input: { id } },
      })
        .then(async (response) => {
          const brEmailsHelper = useBreakoutRoomInvitationUnregistered();
          const brAttendingHelper = useBreakoutRoomAttending();
          const promises = [];
          const slot = response.data.deleteBreakoutRoomSlot;
          slot.emails.items.map((email) => {
            promises.push(brEmailsHelper.remove(email.id));
          });
          slot.invitations.items.map((inv) => {
            promises.push(brAttendingHelper.remove(inv.id));
          });
          if (promises.length) {
            await Promise.all(promises);
          }
          resolve(slot);
        })
        .catch((e) => {
          console.error('event-delete-sponsor-breakout-room-slot', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const get = (id) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: getBreakoutRoomSlotQuery,
        variables: { id: id },
      })
        .then((response) => resolve(response.data.getBreakoutRoomSlot))
        .catch((e) => {
          console.error('get-breakoutroom-slot', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  return { create, update, remove, get };
}
