import React, { useState, useEffect } from 'react';
import { aws, appState, fileHelper } from '@aim/common';
import { useParams } from 'react-router-dom';
import LogoPC from './../../../../assets/images/logo-aim-international.png';
import { AimTypography, theme } from '@aim/components';
import { isMobile } from 'react-device-detect';

const LoginLayout = ({ children, title, subtitle, width }) => {
  const { getAwsS3Link } = fileHelper;
  const { eventId } = useParams();
  const [eventName, setEventName] = useState('Intro to AIM Phygital Platform');
  const [eventConfiguration, setEventConfiguration] = useState({});
  const [logo, setLogo] = useState(null);
  const [titleCustom, setTitleCustom] = useState({
    isActive: false,
    description: '',
    subtitleActive: false,
    descriptionSubtitle: '',
  });

  useEffect(() => {
    const subscription = appState.eventConfiguration.subscribe(
      setEventConfiguration
    );
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    const getImages = async () => {
      if (eventConfiguration?.logo) {
        const link = await getAwsS3Link(
          `events/${eventId}/configuration/generalInfo/registration/logo/`,
          eventConfiguration.logo
        );
        const convertedLink = aws.s3.getS3ObjectUrl(link);

        setLogo(convertedLink);
      }
    };

    getImages();

    setTitleCustom({
      isActive: eventConfiguration.isTitleActive,
      description: eventConfiguration.loginTitleDescription,
      subtitleActive: eventConfiguration.isSubtitleActive,
      descriptionSubtitle: eventConfiguration.loginSubtitleDescription,
    });
  }, [eventConfiguration]);

  return (
    <div
      style={{
        ...(!isMobile && { marginTop: '15%' }),
        width: isMobile ? '100%' : width ? width : '60%',
        marginLeft: isMobile ? 0 : '130px',
      }}
    >
      <div
        style={{
          borderBottom: `1px solid ${theme.colors.greyScale.grey2}`,
          padding: '0px 0px 20px',
          marginBottom: 20,
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: isMobile ? 'center' : 'left',
          }}
        >
          {!logo && (
            <img
              src={LogoPC}
              width="140"
              height="80"
              style={{
                objectFit: 'cover',
              }}
            />
          )}
          {logo && (
            <img
              src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
              width={'180'}
              height={'60'}
              style={{
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                objectFit: 'cover',
                backgroundImage: `url("${logo}")`,
              }}
            />
          )}
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {!titleCustom.isActive ||
            (titleCustom.isActive && !titleCustom.description && (
              <AimTypography
                variant="h1"
                style={{
                  color: theme.colors.primary.yellow,
                  // ...(!isMobile && { marginLeft: 20 }),
                }}
              >
                {eventName}
              </AimTypography>
            ))}
        </div>

        {titleCustom.isActive && titleCustom.description && (
          <AimTypography
            variant="h1"
            style={{
              color: theme.colors.primary.yellow,
              // ...(!isMobile && { marginLeft: 20 }),
            }}
          >
            {titleCustom.description}
          </AimTypography>
        )}

        {titleCustom.subtitleActive && titleCustom.descriptionSubtitle && (
          <AimTypography variant="h4Regular">
            {titleCustom.descriptionSubtitle}
          </AimTypography>
        )}
      </div>

      <AimTypography variant="h2" boxStyle={{ fontWeight: '700' }}>
        {title}
      </AimTypography>
      {subtitle ? <AimTypography>{subtitle}</AimTypography> : <></>}

      {children}
    </div>
  );
};

export default LoginLayout;
