import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import Tooltip from '@material-ui/core/Tooltip';
import ArrowBack from '@material-ui/icons/ArrowBack';

import { aws, appState, constants } from '@aim/common';
import {
  AimIconAndTextButton,
  theme,
  AimSnackbar,
  AimSnackbarSeverity,
  CustomIntl,
} from '@aim/components';

import { StylesManager, Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import 'survey-core/defaultV2.css';

const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

const getPublicSurveyByEventQuery = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      configuration {
        publicLandingPageSurvey {
          id
          title
          status
          sentAt
          model
        }
      }
    }
  }
`;

const createSurveyResponseQuery = /* GraphQL */ `
  mutation CreateSurveyResponse($surveyId: ID!, $result: AWSJSON) {
    createSurveyResponse(
      input: { surveyResponseSurveyId: $surveyId, result: $result }
    ) {
      id
    }
  }
`;

const getEventSurvey = (eventId, isAuthenticated) =>
  new Promise((resolve, reject) => {
    showLoader();
    aws.API.graphql({
      query: getPublicSurveyByEventQuery,
      variables: {
        id: eventId,
      },
      // hack to solve public queries works also with authenticated users
      // FOR SOME REASON AWS_IAM non working anymore with authenticated users
      ...(isAuthenticated ? {} : { authMode: 'AWS_IAM' }),
    })
      .then((result) => resolve(result?.data?.getEvent?.configuration))
      .catch((e) => {
        console.error(`Error:`, e);
        reject();
      })
      .finally(() => hideLoader());
  });

const createEventSurvey = (surveyId, survey, isAuthenticated) =>
  new Promise((resolve, reject) => {
    showLoader();
    aws.API.graphql({
      query: createSurveyResponseQuery,
      variables: {
        surveyId: surveyId,
        result: JSON.stringify(survey.data),
      },
      // hack to solve public queries works also with authenticated users
      // FOR SOME REASON AWS_IAM non working anymore with authenticated users
      ...(isAuthenticated ? {} : { authMode: 'AWS_IAM' }),
    })
      .then((result) => resolve(result?.data?.createSurveyResponse))
      .catch((e) => {
        console.error(`Error create survey:`, e);
        reject();
      })
      .finally(() => hideLoader());
  });

const stylesByEventConfiguration = () => {
  const sdBody = document.querySelector('body');
  sdBody?.style?.setProperty(
    '--primary',
    appState.eventConfiguration.getValue().primaryColor
  );

  const buildButtonStyle = (variant, color, borderRadius) => {
    switch (variant) {
      case constants.TemplateButtonVariants.OUTLINED:
        return {
          'border-radius': borderRadius,
          background: 'transparent',
          border: '2px solid ' + color,
          color: theme.colors.primary.black,
        };
      case constants.TemplateButtonVariants.CONTAINED:
        return {
          'border-radius': borderRadius,
          background: color,
          color: theme.colors.primary.white,
        };
      case constants.TemplateButtonVariants.TEXT:
        return {
          background: 'transparent !important',
          color: color,
          border: 'none !important',
        };
    }
  };

  const primaryBuildButtonStyle = () =>
    buildButtonStyle(
      appState.eventConfiguration.getValue().primaryButtonVariant,
      appState.eventConfiguration.getValue().primaryColor,
      appState.eventConfiguration.getValue().primaryButtonBorderRadius
    );
  const secondaryBuildButtonStyle = () =>
    buildButtonStyle(
      appState.eventConfiguration.getValue().secondaryButtonVariant,
      appState.eventConfiguration.getValue().secondaryColor,
      appState.eventConfiguration.getValue().secondaryButtonBorderRadius
    );

  // buttons
  const svButton = document.querySelector('.sv-action input');
  const cssStyleObject = primaryBuildButtonStyle();

  const inlineStyleString = Object.keys(cssStyleObject).reduce(
    (serialized, key) => {
      serialized += `${key}: ${cssStyleObject[key]};`;
      return serialized;
    },
    ''
  );
  if (svButton) svButton.setAttribute('style', inlineStyleString);
};

const PublicSurveyPage = () => {
  const history = useHistory();
  // const [participation, setParticipation] = useState('');
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [surveyId, setSurveyId] = useState({});
  const [model, setModel] = useState();
  const intl = CustomIntl(useIntl());
  const { eventId } = useParams();

  StylesManager.applyTheme('defaultV2');
  stylesByEventConfiguration();

  const getSurvey = async () => {
    appState.isLoader.next(true);

    try {
      const awsUser = await aws.getAwsUser();
      const eventConfiguration = await getEventSurvey(eventId, !!awsUser);
      if (eventConfiguration.publicLandingPageSurvey) {
        setSurveyId(eventConfiguration.publicLandingPageSurvey.id);
        setModel(new Model(eventConfiguration.publicLandingPageSurvey.model));
      }
      appState.isLoader.next(false);
    } catch (err) {
      console.error(err);
      appState.isLoader.next(false);
    }
  };

  useEffect(() => {
    getSurvey();
  }, []);

  const onComplete = async (survey, options) => {
    const awsUser = await aws.getAwsUser();
    await createEventSurvey(surveyId, survey, awsUser);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          flex: 1,
          overflowY: 'auto',
          paddingLeft: '10%',
          paddingRight: '10%',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            // paddingLeft: 200,
            // paddingRight: 200,
          }}
        >
          <Tooltip
            title={intl.formatMessage({
              description: 'back',
              defaultMessage: 'Back',
            })}
          >
            <AimIconAndTextButton
              isUpperCase
              variant="none"
              text={intl.formatMessage({
                description: 'back label',
                defaultMessage: 'back',
              })}
              style={{
                padding: 0,
                marginLeft: 20,
              }}
              onClick={() => history.goBack()}
            >
              <ArrowBack />
            </AimIconAndTextButton>
          </Tooltip>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flex: 1,
            marginLeft: 20,
          }}
        >
          <div style={{ width: '100%' }}>
            {model ? (
              <Survey
                model={model}
                onComplete={onComplete}
                id="public-survey"
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </>
  );
};

export default PublicSurveyPage;
