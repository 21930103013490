import React from 'react';

import { AimTypography } from '@aim/components';
import { appState } from '@aim/common';

import Grid from '@material-ui/core/Grid';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

const currentTimezone = appState.eventInfo.getValue().timezone;

const moneyFormatter = new Intl.NumberFormat('it-IT', {
  style: 'currency',
  currency: 'EUR',
});

const ServiceSummaryBuyCardContent = ({ description, day, price, i18n }) => {
  return (
    <Grid container>
      <Grid item xs={12} style={{ marginBottom: 10 }}>
        <AimTypography
          variant="h6"
          margin={0}
          color="textSecondary"
          component="span"
        >
          {`${i18n.items.serviceAvailableFrom.label} ${format(
            utcToZonedTime(day, currentTimezone),
            'dd/MM/yyyy'
          )}`}
        </AimTypography>
      </Grid>

      {description && (
        <Grid item xs={12} style={{ marginBottom: 10 }}>
          <AimTypography
            variant="h6Normal"
            margin={0}
            color="textSecondary"
            component="span"
          >
            {description}
          </AimTypography>
        </Grid>
      )}

      <Grid item xs={6}>
        <AimTypography
          variant="h6"
          margin={0}
          color="textSecondary"
          component="span"
        >
          {`${i18n.items.price.label}`}
        </AimTypography>
      </Grid>
      <Grid item xs={6}>
        <AimTypography
          variant="text"
          margin={0}
          textAlign="end"
          color="textSecondary"
          component="span"
        >
          {moneyFormatter.format(price)} {/* TODO */}
          {/* TODO WHAT? */}
        </AimTypography>
      </Grid>
    </Grid>
  );
};

export default ServiceSummaryBuyCardContent;
