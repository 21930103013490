import React, { useState, useRef } from 'react';
import {
  CustomIntl,
  AimIconAndTextButton,
  AimTypography,
  AimRadio,
  AimSnackbarSeverity,
  AimTextField,
  useLoaderHelper,
} from '@aim/components';
import useI18n from '../shared/i18n';
import { useIntl } from 'react-intl';
import { Controller, useForm } from 'react-hook-form';
import SendIcon from '@material-ui/icons/Send';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

import { useBreakoutRoomUtils } from '../../../hooks/useBreakoutRoomUtils';

const BreakoutRoomRejectMotivationSelector = ({
  onRejectComplete,
  setSnackbar,
  eventId,
  breakoutRoomSlotId,
  email,
}) => {
  const intl = CustomIntl(useIntl());
  const i18n = useI18n.mailLandingPage(intl);
  const [customMotivation, setCustomMotivation] = useState();
  const [sendButtonDisabled, setSendButtonDisabled] = useState(false);

  const { showLoader, hideLoader } = useLoaderHelper();
  const { states, callLambda } = useBreakoutRoomUtils();

  const { control } = useForm({
    shouldUnregister: false,
  });

  const radioButton = useRef();
  const inputRef = useRef();

  const updateRejectMotivation = async (motivation) => {
    try {
      showLoader();
      const response = await callLambda({
        action: states.REJECTED,
        email: email,
        slotId: breakoutRoomSlotId,
        eventId: eventId,
        motivation:
          motivation === 'other'
            ? customMotivation
            : rejectMotivations.find((r) => r.id === motivation)?.text,
      });

      onRejectComplete && onRejectComplete();
    } catch (e) {
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: e.message,
      });
      setSendButtonDisabled(true);
    }
    hideLoader();
  };

  const onChangeOtherMotivation = (txt) => {
    setCustomMotivation(txt);
  };

  const rejectMotivations = [
    { id: 'm0', text: i18n.rejectMotivations.m0 },
    { id: 'm1', text: i18n.rejectMotivations.m1 },
    { id: 'm2', text: i18n.rejectMotivations.m2 },
  ];
  const rejectDescription = {
    id: 'other',
    text: i18n.rejectMotivations.other,
    placeholder: i18n.rejectMotivations.otherPlaceholder,
  };

  return (
    <div>
      <AimTypography>{i18n.page.rejectMsg1}</AimTypography>
      <AimTypography>{i18n.page.rejectMsg2}</AimTypography>
      <Controller
        name={'reject'}
        control={control}
        render={(props) => (
          <div>
            <div>
              <AimRadio
                onChange={(e) => props.onChange(e.target.value)}
                row={false}
                value={props.value}
                name={'reject'}
                formControlWidth={50}
                formControlStyle={{
                  minWidth: 50,
                  margin: 0,
                  position: 'relative',
                  right: -15,
                }}
              >
                {rejectMotivations.map((motivation) => (
                  <FormControlLabel
                    key={motivation.id}
                    value={motivation.id}
                    label={motivation.text}
                    control={<Radio />}
                  />
                ))}
                <FormControlLabel
                  ref={radioButton}
                  key={rejectDescription.id}
                  value={rejectDescription.id}
                  label={
                    <AimTextField
                      labelPlacementLeft={true}
                      customLabel={
                        <span style={{ marginRight: 8 }}>
                          {rejectDescription.text}
                        </span>
                      }
                      customInputProps={{ ref: inputRef }}
                      type={'text'}
                      formControlStyle={{
                        marginLeft: 0,
                      }}
                      inputPropsStyle={{ marginTop: 4 }}
                      fieldStyle={{ width: 500 }}
                      disabled={
                        props.value && props.value !== rejectDescription.id
                      }
                      placeholder={rejectDescription.placeholder}
                      onChange={(e) => {
                        if (props.value !== rejectDescription.id) {
                          radioButton.current.click();
                          inputRef.current.focus();
                        }
                        onChangeOtherMotivation(e.target.value);
                      }}
                    />
                  }
                  control={<Radio />}
                />
              </AimRadio>
            </div>
            <AimIconAndTextButton
              text={i18n.buttons.send}
              disabled={
                sendButtonDisabled ||
                (props.value !== rejectDescription.id &&
                  !rejectMotivations.find((r) => r.id === props.value)) ||
                (props.value === rejectDescription.id && !customMotivation)
              }
              onClick={() => {
                updateRejectMotivation(props.value);
              }}
            >
              <SendIcon />
            </AimIconAndTextButton>
          </div>
        )}
      />
    </div>
  );
};

export default BreakoutRoomRejectMotivationSelector;
