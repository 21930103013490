import { aws, appState } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

export const listBillingDocsQuery = /* GraphQL */ `
  query ListBillingDocs($id: ID!) {
    getEvent(id: $id) {
      billingDocs {
        items {
          id
          docNumber
          billingCustomer
          mailStatus
          billingFlow {
            id
            docType
          }
        }
      }
    }
  }
`;

export const listBillingDocsByClientIdQuery = /* GraphQL */ `
  query listBillingDocsByClientId($id: String) {
    productByClientId(clientId: $id) {
      items {
        productId
        billingDocument {
          id
          billingCustomer
          docNumber
          mailStatus
          document {
            id
          }
          billingFlow {
            docType
          }
        }
      }
    }
  }
`;

export const listBillingDocsByClientId = (cliendId) =>
  new Promise((resolve, reject) => {
    showLoader();

    API.graphql({
      query: listBillingDocsByClientIdQuery,
      variables: { id: cliendId },
    })
      .then((response) =>
        resolve(
          response.data.productByClientId.items.filter(
            (p) => !!p.billingDocument
          )
        )
      )
      .catch((e) => {
        console.error('listBillingDocsByClientId-error', e);
        reject();
      })
      .finally(hideLoader);
  });
