import commonFields from './CommonField';

export const mailBrResultReject = () => {
  const data = {
    ...commonFields,
  };

  const body = `
<table
  class="body"
  cellspacing="0"
  width="600"
  style="
font-family: Roboto, Arial, sans-serif;
"
>
  <tr>
    <td style="padding-left: 50px;width: 100%; font-weight: bold; font-size: 24px">${data.INVITE_TITLE}</td>
  </tr>
  <tr>
    <td style="padding-left: 50px;width: 100%;">
    ${data.TIME_GREETING},
    </td>
  </tr>
  <tr>
    <td style="padding-left: 50px;width: 100%;">
    ${data.EMAIL_MESSAGE_1} "${data.SPONSOR_NAME}" ${data.EMAIL_MESSAGE_2} ${data.DATE}.
    </td>
  </tr>
  <tr>
    <td style="padding-left: 50px;width: 100%;">
      ${data.GREATING}    
    </td>
  </tr>
  <tr>
    <td style="padding-left: 50px;width: 100%;">
      ${data.SIGN}   
    </td>
  </tr>
</table>`;

  return {
    data,
    body,
  };
};
