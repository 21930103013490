import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowBack from '@material-ui/icons/ArrowBack';
import {
  AimTypography,
  AimTitleAndButtons,
  AimIconAndTextButton,
  CustomIntl,
  styled,
  theme,
} from '@aim/components';
import { aws, appState, constants, utilities } from '@aim/common';

import AdditionalServiceCard from '../additionalServices/AdditionalServiceCard';
// import SocialEventsTableFilter from '../additionalServices/SocialEventsTableFilter';
// import { listAdditionalServices } from '../additionalServices/gqlHelper';
import useI18n from '../additionalServices/i18n';
import { productGqlHelper } from '../shared/productGqlHelper';
import {
  listProductByClientIdServiceTypeServiceId,
  listEventAdditionalServices,
} from './userSocialEvents/gqlHelper';

const TabsWrapper = styled('div')({
  display: 'flex',
  transform: 'rotate(-90deg) translateY(-170px) translateX(-160px)',
  width: 360,
});

const Tab = styled(AimTypography)({
  cursor: 'pointer',
  textTransform: 'uppercase',
});

const TYPOLOGIES = {
  SOCIAL: 'social',
  SCIENTIFIC: 'scientific',
};

const UserAdditionalServices = () => {
  const intl = CustomIntl(useIntl());
  const i18n = useI18n(intl);
  const history = useHistory();
  const { eventId, participationId } = useParams();
  const productHelper = productGqlHelper();
  const user = appState.user.getValue();

  const [activeView, setActiveView] = useState(
    constants.AdditionalServicesServiceTypology.social.productServiceType
  );
  const [displayEvents, setDisplayEvents] = useState(null);
  const [additionalServices, setAdditionalServices] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let prodResults = await listProductByClientIdServiceTypeServiceId(
        participationId,
        eventId,
        activeView
      );

      let additionalServicesResults = await listEventAdditionalServices(
        eventId
      );

      additionalServicesResults = prodResults
        ?.filter((p) =>
          additionalServicesResults.find((as) => p.productId === as.id)
        )
        .map((p) => ({
          ...additionalServicesResults.find((as) => p.productId === as.id),
          productId: p.id,
        }));

      const data = await Promise.all(
        additionalServicesResults?.map(async (element) => {
          const s3Folder = `events/${eventId}/additional-services/${element.id}/gallery/`;
          const firstImg = element?.images?.items[0];
          if (firstImg) {
            const image = await aws.Storage.get(
              `${s3Folder}${firstImg.id}${firstImg.extension}`
            );
            return {
              ...element,
              image,
            };
          }
          return {
            ...element,
            image: '',
          };
        })
      );
      setAdditionalServices(data);
      // setSocialEvents(data.filter((i) => i.typology === TYPOLOGIES.SOCIAL));
      // // setScientificEvents(
      //   data.filter((i) => i.typology === TYPOLOGIES.SCIENTIFIC)
      // );
    };
    fetchData();
  }, [activeView]);

  const TitleAndButton = () => (
    <div style={{ marginBottom: 10 }}>
      <AimTitleAndButtons
        titleBoxStyle={{ marginLeft: 0 }}
        title={intl.formatMessage({
          description: 'Social events page title',
          defaultMessage: 'Social Events',
        })}
      />
    </div>
  );

  const Cards = () => {
    const data = displayEvents || additionalServices;
    return data.map((d) => (
      <AdditionalServiceCard
        key={d.id}
        onClick={() =>
          history.push(
            `/events/${eventId}/user/${participationId}/additional-services/${d.typology}/${d.productId}`
          )
        }
        date={d.dateService && format(parseISO(d.dateService), 'dd MMMM yyyy')}
        time={d.startTime}
        title={d.title}
        image={d.image}
        i18n={i18n}
      />
    ));
  };

  const EventsTabs = () => (
    <TabsWrapper>
      <Tab
        variant="h4"
        onClick={() => {
          setActiveView(
            constants.AdditionalServicesServiceTypology.social
              .productServiceType
          );
          setDisplayEvents(null);
        }}
        boxStyle={{
          marginRight: 20,
          marginBottom: 0,
          borderBottom:
            activeView ===
            constants.AdditionalServicesServiceTypology.social
              .productServiceType
              ? `4px solid ${theme.colors.primary.yellow}`
              : 'none',
        }}
      >
        {i18n.page.tabs.socialEvents}
      </Tab>
      <Tab
        onClick={() => {
          setActiveView(
            constants.AdditionalServicesServiceTypology.scientific
              .productServiceType
          );
          setDisplayEvents(null);
        }}
        variant="h4"
        boxStyle={{
          marginBottom: 0,
          borderBottom:
            activeView ===
            constants.AdditionalServicesServiceTypology.scientific
              .productServiceType
              ? `4px solid ${theme.colors.primary.yellow}`
              : 'none',
        }}
      >
        {i18n.page.tabs.scientificEvents}
      </Tab>
    </TabsWrapper>
  );

  return (
    <>
      <div
        style={{
          flex: 1,
          maxWidth: 1320,
          width: 'calc(100% - 80px)',
        }}
      >
        <Tooltip
          title={intl.formatMessage({
            description: 'back to webcast list',
            defaultMessage: 'Back to Homepage',
          })}
        >
          <AimIconAndTextButton
            variant="none"
            text={intl.formatMessage({
              description: 'back',
              defaultMessage: 'back',
            })}
            style={{
              padding: 0,
              marginLeft: 0,
            }}
            onClick={() =>
              history.push(
                `/events/${eventId}/user/${participationId}/services`
              )
            }
          >
            <ArrowBack />
          </AimIconAndTextButton>
        </Tooltip>
        <TitleAndButton />
        {/* <SocialEventsTableFilter
          isVisible
          onFilterResult={setDisplayEvents}
          rows={
            activeView === TYPOLOGIES.SOCIAL ? socialEvents : scientificEvents
          }
          view={activeView}
        /> */}
        <Grid
          container
          spacing={2}
          style={{ position: 'relative', transform: 'translateX(-40px)' }}
        >
          <Grid item style={{ width: 40 }}>
            <EventsTabs />
          </Grid>
          <Grid container item xs spacing={1}>
            <Cards />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default UserAdditionalServices;
