import React, { useState, useEffect, useRef } from 'react';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import GroupIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';

import { useIntl } from 'react-intl';
import useI18n from '../../shared/i18n';

import { useBreakoutRoomSlots } from '../../../shared/breakoutRoomSlotGqlHelper';
import { useBreakoutRoomUtils } from '../../../../hooks/useBreakoutRoomUtils';

import {
  theme,
  CustomIntl,
  AimTypography,
  AimIconAndTextButton,
  AimSnackbarSeverity,
  AimSnackbar,
  useFormComponents,
  useLoaderHelper,
  renderLabel,
} from '@aim/components';

const defaultValues = {
  title: '',
  isOneToOne: false,
};

const SlotInfoForm = ({ selectedSlot, fetchData, selectSlot }) => {
  const intl = CustomIntl(useIntl());
  const i18n = useI18n.breakoutRoomReservations(intl);
  // Ref
  const submitButton = useRef();

  // Hooks
  const { showLoader, hideLoader } = useLoaderHelper();
  const { states } = useBreakoutRoomUtils();
  const breakoutRoomSlotsHelper = useBreakoutRoomSlots(false);

  const [selectedSlotType, setSelectedSlotType] = useState({
    isOneToOne: selectedSlot
      ? selectedSlot.isOneToOne
      : defaultValues.isOneToOne,
  });
  const [snackbar, setSnackbar] = useState({ isOpen: false });

  const formControlStyle = { width: 'calc(100% - 15px)', margin: 0 };
  const {
    renderTextInput,
    renderErrorMessage,
    renderButtons,
    formHelper,
  } = useFormComponents({
    formControlStyle: formControlStyle,
    defaultValues: defaultValues,
    validationMode: 'all',
    labels: i18n.form,
  });

  useEffect(() => {
    formHelper.reset();
    if (selectedSlot) {
      Object.keys(defaultValues).map((key) => {
        formHelper.setValue(key, selectedSlot[key] || defaultValues[key]);
      });
      setSelectedSlotType({
        isOneToOne: selectedSlot
          ? selectedSlot.isOneToOne
          : defaultValues.isOneToOne,
      });
    }
  }, [selectedSlot]);

  const onSubmit = async (data) => {
    try {
      showLoader();
      const input = {
        id: selectedSlot.id,
        ...data,
      };
      await breakoutRoomSlotsHelper.update(input);
      await fetchData(true);
      selectSlot(
        {
          ...selectedSlot,
          ...selectedSlotType,
        },
        false,
        true
      );
      hideLoader();
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.success,
        message: i18n.snackbar.updateSuccess,
      });
    } catch (e) {
      console.error(e);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.snackbar.updateFail,
      });
    }
  };

  if (!selectedSlot) return null;

  const load = selectedSlot.invitations.filter(
    (i) => i.state === states.ACCEPTED
  ).length;

  return (
    <div style={{ height: 240, ...formControlStyle, margin: '0 auto' }}>
      <Divider />
      <AimTypography variant={'text'}>{i18n.labels.infoSlot}</AimTypography>
      <div
        style={{
          border: `1px solid ${theme.colors.primary.yellow}`,
          borderRadius: 3,
          padding: 8,
          backgroundColor: theme.colors.greyScale.white,
          height: 192,
        }}
      >
        <form onSubmit={formHelper.handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item xs={12}>
              {renderTextInput({
                key: 'title',
              })}
            </Grid>
            <Grid item xs={12}>
              {renderLabel(i18n.form.types.label, formControlStyle, {
                margin: 0,
              })}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'stretch',
                }}
              >
                <AimIconAndTextButton
                  style={{ flex: '1' }}
                  variant={`${selectedSlotType.isOneToOne ? 'none' : 'yellow'}`}
                  text={i18n.form.types.groups}
                  onClick={() => {
                    formHelper.setValue('isOneToOne', false);
                    setSelectedSlotType({
                      isOneToOne: false,
                    });
                  }}
                >
                  <GroupIcon
                    style={{
                      color: `${
                        selectedSlotType.isOneToOne
                          ? theme.colors.greyScale.grey3
                          : theme.colors.primary.yellow
                      }`,
                    }}
                  />
                </AimIconAndTextButton>
                <AimIconAndTextButton
                  style={{ flex: '1' }}
                  variant={`${selectedSlotType.isOneToOne ? 'yellow' : 'none'}`}
                  text={i18n.form.types.oneToOne}
                  onClick={() => {
                    formHelper.setValue('isOneToOne', true);
                    setSelectedSlotType({
                      isOneToOne: true,
                    });
                  }}
                >
                  <PersonIcon
                    style={{
                      color: `${
                        selectedSlotType.isOneToOne
                          ? theme.colors.primary.yellow
                          : theme.colors.greyScale.grey3
                      }`,
                    }}
                  />
                </AimIconAndTextButton>
              </div>
            </Grid>
          </Grid>
          {renderButtons({
            buttonLabels: i18n.form.buttons,
            isDisabled: !selectedSlotType.isOneToOne ? load > 1 : false,
            onSaveClick: () => {
              submitButton.current.click();
            },
            onCancelClick: () => {
              formHelper.reset();
            },
          })}
          <input
            style={{ visibility: 'hidden' }}
            ref={submitButton}
            type="submit"
          />
        </form>
      </div>
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </div>
  );
};

export default SlotInfoForm;
