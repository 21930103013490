import { aws, appState } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const getEventDetailsQuery = /* GraphQL */ `
  query getEventDetails($id: ID!, $filter: ModelProfileFilterInput) {
    getEvent(id: $id) {
      id
      profiles(filter: $filter) {
        items {
          id
          name
          maxAccompanyingPersons
          category {
            name
          }
        }
      }
      preTitles {
        items {
          id
          key
          title
          preTitle
          isActive
        }
      }
    }
  }
`;

export const getEventDetails = (eventId, cluster, isLoader = true) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();
    API.graphql({
      query: getEventDetailsQuery,
      variables: {
        id: eventId,
        filter: {
          and: {
            isPublic: { eq: true },
            cluster: { eq: cluster },
            isDeleted: { ne: true },
          },
        },
      },
    })
      .then((response) => resolve(response.data.getEvent))
      .catch((e) => {
        console.error('get-event-details', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });
