import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import ArrowBack from '@material-ui/icons/ArrowBack';

import {
  AimIconAndTextButton,
  AimTypography,
  CustomIntl,
} from '@aim/components';

import { translation } from './grant/index';
import GrantForm from './grant/components/GrantForm';
import { useEffect } from 'react';
import { useState } from 'react';
import { getGrant } from './grant/grantGqlHelper';

const Grant = () => {
  // Hooks
  const intl = CustomIntl(useIntl());
  const i18n = translation.grant(intl);
  const history = useHistory();
  const { eventId, grantId } = useParams();
  const [grant, setGrant] = useState();

  useEffect(() => {
    getGrant(grantId).then((nextGrant) => setGrant(nextGrant));
  }, [grantId]);

  return grant ? (
    <>
      <div
        style={{ flex: 1, minWidth: 'calc(100vw - 120px)', marginBottom: 20 }}
      >
        <AimIconAndTextButton
          variant="none"
          text={i18n.backButton}
          style={{
            padding: 0,
          }}
          onClick={() =>
            location.state?.back
              ? history.push(location.state.back)
              : history.push(`/events/${eventId}/landing`)
          }
        >
          <ArrowBack />
        </AimIconAndTextButton>
        <AimTypography variant={'h1'}>
          {grant?.type === 'abstract'
            ? i18n.abstractTitle
            : i18n.educationalTitle}
        </AimTypography>
        <GrantForm eventId={eventId} grantId={grantId} grant={grant} />
      </div>
    </>
  ) : (
    <></>
  );
};

export default Grant;
