import React, { useState, useEffect, useRef } from 'react';
import { MainContainer, Container } from '../serviceList/BreakoutRoomList';
import { useIntl } from 'react-intl';
import useI18n from '../shared/i18n';
import Joi from 'joi';

import { format, startOfDay, addDays, addSeconds, isBefore } from 'date-fns';

import { useParams, useHistory } from 'react-router-dom';
import {
  theme,
  CustomIntl,
  AimCalendarOneDay,
  useLoaderHelper,
  useFormComponents,
  AimIconAndTextButton,
  AimSnackbar,
  AimSnackbarSeverity,
  useDataHelper,
  AimDialog,
  AimTypography,
} from '@aim/components';

import { useBreakoutRoom } from '../../shared/breakoutRoomGqlHelper';
import { useBreakoutRoomSlots } from '../../shared/breakoutRoomSlotGqlHelper';
import { useBreakoutRoomUtils } from '../../../hooks/useBreakoutRoomUtils';

import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import ArrowForward from '@material-ui/icons/ArrowForward';
import TitleAndBackButton from '../../shared/TitleAndBackButton';

const {
  parseBreakoutRoom,
  getHours,
  checkIsPurchased,
  setDate,
  states,
  getStatesOfInvitations,
} = useBreakoutRoomUtils();
const { showLoader, hideLoader } = useLoaderHelper();
const { useBreakoutRoomDataHelpers, decodeDbNumber } = useDataHelper();
const {
  s1lte1,
  e1lts2,
  s2lte2,
  calcBreakoutRoomSlots,
  checkIsTouched,
  getDurations,
} = useBreakoutRoomDataHelpers();

const schema = Joi.object().keys({
  id: Joi.string(),
  date: Joi.string(),
  exceeded: Joi.boolean().valid(false),
  start1: Joi.custom(s1lte1),
  end1: Joi.custom(e1lts2),
  start2: Joi.custom(s2lte2),
  end2: Joi.string(),
  maxAttendants: Joi.number().max(50).min(1),
  purchasedHours: Joi.number(),
  slots: Joi.array(),
  newSlots: Joi.array(),
  createSlots: Joi.boolean(),
  duration: Joi.any(),
  price: Joi.any(),
  sponsor: Joi.any(),
});

const _defaultValues = {
  id: undefined,
  date: undefined,
  exceeded: false,
  start1: '08:00',
  end1: '12:00',
  start2: '14:00',
  end2: '18:00',
  maxAttendants: 50,
  duration: 60,
  price: 0,
  purchasedHours: 0,
  sponsor: undefined,
  slots: [],
  newSlots: [],
  createSlots: false,
};

const BreakoutRoomConfiguration = () => {
  const intl = CustomIntl(useIntl());
  const i18n = useI18n.breakoutRoomConfiguration(intl);
  const { eventId, sponsorId, breakoutRoomId } = useParams();
  const breakoutRoomHelper = useBreakoutRoom(false);
  const breakoutRoomSlotHelper = useBreakoutRoomSlots(false);
  const history = useHistory();

  const formControlStyle = { width: 'calc(100% - 15px)' };
  const {
    renderTextInput,
    renderSelectInput,
    renderButtons,
    renderErrorMessage,
    formHelper,
  } = useFormComponents({
    formControlStyle,
    validationMode: 'all',
    defaultValues: _defaultValues,
    joiSchema: schema,
    labels: i18n.form.labels,
  });

  const durations = getDurations(4);
  const [warnings, setWarnings] = useState();
  const [exceeded, setExceeded] = useState(false);
  const [editConfirmationDialog, setEditConfirmationDialog] = useState({
    isOpen: false,
  });
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [change, setChange] = useState({
    ..._defaultValues,
    duration: durations[durations.length - 1].value,
  });
  const [breakoutRoom, setBreakoutRoom] = useState();
  const [events, setEvents] = useState([]);
  const [eventDates, setEventDates] = useState();

  const updateFormData = async () => {
    await formHelper.trigger();
    if (Object.entries(formHelper.errors).length > 0) {
      setEvents([]);
      setWarnings('');
    } else {
      if (change.date) await calcSlots();
    }
  };

  useEffect(() => {
    if (breakoutRoom && eventDates) {
      if (isBrEditable(breakoutRoom)) {
        formHelper.reset();
        let cng = {};
        console.log('_defaultValues', _defaultValues);
        Object.keys(_defaultValues).map((key) => {
          let val;
          if (key === 'date') {
            val =
              breakoutRoom.hasOwnProperty(key) && breakoutRoom[key]
                ? format(new Date(breakoutRoom[key]), 'yyyy-MM-dd')
                : eventDates?.start;
          } else if (
            key === 'start1' ||
            key === 'end1' ||
            key === 'start2' ||
            key === 'end2'
          ) {
            val =
              (breakoutRoom.hasOwnProperty(key) &&
                breakoutRoom[key] &&
                getHours(breakoutRoom[key])) ||
              _defaultValues[key];
          } else {
            val =
              (breakoutRoom.hasOwnProperty(key) && breakoutRoom[key]) ||
              _defaultValues[key];
          }
          //set dati default o fetched
          formHelper.setValue(key, val);
          cng[key] = val;
        });
        setChange(cng);
      } else {
        goBack();
      }
    }
  }, [breakoutRoom]);

  useEffect(() => {
    updateFormData();
  }, [change]);

  // Ref
  const submitButton = useRef();

  useEffect(() => {
    fetchData();
  }, []);

  const isBrEditable = (br) => {
    console.log('br', br);
    //la br è editabile solo se a livello globale può essere modificata e se si è in una tata valida (entro la mezzanotte del giorno prima che inizi)
    const flag =
      br.breakoutRoomService.isSponsorEditable &&
      isBefore(new Date(), addSeconds(startOfDay(new Date(br.date)), -1));
    return !br.date || flag;
  };

  const getMinDate = () => {
    const min = startOfDay(addDays(new Date(), 1));
    if (eventDates?.start) {
      if (isBefore(min, new Date(eventDates?.start))) {
        console.log('eventDates?.start', eventDates?.start);

        return eventDates?.start;
      }
    }
    console.log('format(min;', format(min, 'yyyy-MM-dd'));
    return format(min, 'yyyy-MM-dd');
  };

  const goBack = () => {
    history.push(
      `/events/${eventId}/${sponsorId}/services-configuration/breakoutrooms`
    );
  };

  const pushNavigation = () => {
    history.push({
      pathname: `/events/${eventId}/${sponsorId}/services-configuration/breakoutrooms/${breakoutRoom.id}`,
      state: {
        breakoutRoom: breakoutRoom,
        alreadyParsedData: true,
      },
    });
  };

  const fetchData = async (forceDB = false) => {
    showLoader();
    const eventDate = await breakoutRoomHelper.getEvent(eventId);
    if (eventDate) setEventDates(eventDate);
    let res;
    if (!forceDB && history.location?.state?.breakoutRoom) {
      res = history.location.state.breakoutRoom;
    } else {
      res = await breakoutRoomHelper.get(breakoutRoomId);
    }
    history.replace(history.location.pathname, null);
    const parsedBR = parseBreakoutRoom(res);
    checkIsPurchased(parsedBR, history);
    setBreakoutRoom(parsedBR);
    hideLoader();
  };

  const onSubmit = (data) => {
    if (!data) {
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.snackbar.editFail,
      });
      return;
    }
    const date = new Date(data.date);

    const parsedData = {
      id: data.id,
      date: date.toISOString(),
      duration: data.duration,
      end1: setDate(date, data.end1),
      end2: setDate(date, data.end2),
      start1: setDate(date, data.start1),
      start2: setDate(date, data.start2),
      maxAttendants: data.maxAttendants,
    };

    if (breakoutRoom.slots.filter((s) => s.invitations.length > 0).length > 0) {
      //se ci sono degli slot con invitati e ho toccato uno di questi devo ricreare tutto (e in caso avvertire gli invitati della modifica)
      setEditConfirmationDialog({ isOpen: true, data: parsedData });
    } else {
      onConfirmEdit(parsedData, checkIsTouched(breakoutRoom, data));
    }
  };

  const onConfirmEdit = async (data, touchedSlots = true) => {
    showLoader();
    try {
      const promises = [];
      if (touchedSlots) {
        //significa che ho modificato gli slot, se ne avevo e quindi li elimino
        breakoutRoom.slots.map((slot) => {
          promises.push(breakoutRoomSlotHelper.remove(slot.id));
        });
      }

      //devo popolare gli slot
      if (touchedSlots) {
        events.map((slot) => {
          const inputSlot = {
            breakoutRoomSlotBreakoutRoomId: breakoutRoomId,
            start: slot.start,
            end: slot.end,
            isOneToOne: data.maxAttendants === 1,
          };
          promises.push(breakoutRoomSlotHelper.create(inputSlot));
        });
      }

      //update breakoutRoom
      promises.push(breakoutRoomHelper.update(data));

      await Promise.all(promises);

      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.success,
        message: i18n.snackbar.editSuccess,
      });
      await fetchData(true);
    } catch (err) {
      console.error(err);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.snackbar.editFail,
      });
    }
    hideLoader();
  };

  const checkIsDisabled = () => {
    if (!breakoutRoom) return true;
    if (
      breakoutRoom.slots?.filter(
        (s) =>
          s.invitations?.filter((i) => i.state === states.ACCEPTED).length > 0
      ).length > 0
    ) {
      return true;
    }
    return false;
  };

  const calcSlots = async () => {
    const [slots, warningData, purchasedHours] = calcBreakoutRoomSlots({
      i18n: i18n,
      data: change,
      originalBreakoutRoom: breakoutRoom,
      maxAvailableHours: breakoutRoom.breakoutRoomService.maxAvailableHours,
    });
    formHelper.setValue('purchasedHours', purchasedHours);
    const _exceeded = warningData.purchasedHours < warningData.totalDuration;
    formHelper.setValue('exceeded', _exceeded);
    setWarnings(renderWarning(warningData, _exceeded));
    setExceeded(_exceeded);
    if (!_exceeded) setEvents(slots);
    else setEvents([]);
  };

  const renderEditConfirmationDialog = () => {
    if (!editConfirmationDialog.isOpen) return null;
    const slots = breakoutRoom.slots;
    const states = {};
    slots.map((s) => {
      const _states = getStatesOfInvitations(s.invitations);
      Object.keys(_states).map((key) => {
        !states.hasOwnProperty(key)
          ? (states[key] = _states[key])
          : (states[key] += _states[key]);
      });
    });
    return (
      <AimDialog
        open={editConfirmationDialog.isOpen}
        title={`${i18n.dialog.edit.title}`}
        onAgree={() => {
          onConfirmEdit(editConfirmationDialog.data);
        }}
        onClose={() => {
          setEditConfirmationDialog({ isOpen: false });
        }}
      >
        <AimTypography variant={'text'}>
          {i18n.dialog.edit.message1}
        </AimTypography>
        <ul>
          {Object.keys(states).map((key) => (
            <li key={key}>{`Status ${key}: ${states[key]}`}</li>
          ))}
        </ul>
        <AimTypography variant={'formError'}>
          {i18n.dialog.edit.message2}
        </AimTypography>
      </AimDialog>
    );
  };

  const headers = [
    {
      key: 'date',
      size: 12,
      type: 'date',
      customInputProps: { min: getMinDate(), max: eventDates?.end },
      customChange: true,
      isDisabled: checkIsDisabled(),
    },
    {
      key: 'duration',
      size: 12,
      inputType: 'select',
      array: durations,
      isDisabled: !change.date || checkIsDisabled(),
      customChange: true,
    },
    {
      key: 'start1',
      size: 6,
      type: 'time',
      isDisabled: !change.date || checkIsDisabled(),
      customChange: true,
    },
    {
      key: 'end1',
      size: 6,
      type: 'time',
      isDisabled: !change.date || checkIsDisabled(),
      customChange: true,
    },
    {
      key: 'start2',
      size: 6,
      type: 'time',
      isDisabled: !change.date || checkIsDisabled(),
      customChange: true,
    },
    {
      key: 'end2',
      size: 6,
      type: 'time',
      isDisabled: !change.date || checkIsDisabled(),
      customChange: true,
    },
    { key: 'maxAttendants', size: 12, type: 'number' },
  ];

  const renderForm = () => {
    return (
      <div style={{ padding: 12 }}>
        <form onSubmit={formHelper.handleSubmit(onSubmit)}>
          <Grid container>
            {headers.map(
              ({
                key,
                size,
                isDisabled,
                type,
                inputType,
                array,
                name,
                keyName,
                customInputProps = false,
                customChange = false,
              }) => {
                return (
                  <Grid item key={key} xs={size}>
                    {inputType === 'select'
                      ? renderSelectInput({
                          key,
                          array,
                          name,
                          keyName,
                          isDisabled,
                          customOnChange: customChange
                            ? (value) => {
                                setChange((pre) => ({ ...pre, [key]: value }));
                              }
                            : null,
                        })
                      : renderTextInput({
                          key,
                          isDisabled,
                          type,
                          customInputProps,
                          customOnChange: customChange
                            ? (value) => {
                                console.log('value', value);
                                setChange((pre) => ({ ...pre, [key]: value }));
                              }
                            : null,
                        })}
                  </Grid>
                );
              }
            )}
          </Grid>
          {renderButtons({
            buttonLabels: i18n.buttons,
            isDisabled: exceeded,
            onSaveClick: () => {
              submitButton.current.click();
            },
            onCancelClick: () => {
              formHelper.reset();
            },
          })}
          <input
            style={{ visibility: 'hidden' }}
            ref={submitButton}
            type="submit"
          />
        </form>
      </div>
    );
  };

  const renderCalendar = () => {
    return (
      <div style={{ backgroundColor: theme.colors.greyScale.backgroundGrey }}>
        <AimCalendarOneDay
          day={change.date && new Date(change.date)}
          events={events}
          i18n={i18n.calendar}
          step={change.duration}
          style={{ height: 458 }}
        />
      </div>
    );
  };

  const renderWarning = (data, exceeded) => {
    if (!data) return null;
    let warning;
    let messageTitle;
    const maxAvailableOrPurchased = data.purchasedHours;
    if (
      data.totalDuration !== maxAvailableOrPurchased ||
      data.slotRestMinutes.A + data.slotRestMinutes.B > 0
    ) {
      messageTitle = exceeded
        ? i18n.form.warnings.labelError
        : i18n.form.warnings.label;
      if (data.totalDuration > maxAvailableOrPurchased) {
        //CALCOLO ECCEDENZA
        messageTitle += ` ${i18n.form.warnings.exceededHours} (+${(
          data.totalDuration - maxAvailableOrPurchased
        ).toFixed(0)}min)`;
      } else if (data.totalDuration < maxAvailableOrPurchased) {
        //CALCOLO RESIDUO
        messageTitle += ` ${i18n.form.warnings.uselessHours} (${(
          data.totalDuration - maxAvailableOrPurchased
        ).toFixed(0)}min)`;
      }
      //COSTRUZIONE MESSAGGIO DI AVVISO
      const style = { margin: 'revert', padding: 'revert' };
      const messageDescription = (
        <ul style={style}>
          {/* <li
            style={style}
          >{`${i18n.form.warnings.maxAvailableHours}: ${data.maxAvailableHours}min`}</li> */}
          <li style={style}>{`${
            i18n.form.warnings.purchasedHours
          }: ${data.purchasedHours.toFixed(0)}min`}</li>
          <li style={style}>{`${
            i18n.form.warnings.selectedHours
          }: ${data.totalDuration.toFixed(0)}min`}</li>
          <li style={style}>
            {`${i18n.form.warnings.allocatedHours}: ${data.totalMinutes.toFixed(
              0
            )}min`}
            <ul style={style}>
              <li style={style}>{`${(
                data.slotTotalMinutes.A * data.duration
              ).toFixed(0)} slots ${i18n.form.warnings.inRange1}`}</li>
              <li style={style}>{`${(
                data.slotTotalMinutes.B * data.duration
              ).toFixed(0)} slots ${i18n.form.warnings.inRange2}`}</li>
            </ul>
          </li>
          {data.totalRest > 0 ? (
            <li style={style}>
              {`${
                i18n.form.warnings.notAllocatedHours
              }: ${data.totalRest.toFixed(0)}min`}
              <ul style={style}>
                <li style={style}>{`${(
                  data.slotRestMinutes.A * data.duration
                ).toFixed(0)}min ${i18n.form.warnings.inRange1}`}</li>
                <li style={style}>{`${(
                  data.slotRestMinutes.B * data.duration
                ).toFixed(0)}min ${i18n.form.warnings.inRange2}`}</li>
              </ul>
            </li>
          ) : null}
        </ul>
      );
      warning = (
        <div>
          {messageTitle}
          {messageDescription}
        </div>
      );
    }
    return warning;
  };

  return (
    <MainContainer>
      <TitleAndBackButton
        backOnClickFunction={() => goBack()}
        backOnHoverText={i18n.page.backButton.tooltip}
        title={i18n.page.title}
        actionButton={
          <AimIconAndTextButton
            variant="lightBlue"
            text={i18n.page.forwardButton}
            disabled={
              breakoutRoom?.slots ? breakoutRoom?.slots.length === 0 : true
            }
            onClick={() => pushNavigation()}
          >
            <ArrowForward />
          </AimIconAndTextButton>
        }
      />
      <Container>
        <Grid container>
          <Grid item xs={6}>
            {renderForm()}
          </Grid>
          <Grid item xs={6}>
            {renderCalendar()}
          </Grid>
        </Grid>
        {checkIsDisabled()
          ? renderErrorMessage(
              'la breakout room ha degli slot con prenotazioni accettate, per poter modificare gli slot bisogna annullare tutte le prenotazioni'
            )
          : null}
        {renderErrorMessage(warnings)}
        {renderEditConfirmationDialog()}
        <AimSnackbar
          open={snackbar.isOpen}
          onClose={() => setSnackbar({ isOpen: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </AimSnackbar>
      </Container>
    </MainContainer>
  );
};

export default BreakoutRoomConfiguration;
