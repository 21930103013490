import React from 'react';
import { isWithinInterval, addDays } from 'date-fns';
import Grid from '@material-ui/core/Grid';

import {
  StepDivider,
  Title,
  AimSelectForm,
  AimSelectMenuItem,
} from '@aim/components';

const formControlStyle = { width: 'calc(100% - 10px)' };

const PresentationStep = ({
  i18n,
  abstractService,
  title,
  control,
  errors,
  getValues,
  variant = 'grey',
}) => {
  if (!abstractService) return null;

  const { presentationTypologies } = abstractService;

  const isInIntervallDate = (startDate, endDate) =>
    isWithinInterval(new Date(), {
      start: new Date(startDate),
      end: addDays(new Date(endDate), 1),
    });

  const presentationMode = getValues('presentationMode');
  const choices = presentationTypologies.items.filter(
    (pt) =>
      pt.presentationMode === presentationMode &&
      isInIntervallDate(pt.validationDate, pt.expirationDate)
  );

  return (
    <>
      <Title>{title}</Title>
      <StepDivider />
      <Grid container>
        <AimSelectForm
          selectVariant={variant}
          control={control}
          errors={errors}
          name="preferredExposureId"
          label={i18n.wizard.subtitles.presentations}
          formControlStyle={formControlStyle}
        >
          {choices.map((item) => {
            return (
              <AimSelectMenuItem key={item.id} value={item.id}>
                {item.isLateBreaker
                  ? `${item.name} (${i18n.wizard.subtitles.lateBreaker})`
                  : item.name}
              </AimSelectMenuItem>
            );
          })}
        </AimSelectForm>
      </Grid>
    </>
  );
};

export default PresentationStep;
