import { aws, appState } from '@aim/common';
import { format } from 'date-fns';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const getParticipationGrantsQuery = /* GraphQL */ `
  query getParticipationGrants($id: ID!) {
    participation: getParticipation(id: $id) {
      participationGrant(filter: { isDeleted: { ne: true } }) {
        items {
          id
          participationGrantGrantId
          participationGrantParticipationId
          abstractId
          publicationDate
          requestStatus
          additionalServices
          ticket
          allotment
          travel
          grant {
            id
            type
            instruction
            title
            closingDate
            additionalServices
            additionalServicesDetail
            ticket
            ticketDetail
            allotment
            allotmentDetail
            travel
            travelDetail
          }
        }
      }
    }
  }
`;

const updateParticipationGrantQuery = /* GraphQL */ `
  mutation UpdateParticipationGrant(
    $input: UpdateParticipationGrantInput!
    $condition: ModelParticipationGrantConditionInput
  ) {
    updateParticipationGrant(input: $input, condition: $condition) {
      id
    }
  }
`;

export const getParticipationGrants = (participantId) =>
  new Promise((resolve, reject) => {
    showLoader();

    API.graphql({
      query: getParticipationGrantsQuery,
      variables: { id: participantId },
    })
      .then(({ data }) => {
        const { participation } = data;
        resolve(participation.participationGrant?.items);
      })
      .catch((e) => {
        console.error('event-get-participation-grant-detail', e);
        reject();
      })
      .finally(hideLoader);
  });

export const updateParticipationGrant = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    API.graphql({
      query: updateParticipationGrantQuery,
      variables: { input },
    })
      .then((response) => {
        resolve(response.data.updateParticipationGrant);
      })
      .catch((e) => {
        console.error('update-participation-grant', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });
