import React from 'react';

import { AimTypography } from '@aim/components';

const InformationCard = ({ title, value }) => {
  return (
    <div
      style={{
        backgroundColor: 'white',
        marginRight: 20,
      }}
    >
      <AimTypography variant={'h3'}>{title}</AimTypography>
      <AimTypography variant={'text'}>{value}</AimTypography>
    </div>
  );
};

export default InformationCard;
