import React from 'react';

import { AimFilterLight } from '@aim/components';

// Initial State for filter
const initialState = {
  participation: '',
};

// Function onFilter
const stateFiltersFns = {
  participation: (item, value) =>
    item.userTxt.toLowerCase().includes(value.toLowerCase()) ||
    item.email.toLowerCase().includes(value.toLowerCase()),
};

function ParticipationFilter({ onFilterResult, rows, i18n }) {
  const model = [
    {
      name: 'participation',
      label: i18n.name.label,
      type: 'AimTextField',
    },
  ];

  return (
    <AimFilterLight
      rows={rows}
      initialState={initialState}
      filtersFns={stateFiltersFns}
      model={model}
      onFilterResult={onFilterResult}
      noPadding
    />
  );
}

export default ParticipationFilter;
