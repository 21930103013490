import styled from 'styled-components';

export const TitleSection = styled.h2`
  font-size: 2rem;
  font-weight: 300;
  text-align: center;
  padding-top: 3rem;
  padding-bottom: 1.5rem;
`;

export const TitleAgendaSection = styled(TitleSection)`
  padding-right: 1.5rem;
  padding-left: 1.5rem;
`;

export const TitleSectionLeft = styled(TitleSection)`
  text-align: left;
  padding-left: 15rem;
  @media (max-width: 700px) {
    padding-left: 1.5rem;
  }
`;
