import { constants } from '@aim/common';
import { isAfter, isBefore, parseISO } from 'date-fns';

export const getModificationData = (p) => {
  // get num of modification requests by status
  const initRes = {
    [constants.ModificationRequestStatus.ACCEPTED]: 0,
    [constants.ModificationRequestStatus.REJECTED]: 0,
    [constants.ModificationRequestStatus.PENDING]: 0,
  };

  const modReqs = p.participationHistorical.items.reduce((res, curr) => {
    if (curr.modificationRequest) {
      res[curr.modificationRequest.requestStatus] += 1;
    }
    return res;
  }, initRes);

  // composing of string for modification column
  const modification = Object.keys(modReqs).reduce((res, curr) => {
    if (res !== '' && modReqs[curr] > 0) {
      res += '\n';
    }
    if (modReqs[curr] > 0) {
      return (res += `${curr}${
        p.participationHistorical.items.length > 1 ? `(${modReqs[curr]})` : ''
      }`);
    } else {
      return (res += '');
    }
  }, '');

  return { modification: { label: modification, reqs: modReqs } };
};

export const getDeadlinesParsed = (deadline) => {
  return deadline ? JSON.parse(deadline) : {};
};

export const checkIsPostDeadlineAndBeforeFreeze = (deadline) => {
  const deadlines = getDeadlinesParsed(deadline);
  const isAfterStandardDeadline = isAfter(
    new Date(),
    parseISO(deadlines?.deadline)
  );
  const isBeforeFreezeDeadline = isBefore(
    new Date(),
    parseISO(deadlines?.freezeDeadline)
  );
  return isAfterStandardDeadline && isBeforeFreezeDeadline;
};

export const checkIsBeforeFreezeDeadline = (deadline) => {
  const deadlines = getDeadlinesParsed(deadline);
  return isBefore(new Date(), parseISO(deadlines.freezeDeadline));
};

export const checkIsGroupDisabled = (deadline, closingDate) => {
  const deadlines = getDeadlinesParsed(deadline);
  const isAfterStandardDeadline = isAfter(
    new Date(),
    parseISO(deadlines?.deadline)
  );
  // console.log('test checkIdGroupDisabled ', deadline, closingDate);
  return (
    !checkIsBeforeFreezeDeadline(deadline) ||
    (!isAfterStandardDeadline && closingDate ? true : false)
  );
};

export const addCreateReqToHistoricalOfParticipation = (modReqs, p) => {
  const createRequests = modReqs.items.filter(
    (x) =>
      x.modificationRequest.requestStatus !==
        constants.ModificationRequestStatus.PENDING &&
      x.modificationRequest.type === constants.ModificationRequestTypes.CREATE
  );
  const paxFound = createRequests.find((req) => req.email === p.email);
  if (paxFound && p.participationHistorical.items.length === 0) {
    const historicalItems = {
      modificationRequest: paxFound?.modificationRequest,
    };
    p.participationHistorical.items = [historicalItems];
  }
};

export const sortBy = (array, fieldName) => {
  return array
    ? array.sort((a, b) => {
        if (a[fieldName] < b[fieldName]) return -1;
        if (b[fieldName] > a[fieldName]) return 1;
        return 0;
      })
    : [];
};
