import React, { useState, useEffect } from 'react';

import { useIntl } from 'react-intl';

import { format, addDays } from 'date-fns';

import { useHistory, useParams } from 'react-router-dom';

import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';

import { appState } from '@aim/common';

import { useSymposiums } from './../shared/symposiumsGqlHelper';
import { useBuyOperations } from './../shared/buyOperationsGqlHelper';

import {
  AimTableRow,
  AimTableCell,
  AimTableNoPaging,
  AimIconButton,
  AimSnackbar,
  AimSnackbarSeverity,
  CustomIntl,
} from '@aim/components';

import translation from './../shared/translation';

const SymposiumSessionsList = ({
  sessions,
  vatRate,
  bookingDuration,
  paymentType,
  fetchAgendaSessions,
}) => {
  //Hooks
  const history = useHistory();
  const { eventId, sponsorId } = useParams();

  const { create: createBuyOperation } = useBuyOperations();
  const { update: updateSymposium } = useSymposiums();
  const intl = CustomIntl(useIntl());
  const i18n = translation.symposiumSessionsList(intl);

  //State
  const [displaySessions, setDisplaySessions] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [selectedSessions, setSelectedSessions] = useState([]);
  const [snackbar, setSnackbar] = useState({ isOpen: false });

  //Effects
  useEffect(() => {
    if (sessions) setDisplaySessions(sessions);
  }, [sessions]);

  //Functions
  const showLoader = () => appState.isLoader.next(true);

  const hideLoader = () => appState.isLoader.next(false);

  const onAddToCart = async (sessionId, purchaseNow = false) => {
    showLoader();
    try {
      const bookingDate = new Date();
      const bookingExpiringDate = addDays(bookingDate, bookingDuration);
      const inputBuyOperation = {
        eventId: eventId,
        buyOperationSymposiumId: sessionId,
        buyOperationSponsorId: sponsorId,
        date: bookingDate,
        expiringDate: bookingExpiringDate,
        paymentType: paymentType,
        isExpired: 'false',
        isDeleted: 'false',
      };

      const response = await createBuyOperation(inputBuyOperation, false);

      const inputSymposium = {
        id: sessionId,
        symposiumBuyOperationId: response.id,
      };

      await updateSymposium(inputSymposium, false);

      const destUrl = `/events/${eventId}/${sponsorId}/cart`;
      if (purchaseNow) {
        history.push({
          pathname: destUrl,
          state: {
            bookings: [response],
          },
        });
      } else {
        await fetchAgendaSessions();
      }
    } catch (e) {
      console.error(e);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.snackbar.addToCartFail.label,
      });
    } finally {
      hideLoader();
    }
  };

  // Columns
  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: i18n.table.columns.name.label,
    },
    {
      id: 'day',
      numeric: false,
      disablePadding: false,
      label: i18n.table.columns.day.label,
    },
    {
      id: 'start',
      numeric: false,
      disablePadding: false,
      label: i18n.table.columns.start.label,
    },
    {
      id: 'end',
      numeric: false,
      disablePadding: false,
      label: i18n.table.columns.end.label,
    },
    {
      id: 'room',
      numeric: false,
      disablePadding: false,
      label: i18n.table.columns.room.label,
    },
    {
      id: 'netPrice',
      numeric: false,
      disablePadding: false,
      label: i18n.table.columns.netPrice.label,
    },
    {
      id: 'vat',
      numeric: false,
      disablePadding: false,
      label: i18n.table.columns.vat.label,
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: i18n.table.columns.status.label,
    },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: i18n.table.columns.actions.label,
    },
  ];

  const Row = ({ row, rowKey, index, isChecked }) => {
    return (
      <>
        <AimTableRow hover>
          <AimTableCell padding="checkbox"></AimTableCell>
          <AimTableCell>{row?.agendaSession?.name}</AimTableCell>
          <AimTableCell>
            {row?.agendaSession?.start
              ? format(new Date(row?.agendaSession?.start), 'dd/MM/yyyy')
              : '-'}
          </AimTableCell>
          <AimTableCell>
            {row?.agendaSession?.start
              ? format(new Date(row?.agendaSession?.start), 'HH:mm')
              : '-'}
          </AimTableCell>
          <AimTableCell>
            {row?.agendaSession?.end
              ? format(new Date(row?.agendaSession?.end), 'HH:mm')
              : '-'}
          </AimTableCell>
          <AimTableCell>{row?.agendaSession?.room?.name}</AimTableCell>
          <AimTableCell>{Number(row.price / 100).toFixed(2)} €</AimTableCell>
          <AimTableCell>{vatRate ? `${vatRate}%` : `-`}</AimTableCell>
          <AimTableCell>
            {row?.sponsor?.id
              ? i18n.table.status.purchased.label
              : i18n.table.status.free.label}
          </AimTableCell>
          <AimTableCell>
            <AimIconButton
              variant="yellowFill"
              small
              onClick={() => onAddToCart(row[rowKey], true)}
              disabled={row?.buyOperation?.id || !paymentType}
            >
              <SwapHorizIcon />
            </AimIconButton>
            <AimIconButton
              variant="violetFill"
              small
              onClick={() => onAddToCart(row[rowKey])}
              disabled={row?.buyOperation?.id || !paymentType}
            >
              <ShoppingCartIcon />
            </AimIconButton>
          </AimTableCell>
        </AimTableRow>
      </>
    );
  };

  return (
    <>
      <AimTableNoPaging
        title={'i18n.table.title'}
        headCells={headCells}
        rows={displaySessions}
        notCheckable={false}
        checkedItems={checkedItems}
        onChecked={(e) => {
          setCheckedItems(e);
        }}
        selected={selectedSessions}
        setSelected={() => {}}
        rowKey="id"
      >
        <Row />
      </AimTableNoPaging>

      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </>
  );
};

export default SymposiumSessionsList;
