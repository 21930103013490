import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import useI18n from './sponsorBookingAppointment/i18n';
import { format } from 'date-fns';

import {
  MainContainer,
  Container,
} from './sponsorServiceConfigurationWall/serviceList/BreakoutRoomList';
import useMailI18n from './sponsorServiceConfigurationWall/shared/i18n';

import {
  CustomIntl,
  AimIconAndTextButton,
  AimTypography,
  AimSnackbarSeverity,
  AimSnackbar,
  useLoaderHelper,
} from '@aim/components';
import { appState } from '@aim/common';

import { useBreakoutRoomAttending } from './shared/breakoutRoomAttendingGqlHelper';

import { useBreakoutRoomUtils } from '../hooks/useBreakoutRoomUtils';

// import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';

import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import CheckIcon from '@material-ui/icons/Check';
import TitleAndBackButton from './shared/TitleAndBackButton';
import { utcToZonedTime } from 'date-fns-tz';

const currentTimezone = appState.eventInfo.getValue().timezone;
const fields = ['exhibitor', 'typology', 'date', 'time', 'notes'];

const SponsorBookingConfirmAppointment = () => {
  // Hooks
  const intl = CustomIntl(useIntl());
  const i18n = useI18n(intl);
  const { eventId, sponsorId } = useParams();
  const history = useHistory();
  const useBreakoutRoomAttendingHelper = useBreakoutRoomAttending(false);
  const { showLoader, hideLoader } = useLoaderHelper();
  const {
    states,
    callLambda,
    emailTypes,
    generateBreakoutRoomEmail,
  } = useBreakoutRoomUtils();

  // States
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [participation, setParticipation] = useState();
  const [fetchedData, setFetchedData] = useState();
  const [confirmed, setConfirmed] = useState(false);

  // Effects
  useEffect(() => {
    if (!fetchedData) {
      fetchDataFromHistory();
    }
  }, [fetchedData]);

  // Functions
  const fetchDataFromHistory = () => {
    if (
      history.location?.state?.breakoutRoom &&
      history.location?.state?.breakoutRoomSlot &&
      history.location?.state?.participation
    ) {
      const br = history.location.state.breakoutRoom;
      const slot = history.location?.state?.breakoutRoomSlot;
      const _participation = history.location.state.participation;
      setParticipation(_participation);
      let notes = '';
      if (history.location?.state?.notes) {
        notes = history.location.state.notes;
      }
      setFetchedData({
        ...fetchedData,
        exhibitor: br.sponsor.name,
        typology: 'Appointment',
        date: format(
          utcToZonedTime(new Date(br.date), currentTimezone),
          'dd-MM-yyyy'
        ),
        time: `${format(
          utcToZonedTime(new Date(slot.start), currentTimezone),
          'HH:mm'
        )} - ${format(
          utcToZonedTime(new Date(slot.end), currentTimezone),
          'HH:mm'
        )}`,
        notes: notes,
        slotId: slot.id,
        start: `${format(new Date(slot.start), 'HH:mm')}`,
        end: `${format(new Date(slot.end), 'HH:mm')}`,
        slot: slot,
        br: br,
      });
      history.replace(history.location.pathname, null);
    } else {
      history.replace(history.location.pathname, null);
      history.push(`/events/${eventId}/sponsors/${sponsorId}/book`);
    }
  };

  const confirmAppointment = async () => {
    try {
      showLoader();
      const input = {
        breakoutRoomAttendingParticipationId: participation.id,
        breakoutRoomAttendingSlotId: fetchedData.slotId,
        state: states.PENDING,
        isSelfProposed: true,
        note: fetchedData.notes,
      };
      await useBreakoutRoomAttendingHelper.create(input);
      const response = await callLambda({
        action: states.ACCEPTED,
        email: participation.email,
        slotId: fetchedData.slotId,
        eventId: eventId,
      });
      if (response) {
        setSnackbar({
          isOpen: true,
          severity: AimSnackbarSeverity.success,
          message: i18n.snackbar.sendSuccess,
        });
        const emailData = {
          breakoutRoom: fetchedData.br,
          slot: fetchedData.slot,
          eventId: eventId,
          i18n: useMailI18n.breakoutRoomMail(intl),
        };
        await generateBreakoutRoomEmail(emailTypes.RESULT_ACCEPT, {
          ...emailData,
          email: participation.email,
        });
        setConfirmed(true);
      }
    } catch (e) {
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: e.message,
      });
    }
    hideLoader();
  };

  //Renders
  // const renderHeader = () => {
  //   return (
  //     <>
  //       <div
  //         style={{
  //           marginLeft: '5%',
  //           marginRight: '5%',
  //           marginBottom: 20,
  //           marginTop: 20,
  //           display: 'flex',
  //           alignItems: 'flex-end',
  //           justifyContent: 'space-between',
  //         }}
  //       >
  //         <div style={{ width: '80%' }}>
  //           <Tooltip title={i18n.page.backButton.tooltip}>
  //             <AimIconAndTextButton
  //               variant="none"
  //               text={i18n.page.backButton.label}
  //               onClick={() =>
  //                 history.push(`/events/${eventId}/sponsors/${sponsorId}/book`)
  //               }
  //             >
  //               <ArrowBack />
  //             </AimIconAndTextButton>
  //           </Tooltip>
  //           <AimTitleAndButtons title={i18n.page.title} />
  //         </div>
  //       </div>
  //     </>
  //   );
  // };

  const renderRecap = () => {
    return (
      <div style={{ padding: 15 }}>
        <AimTypography variant="h6">{i18n.confirmPage.summary}</AimTypography>
        <Divider style={{ marginBottom: 20 }} />
        <div>
          {fields.map((f, idx) => (
            <div key={idx} style={{ display: 'flex' }}>
              <AimTypography variant="columnHeader">
                {i18n.confirmPage[f]}:
              </AimTypography>
              <AimTypography variant="text">{fetchedData[f]}</AimTypography>
            </div>
          ))}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <AimIconAndTextButton
            variant="yellow"
            text={i18n.page.backButton.label}
            onClick={() =>
              history.push(`/events/${eventId}/sponsors/${sponsorId}/book`)
            }
          >
            <ArrowBack />
          </AimIconAndTextButton>
          <AimIconAndTextButton
            variant="greenFill"
            text={i18n.page.saveButton.label}
            onClick={() => confirmAppointment()}
          >
            <CheckIcon />
          </AimIconAndTextButton>
        </div>
      </div>
    );
  };

  const renderConfirmation = () => {
    if (!fetchedData) return;
    return (
      <div style={{ padding: 30 }}>
        <AimTypography variant="h1Regular">
          {i18n.confirmPage.thanks}{' '}
          {participation?.givenName && participation?.familyName
            ? `${participation?.givenName} ${participation?.familyName}`
            : `${participation?.email}`}{' '}
          {},
        </AimTypography>
        <AimTypography variant="h1Regular">
          {i18n.confirmPage.longMessage} {fetchedData.exhibitor}{' '}
          {i18n.confirmPage.theDay} {fetchedData.date} {i18n.confirmPage.from}{' '}
          {fetchedData.start} {i18n.confirmPage.from} {fetchedData.end}.
        </AimTypography>
        <AimTypography variant="h1Regular">
          {i18n.confirmPage.endMessage}
        </AimTypography>
        <AimIconAndTextButton
          variant="yellow"
          text={i18n.page.eventButton.label}
          onClick={() => {
            history.push(
              `/events/${eventId}/sponsors/${sponsorId}/slots/${fetchedData.slotId}`
            );
          }}
          style={{ marginTop: 20 }}
        >
          <ArrowForward />
        </AimIconAndTextButton>
      </div>
    );
  };

  return (
    <MainContainer>
      {!confirmed && (
        <TitleAndBackButton
          backOnClickFunction={() =>
            history.push(`/events/${eventId}/sponsors/${sponsorId}/book`)
          }
          backOnHoverText={i18n.page.backButton.tooltip}
          title={i18n.page.title}
        />
      )}
      <Container style={{ marginTop: !confirmed ? 0 : 20 }}>
        {fetchedData && !confirmed ? renderRecap() : renderConfirmation()}
      </Container>
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </MainContainer>
  );
};

export default SponsorBookingConfirmAppointment;
