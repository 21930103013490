import { aws } from '@aim/common';

const createReviewerMutation = /* GraphQL */ `
  mutation CreateReviewer(
    $input: CreateReviewerInput!
    $condition: ModelReviewerConditionInput
  ) {
    createReviewer(input: $input, condition: $condition) {
      id
      reviewerCategories {
        items {
          reviewerCategoryReviewerId
          category {
            id
            name
          }
          reviewerCategoryCategoryId
        }
      }
    }
  }
`;

const createReviewerCategoryMutation = /* GraphQL */ `
  mutation CreateReviewerCategory(
    $input: CreateReviewerCategoryInput!
    $condition: ModelReviewerCategoryConditionInput
  ) {
    createReviewerCategory(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateReviewerCategoryMutation = /* GraphQL */ `
  mutation UpdateReviewerCategory(
    $input: UpdateReviewerCategoryInput!
    $condition: ModelReviewerCategoryConditionInput
  ) {
    updateReviewerCategory(input: $input, condition: $condition) {
      id
    }
  }
`;

const deleteReviewerCategoryMutation = /* GraphQL */ `
  mutation DeleteReviewerCategory(
    $input: DeleteReviewerCategoryInput!
    $condition: ModelReviewerCategoryConditionInput
  ) {
    deleteReviewerCategory(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateCategoryMutation = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      id
      name
    }
  }
`;

export const createReviewer = (input) =>
  new Promise((resolve, reject) => {
    aws.API.graphql({
      query: createReviewerMutation,
      variables: { input },
    })
      .then((response) => {
        const data = response.data.createReviewer;
        resolve(data);
      })
      .catch((e) => {
        console.error('create-reviewer', e);
        reject(e);
      });
  });

export const createReviewerCategory = (input) =>
  new Promise((resolve, reject) => {
    aws.API.graphql({
      query: createReviewerCategoryMutation,
      variables: { input },
    })
      .then((response) => {
        const data = response.data.createReviewerCategory;
        resolve(data);
      })
      .catch((e) => {
        console.error('create-reviewer-cateogry', e);
        reject(e);
      });
  });

export const updateReviewerCategory = (input) =>
  new Promise((resolve, reject) => {
    aws.API.graphql({
      query: updateReviewerCategoryMutation,
      variables: { input },
    })
      .then((response) => {
        const data = response.data.updateReviewerCategory;
        resolve(data);
      })
      .catch((e) => {
        console.error('update-reviewer-cateogry', e);
        reject(e);
      });
  });

export const deleteReviewerCategory = (input) =>
  new Promise((resolve, reject) => {
    aws.API.graphql({
      query: deleteReviewerCategoryMutation,
      variables: { input },
    })
      .then((response) => {
        const data = response.data.deleteReviewerCategory;
        resolve(data);
      })
      .catch((e) => {
        console.error('delete-reviewer-cateogry', e);
        reject(e);
      });
  });

export const updateCategory = (input) =>
  new Promise((resolve, reject) => {
    aws.API.graphql({
      query: updateCategoryMutation,
      variables: { input },
    })
      .then((response) => {
        const { data } = response;
        resolve(data.update);
      })
      .catch((e) => {
        console.error('update-category', e);
        reject();
      });
  });
