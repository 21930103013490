import React from 'react';

import { AimSelectMenuItem, AimTypography, AimSelect } from '@aim/components';
// TODO: appState per check lingua (LAST)

import { Controller, useFieldArray } from 'react-hook-form';

import Grid from '@material-ui/core/Grid';
import PersonIcon from '@material-ui/icons/Person';
import { utilities } from '@aim/common';

//--- HOTEL CARD COMPONENT
const MobileAvailabilityBox = ({
  i18n,
  highlightedRooms,
  otherRooms,
  control,
}) => {
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'rooms', // unique name for your Field Array,
    keyName: 'keyId',
  });

  //--- MAIN FUNCTIONS
  const getRoomSelectionRows = (room, idx) => {
    const roomSelectionRows = [];
    const roomAvailability = room.freeRooms;
    for (let i = 0; i < room.maxOccupancy; i++) {
      const price = utilities.decodeDbNumber(room.prices?.[i + 1]?.price);
      if (!price) continue;
      const guestsNumber = i + 1;
      roomSelectionRows.push(
        <Grid key={`${room.id}-${idx}${i}`} container item direction="column">
          <Grid
            container
            item
            direction="column"
            style={{ backgroundColor: 'white', padding: '3%' }}
          >
            <Grid
              container
              item
              xs
              sm
              md
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid>
                <AimTypography variant="textBold">
                  {i18n.availability.roomsType}
                </AimTypography>
              </Grid>
              <Grid>
                <AimTypography variant="text">
                  {room?.frontofficeName?.toUpperCase()}{' '}
                </AimTypography>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs
              sm
              md
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid>
                <b>{i18n.availability.guestsMaxNumber}</b>
              </Grid>
              <Grid>{maxGuestsIcons(guestsNumber)}</Grid>
            </Grid>
            <Grid
              container
              item
              xs
              sm
              md
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid>
                <AimTypography variant="textBold">
                  {i18n.availability.roomsPrice}
                </AimTypography>
              </Grid>
              <Grid>
                <AimTypography variant="text">
                  {utilities.formatNumber(price)}
                </AimTypography>
              </Grid>
            </Grid>
            <Grid container item xs sm md>
              <Controller
                name={`${room.id}[${i}]`}
                control={control}
                defaultValue={0}
                render={(props) => (
                  <AimSelect
                    value={props.value}
                    onChange={(e) => {
                      onSelectRoomQuantity(
                        room,
                        e.target.value,
                        guestsNumber,
                        price
                      );
                      props.onChange(e.target.value);
                    }}
                  >
                    {Array.from(Array(roomAvailability + 1).keys()).map(
                      (item) => {
                        return (
                          <AimSelectMenuItem
                            disabled={checkDisableSelect(
                              room,
                              item,
                              guestsNumber,
                              roomAvailability
                            )}
                            key={item}
                            value={item}
                          >
                            {item != 0 ? `${item} (€${price * item})` : item}
                          </AimSelectMenuItem>
                        );
                      }
                    )}
                  </AimSelect>
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return roomSelectionRows;
  };

  const onSelectRoomQuantity = (
    room,
    roomQuantity,
    guestsNumber,
    roomPrice
  ) => {
    const existingRooms = fields
      .map((field, idx) => ({
        ...field,
        idx,
      }))
      .filter((field) => {
        return field.id === room.id && field.guestsNumber === guestsNumber;
      });
    if (existingRooms.length < roomQuantity) {
      for (let i = 0; i < roomQuantity - existingRooms.length; i++) {
        append({
          ...room,
          roomPrice,
          guestsNumber: guestsNumber,
        });
      }
    } else if (existingRooms.length > roomQuantity) {
      const roomsToRemove = existingRooms.length - roomQuantity;
      const roomsIds = existingRooms
        .slice(0, roomsToRemove)
        .map((room) => room.idx);
      remove(roomsIds);
    }
  };

  const checkDisableSelect = (room, item, guestsNumber, roomAvailability) => {
    // check delle disponibilità
    let itemAvailabile = false;
    const totalRoomsSelected = fields.filter((field) => field.id === room?.id)
      .length;
    const roomNumberByRoomIdGuestsNumber = fields.filter(
      (field) => field.id === room?.id && field.guestsNumber === guestsNumber
    ).length;
    const availability = roomAvailability - totalRoomsSelected;

    if (item !== 0 && item > roomNumberByRoomIdGuestsNumber + availability) {
      itemAvailabile = true;
    }
    return itemAvailabile;
  };

  const maxGuestsIcons = (maxGuests) => {
    const maxGuestsRows = [];
    for (let i = 0; i < maxGuests; i++) {
      maxGuestsRows.push(<PersonIcon />);
    }
    return maxGuestsRows;
  };

  return (
    <Grid container item spacing={2}>
      <Grid container item spacing={3}>
        {[...highlightedRooms, ...otherRooms].map((room, idx) =>
          getRoomSelectionRows(room, idx)
        )}
      </Grid>
    </Grid>
  );
};

export default MobileAvailabilityBox;
