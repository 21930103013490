import { aws, appState } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

export const getVirtualStandService = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      sponsorServiceBookingDuration
      virtualStandService {
        id
        name
        description
        vatRate
        isCreditCardEnabled
        isBankTransferEnabled
        publicationDate
        documents {
          items {
            id
            name: originalName
            size
            extension
          }
        }
        virtualStands {
          items {
            id
            name
            ranking
            isEnabled
            specificationPost
            netPrice
            sponsorVirtualStand {
              items {
                id
                buyOperation {
                  sponsor {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export function useVirtualStandService() {
  const get = (id, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({ query: getVirtualStandService, variables: { id } })
        .then((response) => resolve(response.data.getEvent))
        .catch((e) => {
          console.error('list-virtual-stand-service', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  return { get };
}
