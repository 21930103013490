import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';

import { styled, AimDialog, AimTextField } from '@aim/components';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'white',
  padding: '10px',
  minWidth: '450px',
});

const formControlStyle = {
  width: 'calc(100% - 20px)',
};

export const AgencyBudgetDialog = ({
  isDialogOpen,
  style,
  i18n,
  onDisagree,
  onAgree,
  value,
  ...rest
}) => {
  const [prepaidBudget, setPrepaidBudget] = useState(value);

  const onPurchaseClick = () => {
    onAgree({ prepaidBudget });
  };

  return (
    <AimDialog
      disableEnforceFocus
      fullWidth
      maxWidth={'md'}
      open={isDialogOpen}
      title={i18n.modal.prepaidBudget.title}
      onClose={onDisagree}
      disagreeText={i18n.modal.prepaidBudget.buttons.cancel}
      agreeText={i18n.modal.prepaidBudget.buttons.buy}
      onDisagree={onDisagree}
      onAgree={() => onPurchaseClick()}
    >
      <Container style={style} {...rest}>
        <Grid container>
          <Grid item xs={12}>
            <AimTextField
              formControlStyle={formControlStyle}
              label={i18n.modal.prepaidBudget.label}
              placeholder="euro"
              value={prepaidBudget}
              onChange={(e) => setPrepaidBudget(e.target.value)}
            />
          </Grid>
        </Grid>
      </Container>
    </AimDialog>
  );
};
