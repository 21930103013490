export default function (intl) {
  return {
    fileSelector: {
      clickText: intl.formatMessage({
        description: 'clickText',
        defaultMessage: 'Upload',
      }),
    },
  };
}
