import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import {
  AimIconAndTextButton,
  AimTypography,
  CustomIntl,
  theme,
  AimSnackbarSeverity,
  AimSnackbar,
  BillingInformations,
} from '@aim/components';

import translation from './translation';
import {
  getTicketFee,
  getEventGateway,
  getAdditionalServices,
  // getParticipationLastEventBillingInfo,
} from './gqlHelper';

import { format } from 'date-fns';
import { appState, constants, utilities } from '@aim/common';

const {
  safeNum,
  formatNumber,
  vatCalc,
  decodeDbNumber,
  encodeDbNumber,
} = utilities;

const GeneralInfo = ({
  i18n,
  ticketFee,
  feeType,
  intl,
  participation,
  vatRate,
  guests,
}) => {
  const priceOnAir = utilities.decodeDbNumber(ticketFee?.priceOnAir);
  const priceOnSite = utilities.decodeDbNumber(ticketFee?.priceOnSite);
  const accompanyingPersonPrice = utilities.decodeDbNumber(
    ticketFee?.accompanyingPersonPrice
  );
  const netPrice =
    feeType === constants.EventTypes.PHYSICAL.id
      ? safeNum(
          priceOnSite +
            guests.length *
              (accompanyingPersonPrice > 0 ? accompanyingPersonPrice : 0)
        )
      : safeNum(
          priceOnAir +
            guests.length *
              (accompanyingPersonPrice > 0 ? accompanyingPersonPrice : 0)
        );

  return (
    (ticketFee && participation && (
      <div
        style={{
          background: 'white',
          borderRadius: 3,
          padding: '10px 20px',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}
      >
        <div>
          <AimTypography
            variant="h4"
            boxStyle={{
              borderBottom: `3px solid ${theme.colors.greyScale.backgroundGrey}`,
            }}
          >
            {i18n.page.generalInfo}
          </AimTypography>
        </div>
        <div style={{ display: 'flex' }}>
          <AimTypography variant="columnHeader" style={{ flex: 1 }}>
            {i18n.page.labels.ticket}
          </AimTypography>
          <AimTypography variant="columnHeader" style={{ flex: 1 }}>
            {i18n.page.labels.daysIncluded}
          </AimTypography>
          <AimTypography variant="columnHeader" style={{ flex: 1 }}>
            {i18n.page.labels.profile}
          </AimTypography>
          <AimTypography variant="columnHeader" style={{ flex: 1 }}>
            {i18n.page.labels.participationMode}
          </AimTypography>
          <AimTypography variant="columnHeader" style={{ flex: 1 }}>
            {i18n.page.labels.purchasedBy}
          </AimTypography>
          {/* <AimTypography variant="columnHeader" style={{ flex: 1 }}>
            {i18n.page.labels.unitaryPrice}
          </AimTypography>
          <AimTypography variant="columnHeader" style={{ flex: 1 }}>
            {i18n.page.labels.vatRate}
          </AimTypography> */}
          <AimTypography variant="columnHeader" style={{ flex: 1 }}>
            {i18n.page.labels.price}
          </AimTypography>
        </div>
        <div style={{ display: 'flex' }}>
          <AimTypography variant="text" style={{ flex: 1 }}>
            {ticketFee.feeBracket.feeDateRange.label}
          </AimTypography>
          <AimTypography variant="text" style={{ flex: 1 }}>
            {`${format(
              new Date(ticketFee.feeBracket.feeDateRange.start),
              'PP'
            )}${
              ticketFee.feeBracket.feeDateRange.start !==
              ticketFee.feeBracket.feeDateRange.end
                ? ` ${format(
                    new Date(ticketFee.feeBracket.feeDateRange.end),
                    'PP'
                  )}`
                : ''
            }`}
          </AimTypography>
          <AimTypography variant="text" style={{ flex: 1 }}>
            {`${ticketFee.profile.name} - ${
              ticketFee.profile.category?.name || ''
            }`}
          </AimTypography>
          <AimTypography variant="text" style={{ flex: 1 }}>
            {Object.values(constants.EventTypes)
              .find((t) => t.id === feeType)
              ?.label(intl)}
          </AimTypography>
          <AimTypography variant="text" style={{ flex: 1 }}>
            {`${participation?.givenName} ${participation?.familyName}`}
          </AimTypography>
          {/* <AimTypography variant="text" style={{ flex: 1 }}>
            {formatNumber(netPrice)}
          </AimTypography>
          <AimTypography variant="text" style={{ flex: 1 }}>
            {decodeDbNumber(ticketFee.feeBracket.feeBracketModel.fee.vatRate)}
          </AimTypography> */}
          <AimTypography variant="text" style={{ flex: 1 }}>
            {formatNumber(vatCalc(netPrice, vatRate))}
          </AimTypography>
        </div>
      </div>
    )) ||
    null
  );
};

const PaxTicketsBillingInformations = () => {
  const { eventId, profileFeeBracketId, feeType } = useParams();
  const history = useHistory();
  const intl = CustomIntl(useIntl());
  const i18n = translation.billingInformations(intl);
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [ticketFee, setTicketFee] = useState();
  const [billingInfoBookings, setBillingInfoBookings] = useState();
  const [participation] = useState(appState.getCurrentParticipation());
  const [guests] = useState(appState.purchaseData.getValue()?.guests || []);
  const [formExportedValues, setFormExportedValues] = useState({});

  // ref
  const gatewayRef = useRef();
  const additionalServicesRef = useRef();

  useEffect(() => {
    const loadData = async () => {
      if (participation) {
        getTicketFee(profileFeeBracketId).then(setTicketFee);
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    if (eventId) {
      const fetchEventGateway = async () => {
        const nextGateway = await getEventGateway({
          id: eventId,
          serviceType: constants.GatewayServices().REGISTRATION.key,
        });

        gatewayRef.current = nextGateway;
      };
      const fetchAdditionalServices = async () => {
        const nextAdditionalServices = await getAdditionalServices(eventId);

        additionalServicesRef.current = nextAdditionalServices.items;
      };
      fetchEventGateway();
      fetchAdditionalServices(eventId);
    }
  }, [eventId]);

  useEffect(() => {
    setBillingInfoBookings({
      [profileFeeBracketId]: {
        vatRate1: decodeDbNumber(
          ticketFee?.feeBracket?.feeBracketModel?.fee?.vatRate
        ),
        vat1Id: ticketFee?.feeBracket?.feeBracketModel?.fee?.vat?.id,
        participationMode: feeType,
      },
    });
  }, [ticketFee]);

  const checkoutResponse = () => {
    const isCheckoutSuccess = location.pathname.includes('checkout-success');
    const isCheckoutError = location.pathname.includes('checkout-error');
    const query = new URLSearchParams(location.search);
    const errorcode = query.get('errorcode');
    const order = query.get('merchantorderid');
    if (isCheckoutError && errorcode && errorcode > 0) {
      const messageError = '';
      switch (parseInt(errorcode)) {
        case 1: // query error in lambda
          messageError(`${i18n.checkout.errors.error1} ${order}`);
          break;
        case 2: // response error in lambda
          messageError(`${i18n.checkout.errors.error2} ${order}`);
          break;
        case 3: // error in lambda, init payment
          messageError(`${i18n.checkout.errors.error3} ${order}`);
          break;
        case 4: // duplicate order or other errors
          messageError(`${i18n.checkout.errors.error4} ${order}`);
          break;
        default:
          break;
      }
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: messageError,
      });
    } else if (isCheckoutSuccess) {
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.success,
        message: i18n.snackbar.buyTicketSuccess,
      });
      history.push(
        `/events/${eventId}/tickets/${profileFeeBracketId}/payment-success`
      );
    }
  };

  // const handleCopyFromLastEvent = async () => {
  //   const billingInfo = await getParticipationLastEventBillingInfo(
  //     participation?.cognitoUserId,
  //     eventId
  //   );
  //   reset({
  //     ...billingInfo,
  //     country: { label: billingInfo?.country, value: billingInfo?.country },
  //     city: { label: billingInfo?.city, value: billingInfo?.city },
  //     invoiceToPublicAuthority: billingInfo?.invoiceToPublicAuthority?.toString(),
  //     isSplitPayment: billingInfo?.isSplitPayment?.toString(),
  //     isVatEvent: billingInfo?.isVatEvent?.toString(),
  //   });
  // };

  const afterSendData = async (billingInfo) => {
    const getCartData = async () => {
      const priceOnAir = utilities.decodeDbNumber(ticketFee.priceOnAir);
      const priceOnSite = utilities.decodeDbNumber(ticketFee.priceOnSite);
      const accompanyingPersonPrice = utilities.decodeDbNumber(
        ticketFee.accompanyingPersonPrice
      );

      const registrationNetPrice =
        feeType === constants.EventTypes.PHYSICAL.id
          ? safeNum(priceOnSite)
          : safeNum(priceOnAir);

      const netPrice =
        feeType === constants.EventTypes.PHYSICAL.id
          ? safeNum(
              priceOnSite +
                guests.length *
                  (accompanyingPersonPrice > 0 ? accompanyingPersonPrice : 0)
            )
          : safeNum(
              priceOnAir +
                guests.length *
                  (accompanyingPersonPrice > 0 ? accompanyingPersonPrice : 0)
            );

      const vatRate = formExportedValues?.[profileFeeBracketId]?.vatRate1;
      const vatId = formExportedValues?.[profileFeeBracketId]?.vat1Id;
      const isForced = formExportedValues?.[profileFeeBracketId]?.isForced;

      const totalAmount = vatCalc(netPrice, vatRate);
      const user = appState.user.getValue();

      if (gatewayRef.current === undefined) {
        setSnackbar({
          isOpen: true,
          severity: AimSnackbarSeverity.error,
          message: i18n.snackbar.gatewayNotExist,
        });
        return;
      }

      if (gatewayRef.current.paymentType === undefined) {
        setSnackbar({
          isOpen: true,
          severity: AimSnackbarSeverity.error,
          message: i18n.snackbar.paymentTypeNotExist,
        });
        return;
      }

      const paymentType =
        netPrice === 0
          ? constants.PaymentTypes.Free
          : gatewayRef.current.paymentType;

      const preselectedPaymentType =
        history?.location?.state?.prevData?.rawData?.payment?.paymentType;

      const preselectedPaymentId =
        history?.location?.state?.prevData?.rawData?.payment?.paymentId;

      const paymentData = history?.location?.state?.prevData?.rawData?.payment;
      const includedAdditionalServicesProducts =
        history?.location?.state?.prevData?.rawData?.childs?.items;

      // usato per differenziare il primo pagamento o i successivi (al successivo tentativo non dobbiamo più creare il prodotto ma solo salvare li billing)
      const nextAdditionalServices = includedAdditionalServicesProducts
        ? includedAdditionalServicesProducts.map((asp) => ({
            ...additionalServicesRef.current?.find(
              (as) => asp.productId === as.id
            ),
            productTableId: asp.id,
            paymentTableId: asp.payment?.id,
          }))
        : additionalServicesRef.current;

      const formatPaymentMetadata = () => {
        return {
          amount1: encodeDbNumber(totalAmount),
          netAmount1: encodeDbNumber(netPrice), // total net amount = net amount ticket + guests number * net amount guest
          isForced,
          vatRate1: encodeDbNumber(vatRate),
          vat1Id: vatId,
          ticket: {
            netAmount1: encodeDbNumber(registrationNetPrice),
            vatRate1: encodeDbNumber(vatRate),
            vat1Id: vatId,
          },
          ...(guests && {
            guests: {
              quantity: guests.length,
              isForced,
              netAmount1: ticketFee.accompanyingPersonPrice,
              vatRate1: encodeDbNumber(vatRate),
              vat1Id: vatId,
            },
          }),
        };
      };

      const state = {
        netPrice,
        gateway: gatewayRef.current,
        ...(netPrice === 0 && { paymentType: constants.PaymentTypes.Free }),
        productId: profileFeeBracketId,
        serviceId: eventId, // profileFeeBracketId,
        additionalServices: nextAdditionalServices,
        serviceType: constants.Services.REGISTRATION.key,
        clientId: user.userAndParticipation.participation.id,
        clientType: constants.Clusters.Pax.id,
        options: { feeType },
        serviceLabel: `${i18n.page.labels.ticket} - ${ticketFee.feeBracket?.feeDateRange?.label}`,
        serviceDescr: '', // i18n.page.labels.ticketDescr,
        vat: {
          isForced,
          vatRate1: vatRate,
          vatId,
          taxableVatRate1: netPrice,
        },
        paymentMetadata: formatPaymentMetadata(),
        billingInfo,
        url: `/events/${eventId}/tickets/cart/${profileFeeBracketId}/${feeType}`,
        baseUrl: process.env.BASEURL.split('//')[1],
      };

      if (paymentType === 'both' || preselectedPaymentType) {
        history.push({
          pathname: `/events/${eventId}/tickets/cart/${profileFeeBracketId}/${feeType}/payment-opt`,
          state: {
            ...state,
            preselectedPaymentType,
            preselectedPaymentId,
            paymentData,
          },
        });
      } else {
        history.push({
          pathname: `/events/${eventId}/tickets/cart/${profileFeeBracketId}/${feeType}/payment`,
          state: { ...state, preselectedPaymentId, paymentData },
        });
      }
    };

    getCartData();
    checkoutResponse();
  };

  return (
    <>
      <div
        style={{
          flex: 1,
          minWidth: 'calc(100vw - 480px)',
          margin: '20px 180px',
        }}
      >
        <AimIconAndTextButton
          isUpperCase
          variant="none"
          text={i18n.actions.backButton}
          style={{
            padding: 0,
          }}
          onClick={() => history.replace(`/events/${eventId}/tickets/fee`)}
        >
          <ArrowBack />
        </AimIconAndTextButton>
        <AimTypography variant="h1">{i18n.page.title}</AimTypography>
        <GeneralInfo
          i18n={i18n}
          ticketFee={ticketFee}
          feeType={feeType}
          intl={intl}
          participation={participation}
          vatRate={formExportedValues?.[profileFeeBracketId]?.vatRate1}
          guests={guests}
        />

        <BillingInformations
          type={constants.Clusters.Pax.id}
          customerId={participation.id}
          history={history}
          intl={intl}
          showSnackbar
          saveButtonText={i18n.actions.continue}
          SaveButtonIcon={ShoppingCartIcon}
          onAfterSave={afterSendData}
          setFormExportedValues={setFormExportedValues}
          billingInfoBookings={billingInfoBookings}
        />
      </div>
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </>
  );
};

export default PaxTicketsBillingInformations;
