export const translation = {
  visa: (intl) => ({
    title: intl.formatMessage({
      description: 'Visa front title',
      defaultMessage: 'Visa',
    }),
    titleH2: intl.formatMessage({
      description: 'Visa front titleH2',
      defaultMessage: 'Edit passport information',
    }),
    subTitle: intl.formatMessage({
      description: 'Visa front subtitle',
      defaultMessage: 'Fill in the form in order to ask for a visa',
    }),
    backButton: intl.formatMessage({
      description: 'Visa front back button label',
      defaultMessage: 'back',
    }),
    download: {
      pax: intl.formatMessage({
        description: 'cancel button',
        defaultMessage: 'Download the template of your personal visa',
      }),
      embassy: intl.formatMessage({
        description: 'cancel button',
        defaultMessage:
          'Download the template to be delivered to to the embassy',
      }),
    },
    buttons: {
      cancelButton: intl.formatMessage({
        description: 'cancel button',
        defaultMessage: 'Cancel',
      }),
      saveButton: intl.formatMessage({
        description: 'save button',
        defaultMessage: 'save and continue',
      }),
      downloadButton: intl.formatMessage({
        description: 'download button',
        defaultMessage: 'download',
      }),
    },
  }),
};
