import React, { useEffect, useState } from 'react';
import { aws } from '@aim/common';

import LoginLayout from './shared/LoginLayout';
import Grid from '@material-ui/core/Grid';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {
  AimTypography,
  AimIconAndTextButton,
  CustomIntl,
  AimSnackbar,
} from '@aim/components';
import { translations } from './shared/translations';
import { useIntl } from 'react-intl';
import { useParams, useHistory } from 'react-router-dom';

const ResetTemporaryPassword = () => {
  const intl = CustomIntl(useIntl());
  const i18n = translations.resetTemporaryPassword(intl);
  const [username, setUsername] = useState('');
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const history = useHistory();
  const { eventId } = useParams();

  useEffect(() => {
    const getQuery = () => {
      if (typeof window !== 'undefined') {
        return new URLSearchParams(window.location.search);
      }
      return new URLSearchParams();
    };

    const getQueryStringVal = (key) => {
      return getQuery().get(key);
    };

    const loadData = () => {
      const username = getQueryStringVal('username');
      setUsername(username);
    };

    loadData();
  }, []);

  const handleSubmitCode = async (e) => {
    e.preventDefault();

    await aws.resetUserTemporaryPassword({
      username,
    });
    history.push(`/events/${eventId}/login`);
  };

  return (
    <>
      <LoginLayout title={i18n.pageTitle}>
        {username && (
          <>
            <AimTypography variant="h4Regular">
              {`${i18n.subtitle1} `} <b>{username}</b>
              {` ${i18n.subtitle2}`}
            </AimTypography>
            <AimTypography variant="h4Regular">{i18n.subtitle3}</AimTypography>
            <Grid>
              <form onSubmit={handleSubmitCode}>
                <Grid
                  item
                  xs={12}
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  <AimIconAndTextButton
                    type="submit"
                    text={i18n.send}
                    isIconLeft
                  >
                    <ArrowForwardIcon />
                  </AimIconAndTextButton>
                </Grid>
              </form>
            </Grid>
          </>
        )}
        <AimSnackbar
          open={snackbar.isOpen}
          onClose={() => setSnackbar({ isOpen: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </AimSnackbar>
      </LoginLayout>
    </>
  );
};

export default ResetTemporaryPassword;
