import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import useI18n from './i18n';
import { useForm } from 'react-hook-form';
import {
  CustomIntl,
  AimSnackbarSeverity,
  AimTypography,
  AimIconAndTextButton,
  useAimMediaQuery,
} from '@aim/components';
import { appState, constants } from '@aim/common';
import { useFormComponents } from '../shared/Form/FormComponents';
import { useUserSettingsHelper } from '../shared/userSettingsGqlHelper';
import FormButtons from './FormButtons';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

const _defaultValue = {
  isPublic: true,
  showMyAgenda: true,
  showBiography: true,
};

const ProfileSettings = ({
  fetchedData = _defaultValue,
  fetchSettingsInfo,
  navigateBack,
  cluster,
  eventId,
  participationId,
  setSnackbar,
}) => {
  const history = useHistory();
  const intl = CustomIntl(useIntl());
  const i18n = useI18n(intl);
  const { isMobile } = useAimMediaQuery();
  const { control, handleSubmit, errors, getValues, reset } = useForm({
    reValidateMode: 'onChange',
    shouldUnregister: false,
    defaultValues: _defaultValue,
  });

  const formControlStyle = { width: 'calc(100% - 15px)', margin: 8 };

  const headerSettings = [
    { key: 'isPublic', valueLabels: i18n.labels.switch.activationLabels },
    { key: 'showMyAgenda', valueLabels: i18n.labels.switch.activationLabels },
    { key: 'showBiography', valueLabels: i18n.labels.switch.visibilityLabels },
  ];

  const [headers, setHeaders] = useState(headerSettings);
  const settingsHelper = useUserSettingsHelper();

  const { renderSwitchInput } = useFormComponents({
    control: control,
    formControlStyle: formControlStyle,
    errors: errors,
    getValues: getValues,
    labels: i18n.labels,
  });

  useEffect(() => {
    if (fetchedData) {
      if (cluster === constants.Clusters.Pax.id) setHeaders(headerSettings);
      reset(fetchedData);
    }
  }, [fetchedData]);

  const sendData = async (submittedData) => {
    showLoader();
    if (submittedData.id) {
      await settingsHelper.update(submittedData);
    } else {
      await settingsHelper.create({
        participationId: participationId,
        ...submittedData,
      });
    }
    try {
      await fetchSettingsInfo();
      navigateBack();
    } catch (e) {
      console.error(e);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.actions.saveFail,
      });
    }
    hideLoader();
  };

  return (
    <div>
      <form onSubmit={handleSubmit(sendData)}>
        <Grid container>
          {headers.map(({ key }) => {
            return (
              <Grid
                item
                xs={12}
                key={key}
                style={{
                  backgroundColor: 'white',
                  marginBottom: 10,
                  marginLeft: 5,
                  marginRight: 5,
                }}
              >
                {renderSwitchInput({
                  variant: 'secondary',
                  key: key,
                  valueLabels: ' ',
                })}

                <AimTypography
                  variant="text"
                  style={{
                    width: 'calc(90% - 100px)',
                    position: 'relative',
                    top: -18,
                    left: 16,
                  }}
                >
                  {i18n.labels[key + 'Description']}
                </AimTypography>
              </Grid>
            );
          })}
        </Grid>
        <div
          style={{
            width: '100%',
            paddingTop: 5,
            paddingLeft: 5,
            paddingRight: 5,
            justifyContent: !isMobile ? 'start' : 'center',
            display: 'flex',
          }}
        >
          <AimIconAndTextButton
            text={i18n.actions.changePassword.toUpperCase()}
            onClick={() => {
              history.push({
                pathname: `/events/${eventId}/user/${participationId}/edit/edit-password`,
                state: {
                  navigateBack: `/events/${eventId}/user/${participationId}/edit`,
                },
              });
            }}
            variant="primary"
            style={{ width: isMobile ? '100%' : 200 }}
          ></AimIconAndTextButton>
        </div>
        <FormButtons i18n={i18n} navigateBack={navigateBack} />
      </form>
    </div>
  );
};

ProfileSettings.propTypes = {
  setSnackbar: PropTypes.func.isRequired,
  fetchSettingsInfo: PropTypes.func.isRequired,
  navigateBack: PropTypes.func.isRequired,
  cluster: PropTypes.string.isRequired,
  eventId: PropTypes.string.isRequired,
  participationId: PropTypes.string.isRequired,
  fetchedData: PropTypes.exact({
    id: PropTypes.any,
    isPublic: PropTypes.bool,
    showMyAgenda: PropTypes.bool,
    showBiography: PropTypes.bool,
  }),
};

export default ProfileSettings;
