import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import useI18n from './paxAbstractsEdit/i18n';
import { useForm } from 'react-hook-form';

import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';

import { endOfDay, isAfter, parseISO } from 'date-fns';

import Typography from '@material-ui/core/Typography';
import Check from '@material-ui/icons/Check';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Tooltip from '@material-ui/core/Tooltip';
import DialogContent from '@material-ui/core/DialogContent';
import { sortBy } from 'lodash';
import { aws, constants, appState, fileHelper, utilities } from '@aim/common';
import {
  styled,
  AimSnackbar,
  AimSnackbarSeverity,
  AimStepper,
  // StepDotTitle,
  AimTitleAndButtons,
  AimIconAndTextButton,
  CustomIntl,
  AbstractDetailPage,
  AimDialog,
  AimTypography,
  AimMegadraftToJSON,
} from '@aim/components';

import InstructionsStep from './paxAbstractsEdit/steps/InstructionsStep';
import TopicStep from './paxAbstractsEdit/steps/TopicStep';
import PresentationStep from './paxAbstractsEdit/steps/PresentationStep';
import AgreementStep from './paxAbstractsEdit/steps/AgreementStep';
import AifaData from './paxAbstractsEdit/steps/AifaData';
import ContentStep from './paxAbstractsEdit/steps/ContentStep';
import AffiliationsStep from './paxAbstractsEdit/steps/AffiliationsStep';
import AuthorsAndPresenterStep from './paxAbstractsEdit/steps/AuthorsAndPresenterStep';
import { getEvent } from './paxAbstractsEdit/gqlHelper';
import AdditionalData from './paxAbstractsEdit/steps/AdditionalData';

import {
  getAbstractDocumentDetail,
  getEventAbstractService,
  createAbstractDocument,
  updateAbstractDocument,
  createFieldValue,
  updateFieldValue,
  createAbstractAffiliation,
  updateAbstractAffiliation,
  deleteAbstractAffiliation,
  createAbstractPresenter,
  updateAbstractPresenter,
  createAbstractAuthor,
  updateAbstractAuthor,
  deleteAbstractAuthor,
  deleteFieldValue,
  createFile,
  deleteFile,
} from './shared/participationGqlHelper';

import { useWelcomePageModulesRedirects } from './../hooks/useWelcomePageModulesRedirects';

const CustomSnackbar = styled(AimSnackbar)({
  '& .MuiAlert-action': {
    alignItems: 'start',
  },
});

const PaxAbstractsEdit = () => {
  const history = useHistory();
  const intl = CustomIntl(useIntl());
  const i18n = useI18n(intl);
  const { eventId, abstractId } = useParams();
  const [abstractService, setAbstractService] = useState();
  const [eventType, setEventType] = useState();
  const [grants, setGrants] = useState();
  const [grantDialog, setGrantDialog] = useState({ isOpen: false });
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false });
  const [abstractDocument, setAbstractDocument] = useState();
  // const [participation, setParticipation] = useState();
  const [participation] = useState(appState.getCurrentParticipation());

  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [files, setFiles] = useState([]);
  const [showPreview, setShowPreview] = useState(false);
  const [titles, setTitles] = useState();
  const [standardFields, setStandardFields] = useState();
  const [customFields, setCustomFields] = useState();

  const { getServiceRedirectLinkEvent } = useWelcomePageModulesRedirects();

  const [instructionsElement, setInstructionsElement] = useState({});

  const schema = ({ minNumberKeyword = 1 }) =>
    Joi.object({
      isAgreementChecked: Joi.boolean().valid(true).required().messages({
        // 'string.base': `"field" should be a type of 'text'`,
        // 'string.empty': `"field" cannot be an empty field`,
        // 'string.min': `"field" should have a minimum length of {#limit}`,
        'any.required': i18n.wizard.errors.snackbar.isAgreementChecked,
      }),
      // .label('is agreement checked'),
      ...(abstractService?.isAifaEvent && {
        aifaEuropeanCv: Joi.object().required().messages({
          'any.required': i18n.wizard.errors.snackbar.aifaEuropeanCv,
        }),
      }),
      ...(abstractService?.isAifaEvent && {
        aifaDisclosure: Joi.object().required().messages({
          'any.required': i18n.wizard.errors.snackbar.aifaDisclosure,
        }),
      }),
      title: Joi.string().required().messages({
        'any.required': i18n.wizard.errors.snackbar.title,
      }),
      keywords: Joi.array()
        .items(Joi.string())
        .ruleset.min(minNumberKeyword)
        .rule({
          message: `${i18n.wizard.errors.snackbar.minNumberKeyword} ${minNumberKeyword}`,
        }),
    }).unknown();
  const {
    control,
    handleSubmit,
    register,
    errors,
    reset,
    watch,
    getValues,
    setValue,
    clearErrors,
    formState,
    unregister,
    trigger,
  } = useForm({
    shouldUnregister: false,
    resolver: joiResolver(
      schema({
        minNumberKeyword: !abstractService?.minNumberKeyword
          ? 0
          : abstractService?.minNumberKeyword,
      })
    ),
  });

  const { dirtyFields } = formState;

  useEffect(() => {
    console.log('effect 1');
    console.log(eventId, '1');

    if (!eventId) return;
    getEvent(eventId, 'abstract').then((e) => {
      setEventType(e.type);
      const filteredGrants = e.grant?.items?.filter((grant) =>
        // !grant.closingDate ||
        isAfter(endOfDay(parseISO(grant.closingDate)), new Date())
      );
      setGrants(filteredGrants);
    });
    getEventAbstractService(eventId).then((resultAbstractService) => {
      const innerAbsService = {
        ...resultAbstractService,
        templateFields: mapToContextsOfUse(
          resultAbstractService.event.services.items[0].customFields,
          constants.AbstractFieldContext.AbstractTemplate
        ),
        authorFields: mapToContextsOfUse(
          resultAbstractService.event.services.items[0].customFields,
          constants.AbstractFieldContext.Author
        ),
        presenterFields: mapToContextsOfUse(
          resultAbstractService.event.services.items[0].customFields,
          constants.AbstractFieldContext.Presenter
        ),
        additionalDataFields: mapToContextsOfUse(
          resultAbstractService.event.services.items[0].customFields,
          constants.AbstractFieldContext.Abstract
        ),
      };
      console.log('inner', innerAbsService);

      const { instructions, presentationTypologies } = innerAbsService;
      const instructionsText = JSON.parse(instructions).blocks.map(
        (b) => b.text
      );

      const presentationModes = presentationTypologies.items.reduce(
        (acc, curr) => {
          if (!acc.includes(curr.presentationMode)) {
            acc.push(curr.presentationMode);
          }
          return acc;
        },
        []
      );
      setInstructionsElement({ presentationModes, instructionsText });

      const titles = [
        {
          title: i18n.wizard.instructions,
          isHidden: !(
            presentationModes.length > 1 ||
            instructionsText.some((text) => text.trim() !== '')
          ),
        },
        { title: i18n.wizard.presentationType, isHidden: false },
        { title: i18n.wizard.topics, isHidden: false },
        { title: i18n.wizard.affiliations, isHidden: false },
        { title: i18n.wizard.authorsAndPresenter, isHidden: false },
        {
          title: i18n.wizard.additionalData,
          isHidden: !innerAbsService.additionalDataFields?.length,
        },
        {
          title: i18n.wizard.aifaData,
          isHidden: !innerAbsService.isAifaEvent,
        },
        { title: i18n.wizard.content, isHidden: false },
        { title: i18n.wizard.agreements, isHidden: false },
      ];
      setAbstractService(innerAbsService);
      setTitles(titles);
    });
  }, []);

  // useEffect(() => {
  //   console.log('effect 2');
  //   console.log(eventId, '2');
  //   if (!eventId) return;
  //   getEventAbstractService(eventId).then((resultAbstractService) => {
  //     const innerAbsService = {
  //       ...resultAbstractService,
  //       templateFields: mapToContextsOfUse(
  //         resultAbstractService.event.services.items[0].customFields,
  //         constants.AbstractFieldContext.AbstractTemplate
  //       ),
  //       authorFields: mapToContextsOfUse(
  //         resultAbstractService.event.services.items[0].customFields,
  //         constants.AbstractFieldContext.Author
  //       ),
  //       presenterFields: mapToContextsOfUse(
  //         resultAbstractService.event.services.items[0].customFields,
  //         constants.AbstractFieldContext.Presenter
  //       ),
  //       additionalDataFields: mapToContextsOfUse(
  //         resultAbstractService.event.services.items[0].customFields,
  //         constants.AbstractFieldContext.Abstract
  //       ),
  //     };
  //     console.log('inner', innerAbsService);

  //     const { instructions, presentationTypologies } = innerAbsService;
  //     const instructionsText = JSON.parse(instructions).blocks.map(
  //       (b) => b.text
  //     );

  //     const presentationModes = presentationTypologies.items.reduce(
  //       (acc, curr) => {
  //         if (!acc.includes(curr.presentationMode)) {
  //           acc.push(curr.presentationMode);
  //         }
  //         return acc;
  //       },
  //       []
  //     );
  //     setInstructionsElement({ presentationModes, instructionsText });

  //     const titles = [
  //       {
  //         title: i18n.wizard.instructions,
  //         isHidden: !(
  //           presentationModes.length > 1 ||
  //           instructionsText.some((text) => text.trim() !== '')
  //         ),
  //       },
  //       { title: i18n.wizard.presentationType, isHidden: false },
  //       { title: i18n.wizard.topics, isHidden: false },
  //       { title: i18n.wizard.affiliations, isHidden: false },
  //       { title: i18n.wizard.authorsAndPresenter, isHidden: false },
  //       {
  //         title: i18n.wizard.additionalData,
  //         isHidden: !innerAbsService.additionalDataFields,
  //       },
  //       {
  //         title: i18n.wizard.aifaData,
  //         isHidden: !innerAbsService.isAifaEvent,
  //       },
  //       { title: i18n.wizard.content, isHidden: false },
  //       { title: i18n.wizard.agreements, isHidden: false },
  //     ];
  //     setAbstractService(innerAbsService);
  //     setTitles(titles);
  //   });
  // }, []);

  useEffect(() => {
    if (!abstractId || abstractId === 'create') {
      const innerDocument = {};
      if (abstractService) {
        innerDocument.abstractSections = mapToAbstractFields(
          [],
          abstractService.templateFields
        );
        innerDocument.authors = [];
        innerDocument.presenter = {
          fieldValues: mapToAbstractFields([], abstractService.presenterFields),
        };
        innerDocument.keywords = [];
      }

      if (eventType === 'physical') {
        innerDocument.presentationMode = 'onsite';
      } else if (eventType === 'virtual') {
        innerDocument.presentationMode = 'online';
      } else {
        innerDocument.presentationMode =
          instructionsElement?.presentationModes?.[0];
      }
      setAbstractDocument(innerDocument);
      reset(innerDocument);
    } else {
      if (abstractService) {
        getAbstractDocumentDetail(abstractId).then((document) => {
          const { preferredExposure, proposedPresentationTypology } = document;
          const innerDocument = {
            ...document,
            affiliations: document.affiliations.items.map((a) => ({
              ...a,
              city: { label: a.city, value: a.city },
              country: { label: a.country, value: a.country },
            })),
            category: document?.category?.id,
            subCategory: document?.subCategory?.id,
            presentationMode: preferredExposure,
            preferredExposureId: proposedPresentationTypology?.id,
            abstractSections: mapToAbstractFields(
              document.abstractSections.items.map((fieldValue) => ({
                ...fieldValue,
                value: fieldValue.value
                  ? JSON.parse(fieldValue.value)
                  : undefined,
              })),
              abstractService.templateFields
            ),
            additionalData: mapToAbstractFields(
              document.additionalData.items.map((fieldValue) => ({
                ...fieldValue,
                value: JSON.parse(fieldValue.value),
              })),
              abstractService.additionalDataFields
            ),
            authors: document.authors.items.map((author) => ({
              ...author,
              affiliation: author.affiliation?.id || '',
              fieldValues: mapToAbstractFields(
                author.fieldValues.items.map((fieldValue) => ({
                  ...fieldValue,
                  value: JSON.parse(fieldValue.value),
                })),
                abstractService.authorFields
              ),
            })),
            presenterParticipation: {
              value: document?.presenter?.participant?.id,
              label:
                (document?.presenter?.participant?.id &&
                  `${document?.presenter?.participant.givenName} ${document?.presenter?.participant.familyName} <${document?.presenter?.participant.email}>`) ||
                '',
            },
            presenter: {
              ...document.presenter,
              affiliation: document.presenter?.affiliation?.id || '',
              fieldValues: mapToAbstractFields(
                document.presenter?.fieldValues.items.map((fieldValue) => ({
                  ...fieldValue,
                  value: JSON.parse(fieldValue.value),
                })) || [],
                abstractService.presenterFields
              ),
            },
            keywords: (!document.keywords && []) || document.keywords,
          };
          setAbstractDocument(innerDocument);
          setFiles(innerDocument.attachments.items);
          reset(innerDocument);
        });
      }
    }
  }, [
    abstractId,
    abstractService,
    eventType,
    instructionsElement?.presentationModes,
  ]);

  const createOrUpdateFieldValues = async (
    fieldValueConnectionIdObj,
    fieldValues
  ) => {
    await Promise.all([
      ...fieldValues.map(async (fieldValue) => {
        if (!fieldValue.id) {
          return await createFieldValue(
            {
              ...fieldValueConnectionIdObj,
              // abstractDocumentAbstractSectionsId: abstractDocumentId,
              value: JSON.stringify(fieldValue.value),
              fieldValueFieldDefinitionId: fieldValue.fieldDefinition.id,
            },
            false
          );
        } else {
          return await updateFieldValue(
            {
              id: fieldValue.id,
              ...fieldValueConnectionIdObj,
              value: JSON.stringify(fieldValue.value),
              fieldValueFieldDefinitionId: fieldValue.fieldDefinition.id,
            },
            false
          );
        }
      }),
    ]);
  };

  const createUpdateOrDeleteAffiliations = async (
    abstractDocumentId,
    affiliations
  ) => {
    return await Promise.all([
      ...(affiliations?.map(async ({ id, nanoId, ...affiliation }) => {
        let resultAffiliation = {};
        if (!id) {
          resultAffiliation = await createAbstractAffiliation(
            {
              ...affiliation,
              abstractDocumentAffiliationsId: abstractDocumentId,
            },
            false
          );
        } else {
          resultAffiliation = await updateAbstractAffiliation(
            {
              id,
              ...affiliation,
              abstractDocumentAffiliationsId: abstractDocumentId,
            },
            false
          );
        }
        resultAffiliation['nanoId'] = nanoId;
        return resultAffiliation;
      }) || []),
      ...(abstractDocument.affiliations
        ?.filter((dba) => !affiliations?.find((a) => a.id === dba.id))
        .map(async (affiliation) => {
          return await deleteAbstractAffiliation(affiliation.id, false);
        }) || []),
    ]);
  };

  const updateFiles = async (abstractId, abstractSections) =>
    Promise.all([
      ...abstractSections.map(async (section) => {
        await Promise.all([
          ...(section.addedFiles?.map(async (fileObj) => {
            const matchExtension = fileObj.file.name.match(/\.[0-9a-z]+$/i);
            const extension = matchExtension ? matchExtension[0] : '';

            const input = {
              originalName: fileObj.file.name,
              extension: extension,
              size: Number(fileObj.file.size) || 0,
            };

            const file = await createFile(input, false);
            const key = `events/${eventId}/abstracts/${abstractId}/${file.id}${extension}`;

            await aws.s3.putObject({
              key,
              file: fileObj.file,
              isPublic: true,
            });
            for (let i = 0; i < section?.value?.blocks?.length || 0; i++) {
              if (
                section?.value?.blocks[i]?.type === 'atomic' &&
                section?.value?.blocks[i]?.data?.src === fileObj.fileUrl
              ) {
                section.value.blocks[i].data.src = await aws.s3.getS3ObjectUrl(
                  key
                );
              }
            }
          }) || []),
          ...(section.deletedFiles?.map(async (fileObj) => {
            const fileId = fileObj.fileUrl.match(/([^/]+)\.[0-9a-z]+$/i);
            // console.log('fileId', fileId);
            await deleteFile(fileId[1], false);
            return await aws.Storage.remove(
              aws.s3.getKeyFromS3ObjectUrl(fileObj.fileUrl)
            );
          }) || []),
        ]);
        // console.log('section', { section });
        return section;
      }),
    ]);

  const uploadFiles = async (abstractId) => {
    const dirPath = `events/${eventId}/abstracts/${abstractId}/`;
    return await Promise.all(
      files
        .filter((f) => !f.id)
        .map(async (f) => {
          const originalName = f?.name || new Date().getTime().toString();

          const matchExtension = originalName.match(/\.[0-9a-z]+$/i);
          const extension = matchExtension ? matchExtension[0] : '';

          const fileSize = f?.size;

          const input = {
            originalName: originalName,
            extension: extension,
            size: Number(fileSize) || 0,
            abstractDocumentAttachmentsId: abstractId,
          };

          return await fileHelper.createFile({
            dirPath,
            fileData: input,
            localFile: f,
            useLoader: false,
          });
        })
    );
  };

  const deleteFiles = async () => {
    // devo cancellare i files che sono su s3
    const dirPath = `events/${eventId}/abstracts/${abstractId}/`;
    const oldFiles = abstractDocument.attachments?.items;
    const filesToDelete =
      oldFiles?.filter((f) => !files.find((i) => i.id === f.id)) || [];
    if (Object.keys(filesToDelete).length == 0) return;
    return await Promise.all(
      filesToDelete.map(
        async (f) =>
          await fileHelper.deleteFile({
            dirPath,
            fileData: f,
            useLoader: false,
          })
      )
    );
  };

  const generateAbstractCode = async () => {
    const eventCode = appState.eventInfo.getValue().code;
    const abstractSequence = await utilities.getNextSequenceValue({
      eventCode,
      // eventId,
      sequenceName: 'abstractSequence',
    });
    console.log(`${eventCode}-ABS-${abstractSequence}`, 'generated');
    return `${eventCode}-ABS-${abstractSequence}`;
  };

  const createOrUpdateAbstractPresenter = async (
    abstractId,
    { fieldValues, affiliation, ...presenter },
    affiliations,
    participationId
  ) => {
    let abstractPresenter = {};
    let foundAffiliation = affiliations?.find((a) => a.id === affiliation);
    if (!foundAffiliation) {
      foundAffiliation = affiliations?.find((c) => c.nanoId === affiliation);
    }
    if (!presenter.id) {
      abstractPresenter = await createAbstractPresenter(
        {
          abstractPresenterAffiliationId: foundAffiliation?.id,
          abstractPresenterParticipantId: participationId,
        },
        false
      );
      await updateAbstractDocument(
        {
          id: abstractId,
          abstractDocumentPresenterId: abstractPresenter.id,
        },
        false
      );
    } else {
      abstractPresenter = await updateAbstractPresenter(
        {
          id: presenter.id,
          abstractPresenterParticipantId: participationId,
          abstractPresenterAffiliationId: foundAffiliation?.id,
        },
        false
      );
    }
    createOrUpdateFieldValues(
      { abstractPresenterFieldValuesId: abstractPresenter.id },
      fieldValues
    );
  };

  const createOrUpdateOrDeleteAbstractAuthors = async (
    abstractId,
    authors,
    affiliations
  ) => {
    await Promise.all([
      ...(authors.map(async ({ fieldValues, affiliation, ...author }) => {
        let abstractAuthor = {};
        let foundAffiliation = affiliations?.find((a) => a.id === affiliation);
        if (!foundAffiliation) {
          foundAffiliation = affiliations?.find(
            (c) => c.nanoId === affiliation
          );
        }
        if (!author.id) {
          abstractAuthor = await createAbstractAuthor(
            {
              abstractDocumentAuthorsId: abstractId,
              abstractAuthorAffiliationId: foundAffiliation?.id,
            },
            false
          );
        } else {
          abstractAuthor = await updateAbstractAuthor(
            {
              ...author,
              abstractAuthorAffiliationId: foundAffiliation?.id,
              abstractDocumentAuthorsId: abstractId,
            },
            false
          );
        }
        return await createOrUpdateFieldValues(
          { abstractAuthorFieldValuesId: abstractAuthor.id },
          fieldValues
        );
      }) || []),
      ...(abstractDocument.authors
        ?.filter((dba) => !authors?.find((a) => a.id === dba.id))
        .map(async (author) => {
          await deleteAbstractAuthor(author.id, false);
          return Promise.all([
            ...(author.fieldValues.map(
              async (fieldValue) => await deleteFieldValue(fieldValue.id, false)
            ) || []),
          ]);
        }) || []),
    ]);
  };

  const mapAdditionalData = (submittedData) => {
    const { standardDefaultValues, customDefaultValues } = submittedData;
    const nextStandardDefaultValues = standardDefaultValues
      ? Object.entries(standardDefaultValues).map(([key, value]) => ({
          ...Object.values(standardFields)
            .flat()
            .find((sf) => sf.id === key),
          fieldDefinition: { id: key },
          value,
        }))
      : [];
    const nextCustomDefaultValues = customDefaultValues
      ? Object.entries(customDefaultValues).map(([key, value]) => ({
          ...Object.values(customFields)
            .flat()
            .find((cf) => cf.id === key),
          fieldDefinition: { id: key },
          value,
        }))
      : [];
    const nextSubmittedData = {
      standardDefaultValues: nextStandardDefaultValues,
      customDefaultValues: nextCustomDefaultValues,
    };
    return nextSubmittedData;
  };

  const sendData = async (_values, status, historyLink) => {
    appState.isLoader.next(true);

    const values = {
      ..._values,
      affiliations: _values.affiliations?.map((x) => {
        return {
          ...x,
          city: x.city?.value,
          country: x.country?.value,
        };
      }),
    };

    const abstractSections = values.abstractSections.map((s) => ({
      ...s,
      value: Array.isArray(s?.value?.blocks)
        ? s.value
        : s.value
        ? JSON.parse(AimMegadraftToJSON(s.value))
        : undefined,
    }));
    if (
      !values.isAgreementChecked &&
      status !== constants.AbstractStatus.DRAFT.id
    )
      return;
    const {
      title,
      category,
      subCategory,
      preferredExposureId,
      presentationMode,
      tags,
      keywords,
      presenterParticipation,
      aifaEuropeanCv,
      aifaEuropeanCvFile,
      aifaDisclosure,
      aifaDisclosureFile,
    } = values;

    const input = {
      title,
      abstractDocumentPresenterId: presenterParticipation?.id,
      abstractDocumentParticipantId: participation.id,
      abstractDocumentAbstractServiceId: abstractService.id,
      abstractDocumentCategoryId: category,
      abstractDocumentSubCategoryId: subCategory,
      preferredExposure: presentationMode,
      abstractDocumentProposedPresentationTypologyId: preferredExposureId,
      status,
      tags,
      keywords,
    };
    let promise = {};
    if (abstractId) {
      if (!abstractDocument.code) {
        input.code = await generateAbstractCode();
      }
      promise = updateAbstractDocument({ ...input, id: abstractId }, false);
    } else {
      input.code = await generateAbstractCode();
      promise = createAbstractDocument(input, false);
    }
    promise
      .then(async (document) => {
        const nextAbstractSections = await updateFiles(
          document.id,
          abstractSections
        );
        return { document, abstractSections: nextAbstractSections };
      })
      .then(async ({ document, abstractSections }) => {
        await createOrUpdateFieldValues(
          { abstractDocumentAbstractSectionsId: document.id },
          abstractSections
        );
        return document;
      })
      .then(async (document) => {
        await Promise.all([uploadFiles(document.id), deleteFiles()]);
        return document;
      })
      .then(async (document) => {
        const affiliations = await createUpdateOrDeleteAffiliations(
          document.id,
          values.affiliations
        );
        return { document, affiliations: affiliations.filter((a) => !!a) };
      })
      .then(async ({ document, affiliations }) => {
        await createOrUpdateAbstractPresenter(
          document.id,
          values.presenter,
          affiliations,
          presenterParticipation?.value
        );
        return { document, affiliations };
      })
      .then(async ({ document, affiliations }) => {
        await createOrUpdateOrDeleteAbstractAuthors(
          document.id,
          values.authors,
          affiliations
        );
        return document;
      })
      .then(async (document) => {
        const submittedAdditionalData = mapAdditionalData(values);
        const {
          standardDefaultValues,
          customDefaultValues,
        } = submittedAdditionalData;

        // standardFields
        if (dirtyFields?.standardDefaultValues) {
          const filteredStandardDefaultValues = standardDefaultValues.filter(
            (f) => dirtyFields.standardDefaultValues[f.fieldDefinition.id]
          );

          const standardDefaultValuesPromises = filteredStandardDefaultValues.map(
            (f) => {
              createFieldValue(
                {
                  participationFieldValuesId: participation.id,
                  fieldValueFieldDefinitionId: f.fieldDefinition.id,
                  value: JSON.stringify(f.value),
                },
                false
              );
            }
          );
          await Promise.all(standardDefaultValuesPromises);
        }

        // customFields
        if (dirtyFields?.customDefaultValues) {
          const filteredCustomDefaultValues = customDefaultValues.filter(
            (f) => dirtyFields.customDefaultValues[f.fieldDefinition.id]
          );
          const customDefaultValuesPromises = filteredCustomDefaultValues.map(
            (f) => {
              createFieldValue(
                {
                  participationFieldValuesId: participation.id,
                  fieldValueFieldDefinitionId: f.fieldDefinition.id,
                  value: JSON.stringify(f.value),
                },
                false
              );
            }
          );
          await Promise.all(customDefaultValuesPromises);
        }
        return document;
      })
      .then(async (document) => {
        // AifaEuropeanCv
        if (dirtyFields.aifaEuropeanCv) {
          const dirPath = `events/${eventId}/abstracts/${document.id}`;
          if (abstractDocument.aifaEuropeanCv) {
            await fileHelper.deleteFile({
              dirPath,
              fileData: abstractDocument.aifaEuropeanCv,
              useLoader: false,
            });
          }
          if (aifaEuropeanCv && aifaEuropeanCvFile) {
            const fileId = await fileHelper.createFile({
              dirPath,
              fileData: aifaEuropeanCv,
              localFile: aifaEuropeanCvFile,
              useLoader: false,
            });
            const input = {
              id: document.id,
              abstractDocumentAifaEuropeanCvId: fileId,
            };
            await updateAbstractDocument(input);
          }
        }
        return document;
      })
      .then(async (document) => {
        // AifaDisclosure
        if (dirtyFields.aifaDisclosure) {
          const dirPath = `events/${eventId}/abstracts/${document.id}`;
          if (abstractDocument.aifaDisclosure) {
            await fileHelper.deleteFile({
              dirPath,
              fileData: abstractDocument.aifaDisclosure,
              useLoader: false,
            });
          }
          if (aifaDisclosure && aifaDisclosureFile) {
            const fileId = await fileHelper.createFile({
              dirPath,
              fileData: aifaDisclosure,
              localFile: aifaDisclosureFile,
              useLoader: false,
            });
            const input = {
              id: document.id,
              abstractDocumentAifaDisclosureId: fileId,
            };
            await updateAbstractDocument(input);
          }
        }
        return document;
      })
      .then(
        (document) =>
          appState.isLoader.next(false) ||
          history.push(historyLink || `/events/${eventId}/abstracts`, {
            abstractId: document.id,
          })
      )
      .catch((err) => {
        appState.isLoader.next(false);
        console.error(err);
        setSnackbar({
          isOpen: true,
          severity: AimSnackbarSeverity.error,
          message: i18n.page.sendDataError,
        });
      });
  };

  // eslint-disable-next-line react/display-name
  const StepperControls = (errors, trigger) => ({
    previous,
    next,
    index,
    isLastStep,
  }) => (
    <div
      style={{
        display: 'flex',
        flex: 1,
        gap: 7,
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <AimIconAndTextButton
        text={i18n.actions.back}
        variant="greyFill"
        onClick={previous}
        disabled={index === 0}
      >
        <ArrowBack />
      </AimIconAndTextButton>
      <div style={{ flex: 1 }} />
      {isLastStep ? (
        <>
          <AimIconAndTextButton
            text={i18n.actions.saveDraft}
            variant="greyFill"
            onClick={() => {
              // bypass form validation
              {
                /* clearErrors(); */
              }
              sendData(getValues(), constants.AbstractStatus.DRAFT.id);
            }}
          >
            <ArrowForward />
          </AimIconAndTextButton>
          <AimIconAndTextButton
            text={i18n.actions.saveAndExit}
            variant="greenFill"
            type="submit"
            disabled={Object.keys(errors).length}
          >
            <Check />
          </AimIconAndTextButton>
        </>
      ) : (
        <AimIconAndTextButton
          text={i18n.actions.forward}
          variant="greyFill"
          onClick={next}
        >
          <ArrowForward />
        </AimIconAndTextButton>
      )}
    </div>
  );

  const mapToContextsOfUse = (fields, filterKey) => {
    return sortBy(
      fields.items
        .map((field) => {
          const contextsOfUse = field.contextsOfUse.items.find(
            (fieldContext) => fieldContext.contextName === filterKey
          );
          return { ...field, contextsOfUse };
        })
        .filter(
          (field) => field.contextsOfUse && !field.contextsOfUse.isHidden
        ),
      ['contextsOfUse.position']
    );
  };

  const mapToAbstractFields = (abstractFields, abstractServiceFields) => {
    return abstractServiceFields.map(
      (abstractServiceField) =>
        abstractFields.find(
          (abstractField) =>
            abstractField?.fieldDefinition?.id === abstractServiceField.id
        ) || {
          fieldDefinition: { ...abstractServiceField },
        }
    );
  };

  const downloadFile = (file) => {
    const dirPath = `events/${eventId}/abstracts/${abstractId}/`;
    if (file.id)
      fileHelper.downloadFromS3({
        dirPath,
        fileData: file,
        useLoader: false,
      });
    else {
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(file);
      link.download = file.name;
      link.click();
      link.remove();
    }
  };

  const affiliations = watch('affiliations');
  const formValues = getValues();

  const category = abstractService?.categories?.items?.find(
    (i) => i.id === formValues.category
  );
  const subCategory = category?.subCategories?.items?.find(
    (i) => i.id === formValues.subCategory
  );

  const parsedContexts = abstractService?.templateFields.map((c) => ({
    ...c,
    value: JSON.stringify(
      formValues?.abstractSections?.find((s) => s.fieldDefinition.id === c.id)
        ?.value
    ),
    contextsOfUse: { items: [{ ...c.contextsOfUse }] },
  }));

  const abstractSections = { items: parsedContexts };

  const handleConfim = async () => {
    if (grants.length) {
      setGrantDialog({
        values: confirmDialog.values,
        isOpen: true,
        status: constants.AbstractStatus.PUBLISHED.id,
      });
    } else {
      sendData(
        confirmDialog.values,
        constants.AbstractStatus.PUBLISHED.id,
        getServiceRedirectLinkEvent('SendAbstract')
      );
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        width: '100%',
        maxHeight: 'calc(100vh - 210px)',
        overflow: 'auto',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          maxWidth: '80vw',
          minWidth: '80vw',
        }}
      >
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Tooltip title={i18n.page.backButton.tooltip}>
              <AimIconAndTextButton
                isUpperCase
                variant="none"
                style={{
                  padding: 0,
                }}
                onClick={() => history.push(`/events/${eventId}/abstracts`)}
                text={i18n.page.backText.backText}
              >
                <ArrowBack />
              </AimIconAndTextButton>
            </Tooltip>
            <Tooltip title={i18n.actions.preview}>
              <AimIconAndTextButton
                isUpperCase
                variant="secondary"
                text={i18n.actions.preview}
                style={{ padding: 0 }}
                onClick={() => setShowPreview(true)}
              >
                {/* <ArrowForward /> */}
              </AimIconAndTextButton>
            </Tooltip>
          </div>
          <Typography
            variant="body1"
            component="h1"
            style={{ fontSize: '1.3125rem', fontWeight: '700' }}
          >
            {i18n.page.title}
          </Typography>
        </div>
        <div style={{ flex: 1 }}>
          <AimTitleAndButtons
            title={intl.formatMessage({
              description: 'send abstract title',
              defaultMessage: 'Send abstract',
            })}
          />
          <form
            onSubmit={handleSubmit(
              (values) =>
                setConfirmDialog({
                  isOpen: true,
                  values,
                }),
              (submitErrors) => {
                setSnackbar({
                  isOpen: true,
                  autoHide: false,
                  severity: AimSnackbarSeverity.error,
                  message: Object.values(
                    submitErrors
                  ).map(({ message, index }) => (
                    <div key={index}>{message}</div>
                  )),
                });
              }
              // setGrantDialog({
              //   isOpen: true,
              //   values,
              //   status: constants.AbstractStatus.PUBLISHED.id,
              // })
            )}
          >
            {titles && (
              <AimStepper
                titlesObject={titles}
                stepperControls={StepperControls(errors, trigger)}
                outsideStepperControls
                //fixedHeight
                slideStyle={{
                  maxHeight: 'calc(100vh - 210px)',
                  overflowY: 'auto',
                }}
                variant="grey"
              >
                <InstructionsStep
                  event={event}
                  variant="white"
                  {...{
                    control,
                    title: titles[0].title,
                    i18n,
                    errors,
                    abstractService,
                    instructionsElement,
                  }}
                />

                <PresentationStep
                  variant="white"
                  {...{
                    control,
                    title: titles[1].title,
                    i18n,
                    errors,
                    abstractService,
                    getValues,
                    setValue,
                  }}
                />
                <TopicStep
                  {...{
                    variant: 'white',
                    control,
                    title: titles[2].title,
                    i18n,
                    errors,
                    abstractService,
                    watch,
                    setValue,
                  }}
                />
                <AffiliationsStep
                  control={control}
                  register={register}
                  title={titles[3].title}
                  i18n={i18n}
                  errors={errors}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  getValues={getValues}
                  watch={watch}
                />
                <AuthorsAndPresenterStep
                  abstractService={abstractService}
                  control={control}
                  register={register}
                  title={titles[4].title}
                  i18n={i18n}
                  errors={errors}
                  setValue={setValue}
                  getValues={getValues}
                  watch={watch}
                  affiliations={affiliations}
                  participation={participation}
                  clearErrors={clearErrors}
                />
                <AdditionalData
                  title={titles[5].title}
                  control={control}
                  reset={reset}
                  register={register}
                  setValue={setValue}
                  getValues={getValues}
                  errors={errors}
                  clearErrors={clearErrors}
                  standardFields={standardFields}
                  setStandardFields={setStandardFields}
                  customFields={customFields}
                  setCustomFields={setCustomFields}
                  participation={participation}
                />
                <AifaData
                  variant="white"
                  control={control}
                  title={titles[6].title}
                  i18n={i18n}
                  setValue={setValue}
                  abstractService={abstractService}
                  watch={watch}
                  errors={errors}
                  s3Folder={`events/${eventId}`}
                  abstractId={abstractId}
                />
                <ContentStep
                  variant="white"
                  control={control}
                  title={titles[7].title}
                  i18n={i18n}
                  errors={errors}
                  getValues={getValues}
                  setValue={setValue}
                  abstractService={abstractService}
                  abstractId={abstractId}
                  files={files}
                  setFiles={setFiles}
                  s3Folder={`events/${eventId}/abstracts/${abstractId}`}
                  watch={watch}
                />
                <AgreementStep
                  {...{
                    control,
                    title: titles[8].title,
                    i18n,
                    errors,
                    abstractService,
                  }}
                />
              </AimStepper>
            )}
          </form>
          <CustomSnackbar
            open={snackbar.isOpen}
            onClose={() => setSnackbar({ isOpen: false })}
            severity={snackbar.severity}
            autoHide={snackbar.autoHide}
          >
            {snackbar.message}
          </CustomSnackbar>
        </div>
        <AimDialog
          maxWidth={'xl'}
          fullWidth
          open={showPreview}
          onClose={() => setShowPreview(false)}
          hideAgreeButton
        >
          <DialogContent>
            <AbstractDetailPage
              intl={intl}
              abstractDocument={{
                ...formValues,
                category,
                subCategory,
                abstractSections: {
                  items: formValues.abstractSections?.map((as, idx) => ({
                    ...as,
                    fieldDefinition: {
                      ...as.fieldDefinition,
                      contextsOfUse: {
                        items: [{ position: 0 }],
                      },
                    },
                    value: Array.isArray(as.value?.blocks)
                      ? JSON.stringify(as.value)
                      : AimMegadraftToJSON(as.value),
                  })),
                },
                presenter: {
                  ...formValues.presenter,
                  fieldValues: {
                    items: formValues.presenter?.fieldValues?.map((item) => ({
                      ...item,
                      value: JSON.stringify(item.value),
                    })),
                  },
                },
                authors: {
                  ...formValues.authors,
                  items: formValues.authors?.map((a) => ({
                    fieldValues: {
                      items: a.fieldValues.map((f) => ({
                        ...f,
                        value: JSON.stringify(f.value),
                      })),
                    },
                  })),
                },
                attachments: {
                  items: files,
                },
              }}
              hideReviewsFromPage
              printWithoutReviewsFrontOffice
              onDownloadAttachments={downloadFile}
              maxHeight={'100%'}
            />
          </DialogContent>
        </AimDialog>
        <AimDialog
          title={i18n.dialogs.grantDialog.title}
          open={grantDialog.isOpen}
          onClose={() => setGrantDialog({ isOpen: false })}
          onAgree={async () => {
            await sendData(
              grantDialog.values,
              grantDialog.status,
              `/events/${eventId}/grants/abstract`
            );
          }}
          onDisagree={() =>
            sendData(
              grantDialog.values,
              grantDialog.status,
              getServiceRedirectLinkEvent('SendAbstract')
            )
          }
          agreeText={i18n.dialogs.grantDialog.yesButton}
          disagreeText={i18n.dialogs.grantDialog.noButton}
          maxWidth="lg"
        >
          <AimTypography variant="text">
            {i18n.dialogs.grantDialog.description}
          </AimTypography>
        </AimDialog>
        <AimDialog
          title={i18n.dialogs.confirmDialog.title}
          open={confirmDialog.isOpen}
          onClose={() => setConfirmDialog({ isOpen: false })}
          onAgree={handleConfim}
          onDisagree={() => setConfirmDialog({ isOpen: false })}
          agreeText={i18n.dialogs.confirmDialog.submitButton}
          disagreeText={i18n.dialogs.confirmDialog.cancelButton}
        >
          {i18n.dialogs.confirmDialog.message}
          <AimTypography variant="text" boxStyle={{ margin: '10px 0px' }}>
            {abstractService?.assesment}
          </AimTypography>
        </AimDialog>
      </div>
    </div>
  );
};

export default PaxAbstractsEdit;
