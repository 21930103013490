import React, { useState, useEffect } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { format } from 'date-fns';

import { aws, constants } from '@aim/common';

import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import ArrowBack from '@material-ui/icons/ArrowBack';
import InformationCard from './InformationCard';

import SymposiumSessionsList from './SymposiumSessionsList';
import {
  MainContainer,
  InnerContainer,
} from './../shared/SponsorPagesContainers';

import { useSymposiums } from './../shared/symposiumsGqlHelper';

import {
  AimIconAndTextButton,
  AimTypography,
  AimRichText,
  AimFileList,
  CustomIntl,
} from '@aim/components';

import translation from './../shared/translation';

const Symposium = () => {
  //Hooks
  const history = useHistory();
  const { eventId, sponsorId, service } = useParams();
  const { list, onUpdate, onDelete } = useSymposiums();
  const intl = CustomIntl(useIntl());
  const i18n = translation.symposium(intl);

  //State
  const [sessions, setSessions] = useState([]);
  const [deadline, setDeadline] = useState('');
  const [vatRate, setVatRate] = useState('');
  const [symposiumService, setSymposiumService] = useState();
  const [bookingDuration, setBookingDuration] = useState();
  const [paymentType, setPaymentType] = useState();

  //Effects
  useEffect(() => {
    fetchAgendaSessions();
  }, []);

  useEffect(() => {
    const subscriptionUpdate = onUpdate(subscriptionCallback);
    const subscriptionDelete = onDelete(subscriptionCallback);
    return () => {
      subscriptionUpdate.unsubscribe();
      subscriptionDelete.unsubscribe();
    };
  });

  // Functions
  const fetchAgendaSessions = async () => {
    const result = await list(eventId);
    setSymposiumService(result.symposiumService);
    console.log('sessions', result.symposiumService.symposiums.items);
    setSessions(result.symposiumService.symposiums.items);
    setDeadline(result.symposiumService.deadline);
    setVatRate(result.symposiumService.vatRate / 100);
    setBookingDuration(result.sponsorServiceBookingDuration || 10);

    const paymentType =
      result.symposiumService.isCreditCardEnabled &&
      result.symposiumService.isBankTransferEnabled
        ? 'both'
        : (result.symposiumService.isCreditCardEnabled &&
            constants.PaymentTypes.CreditCard) ||
          (result.symposiumService.isBankTransferEnabled &&
            constants.PaymentTypes.BankTransfer);
    setPaymentType(paymentType);
  };

  const subscriptionCallback = (data) => {
    if (sessions.find((s) => s.id === data.id)) {
      fetchAgendaSessions();
    }
  };

  const downloadFile = async (item) => {
    const s3Folder = `events/${eventId}/sponsor/${service}/documents/`;
    const link = `${s3Folder}${item.id}${item.extension}`;

    var res = await aws.Storage.get(link);
    window.open(res);
  };

  return (
    <MainContainer>
      <InnerContainer grow bottomSpacing>
        <div style={{ display: 'flex-root', marginTop: 20 }}>
          <Tooltip title={i18n.page.back.tooltip.label}>
            <AimIconAndTextButton
              variant="text"
              text={i18n.page.back.button.label}
              onClick={() =>
                history.push(`/events/${eventId}/${sponsorId}/my-services`)
              }
            >
              <ArrowBack />
            </AimIconAndTextButton>
          </Tooltip>
        </div>
        <Grid container>
          <Grid item xs={12}>
            <AimTypography variant={'h1'}>
              {i18n.page.title.label}
            </AimTypography>
          </Grid>
          <Grid item xs={12}>
            {symposiumService?.description && (
              <AimRichText
                label={i18n.page.instructions.label}
                value={JSON.parse(symposiumService.description)}
                readOnly
                editorStyleOverride={{
                  minHeight: 'auto',
                  maxHeight: 'fit-content',
                  overflow: 'hidden !important',
                }}
              />
            )}
          </Grid>
        </Grid>
        <Grid container spacing={1} style={{ marginTop: 20, maxWidth: '100%' }}>
          <Grid
            item
            xs={6}
            style={{
              maxWidth: '49%',
              backgroundColor: 'white',
            }}
          >
            <InformationCard
              title={i18n.page.infoCard.days.label}
              value={bookingDuration}
            />
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              maxWidth: '49%',
              backgroundColor: 'white',
              marginLeft: '1%',
            }}
          >
            <InformationCard
              title={i18n.page.infoCard.deadline.label}
              value={deadline ? format(new Date(deadline), 'dd/MM/yyyy') : '-'}
            />
          </Grid>
        </Grid>

        {symposiumService?.documents?.items?.length ? (
          <Grid container spacing={2} style={{ marginTop: 20 }}>
            <Grid
              item
              xs={12}
              style={{
                backgroundColor: 'white',
                maxWidth: '98%',
              }}
            >
              <Grid
                item
                container
                alignItems={'center'}
                style={{
                  backgroundColor: 'white',
                }}
              >
                <Grid
                  item
                  xs={10}
                  style={{
                    paddingLeft: 15,
                  }}
                >
                  <AimTypography variant={'h3'}>
                    {i18n.page.fileList.fileName.label}
                  </AimTypography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{
                    paddingLeft: 60,
                  }}
                >
                  <AimTypography variant={'h3'}>
                    {i18n.page.fileList.fileDimension.label}
                  </AimTypography>
                </Grid>
              </Grid>
              <AimFileList
                files={symposiumService.documents.items}
                onDownload={downloadFile}
              />
            </Grid>
          </Grid>
        ) : null}

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SymposiumSessionsList
              sessions={sessions}
              vatRate={vatRate}
              bookingDuration={bookingDuration}
              paymentType={paymentType}
              fetchAgendaSessions={fetchAgendaSessions}
            />
          </Grid>
        </Grid>
      </InnerContainer>
    </MainContainer>
  );
};

export default Symposium;
