export default function (intl) {
  return {
    page: {
      title: intl.formatMessage({
        description: 'webcast page list',
        defaultMessage: 'On-Demand',
      }),
      search: intl.formatMessage({
        description: 'Search for webcast topic, title or subtitle',
        defaultMessage: 'Search for webcast topic, title or subtitle',
      }),
      searchbyDate: intl.formatMessage({
        description: 'Search for webcast date',
        defaultMessage: 'Search for webcast date',
      }),
      filter: {
        speakersPlaceholder: intl.formatMessage({
          description: 'webcast speaker select',
          defaultMessage: 'Select speaker',
        }),
      },
    },
    toolbar: {
      buttons: {
        sendEmail: {
          tooltip: intl.formatMessage({
            description: 'send email',
            defaultMessage: 'Send Email',
          }),
        },
        exportExcel: {
          tooltip: intl.formatMessage({
            description: 'export excel',
            defaultMessage: 'Export Excel',
          }),
        },
        import: {
          tooltip: intl.formatMessage({
            description: 'import',
            defaultMessage: 'Import',
          }),
        },
        filter: {
          tooltip: intl.formatMessage({
            description: 'filter',
            defaultMessage: 'Filter',
          }),
        },
        add: {
          tooltip: intl.formatMessage({
            description: 'add new webcast',
            defaultMessage: 'Add new webcast',
          }),
        },
      },
    },
    card: {
      views: intl.formatMessage({
        description: 'views label',
        defaultMessage: 'Views',
      }),
      uploadDate: intl.formatMessage({
        description: 'upload date label',
        defaultMessage: 'Upload date',
      }),
      topic: intl.formatMessage({
        description: 'topic label',
        defaultMessage: 'Topic',
      }),
      speakers: intl.formatMessage({
        description: 'speakers label',
        defaultMessage: 'Speakers',
      }),
    },
    dialog: {
      title: intl.formatMessage({
        description: 'delete webcast title',
        defaultMessage: 'Delete webcast',
      }),
      message: intl.formatMessage({
        description: 'are you sure delete webcast message',
        defaultMessage: 'Are you sure?',
      }),
    },
  };
}
