import React, { useState, useEffect } from 'react';
import { appState, googleHelper } from '@aim/common';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';

import {
  theme,
  styled,
  AimIconAndTextButton,
  AimTypography,
  AimSnackbar,
  useAimMediaQuery,
} from '@aim/components';

import { useIntl } from 'react-intl';
// import { isMobile } from 'react-device-detect';
import useI18n from './sponsorDetailEdit/i18n';

import ArrowBack from '@material-ui/icons/ArrowBack';
import CheckIcon from '@material-ui/icons/Check';
import RefreshIcon from '@material-ui/icons/Refresh';
import SponsorDetailEditForm from './sponsorDetailEdit/SponsorDetailEditForm';
import {
  downloadFromS3,
  useSponsorDetailHelper,
} from './sponsorDetail/sponsorDetailHelper';
import { FileTypeEnum } from './sponsorDetail/enums';

const { getGoogleMapsPlaceByPlaceId } = googleHelper;

const HomeContainer = styled('div')({
  background: theme.colors.greyScale.white,
  flex: 1,
  overflowX: 'hidden',
  flexDirection: 'column',
  width: '100%',
});

const PageContainer = styled('div')(({ isMobile }) => ({
  // background: theme.colors.greyScale.backgroundGrey,
  height: '100%',
  display: 'flex',
  // width: isMobile ? '100%' : '1280px',
  width: isMobile ? 'auto' : '70%',
  flexDirection: 'column',
  margin: '0 auto',
  alignItems: 'center',
  flex: 1,
}));

export const Container = styled('div')({
  // background: theme.colors.greyScale.white,
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  width: '95%',
  margin: '0 auto',
  padding: '10px',
});

export const FormContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  width: '95%',
  margin: '0 auto',
  backgroundColor: 'red',
  padding: '10px',
});

const sponsorDetailData = {
  name: '',
  description: '',
  contacts: [],
  profileImage: '',
  profileCover: '',
  documents: {
    items: [],
  },
};

const SponsorDetailEdit = () => {
  const intl = useIntl();
  const i18n = useI18n(intl);

  const history = useHistory();
  const { sponsorId, eventId } = useParams();
  const {
    get,
    update,
    createContact,
    updateContact,
    removeContact,
    createSponsorFile,
    removeFile,
  } = useSponsorDetailHelper();
  const { isMobile } = useAimMediaQuery();

  const [fetchedData, setFetchedData] = useState();
  const [deleteList, setDeleteList] = useState({
    contacts: [],
    documents: [],
    profileImage: null,
    coverImage: null,
  });
  const [snackbar, setSnackbar] = useState({ isOpen: false });

  const s3FolderBase = `events/${eventId}/sponsor/frontoffice/${sponsorId}/`;

  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,
    errors,
    clearErrors,
  } = useForm({
    reValidateMode: 'onChange',
    shouldUnregister: false,
    defaultValues: { ...sponsorDetailData },
  });

  const sendData = async (submittedData) => {
    try {
      // removing documents if needed
      deleteList.documents.map(async (file) => {
        await removeFileS3(file, FileTypeEnum.documents);
      });
      // removing profileImage if needed
      if (deleteList.profileImage) {
        await removeFileS3(deleteList.profileImage, FileTypeEnum.profileImage);
      }
      // removing profileCover if needed
      if (deleteList.profileCover) {
        await removeFileS3(deleteList.profileCover, FileTypeEnum.profileCover);
      }

      // removing contacts if needed
      deleteList.contacts.map(async (contactId) => {
        await removeContact(contactId);
      });
      // removing profileImage if needed
      if (deleteList.profileImage) {
        await removeFileS3(deleteList.profileImage, FileTypeEnum.profileImage);
      }
      // removing profileCover if needed
      if (deleteList.profileCover) {
        await removeFileS3(deleteList.profileCover, FileTypeEnum.profileCover);
      }

      // removing videoCoverImage if needed
      if (deleteList.videoCoverImage) {
        await removeFileS3(
          deleteList.videoCoverImage,
          FileTypeEnum.profileCover
        );
      }

      //insert contacts on DB
      submittedData.contacts.map(async (contact) => {
        const nextContact = {
          ...contact,
          country: contact.country?.value,
          city: contact.city?.value,
        };

        if (contact.id) {
          await updateContact(nextContact);
        } else {
          nextContact.sponsorContactSponsorId = sponsorId;
          await createContact(nextContact);
        }
      });

      //insert profileImage, coverImage, documents (dopo upload su s3 mando su DB)
      if (submittedData.profileImage && !submittedData.profileImage.id) {
        submittedData.profileImage.id = await uploadFileS3(
          submittedData.profileImage,
          FileTypeEnum.profileImage
        );
      }

      if (submittedData.profileCover && !submittedData.profileCover.id) {
        submittedData.profileCover.id = await uploadFileS3(
          submittedData.profileCover,
          FileTypeEnum.profileCover
        );
      }

      submittedData.documents.items.map(async (file) => {
        if (!file.id) {
          file.id = await uploadFileS3(file, FileTypeEnum.documents);
        }
      });

      if (submittedData.videoCoverImage && !submittedData.videoCoverImage.id) {
        submittedData.videoCoverImage.id = await uploadFileS3(
          submittedData.videoCoverImage,
          FileTypeEnum.videoCoverImage
        );
      }

      const input = {
        id: sponsorId,
        name: submittedData.name,
        ...(!!submittedData.profileImage?.id && {
          sponsorProfileImageId: submittedData.profileImage.id,
        }),
        ...(!!submittedData.profileCover?.id && {
          sponsorProfileCoverId: submittedData.profileCover.id,
        }),
        ...(!!submittedData.description &&
          submittedData.description !== fetchedData.description && {
            description: JSON.stringify(submittedData.description),
          }),
        ...(!!submittedData.videoCoverImage?.id && {
          sponsorVideoCoverId: submittedData.videoCoverImage.id,
        }),
        videoTitle: submittedData.videoTitle,
        videoSubtitle: submittedData.videoSubtitle,
        videoLink: submittedData.videoLink,
        videoEmbeddedLink: submittedData.videoEmbeddedLink,
        videoProvider: submittedData.videoProvider,
      };

      await update(input);
      navigateBack();
    } catch (e) {
      console.error(e);
    }
  };

  const checkIsValidUser = async () => {
    const participation = appState.user.getValue()?.userAndParticipation
      ?.participation;
    if (participation?.sponsor?.id === sponsorId) fetchSponsorInfo();
    else history.push('/404');
  };

  useEffect(() => {
    checkIsValidUser();
  }, []);

  useEffect(() => {
    const loadData = async () => {
      if (fetchedData) {
        const resetObj = {
          name: fetchedData.name,
          description: fetchedData.description,
          // contacts: fetchedData.contacts.items,
          profileImage: fetchedData.profileImage,
          profileCover: fetchedData.profileCover,
          videoTitle: fetchedData.videoTitle,
          videoSubtitle: fetchedData.videoSubtitle,
          videoLink: fetchedData.videoLink,
          videoEmbeddedLink: fetchedData.videoEmbeddedLink,
          videoProvider: fetchedData.videoProvider,
          videoCoverImage: fetchedData.videoCover,
          documents: {
            items:
              fetchedData.documents.items.length > 0
                ? fetchedData.documents.items.filter(
                    (doc) =>
                      doc.id !== fetchedData.profileCover?.id &&
                      doc.id !== fetchedData.profileImage?.id
                  )
                : [],
          },
          contacts: fetchedData.contacts.items.map((c) => {
            const city = { label: c.city, value: c.city };
            const country = { label: c.country, value: c.country };
            return { ...c, country, city };
          }),
        };

        reset(resetObj);
      }
    };
    loadData();
  }, [fetchedData]);

  const fetchSponsorInfo = async () => {
    const res = await get(sponsorId);
    // res.id;
    setFetchedData(res);
  };

  const uploadFileS3 = async (file, subFolder) => {
    const input = {
      originalName: file.name,
      sponsorDocumentsId: sponsorId,
      extension: file.extension,
      size: file.size,
    };
    const res = await createSponsorFile(
      input,
      `${s3FolderBase}${subFolder}/`,
      file.file
    );
    return res.id;
  };

  const removeFileS3 = async (file, subFolder) => {
    await removeFile(
      file.id,
      `${s3FolderBase}${subFolder}/${file.id}${file.extension}`
    );
  };

  const downloadFile = async (file, subFolder) => {
    downloadFromS3(file, subFolder, eventId, sponsorId);
  };

  const navigateBack = () => {
    history.push(`/events/${eventId}/sponsors/${sponsorId}`);
  };

  return (
    <HomeContainer>
      <PageContainer isMobile={isMobile}>
        <Container>
          <Grid container style={{ marginBottom: '6px' }}>
            <Grid item xs={12}>
              <AimIconAndTextButton
                variant="none"
                onClick={() => navigateBack()}
              >
                <ArrowBack style={{ color: theme.colors.greyScale.grey3 }} />
                <AimTypography variant="text">
                  {i18n.actions.back}
                </AimTypography>
              </AimIconAndTextButton>
              <AimTypography variant="h2">
                {fetchedData?.businessName || i18n.page.title}
              </AimTypography>
            </Grid>
          </Grid>
          <form onSubmit={handleSubmit(sendData)}>
            <SponsorDetailEditForm
              intl={intl}
              control={control}
              setValue={setValue}
              getValues={getValues}
              register={register}
              downloadFile={downloadFile}
              snackbar={snackbar}
              setSnackbar={setSnackbar}
              i18n={i18n}
              theme={theme.colors}
              deleteList={deleteList}
              setDeleteList={setDeleteList}
              watch={watch}
              errors={errors}
              fetchedData={fetchedData}
              clearErrors={clearErrors}
            />
            <Grid container spacing={3} style={{ alignItems: 'end' }}>
              <Grid item xs={12} style={{ paddingRight: 0 }}>
                <AimIconAndTextButton
                  text={i18n.actions.save}
                  type="submit"
                  variant="greenFill"
                  style={{ float: 'right', width: 200 }}
                >
                  <CheckIcon />
                </AimIconAndTextButton>
                <AimIconAndTextButton
                  text={i18n.actions.cancel}
                  variant="greyFill"
                  style={{ float: 'right', width: 200 }}
                  onClick={() => navigateBack()}
                >
                  <RefreshIcon
                    style={{
                      transform: 'rotateY(180deg)',
                    }}
                  />
                </AimIconAndTextButton>
              </Grid>
            </Grid>
          </form>
        </Container>
      </PageContainer>
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </HomeContainer>
  );
};

export default SponsorDetailEdit;
