import React from 'react';

import { styled } from '@aim/components';

const LiveNowContainer = styled('div')(() => ({
  height: 40,
  width: 140,
  backgroundColor: '#FE0C4E',
  borderRadius: 4,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const LiveNowBanner = ({ text }) => {
  return <LiveNowContainer>{text}</LiveNowContainer>;
};

export { LiveNowBanner };
