import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
// import { sortBy } from 'lodash';
import ReplayIcon from '@material-ui/icons/Replay';
import CheckIcon from '@material-ui/icons/Check';
import Grid from '@material-ui/core/Grid';

import { format, isAfter, parseISO } from 'date-fns';

import {
  AimCheckbox,
  AimIconAndTextButton,
  AimTypography,
  CustomIntl,
  ParticipationForm,
  defaultSaveData,
} from '@aim/components';
import { appState, constants, fileHelper, awsmobile, aws } from '@aim/common';

import LoginLayout from '../login/shared/LoginLayout';

import translation from './translation';
import {
  getEventDetails,
  // listParticipationsByCognitoUserId,
  getEventParticipations,
  getFile,
} from './gqlHelper';
import { setUserCognitoIdentityProviderIdentityId } from '../../shared/utilities';

const pNew = {
  givenName: '',
  familyName: '',
  email: '',
  isParticipant: false,
  isSpeaker: false,
  isReviewer: false,
};

const toSkipBaseFields = [
  'typology',
  'profile',
  'feeDateRange',
  'type',
  'isParticipant',
  'isSpeaker',
  'isReviewer',
  'isSponsorAdmin',
];

const downloadMultipleFilesFromS3 = ({
  downloadFromS3,
  privacyFiles,
  eventId,
}) => {
  privacyFiles.forEach((file) => {
    downloadFromS3({
      dirPath: `events/${eventId}/privacy`,
      fileData: file,
    });
  });
};

const RegistrationForm = () => {
  const { downloadFromS3 } = fileHelper;
  // const history = useHistory();
  const { eventId } = useParams();
  const intl = CustomIntl(useIntl());
  const i18n = translation.participationEdit(intl);
  //il cluster in questo caso sarà sempre pax
  // const [cluster] = useState(constants.Clusters.Pax.id);

  const [user] = useState(appState.user.getValue());
  const [isRegistrationClosed, setIsRegistrationClosed] = useState(false);
  const [event, setEvent] = useState();
  const [privacyFiles, setPrivacyFiles] = useState([]);
  const [isPrivacySelected, setIsPrivacySelected] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [feeDateRanges, setFeeDateRanges] = useState([]);
  const [typologies, setTypologies] = useState([]);
  const [currentParticipation, setCurrentParticipation] = useState();
  const [preTitles, setPreTitles] = useState();
  const saveDataRef = useRef();
  useEffect(() => {
    const eventInfo = appState.eventInfo.getValue();
    if (eventInfo.byInvitationOnlyFromEnabled) {
      if (isAfter(Date.now(), parseISO(eventInfo.byInvitationOnlyFromDate))) {
        setIsRegistrationClosed(true);
        return;
      }
    }
    if (eventInfo.maxParticipants) {
      getEventParticipations(eventId).then((participations) => {
        if (eventInfo.maxParticipants <= participations.length) {
          setIsRegistrationClosed(true);
          return;
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!user) return;

    const getData = async () => {
      showLoader();

      const nextParticipation = user?.userAndParticipation?.participation;
      const cluster = nextParticipation?.cluster || constants.Clusters.Pax.id;

      const evDetails = await getEventDetails(eventId, cluster, false);
      setEvent(evDetails);

      const privacyFilesJSON = evDetails.privacyFiles;
      if (privacyFiles) {
        const privacyFilesParsed = JSON.parse(privacyFilesJSON);
        const privacyFilesPromises = [];
        privacyFilesParsed?.forEach((privacyFile) => {
          privacyFilesPromises.push(getFile(privacyFile));
        });
        Promise.all(privacyFilesPromises).then((values) => {
          setPrivacyFiles(values);
        });
      }

      const nextProfiles = evDetails.profiles.items.map((p) => ({
        label: p.name + (p?.category?.name ? ` - ${p.category.name}` : ''),
        value: p.id,
      }));
      setProfiles(nextProfiles);

      const nextFeeDateRanges = evDetails?.fee?.feeDateRanges.items.map(
        ({ id, start, end, label }) => {
          const eventDateStart = format(new Date(start), 'PP');
          const eventDateEnd = format(new Date(end), 'PP');
          const nextLabel =
            eventDateStart === eventDateEnd
              ? `${label} - ${eventDateStart}`
              : `${label} - ${eventDateStart} - ${eventDateEnd}`;
          return {
            value: id,
            label: nextLabel,
          };
        }
      );
      setFeeDateRanges(nextFeeDateRanges);

      const nextTypologies = evDetails.participationTypologies.items.map(
        (p) => ({
          label: p.name,
          value: p.id,
        })
      );
      setTypologies(nextTypologies);

      const nextPreTitles = evDetails.preTitles.items
        .filter((p) => p.isActive)
        .map((p) => ({
          label: p.title,
          value: p.key,
        }));

      setPreTitles(nextPreTitles);

      let p = {
        ...(nextParticipation || pNew),
        event: { id: eventId },
        cluster,
        username: nextParticipation?.username || user.awsUser.username,
        cognitoUserId:
          nextParticipation?.cognitoUserId || user.awsUser.attributes.sub,
        email: nextParticipation?.email || user.awsUser.attributes.email,
        isParticipant: true,
        status: 'registered',
      };
      setCurrentParticipation(p);
      hideLoader();
    };

    getData();
  }, [user]);

  const showLoader = () => appState.isLoader.next(true);

  const hideLoader = () => appState.isLoader.next(false);

  const sendData = async (submittedData, dirtyFields) => {
    showLoader();
    const nextDirtyFields = {
      ...dirtyFields,
      baseDefaultValues: { ...dirtyFields.baseDefaultValues, status: true },
    };
    const createdParticipation = await defaultSaveData({
      participation: {
        ...currentParticipation,
        isInvited: true,
        isFirstAccessCompleted: true,
        // status: 'registered',
      },
      submittedData,
      dirtyFields: nextDirtyFields,
    });

    await setUserCognitoIdentityProviderIdentityId(
      createdParticipation.id,
      appState.user.getValue().awsUser.storage[
        `CognitoIdentityId-${awsmobile.aws_cognito_identity_pool_id}`
      ]
    );

    window.location.reload();
    hideLoader();
  };

  const handleSave = () => {
    if (!saveDataRef.current) return;
    saveDataRef.current.click();
  };

  // paolo:
  // se cliccato prima che la form sia caricata a volte non funziona correttamente
  // problema con l'asincronia dei dynamic fields
  // const recoveryUserInfoFromLastEvent = async () => {
  //   const participations = await listParticipationsByCognitoUserId(
  //     currentParticipation.cognitoUserId
  //     // 'beuyutoimete-8944@yopmail.com' // for test with impersonate
  //   );
  //   const recoveryUserInfo = sortBy(participations, [
  //     'createdAt',
  //   ]).reverse()?.[0];
  //   setCurrentParticipation({ ...currentParticipation, ...recoveryUserInfo });
  //   // setRecoveryUserInfo(recoveryUserInfo);
  // };
  const handleLogout = async () => {
    await aws.signOutFromWebsite(eventId);
  };

  return (
    <LoginLayout title={i18n.registrationForm.title} width="70%">
      <Grid container>
        {!user?.awsUser?.isBackofficeUser &&
          isRegistrationClosed &&
          currentParticipation &&
          !currentParticipation?.id && (
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <AimTypography variant="text">
                {i18n.registrationForm.registrationClosedSubtitle}
              </AimTypography>
              <AimTypography
                variant="textBold"
                onClick={handleLogout}
                style={{ cursor: 'pointer' }}
              >
                {i18n.registrationForm.clickHereToLogout}
              </AimTypography>
            </Grid>
          )}
        {user?.awsUser?.isBackofficeUser &&
        currentParticipation &&
        !currentParticipation?.id ? (
          <>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <AimTypography variant="text">
                {i18n.registrationForm.backofficeUserBlocked}
              </AimTypography>
            </Grid>
          </>
        ) : null}
        {currentParticipation?.id || !isRegistrationClosed ? (
          <>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <AimTypography variant="text">
                {i18n.registrationForm.subtitle}
              </AimTypography>
            </Grid>
            {/* <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              style={{ display: 'flex', justifyContent: 'right' }}
            >
              <AimTypography variant="text">
                {i18n.registrationForm.recoveryPersonalDetails.description}
              </AimTypography>
              <Tooltip
                title={i18n.registrationForm.recoveryPersonalDetails.tooltip}
              >
                <AimIconAndTextButton
                  small
                  // variant="secondary"
                  text={
                    i18n.registrationForm.recoveryPersonalDetails.buttonLabel
                  }
                  onClick={() => recoveryUserInfoFromLastEvent()}
                  disabled={!currentParticipation}
                ></AimIconAndTextButton>
              </Tooltip>
            </Grid> */}
          </>
        ) : null}
      </Grid>

      {user &&
      profiles &&
      currentParticipation &&
      (currentParticipation.id ||
        (!isRegistrationClosed && !user?.awsUser?.isBackofficeUser)) ? (
        <div
          style={{
            height: '50vh',
            overflowY: 'auto',
            overflowX: 'hidden',
            // paddingRight: '20px',
          }}
        >
          <ParticipationForm
            {...{
              intl,
              participation: currentParticipation,
              profiles,
              typologies,
              preTitles,
              feeDateRanges,
              refButton: saveDataRef,
              onSaveDataFn: sendData,
              showAdminFields: false,
              showHiddenFields: false,
              toSkipBaseFieldsIds: toSkipBaseFields,
              requiredBaseFields: ['email'],
              toHideBaseFields: ['status'],
              showBlocks: true,
              // isReadOnly: true,
            }}
          />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <AimTypography variant="h5">
              <>
                <span style={{ fontWeight: 'normal' }}>
                  {i18n.registrationForm.privacyText}{' '}
                </span>
                <span
                  style={{ cursor: 'pointer', fontWeight: 'bold' }}
                  onClick={
                    () =>
                      downloadMultipleFilesFromS3({
                        downloadFromS3,
                        privacyFiles,
                        eventId: event.id,
                      })
                    // downloadFromS3({
                    //   dirPath: `events/${event.id}/privacy`,
                    //   fileData: event.privacyFile,
                    // })
                  }
                >
                  {i18n.registrationForm.privacyPolicy}
                </span>
                <span style={{ fontWeight: 'normal' }}> (*) </span>
              </>
            </AimTypography>
            <AimCheckbox
              variant="grey"
              name="privacy"
              onChange={() => setIsPrivacySelected(!isPrivacySelected)}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'flex-end',
              marginTop: 20,
            }}
          >
            <AimIconAndTextButton
              variant="secondary"
              text={i18n.actions.cancel}
              // onClick={() =>
              //   history.push(getBackUrl(currentParticipation, eventId))
              // }
            >
              <ReplayIcon />
            </AimIconAndTextButton>
            <AimIconAndTextButton
              variant="primary"
              text={i18n.actions.save}
              onClick={handleSave}
              disabled={!isPrivacySelected}
            >
              <CheckIcon />
            </AimIconAndTextButton>
          </div>
        </div>
      ) : null}
    </LoginLayout>
  );
};

export default RegistrationForm;
