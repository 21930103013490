/* Signup page */
import React, { useState } from 'react';
import { aws, appState, utilities } from '@aim/common';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Grid from '@material-ui/core/Grid';
// import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {
  AimTextField,
  AimTypography,
  AimIconAndTextButton,
  AimSnackbar,
  theme,
  CustomIntl,
} from '@aim/components';
import { translations } from './shared/translations';
import { useIntl } from 'react-intl';

import LoginLayout from './shared/LoginLayout';

const { isLoader } = appState;

const showLoader = () => {
  isLoader.next(true);
};

const hideLoader = () => {
  isLoader.next(false);
};

const SignUp = () => {
  const intl = CustomIntl(useIntl());
  const i18n = translations.signUp(intl);
  const { eventId } = useParams();
  const [errorMessage, setErrorMessage] = useState();
  const [userExistsError, setUserExistsError] = useState();
  const [snackbar, setSnackbar] = useState({ isOpen: false });

  const history = useHistory();

  const validationSchema = {
    email: yup.string().required(i18n.emailRequired),
    password: yup.string().required(i18n.passwordRequired),
    passwordConfirm: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
  };

  const { register, handleSubmit, errors } = useForm({
    reValidateMode: 'onChange',
    mode: 'onChange',
    shouldUnregister: false,
    defaultValues: {},
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });

  const formControlStyle = { width: 'calc(100% - 10px)' };

  async function signup(submittedData) {
    setErrorMessage();
    setUserExistsError();
    showLoader();
    try {
      const eventCode = appState.eventInfo.getValue().code;
      if (!eventCode) return;
      const participationSequence = await utilities.getNextSequenceValue({
        eventCode,
        // eventId,
        sequenceName: 'participationSequence',
      });

      const username = `${eventCode}-${participationSequence}`;

      let result = await aws.Auth.signUp({
        username: username,

        password: submittedData.password,
        attributes: {
          email: submittedData.email.trim().toLowerCase(),
          'custom:type': 'customer',
          'custom:eventId': eventId,
        },
      });

      if (!result.userConfirmed) {
        history.push(`/events/${eventId}/verify?username=${username}`);
      } else {
        await aws.Auth.signIn(
          submittedData.email.trim().toLowerCase(),
          submittedData.password
        );
      }
    } catch (ex) {
      console.error(ex);
      if (ex.code === 'UsernameExistsException') {
        setUserExistsError(true);
      } else setErrorMessage(ex.message);
    }
    hideLoader();
  }

  const goToLogin = () => {
    history.push(`/events/${eventId}/login`);
  };

  const goToForgotPassword = () => {
    history.push(`/events/${eventId}/forgotpassword`);
  };

  return (
    <LoginLayout title={'Sign Up'}>
      <AimTypography>{i18n.subtitle}</AimTypography>
      <form onSubmit={handleSubmit(signup)}>
        <Grid container>
          <Grid item xs={12}>
            <AimTextField
              label={i18n.email}
              inputRef={register}
              textfieldVariant="grey"
              name="email"
              placeholder={i18n.email}
              formControlStyle={formControlStyle}
            />
            {errors['email'] && (
              <AimTypography variant="formError">
                *{errors['email'].message}
              </AimTypography>
            )}
          </Grid>
          <Grid item xs={12}>
            <AimTextField
              label={i18n.password}
              inputRef={register}
              textfieldVariant="grey"
              name="password"
              placeholder={i18n.password}
              type="password"
              formControlStyle={formControlStyle}
            />
            {errors['password'] && (
              <AimTypography variant="formError">
                *{errors['password'].message}
              </AimTypography>
            )}{' '}
          </Grid>
          <Grid item xs={12}>
            <AimTextField
              label={i18n.repeatpassword}
              inputRef={register}
              textfieldVariant="grey"
              name="passwordConfirm"
              placeholder={i18n.repeatpassword}
              type="password"
              formControlStyle={formControlStyle}
            />
            {errors['passwordConfirm'] && (
              <AimTypography variant="formError">
                *{errors['passwordConfirm'].message}
              </AimTypography>
            )}
          </Grid>
          <Grid item xs={12}>
            {errorMessage && (
              <AimTypography variant="formError">
                {errorMessage ? (
                  <p style={{ color: 'red' }}>{errorMessage}</p>
                ) : null}
              </AimTypography>
            )}
          </Grid>
          <AimTypography>
            {i18n.alreadyRegistered}
            <b style={{ cursor: 'pointer' }} onClick={goToLogin}>
              {` ${i18n.login} `}
            </b>
          </AimTypography>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              margin: '20px 14px 0px',
            }}
          >
            <AimIconAndTextButton
              text={i18n.confirm}
              type="submit"
              isUpperCase
              style={{ width: '100%' }}
            >
              {/* <ArrowForwardIcon /> */}
            </AimIconAndTextButton>
          </Grid>
          {userExistsError && (
            <Grid item xs={12} style={{ padding: '8px 4px 0 8px' }}>
              <div
                style={{
                  width: '100%',
                  padding: '10px',
                  backgroundColor: theme.colors.light.red,
                }}
              >
                <AimTypography>
                  {i18n.userAlreadyExists}
                  <br />
                  <b style={{ cursor: 'pointer' }} onClick={goToLogin}>
                    {` ${i18n.login} `}
                  </b>
                  {` ${i18n.or} `}
                  <b style={{ cursor: 'pointer' }} onClick={goToForgotPassword}>
                    {i18n.resetPassword}
                  </b>
                  {` ${i18n.ifDontRemember} `}
                </AimTypography>
              </div>
            </Grid>
          )}
        </Grid>
      </form>
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </LoginLayout>
  );
};

export default SignUp;
