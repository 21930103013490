import React, { useState, useEffect } from 'react';
import { sortBy } from 'lodash';

import { useHistory, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import ArrowBack from '@material-ui/icons/ArrowBack';
import SponsorFrontServiceCard from './sponsor/components/SponsorFrontServiceCard';
import SelectableList from './sponsor/components/SelectableList';
import { constants, appState } from '@aim/common';

import { useSponsors } from './shared/sponsorGqlHelper';
import { listSponsorPackages } from './myServices/sponsorPackages/sponsorPackagesGqlHelper';

import {
  AimIconAndTextButton,
  AimTypography,
  CustomIntl,
} from '@aim/components';
import ServiceBuyCardContent from './sponsor/components/ServiceBuyCardContent';
import { useWallCards } from './sponsorServiceConfigurationWall/cards';
import { MainContainer, InnerContainer } from './shared/SponsorPagesContainers';

import translation from './shared/translation';
import { useDataHelper } from './shared/dataHelper';
import { utcToZonedTime } from 'date-fns-tz';
import { isAfter, isEqual } from 'date-fns';

const MyServices = () => {
  //Hooks
  const history = useHistory();
  const { eventId, sponsorId } = useParams();
  const { get, getDeadline } = useSponsors();
  const intl = CustomIntl(useIntl());
  const i18n = translation.myServices(intl);
  const wallCards = useWallCards(i18n.cards, eventId, sponsorId);
  const { isValidPurchase } = useDataHelper();
  //States
  const [sponsorServices] = useState(constants.SponsorServices);
  const [sponsor, setSponsor] = useState();
  // const [deadlines, setDeadlines] = useState();
  const [packages, setPackages] = useState([]);
  const currentTimezone = appState.eventInfo.getValue().timezone;

  //Effects
  useEffect(() => {
    // getSponsorDeadline();
    getSponsor();
    listPackages();
  }, []);

  const listPackages = async () => {
    const { packages, deactivationDate } = await listSponsorPackages(eventId);
    const nextPackages = packages.map((item) => ({
      ...item,
      deactivationDate,
    }));
    setPackages(nextPackages);
  };

  //Functions
  const ItemList = ({ title, link, id, isPurchaseDisabled }) => {
    const priceRangeDate = new Date(sponsor?.priceRange?.start);
    let isBuyDisabled = priceRangeDate > new Date();
    let customTooltip = null;

    const orders = sponsor.orders;
    const bookings = sponsor.bookings;

    let nOrder = 0;
    let nBooking = 0;
    let configurationLink = '';
    let alwaysEnableConfig = false;

    switch (link) {
      case 'symposium':
        nOrder =
          orders.filter(
            (i) =>
              i.symposium ||
              i?.sponsorPackage?.services?.items?.find(
                (service) => service.symposium
              )
          ).length || 0;
        nBooking =
          bookings.filter(
            (i) =>
              i.symposium ||
              i?.sponsorPackage?.services?.items?.find(
                (service) => service.symposium
              )
          ).length || 0;

        // TODO insert link configuration
        if (nOrder > 0) {
          configurationLink = '';
        }
        break;
      case 'sponsor-banner':
        nOrder =
          orders.filter(
            (i) =>
              i.bannerArea ||
              i?.sponsorPackage?.services?.items?.find(
                (service) => service.bannerArea
              )
          ).length || 0;
        nBooking =
          bookings.filter(
            (i) =>
              i.bannerArea ||
              i?.sponsorPackage?.services?.items?.find(
                (service) => service.bannerArea
              )
          ).length || 0;
        configurationLink = '';
        break;
      case 'public-page':
        nOrder =
          orders.filter(
            (i) =>
              i.publicPage ||
              i?.sponsorPackage?.services?.items?.find(
                (service) => service.publicPageService
              )
          ).length || 0;
        nBooking =
          bookings.filter(
            (i) =>
              i.publicPage ||
              i?.sponsorPackage?.services?.items?.find(
                (service) => service.publicPageService
              )
          ).length || 0;
        configurationLink = `/events/${eventId}/sponsors/${sponsorId}`;

        if (!isNaN(nOrder) && nOrder > 0) {
          isBuyDisabled = true;
        }

        break;
      case 'breakout-room':
        nOrder =
          orders.filter(
            (i) =>
              i.breakoutRoom ||
              i?.sponsorPackage?.services?.items?.find(
                (service) => service.breakoutRoomService
              )
          ).length || 0;
        nBooking =
          bookings.filter(
            (i) =>
              i.breakoutRoom ||
              i?.sponsorPackage?.services?.items?.find(
                (service) => service.breakoutRoomService
              )
          ).length || 0;
        configurationLink = `/events/${eventId}/${sponsorId}/services-configuration/breakoutrooms`;

        if (
          !orders.filter(
            (i) =>
              i.publicPage ||
              i?.sponsorPackage?.services?.items?.find(
                (service) => service.publicPageService
              )
          ).length ||
          !sponsor?.event?.publicPageService?.id
        ) {
          customTooltip = i18n.serviceList.needToBuyPublicPageMessage.label;
          isBuyDisabled = true;
        }

        break;
      case 'physical-stand':
        nOrder =
          orders.filter(
            (i) =>
              i.physicalStandSpace ||
              i?.sponsorPackage?.services?.items?.find(
                (service) => service.physicalStand
              )
          ).length || 0;
        nBooking =
          bookings.filter(
            (i) =>
              i.physicalStandSpace ||
              i?.sponsorPackage?.services?.items?.find(
                (service) => service.physicalStand
              )
          ).length || 0;
        // configurationLink = `/events/${eventId}/${sponsorId}/services-configuration/physical-stands`;
        break;
      case 'virtual-stand':
        nOrder =
          orders.filter(
            (i) =>
              i.sponsorVirtualStand ||
              i?.sponsorPackage?.services?.items?.find(
                (service) => service.virtualStand
              )
          ).length || 0;
        nBooking =
          bookings.filter(
            (i) =>
              i.sponsorVirtualStand ||
              i?.sponsorPackage?.services?.items?.find(
                (service) => service.virtualStand
              )
          ).length || 0;
        configurationLink = `/events/${eventId}/${sponsorId}/services-configuration/virtual-stands`;

        if (
          orders.filter(
            (i) =>
              i.publicPage ||
              i?.sponsorPackage?.services?.items?.find(
                (service) => service.publicPageService
              )
          ).length ||
          !sponsor?.event?.publicPageService?.id
        ) {
          customTooltip = i18n.serviceList.needToBuyPublicPageMessage.label;
          isBuyDisabled = true;
        }

        break;
      case 'other-services': {
        nOrder = orders.filter((i) => i.physicalStandSpaceItems).length || 0;
        nBooking =
          bookings.filter((i) => i.physicalStandSpaceItems).length || 0;
        const nOrderPhysicalStand =
          orders.filter(
            (i) =>
              i.physicalStandSpace ||
              i?.sponsorPackage?.services?.items?.find(
                (service) => service.physicalStand
              )
          ).length || 0;
        if (nOrderPhysicalStand === 0) {
          isBuyDisabled = true;
        }
        break;
      }
      case 'sponsor-staff':
        nOrder = orders.filter((i) => i.sponsorStaffPurchase).length || 0;
        nBooking = bookings.filter((i) => i.sponsorStaffPurchase).length || 0;
        configurationLink = `/events/${eventId}/${sponsorId}/services-configuration/staff`;
        alwaysEnableConfig = true;
        break;
      case 'sponsor-list':
        nOrder = orders.filter((i) => i.sponsorListPurchase).length || 0;
        nBooking = bookings.filter((i) => i.sponsorListPurchase).length || 0;
        configurationLink = `/events/${eventId}/${sponsorId}/services-configuration/list`;
        alwaysEnableConfig = true;
        break;
      case 'symposium-services': {
        nOrder =
          orders.filter(
            (i) =>
              i.symposiumServicesPurchase ||
              i?.sponsorPackage?.services?.items?.find(
                (service) => service.symposiumServicesPurchase
              )
          ).length || 0;
        const nOrdersSymposium =
          orders.filter(
            (i) =>
              i.symposium ||
              i?.sponsorPackage?.services?.items?.find(
                (service) => service.symposium
              )
          ).length || 0;
        const nBookingsSymposium =
          bookings.filter(
            (i) =>
              i.symposium ||
              i?.sponsorPackage?.services?.items?.find(
                (service) => service.symposium
              )
          ).length || 0;
        nBooking =
          bookings.filter(
            (i) =>
              i.symposiumServicesPurchase ||
              i?.sponsorPackage?.services?.items?.find(
                (service) => service.symposiumServicesPurchase
              )
          ).length || 0;

        console.log('nOrdersSymposium', nOrdersSymposium);
        console.log('nBookingsSymposium', nBookingsSymposium);
        if (nOrdersSymposium === 0 && nBookingsSymposium === 0) {
          isBuyDisabled = true;
        }
        break;
      }
      case 'other-sponsorizations':
        nOrder =
          orders.filter(
            (i) =>
              i.otherSponsorizationsBookingItem ||
              i?.sponsorPackage?.services?.items?.find(
                (service) => service.otherSponsorizationsBookingItem
              )
          ).length || 0;
        nBooking =
          bookings.filter(
            (i) =>
              i.otherSponsorizationsBookingItem ||
              i?.sponsorPackage?.services?.items?.find(
                (service) => service.otherSponsorizationsBookingItem
              )
          ).length || 0;
        break;
      case link.match(/sponsor-packages\//)?.input:
        nOrder = orders.filter((i) => i?.sponsorPackage?.id === id).length || 0;
        nBooking =
          bookings.filter((i) => i?.sponsorPackage?.id === id).length || 0;
        break;
      default:
        break;
    }

    const nextTitle = typeof title === 'function' ? title(intl) : title;
    // console.log(`${nextTitle}: is disabled: ${isPurchaseDisabled}`);
    const isAllBuyButtonsDisabled = checkTodayIsEqualOrIsAfterDate(
      sponsor.event?.sponsorDeactivationDate
    );

    const nextBuyDisabled =
      isAllBuyButtonsDisabled || isBuyDisabled || isPurchaseDisabled;

    if (nextBuyDisabled) {
      customTooltip = i18n.serviceList.buyButtonDisabledMessage.label;
    }

    return (
      <SponsorFrontServiceCard
        {...{
          title: nextTitle,
          onClickBuy: () => {
            onClickBuy(link);
          },
          buyDisabled: nextBuyDisabled,
          onClickConfiguration: () => {
            onClickConfiguration(configurationLink);
          },
          configurationVisible: configurationLink !== '',
          configurationDisabled: alwaysEnableConfig ? false : nOrder === 0,
          ...(customTooltip && { customTooltip }),
        }}
      >
        <ServiceBuyCardContent
          i18n={i18n.serviceList.card}
          nOrder={nOrder}
          nBooking={nBooking}
        />
      </SponsorFrontServiceCard>
    );
  };

  // const getSponsorDeadline = async () => {
  //   const deadlineSponsorStaff = await getDeadline(eventId, 'sponsorstaff');
  //   const nextDeadlineSponsorStaff = JSON.parse(deadlineSponsorStaff.value);

  //   setDeadlines([
  //     { key: 'sponsorstaff', value: nextDeadlineSponsorStaff.deadline },
  //   ]);
  // };

  const getSponsor = async () => {
    const nextSponsor = await get(sponsorId);
    const filteredSponsor = {
      ...nextSponsor,
      orders: nextSponsor?.buyOperations.items.filter((op) =>
        isValidPurchase(op.purchase)
      ),
      bookings: nextSponsor?.buyOperations.items.filter(
        (op) =>
          !op.purchase && op.isExpired !== 'true' && op.isDeleted !== 'true'
      ),
    };
    setSponsor(filteredSponsor);
  };

  const onClickBuy = (link) => {
    history.push(`/events/${eventId}/${sponsorId}/my-services/${link}`);
  };
  const onClickConfiguration = (link) => {
    history.push(link);
  };

  const isServiceOnline = (link) => {
    const serviceDate = checkServiceDate(link);

    return (
      serviceDate &&
      (isAfter(new Date(), utcToZonedTime(serviceDate, currentTimezone)) ||
        isEqual(new Date(), utcToZonedTime(serviceDate, currentTimezone)))
    );
  };

  const isServiceOnlineDate = (date) => {
    return (
      date &&
      (isAfter(new Date(), utcToZonedTime(date, currentTimezone)) ||
        isEqual(new Date(), utcToZonedTime(date, currentTimezone)))
    );
  };

  const checkTodayIsEqualOrIsAfterDate = (date) => {
    return (
      date &&
      (isAfter(new Date(), utcToZonedTime(date, currentTimezone)) ||
        isEqual(new Date(), utcToZonedTime(date, currentTimezone)))
    );
  };

  const checkTodayIsAfterDeadline = (deadlineDate) => {
    if (!deadlineDate) return true;
    // add a day so if deadline date is today return true (button is active)
    var nextDate = new Date(deadlineDate);
    nextDate.setDate(nextDate.getDate() + 1);

    // check TODO: in SponsorStaffDetail was used parseISO and also if deadline date is today is disabled, is it correct?
    return isAfter(new Date(), utcToZonedTime(nextDate, currentTimezone));
  };

  const checkPurchaseDisabled = (link) => {
    switch (link) {
      case 'symposium':
        return checkTodayIsEqualOrIsAfterDate(
          sponsor?.event?.symposiumService?.deactivationDate
        );
      case 'sponsor-banner':
        return checkTodayIsEqualOrIsAfterDate(
          sponsor?.event?.bannerService?.deactivationDate
        );
      case 'public-page':
        return checkTodayIsEqualOrIsAfterDate(
          sponsor?.event?.publicPageService?.deactivationDate
        );
      case 'breakout-room':
        return checkTodayIsEqualOrIsAfterDate(
          sponsor?.event?.breakoutRoomService?.deactivationDate
        );
      case 'other-services':
        return checkTodayIsEqualOrIsAfterDate(
          sponsor?.event?.otherStandServicesService?.deactivationDate
        );
      case 'physical-stand':
        return checkTodayIsEqualOrIsAfterDate(
          sponsor?.event?.physicalStandService?.deactivationDate
        );
      case 'virtual-stand':
        return checkTodayIsEqualOrIsAfterDate(
          sponsor?.event?.virtualStandService?.deactivationDate
        );
      case 'sponsor-staff':
        return (
          checkTodayIsEqualOrIsAfterDate(
            sponsor?.event?.sponsorStaffService?.deactivationDate
          ) || sponsor.sponsorStaff?.closingDate
          // checkTodayIsAfterDeadline(
          //   deadlines?.find((x) => x.key === 'sponsorstaff').value
          // )
        );

      case 'sponsor-list':
        return checkTodayIsEqualOrIsAfterDate(
          sponsor?.event?.sponsorListService?.deactivationDate
        );
      case 'symposium-services':
        return checkTodayIsEqualOrIsAfterDate(
          sponsor?.event?.symposiumServicesService?.deactivationDate
        );
      case 'other-sponsorizations':
        return checkTodayIsEqualOrIsAfterDate(
          sponsor?.event?.otherSponsorizationsService?.deactivationDate
        );
      default:
        break;
    }
  };

  const checkServiceDate = (link) => {
    switch (link) {
      case 'symposium':
        return sponsor?.event?.symposiumService?.publicationDate || '';
      case 'sponsor-banner':
        return sponsor?.event?.bannerService?.publicationDate || '';
      case 'public-page':
        return sponsor?.event?.publicPageService?.publicationDate || '';
      case 'breakout-room':
        return sponsor?.event?.breakoutRoomService?.publicationDate || '';
      case 'other-services':
        return sponsor?.event?.otherStandServicesService?.publicationDate;
      case 'physical-stand':
        return sponsor?.event?.physicalStandService?.publicationDate || '';
      case 'virtual-stand':
        return sponsor?.event?.virtualStandService?.publicationDate || '';
      case 'sponsor-staff':
        return sponsor?.event?.sponsorStaffService?.publicationDate || '';
      case 'sponsor-list':
        return sponsor?.event?.sponsorListService?.publicationDate || '';
      case 'symposium-services':
        return sponsor?.event?.symposiumServicesService?.publicationDate || '';
      case 'other-sponsorizations':
        return (
          sponsor?.event?.otherSponsorizationsService?.publicationDate || ''
        );
      default:
        break;
    }
  };

  const renderServiceCard = (groupService) => {
    const value = sponsorServices[groupService];
    const serviceItems = value.items
      .filter((i) => !i.hideFrontoffice)
      .sort((a, b) => (a.order > b.order ? 1 : -1))
      .map((item) => {
        return {
          ...item,
          isOnline: isServiceOnline(item.link),
          isPurchaseDisabled: checkPurchaseDisabled(item.link),
        };
      });

    const myServices = serviceItems.filter((s) => s.isOnline);
    const nextMyServices = sortBy(myServices, ['order']);

    return nextMyServices.length ? (
      <div style={{ display: 'flex' }} key={groupService}>
        <SelectableList
          items={nextMyServices}
          Component={ItemList}
          pageTitle={
            <AimTypography variant="h6">
              {sponsorServices[groupService].label(intl)}
            </AimTypography>
          }
        />
      </div>
    ) : null;
  };

  const renderPackagesCard = () => {
    const packagesItems = packages.map((item) => {
      return {
        ...item,
        title: item.name,
        link: `sponsor-packages/${item.id}`,
        isPurchaseDisabled: checkTodayIsEqualOrIsAfterDate(
          item.deactivationDate
        ),
      };
    });
    const myPackages = packagesItems.filter(
      (p) =>
        // p.isEnabled &&
        isServiceOnlineDate(p.publicationDate) &&
        p.quantity >
          p.buyOperations.items.filter((op) => isValidPurchase(op.purchase))
            .length
    );

    return myPackages?.length ? (
      <div style={{ display: 'flex' }} key="packages">
        <SelectableList
          items={myPackages}
          Component={ItemList}
          pageTitle={
            <AimTypography variant="h6">
              {i18n.serviceList.packages}
            </AimTypography>
          }
        />
      </div>
    ) : null;
  };

  return (
    <MainContainer>
      <InnerContainer grow bottomSpacing>
        <div style={{ display: 'flex-root', marginTop: 20 }}>
          <AimIconAndTextButton
            variant="text"
            style={{ paddingLeft: 0 }}
            text={i18n.page.back.button.label}
            onClick={() => history.push(`/events/${eventId}/landing`)}
          >
            <ArrowBack />
          </AimIconAndTextButton>

          <AimTypography variant="h1">{i18n.page.title}</AimTypography>
        </div>
        <div
          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        >
          {sponsor && (
            <>
              {Object.keys(sponsorServices).map((groupService) => {
                return renderServiceCard(groupService);
              })}
              {renderPackagesCard()}
            </>
          )}
        </div>

        {/* <div
        style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}
      >
        <AimIconAndTextButton
          style={{
            paddingLeft: 30,
            paddingRight: 30,
          }}
          variant="yellow"
          text={i18n.page.forwardButton.label}
          onClick={() => console.log('save')}
        ></AimIconAndTextButton>
      </div> */}
      </InnerContainer>
    </MainContainer>
  );
};

export default MyServices;
