import { aws } from '@aim/common';
import { fileSchema } from './fileHelper';
import { useLoaderHelper } from './loaderHelper';
const { API } = aws;

const userShowcaseSchema = `
  id
  address
  birthDate
  contactEmail
  coverImage {
    ${fileSchema}
  }
  profileImage {
    ${fileSchema}
  }
  nationality
  phone
  workJob  
  companyName
  businessPhone
  description
  instagram
  facebook
  twitter
  linkedin
`;

const createUserShowcaseQuery = /* GraphQL */ `
  mutation CreateUserShowcase(
    $input: CreateUserShowcaseInput!
    $condition: ModelUserShowcaseConditionInput
  ) {
    createUserShowcase(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateUserShowcaseQuery = /* GraphQL */ `
  mutation UpdateUserShowcase(
    $input: UpdateUserShowcaseInput!
    $condition: ModelUserShowcaseConditionInput
  ) {
    updateUserShowcase(input: $input, condition: $condition) {
      id
    }
  }
`;

const deleteUserShowcaseQuery = /* GraphQL */ `
  mutation DeleteUserShowcase(
    $input: DeleteUserShowcaseInput!
    $condition: ModelUserShowcaseConditionInput
  ) {
    deleteUserShowcase(input: $input, condition: $condition) {
      id
    }
  }
`;

const getUserShowcaseQuery = /* GraphQL */ `
  query getUserShowcase($id: ID!) {
    getParticipation( id: $id ) {
      userShowcase {
        ${userShowcaseSchema}
      }
    }
  }
`;

/**handle participation update */
const updateParticipationQuery = /* GraphQL */ `
  mutation UpdateParticipation(
    $input: UpdateParticipationInput!
    $condition: ModelParticipationConditionInput
  ) {
    updateParticipation(input: $input, condition: $condition) {
      id
    }
  }
`;

export const useUserShowcaseHelper = (useLoader) => {
  const { showLoader, hideLoader } = useLoaderHelper();
  const get = (participationId) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();
      API.graphql({
        query: getUserShowcaseQuery,
        variables: { id: participationId },
      })
        .then((response) => {
          resolve(response.data.getParticipation.userShowcase);
        })
        .catch((e) => {
          console.error('get-userShowcaseHelper-error', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const create = (input) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: createUserShowcaseQuery,
        variables: { input },
      })
        .then((response) => {
          //update participation
          aws.API.graphql({
            query: updateParticipationQuery,
            variables: {
              input: {
                id: input.participationId,
                participationUserShowcaseId:
                  response.data.createUserShowcase.id,
              },
            },
          }).then((participationResponse) => {
            resolve(response.data.createUserShowcase);
          });
        })
        .catch((e) => {
          console.error('create-userShowcaseHelper-error', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const remove = (id) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: deleteUserShowcaseQuery,
        variables: { input: { id: id } },
      })
        .then((response) => resolve(response.data.deleteUserShowcase))
        .catch((e) => {
          console.error('delete-userShowcaseHelper-error', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const update = (input) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: updateUserShowcaseQuery,
        variables: { input },
      })
        .then((response) => resolve(response.data.updateUserShowcase))
        .catch((e) => {
          console.error('update-userShowcaseHelper-error', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  return {
    create,
    remove,
    update,
    get,
  };
};
