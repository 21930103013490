export default {
  TravelTransfer: (intl) => ({
    page: {
      save: intl.formatMessage({
        description: 'travel transfer / Save',
        defaultMessage: 'Save',
      }),
      saveDraft: intl.formatMessage({
        description: 'travel transfer / Save draft',
        defaultMessage: 'Save draft',
      }),
      cancel: intl.formatMessage({
        description: 'travel transfer / Cancel',
        defaultMessage: 'Cancel',
      }),
      selectedParticipants: intl.formatMessage({
        description: 'travel transfer / selected participants',
        defaultMessage: 'N° of selected participants: ',
      }),
      backButton: {
        label: intl.formatMessage({
          description: 'travel transfer / back',
          defaultMessage: 'Back',
        }),
        tooltip: intl.formatMessage({
          description: 'travel transfer / back',
          defaultMessage: 'Back',
        }),
      },
      name: intl.formatMessage({
        description: 'travel transfer Name',
        defaultMessage: 'Name',
      }),
      surname: intl.formatMessage({
        description: 'travel transfer / Surname',
        defaultMessage: 'Surname',
      }),
      email: intl.formatMessage({
        description: 'travel transfer / Email user',
        defaultMessage: 'Email user',
      }),
      authBooking: intl.formatMessage({
        description:
          'travel transfer / I authorise AIM Travel to proceed with the booking of my Services',
        defaultMessage:
          'I authorise AIM Travel to proceed with the booking of my Services',
      }),
      useAgencyToBook: intl.formatMessage({
        description: 'I use an agency to book the Services*',
        defaultMessage: 'I use an agency to book the Services*',
      }),
      autonomousBooking: intl.formatMessage({
        description:
          'travel transfer / I prefer to make my own booking and purchase and request a refund afterwards',
        defaultMessage:
          'I prefer to make my own booking and purchase and request a refund afterwards',
      }),
      pleaseUploadYourIdNum: intl.formatMessage({
        description:
          'travel transfer / please upload your identification document below to present at the airport',
        defaultMessage:
          'Please upload your identification document below to present at the airport',
      }),
      pleaseUploadYourIdNumDescr: intl.formatMessage({
        description: 'travel transfer / max 200KB',
        defaultMessage: 'Max 200KB',
      }),
      dragAndDrop: {
        title: intl.formatMessage({
          description: 'travel transfer / drag and drop title',
          defaultMessage: 'drag & drop your files',
        }),
        subTitle: intl.formatMessage({
          description: 'travel transfer / drag and drop subtitle',
          defaultMessage: 'or',
        }),
        clickText: intl.formatMessage({
          description: 'travel transfer / drag and drop click text',
          defaultMessage: 'select from your pc',
        }),
        fileLimitText: intl.formatMessage({
          description: 'travel transfer / drag and drop file limit text',
          defaultMessage: 'Limit of file reached',
        }),
      },
      selectTheContentToUpload: intl.formatMessage({
        description: 'travel transfer / select content to upload',
        defaultMessage: 'Select content to upload',
      }),
      uploadNote1: intl.formatMessage({
        description:
          'travel transfer / *The options does not include a subsequent request for reimbursement of exepenses to AIM.',
        defaultMessage:
          '*The options does not include a subsequent request for reimbursement of exepenses to AIM.',
      }),
      uploadNote2: intl.formatMessage({
        description:
          'travel transfer / **If your request is different from what is included in the ospitality, you will be contacted.',
        defaultMessage:
          '**If your request is different from what is included in the ospitality, you will be contacted.',
      }),
      generalInfo: {
        title: intl.formatMessage({
          description: 'travel transfer / GENERAL INFO',
          defaultMessage: 'GENERAL INFO',
        }),
        travel: intl.formatMessage({
          description: 'travel transfer / travel included',
          defaultMessage: 'Travel Included',
        }),
        accompanying: intl.formatMessage({
          description: 'travel transfer / accompanying person',
          defaultMessage: 'Accompanying person',
        }),
        businessUnit: intl.formatMessage({
          description: 'travel transfer / business unit label',
          defaultMessage: 'Business unit',
        }),
        orderCode: intl.formatMessage({
          description: 'travel transfer / order code label',
          defaultMessage: 'Order code',
        }),
        transfer: intl.formatMessage({
          description: 'travel transfer / transfer included',
          defaultMessage: 'Transfer Included',
        }),
        yes: intl.formatMessage({
          description: 'travel transfer / yes',
          defaultMessage: 'yes',
        }),
        no: intl.formatMessage({
          description: 'travel transfer / no',
          defaultMessage: 'no',
        }),
        travelPreferences: intl.formatMessage({
          description: 'travel transfer / travel preferences',
          defaultMessage: 'Travel Preferences',
        }),
      },
      travelsTable: {
        table: {
          title: intl.formatMessage({
            description: 'travel transfer / title label',
            defaultMessage: 'ASSIGNED TRAVEL',
          }),
          columns: {
            travelType: intl.formatMessage({
              description: 'travel transfer / travel type label',
              defaultMessage: 'Type of travel',
            }),
            code: intl.formatMessage({
              description: 'travel transfer / code label',
              defaultMessage: 'Code travel',
            }),
            start: intl.formatMessage({
              description: 'travel transfer / start label',
              defaultMessage: 'Start',
            }),
            startTime: intl.formatMessage({
              description: 'travel transfer / start time label',
              defaultMessage: 'Start time',
            }),
            arrive: intl.formatMessage({
              description: 'travel transfer / arrive label',
              defaultMessage: 'Arrive',
            }),
            arriveTime: intl.formatMessage({
              description: 'travel transfer / arrive time label',
              defaultMessage: 'Arrive time',
            }),
            oneWayReturn: intl.formatMessage({
              description: 'travel transfer / one way / return label',
              defaultMessage: 'A/R',
            }),
            date: intl.formatMessage({
              description: 'travel transfer / date label',
              defaultMessage: 'Date',
            }),
            pnr: intl.formatMessage({
              description: 'travel transfer / pnr label',
              defaultMessage: 'PNR',
            }),
          },
          dialog: {
            title: intl.formatMessage({
              description: 'travel transfer / delete dialog title',
              defaultMessage: 'Cancellation confirmation',
            }),
            message: intl.formatMessage({
              description: 'travel transfer / Delete dialog message',
              defaultMessage: 'Are you sure you want to proceed?',
            }),
            agreeText: intl.formatMessage({
              description: 'travel transfer / Delete dialog agree text',
              defaultMessage: 'Confirm',
            }),
          },
        },
      },
      travel: {
        title: intl.formatMessage({
          description: 'travel transfer / travel & transfer',
          defaultMessage: 'Travel & Transfer',
        }),
        myTravels: intl.formatMessage({
          description: 'travel transfer / my travels',
          defaultMessage: 'My travels',
        }),
        myTravelDescr: intl.formatMessage({
          description: 'travel transfer / my travel description',
          defaultMessage:
            'Included with your participant in the Event, you have at your disposal:',
        }),
        myTravelItem1: intl.formatMessage({
          description: 'travel transfer / second class',
          defaultMessage: 'Second class train ride there and back',
        }),
        myTravelItem2: intl.formatMessage({
          description: 'travel transfer / station',
          defaultMessage: 'Station - Congress venue transfer',
        }),
        generalPrefs: intl.formatMessage({
          description: 'travel transfer / general preferences',
          defaultMessage: 'General preferences',
        }),
        oneWay: intl.formatMessage({
          description: 'travel transfer / travel one Way (A)',
          defaultMessage: 'TRAVEL ONE WAY (A)',
        }),
        return: intl.formatMessage({
          description: 'travel transfer / travel return (R)',
          defaultMessage: 'TRAVEL RETURN (R)',
        }),
        travelDate: intl.formatMessage({
          description: 'travel transfer / Date',
          defaultMessage: 'Date',
        }),
        travelBy: intl.formatMessage({
          description: 'travel transfer / travel by',
          defaultMessage: 'Travel by',
        }),
        flightTrainN: intl.formatMessage({
          description: 'travel transfer / flight / train number',
          defaultMessage: 'Flight/Train n°',
        }),
        leavingFrom: intl.formatMessage({
          description: 'travel transfer / Leaving from',
          defaultMessage: 'Leaving from',
        }),
        at: intl.formatMessage({
          description: 'travel transfer / at',
          defaultMessage: 'At (hrs)',
        }),
        intermediateAirport: intl.formatMessage({
          description: 'travel transfer / intermediate airport',
          defaultMessage: 'Intermediate airport',
        }),
        arrivingIn: intl.formatMessage({
          description: 'travel transfer / arriving in',
          defaultMessage: 'Arriving in',
        }),
        returnDate: intl.formatMessage({
          description: 'travel transfer / Pick Up Date',
          defaultMessage: 'Pick Up Date',
        }),
        airplaneFidelityCard: intl.formatMessage({
          description:
            'travel transfer / airplane fidelity fard (e.g. Mille Miglia)',
          defaultMessage: 'Airplane Fidelity Card (e.g. Mille Miglia)',
        }),
        trainFidelityCard: intl.formatMessage({
          description:
            'travel transfer / train fidelity fard (e.g. Carta Freccia)',
          defaultMessage: 'Train Fidelity Card (e.g. Carta Freccia)',
        }),
        telephoneNumber: intl.formatMessage({
          description: 'travel transfer / telephone number',
          defaultMessage: 'Telephone number',
        }),
        notes: intl.formatMessage({
          description: 'travel transfer / notes',
          defaultMessage: 'Notes',
        }),
        consentForTheCheckedBaggage: intl.formatMessage({
          description: 'travel transfer / consent for the checked baggage',
          defaultMessage: 'Consent for the checked baggage',
        }),
        issueTickets: intl.formatMessage({
          description:
            'travel transfer / issue tickets according to the indications provided',
          defaultMessage: 'Issue tickets according to the indications provided',
        }),
        proposal: intl.formatMessage({
          description:
            'travel transfer / send me proposal accoding to the specifications',
          defaultMessage: 'Send me proposal accoding to the specifications',
        }),
        // to: intl.formatMessage({
        //   description: 'travel transfer / To',
        //   defaultMessage: 'To',
        // }),
        // pickUpPlace: intl.formatMessage({
        //   description: 'travel transfer / Place Pick Up',
        //   defaultMessage: 'Place Pick Up',
        // }),
        // pickUpDestination: intl.formatMessage({
        //   description: 'travel transfer / Destination Pick Up',
        //   defaultMessage: 'Destination Pick Up',
        // }),
        no: intl.formatMessage({
          description: 'travel transfer / no',
          defaultMessage: 'No',
        }),
        yes: intl.formatMessage({
          description: 'travel transfer / yes',
          defaultMessage: 'Yes',
        }),
      },
      transfer: {
        title: intl.formatMessage({
          description: 'travel transfer / TRANSFER ',
          defaultMessage: 'TRANSFER',
        }),
        transferAIncluded: intl.formatMessage({
          description: 'travel transfer / transfer A included',
          defaultMessage: 'Included Transfer A',
        }),
        transferRIncluded: intl.formatMessage({
          description: 'travel transfer / transfer R included',
          defaultMessage: 'Included Transfer R',
        }),
        yes: intl.formatMessage({
          description:
            'travel transfer / travel and transfer, transfer A/R yes',
          defaultMessage: 'Yes',
        }),
        no: intl.formatMessage({
          description: 'travel transfer / travel and transfer, transfer A/R no',
          defaultMessage: 'No',
        }),
        oneWay: intl.formatMessage({
          description: 'travel transfer / transfer one way',
          defaultMessage: 'Transfer One way',
        }),
        return: intl.formatMessage({
          description: 'travel transfer / transfer return',
          defaultMessage: 'Transfer return',
        }),
        // transferDate: intl.formatMessage({
        //   description: 'travel transfer / date',
        //   defaultMessage: 'Date',
        // }),
        // returnDate: intl.formatMessage({
        //   description: 'travel transfer / pick Up Date ',
        //   defaultMessage: 'Pick Up Date',
        // }),
        pickUpTime: intl.formatMessage({
          description: 'travel transfer / pick up time',
          defaultMessage: 'Pick up time',
        }),
        from: intl.formatMessage({
          description: 'travel transfer / From ',
          defaultMessage: 'From',
        }),
        to: intl.formatMessage({
          description: 'travel transfer / To',
          defaultMessage: 'To',
        }),
        pickUpPlace: intl.formatMessage({
          description: 'travel transfer / pick up place',
          defaultMessage: 'Pick up place',
        }),
        pickUpDestination: intl.formatMessage({
          description: 'travel transfer / pick up destination',
          defaultMessage: 'Pick up destination',
        }),
        uploadByDocId: intl.formatMessage({
          description: 'travel transfer / upload by document ID',
          defaultMessage: 'Upload by Document ID',
        }),
        uploadForPurchase: intl.formatMessage({
          description: 'travel transfer / upload for purchase through Agency',
          defaultMessage: 'Upload for purchase through Agency',
        }),
        uploadForAutoBooking: intl.formatMessage({
          description: 'travel transfer / upload for autonomous booking',
          defaultMessage: 'Upload for autonomous booking',
        }),
      },
    },
  }),
};
