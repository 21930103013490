export const translations = {
  root: (intl) => ({
    title: intl.formatMessage({
      description: 'documents list title',
      defaultMessage: 'Documents',
    }),
    subtitle: intl.formatMessage({
      description: 'documents list subtitle',
      defaultMessage: 'Open the mandatory documents to continue',
    }),
    name: intl.formatMessage({
      description: 'documents list name column',
      defaultMessage: 'Name',
    }),
  }),
};
