export default {
  landing: (intl) => ({
    grants: {
      grant: intl.formatMessage({
        description: 'the grant word itself',
        defaultMessage: 'grants',
      }),
    },
    page: {
      back: intl.formatMessage({
        description: 'back',
        defaultMessage: 'back',
      }),
      choose: intl.formatMessage({
        description: 'choose which initiative you want to participate in',
        defaultMessage: 'choose which initiative you want to participate in',
      }),
    },
  }),
};
