import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { getNewPrepaidBudget } from '../agency/shared/dataHelper';

import {
  AimTypography,
  AimCheckbox,
  theme,
  styled,
  AimDialog,
} from '@aim/components';

import { useDataHelper } from '../shared/dataHelper';

// import { format, isEqual, isWithinInterval } from 'date-fns';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'white',
  padding: '10px',
  minWidth: '450px',
});

const formControlStyle = {
  width: 'calc(100% - 20px)',
};

export const AgencyPrepaidBudgetDialog = ({
  isDialogOpen,
  style,
  i18n,
  onDisagree,
  onAgree,
  prepaidBudget,
  defChecked,
  agencyId,
  ...rest
}) => {
  const dataHelper = useDataHelper();

  const onPurchaseClick = () => {
    onAgree(checked);
  };

  const [checked, setChecked] = useState(defChecked);
  const [prepaid, setPrepaid] = useState(0);

  const handleCheck = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    if (!prepaidBudget) return;
    const prepaidBudgetNew = async () =>
      await getNewPrepaidBudget({
        prepaidBudget,
        agencyId,
      });
    prepaidBudgetNew().then((response) => {
      console.log('response ', response);
      setPrepaid(response);
    });
  }, [prepaidBudget]);

  return (
    <AimDialog
      disableEnforceFocus
      fullWidth
      maxWidth={'md'}
      open={isDialogOpen}
      title={i18n.modal.agencyPrepaidBudget.residualBudget}
      onClose={onDisagree}
      disagreeText={i18n.modal.agencyPrepaidBudget.buttons.cancel}
      agreeText={i18n.modal.agencyPrepaidBudget.buttons.buy}
      onDisagree={onDisagree}
      onAgree={() => onPurchaseClick()}
    >
      <Container style={style} {...rest}>
        <AimTypography variant="h3Regular" margin="0">
          {i18n.modal.agencyPrepaidBudget.availableBudgetText1}{' '}
          {dataHelper.formatNumber(dataHelper.decodeDbNumber(prepaid))}{' '}
          {i18n.modal.agencyPrepaidBudget.availableBudgetText2}
        </AimTypography>
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
          <AimCheckbox
            tabIndex={-1}
            onClick={(event) => handleCheck(event)}
            checked={checked}
            // labelPlacementLeft
          />
          <span style={{ marginLeft: 15 }}>
            {i18n.modal.agencyPrepaidBudget.useRemainingBudget}
          </span>
        </div>
      </Container>
    </AimDialog>
  );
};
