import React, { useState, useEffect } from 'react';

import { useHistory, useParams } from 'react-router-dom';

// import { format, addDays, differenceInDays } from 'date-fns';

import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';

import { fileHelper } from '@aim/common';

import {
  AimIconAndTextButton,
  AimTypography,
  styled,
  CustomIntl,
} from '@aim/components';

import { GetParticipantsByEventId } from './gqlHelper';

const StyledRelatorCard = styled('div')({
  width: 310,
  height: 261,
  display: 'flex',
  paddingTop: '26px',
  alignItems: 'center',
  // justifyContent: 'center',
  backgroundColor: '#F3F3F4',
  flexDirection: 'column',
  position: 'relative',
});

export const StyledParagraphCenter = styled('div')({
  textTransform: 'uppercase',
});

const ButtonContainer = styled('div')({
  position: 'absolute',
  justifyContent: 'flex-end',
  margin: 10,
  bottom: '10px',
});

const Speakers = () => {
  // Hooks
  const history = useHistory();
  const { eventId } = useParams();
  const intl = CustomIntl(useIntl());
  // const { getEvent } = useAgenda();

  // States
  const [speakers, setSpeakers] = useState([]);

  // Effects
  useEffect(() => {
    const fetchSpeakers = async () => {
      const res = await GetParticipantsByEventId(eventId);
      if (res.items.length > 0) {
        const nextres = await Promise.all(
          res.items.map(async (item) => {
            const profileImageLink =
              item.userShowcase?.profileImage && (await getLink(item));
            let filterName = `${item.givenName} ${item.familyName}`;

            return {
              ...item,
              givenName: item.givenName.toLowerCase(),
              familyName: item.familyName.toLowerCase(),
              filterName: filterName.toLowerCase(),
              profileImageLink,
              workJob: item.userShowcase?.workJob,
            };
          })
        );
        setSpeakers(nextres.slice(0, 4));
      }
    };

    fetchSpeakers();
  }, []);

  const getLink = async (user) => {
    const link = fileHelper.getPublicFileLink({
      dirPath: `events/${eventId}/user/${user?.id}/showcase/profileImage`,
      skipFileDataOnS3Link: true,
    });
    return link;
  };

  return speakers.length ? (
    <>
      <Grid container alignItems="center" justifyContent="center">
        {speakers.map((s, idx) => {
          return (
            <Grid
              item
              style={{
                cursor: 'pointer',
                marginBottom: 10,
                marginLeft: 10,
                marginRigth: 10,
              }}
              key={s.id}
              tabIndex={idx}
              role="button"
              // onClick={() => {
              //   history.push(`user/${s.id}`);
              // }}
            >
              <StyledRelatorCard>
                {/* <Overlay /> */}
                <>
                  {/* <StyledCardImg src={s.profileImageLink} /> */}
                  <Avatar
                    style={{
                      border: '2px solid white',
                      height: '97px',
                      width: '97px',
                      fontSize: '25px',
                    }}
                  >
                    {s.profileImageLink ? (
                      <img
                        src={s.profileImageLink}
                        alt="avatar"
                        style={{
                          objectFit: 'cover',
                          width: 'inherit',
                          height: 'inherit',
                          backgroundColor: 'white',
                        }}
                      />
                    ) : (
                      <>
                        {s.givenName && s.givenName[0].toUpperCase()}
                        {s.familyName && s.familyName[0].toUpperCase()}
                      </>
                    )}
                    {/* <ChangeAvatarButton
                            variant={'lightBlueFill'}
                            onClick={onChangeAvatar}
                          >
                            {editMode && <PhotoCameraIcon />}
                          </ChangeAvatarButton> */}
                  </Avatar>
                  <StyledParagraphCenter>
                    <AimTypography
                      variant="textBold"
                      boxStyle={{ fontWeight: 'bold' }}
                    >
                      {s.givenName} {s.familyName}
                    </AimTypography>
                  </StyledParagraphCenter>
                  {s.workJob && (
                    <AimTypography variant={'h5'} margin={0}>
                      {s.workJob}
                    </AimTypography>
                  )}
                  <ButtonContainer>
                    <AimIconAndTextButton
                      style={{ padding: '0px 30px' }}
                      isUpperCase
                      text={intl.formatMessage({
                        description: 'view text',
                        defaultMessage: 'View',
                      })}
                      onClick={() => {
                        history.push(`/events/${eventId}/user/${s.id}`);
                      }}
                    ></AimIconAndTextButton>
                  </ButtonContainer>
                </>
              </StyledRelatorCard>
            </Grid>
          );
        })}
      </Grid>
    </>
  ) : (
    <></>
  );
};

export default Speakers;
