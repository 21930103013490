import React, { useState, useEffect } from 'react';

import { useIntl } from 'react-intl';
import useI18n from './shared/translation';

import { useParams, useHistory } from 'react-router-dom';

import Tooltip from '@material-ui/core/Tooltip';
import ArrowBack from '@material-ui/icons/ArrowBack';

import {
  AimSnackbar,
  AimSnackbarSeverity,
  AimTypography,
  AimIconAndTextButton,
  CustomIntl,
} from '@aim/components';
import { constants, appState, aws } from '@aim/common';

import SponsorCheckout from './sponsorCart/SponsorCheckout';
import { MainContainer, InnerContainer } from './shared/SponsorPagesContainers';

import { useBuyOperations } from './shared/buyOperationsGqlHelper';
import { getEvent, usePurchases } from './shared/purchaseGqlHelper';
import { useSymposiums } from './shared/symposiumsGqlHelper';
import { useSponsorArea } from './shared/sponsorAreaGqlHelper';
import { paymentGqlHelper } from './shared/paymentGqlHelper';
import { useSponsors } from './shared/sponsorGqlHelper';
import { useOtherStandServiceItems } from './shared/otherStandServiceItemsGqlHelper';
import { usePhysicalStandFloorService } from './shared/physicalStandFloorGqlHelper';
import { useSponsorVirtualStand } from './shared/sponsorVirtualStandGqlHelper';
import { useDataHelper } from './shared/dataHelper';
import { usePublicPage } from './shared/publicPageGqlHelper';
import { useBreakoutRoom } from './shared/breakoutRoomGqlHelper';
import { getEventOrder } from './shared/eventOrderGqlHelper';
import {
  createBillingInformation,
  getBuyOperations,
  updatePayment,
} from './sponsorPayment/gqlHelper';
import { useRef } from 'react';

const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

const createBillingInfo = async (paymentId, billingInfo) => {
  const billingInfoResp = await createBillingInformation({
    ...billingInfo,
  });

  await updatePayment(
    {
      id: paymentId,
      paymentBillingInformationId: billingInfoResp.id,
    },
    false
  );
};

const SponsorPayment = () => {
  // Hooks
  const { eventId, sponsorId } = useParams();
  const history = useHistory();
  const { update } = useBuyOperations();
  const purchaseGqlHelper = usePurchases();
  const symposiumsGqlHelper = useSymposiums();
  const sponsorAreaGqlHelper = useSponsorArea();
  const paymentHelper = paymentGqlHelper();
  const sponsorGqlHelper = useSponsors();
  const otherStandServiceItemsHelper = useOtherStandServiceItems();
  const physicalStandSpaceHelper = usePhysicalStandFloorService();
  const sponsorVirtualStandHelper = useSponsorVirtualStand();
  const publicPageHelper = usePublicPage();
  const breakoutRoomGqlHelper = useBreakoutRoom();
  const { encodeDbNumber } = useDataHelper();
  const intl = CustomIntl(useIntl());
  const i18n = useI18n.sponsorCart(intl);
  const eventCodeRef = useRef();

  // States
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [checkoutBookings, setCheckoutBookings] = useState(
    history.location.state?.bookings
  );
  const [responsePaymentIngenico, setResponsePaymentIngenico] = useState();

  const paymentGateway = history.location.state?.payment;
  const sponsorGateway = history.location.state?.gateway;
  const paymentType = history.location.state?.paymentType;
  const billingInfo = history.location.state?.billingInfo;
  // console.log('history.location.state', history.location.state);
  // const handlePaymentChange = (event) => {
  //   setPaymentGateway(event.target.value);
  // };

  useEffect(() => {
    const fetchEvent = async () => {
      const response = await getEvent(eventId);
      eventCodeRef.current = response.code;
    };
    fetchEvent();
  }, []);

  useEffect(() => {
    if (!history.location.state || !history.location.state.bookings) {
      history.push(`/events/${eventId}/${sponsorId}/cart`);
    }
  }, [history.location]);

  const initPaymentMonetaOnLine_old = async (paymentId, relurl) => {
    // const testurl = 'https://test.d1zwmjatj8p4ws.amplifyapp.com';
    // const baseurl = process.env.BASEURL.split('//')[1]; // testurl.split('//')[1] ||
    const baseurl =
      window.location.hostname === 'localhost'
        ? 'localhost:9001'
        : window.location.hostname;

    const apiName = 'aimMonetaOnLine';
    const path = '/monetaonline/initpayment';
    const queryData = {
      queryStringParameters: {
        paymentId,
        url: baseurl,
        relurl,
      },
      responseType: 'json',
    };

    const response = await aws.standardAPI.get(apiName, path, queryData);
    return response;
  };

  const initPaymentTest = async ({ paymentId, relurl, gateway }) => {
    const baseurl =
      window.location.hostname === 'localhost'
        ? 'localhost:9001'
        : window.location.hostname;
    const res = await aws.standardAPI.post('aimlambdaproxy', '/pay', {
      body: {
        paymentId,
        url: baseurl,
        relurl,
        gateway,
      },
    });
    return res;
  };

  const initPaymentIngenico = async (paymentId) => {
    // const testurl = 'https://test.d1zwmjatj8p4ws.amplifyapp.com';
    const baseurl = process.env.BASEURL.split('//')[1]; // testurl.split('//')[1] ||

    const apiName = 'aimIngenico';
    const path = '/ingenico/initpayment';
    const queryData = {
      queryStringParameters: {
        paymentId,
        url: baseurl,
      },
      responseType: 'json',
    };

    const response = await aws.standardAPI.get(apiName, path, queryData);
    return response;
  };

  const initGpWebpay = async (paymentId) => {
    const baseurl = process.env.BASEURL.split('//')[1]; // testurl.split('//')[1] ||

    const apiName = 'aimGpWebPay';
    const path = '/gpwebpay/initpayment';

    const queryData = {
      queryStringParameters: {
        paymentId,
        url: baseurl,
      },
      responseType: 'json',
    };

    const response = await aws.standardAPI.get(apiName, path, queryData);
    return response;
  };

  const onClickConfirm = async () => {
    showLoader();
    const buyOperations = await getBuyOperations(checkoutBookings);

    // check if some buyOperations already has purchase, to prevent wrong overrite
    if (Object.values(buyOperations).some((bo) => bo.buyOperationPurchaseId)) {
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.page.errors.invalidCart,
        alertActions: (
          <>
            <AimIconAndTextButton
              small
              variant="none"
              style={{ marginTop: 32, fontSize: '0.75rem', color: 'blue' }}
              onClick={() =>
                history.push(`/events/${eventId}/${sponsorId}/cart`)
              }
            >
              GO BACK TO CART
            </AimIconAndTextButton>
          </>
        ),
      });
      hideLoader();
      return;
      // history.push(`/events/${eventId}/${sponsorId}/cart`);
    }
    let purchase;
    let sponsorArea; // sponsor areas must have a separate variable for rollback

    let currentCheckoutBookings = checkoutBookings;

    // calculate total amount of the purchase
    const amount = encodeDbNumber(
      currentCheckoutBookings.reduce(
        (prev, curr) => (prev += curr.price * curr.quantity),
        0
      )
    );

    // if the total is 0 force bank transfer payment
    if (amount === 0) {
      currentCheckoutBookings = currentCheckoutBookings.map((cb) => ({
        ...cb,
        paymentType: constants.PaymentTypes.BankTransfer,
      }));
    }

    try {
      // first create purchase
      purchase = await purchaseGqlHelper.create(
        {
          eventId,
          date: new Date(),
          eventCode: eventCodeRef.current,
          purchaseSponsorId: sponsorId,
          paymentType: paymentType, //currentCheckoutBookings[0].paymentType,
          hasFailed: 'false',
          failedPurchaseBuyOperationsData: JSON.stringify(
            currentCheckoutBookings.map((b) =>
              history.location.state.cartBookings.find((x) => x.id === b.id)
            )
          ),
        },
        false
      );

      // associate all buy operation to purchase (for easier rollback)
      const promisesOperations = currentCheckoutBookings.map((cb) => {
        return update(
          {
            id: cb.id,
            buyOperationPurchaseId: purchase.id,
            vatId: cb.vatId,
            unitaryPrice: encodeDbNumber(cb.netPrice),
            unitaryVatRate: encodeDbNumber(cb.vatRate),
          },
          false
        );
      });
      await Promise.all(promisesOperations);

      // create associations with purchased services
      const promisesServices = currentCheckoutBookings.flatMap(async (cb) => {
        const cbPromises = [];

        if (cb.bannerArea) {
          // create immediately and save result (for eventual rollback)
          sponsorArea = await sponsorAreaGqlHelper.create(
            {
              sponsorAreaBannerAreaId: cb.bannerArea.id,
              sponsorAreaSponsorId: sponsorId,
            },
            false
          );
        } else if (cb.symposium) {
          cbPromises.push(
            symposiumsGqlHelper.update(
              {
                id: cb.symposium.id,
                symposiumSponsorId: sponsorId,
              },
              false
            )
          );
        } else if (cb.publicPage) {
          cbPromises.push(
            sponsorGqlHelper.update({
              id: sponsorId,
              sponsorPublicPageId: cb.publicPage.id,
            })
          );
        } else if (cb.breakoutRoom) {
          //nd
        } else if (cb.physicalStandSpace) {
          cbPromises.push(
            physicalStandSpaceHelper.update(
              {
                id: cb.physicalStandSpace.id,
                physicalStandSpaceSponsorId: sponsorId,
              },
              false
            )
          );
        } else if (cb.physicalStandSpaceItems) {
          cbPromises.push(
            otherStandServiceItemsHelper.update(
              {
                id: cb.physicalStandSpaceItems.id,
                physicalStandSpaceItemsSponsorId: sponsorId,
              },
              false
            )
          );
        } else if (cb.sponsorVirtualStand) {
          cbPromises.push(
            sponsorVirtualStandHelper.update(
              {
                id: cb.sponsorVirtualStand.id,
                sponsorVirtualStandSponsorId: sponsorId,
              },
              false
            )
          );
        } else if (cb.sponsorStaffPurchase) {
          //no op
        } else if (cb.sponsorListPurchase) {
          //no op
        } else if (cb.sponsorPackage) {
          cb.sponsorPackage.services.items.map(async (service) => {
            if (service.bannerArea) {
              cbPromises.push(
                await sponsorAreaGqlHelper.create(
                  {
                    sponsorAreaBannerAreaId: service.bannerArea.id,
                    sponsorAreaSponsorId: sponsorId,
                  },
                  false
                )
              );
            } else if (service.symposium) {
              cbPromises.push(
                symposiumsGqlHelper.update(
                  {
                    id: service.symposium.id,
                    symposiumSponsorId: sponsorId,
                  },
                  false
                )
              );
            } else if (service.symposiumService) {
              //no op
            } else if (service.publicPageService) {
              // cbPromises.push(
              const publicPage = await publicPageHelper.create(
                {
                  publicPagePublicPageServiceId: service.publicPageService.id,
                  publicPageSponsorId: sponsorId,
                  publicPageSponsorPackageItemId: service.id,
                },
                false
              );
              cbPromises.push(
                await sponsorGqlHelper.update({
                  id: sponsorId,
                  sponsorPublicPageId: publicPage.id,
                })
              );
              // );
            } else if (service.breakoutRoomService) {
              cbPromises.push(
                Array.apply(null, Array(service.quantity)).map(() =>
                  breakoutRoomGqlHelper.create(
                    {
                      breakoutRoomBreakoutRoomServiceId:
                        service.breakoutRoomService.id,
                      breakoutRoomSponsorId: sponsorId,
                      purchasedHours:
                        service.breakoutRoomService.maxAvailableHours,
                      breakoutRoomSponsorPackageItemId: service.id,
                    },
                    false
                  )
                )
              );
            } else if (service.physicalStand) {
              cbPromises.push(
                physicalStandSpaceHelper.update(
                  {
                    id: service.physicalStand.id,
                    physicalStandSpaceSponsorId: sponsorId,
                  },
                  false
                )
              );
              // } else if (service.otherStandsItem) {
              //   const spaceService = cb.sponsorPackage.services.items.find(
              //     (service) => service?.physicalStand?.id
              //   );

              //   cbPromises.push(
              //     Array.apply(null, Array(service.quantity)).map(() =>
              //       otherStandServiceItemsHelper.create(
              //         {
              //           physicalStandSpaceItemsPhysicalStandSpaceId:
              //             spaceService.physicalStand.id,
              //           physicalStandSpaceItemsItemId: service.otherStandsItem.id,
              //           physicalStandSpaceItemsSponsorId: sponsorId,
              //           quantity: service.quantity,
              //           amountPerQuantity:
              //             service.otherStandsItem.type ===
              //             constants.OtherStandServiceItemType.doubleCost
              //               ? 1
              //               : null,
              //           physicalStandSpaceItemsSponsorPackageItemId: service.id,
              //         },
              //         false
              //       )
              //     )
              //   );
            } else if (service.virtualStand) {
              cbPromises.push(
                await sponsorVirtualStandHelper.create(
                  {
                    sponsorVirtualStandVirtualStandId: service.virtualStand.id,
                    sponsorVirtualStandSponsorId: sponsorId,
                    sponsorVirtualStandSponsorPackageItemId: service.id,
                  },
                  false
                )
              );
            }
          });
        }
        return cbPromises;
      });
      await Promise.all(promisesServices);

      // console.log('currentCheckoutBookings', currentCheckoutBookings);
      const payment = await paymentHelper.create(
        {
          paymentPurchaseId: purchase.id,
          paymentType: paymentType,
          date: new Date(),
          amount: amount,
          paymentStatus: constants.PaymentStatuses.PENDING.key,
          description: currentCheckoutBookings
            .map(
              (item) => `${item.quantity} x ${item.service} - ${item.instance}`
            )
            .join(', '),
        },
        false
      );

      // update purchase with event order code
      const eventOrders = await getEventOrder(eventId);
      const orderBySponsorId = eventOrders?.items?.find(
        (order) => order?.sponsor?.id === sponsorId
      );

      await purchaseGqlHelper.update(
        {
          id: purchase.id,
          purchasePaymentId: payment.id,
          orderCode: orderBySponsorId?.code,
        },
        false
      );

      await createBillingInfo(payment.id, billingInfo);

      if (paymentType === constants.PaymentTypes.CreditCard) {
        if (paymentGateway === constants.PaymentGateway[2].key) {
          // init payment GpWebpay
          const response = await initGpWebpay(payment.id);
          if (response.url) {
            window.location = response.url;
            // setResponseGpWebpay(response.html);
          } else {
            history.push(
              `/events/${eventId}/${sponsorId}/checkout-error?errorcode=${response.errorcode}&merchantorderid=${purchase.id}`
            );
          }
        } else if (paymentGateway === constants.PaymentGateway[1].key) {
          // init payment Ingenico
          const response = await initPaymentIngenico(payment.id);
          setResponsePaymentIngenico(response.html);
        } else if (paymentGateway === constants.PaymentGateway[0].key) {
          // get payment id from MonetaOnLine
          const response = await initPaymentMonetaOnLine_old(
            payment.id,
            `events/${eventId}/${sponsorId}`
          );

          // const response = await initPaymentTest({
          //   paymentId: payment.id,
          //   relurl: `events/${eventId}/${sponsorId}`,
          //   gateway: paymentGateway,
          // });

          // if (response?.status?.error) {
          //   // gestisco l'errore con un history push
          // }

          // window.location = response?.redirectUrl;

          if (response.error > 0) {
            history.push(
              `/events/${eventId}/${sponsorId}/checkout-error?errorcode=` +
                response.error
            );
          } else {
            const { paymentid, hostedpageurl } = response;
            window.location = `${hostedpageurl}?paymentid=${paymentid}`;
            // window.open(`${hostedpageurl}?paymentid=${paymentid}`, '_blank');
          }
        }
      } else {
        history.push(`/events/${eventId}/${sponsorId}/checkout-success`);
      }
    } catch (e) {
      console.error(e);
      // if there is an error rollback everything if the purchase has been created
      try {
        if (purchase?.id) {
          // get the new created items
          const createdPurchase = await purchaseGqlHelper.get(
            purchase.id,
            false
          );

          const rollbackPromises = createdPurchase.buyOperations.items.flatMap(
            (bo) => {
              const innerPromises = [];

              // rollback association with services
              if (bo.bannerArea) {
                innerPromises.push(
                  sponsorAreaGqlHelper.remove(
                    {
                      id: sponsorArea?.id,
                    },
                    false
                  )
                );
              } else if (bo.symposium?.id) {
                innerPromises.push(
                  symposiumsGqlHelper.update(
                    {
                      id: bo.symposium.id,
                      symposiumSponsorId: null,
                    },
                    false
                  )
                );
              } else if (bo.publicPage) {
                innerPromises.push(
                  sponsorGqlHelper.update({
                    id: sponsorId,
                    sponsorPublicPageId: null,
                  })
                );
              } else if (bo.breakoutRoom) {
                //nd
              } else if (bo.physicalStandSpace) {
                innerPromises.push(
                  otherStandServiceItemsHelper.update(
                    {
                      id: bo.physicalStandSpace.id,
                      physicalStandSpaceSponsorId: null,
                    },
                    false
                  )
                );
              } else if (bo.physicalStandSpaceItems) {
                innerPromises.push(
                  physicalStandSpaceHelper.update(
                    {
                      id: bo.physicalStandSpaceItems.id,
                      physicalStandSpaceItemsSponsorId: null,
                    },
                    false
                  )
                );
              } else if (bo.sponsorVirtualStand) {
                innerPromises.push(
                  sponsorVirtualStandHelper.update(
                    {
                      id: bo.sponsorVirtualStand.id,
                      sponsorVirtualStandSponsorId: null,
                    },
                    false
                  )
                );
              } else if (bo.sponsorStaffPurchase) {
                // no op
              } else if (bo.sponsorListPurchase) {
                // no op
              } else if (bo.sponsorPackage) {
                bo.sponsorPackage.services.items.map(async (service) => {
                  if (service.bannerArea) {
                    innerPromises.push(
                      await sponsorAreaGqlHelper.remove(
                        {
                          id: service.bannerArea.id,
                        },
                        false
                      )
                    );
                  } else if (service.symposium) {
                    innerPromises.push(
                      symposiumsGqlHelper.update(
                        {
                          id: service.symposium.id,
                          symposiumSponsorId: null,
                        },
                        false
                      )
                    );
                  } else if (service.symposiumService) {
                    // no op
                  } else if (service.publicPageService) {
                    innerPromises.push(
                      await sponsorGqlHelper.update({
                        id: sponsorId,
                        sponsorPublicPageId: null,
                      })
                    );
                  } else if (service.breakoutRoomService) {
                    // no op
                  } else if (service.physicalStand) {
                    innerPromises.push(
                      otherStandServiceItemsHelper.update(
                        {
                          id: service.physicalStandSpace.id,
                          physicalStandSpaceSponsorId: null,
                        },
                        false
                      )
                    );
                    // } else if (service.otherStandsItem) {
                    //   innerPromises.push(
                    //     physicalStandSpaceHelper.update(
                    //       {
                    //         id: service.otherStandsItem.id,
                    //         physicalStandSpaceItemsSponsorId: null,
                    //       },
                    //       false
                    //     )
                    //   );
                  } else if (service.virtualStand) {
                    innerPromises.push(
                      sponsorVirtualStandHelper.update(
                        {
                          id: service.virtualStand.id,
                          sponsorVirtualStandSponsorId: null,
                        },
                        false
                      )
                    );
                  }
                });
              }
              // rollback association of buy operation with purchase
              innerPromises.push(
                update(
                  {
                    id: bo.id,
                    buyOperationPurchaseId: null,
                    unitaryPrice: null,
                    unitaryVatRate: null,
                  },
                  false
                )
              );
              return innerPromises;
            }
          );

          if (createdPurchase.payment?.id) {
            rollbackPromises.push(
              paymentHelper.remove({ id: createdPurchase.payment.id }, false)
            );
          }

          rollbackPromises.push(purchaseGqlHelper.remove({ id: purchase.id }));
          await Promise.all(rollbackPromises);

          setSnackbar({
            isOpen: true,
            severity: AimSnackbarSeverity.error,
            message: i18n.page.errors.purchase.label,
          });
        }
      } catch (e) {
        // rollback error
        console.error(e);
      } finally {
        setSnackbar({
          isOpen: true,
          severity: AimSnackbarSeverity.error,
          message: i18n.page.errors.purchase.label,
        });
      }
    } finally {
      hideLoader();
    }
  };

  return (
    <>
      <MainContainer>
        <div style={{ display: 'flex-root', marginTop: 20 }}>
          <InnerContainer>
            <Tooltip
              title={
                !checkoutBookings?.length
                  ? i18n.page.back.cart.tooltip
                  : i18n.page.back.checkout.tooltip
              }
            >
              <AimIconAndTextButton
                variant="text"
                text={
                  !checkoutBookings?.length
                    ? i18n.page.back.cart.label
                    : i18n.page.back.checkout.label
                }
                onClick={() => {
                  history.goBack();
                }}
              >
                <ArrowBack />
              </AimIconAndTextButton>
            </Tooltip>
            <AimTypography variant={'h1'}>
              {!checkoutBookings?.length
                ? i18n.page.title.cart
                : i18n.page.title.checkout}
            </AimTypography>
          </InnerContainer>
        </div>
        <div>
          <div>
            {paymentGateway === constants.PaymentGateway[1].key &&
            responsePaymentIngenico ? (
              <div
                className="content"
                dangerouslySetInnerHTML={{
                  __html: responsePaymentIngenico,
                }}
              ></div>
            ) : (
              <InnerContainer bottomSpacing>
                {checkoutBookings && (
                  <SponsorCheckout
                    checkoutBookings={checkoutBookings}
                    setCheckoutBookings={setCheckoutBookings}
                    onClickConfirm={onClickConfirm}
                    // onHandlePayment={handlePaymentChange}
                    i18n={i18n}
                    paymentGateway={paymentGateway}
                    paymentType={paymentType}
                    sponsorGateway={sponsorGateway}
                  />
                )}
              </InnerContainer>
            )}
          </div>
        </div>
      </MainContainer>

      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
        alertActions={snackbar.alertActions}
      >
        {snackbar.message}
      </AimSnackbar>
    </>
  );
};

export default SponsorPayment;
