import React from 'react';

import Grid from '@material-ui/core/Grid';
import { AimTypography } from '@aim/components';
import { useDataHelper } from './../../shared/dataHelper';

const PhysicalStandFloorsDialogInfoContent = ({ item, i18n }) => {
  //Hooks
  const { formatNumber } = useDataHelper();

  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <AimTypography variant="textBold">
            {i18n.dialog.fields.code.label}
          </AimTypography>
          <AimTypography variant="text">{item?.code}</AimTypography>
        </Grid>
        <Grid item xs={6}>
          <AimTypography variant="textBold">
            {i18n.dialog.fields.name.label}
          </AimTypography>
          <AimTypography variant="text">{item?.name}</AimTypography>
        </Grid>
        <Grid item xs={6}>
          <AimTypography variant="textBold">
            {i18n.dialog.fields.spaceLength.label}
          </AimTypography>
          <AimTypography variant="text">{item?.spaceLength}</AimTypography>
        </Grid>
        <Grid item xs={6}>
          <AimTypography variant="textBold">
            {i18n.dialog.fields.spaceHeight.label}
          </AimTypography>
          <AimTypography variant="text">{item?.spaceHeight}</AimTypography>
        </Grid>
        <Grid item xs={6}>
          <AimTypography variant="textBold">
            {i18n.dialog.fields.spaceDepth.label}
          </AimTypography>
          <AimTypography variant="text">{item?.spaceDepth}</AimTypography>
        </Grid>
        <Grid item xs={6}>
          <AimTypography variant="textBold">
            {i18n.dialog.fields.surface.label}
          </AimTypography>
          <AimTypography variant="text">{item?.surface}</AimTypography>
        </Grid>
        <Grid item xs={6}>
          <AimTypography variant="textBold">
            {i18n.dialog.fields.netPrice.label}
          </AimTypography>
          <AimTypography variant="text">
            {formatNumber(item?.netPrice)}
          </AimTypography>
        </Grid>
        <Grid item xs={6}>
          <AimTypography variant="textBold">
            {i18n.dialog.fields.vatRate.label}
          </AimTypography>
          <AimTypography variant="text">{`${item?.vatRate} %`}</AimTypography>
        </Grid>
        <Grid item xs={6}>
          <AimTypography variant="textBold">
            {i18n.dialog.fields.taxedPrice.label}
          </AimTypography>
          <AimTypography variant="text">
            {formatNumber(item?.taxedPrice)}
          </AimTypography>
        </Grid>
        <Grid item xs={12}>
          <AimTypography variant="textBold">
            {i18n.dialog.fields.note.label}
          </AimTypography>
          <AimTypography variant="text">{item?.note}</AimTypography>
        </Grid>
      </Grid>
    </>
  );
};

export { PhysicalStandFloorsDialogInfoContent };
