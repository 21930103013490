const commonFields = {
  INVITE_TITLE: '##INVITE_TITLE##',
  TIME_GREETING: '##TIME_GREETING##',
  SPONSOR_NAME: '##SPONSOR_NAME##',
  DATE: '##DATE##',
  GREATING: '##GREATING##',
  SIGN: '##SIGN##',
  EMAIL_MESSAGE_1: '##EMAIL_MESSAGE_1##',
  EMAIL_MESSAGE_2: '##EMAIL_MESSAGE_2##',
};
export default commonFields;
