import { aws, appState } from '@aim/common';

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

// const ParticipantsByEventIdQuery = /* GraphQL */ `
//   query ParticipantsByEventId($participationEventId: ID) {
//     participantByEventId(
//       participationEventId: $participationEventId
//       filter: { isSpeaker: { eq: true } }
//     ) {
//       items {
//         id
//         userShowcase {
//           profileImage {
//             id
//             extension
//             originalName
//             size
//           }
//           workJob
//         }
//         givenName
//         familyName
//         isSpeaker
//         type
//       }
//     }
//   }
// `;

// export const GetParticipantsByEventId = (eventId, useLoader = true) =>
//   new Promise((resolve, reject) => {
//     useLoader && showLoader();

//     API.graphql({
//       query: ParticipantsByEventIdQuery,
//       variables: {
//         participationEventId: eventId,
//       },
//     })
//       .then((response) => {
//         resolve(response.data.participantByEventId);
//       })
//       .catch((e) => {
//         console.error('participants-by-eventId', e);
//         reject();
//       })
//       .finally(() => useLoader && hideLoader());
//   });

const getPostItemsByEventId = /* GraphQL */ `
  query listSocialWallItemsByStatus(
    $nextToken: String
    $limit: Int
    $socialWallItemEventId: ID
  ) {
    listSocialWallItemsByStatus(
      limit: $limit
      isDeleted: "false"
      nextToken: $nextToken
      isVisibleSocialWallItemEventId: {
        eq: {
          isVisible: "published"
          socialWallItemEventId: $socialWallItemEventId
        }
      }
    ) {
      nextToken
      items {
        createdAt
        creationDateTime
        id
        isDeleted
        isVisible
        postId
        postWebsiteType
        socialWallItemEventId
        text
        updatedAt
      }
    }
  }
`;

const getTwitterTagsByEventId = /* GraphQL */ `
  query GetTags($id: ID!) {
    getEvent(id: $id) {
      id
      twitterSocialWallTags
    }
  }
`;

export const getEventTwitterTagsByEventId = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    aws.API.graphql({ query: getTwitterTagsByEventId, variables: input })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        console.error('get-event-twitter-tags', e);
        reject(e);
      })
      .finally(() => useLoader && hideLoader());
  });

export const getSocialWallPosts = async (input) => {
  var x = await new Promise((resolve, reject) => {
    aws.standardAPI
      .graphql({
        query: getPostItemsByEventId,
        variables: input,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((e) => {
        console.error('get-social-wall-posts', e);
        reject(e);
      });
  });
  return x;
};
