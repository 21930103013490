import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useParams, useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import CheckIcon from '@material-ui/icons/Check';

import {
  AimTypography,
  AimIconAndTextButton,
  AimSnackbar,
  AimSnackbarSeverity,
  CustomIntl,
  theme,
  styled,
} from '@aim/components';

import { appState, constants } from '@aim/common';
import { translation } from './faculty/translation';
import AdditionalServicesDialog from './faculty/additionalServices/AdditionalServiceDialog';
import AccreditationMaterialDialog from './faculty/accreditationMaterial/AccreditationMaterialDialog';
import AifaDataDialog from './faculty/aifaData/AifaDataDialog';
import TravelTransferDialog from './faculty/travelAndTransfer/TravelTransferDialog';
import AllotmentDialog from './faculty/allotment/AllotmentDialog';
import ScientificCommitmentDialog from './faculty/scientificCommitment/ScientificCommitmentDialog';
import ParticipationConfirm from './faculty/participation/ParticipationConfirm';
import SessionCard from './faculty/agendaSessions/SessionCard';

import {
  getParticipationServices,
  listAdditionalServices,
  // getAgendaSessionsByEventAndParticipant,
  getAgendaSessionsByEvent,
  updateParticipationServices,
} from './faculty/facultyGqlHelper';

const formControlStyle = { width: 'calc(100% - 20px)' };

const FacultyContainer = styled('div')(({ smDown }) => ({
  width: smDown ? '100%' : '100vw',
  backgroundColor: '#FFF',
}));

const FacultyInnerContainer = styled('div')(({ smDown }) => ({
  flex: 1,
  width: smDown ? 'calc(100% - 60px)' : 'calc(100% - 120px)',
  margin: 'auto',
  marginBottom: 20,
}));

const HeaderContainer = styled('div')(({ smDown }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: smDown ? 'column' : 'row',
}));

const FacultySectionWrapper = styled('div')({
  backgroundColor: theme.colors.greyScale.backgroundGrey,
  padding: 30,
  minHeight: 150,
  marginBottom: 20,
});

const CardsWrapper = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 10,
  marginTop: 10,
});

const CardWrapper = styled('div')({
  width: 250,
  height: 125,
  background: theme.colors.greyScale.white,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textTransform: 'uppercase',
  position: 'relative',
  cursor: 'pointer',
});

const CardStatus = styled('div')({
  position: 'absolute',
  width: 30,
  height: 30,
  backgroundColor: '#29E129',
  top: 15,
  right: 15,
  borderRadius: 100,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#FFF',
});

const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

const Faculty = () => {
  //Hooks
  const intl = CustomIntl(useIntl());
  const i18n = translation.faculty(intl);
  const { eventId } = useParams();
  const history = useHistory();
  const themeMU = useTheme();
  const smDown = useMediaQuery(themeMU.breakpoints.down('xs'));

  // State
  const [
    availableAdditionalServices,
    setAvailableAdditionalServices,
  ] = useState([]);
  const [participationServices, setParticipationServices] = useState();
  const [
    participationAgendaSessions,
    setParticipationAgendaSessions,
  ] = useState();
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [additionalServicesDialog, setAdditionalServicesDialog] = useState({
    isOpen: false,
  });
  const [travelTransferDialog, setTravelTransferDialog] = useState({
    isOpen: false,
  });
  const [allotmentDialog, setAllotmentDialog] = useState({
    isOpen: false,
  });

  const [
    accreditationMaterialDialog,
    setAccreditationMaterialDialog,
  ] = useState({
    isOpen: false,
  });

  const [aifaDataDialog, setAifaDataDialog] = useState({ isOpen: false });

  const [scientificCommitmentDialog, setScientificCommitmentDialog] = useState({
    isOpen: false,
  });

  // Effects
  useEffect(() => {
    fetchData(false);
  }, []);

  // API
  const fetchData = async (isOnSubmit) => {
    showLoader();
    const participationId = appState.user.getValue().userAndParticipation
      .participation.id;

    const fetchOps = [
      getParticipationServices(participationId, false),
      listAdditionalServices(eventId, false),
      getAgendaSessionsByEvent(eventId),
      // getAgendaSessionsByEventAndParticipant(eventId, participationId),
    ];
    const [
      participationServicesRes,
      additionalServicesRes,
      agendaSessionsRes,
    ] = await Promise.all(fetchOps);

    // if (
    //   isOnSubmit &&
    //   checkAllPrefsHaveStatusPublished(participationServicesRes) &&
    //   !participationServicesRes.publicationDateOfPrefs
    // ) {
    //   // update with date
    //   await updateParticipationServices({
    //     id: participationServicesRes.id,
    //     publicationDateOfPrefs: new Date(),
    //   });
    // }

    const updatedAvailableAdditionalServices = additionalServicesRes.filter(
      (as) =>
        participationServicesRes?.additionalServicesIncludedServices?.includes(
          as.id
        )
    );

    // console.log('!agendaSessionsRes ', agendaSessionsRes);
    const participationAgendaSpeechesFiltered = agendaSessionsRes.filter(
      (item) =>
        item.speeches.items.find((speech) => speech.attendees?.items?.length)
    );

    const speechesIncludePax = participationAgendaSpeechesFiltered
      .map((item) => {
        const filtered = item.speeches.items.filter(
          (s) =>
            s.attendees?.items?.length &&
            s.attendees?.items?.find(
              (pax) => pax.participation.id === participationId
            )
        );
        item.speeches = filtered;
        return { ...item };
      })
      .filter((x) => x.speeches?.length);

    const nextParticipationAgendaAttendeesRes = speechesIncludePax.map(
      (item) => {
        const session = item;
        const speeches = item.speeches.map((s) => {
          const titleDescription = s.titleDescription;
          const titleSpeech = s.title;
          const startSpeech = s.start;
          const endSpeech = s.end;
          const attendees = s.attendees.items
            .reduce((res, curr) => {
              let nationality = '';
              if (curr.participation.user) {
                nationality = ` (${curr.participation.user.nationality})`;
              }
              return `${res} ${curr.participation.givenName} ${curr.participation.familyName}${nationality},`;
            }, '')
            .slice(0, -1);
          return {
            attendees,
            titleSpeech,
            startSpeech,
            endSpeech,
            titleDescription,
          };
        });
        session.speeches = speeches;
        return { session };
      }
    );

    setAvailableAdditionalServices(updatedAvailableAdditionalServices);
    setParticipationServices(participationServicesRes);
    setParticipationAgendaSessions(nextParticipationAgendaAttendeesRes);
    hideLoader();
  };

  // Helpers
  const getStatus = (schemaFacultyResField) => {
    const rawFacultyResponse = participationServices?.[schemaFacultyResField];
    if (rawFacultyResponse) {
      const facultyResponse = JSON.parse(rawFacultyResponse);
      return facultyResponse.responseStatus;
    }
  };

  const checkAllIncludedPrefsHaveStatusPublished = (participationServices) => {
    const participationFacultyResponse = JSON.parse(
      participationServices['participationFacultyResponse']
    );
    const accreditationMaterialFacultyResponse = JSON.parse(
      participationServices['accreditationMaterialFacultyResponse']
    );
    const aifaDataFacultyResponse = JSON.parse(
      participationServices['aifaDataFacultyResponse']
    );
    const allotmentFacultyResponse = JSON.parse(
      participationServices['allotmentFacultyResponse']
    );
    const travelFacultyResponse = JSON.parse(
      participationServices['travelFacultyResponse']
    );
    const additionalServicesFacultyResponse = JSON.parse(
      participationServices['additionalServicesFacultyResponse']
    );

    const allIncludedPrefsPublished =
      participationFacultyResponse &&
      (!participationServices.accreditationMaterialIncluded ||
        (participationServices.accreditationMaterialIncluded &&
          accreditationMaterialFacultyResponse?.responseStatus ===
            constants.FacultyResponseStatus.PUBLISHED)) &&
      (!participationServices.aifaDataRequiredIncluded ||
        (participationServices.aifaDataRequiredIncluded &&
          aifaDataFacultyResponse?.responseStatus ===
            constants.FacultyResponseStatus.PUBLISHED)) &&
      (!participationServices.allotmentIncluded ||
        (participationServices.allotmentIncluded &&
          allotmentFacultyResponse?.responseStatus ===
            constants.FacultyResponseStatus.PUBLISHED)) &&
      (!participationServices.isTravelIncluded ||
        (participationServices.isTravelIncluded &&
          travelFacultyResponse?.responseStatus ===
            constants.FacultyResponseStatus.PUBLISHED)) &&
      (!participationServices.additionalServicesIncluded ||
        (participationServices.additionalServicesIncluded &&
          additionalServicesFacultyResponse?.responseStatus ===
            constants.FacultyResponseStatus.PUBLISHED));

    return allIncludedPrefsPublished;
  };

  const getTravelTrasferCardLabel = () => {
    if (!participationServices) return '';
    const {
      isTravelIncluded,
      isTransferAIncluded,
      isTransferRIncluded,
    } = participationServices;
    if (isTravelIncluded && (isTransferAIncluded || isTransferRIncluded))
      return i18n.cards.travelTransfer;
    else if (isTravelIncluded) return i18n.cards.travel;
    else if (isTransferAIncluded || isTransferRIncluded)
      return i18n.cards.transfer;
  };

  // Renders
  const FacultySection = ({ children, subtitle, title }) => (
    <FacultySectionWrapper>
      <AimTypography variant="h4Regular" style={{ textTransform: 'uppercase' }}>
        {title}
      </AimTypography>
      <AimTypography variant="text">{subtitle}</AimTypography>
      {children}
    </FacultySectionWrapper>
  );

  const renderDialogs = () => (
    <>
      <AdditionalServicesDialog
        dialogState={additionalServicesDialog}
        i18n={i18n}
        onClose={() => setAdditionalServicesDialog({ isOpen: false })}
        onSuccess={() => {
          setSnackbar({
            isOpen: true,
            severity: AimSnackbarSeverity.success,
            message: i18n.snackbar.success,
          });
          fetchData(true);
        }}
        onConflictingEvents={() =>
          setSnackbar({
            isOpen: true,
            severity: AimSnackbarSeverity.error,
            message: i18n.snackbar.errors.concurrentEvents,
          })
        }
        onError={() =>
          setSnackbar({
            isOpen: true,
            severity: AimSnackbarSeverity.error,
            message: i18n.snackbar.errors.additionalServices,
          })
        }
      />
      <AccreditationMaterialDialog
        dialogState={accreditationMaterialDialog}
        i18n={i18n}
        onClose={() => setAccreditationMaterialDialog({ isOpen: false })}
        onSuccess={() => {
          setSnackbar({
            isOpen: true,
            severity: AimSnackbarSeverity.success,
            message: i18n.snackbar.success,
          });
          fetchData(true);
        }}
        onError={() =>
          setSnackbar({
            isOpen: true,
            severity: AimSnackbarSeverity.error,
            message: i18n.snackbar.errors.accreditationMaterial,
          })
        }
      />
      <AifaDataDialog
        dialogState={aifaDataDialog}
        i18n={i18n}
        onClose={() => setAifaDataDialog({ isOpen: false })}
        onSuccess={() => {
          setSnackbar({
            isOpen: true,
            severity: AimSnackbarSeverity.success,
            message: i18n.snackbar.success,
          });
          fetchData(true);
        }}
        onError={() =>
          setSnackbar({
            isOpen: true,
            severity: AimSnackbarSeverity.error,
            message: i18n.snackbar.errors.aifaData,
          })
        }
      />
      {travelTransferDialog.isOpen && (
        <TravelTransferDialog
          dialogState={travelTransferDialog}
          i18n={i18n}
          onClose={() => setTravelTransferDialog({ isOpen: false })}
          onSuccess={() => {
            setSnackbar({
              isOpen: true,
              severity: AimSnackbarSeverity.success,
              message: i18n.snackbar.success,
            });
            fetchData(true);
          }}
          onConflictingEvents={() =>
            setSnackbar({
              isOpen: true,
              severity: AimSnackbarSeverity.error,
              message: i18n.snackbar.errors.concurrentEvents,
            })
          }
          onError={() =>
            setSnackbar({
              isOpen: true,
              severity: AimSnackbarSeverity.error,
              message: i18n.snackbar.errors.travelTransfer,
            })
          }
        />
      )}
      {allotmentDialog.isOpen && (
        <AllotmentDialog
          dialogState={allotmentDialog}
          i18n={i18n}
          onClose={() => setAllotmentDialog({ isOpen: false })}
          onSuccess={() => {
            setSnackbar({
              isOpen: true,
              severity: AimSnackbarSeverity.success,
              message: i18n.snackbar.success,
            });
            fetchData(true);
          }}
          onConflictingEvents={() =>
            setSnackbar({
              isOpen: true,
              severity: AimSnackbarSeverity.error,
              message: i18n.snackbar.errors.concurrentEvents,
            })
          }
          onError={() =>
            setSnackbar({
              isOpen: true,
              severity: AimSnackbarSeverity.error,
              message: i18n.snackbar.errors.allotment,
            })
          }
        />
      )}
      {scientificCommitmentDialog.isOpen && (
        <ScientificCommitmentDialog
          dialogState={scientificCommitmentDialog}
          i18n={i18n}
          onClose={() => setScientificCommitmentDialog({ isOpen: false })}
          onSuccess={() => {
            setSnackbar({
              isOpen: true,
              severity: AimSnackbarSeverity.success,
              message: i18n.snackbar.success,
            });
            fetchData(true);
          }}
          onError={() =>
            setSnackbar({
              isOpen: true,
              severity: AimSnackbarSeverity.error,
              message: i18n.snackbar.errors.scientificCommitment,
            })
          }
        />
      )}
    </>
  );

  const FacultyCard = ({ title, status, onClick }) => {
    return (
      <CardWrapper onClick={onClick}>
        {status && (
          <CardStatus
            style={{
              backgroundColor:
                status === constants.FacultyResponseStatus.PUBLISHED
                  ? '#29E129'
                  : theme.colors.greyScale.grey3,
            }}
          >
            <CheckIcon />
          </CardStatus>
        )}
        <AimTypography variant="h5">{title}</AimTypography>
      </CardWrapper>
    );
  };

  // send email, update with publication date and status published, no more changes can be made
  const saveAndSend = async () => {
    showLoader();
    if (
      checkAllIncludedPrefsHaveStatusPublished(participationServices) &&
      !participationServices.publicationDateOfPrefs
    ) {
      // update with date
      await updateParticipationServices({
        id: participationServices.id,
        publicationDateOfPrefs: new Date(),
        facultyStatus: constants.FacultyResponseStatus.PUBLISHED,
      });
    }
    hideLoader();
    // send automatic mail
  };

  // send email, update with status draft, changes can still be made
  const saveDraft = async () => {
    console.log('save draft');
    showLoader();
    if (
      !checkAllIncludedPrefsHaveStatusPublished(participationServices) &&
      !participationServices.publicationDateOfPrefs
    ) {
      // update with date
      await updateParticipationServices({
        id: participationServices.id,
        facultyStatus: constants.FacultyResponseStatus.DRAFT,
      });
    }
    hideLoader();
    // send automatic mail
  };

  const renderButtons = () => {
    return (
      <>
        {participationServices && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'right', // 'space-between',
              alignItems: 'center',
              margin: '10px auto',
              ...formControlStyle,
            }}
          >
            <div>
              <AimIconAndTextButton
                variant={'greenFill'}
                text={i18n.buttons.saveDraft}
                onClick={saveDraft}
                disabled={participationServices.publicationDateOfPrefs}
              >
                <CheckIcon />
              </AimIconAndTextButton>
              <AimIconAndTextButton
                variant={'greenFill'}
                text={i18n.buttons.saveAndSend}
                onClick={saveAndSend}
                disabled={
                  participationServices.publicationDateOfPrefs ||
                  !checkAllIncludedPrefsHaveStatusPublished(
                    participationServices
                  )
                }
              >
                <CheckIcon />
              </AimIconAndTextButton>
            </div>
          </div>
        )}
      </>
    );
  };

  if (!participationServices) return null;

  return (
    <FacultyContainer smDown={smDown}>
      <FacultyInnerContainer smDown={smDown}>
        <AimIconAndTextButton
          variant="none"
          text={i18n.buttons.back}
          style={{
            padding: 0,
          }}
          onClick={() => history.replace(`/events/${eventId}/landing`)}
        >
          <ArrowBack />
        </AimIconAndTextButton>
        <HeaderContainer smDown={smDown}>
          <div>
            <AimTypography variant={'h1'}>{i18n.title}</AimTypography>
            <AimTypography variant="h4Regular">
              {`${format(
                new Date(participationServices?.participation.event.start),
                'dd LLLL yyyy'
              )} - 
              ${format(
                new Date(participationServices?.participation.event.end),
                'dd LLLL yyyy'
              )}`}
            </AimTypography>
          </div>
          <div>
            <AimIconAndTextButton
              isUpperCase
              variant="yellow"
              style={{ whiteSpace: 'nowrap', marginTop: '8px' }}
              text={i18n.buttons.purchaseServices}
              onClick={() => history.push(`/events/${eventId}/landing`)}
            >
              <ArrowForward />
            </AimIconAndTextButton>
          </div>
        </HeaderContainer>

        {participationAgendaSessions?.length > 0 && (
          <FacultySection title={i18n.sections.myContributions.title}>
            <CardsWrapper>
              {participationAgendaSessions &&
                participationAgendaSessions.map((paxSession) => (
                  <SessionCard
                    key={paxSession.id}
                    i18n={i18n}
                    data={paxSession}
                  />
                ))}
            </CardsWrapper>
          </FacultySection>
        )}
        <FacultySection
          title={i18n.sections.participation.title}
          subtitle={
            participationServices.participationIncluded
              ? `${i18n.pax.subTitle1} ${i18n.pax.subTitle2}`
              : i18n.pax.subTitle2
          }
        >
          <CardsWrapper>
            <ParticipationConfirm
              servicesState={{
                eventId,
                data: participationServices,
              }}
              i18n={i18n}
              // onCancel={}
              onSuccess={() => {
                setSnackbar({
                  isOpen: true,
                  severity: AimSnackbarSeverity.success,
                  message: i18n.snackbar.success,
                });
                fetchData();
              }}
              onError={() =>
                setSnackbar({
                  isOpen: true,
                  severity: AimSnackbarSeverity.error,
                  message: i18n.snackbar.errors.aifaData,
                })
              }
            />
          </CardsWrapper>
        </FacultySection>

        {/* // TODO: Conditionally show cards based on schema field */}
        <FacultySection
          title={i18n.sections.participationDetails.title}
          subtitle={i18n.sections.participationDetails.subtitle}
        >
          <CardsWrapper>
            {/* {participationServices?.participationScientificCommitment &&
              (participationServices?.participationReportUpload ||
                participationServices?.participationPresentationUpload) && (
                <FacultyCard
                  title="Scientific Commitment"
                  status={getStatus('participationFacultyResponse')}
                  onClick={() =>
                    setScientificCommitmentDialog({
                      isOpen: true,
                      eventId,
                      data: participationServices,
                    })
                  }
                />
              )} */}
            {participationServices?.accreditationMaterialIncluded && (
              <FacultyCard
                title="Accreditation Material"
                status={getStatus('accreditationMaterialFacultyResponse')}
                onClick={() =>
                  setAccreditationMaterialDialog({
                    isOpen: true,
                    eventId,
                    data: participationServices,
                    accreditationMaterialDescription: participationServices?.accreditationMaterialDescription
                      ? JSON.parse(
                          participationServices?.accreditationMaterialDescription
                        )
                      : '',
                    accreditationMaterialTemplates: participationServices?.accreditationMaterialTemplates
                      ? JSON.parse(
                          participationServices?.accreditationMaterialTemplates
                        )
                      : '',
                  })
                }
              />
            )}
            {participationServices?.aifaDataRequiredIncluded && (
              <FacultyCard
                title="Aifa Data"
                status={getStatus('aifaDataFacultyResponse')}
                onClick={() =>
                  setAifaDataDialog({
                    isOpen: true,
                    eventId,
                    data: participationServices,
                    aifaDataDescription: participationServices?.aifaDataDescription
                      ? JSON.parse(participationServices?.aifaDataDescription)
                      : '',
                  })
                }
              />
            )}
          </CardsWrapper>
        </FacultySection>

        <FacultySection
          title={i18n.sections.servicesAndPreferences.title}
          subtitle={i18n.sections.servicesAndPreferences.subtitle}
        >
          <CardsWrapper>
            {(participationServices?.isTravelIncluded ||
              participationServices?.isTransferAIncluded ||
              participationServices?.isTransferRIncluded) && (
              <FacultyCard
                title={getTravelTrasferCardLabel()}
                status={getStatus('travelFacultyResponse')}
                onClick={() =>
                  setTravelTransferDialog({
                    isOpen: true,
                    eventId,
                    participationServices,
                  })
                }
              />
            )}
            {participationServices?.allotmentIncluded && (
              <FacultyCard
                title={i18n.cards.allotment}
                status={getStatus('allotmentFacultyResponse')}
                onClick={() =>
                  setAllotmentDialog({
                    isOpen: true,
                    eventId,
                    participationServices,
                  })
                }
              />
            )}
            {participationServices?.additionalServicesIncluded && (
              <FacultyCard
                title={i18n.cards.additionalServices}
                status={getStatus('additionalServicesFacultyResponse')}
                onClick={() =>
                  setAdditionalServicesDialog({
                    isOpen: true,
                    eventId,
                    data: {
                      availableAdditionalServices,
                      participationServices,
                    },
                  })
                }
              />
            )}
          </CardsWrapper>
        </FacultySection>
        {renderButtons()}
      </FacultyInnerContainer>
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
      {renderDialogs()}
    </FacultyContainer>
  );
};

export default Faculty;
