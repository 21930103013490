import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import ArrowForward from '@material-ui/icons/ArrowForward';

import { appState, constants } from '@aim/common';
import {
  AimTypography,
  AimIconAndTextButton,
  CustomIntl,
} from '@aim/components';
import { translation } from './grant/index';

import { useWelcomePageModulesRedirects } from '../hooks/useWelcomePageModulesRedirects';
import { useEffect } from 'react';

const GrantSubscribeSuccess = () => {
  const { getServiceRedirectLinkEvent } = useWelcomePageModulesRedirects();

  const [participation] = useState(
    appState.user.getValue()?.userAndParticipation?.participation
  );
  const history = useHistory();
  const { eventId } = useParams();
  const intl = CustomIntl(useIntl());
  const i18n = translation.subscribeSuccess(intl);

  useEffect(() => {
    const link = history.location.state?.grantType
      ? getServiceRedirectLinkEvent(
          history.location.state?.grantType === constants.GrantType.ABSTRACT.key
            ? 'AbstractGrant'
            : 'EducationalGrant'
        )
      : null;
    link && history.push(link);
  }, []);

  return (
    <>
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            width: '60%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <AimTypography variant="h2Regular" margin={0} textAlign="center">
            {i18n.message}
          </AimTypography>
          <AimTypography variant="h2Regular" margin={0} textAlign="center">
            {i18n.subscribeStatusMessage}
          </AimTypography>
        </div>
        <Grid container style={{ width: '60%' }} spacing={1}>
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <AimIconAndTextButton
              isUpperCase
              text={i18n.goToPersonalArea}
              onClick={() =>
                history.push(`/events/${eventId}/user/${participation.id}`)
              }
              style={{ width: '100%' }}
            >
              <ArrowForward />
            </AimIconAndTextButton>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <AimIconAndTextButton
              isUpperCase
              text={i18n.goToServices}
              onClick={() =>
                history.push(
                  `/events/${eventId}/user/${participation.id}/services`
                )
              }
              style={{ width: '100%' }}
            >
              <ArrowForward />
            </AimIconAndTextButton>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default GrantSubscribeSuccess;
