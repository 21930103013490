import React from 'react';
import { useIntl } from 'react-intl';

import { styled, AimTypography, CustomIntl } from '@aim/components';

import { HomeContainer } from './home/style';

// import { translation } from './ePoster/translation';
import { useState } from 'react';
import { useEffect } from 'react';
import { appState, constants } from '@aim/common';
import { getEventConfigurationPageTitle } from '../shared/utilities';
import { isMobileOnly } from 'react-device-detect';

const Container = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
});

const EPoster = () => {
  // i18n
  const intl = CustomIntl(useIntl());
  // const i18n = translation.ePoster(intl);

  // State
  const [eventConfiguration] = useState(appState.eventConfiguration.getValue());
  const [pageTitle, setPageTitle] = useState('');
  const [ePosterLink, setEPosterLink] = useState('');

  useEffect(() => {
    const menuItemLabel = getEventConfigurationPageTitle(
      eventConfiguration?.eventGenericMenuItems?.items || [],
      intl,
      constants.EventGenericMenuItems.E_POSTER.key
    );
    setPageTitle(menuItemLabel);
    setEPosterLink(eventConfiguration?.ePosterLink);
  }, [eventConfiguration]);

  return (
    <>
      <HomeContainer>
        <Container>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flex: 1,
            }}
          >
            <div
              style={{
                width: isMobileOnly ? '100%' : '70%',
                height: '80vh',
              }}
            >
              <AimTypography variant="h1">{pageTitle}</AimTypography>
              {ePosterLink ? (
                <iframe
                  src={ePosterLink}
                  frameBorder="0"
                  style={{
                    border: 0,
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    width: '100%',
                    height: '100%',
                  }}
                  allowFullScreen
                />
              ) : null}
            </div>
          </div>
        </Container>
      </HomeContainer>
    </>
  );
};

export default EPoster;
