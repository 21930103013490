import { aws, appState } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const createPublicPageMutation = /* GraphQL */ `
  mutation CreatePublicPage(
    $input: CreatePublicPageInput!
    $condition: ModelPublicPageConditionInput
  ) {
    createPublicPage(input: $input, condition: $condition) {
      id
    }
  }
`;

const updatePublicPageMutation = /* GraphQL */ `
  mutation UpdatePublicPage(
    $input: UpdatePublicPageInput!
    $condition: ModelPublicPageConditionInput
  ) {
    updatePublicPage(input: $input, condition: $condition) {
      id
    }
  }
`;

export function usePublicPage() {
  const create = (input, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: createPublicPageMutation,
        variables: { input },
      })
        .then((response) => resolve(response.data.createPublicPage))
        .catch((e) => {
          console.error('create-public-page', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const update = (input, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: updatePublicPageMutation,
        variables: { input },
      })
        .then((response) => resolve(response.data.updatePublicPage))
        .catch((e) => {
          console.error('update-public-page', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  return { create, update };
}
