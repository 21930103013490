import React from 'react';
import { AimIconAndTextButton, useAimMediaQuery } from '@aim/components';

const FormButtons = ({ navigateBack, i18n }) => {
  const { isMobile } = useAimMediaQuery();
  return (
    <div
      style={{
        width: '100%',
        padding: !isMobile ? 5 : '0px 5px',
        alignItems: !isMobile ? 'end' : 'center',
        justifyContent: !isMobile ? 'space-between' : 'center',
        display: 'flex',
        flexDirection: !isMobile ? 'row-reverse' : 'column',
      }}
    >
      <AimIconAndTextButton
        text={i18n.actions.save.toUpperCase()}
        type="submit"
        style={{ width: isMobile ? '100%' : 200 }}
      ></AimIconAndTextButton>
      <AimIconAndTextButton
        text={i18n.actions.cancel.toUpperCase()}
        variant="secondary"
        style={{ width: isMobile ? '100%' : 200 }}
        onClick={() => navigateBack()}
      ></AimIconAndTextButton>
    </div>
  );
};

export default FormButtons;
