import React from 'react';

import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import VisibilityIcon from '@material-ui/icons/Visibility';

import {
  AimTableRow,
  AimTableCell,
  AimTableNoPaging,
  AimIconButton,
} from '@aim/components';
import { constants } from '@aim/common';

import { useDataHelper } from './../../../shared/dataHelper';

const PhysicalStandItemsTable = ({
  intl,
  items,
  i18n,
  showInfoDialog,
  onAddToCart,
}) => {
  //Hooks
  const { decodeDbNumber, formatNumber, vatCalc } = useDataHelper();

  // Columns
  const headCells = [
    {
      id: 'service',
      numeric: false,
      disablePadding: false,
      label: i18n.table.columns.service.label,
    },
    // {
    //   id: 'type',
    //   numeric: false,
    //   disablePadding: false,
    //   label: i18n.table.columns.type.label,
    // },
    {
      id: 'availability',
      numeric: false,
      disablePadding: false,
      label: i18n.table.columns.availability.label,
    },
    {
      id: 'netPrice',
      numeric: false,
      disablePadding: false,
      label: i18n.table.columns.netPrice.label,
    },
    {
      id: 'vatRate',
      numeric: false,
      disablePadding: false,
      label: i18n.table.columns.vatRate.label,
    },
    {
      id: 'vatPrice',
      numeric: false,
      disablePadding: false,
      label: i18n.table.columns.totalPrice.label,
    },
    {
      id: 'buttons',
      numeric: false,
      disablePadding: false,
      label: '',
    },
  ];

  //Functions
  const Row = ({ row, rowKey }) => {
    const { purchaseDisabled } = row;

    const formattedRow = {
      ...row,
      // unlimited if no quantity, soldout if bookable is 0 or less and bookable otherwise
      quantityLabel: !row.availability
        ? i18n.table.unlimitedQuantity
        : row.bookableQuantity <= 0
        ? i18n.table.soldOutQuantity
        : row.bookableQuantity,
      type: constants.OtherStandServiceItemType[row.type].label(intl),
      netPrice: formatNumber(decodeDbNumber(row?.netPrice)),
      vatRate: decodeDbNumber(row.vatRate),
      totalAmount: formatNumber(
        vatCalc(decodeDbNumber(row?.netPrice), decodeDbNumber(row?.vatRate))
      ),
    };

    return (
      <>
        <AimTableRow hover>
          <AimTableCell>{formattedRow?.name}</AimTableCell>
          {/* <AimTableCell>{row?.itemSubCategory?.name}</AimTableCell> */}
          {/* <AimTableCell>{formattedRow.type}</AimTableCell> */}
          <AimTableCell>{formattedRow.quantityLabel}</AimTableCell>
          <AimTableCell>{formattedRow.netPrice}</AimTableCell>
          <AimTableCell>{`${formattedRow.vatRate} %`}</AimTableCell>
          <AimTableCell>{formattedRow.totalAmount}</AimTableCell>
          <AimTableCell style={{ display: 'flex', justifyContent: 'right' }}>
            <AimIconButton
              variant="lightBlueFill"
              small
              onClick={() => showInfoDialog(row)}
            >
              <VisibilityIcon />
            </AimIconButton>
            <AimIconButton
              variant="yellowFill"
              small
              onClick={() => onAddToCart(row, true)}
              disabled={purchaseDisabled}
            >
              <SwapHorizIcon />
            </AimIconButton>
            <AimIconButton
              variant="violetFill"
              small
              onClick={() => onAddToCart(row)}
              disabled={purchaseDisabled}
            >
              <ShoppingCartIcon />
            </AimIconButton>
          </AimTableCell>
        </AimTableRow>
      </>
    );
  };

  return (
    <>
      <AimTableNoPaging
        headCells={headCells}
        rows={items}
        notCheckable={true}
        rowKey="id"
      >
        <Row />
      </AimTableNoPaging>
    </>
  );
};

export { PhysicalStandItemsTable };
