/* Login page */
import React, { useState } from 'react';
import ForceChangePassword from './ForceChangePassword';
import StandardLogin from './StandardLogin';

const SignIn = () => {
  const [window, setWindow] = useState('LOGIN');
  const [user, setUser] = useState();

  return window === 'NEW_PASSWORD_REQUIRED' ? (
    <ForceChangePassword config={{ user, setWindow }} />
  ) : (
    <StandardLogin config={{ setWindow, setUser }} />
  );
};

export default SignIn;
