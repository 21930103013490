import React, { useState, useEffect } from 'react';

import { useIntl } from 'react-intl';

import { addDays } from 'date-fns';

import { useHistory, useParams } from 'react-router-dom';

import Tooltip from '@material-ui/core/Tooltip';
import ArrowBack from '@material-ui/icons/ArrowBack';
import GetAppIcon from '@material-ui/icons/GetApp';
import CheckIcon from '@material-ui/icons/Check';

import {
  MainContainer,
  InnerContainer,
} from '../shared/SponsorPagesContainers';

import { aws, appState, constants } from '@aim/common';

import { PhysicalStandFloorsDialogInfoContent } from './physicalStandFloorsList/PhysicalStandFloorsDialogInfoContent';

import { usePhysicalStandFloorService } from '../shared/physicalStandFloorGqlHelper';
import { useBuyOperations } from '../shared/buyOperationsGqlHelper';
import { useDataHelper } from '../shared/dataHelper';

import {
  AimSnackbar,
  AimTypography,
  AimSnackbarSeverity,
  CustomIntl,
  AimIconAndTextButton,
  AimDialog,
} from '@aim/components';
import { PhysicalStandFloorGrid } from './physicalStandFloorsList/PhysicalStandFloorGrid';
import translation from './../shared/translation';

const PhysicalStandFloorsList = () => {
  //Hooks
  const history = useHistory();
  const { eventId, sponsorId, floorId } = useParams();

  const { create: createBuyOperation } = useBuyOperations();
  const {
    list,
    update: updatePhysicalStand,
    onUpdateSpace,
    onDeleteSpace,
  } = usePhysicalStandFloorService();
  const { decodeDbNumber, physicalStandSpacePriceCalc } = useDataHelper();
  const intl = CustomIntl(useIntl());
  const i18n = translation.physicalStandFloorsList(intl);

  //State
  const [displaySpaces, setDisplaySpaces] = useState([]);
  const [physicalStandFloor, setPhysicalStandFloor] = useState();
  const [paymentType, setPaymentType] = useState();
  const [bookingDuration, setBookingDuration] = useState();
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [dialog, setDialog] = useState({ isOpen: false });

  //Effects
  useEffect(() => {
    fetchData();
  }, []);

  // useEffect(() => {
  //   const subscriptionUpdate = onUpdateSpace(subscriptionCallback);
  //   const subscriptionDelete = onDeleteSpace(subscriptionCallback);
  //   return () => {
  //     subscriptionUpdate.unsubscribe();
  //     subscriptionDelete.unsubscribe();
  //   };
  // });

  //Functions
  // const subscriptionCallback = (data) => {
  //   if (displaySpaces.find((s) => s.id === data.id)) {
  //     fetchData();
  //   }
  // };

  const fetchData = async () => {
    const results = await list(floorId);

    const spaces = results.spaces?.items?.map((s) => {
      const priceData = physicalStandSpacePriceCalc({
        netPrice: decodeDbNumber(s.netPrice),
        vatRate: decodeDbNumber(results.physicalStandService.vatRate),
        noVat: s.noVat,
        extraPriceDisabled: s.extraPriceDisabled,
        extraPriceRate: decodeDbNumber(
          results.physicalStandService.extraPriceRate
        ),
        extraPriceDate: results.physicalStandService.extraPriceDate,
      });
      return {
        id: s.id,
        code: s.code,
        name: s.name,
        netPrice: priceData.applyExtraPrice
          ? priceData.extraNetPrice
          : priceData.netPrice,
        vatRate: priceData.vatRate,
        taxedPrice: priceData.applyExtraPrice
          ? priceData.extraVatPrice
          : priceData.vatPrice,
        purchasedBy: s?.buyOperation?.sponsor?.name,
        buyOperation: s?.buyOperation,
        plantImage: s?.plantImage,
        surface: decodeDbNumber(s?.surface),
        spaceLength: decodeDbNumber(s.spaceLength),
        spaceHeight: decodeDbNumber(s.spaceHeight),
        spaceDepth: decodeDbNumber(s.spaceDepth),
        note: s?.note,
      };
    });

    setDisplaySpaces(spaces);
    setPhysicalStandFloor(results);
    setBookingDuration(
      results.physicalStandService.event.sponsorServiceBookingDuration || 10
    );

    const paymentType =
      results.physicalStandService.isCreditCardEnabled &&
      results.physicalStandService.isBankTransferEnabled
        ? 'both'
        : (results.physicalStandService.isCreditCardEnabled &&
            constants.PaymentTypes.CreditCard) ||
          (results.physicalStandService.isBankTransferEnabled &&
            constants.PaymentTypes.BankTransfer);
    setPaymentType(paymentType);
  };

  const showLoader = () => appState.isLoader.next(true);

  const hideLoader = () => appState.isLoader.next(false);

  const onAddToCart = async (spaceId, purchaseNow = false) => {
    showLoader();
    try {
      const bookingDate = new Date();
      const bookingExpiringDate = addDays(bookingDate, bookingDuration);
      const inputBuyOperation = {
        eventId: eventId,
        buyOperationPhysicalStandSpaceId: spaceId,
        buyOperationSponsorId: sponsorId,
        date: bookingDate,
        expiringDate: bookingExpiringDate,
        paymentType: paymentType,
        isExpired: 'false',
        isDeleted: 'false',
      };

      const response = await createBuyOperation(inputBuyOperation, false);

      const inputPhysicalStand = {
        id: spaceId,
        physicalStandSpaceBuyOperationId: response.id,
      };

      await updatePhysicalStand(inputPhysicalStand, false);

      const destUrl = `/events/${eventId}/${sponsorId}/cart`;
      if (purchaseNow) {
        history.push({
          pathname: destUrl,
          state: {
            bookings: [response],
          },
        });
      } else {
        await fetchData();
      }
    } catch (e) {
      console.error(e);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.snackbar.addToCartFail.label,
      });
    } finally {
      hideLoader();
    }
  };

  const downloadPlantImage = async (item) => {
    const s3Folder = `events/${eventId}/sponsor/physical-stand/plant-images/${physicalStandFloor?.id}/${item.id}`;
    const link = `${s3Folder}${item.plantImage.id}${item.plantImage.extension}`;

    var res = await aws.Storage.get(link);
    window.open(res);
  };

  return (
    <>
      <MainContainer>
        <InnerContainer grow bottomSpacing>
          <div style={{ display: 'flex-root', marginTop: 20 }}>
            <Tooltip title={i18n.page.back.tooltip.label}>
              <AimIconAndTextButton
                variant="text"
                text={i18n.page.back.button.label}
                onClick={() =>
                  history.push(
                    `/events/${eventId}/${sponsorId}/my-services/physical-stand`
                  )
                }
              >
                <ArrowBack />
              </AimIconAndTextButton>
            </Tooltip>
          </div>

          <AimTypography variant={'h1'}>
            {physicalStandFloor?.name}
          </AimTypography>

          <PhysicalStandFloorGrid
            i18n={i18n}
            items={displaySpaces}
            setDialog={setDialog}
            onAddToCart={onAddToCart}
            paymentType={paymentType}
          />

          <AimSnackbar
            open={snackbar.isOpen}
            onClose={() => setSnackbar({ isOpen: false })}
            severity={snackbar.severity}
          >
            {snackbar.message}
          </AimSnackbar>
        </InnerContainer>
      </MainContainer>
      <AimDialog
        title={dialog?.row?.name}
        open={dialog?.isOpen}
        onClose={() => setDialog({ isOpen: false })}
        customActions={
          <div style={{ display: 'flex', width: '100%' }}>
            {dialog?.row?.plantImage && (
              <AimIconAndTextButton
                text={i18n.dialog.actions.plantImage.label}
                onClick={() => downloadPlantImage(dialog?.row)}
                variant="lightBlueFill"
              >
                <GetAppIcon />
              </AimIconAndTextButton>
            )}
            <div style={{ flexGrow: 1 }} />
            <AimIconAndTextButton
              text={i18n.dialog.actions.close.label}
              onClick={() => setDialog({ isOpen: false })}
              variant="greenFill"
            >
              <CheckIcon />
            </AimIconAndTextButton>
          </div>
        }
      >
        <PhysicalStandFloorsDialogInfoContent i18n={i18n} item={dialog?.row} />
      </AimDialog>
    </>
  );
};

export default PhysicalStandFloorsList;
