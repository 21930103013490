export default function (intl) {
  return {
    cards: {
      reviewer: intl.formatMessage({
        description: 'Reviewer card title',
        defaultMessage: 'Reviewer',
      }),
      participant: intl.formatMessage({
        description: 'Submitter card title',
        defaultMessage: 'Submitter',
      }),
      presenter: intl.formatMessage({
        description: 'Presenter card title',
        defaultMessage: 'Presenter',
      }),
    },
    page: {
      title: intl.formatMessage({
        description: 'Page title',
        defaultMessage: 'Welcome!',
      }),
      subtitle: intl.formatMessage({
        description: 'Page subtitle',
        defaultMessage: 'Select the role to log in with:',
      }),
      theQuestionWelcome: intl.formatMessage({
        description: 'welcome text',
        defaultMessage: 'Welcome!',
      }),
      theQuestionText: intl.formatMessage({
        description: 'the question text',
        defaultMessage:
          'You have been nominated reviewer, do you want to accept the nomination? You can choose to log in as a participant or as a reviewer when you enter the abstract form.',
      }),
      accept: intl.formatMessage({
        description: 'abstract reviewer accept role',
        defaultMessage: 'Accept',
      }),
      reject: intl.formatMessage({
        description: 'abstract reviewer reject role',
        defaultMessage: 'Reject',
      }),
    },
  };
}
