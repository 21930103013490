import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { differenceInDays, format } from 'date-fns';

import { constants } from '@aim/common';
import translation from './translation';

import ReplayIcon from '@material-ui/icons/Replay';
import CheckIcon from '@material-ui/icons/Check';

import Grid from '@material-ui/core/Grid';

import {
  AimRadioForm,
  AimFormControlLabel,
  AimRadioButton,
  AimTypography,
  AimRichText,
  AimIconAndTextButton,
  styled,
  CustomIntl,
  useDataHelper,
} from '@aim/components';

import {
  updateParticipationServices,
  getHotelEventVentureRooms,
  getRoom,
  getEventAllotmentDateRange,
} from './allotmentGqlHelper';
import { appState } from '@aim/common';
import { Accomodation } from './Accomodation';

//Constants
const formControlStyle = { width: 'calc(100% - 15px)' };

const InnerContainer = styled('div')({
  padding: 20,
});

const Container = styled('div')({
  backgroundColor: 'white',
  margin: '10px 0px',
  display: 'flex',
});

const ButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  margin: 10,
});

const AllotmentPrefs = ({
  participationServices,
  allotmentData,
  onSuccess,
  onError,
  onClose,
}) => {
  const intl = CustomIntl(useIntl());
  const i18n = translation.allotment(intl);
  const { parseRichText } = useDataHelper();
  const { eventId } = useParams();
  // const history = useHistory();
  const participationId = appState.user.getValue().userAndParticipation
    .participation.id;
  const [userParticipation, setUserParticipation] = useState();

  const [roomTypePref, setRoomTypePref] = useState();
  const [accomodationData, setAccomodationData] = useState();
  const [errors, setErrors] = useState('');
  // const [radioPanel, setRadioPanel] = useState(0);
  const { control, handleSubmit, reset, setValue, getValues, watch } = useForm({
    defaultValues: {
      allotmentId: undefined,
      // chooseBooking: 0,
      // radioPanel: 0,
    },
  });

  const chooseAuthBookingWatcher = watch('chooseAuthBooking');

  const checkError = () => {
    if (!chooseAuthBookingWatcher) {
      setErrors(i18n.page.makeYourChoice);
      return true;
    }
    return false;
  };

  const onSave = async (data, status) => {
    // console.log('input ', input);
    if (checkError()) return;

    delete data.chooseAuthBooking;
    delete data.chooseNoBooking;
    const input = {
      id: participationServices.id,
      allotmentFacultyResponse: JSON.stringify({
        ...data,
        chooseBooking: chooseAuthBookingWatcher,
        bookByAuth: chooseAuthBookingWatcher === '0',
        noBook: chooseAuthBookingWatcher === '1',
        responseStatus: status, // constants.FacultyResponseStatus.PUBLISHED,
      }),
    };

    try {
      if (input?.id) {
        await updateParticipationServices(input);
      }
      onClose();
      onSuccess();
    } catch (err) {
      console.error('err', err);
      onError();
    }
  };

  useEffect(() => {
    const getData = async () => {
      const [hotelAndRooms, roomType, allotmentDateRange] = await Promise.all([
        getHotelEventVentureRooms(participationServices?.allotmentHotel),
        getRoom(participationServices?.allotmentRoomType),
        getEventAllotmentDateRange(eventId),
      ]);
      setAccomodationData({
        hotelAndRooms,
        allotmentDateRange: {
          start: allotmentDateRange?.start
            ? format(new Date(allotmentDateRange.start), 'yyyy-MM-dd')
            : null,
          end: allotmentDateRange?.end
            ? format(new Date(allotmentDateRange.end), 'yyyy-MM-dd')
            : null,
        },
      });
      setRoomTypePref(roomType);
    };

    // setRadioPanel(
    //   allotmentData.chooseBooking && parseInt(allotmentData.chooseBooking, 10)
    // );

    getData();

    const nextAllotmentData = {
      ...allotmentData,
      chooseAuthBooking: allotmentData.chooseBooking,
      chooseNoBooking: allotmentData.chooseBooking,
    };
    reset(nextAllotmentData);
  }, []);

  // Helpers
  const handleRadio = (e) => {
    // const res = parseInt(e.target.value, 10);
    // // setRadioPanel(res);
    setValue('chooseAuthBooking', e.target.value.toString());
    setValue('chooseNoBooking', e.target.value.toString());
  };

  const days = differenceInDays(
    new Date(participationServices?.allotmentCheckOutDate),
    new Date(participationServices?.allotmentCheckInDate)
  );

  return (
    <InnerContainer>
      <Container style={{ flexDirection: 'column' }}>
        <div
          style={{
            zIndex: 10,
            backgroundColor: 'white',
          }}
        >
          {/* <AimTypography variant="h3">
            {i18n.page.allotment.title}
          </AimTypography> */}

          <AimRichText
            value={parseRichText(participationServices?.allotmentPreferences)}
            readOnly
            variant="white"
            editorStyleOverride={{
              minHeight: 'auto',
              maxHeight: 'fit-content',
              overflow: 'hidden !important',
            }}
          />
        </div>
        <div style={{ marginBottom: 40 }}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <AimTypography variant="textBold">
                {i18n.page.allotmentTable.columns.hotel}
              </AimTypography>
            </Grid>
            <Grid item xs={3}>
              <AimTypography variant="textBold">
                {i18n.page.allotmentTable.columns.numberNightsIncluded}
              </AimTypography>
            </Grid>
            <Grid item xs={2}>
              <AimTypography variant="textBold">
                {i18n.page.allotmentTable.columns.roomType}
              </AimTypography>
            </Grid>
            <Grid item xs={2}>
              <AimTypography variant="textBold">
                {i18n.page.allotmentTable.columns.checkInDate}
              </AimTypography>
            </Grid>
            <Grid item xs={2}>
              <AimTypography variant="textBold">
                {i18n.page.allotmentTable.columns.checkOutDate}
              </AimTypography>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={3}>
              <AimTypography variant="text">
                {accomodationData?.hotelAndRooms?.hotel.name}
              </AimTypography>
            </Grid>
            <Grid item xs={3}>
              <AimTypography variant="text">{days}</AimTypography>
            </Grid>
            <Grid item xs={2}>
              <AimTypography variant="text">
                {roomTypePref?.frontofficeName}
              </AimTypography>
            </Grid>
            <Grid item xs={2}>
              <AimTypography variant="text">
                {format(
                  new Date(participationServices?.allotmentCheckInDate),
                  'dd/MM/yyyy'
                )}
              </AimTypography>
            </Grid>
            <Grid item xs={2}>
              <AimTypography variant="text">
                {format(
                  new Date(participationServices?.allotmentCheckOutDate),
                  'dd/MM/yyyy'
                )}
              </AimTypography>
            </Grid>
          </Grid>
        </div>
        <div>
          <AimTypography variant="textBold" boxStyle={{ margin: '0px 10px' }}>
            {i18n.page.generalPrefs}
          </AimTypography>

          {/* auth radio */}
          <div
            style={{
              display: 'flex',
              marginLeft: 16,
              marginTop: 10,
              alignItems: 'center',
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <AimRadioForm
                  control={control}
                  name="chooseAuthBooking"
                  // defaultValue={radioPanel}
                  style={{ display: 'flex' }}
                  onChange={handleRadio}
                  // value={radioPanel}
                >
                  <AimFormControlLabel
                    key={'0'}
                    value={'0'}
                    control={<AimRadioButton />}
                    label={i18n.page.authBooking}
                  />
                </AimRadioForm>
              </Grid>
            </Grid>
          </div>
          {chooseAuthBookingWatcher === '0' && (
            <>
              {accomodationData && (
                <Accomodation
                  i18n={i18n}
                  formControlStyle={formControlStyle}
                  control={control}
                  intl={intl}
                  isEditable={true}
                  // rooms={hotelRooms?.hotel?.rooms.items}
                  data={accomodationData}
                />
              )}
            </>
          )}

          {/* no auth radio */}
          <div
            style={{
              display: 'flex',
              marginLeft: 16,
              marginTop: 10,
              alignItems: 'center',
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <AimRadioForm
                  control={control}
                  name="chooseNoBooking"
                  // defaultValue={radioPanel}
                  style={{ display: 'flex' }}
                  onChange={handleRadio}
                  // value={radioPanel}
                >
                  <AimFormControlLabel
                    key={'1'}
                    value={'1'}
                    control={<AimRadioButton />}
                    label={i18n.page.noBooking}
                  />
                </AimRadioForm>
              </Grid>
            </Grid>
          </div>
          {chooseAuthBookingWatcher === '1' && <></>}
        </div>
        {chooseAuthBookingWatcher === '0' && (
          <div style={{ marginTop: 30 }}>
            <AimTypography variant="text">
              {i18n.page.requestForDiffers}
            </AimTypography>
          </div>
        )}

        <div style={{ color: 'red', marginLeft: 20 }}>
          {errors !== '' && <AimTypography>{errors}</AimTypography>}
        </div>

        <ButtonContainer>
          <AimIconAndTextButton
            onClick={onClose}
            variant="greyFill"
            text={i18n.page.cancel}
            // type="submit"
          >
            <ReplayIcon />
          </AimIconAndTextButton>
          <AimIconAndTextButton
            text={i18n.page.save}
            variant="greenFill"
            // type="submit"
            // onClick={handleSubmit(onSave)}
            onClick={() => {
              handleSubmit(
                onSave(getValues(), constants.FacultyResponseStatus.PUBLISHED)
              );
            }}
          >
            <CheckIcon />
          </AimIconAndTextButton>
          <AimIconAndTextButton
            text={i18n.page.saveDraft}
            variant="greenFill"
            onClick={() => {
              onClose();
              handleSubmit(
                onSave(getValues(), constants.FacultyResponseStatus.DRAFT)
              );
            }}
          >
            <CheckIcon />
          </AimIconAndTextButton>
        </ButtonContainer>
      </Container>
    </InnerContainer>
  );
};

export default AllotmentPrefs;
