import React from 'react';
import PropTypes from 'prop-types';

import { AimIconAndTextButton, AimTypography, styled } from '@aim/components';

import { InnerContainer } from './../SponsorPagesContainers';

import Tooltip from '@material-ui/core/Tooltip';
import ArrowBack from '@material-ui/icons/ArrowBack';

const HeaderContainer = styled(InnerContainer)({
  marginBottom: 20,
  marginTop: 20,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

function TitleAndBackButton({
  backOnClickFunction,
  backOnHoverText,
  title,
  subtitle = false,
  actionButton = false,
  style,
}) {
  return (
    <HeaderContainer style={style}>
      <div style={{ width: '5%' }}>
        <Tooltip title={backOnHoverText}>
          <AimIconAndTextButton variant="none" onClick={backOnClickFunction}>
            <ArrowBack />
          </AimIconAndTextButton>
        </Tooltip>
      </div>
      <div style={{ width: actionButton ? '75%' : '95%', textAlign: 'center' }}>
        <AimTypography margin={0} variant="h1">
          {title}
        </AimTypography>
        {subtitle && (
          <AimTypography margin={0} variant="text">
            {subtitle}
          </AimTypography>
        )}
      </div>
      {actionButton && (
        <div style={{ width: '20%', textAlign: 'right' }}>{actionButton}</div>
      )}
    </HeaderContainer>
  );
}

TitleAndBackButton.propTypes = {
  backOnClickFunction: PropTypes.func.isRequired,
  backOnHoverText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  actionButton: PropTypes.element,
  style: PropTypes.object,
};

export default TitleAndBackButton;
