import { aws, appState, constants } from '@aim/common';
import { format } from 'date-fns';
const { API } = aws;
const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

/**
 * downloadFromS3
 */
export const downloadFromS3 = async (file, subFolder, eventId, sponsorId) => {
  const link = await getFullBucketPath(eventId, sponsorId, subFolder, file);
  window.open(link);
};
/**
 * Parse richText string to JSON
 */
export const parseRichText = (str) => {
  if (str) {
    try {
      const jsonParsed = JSON.parse(str);
      return jsonParsed.blocks[0].text ? jsonParsed : null;
    } catch (e) {
      console.error(e);
    }
  }
  return null;
};
/**
 * Fomrat Date
 */
export const getFormattedDate = (dateString, formatString = 'dd/MM/yyyy') => {
  const date = new Date(dateString);
  return format(date, formatString);
};
/**
 * Handling SponsorsContact
 */
export const getFullBucketPath = async (
  eventId,
  sponsorId,
  directory,
  file
) => {
  const s3FolderBase = `events/${eventId}/sponsor/frontoffice/${sponsorId}`;
  const handleDirectory = directory ? `${directory}/` : '';
  return await aws.Storage.get(
    `${s3FolderBase}/${handleDirectory}${file.id}${file.extension}`
  );
};

/**
 * Handling Sponsor ProfileImage, CoverImage, Documents
 */
export const createFile = /* GraphQL */ `
  mutation CreateFile(
    $input: CreateFileInput!
    $condition: ModelFileConditionInput
  ) {
    createFile(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteFile = /* GraphQL */ `
  mutation DeleteFile(
    $input: DeleteFileInput!
    $condition: ModelFileConditionInput
  ) {
    deleteFile(input: $input, condition: $condition) {
      id
    }
  }
`;

const getProductByClientIdServiceTypeServiceIdQuery = /* GraphQL */ `
  query getProductByClientIdServiceTypeServiceId(
    $clientId: String
    $serviceId: String
    $serviceType: String
  ) {
    productByClientIdServiceTypeServiceId(
      clientId: $clientId
      serviceTypeServiceId: {
        eq: { serviceId: $serviceId, serviceType: $serviceType }
      }
    ) {
      items {
        id
        productId
        clientId
        clientType
        serviceId
        serviceType
        createdAt
        payment {
          id
          serviceId
          serviceType
        }
      }
    }
  }
`;

// const getParticipationServicesQuery = /* GraphQL */ `
//   query getParticipationServices($id: ID!) {
//     getParticipation(id: $id) {
//       id
//       email
//       givenName
//       familyName
//       phone
//       profile {
//         id
//         name
//       }
//       guests {
//         items {
//           id
//           givenName
//           familyName
//           country
//         }
//       }
//     }
//   }
// `;

const getProductsByClientIdServiceIdQuery = /* GraphQL */ `
  query getProductsByClientIdServiceId($clientId: String) {
    productByClientIdServiceId(
      clientId: $clientId
      filter: { isPublished: { eq: "true" } }
    ) {
      items {
        id
        productId
        clientId
        clientType
        serviceId
        serviceType
        createdAt
        isPublished
        payment {
          id
          paymentId
          paymentType
          paymentIdMonetaOnLine
          paymentIdIngenico
          paymentIdGpWebpay
          paymentError
          amount
          paymentStatus
        }
      }
    }
  }
`;

const getOthersParticipantAvailableServicesQuery = /* GraphQL */ `
  query GetOthersParticipantAvailableServices($id: ID!) {
    getParticipation(id: $id) {
      id
      participationGrant(filter: { isDeleted: { ne: true } }) {
        items {
          id
          grant {
            id
            type
            isDeleted
          }
        }
      }
    }
  }
`;

export function useProfileHelper() {
  return true;
}

export const getProduct = (clientId, serviceId, serviceType) =>
  new Promise((resolve, reject) => {
    showLoader();

    API.graphql({
      query: getProductByClientIdServiceTypeServiceIdQuery,
      variables: {
        clientId,
        serviceId,
        serviceType,
      },
    })
      .then(({ data }) =>
        resolve(data.productByClientIdServiceTypeServiceId.items[0])
      )
      .catch((e) => {
        console.error('event-product', e);
        reject();
      })
      .finally(hideLoader);
  });

export const getOthersParticipantAvailableServices = (id) =>
  new Promise((resolve, reject) => {
    showLoader();

    API.graphql({
      query: getOthersParticipantAvailableServicesQuery,
      variables: { id },
    })
      .then((response) => {
        const participationGrants = response.data.getParticipation.participationGrant.items.filter(
          (pg) => !pg.grant.isDeleted
        );

        const res = [];
        if (
          participationGrants.filter(
            (pg) => pg?.grant?.type === constants.GrantType.ABSTRACT.key
          ).length > 0
        ) {
          res.push({
            serviceType: constants.GrantType.ABSTRACT.key,
          });
        }

        if (
          participationGrants.filter(
            (pg) => pg?.grant?.type === constants.GrantType.EDUCATIONAL.key
          ).length > 0
        ) {
          res.push({
            serviceType: constants.GrantType.EDUCATIONAL.key,
          });
        }

        resolve(res);
      })
      .catch((e) => {
        console.error('get-participation-available-services', e);
        reject();
      })
      .finally(hideLoader);
  });

export const getProductsByClientIdServiceId = (participationId) =>
  new Promise((resolve, reject) => {
    showLoader();

    API.graphql({
      query: getProductsByClientIdServiceIdQuery,
      variables: { clientId: participationId },
    })
      .then((response) => {
        resolve([...response?.data?.productByClientIdServiceId?.items]);
      })
      .catch((e) => {
        console.error('get-products-by-clientId', e);
        reject();
      })
      .finally(hideLoader);
  });
