import { styled, AimIconButton, theme } from '@aim/components';

export const OtherSponsRow = styled('div')({
  backgroundColor: '#FFF',
  padding: '10px 20px',
  marginBottom: 8,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: theme.colors.greyScale.backgroundGrey,
  },
});

export const OtherSponsIconButton = styled(AimIconButton)({
  marginLeft: 'auto !important',
});
