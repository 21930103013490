import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
// import { format, isAfter } from 'date-fns';
import { useHistory, useParams, useLocation } from 'react-router-dom';

import { appState, constants } from '@aim/common';
import {
  theme,
  styled,
  CustomIntl,
  AimTypography,
  AimCheckbox,
  AimIconAndTextButton,
} from '@aim/components';

import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';

import useI18n from './grantDetail/i18n';
import {
  getParticipationGrants,
  updateParticipationGrant,
} from './grantDetail/grantDetailHelper';
import GrantForm from './grant/components/GrantForm';
import GrantServices from './grantDetail/components/GrantServices';
import { isAfter, isBefore, parseISO, startOfDay } from 'date-fns';
import { getGrant } from './grant/grantGqlHelper';

const Page = styled('div')({
  width: '100%',
  height: '100%',
  paddingTop: '2%',
  paddingLeft: '10%',
  paddingRight: '10%',
  paddingBottom: '2%',
});

const getRequestStatus = (participationGrant) => {
  if (
    participationGrant.requestStatus !== constants.GrantStatuses.PROCESSED.id
  ) {
    return Object.values(constants.GrantStatuses).find(
      (type) => type.id === participationGrant.requestStatus
    );
  } else {
    const startToday = startOfDay(new Date());
    if (
      !participationGrant.publicationDate ||
      (participationGrant.publicationDate &&
        isBefore(startToday, parseISO(participationGrant.publicationDate)))
    ) {
      return constants.GrantStatuses.TO_BE_PROCESSED;
    } else {
      const hasWon =
        participationGrant.ticket ||
        participationGrant.additionalServices ||
        participationGrant.allotment ||
        participationGrant.travel ||
        participationGrant.bankTransfer;

      return hasWon
        ? constants.GrantStatuses.SUCCESSFUL
        : constants.GrantStatuses.REFUSED;
    }
  }
};

const GrantDetails = () => {
  const intl = CustomIntl(useIntl());
  const i18n = useI18n(intl);
  const history = useHistory();
  const { eventId, participationId, grantId } = useParams();
  const [participationGrant, setParticipationGrant] = useState(null);
  const [statusData, setStatusData] = useState();
  const [authorise, setAuthorise] = useState();
  const [isDisabled, setIsDisabled] = useState();
  const [participation] = useState(appState.getCurrentParticipation());
  const [grant, setGrant] = useState();

  useEffect(() => {
    getGrant(grantId).then((nextGrant) => setGrant(nextGrant));
  }, [grantId]);

  useEffect(() => {
    const fetchGrant = async () => {
      if (grantId) {
        const participationGrants = await getParticipationGrants(
          participation.id
        );
        const participationGrant = participationGrants.find(
          (g) => g.participationGrantGrantId === grantId
        );
        const nextParticipationGrant = fetchBooleanServices(participationGrant);
        setParticipationGrant(nextParticipationGrant);
        setStatusData(getStatusData(nextParticipationGrant));
        setIsDisabled(
          isAfter(
            new Date(),
            new Date(nextParticipationGrant?.grant.closingDate)
          )
        );
      }
    };

    fetchGrant();
  }, [grantId]);

  const fetchBooleanServices = (participationGrant) => {
    const booleanServices = [];
    const booleans = [
      'ticket',
      'additionalServices',
      'allotment',
      'travel',
      'bankTransfer',
    ];
    const nextParticipationGrant = {
      ...participationGrant,
      booleans: [
        {
          id: 'ticket',
          value: participationGrant?.ticket,
          label: (intl) =>
            intl.formatMessage({
              description: 'grant to be processed status',
              defaultMessage: 'Registration',
            }),
        },
        {
          id: 'additionalServices',
          value: participationGrant?.additionalServices,
          label: (intl) =>
            intl.formatMessage({
              description: 'additional services label',
              defaultMessage: 'Additional services',
            }),
        },
        {
          id: 'allotment',
          value: participationGrant?.allotment,
          label: (intl) =>
            intl.formatMessage({
              description: 'allotment label',
              defaultMessage: 'Allotment',
            }),
        },
        {
          id: 'travel',
          value: participationGrant?.travel,
          label: (intl) =>
            intl.formatMessage({
              description: 'travel label',
              defaultMessage: 'Travel/Transfer',
            }),
        },
        {
          id: 'bankTransfer',
          value: participationGrant?.travel,
          label: (intl) =>
            intl.formatMessage({
              description: 'bank transfer label',
              defaultMessage: 'Bank transfer',
            }),
        },
      ],
    };

    for (let index = 0; index < booleans.length; index++) {
      const item = booleans[index];
      var idx = nextParticipationGrant.booleans.findIndex((x) => x.id === item);
      if (nextParticipationGrant.booleans[idx].value === true) {
        booleanServices.push(nextParticipationGrant.booleans[idx]);
      }
    }

    return {
      ...nextParticipationGrant,
      booleanServicesTrue: booleanServices.length,
    };
  };

  const getStatusData = (participationGrant) => {
    const status = getRequestStatus(participationGrant);
    let message = '';
    let youWon = false;
    if (status.id === constants.GrantStatuses.SUCCESSFUL.id) {
      message = i18n.page.successMessage;
      youWon = true;
    } else if (status.id === constants.GrantStatuses.REFUSED.id) {
      message = i18n.page.refusedMessage;
      youWon = false;
    } else if (status.id === constants.GrantStatuses.TO_BE_PROCESSED.id) {
      message = i18n.page.toBeProcessedMessage;
    }

    return {
      ...status,
      message,
      youWon,
    };
  };

  const saveStatusGrant = async (requestStatus) => {
    if (authorise) {
      // accept or rejected by winner user
      await updateParticipationGrant({
        id: participationGrant.id,
        requestStatus,
      });
      const nextParticipationGrant = { ...participationGrant, requestStatus };
      setParticipationGrant(nextParticipationGrant);
      setStatusData(getStatusData(nextParticipationGrant));
    }
  };

  return (
    <Page>
      <AimIconAndTextButton
        variant="none"
        onClick={() =>
          history.push(
            `/events/${eventId}/user/${participationId}/my-grants/${participationGrant?.grant?.type}`
          )
        }
      >
        <ArrowBack style={{ color: theme.colors.greyScale.grey3 }} />
        <AimTypography variant="text">{i18n.page.backButton}</AimTypography>
      </AimIconAndTextButton>
      <AimTypography variant={'h1'}>
        {grant?.type === 'abstract'
          ? i18n.page.abstractTitle
          : i18n.page.educationalTitle}
      </AimTypography>
      {statusData && (
        <Grid container>
          <Grid item xs={12}>
            <Chip
              label={statusData.label(intl)}
              style={{
                backgroundColor: statusData.color,
                marginBottom: '10px',
              }}
            />
            <AimTypography margin={0}>{statusData.message}</AimTypography>
          </Grid>
          {statusData.youWon &&
            participationGrant.requestStatus !==
              constants.GrantStatuses.REJECTED.id &&
            participationGrant.requestStatus !==
              constants.GrantStatuses.ACCEPTED.id && (
              <Grid container style={{ marginBottom: '20px' }}>
                <Grid item xs={12}>
                  <AimTypography margin={0}>
                    {i18n.page.prizeMessage}
                  </AimTypography>
                  <AimCheckbox
                    label={i18n.page.authorise}
                    labelPlacement="right"
                    onChange={(e) => setAuthorise(e.target.checked)}
                    disabled={isDisabled}
                  />
                  {!authorise && !isDisabled && (
                    <AimTypography variant="formError">
                      {i18n.page.authoriseReq}
                    </AimTypography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <AimIconAndTextButton
                    style={{
                      backgroundColor: theme.colors.light.red,
                      opacity: isDisabled || !authorise ? '0.5' : 1,
                    }}
                    onClick={() =>
                      saveStatusGrant(constants.GrantStatuses.REJECTED.id)
                    }
                    disabled={isDisabled || !authorise}
                  >
                    <ClearIcon style={{ color: theme.colors.dark.red }} />
                    <AimTypography
                      variant="text"
                      boxStyle={{
                        color: theme.colors.greyScale.grey3,
                      }}
                    >
                      {i18n.page.rejectButton}
                    </AimTypography>
                  </AimIconAndTextButton>
                  <AimIconAndTextButton
                    style={{
                      backgroundColor: theme.colors.light.green,
                      opacity: isDisabled || !authorise ? '0.5' : 1,
                    }}
                    onClick={() =>
                      saveStatusGrant(constants.GrantStatuses.ACCEPTED.id)
                    }
                    disabled={isDisabled || !authorise}
                  >
                    <CheckIcon style={{ color: theme.colors.dark.green }} />
                    <AimTypography
                      variant="text"
                      boxStyle={{ color: theme.colors.greyScale.grey3 }}
                    >
                      {i18n.page.acceptButton}
                    </AimTypography>
                  </AimIconAndTextButton>
                </Grid>
              </Grid>
            )}
          <Grid container>
            {(statusData.youWon &&
              participationGrant.requestStatus !==
                constants.GrantStatuses.REJECTED.id) ||
            participationGrant.requestStatus ===
              constants.GrantStatuses.ACCEPTED.id ? (
              <Grid item xs={12} style={{ marginTop: '20px' }}>
                <GrantServices participationGrant={participationGrant} />
              </Grid>
            ) : (
              <Grid item xs={12} style={{ marginTop: '20px' }}>
                <GrantForm eventId={eventId} grantId={grantId} grant={grant} />
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </Page>
  );
};

export default GrantDetails;
