import React from 'react';

import { UpNextContainer } from './style';
import { AimTypography } from '@aim/components';
import Grid from '@material-ui/core/Grid';

import { UpNextTile } from './UpNextTile';

const UpNextList = ({ upNextSessions, i18n }) => {
  return (
    <UpNextContainer>
      <AimTypography variant="h1Regular">
        {i18n.page.upNext.title}
      </AimTypography>
      {upNextSessions?.length > 0 ? (
        <Grid container spacing={3}>
          {upNextSessions?.map((name, index) => (
            <UpNextTile key={index} tileData={name} />
          ))}
        </Grid>
      ) : (
        <AimTypography variant="text">
          {i18n.page.upNext.noSessions}
        </AimTypography>
      )}
    </UpNextContainer>
  );
};

export { UpNextList };
