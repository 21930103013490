import React from 'react';

import { AimTypography } from '@aim/components';

import Grid from '@material-ui/core/Grid';

const ServiceBuyCardContent = ({ nOrder, nBooking, i18n }) => {
  return (
    <Grid container>
      <Grid item xs={6}>
        <AimTypography
          variant="h6"
          margin={0}
          color="textSecondary"
          component="span"
        >
          {i18n.bookings.label}
        </AimTypography>
      </Grid>
      <Grid item xs={6}>
        <AimTypography
          variant="text"
          textAlign="end"
          margin={0}
          color="textSecondary"
          component="span"
        >
          {nBooking}
        </AimTypography>
      </Grid>

      <Grid item xs={6}>
        <AimTypography
          variant="h6"
          margin={0}
          color="textSecondary"
          component="span"
        >
          {i18n.orders.label}
        </AimTypography>
      </Grid>
      <Grid item xs={6}>
        <AimTypography
          variant="text"
          margin={0}
          textAlign="end"
          color="textSecondary"
          component="span"
        >
          {nOrder}
        </AimTypography>
      </Grid>
    </Grid>
  );
};

export default ServiceBuyCardContent;
