import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';

import { appState, aws } from '@aim/common';
import {
  CustomIntl,
  AimTypography,
  AimIconAndTextButton,
  AimUrlPdfViewer,
} from '@aim/components';

import { translation } from './welcomePublicPage/translation';

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const MainContainer = ({ children }) => (
  <div
    style={{
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      paddingLeft: '5%',
      paddingTop: 20,
      paddingRight: '5%',
      paddingBottom: 20,
    }}
  >
    {children}
  </div>
);

const ParticipationWelcomePublicPage = () => {
  const location = useLocation();
  const history = useHistory();
  const { eventId, participationServiceId } = useParams();

  const intl = CustomIntl(useIntl());
  const i18n = translation.welcomePublicPage(intl);

  const [pdfUrl, setPdfUrl] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get('code');

    const fetchData = async () => {
      const res = await aws.standardAPI.post(
        'aimlambdaproxy',
        '/admin/public-services',
        {
          body: {
            eventId,
            participationServiceId,
            code,
          },
        }
      );
      return res;
    };

    showLoader();
    setIsLoading(true);
    fetchData()
      .then((result) => {
        setPdfUrl(result.WELCOME.url);
        setIsLoading(false);
      })
      .finally(() => hideLoader());
  }, []);

  const onClickBack = () => {
    history.push(
      `/events/${eventId}/my-services/${participationServiceId}${location.search}`
    );
  };

  return (
    <MainContainer>
      <Grid container>
        <Grid item xs={12}>
          <AimIconAndTextButton
            onClick={onClickBack}
            text={i18n.back}
            variant="text"
          >
            <ArrowBack />
          </AimIconAndTextButton>
        </Grid>
        <Grid item xs={12}>
          <AimTypography variant={`h1`}>{i18n.title}</AimTypography>
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          {isLoading ? (
            <AimTypography>{i18n.loading}</AimTypography>
          ) : pdfUrl ? (
            <AimUrlPdfViewer url={pdfUrl} />
          ) : (
            <AimTypography>{i18n.notFound}</AimTypography>
          )}
        </Grid>
      </Grid>
    </MainContainer>
  );
};

export default ParticipationWelcomePublicPage;
