import React, { useEffect, useState } from 'react';

import { useIntl } from 'react-intl';
import { isMobileOnly } from 'react-device-detect';
import { useHistory, useParams } from 'react-router-dom';

import {
  styled,
  CustomIntl,
  AimTitleAndButtons,
  AimIconAndTextButton,
} from '@aim/components';
import { constants } from '@aim/common';

import ArrowBack from '@material-ui/icons/ArrowBack';

import {
  getPurchaseInformations,
  getHotelReservationInformations,
} from './myAllotment/myAllotmentGqlHelper';
import translation from './myAllotment/translation';
import MyAllotmentWeb from './myAllotment/components/MyAllotmentWeb';
import MyAllotmentMobile from './myAllotment/components/MyAllotmentMobile';

//--- CUSTOM COMPONENTS
const HotelDetailPage = styled('div')({
  width: '80%',
  marginBottom: '5%',
});

// ALLOTMENT COMPONENT
const MyAllotment = () => {
  const [purchaseInformations, setPurchaseInformations] = useState([]);

  const intl = CustomIntl(useIntl());
  const i18n = translation.myAllotment(intl);
  const history = useHistory();
  const { eventId, participationId } = useParams();

  useEffect(() => {
    const serviceType = constants.Services.ALLOTMENT.key;
    const hotelDetailsInfo = async () => {
      const products = await getPurchaseInformations(
        participationId,
        eventId,
        serviceType
      );

      const purchaseInformations = await Promise.all(
        products?.map(async (product) => {
          const reservation = await getHotelReservationInformations(
            participationId,
            product.productId
          );

          const { payment } = product;
          console.log('Product', product);
          console.log('Reservation front end', reservation);

          return {
            ...payment,
            ...reservation,
            productId: product.id,
          };
        })
      );
      setPurchaseInformations(purchaseInformations);
    };
    hotelDetailsInfo();
  }, []);

  return (
    <HotelDetailPage>
      <AimIconAndTextButton
        variant="none"
        text={i18n.page.back}
        style={{
          padding: 0,
        }}
        onClick={() =>
          history.push(`/events/${eventId}/user/${participationId}/services`)
        }
      >
        <ArrowBack />
      </AimIconAndTextButton>
      <AimTitleAndButtons title={i18n.page.title} />
      {isMobileOnly ? (
        <MyAllotmentMobile
          i18n={i18n.purchaseTable}
          eventId={eventId}
          history={history}
          purchases={purchaseInformations}
          participationId={participationId}
        />
      ) : (
        <MyAllotmentWeb
          i18n={i18n.purchaseTable}
          eventId={eventId}
          history={history}
          purchases={purchaseInformations}
          participationId={participationId}
        />
      )}
    </HotelDetailPage>
  );
};

export default MyAllotment;
