import { aws, appState } from '@aim/common';
const { API } = aws;

const { customAlphabet } = require('nanoid');
const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const nanoid = customAlphabet(alphabet, 8); // 1 id/hour --> 1% probability of collision in 27 years

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

export const createPaymentMutation = /* GraphQL */ `
  mutation CreatePayment(
    $input: CreatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    createPayment(input: $input, condition: $condition) {
      id
    }
  }
`;

const deletePaymentMutation = /* GraphQL */ `
  mutation DeletePayment(
    $input: DeletePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    deletePayment(input: $input, condition: $condition) {
      id
    }
  }
`;

const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      end
      id
    }
  }
`;

export const getEndDateOfEvent = (id, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: getEvent,
      variables: { id },
    })
      .then((response) => resolve(response.data.getEvent))
      .catch((e) => {
        console.error('get-end date of event-operation', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const paymentGqlHelper = () => {
  const create = (input, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: createPaymentMutation,
        variables: { input: { ...input, paymentId: nanoid() } }, //TODO set paymentId with the one from effective payment
      })
        .then((response) => resolve(response.data.createPayment))
        .catch((e) => {
          console.error('create-payment', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const remove = (input, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: deletePaymentMutation,
        variables: { input },
      })
        .then((response) => resolve(response.data.deletePayment))
        .catch((e) => {
          console.error('delete-payment', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  return { create, remove };
};
