import { styled, theme } from '@aim/components';
import { isMobile } from 'react-device-detect';

export const Container = styled('div')({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  padding: '0 20px 0 20px',
});

export const RowContainer = styled('div')({
  display: 'flex',
  flex: 1,
  height: isMobile ? 'auto' : 'calc(100% - 10px)',
  flexDirection: isMobile ? 'column' : 'row',
});

export const MessagesContainer = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  paddingRight: 8,
  height: 'calc(100vh - 270px)',
});

export const InputContainer = styled('div')({
  display: 'flex',
  maxHeight: 80,
});

export const ColumnContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: isMobile ? '100%' : '20%',
  background: 'white',
  height: 'calc(100vh - 270px)',
  overflowY: 'auto',
  zIndex: 1,
});

export const SectionContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  height: 'calc(100vh - 270px)',
  overflowY: 'auto',
});

export const SectionContent = styled('div')({
  backgroundColor: '#F3F3F4',
  height: '100%',
  padding: '10px',
});

export const SectionTitle = styled('div')({
  display: 'flex',
  height: '50px',
  alignItems: 'center',
  backgroundColor: '#F3F3F4',
});
