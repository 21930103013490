import React, { useState } from 'react';
import { CustomIntl, BillingInformations } from '@aim/components';
import { useHistory, useParams } from 'react-router-dom';
import { constants, utilities } from '@aim/common';
import { useIntl } from 'react-intl';
import translation from './sponsorbillinginformations/translation';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { isMobile } from 'react-device-detect';
import { useEffect } from 'react';

const getParticipationMode = (bookingItem) => {
  if (bookingItem?.sponsorListPurchase) {
    return (
      bookingItem.sponsorListPurchase?.participationMode ||
      constants.EventTypes.VIRTUAL.id
    );
  } else if (bookingItem?.sponsorStaffPurchase) {
    return constants.EventTypes.PHYSICAL.id;
  } else {
    return constants.EventTypes.VIRTUAL.id;
  }
};

const SponsorCartBillingInformations = () => {
  const { eventId, sponsorId } = useParams();
  const history = useHistory();
  const [billingInfoBookings, setBillingInfoBookings] = useState();
  const intl = CustomIntl(useIntl());
  const i18n = translation.billingInformations(intl);
  const [formExportedValues, setFormExportedValues] = useState({});

  console.log('formExportedValues', formExportedValues);
  // deconstruct id because we do not need it for payment
  // eslint-disable-next-line unused-imports/no-unused-vars
  const onAfterSave = ({ id, ...billingInfo }) => {
    const nextBookings = history.location.state.bookings.map((b) => {
      console.log('formExportedValues', formExportedValues[b.id]);
      console.log('b.price', b.price);
      const price = utilities.vatCalc(
        b.netPrice,
        formExportedValues[b.id].vatRate1
      );

      if (
        //CUSTOMIZATION FOR ESOT
        b.otherSponsorizationsBookingItem ||
        //CUSTOMIZATION FOR GIVRE
        b?.physicalStandSpaceItems?.item?.id ===
          '9bc674e0-8637-497c-8fa8-dc339baa2d47' ||
        //CUSTOMIZATION FOR SIFO
        b?.physicalStandSpaceItems?.item?.id ===
          'b5cb28c6-ace8-48d4-972a-f343ca3ca802'
      ) {
        return b;
      }
      return {
        ...b,
        vatRate: formExportedValues[b.id].vatRate1,
        price,
        vatAmount: utilities.roundTo2Decimal(price - b.netPrice),
      };
    });
    console.log('bookings', nextBookings);
    // if not payment type are both options check in SponsorCart to remember behaviour
    if (
      history.location.state?.bookings?.length &&
      !history.location.state?.paymentType
    ) {
      history.push({
        pathname: `/events/${eventId}/${sponsorId}/payment-opt`,
        state: {
          //state contains bookings, payment, gateway and url
          ...history.location.state,
          bookings: nextBookings,
          billingInfo: billingInfo,
          //original bookings data to save on purchase
          // used in case of cancel payment with credit card
          cartBookings: history.location.state.bookings,
          //backUrl: `/events/${eventId}/${sponsorId}/billing-informations`,
        },
      });
    } else if (history.location.state?.bookings?.length) {
      history.push({
        pathname: `/events/${eventId}/${sponsorId}/payment`,
        state: {
          //state contains bookings, payment, gateway, url and payment type
          ...history.location.state,
          bookings: nextBookings,
          billingInfo,
          //original bookings data to save on purchase
          // used in case of cancel payment with credit card
          cartBookings: history.location.state.bookings,
          //backUrl: `/events/${eventId}/${sponsorId}/billing-informations`,
        },
      });
    }
  };

  /*
  bookings: [{
    id
    netprice
    vatRate
  }]

  input
  participationType: {
    123: {
      participationMode: "physical",
      vatRate1: 2200,
      vatRate1Id: '123453646'
    },
    234: { 
      participationMode: "virtual",
      vatRate1: 2200,
      vatRate2: 1000,
    }
  }

  output 
  {
    123: { vatRate: 2200, includeVat: true},
    234: { vatRate1: 2200, vatRate1Id: '4643637', isForced: true, vatRate2: 2200, includeVat: true}
  }
  */

  console.log(
    'history.location.state.bookings',
    history.location.state.bookings
  );

  useEffect(() => {
    const nextBillingInfoBookings = history.location.state.bookings.reduce(
      (prev, curr) => {
        prev[curr.id] = {
          vatRate1: curr.vatRate,
          vat1Id: curr.vatId,
          participationMode: getParticipationMode(curr),
        };
        return prev;
      },
      {}
    );

    setBillingInfoBookings(nextBillingInfoBookings);
  }, [history]);

  return (
    <div
      style={{
        flex: 1,
        minWidth: 'calc(100vw - 480px)',
        margin: isMobile ? '0px 20px' : '0px 180px',
      }}
    >
      <BillingInformations
        type={constants.Clusters.Sponsor.id}
        customerId={sponsorId}
        history={history}
        intl={intl}
        pageTitle={i18n.pageTitle}
        showSubTitle
        saveButtonText={i18n.confirm}
        SaveButtonIcon={ShoppingCartIcon}
        onAfterSave={onAfterSave}
        backUrl={`/events/${eventId}/${sponsorId}/cart`}
        setFormExportedValues={setFormExportedValues}
        participationType={constants.EventTypes.VIRTUAL.id}
        billingInfoBookings={billingInfoBookings}
      />
    </div>
  );
};

export default SponsorCartBillingInformations;
