import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { format, isAfter, parseISO } from 'date-fns';
import { uniqBy } from 'lodash';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import xIcon from '../../assets/images/X.svg';

import { appState, constants, fileHelper } from '@aim/common';
import {
  styled,
  theme,
  AimIconAndTextButton,
  AimTypography,
  AimCard,
  AimIconButton,
  AimRichText,
  CustomIntl,
  useAimMediaQuery,
} from '@aim/components';

import useI18n from './profile/i18n';
import { useUserShowcaseHelper } from './shared/userShowcaseGqlHelper';
import { useUserSettingsHelper } from './shared/userSettingsGqlHelper';
import { useDataHelper } from './shared/dataHelper';
import { getParticipation } from './shared/participationGqlHelper';

import FacebookIcon from '@material-ui/icons/Facebook';
// import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';

import ProfileBackground from './../../assets/images/profile-background.png';
import { useAgenda } from '../shared/agenda/shared/agendaGqlHelper';
import { useLoaderHelper } from './shared/loaderHelper';
import CustomHeaderPage from './shared/CustomHeaderPage';

const nonParticipantClusters = [
  constants.Clusters.SponsorStaff.id,
  constants.Clusters.Agency.id,
];

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  margin: '0 auto',
  padding: '10px',
  width: '100%',
  maxWidth: '1280px',
});

export const ContactCardContent = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'start',
});

export const SingleInfo = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const InterventionCard = styled('div')(({ isMobileOnly }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  width: isMobileOnly ? '100%' : '49%',
  height: 95,
  marginRight: 10,
  marginTop: 10,
  background: '#F3F3F4',
}));

export const CustomCard = styled('div')({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  cursor: 'pointer',
  height: 192,
  marginRight: 10,
  marginTop: 10,
});

export const CustomOverlay = styled('div')({
  bottom: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0,0,0,0.4)',
});

export const CustomInfo = styled('div')({
  position: 'absolute',
  bottom: '10px',
  left: '10px',
  color: '#fff',
});

export const MainContainer = styled('div')({
  width: '100%',
  height: 'inherit',
  display: 'flex',
  flexDirection: 'column',
  background: theme.colors.greyScale.backgroundGrey,
});

const ParticipationProfile = () => {
  const intl = CustomIntl(useIntl());
  const i18n = useI18n(intl);
  const history = useHistory();
  const { isMobile, isMobileOnly } = useAimMediaQuery();
  const { getEvent, parseSession } = useAgenda();
  const { showLoader, hideLoader } = useLoaderHelper();
  const { eventId, participationId } = useParams();
  const [user, setUser] = useState();
  const showcaseHelper = useUserShowcaseHelper();
  const settingsHelper = useUserSettingsHelper();
  const { parseRichText } = useDataHelper();
  const [participation, setParticipation] = useState();
  const [isOwner, setIsOwner] = useState(false);
  const [speeches, setSpeeches] = useState([]);
  const [eventInfo] = useState(appState.eventInfo.getValue());

  const headerContact = [
    'birthDate',
    'address',
    'phone',
    'businessPhone',
    'contactEmail',
    'email',
    'nationality',
  ];

  const headerSocial = [
    { key: 'facebook', icon: <FacebookIcon fontSize="small" /> },
    { key: 'linkedin', icon: <LinkedInIcon fontSize="small" /> },
    { key: 'twitter', icon: <img src={xIcon} style={{ height: 15 }} /> },
    { key: 'instagram', icon: <InstagramIcon fontSize="small" /> },
  ];

  const getParticipationData = async () => {
    showLoader();
    const loggedParticipation = appState.user.getValue()?.userAndParticipation
      ?.participation;
    setIsOwner(loggedParticipation.id === participationId);
    const res = await getParticipation(participationId);
    setParticipation(res);
    if ((res.isSpeaker, loggedParticipation.id !== participationId)) {
      window.dataLayer.push({
        event: 'speaker',
        speaker_id: res?.id,
        speaker_name: `${res.givenName} ${res.familyName}`,
        event_id: eventId,
        event_name: appState.eventInfo.getValue()?.name,
        username: loggedParticipation?.username,
        name: loggedParticipation?.givenName,
        surname: loggedParticipation?.familyName,
        email: loggedParticipation?.email,
      });
    }
    if (res.isSpeaker) {
      const event = await getEvent(eventId, loggedParticipation.cluster);
      const promises = [];
      event.agenda?.sessions.items.map((s) => {
        promises.push(parseSession(s, eventId));
      });
      if (promises.length) {
        const nextSpeeches = (await Promise.all(promises)).flatMap(
          (session) => {
            const sessionSpeakers = session.attendeeRoles.items.flatMap((ar) =>
              ar.attendees.items
                .filter((a) => a?.participation?.id === participationId)
                .map((p) => ({
                  sessionId: session.id,
                  imgLink: session.coverImage?.img,
                  title: session.name,
                  participationId: p.id,
                }))
            );
            const subSessionSpeakers = session?.subSessions.items.flatMap(
              (ss) =>
                ss.attendeeRoles.items.flatMap((ar) =>
                  ar.attendees.items
                    .filter((a) => a?.participation?.id === participationId)
                    .map((p) => ({
                      sessionId: session.id,
                      imgLink: session.coverImage?.img,
                      title: session.name,
                      participationId: p.id,
                    }))
                )
            );

            const interventionsSpeeches = session?.subSessions.items.flatMap(
              (subSession) => {
                const nextSpeeches = subSession?.speeches?.items.map(
                  (speech) => ({
                    ...speech,
                    attendees: {
                      items: speech.abstract
                        ? [
                            {
                              participation: {
                                ...speech?.abstract?.presenter?.participant,
                              },
                            },
                          ]
                        : speech.attendees.items,
                    },
                  })
                );
                return nextSpeeches
                  .filter((i) =>
                    i.attendees.items.find(
                      (a) => a?.participation?.id === participationId
                    )
                  )
                  .flatMap((speech) => {
                    return {
                      ...speech,
                      title: session.name,
                      sessionId: session.id,
                      imgLink: session.coverImage?.img,
                    };
                  });
              }
            );
            return [
              ...sessionSpeakers,
              ...subSessionSpeakers,
              ...interventionsSpeeches,
            ];
          }
        );
        setSpeeches(uniqBy(nextSpeeches, 'sessionId'));
      }
    }

    const showcase = (await showcaseHelper.get(participationId)) || {};
    const settings = (await settingsHelper.get(participationId)) || {};

    const values = {
      ...res,
      ...showcase,
      birthDate:
        showcase.birthDate &&
        format(new Date(showcase.birthDate), 'dd/MM/yyyy'),
      nationality:
        showcase.nationality &&
        showcase.nationality[0].toUpperCase() + showcase.nationality.slice(1),
      profileImageLink:
        showcase.profileImage &&
        fileHelper.getPublicFileLink({
          dirPath: `events/${eventId}/user/${participationId}/showcase/profileImage`,
          skipFileDataOnS3Link: true,
        }),
      coverImageLink: showcase.coverImage
        ? fileHelper.getPublicFileLink({
            dirPath: `events/${eventId}/user/${participationId}/showcase/coverImage`,
            skipFileDataOnS3Link: true,
          })
        : ProfileBackground,
      description: showcase.description || null,
      myAgenda: res.myAgenda?.items
        ?.filter((i) => !!i.session?.id)
        ?.map((fav) => ({
          ...fav.session,
          sessionId: fav.session?.id,
          title: fav.session?.name,
        })),
      settings: settings,
    };
    const promises = res.myAgenda?.items
      ?.filter((fav) => fav.session && fav.session.id && fav.session.coverImage)
      ?.map((fav) =>
        getS3Link(
          `agendaSessions/${fav.session.id}/coverImage`,
          fav.session.coverImage
        )
      );
    if (promises.length) {
      (await Promise.all(promises)).map((img, idx) => {
        values.myAgenda[idx].imgLink = img;
      });
    }

    //TODO: rivedi query
    setUser(values);
    hideLoader();
  };

  const upperCardHeight = {
    minHeight: 368,
    margin: 0,
  };

  useEffect(() => {
    getParticipationData();
  }, [participationId]);

  const onClickSendMessage = () =>
    appState.chat.onNewChatRequest.next({
      participationId,
      displayName: `${user.givenName.charAt(0)?.toUpperCase()}. ${
        user.familyName.charAt(0)?.toUpperCase() + user.familyName?.slice(1)
      }`,
    }) || history.push(`/events/${eventId}/lounge-area`);

  const navigateToAgendaSession = (sessionId) =>
    history.push(`/events/${eventId}/agenda/sessions/${sessionId}`);

  const renderInterventions = (label, array) => {
    return (
      <AimCard
        title={label}
        typographyProps={{ isUpperCase: true }}
        titleVariant="h2"
        style={{
          ...upperCardHeight,
          paddingTop:
            isMobileOnly &&
            !user.settings.showMyAgenda &&
            !participation?.isSpeaker
              ? 20
              : 0,
        }}
      >
        {array && array.length > 0 ? (
          <Grid container spacing={2}>
            {array.map((item) => (
              <Grid
                item
                xs={12}
                sm={6}
                key={item.id}
                //{/* isMobileOnly={isMobileOnly} */}
                onClick={() => {
                  navigateToAgendaSession(item.sessionId);
                }}
              >
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  style={{ background: '#F3F3F4', padding: 10 }}
                >
                  <Grid item xs={isMobile ? 2 : 1}>
                    <Avatar
                      src={item.imgLink || ProfileBackground}
                      style={{
                        border: 0,
                      }}
                    />
                  </Grid>
                  <Grid item xs={isMobile ? 10 : 11}>
                    <AimTypography
                      variant="textBold"
                      ellipsis
                      style={{
                        maxWidth: isMobileOnly
                          ? 'calc(100vw - 130px)'
                          : 'calc(50vw - 130px)',
                      }}
                    >
                      {item.title}
                    </AimTypography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        ) : (
          // <AimTypography variant="body1" component="h2">
          //   {`${i18n.page.noDataAvailable}${
          //     isOwner ? i18n.page.pleaseEdit : ''
          //   }`}
          // </AimTypography>
          <></>
        )}
      </AimCard>
    );
  };

  const renderUnpublishedContentsLabel = (flag) => {
    if (!flag && !participation?.isSpeaker)
      return (
        <AimTypography
          variant="text"
          textAlign="right"
          style={{ color: 'black' }}
        >
          <div
            style={{
              margin: '-19px -8px',
              transform: ' translate(-30px, 26px)',
            }}
          >
            {i18n.page.isUnpublishedContent}
          </div>
        </AimTypography>
      );
    return null;
  };

  const getParticipationDataEditDisabledStatus = () => {
    const eventInfo = appState.eventInfo.getValue();
    console.log('eventInfo', eventInfo);
    console.log('participation', participation);
    if (
      (eventInfo.editPersonalDetailsType ===
        constants.EventEditPersonalDetailsTypes.ONLY_ONCE.id &&
        participation?.isParsonalDetailsEditedAtLeastOnce) ||
      (eventInfo.editPersonalDetailsType ===
        constants.EventEditPersonalDetailsTypes.DISABLED_AFTER_DATE.id &&
        isAfter(Date.now(), parseISO(eventInfo.editPersonalDetailsDate)))
    ) {
      return true;
    } else {
      return false;
    }
  };
  const isParticipationDataEditHidden = () => {
    const eventInfo = appState.eventInfo.getValue();
    if (
      eventInfo.editPersonalDetailsType ===
      constants.EventEditPersonalDetailsTypes.DISABLED.id
    ) {
      return true;
    } else {
      return false;
    }
  };

  if (!user) return <div>Loading...</div>;
  return (
    <MainContainer>
      {user && (
        <>
          <CustomHeaderPage
            logo={user.profileImageLink}
            title={user.givenName + ' ' + user.familyName}
            subtitle={user?.address}
            preTitle={participation?.isSpeaker ? i18n.page.speaker : null}
            headerImage={user.coverImageLink}
            page={
              participation?.isSpeaker
                ? constants.WebsitePages.SPEAKER_PROFILE_PAGE.key
                : constants.WebsitePages.PARTICIPANT_PROFILE_PAGE.key
            }
          />
          <div
            style={{
              display: 'flex',
              //flexDirection: isMobile ? 'column' : 'row',
              alignItems: 'center',
              justifyContent: 'center',
              //width: isMobile ? '100vw' : '1280px',
              //padding: '0px 5px',
            }}
          >
            {isOwner ? (
              <Grid
                container
                justify="flex-end"
                style={{ maxWidth: '1280px', padding: '0px 5px' }}
              >
                {console.log(isMobile)}
                <Grid item xs={isMobile ? 12 : undefined}>
                  <Grid container justify="flex-end">
                    {!isParticipationDataEditHidden() ? (
                      <Grid
                        item
                        sm={isMobile ? 6 : undefined}
                        xs={isMobileOnly ? 12 : undefined}
                      >
                        <AimIconAndTextButton
                          style={{
                            minWidth: 'calc(100% - 10px)',
                          }}
                          disabled={getParticipationDataEditDisabledStatus()}
                          onClick={() =>
                            history.push(
                              `/events/${eventId}/participation/${participationId}/edit`
                            )
                          }
                          text={i18n.page.participationDataEdit}
                          isUpperCase
                        ></AimIconAndTextButton>
                      </Grid>
                    ) : null}
                    <Grid
                      item
                      style={{ flexGrow: isMobile ? 1 : 0 }}
                      sm={
                        isMobile
                          ? // TO EXPAND BUTTON ON MOBILE IF OTHER BUTTON IS HIDDEN
                            !isParticipationDataEditHidden()
                            ? 6
                            : 12
                          : undefined
                      }
                      xs={isMobileOnly ? 12 : undefined}
                    >
                      <AimIconAndTextButton
                        style={{ minWidth: 'calc(100% - 10px)' }}
                        isUpperCase
                        onClick={() =>
                          history.push(
                            `/events/${eventId}/user/${participationId}/edit`
                          )
                        }
                        text={i18n.page.detailEdit}
                      ></AimIconAndTextButton>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={isMobile ? 12 : undefined}>
                  <Grid container justify="flex-end">
                    {eventInfo?.availableServices?.includes(
                      constants.Services.SURVEY.key
                    ) ? (
                      <Grid
                        item
                        sm={
                          isMobile
                            ? // TO EXPAND BUTTON ON MOBILE IF OTHER BUTTON IS HIDDEN
                              !nonParticipantClusters.includes(user.cluster)
                              ? 6
                              : 12
                            : undefined
                        }
                        xs={isMobileOnly ? 12 : undefined}
                      >
                        <AimIconAndTextButton
                          style={{ minWidth: 'calc(100% - 10px)' }}
                          variant="secondary"
                          isUpperCase
                          onClick={() => {
                            history.push(
                              `/events/${eventId}/user/${participationId}/surveys`
                            );
                          }}
                          text={i18n.page.mySurveys}
                        ></AimIconAndTextButton>
                      </Grid>
                    ) : null}
                    {!nonParticipantClusters.includes(user.cluster) ? (
                      <Grid
                        item
                        sm={
                          isMobile
                            ? // TO EXPAND BUTTON ON MOBILE IF OTHER BUTTON IS HIDDEN
                              eventInfo?.availableServices?.includes(
                                constants.Services.SURVEY.key
                              )
                              ? 6
                              : 12
                            : undefined
                        }
                        xs={isMobileOnly ? 12 : undefined}
                      >
                        <AimIconAndTextButton
                          style={{ minWidth: 'calc(100% - 10px)' }}
                          variant="secondary"
                          isUpperCase
                          onClick={() => {
                            history.push(
                              `/events/${eventId}/user/${participationId}/services`
                            );
                          }}
                          text={i18n.page.myServices}
                        ></AimIconAndTextButton>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                justify="flex-end"
                style={{ maxWidth: '1280px', padding: '0px 5px' }}
              >
                <Grid item xs={isMobile ? 12 : undefined}>
                  <AimIconAndTextButton
                    style={{
                      minWidth: 'calc(100% - 10px)',
                    }}
                    variant="secondary"
                    isUpperCase
                    onClick={onClickSendMessage}
                    text={i18n.page.sendMessage}
                  ></AimIconAndTextButton>
                </Grid>
              </Grid>
            )}
          </div>
        </>
      )}
      <Container>
        <Grid
          container
          justify="center"
          alignItems="center"
          spacing={2}
          style={{ marginTop: 0 }}
        >
          {(isOwner ||
            participation?.isSpeaker ||
            user.settings?.showBiography) && (
            <Grid item xs={12} sm={6}>
              {renderUnpublishedContentsLabel(user.settings.showBiography)}
              <AimCard
                title={i18n.contact.bio}
                typographyProps={{ isUpperCase: true }}
                titleVariant="h2"
                style={{
                  ...upperCardHeight,
                  paddingTop:
                    isMobileOnly &&
                    !user.settings.showBiography &&
                    !participation?.isSpeaker
                      ? 20
                      : 0,
                }}
              >
                {user.description ? (
                  <div>
                    <AimRichText
                      value={parseRichText(user.description)}
                      variant={'white'}
                      readOnly
                      formControlStyle={{
                        height: '100%',
                      }}
                      editorStyleOverride={{
                        minHeight: 96,
                        maxHeight: 286,
                      }}
                    />
                  </div>
                ) : (
                  // <AimTypography variant="body1" component="h2">
                  //   {`${i18n.page.noDataAvailable}${
                  //     isOwner ? i18n.page.pleaseEdit : ''
                  //   }`}
                  // </AimTypography>
                  <></>
                )}
              </AimCard>
            </Grid>
          )}
          {(isOwner || participation?.isSpeaker || user.settings?.isPublic) && (
            <Grid item xs={12} sm={6}>
              {renderUnpublishedContentsLabel(user.settings.isPublic)}
              <AimCard
                title={i18n.page.contacts}
                typographyProps={{ isUpperCase: true }}
                titleVariant="h2"
                style={{
                  ...upperCardHeight,
                  paddingTop:
                    isMobileOnly &&
                    !user.settings.isPublic &&
                    !participation?.isSpeaker
                      ? 20
                      : 0,
                }}
              >
                <ContactCardContent>
                  <div style={{ width: '100%' }}>
                    {headerContact
                      .filter((x) => user[x])
                      .map((key) => {
                        if (key !== 'email')
                          return (
                            <SingleInfo key={key}>
                              <AimTypography
                                variant={'textBold'}
                                margin={'5px'}
                              >
                                {i18n.contact[key]} :
                              </AimTypography>
                              <AimTypography variant={'text'} margin={0}>
                                {user[key]}
                              </AimTypography>
                            </SingleInfo>
                          );
                      })}
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      {headerSocial.map(({ key, icon }) =>
                        user[key] ? (
                          <AimIconButton
                            key={key}
                            size="small"
                            onClick={() =>
                              window.open(`${user[key]}`, '_blank')
                            }
                            variant="none"
                          >
                            {icon}
                          </AimIconButton>
                        ) : null
                      )}
                    </div>
                  </div>
                </ContactCardContent>
              </AimCard>
            </Grid>
          )}
          {user.myAgenda?.length
            ? (isOwner || user.settings.showMyAgenda) && (
                <Grid item xs={12}>
                  {renderUnpublishedContentsLabel(user.settings.showMyAgenda)}
                  {renderInterventions(i18n.page.myAgenda, user.myAgenda)}
                </Grid>
              )
            : null}
          {participation?.isSpeaker && speeches.length ? (
            <Grid item xs={12}>
              {renderInterventions(i18n.page.myInterventions, speeches)}
            </Grid>
          ) : null}
        </Grid>
      </Container>
    </MainContainer>
  );
};

export default ParticipationProfile;
