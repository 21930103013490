import React from 'react';

import Grid from '@material-ui/core/Grid';

import { Controller, useFieldArray } from 'react-hook-form';

import { format } from 'date-fns';

import { AimTextField, AimTypography } from '@aim/components';
import { utilities } from '@aim/common';

const formControlStyle = {
  width: 'calc(100% - 10px)',
};

const sortFn = (a, b) => new Date(a.start) - new Date(b.start);

const ProfileFeeBracketsList = ({
  control,
  index,
  isReadOnly,
  fieldArrayPrefix,
}) => {
  const { fields } = useFieldArray({
    control,
    name: `${fieldArrayPrefix}dateRanges[${index}].feeBrackets`,
    keyName: '_id',
  });

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {fields
        .slice()
        .sort(sortFn)
        .map((item, feeBracketIdx) => (
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            key={item._id}
          >
            <Controller
              name={`${fieldArrayPrefix}dateRanges[${index}].feeBrackets.[${feeBracketIdx}].id`}
              control={control}
              defaultValue={item.id}
              as={<input type="hidden" />}
            />
            <Controller
              name={`${fieldArrayPrefix}dateRanges[${index}].feeBrackets.[${feeBracketIdx}].feeBracketId`}
              control={control}
              defaultValue={item.feeBracketId}
              as={<input type="hidden" />}
            />
            <Grid item xs>
              <AimTypography variant="textBold">{item.name}</AimTypography>
            </Grid>
            <Grid item xs>
              <AimTypography variant="text">
                {format(new Date(item.start), 'dd/MM/yyyy')}
              </AimTypography>
            </Grid>
            <Grid item xs>
              <AimTypography variant="text">
                {format(new Date(item.end), 'dd/MM/yyyy')}
              </AimTypography>
            </Grid>
            <Grid item xs>
              <Controller
                name={`${fieldArrayPrefix}dateRanges[${index}].feeBrackets.[${feeBracketIdx}].priceOnAir`}
                control={control}
                defaultValue={utilities.decodeDbNumber(item.priceOnAir)}
                render={(props) => (
                  <AimTextField
                    disabled={isReadOnly}
                    textfieldVariant="grey"
                    type="number"
                    value={props.value}
                    onChange={(e) => props.onChange(e.target.value)}
                    formControlStyle={formControlStyle}
                  />
                )}
              />
            </Grid>
            <Grid item xs>
              <Controller
                name={`${fieldArrayPrefix}dateRanges[${index}].feeBrackets.[${feeBracketIdx}].priceOnSite`}
                control={control}
                defaultValue={utilities.decodeDbNumber(item.priceOnSite)}
                render={(props) => (
                  <AimTextField
                    disabled={isReadOnly}
                    textfieldVariant="grey"
                    type="number"
                    value={props.value}
                    onChange={(e) => props.onChange(e.target.value)}
                  />
                )}
              />
            </Grid>
            <Grid item xs>
              <Controller
                name={`${fieldArrayPrefix}dateRanges[${index}].feeBrackets.[${feeBracketIdx}].accompanyingPersonPrice`}
                control={control}
                defaultValue={utilities.decodeDbNumber(
                  item.accompanyingPersonPrice
                )}
                render={(props) => (
                  <AimTextField
                    disabled={isReadOnly}
                    textfieldVariant="grey"
                    type="number"
                    value={props.value}
                    onChange={(e) => props.onChange(e.target.value)}
                  />
                )}
              />
            </Grid>
          </Grid>
        ))}
    </div>
  );
};

export { ProfileFeeBracketsList };
