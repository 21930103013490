import { aws } from '@aim/common';
import { useLoaderHelper } from '@aim/components';
const { API } = aws;

export const breakoutRoomAttendingSchema = `
id
participation {
  id
  isDeleted
  cognitoUserId
  identityPoolIdentityId
  cluster
  givenName
  familyName
  email
  businessName
  phone
  fieldValues {
    items {
      id
      value
      fieldDefinition {
        id
        controlType
      }
    }
  }
  participationGrant(filter: { isDeleted: { ne: true } }) {
    items {
      id
      participationGrantGrantId
    }
  }
  profile {
    id
    name
    cluster
    isPublic
    createdAt
    updatedAt
  }
  userShowcase {
    id
    contactEmail
    profileImage {
      id
      extension
      originalName
      size
    }
  }
  userSettings {
    isPublic
  }
  myAgenda {
    items {
      id
      session {
        id
      }
    }
  }
  myBreakoutRoom(filter: { state: { eq: "accepted" } }) {
    items {
      id
      isSelfProposed
      note
      state
      slot {
        id
        start
        end
      }
    }
  }
  surveyInvitations {
    items {
      id
      isCompleted
      survey {
        id
      }
    }
  }
  agency {
    id
  }
  sponsorStaff {
    id
    sponsor {
      id
      isDeleted
      publicPage {
        id
        isPublic
        buyOperation {
          id
          purchase {
            id
          }
        }
      }
      priceRange {
        id
        name
        price
        start
      }
      fieldValues {
        items {
          id
        }
      }
    }
  }
  event {
    id
    name
    abstractService {
      id
      maxAbstractsPerAuthor
      abstractTerm
    }
  }
  guests {
    items {
      id
      givenName
      familyName
      country
    }
  }
  sponsor {
    id
    isDeleted
    publicPage {
      id
      isPublic
      sponsorPackageItem {
        package {
          buyOperations {
            items {
              id
              purchase {
                id
              }
            }
          }
        }
      }
      buyOperation {
        id
        purchase {
          id
        }
      }
    }
    priceRange {
      id
      name
      price
      start
    }
    fieldValues {
      items {
        id
      }
    }
  }
  isReviewer
  isSpeaker
  reviewer {
    id
    reviews {
      items {
        id
      }
    }
  }
}
state
note
isSelfProposed
`;

const createBreakoutRoomAttending = /* GraphQL */ `
  mutation CreateBreakoutRoomAttending(
    $input: CreateBreakoutRoomAttendingInput!
  ) {
    createBreakoutRoomAttending(input: $input) {
      ${breakoutRoomAttendingSchema}
    }
  }
`;

const deleteBreakoutRoomAttending = /* GraphQL */ `
  mutation DeleteBreakoutRoomAttending(
    $input: DeleteBreakoutRoomAttendingInput!
    $condition: ModelBreakoutRoomAttendingConditionInput
  ) {
    deleteBreakoutRoomAttending(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateBreakoutRoomAttending = /* GraphQL */ `
  mutation UpdateBreakoutRoomAttending(
    $input: UpdateBreakoutRoomAttendingInput!
    $condition: ModelBreakoutRoomAttendingConditionInput
  ) {
    updateBreakoutRoomAttending(input: $input, condition: $condition) {
      id
    }
  }
`;

export function useBreakoutRoomAttending(useLoader = false) {
  const { showLoader, hideLoader } = useLoaderHelper();
  const create = (input) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({ query: createBreakoutRoomAttending, variables: { input } })
        .then((response) => resolve(response.data.createBreakoutRoomAttending))
        .catch((e) => {
          console.error('event-create-sponsor-breakout-room-attending', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const update = (input) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({ query: updateBreakoutRoomAttending, variables: { input } })
        .then((response) => resolve(response.data.updateBreakoutRoomAttending))
        .catch((e) => {
          console.error('event-update-sponsor-breakout-room-attending', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const remove = (id) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: deleteBreakoutRoomAttending,
        variables: { input: { id } },
      })
        .then((response) => resolve(response.data.deleteBreakoutRoomAttending))
        .catch((e) => {
          console.error('event-delete-sponsor-breakout-room-attending', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  return { create, update, remove };
}
