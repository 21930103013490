import React, { useState, useEffect, useRef } from 'react';
import { MainContainer, Container } from '../serviceList/BreakoutRoomList';
import { useIntl } from 'react-intl';
import useI18n from '../shared/i18n';

import { useParams, useHistory } from 'react-router-dom';
import {
  CustomIntl,
  AimSnackbarSeverity,
  AimSnackbar,
  AimTypography,
  useLoaderHelper,
  useFormComponents,
} from '@aim/components';

import { getByCluster } from '../../shared/participationGqlHelper';
import { useBreakoutRoomInvitationUnregistered } from '../../shared/breakoutRoomInvitationUnregisteredGqlHelper';
import { useBreakoutRoomAttending } from '../../shared/breakoutRoomAttendingGqlHelper';
import { useBreakoutRoomSlots } from '../../shared/breakoutRoomSlotGqlHelper';
import { useBreakoutRoomUtils } from '../../../hooks/useBreakoutRoomUtils';

import { constants } from '@aim/common';

import Grid from '@material-ui/core/Grid';

import Joi from 'joi';

import TitleAndBackButton from '../../shared/TitleAndBackButton';

const emailSchema = Joi.object()
  .keys({
    userTxt: Joi.string(),
    value: Joi.string().email({ tlds: { allow: false } }),
  })
  .unknown();

const schema = Joi.object().keys({
  note: Joi.any(),
  emails: Joi.array()
    .unique((a, b) => a.value === b.value)
    .items(emailSchema),
});

const defaultValues = {
  emails: [],
  note: '',
};

const BreakoutRoomInvite = () => {
  const intl = CustomIntl(useIntl());
  const i18n = useI18n.breakoutRoomInvite(intl);
  const { eventId, sponsorId, breakoutRoomId } = useParams();
  const history = useHistory();

  const [breakoutRoom, setBreakoutRoom] = useState();
  const [selectedSlot, setSelectedSlot] = useState();
  const [selectedParticipations, setSelectedParticipations] = useState([]);
  const [allParticipations, setAllParticipations] = useState([]);
  const [participations, setParticipations] = useState([]);
  const [snackbar, setSnackbar] = useState({ isOpen: false });

  const breakoutRoomAttendingHelper = useBreakoutRoomAttending(true);
  const breakoutRoomSlotsHelper = useBreakoutRoomSlots(false);
  const breakoutRoomInvitationUnregisteredHelper = useBreakoutRoomInvitationUnregistered();
  const {
    states,
    parseParticipation,
    parseEmailInvitation,
    generateBreakoutRoomEmail,
    emailTypes,
    splitArray,
    getDate,
    getHours,
  } = useBreakoutRoomUtils();

  // Ref
  const submitButton = useRef();

  const formControlStyle = { width: 'calc(100% - 15px)', margin: 0 };

  // Hooks
  const { showLoader, hideLoader } = useLoaderHelper();

  const {
    renderRichTextInput,
    renderAutoCompleteInput,
    renderButtons,
    formHelper,
  } = useFormComponents({
    formControlStyle: formControlStyle,
    defaultValues: defaultValues,
    validationMode: 'all',
    labels: i18n.form,
    joiSchema: schema,
  });

  useEffect(() => {
    fetchParticipations();
    formHelper.reset();
  }, []);

  const fetchParticipations = async () => {
    if (
      history.location?.state?.breakoutRoomSlot &&
      history.location?.state?.breakoutRoom
    ) {
      showLoader();
      const promises = [];
      //TODO: sponsor, nowadays, does not exist
      [constants.Clusters.Pax.id, constants.Clusters.Delegations.id].map(
        (item) => {
          promises.push(getByCluster(eventId, item));
        }
      );
      const all = await Promise.all(promises);
      const _participations = all.flat().map((p) => parseParticipation(p));
      setAllParticipations(_participations);
      setParticipations(_participations);
      await fetchData();
      hideLoader();
    } else {
      history.push(
        `/events/${eventId}/${sponsorId}/services-configuration/breakoutrooms/${breakoutRoomId}`
      );
    }
  };

  const fetchData = async () => {
    const slot = history.location.state.breakoutRoomSlot;
    setSelectedSlot(slot);
    setBreakoutRoom(history.location.state.breakoutRoom);
    history.replace(history.location.pathname, null);
  };

  const navigateBack = (_selectedSlot) => {
    history.push({
      pathname: `/events/${eventId}/${sponsorId}/services-configuration/breakoutrooms/${breakoutRoomId}`,
      state: {
        breakoutRoom: breakoutRoom,
        breakoutRoomSlot: _selectedSlot ? _selectedSlot : selectedSlot,
        alreadyParsedData: true,
      },
    });
  };

  const reset = () => {
    setParticipations(allParticipations);
    formHelper.reset();
  };

  const insertCustomInvitation = (item) => {
    const _participations = [...participations, item];
    setParticipations(_participations);
  };

  const onSubmit = async (data) => {
    const emailData = {
      breakoutRoom: breakoutRoom,
      slot: selectedSlot,
      eventId: eventId,
      i18n: useI18n.breakoutRoomMail(intl),
      rawMessage: data.note,
    };
    try {
      const promises = [];
      const emailsPromises = [];
      const existInvitations =
        selectedSlot.invitations?.map((inv) => inv.participation.id) || [];
      data.emails.map((participation) => {
        if (participation.id) {
          if (!existInvitations.includes(participation.id)) {
            const input = {
              breakoutRoomAttendingParticipationId: participation.id,
              breakoutRoomAttendingSlotId: selectedSlot.id,
              isSelfProposed: false,
              state: states.PENDING,
            };
            promises.push(breakoutRoomAttendingHelper.create(input));
            generateBreakoutRoomEmail(emailTypes.INVITATION, {
              ...emailData,
              email: participation.email,
            });
          }
        } else {
          if (
            !selectedSlot.emails
              ?.map((i) => i.email)
              .includes(participation.value)
          ) {
            const input = {
              breakoutRoomInvitationUnregisteredSlotId: selectedSlot.id,
              state: states.PENDING,
              email: participation.value,
              //token: nanoid(), //in futuro sostituire email con token (solo per una questione grafica sull'url di accettazione)
            };
            emailsPromises.push(
              breakoutRoomInvitationUnregisteredHelper.create(input)
            );
            generateBreakoutRoomEmail(emailTypes.INVITATION, {
              ...emailData,
              email: participation.value,
            });
          }
        }
      });

      if (promises.length || emailsPromises.length) {
        showLoader();
        const _invitations = promises.length ? await Promise.all(promises) : [];
        const emails = emailsPromises.length
          ? await Promise.all(emailsPromises)
          : [];

        const invitations = _invitations.map((invitation) => ({
          ...invitation,
          participation: parseParticipation(invitation.participation),
        }));

        const invitationUnregistered = emails.map((email) =>
          parseEmailInvitation(email)
        );

        const _selectedSlot = {
          ...selectedSlot,
          emails: [
            ...(selectedSlot.emails ? selectedSlot.emails : []),
            ...emails,
          ],
          invitations: [
            ...(selectedSlot.invitations ? selectedSlot.invitations : []),
            ...invitations,
            ...invitationUnregistered,
          ],
        };

        hideLoader();
        navigateBack(_selectedSlot);
      } else {
        setSnackbar({
          isOpen: true,
          severity: AimSnackbarSeverity.error,
          message: i18n.snackbar.sendInvalid,
        });
      }
    } catch (e) {
      console.error(e);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.snackbar.sendFail,
      });
    }
  };

  return (
    <MainContainer>
      {/* {JSON.stringify(formHelper.errors)} */}
      <TitleAndBackButton
        backOnClickFunction={() => navigateBack()}
        backOnHoverText={i18n.page.backButton.tooltip}
        title={i18n.page.title}
        subtitle={i18n.page.subTitle}
      />
      <Container>
        <form onSubmit={formHelper.handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item xs={12}>
              {selectedSlot ? (
                <AimTypography variant="textBold">
                  {(selectedSlot.title || '') +
                    (' ' +
                      getDate(selectedSlot.start) +
                      ' - [' +
                      getHours(selectedSlot.start) +
                      ' - ' +
                      getHours(selectedSlot.end) +
                      ']')}
                </AimTypography>
              ) : (
                <></>
              )}
            </Grid>
            <Grid item xs={12}>
              {renderAutoCompleteInput({
                key: 'emails',
                textKeyName: 'userTxt',
                onInsertCustom: (item) => {
                  insertCustomInvitation(item);
                }, //permette l'inserimento di nuove email
                customOnChange: (items) => {
                  setSelectedParticipations(items);
                },
                valueMutation: (value) => {
                  return `${value}`.toLowerCase().trim();
                },
                array: participations,
              })}
            </Grid>
            <Grid item xs={12}>
              {renderRichTextInput({
                key: 'note',
              })}
            </Grid>
            <Grid item xs={12}>
              {renderButtons({
                buttonLabels: i18n.form.buttons,
                isDisabled: selectedParticipations.length === 0,
                onSaveClick: () => {
                  submitButton.current.click();
                },
                onCancelClick: () => {
                  reset();
                },
              })}
            </Grid>
          </Grid>

          <input
            style={{ visibility: 'hidden' }}
            ref={submitButton}
            type="submit"
          />
        </form>
      </Container>
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </MainContainer>
  );
};

export default BreakoutRoomInvite;

{
  /* <AimIconAndTextButton
                onClick={() => {
                  mockInvitations();
                }}
                text={'Mock invitations'}
              ></AimIconAndTextButton>
              <ul>
                {selectedParticipations.map((p) => {
                  if (!p) return null;
                  return <li key={p.id}>{`${p.text}`}</li>;
                })}
              </ul> 
              <AimIconAndTextButton
                onClick={() => {
                  submitButton.current.click();
                }}
                text={'invita'}
              ></AimIconAndTextButton>
            </Grid>*/
}

// const mockInvitations = () => {
//   if (selectedParticipations.length < participations.length) {
//     if (
//       selectedParticipations.filter((i) => i?.state === states.ACCEPTED)
//         .length < breakoutRoom.maxAttendants
//     ) {
//       let _participations = [...participations];
//       const _invitations = [
//         ...selectedSlot.invitations.map((i) => i.participation),
//         ...selectedParticipations,
//       ];
//       _invitations.map((participation) => {
//         const pass = _participations.filter((p) => p.id !== participation.id);
//         _participations = [...pass];
//       });
//       const sel =
//         _participations[
//           Math.round(Math.random() * (_participations.length - 1))
//         ];
//       const pass = selectedParticipations.find((p) => sel.id === p.id);
//       if (pass) {
//         mockInvitations();
//       } else {
//         const invitations = [...selectedParticipations, sel];
//         setSelectedParticipations(invitations);
//       }
//     } else {
//       setSnackbar({
//         isOpen: true,
//         severity: AimSnackbarSeverity.error,
//         message: i18n.snackbar.maxReached,
//       });
//     }
//   }
// };
