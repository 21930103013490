import React, { Fragment, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import FieldArray from './fieldArray';

import { useForm } from 'react-hook-form';

import {
  AimIconAndTextButton,
  AimSnackbar,
  AimSnackbarSeverity,
} from '@aim/components';

import { appState, constants } from '@aim/common';

import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import { useEffect } from 'react';

// import { appState } from '@aim/common';

const defaultValues = {
  rooms: [],
};

const RoomsAndParticipants = ({
  participantsForTheEvent,
  data,
  VATPercentage,
  vatId,
  isMobileOnly,
  //   eventVentureDetails,
  priceInformationGrid,
  i18n,
  whoAmI,
  startDate,
  endDate,
  eventId,
  onClickSubmit,
}) => {
  const runError = (message) => {
    // alert(message);
    setSnackbar({
      isOpen: true,
      severity: AimSnackbarSeverity.error,
      message: message,
    });
  };

  useEffect(() => {
    reset({
      rooms: data.map((x, ndx) => {
        return {
          ...x,
          guests: new Array(x.guestsNumber).fill({
            name: null,
            surname: null,
            email: null,
            phone: null,
            isPax: ndx > 0 ? false : true, // is a Registered Participant
            participantId: null,
            radioChoice: '1',
          }),
          notes: null,
        };
      }),
    });
  }, []);

  const [snackbar, setSnackbar] = useState({ isOpen: false });

  const {
    control,
    register,
    handleSubmit,
    getValues,
    errors,
    setValue,
    trigger,
    clearErrors,
    watch,
    reset,
  } = useForm({
    shouldUnregister: false,
    defaultValues: defaultValues,
  });
  const onSubmit = async (data) => {
    let proceed = 0;
    data.rooms?.forEach((x) => {
      x.guests?.forEach((y) => {
        console.log(y, 'the guest');
        if (y.name === null && (!y.familyName || !y.givenName)) {
          proceed++;
        }
      });
    });

    // before judging what's above go check the form and the time

    if (proceed) {
      runError('Fill in all the guests');
      return;
    }

    var rooms = data.rooms?.map((room) => {
      return {
        id: room.id,
        maxOccupancy: room.maxOccupancy,
        frontofficeName: room.frontofficeName,
        notes: room.notes,
        amount: +room.roomPrice,
        availability: room.hotelRoomAvailabilitiesIds.split(','),
        guests: room.guests,
      };
    });

    let obj = {
      start: startDate,
      end: endDate,
      status: constants.PaymentStatuses.PENDING.key,
      eventId: eventId,
      vat: VATPercentage,
      vatId,
      hotelRoomsToReserve: rooms.map((room) => {
        return {
          ...room,
          guests: room.guests.map((guest) => {
            if (
              typeof guest.name === 'object' &&
              JSON.stringify(guest.name) !== 'null'
            ) {
              const object = {
                name: guest.name.givenName || null,
                surname: guest.name.familyName || null,
                email: guest.name.email || null,
                phone: guest.name.phone || null,
                id: guest.name.value || null,
                isPax: true,
              };

              return object;
            } else {
              const object = {
                name: guest.givenName,
                surname: guest.familyName,
                email: guest.email,
                phone: guest.phone,
                isPax: false,
              };

              return object;
            }
          }),
        };
      }),
    };

    await appState.purchaseData.next(obj);

    onClickSubmit();
  };

  const roomsData = getValues();

  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FieldArray
          {...{
            control,
            register,
            defaultValues,
            getValues,
            setValue,
            errors,
            trigger,
            roomsData,
            clearErrors,
            watch,
            whoAmI,
            isMobileOnly,
            reset,
            VATPercentage,
            participantsForTheEvent,
          }}
        />
        <Grid
          container
          direction="column"
          style={{ padding: isMobileOnly ? '3%' : '1%' }}
        >
          <Grid
            container
            style={priceInformationGrid}
            direction="row"
            alignItems="flex-end"
            justifyContent="space-between"
          >
            <Grid item>
              <b>{`${i18n.page.totalNetPrice} (€)`}</b>
            </Grid>
            <Grid item style={{ fontSize: 20 }}>
              {roomsData.rooms
                ?.reduce((tot, room) => tot + +room.roomPrice, 0)
                .toFixed(2)
                .replace('.', ',')}
            </Grid>
          </Grid>

          <Grid
            container
            style={priceInformationGrid}
            direction="row"
            alignItems="flex-end"
            justifyContent="space-between"
          >
            <Grid item>
              <b>{`${i18n.page.iva} (${+VATPercentage ?? 0}%) (€)`}</b>
            </Grid>
            <Grid item style={{ fontSize: 20 }}>
              {(
                (roomsData.rooms?.reduce(
                  (tot, room) => tot + +room.roomPrice,
                  0
                ) /
                  100) *
                VATPercentage
              )
                .toFixed(2)
                .replace('.', ',')}
            </Grid>
          </Grid>
          <Grid
            container
            style={priceInformationGrid}
            direction="row"
            alignItems="flex-end"
            justifyContent="space-between"
          >
            <Grid item>
              <b>{`${i18n.page.totalPrice} (€)`}</b>
            </Grid>
            <Grid item style={{ fontSize: 20 }}>
              {(
                (roomsData.rooms?.reduce(
                  (tot, room) => tot + +room.roomPrice,
                  0
                ) /
                  100) *
                  (+VATPercentage ?? 0) +
                roomsData.rooms?.reduce((tot, room) => tot + +room.roomPrice, 0)
              )
                .toFixed(2)
                .replace('.', ',')}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          alignItems={isMobileOnly ? 'stretch' : 'flex-end'}
        >
          <AimIconAndTextButton
            text={'continue'}
            style={{
              padding: '1%',
            }}
            type="submit"
          >
            <ShoppingCartOutlinedIcon />
          </AimIconAndTextButton>
        </Grid>
      </form>
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </Fragment>
  );
};

export default RoomsAndParticipants;
