import { aws, appState } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

// const createProfile = /* GraphQL */ `
//   mutation CreateProfile(
//     $input: CreateProfileInput!
//     $condition: ModelProfileConditionInput
//   ) {
//     createProfile(input: $input, condition: $condition) {
//       id
//     }
//   }
// `;

// const updateProfile = /* GraphQL */ `
//   mutation UpdateProfile(
//     $input: UpdateProfileInput!
//     $condition: ModelProfileConditionInput
//   ) {
//     updateProfile(input: $input, condition: $condition) {
//       id
//     }
//   }
// `;

// const deleteProfile = /* GraphQL */ `
//   mutation DeleteProfile(
//     $input: DeleteProfileInput!
//     $condition: ModelProfileConditionInput
//   ) {
//     deleteProfile(input: $input, condition: $condition) {
//       id
//     }
//   }
// `;

const getProfile = /* GraphQL */ `
  query GetProfile($id: ID!) {
    getProfile(id: $id) {
      id
      name
      cluster
      isPublic
      createdBy
      category {
        id
        name
      }
    }
  }
`;

const listProfilesByClusters = /* GraphQL */ `
  query GetEventProfiles($id: ID!, $filter: ModelProfileFilterInput) {
    getAgency(id: $id) {
      id
      name
      prepaidBudget
      event {
        id
        name
        type
        profiles(filter: $filter) {
          items {
            id
            isDeleted
            name
            cluster
            isPublic
            category {
              id
              name
            }
            profileFeeBrackets {
              items {
                id
                priceOnAir
                priceOnSite
                accompanyingPersonPrice
                profileFeeConfiguration {
                  id
                  isActive
                }
                feeBracket {
                  id
                  createdAt
                  feeBracketModel {
                    end
                    name
                    start
                  }
                  feeDateRange {
                    id
                    start
                    end
                    label
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const useProfiles = () => {
  const get = (id, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: getProfile,
        variables: { id },
      })
        .then((response) => {
          resolve(response.data.getProfile);
        })
        .catch((e) => {
          console.error('get-profile', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  // const list = (eventId, useLoader = true) =>
  //   new Promise((resolve, reject) => {
  //     useLoader && showLoader();

  //     API.graphql({
  //       query: listProfiles,
  //       variables: { id: eventId },
  //     })
  //       .then((response) => {
  //         resolve(response.data.getEvent.profiles.items);
  //       })
  //       .catch((e) => {
  //         console.error('list-profile', e);
  //         reject();
  //       })
  //       .finally(() => useLoader && hideLoader());
  //   });

  const listByClusters = (agencyId, eventId, clusters, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: listProfilesByClusters,
        variables: {
          id: agencyId,
          filter: {
            isDeleted: { ne: true },
            or: { cluster: { eq: 'groups' } },
          },
        },
      })
        .then((response) => {
          resolve(response.data.getAgency?.event.profiles.items);
        })
        .catch((e) => {
          console.error('list-profiles', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  // const create = (input, useLoader = true) =>
  //   new Promise((resolve, reject) => {
  //     useLoader && showLoader();

  //     API.graphql({
  //       query: createProfile,
  //       variables: { input },
  //     })
  //       .then((response) => {
  //         resolve(response.data.createProfile);
  //       })
  //       .catch((e) => {
  //         console.error('create-profile', e);
  //         reject();
  //       })
  //       .finally(() => useLoader && hideLoader());
  //   });

  // const update = (input, useLoader = true) =>
  //   new Promise((resolve, reject) => {
  //     useLoader && showLoader();

  //     API.graphql({
  //       query: updateProfile,
  //       variables: { input },
  //     })
  //       .then((response) => {
  //         resolve(response.data.updateProfile);
  //       })
  //       .catch((e) => {
  //         console.error('update-profile', e);
  //         reject();
  //       })
  //       .finally(() => useLoader && hideLoader());
  //   });

  // const remove = (input, useLoader = true) =>
  //   new Promise((resolve, reject) => {
  //     useLoader && showLoader();

  //     API.graphql({
  //       query: deleteProfile,
  //       variables: { input },
  //     })
  //       .then((response) => {
  //         resolve(response.data.deleteProfile);
  //       })
  //       .catch((e) => {
  //         console.error('delete-profile', e);
  //         reject();
  //       })
  //       .finally(() => useLoader && hideLoader());
  //   });

  return { get, listByClusters /* list , create, update, remove */ };
};
