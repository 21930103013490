export default function (intl) {
  return {
    snackbar: {
      success: intl.formatMessage({
        description: 'Codes regenerated success',
        defaultMessage: 'Codes regenerated successfully',
      }),
      error: intl.formatMessage({
        description: 'Codes regenerated error',
        defaultMessage: 'Error regenerating codes',
      }),
    },
    dialog: {
      title: intl.formatMessage({
        description: 'dialog title',
        defaultMessage: 'Select intervention',
      }),
      placeholderSelect: intl.formatMessage({
        description: 'View Abstract List button text',
        defaultMessage: 'Select intervention',
      }),
      labelSelect: intl.formatMessage({
        description: 'View Abstract List button text',
        defaultMessage: 'Select an available slot from the list below',
      }),
      agreeBtn: intl.formatMessage({
        description: 'apply btn',
        defaultMessage: 'apply',
      }),
      cancelBtn: intl.formatMessage({
        description: 'cancel btn',
        defaultMessage: 'cancel',
      }),
    },
    actions: {
      myAgenda: {
        success: intl.formatMessage({
          description: 'success MyAgenda',
          defaultMessage: 'Event successfully add to MyAgenda',
        }),
        error: intl.formatMessage({
          description: 'error MyAgenda',
          defaultMessage: 'There was an error, please try again',
        }),
        remove: intl.formatMessage({
          description: 'remove MyAgenda',
          defaultMessage: 'Event successfully removed from MyAgenda',
        }),
      },
      saveAbstractSucces: intl.formatMessage({
        description: 'AbstractDocument saved confirm message',
        defaultMessage: 'AbstractDocument saved!',
      }),
      saveAbstractFail: intl.formatMessage({
        description: 'AbstractDocument saved confirm message',
        defaultMessage: 'Error during abstract document update',
      }),
      saveSucces: intl.formatMessage({
        description: 'Tags saved confirm message',
        defaultMessage: 'Tags saved!',
      }),
      saveTagsFail: intl.formatMessage({
        description: 'Tags saved confirm message',
        defaultMessage: 'Error during tags update',
      }),
      deleteInterventionSucces: intl.formatMessage({
        description: 'deleteInterventionSucces',
        defaultMessage: 'Intervention deleted!',
      }),
      deleteInterventionFail: intl.formatMessage({
        description: 'deleteInterventionFail',
        defaultMessage: 'Error during intervention delete',
      }),
      saveRoomsSucces: intl.formatMessage({
        description: 'Rooms saved confirm message',
        defaultMessage: 'Rooms saved!',
      }),
      saveRoomsFail: intl.formatMessage({
        description: 'Rooms saved confirm message',
        defaultMessage: 'Error during rooms update',
      }),
      saveInterventionsSucces: intl.formatMessage({
        description: 'Interventions saved confirm message',
        defaultMessage: 'Intervention saved!',
      }),
      saveInterventionsFail: intl.formatMessage({
        description: 'Interventions saved confirm message',
        defaultMessage: 'Error during intervention update',
      }),
      emptyTag: intl.formatMessage({
        description: 'TagsHaveEmptyTag',
        defaultMessage: 'Error cannot save an empty tag',
      }),
      doubleTag: intl.formatMessage({
        description: 'DoubleTag',
        defaultMessage: 'You are trying to add an already existing tag',
      }),
      doubleRoom: intl.formatMessage({
        description: 'DoubleTag',
        defaultMessage: 'You are trying to add an already existing room',
      }),
      emptyRoomName: intl.formatMessage({
        description: 'RoomsHaveEmptyName',
        defaultMessage: 'Error cannot save an empty room name',
      }),
      timeRange: intl.formatMessage({
        description: 'OutOfTimeRange',
        defaultMessage: 'The time range you are selecting is not correct',
      }),
    },
    placeholders: {
      tagName: intl.formatMessage({
        description: 'Write here tag name placeholder',
        defaultMessage: 'Write here tag name',
      }),
      emptyArrayLabel: intl.formatMessage({
        description: 'emptyArrayLabel',
        defaultMessage: 'This option is not available',
      }),
    },
    buttons: {
      cancel: {
        tooltip: intl.formatMessage({
          description: 'delete tag',
          defaultMessage: 'cancel',
        }),
      },
      filter: {
        tooltip: intl.formatMessage({
          description: 'filter',
          defaultMessage: 'Filter',
        }),
      },
      delete: {
        label: intl.formatMessage({
          description: 'deleteAgendaSession',
          defaultMessage: 'Delete',
        }),
        tooltip: intl.formatMessage({
          description: 'delete tag',
          defaultMessage: 'cancel',
        }),
        disabledTooltip: intl.formatMessage({
          description: 'alreadyPurchasedSymposium',
          defaultMessage:
            'You cannot delete a symposium that has already been purchased',
        }),
      },
      save: {
        tooltip: intl.formatMessage({
          description: 'save tags',
          defaultMessage: 'Save',
        }),
      },
      handleRooms: intl.formatMessage({
        description: 'handleRooms',
        defaultMessage: 'Handle rooms',
      }),
      handleTags: intl.formatMessage({
        description: 'handleTags',
        defaultMessage: 'Handle tags',
      }),
      newPosterSession: intl.formatMessage({
        description: 'new poster session button',
        defaultMessage: 'Poster session',
      }),
      sessionTypologie: intl.formatMessage({
        description: 'session typologies button',
        defaultMessage: 'Session typologies',
      }),
      regenerateCodes: intl.formatMessage({
        description: 'regenerateCodes',
        defaultMessage: 'Regenerate Codes',
      }),
    },
    page: {
      backButton: {
        label: intl.formatMessage({
          description: 'event label',
          defaultMessage: 'Event',
        }),
        posterDetailLabel: intl.formatMessage({
          description: 'poster detail page back button label',
          defaultMessage: 'back',
        }),
        tooltip: intl.formatMessage({
          description: 'back to event',
          defaultMessage: 'Back to Event',
        }),
      },
      title: intl.formatMessage({
        description: 'program page title',
        defaultMessage: 'Program',
      }),
      error: {
        periodNotDefined: intl.formatMessage({
          description: 'PeriodOfProgramNotDefined',
          defaultMessage: 'Period of event is not defined yet ',
        }),
        roomsMissing: intl.formatMessage({
          description: 'RoomsMissing',
          defaultMessage: 'Rooms are not defined yet.',
        }),
        roomsMissingAddOne: intl.formatMessage({
          description: 'RoomsMissing add one',
          defaultMessage: 'Add One',
        }),
      },
    },
    toolbar: {
      buttons: {
        add: {
          tooltip: intl.formatMessage({
            description: 'add new session',
            defaultMessage: 'Add new session',
          }),
        },
      },
    },
    calendar: {
      buttons: {
        previous: {
          label: intl.formatMessage({
            description: 'previous',
            defaultMessage: 'Previous',
          }),
        },
        next: {
          label: intl.formatMessage({
            description: 'next',
            defaultMessage: 'next',
          }),
        },
        showPreview: {
          label: intl.formatMessage({
            description: 'Preview',
            defaultMessage: 'Preview',
          }),
        },
        zoom: {
          label: intl.formatMessage({
            description: 'Zoom level',
            defaultMessage: 'Zoom level',
          }),
        },
      },
    },
    dialogs: {
      crudDialog: {
        title: intl.formatMessage({
          description: 'crudDialogTitle',
          defaultMessage: 'Session',
        }),
        newSessionTitle: intl.formatMessage({
          description: 'crudDialogNewSessionTitle',
          defaultMessage: 'New',
        }),
        helpers: {
          clickHere: intl.formatMessage({
            description: 'sessionClickHereHelper',
            defaultMessage: 'Click here',
          }),
        },
        sponsoredBy: intl.formatMessage({
          description: 'sponsoredBy',
          defaultMessage: 'Sponsored by',
        }),
        buttons: {
          abort: intl.formatMessage({
            description: 'crudDialogButtonsAbort',
            defaultMessage: 'Cancel',
          }),
          save: intl.formatMessage({
            description: 'crudDialogButtonsSave',
            defaultMessage: 'Save',
          }),
          saveDraft: intl.formatMessage({
            description: 'crudDialogButtonsSaveDraft',
            defaultMessage: 'Save in draft',
          }),
          saveAndPlan: intl.formatMessage({
            description: 'crudDialogButtonsSaveAndPlan',
            defaultMessage: 'Save and plan interventions',
          }),
          plan: intl.formatMessage({
            description: 'crudDialogButtonsSaveAndPlan',
            defaultMessage: 'Plan interventions',
          }),
          plan: intl.formatMessage({
            description: 'crudDialogButtonsPlan',
            defaultMessage: 'Plan interventions',
          }),
        },
        labels: {
          placeholder: intl.formatMessage({
            description: 'placeholder',
            defaultMessage: 'Insert',
          }),
          none: intl.formatMessage({
            description: 'none placeholder',
            defaultMessage: 'None',
          }),
          room: intl.formatMessage({
            description: 'room',
            defaultMessage: 'Room',
          }),
          name: intl.formatMessage({
            description: 'title',
            defaultMessage: 'Title',
          }),
          canBeBought: intl.formatMessage({
            description: 'canBeBought',
            defaultMessage: 'Sponsored Session',
          }),
          context: intl.formatMessage({
            description: 'sessionContextLabel',
            defaultMessage: 'Context',
          }),
          attendees: intl.formatMessage({
            description: 'attendeesLabel',
            defaultMessage: 'Select attendees for this session',
          }),
          attendeesName: intl.formatMessage({
            description: 'attendeesNameLabel',
            defaultMessage: 'Select the names',
          }),
          visibility: {
            label: intl.formatMessage({
              description: 'visibilityLabel',
              defaultMessage: 'Make visible only to profiles of:',
            }),
            placeholder: intl.formatMessage({
              description: 'visibilityplaceholder',
              defaultMessage: 'Select a profile',
            }),
          },
          visibilityCategory: {
            label: intl.formatMessage({
              description: 'visibilityCategoryLabel',
              defaultMessage: 'Category',
            }),
            placeholder: intl.formatMessage({
              description: 'visibilityCategoryPlaceholder',
              defaultMessage: 'Select a Category',
            }),
          },
          webinarModerator: {
            label: intl.formatMessage({
              description: 'webinarModeratorLabel',
              defaultMessage: 'Webinar Chat Moderator',
            }),
            placeholder: intl.formatMessage({
              description: 'webinarModeratorplaceholder',
              defaultMessage: 'Select a Moderator',
            }),
          },
          duration: intl.formatMessage({
            description: 'durationLabel',
            defaultMessage: 'Duration',
          }),
          hasWebinarCounterUser: intl.formatMessage({
            description: 'hasWebinarCounterUser',
            defaultMessage: 'Counter User',
          }),
          hasWebinarReactions: intl.formatMessage({
            description: 'hasWebinarReactions',
            defaultMessage: 'Reactions',
          }),
          hasWebinarPolling: intl.formatMessage({
            description: 'hasWebinarPolling',
            defaultMessage: 'Polling',
          }),
          hasWebinarChat: intl.formatMessage({
            description: 'hasWebinarChat',
            defaultMessage: 'Chat',
          }),
          hasWebinarQA: intl.formatMessage({
            description: 'hasWebinarQA',
            defaultMessage: 'Q&A',
          }),
          webinarActive: intl.formatMessage({
            description: 'webinarActive',
            defaultMessage: 'Active',
          }),
          webinarInactive: intl.formatMessage({
            description: 'webinarInactive',
            defaultMessage: 'Inactive',
          }),
          abstract: intl.formatMessage({
            description: 'sessionAbstractLabel',
            defaultMessage: 'Abstract',
          }),
          type: intl.formatMessage({
            description: 'sessionTypeLabel',
            defaultMessage: 'Session Typology',
          }),
          sessionMode: intl.formatMessage({
            description: 'sessionModeLabel',
            defaultMessage: 'Session Mode',
          }),
          title: intl.formatMessage({
            description: 'sessionTitleLabel',
            defaultMessage: 'Session Title',
          }),
          president: intl.formatMessage({
            description: 'sessionPresidentLabel',
            defaultMessage: 'President',
          }),
          moderators: intl.formatMessage({
            description: 'sessionModeratorLabel',
            defaultMessage: 'Moderators',
          }),
          provocateurs: intl.formatMessage({
            description: 'sessionProvacateurLabel',
            defaultMessage: 'Provocateurs',
          }),
          description: intl.formatMessage({
            description: 'sessionDescriptionLabel',
            defaultMessage: 'Description',
          }),
          shortDescription: intl.formatMessage({
            description: 'sessionShortDescriptionLabel',
            defaultMessage: 'Short description',
          }),
          coverImage: intl.formatMessage({
            description: 'sessionCoverImageLabel',
            defaultMessage: 'Uploaded cover image',
          }),
          visibilities: intl.formatMessage({
            description: 'sessionVisibilitiesLabel',
            defaultMessage: 'Visibilities',
          }),
          tags: intl.formatMessage({
            description: 'sessionTagsLabel',
            defaultMessage: 'Tags',
          }),
          linkPax: intl.formatMessage({
            description: 'sessionLinkPaxLabel',
            defaultMessage: 'Link Pax',
          }),
          linkSpeaker: intl.formatMessage({
            description: 'sessionLinkSpeakerLabel',
            defaultMessage: 'Link Speaker',
          }),
          rating: intl.formatMessage({
            description: 'sessionRatingLabel',
            defaultMessage: 'Rating',
          }),
          topic: intl.formatMessage({
            description: 'sessionTopicLabel',
            defaultMessage: 'Topic',
          }),
          speaker: intl.formatMessage({
            description: 'sessionSpeakerLabel',
            defaultMessage: 'Speaker',
          }),
          instructions: intl.formatMessage({
            description: 'sessionInstructionsLabel',
            defaultMessage: 'Instructions for exposure',
          }),
          start: intl.formatMessage({
            description: 'sessionStartLabel',
            defaultMessage: 'Start',
          }),
          end: intl.formatMessage({
            description: 'sessionEndLabel',
            defaultMessage: 'End',
          }),
          date: intl.formatMessage({
            description: 'sessionDateLabel',
            defaultMessage: 'Date',
          }),
          urlWebinar: intl.formatMessage({
            description: 'urlWebinar',
            defaultMessage: 'Url webinar',
          }),
        },
        error: {
          fieldRequired: intl.formatMessage({
            description: 'field required',
            defaultMessage: 'field required',
          }),
          sessionOverlaps: intl.formatMessage({
            description: 'session overlaps',
            defaultMessage: 'Sessions cannot overlap',
          }),
          sessionsConflict: intl.formatMessage({
            description: 'session conflicts',
            defaultMessage:
              'There is another session scheduled for this time range',
          }),
          cannotReducePlannedSession: intl.formatMessage({
            description:
              'Cannot redure already configured session error message',
            defaultMessage:
              'Cannot reduce duration of an already configured session',
          }),
        },
      },
      previewDialog: {
        title: intl.formatMessage({
          description: 'previewDialogTitle',
          defaultMessage: 'Session preview',
        }),
        sponsoredBy: intl.formatMessage({
          description: 'sponsoredBy',
          defaultMessage: 'Sponsored by',
        }),
      },
      deleteDialog: {
        title: intl.formatMessage({
          description: 'delete dialog title',
          defaultMessage: 'Delete session',
        }),
        message: intl.formatMessage({
          description: 'delete dialog message',
          defaultMessage: 'Are you sure that you want to delete this session?',
        }),
        intervention: {
          title: intl.formatMessage({
            description: 'deleteInterventionDialogTitle',
            defaultMessage: 'Delete',
          }),
          messageDeleteConfirmation: intl.formatMessage({
            description: 'deleteInterventionDialogMessageDeleteConfirmation',
            defaultMessage: 'Are you sure you want to continue?',
          }),
        },
        sessions: {
          title: intl.formatMessage({
            description: 'deleteSessionDialogTitle',
            defaultMessage: 'Delete',
          }),
          messageDependency1: intl.formatMessage({
            description: 'deleteSessionDialogMessageDependency1',
            defaultMessage: 'This session have ',
          }),
          messageDependency2: intl.formatMessage({
            description: 'deleteSessionDialogMessageDependency2',
            defaultMessage: ' dependant interventions.',
          }),
          messageDependency3: intl.formatMessage({
            description: 'deleteSessionDialogMessageDependency3',
            defaultMessage:
              'Deleting this session will result in the definitive deletion of the interventions.',
          }),
          messageNoDependency: intl.formatMessage({
            description: 'deleteSessionDialogMessageNoDependency',
            defaultMessage: 'No interventions are defined for this session.',
          }),
          messageDeleteConfirmation: intl.formatMessage({
            description: 'deleteSessionDialogMessageDeleteConfirmation',
            defaultMessage: 'Are you sure you want to continue?',
          }),
        },
        rooms: {
          title: intl.formatMessage({
            description: 'deleteRoomDialogTitle',
            defaultMessage: 'Delete',
          }),
          messageDependency1: intl.formatMessage({
            description: 'deleteRoomDialogMessageDependency1',
            defaultMessage: 'This room have ',
          }),
          messageDependency2: intl.formatMessage({
            description: 'deleteRoomDialogMessageDependency2',
            defaultMessage: ' dependant sessions.',
          }),
          messageDependency3: intl.formatMessage({
            description: 'deleteRoomDialogMessageDependency3',
            defaultMessage:
              'Deleting the room will result in the definitive deletion of the session.',
          }),
          messageNoDependency: intl.formatMessage({
            description: 'deleteRoomDialogMessageNoDependency',
            defaultMessage: 'No sessions are defined for this room.',
          }),
          messageDeleteConfirmation: intl.formatMessage({
            description: 'deleteRoomDialogMessageDeleteConfirmation',
            defaultMessage: 'Are you sure you want to continue?',
          }),
        },
        tags: {
          title: intl.formatMessage({
            description: 'deleteTagsDialogTitle',
            defaultMessage: 'Delete',
          }),
          messageDependency1: intl.formatMessage({
            description: 'deleteTagsDialogMessageDependency1',
            defaultMessage: 'This tag have ',
          }),
          messageDependency2: intl.formatMessage({
            description: 'deleteTagsDialogMessageDependency2',
            defaultMessage: ' dependant sessions.',
          }),
          messageDependency3: intl.formatMessage({
            description: 'deleteTagsDialogMessageDependency3',
            defaultMessage:
              'Deleting this tag will result in it being removed from sessions.',
          }),
          messageNoDependency: intl.formatMessage({
            description: 'deleteTagsDialogMessageNoDependency',
            defaultMessage: 'No session has this tag.',
          }),
          messageDeleteConfirmation: intl.formatMessage({
            description: 'deleteTagsDialogMessageDeleteConfirmation',
            defaultMessage: 'Are you sure you want to continue?',
          }),
        },
      },
    },
    abstractFilter: {
      title: intl.formatMessage({
        description: 'title',
        defaultMessage: 'List Abstracts',
      }),
      subTitle: intl.formatMessage({
        description: 'subTitle',
        defaultMessage: 'Fill in the fields below to view the abstracts',
      }),
      buttons: {
        search: intl.formatMessage({
          description: 'buttonSearch',
          defaultMessage: 'Search',
        }),
        reset: intl.formatMessage({
          description: 'buttonReset',
          defaultMessage: 'Reset',
        }),
        edit: intl.formatMessage({
          description: 'buttonEdit',
          defaultMessage: 'Edit',
        }),
      },
      filterLabels: {
        placeholder: intl.formatMessage({
          description: 'placeholder',
          defaultMessage: 'Insert',
        }),
        title: intl.formatMessage({
          description: 'labelTitle',
          defaultMessage: 'Abstract title',
        }),
        category: intl.formatMessage({
          description: 'labelCategory',
          defaultMessage: 'Category',
        }),
        minValue: intl.formatMessage({
          description: 'labelMinValue',
          defaultMessage: 'Rating from',
        }),
        maxValue: intl.formatMessage({
          description: 'labelMaxValue',
          defaultMessage: 'Rating to',
        }),
        tipology: intl.formatMessage({
          description: 'labelTipology',
          defaultMessage: 'Accepted as',
        }),
      },
      labels: {
        select: {
          label: intl.formatMessage({
            description: 'select',
            defaultMessage: 'Select',
          }),
          placeholder: intl.formatMessage({
            description: 'select an abstract',
            defaultMessage: 'Select an abstract',
          }),
        },
        edit: {
          label: intl.formatMessage({
            description: 'edit',
            defaultMessage: 'Edit',
          }),
          placeholder: intl.formatMessage({
            description: 'edit an abstract',
            defaultMessage: 'Edit an abstract',
          }),
        },
        status: {
          label: intl.formatMessage({
            description: 'status',
            defaultMessage: 'Status',
          }),
          placeholder: intl.formatMessage({
            description: 'select a status',
            defaultMessage: 'Select a status',
          }),
        },
        cod0: {
          label: intl.formatMessage({
            description: 'cod 0',
            defaultMessage: 'Cod 0',
          }),
          placeholder: intl.formatMessage({
            description: 'write here a cod 0',
            defaultMessage: 'Write here a cod 0',
          }),
        },
        cod1: {
          label: intl.formatMessage({
            description: 'cod1',
            defaultMessage: 'Cod 1',
          }),
          placeholder: intl.formatMessage({
            description: 'write here a cod1',
            defaultMessage: 'Write here a cod 1',
          }),
        },
        cod2: {
          label: intl.formatMessage({
            description: 'cod2',
            defaultMessage: 'Cod 2',
          }),
          placeholder: intl.formatMessage({
            description: 'write here a cod2',
            defaultMessage: 'Write here a cod 2',
          }),
        },
        print: {
          label: intl.formatMessage({
            description: 'print',
            defaultMessage: 'Print',
          }),
          placeholder: intl.formatMessage({
            description: 'write here a print',
            defaultMessage: 'Write here a print',
          }),
        },
        int: {
          label: intl.formatMessage({
            description: 'int',
            defaultMessage: 'Int',
          }),
          placeholder: intl.formatMessage({
            description: 'write here a int',
            defaultMessage: 'Write here a int',
          }),
        },
        disc: {
          label: intl.formatMessage({
            description: 'disc',
            defaultMessage: 'Disc',
          }),
          placeholder: intl.formatMessage({
            description: 'write here a disc',
            defaultMessage: 'Write here a disc',
          }),
        },
        sentBy: {
          label: intl.formatMessage({
            description: 'sentBy',
            defaultMessage: 'Sent By',
          }),
        },
        title: {
          label: intl.formatMessage({
            description: 'title',
            defaultMessage: 'Title',
          }),
          placeholder: intl.formatMessage({
            description: 'write here a title',
            defaultMessage: 'Write here a title',
          }),
        },
        date: {
          label: intl.formatMessage({
            description: 'presentation date',
            defaultMessage: 'Presentation date',
          }),
          placeholder: intl.formatMessage({
            description: 'write here a date',
            defaultMessage: 'Write here a date',
          }),
        },
        session: {
          label: intl.formatMessage({
            description: 'session',
            defaultMessage: 'Session',
          }),
          placeholder: intl.formatMessage({
            description: 'write here a session',
            defaultMessage: 'Write here a session',
          }),
        },
        category: {
          label: intl.formatMessage({
            description: 'category',
            defaultMessage: 'Category',
          }),
          placeholder: intl.formatMessage({
            description: 'write here a category',
            defaultMessage: 'Write here a category',
          }),
        },
        preferred: {
          label: intl.formatMessage({
            description: 'preferred',
            defaultMessage: 'Preferred',
          }),
          placeholder: intl.formatMessage({
            description: 'write here a preferred',
            defaultMessage: 'Write here a preferred',
          }),
        },
        acceptedAs: {
          label: intl.formatMessage({
            description: 'accepted as',
            defaultMessage: 'Accepted as',
          }),
          placeholder: intl.formatMessage({
            description: 'select accepted as',
            defaultMessage: 'Select accepted as',
          }),
        },
        processing: {
          label: intl.formatMessage({
            description: 'review status',
            defaultMessage: 'Review status',
          }),
          placeholder: intl.formatMessage({
            description: 'write here a processing',
            defaultMessage: 'Write here a processing',
          }),
        },
        finalScore: {
          label: intl.formatMessage({
            description: 'final score',
            defaultMessage: 'Final Score',
          }),
          placeholder: intl.formatMessage({
            description: 'write here a final score',
            defaultMessage: 'Write here a final score',
          }),
        },
        reviewers: {
          label: intl.formatMessage({
            description: 'reviewers',
            defaultMessage: 'Reviewers',
          }),
          placeholder: intl.formatMessage({
            description: 'select reviewers',
            defaultMessage: 'Select reviewers',
          }),
        },
        presentation: {
          label: intl.formatMessage({
            description: 'presentation',
            defaultMessage: 'Presentation',
          }),
        },
      },
    },
    interventions: {
      backButton: intl.formatMessage({
        description: 'backButton',
        defaultMessage: 'back',
      }),
      errors: {
        emptyField: intl.formatMessage({
          description: 'emptyField',
          defaultMessage: 'This field cannot be empty',
        }),
      },
      title: {
        label: intl.formatMessage({
          description: 'plan intervention title page',
          defaultMessage: 'Plan Interventions',
        }),
      },
      namePlaceholder: {
        label: intl.formatMessage({
          description: 'plan intervention title page',
          defaultMessage: 'Intervention',
        }),
      },
    },
    rooms: {
      labels: {
        name: intl.formatMessage({
          description: 'name',
          defaultMessage: 'Name',
        }),
        capacity: intl.formatMessage({
          description: 'capacity',
          defaultMessage: 'Capacity',
        }),
        delay: intl.formatMessage({
          description: 'delay',
          defaultMessage: 'Delay',
        }),
        type: intl.formatMessage({
          description: 'type',
          defaultMessage: 'Type',
        }),
      },
    },

    card: {
      emptyField: {
        msg1: intl.formatMessage({
          description: 'emptyFieldMsg1',
          defaultMessage: 'No',
        }),
        msg2: intl.formatMessage({
          description: 'emptyFieldMsg2',
          defaultMessage: 'specified',
        }),
      },
      pinEvent: intl.formatMessage({
        description: 'pinEvent',
        defaultMessage: 'Add to MyAgenda',
      }),
      unPinEvent: intl.formatMessage({
        description: 'unPinEvent',
        defaultMessage: 'Remove from MyAgenda',
      }),
      playEvent: intl.formatMessage({
        description: 'playEvent',
        defaultMessage: 'Take part',
      }),
      placeholder: intl.formatMessage({
        description: 'placeholder',
        defaultMessage: 'Insert',
      }),
      room: intl.formatMessage({
        description: 'room',
        defaultMessage: 'Room',
      }),
      name: intl.formatMessage({
        description: 'title',
        defaultMessage: 'Title',
      }),
      context: intl.formatMessage({
        description: 'sessionContextLabel',
        defaultMessage: 'Context',
      }),
      speech: intl.formatMessage({
        description: 'sessionSpeech',
        defaultMessage: 'Interventions',
      }),
      abstract: intl.formatMessage({
        description: 'sessionAbstractLabel',
        defaultMessage: 'Abstract',
      }),
      type: intl.formatMessage({
        description: 'sessionTypeLabel',
        defaultMessage: 'Type',
      }),
      title: intl.formatMessage({
        description: 'sessionTitleLabel',
        defaultMessage: 'Title',
      }),
      president: intl.formatMessage({
        description: 'sessionPresidentLabel',
        defaultMessage: 'President',
      }),
      moderators: intl.formatMessage({
        description: 'sessionModeratorLabel',
        defaultMessage: 'Moderators',
      }),
      provocateurs: intl.formatMessage({
        description: 'sessionProvacateurLabel',
        defaultMessage: 'Provocateurs',
      }),
      description: intl.formatMessage({
        description: 'sessionDescriptionLabel',
        defaultMessage: 'Description',
      }),
      coverImage: intl.formatMessage({
        description: 'sessionCoverImageLabel',
        defaultMessage: 'Uploaded cover image',
      }),
      visibility: intl.formatMessage({
        description: 'sessionVisibilityLabel',
        defaultMessage: 'Visibility',
      }),
      tags: intl.formatMessage({
        description: 'sessionTagsLabel',
        defaultMessage: 'Tags',
      }),
      linkPax: intl.formatMessage({
        description: 'sessionLinkPaxLabel',
        defaultMessage: 'Link Pax',
      }),
      linkSpeaker: intl.formatMessage({
        description: 'sessionLinkSpeakerLabel',
        defaultMessage: 'Link Speaker',
      }),
      rating: intl.formatMessage({
        description: 'sessionRatingLabel',
        defaultMessage: 'Rating',
      }),
      topic: intl.formatMessage({
        description: 'sessionTopicLabel',
        defaultMessage: 'Topic',
      }),
      speaker: intl.formatMessage({
        description: 'sessionSpeakerLabel',
        defaultMessage: 'Speakers',
      }),
      instructions: intl.formatMessage({
        description: 'sessionInstructionsLabel',
        defaultMessage: 'Instructions for exposure',
      }),
      start: intl.formatMessage({
        description: 'sessionStartLabel',
        defaultMessage: 'Start',
      }),
      end: intl.formatMessage({
        description: 'sessionEndLabel',
        defaultMessage: 'End',
      }),
      date: intl.formatMessage({
        description: 'sessionDateLabel',
        defaultMessage: 'Date',
      }),
    },
    speechesContainer: {
      errors: {
        noSpeeches: intl.formatMessage({
          description: 'slot loading error',
          defaultMessage: 'Interventions slot not available',
        }),
      },
      calendar: {
        placeholder: intl.formatMessage({
          description: 'placeholder',
          defaultMessage: 'Set topic...',
        }),
        interventionsLabel: intl.formatMessage({
          description: 'placeholder',
          defaultMessage: 'Interventions',
        }),
      },
    },
    slotInfo: {
      title: intl.formatMessage({
        description: 'slotInfo title',
        defaultMessage: 'Intervention Info',
      }),
      form: {
        type: {
          label: intl.formatMessage({
            description: 'type label',
            defaultMessage: 'Intervention Type',
          }),
          placeholder: intl.formatMessage({
            description: 'type placeholder',
            defaultMessage: 'Select intervention type',
          }),
        },
        duration: intl.formatMessage({
          description: 'duration label',
          defaultMessage: 'Intervention duration (min)',
        }),
        title: {
          label: intl.formatMessage({
            description: 'Title label',
            defaultMessage: 'Title',
          }),
          placeholder: intl.formatMessage({
            description: 'Title placeholder',
            defaultMessage: 'Title',
          }),
          error: intl.formatMessage({
            description: 'Title error',
            defaultMessage: 'Title is required',
          }),
        },
        titleDescription: {
          label: intl.formatMessage({
            description: 'title description label',
            defaultMessage: 'Title description',
          }),
          placeholder: intl.formatMessage({
            description: 'Title descirpition placeholder',
            defaultMessage: 'Select title description',
          }),
        },
        attendees: {
          label: intl.formatMessage({
            description: 'attendee label',
            defaultMessage: 'Attendees',
          }),
          placeholder: intl.formatMessage({
            description: 'attendee placeholder',
            defaultMessage: 'Select attendees',
          }),
          error: intl.formatMessage({
            description: 'attendee error',
            defaultMessage: 'At least one attendee is required',
          }),
        },
        authors: intl.formatMessage({
          description: 'authors label',
          defaultMessage: 'Authors',
        }),
        presenter: intl.formatMessage({
          description: 'presenter label',
          defaultMessage: 'Presenter',
        }),
        start: intl.formatMessage({
          description: 'intervention start time label',
          defaultMessage: 'Intervention start time',
        }),
        end: intl.formatMessage({
          description: 'intervention end time label',
          defaultMessage: 'Intervention end time',
        }),
        code: intl.formatMessage({
          description: 'code label',
          defaultMessage: 'Code',
        }),
        instructions: intl.formatMessage({
          description: 'instructions label',
          defaultMessage: 'Instructions For Exposure',
        }),
        cancelBtn: intl.formatMessage({
          description: 'cancelBtn label',
          defaultMessage: 'cancel',
        }),
        saveBtn: intl.formatMessage({
          description: 'saveBtn label',
          defaultMessage: 'save',
        }),
        errors: {
          inconsistentStartEnd: intl.formatMessage({
            description: 'start time must be before end time',
            defaultMessage: 'Slot start time must be before end time',
          }),
          slotOutsideSessionRange: intl.formatMessage({
            description: 'slot outside session range error',
            defaultMessage:
              'Current start and end times are outside of current session time range',
          }),
          overlappingSlots: intl.formatMessage({
            description: 'overlapping slots error',
            defaultMessage:
              'Current slot time range is overlapping with another slot, please adjust the other slot time range first.',
          }),
        },
      },
    },
    speechDetail: {
      splash: {
        introText: intl.formatMessage({
          description: 'Speech detail splash screen intro text',
          defaultMessage:
            'Select a speech for details or click View Abstract List to view Abstract Documents to associate with slots',
        }),
        buttons: {
          viewAbstractsList: intl.formatMessage({
            description: 'View Abstract List button text',
            defaultMessage: 'View Abstract List',
          }),
        },
      },
      abstractList: {
        instructions: intl.formatMessage({
          description: 'abstract list instructions',
          defaultMessage:
            'Select desired Abstract and then the slot to bind it to',
        }),
        title: intl.formatMessage({
          description: 'abstract list title',
          defaultMessage: 'Select abstract',
        }),
        listTitle: intl.formatMessage({
          description: 'abstract list list title',
          defaultMessage: 'Abstract list',
        }),
        buttons: {
          viewAbstractsList: intl.formatMessage({
            description: 'View Abstract List button text',
            defaultMessage: 'View Abstract List',
          }),
        },
        header: {
          columns: {
            title: intl.formatMessage({
              description: 'Abstract List Title column header',
              defaultMessage: 'Title',
            }),
            category: intl.formatMessage({
              description: 'Abstract List Category column header',
              defaultMessage: 'Category',
            }),
            acceptedAs: intl.formatMessage({
              description: 'Abstract List Accepted As column header',
              defaultMessage: 'Accepted As',
            }),
            finalScore: intl.formatMessage({
              description: 'Abstract List Final Score column header',
              defaultMessage: 'Final Score',
            }),
            presenter: intl.formatMessage({
              description: 'Abstract List Presenter column header',
              defaultMessage: 'Presenter',
            }),
            authors: intl.formatMessage({
              description: 'Abstract List Authors column header',
              defaultMessage: 'Authors',
            }),
          },
        },
      },
      buttons: {
        backButton: intl.formatMessage({
          description: 'abstract list back button label',
          defaultMessage: 'back',
        }),
      },
    },
  };
}
