import { aws, appState } from '@aim/common';

const getHotelDataQuery = /* GraphQL */ `
  query GetHotelEventVenture($id: ID!) {
    getHotelEventVenture(id: $id) {
      id
      hotel {
        id
        address
        name
        stars
        images {
          items {
            extension
            id
            originalName
            size
          }
        }
        descriptions {
          items {
            description
            id
            language
          }
        }
      }
    }
  }
`;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

export const getHotelData = (id) =>
  new Promise((resolve, reject) => {
    showLoader();
    aws.API.graphql({
      query: getHotelDataQuery,
      variables: { id },
    })
      .then((response) => {
        const { data } = response;
        resolve(data?.getHotelEventVenture);
      })
      .catch((e) => {
        console.error('get-hotel-data', e);
        reject();
      })
      .finally(hideLoader);
  });
