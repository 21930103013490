import React from 'react';

import { constants } from '@aim/common';

import { AimNumericInput, AimTypography, AimTextField } from '@aim/components';

const StandItemAddToCartDialogContent = ({
  i18n,
  addToCartDialog,
  setAddToCartDialog,
  isPlusDisabled,
}) => {
  //Constants
  const isNoteEnabled = addToCartDialog.item?.isNoteEnabled;
  const noteLabel = addToCartDialog.item?.noteLabel;
  const isNoteRequired = addToCartDialog.item?.isNoteRequired;
  const itemTypes = constants.OtherStandServiceItemType;

  return (
    <>
      {addToCartDialog?.item?.type !== itemTypes.singleChoice.id ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <AimTypography variant="textBold">
            {i18n.addToCartDialog.fields.quantity.label}
          </AimTypography>
          <AimNumericInput
            value={addToCartDialog?.quantity}
            editableInput
            onClickPlus={() =>
              setAddToCartDialog({
                ...addToCartDialog,
                quantity: addToCartDialog.quantity + 1,
              })
            }
            onClickMinus={() =>
              setAddToCartDialog({
                ...addToCartDialog,
                quantity: addToCartDialog.quantity - 1,
              })
            }
            onChange={(value) => {
              setAddToCartDialog({
                ...addToCartDialog,
                quantity: value,
              });
            }}
            disabledPlus={isPlusDisabled}
            disabledMinus={addToCartDialog?.quantity <= 1}
          />
        </div>
      ) : null}

      {addToCartDialog?.item?.type === itemTypes.doubleCost.id && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <AimTypography variant="textBold">
            {addToCartDialog?.item?.amountPerQuantityLabel}
          </AimTypography>
          <AimNumericInput
            value={addToCartDialog?.amountPerQuantity}
            editableInput
            onClickPlus={() =>
              setAddToCartDialog({
                ...addToCartDialog,
                amountPerQuantity: addToCartDialog.amountPerQuantity + 1,
              })
            }
            onClickMinus={() =>
              setAddToCartDialog({
                ...addToCartDialog,
                amountPerQuantity: addToCartDialog.amountPerQuantity - 1,
              })
            }
            onChange={(value) => {
              setAddToCartDialog({
                ...addToCartDialog,
                amountPerQuantity: value,
              });
            }}
            disabledPlus={isPlusDisabled}
            disabledMinus={addToCartDialog?.amountPerQuantity <= 1}
          />
        </div>
      )}
      {isNoteEnabled && (
        <AimTextField
          value={addToCartDialog?.note}
          label={`${noteLabel || 'Note'}${isNoteRequired ? '*' : ''}`}
          rows={5}
          multiline
          formControlStyle={{ width: 'calc(100vw - 10px)' }}
          onChange={(e) =>
            setAddToCartDialog({ ...addToCartDialog, note: e.target.value })
          }
        />
      )}
    </>
  );
};

export { StandItemAddToCartDialogContent };
