export default function (intl) {
  return {
    page: {
      title: intl.formatMessage({
        description: 'titlePage',
        defaultMessage: 'Edit Sponsor',
      }),
    },
    actions: {
      back: intl.formatMessage({
        description: 'back',
        defaultMessage: 'back',
      }),
      cancel: intl.formatMessage({
        description: 'cancel',
        defaultMessage: 'cancel',
      }),
      save: intl.formatMessage({
        description: 'save',
        defaultMessage: 'Save',
      }),
      delete: intl.formatMessage({
        description: 'deleteContact',
        defaultMessage: 'Delete',
      }),
    },
    labels: {
      sponsorName: intl.formatMessage({
        description: 'sponsor name',
        defaultMessage: 'Sponsor name',
      }),
      addContact: intl.formatMessage({
        description: 'add contact label',
        defaultMessage: 'Add contact',
      }),
      name: intl.formatMessage({
        description: 'name',
        defaultMessage: 'Name',
      }),
      surname: intl.formatMessage({
        description: 'lastname',
        defaultMessage: 'Lastname',
      }),
      phone: intl.formatMessage({
        description: 'phone',
        defaultMessage: 'Phone',
      }),
      email: intl.formatMessage({
        description: 'email',
        defaultMessage: 'Email',
      }),
      address: intl.formatMessage({
        description: 'address',
        defaultMessage: 'Address',
      }),
      city: intl.formatMessage({
        description: 'city',
        defaultMessage: 'City',
      }),
      country: intl.formatMessage({
        description: 'country',
        defaultMessage: 'Country',
      }),
      website: intl.formatMessage({
        description: 'website',
        defaultMessage: 'Website',
      }),
      facebook: intl.formatMessage({
        description: 'facebookProfile',
        defaultMessage: 'Facebook Profile',
      }),
      twitter: intl.formatMessage({
        description: 'twitterProfile',
        defaultMessage: 'Twitter Profile',
      }),
      linkedin: intl.formatMessage({
        description: 'linkedinProfile',
        defaultMessage: 'Linkedin Profile',
      }),
      instagram: intl.formatMessage({
        description: 'instagramProfile',
        defaultMessage: 'Instagram Profile',
      }),
      description: intl.formatMessage({
        description: 'description',
        defaultMessage: 'Description',
      }),
      profileImage: intl.formatMessage({
        description: 'profileImage',
        defaultMessage: 'Select profile image',
      }),
      profileBtnText: intl.formatMessage({
        description: 'upload label',
        defaultMessage: 'Upload',
      }),
      coverImage: intl.formatMessage({
        description: 'coverImage',
        defaultMessage: 'Select cover image',
      }),
      documents: intl.formatMessage({
        description: 'documents',
        defaultMessage: 'Documents',
      }),
      documentsBtnText: intl.formatMessage({
        description: 'upload new label',
        defaultMessage: 'Upload new',
      }),
      contacts: intl.formatMessage({
        description: 'contacts',
        defaultMessage: 'Contacts',
      }),
      info: intl.formatMessage({
        description: 'info',
        defaultMessage: 'Info',
      }),
      video: intl.formatMessage({
        description: 'video',
        defaultMessage: 'Video',
      }),
      title: intl.formatMessage({
        description: 'title',
        defaultMessage: 'Title',
      }),
      subtitle: intl.formatMessage({
        description: 'subtitle',
        defaultMessage: 'Subtitle',
      }),
      link: intl.formatMessage({
        description: 'link',
        defaultMessage: 'link',
      }),
      embeddedLink: intl.formatMessage({
        description: 'embedded link',
        defaultMessage: 'Embedded link',
      }),
      upload: intl.formatMessage({
        description: 'upload',
        defaultMessage: 'Upload',
      }),
      provider: {
        label: intl.formatMessage({
          description: 'provider',
          defaultMessage: 'Provider',
        }),
        placeholder: intl.formatMessage({
          description: 'select provider',
          defaultMessage: 'select provider',
        }),
      },
    },
  };
}
