export default function (intl) {
  return {
    page: {
      introTextPartOne: intl.formatMessage({
        description: 'Page intro text',
        defaultMessage: 'Choose up to',
      }),
      introTextPartTwo: intl.formatMessage({
        description: 'Page intro text',
        defaultMessage: 'categories you prefer to review:',
      }),
      textUnlimited: intl.formatMessage({
        description: 'Page intro text',
        defaultMessage: 'Choose the categories you prefer to review:',
      }),
    },
    actions: {
      forwardButton: intl.formatMessage({
        description: 'Forward button label',
        defaultMessage: 'forward',
      }),
    },
  };
}
