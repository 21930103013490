import { aws, appState, utilities, constants } from '@aim/common';
const { user } = aws;
import { getByCognitoIdEventId } from '../pages/shared/participationGqlHelper';

const getProductByClientIdServiceTypeServiceIdQuery = /* GraphQL */ `
  query getProductByClientIdServiceTypeServiceId(
    $clientId: String
    $serviceId: String
    $serviceType: String
  ) {
    productByClientIdServiceTypeServiceId(
      clientId: $clientId
      serviceTypeServiceId: {
        eq: { serviceId: $serviceId, serviceType: $serviceType }
      }
    ) {
      items {
        id
        productId
        clientId
        clientType
        serviceId
        serviceType
        createdAt
        payment {
          id
          serviceId
          serviceType
          paymentId
          paymentType
          paymentIdMonetaOnLine
          paymentIdIngenico
          paymentIdGpWebpay
          paymentError
          amount
          paymentStatus
        }
      }
    }
  }
`;

export const createUSer = async (email, cognitoUserId) =>
  await user.createNewUserInDb({
    cognitoUserId,
    email,
  });

const getProduct = (clientId, serviceId, serviceType, useLoader) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    aws.API.graphql({
      query: getProductByClientIdServiceTypeServiceIdQuery,
      variables: {
        clientId,
        serviceId,
        serviceType,
      },
    })
      .then(({ data }) =>
        resolve(
          data.productByClientIdServiceTypeServiceId?.items.filter((product) =>
            utilities.isValidPayment(product.payment)
          )?.[0]
        )
      )
      .catch((e) => {
        console.error('event-product', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const getMyEventParticipation = async (id, eventId, useLoader) => {
  const res = await getByCognitoIdEventId(id, eventId, useLoader);

  if (res) {
    const product = await getProduct(
      res.id,
      eventId,
      constants.Services.REGISTRATION.key,
      useLoader
    );
    const user = {
      id: id,
      email: res.email,
      givenName: res.givenName,
      familyName: res.familyName,
      businessName: res.businessName,
      participation: { ...res, hasTicket: !!product },
    };
    return user;
  }
};

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);
