export default (intl) => ({
  header: {
    // title: intl.formatMessage({
    //   description: 'Page title',
    //   defaultMessage: 'The AimTablePage test',
    // }),
    back: intl.formatMessage({
      description: 'Page back button label',
      defaultMessage: 'back',
    }),
    action: {
      closeGroup: {
        label: intl.formatMessage({
          description: 'Close group header button',
          defaultMessage: 'Close Group',
        }),
        tooltip: intl.formatMessage({
          description: 'Close group header button tooltip',
          defaultMessage: 'Close Group tooltip',
        }),
        disabledTooltip: intl.formatMessage({
          description: 'Close group header button tooltip',
          defaultMessage: 'Disabled lose Group tooltip',
        }),
      },
    },
  },
  stats: {
    currentMembers: intl.formatMessage({
      description: 'Stats curremt members',
      defaultMessage: 'Current members from stats',
    }),
  },
  table: {
    empty: intl.formatMessage({
      description: 'Table empty',
      defaultMessage: 'No data',
    }),
    columns: {
      givenName: intl.formatMessage({
        description: 'sponsor staff member givenName',
        defaultMessage: 'Name',
      }),
      familyName: intl.formatMessage({
        description: 'sponsor staff member familyName',
        defaultMessage: 'Surname',
      }),
      email: intl.formatMessage({
        description: 'sponsor staff member email',
        defaultMessage: 'Email',
      }),
      typology: intl.formatMessage({
        description: 'sponsor staff member typology',
        defaultMessage: 'Typology',
      }),
      profile: intl.formatMessage({
        description: 'sponsor staff member profile',
        defaultMessage: 'Profile',
      }),
      cluster: intl.formatMessage({
        description: 'sponsor staff member cluster',
        defaultMessage: 'Cluster',
      }),
      createdAt: intl.formatMessage({
        description: 'sponsor staff member createdAt',
        defaultMessage: 'Date added',
      }),
      isParticipant: intl.formatMessage({
        description: 'sponsor staff member isParticipant',
        defaultMessage: 'Pax',
      }),
      // isSpeaker: intl.formatMessage({
      //   description: 'sponsor staff member isSpeaker',
      //   defaultMessage: 'Speaker',
      // }),
      // isReviewer: intl.formatMessage({
      //   description: 'sponsor staff member isReviewer',
      //   defaultMessage: 'Reviewer',
      // }),
    },
    rowAction: {
      view: {
        tooltip: intl.formatMessage({
          description: 'View tooltip',
          defaultMessage: 'View',
        }),
        disabledTooltip: intl.formatMessage({
          description: 'Disabled view tooltip',
          defaultMessage: 'Disabled view',
        }),
      },
    },
    filters: {
      givenName: {
        label: intl.formatMessage({
          description: 'filter givenName',
          defaultMessage: 'Filter name',
        }),
        placeholder: intl.formatMessage({
          description: 'filter givenName placeholder',
          defaultMessage: 'Filter name placeholder',
        }),
      },
    },
  },
  dialog: {
    delete: {
      title: intl.formatMessage({
        description: 'Dialog delete title',
        defaultMessage: 'Kaboom',
      }),
      message: intl.formatMessage({
        description: 'Dialog delete message',
        defaultMessage: 'Wanna delete?',
      }),
      onAgreeText: intl.formatMessage({
        description: 'Dialog delete onAgreeText',
        defaultMessage: 'Sure',
      }),
      onDisagreeText: intl.formatMessage({
        description: 'Dialog delete onDisagreeText',
        defaultMessage: 'Hold on',
      }),
    },
    dialogTest: {
      title: intl.formatMessage({
        description: 'Dialog delete title',
        defaultMessage: 'Dialog delete title',
      }),
      message: intl.formatMessage({
        description: 'Dialog delete message',
        defaultMessage: 'Dialog delete message',
      }),
      onAgreeText: intl.formatMessage({
        description: 'Dialog delete onAgreeText',
        defaultMessage: 'Dialog delete onAgreeText',
      }),
      onDisagreeText: intl.formatMessage({
        description: 'Dialog delete onDisagreeText',
        defaultMessage: 'Dialog delete onDisagreeText',
      }),
    },
  },
  snackbar: {
    delete: {
      success: intl.formatMessage({
        description: 'Snackbar delete message',
        defaultMessage: 'Delete ok',
      }),
      error: intl.formatMessage({
        description: 'Snackbar delete message',
        defaultMessage: 'Some error',
      }),
    },
    snackTest: {
      info: intl.formatMessage({
        description: 'Snackbar delete message',
        defaultMessage: 'info',
      }),
      success: intl.formatMessage({
        description: 'Snackbar delete message',
        defaultMessage: 'Snackbar delete message',
      }),
      error: intl.formatMessage({
        description: 'Snackbar delete message',
        defaultMessage: 'Snackbar delete message',
      }),
    },
  },
});
