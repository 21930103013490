import React, { useState, useEffect } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { appState, aws } from '@aim/common';

import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import ArrowBack from '@material-ui/icons/ArrowBack';

import {
  MainContainer,
  InnerContainer,
} from '../shared/SponsorPagesContainers';

import { usePhysicalStandService } from '../shared/physicalStandGqlHelper';

import {
  AimIconAndTextButton,
  AimTypography,
  AimRichText,
  AimFileList,
  CustomIntl,
  AimSnackbar,
  AimSnackbarSeverity,
} from '@aim/components';
import PhysicalStandGrid from './PhysicalStandGrid';

import translation from './../shared/translation';
import { useSponsors } from '../shared/sponsorGqlHelper';

const PhysicalStand = () => {
  //Hooks
  const history = useHistory();
  const { eventId, sponsorId, service } = useParams();
  const { list } = usePhysicalStandService();
  const intl = CustomIntl(useIntl());
  const i18n = translation.physicalStand(intl);
  const { get: getSponsor, update: updateSponsor } = useSponsors();

  //State
  const [sponsorData, setSponsorData] = useState();
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [vatRate, setVatRate] = useState('');
  const [physicalStandService, setPhysicalStandService] = useState();

  const mandatoryDocs = physicalStandService?.documents.items?.filter(
    (d) => d.isMandatory
  );
  const hasReadMandatoryDocs =
    !mandatoryDocs?.length ||
    mandatoryDocs?.every(
      (md) => !!sponsorData?.requiredDocumentsIds.find((rd) => rd === md.id)
    );

  //Effects
  useEffect(() => {
    fetchService();
    fetchSponsor();
  }, []);

  // Functions
  const fetchService = async () => {
    const result = await list(eventId);
    setPhysicalStandService({
      ...result.physicalStandService,
      documents: {
        items: result.physicalStandService.documents.items.sort((a, b) =>
          a.name.localeCompare(b.name)
        ),
      },
    });
    setVatRate(result.physicalStandService.vatRate);
  };

  const fetchSponsor = async () => {
    const userAndParticipation = appState.user.getValue()?.userAndParticipation;
    const sponsor =
      userAndParticipation?.participation?.sponsor ||
      userAndParticipation?.participation?.sponsorStaff?.sponsor;

    const sponsorData = await getSponsor(sponsor.id);
    setSponsorData(sponsorData);
  };

  // Helpers
  const onDownload = async (fileData) => {
    const documentId = fileData.id;
    if (
      fileData.isMandatory &&
      !sponsorData?.requiredDocumentsIds.includes(documentId)
    ) {
      const nextRequiredDocumentsIds = [
        ...sponsorData?.requiredDocumentsIds,
        documentId,
      ];
      await updateSponsor({
        id: sponsorData.id,
        requiredDocumentsIds: nextRequiredDocumentsIds,
      });

      fetchSponsor();
    }
  };

  const downloadFile = async (item) => {
    const s3Folder = `events/${eventId}/sponsor/${service}/documents/`;
    const link = `${s3Folder}${item.id}${item.extension}`;

    var res = await aws.Storage.get(link);
    window.open(res);
    onDownload(item);
  };

  const renderSnackbar = () => (
    <AimSnackbar
      open={snackbar.isOpen}
      onClose={() => setSnackbar({ isOpen: false })}
      severity={snackbar.severity}
    >
      {snackbar.message}
    </AimSnackbar>
  );

  const handleNavigation = (rowId) => {
    if (!hasReadMandatoryDocs)
      return setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.snackbar.missingDownloadsError,
      });

    history.push(
      `/events/${eventId}/${sponsorId}/my-services/physical-stand/${rowId}`
    );
  };

  return (
    <MainContainer>
      <InnerContainer grow bottomSpacing>
        <div style={{ display: 'flex-root', marginTop: 20 }}>
          <Tooltip title={i18n.page.back.tooltip.label}>
            <AimIconAndTextButton
              variant="text"
              text={i18n.page.back.button.label}
              onClick={() =>
                history.push(`/events/${eventId}/${sponsorId}/my-services`)
              }
            >
              <ArrowBack />
            </AimIconAndTextButton>
          </Tooltip>
        </div>
        <Grid container>
          <Grid item xs={12}>
            <AimTypography variant={'h1'}>
              {i18n.page.title.label}
            </AimTypography>
          </Grid>
          <Grid item xs={12}>
            {physicalStandService?.description && (
              <AimRichText
                label={i18n.page.instructions.label}
                value={JSON.parse(physicalStandService.description)}
                readOnly
                editorStyleOverride={{
                  minHeight: 'auto',
                  maxHeight: 'fit-content',
                  overflow: 'hidden !important',
                }}
              />
            )}
          </Grid>
        </Grid>

        {physicalStandService?.documents?.items?.length ? (
          <Grid
            container
            spacing={2}
            style={{ marginTop: 10, marginLeft: -8, marginRight: -8 }}
          >
            <Grid
              item
              xs={12}
              style={{
                backgroundColor: 'white',
                maxWidth: '100%',
              }}
            >
              <Grid
                item
                container
                alignItems={'center'}
                style={{
                  backgroundColor: 'white',
                }}
              >
                <Grid item xs={12}>
                  <AimTypography variant={'h6'}>
                    {i18n.page.documents.label}
                  </AimTypography>
                  <AimTypography variant={'text'}>
                    {i18n.page.mandatoryDocuments.label}
                  </AimTypography>
                </Grid>

                <Grid item xs={10}>
                  <AimTypography variant={`h6`}>
                    {i18n.page.fileList.fileName.label}
                  </AimTypography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{
                    paddingLeft: 60,
                  }}
                >
                  <AimTypography variant={'h6'}>
                    {i18n.page.fileList.fileDimension.label}
                  </AimTypography>
                </Grid>
              </Grid>
              <AimFileList
                files={physicalStandService.documents.items}
                onDownload={downloadFile}
                marginBottom={0}
                marginTop={0}
                marginLeft={8}
                marginRight={8}
                padding={0}
              />
            </Grid>
          </Grid>
        ) : null}

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PhysicalStandGrid
              floors={physicalStandService?.floors.items}
              vatRate={vatRate}
              handleNavigation={handleNavigation}
            />
          </Grid>
        </Grid>
      </InnerContainer>
      {renderSnackbar()}
    </MainContainer>
  );
};

export default PhysicalStand;
