import React, { useState, useEffect, useRef } from 'react';
import { MainContainer, Container } from '../serviceList/BreakoutRoomList';
import { useIntl } from 'react-intl';
import useI18n from '../shared/i18n';
import useMailI18n from '../../sponsorServiceConfigurationWall/shared/i18n';

import { format } from 'date-fns';

import { useParams, useHistory } from 'react-router-dom';

import {
  theme,
  styled,
  CustomIntl,
  AimTypography,
  AimIconAndTextButton,
  useLoaderHelper,
  AimSnackbarSeverity,
  useFormComponents,
  AimSnackbar,
} from '@aim/components';

import { useBreakoutRoomAttending } from '../../shared/breakoutRoomAttendingGqlHelper';
import { useBreakoutRoomInvitationUnregistered } from '../../shared/breakoutRoomInvitationUnregisteredGqlHelper';
import { useBreakoutRoomUtils } from '../../../hooks/useBreakoutRoomUtils';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import ArrowBack from '@material-ui/icons/ArrowBack';
import TitleAndBackButton from '../../shared/TitleAndBackButton';

const defaultValues = {
  note: '',
};
const formControlStyle = { width: 'calc(100% - 15px)', margin: 0 };

const CustomContainer = styled('div')({
  width: 'calc(100% - 15px)',
  marginBottom: 15,
  display: 'flex',
  padding: 15,
  background: theme.colors.greyScale.backgroundGrey,
});

const BreakoutRoomSendDelete = () => {
  // Hooks
  const intl = CustomIntl(useIntl());
  const i18n = useI18n.breakoutRoomSendDelete(intl);
  const { eventId, sponsorId, breakoutRoomId } = useParams();
  const { showLoader, hideLoader } = useLoaderHelper();
  const breakoutRoomAttendingHelper = useBreakoutRoomAttending(true);
  const breakoutRoomInvitationUnregisteredHelper = useBreakoutRoomInvitationUnregistered(
    true
  );
  const {
    getHours,
    emailTypes,
    generateBreakoutRoomEmail,
    states,
  } = useBreakoutRoomUtils();
  const history = useHistory();
  const { renderRichTextInput, renderButtons, formHelper } = useFormComponents({
    formControlStyle: formControlStyle,
    defaultValues: defaultValues,
    validationMode: 'all',
    labels: i18n.form,
  });
  // Ref
  const submitButton = useRef();

  //States
  const [participation, setParticipation] = useState([]);
  const [forceDelete, setForceDelete] = useState(false);
  const [breakoutRoom, setBreakoutRoom] = useState();
  const [slot, setSlot] = useState();
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [sendedNotification, setSendedNotification] = useState(false);

  //Effects
  useEffect(() => {
    if (!breakoutRoom) fetchParticipations();
  }, []);

  //Functions
  const fetchParticipations = () => {
    if (
      history.location?.state?.breakoutRoomSlot &&
      history.location?.state?.breakoutRoom
    ) {
      showLoader();
      setBreakoutRoom(history.location.state.breakoutRoom);
      setSlot(history.location.state.breakoutRoomSlot);
      const isOneInvitation = history.location?.state?.invitation;
      const _forceDelete = history.location?.state?.forceDelete;
      setForceDelete(_forceDelete);
      setParticipation(isOneInvitation);
      history.replace(history.location.pathname, null);
      hideLoader();
    } else {
      history.push(
        `/events/${eventId}/${sponsorId}/services-configuration/breakoutrooms/${breakoutRoomId}`
      );
    }
  };

  const navigateBack = () => {
    history.push({
      pathname: `/events/${eventId}/${sponsorId}/services-configuration/breakoutrooms/${breakoutRoomId}`,
      state: {
        breakoutRoom: breakoutRoom,
        breakoutRoomSlot: slot,
        alreadyParsedData: true,
      },
    });
  };

  const onSubmit = async (data) => {
    /**
     * Le note me li salvo in costante, non vanno salvate a Db ma inviate per email
     */
    const note = data?.note;
    /** Una volta handled la sendEmail, elimina la participation */
    try {
      const emails = [];
      const promises = [];
      if (participation) {
        if (participation.id) {
          if (forceDelete) {
            promises.push(breakoutRoomAttendingHelper.remove(participation.id));
          } else {
            emails.push(participation.participation.email);
            const input = {
              id: participation.id,
              state: states.CANCELED,
            };
            promises.push(breakoutRoomAttendingHelper.update(input));
          }
        } else {
          const unregistered = slot.emails.find(
            (em) => em.email === participation.participation.email
          );
          if (forceDelete) {
            promises.push(
              breakoutRoomInvitationUnregisteredHelper.remove(unregistered.id)
            );
          } else {
            emails.push(participation.participation.email);
            const input = {
              id: unregistered.id,
              state: states.CANCELED,
            };
            promises.push(
              breakoutRoomInvitationUnregisteredHelper.update(input)
            );
          }
        }
      } else {
        slot.invitations.map((i) => {
          if (i.state === states.ACCEPTED || i.state === states.PENDING)
            emails.push(i.participation.email);
          if (i.id) {
            promises.push(breakoutRoomAttendingHelper.remove(i.id));
          } else {
            const unregistered = slot.emails.find(
              (em) => em.email === i.participation.email
            );
            promises.push(
              breakoutRoomInvitationUnregisteredHelper.remove(unregistered.id)
            );
          }
        });
      }
      emails.map((email) => {
        const emailData = {
          breakoutRoom: {
            date: slot.start,
            sponsor: breakoutRoom.sponsor,
          },
          slot: slot,
          rawMessage: note,
          eventId: eventId,
          i18n: useMailI18n.breakoutRoomMail(intl),
        };
        promises.push(
          generateBreakoutRoomEmail(emailTypes.CANCELLATION, {
            ...emailData,
            email: email,
          })
        );
      });

      await Promise.all(promises);

      setSendedNotification(true);
    } catch (e) {
      console.error(e);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.snackbar.deleteFail,
      });
    }
    return;
  };

  //Renders

  const renderDeleteForm = () => {
    return (
      <div>
        <AimTypography variant="h3">{`${format(
          new Date(breakoutRoom.date),
          'dd/MM/yyyy'
        )} - [${getHours(slot.start)} - ${getHours(slot.end)}]`}</AimTypography>
        <Divider style={{ width: 'calc(100% - 15px)' }} />
        <AimTypography variant="h6" margin={0} style={{ marginTop: 15 }}>
          {participation
            ? i18n.form.nameParticipantSingular
            : i18n.form.nameParticipantPlural}
        </AimTypography>
        <CustomContainer>
          {participation?.participation?.userTxt ||
            `${slot.invitations.map((i) => `${i.participation.userTxt} `)}`}
        </CustomContainer>
        <form onSubmit={formHelper.handleSubmit(onSubmit)}>
          <Grid item xs={12}>
            {renderRichTextInput({
              key: 'note',
            })}
          </Grid>
          {renderButtons({
            buttonLabels: i18n.form.buttons,
            onSaveClick: () => {
              submitButton.current.click();
            },
            onCancelClick: () => {
              formHelper.reset();
              navigateBack();
            },
          })}
          <input
            style={{ visibility: 'hidden' }}
            ref={submitButton}
            type="submit"
          />
        </form>
      </div>
    );
  };

  const renderConfirm = () => {
    if (!slot || !breakoutRoom) return;
    return (
      <div style={{ padding: 30 }}>
        <AimTypography variant="h1Regular">
          {!forceDelete
            ? i18n.confirmPage.confirmBan
            : i18n.confirmPage.confirm}
          :
        </AimTypography>
        <AimTypography variant="h1Regular">
          {participation?.participation?.userTxt ||
            `${slot.invitations.map((i) => `${i.participation.userTxt} `)}`}
        </AimTypography>
        <AimIconAndTextButton
          variant="yellow"
          text={i18n.page.backButton.label}
          onClick={() =>
            history.push(
              `/events/${eventId}/${sponsorId}/services-configuration/breakoutrooms/${breakoutRoomId}`
            )
          }
          style={{ marginTop: 20 }}
        >
          <ArrowBack />
        </AimIconAndTextButton>
      </div>
    );
  };

  return (
    <MainContainer>
      {!sendedNotification && (
        <TitleAndBackButton
          backOnClickFunction={() => navigateBack()}
          backOnHoverText={i18n.page.backButton.tooltip}
          title={
            participation
              ? !forceDelete
                ? i18n.page.titleSingularBan
                : i18n.page.titleSingular
              : i18n.page.titlePlural
          }
          subtitle={
            participation
              ? !forceDelete
                ? i18n.page.subTitleSingularBan
                : i18n.page.subTitleSingular
              : i18n.page.subTitlePlural
          }
        />
      )}
      <Container style={{ marginTop: !sendedNotification ? 0 : 20 }}>
        {breakoutRoom && slot && !sendedNotification
          ? renderDeleteForm()
          : renderConfirm()}
      </Container>
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </MainContainer>
  );
};

export default BreakoutRoomSendDelete;
