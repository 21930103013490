import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import Tooltip from '@material-ui/core/Tooltip';
import ArrowBack from '@material-ui/icons/ArrowBack';

import { aws, appState, constants } from '@aim/common';
import {
  AimIconAndTextButton,
  theme,
  AimSnackbar,
  AimSnackbarSeverity,
  CustomIntl,
} from '@aim/components';

import { StylesManager, Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import 'survey-core/defaultV2.css';

const getSurveyQuery = /* GraphQL */ `
  query GetSurvey($surveyId: ID!) {
    getSurvey(id: $surveyId) {
      id
      title
      status
      sentAt
      model
    }
  }
`;

const createResponseAndUpdateInvitation = /* GraphQL */ `
  mutation CreateResponseAndUpdateInvitation(
    $surveyInvitationId: ID!
    $surveyId: ID!
    $result: AWSJSON
  ) {
    updateSurveyInvitation(
      input: { isCompleted: "true", id: $surveyInvitationId }
    ) {
      id
    }
    createSurveyResponse(
      input: { surveyResponseSurveyId: $surveyId, result: $result }
    ) {
      id
    }
  }
`;

const stylesByEventConfiguration = () => {
  const sdBody = document.querySelector('body');
  sdBody?.style?.setProperty(
    '--primary',
    appState.eventConfiguration.getValue().primaryColor
  );

  const buildButtonStyle = (variant, color, borderRadius) => {
    switch (variant) {
      case constants.TemplateButtonVariants.OUTLINED:
        return {
          'border-radius': borderRadius,
          background: 'transparent',
          border: '2px solid ' + color,
          color: theme.colors.primary.black,
        };
      case constants.TemplateButtonVariants.CONTAINED:
        return {
          'border-radius': borderRadius,
          background: color,
          color: theme.colors.primary.white,
        };
      case constants.TemplateButtonVariants.TEXT:
        return {
          background: 'transparent !important',
          color: color,
          border: 'none !important',
        };
    }
  };

  const primaryBuildButtonStyle = () =>
    buildButtonStyle(
      appState.eventConfiguration.getValue().primaryButtonVariant,
      appState.eventConfiguration.getValue().primaryColor,
      appState.eventConfiguration.getValue().primaryButtonBorderRadius
    );
  const secondaryBuildButtonStyle = () =>
    buildButtonStyle(
      appState.eventConfiguration.getValue().secondaryButtonVariant,
      appState.eventConfiguration.getValue().secondaryColor,
      appState.eventConfiguration.getValue().secondaryButtonBorderRadius
    );

  // buttons
  const svButton = document.querySelector('.sv-action input');
  const cssStyleObject = primaryBuildButtonStyle();

  const inlineStyleString = Object.keys(cssStyleObject).reduce(
    (serialized, key) => {
      serialized += `${key}: ${cssStyleObject[key]};`;
      return serialized;
    },
    ''
  );
  if (svButton) svButton.setAttribute('style', inlineStyleString);
};

const SurveyPage = () => {
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [survey, setSurvey] = useState({});
  const [surveyInvitation, setSurveyInvitation] = useState('');
  const [participation, setParticipation] = useState('');
  const [model, setModel] = useState();
  const history = useHistory();
  const intl = CustomIntl(useIntl());
  const { eventId, surveyId } = useParams();

  StylesManager.applyTheme('defaultV2');
  stylesByEventConfiguration();

  const getParticipationAndSurvey = async () => {
    appState.isLoader.next(true);

    try {
      const nextParticipation = appState.user.getValue()?.userAndParticipation
        ?.participation;
      const { data } = await aws.API.graphql({
        query: getSurveyQuery,
        variables: { surveyId: surveyId },
      });

      setParticipation(nextParticipation);
      const currentSurveyInvitation = nextParticipation.surveyInvitations.items.find(
        (invitation) =>
          invitation?.survey?.id === surveyId &&
          invitation?.isCompleted === 'false'
      );
      if (currentSurveyInvitation && data.getSurvey.status === 'published') {
        setSurveyInvitation(currentSurveyInvitation);
        setSurvey(data.getSurvey);
        setModel(new Model(data.getSurvey.model));
      } else {
        setSnackbar({
          isOpen: true,
          severity: AimSnackbarSeverity.error,
          message: 'Survey not available',
        });
      }

      appState.isLoader.next(false);
    } catch (err) {
      console.error(err);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: 'Something went wrong',
      });
      appState.isLoader.next(false);
    }
  };

  useEffect(() => {
    getParticipationAndSurvey();
  }, []);

  const onComplete = async (survey) => {
    try {
      await aws.API.graphql({
        query: createResponseAndUpdateInvitation,
        variables: {
          surveyInvitationId: surveyInvitation.id,
          surveyId: surveyId,
          result: JSON.stringify(survey.data),
        },
      });
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.success,
        message: 'Survey completed correctly',
      });
    } catch (err) {
      console.error('💥 ERROR: ', err);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: 'Not possible to save survey, please try later',
      });
    }
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          flex: 1,
          overflowY: 'auto',
          width: 1280,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',

            // paddingLeft: 200,
            // paddingRight: 200,
          }}
        >
          <Tooltip
            title={intl.formatMessage({
              description: 'back to survey list',
              defaultMessage: 'Back to survey list',
            })}
          >
            <AimIconAndTextButton
              isUpperCase
              variant="none"
              text={intl.formatMessage({
                description: 'surveys label',
                defaultMessage: 'surveys',
              })}
              style={{
                padding: 0,
                marginLeft: 20,
              }}
              onClick={() =>
                history.replace(
                  `/events/${eventId}/user/${participation.id}/surveys`
                )
              }
            >
              <ArrowBack />
            </AimIconAndTextButton>
          </Tooltip>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flex: 1,
            marginLeft: 20,
          }}
        >
          <div style={{ width: '100%' }}>
            {model ? <Survey model={model} onComplete={onComplete} /> : <></>}
          </div>
        </div>
      </div>
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </>
  );
};

export default SurveyPage;
