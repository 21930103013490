export const translation = {
  aimTablePage: (intl) => ({
    header: {
      backButton: intl.formatMessage({
        description: 'Back button label',
        defaultMessage: 'Group list',
      }),
      action: {
        goToFeeTable: {
          label: intl.formatMessage({
            description: 'go to fee table button',
            defaultMessage: 'GO TO FEE TABLE',
          }),
          tooltip: intl.formatMessage({
            description: 'go to fee table button',
            defaultMessage: 'go to fee table',
          }),
        },
        goToWelcomePage: {
          label: intl.formatMessage({
            description: 'go to welcome page button',
            defaultMessage: 'GO TO WELCOME PAGE',
          }),
          tooltip: intl.formatMessage({
            description: 'go to welcome page button',
            defaultMessage: 'go to welcome page',
          }),
        },
        import: {
          tooltip: intl.formatMessage({
            description: 'tooltip import',
            defaultMessage: 'Import',
          }),
        },
        add: {
          tooltip: intl.formatMessage({
            description: 'label add member',
            defaultMessage: 'Add',
          }),
        },
        payAndClose: {
          label: intl.formatMessage({
            description: 'label pay and close group',
            defaultMessage: 'PAY AND CLOSE GROUP',
          }),
        },
        payApprovedChanges: {
          label: intl.formatMessage({
            description: 'label pay approved changes',
            defaultMessage: 'PAY APPROVED CHANGES',
          }),
        },
        closePrepaidGroup: {
          label: intl.formatMessage({
            description: 'label close prepaid group',
            defaultMessage: 'CLOSE PREPAID GROUP',
          }),
        },
      },
    },
    bigStats: {},
    stats: {
      totalCost: intl.formatMessage({
        description: 'total cost',
        defaultMessage: 'Total cost',
      }),
      amountDue: intl.formatMessage({
        description: 'amount due',
        defaultMessage: 'Amount due',
      }),
      budgetAvailable: intl.formatMessage({
        description: 'budget available',
        defaultMessage: 'Budget Available',
      }),
      nRecord: intl.formatMessage({
        description: 'number of records',
        defaultMessage: 'N. records',
      }),
      status: intl.formatMessage({
        description: 'status',
        defaultMessage: 'Status',
      }),
      minMembers: intl.formatMessage({
        description: 'minimum number of members',
        defaultMessage: 'Minimum number to close the group',
      }),
      closingDate: intl.formatMessage({
        description: 'closing date',
        defaultMessage: 'Closing date',
      }),
      postCloseChangeDeadline: intl.formatMessage({
        description: 'post closing paid changes until',
        defaultMessage: 'Post-closing paid changes until',
      }),
      modificationRequests: intl.formatMessage({
        description: 'number of modification requests',
        defaultMessage: 'Modification requests',
      }),
      requestsAccepted: intl.formatMessage({
        description: 'requests accepted',
        defaultMessage: 'Requests accepted',
      }),
    },
    table: {
      empty: intl.formatMessage({
        description: 'Table empty',
        defaultMessage: 'No data',
      }),
      columns: {
        username: intl.formatMessage({
          description: 'username',
          defaultMessage: 'ID',
        }),
        givenName: intl.formatMessage({
          description: 'given name',
          defaultMessage: 'Name',
        }),
        familyName: intl.formatMessage({
          description: 'family name',
          defaultMessage: 'Surname',
        }),
        email: intl.formatMessage({
          description: 'member email',
          defaultMessage: 'Email',
        }),
        profile: intl.formatMessage({
          description: 'member profile',
          defaultMessage: 'Profile',
        }),
        cluster: intl.formatMessage({
          description: 'member cluster',
          defaultMessage: 'Cluster',
        }),
        createdAt: intl.formatMessage({
          description: 'member is created at',
          defaultMessage: 'Date added',
        }),
        isPax: intl.formatMessage({
          description: 'member is pax',
          defaultMessage: 'Pax',
        }),
        isSpeaker: intl.formatMessage({
          description: 'member is speaker',
          defaultMessage: 'Speaker',
        }),
        isReviewer: intl.formatMessage({
          description: 'member is reviewer',
          defaultMessage: 'Reviewer',
        }),
        modification: intl.formatMessage({
          description: 'modification',
          defaultMessage: 'Modification',
        }),
      },
      rowAction: {
        edit: {
          tooltip: intl.formatMessage({
            description: 'edit tooltip',
            defaultMessage: 'Edit',
          }),
        },
        delete: {
          tooltip: intl.formatMessage({
            description: 'delete tooltip',
            defaultMessage: 'Delete',
          }),
        },
      },
    },
    dialog: {
      delete: {
        title: intl.formatMessage({
          description: 'Delete Participants title',
          defaultMessage: 'Delete Participants',
        }),
        message: intl.formatMessage({
          description: 'Dialog delete message',
          defaultMessage: 'Are you sure?',
        }),
        agreeText: intl.formatMessage({
          description: 'Dialog delete onAgreeText',
          defaultMessage: 'Delete',
        }),
        disagreeText: intl.formatMessage({
          description: 'Dialog delete onDisagreeText',
          defaultMessage: 'Cancel',
        }),
      },
    },
    modal: {
      agencyPrepaidBudget: {
        residualBudget: intl.formatMessage({
          description: 'residual budget',
          defaultMessage: 'Residual budget',
        }),
        availableBudgetText1: intl.formatMessage({
          description: 'you have',
          defaultMessage: 'You have',
        }),
        availableBudgetText2: intl.formatMessage({
          description:
            'available from the prepaid budget. Decide whether to use it for the current transaction:',
          defaultMessage:
            'available from the prepaid budget. Decide whether to use it for the current transaction:',
        }),
        useRemainingBudget: intl.formatMessage({
          description: 'use the remaining budget',
          defaultMessage: 'Use the remaining budget',
        }),
        buttons: {
          close: intl.formatMessage({
            description: 'close button dialog',
            defaultMessage: 'Close',
          }),
          buy: intl.formatMessage({
            description: 'buy',
            defaultMessage: 'buy',
          }),
        },
      },
    },
    snackbar: {
      delete: {
        success: intl.formatMessage({
          description: 'Snackbar delete message',
          defaultMessage: 'Delete ok',
        }),
        error: intl.formatMessage({
          description: 'Snackbar delete message',
          defaultMessage: 'Some error',
        }),
      },
      closingGroupSuccess: intl.formatMessage({
        description: 'closing group success',
        defaultMessage: 'Closing group success',
      }),
      payingPenaltiesSuccess: intl.formatMessage({
        description: 'paying penalties success',
        defaultMessage: 'Paying penalties success',
      }),
    },
    alert: {
      notEnoughBudget: intl.formatMessage({
        description:
          'not Enough Budget: remove participant or buy prepaid budget',
        defaultMessage:
          'Not Enough Budget: remove participant or buy prepaid budget',
      }),
    },
    page: {
      statuses: {
        open: intl.formatMessage({
          description: 'open status',
          defaultMessage: 'Open',
        }),
        closed: intl.formatMessage({
          description: 'closes status',
          defaultMessage: 'Closed',
        }),
      },
    },
  }),
};
