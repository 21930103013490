import { aws } from '@aim/common';
import { useLoaderHelper } from './loaderHelper';
const { API } = aws;

const UserSettingsSchema = `
  id
  isPublic
  showMyAgenda
  showBiography
`;

const createUserSettingsQuery = /* GraphQL */ `
  mutation CreateUserSettings(
    $input: CreateUserSettingsInput!
    $condition: ModelUserSettingsConditionInput
  ) {
    createUserSettings(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateUserSettingsQuery = /* GraphQL */ `
  mutation UpdateUserSettings(
    $input: UpdateUserSettingsInput!
    $condition: ModelUserSettingsConditionInput
  ) {
    updateUserSettings(input: $input, condition: $condition) {
      id
    }
  }
`;

const deleteUserSettingsQuery = /* GraphQL */ `
  mutation DeleteUserSettings(
    $input: DeleteUserSettingsInput!
    $condition: ModelUserSettingsConditionInput
  ) {
    deleteUserSettings(input: $input, condition: $condition) {
      id
    }
  }
`;

const getUserSettingsQuery = /* GraphQL */ `
  query getUserSettings($id: ID!) {
    getParticipation( id: $id ) {
      userSettings {
        ${UserSettingsSchema}
      }
    }
  }
`;

/**handle participation update */
const updateParticipationQuery = /* GraphQL */ `
  mutation UpdateParticipation(
    $input: UpdateParticipationInput!
    $condition: ModelParticipationConditionInput
  ) {
    updateParticipation(input: $input, condition: $condition) {
      id
    }
  }
`;

export const useUserSettingsHelper = (useLoader) => {
  const { showLoader, hideLoader } = useLoaderHelper();
  const get = (participationId) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();
      API.graphql({
        query: getUserSettingsQuery,
        variables: { id: participationId },
      })
        .then((response) => {
          resolve(response.data.getParticipation.userSettings);
        })
        .catch((e) => {
          console.error('get-UserSettingsHelper-error', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const create = (input) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: createUserSettingsQuery,
        variables: { input },
      })
        .then((response) => {
          //update participation
          aws.API.graphql({
            query: updateParticipationQuery,
            variables: {
              input: {
                id: input.participationId,
                participationUserSettingsId:
                  response.data.createUserSettings.id,
              },
            },
          }).then((participationResponse) => {
            resolve(response.data.createUserSettings);
          });
        })
        .catch((e) => {
          console.error('create-UserSettingsHelper-error', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const remove = (id) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: deleteUserSettingsQuery,
        variables: { input: { id: id } },
      })
        .then((response) => resolve(response.data.deleteUserSettings))
        .catch((e) => {
          console.error('delete-UserSettingsHelper-error', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const update = (input) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: updateUserSettingsQuery,
        variables: { input },
      })
        .then((response) => resolve(response.data.updateUserSettings))
        .catch((e) => {
          console.error('update-UserSettingsHelper-error', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  return {
    create,
    remove,
    update,
    get,
  };
};
