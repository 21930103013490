import React from 'react';

import { AimFilterLight } from '@aim/components';

// Initial State for filter
const initialState = {
  filterName: '',
};

// Function onFilter
const stateFiltersFns = {
  filterName: (item, value) => {
    return item.toLowerCase().includes(value.toLowerCase());
  },
};

function SpeakersFilter({ onFilterResult, rows, i18n }) {
  const model = [
    {
      name: 'filterName',
      label: i18n.name.label,
      type: 'AimTextField',
      customComponentWidth: 'calc(100% - 150px)',
    },
  ];

  return (
    <AimFilterLight
      rows={rows}
      initialState={initialState}
      filtersFns={stateFiltersFns}
      model={model}
      onFilterResult={onFilterResult}
      noPadding
    />
  );
}

export default SpeakersFilter;
