import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import { AimTypography } from '@aim/components';
import { fileHelper } from '@aim/common';

const OtherSponsItemsDialogContent = ({ item, i18n }) => {
  const { getS3Link } = fileHelper;
  const { eventId } = useParams();

  const [itemImageSrc, setItemImageSrc] = useState();

  useEffect(() => {
    const getImageLink = async () => {
      const dirPath = `events/${eventId}/sponsor-services/other-sponsorizations/items/${item.id}/image`;
      const link = await getS3Link(dirPath, item.image);
      setItemImageSrc(link);
    };
    getImageLink();
  }, []);

  if (!item) return null;

  return (
    <>
      <Grid container style={{ minWidth: 300 }} alignItems="center">
        <Grid item xs={12} sm={7} style={{ maxWidth: 400 }}>
          {itemImageSrc && (
            <img
              src={itemImageSrc}
              style={{ width: 'calc(100% - 20px)', height: 'auto' }}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={itemImageSrc ? 5 : 12}>
          <AimTypography variant="textBold">
            {i18n.dialog.fields.subCategory.label}
          </AimTypography>
          <AimTypography variant="text">{item.subCategory}</AimTypography>

          {/* <AimTypography variant="textBold">
            {i18n.dialog.fields.type.label}
          </AimTypography> */}
          {/* <AimTypography variant="text">{item.type}</AimTypography> */}

          <AimTypography variant="textBold">
            {i18n.dialog.fields.netPrice.label}
          </AimTypography>
          <AimTypography variant="text">{item.netPrice}</AimTypography>

          <AimTypography variant="textBold">
            {i18n.dialog.fields.vatRate.label}
          </AimTypography>
          <AimTypography variant="text">{item.vatRate} %</AimTypography>

          <AimTypography variant="textBold">
            {i18n.dialog.fields.totalAmount.label}
          </AimTypography>
          <AimTypography variant="text">{item.totalAmount}</AimTypography>

          <AimTypography variant="textBold">
            {i18n.dialog.fields.note.label}
          </AimTypography>
          <AimTypography variant="text">{item?.note || '-'}</AimTypography>
        </Grid>
      </Grid>
    </>
  );
};

export { OtherSponsItemsDialogContent };
