export default {
  myTickets: (intl) => ({
    back: intl.formatMessage({
      description: 'my tickets page Back button label',
      defaultMessage: 'back',
    }),
    title: intl.formatMessage({
      description: 'table column title',
      defaultMessage: 'Title',
    }),
    submissionDate: intl.formatMessage({
      description: 'table column submission date',
      defaultMessage: 'Submission Date',
    }),
    status: intl.formatMessage({
      description: 'table column status',
      defaultMessage: 'Status',
    }),
    viewDetail: intl.formatMessage({
      description: 'grant view detail',
      defaultMessage: 'Detail',
    }),
    errors: {
      error1: intl.formatMessage({
        description: 'order data request processing error',
        defaultMessage: 'Order data request processing error',
      }),
      error2: intl.formatMessage({
        description: 'order data processing error',
        defaultMessage: 'Order data processing error',
      }),
      error3: intl.formatMessage({
        description: 'order payment initialization error',
        defaultMessage: 'Order payment initialization error',
      }),
      error4: intl.formatMessage({
        description: 'order payment error',
        defaultMessage: 'Order payment error',
      }),
      paymentExist: intl.formatMessage({
        description: 'payment exist',
        defaultMessage: 'Payment exist',
      }),
    },
  }),
};
