export default function (intl) {
  return {
    page: {
      title: intl.formatMessage({
        description: 'abstracts list',
        defaultMessage: 'Abstracts list',
      }),
      categoriesCardTitle: intl.formatMessage({
        description: 'Categories Card title',
        defaultMessage: 'Categories',
      }),
      deadlineReview: intl.formatMessage({
        description: 'deadline Review card title',
        defaultMessage: 'Deadline Review',
      }),
      nAbstractToBeEvaluated: intl.formatMessage({
        description: 'n abstract to be evaluated card title',
        defaultMessage: 'N° Abstract to be Evaluated',
      }),
      nAbstractEvaluted: intl.formatMessage({
        description: 'n abstract evaluated card title',
        defaultMessage: 'N° Abstract Evaluated',
      }),
      abstractDeclined: intl.formatMessage({
        description: 'abstract declined card title',
        defaultMessage: 'N° Abstract "Declined"',
      }),
      showAllCategories: intl.formatMessage({
        description: 'Show all categories button label',
        defaultMessage: 'show all',
      }),
    },
    toolbar: {
      buttons: {
        goToWall: intl.formatMessage({
          description: 'go to the wall',
          defaultMessage: 'Go to the wall',
        }),
      },
    },
    card: {
      presentationButton: intl.formatMessage({
        description: 'setup presentation',
        defaultMessage: 'Setup your presentation',
      }),
    },
    dialog: {
      text: intl.formatMessage({
        description: 'setup presentation',
        defaultMessage: 'Are you sure you want to delete the astract document?',
      }),
      title: intl.formatMessage({
        description: 'setup presentation',
        defaultMessage: 'Delete abstract document',
      }),
    },
    subtitles: {
      subtitleData: intl.formatMessage({
        description: 'setup presentation',
        defaultMessage: 'You can send your abstract untill ',
      }),
      subtitleMax1: intl.formatMessage({
        description: 'setup presentation',
        defaultMessage: 'You can send at least ',
      }),
      subtitleMax2: intl.formatMessage({
        description: 'setup presentation',
        defaultMessage: ' abstract',
      }),
    },
    table: {
      viewRowButton: intl.formatMessage({
        description: 'detail btn abstract view',
        defaultMessage: 'View',
      }),
      reviewStatus: {
        toBeprocessed: intl.formatMessage({
          description: 'processed status',
          defaultMessage: 'to be processed',
        }),
      },
      columnsTitles: {
        title: intl.formatMessage({
          description: 'title',
          defaultMessage: 'Title ',
        }),
        presentationType: intl.formatMessage({
          description: 'presentation type',
          defaultMessage: 'Presentation Type ',
        }),
        category: intl.formatMessage({
          description: 'category',
          defaultMessage: 'Category',
        }),
        subCategory: intl.formatMessage({
          description: 'subCategory',
          defaultMessage: 'Subcategory',
        }),
        // preferred: intl.formatMessage({
        //   description: 'preferred',
        //   defaultMessage: 'Preferred',
        // }),
        status: intl.formatMessage({
          description: 'status',
          defaultMessage: 'Status',
        }),
        finalScore: intl.formatMessage({
          description: 'score',
          defaultMessage: 'Score',
        }),
      },
    },
    checkbox: intl.formatMessage({
      description: 'checkbox label',
      defaultMessage: 'Only abstract to evaluate',
    }),
  };
}
