import React, { ElementType, MouseEvent } from 'react';

import {
  ChannelEntity,
  ChannelList,
  getNameInitials,
  getPredefinedColor,
  UserEntity,
  ChannelEntityWithMembership,
} from '@pubnub/react-chat-components';
import Badge from '@material-ui/core/Badge';
import { styled } from '@aim/components';
import { isPresentMember } from './pubnub-utils';

const StyledBadge = styled(Badge)(() => ({
  width: 30,
  left: -10,
  '& .MuiBadge-badge': {
    color: 'white',
    fontWeight: 'bold',
  },
})) as ElementType;

interface AimChannelListProps {
  channels: ChannelEntityWithMembership[];
  users: UserEntity[];
  currentUser: UserEntity;
  currentChannel: Pick<ChannelEntity, 'id' | 'name' | 'description'>;
  switchChannel: (
    newChannel: Pick<ChannelEntity, 'id' | 'name' | 'description'>,
    oldChannel: Pick<ChannelEntity, 'id' | 'name' | 'description'>
  ) => void;
  leaveChannel?: (channel: ChannelEntity, event: MouseEvent) => void;
  onlineUUIDs?: string[];
  unreadMessages?: any;
  extraActionsRenderer?: Function;
}

const AimChannelList = ({
  channels,
  users,
  currentUser,
  switchChannel,
  currentChannel,
  leaveChannel,
  onlineUUIDs,
  unreadMessages,
  extraActionsRenderer,
}: AimChannelListProps) => {
  console.log('channels', channels);
  return (
    <ChannelList
      channels={channels}
      channelRenderer={(channel: ChannelEntityWithMembership) => {
        const uuids: string[] = channel.id.startsWith('direct.')
          ? channel.id.substring(7).split('@')
          : [];
        const otherUserUuid: string | undefined = uuids.find(
          (uuid: string) => uuid !== currentUser.id
        );
        const otherUser: any = otherUserUuid
          ? users.find((u: UserEntity) => u.id === otherUserUuid)
          : null;

        const profileUrl: any = otherUser
          ? otherUser.profileUrl
          : channel?.custom?.profileUrl;

        return (
          <div
            key={channel.id}
            className={`pn-channel ${
              channel.id === currentChannel.id ? 'pn-channel--active' : ''
            }`}
            onClick={() => {
              switchChannel(channel, currentChannel);
            }}
          >
            {/* {channel.custom?.profileUrl ? (
          <img
            className="pn-channel__thumb"
            src={channel.custom?.profileUrl as string}
            alt="Channel thumb"
          />
        ) : (
          getNameInitials(user.name || user.id)
        )} */}
            <div
              className="pn-member__avatar"
              style={{
                backgroundColor: getPredefinedColor(currentUser.id),
              }}
            >
              {profileUrl ? (
                <img src={profileUrl || ''} alt="User avatar" />
              ) : otherUser ? (
                getNameInitials(otherUser.initial || '')
              ) : (
                channel.name
              )}
              {otherUser &&
                onlineUUIDs?.length &&
                isPresentMember(otherUser.id, onlineUUIDs) && (
                  <i className="pn-member__presence"></i>
                )}
            </div>
            <div className="pn-channel__title">
              <p className="pn-channel__name">{channel.name || channel.id}</p>
              {otherUser ? (
                <p className="pn-channel__description">
                  {otherUser?.custom?.title}
                </p>
              ) : (
                channel.description && (
                  <p className="pn-channel__description">
                    {channel.description}
                  </p>
                )
              )}
            </div>
            <div>
              {console.log(
                unreadMessages[channel.id],
                unreadMessages[channel.id]
              )}
              {unreadMessages?.[channel.id] || 0 > 0 ? (
                <div className="pn-member__messages">
                  <StyledBadge
                    max={99}
                    color="secondary"
                    badgeContent={unreadMessages[channel.id]}
                  />
                </div>
              ) : null}
              {leaveChannel ? (
                <div
                  className="pn-member__action"
                  onClick={(e) => {
                    leaveChannel(channel, e);
                    //   actionCompleted({
                    //     action: 'Leave a 1:1 or Group Chat',
                    //   });
                  }}
                  title="Leave channel"
                >
                  <i className="material-icons-outlined small">logout</i>
                </div>
              ) : null}
              {extraActionsRenderer ? extraActionsRenderer(channel) : null}
            </div>
          </div>
        );
      }}
    />
  );
};

export default AimChannelList;
