import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';

import {
  AimSelect,
  AimSelectMenuItem,
  styled,
  theme,
  AimDialog,
} from '@aim/components';

import { agencyGqlHelper } from '../shared/agencyGqlHelper';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'white',
  padding: '10px',
  minWidth: '450px',
});

const formControlStyle = {
  width: 'calc(100% - 20px)',
};

export const AgencyGroupsDialog = ({
  isDialogOpen,
  style,
  agencyId,
  i18n,
  onDisagree,
  onAgree,
  ...rest
}) => {
  const agency = agencyGqlHelper();
  const [groups, setGroups] = useState([]);
  const [group, setGroup] = useState();

  useEffect(() => {
    if (!agencyId) return;

    const getData = async () => {
      const respGroups = await agency.getAgencyGroups(agencyId);
      setGroups(respGroups);
    };

    getData();
  }, [agencyId]);

  return (
    <AimDialog
      disableEnforceFocus
      fullWidth
      maxWidth={'md'}
      open={isDialogOpen}
      title={i18n.modal.groups.title}
      onClose={onDisagree}
      disagreeText={i18n.modal.groups.buttons.cancel}
      agreeText={i18n.modal.groups.buttons.addParticipant}
      onDisagree={onDisagree}
      onAgreeDisabled={!group}
      onAgree={() => onAgree(group)}
    >
      <Container style={style} {...rest}>
        <Grid
          container
          alignItems="center"
          style={{
            margin: '0px 10px 10px 10px',
            padding: '0px',
            backgroundColor: theme.colors.greyScale.backgroundGrey,
            width: 'auto',
          }}
        >
          <AimSelect
            name="group"
            value={group}
            onChange={(e) => setGroup(e.target.value)}
            selectPlaceholder={i18n.modal.groups.label}
            formControlStyle={formControlStyle}
          >
            {groups &&
              groups.map((item) => {
                if (!item.isPrepaid) {
                  return (
                    <AimSelectMenuItem
                      key={item.id}
                      value={item.id}
                      disabled={item.closingDate}
                    >
                      {item.name}
                    </AimSelectMenuItem>
                  );
                }
              })}
          </AimSelect>
        </Grid>
      </Container>
    </AimDialog>
  );
};
