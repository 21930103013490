export const translation = {
  sponsorListDetails: (intl) => ({
    aimTablePage: {
      header: {
        backButton: intl.formatMessage({
          description: 'Services Configuration label',
          defaultMessage: 'Services Configuration',
        }),
        action: {
          exportExcel: {
            tooltip: intl.formatMessage({
              description: 'export selected tooltip',
              defaultMessage: 'Export',
            }),
          },
          import: {
            tooltip: intl.formatMessage({
              description: 'massive import',
              defaultMessage: 'Massive import',
            }),
          },
          deleteAll: {
            tooltip: intl.formatMessage({
              description: 'delete selected tooltip',
              defaultMessage: 'Delete selected',
            }),
          },
          add: {
            tooltip: intl.formatMessage({
              description: 'add member tooltip',
              defaultMessage: 'Add',
            }),
          },
          close: {
            label: intl.formatMessage({
              description: 'close button',
              defaultMessage: 'CLOSE GROUP',
            }),
          },
          payApprovedChanges: {
            label: intl.formatMessage({
              description: 'pay approved changes button',
              defaultMessage: 'PAY APPROVED CHANGES',
            }),
          },
        },
      },
      stats: {
        remainingPlaces: intl.formatMessage({
          description: 'remaining places label',
          defaultMessage: 'Remaining places',
        }),
        currentMembers: intl.formatMessage({
          description: 'current members label',
          defaultMessage: 'Current Members',
        }),
        status: intl.formatMessage({
          description: 'status label',
          defaultMessage: 'Status',
        }),
        agency: intl.formatMessage({
          description: 'agency label',
          defaultMessage: 'Agency',
        }),
        deadline: intl.formatMessage({
          description: 'closing group date label',
          defaultMessage: 'Closing group',
        }),
        freezeDeadline: intl.formatMessage({
          description: 'Post-closing paid changes until label',
          defaultMessage: 'Post-closing paid changes until',
        }),
        pendingRequests: intl.formatMessage({
          description: 'pending requests',
          defaultMessage: 'Pending requests',
        }),
      },
      table: {
        empty: intl.formatMessage({
          description: 'Table empty',
          defaultMessage: 'No data',
        }),
        columns: {
          username: intl.formatMessage({
            description: 'username label',
            defaultMessage: 'ID',
          }),
          givenName: intl.formatMessage({
            description: 'given name label',
            defaultMessage: 'Given Name',
          }),
          familyName: intl.formatMessage({
            description: 'familyName',
            defaultMessage: 'Family Name',
          }),
          email: intl.formatMessage({
            description: 'Sponsored Participations member email',
            defaultMessage: 'email',
          }),
          profile: intl.formatMessage({
            description: 'Sponsored Participations member profile',
            defaultMessage: 'Profile',
          }),
          typology: intl.formatMessage({
            description: 'Sponsored Participations member typology',
            defaultMessage: 'Typology',
          }),
          statusLabel: intl.formatMessage({
            description: 'spoSponsored Participations member status',
            defaultMessage: 'Status',
          }),
          isParticipant: intl.formatMessage({
            description: 'Sponsored Participations member is pax',
            defaultMessage: 'Pax',
          }),
          isSpeaker: intl.formatMessage({
            description: 'Sponsored Participations member is spreaker',
            defaultMessage: 'Speak',
          }),
          isReviewer: intl.formatMessage({
            description: 'Sponsored Participations member is reviewer',
            defaultMessage: 'Rev',
          }),
        },
        rowActionText: intl.formatMessage({
          description: 'under examination',
          defaultMessage: 'Under Examination',
        }),
        rowAction: {
          edit: intl.formatMessage({
            description: 'edit tooltip',
            defaultMessage: 'Edit',
          }),
          delete: intl.formatMessage({
            description: 'delete tooltip',
            defaultMessage: 'Delete',
          }),
        },
      },
      dialog: {
        delete: {
          title: intl.formatMessage({
            description: 'Delete Participants title',
            defaultMessage: 'Delete Participants',
          }),
          message: intl.formatMessage({
            description: 'Dialog delete message',
            defaultMessage: 'Are you sure?',
          }),
          agreeText: intl.formatMessage({
            description: 'Dialog delete onAgreeText',
            defaultMessage: 'Delete',
          }),
          disagreeText: intl.formatMessage({
            description: 'Dialog delete onDisagreeText',
            defaultMessage: 'Cancel',
          }),
        },
        deleteAll: {
          title: intl.formatMessage({
            description: 'Delete All Participants title',
            defaultMessage: 'Delete Participants',
          }),
          message: intl.formatMessage({
            description: 'Dialog delete message',
            defaultMessage: 'Do you want delete all selected participants?',
          }),
          agreeText: intl.formatMessage({
            description: 'Dialog delete onAgreeText',
            defaultMessage: 'Delete',
          }),
          disagreeText: intl.formatMessage({
            description: 'Dialog delete onDisagreeText',
            defaultMessage: 'Cancel',
          }),
        },
        buyTickets: {
          title: intl.formatMessage({
            description: 'buy list participations label',
            defaultMessage: 'Buy additional list participations',
          }),
          message: intl.formatMessage({
            description: 'Dialog add message',
            defaultMessage:
              'If you want to add new list members, you have to purchase the corresponding fee. Choose the number of members you would like to engage.',
          }),
          agreeText: intl.formatMessage({
            description: 'Dialog buy onAgreeText',
            defaultMessage: 'Buy',
          }),
          disagreeText: intl.formatMessage({
            description: 'Dialog buy onDisagreeText',
            defaultMessage: 'Cancel',
          }),
        },
        close: {
          title: intl.formatMessage({
            description: 'Confirm Sponsored Participations close title',
            defaultMessage: 'Confirm closing',
          }),
          message: intl.formatMessage({
            description: 'Dialog close message',
            defaultMessage: 'Are You sure?',
          }),
          agreeText: intl.formatMessage({
            description: 'Dialog delete onAgreeText',
            defaultMessage: 'Close',
          }),
          disagreeText: intl.formatMessage({
            description: 'Dialog delete onDisagreeText',
            defaultMessage: 'Cancel',
          }),
        },
      },
      snackbar: {
        delete: {
          success: intl.formatMessage({
            description: 'Snackbar delete message',
            defaultMessage: 'Delete ok',
          }),
          error: intl.formatMessage({
            description: 'Snackbar delete message',
            defaultMessage: 'Some error',
          }),
        },
        close: {
          success: intl.formatMessage({
            description: 'Snackbar delete message',
            defaultMessage: 'Sponsored Participation group closed',
          }),
          error: intl.formatMessage({
            description: 'Snackbar delete message',
            defaultMessage:
              'Is not possible to close Sponsored Participation Group',
          }),
        },
      },
    },
    page: {
      statuses: {
        open: intl.formatMessage({
          description: 'open status',
          defaultMessage: 'Open',
        }),
        closed: intl.formatMessage({
          description: 'closes status',
          defaultMessage: 'Closed',
        }),
      },
      snackbar: {
        participationsWithNoTickets: intl.formatMessage({
          description:
            'sponsor list close error all participations must have ticket, participation mode and profile',
          defaultMessage:
            'All participations must have ticket, participation mode and profile',
        }),
        missingTicketsForCobmination: intl.formatMessage({
          description:
            'sponsor list close error all there are not sufficient quotas for:',
          defaultMessage: 'there are not sufficient tickets for:',
        }),
        missing: intl.formatMessage({
          description: 'sponsor list close error all missing',
          defaultMessage: 'missing',
        }),
      },
    },
  }),
};
