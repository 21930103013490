export const translation = {
  participationServicesPublicPage: (intl) => ({
    general: {
      bookingInfo: intl.formatMessage({
        description: 'booking info label',
        defaultMessage: 'Booking info',
      }),
      welcomeTo: intl.formatMessage({
        description: 'booking info label',
        defaultMessage: 'Welcome to your services summary section',
      }),
      clientInfo: intl.formatMessage({
        description: 'booking info label',
        defaultMessage: 'Client info',
      }),
      clickOnTheSection: intl.formatMessage({
        description: 'booking info label',
        defaultMessage:
          'Click on the sections to view details of the different services and download any attachments',
      }),
      clickOnButton: intl.formatMessage({
        description: 'booking info label',
        defaultMessage:
          'Click on the button to view details of your participation at the event',
      }),
      guests: intl.formatMessage({
        description: 'guests label',
        defaultMessage: 'Guests',
      }),
      hotel: intl.formatMessage({
        description: 'hotel label',
        defaultMessage: 'Hotel',
      }),
      hotelAddress: intl.formatMessage({
        description: 'hotel address label',
        defaultMessage: 'Address',
      }),
      hotelStars: intl.formatMessage({
        description: 'hotel stars label',
        defaultMessage: 'Stars',
      }),
      hotelEmail: intl.formatMessage({
        description: 'hotel email label',
        defaultMessage: 'Email',
      }),
      hotelPhone: intl.formatMessage({
        description: 'hotel phone label',
        defaultMessage: 'Phone',
      }),
      roomTypology: intl.formatMessage({
        description: 'roomTypology label',
        defaultMessage: 'Room Typology',
      }),
      hotelTreatment: intl.formatMessage({
        description: 'hotelTreatment label',
        defaultMessage: 'Accomodation',
      }),
      checkIn: intl.formatMessage({
        description: 'checkIn label',
        defaultMessage: 'Check-in',
      }),
      checkOut: intl.formatMessage({
        description: 'checkOut label',
        defaultMessage: 'Check-out',
      }),
      downloadArea: intl.formatMessage({
        description: 'participation public services downloadArea label',
        defaultMessage: 'Download area',
      }),
      download: intl.formatMessage({
        description: 'participation public services download button',
        defaultMessage: 'Download',
      }),
      print: intl.formatMessage({
        description: 'participation public services print button',
        defaultMessage: 'Print',
      }),
      registrationInfo: intl.formatMessage({
        description: 'registrationInfo label',
        defaultMessage: 'Registration info',
      }),
      start: intl.formatMessage({
        description: 'start label',
        defaultMessage: 'Start',
      }),
      end: intl.formatMessage({
        description: 'end label',
        defaultMessage: 'End',
      }),

      event: intl.formatMessage({
        description: 'event label',
        defaultMessage: 'Event',
      }),
      description: intl.formatMessage({
        description: 'description label',
        defaultMessage: 'Description',
      }),
      date: intl.formatMessage({
        description: 'date label',
        defaultMessage: 'Date',
      }),
      startTime: intl.formatMessage({
        description: 'startTime label',
        defaultMessage: 'Start time',
      }),
      endTime: intl.formatMessage({
        description: 'endTime label',
        defaultMessage: 'End time',
      }),
      location: intl.formatMessage({
        description: 'location label',
        defaultMessage: 'Location',
      }),
      address: intl.formatMessage({
        description: 'address label',
        defaultMessage: 'Address',
      }),
      from: intl.formatMessage({
        description: 'from label',
        defaultMessage: 'From',
      }),
      time: intl.formatMessage({
        description: 'pick up time label',
        defaultMessage: 'Pick-up time',
      }),
      to: intl.formatMessage({
        description: 'to label',
        defaultMessage: 'To',
      }),
      typeOfTravel: intl.formatMessage({
        description: 'typeOfTravel label',
        defaultMessage: 'Type of Travel',
      }),
      personalTransportDescr: intl.formatMessage({
        description: 'personal transport label',
        defaultMessage:
          'We have taken note that he will independently arrange for the journey and will reach the headquarters by his own means.',
      }),
      registration: intl.formatMessage({
        description: 'participation public services registration label',
        defaultMessage: 'Registration',
      }),
      additional_services: intl.formatMessage({
        description: 'participation public services Additional Services label',
        defaultMessage: 'AdditionalServices',
      }),
      allotment: intl.formatMessage({
        description: 'participation public services allotment label',
        defaultMessage: 'Allotment',
      }),
      travel: intl.formatMessage({
        description: 'participation public services travel label',
        defaultMessage: 'Travel',
      }),
      travelCode: intl.formatMessage({
        description: 'travelCode label',
        defaultMessage: 'Travel Code',
      }),
      aR: intl.formatMessage({
        description: 'A/R label',
        defaultMessage: 'A/R',
      }),
      accompanyingPersons: intl.formatMessage({
        description: 'accompanyingPersons label',
        defaultMessage: 'Accompanying Persons',
      }),
      departureFrom: intl.formatMessage({
        description: 'departureFrom label',
        defaultMessage: 'Departure From',
      }),
      departureTime: intl.formatMessage({
        description: 'departureTime label',
        defaultMessage: 'Departure Time',
      }),
      arrivalTo: intl.formatMessage({
        description: 'arrivalTo label',
        defaultMessage: 'Arrival To',
      }),
      arrivalTime: intl.formatMessage({
        description: 'arrivalTime label',
        defaultMessage: 'Arrival Time',
      }),
      arrivalDate: intl.formatMessage({
        description: 'arrivalDate label',
        defaultMessage: 'Arrival Date',
      }),
      departureDate: intl.formatMessage({
        description: 'departureDate label',
        defaultMessage: 'Departure Date',
      }),
      pnr: intl.formatMessage({
        description: 'pnr label',
        defaultMessage: 'PNR',
      }),
    },
    generalInfo: {
      backButtonLabel: intl.formatMessage({
        description: 'back button label',
        defaultMessage: 'Back',
      }),
    },
    client: {
      name: intl.formatMessage({
        description: 'name label',
        defaultMessage: 'Name',
      }),
      surname: intl.formatMessage({
        description: 'surname label',
        defaultMessage: 'Surname',
      }),
      email: intl.formatMessage({
        description: 'email label',
        defaultMessage: 'Email',
      }),
      phone: intl.formatMessage({
        description: 'phone label',
        defaultMessage: 'Phone',
      }),
    },
    pdf: {
      time: intl.formatMessage({
        description: 'time  label',
        defaultMessage: 'Time',
      }),
      paymentDetailsTitle: intl.formatMessage({
        description: 'paymentDetailsTitle section label',
        defaultMessage: 'Payment Details',
      }),
      total: intl.formatMessage({
        description: 'total section label',
        defaultMessage: 'Total',
      }),
      paymentMethod: intl.formatMessage({
        description: 'paymentMethod section label',
        defaultMessage: 'Payment Method',
      }),
      iban: intl.formatMessage({
        description: 'iban section label',
        defaultMessage: 'IBAN',
      }),
      paymentReason: intl.formatMessage({
        description: 'paymentReason section label',
        defaultMessage: 'Payment Reason',
      }),
      addressedTo: intl.formatMessage({
        description: 'addressedTo section label',
        defaultMessage: 'Addressed To',
      }),
      paymentStatus: intl.formatMessage({
        description: 'paymentStatus section label',
        defaultMessage: 'Payment Status',
      }),
      bookingInfo: intl.formatMessage({
        description: 'bookingInfo section label',
        defaultMessage: 'Booking Info',
      }),
      hotel: intl.formatMessage({
        description: 'hotel section label',
        defaultMessage: 'Hotel',
      }),
      hotelStars: intl.formatMessage({
        description: 'hotel stars label',
        defaultMessage: 'Stars',
      }),
      hotelTreatment: intl.formatMessage({
        description: 'hotel treatment label',
        defaultMessage: 'Accomodation',
      }),
      hotelPhone: intl.formatMessage({
        description: 'hotel phone label',
        defaultMessage: 'Phone',
      }),
      hotelEmail: intl.formatMessage({
        description: 'hotel email label',
        defaultMessage: 'Email',
      }),
      hotelAddress: intl.formatMessage({
        description: 'hotel address label',
        defaultMessage: 'Address',
      }),
      roomTypology: intl.formatMessage({
        description: 'roomTypology section label',
        defaultMessage: 'Room Typology',
      }),
      guests: intl.formatMessage({
        description: 'guests section label',
        defaultMessage: 'Guests',
      }),
      checkIn: intl.formatMessage({
        description: 'checkIn section label',
        defaultMessage: 'Check-In',
      }),
      checkOut: intl.formatMessage({
        description: 'checkOut section label',
        defaultMessage: 'Check-Out',
      }),
      amountVatExcluded: intl.formatMessage({
        description: 'amountVatExcluded section label',
        defaultMessage: 'Amount VAT Excluded',
      }),
      vat: intl.formatMessage({
        description: 'vat section label',
        defaultMessage: 'VAT (%)',
      }),
      amountVatIncluded: intl.formatMessage({
        description: 'amountVatIncluded section label',
        defaultMessage: 'Amount VAT Included',
      }),
      typeOfTravel: intl.formatMessage({
        description: 'typeOfTravel section label',
        defaultMessage: 'Type Of Travel',
      }),
      personalTransportDescr: intl.formatMessage({
        description: 'personal transport label',
        defaultMessage:
          'We have taken note that he will independently arrange for the journey and will reach the headquarters by his own means.',
      }),
      travelCode: intl.formatMessage({
        description: 'travelCode section label',
        defaultMessage: 'Travel Code',
      }),
      aR: intl.formatMessage({
        description: 'aR section label',
        defaultMessage: 'A/R',
      }),
      accompanyingPersons: intl.formatMessage({
        description: 'accompanyingPersons section label',
        defaultMessage: 'Accompanying Persons',
      }),
      departureFrom: intl.formatMessage({
        description: 'departureFrom section label',
        defaultMessage: 'Departure From',
      }),
      departureDate: intl.formatMessage({
        description: 'departureDate section label',
        defaultMessage: 'Departure Date',
      }),
      departureTime: intl.formatMessage({
        description: 'departureTime section label',
        defaultMessage: 'Departure Time',
      }),
      arrivalTo: intl.formatMessage({
        description: 'arrivalTo section label',
        defaultMessage: 'Arrival To',
      }),
      arrivalDate: intl.formatMessage({
        description: 'arrivalDate section label',
        defaultMessage: 'Arrival Date',
      }),
      arrivalTime: intl.formatMessage({
        description: 'arrivalTime section label',
        defaultMessage: 'Arrival Time',
      }),
      pnr: intl.formatMessage({
        description: 'pnr section label',
        defaultMessage: 'PNR',
      }),
      date: intl.formatMessage({
        description: 'date section label',
        defaultMessage: 'Date',
      }),
      from: intl.formatMessage({
        description: 'from section label',
        defaultMessage: 'From',
      }),
      to: intl.formatMessage({
        description: 'to section label',
        defaultMessage: 'To',
      }),
      clientInformationsTitle: intl.formatMessage({
        description: 'clientInformationsTitle section label',
        defaultMessage: 'Client Informations',
      }),
      name: intl.formatMessage({
        description: 'name section label',
        defaultMessage: 'Name',
      }),
      surname: intl.formatMessage({
        description: 'surname section label',
        defaultMessage: 'Surname',
      }),
      email: intl.formatMessage({
        description: 'email section label',
        defaultMessage: 'Email',
      }),
      phone: intl.formatMessage({
        description: 'phone section label',
        defaultMessage: 'Phone',
      }),
      registrationInformationsTitle: intl.formatMessage({
        description: 'registrationInformationsTitle section label',
        defaultMessage: 'Registration Informations',
      }),
      registration: intl.formatMessage({
        description: 'registration section label',
        defaultMessage: 'Registration',
      }),
      start: intl.formatMessage({
        description: 'start section label',
        defaultMessage: 'Start',
      }),
      end: intl.formatMessage({
        description: 'end section label',
        defaultMessage: 'End',
      }),
      serviceInformationsTitle: intl.formatMessage({
        description: 'serviceInformationsTitle section label',
        defaultMessage: 'Service Informations',
      }),
      event: intl.formatMessage({
        description: 'event section label',
        defaultMessage: 'Event',
      }),
      description: intl.formatMessage({
        description: 'description section label',
        defaultMessage: 'Description',
      }),
      startTime: intl.formatMessage({
        description: 'startTime section label',
        defaultMessage: 'Start Time',
      }),
      endTime: intl.formatMessage({
        description: 'endTime section label',
        defaultMessage: 'End Time',
      }),
      location: intl.formatMessage({
        description: 'location section label',
        defaultMessage: 'Location',
      }),
      address: intl.formatMessage({
        description: 'address section label',
        defaultMessage: 'Address',
      }),
      amount: intl.formatMessage({
        description: 'amount section label',
        defaultMessage: 'Amount',
      }),
      billingInformationsTitle: intl.formatMessage({
        description: 'billingInformationsTitle section label',
        defaultMessage: 'Billing informations',
      }),
      invoiceTo: intl.formatMessage({
        description: 'invoiceTo section label',
        defaultMessage: 'Invoice to',
      }),
      companyOrganization: intl.formatMessage({
        description: 'companyOrganization section label',
        defaultMessage: 'Company/Organization',
      }),

      city: intl.formatMessage({
        description: 'city section label',
        defaultMessage: 'City',
      }),
      postalCode: intl.formatMessage({
        description: 'postalCode section label',
        defaultMessage: 'Postal code',
      }),
      region: intl.formatMessage({
        description: 'region section label',
        defaultMessage: 'Region',
      }),
      province: intl.formatMessage({
        description: 'province section label',
        defaultMessage: 'Province',
      }),
      country: intl.formatMessage({
        description: 'country section label',
        defaultMessage: 'Country',
      }),
      vatNumber: intl.formatMessage({
        description: 'vatNumber section label',
        defaultMessage: 'VAT Number',
      }),
      fiscalCode: intl.formatMessage({
        description: 'fiscalCode section label',
        defaultMessage: 'Fiscal Code',
      }),
      reference: intl.formatMessage({
        description: 'reference section label',
        defaultMessage: 'Reference',
      }),

      invoiceToPublicAuthority: intl.formatMessage({
        description: 'invoiceToPublicAuthority section label',
        defaultMessage: 'Invoice to Public Authority',
      }),
      uniqueCode: intl.formatMessage({
        description: 'amount section label',
        defaultMessage: 'Unique Code',
      }),
      ipaCode: intl.formatMessage({
        description: 'ipaCode section label',
        defaultMessage: 'IPA code',
      }),
      splitPayment: intl.formatMessage({
        description: 'splitPayment section label',
        defaultMessage: 'Split Payment',
      }),
      yes: intl.formatMessage({
        description: 'yes label',
        defaultMessage: 'Yes',
      }),
      no: intl.formatMessage({
        description: 'no label',
        defaultMessage: 'No',
      }),

      isVatExentExtended: intl.formatMessage({
        description: 'isVatExentExtended label',
        defaultMessage: 'Is VAT Exent? (Art. 10 DPR N.633/72)',
      }),
    },
  }),
};
