import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
// import { isMobileOnly } from 'react-device-detect';
import { useParams, useHistory } from 'react-router-dom';
import {
  CustomIntl,
  AimCalendar,
  AimTypography,
  AimSnackbar,
  AimSnackbarSeverity,
  calendarVariants,
  theme,
  useAimMediaQuery,
} from '@aim/components';
import useI18n from './agendaList/i18n';
import Timezone from './agendaList/Timezone';
import { createFavorite, deleteFavorite } from './agendaList/gqlHelper';
import { useAgenda } from './shared/agendaGqlHelper';
import { constants, appState, fileHelper } from '@aim/common';

import format from 'date-fns/format';
import isEqual from 'date-fns/isEqual';
import startOfDay from 'date-fns/startOfDay';
// import { sortBy } from 'lodash';
import {
  utcToZonedTime,
  formatInTimeZone,
  format as formatTz,
} from 'date-fns-tz';
import enGB from 'date-fns/locale/en-GB';
import AgendaMontlhy from './agendaList/AgendaMonthly';
import { loadUserData } from '../utilities';

const AgendaList = () => {
  const intl = CustomIntl(useIntl());
  const i18n = useI18n(intl);
  const history = useHistory();
  const { getEvent } = useAgenda();

  const { isMobileOnly } = useAimMediaQuery();

  const currentTimezone = appState.eventInfo.getValue().timezone;

  const [event, setEvent] = useState();
  const [eventConfiguration] = useState(appState.eventConfiguration.getValue());
  const [sessions, setSessions] = useState([]);
  const [visibleSessions, setVisibleSessions] = useState([]);
  const [tabState, setTabState] = useState({ timetable: true });
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [myAgenda, setMyAgenda] = useState([]);
  const [screenDate, setScreenDate] = useState(
    utcToZonedTime(new Date(), currentTimezone)
  );
  const [posterSessions, setPosterSessions] = useState(
    utcToZonedTime(new Date(), currentTimezone)
  );
  const [screenDatePs, setScreenDatePs] = useState([]);
  const [participation, setParticipation] = useState();

  const { eventId } = useParams();

  const addToMyAgenda = async (agendaSessionId) => {
    const favorite = await createFavorite(agendaSessionId, participation.id);
    if (!favorite) return;

    loadUserData({ eventId, useLoader: false });
    setSnackbar({
      isOpen: true,
      severity: AimSnackbarSeverity.success,
      message: i18n.actions.myAgenda.success,
    });

    const nextAgenda = [
      ...myAgenda,
      { id: favorite.id, session: agendaSessionId },
    ];
    setMyAgenda(nextAgenda);
  };

  const removeFromMyAgenda = async (favoriteId, agendaSessionId) => {
    const removedFavId = await deleteFavorite(favoriteId);
    if (!removedFavId) return;

    loadUserData({ eventId, useLoader: false });
    setSnackbar({
      isOpen: true,
      severity: AimSnackbarSeverity.success,
      message: i18n.actions.myAgenda.remove,
    });

    const nextAgenda = myAgenda.filter((s) => s.session !== agendaSessionId);
    setMyAgenda(nextAgenda);
  };

  const handleSelectEvent = (session) => {
    history.push(`/events/${eventId}/agenda/sessions/${session.id}`);
  };

  const handleBookmarkClick = (session) => {
    const { id: agendaSessionId } = session;
    const favorite = myAgenda.find((s) => s.session === agendaSessionId);
    favorite
      ? removeFromMyAgenda(favorite.id, agendaSessionId)
      : addToMyAgenda(agendaSessionId);
  };

  const getRooms = () => {
    const sortedRooms = [...event.rooms.items].sort(
      (a, b) => (a.ordering || 0) - (b.ordering || 0)
    );
    return tabState.timetable
      ? sortedRooms.filter((r) => r.id !== 'br')
      : sortedRooms;
  };

  const formattedInTimeZone = () => {
    return formatTz(screenDate, "zzz '(' x 'h)'", {
      timeZone: currentTimezone,
      locale: enGB,
    });
  };

  // const formattedForCurrentTimeZone = formatInTimeZone(
  //   screenDate,
  //   currentTimezone,
  //   'zzz'
  // );
  // console.log('formattedForCurrentTimeZone ', formattedForCurrentTimeZone);

  const renderCalendar = () => {
    return (
      <div
        style={{
          marginBottom: 30,
          maxHeight: 'calc(100vh - 200px)',
          padding: 10,
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <AimCalendar
          style={{
            maxWidth: 'calc(100vw - 20px)',
            display: 'flex',
            flex: 1,
          }}
          event={event}
          sessions={visibleSessions}
          rooms={getRooms()}
          defaultZoomValue={2}
          selectable={false}
          onSelectEvent={handleSelectEvent}
          showPreview={false}
          scrollToFirst
          getCurrentDate={setScreenDate}
          showToolbarButtonsText={isMobileOnly ? false : true}
          toolbalButtonNextText={'Next day'}
          toolbalButtonPreviousText={'Previous day'}
          onBookmarkClick={handleBookmarkClick}
        />
      </div>
    );
  };

  const pinnedAgendaSession = { icon: 'pinned', isPinned: true };
  const unPinnedAgendaSession = { icon: 'unpinned', isPinned: false };
  //get events to be put on the calendar
  const getAgenda = async () => {
    //TODO: da modificicare il cluster in base a quello dal partecipante
    const res = await getEvent(eventId, constants.Clusters.Pax.id);
    const innerParticipation = appState.user.getValue()?.userAndParticipation
      ?.participation;
    setParticipation(innerParticipation);
    let myBr = [];

    if (innerParticipation.myBreakoutRoom.items) {
      myBr = innerParticipation.myBreakoutRoom.items
        .filter((item) => item.slot.breakoutRoom)
        .map((item) => ({
          //le br sono state rimosse male, rimuovere anche lo slot

          id: item.slot.id,
          sponsorId: item.slot.breakoutRoom.sponsor.id,
          type: item.slot.isOneToOne
            ? calendarVariants.BREAKOUT_ROOM_MEETING
            : calendarVariants.BREAKOUT_ROOM_SLOT,
          title: item.slot.title,
          start: utcToZonedTime(new Date(item.slot.start), currentTimezone),
          end: utcToZonedTime(new Date(item.slot.end), currentTimezone),
          date: utcToZonedTime(new Date(item.slot.start), currentTimezone),
          resourceId: 'br',
          ...unPinnedAgendaSession,
        }));
    }
    setMyAgenda([
      ...innerParticipation.myAgenda.items.map((s) => ({
        id: s.id,
        session: s.session?.id,
      })),
      ...myBr,
    ]);
    const _myAgenda = innerParticipation.myAgenda.items.map(
      (s) => s.session?.id
    );

    setPosterSessions(res.agenda?.posterSessions?.items);

    //parse sessions
    let parsedSessions = await Promise.all(
      res.agenda.sessions.items
        .filter((s) => {
          const isSessionPublished =
            s.status === constants.agendaSessionStatus.PUBLISHED;

          const sessionSpeakers = [
            // Filter pax which are speakers...
            ...s.attendeeRoles.items, // ...for main agenda session
            ...s.subSessions.items.flatMap((ss) => [
              ...ss.attendeeRoles.items, // ...or for subsessions
              ...ss.speeches.items, // ...or for subsessions speeches
            ]),
          ].flatMap((r) => r.attendees?.items || []);
          const isSpeaker = sessionSpeakers.find(
            (s) => s.participation?.id === innerParticipation.id
          );
          const visibleForSpeaker = s.isPublishedForSpeakers && isSpeaker;

          return (
            visibleForSpeaker ||
            (isSessionPublished &&
              (!s.profileVisibilityIds?.length ||
                (s.profileVisibilityIds?.length &&
                  s.profileVisibilityIds.includes(
                    innerParticipation?.profile?.id
                  ))))
          );
        })
        .map(async (s) => {
          const session = {
            ...s,
            title: s.name,
            start: utcToZonedTime(new Date(s.start), currentTimezone),
            end: utcToZonedTime(new Date(s.end), currentTimezone),
            date: utcToZonedTime(new Date(s.date), currentTimezone),
            resourceId: s.room?.id,
            color: s.sessionTypology?.color,
            sessionName: s?.sessionTypology?.name || '',
            ...(_myAgenda.includes(s.id) && pinnedAgendaSession),
            attendees: s.attendeeRoles.items.flatMap((ar) =>
              ar.attendees.items.map((as) => {
                const profileImageLink = as?.participation?.userShowcase
                  ?.profileImage
                  ? fileHelper.getPublicFileLink({
                      dirPath: `events/${eventId}/user/${as.participation.id}/showcase/profileImage`,
                      skipFileDataOnS3Link: true,
                    })
                  : '';
                return {
                  ...as,
                  participation: {
                    ...as.participation,
                    profileImageLink,
                  },
                  role: ar.role,
                };
              })
            ),
          };
          return session;
        })
    );

    let rooms =
      res.rooms.items && res.rooms.items.length ? res.rooms : { items: [] };
    // rooms.items = sortBy(rooms.items, 'name');
    if (innerParticipation.myBreakoutRoom.items) {
      parsedSessions = [...parsedSessions, ...myBr];

      const brRoom = {
        id: 'br',
        type: 'virtual',
        capacity: 1,
        delay: 0,
        name: 'BreakoutRoom',
      };

      rooms.items = [brRoom, ...rooms.items];
    }

    // const start = utcToZonedTime(new Date(res.start), currentTimezone);
    // const timezoneOffset = start.getTimezoneOffset() / 60;

    const _event = {
      ...res,
      start: utcToZonedTime(new Date(res.start), currentTimezone),
      end: utcToZonedTime(new Date(res.end), currentTimezone),
      // timezoneOffset,
      rooms: rooms,
    };

    setEvent(_event);
    setSessions(parsedSessions);
    setVisibleSessions(parsedSessions);
  };

  useEffect(() => {
    getAgenda();
  }, []);

  useEffect(() => {
    if (posterSessions.length) {
      const slots = posterSessions
        .map((ps) =>
          ps.sessionSlots?.items?.map((slot) => ({
            ...slot,
            title: ps?.title || '',
            room: ps?.room?.name || '',
            posterSessionId: ps?.id,
          }))
        )
        .flat();
      setScreenDatePs(
        slots.filter((ps) => {
          return isEqual(
            startOfDay(new Date(ps.date)),
            startOfDay(new Date(screenDate))
          );
        })
      );
    }
  }, [screenDate]);

  useEffect(() => {
    let _visibleSessions = [];
    if (tabState.timetable) {
      _visibleSessions = sessions.map((s) => ({
        ...s,
        ...(flatMyAgenda().includes(s.id)
          ? pinnedAgendaSession
          : unPinnedAgendaSession),
      }));
    } else {
      _visibleSessions = sessions
        .filter(
          (s) =>
            flatMyAgenda().includes(s.id) ||
            s.type === calendarVariants.BREAKOUT_ROOM_MEETING ||
            s.type === calendarVariants.BREAKOUT_ROOM_SLOT
        )
        .map((s) => ({
          ...s,
          ...pinnedAgendaSession,
        }));
    }
    setVisibleSessions(() => _visibleSessions);
  }, [tabState, myAgenda]);

  const flatMyAgenda = () => {
    return myAgenda.map((s) => s.session);
  };

  const borderColor = (timetable) => {
    return timetable === tabState.timetable
      ? theme.palette.primary
      : 'transparent';
  };

  const PosterSessionComponent = ({ poster }) => {
    return (
      <div
        style={{
          width: '92%',
          margin: '15px auto',
          height: 60,
          backgroundColor: '#FFF7CF',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
        }}
        onClick={() =>
          history.push(
            `/events/${eventId}/agenda/poster-session/${poster.posterSessionId}`
          )
        }
      >
        <AimTypography variant={'textBold'}>
          {poster.title} ({poster.room},{' '}
          {format(new Date(poster.start), 'HH:mm')} -{' '}
          {format(new Date(poster.end), 'HH:mm')})
        </AimTypography>
      </div>
    );
  };

  return (
    <div
      style={{
        overflowY: 'auto',
        overflowX: 'auto',
        width: '100%',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        background:
          eventConfiguration.homepageAgendaViewType ===
          constants.AgendaViewTypes.MONTHLY.key
            ? theme.colors.greyScale.backgroundGrey
            : theme.colors.primary.white,
      }}
    >
      {/* <Timezone event={event} /> */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: 32,
          flex: 1,
        }}
      >
        <AimTypography
          varian="h4"
          boxStyle={{ fontWeight: 600 }}
        >{`TIME ZONE ${formattedInTimeZone()}`}</AimTypography>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flex: 1,
          gap: 10,
        }}
      >
        <AimTypography
          variant={isMobileOnly ? 'h2' : 'h1'}
          onClick={() => {
            setTabState({ timetable: false });
          }}
          boxStyle={{
            cursor: 'pointer',
            borderBottom: `3px solid ${borderColor(false)}`,
          }}
        >
          My Agenda
        </AimTypography>
        <AimTypography
          variant={isMobileOnly ? 'h2' : 'h1'}
          onClick={() => {
            setTabState({ timetable: true });
          }}
          boxStyle={{
            cursor: 'pointer',
            borderBottom: `3px solid ${borderColor(true)}`,
          }}
        >
          TimeTable
        </AimTypography>
      </div>
      {event &&
      eventConfiguration.homepageAgendaViewType ===
        constants.AgendaViewTypes.MONTHLY.key ? (
        <AgendaMontlhy
          tabState={tabState}
          setTabState={setTabState}
          sessions={visibleSessions}
          handleBookmarkClick={handleBookmarkClick}
        />
      ) : event?.start && event?.end && event?.rooms ? (
        <>
          {renderCalendar()}
          {screenDatePs.length
            ? screenDatePs.map((p, idx) => (
                <PosterSessionComponent key={idx} poster={p} />
              ))
            : null}
        </>
      ) : null}

      {/* <AimDialog
        open={detailDialog.isOpen}
        disableScrollLock
        onClose={() => setDetailDialog({ isOpen: false })}
        hideAgreeButton
        hideCancelButton
        disableEnforceFocus
        hideDivider
        PaperProps={{
          style: {
            padding: 16,
            minWidth: 330,
          },
        }}
      >
        <AgendaCalendarEventCard
          event={detailDialog}
          addToMyAgenda={addToMyAgenda}
          removeFromMyAgenda={removeFromMyAgenda}
          isTimetable={tabState.timetable}
        />
      </AimDialog> */}
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
      {event && (!event.start || !event.end) && (
        <AimTypography variant="formError">
          {i18n.page.errors.loading}
        </AimTypography>
      )}
    </div>
  );
};

export default AgendaList;
