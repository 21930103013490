import { aws, appState, awsmobile, constants, googleHelper } from '@aim/common';
import { loadCognitoUserItParticipations } from '../pages/shared/participationGqlHelper';
import { getMyEventParticipation } from './gqlHelper';

const {
  getGoogleMapsCountriesByInput,
  getGoogleMapsPlaceByPlaceId,
} = googleHelper;

const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

const updateParticipationQuery = /* GraphQL */ `
  mutation UpdateParticipation(
    $input: UpdateParticipationInput!
    $condition: ModelParticipationConditionInput
  ) {
    updateParticipation(input: $input, condition: $condition) {
      id
    }
  }
`;

const onUpdateParticipationSubscription = /* GraphQL */ `
  subscription OnUpdateParticipationId($id: ID!) {
    onUpdateParticipationId(id: $id) {
      id
    }
  }
`;

const eventConfigurationQuery = /* GraphQL */ `
  query GetEventConfiguration($id: ID!) {
    getEvent(id: $id) {
      id
      name
      code
      start
      end
      city
      timezone
      language {
        id
        name
      }
      isInsertControlCodeInANewUserEnabled
      controlCodeLabel
      controlCodeCode
      availableServices
      maxParticipants
      byInvitationOnlyFromEnabled
      byInvitationOnlyFromDate
      editPersonalDetailsType
      editPersonalDetailsDate
      configuration {
        id
        ePosterLink
        font
        primaryColor
        secondaryColor
        primaryButtonVariant
        primaryButtonBorderRadius
        secondaryButtonVariant
        secondaryButtonBorderRadius
        background
        defaultHeaderCoverImage {
          id
          originalName
          size
          extension
        }
        defaultHeaderCoverImageMobile {
          id
          originalName
          size
          extension
        }
        defaultHeaderCoverImagePages
        footerTextColor
        footerBackgroundColor
        footerInfoCompanyEnabled
        footerInfoCompanyPosition
        footerInfoCompanyDescription
        footerSocialEnabled
        footerSocialPosition
        footerSocialFacebookLink
        footerSocialLinkedinLink
        footerSocialInstagramLink
        footerSocialTwitterLink
        footerLinkEnabled
        footerLinkPosition
        footerLinkData
        eventGenericMenuItems {
          items {
            activeFlagChecked
            dateOfActivation
            id
            key
            menuItemLabel
            showPremiumRangesFlagChecked
            showUpNextFlagChecked
          }
        }
        logo {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        loginCover {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        loginCoverMobile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        isTitleActive
        loginTitleDescription
        isSubtitleActive
        loginSubtitleDescription
        eventActivationDate
        eventDeactivationDate
        countdown
        eventDatesEnabled
        welcomePageTitle
        welcomePageTitleColor
        welcomePageDescription
        welcomePageDescriptionColor
        welcomePageBuyTicket
        welcomePageBuyTicketLabelItem
        welcomePageBuyTicketLinkEvent
        welcomePageJoinEvent
        welcomePageJoinEventLabelItem
        welcomePageMyArea
        welcomePageMyAreaLabelItem
        welcomePageSendAbstract
        welcomePageSendAbstractLabelItem
        welcomePageSendAbstractLinkEvent
        welcomePageAbstractGrant
        welcomePageAbstractGrantLabelItem
        welcomePageAbstractGrantLinkEvent
        welcomePageEducationalGrant
        welcomePageEducationalGrantLabelItem
        welcomePageEducationalGrantLinkEvent
        welcomePageAllotment
        welcomePageAllotmentLabelItem
        welcomePageAllotmentLinkEvent
        welcomePageSocialEvent
        welcomePageSocialEventLabelItem
        welcomePageSocialEventLinkEvent
        welcomePageScientificEvent
        welcomePageScientificEventLabelItem
        welcomePageScientificEventLinkEvent
        welcomePageVisa
        welcomePageVisaLabelItem
        welcomePageVisaLinkEvent
        welcomePageCoverImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        publicLandingPageSurvey {
          id
        }
        publicLandingPageTitle
        publicLandingPageTitleColor
        publicLandingPageDescription
        publicLandingPageDescriptionColor
        publicLandingPageLoginButtonLabel
        publicLandingPageButtonLabel
        publicLandingPageActivationDate
        publicLandingPageDeactivationDate
        publicLandingPageSurveyButtonLabel
        publicLandingPageSurveyActivationDate
        publicLandingPageSurveyDeactivationDate
        publicLandingPageCoverImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        publicLandingPageMobileCoverImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
        generalInfoPageDescription

        homepageWelcomeMessageEnabled
        homepageMapEnabled
        homepageActivityEnabled
        homepageSponsorEnabled
        homepageAgendaEnabled
        homepageAgendaViewType
        homepageSpeakersEnabled
        homepagePrefooterEnabled
        homepageWelcomeMessageText
        homepageMapDesktopModel
        homepageMapMobileModel
        homepagePrefooterMapDesktopModel
        homepagePrefooterMapMobileModel
        homepagePrefooterSocialFacebookLink
        homepagePrefooterSocialLinkedinLink
        homepagePrefooterSocialInstagramLink
        homepagePrefooterSocialTwitterLink
        homepagePrefooterContacts
        homepagePrefooterType
        homepagePrefooterMainPartnerImage {
          id
          originalName
          extension
        }
        homepagePrefooterMainPartnerLink

        homepageItemsOrder
      }
    }
  }
`;

const getEventConfiguration = (eventId, isAuthenticated = true) =>
  new Promise((resolve, reject) => {
    showLoader();
    aws.API.graphql({
      query: eventConfigurationQuery,
      variables: {
        id: eventId,
      },
      // hack to solve public queries works also with authenticated users
      // FOR SOME REASON AWS_IAM non working anymore with authenticated users
      ...(isAuthenticated ? {} : { authMode: 'AWS_IAM' }),
    })
      .then((result) => resolve(result?.data?.getEvent))
      .catch((e) => {
        console.error(`Error:`, e);
        reject();
      })
      .finally(() => hideLoader());
  });

export const loadEventData = async ({ eventId }) => {
  const awsUser = await loadUserData({ eventId });
  const { configuration, ...eventData } = await getEventConfiguration(
    eventId,
    !!awsUser
  );
  if (configuration) {
    appState.eventConfiguration.next({
      ...configuration,
      background: configuration.background || '#FFFFFF',
      primaryColor: configuration.primaryColor || '#FFD400',
      primaryButtonVariant: configuration.primaryButtonVariant || 'contained',
      primaryButtonBorderRadius:
        configuration.primaryButtonBorderRadius || '5px',
      secondaryColor: configuration.secondaryColor || '#84819A',
      secondaryButtonVariant:
        configuration.secondaryButtonVariant || 'contained',
      secondaryButtonBorderRadius:
        configuration.secondaryButtonBorderRadius || '5px',
      font: configuration.font || 'Roboto',
      homepageMapDesktopModel: configuration.homepageMapDesktopModel
        ? JSON.parse(configuration.homepageMapDesktopModel)
        : null,
      homepageMapMobileModel: configuration.homepageMapMobileModel
        ? JSON.parse(configuration.homepageMapMobileModel)
        : null,
      homepagePrefooterMapDesktopModel: configuration.homepagePrefooterMapDesktopModel
        ? JSON.parse(configuration.homepagePrefooterMapDesktopModel)
        : null,
      homepagePrefooterMapMobileModel: configuration.homepagePrefooterMapMobileModel
        ? JSON.parse(configuration.homepagePrefooterMapMobileModel)
        : null,
      footerTextColor: configuration.footerTextColor || '#ffffff',
      footerBackgroundColor: configuration.footerBackgroundColor || '#000000',
    });
  }

  if (eventData?.language?.name === 'English') {
    appState.locale.next('en');
  } else if (eventData?.language?.name === 'Italian') {
    appState.locale.next('it');
  }
  appState.eventInfo.next({
    ...eventData,
    timezone: eventData?.timezone || 'Europe/Rome',
  });
  return true;
};

export const setUserCognitoIdentityProviderIdentityId = (
  participationId,
  identityId
) =>
  new Promise((resolve, reject) => {
    showLoader();

    aws.API.graphql({
      query: updateParticipationQuery,
      variables: {
        input: {
          id: participationId,
          identityPoolIdentityId: identityId,
        },
      },
    })
      .then((response) => {
        resolve(response.data.updateParticipation.id);
      })
      .catch((e) => {
        console.error('login-set-participation-cognito-identity-id', e);
        reject();
      })
      .finally(() => hideLoader());
  });
export const loadUserData = async ({ eventId, useLoader = true }) => {
  const awsUser = await aws.getAwsUser();
  if (awsUser) {
    let userAndParticipation = {};
    if (awsUser?.groups.includes('admin')) {
      //backoffice users not checks cognito identity id
      const storageUserString = localStorage.getItem(
        `impersonate-user-aim-website-root-config`
      );
      if (storageUserString) {
        const impersonateUser = JSON.parse(storageUserString);
        if (impersonateUser) {
          userAndParticipation = await getMyEventParticipation(
            impersonateUser.cognitoUserId,
            eventId,
            useLoader
          );
        } //else user admin has no access on website
      }
    } else if (awsUser?.groups.includes('customer')) {
      if (!awsUser?.attributes) {
        await aws.signOutFromWebsite(eventId);
      }
      userAndParticipation = await getMyEventParticipation(
        awsUser.attributes.sub,
        eventId,
        useLoader
      );
      if (userAndParticipation) {
        const cognitoIdentityId =
          userAndParticipation.participation.identityPoolIdentityId;
        const storageCognitoIdentityId = localStorage.getItem(
          `CognitoIdentityId-${awsmobile.aws_cognito_identity_pool_id}`
        );
        if (
          storageCognitoIdentityId &&
          cognitoIdentityId !== storageCognitoIdentityId
        ) {
          return await aws.signOutFromWebsite(eventId);
        }
      } else {
        if (!userAndParticipation?.id) {
          const otherEventsParticipations = await loadCognitoUserItParticipations(
            awsUser.attributes.sub,
            eventId
          );
          if (otherEventsParticipations.length) {
            return await aws.signOutFromWebsite(eventId);
          }
        }
      }
    }

    window.dataLayer.push({
      event: 'user',
      event_id: eventId,
      event_name: appState.eventInfo.getValue()?.name,
      username: userAndParticipation?.participation?.username,
      name: userAndParticipation?.participation?.givenName,
      suname: userAndParticipation?.participation?.familyName,
      email: userAndParticipation?.participation?.email,
    });

    appState.user.next({
      awsUser: {
        ...awsUser,
        isBackofficeUser:
          awsUser?.groups.includes('admin') ||
          awsUser?.groups.includes('ticket') ||
          awsUser?.groups.includes('scientificCommittee'),
      },
      userAndParticipation,
    });
  }
  return awsUser;
};
export const onUpdateParticipationId = (id, callback) =>
  aws.standardAPI
    .graphql({
      query: onUpdateParticipationSubscription,
      variables: { id },
    })
    .subscribe({
      next: ({ value }) => callback(value.data.onUpdateParticipationId),
      error: (e) => {
        console.error('on-update-participation-id', e);
      },
    });

export const getEventConfigurationPageTitle = (menuItems, intl, key) => {
  return (
    menuItems?.find((i) => i.key === key)?.menuItemLabel ||
    Object.values(constants.EventGenericMenuItems)
      .find((menuItem) => menuItem.key === key)
      .label(intl)
  );
};
export const getEventConfigurationPageItem = (menuItems, intl, key) => {
  const item =
    menuItems?.find((i) => i.key === key) ||
    Object.values(constants.EventGenericMenuItems).find(
      (menuItem) => menuItem.key === key
    );
  const label =
    menuItems?.find((i) => i.key === key)?.menuItemLabel ||
    Object.values(constants.EventGenericMenuItems)
      .find((menuItem) => menuItem.key === key)
      .label(intl);

  return { ...item, label };
};

const getColorsByRange = (text) => {
  switch (text.trim().toLowerCase()) {
    case 'gold':
      // return '#FFDF00CC';
      return { colorBckgr: '#ffef8e', colorIcon: '#e9cc39' };
    case 'platinum':
      return { colorBckgr: '#e4e4ec', colorIcon: '#a7a6c5' };
    case 'silver':
      return { colorBckgr: '#eeeeee', colorIcon: '#d2d2d4' };
    case 'bronze':
      return { colorBckgr: '#dba757', colorIcon: '#b08d57' };
    case 'supporter':
      return { colorBckgr: '#ffffff', colorIcon: '#e6e6e6' };
    default:
      return { colorBckgr: 'transparent', colorIcon: 'transparent' };
  }
};

export const extractSponsorInfo = async ({ sponsorsList, eventId }) => {
  const normalizedSponsorList = sponsorsList.map(async (s) => {
    const s3FolderProfileImage = `events/${eventId}/sponsor/frontoffice/${s?.id}/profileImage/`;
    const profileImage = s?.profileImage?.id
      ? await aws.Storage.get(
          `${s3FolderProfileImage}${s?.profileImage?.id}${s?.profileImage?.extension}`
        )
      : null;
    return {
      id: s?.id,
      name: s?.name,
      category: s?.therapeuticArea,
      profileImage,
      price: s?.priceRange?.price || '',
      sponsorship: {
        name: s?.priceRange?.name || '',
        color: getColorsByRange(s?.priceRange ? s?.priceRange.name : ''),
      },
    };
  });

  const result = await Promise.all(normalizedSponsorList);

  return result;
};
