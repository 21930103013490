import { aws, appState } from '@aim/common';
const { API, standardAPI } = aws;

const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

const listAllEventParticipationsQuery = /* GraphQL */ `
  query GetEvent($id: ID!) {
    participantByEventId(
      participationEventId: $id
      filter: { isDeleted: { ne: true } }
      limit: 1000
    ) {
      items {
        id
        givenName
        familyName
        cluster
        isSpeaker
        sponsorStaff {
          id
          sponsor {
            id
            name
          }
        }
        userSettings {
          isPublic
        }
        userShowcase {
          id
          address
          birthDate
          contactEmail
          profileImage {
            id
            extension
            originalName
            size
          }
          nationality
          phone
          workJob
          companyName
          businessPhone
          description
          instagram
          facebook
          twitter
          linkedin
          city
          country
        }
      }
    }
  }
`;

export const listAllEventParticipations = (eventId) =>
  new Promise((resolve, reject) => {
    API.graphql({
      query: listAllEventParticipationsQuery,
      variables: {
        id: eventId,
      },
    })
      .then((response) =>
        resolve(response.data.participantByEventId?.items || [])
      )
      .catch((e) => {
        console.error('list-all-event-participations-error', e);
        reject();
      });
  });

export const listHundredEventParticipations = (eventId, useLoader = false) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    standardAPI
      .graphql({
        query: listhundredEventParticipationsQuery,
        variables: {
          id: eventId,
        },
      })
      .then((response) =>
        resolve(response.data.participantByEventId?.items || [])
      )
      .catch((e) => {
        console.error('list-all-event-participations-error', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const listAllEventParticipationsFieldValues = (
  eventId,
  useLoader = false
) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    API.graphql({
      query: listAllEventParticipationsFieldValuesQuery,
      variables: {
        id: eventId,
      },
    })
      .then((response) =>
        resolve(response.data.participantByEventId?.items || [])
      )
      .catch((e) => {
        console.error('list-all-event-participations-field-values-error', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });
