import React, { useState, useEffect, useCallback, MouseEvent } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { BaseObjectsEvent, FileEvent, HereNowResponse } from 'pubnub';
import pickerData from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { usePubNub } from 'pubnub-react';
import { sortBy } from 'lodash';
import {
  ChannelEntity,
  Chat,
  MessageEnvelope,
  useChannelMembers,
  usePresence,
  useUserMemberships,
} from '@pubnub/react-chat-components';
import { useIntl } from 'react-intl';

// import { actionCompleted, containsEmoji } from 'pubnub-demo-integration';

import { CreateChatModal } from './components/create-chat-modal';
import { ReportUserModal } from './components/report-user-modal';
import { PublicChannelsModal } from './components/public-channels-modal';
import { pubNubTimeToken, isPresentMember } from './components/pubnub-utils';
import AimChannelList from './components/aim-channel-list';
import GroupChat from './GroupChat';
import { appState, aws, constants, fileHelper } from '@aim/common';
import { CustomIntl } from '@aim/components';
import { listAllEventParticipations } from './chatGqlHelper';
import Layout from '../shared/layout/Layout';
import './group-chat.scss';

type ChannelType = ChannelEntity;

interface MembershipsEvent {
  channel: string;
  message: {
    event: 'set' | 'delete';
    type: 'uuid' | 'channel' | 'membership';
    data: { channel: { id: string } };
  };
  subscription: string | null;
  publisher?: string | undefined;
  timetoken: number;
}
interface UnreadMessages {
  [key: string]: number;
}

const defaultChannel = {
  id: 'default',
  name: 'No Active Chat',
  description: 'This is the default channel',
  custom: { profileUrl: '' },
} as Pick<ChannelType, 'id' | 'name' | 'description' | 'custom'>;

const getEventAgendaTags = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      agenda {
        id
        tags
      }
    }
  }
`;

const LoungeWrapperComponent = ({
  children,
}: {
  children: string | JSX.Element | JSX.Element[];
}): JSX.Element => {
  const { eventId } = useParams<{ eventId: string }>();
  const isLounge = useRouteMatch('/events/:eventId/lounge-area');
  const intl = CustomIntl(useIntl());
  console.log('isLounge', isLounge);

  /**
   * Component state related hooks
   * Those mostly store the current channel, modals and side panels being shown
   */
  //our props
  const [availableChannels, setAvailableChannels] = useState<ChannelType[]>([]);
  const [eventUsers, setEventUsers] = useState<any>([]);
  const [eventVisibleUsers, setEventVisibleUsers] = useState<any>([]);
  const [user, setUser] = useState<any>();
  //chat props
  const [theme] = useState<'light'>('light');
  const [currentChannel, setCurrentChannel] = useState(defaultChannel);
  const [showChannels, setShowChannels] = useState<boolean>(true);
  const [unreadMessages, setUnreadMessages] = useState<UnreadMessages>({});
  const [firstUnreadMessageLoaded, setFirstUnreadMessageLoaded] = useState(
    false
  );
  const [presenceChannel] = useState<string>(`presence__${eventId}`);

  /**
   * All data related to Users, Channels and Memberships is stored within PubNub Objects API
   * It can be easily accessed using React Chat Components hooks
   */
  const pubnub = usePubNub();
  const [joinedChannels, , refetchJoinedChannels] = useUserMemberships({
    include: {
      channelFields: true,
      customChannelFields: true,
      customFields: true,
    },
  });

  const [
    channelMembers,
    ,
    refetchChannelMemberships,
    totalChannelMembers,
  ] = useChannelMembers({
    channel: currentChannel.id,
    include: { customUUIDFields: true, customFields: true },
  });

  const [presenceData] = usePresence({
    channels: joinedChannels.length
      ? [presenceChannel, ...joinedChannels.map((c) => c.id)]
      : [presenceChannel, currentChannel.id],
  });

  useEffect(() => {
    const loadUnreadMessages = async () => {
      const response = await pubnub.messageCounts({
        channels: joinedChannels.map((c) => c.id),
        channelTimetokens: joinedChannels.map(
          (c) => (c.membership?.lastReadTimetoken || 0) as number
        ),
      });

      setUnreadMessages(response.channels);
      setFirstUnreadMessageLoaded(true);
    };

    if (!firstUnreadMessageLoaded && joinedChannels.length) {
      loadUnreadMessages();
    }
  }, [joinedChannels]);

  useEffect(() => {
    const totalCount = Object.entries(unreadMessages)
      .filter(([channel]) => channel !== currentChannel.id)
      .reduce((prev, [_, messages]) => (prev = prev + messages || 0), 0);

    appState.loungeUnreadMessages.next(totalCount);
  }, [unreadMessages]);

  useEffect(() => {
    const loadUnreadMessages = async () => {
      const response = await pubnub.messageCounts({
        channels: joinedChannels.map((c) => c.id),
        channelTimetokens: joinedChannels.map(
          (c) => (c.membership?.lastReadTimetoken || 0) as number
        ),
      });

      setUnreadMessages(response.channels);
      setFirstUnreadMessageLoaded(true);
    };

    if (!firstUnreadMessageLoaded && joinedChannels.length) {
      loadUnreadMessages();
    }
  }, [joinedChannels]);

  const refreshMemberships = useCallback(
    (event: MembershipsEvent) => {
      if (
        currentChannel.id === event.channel &&
        event.message.event === 'delete'
      ) {
        setCurrentChannel(defaultChannel);
      }
      if (
        event.channel === user.id &&
        event.message.event === 'set' &&
        !joinedChannels.find((jc) => jc.id === event.message.data.channel.id)
      ) {
        refetchJoinedChannels();
      } else if (event.channel === user.id) {
        //new membership event, i have to clean unread messages only in active channel,
        //in other channels i can't have memberships read event so i can skip check on current channel
        setUnreadMessages((unreadMessages) => ({
          ...unreadMessages,
          [event.message.data.channel.id]: 0,
        }));
      }
      if (event.channel === currentChannel.id) {
        refetchChannelMemberships();
      }
    },
    [currentChannel, refetchJoinedChannels, refetchChannelMemberships]
  );

  const mapAndSetParticipations = async (res: any[]) => {
    const nextRes = res.filter(
      (p) => !p.isDeleted && p.givenName && p.familyName
    );

    const nextUsers = nextRes.map((p) => {
      const cluster = Object.values(constants.Clusters).find(
        (x) => x.id === p.cluster
      );
      let typology = '';
      if (p.cluster === constants.Clusters.SponsorStaff.id) {
        typology = p.sponsorStaff?.sponsor?.name || '';
      } else if (p.isSpeaker) {
        typology = 'Speaker';
      } else {
        typology = cluster?.label?.(intl);
      }
      return {
        ...p,
        name: `${p.givenName.charAt(0).toUpperCase()}. ${
          p.familyName.charAt(0).toUpperCase() + p.familyName.slice(1)
        }`,
        nameToSearch: `${p.givenName} ${p.familyName}.`,
        custom: { title: typology },
        profileUrl:
          p.userShowcase?.profileImage &&
          fileHelper.getPublicFileLink({
            dirPath: `events/${eventId}/user/${p.id}/showcase/profileImage`,
            fileData: p.userShowcase?.profileImage,
            skipFileDataOnS3Link: true,
          }),
      };
    });

    setEventUsers(nextUsers);
    setEventVisibleUsers(
      nextUsers
      // .filter((p) => p.userSettings?.isPublic !== false)
    );
    setUser(
      nextUsers.find(
        (p) =>
          p.id ===
          appState.user.getValue().userAndParticipation.participation.id
      )
    );
  };

  const getAllParticipations = async () => {
    try {
      // const firstLoad = await listHundredEventParticipations(eventId);
      // mapAndSetParticipations(firstLoad);
      listAllEventParticipations(eventId).then(async (res) => {
        mapAndSetParticipations(res);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getAllAvailableChannels = async () => {
    const eventConfiguration = appState.eventConfiguration.getValue() as any;
    let profileUrl = '';
    if (eventConfiguration?.welcomePageCoverImage) {
      profileUrl =
        fileHelper.getPublicFileLink({
          dirPath: `events/${eventId}/configuration/welcomePageCoverImage`,
          fileData: eventConfiguration.welcomePageCoverImage,
        }) || '';
    }
    const response = await aws.API.graphql({
      query: getEventAgendaTags,
      variables: { id: eventId },
    });
    const mainEventChannel = ({
      id: 'space.' + eventId + '.general',
      name: '# General',
      custom: { profileUrl },
    } as unknown) as ChannelType;
    if (response?.data?.getEvent?.agenda?.tags) {
      const nextAvailableChannels = sortBy<ChannelType>(
        [
          mainEventChannel,
          ...response.data.getEvent.agenda.tags.map((tag: string) => ({
            id: `space.${eventId}.${tag.replace(/ /g, '_')}`,
            name: `# ${tag}`,
            custom: { profileUrl },
          })),
        ],
        ['name']
      );
      setAvailableChannels(nextAvailableChannels);
    } else {
      setAvailableChannels([mainEventChannel]);
    }
  };

  useEffect(() => {
    mapAndSetParticipations([
      appState.user.getValue()?.userAndParticipation?.participation,
    ]);
    getAllAvailableChannels();
    getAllParticipations();
  }, []);

  const switchChannel = async (
    newChannel: Pick<ChannelType, 'id' | 'name' | 'description'>,
    oldChannel: Pick<ChannelType, 'id' | 'name' | 'description'>
  ) => {
    const lastReadTimetoken = pubNubTimeToken();
    if (oldChannel.id !== defaultChannel.id) {
      await pubnub.objects.setMemberships({
        channels: [
          {
            id: oldChannel.id,
            custom: {
              lastReadTimetoken,
            },
          },
        ],
      });
    }
    if (newChannel.id !== defaultChannel.id) {
      await pubnub.objects.setMemberships({
        channels: [
          {
            id: newChannel.id,
            custom: {
              lastReadTimetoken,
            },
          },
        ],
      });
    }
    setCurrentChannel(newChannel);
    setShowChannels(false);
  };

  /**
   * Handling publish errors
   */
  const handleError = (e: any) => {
    if (
      (e.status?.operation === 'PNPublishOperation' &&
        e.status?.statusCode === 403) ||
      e.message.startsWith('Publish failed')
    ) {
      alert(
        'Your message was blocked. Perhaps you tried to use offensive language or send an image that contains nudity?'
      );
    }

    console.warn(e);
  };

  // useEffect(() => {
  //   if (currentChannel.id === 'default' && joinedChannels.length)
  //     setCurrentChannel(joinedChannels[0]);
  // }, [currentChannel, joinedChannels]);

  /**
   * Rendered markup is a mixture of PubNub Chat Components (Chat, ChannelList, MessageList,
   * MessageInput, MemberList) and some elements to display additional information and to handle
   * custom behaviors (channels modal, showing/hiding panels, responsive design)
   */
  return user ? (
    <>
      <div
        // style={{ ...(!isLounge ? { height: '100%' } : {}) }}
        // style={{ height: '100%' }}
        className={isLounge ? `app-moderated app-moderated--${theme}` : ''}
      >
        {/* Be sure to wrap Chat component in PubNubProvider from pubnub-react package.
        In this case it's done in the index.tsx file */}
        {/* Current uuid is passed to channels prop to subscribe and listen to User metadata changes */}
        <Chat
          theme={theme}
          users={eventUsers.length ? eventUsers : [user]}
          currentChannel={currentChannel.id}
          channels={[
            presenceChannel,
            ...joinedChannels.map((c) => c.id),
            user.id,
          ]}
          onError={handleError}
          onMembership={(e) => {
            refreshMemberships(e as MembershipsEvent);
          }}
          onMessage={(message: MessageEnvelope) => {
            if (message.channel !== currentChannel.id) {
              setUnreadMessages((unreadMessages) => ({
                ...unreadMessages,
                [message.channel || '']:
                  (unreadMessages?.[message.channel || ''] || 0) + 1,
              }));
            }
          }}
          onFile={(file: FileEvent) => {
            if (file.channel !== currentChannel.id) {
              setUnreadMessages((unreadMessages) => ({
                ...unreadMessages,
                [file.channel || '']: unreadMessages?.[file.channel || ''] + 1,
              }));
            }
          }}
        >
          {isLounge ? (
            <GroupChat
              {...{
                eventId,
                users: eventVisibleUsers,
                user,
                availableChannels,
                switchChannel,
                joinedChannels,
                channelMembers,
                totalChannelMembers,
                presenceData,
                unreadMessages,
                currentChannel,
                setCurrentChannel,
                showChannels,
                setShowChannels,
              }}
            />
          ) : (
            children
          )}
        </Chat>
      </div>
    </>
  ) : (
    <></>
  );
};

export default LoungeWrapperComponent;
