import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { sortBy } from 'lodash';

import { useIntl } from 'react-intl';
import { appState, constants, fileHelper } from '@aim/common';
import Grid from '@material-ui/core/Grid';

import {
  styled,
  CustomIntl,
  AimWallButtonCard,
  AimSnackbarSeverity,
  AimSnackbar,
  AimTypography,
  useAimMediaQuery,
} from '@aim/components';
import { translation } from './virtualBag/translation';

import { HomeContainer } from './home/style';
import { getVirtualBags } from './virtualBag/virtualBagGqlHelper';
import { getEventConfigurationPageTitle } from '../shared/utilities';
// import CustomHeaderPage from './CustomHeaderPage';
// import avatarVirtualBag from '../../assets/images/avatarVirtualBag.jpg';

const PageContainer = styled('div')({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const PageContent = styled('div')(({ isMobileOnly }) => ({
  // ...(!isMobileOnly && { width: 1280 }),
  width: isMobileOnly ? '95%' : '70%',
  minHeight: 'calc(100vh - 260px)',
}));

const VirtualBag = () => {
  const { eventId } = useParams();
  const intl = CustomIntl(useIntl());
  const i18n = translation.virtualBag(intl);
  const { isMobileOnly } = useAimMediaQuery();
  const [virtualBagsItems, setVirtualBagsItems] = useState([]);
  const { getS3Link, downloadFromS3 } = fileHelper;
  const s3Folder = `events/${eventId}/virtualBag`;
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [eventConfiguration] = useState(appState.eventConfiguration.getValue());
  const [user] = useState(appState.user.getValue());
  const [pageTitle, setPageTitle] = useState('');

  useEffect(() => {
    window.dataLayer.push({
      event: 'virtual-bag',
      event_id: eventId,
      event_name: appState.eventInfo.getValue()?.name,
      username: user?.userAndParticipation?.participation?.username,
      name: user?.userAndParticipation?.participation?.givenName,
      surname: user?.userAndParticipation?.participation?.familyName,
      email: user?.userAndParticipation?.participation?.email,
    });
  }, []);

  useEffect(() => {
    const menuItemLabel = getEventConfigurationPageTitle(
      eventConfiguration?.eventGenericMenuItems?.items || [],
      intl,
      constants.EventGenericMenuItems.VIRTUAL_BAG.key
    );
    setPageTitle(menuItemLabel);
  }, [eventConfiguration]);

  useEffect(() => {
    const getVirtualBagItems = async () => {
      const res = await getVirtualBags(eventId);
      const items = [];
      await Promise.all(
        res.items?.map(async (el) => {
          let convertedLink = '';
          if (el?.documentPreviewImage) {
            convertedLink = await getS3Link(
              s3Folder + '/previewImage',
              el.documentPreviewImage
            );
          }
          items.push({
            id: el.id,
            title: el.documentTitle,
            description: el.documentSubtitle,
            index: parseInt(el.documentPosition || 100),
            logoVirtualBag: convertedLink,
            file: el.documentFile,
          });
        })
      );
      const sorted = sortBy(items, 'index');
      setVirtualBagsItems(sorted);
    };

    getVirtualBagItems();
  }, []);

  const downloadFileFromS3 = async (file) => {
    if (file) {
      try {
        await downloadFromS3({
          dirPath: s3Folder + '/file',
          fileData: file,
        });
        window.dataLayer.push({
          event: 'virtual-bag-download-item',
          event_id: eventId,
          event_name: appState.eventInfo.getValue()?.name,
          virtual_bag_item_id: file?.id,
          virtual_bag_item_name: `${file?.originalName}${file?.extension}`,
          username: user?.userAndParticipation?.participation?.username,
          name: user?.userAndParticipation?.participation?.givenName,
          surname: user?.userAndParticipation?.participation?.familyName,
          email: user?.userAndParticipation?.participation?.email,
        });
      } catch (e) {
        console.error('Error: ', e);
        setSnackbar({
          isOpen: true,
          severity: AimSnackbarSeverity.error,
          message: i18n.snackbar.errors.general,
        });
      }
    }
  };
  // const downloadAllFilesFromS3 = async () => {
  //   const files = virtualBagsItems.map((el) => el.file);
  //   try {
  //     await downloadZipFromS3(files, s3Folder + '/file', 'virtual-bag.zip');
  //   } catch (e) {
  //     console.error('Error: ', e);
  //     setSnackbar({
  //       isOpen: true,
  //       severity: AimSnackbarSeverity.error,
  //       message: i18n.snackbar.errors.general,
  //     });
  //   }
  // };

  return (
    <>
      <HomeContainer>
        <PageContainer>
          <PageContent isMobileOnly={isMobileOnly}>
            <AimTypography variant="h1">{pageTitle}</AimTypography>
            <Grid container>
              {virtualBagsItems.map(
                ({ id, title, description, logoVirtualBag, file }) => {
                  return (
                    <Grid Item sm={12} md={6} key={id}>
                      <AimWallButtonCard
                        cardVariant={'grey'}
                        title={title}
                        description={description}
                        buttonDisabled={!file}
                        onButtonClick={() => downloadFileFromS3(file)}
                        textButton={i18n.page.buttons.download}
                        logoVirtualBag={logoVirtualBag}
                        cardStyle={{
                          width: isMobileOnly ? 'calc(100vw - 40px)' : '97%',
                        }}
                      />
                    </Grid>
                  );
                }
              )}
            </Grid>
            <AimSnackbar
              open={snackbar.isOpen}
              onClose={() => setSnackbar({ isOpen: false })}
              severity={snackbar.severity}
            >
              {snackbar.message}
            </AimSnackbar>
          </PageContent>
        </PageContainer>
      </HomeContainer>
    </>
  );
};

export default VirtualBag;
