import { aws, appState } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const getListByCategory = /* GraphQL */ `
  query getItemsQueryByEvent($id: ID!, $subCategoryId: ID!) {
    getEvent(id: $id) {
      id
      sponsorServiceBookingDuration
      otherStandServicesService {
        description
        isBankTransferEnabled
        isCreditCardEnabled
        id
        documents {
          items {
            createdAt
            extension
            id
            originalName
            size
            isMandatory
            updatedAt
          }
        }
        publicationDate
        name
      }
    }
    getItemSubcategory(id: $subCategoryId) {
      id
      name
      childItems {
        items {
          amountPerQuantityLabel
          code
          description
          id
          name
          netPrice
          note
          noteLabel
          isNoteEnabled
          isNoteRequired
          type
          vatRate
          cost
          availability
          physicalStandSpaceItems {
            items {
              sponsor {
                id
                name
              }
              id
              quantity
              amountPerQuantity
              buyOperation {
                id
                isDeleted
                isExpired
              }
              sponsorPackageItem {
                id
                quantity
                package {
                  buyOperations {
                    items {
                      id
                      isDeleted
                      isExpired
                    }
                  }
                }
              }
            }
          }
          image {
            id
            originalName
            size
            extension
          }
        }
      }
    }
  }
`;

export const createPhysicalStandSpaceItems = /* GraphQL */ `
  mutation CreatePhysicalStandSpaceItems(
    $input: CreatePhysicalStandSpaceItemsInput!
    $condition: ModelPhysicalStandSpaceItemsConditionInput
  ) {
    createPhysicalStandSpaceItems(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updatePhysicalStandSpaceItems = /* GraphQL */ `
  mutation UpdatePhysicalStandSpaceItems(
    $input: UpdatePhysicalStandSpaceItemsInput!
    $condition: ModelPhysicalStandSpaceItemsConditionInput
  ) {
    updatePhysicalStandSpaceItems(input: $input, condition: $condition) {
      id
    }
  }
`;

export function useOtherStandServiceItems() {
  const listBySubCategory = (id, subCategoryId, useLoader = true) =>
    new Promise((resolve, reject) => {
      showLoader();

      API.graphql({
        query: getListByCategory,
        variables: { id, subCategoryId },
      })
        .then((response) => {
          const subCategory = response.data.getItemSubcategory;
          resolve({
            items: subCategory.childItems.items,
            subCategory: { id: subCategory?.id, name: subCategory?.name },
            otherStandServicesService:
              response.data.getEvent?.otherStandServicesService,
          });
        })
        .catch((e) => {
          console.error('other-stand-service-items-list-by-category', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const create = (input, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: createPhysicalStandSpaceItems,
        variables: { input },
      })
        .then((response) =>
          resolve(response.data.createPhysicalStandSpaceItems)
        )
        .catch((e) => {
          console.error('physical-stand-items-create', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const update = (input, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: updatePhysicalStandSpaceItems,
        variables: { input },
      })
        .then((response) =>
          resolve(response.data.updatePhysicalStandSpaceItems)
        )
        .catch((e) => {
          console.error('physical-stand-items-update', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  return { listBySubCategory, create, update };
}
