export const translation = {
  payment: (intl) => ({
    page: {
      title: {
        cart: intl.formatMessage({
          description: 'cart title text',
          defaultMessage: 'Cart',
        }),
        checkout: intl.formatMessage({
          description: 'checkout title text',
          defaultMessage: 'Purchase',
        }),
      },
      back: {
        cart: {
          label: intl.formatMessage({
            description: 'back to payment options',
            defaultMessage: 'back',
          }),
          tooltip: intl.formatMessage({
            description: 'back to payment options',
            defaultMessage: 'Payment options',
          }),
        },
        checkout: {
          label: intl.formatMessage({
            description: 'back to payment',
            defaultMessage: 'back',
          }),
          tooltip: intl.formatMessage({
            description: 'back to payment',
            defaultMessage: 'Payment',
          }),
        },
      },
      // messages: {
      //   noBookings: {
      //     label: intl.formatMessage({
      //       description: 'no bookings message',
      //       defaultMessage: 'Cart is empty',
      //     }),
      //   },
      // },
      errors: {
        loadBookings: {
          label: intl.formatMessage({
            description: 'load bookings error',
            defaultMessage: 'Error loading bookings list',
          }),
        },
        product: {
          label: intl.formatMessage({
            description: 'product error',
            defaultMessage: 'Error during creating product',
          }),
        },
      },
    },
    checkoutTable: {
      buttons: {
        confirm: {
          label: intl.formatMessage({
            description: 'confirm checkout button',
            defaultMessage: 'Confirm',
          }),
        },
      },
      columns: {
        service: {
          label: intl.formatMessage({
            description: 'service column',
            defaultMessage: 'Service',
          }),
        },
        quantity: {
          label: intl.formatMessage({
            description: 'quantity column',
            defaultMessage: 'Quantity',
          }),
        },
        amount: {
          label: intl.formatMessage({
            description: 'amount column',
            defaultMessage: 'Amount (€)',
          }),
        },
      },
      summary: {
        netTotal: {
          label: intl.formatMessage({
            description: 'net total summary',
            defaultMessage: 'Net Total (€)',
          }),
        },
        vat: {
          label: intl.formatMessage({
            description: 'vat summary',
            defaultMessage: 'VAT',
          }),
        },
        total: {
          label: intl.formatMessage({
            description: 'net total summary',
            defaultMessage: 'Total (€)',
          }),
        },
      },
    },
    checkoutAlerts: {
      eventIsOver: {
        label: intl.formatMessage({
          description: "the event is over, you can't confirm the purchase",
          defaultMessage: "The event is over, you can't confirm the purchase",
        }),
      },
    },
  }),
  paymentOptions: (intl) => ({
    page: {
      back: {
        tooltip: intl.formatMessage({
          description: 'back button tooltip',
          defaultMessage: 'Go back',
        }),
        button: intl.formatMessage({
          description: 'back button tooltip',
          defaultMessage: 'back',
        }),
      },
      title: intl.formatMessage({
        description: 'page title',
        defaultMessage: 'Choose the payment method',
      }),
      bankTransfer: {
        label: intl.formatMessage({
          description: 'payemnt by bank transfer',
          defaultMessage: 'Bank Transfer',
        }),
      },
      creditCard: {
        label: intl.formatMessage({
          description: 'payment by credit card',
          defaultMessage: 'Credit Card',
        }),
      },
    },
  }),
  billingInformations: (intl) => ({
    actions: {
      continue: intl.formatMessage({
        description: 'send data ticket billing informations button',
        defaultMessage: 'continue',
      }),
      backButton: intl.formatMessage({
        description: 'back to event fee list',
        defaultMessage: 'Back',
      }),
    },
    page: {
      title: intl.formatMessage({
        description: 'resume buy ticket page title',
        defaultMessage: 'Resume',
      }),
      generalInfo: intl.formatMessage({
        description: 'general info ticket billing informations card title',
        defaultMessage: 'General info',
      }),
      billingInformations: intl.formatMessage({
        description:
          'billing informations ticket billing informations card title',
        defaultMessage: 'Billing Information',
      }),
      copyEvent: intl.formatMessage({
        description: 'Copy event ticket billing informations card title',
        defaultMessage: 'COPY FROM LAST EVENT',
      }),
      labels: {
        yes: intl.formatMessage({
          description: 'Yes label billing informations form',
          defaultMessage: 'Yes',
        }),
        no: intl.formatMessage({
          description: 'No label billing informations form',
          defaultMessage: 'No',
        }),
        company: intl.formatMessage({
          description: 'Company label billing informations form',
          defaultMessage: 'Company',
        }),
        individual: intl.formatMessage({
          description: 'Individual label billing informations form',
          defaultMessage: 'Individual',
        }),
        service: intl.formatMessage({
          description:
            'service label additional service billing informations table',
          defaultMessage: 'Service',
        }),
        ticketDescr: intl.formatMessage({
          description:
            'Includes the participation at the event: "Time and Frequency Standards", you will find the voucher in your personal area.',
          defaultMessage:
            'Includes the participation at the event: "Time and Frequency Standards", you will find the voucher in your personal area.',
        }),
        daysIncluded: intl.formatMessage({
          description:
            'daya included additionalservice billing informations table',
          defaultMessage: 'Days included',
        }),
        profile: intl.formatMessage({
          description: 'profile additionalservice billing informations table',
          defaultMessage: 'Profile',
        }),
        participationMode: intl.formatMessage({
          description:
            'participation mode additionalservice billing informations table',
          defaultMessage: 'Participation mode',
        }),
        purchasedBy: intl.formatMessage({
          description:
            'purchased by additionalservice billing informations table',
          defaultMessage: 'Purchased by',
        }),
        unitaryPrice: intl.formatMessage({
          description:
            'price without vat rate additionalservice billing informations table',
          defaultMessage: 'Price (without VAT rate)',
        }),
        vatRate: intl.formatMessage({
          description: 'vat  additionalservice billing informations table',
          defaultMessage: 'VAT',
        }),
        price: intl.formatMessage({
          description:
            'price vat included additionalservice billing informations table',
          defaultMessage: 'Price (VAT included)',
        }),

        address: intl.formatMessage({
          description: 'address ticket billing informations form',
          defaultMessage: 'Address',
        }),
        city: intl.formatMessage({
          description: 'city ticket billing informations form',
          defaultMessage: 'City',
        }),
        email: intl.formatMessage({
          description: 'email ticket billing informations form',
          defaultMessage: 'Email',
        }),
        pec: intl.formatMessage({
          description: 'pec ticket billing informations form',
          defaultMessage: 'Pec',
        }),
        phone: intl.formatMessage({
          description: 'phone ticket billing informations form',
          defaultMessage: 'Phone',
        }),
        name: intl.formatMessage({
          description: 'company name ticket billing informations form',
          defaultMessage: "Company's name (Individual name)",
        }),
        postalCode: intl.formatMessage({
          description: 'Postal code/Zip ticket billing informations form',
          defaultMessage: 'Postal code/Zip',
        }),
        regionProvinceState: intl.formatMessage({
          description: 'Region/Province/State ticket billing informations form',
          defaultMessage: 'Region/Province/State',
        }),
        region: intl.formatMessage({
          description: 'Region ticket billing informations form',
          defaultMessage: 'Region',
        }),
        province: intl.formatMessage({
          description: 'Province ticket billing informations form',
          defaultMessage: 'Province',
        }),
        country: intl.formatMessage({
          description: 'Country* ticket billing informations form',
          defaultMessage: 'Country',
        }),
        vatCode: intl.formatMessage({
          description: 'VAT Number ticket billing informations form',
          defaultMessage: 'VAT Number',
        }),
        taxCode: intl.formatMessage({
          description: 'Fiscal Code ticket billing informations form',
          defaultMessage: 'Fiscal code',
        }),
        reference: intl.formatMessage({
          description: ' ticket billing informations form',
          defaultMessage: 'Reference',
        }),
        invoiceToPublicAuthority: intl.formatMessage({
          description:
            'Invoice to public authority ticket billing informations form',
          defaultMessage: 'Invoice to public authority',
        }),
        invoiceTo: intl.formatMessage({
          description: 'Invoice to  ticket billing informations form',
          defaultMessage: 'Invoice to:',
        }),
        uniqueCode: intl.formatMessage({
          description: 'Unique Code ticket billing informations form',
          defaultMessage: 'Unique Code',
        }),
        ipaCode: intl.formatMessage({
          description: 'IPA code ticket billing informations form',
          defaultMessage: 'IPA code',
        }),
        isSplitPayment: intl.formatMessage({
          description: 'Split Payment? ticket billing informations form',
          defaultMessage: 'Split Payment?',
        }),
        isVatEvent: intl.formatMessage({
          description:
            'Is VAT Exent? (Art.10 DPR N. 633/72) ticket billing informations form',
          defaultMessage: 'Is VAT Exent? (Art.10 DPR N. 633/72)*',
        }),
      },
    },
    snackbar: {
      buyTicketSuccess: intl.formatMessage({
        description: 'ticket successfully purchased',
        defaultMessage: 'Ticket successfully purchased',
      }),
      gatewayNotExist: intl.formatMessage({
        description: 'gateway not exist',
        defaultMessage: 'Gateway not exist',
      }),
      paymentTypeNotExist: intl.formatMessage({
        description: 'payment Type Not Exist',
        defaultMessage: 'Payment Type Not Exist',
      }),
    },
    checkout: {
      errors: {
        error1: intl.formatMessage({
          description: 'order data request processing error',
          defaultMessage: 'Order data request processing error',
        }),
        error2: intl.formatMessage({
          description: 'order data processing error',
          defaultMessage: 'Order data processing error',
        }),
        error3: intl.formatMessage({
          description: 'order payment initialization error',
          defaultMessage: 'Order payment initialization error',
        }),
        error4: intl.formatMessage({
          description: 'order payment error',
          defaultMessage: 'Order payment error',
        }),
        paymentExist: intl.formatMessage({
          description: 'payment exist',
          defaultMessage: 'Payment exist',
        }),
      },
    },
  }),
};
