import React from 'react';

import { AimFilterLight } from '@aim/components';

const formControlStyle = { width: 'calc(100% - 15px)' };

// Initial State for filter
const initialState = {
  title: '',
  dateService: '',
};

// Function onFilter
const stateFiltersFns = {
  title: (_, value, item) => {
    return item.additionalServices.filter((s) =>
      s.title?.trim()?.toLowerCase().includes(value?.trim()?.toLowerCase())
    ).length;
  },
  dateService: (_, value, item) => {
    return item.additionalServices.filter((s) => s.dateService === value)
      .length;
  },
};

function AdditionalServicesFilter({ onFilterResult, rows, i18n }) {
  const model = [
    {
      name: 'title',
      label: i18n.title,
      type: 'AimTextField',
    },
    {
      name: 'dateService',
      label: i18n.dateService,
      type: 'AimDateTextField',
    },
  ];

  const onInnerFilterResult = (currentRows, filter) => {
    const nextRows =
      filter?.title || filter?.dateService
        ? rows
            .map((r) => ({
              ...r,
              additionalServices: r.additionalServices.filter(
                (s) =>
                  (!filter.title ||
                    s.title
                      ?.trim()
                      ?.toLowerCase()
                      .includes(filter?.title?.trim()?.toLowerCase())) &&
                  (!filter.dateService ||
                    console.log(
                      's.dateService, filter.dateService',
                      s.dateService,
                      filter.dateService
                    ) ||
                    s.dateService === filter.dateService)
              ),
            }))
            .filter((c) => c.additionalServices.length)
        : rows;
    onFilterResult(nextRows);
  };

  return (
    <AimFilterLight
      rows={rows}
      initialState={initialState}
      filtersFns={stateFiltersFns}
      model={model}
      onFilterResult={onInnerFilterResult}
      noPadding
      formControlStyle={formControlStyle}
    />
  );
}

export default AdditionalServicesFilter;
