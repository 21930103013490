export const useWallCards = (i18n, eventId, sponsorId) => [
  {
    id: 1,
    label: i18n.breakoutRoom,
    link: `/events/${eventId}/${sponsorId}/services-configuration/breakoutrooms`,
  },
  {
    id: 2,
    label: i18n.virtualStand,
    link: `/events/${eventId}/${sponsorId}/services-configuration/virtual-stands`,
  },
  // {
  //   id: 3,
  //   label: i18n.liveExhibition,
  //   link: `/events/${eventId}/${sponsorId}/services-configuration`, // TODO real link
  // },
  {
    id: 4,
    label: i18n.sponsorBanner,
    link: `/events/${eventId}/${sponsorId}/services-configuration`, // TODO real link
  },
  {
    id: 5,
    label: i18n.publicPage,
    link: `/events/${eventId}/sponsors/${sponsorId}`,
  },
  {
    id: 6,
    label: i18n.physicalStand,
    link: `/events/${eventId}/${sponsorId}/services-configuration/physical-stands`,
  },
  {
    id: 8,
    label: i18n.list,
    link: `/events/${eventId}/${sponsorId}/services-configuration/list`,
  },
  {
    id: 7,
    label: i18n.staff,
    link: `/events/${eventId}/${sponsorId}/services-configuration/staff`,
  },
];
