import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import useI18n from './i18n';
import { appState, aws } from '@aim/common';
import {
  CustomIntl,
  AimSnackbarSeverity,
  AimIconAndTextButton,
  theme,
  styled,
  AimSnackbar,
  AimTypography,
  useAimMediaQuery,
} from '@aim/components';
import Grid from '@material-ui/core/Grid';

import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useFormComponents } from '../shared/Form/FormComponents';
import FormButtons from './FormButtons';
import { useHistory } from 'react-router-dom';
import ArrowBack from '@material-ui/icons/ArrowBack';

const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

const HomeContainer = styled('div')({
  background: theme.colors.greyScale.white,
  flex: 1,
  overflowX: 'hidden',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '1280px',
});
const Container = styled('div')({
  background: '#F3F3F4',
  width: '100%',
});

const EditPassword = () => {
  const intl = CustomIntl(useIntl());
  const i18n = useI18n(intl);
  const { isMobile } = useAimMediaQuery();
  const history = useHistory();
  const navigateBack = history?.location?.state?.navigateBack;
  const [snackbar, setSnackbar] = useState({ isOpen: false });

  const validationSchema = Joi.object().keys({
    oldPassword: Joi.string().required().label('Old password is required'),
    password: Joi.string().required().label('Password is required'),
    confirmPassword: Joi.string()
      .valid(Joi.ref('password'))
      .required()
      .label('Passwords must match'),
  });

  const { control, getValues, handleSubmit, errors } = useForm({
    reValidateMode: 'onChange',
    mode: 'onChange',
    shouldUnregister: false,
    defaultValues: {},
    resolver: joiResolver(validationSchema),
  });

  const formControlStyle = { width: isMobile ? '100%' : 'calc(50% - 15px)' };
  const { renderTextInput } = useFormComponents({
    control: control,
    formControlStyle: formControlStyle,
    errors: errors,
    getValues: getValues,
    labels: i18n.labels,
  });

  const sendData = async (data) => {
    const user = await aws.Auth.currentAuthenticatedUser({ bypassCache: true });
    showLoader();
    aws.Auth.changePassword(user, data.oldPassword, data.password)
      .then(() => {
        hideLoader();
        history.push(navigateBack);
      })
      .catch((err) => {
        console.error(err);
        setSnackbar({
          isOpen: true,
          severity: AimSnackbarSeverity.error,
          message: err.message,
        });
        hideLoader();
      });
  };

  return (
    <HomeContainer>
      <form onSubmit={handleSubmit(sendData)}>
        <AimIconAndTextButton
          variant="text"
          style={{ padding: 0 }}
          text={''}
          onClick={() => history.push(navigateBack)}
        >
          <ArrowBack />
        </AimIconAndTextButton>

        <AimTypography variant="h1" style={{ marginLeft: '10px' }}>
          {i18n.page.title}
        </AimTypography>
        <Container>
          <Grid container style={{ padding: '20px 10px' }}>
            <Grid item xs={12}>
              {renderTextInput({
                variant: 'secondary',
                key: 'oldPassword',
                type: 'password',
              })}
            </Grid>
            <Grid item xs={12}>
              {renderTextInput({
                variant: 'secondary',
                key: 'password',
                type: 'password',
              })}
            </Grid>
            <Grid item xs={12}>
              {renderTextInput({
                variant: 'secondary',
                key: 'confirmPassword',
                type: 'password',
                placeholder: 'Repeat password',
              })}
            </Grid>
          </Grid>
        </Container>

        <FormButtons
          i18n={i18n}
          navigateBack={() => history.push(navigateBack)}
        />
      </form>
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </HomeContainer>
  );
};

export default EditPassword;
