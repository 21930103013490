import { aws, appState } from '@aim/common';
import { PromisePool } from '@supercharge/promise-pool';

const updateParticipationIsDeletedQuery = /* GraphQL */ `
  mutation UpdateParticipation($participationId: ID!, $isDeleted: Boolean) {
    updateParticipation(
      input: { id: $participationId, isDeleted: $isDeleted }
    ) {
      id
    }
  }
`;

const updateSponsorListMutation = /* GraphQL */ `
  mutation UpdateSponsorList(
    $input: UpdateSponsorListInput!
    $condition: ModelSponsorListConditionInput
  ) {
    updateSponsorList(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateParticipationMutation = /* GraphQL */ `
  mutation UpdateParticipation(
    $input: UpdateParticipationInput!
    $condition: ModelParticipationConditionInput
  ) {
    updateParticipation(input: $input, condition: $condition) {
      id
    }
  }
`;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

export const updateParticipationIsDeleted = (participationId) =>
  new Promise((resolve, reject) => {
    showLoader();
    aws.API.graphql({
      query: updateParticipationIsDeletedQuery,
      variables: { participationId, isDeleted: true },
    })
      .then((response) => {
        resolve(response.data.updateParticipation);
      })
      .catch((e) => {
        console.error('update-participation-error', e);
        reject();
      })
      .finally(() => hideLoader());
  });

const updateParticipation = (input) =>
  new Promise((resolve, reject) => {
    aws.API.graphql({
      query: updateParticipationMutation,
      variables: { input },
    })
      .then((response) => {
        resolve(response.data.updateParticipation);
      })
      .catch((e) => {
        console.error('update-participation-error', e);
        reject();
      });
  });

export const closeListGroup = (input, participationIds, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    aws.API.graphql({
      query: updateSponsorListMutation,
      // variables: { input: { id, closingDate: new Date() } },
      variables: { input },
    })
      //.then(({ data }) => resolve(data.updateParticipation))
      .then(async () =>
        resolve(
          await PromisePool.for(participationIds || [])
            .withConcurrency(30)
            .process(async (participationId) =>
              updateParticipation({
                id: participationId,
                isClusterClosed: 'true',
              })
            )
        )
      )
      .catch((e) => {
        console.error('close-list-group', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });
