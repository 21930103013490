import { aws, appState } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const getOtherServicesService = /* GraphQL */ `
  query getOtherStandServicesService($id: ID!) {
    getEvent(id: $id) {
      id
      otherStandServicesService {
        description
        isBankTransferEnabled
        isCreditCardEnabled
        id
        documents {
          items {
            createdAt
            extension
            id
            name: originalName
            size
            updatedAt
            isMandatory
          }
        }
        itemCategories {
          items {
            id
            name
            itemSubcategories {
              items {
                id
                childItems {
                  items {
                    id
                  }
                }
              }
            }
          }
        }
        publicationDate
        name
      }
    }
  }
`;

const createOtherStandServicesService = /* GraphQL */ `
  mutation CreateOtherStandServicesService(
    $input: CreateOtherStandServicesServiceInput!
  ) {
    createOtherStandServicesService(input: $input) {
      id
      isCreditCardEnabled
      isBankTransferEnabled
      event {
        id
      }
    }
  }
`;

const updateOtherService = /* GraphQL */ `
  mutation UpdateOtherService(
    $input: UpdateOtherStandServicesServiceInput!
    $condition: ModelOtherStandServicesServiceConditionInput
  ) {
    updateOtherStandServicesService(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
    }
  }
`;

export function useOtherServicesService() {
  const update = (input, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({ query: updateOtherService, variables: { input } })
        .then((response) => {
          resolve(response.data.updateOtherStandServicesService);
        })
        .catch((e) => {
          console.error('event-update-other-service', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const list = (id, useLoader = true) =>
    new Promise((resolve, reject) => {
      showLoader();

      API.graphql({ query: getOtherServicesService, variables: { id } })
        .then((response) => resolve(response.data.getEvent))
        .catch((e) => {
          console.error('event-other-services-list', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const create = (input, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: createOtherStandServicesService,
        variables: { input },
      })
        .then(async (response) => {
          const eventOtherStandServicesServiceId =
            response.data.createOtherStandServicesService.id;
          const id = response.data.createOtherStandServicesService.event.id;
          await API.graphql({
            query: updateEvent,
            variables: { input: { id, eventOtherStandServicesServiceId } },
          });
          resolve(response.data.createOtherStandServicesService);
        })
        .catch((e) => {
          console.error('event-create-other-services-room', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  return { list, update, create };
}
