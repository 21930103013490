import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useFieldArray, Controller } from 'react-hook-form';

import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import { appState } from '@aim/common';

import {
  AimTextField,
  AimIconButton,
  AimSelect,
  AimSelectMenuItem,
  AimCheckbox,
  AimList,
  AimListItem,
  AimTypography,
  StepDivider,
  Title,
  AimAutocompleteForm,
} from '@aim/components';
import { listParticipations } from '../../shared/participationGqlHelper';
import Grid from '@material-ui/core/Grid';

const AuthorRow = ({
  row,
  index,
  control,
  register,
  setValue,
  name,
  standardFields,
  affiliations,
  i18n,
  remove,
}) => {
  const formControlStyle = { width: 'calc(100% - 10px)' };
  const squareText = index > -1 ? `[${index}]` : '';
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
        <div style={{ flex: 1 }}>
          <Grid container>
            {standardFields.map((field, fieldIdx) =>
              field.options ? (
                <Grid item xs={3} sm={6} md={3}>
                  <AimTypography variant={`textBold`}>
                    {field.label}
                  </AimTypography>
                  <AimAutocompleteForm
                    control={control}
                    register={register}
                    setValue={setValue}
                    textfieldVariant="white"
                    placeholder={field?.label || ''}
                    options={JSON.parse(field.options) || []}
                    formControlStyle={formControlStyle}
                    name={`${name}${squareText}.fieldValues[${fieldIdx}].value`}
                  />
                </Grid>
              ) : (
                <Grid item xs={3} sm={6} md={3}>
                  <AimTypography variant={`textBold`}>
                    {field.label}
                  </AimTypography>
                  <Controller
                    key={fieldIdx}
                    render={(props) => (
                      <AimTextField
                        displayLabel={false}
                        // inputRef={register}
                        // placeholder="Category Name"
                        formControlStyle={formControlStyle}
                        textfieldVariant="white"
                        value={props.value}
                        onChange={(e) => props.onChange(e.target.value)}
                      />
                    )}
                    name={`${name}${squareText}.fieldValues[${fieldIdx}].value`}
                    control={control}
                    defaultValue={row?.fieldValues[fieldIdx]?.value}
                  />
                </Grid>
              )
            )}

            <Grid item xs={3} sm={6} md={3}>
              <AimTypography variant={`textBold`}>
                {i18n.wizard.affiliations}
              </AimTypography>
              <Controller
                render={(props) => (
                  <AimSelect
                    displayLabel={false}
                    selectVariant="white"
                    value={props.value || ''}
                    onChange={(e) => {
                      props.onChange(e.target.value);
                    }}
                    formControlStyle={formControlStyle}
                    disabled={
                      affiliations && affiliations.length > 0 ? false : true
                    }
                  >
                    {affiliations?.map((c) => (
                      <AimSelectMenuItem
                        key={c.id || c.nanoId}
                        value={c.id || c.nanoId}
                      >
                        {c.institution}
                      </AimSelectMenuItem>
                    ))}
                  </AimSelect>
                )}
                name={`${name}${squareText}.affiliation`}
                control={control}
                defaultValue={row?.affiliation}
              />
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            flex: 0.01,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <AimIconButton variant="redFill">
            <ClearIcon onClick={(e, row, idx) => remove(idx)} />
          </AimIconButton>
        </div>
      </div>
    </>
  );
};

const PresenterRow = ({
  row,
  index,
  control,
  register,
  setValue,
  errors,
  isOwnerAPresentingAuthor,
  name,
  standardFields,
  affiliations,
  participations,
  i18n,
  presenterParticipation,
  clearErrors,
  watch,
}) => {
  const formControlStyle = { width: 'calc(100% - 10px)' };
  const squareText = index > -1 ? `[${index}]` : '';

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={6} md={3}>
          <AimTypography variant={`textBold`}>
            {i18n.wizard.presenter}
          </AimTypography>
          <AimAutocompleteForm
            clearErrors={clearErrors}
            control={control}
            register={register}
            setValue={setValue}
            errors={errors}
            textfieldVariant="white"
            placeholder={'name, surname or email'}
            name={'presenterParticipation'}
            options={participations || []}
            formControlStyle={formControlStyle}
            disabled={isOwnerAPresentingAuthor}
            filterOptions={(options, state) =>
              options.filter((o) => o.email === state.inputValue)
            }
          />
        </Grid>
        {standardFields
          // .filter(
          //   (field) =>
          //     field.label !== 'Name' &&
          //     field.label !== 'Surname' &&
          //     field.label !== 'Email'
          // )
          .map((field, fieldIdx) =>
            field.options ? (
              <Grid item xs={3} sm={6} md={3}>
                <AimTypography variant={`textBold`}>
                  {field?.label}
                </AimTypography>
                <AimAutocompleteForm
                  control={control}
                  register={register}
                  setValue={setValue}
                  errors={errors}
                  textfieldVariant="white"
                  placeholder={field.label || ''}
                  name={`${name}${squareText}.fieldValues[${fieldIdx}].value`}
                  options={JSON.parse(field.options) || []}
                  formControlStyle={formControlStyle}
                />
              </Grid>
            ) : (
              <Grid item xs={3} sm={6} md={3}>
                <AimTypography variant={`textBold`}>
                  {field?.label}
                </AimTypography>
                <Controller
                  key={fieldIdx}
                  render={(props) => (
                    <AimTextField
                      displayLabel={false}
                      // inputRef={register}
                      // placeholder="Category Name"
                      // formControlStyle={{
                      //   ...formControlStyle,
                      //   display:
                      //     field.label === 'Name' ||
                      //     field.label === 'Surname' ||
                      //     field.label === 'Email'
                      //       ? 'none'
                      //       : '',
                      // }}
                      formControlStyle={formControlStyle}
                      textfieldVariant="white"
                      value={props.value}
                      onChange={(e) => props.onChange(e.target.value)}
                      disabled={
                        (field.label === 'Name' ||
                          field.label === 'Surname' ||
                          field.label === 'Email') &&
                        presenterParticipation
                          ? true
                          : false
                      }
                    />
                  )}
                  name={`${name}${squareText}.fieldValues[${fieldIdx}].value`}
                  control={control}
                  defaultValue={row?.fieldValues[fieldIdx]?.value}
                />
              </Grid>
            )
          )}
        <Grid item xs={3} sm={6} md={3}>
          <AimTypography variant={`textBold`}>
            {i18n.wizard.affiliations}
          </AimTypography>
          <Controller
            render={(props) => (
              <AimSelect
                displayLabel={false}
                selectVariant="white"
                value={props.value || ''}
                onChange={(e) => {
                  props.onChange(e.target.value);
                }}
                formControlStyle={formControlStyle}
                disabled={
                  affiliations && affiliations.length > 0 ? false : true
                }
              >
                {affiliations?.map((c) => (
                  <AimSelectMenuItem
                    key={c.id || c.nanoId}
                    value={c.id || c.nanoId}
                  >
                    {c.institution}
                  </AimSelectMenuItem>
                ))}
              </AimSelect>
            )}
            name={`${name}${squareText}.affiliation`}
            control={control}
            defaultValue={row?.affiliation}
          />
        </Grid>
      </Grid>
    </>
  );
};

const AuthorsAndPresenterStep = ({
  control,
  title,
  i18n,
  abstractService,
  affiliations,
  getValues,
  setValue,
  errors,
  watch,
  register,
  clearErrors,
}) => {
  const [isAutoCompleteOn, setIsAutoCompleteOn] = useState(false);
  const { eventId } = useParams();
  const [participations, setParticipations] = useState([]);
  const isOwnerAPresentingAuthor = watch('isOwnerAPresentingAuthor');
  const presenterParticipation = watch('presenterParticipation');

  const fetchParticipations = () => {
    listParticipations(eventId).then((items) =>
      setParticipations(
        items.map((p) => ({
          value: p.id,
          label: `${p.givenName} ${p.familyName} <${p.email}>`,
          givenName: p.givenName,
          familyName: p.familyName,
          email: p.email,
        }))
      )
    );
  };

  useEffect(() => {
    fetchParticipations();
  }, []);

  useEffect(() => {
    if (presenterParticipation) {
      updateParticipationPresenter(presenterParticipation);
    } else {
      updateParticipationPresenter(presenterParticipation, true);
    }
  }, [presenterParticipation]);

  const updateParticipationPresenter = (participation, clear = false) => {
    const nameFieldIdx = abstractService.presenterFields.findIndex(
      (field) => field.label === 'Name'
    );
    const surnameFieldIdx = abstractService.presenterFields.findIndex(
      (field) => field.label === 'Surname'
    );
    const emailFieldIdx = abstractService.presenterFields.findIndex(
      (field) => field.label === 'Email'
    );
    setValue(
      `presenter.fieldValues[${nameFieldIdx}].value`,
      clear ? '' : participation?.givenName || ''
    );
    setValue(
      `presenter.fieldValues[${surnameFieldIdx}].value`,
      clear ? '' : participation?.familyName || ''
    );
    setValue(
      `presenter.fieldValues[${emailFieldIdx}].value`,
      clear ? '' : participation?.email || ''
    );
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'authors',
    keyName: 'key',
  });

  return (
    <>
      <Title>{title}</Title>
      <StepDivider />
      <AimTypography variant="text" style={{ paddingLeft: 25 }}>
        <ul>
          <li>{i18n.wizard.subtitles.authorsAndPresenter.instructionOne}</li>
          <li>{i18n.wizard.subtitles.authorsAndPresenter.instructionTwo}</li>
          <li>{i18n.wizard.subtitles.authorsAndPresenter.instructionThree}</li>
          <li>{i18n.wizard.subtitles.authorsAndPresenter.instructionFour}</li>
        </ul>
      </AimTypography>
      <div
        style={{
          background: 'white',
          borderRadius: 4,
          flex: 1,
          paddingTop: 10,
          paddingBottom: 10,
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        {/* <PresenterHeaderRow
          standardFields={abstractService.presenterFields}
          i18n={i18n}
        /> */}
        <AimListItem rowvariant="grey">
          <PresenterRow
            {...{
              row: getValues('presenter'),
              control,
              name: 'presenter',
              register,
              setValue,
              errors,
              isOwnerAPresentingAuthor,
              standardFields: abstractService.presenterFields,
              affiliations,
              isAutoCompleteOn,
              participations,
              isRequired: true,
              i18n,
              presenterParticipation,
              clearErrors,
              watch,
            }}
          />
        </AimListItem>
        <Controller
          render={(props) => (
            <AimCheckbox
              onChange={(e) => {
                props.onChange(e.target.checked);
                setValue(
                  'presenterParticipation',
                  e.target.checked
                    ? participations.find(
                        (p) =>
                          p.value ===
                          appState.user.getValue()?.userAndParticipation
                            ?.participation?.id
                      )
                    : ''
                );
              }}
              checked={props.value}
              label={i18n.wizard.labels.checkboxAutorsAndPresenter}
              labelPlacementLeft
              style={{ marginLeft: 10 }}
            />
          )}
          name={`isOwnerAPresentingAuthor`}
          control={control}
          defaultValue={false}
        />
        <div style={{ marginTop: 20 }}>
          <AimTypography variant="textBold" style={{ flex: 1 }}>
            {i18n.wizard.authors}
          </AimTypography>
          <AimList
            rows={fields}
            rowKey="key"
            variant="grey"
            deletable={false}
            // HeaderRow={
            //   // <AuthorHeaderRow
            //   //   standardFields={abstractService.authorFields}
            //   //   i18n={i18n}
            //   // />
            // }
            childrenProps={{
              name: 'authors',
              control,
              standardFields: abstractService.authorFields,
              affiliations,
              register,
              setValue,
              errors,
              isAutoCompleteOn,
            }}
            noHeader
          >
            <AuthorRow {...{ i18n, remove }} />
          </AimList>
        </div>
        <AimIconButton
          variant="yellowFill"
          onClick={() =>
            append({
              fieldValues: abstractService.authorFields.map(
                (fieldDefinition) => ({
                  value: '',
                  fieldDefinition,
                })
              ),
            })
          }
        >
          <AddIcon />
        </AimIconButton>
      </div>
    </>
  );
};

export default AuthorsAndPresenterStep;
