import styled from 'styled-components';

export const MenuContainer = styled.div`
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
  padding-left: 1rem;
`;

export const MenuNav = styled.nav`
  display: flex;
  flex: 1;
  justify-content: center;
`;

export const LinkContainer = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  a {
    text-decoration: none;
    color: black;
    &.active-page {
      font-weight: 700;
    }
  }
`;
