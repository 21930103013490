const translation = {
  presenterAbstracts: (intl) => ({
    subtitles: {
      abstractsTerm: intl.formatMessage({
        description: 'submit your abstract',
        defaultMessage: 'You can submit your abstract up to',
      }),
      lateBreakersTerm: intl.formatMessage({
        description: 'late breackers',
        defaultMessage:
          'Late breackers: you can only submit your abstract as poster',
      }),
      deadline: intl.formatMessage({
        description: 'deadline',
        defaultMessage: 'deadline',
      }),
      subtitleMax1: intl.formatMessage({
        description: 'setup presentation',
        defaultMessage: 'You can send at least ',
      }),
      subtitleMax2: intl.formatMessage({
        description: 'setup presentation',
        defaultMessage: ' abstract',
      }),
      maxFile: intl.formatMessage({
        description: 'maxFile first string presentation',
        defaultMessage: 'You can upload a maximum of ',
      }),
      maxFileDocuments: intl.formatMessage({
        description: 'maxFile second string ',
        defaultMessage: ' documents per abstract',
      }),
      maxAbstracts: intl.formatMessage({
        description: 'maximum of abstracts',
        defaultMessage: 'You can submit up to a maximum of',
      }),
      maxSize: intl.formatMessage({
        description: 'max size presentation',
        defaultMessage: 'Each document must have a maximum size of',
      }),
    },
    aimTablePage: {
      header: {
        backButton: intl.formatMessage({
          description: 'back label',
          defaultMessage: 'Back',
        }),
        submitAbstract: {
          deadline: intl.formatMessage({
            description: 'submit your abstract',
            defaultMessage: 'You can submit your abstract up to',
          }),
        },
        title: intl.formatMessage({
          description: 'abstract as presenter title',
          defaultMessage: 'Abstract for which you are nominated as presenter',
        }),
        action: {
          add: {
            tooltip: intl.formatMessage({
              description: 'add abstract tooltip',
              defaultMessage: 'Add',
            }),
          },
        },
      },
      card: {
        empty: intl.formatMessage({
          description: 'there are no abstract',
          defaultMessage: 'There are no abstract. Add one',
        }),
        labels: {
          title: intl.formatMessage({
            description: 'title label',
            defaultMessage: 'Title',
          }),
          status: intl.formatMessage({
            description: 'status services label',
            defaultMessage: 'Status',
          }),
          proposedPresentationTypology: intl.formatMessage({
            description: 'proposed presentation typology label',
            defaultMessage: 'Proposed presentation typology',
          }),
          acceptedAs: intl.formatMessage({
            description: 'accepted as label',
            defaultMessage: 'Accepted as',
          }),
        },
        buttons: {
          prepareYourPresentation: intl.formatMessage({
            description: 'prepare your presentation label',
            defaultMessage: 'Prepare your presentation',
          }),
        },
        cardAction: {
          view: intl.formatMessage({
            description: 'view tooltip',
            defaultMessage: 'View',
          }),
        },
      },
      dialog: {},
      snackbar: {
        delete: {
          success: intl.formatMessage({
            description: 'snackbar delete message',
            defaultMessage: 'Delete ok',
          }),
          error: intl.formatMessage({
            description: 'snackbar delete message',
            defaultMessage: 'Some error',
          }),
        },
      },
    },
  }),
};

export default translation;
