import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';

import {
  AimSelect,
  AimSelectMenuItem,
  AimRichText,
  StepDivider,
  Title,
} from '@aim/components';

const formControlStyle = { width: 'calc(100% - 10px)' };

const InstructionsStep = ({
  i18n,
  abstractService,
  title,
  control,
  errors,
  variant = 'grey',
  instructionsElement,
}) => {
  if (!abstractService) return null;
  const { instructions, presentationTypologies } = abstractService;

  const presentationModes = presentationTypologies.items.reduce((res, curr) => {
    const alreadySeen = res.find((i) => i === curr.presentationMode);
    return alreadySeen ? res : [...res, curr.presentationMode];
  }, []);

  const instrutionsTexts = JSON.parse(instructions).blocks.map((b) => b.text);

  return (
    <>
      <Title>{title}</Title>
      <StepDivider />
      <Controller
        name="presentationMode"
        control={control}
        render={(props) => (
          <AimSelect
            selectVariant={variant}
            label={i18n.wizard.subtitles.instructions}
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
            formControlStyle={formControlStyle}
          >
            {presentationModes.map((pt) => (
              <AimSelectMenuItem key={pt} value={pt}>
                {pt}
              </AimSelectMenuItem>
            ))}
          </AimSelect>
        )}
      />
      {instrutionsTexts.some((text) => text.trim() !== '') && (
        <AimRichText
          readOnly={true}
          variant={variant}
          style={formControlStyle}
          value={JSON.parse(instructions)}
        />
      )}
    </>
  );
};

export default InstructionsStep;
