import commonFields from './CommonField';
import renderButton from './MailButton';

export const mailBrInvitation = () => {
  const data = {
    ...commonFields,
    LINK: '##ACCEPT_LINK##',
    LINK_TXT: '##ACCEPT_TXT##',
    MESSAGE: '##MESSAGE##',
    EMAIL_REGISTRATION_MESSAGE: '##EMAIL_REGISTRATION_MESSAGE##',
    LINK_REGISTRATION: '##LINK_REGISTRATION##',
    LINK_REGISTRATION_TXT: '##LINK_REGISTRATION_TXT##',
  };

  const body = `
<table
  class="body"
  cellspacing="0"
  width="600"
  style="
font-family: Roboto, Arial, sans-serif;
"
>
  <tr>
    <td style="padding-left: 50px;width: 100%; font-weight: bold; font-size: 24px">${
      data.INVITE_TITLE
    }</td>
  </tr>
  <tr>
    <td style="padding-left: 50px;width: 100%;">
    ${data.TIME_GREETING},
    </td>
  </tr>
  <tr>
    <td style="padding-left: 50px;width: 100%;">
    ${data.EMAIL_MESSAGE_1} "${data.SPONSOR_NAME}" ${data.EMAIL_MESSAGE_2} ${
    data.DATE
  }
    </td>
  </tr>
  <tr>
    <td style="padding-left: 50px;width: 100%;">
      ${data.MESSAGE}    
    </td>
  </tr>  
  <tr>
    <td style="padding-left: 50px;width: 100%;">
    ${renderButton(data.LINK_TXT, data.LINK)}      
    </td>
  </tr> 
  <tr>
    <td style="padding-left: 50px;width: 100%;">
    ${data.EMAIL_REGISTRATION_MESSAGE}
    </td>
  </tr>
  <tr>
    <td style="padding-left: 50px;width: 100%;">
    ${renderButton(data.LINK_REGISTRATION_TXT, data.LINK_REGISTRATION)}       
    </td>
  </tr>  
  <tr>
    <td style="padding-left: 50px;width: 100%;">
      ${data.GREATING}    
    </td>
  </tr>
  <tr>
    <td style="padding-left: 50px;width: 100%;">
      ${data.SIGN}   
    </td>
  </tr>
</table>`;

  return {
    body,
    data,
  };
};
