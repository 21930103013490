export const translation = {
  bookingData: (intl) => ({
    page: {
      title: intl.formatMessage({
        description: 'allotment booking data page title',
        defaultMessage: 'Enter booking data',
      }),
      back: intl.formatMessage({
        description: 'back',
        defaultMessage: 'back',
      }),
      hotelName: intl.formatMessage({
        description: 'hotel name label',
        defaultMessage: 'Hotel',
      }),
      checkIn: intl.formatMessage({
        description: 'check in label',
        defaultMessage: 'Check in',
      }),
      checkOut: intl.formatMessage({
        description: 'check out label',
        defaultMessage: 'Check out',
      }),
      roomsChoice: intl.formatMessage({
        description: 'rooms choice label',
        defaultMessage: 'Your choice',
      }),
      totalNetPrice: intl.formatMessage({
        description: 'total net price label',
        defaultMessage: 'Total net price',
      }),
      iva: intl.formatMessage({
        description: 'iva label',
        defaultMessage: 'IVA',
      }),
      totalPrice: intl.formatMessage({
        description: 'total price label',
        defaultMessage: 'Total',
      }),
    },
  }),
};
