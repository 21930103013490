import React from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom';

import './styles/main.scss';
import Routes from './routes';

const WebsiteApp = () => {
  return (
    <>
      <Router>
        <Route path="/events/:eventId" component={Routes} />
      </Router>
    </>
  );
};
export default WebsiteApp;
