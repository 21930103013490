import React from 'react';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import {
  AimTypography,
  AimTextField,
  AimSelect,
  AimSelectMenuItem,
  AimRichText,
  AimFileSelector,
  AimSwitch,
  CustomIntl,
} from '@aim/components';
import { useDataHelper } from '../dataHelper';
import useI18n from './i18n';

export const renderLabel = (label, style) => {
  return (
    <AimTypography variant="columnHeader" style={{ ...style }}>
      {label}
    </AimTypography>
  );
};

export const useFormComponents = ({
  formControlStyle,
  control,
  errors,
  getValues,
  labels,
}) => {
  const intl = CustomIntl(useIntl());
  const i18n = useI18n(intl);
  const { parseRichText } = useDataHelper();

  const renderErrorMessage = (message = null) => {
    return (
      !!message && <AimTypography variant="formError">{message}</AimTypography>
    );
  };

  const renderError = (key) => {
    return (
      !!errors[key] && (
        <AimTypography variant="formError">
          {`${errors[key]?.message} `}
        </AimTypography>
      )
    );
  };

  const renderTextInput = ({
    key,
    name = null,
    isRequired = false,
    defaultValue = '',
    isDisabled = false,
    customOnChange = null,
    placeholder = null,
    type = 'text',
    variant = 'grey',
  }) => {
    return (
      <>
        <Controller
          render={(props) => (
            <AimTextField
              key={key}
              disabled={isDisabled}
              onChange={(e) => {
                if (customOnChange) customOnChange(e.target.value);
                props.onChange(e.target.value);
              }}
              textfieldVariant={variant}
              type={type}
              name={name || key}
              value={props.value || defaultValue}
              label={labels[key]}
              formControlStyle={formControlStyle}
              placeholder={
                isDisabled
                  ? labels.empty || 'No data'
                  : placeholder || `${labels.placeholder} ${labels[key]}`
              }
            />
          )}
          rules={{
            required: { isRequired },
          }}
          defaultValue={defaultValue}
          name={name || key}
          control={control}
        />
        {renderError(key)}
      </>
    );
  };

  const renderSwitchInput = ({
    key,
    name = null,
    isRequired = false,
    defaultValue = false,
    isDisabled = false,
    customOnChange = null,
    valueLabels,
    valueLabelStyle,
    variant = 'primary',
  }) => {
    const vlabel = valueLabels || ['OFF', 'ON'];
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          ...formControlStyle,
        }}
      >
        {renderLabel(labels[key])}
        <Controller
          render={(props) => (
            <div
              style={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                ...valueLabelStyle,
              }}
            >
              <AimSwitch
                variant={variant}
                key={key}
                disabled={isDisabled}
                onChange={(e) => {
                  if (customOnChange) customOnChange(e.target.checked);
                  props.onChange(e.target.checked);
                }}
                name={name || key}
                checked={props.value || defaultValue}
              />
              <AimTypography variant="text">
                {vlabel[props.value ? 1 : 0]}
              </AimTypography>
            </div>
          )}
          rules={{
            required: { isRequired },
          }}
          defaultValue={defaultValue}
          name={name || key}
          control={control}
        />

        {renderError(key)}
      </div>
    );
  };

  const renderSelectInput = ({
    key,
    name = null,
    array,
    keyName = null,
    boldKeyName = null,
    isRequired = false,
    isDisabled = false,
    customOnChange = null,
    variant = 'primary',
    emptyLabel,
  }) => {
    const emptyArray = !array || array.length === 0;
    if (emptyArray)
      array = [
        {
          id: '',
          value: emptyLabel || labels.empty || 'No selectable options',
        },
      ];
    return (
      <>
        <Controller
          render={(props) => {
            return (
              <AimSelect
                selectVariant={variant}
                disabled={emptyArray || isDisabled}
                label={labels[key]}
                value={props.value}
                onChange={(e) => {
                  if (customOnChange) customOnChange(e.target.value);
                  props.onChange(e.target.value);
                }}
                formControlStyle={formControlStyle}
              >
                {array.map((item) => {
                  const style = item[boldKeyName]
                    ? { fontWeight: 'bold' }
                    : { marginLeft: 8 };

                  return (
                    <AimSelectMenuItem
                      key={item.id}
                      value={keyName ? item[keyName] : item.value}
                      style={{ ...(boldKeyName && style) }}
                    >
                      {item.value}
                    </AimSelectMenuItem>
                  );
                })}
              </AimSelect>
            );
          }}
          defaultValue={keyName ? array[0][keyName] : array[0].value}
          name={name || key}
          control={control}
        />
        {renderError(key)}
      </>
    );
  };

  const renderRichTextInput = ({
    key,
    isRequired = false,
    editorStyleOverride,
    variant = 'primary',
  }) => {
    return (
      <>
        <Controller
          render={(props) => {
            return (
              <AimRichText
                variant={variant}
                label={labels[key]}
                value={parseRichText(getValues(key))}
                formControlStyle={formControlStyle}
                onChange={(value) => {
                  props.onChange(value);
                }}
                editorStyleOverride={editorStyleOverride}
                defaultValue={null}
              />
            );
          }}
          rules={{
            required: { isRequired },
          }}
          name={key}
          control={control}
        />
        {renderError(key)}
      </>
    );
  };

  const renderFileSelectorInput = ({
    key,
    isRequired = false,
    onUpload,
    onRemove,
    onDownload,
    accept = '.jpeg,.jpg,.png',
    variant = 'grey',
  }) => {
    return (
      <>
        <Controller
          render={(props) => (
            <AimFileSelector
              variant={variant}
              label={labels[key]}
              selectFileLabel={`${i18n.fileSelector.clickText}, ${labels[key]}`}
              onUploadFile={(res) => {
                onUpload(res);
                props.onChange(res.parsed);
              }}
              onRemove={(file) => {
                onRemove({ parsed: props.value, file: file });
                props.onChange(null);
              }}
              onDownload={(fileData) => {
                onDownload(fileData);
              }}
              file={props.value}
              formControlStyle={{
                ...formControlStyle,
              }}
              dropzoneAdditionalProps={{ accept }}
            />
          )}
          rules={{
            required: { isRequired },
          }}
          name={key}
          control={control}
        />
        {renderError(key)}
      </>
    );
  };

  return {
    renderSelectInput,
    renderTextInput,
    renderFileSelectorInput,
    renderRichTextInput,
    renderSwitchInput,
    renderError,
    renderErrorMessage,
  };
};
