import React, { useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import {
  styled,
  theme,
  AimIconAndTextButton,
  AimTypography,
  CustomIntl,
  AimSnackbarSeverity,
  AimSnackbar,
} from '@aim/components';
import { constants, appState, utilities } from '@aim/common';
const { roundTo2Decimal, decodeDbNumber, vatCalc, safeNum } = utilities;

import useI18n from './i18n';

import { useWelcomePageModulesRedirects } from '../../hooks/useWelcomePageModulesRedirects';

const Container = styled('div')({
  background: theme.colors.greyScale.white,
  padding: 20,
});

const CartRow = styled('div')((props) => ({
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: props.border && `1px solid ${theme.colors.greyScale.grey2}`,
}));

const SubTotal = styled('div')({
  width: '25%',
  margin: '50px 0 20px auto',
});

const PaxCart = () => {
  const { getServiceRedirectLinkEvent } = useWelcomePageModulesRedirects();
  const intl = CustomIntl(useIntl());
  const i18n = useI18n(intl);
  const history = useHistory();
  const { eventId, profileFeeBracketId, feeType } = useParams();
  const [fee, setFee] = useState({});
  const [snackbar, setSnackbar] = useState({ isOpen: false });

  // ref
  const gatewayRef = useRef();

  console.log('gatewayRef.current ', gatewayRef.current);

  useEffect(() => {
    // const getCartData = async () => {
    //   const res = await getProfileFeeBracket(profileFeeBracketId, eventId);
    //   const requireVat =
    //     res.getProfileFeeBracket?.profileFeeConfiguration?.requireVat;
    //   console.log('require vat ', requireVat);
    //   const netPrice =
    //     feeType === constants.EventTypes.PHYSICAL.id
    //       ? safeNum(res.getProfileFeeBracket.priceOnSite)
    //       : safeNum(res.getProfileFeeBracket.priceOnAir);
    //   const vatRate = decodeDbNumber(
    //     res.getProfileFeeBracket.feeBracket?.feeBracketModel?.fee?.vatRate
    //   );
    //   const price = vatCalc(netPrice, vatRate);
    //   const totalVat = safeNum(roundTo2Decimal(price - netPrice));
    //   const data = {
    //     start: format(
    //       new Date(res.getProfileFeeBracket.feeBracket?.feeBracketModel?.start),
    //       'dd/MM/yyyy'
    //     ),
    //     label: res.getProfileFeeBracket.feeBracket?.feeDateRange?.label,
    //     netPrice,
    //     vatRate,
    //     totalVat,
    //     price,
    //   };
    //   setFee(data);
    // };

    // getCartData();
    checkoutResponse();
  }, []);

  // useEffect(() => {
  //   if (eventId) {
  //     const fetchEventGateway = async () => {
  //       const nextGateway = await getEventGateway({
  //         id: eventId,
  //         serviceId: constants.GatewayServices().REGISTRATION.id,
  //       });

  //       gatewayRef.current = nextGateway;
  //     };
  //     fetchEventGateway();
  //     console.log('ci sono');
  //   }
  // }, [eventId]);

  const checkoutResponse = () => {
    const isCheckoutSuccess = location.pathname.includes('checkout-success');
    const isCheckoutError = location.pathname.includes('checkout-error');
    const query = new URLSearchParams(location.search);
    const errorcode = query.get('errorcode');
    const order = query.get('merchantorderid');
    if (isCheckoutError && errorcode && errorcode > 0) {
      let messageError = '';
      switch (parseInt(errorcode)) {
        case 1: // query error in lambda
          messageError = `${i18n.checkout.errors.error1} ${order}`;
          break;
        case 2: // response error in lambda
          messageError = `${i18n.checkout.errors.error2} ${order}`;
          break;
        case 3: // error in lambda, init payment
          messageError = `${i18n.checkout.errors.error3} ${order}`;
          break;
        case 4: // duplicate order or other errors
          messageError = `${i18n.checkout.errors.error4} ${order}`;
          break;
        default:
          break;
      }
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: messageError,
      });
    } else if (isCheckoutSuccess) {
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.success,
        message: i18n.snackbar.buyTicketSuccess,
      });
      const link = getServiceRedirectLinkEvent('BuyTicket');
      if (link) {
        history.push(link);
      } else {
        history.push(
          `/events/${eventId}/tickets/${profileFeeBracketId}/payment-success`
        );
      }
    }
  };

  const purchaseTicket = () => {
    //al momento nel carrello posso acquistare solo un ticket per volta e senza servizi aggiuntivi extra, quindi prendo il fee price
    let amountToPay = 0;
    if (fee?.price) amountToPay = fee?.price;

    if (!amountToPay) {
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: 'is not a valid number',
      });
      return;
    }

    const user = appState.user.getValue();

    if (gatewayRef.current === undefined) {
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.snackbar.gatewayNotExist,
      });
      return;
    }

    if (gatewayRef.current.paymentType === undefined) {
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.snackbar.paymentTypeNotExist,
      });
      return;
    }

    console.log('gatewayRef.current ', gatewayRef.current);

    const state = {
      amount: amountToPay,
      gateway: gatewayRef.current,
      productId: profileFeeBracketId,
      serviceId: eventId, // profileFeeBracketId,
      serviceType: constants.ProductType.registration,
      isService: true,
      isClient: true,
      clientId: user.userAndParticipation.participation.id,
      // clientId: user.userAndParticipation.id,
    };

    if (gatewayRef.current.paymentType === 'both') {
      history.push({
        pathname: `/events/${eventId}/tickets/cart/${profileFeeBracketId}/${feeType}/payment-opt`,
        state,
      });
    } else {
      history.push({
        pathname: `/events/${eventId}/tickets/cart/${profileFeeBracketId}/${feeType}/payment`,
        state,
      });
    }
  };

  return (
    <>
      <div style={{ flex: 1, minWidth: 'calc(100vw - 120px)' }}>
        <AimIconAndTextButton
          isUpperCase
          variant="none"
          text={i18n.actions.backButton}
          style={{
            padding: 0,
          }}
          onClick={() => history.replace(`/events/${eventId}/landing`)}
        >
          <ArrowBack />
        </AimIconAndTextButton>
        <AimTypography variant="h1">{i18n.page.cart}</AimTypography>
        <Container>
          <CartRow>
            <AimTypography variant="h5">{i18n.page.service}</AimTypography>
            <AimTypography variant="h5">{i18n.page.priceLabel}</AimTypography>
          </CartRow>
          <AimTypography>{i18n.page.ticket}</AimTypography>
          <CartRow border>
            <AimTypography variant="text">{`${fee?.label} - ${fee?.start} 
          `}</AimTypography>
            <AimTypography variant="text">
              {fee?.netPrice?.toFixed(2)}
            </AimTypography>
          </CartRow>
          <AimTypography>{i18n.page.additionalServices}</AimTypography>
          {fee?.additionalServices?.map((item, key) => (
            <CartRow border key={`as-${key}`}>
              <AimTypography variant="text">{item?.label}</AimTypography>
              <AimTypography variant="text">
                {item?.netPrice?.toFixed(2)}
              </AimTypography>
            </CartRow>
          ))}

          <SubTotal>
            <CartRow border>
              <AimTypography variant="h5">{`${i18n.page.vat} (${fee?.vatRate}%)`}</AimTypography>
              <AimTypography variant="text">
                {fee?.totalVat?.toFixed(2)}
              </AimTypography>
            </CartRow>
            <CartRow border>
              <AimTypography variant="h5">{i18n.page.total}</AimTypography>
              <AimTypography variant="text">
                {fee?.price?.toFixed(2)}
              </AimTypography>
            </CartRow>
            <div
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'flex-end',
                marginTop: 10,
              }}
            >
              <AimIconAndTextButton
                isUpperCase
                variant="yellowFill"
                text={i18n.page.buy}
                onClick={() => purchaseTicket()}
              >
                <ShoppingCartIcon />
              </AimIconAndTextButton>
            </div>
          </SubTotal>
        </Container>
      </div>
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </>
  );
};

export default PaxCart;
