import { aws, appState } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const getEventQuery = /* GraphQL */ `
  query GetEvent($id: ID!, $grantType: String!) {
    getEvent(id: $id) {
      id
      type
      grant(filter: { isDeleted: { ne: true }, type: { eq: $grantType } }) {
        items {
          id
          type
          title
          closingDate
        }
      }
    }
  }
`;

export const getEvent = (id, grantType, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: getEventQuery,
      variables: { id, grantType },
    })
      .then((response) => resolve(response.data.getEvent))
      .catch((e) => {
        console.error('ERROR get event PaxAbstractsEdit', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });
