import React, { useState, useEffect } from 'react';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

import { useIntl } from 'react-intl';

import { VirtualStandCard } from './VirtualStandCard';

import { useDataHelper } from './../../shared/dataHelper';

import CircularProgress from '@material-ui/core/CircularProgress';

import { AimTypography, CustomIntl, theme } from '@aim/components';

import translation from './../../shared/translation';

const VirtualStandList = ({ virtualStandService, sponsor, onAddToCart }) => {
  //Hooks
  const {
    sortBy,
    isValidPurchase,
    searchSponsorHighestRankingVirtualStand,
  } = useDataHelper();
  const intl = CustomIntl(useIntl());
  const i18n = translation.virtualStandList(intl);

  //States
  const [
    highestRankingVirtualStand,
    setHighestRankingVirtualStand,
  ] = useState();
  const [virtualStands, setVirtualStands] = useState();

  //Effects
  useEffect(() => {
    if (sponsor) {
      const sponsorVirtualStands = sponsor?.sponsorVirtualStand?.items?.filter(
        (i) =>
          i?.buyOperation?.purchase &&
          isValidPurchase(i?.buyOperation?.purchase)
      );
      const vs =
        searchSponsorHighestRankingVirtualStand(sponsorVirtualStands)
          ?.virtualStand || {};
      setHighestRankingVirtualStand(vs);
    }
  }, [sponsor]);

  useEffect(() => {
    if (virtualStandService && highestRankingVirtualStand) {
      const nextVirtualStand = sortBy(
        virtualStandService?.virtualStands?.items?.filter((i) => i.isEnabled),
        'ranking'
      ).map((vs) => {
        const upgrade =
          vs.ranking > (highestRankingVirtualStand?.ranking || -1);
        const purchased = highestRankingVirtualStand?.ranking === vs.ranking;
        const clean = highestRankingVirtualStand?.ranking === undefined;
        const notPurchasable =
          vs.ranking < (highestRankingVirtualStand?.ranking || -1);
        return {
          ...vs,
          upgrade: upgrade,
          purchased: purchased,
          clean: clean,
          notPurchasable: notPurchasable,
        };
      });
      setVirtualStands(nextVirtualStand || []);
    }
  }, [virtualStandService, highestRankingVirtualStand]);

  const renderList = () => {
    return virtualStands.length ? (
      <>
        <Grid item xs={12}>
          <AimTypography variant={'h2'} margin={0}>
            {i18n.page.title.label}
          </AimTypography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <AimTypography variant={'h4Regular'} margin={0}>
            {i18n.page.subtitle.label}
          </AimTypography>
        </Grid>
        {virtualStands?.map((v) => {
          return (
            <Grid item xs={3} key={v.id}>
              <VirtualStandCard
                virtualStand={{ ...v, vatRate: virtualStandService?.vatRate }}
                onAddToCart={onAddToCart}
              />
            </Grid>
          );
        })}
      </>
    ) : (
      <AimTypography variant={'h4Regular'}>
        {i18n.page.noItemsInList.label}
      </AimTypography>
    );
  };

  return (
    <Grid
      container
      spacing={2}
      style={{ backgroundColor: 'white', marginTop: 20, padding: 20 }}
    >
      {virtualStands ? (
        renderList()
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '10%',
            width: '100%',
          }}
        >
          <CircularProgress style={{ color: theme.colors.primary.yellow }} />
        </div>
      )}
    </Grid>
  );
};

export { VirtualStandList };
