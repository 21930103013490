import React, { useState, useEffect, useMemo } from 'react';

import { useParams, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

import Tooltip from '@material-ui/core/Tooltip';
import ArrowBack from '@material-ui/icons/ArrowBack';

import translation from './shared/translation';
import { MainContainer, InnerContainer } from './shared/SponsorPagesContainers';
import { appState } from '@aim/common';
import { fileHelper } from '@aim/common';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import {
  AimIconAndTextButton,
  CustomIntl,
  AimTypography,
  AimDataGrid,
  AimIconButton,
} from '@aim/components';

import { listSponsorBillingDocs } from './shared/InvoicesGqlHelper';

const Invoices = () => {
  const { sponsorId, eventId } = useParams();
  const history = useHistory();
  const intl = CustomIntl(useIntl());
  const i18n = translation.sponsorInvoices(intl);
  const [billingDocs, setBillingDocs] = useState([]);
  const { downloadFromS3 } = fileHelper;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const res = await listSponsorBillingDocs(sponsorId);

    const mappedData = res.items.map((item) => {
      return {
        ...item,
        id: item.id,
        docNumber: item.docNumber,
        docType: item.billingFlow.docType,
        billingCustomer: item.billingCustomer,
        // totalAmount: formatter.format(item.amount / 100),
      };
    });
    setBillingDocs(mappedData);
  };

  const downloadFile = async (row) => {
    console.log('row', row);
    await downloadFromS3({
      dirPath: `events/${eventId}/billing/${row.billingFlow.flowNumber}`,
      fileData: row.document,
    });
  };

  const renderRowButtons = (row) => {
    return (
      <>
        {row.document ? (
          <Tooltip title="download">
            <AimIconButton
              variant="lightBlueFill"
              small={1}
              onClick={() => downloadFile(row)}
            >
              <VerticalAlignBottomIcon />
            </AimIconButton>
          </Tooltip>
        ) : null}
      </>
    );
  };

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const loadColumns = ({ labels }) => [
    {
      field: 'billingCustomer',
      headerName: labels.table.labels.billingCustomer,
      minWidth: isLargeScreen ? 250 : 150,
    },
    {
      field: 'docNumber',
      headerName: labels.table.labels.docNumber,
      minWidth: isLargeScreen ? 250 : 150,
    },
    {
      field: 'docType',
      headerName: labels.table.labels.docType,
      minWidth: isLargeScreen ? 250 : 150,
    },
    // {
    //   field: 'totalAmount',
    //   headerName: labels.table.labels.totalAmount,
    //   minWidth: isLargeScreen ? 250 : 150,
    // },
    {
      field: 'actions',
      headerName: labels.table.labels.actions,
      type: 'button',
      width: 100,
      pinnable: false,
      hideSortIcons: true,
      renderCell: ({ row }) => {
        return renderRowButtons(row);
      },
    },
  ];

  const columns = useMemo(
    () =>
      loadColumns({
        labels: i18n,
      }),
    [i18n]
  );

  return (
    <MainContainer>
      <InnerContainer>
        <div style={{ display: 'flex-root', marginTop: 20 }}>
          <Tooltip title={i18n.page.back.tooltip.label}>
            <AimIconAndTextButton
              variant="text"
              text={i18n.page.back.button.label}
              onClick={() => history.goBack()}
            >
              <ArrowBack />
            </AimIconAndTextButton>
          </Tooltip>
          <AimTypography variant="h1">{i18n.page.title.label}</AimTypography>
        </div>
        <div
          style={{
            flex: 1,
            marginBottom: 40,
            height: `calc(${
              appState?.mainContainerSize?.getValue?.()?.height
                ? `${appState?.mainContainerSize?.getValue?.()?.height}px`
                : '100vh'
            } - 152px)`,
          }}
        >
          <AimDataGrid
            rows={billingDocs}
            columns={columns}
            tableName={'invoices'}
            pinnedColumns={{ left: [], right: ['actions'] }}
          />
        </div>
      </InnerContainer>
    </MainContainer>
  );
};

export default Invoices;
