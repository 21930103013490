export const translation = {
  welcomePublicPage: (intl) => ({
    title: intl.formatMessage({
      description: 'welcome public page title',
      defaultMessage: 'Welcome letter',
    }),
    back: intl.formatMessage({
      description: 'welcome public page back button',
      defaultMessage: 'Go to my services',
    }),
    notFound: intl.formatMessage({
      description: 'welcome public page not found',
      defaultMessage: 'Welcome letter not found',
    }),
    loading: intl.formatMessage({
      description: 'welcome public page loading',
      defaultMessage: 'Welcome letter loading...',
    }),
  }),
};
