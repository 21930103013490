import { fileHelper, constants, appState } from '@aim/common';
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

const renderRectangle = ({ data, title, id }) => {
  return (
    <rect
      width={Math.abs(data.width)}
      x={Math.abs(data.x)}
      height={Math.abs(data.height)}
      y={Math.abs(data.height < 0 ? data.y + data.height : data.y)}
      id={id}
      opacity="0"
    >
      <title>{title}</title>
    </rect>
  );
};
const renderCircle = ({ data, title, id }) => {
  const r = Math.min(Math.abs(data.width), Math.abs(data.height)) / 2;
  const cx = data.x + data.width / 2;
  const cy = data.y + data.height / 2;
  return (
    <circle
      cx={cx}
      cy={cy}
      r={r}
      x={Math.abs(data.x)}
      height={Math.abs(data.height)}
      y={Math.abs(data.height < 0 ? data.y + data.height : data.y)}
      id={id}
      opacity="0"
    >
      <title>{title}</title>
    </circle>
  );
};
const renderEllipsis = ({ data, title, id }) => {
  const rx = Math.abs(data.width) / 2;
  const ry = Math.abs(data.height) / 2;
  const cx = data.x + data.width / 2;
  const cy = data.y + data.height / 2;
  return (
    <ellipse rx={rx} ry={ry} cx={cx} cy={cy} id={id} opacity="0">
      <title>{title}</title>
    </ellipse>
  );
};
const renderPolygon = ({ data, title, id }) => {
  return (
    <polygon
      points={data.map((item) => `${item.x},${item.y}`).join(' ')}
      id={id}
      opacity="0"
    >
      <title>{title}</title>
    </polygon>
  );
};

const generateOnclickOrXhrefObj = ({ item, eventId, history }) => {
  if (item.hrefLink) {
    return { xlinkHref: item.hrefLink, target: '_blank' };
  } else {
    const { link, linkFn } = Object.values(
      constants.EventHomepageMapLinkTypes
    ).find((elem) => elem.id === item.link);
    const nextLink = linkFn
      ? linkFn(
          appState.user.getValue()?.userAndParticipation?.participation?.id
        )
      : link;
    const subLink = item.sponsor
      ? `/${item.sponsor}`
      : item.speaker
      ? `/${item.speaker}`
      : '';
    return {
      onClick: (e) =>
        e.preventDefault() ||
        history.push(`/events/${eventId}/${nextLink}${subLink}`),
    };
  }
};

const EventMapSvg = ({ input, s3Folder }) => {
  const { eventId } = useParams();
  const [fileData, setFileData] = useState();
  const history = useHistory();

  useEffect(() => {
    const loadImagelink = async () => {
      const fileLink = await fileHelper.getS3Link(s3Folder, input.image);
      const img = new Image();
      img.onload = function () {
        setFileData({
          url: fileLink,
          width: this.width > 1280 ? 1280 : this.width,
          height:
            this.width > 1280 ? (this.height / this.width) * 1280 : this.height,
        });
      };
      img.src = fileLink;
    };
    loadImagelink();
  }, [input]);

  return fileData ? (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox={`0 0 ${fileData.width} ${fileData.height}`}
    >
      <image
        width={fileData.width}
        height={fileData.height}
        xlinkHref={fileData.url}
      ></image>
      {input.model.components.map((item) => (
        <a
          key={item.id}
          style={{ cursor: 'pointer' }}
          {...generateOnclickOrXhrefObj({ item, eventId, history })}
        >
          {item.type === constants.EventHomepageMapShapes.RECT.id &&
            renderRectangle(item)}
          {item.type === constants.EventHomepageMapShapes.CIRCLE.id &&
            renderCircle(item)}
          {item.type === constants.EventHomepageMapShapes.ELLIPSE.id &&
            renderEllipsis(item)}
          {item.type === constants.EventHomepageMapShapes.POLYGON.id &&
            renderPolygon(item)}
        </a>
      ))}
    </svg>
  ) : null;
};

export default EventMapSvg;
