import React, { useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { appState } from '@aim/common';
import LoginLayout from './shared/LoginLayout';
import {
  AimIconAndTextButton,
  AimSnackbarSeverity,
  AimSnackbar,
  AimTextField,
  AimTypography,
  CustomIntl,
} from '@aim/components';
import { translations } from './shared/translations';

import { useIntl } from 'react-intl';

const formControlStyle = { width: 'calc(100% - 10px)' };

const ControlCodePage = (props) => {
  const intl = CustomIntl(useIntl());
  const i18n = translations.controlCode(intl);
  const { eventId } = useParams();
  const history = useHistory();
  const [controlCode, setControlCode] = useState();
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const { isLoader } = appState;

  const setIsWorking = (state) => {
    isLoader.next(state);
  };

  console.log('history.location', history);

  const submitCode = async (e) => {
    e.preventDefault();
    setIsWorking(true);
    if (appState.eventInfo.getValue().controlCodeCode === controlCode) {
      localStorage.setItem(`event-${eventId}-control-code`, controlCode);
      setIsWorking(false);

      history.push(
        history.location?.state?.pathname
          ? `${history.location?.state?.pathname}${history.location?.state.search}`
          : `/events/${eventId}/landing`
      );
    } else {
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.invalidCodeMessage,
      });
    }
    setIsWorking(false);
  };

  return (
    <LoginLayout title={i18n.pageTitle} subtitle={i18n.subtitle}>
      <form onSubmit={(e) => submitCode(e)}>
        <Grid container>
          <Grid item xs={12}>
            <AimTextField
              label={
                appState.eventInfo.getValue().controlCodeLabel || i18n.code
              }
              name="password"
              value={controlCode}
              placeholder={i18n.code}
              onChange={(e) => setControlCode(e.target.value)}
              formControlStyle={formControlStyle}
              type="password"
            />
          </Grid>
          <Grid item xs={12} style={{ display: 'flex' }}>
            <AimTypography
              margin={0}
              variant={'h4Regular'}
              style={{ margin: '8px 0 0 8px' }}
            >
              {i18n.newUser}
            </AimTypography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              margin: '20px 14px 0px',
            }}
          >
            <AimIconAndTextButton
              text={i18n.confirm}
              disabled={!appState.eventInfo.getValue().controlCodeCode}
              type="submit"
              isUpperCase
              style={{ width: '100%' }}
            ></AimIconAndTextButton>
          </Grid>
        </Grid>
      </form>
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </LoginLayout>
  );
};

export default ControlCodePage;
