import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import useI18n from './i18n';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { AimRichText, AimSnackbarSeverity, CustomIntl } from '@aim/components';
import { useLoaderHelper } from '../shared/loaderHelper';
import { useFormComponents } from '../shared/Form/FormComponents';
import { renderLabel } from '../shared/Form/FormComponents';

import { useUserShowcaseHelper } from '../shared/userShowcaseGqlHelper';

import Grid from '@material-ui/core/Grid';
import FormButtons from './FormButtons';
import { appState, constants, fileHelper } from '@aim/common';

const _defaultValue = {
  companyName: undefined,
  address: undefined,
  facebook: undefined,
  twitter: undefined,
  linkedin: undefined,
  instagram: undefined,
  givenName: undefined,
  familyName: undefined,
  phone: undefined,
  contactEmail: undefined,
  description: undefined,
  profileImage: undefined,
  coverImage: undefined,
  birthDate: undefined,
  nationality: undefined,
  workJob: undefined,
  businessPhone: undefined,
};

const headerReadOnly = ['givenName', 'familyName'];

const headerContact = [
  { key: 'birthDate', type: 'date' },
  { key: 'phone', type: 'tel' },
  { key: 'businessPhone', type: 'tel' },
  { key: 'contactEmail', type: 'email' },
  { key: 'nationality' },
  { key: 'companyName' },
  { key: 'workJob' },
  { key: 'address' },
  { key: 'linkedin', type: 'url' },
  { key: 'facebook', type: 'url' },
  { key: 'twitter', type: 'url' },
  { key: 'instagram', type: 'url' },
];

const PersonalDataEdit = ({
  fetchedData,
  fetchProfileInfo,
  eventId,
  participationId,
  setSnackbar,
  navigateBack,
  participation,
}) => {
  console.log('participation', participation);
  const intl = CustomIntl(useIntl());
  const i18n = useI18n(intl);
  const history = useHistory();
  const eventConfiguration = appState.eventConfiguration.getValue();
  const isDefaultCoverEnabled = (
    eventConfiguration?.defaultHeaderCoverImagePages || []
  ).includes(
    participation?.isSpeaker
      ? constants.WebsitePages.SPEAKER_PROFILE_PAGE.key
      : constants.WebsitePages.PARTICIPANT_PROFILE_PAGE.key
  );

  const { showLoader, hideLoader } = useLoaderHelper();
  const showcaseHelper = useUserShowcaseHelper();

  const [deleteList, setDeleteList] = useState({
    profileImage: null,
    coverImage: null,
  });

  const [createFileList, setCreateFileList] = useState({
    profileImage: null,
    coverImage: null,
  });

  const { control, handleSubmit, errors, getValues, reset } = useForm({
    reValidateMode: 'onChange',
    shouldUnregister: false,
    defaultValues: _defaultValue,
  });

  // const formControlStyle = { width: 'calc(100% - 15px)', margin: 8 };
  const formControlStyle = { width: '100%' };

  const { renderTextInput, renderFileSelectorInput } = useFormComponents({
    control: control,
    formControlStyle: formControlStyle,
    errors: errors,
    getValues: getValues,
    labels: i18n.labels,
  });

  useEffect(() => {
    if (fetchedData) {
      reset(fetchedData);
    }
  }, [fetchedData]);

  const sendData = async (submittedData) => {
    showLoader();
    try {
      const promises = [];
      //removing profileImage if needed
      if (deleteList.profileImage) {
        `events/${eventId}/user/${participationId}/showcase/`;

        promises.push(
          fileHelper.deleteFile({
            dirPath: `events/${eventId}/user/${participationId}/showcase/profileImage`,
            fileData: deleteList.profileImage,
            useLoader: false,
            skipFileDataOnS3Link: true,
          })
        );
        // deleteFile('profileImage', deleteList.profileImage));
      }
      // removing coverImage if needed
      if (deleteList.coverImage) {
        promises.push(
          fileHelper.deleteFile({
            dirPath: `events/${eventId}/user/${participationId}/showcase/coverImage`,
            fileData: deleteList.coverImage,
            useLoader: false,
            skipFileDataOnS3Link: true,
          })
        );
      }

      if (promises.length) {
        await Promise.all(promises);
      }

      //insert profileImage, coverImage
      if (submittedData.profileImage && !submittedData.profileImage.id) {
        submittedData.profileImage.id = await fileHelper.createFile({
          dirPath: `events/${eventId}/user/${participationId}/showcase/profileImage`,
          localFile: createFileList.profileImage.file,
          useLoader: false,
          isPublic: true,
          skipFileDataOnS3Link: true,
        });
      }
      if (submittedData.coverImage && !submittedData.coverImage.id) {
        submittedData.coverImage.id = await fileHelper.createFile({
          dirPath: `events/${eventId}/user/${participationId}/showcase/coverImage`,
          localFile: createFileList.coverImage.file,
          useLoader: false,
          isPublic: true,
          skipFileDataOnS3Link: true,
        });
      }

      const input = {
        ...(fetchedData.id && { id: fetchedData.id }),
        address: submittedData.address,
        facebook: submittedData.facebook,
        twitter: submittedData.twitter,
        linkedin: submittedData.linkedin,
        instagram: submittedData.instagram,
        phone: submittedData.phone,
        contactEmail: submittedData.contactEmail,
        ...(!!submittedData.description &&
          submittedData.description !== fetchedData.description && {
            description: JSON.stringify(submittedData.description),
          }),
        birthDate: submittedData.birthDate,
        nationality: submittedData.nationality,
        businessPhone: submittedData.businessPhone,
        companyName: submittedData.companyName,
        workJob: submittedData.workJob,
        userShowcaseCoverImageId: submittedData?.coverImage?.id,
        userShowcaseProfileImageId: submittedData?.profileImage?.id,
      };

      if (fetchedData.id) await showcaseHelper.update(input);
      else
        await showcaseHelper.create({
          participationId: participationId,
          ...input,
        });
      await fetchProfileInfo();
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.success,
        message: i18n.actions.saveSucces,
      });
      history.push(`/events/${eventId}/user/${participationId}`);
    } catch (e) {
      console.error(e);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.actions.saveFail,
      });
    }
    hideLoader();
  };

  return (
    <div>
      <form onSubmit={handleSubmit(sendData)}>
        <Grid container style={{ width: '100%' }}>
          {headerReadOnly.map((key) => (
            <Grid key={key} item xs={12} sm={6}>
              {renderTextInput({
                variant: 'secondary',
                key,
                isDisabled: true,
              })}
            </Grid>
          ))}
          <Grid item xs={12}>
            {renderFileSelectorInput({
              variant: 'white',
              key: 'profileImage',
              isRequired: true,
              onUpload: (data) => {
                setCreateFileList((pre) => ({
                  ...pre,
                  profileImage: data,
                }));
              },
              onRemove: (data) => {
                if (data.parsed.id)
                  setDeleteList((pre) => ({
                    ...pre,
                    profileImage: data.parsed,
                  }));
              },
              onDownload: (parsed) => {
                downloadFromS3('profileImage', parsed);
              },
            })}
          </Grid>
          <Grid
            item
            xs={12}
            style={isDefaultCoverEnabled ? { display: 'none' } : {}}
          >
            {renderFileSelectorInput({
              variant: 'white',
              key: 'coverImage',
              isRequired: true,
              onUpload: (data) => {
                setCreateFileList((pre) => ({
                  ...pre,
                  coverImage: data,
                }));
              },
              onRemove: (data) => {
                if (data.parsed.id)
                  setDeleteList((pre) => ({
                    ...pre,
                    coverImage: data.parsed,
                  }));
              },
              onDownload: (parsed) => {
                downloadFromS3('coverImage', parsed);
              },
            })}
          </Grid>
          <Grid item xs={12} style={{ marginRight: 20 }}>
            {fetchedData && (
              <Controller
                render={(props) => {
                  return (
                    <AimRichText
                      variant="white"
                      label={i18n.labels['description']}
                      value={
                        fetchedData.description
                          ? JSON.parse(fetchedData.description)
                          : ''
                      }
                      formControlStyle={formControlStyle}
                      onChange={(value) => {
                        props.onChange(value);
                      }}
                    />
                  );
                }}
                name={'description'}
                control={control}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <>
              {renderLabel(i18n.labels.infoAndContacts)}
              <Grid
                container
                style={
                  {
                    // margin: 8,
                    // width: isMobileOnly ? '100%' : 'calc(100% - 16px)',
                    // padding: 12,
                    // border: `15px solid ${theme.colors.greyScale.backgroundGrey}`,
                  }
                }
              >
                <>
                  {headerContact.map(({ key, type }) => (
                    <Grid key={key} item xs={12} sm={6}>
                      {renderTextInput({
                        variant: 'secondary',
                        key,
                        type,
                      })}
                    </Grid>
                  ))}
                </>
              </Grid>
            </>
          </Grid>
          <FormButtons i18n={i18n} navigateBack={navigateBack} />
        </Grid>
      </form>
    </div>
  );
};

PersonalDataEdit.propTypes = {
  setSnackbar: PropTypes.func.isRequired,
  fetchProfileInfo: PropTypes.func.isRequired,
  navigateBack: PropTypes.func.isRequired,
  eventId: PropTypes.string.isRequired,
  participationId: PropTypes.string.isRequired,
  fetchedData: PropTypes.exact({
    id: PropTypes.any,
    companyName: PropTypes.string,
    address: PropTypes.string,
    facebook: PropTypes.string,
    twitter: PropTypes.string,
    linkedin: PropTypes.string,
    instagram: PropTypes.string,
    givenName: PropTypes.string,
    familyName: PropTypes.string,
    phone: PropTypes.string,
    contactEmail: PropTypes.string,
    description: PropTypes.any,
    profileImage: PropTypes.object,
    coverImage: PropTypes.object,
    birthDate: PropTypes.string,
    nationality: PropTypes.string,
    workJob: PropTypes.string,
    businessPhone: PropTypes.string,
  }),
};

export default PersonalDataEdit;
