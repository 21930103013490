import React, { useState, useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import LoginLayout from './shared/LoginLayout';
import Grid from '@material-ui/core/Grid';
// import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  AimTextFieldForm,
  AimTypography,
  AimIconAndTextButton,
  AimSnackbarSeverity,
  AimSnackbar,
  CustomIntl,
} from '@aim/components';
import { translations } from './shared/translations';
import { useIntl } from 'react-intl';

import { appState, aws } from '@aim/common';

const formControlStyle = { width: 'calc(100% - 10px)' };

const ForgotPassword = () => {
  const intl = CustomIntl(useIntl());
  const i18n = translations.forgotPassword(intl);
  const { eventId } = useParams();
  const { search } = useLocation();
  const history = useHistory();
  const [isSteOneComplete, setIsSteOneComplete] = useState(false);
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const { handleSubmit, reset, errors, control } = useForm({
    reValidateMode: 'onChange',
    mode: 'onChange',
    shouldUnregister: false,
    defaultValues: {
      step: 1,
    },
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });

  const { isLoader } = appState;

  useEffect(() => {
    const params = new URLSearchParams(search);
    const username = params.get('username');
    const code = params.get('code');
    if (!code) return;
    else {
      setIsSteOneComplete(true);
      reset({
        step: 2,
        code,
        username,
      });
    }
  }, []);

  const setIsWorking = (state) => {
    isLoader.next(state);
  };

  const goToLogin = () => {
    history.push(`/events/${eventId}/login`);
  };

  const validationSchema = {
    username: yup.string().required(),
    code: yup.string().notRequired().when('step', {
      is: 2,
      then: yup.string().required(),
      otherwise: yup.string().notRequired(),
    }),
    newPassword: yup.string().notRequired().when('step', {
      is: 2,
      then: yup.string().required(),
      otherwise: yup.string().notRequired(),
    }),
  };

  const forgotPassword = async (data) => {
    setIsWorking(true);
    try {
      if (data.step === 1) {
        const resultForgot = await aws.Auth.forgotPassword(
          data.username.trim()
        );
        setSnackbar({
          isOpen: true,
          severity: AimSnackbarSeverity.success,
          message: `${i18n.codeSentMessage} ${resultForgot.CodeDeliveryDetails.Destination}`,
        });
        reset({
          username: data.username,
          step: 2,
        });
        setIsSteOneComplete(true);
      } else {
        await aws.Auth.forgotPasswordSubmit(
          data.username?.trim(),
          data.code,
          data.newPassword
        );
        window.dataLayer.push({
          event: 'forgot-password',
          event_id: eventId,
          username: data.username.trim(),
        });
        goToLogin();
      }
    } catch (err) {
      console.error(err);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: err.message,
      });
    }
    setIsWorking(false);
  };

  const backToLogin = () => {
    history.push(`/events/${eventId}/login`);
  };

  return (
    <LoginLayout title={i18n.pageTitle}>
      <AimTypography {...{ variant: 'text', style: { marginTop: -5 } }}>
        {i18n.pageSubtitle}
      </AimTypography>
      <form autoComplete="off" onSubmit={handleSubmit(forgotPassword)}>
        <Grid {...{ container: true, style: { marginTop: 20 } }}>
          <Grid
            item
            xs={12}
            style={{ display: isSteOneComplete ? 'none' : 'block' }}
          >
            <AimTextFieldForm
              control={control}
              textfieldVariant="grey"
              name="username"
              placeholder={i18n.username}
              formControlStyle={formControlStyle}
              errors={errors}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: !isSteOneComplete ? 'none' : 'block' }}
          >
            <AimTextFieldForm
              control={control}
              textfieldVariant="grey"
              name="code"
              placeholder={i18n.code}
              formControlStyle={formControlStyle}
              errors={errors}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: !isSteOneComplete ? 'none' : 'block' }}
          >
            <AimTextFieldForm
              control={control}
              textfieldVariant="grey"
              name="newPassword"
              type="password"
              placeholder={i18n.newPassword}
              formControlStyle={formControlStyle}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12}>
            <AimTypography>
              {i18n.backTo}
              <b style={{ cursor: 'pointer' }} onClick={backToLogin}>
                {` ${i18n.login} `}
              </b>
            </AimTypography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              margin: '20px 14px 0px',
            }}
          >
            <AimIconAndTextButton
              text={i18n.confirm}
              type="submit"
              isUpperCase
              style={{ width: '100%' }}
            ></AimIconAndTextButton>
          </Grid>
        </Grid>
      </form>
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </LoginLayout>
  );
};
export default ForgotPassword;
