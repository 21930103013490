const translation = {
  participationEdit: (intl) => ({
    actions: {
      save: intl.formatMessage({
        description: 'edit registration data page save data button',
        defaultMessage: 'Save',
      }),
      backButton: intl.formatMessage({
        description: 'back',
        defaultMessage: 'Back',
      }),
    },
    page: {
      title: intl.formatMessage({
        description: 'edit registration data page title ',
        defaultMessage: 'Edit registration data',
      }),
      subtitle: intl.formatMessage({
        description: 'edit registration data page subtitle',
        defaultMessage: 'Here you can edit your registration data',
      }),
    },
    labels: {
      profile: intl.formatMessage({
        description: 'profile',
        defaultMessage: 'Profile',
      }),
    },
  }),
};

export default translation;
