import { aws, appState } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const getFee = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      fee {
        id
        feeEventId
        vatRate
        currency
        isPublished
        feeDateRanges {
          items {
            id
            label
            start
            end
            feeBrackets {
              items {
                id
                feeDateRange {
                  id
                  label
                  start
                  end
                }
                feeBracketModel {
                  id
                  name
                  start
                  end
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const useFeeBrackets = () => {
  const list = (id, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: getFee,
        variables: { id },
      })
        .then((response) => {
          resolve(response.data.getEvent);
        })
        .catch((e) => {
          console.error('list-event-fee-brackets', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  return { list };
};
