import React, { useState, useEffect } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import { useIntl } from 'react-intl';
import translation from './../shared/translation';

import { addDays } from 'date-fns';

import { aws, appState, constants } from '@aim/common';

import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import ArrowBack from '@material-ui/icons/ArrowBack';

import SponsorArea from './SponsorBannerArea';
import {
  MainContainer,
  InnerContainer,
} from './../shared/SponsorPagesContainers';

import {
  getEventBannerService,
  updateBannerArea,
  onUpdateBannerArea,
  onDeleteBannerArea,
} from './../shared/sponsorBannerGqlHelper';
import { useBuyOperations } from './../shared/buyOperationsGqlHelper';

import {
  AimIconAndTextButton,
  AimTypography,
  AimSnackbarSeverity,
  AimSnackbar,
  AimRichText,
  AimFileList,
  CustomIntl,
  styled,
  theme,
} from '@aim/components';

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const Container = styled('div')({
  width: '100%',
  padding: 16,
  margin: 10,
});

const HeaderWrapper = styled('div')({
  margin: 8,
});

const SponsorBanner = () => {
  //Hooks
  const history = useHistory();
  const { eventId, sponsorId, service } = useParams();
  const { create: createBuyOperation } = useBuyOperations();
  const intl = CustomIntl(useIntl());
  const i18n = translation.sponsorBannerPurchase(intl);

  //State
  const [bannerService, setBannerService] = useState([]);
  const [areas, setAreas] = useState([]);
  // const [selectedAreas, setSelectedAreas] = useState([]);
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [bookingDuration, setBookingDuration] = useState();
  const [paymentType, setPaymentType] = useState();

  //Effects
  useEffect(() => {
    fetchSponsorBanner();
  }, []);

  // useEffect(() => {
  //   if (eventId) {
  //     const fetchEventGatewayByService = async () => {
  //       const gatewayResp = await getEventGatewayByService({
  //         id: eventId,
  //         : constants.GatewayServices()['SPONSOR_BANNER'].id,
  //       });
  //       setPaymentType(gatewayResp.paymentType);
  //     };
  //     fetchEventGatewayByService();
  //   }
  // }, [eventId]);

  // useEffect(() => {
  //   const subscriptionUpdate = onUpdateBannerArea(subscriptionCallback);
  //   const subscriptionDelete = onDeleteBannerArea(subscriptionCallback);
  //   return () => {
  //     subscriptionUpdate.unsubscribe();
  //     subscriptionDelete.unsubscribe();
  //   };
  // }, []);

  // Functions
  const fetchSponsorBanner = async () => {
    const result = await getEventBannerService(eventId);
    setBannerService(result.bannerService);
    setAreas(
      result.configuration.homepageSponsorBannerArea?.items.sort((a, b) =>
        a.name.localeCompare(b.name)
      ) || []
    );
    const paymentType =
      result.bannerService.isCreditCardEnabled &&
      result.bannerService.isBankTransferEnabled
        ? 'both'
        : (result.bannerService.isCreditCardEnabled &&
            constants.PaymentTypes.CreditCard) ||
          (result.bannerService.isBankTransferEnabled &&
            constants.PaymentTypes.BankTransfer);
    setPaymentType(paymentType);
    setBookingDuration(result.sponsorServiceBookingDuration || 10);
  };

  // const subscriptionCallback = (data) => {
  //   const areas = bannerService.bannerPages.items.flatMap(
  //     (bp) => bp.bannerAreas.items
  //   );
  //   if (areas.find((s) => s.id === data.id)) {
  //     fetchSponsorBanner();
  //   }
  // };

  const addToCart = async (areaId) => {};

  const onClickAddToCart = async (area, purchaseNow = false) => {
    showLoader();
    try {
      const bookingDate = new Date();
      const bookingExpiringDate = addDays(bookingDate, bookingDuration);
      const inputBuyOperation = {
        eventId: eventId,
        buyOperationBannerAreaId: area.id,
        buyOperationSponsorId: sponsorId,
        date: bookingDate,
        expiringDate: bookingExpiringDate,
        paymentType: paymentType,
        isExpired: 'false',
        isDeleted: 'false',
      };
      await createBuyOperation(inputBuyOperation, false);

      const destUrl = `/events/${eventId}/${sponsorId}/cart`;
      if (purchaseNow) {
        history.push({
          pathname: destUrl,
          state: {
            bookings: results.flat().filter((i) => i.bookingId),
          },
        });
      } else {
        await fetchSponsorBanner();
      }
    } catch (e) {
      console.error(e);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.snackbar.addToCartFail.label,
      });
    } finally {
      hideLoader();
    }
  };

  const downloadFile = async (item) => {
    const s3Folder = `events/${eventId}/sponsor/${service}/documents/`;
    const link = `${s3Folder}${item.id}${item.extension}`;

    var res = await aws.Storage.get(link);
    window.open(res);
  };

  const headerTitles = [
    i18n.headers.areas.label,
    i18n.headers.netPrice.label,
    i18n.headers.vatRate.label,
    i18n.headers.taxedPrice.label,
  ];

  return (
    <MainContainer>
      <InnerContainer grow bottomSpacing>
        <div style={{ display: 'flex-root', marginTop: 20 }}>
          <Tooltip title={i18n.page.back.tooltip.label}>
            <AimIconAndTextButton
              variant="text"
              text={i18n.page.back.button.label}
              onClick={() =>
                history.push(`/events/${eventId}/${sponsorId}/my-services`)
              }
            >
              <ArrowBack />
            </AimIconAndTextButton>
          </Tooltip>
        </div>
        <Grid container>
          <Grid item xs={12}>
            <AimTypography variant={'h1'}>
              {i18n.page.title.label}
            </AimTypography>
          </Grid>
          <Grid item xs={12}>
            {bannerService?.description && (
              <AimRichText
                label={i18n.page.instructions.label}
                value={JSON.parse(bannerService.description)}
                readOnly
                editorStyleOverride={{
                  minHeight: 'auto',
                  maxHeight: 'fit-content',
                  overflow: 'hidden !important',
                }}
              />
            )}
          </Grid>
        </Grid>

        {bannerService?.documents?.items?.length ? (
          <Grid container spacing={2} style={{ marginTop: 20 }}>
            <Grid
              item
              xs={12}
              style={{
                backgroundColor: 'white',
              }}
            >
              <AimFileList
                files={bannerService.documents.items}
                onDownload={downloadFile}
              />
            </Grid>
          </Grid>
        ) : null}

        <Grid
          container
          spacing={2}
          style={{ marginTop: 20, alignItems: 'center' }}
        >
          <Container>
            <Grid
              container
              style={{
                backgroundColor: theme.colors.greyScale.backgroundGrey,
              }}
            >
              {headerTitles.map((header, idx) => (
                <Grid item xs={idx === 0 ? 4 : 2} key={idx}>
                  <HeaderWrapper>
                    <AimTypography variant="columnHeader">
                      {header}
                    </AimTypography>
                  </HeaderWrapper>
                </Grid>
              ))}
            </Grid>
            <Grid container alignItems="flex-start">
              {areas.map((a) => (
                <SponsorArea
                  key={a.id}
                  paymentType={paymentType}
                  i18n={i18n}
                  vat={bannerService?.vatRate}
                  onClickAddToCart={onClickAddToCart}
                  area={a}
                />
              ))}
            </Grid>
          </Container>
        </Grid>

        <AimSnackbar
          open={snackbar.isOpen}
          onClose={() => setSnackbar({ isOpen: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </AimSnackbar>
      </InnerContainer>
    </MainContainer>
  );
};

export default SponsorBanner;
