import { aws, appState, constants } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const getSponsorStaffData = /* GraphQL */ `
  query GetSponsorStaffData($id: ID!, $eventId: ID!, $type: String!) {
    getSponsor(id: $id) {
      sponsorStaff {
        id
      }
      priceRange {
        freeStaffMembers
        id
      }
      event {
        sponsorServiceBookingDuration
        sponsorStaffService {
          description
          id
          isBankTransferEnabled
          isCreditCardEnabled
          publicationDate
          name
          vatRate
          documents {
            items {
              id
              name: originalName
              size
              extension
            }
          }
        }
      }
    }
    getDeadline(externalId: $eventId, type: $type) {
      value
      externalId
    }
  }
`;

const updateSponsorStaffServiceMutation = /* GraphQL */ `
  mutation UpdateSponsorStaffService(
    $input: UpdateSponsorStaffServiceInput!
    $condition: ModelSponsorStaffServiceConditionInput
  ) {
    updateSponsorStaffService(input: $input, condition: $condition) {
      id
    }
  }
`;

const createSponsorStaffPurchaseMutation = /* GraphQL */ `
  mutation CreateSponsorStaffPurchase(
    $input: CreateSponsorStaffPurchaseInput!
    $condition: ModelSponsorStaffPurchaseConditionInput
  ) {
    createSponsorStaffPurchase(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateSponsorStaffPurchaseMutation = /* GraphQL */ `
  mutation CreateSponsorStaffPurchase(
    $input: UpdateSponsorStaffPurchaseInput!
    $condition: ModelSponsorStaffPurchaseConditionInput
  ) {
    updateSponsorStaffPurchase(input: $input, condition: $condition) {
      id
    }
  }
`;

export const useSponsorStaffService = () => {
  const get = (input, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: getSponsorStaffData,
        variables: { ...input, type: constants.Clusters.SponsorStaff.id },
      })
        .then((response) => {
          return resolve({
            ...response.data.getSponsor.sponsorStaff,
            freeStaffMembers:
              response.data.getSponsor?.priceRange?.freeStaffMembers,
            deadlines: response.data?.getDeadline
              ? JSON.parse(response.data.getDeadline.value)
              : {},
            event: response.data.getSponsor.event,
          });
        })
        .catch((e) => {
          console.error('event-sponsor-staff-service', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const createSponsorStaffPurchase = (input, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: createSponsorStaffPurchaseMutation,
        variables: { input },
      })
        .then((response) => resolve(response.data.createSponsorStaffPurchase))
        .catch((e) => {
          console.error('create-sponsor-staff-purchase', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const updateSponsorStaffPurchase = (input, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: updateSponsorStaffPurchaseMutation,
        variables: { input },
      })
        .then((response) => resolve(response.data.updateSponsorStaffPurchase))
        .catch((e) => {
          console.error('update-sponsor-staff-purchase', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const update = (input, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: updateSponsorStaffServiceMutation,
        variables: { input },
      })
        .then((response) => resolve(response.data.updateSponsorStaff))
        .catch((e) => {
          console.error('update-sponsor-staff-service', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  return {
    get,
    update,
    createSponsorStaffPurchase,
    updateSponsorStaffPurchase,
  };
};
