import styled from 'styled-components';
export const TitleHome = styled.h1`
  font-size: 1.9375rem;
  font-weight: 300;
  text-align: center;
`;
export const SubTitleHome = styled.h4`
  text-align: center;
  font-size: 1.1rem;
  font-weight: 400;
`;
export const HomeContainer = styled.div`
  flex: 1;
  overflow-x: hidden;
  flex-direction: column;
  width: 100%;
`;
export const SectionContainer = styled.div`
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  flex: 1;
  @media (max-width: 600px) {
    min-height: 40vh;
    align-items: center;
  }
`;
export const SectionContainerAgenda = styled.div`
  min-height: 70vh;
  max-width: 100vw;
  margin: 0 auto;
  padding-bottom: 3rem;
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    margin-bottom: 5vh;
  }
`;

export const SectionContainerSpeakers = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  margin-left: 10vw;
`;

export const SectionContainerParticipation = styled.div`
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  margin-left: 10vw;
`;

export const SectionContainerGray = styled(SectionContainer)`
  background-color: var(--dirty-white);
  @media (max-width: 600px) {
    margin: 0;
  }
`;
export const BoxesContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  @media (max-width: 700px) {
    width: 95%;
  }
`;
export const SeeAllLinkSection = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem 0;
`;
export const SeeAllLink = styled.div`
  font-size: 1.1rem;
  font-weight: 700;
  a {
    color: var(--water-green);
  }
`;
export const BoxContainer = styled.div`
  width: 217px;
  height: 217px;
  position: relative;
  @media (max-width: 700px) {
    width: 100%;
    margin: 1rem 0;
  }
`;
export const MapImg = styled.img`
  margin: 0 auto;
`; //commented for linked image map
//width: 70%;
export const AgendaMobileSection = styled.div`
  overflow-x: auto;
`;
