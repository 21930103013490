import act1 from './Activity/assets/sessions.jpg';
import act3 from './Activity/assets/act3.png';
import act4 from './Activity/assets/act4.png';
import aimGroup from './Company/assets/aim-group.png';
import aimCommunication from './Company/assets/aim-communication.png';
import nautes from './Company/assets/nautes.png';
import tc from './Company/assets/tc.png';

export const activities = [
  {
    id: Math.random().toString(),
    title: 'Sessions',
    // subTitle: 'Attend live sessions',
    image: act1,
    // link: 'sessions',
    order: 0,
    activity: 'sessions',
  },
  {
    id: Math.random().toString(),
    title: 'On Demand',
    // subTitle: 'Webcast, e-posters and documenti',
    image: act3,
    // link: 'contents',
    order: 1,
    activity: 'contents',
  },
  // {
  //   title: 'Lounge Area',
  //   subTitle: 'Attend live sessions',
  //   img: act4,
  //   link: 'lounge-area',
  // },
];

export const sponsors = [
  {
    name: 'Aim Group',
    img: null,
    id: '635568e8-1fde-4832-8973-2bc4cc4e6df1',
  },
  {
    name: 'Aim Communication',
    img: aimCommunication,
    id: 'fa94acad-b5db-4dd5-a821-99b5e0f91608',
  },
  {
    name: 'Nautes',
    img: nautes,
    id: '99f7d916-370c-4f0f-b808-1125cda2ef1e',
  },
  {
    name: 'TC Group',
    img: tc,
    id: '6ce25daa-e545-4172-9ac6-b4dc0f5bfb32',
  },
];

export const news = [
  {
    data: '12 settembre 2020',
    title: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr sed',
    author: 'Nome Cognome',
  },
  {
    data: '12 settembre 2020',
    title: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr sed',
    author: 'Nome Cognome',
  },
  {
    data: '12 settembre 2020',
    title: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr sed',
    author: 'Nome Cognome',
  },
  {
    data: '12 settembre 2020',
    title: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr sed',
    author: 'Nome Cognome',
  },
];

export const speakers = [
  {
    img: 'https://picsum.photos/300/400',
    name: 'Nome Cognome',
    company: 'Nome Azienda',
  },
  {
    img: 'https://picsum.photos/300/400',
    name: 'Nome Cognome',
    company: 'Nome Azienda',
  },
  {
    img: 'https://picsum.photos/300/400',
    name: 'Nome Cognome',
    company: 'Nome Azienda',
  },
  {
    img: 'https://picsum.photos/300/400',
    name: 'Nome Cognome',
    company: 'Nome Azienda',
  },
  {
    img: 'https://picsum.photos/300/400',
    name: 'Nome Cognome',
    company: 'Nome Azienda',
  },
  {
    img: 'https://picsum.photos/300/400',
    name: 'Nome Cognome',
    company: 'Nome Azienda',
  },
  {
    img: 'https://picsum.photos/300/400',
    name: 'Nome Cognome',
    company: 'Nome Azienda',
  },
  {
    img: 'https://picsum.photos/300/400',
    name: 'Nome Cognome',
    company: 'Nome Azienda',
  },
];
