import { aws, appState } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const getOtherSponsorizationsService = /* GraphQL */ `
  query getOtherSponsorizationsService($id: ID!) {
    getEvent(id: $id) {
      id
      otherSponsorizationsService {
        description
        isBankTransferEnabled
        isCreditCardEnabled
        id
        documents {
          items {
            createdAt
            extension
            id
            originalName
            size
            isMandatory
            updatedAt
          }
        }
        itemCategories {
          items {
            id
            name
            itemSubcategories {
              items {
                id
                childItems {
                  items {
                    id
                  }
                }
              }
            }
          }
        }
        publicationDate
        name
      }
    }
  }
`;

const getOtherSponsorizationsServiceSubcategory = /* GraphQL */ `
  query getOtherSponsorizationsServiceSubcategory(
    $id: ID!
    $itemCategoryId: ID!
  ) {
    getEvent(id: $id) {
      id
      otherSponsorizationsService {
        id
        itemCategories(filter: { id: { eq: $itemCategoryId } }) {
          items {
            id
            name
            itemSubcategories {
              items {
                id
                name
                childItems {
                  items {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const updateOtherService = /* GraphQL */ `
  mutation UpdateOtherService(
    $input: UpdateOtherStandServicesServiceInput!
    $condition: ModelOtherStandServicesServiceConditionInput
  ) {
    updateOtherStandServicesService(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateOtherSponsorizationsService = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({ query: updateOtherService, variables: { input } })
      .then((response) => {
        resolve(response.data.updateOtherStandServicesService);
      })
      .catch((e) => {
        console.error('event-update-other-service', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const listOtherSponsorizationsService = (id, useLoader = true) =>
  new Promise((resolve, reject) => {
    showLoader();

    API.graphql({ query: getOtherSponsorizationsService, variables: { id } })
      .then((response) => resolve(response.data.getEvent))
      .catch((e) => {
        console.error('event-other-sponsorizations-list', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const listOtherSponsorizationsServiceSubcategory = (
  id,
  itemCategoryId,
  useLoader = true
) =>
  new Promise((resolve, reject) => {
    showLoader();

    API.graphql({
      query: getOtherSponsorizationsServiceSubcategory,
      variables: { id: id, itemCategoryId: itemCategoryId },
    })
      .then((response) =>
        resolve(
          response.data.getEvent?.otherSponsorizationsService.itemCategories
            ?.items[0]
        )
      )
      .catch((e) => {
        console.error('event-other-sponsorizations-list', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });
