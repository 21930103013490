import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import useI18n from './shared/i18n';
import useMailI18n from '../sponsorServiceConfigurationWall/shared/i18n';

import { useParams, useHistory } from 'react-router-dom';
import { appState } from '@aim/common';
import { format } from 'date-fns';

import { MainContainer } from '../sponsorServiceConfigurationWall/serviceList/BreakoutRoomList';

import {
  CustomIntl,
  AimIconAndTextButton,
  useLoaderHelper,
  AimTypography,
  AimSnackbarSeverity,
  AimSnackbar,
} from '@aim/components';

import { useBreakoutRoomSlots } from '../shared/breakoutRoomSlotGqlHelper';
import { useBreakoutRoomUtils } from '../../hooks/useBreakoutRoomUtils';
import BreakoutRoomRejectMotivationSelector from './component/BreakoutRoomRejectMotivationSelector';

import Tooltip from '@material-ui/core/Tooltip';

import ArrowBack from '@material-ui/icons/ArrowBack';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import GroupIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';
import { utcToZonedTime } from 'date-fns-tz';

const currentTimezone = appState.eventInfo.getValue().timezone;
const BreakoutRoomSlotDetail = () => {
  // Hooks
  const intl = CustomIntl(useIntl());
  const i18n = useI18n.breakoutRoomSlotDetail(intl);
  const history = useHistory();
  const { eventId, sponsorId, breakoutRoomSlotId } = useParams();
  const { showLoader, hideLoader } = useLoaderHelper();
  const breakoutRoomSlotHelper = useBreakoutRoomSlots(false);
  const {
    states,
    getHours,
    isJoinVisible,
    callLambda,
    emailTypes,
    generateBreakoutRoomEmail,
  } = useBreakoutRoomUtils();

  // States
  const [participation, setParticipation] = useState();
  const [currentSlotInfo, setCurrentSlotInfo] = useState();
  const [sponsor, setSponsor] = useState();
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [parsedSlot, setParsedSlot] = useState();
  const [rejectCollapsable, setRejectCollapsable] = useState(false);
  const [selectedResponse, setselectedResponse] = useState();

  // UseEffects
  useEffect(() => {
    if (participation) {
      fetchCurrentSlotInfo();
    } else {
      getUserParticipation();
    }
  }, [participation]);

  useEffect(() => {
    if (sponsor) parseSponsorInfo();
  }, [sponsor]);

  const getUserParticipation = async () => {
    try {
      const nextParticipation = appState.user.getValue()?.userAndParticipation
        ?.participation;
      setParticipation(nextParticipation);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchCurrentSlotInfo = async () => {
    showLoader();
    const res = await breakoutRoomSlotHelper.get(breakoutRoomSlotId);
    if (res) {
      const attendings = res.invitations.items;
      const isInList = attendings.find(
        (att) =>
          att.participation.id === participation.id &&
          att.state === states.ACCEPTED
      );
      if (isInList) {
        setCurrentSlotInfo(res);
        setSponsor(res?.breakoutRoom?.sponsor);
      } else {
        history.push(`/events/${eventId}/homepage`);
      }
    }
    hideLoader();
  };

  const parseSponsorInfo = () => {
    const actualAttending = currentSlotInfo.invitations.items.find(
      (attending) => attending.participation.id === participation.id
    );
    if (actualAttending) {
      const _parsedSlot = {
        name: sponsor?.name,
        date: `${format(
          utcToZonedTime(new Date(currentSlotInfo?.start), currentTimezone),
          'do MMMM yyyy'
        )},  ${getHours(
          utcToZonedTime(new Date(currentSlotInfo?.start), currentTimezone)
        )} - ${getHours(
          utcToZonedTime(new Date(currentSlotInfo?.end), currentTimezone)
        )}`,
        note: currentSlotInfo?.note,
        state:
          actualAttending.state === states.ACCEPTED ? 'accepted' : 'declined',
      };
      setParsedSlot(_parsedSlot);
      //setselectedResponse()
    } else {
      history.push(`/events/${eventId}/homepage`);
    }
  };

  const reject = async () => {
    try {
      showLoader();
      await callLambda({
        action: states.REJECTED,
        email: participation.email,
        slotId: breakoutRoomSlotId,
        eventId: eventId,
      });
      setselectedResponse(states.REJECTED);

      const emailData = {
        breakoutRoom: {
          date: currentSlotInfo.start,
          sponsor: sponsor,
        },
        slot: currentSlotInfo,
        eventId: eventId,
        i18n: useMailI18n.breakoutRoomMail(intl),
      };
      generateBreakoutRoomEmail(emailTypes.RESULT_REJECT, {
        ...emailData,
        email: participation.email,
      });
      setRejectCollapsable(!rejectCollapsable);
    } catch (e) {
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: e.message,
      });
      setselectedResponse(states.CANCELED);
    }
    hideLoader();
  };

  const goToBreakoutRoom = () => {
    history.push(
      `/events/${eventId}/breakoutroom/${sponsorId}/${breakoutRoomSlotId}`
    );
  };

  // Renders
  const renderHeader = () => {
    return (
      <>
        <div
          style={{
            margin: '20px auto',
            width: 1280,
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ width: '100%' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'row',
                width: '100%',
              }}
            >
              <Tooltip title={i18n.page.backButton.tooltip}>
                <AimIconAndTextButton
                  variant="none"
                  text={i18n.page.backButton.label}
                  onClick={() => history.push(`/events/${eventId}/homepage`)}
                >
                  <ArrowBack />
                </AimIconAndTextButton>
              </Tooltip>
              <AimIconAndTextButton
                variant="lightBlue"
                text={`${i18n.page.buttons.goToBreakoutRoom}`}
                disabled={
                  !isJoinVisible(currentSlotInfo) ||
                  selectedResponse === states.CANCELED ||
                  selectedResponse === states.REJECTED
                }
                onClick={() => {
                  goToBreakoutRoom();
                }}
              >
                <PlayArrowIcon />
              </AimIconAndTextButton>
            </div>
            <AimTypography variant="h1Regular">
              {i18n.page.title}: {parsedSlot?.name}
            </AimTypography>
            {parsedSlot && (
              <AimTypography variant="h3">{parsedSlot.date}</AimTypography>
            )}
          </div>
        </div>
      </>
    );
  };

  const renderGeneralInfo = () => {
    if (!parsedSlot) return;

    const hasAccepted = parsedSlot.state === states.ACCEPTED;

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {parsedSlot.note && (
          <div style={{ marginTop: 30 }}>
            <AimTypography variant="h4">{i18n.page.message}</AimTypography>
            <AimTypography variant="text">{parsedSlot.note}</AimTypography>
          </div>
        )}
        <div>
          {currentSlotInfo.title && (
            <AimTypography variant="h1" style={{ fontStyle: 'italic' }}>
              {currentSlotInfo.title}
            </AimTypography>
          )}
          {currentSlotInfo.isOneToOne ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginLeft: 8,
              }}
            >
              <PersonIcon style={{ color: '#84819A' }} />
              <AimTypography
                style={{
                  paddingTop: 2,
                }}
                variant="h6"
              >
                {i18n.page.oneToOne}
              </AimTypography>
            </div>
          ) : (
            <div
              style={{ display: 'flex', flexDirection: 'row', marginLeft: 8 }}
            >
              <GroupIcon style={{ color: '#84819A' }} />
              <AimTypography
                style={{
                  paddingTop: 2,
                }}
                variant="h6"
                margin={0}
              >
                {i18n.page.meeting}
              </AimTypography>
            </div>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            maxWidth: 200,
            marginTop: 30,
          }}
        >
          {parsedSlot.state && (
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: 'calc(100% + 45px)',
                }}
              >
                <AimIconAndTextButton
                  variant={'greenFill'}
                  text={i18n.page.buttons[hasAccepted ? 'accepted' : 'accept']}
                  disabled
                >
                  <CheckIcon />
                </AimIconAndTextButton>
                <AimIconAndTextButton
                  variant={hasAccepted ? 'redFill' : 'none'}
                  text={i18n.page.buttons[hasAccepted ? 'decline' : 'declined']}
                  disabled={
                    !hasAccepted ||
                    selectedResponse === states.REJECTED ||
                    selectedResponse === states.CANCELED
                  }
                  onClick={() => {
                    reject();
                  }}
                >
                  <ClearIcon />
                </AimIconAndTextButton>
              </div>
              {rejectCollapsable ? (
                <div
                  style={{
                    backgroundColor: 'white',
                    padding: 10,
                    borderRadius: 3,
                    marginTop: 10,
                    width: 'calc(100% + 45px)',
                  }}
                >
                  <BreakoutRoomRejectMotivationSelector
                    setSnackbar={setSnackbar}
                    eventId={eventId}
                    breakoutRoomSlotId={breakoutRoomSlotId}
                    email={participation.email}
                    onRejectComplete={() => {
                      setRejectCollapsable(false);
                      setselectedResponse(states.CANCELED);
                      setSnackbar({
                        isOpen: true,
                        severity: AimSnackbarSeverity.success,
                        message: i18n.page.snackbar.successRejected,
                      });
                    }}
                  />
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <MainContainer>
      {currentSlotInfo && renderHeader()}
      <div style={{ margin: '0 auto', width: 1280 }}>{renderGeneralInfo()}</div>
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </MainContainer>
  );
};

export default BreakoutRoomSlotDetail;
