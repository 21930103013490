import React, { useEffect } from 'react';
import { format, isAfter } from 'date-fns';
import { useForm } from 'react-hook-form';

import {
  AimIconAndTextButton,
  AimTextFieldForm,
  AimTypography,
  styled,
} from '@aim/components';

import Grid from '@material-ui/core/Grid';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const FilterBox = styled('div')({
  padding: '1%',
  backgroundColor: '#E8E6EC',
});

const addDays = (date, days) => {
  let newDate = new Date();
  const start = Date.parse(date);
  const daysToAdd = 86400000 * days;
  newDate = new Date(start + daysToAdd).toISOString();
  newDate = format(new Date(newDate), 'yyyy-MM-dd');
  return newDate;
};

const removeDays = (date, days) => {
  let newDate = new Date();
  const start = Date.parse(date);
  const daysToAdd = 86400000 * days;
  newDate = new Date(start - daysToAdd).toISOString();
  newDate = format(new Date(newDate), 'yyyy-MM-dd');
  return newDate;
};

const FilterRow = ({
  i18n,
  nightsDates,
  isMobileOnly,
  onSearchClick,
  useMobileFilter,
}) => {
  const { control, handleSubmit, getValues, watch, reset } = useForm({
    checkIn: '',
    checkOut: '',
    guestsNumber: 1,
    roomsNumber: 1,
  });

  const { startDate, endDate } = nightsDates;
  const checkInDate = watch('checkIn');

  useEffect(() => {
    let endDate = null;
    if (startDate) {
      endDate = addDays(startDate, 1);
    }
    reset({
      checkIn: startDate,
      checkOut: endDate ? endDate : '',
      guestsNumber: 1,
      roomsNumber: 1,
    });
  }, [nightsDates]);

  useEffect(() => {
    const checkOutDate = getValues('checkOut');
    if (checkInDate && isAfter(new Date(checkInDate), new Date(checkOutDate))) {
      const formData = getValues();
      const newCheckOutDate =
        checkInDate === endDate ? checkInDate : addDays(checkInDate, 1);
      reset({
        ...formData,
        checkOut: newCheckOutDate,
      });
    }
  }, [checkInDate]);

  return (
    <FilterBox
      style={{
        display: useMobileFilter ? 'none' : 'block',
      }}
    >
      <AimTypography variant="text">{i18n.filter.filterTitle}</AimTypography>
      <form onSubmit={handleSubmit(onSearchClick)}>
        <Grid
          container
          style={{ alignItems: 'flex-end' }}
          direction={isMobileOnly ? 'column' : 'row'}
          spacing={{ xs: 0, sm: 0, md: 0 }}
          columns={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid container item xs sm md>
            <AimTextFieldForm
              label={i18n.filter.checkIn}
              type="date"
              control={control}
              name="checkIn"
              //inputProps={{
              //  min: startDate,
              //  max: endDate ? removeDays(endDate, 1) : '',
              //}}
            />
          </Grid>
          <Grid container item xs sm md>
            <AimTextFieldForm
              label={i18n.filter.checkOut}
              type="date"
              control={control}
              name="checkOut"
              //inputProps={{
              //  min: watch('checkIn') ? addDays(watch('checkIn'), 1) : '',
              //  max: endDate,
              //}}
            />
          </Grid>
          <Grid container item xs sm md>
            <AimTextFieldForm
              label={i18n.filter.roomsNumber}
              type="number"
              inputProps={{
                min: '0',
              }}
              control={control}
              name="roomsNumber"
            />
          </Grid>
          <Grid container item xs sm md>
            <AimTextFieldForm
              label={i18n.filter.guestsNumber}
              type="number"
              inputProps={{
                min: '0',
              }}
              control={control}
              name="guestsNumber"
            />
          </Grid>
          <Grid
            container
            item
            xs
            sm
            md
            direction="column"
            alignItems="stretch"
            style={{ marginBottom: isMobileOnly ? '3%' : '1%' }}
          >
            <AimIconAndTextButton type="submit" text={i18n.filter.search}>
              <ArrowForwardIcon />
            </AimIconAndTextButton>
          </Grid>
        </Grid>
      </form>
    </FilterBox>
  );
};

export default FilterRow;
