export default {
  myAllotment: (intl) => ({
    page: {
      back: intl.formatMessage({
        description: 'Back my allotment page',
        defaultMessage: 'back',
      }),
      title: intl.formatMessage({
        description: 'Title my allotment page',
        defaultMessage: 'My services - Allotment',
      }),
      ticketTitle: intl.formatMessage({
        description: 'Title my allotment page',
        defaultMessage: 'My services - Allotment - Ticket',
      }),
    },
    purchaseTable: {
      header: {
        title: intl.formatMessage({
          description: 'Title purchase table - My Allotment',
          defaultMessage: 'Purchases',
        }),
        paymentId: intl.formatMessage({
          description: 'Payment ID label purchase table - My Allotment',
          defaultMessage: 'Order',
        }),
        hotelName: intl.formatMessage({
          description: 'Hotel name label purchase table - My Allotment',
          defaultMessage: 'Hotel',
        }),
        roomsNumber: intl.formatMessage({
          description: 'Rooms number label purchase table - My Allotment',
          defaultMessage: 'N° rooms',
        }),
        guestsNumber: intl.formatMessage({
          description: 'Guests number label purchase table - My Allotment',
          defaultMessage: 'N° guests',
        }),
        checkIn: intl.formatMessage({
          description: 'Check in label purchase table - My Allotment',
          defaultMessage: 'Check In',
        }),
        checkOut: intl.formatMessage({
          description: 'Check out label purchase table - My Allotment',
          defaultMessage: 'Check Out',
        }),
        amount: intl.formatMessage({
          description: 'Amount label purchase table - My Allotment',
          defaultMessage: 'Total amount (€)',
        }),
        paymentDate: intl.formatMessage({
          description: 'Payment date label purchase table - My Allotment',
          defaultMessage: 'Payment Date',
        }),
        paymentStatus: intl.formatMessage({
          description: 'Payment status label purchase table - My Allotment',
          defaultMessage: 'Payment Status',
        }),
      },
      detail: intl.formatMessage({
        description: 'Table detail button',
        defaultMessage: 'detail',
      }),
    },
  }),
};
