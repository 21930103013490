import React, { useState, useEffect } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import SponsorFrontServiceCard from './sponsor/components/SponsorFrontServiceCard';
import SelectableList from './sponsor/components/SelectableList';
import ServiceSummaryBuyCardContent from './sponsor/components/ServiceSummaryBuyCardContent';
import { appState } from '@aim/common';

import { usePriceRanges } from './shared/priceRangeGqlHelper';
import { useSponsors } from './shared/sponsorGqlHelper';

import CircularProgress from '@material-ui/core/CircularProgress';

import { MainContainer, InnerContainer } from './shared/SponsorPagesContainers';

import {
  AimIconAndTextButton,
  AimTypography,
  CustomIntl,
} from '@aim/components';

import translation from './shared/translation';
import { isValid, parse } from 'date-fns';

const selectedStyle = ({ currentEventConfiguration }) => {
  return {
    border: `1px solid ${
      currentEventConfiguration && currentEventConfiguration.primaryColor
        ? currentEventConfiguration.primaryColor
        : 'black'
    }`,
    borderRadius: 4,
  };
};

const PriceRangesList = () => {
  //Hooks
  const history = useHistory();
  const { eventId, sponsorId } = useParams();
  const intl = CustomIntl(useIntl());
  const i18n = translation.priceRangeList(intl);

  const { list } = usePriceRanges();
  const { update } = useSponsors();

  //States
  const [eventPriceRanges, setEventPriceRanges] = useState([]);
  const [selected, setSelected] = useState();
  const [participation, setParticipation] = useState();
  const [currentEventConfiguration, setCurrentEventConfiguration] = useState();
  const [currentPriceRange, setCurrentPriceRange] = useState(0);
  const [selectedPriceRange, setSelectedPriceRange] = useState(0);

  //Effects
  useEffect(() => {
    fetchSponsorPriceRanges();

    getParticipation();
  }, []);

  useEffect(() => {
    const eventConfiguration = appState.eventConfiguration.subscribe(
      (_eventConfiguration) => {
        setCurrentEventConfiguration(_eventConfiguration);
      }
    );

    return () => {
      eventConfiguration.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (participation) {
      setCurrentPriceRange(participation?.sponsor?.priceRange?.id);
      setSelected(participation?.sponsor?.priceRange?.id);
      setSelectedPriceRange(participation?.sponsor?.priceRange?.price || 0);
    }
  }, [participation]);

  //Functions
  const onClickBuy = () => {
    if (selected !== participation?.sponsor?.priceRange?.id) {
      //set price range to sponsor
      const input = {
        id: participation?.sponsor?.id,
        sponsorPriceRangeId: selected,
      };
      update(input);
    }

    history.push(`/events/${eventId}/${sponsorId}/my-services`);
  };

  const getParticipation = async () => {
    setParticipation(
      appState.user.getValue()?.userAndParticipation?.participation
    );
  };

  const fetchSponsorPriceRanges = async () => {
    try {
      const response = await list(eventId);

      const priceRangeUnsorted = response.sponsorPriceRanges.items;
      if (priceRangeUnsorted?.length === 0) {
        // issue reference #344
        history.push(`/events/${eventId}/${sponsorId}/my-services`);
      } else {
        const priceRangeSorted = priceRangeUnsorted
          .filter((pr) => isValid(parse(pr.start, 'yyyy-MM-dd', new Date())))
          .sort((a, b) => (a.price < b.price ? -1 : 1))
          .reverse();

        setEventPriceRanges(priceRangeSorted);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const selectedConditionBordered = (id) => id === selected?.id;

  const ItemList = ({ name, id, start, price, description }) => {
    return (
      <SponsorFrontServiceCard
        title={name}
        bordered={selectedConditionBordered(id)}
      >
        <ServiceSummaryBuyCardContent
          i18n={i18n}
          day={start}
          price={Number(price / 100).toFixed(2)}
          description={description}
        />
      </SponsorFrontServiceCard>
    );
  };

  return (
    <MainContainer>
      <InnerContainer grow bottomSpacing>
        {eventPriceRanges ? (
          <>
            <div
              style={{ display: 'flex-root', marginLeft: 15, marginTop: 20 }}
            >
              <AimTypography variant="h1" margin={0}>
                {i18n.page.subtitle.label}
              </AimTypography>
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
              <SelectableList
                // Component={ItemList}
                {...{
                  setSelected,
                  selected,
                  selectedStyle: selectedStyle({ currentEventConfiguration }),
                  Component: ItemList,
                  items: eventPriceRanges.filter(
                    (x) => x.price > selectedPriceRange
                  ),
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'flex-end',
              }}
            >
              <AimIconAndTextButton
                style={{
                  paddingLeft: 30,
                  paddingRight: 30,
                }}
                // variant="yellow"
                text={i18n.page.forwardButton.label}
                onClick={() => onClickBuy()}
                disabled={!selected || selected === currentPriceRange}
              />
            </div>
          </>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <CircularProgress />
          </div>
        )}
      </InnerContainer>
    </MainContainer>
  );
};

export default PriceRangesList;
