import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import ListItem from '@material-ui/core/ListItem';
import useI18n from './reviewersAbstractsEdit/i18n';
import Tooltip from '@material-ui/core/Tooltip';
import CheckIcon from '@material-ui/icons/Check';
import Replay from '@material-ui/icons/Replay';
import ArrowBack from '@material-ui/icons/ArrowBack';

import {
  AimIconAndTextButton,
  AimSelect,
  AimSelectMenuItem,
  AimSnackbar,
  AimSnackbarSeverity,
  styled,
  CustomIntl,
} from '@aim/components';

import {
  updateReviewer,
  getEventAbstractService,
} from './shared/participationGqlHelper';

const ReviewerCategory = () => {
  const intl = CustomIntl(useIntl());
  const i18n = useI18n(intl);
  const { eventId, abstractId } = useParams();
  const history = useHistory();
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [abstractServiceDetail, setAstractServiceDetail] = useState([]);
  const [abstractDocumentDetails, setAbstractDocumentDetails] = useState([]);

  const StyledHeaderListItem = styled(ListItem)({
    marginLeft: 5,
    marginRight: 5,
    height: '20px !important',
    background: 'transparent',
  });

  const {
    control,
    register,
    handleSubmit,
    watch,
    getValues,
    errors,
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      category: '',
      subCategory: '',
    },
    shouldUnregister: false,
  });

  const saveService = async (submittedData) => {
    try {
      const { category, subCategory } = submittedData;

      await updateReviewer({
        id: history.location.state.reviewer.id,
        reviewerFavoriteCategoryId: category,
        reviewerFavoriteSubCategoryId: subCategory,
      });
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.success,
        message: i18n.page.messages.save,
      });
    } catch (err) {
      console.error(err);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.page.messages.save,
      });
    }
  };

  const initValues = async () => {
    try {
      const astractServiceData = await getEventAbstractService(eventId);
      setAstractServiceDetail(astractServiceData);
      reset({
        category: history.location.state.reviewer.favoriteCategory?.id,
        subCategory: history.location.state.reviewer.favoriteSubCategory?.id,
      });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    initValues();
  }, []);

  const formControlStyle = {
    width: 'calc(100% - 20px)',
  };

  const currCategoryId = watch('category');
  const currCategory = currCategoryId
    ? abstractServiceDetail?.categories.items.find(
        (c) => c.id === currCategoryId
      )
    : null;

  return (
    <>
      <div
        style={{
          flex: 1,
          alignSelft: 'start',
          minWidth: 'calc(80% - 20px)',
        }}
      >
        <Tooltip
          title={intl.formatMessage({
            description: 'back to abstracts',
            defaultMessage: 'Back to Abstracts',
          })}
        >
          <AimIconAndTextButton
            style={{
              padding: 0,
            }}
            variant="none"
            text="Abstract"
            onClick={() => history.push(`/events/${eventId}/abstracts-wall`)}
          >
            <ArrowBack />
          </AimIconAndTextButton>
        </Tooltip>

        <form onSubmit={handleSubmit(saveService)}>
          <Controller
            render={(props) => (
              <AimSelect
                label={i18n.page.selects.category}
                value={props.value}
                placeholder
                onChange={(e) => props.onChange(e.target.value)}
                formControlStyle={formControlStyle}
                style={{ backgroundColor: 'white', color: 'black' }}
              >
                {abstractServiceDetail?.categories &&
                  Object.entries(abstractServiceDetail.categories.items).map(
                    ([key, value]) => (
                      <AimSelectMenuItem key={key} value={value.id}>
                        {value.name}
                      </AimSelectMenuItem>
                    )
                  )}
              </AimSelect>
            )}
            name="category"
            control={control}
          />
          <Controller
            render={(props) => (
              <AimSelect
                label={i18n.page.selects.subcategory}
                value={props.value}
                onChange={(e) => props.onChange(e.target.value)}
                formControlStyle={formControlStyle}
                style={{ backgroundColor: 'white', color: 'black' }}
              >
                {currCategory &&
                  currCategory.subCategories.items.map((value) => (
                    <AimSelectMenuItem key={value.id} value={value.id}>
                      {value.name}
                    </AimSelectMenuItem>
                  ))}
              </AimSelect>
            )}
            name="subCategory"
            control={control}
          />
          <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
            <AimIconAndTextButton
              text={i18n.snackbar.buttons.cancel}
              aria-label="cancel"
              variant="greyFill"
              onClick={() => history.push(`/events/${eventId}/abstracts-wall`)}
            >
              <Replay />
            </AimIconAndTextButton>
            <AimIconAndTextButton
              text={i18n.snackbar.buttons.save}
              type="submit"
              variant="greenFill"
            >
              <CheckIcon />
            </AimIconAndTextButton>
          </div>
        </form>
      </div>
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </>
  );
};

export default ReviewerCategory;
