import { aws, appState, constants } from '@aim/common';
const { API } = aws;
const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

export const getEventFieldsQuery = /* GraphQL */ `
  query GetEvent($id: ID! $filter: ModelFieldContextFilterInput) {
    getEvent(id: $id) {
      id
      papers {
        items {
          name
          mappings {
            items {
              id
              name
              valueString
              value {
                id
                key
              }
            }
          }
        }
      }
      services(filter: { serviceName: { eq: "${constants.Services.VISA.key}" } }) {
        items {
          serviceName
          id
          customFields(filter: { isDeleted: { ne: false } }) {
            items {
              controlType
              id
              key
              isDeleted
              label
              options
              placeholder
              contextsOfUse(filter: $filter) {
                items {
                  id
                  position
                  contextName
                  isRequired
                  isHidden
                }
              }
            }
          }
        }
      }
    }
  }
`;

const listParticipationFieldValues = /* GraphQL */ `
  query listParticipationFieldValues($id: ID!) {
    getParticipation(id: $id) {
      id
      givenName
      familyName
      email
      fieldValues {
        items {
          id
          value
          fieldDefinition {
            controlType
            id
            label
            options
            placeholder
          }
        }
      }
    }
  }
`;

const listParticipationFilledPapersQuery = /* GraphQL */ `
  query listParticipationFilledPapers($id: ID!) {
    getParticipation(id: $id) {
      id
      filledPapers {
        items {
          id
          type
          file {
            extension
            id
            originalName
            size
          }
        }
      }
    }
  }
`;

const getEventPapersQuery = /* GraphQL */ `
  query getEventPapers($id: ID!) {
    getEvent(id: $id) {
      id
      papers(filter: { type: { contains: "visa|" } }) {
        items {
          id
          isGeneric
          type
          name
          template {
            originalName
            size
          }
        }
      }
    }
  }
`;

export const createFieldValueMutation = /* GraphQL */ `
  mutation CreateFieldValue(
    $input: CreateFieldValueInput!
    $condition: ModelFieldValueConditionInput
  ) {
    createFieldValue(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateFieldValueMutation = /* GraphQL */ `
  mutation UpdateFieldValue(
    $input: UpdateFieldValueInput!
    $condition: ModelFieldValueConditionInput
  ) {
    updateFieldValue(input: $input, condition: $condition) {
      id
    }
  }
`;

const deleteFieldValueMutation = /* GraphQL */ `
  mutation DeleteFieldValue(
    $input: DeleteFieldValueInput!
    $condition: ModelFieldValueConditionInput
  ) {
    deleteFieldValue(input: $input, condition: $condition) {
      id
    }
  }
`;

export const getEventFields = (id, isLoader = true) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();

    aws.API.graphql({ query: getEventFieldsQuery, variables: { id } })
      .then((response) =>
        resolve({
          customFields:
            response.data.getEvent.services?.items[0]?.customFields?.items,
          paper: response.data.getEvent.papers?.items[0],
        })
      )
      .catch((e) => {
        console.error('event-fields-list', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });

export const getEventPapers = (id, isLoader = false) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();

    aws.API.graphql({ query: getEventPapersQuery, variables: { id } })
      .then((response) => resolve(response.data.getEvent.papers?.items))
      .catch((e) => {
        console.error('event-papers-list', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });

export const getParticipationFilledPapers = (id, isLoader = false) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();

    aws.API.graphql({
      query: listParticipationFilledPapersQuery,
      variables: { id },
    })
      .then((response) =>
        resolve(response.data.getParticipation.filledPapers?.items)
      )
      .catch((e) => {
        console.error('event-papers-list', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });

export const createFieldValue = (input, isLoader = true) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();
    API.graphql({
      query: createFieldValueMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.createFieldValue))
      .catch((e) => {
        console.error('update-field-value', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });

export const updateFieldValue = (input, isLoader = true) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();
    API.graphql({
      query: updateFieldValueMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.updateFieldValue))
      .catch((e) => {
        console.error('update-field-value', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });

export const deleteFieldValue = (input, isLoader = true) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();
    API.graphql({
      query: deleteFieldValueMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.deleteFieldValue))
      .catch((e) => {
        console.error('delete-field-value', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });

export const getParticipationVisaFieldValues = (
  participationId,
  isLoader = true
) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();
    API.graphql({
      query: listParticipationFieldValues,
      variables: { id: participationId },
    })
      .then((response) =>
        resolve(response.data.getParticipation.fieldValues?.items)
      )
      .catch((e) => {
        console.error('get-field-values', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });
