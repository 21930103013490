import { aws, appState } from '@aim/common';
import { PromisePool } from '@supercharge/promise-pool';

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const getSponsorStaffClosingDateQuery = /* GraphQL */ `
  query GetSponsor($id: ID!) {
    getSponsorStaff(id: $id) {
      id
      closingDate
    }
  }
`;

const updateParticipationMutation = /* GraphQL */ `
  mutation UpdateParticipation(
    $input: UpdateParticipationInput!
    $condition: ModelParticipationConditionInput
  ) {
    updateParticipation(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateSponsorStaffMutation = /* GraphQL */ `
  mutation UpdateSponsorStaff(
    $input: UpdateSponsorStaffInput!
    $condition: ModelSponsorStaffConditionInput
  ) {
    updateSponsorStaff(input: $input, condition: $condition) {
      id
    }
  }
`;

export const deleteStaffMember = (
  sponsorStaffId,
  participationId,
  useLoader = true
) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    aws.API.graphql({
      query: getSponsorStaffClosingDateQuery,
      variables: { id: sponsorStaffId },
    })
      .then(({ data }) => {
        const closingDate = data.getSponsorStaff.closingDate;

        // check if already closed
        if (closingDate) return;

        const input = { id: participationId, isDeleted: true };
        aws.API.graphql({
          query: updateParticipationMutation,
          variables: { input },
        })
          .then(({ data }) => resolve(data.updateParticipation))
          .catch((e) => {
            console.error('soft-delete-staff-member-participation', e);
            reject();
          });
      })
      .catch((e) => {
        console.error('check-staff-detail-closing-date', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const updateParticipation = (input) =>
  new Promise((resolve, reject) => {
    showLoader();
    aws.API.graphql({
      query: updateParticipationMutation,
      variables: { input },
    })
      .then((response) => {
        resolve(response.data.updateParticipation);
      })
      .catch((e) => {
        console.error('update-participation-error', e);
        reject();
      })
      .finally(() => hideLoader());
  });

export const closeStaffGroup = (input, participationIds, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    aws.API.graphql({
      query: updateSponsorStaffMutation,
      // variables: { input: { id, closingDate: new Date() } },
      variables: { input },
    })
      //.then(({ data }) => resolve(data.updateParticipation))
      .then(async () =>
        resolve(
          await PromisePool.for(participationIds || [])
            .withConcurrency(30)
            .process(async (participationId) =>
              updateParticipation({
                id: participationId,
                isClusterClosed: 'true',
              })
            )
        )
      )
      .catch((e) => {
        console.error('close-staff-group', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });
