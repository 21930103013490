import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import { aws } from '@aim/common';
import { AimTypography } from '@aim/components';

const WebcastCardGridElement = ({ webcast }) => {
  const { eventId } = useParams();
  const history = useHistory();
  const widthRef = useRef(null);

  const [imageUrl, setImageUrl] = useState('');
  const [height, setHeight] = useState(175);

  useEffect(() => {
    setHeight((widthRef.current?.clientWidth || 100) / 1.33);
  });

  useEffect(() => {
    aws.Storage.get(
      'events/' + eventId + '/webcasts/' + webcast.id + '/' + webcast.image
    ).then((url) => {
      setImageUrl(url);
    });
  }, []);

  const handleSelectedClick = (e) =>
    history.replace(`/events/${eventId}/webcast-list/${webcast.id}`);

  return (
    <>
      <Grid
        item
        xs={12}
        sm={6}
        lg={4}
        xl={3}
        onClick={(e) => handleSelectedClick(e)}
      >
        <div
          ref={widthRef}
          style={{
            width: '100%',
            maxWidth: '98vw',
            height,
            boxShadow: '0 0 black !important',
            cursor: 'pointer',
          }}
        >
          <div
            style={{
              height: '70%',
              display: 'flex',
              alignItems: 'flex-end',
              backgroundImage: `url(${imageUrl})`,
              backgroundSize: 'cover',
              backgroundColor: '#cccccc',
            }}
          />
          <div
            style={{
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              overflow: 'hidden',
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <AimTypography variant="text" margin="0">
                    {webcast.topic}
                  </AimTypography>
                </Grid>
                <Grid item xs={12}>
                  <AimTypography
                    isUpperCase
                    ellipsis
                    variant="h2"
                    margin="0"
                    style={{
                      maxWidth: '100%',
                    }}
                  >
                    {webcast.title}
                  </AimTypography>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </Grid>
    </>
  );
};

export default WebcastCardGridElement;
