import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { AimIconButton, useAimMediaQuery } from '@aim/components';
import MenuIcon from '@material-ui/icons/Menu';
import ResponsiveDrawer from '../responsiveDrawer/ResponsiveDrawer';
import Logo from '../logo/Logo';
import Menu from '../menu/Menu';
import ProfileAvatar from '../profile/ProfileAvatar';
import { appState } from '@aim/common';
import { getPremiumRanges, getSponsorStaffServiceByEvent } from './gqlHelper';
import { CartButton } from '../cart';

const Header = () => {
  const { eventId } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [hasPremiumRanges, setHasPremiumRanges] = useState(false);
  const [hideMyArea, setHideMyArea] = useState(false);

  const { isMobile } = useAimMediaQuery();

  const participation = appState.user.getValue()?.userAndParticipation
    ?.participation;

  const sponsor = participation?.sponsor; // || participation?.sponsorStaff?.sponsor;

  const isValidSponsor =
    !participation?.isDeleted && sponsor?.id && !sponsor?.isDeleted;

  useEffect(() => {
    if (isValidSponsor) {
      getPremiumRanges(eventId).then((response) => {
        if (response.length) {
          setHasPremiumRanges(true);
        }
      });
    }
  }, [isValidSponsor]);

  useEffect(() => {
    getSponsorStaffServiceByEvent(eventId).then((response) => {
      setHideMyArea(response.hideMyArea);
    });
  }, []);

  return (
    <>
      {/* // <div
    //   style={{
    //     boxShadow: '0px 3px 6px rgb(0 0 0 / 29%)',
    //     marginBottom: 7,
    //     display: 'flex',
    //     justifyContent: isMobile ? 'space-between' : 'center',
    //     alignItems: 'center',
    //   }}
    // > */}

      <Logo />
      <Menu />
      {isValidSponsor && (
        <div
          style={{
            paddingRight: !isMobile ? 24 : 14,
          }}
        >
          <CartButton />
        </div>
      )}
      {!isMobile && (
        <ProfileAvatar
          hasPremiumRanges={hasPremiumRanges}
          hideMyArea={hideMyArea}
        />
      )}
      {isMobile && (
        <>
          <AimIconButton variant="text" onClick={() => setIsOpen(!isOpen)}>
            <MenuIcon fontSize="large" style={{ color: 'black' }} />
          </AimIconButton>
          <ResponsiveDrawer
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            hasPremiumRanges={hasPremiumRanges}
            hideMyArea={hideMyArea}
          />
        </>
      )}
    </>
  );
};

export default Header;
