import React from 'react';
import { AimImageAndTextCard } from '@aim/components';
import Grid from '@material-ui/core/Grid';
import { constants } from '@aim/common';

const openPage = ({ history, link }) => {
  history.push(link);
};

const EventHomepageActivitiesTypes = Object.values(
  constants.EventHomepageActivitiesTypes
);

const Activity = ({ history, eventId, isMobile, activities }) => {
  const sortedActivitiesByPropertyNameOrder = [...activities].sort(
    (x, y) => x.order - y.order
  );

  return (
    <div>
      <Grid
        container
        direction="row"
        style={{ justifyContent: 'center', display: 'flex' }}
      >
        {sortedActivitiesByPropertyNameOrder.map((activity, ndx) => (
          <Grid
            item
            key={ndx}
            {...(isMobile && { xs: 12 })}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <AimImageAndTextCard
              isMobile={isMobile}
              key={ndx}
              {...activity}
              {...(isMobile && { brightnessPercentage: 60, titleOver: true })}
              style={{ cursor: 'pointer' }}
              onClick={() =>
                openPage({
                  link: `/events/${eventId}/${
                    EventHomepageActivitiesTypes.find(
                      (x) => x.id.toLowerCase() === activity.activity
                    ).link
                  }`,
                  history,
                })
              }
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Activity;
