export default function (intl) {
  return {
    page: {
      backButton: {
        label: intl.formatMessage({
          description: 'back button label',
          defaultMessage: 'Back',
        }),
        tooltip: intl.formatMessage({
          description: 'back to webcast list',
          defaultMessage: 'Back to Webcast list',
        }),
      },
      footer: {
        firstContainer: intl.formatMessage({
          description: 'descrizione',
          defaultMessage: 'descrizione',
        }),
        secondContainer: intl.formatMessage({
          description: 'tags & link',
          defaultMessage: 'tags & link',
        }),
      },
      labels: {
        link: intl.formatMessage({
          description: 'webcast link',
          defaultMessage: 'Link:',
        }),
        topic: intl.formatMessage({
          description: 'webcast topic',
          defaultMessage: 'Topic:',
        }),
        speakers: intl.formatMessage({
          description: 'webcast speakers',
          defaultMessage: 'Speakers:',
        }),
        tags: intl.formatMessage({
          description: 'webcast tags',
          defaultMessage: 'Tag:',
        }),
      },
      errors: {
        loading: intl.formatMessage({
          description: 'errorLoading',
          defaultMessage:
            'There was an error loading the Agenda, please try to refresh the page',
        }),
      },
    },
    actions: {
      myAgenda: {
        success: intl.formatMessage({
          description: 'success MyAgenda',
          defaultMessage: 'Event successfully add to MyAgenda',
        }),
        error: intl.formatMessage({
          description: 'error MyAgenda',
          defaultMessage: 'There was an error, please try again',
        }),
        remove: intl.formatMessage({
          description: 'remove MyAgenda',
          defaultMessage: 'Event successfully removed from MyAgenda',
        }),
      },
    },
    toolbar: {
      buttons: {
        sendEmail: {
          tooltip: intl.formatMessage({
            description: 'send email',
            defaultMessage: 'Send Email',
          }),
        },
        exportExcel: {
          tooltip: intl.formatMessage({
            description: 'export excel',
            defaultMessage: 'Export Excel',
          }),
        },
        import: {
          tooltip: intl.formatMessage({
            description: 'import',
            defaultMessage: 'Import',
          }),
        },
        filter: {
          tooltip: intl.formatMessage({
            description: 'filter',
            defaultMessage: 'Filter',
          }),
        },
        add: {
          tooltip: intl.formatMessage({
            description: 'add new webcast',
            defaultMessage: 'Add new webcast',
          }),
        },
      },
    },
    card: {
      emptyField: {
        msg1: intl.formatMessage({
          description: 'emptyFieldMsg1',
          defaultMessage: 'No',
        }),
        msg2: intl.formatMessage({
          description: 'emptyFieldMsg2',
          defaultMessage: 'specified',
        }),
      },
      pinEvent: intl.formatMessage({
        description: 'pinEvent',
        defaultMessage: 'Add to MyAgenda',
      }),
      unPinEvent: intl.formatMessage({
        description: 'unPinEvent',
        defaultMessage: 'Remove from MyAgenda',
      }),
      placeholder: intl.formatMessage({
        description: 'placeholder',
        defaultMessage: 'Insert',
      }),
      room: intl.formatMessage({
        description: 'room',
        defaultMessage: 'Room',
      }),
      name: intl.formatMessage({
        description: 'title',
        defaultMessage: 'Title',
      }),
      context: intl.formatMessage({
        description: 'sessionContextLabel',
        defaultMessage: 'Context',
      }),
      abstract: intl.formatMessage({
        description: 'sessionAbstractLabel',
        defaultMessage: 'Abstract',
      }),
      type: intl.formatMessage({
        description: 'sessionTypeLabel',
        defaultMessage: 'Type',
      }),
      title: intl.formatMessage({
        description: 'sessionTitleLabel',
        defaultMessage: 'Title',
      }),
      president: intl.formatMessage({
        description: 'sessionPresidentLabel',
        defaultMessage: 'President',
      }),
      moderators: intl.formatMessage({
        description: 'sessionModeratorLabel',
        defaultMessage: 'Moderators',
      }),
      provocateurs: intl.formatMessage({
        description: 'sessionProvacateurLabel',
        defaultMessage: 'Provocateurs',
      }),
      description: intl.formatMessage({
        description: 'sessionDescriptionLabel',
        defaultMessage: 'Description',
      }),
      coverImage: intl.formatMessage({
        description: 'sessionCoverImageLabel',
        defaultMessage: 'Uploaded cover image',
      }),
      visibility: intl.formatMessage({
        description: 'sessionVisibilityLabel',
        defaultMessage: 'Visibility',
      }),
      tags: intl.formatMessage({
        description: 'sessionTagsLabel',
        defaultMessage: 'Tags',
      }),
      linkPax: intl.formatMessage({
        description: 'sessionLinkPaxLabel',
        defaultMessage: 'Link Pax',
      }),
      linkSpeaker: intl.formatMessage({
        description: 'sessionLinkSpeakerLabel',
        defaultMessage: 'Link Speaker',
      }),
      rating: intl.formatMessage({
        description: 'sessionRatingLabel',
        defaultMessage: 'Rating',
      }),
      topic: intl.formatMessage({
        description: 'sessionTopicLabel',
        defaultMessage: 'Topic',
      }),
      speaker: intl.formatMessage({
        description: 'sessionSpeakerLabel',
        defaultMessage: 'Speaker',
      }),
      instructions: intl.formatMessage({
        description: 'sessionInstructionsLabel',
        defaultMessage: 'Instructions for exposure',
      }),
      start: intl.formatMessage({
        description: 'sessionStartLabel',
        defaultMessage: 'Start',
      }),
      end: intl.formatMessage({
        description: 'sessionEndLabel',
        defaultMessage: 'End',
      }),
      date: intl.formatMessage({
        description: 'sessionDateLabel',
        defaultMessage: 'Date',
      }),
    },
    dialog: {
      title: intl.formatMessage({
        description: 'delete webcast title',
        defaultMessage: 'Delete webcast',
      }),
      message: intl.formatMessage({
        description: 'are you sure delete webcast message',
        defaultMessage: 'Are you sure?',
      }),
    },
  };
}
