import React from 'react';
import { sortBy } from 'lodash';
import { useIntl } from 'react-intl';

import translation from './../shared/translation';
import {
  CustomIntl,
  AimIconAndTextButton,
  AimDialog,
  AimTypography,
  AimSnackbar,
  AimSnackbarSeverity,
} from '@aim/components';

import { appState, constants } from '@aim/common';

import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import ArrowBack from '@material-ui/icons/ArrowBack';

import {
  MainContainer,
  InnerContainer,
} from '../shared/SponsorPagesContainers';
import { useHistory, useParams } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';

import {
  listItemsByEvent,
  createOtherSponsBookingItem,
  updateOtherSponsBookingItem,
} from '../shared/otherSponsorizationsItemServiceGqlHelper';
import { OtherSponsorizationsItemsListTable } from './otherSponsorizationsItemList/OtherSponsorizationsItemsListTable';
import { OtherSponsItemsDialogContent } from './otherSponsorizationsItemList/OtherSponsItemsDialogContent';
import { OtherSponsAddToCartDialogContent } from './otherSponsorizationsItemList/OtherSponsAddToCartDialogContent';
import { addDays } from 'date-fns';
import { useBuyOperations } from '../shared/buyOperationsGqlHelper';

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

//Constants
const itemTypes = constants.OtherStandServiceItemType;

const OtherSponsorizationsItemsList = () => {
  // Hooks
  const history = useHistory();
  const intl = CustomIntl(useIntl());
  const i18n = translation.otherSponsorizationsItemsList(intl);

  const buyOperationsHelper = useBuyOperations();
  const { eventId, sponsorId, categoryId, subcategoryId } = useParams();

  // State
  const [dialog, setDialog] = useState({ isOpen: false });
  const [addToCartDialog, setAddToCartDialog] = useState({ isOpen: false });
  const [subCategoryData, setSubCategoryData] = useState();
  const [items, setItems] = useState([]);
  console.log('items', items);
  const [bookingDuration, setBookingDuration] = useState();
  const [paymentType, setPaymentType] = useState();
  const [snackbar, setSnackbar] = useState({ isOpen: false });

  const isDoubleCost = addToCartDialog?.item?.type === itemTypes.doubleCost.id;
  const isPlusDisabled =
    addToCartDialog?.item?.availability &&
    addToCartDialog?.quantity *
      (isDoubleCost ? addToCartDialog?.amountPerQuantity : 1) >
      addToCartDialog?.item?.bookableQuantity;

  // Effects
  useEffect(() => {
    fetchSubCategory();
  }, []);

  // API
  const fetchSubCategory = async () => {
    const { event, subCategory } = await listItemsByEvent(
      eventId,
      subcategoryId
    );
    setSubCategoryData(subCategory);

    const sortedItems = sortBy(subCategory?.childItems?.items, 'name');
    const nextItems =
      sortedItems?.map((i) => {
        console.log('i', i);
        const validBookings = i.otherSponsorizationsBookingItems.items.filter(
          (booking) =>
            booking.buyOperation &&
            booking.buyOperation.isDeleted !== 'true' &&
            booking.buyOperation.isExpired !== 'true'
        );
        console.log('validBookings', validBookings);
        // Detect still bookable quantity by summing directly booking quantities (from other sponsorizations service...)
        const bookedQtyOtherSpons = validBookings
          .filter((i) => i.buyOperation)
          .reduce((sum, curr) => {
            const currQuantity =
              curr.quantity && curr.quantity >= 0 ? curr.quantity : 1;
            const currApQ =
              curr.amountPerQuantity && curr.amountPerQuantity >= 0
                ? curr.amountPerQuantity
                : 1;

            return sum + currQuantity * currApQ;
          }, 0);

        // ...and the ones from sponsor packages
        const bookedInPackagesQty = i.otherSponsorizationsBookingItems.items
          .filter((i) => i.sponsorPackageItem)
          .reduce((prev, curr) => {
            prev =
              prev +
              (curr?.sponsorPackageItem?.package?.buyOperations?.items.filter(
                (bo) => bo.isDeleted !== 'true' && bo.isExpired !== 'true'
              ).length || 0) *
                (curr?.quantity || 1);
            return prev;
          }, 0);

        const bookedQty = bookedQtyOtherSpons + bookedInPackagesQty;

        const bookableQuantity = i.availability - bookedQty;
        // If we have a booking for a single choice product or if we booked all availabilities purchaes is disabled
        const purchaseDisabled = i.availability && bookableQuantity < 1;

        return {
          ...i,
          otherSponsorizationsBookingItems: {
            items: i.otherSponsorizationsBookingItems.items.filter(
              (i) => i.buyOperation
            ),
          },
          purchaseDisabled,
          bookedQty,
          bookableQuantity,
        };
      }) || [];

    setItems(nextItems);

    setBookingDuration(event.sponsorServiceBookingDuration || 10);

    const paymentType =
      event.otherSponsorizationsService.isCreditCardEnabled &&
      event.otherSponsorizationsService.isBankTransferEnabled
        ? 'both'
        : (event.otherSponsorizationsService.isCreditCardEnabled &&
            constants.PaymentTypes.CreditCard) ||
          (event.otherSponsorizationsService.isBankTransferEnabled &&
            constants.PaymentTypes.BankTransfer);

    setPaymentType(paymentType || constants.PaymentTypes.BankTransfer);
  };

  // Helpers
  const showInfoDialog = (item) =>
    setDialog({
      isOpen: true,
      item: { ...item, subCategory: subCategoryData.name },
    });

  const onAddToCart = (item, purchaseNow = false) => {
    const isNoteEnabled = item.isNoteEnabled;
    const nextDialog = { isOpen: true, item, purchaseNow };

    switch (item.type) {
      case itemTypes.unitaryCost.id:
        setAddToCartDialog({ ...nextDialog, quantity: 1 });
        break;
      case itemTypes.doubleCost.id:
        setAddToCartDialog({
          ...nextDialog,
          quantity: 1,
          amountPerQuantity: 1,
        });
        break;
      case itemTypes.singleChoice.id:
      case itemTypes.typeTextService.id:
        isNoteEnabled
          ? setAddToCartDialog(nextDialog)
          : createBuyOperation(item.id, purchaseNow);

        break;

      default:
        break;
    }
  };

  const createBuyOperation = async (
    itemId,
    purchaseNow = false,
    quantity = 1,
    amountPerQuantity,
    note
  ) => {
    showLoader();
    try {
      const itemInput = {
        otherSponsorizationsBookingItemItemId: itemId,
        otherSponsorizationsBookingItemSponsorId: sponsorId,
        quantity,
        amountPerQuantity,
      };
      const otherSponsBookingItem = await createOtherSponsBookingItem(
        itemInput,
        false
      );

      const bookingDate = new Date();
      const bookingExpiringDate = addDays(bookingDate, bookingDuration);
      const inputBuyOperation = {
        eventId,
        buyOperationOtherSponsorizationsItemId: itemId,
        buyOperationOtherSponsorizationsBookingItemId: otherSponsBookingItem.id,
        buyOperationSponsorId: sponsorId,
        date: bookingDate,
        expiringDate: bookingExpiringDate,
        paymentType,
        type: constants.SponsorServicesType.OTHER_SPONSORIZATIONS.id,
        note: note,
        isExpired: 'false',
        isDeleted: 'false',
      };
      const buyOperationData = await buyOperationsHelper.create(
        inputBuyOperation,
        false
      );
      const itemInputUpdate = {
        id: otherSponsBookingItem.id,
        otherSponsorizationsBookingItemBuyOperationId: buyOperationData.id,
      };
      await updateOtherSponsBookingItem(itemInputUpdate, false);
      const destUrl = `/events/${eventId}/${sponsorId}/cart`;
      if (purchaseNow) {
        history.push({
          pathname: destUrl,
          state: {
            bookings: [buyOperationData],
          },
        });
      } else {
        // refetch data to reload availabilities
        await fetchSubCategory();
      }
    } catch (e) {
      console.error(e);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.snackbar.addToCartFail.label,
      });
    } finally {
      hideLoader();
    }
  };

  // Renders
  const renderPageHeading = () =>
    subCategoryData && (
      <>
        <div style={{ display: 'flex-root', marginTop: 20 }}>
          <Tooltip title={i18n.page.back.tooltip.label}>
            <AimIconAndTextButton
              variant="text"
              text={i18n.page.back.button.label}
              onClick={() =>
                history.push(
                  `/events/${eventId}/${sponsorId}/my-services/other-sponsorizations/${categoryId}/`
                )
              }
            >
              <ArrowBack />
            </AimIconAndTextButton>
          </Tooltip>
        </div>
        <Grid container>
          <Grid item xs={12}>
            <AimTypography variant={'h1'}>{subCategoryData.name}</AimTypography>
          </Grid>
        </Grid>
      </>
    );

  const renderPageBody = () =>
    subCategoryData && items?.length ? (
      <Grid container>
        <OtherSponsorizationsItemsListTable
          intl={intl}
          i18n={i18n}
          items={items}
          showInfoDialog={showInfoDialog}
          onAddToCart={onAddToCart}
        />
      </Grid>
    ) : (
      <AimTypography variant="ẗext">{i18n.table.noData}</AimTypography>
    );

  const renderSnackbar = () => (
    <AimSnackbar
      open={snackbar.isOpen}
      onClose={() => setSnackbar({ isOpen: false })}
      severity={snackbar.severity}
    >
      {snackbar.message}
    </AimSnackbar>
  );

  const renderDialogs = () => (
    <>
      <AimDialog
        open={dialog.isOpen}
        title={dialog?.item?.name || i18n.dialog.title.label}
        agreeText={i18n.dialog.closeButton.label}
        hideCancelButton
        onClose={() => setDialog({ isOpen: false, item: null })}
        maxWidth={'xl'}
      >
        <OtherSponsItemsDialogContent item={dialog?.item} i18n={i18n} />
      </AimDialog>
      <AimDialog
        open={addToCartDialog.isOpen}
        title={addToCartDialog?.item?.name || i18n.addToCartDialog.title.label}
        agreeText={i18n.addToCartDialog.buttons.add.label}
        disagreeText={i18n.addToCartDialog.buttons.close.label}
        onClose={() => setAddToCartDialog({ isOpen: false })}
        maxWidth={'xl'}
        onAgree={() =>
          createBuyOperation(
            addToCartDialog?.item?.id,
            addToCartDialog?.purchaseNow,
            addToCartDialog?.quantity,
            addToCartDialog?.amountPerQuantity,
            addToCartDialog?.note
          )
        }
        onAgreeDisabled={
          (addToCartDialog.item?.isNoteRequired && !addToCartDialog.note) ||
          isPlusDisabled
        }
      >
        <OtherSponsAddToCartDialogContent
          {...{
            i18n,
            addToCartDialog,
            setAddToCartDialog,
            isPlusDisabled,
          }}
        />
      </AimDialog>
    </>
  );

  return (
    <MainContainer>
      <InnerContainer grow bottomSpacing>
        {renderPageHeading()}
        {renderPageBody()}
      </InnerContainer>
      {renderSnackbar()}
      {renderDialogs()}
    </MainContainer>
  );
};

export default OtherSponsorizationsItemsList;
