export default {
  breakoutRoomSlotDetail: (intl) => ({
    page: {
      backButton: {
        label: intl.formatMessage({
          description: 'backDetail',
          defaultMessage: 'back',
        }),
        tooltip: intl.formatMessage({
          description: 'backtoHomeDetail',
          defaultMessage: 'Back to Homepage',
        }),
      },
      title: intl.formatMessage({
        description: 'myBreakoutRooms',
        defaultMessage: 'Breakout Room with the Sponsor',
      }),
      message: intl.formatMessage({
        description: 'messageBreakoutRoomsSlotDetail',
        defaultMessage: 'Message',
      }),
      sponsorContact: intl.formatMessage({
        description: 'sponsorContactBreakoutRoomsSlotDetail',
        defaultMessage: "Sponsor's Contacts",
      }),
      meeting: intl.formatMessage({
        description: 'meetingBreakoutRoomsSlotDetail',
        defaultMessage: 'Group meeting',
      }),
      oneToOne: intl.formatMessage({
        description: 'oneToOneBreakoutRoomsSlotDetail',
        defaultMessage: 'One to One',
      }),
      buttons: {
        accepted: intl.formatMessage({
          description: 'acceptedBreakoutRoomsSlotDetail',
          defaultMessage: 'accepted',
        }),
        accept: intl.formatMessage({
          description: 'accepteBreakoutRoomsSlotDetail',
          defaultMessage: 'accept',
        }),
        declined: intl.formatMessage({
          description: 'declinedBreakoutRoomsSlotDetail',
          defaultMessage: 'declined',
        }),
        decline: intl.formatMessage({
          description: 'declineBreakoutRoomsSlotDetail',
          defaultMessage: 'decline',
        }),
        goToBreakoutRoom: intl.formatMessage({
          description: 'enterBreakoutRoomsSlotDetail',
          defaultMessage: 'enter',
        }),
      },
      snackbar: {
        successRejected: intl.formatMessage({
          description: 'successRejected',
          defaultMessage: 'Thanks for your feedback',
        }),
      },
    },
  }),
  mailLandingPage: (intl) => ({
    buttons: {
      accept: intl.formatMessage({
        description: 'accept',
        defaultMessage: 'Accept',
      }),
      reject: intl.formatMessage({
        description: 'reject',
        defaultMessage: 'Reject',
      }),
      close: intl.formatMessage({
        description: 'close',
        defaultMessage: 'Close',
      }),
      send: intl.formatMessage({
        description: 'send',
        defaultMessage: 'Send',
      }),
      enter: intl.formatMessage({
        description: 'enter',
        defaultMessage: 'Enter',
      }),
    },
    rejectMotivations: {
      m0: intl.formatMessage({
        description: 'm0',
        defaultMessage: 'I cannot attend',
      }),
      m1: intl.formatMessage({
        description: 'm1',
        defaultMessage:
          'I would like to receive a different option for the appointment',
      }),
      m2: intl.formatMessage({
        description: 'm2',
        defaultMessage: "I'm not interested",
      }),
      other: intl.formatMessage({
        description: 'other',
        defaultMessage: 'Other',
      }),
      otherPlaceholder: intl.formatMessage({
        description: 'otherPlaceholder',
        defaultMessage: 'Write a message to the Sponsor',
      }),
      successRejected: intl.formatMessage({
        description: 'successRejected',
        defaultMessage: 'Thanks for the feedback',
      }),
    },
    page: {
      hello: intl.formatMessage({
        description: 'hello',
        defaultMessage: 'Hi,',
      }),
      msg1: intl.formatMessage({
        description: 'msg1',
        defaultMessage: 'reply below to confirm or reject the appointment',
      }),
      msg2: intl.formatMessage({
        description: 'msg2',
        defaultMessage: 'with Sponsor',
      }),
      msg3: intl.formatMessage({
        description: 'msg3',
        defaultMessage: 'on',
      }),
      msg4: intl.formatMessage({
        description: 'msg4',
        defaultMessage: 'from',
      }),
      msg5: intl.formatMessage({
        description: 'msg5',
        defaultMessage: 'to',
      }),
      rejectMsg1: intl.formatMessage({
        description: 'rejectMsg1',
        defaultMessage: 'You rejected the invitation to the appointment.',
      }),
      rejectMsg2: intl.formatMessage({
        description: 'rejectMsg2',
        defaultMessage:
          'If you want, insert some more details to inform the Sponsor.',
      }),
      acceptMsg: intl.formatMessage({
        description: 'acceptMsg',
        defaultMessage:
          'You will receive an email with the link to the appointment, valid for the day of the invitation',
      }),
    },
  }),
};
