import React, { useState, useEffect } from 'react';

import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import Visibility from '@material-ui/icons/Visibility';
import translation from './../shared/translation';

import {
  AimDataGrid,
  AimIconButton,
  CustomIntl,
  styled,
  theme,
  useAimMediaQuery,
} from '@aim/components';
import { useMemo } from 'react';

const GridWrapper = styled('div')({
  backgroundColor: theme.colors.greyScale.white,
  paddingInline: 8,
  paddingBottom: 20,
});

const PhysicalStandGrid = ({ floors, handleNavigation }) => {
  //Hooks
  const { isMobile, isMobileOnly } = useAimMediaQuery();

  const intl = CustomIntl(useIntl());
  const i18n = translation.physicalStandList(intl);

  //State
  const [displayFloors, setDisplayFloors] = useState([]);

  //Effects
  useEffect(() => {
    if (floors) setDisplayFloors(floors);
  }, [floors]);

  // Columns
  const loadColumns = ({ i18n }) => [
    {
      field: 'id',
      headerName: i18n.table.columns.id.label,
      width: isMobileOnly ? 80 : 200,
      renderCell: ({ row }) => {
        return row?.code;
      },
    },
    {
      field: 'floor',
      headerName: i18n.table.columns.floor.label,
      width: isMobileOnly ? 100 : 350,
      renderCell: ({ row }) => {
        return row?.name;
      },
    },
    {
      field: 'spaces',
      headerName: i18n.table.columns.spaces.label,
      width: isMobileOnly ? 80 : 150,
      renderCell: ({ row }) => {
        return row?.spaces?.items?.length;
      },
    },
    {
      field: 'actions',
      type: 'actions',
      width: isMobileOnly ? 40 : 150,
      getActions: ({ row }) => {
        return [
          <AimIconButton
            key="0"
            style={{ float: 'right' }}
            {...{
              ...(isMobileOnly && { showInMenu: true }),
              variant: 'lightBlueFill',
              small: true,
              onClick: () => handleNavigation(row.id),
              disabled: row?.spaces?.items?.length === 0,
            }}
          >
            <Visibility />
          </AimIconButton>,
        ];
      },
    },
  ];

  const columns = useMemo(
    () =>
      loadColumns({
        i18n,
      }),
    [i18n]
  );

  return (
    <GridWrapper>
      <AimDataGrid
        columns={columns}
        rows={displayFloors}
        tableName="physical stand"
        pinnedColumns={{ left: [], right: ['actions'] }}
        sortModel={[{ field: 'id', sort: 'asc' }]}
        disableMultipleSelection
        autoHeight
      />
    </GridWrapper>
  );
};

export default PhysicalStandGrid;
