import React from 'react';
import { format } from 'date-fns';

import Grid from '@material-ui/core/Grid';

import { AimTypography, styled, theme } from '@aim/components';

const Card = styled('div')({
  display: 'flex',
  width: '100%',
  paddingBottom: 5,
  marginBottom: 6,
  backgroundColor: theme.colors.greyScale.backgroundGrey,
  borderBottom: `1px solid ${theme.colors.greyScale.grey2}`,
});

const SessionCard = ({ data }) => (
  <Card key={data.id}>
    <Grid container>
      <Grid item xs={12}>
        <AimTypography variant="textBold" margin="0 0 0 8px">
          {data.session.name.toUpperCase()}
        </AimTypography>
        <AimTypography variant="text" margin="0 0 2px 8px">
          {data.session.room.name}{' '}
          {format(new Date(data.session.date), 'dd LLLL yyyy')}{' '}
          {format(new Date(data.session.start), 'hh:mm')} -{' '}
          {format(new Date(data.session.end), 'hh:mm')}
        </AimTypography>

        {data.session.speeches &&
          data.session.speeches.map((speech) => (
            <div
              style={{ display: 'flex', alignItems: 'center' }}
              key={speech.id}
            >
              <AimTypography variant="textBold" margin="0 0 0 8px">
                {speech.titleSpeech}
              </AimTypography>
              <AimTypography variant="text" margin="0 0 0 8px">
                {speech.attendees}
              </AimTypography>
              <AimTypography variant="text" margin="0 0 0 8px">
                {format(new Date(speech.startSpeech), 'hh:mm')} -{' '}
                {format(new Date(speech.endSpeech), 'hh:mm')}
              </AimTypography>
              <AimTypography
                variant="textBold"
                style={{
                  color: theme.colors.secondary.lightBlue,
                }}
              >
                {speech.titleDescription.toUpperCase()}
              </AimTypography>
            </div>
          ))}
      </Grid>
    </Grid>
  </Card>
);

export default SessionCard;
