export default function (intl) {
  return {
    page: {
      title: intl.formatMessage({
        description: 'abstracts list',
        defaultMessage: 'Abstracts list',
      }),
      selects: {
        category: intl.formatMessage({
          description: 'category',
          defaultMessage: 'Choose a category',
        }),
        subcategory: intl.formatMessage({
          description: 'subCategory',
          defaultMessage: 'Choose a subcategory',
        }),
      },
      messages: {
        save: intl.formatMessage({
          description: 'save',
          defaultMessage: 'Data has been saved',
        }),
        fail: intl.formatMessage({
          description: 'fail',
          defaultMessage: 'There is a problem',
        }),
      },
    },
    snackbar: {
      buttons: {
        goToWall: intl.formatMessage({
          description: 'go to the wall',
          defaultMessage: 'Go to the wall',
        }),
        cancel: intl.formatMessage({
          description: 'cancel',
          defaultMessage: 'Cancel',
        }),
        save: intl.formatMessage({
          description: 'save',
          defaultMessage: 'Save',
        }),
      },
    },
    card: {
      presentationButton: intl.formatMessage({
        description: 'setup presentation',
        defaultMessage: 'Setup your presentation',
      }),
    },
    dialog: {
      text: intl.formatMessage({
        description: 'setup presentation',
        defaultMessage: 'Are you sure you want to delete the astract document?',
      }),
      title: intl.formatMessage({
        description: 'setup presentation',
        defaultMessage: 'Delete abstract document',
      }),
    },
    subtitles: {
      subtitleData: intl.formatMessage({
        description: 'setup presentation',
        defaultMessage: 'You can send your abstract untill ',
      }),
      subtitleMax1: intl.formatMessage({
        description: 'setup presentation',
        defaultMessage: 'You can send at least ',
      }),
      subtitleMax2: intl.formatMessage({
        description: 'setup presentation',
        defaultMessage: ' abstract',
      }),
    },
    table: {
      rowButton: intl.formatMessage({
        description: 'detail',
        defaultMessage: 'Detail ',
      }),
      columnsTitles: {
        title: intl.formatMessage({
          description: 'title',
          defaultMessage: 'Title ',
        }),
        preferred: intl.formatMessage({
          description: 'preferred',
          defaultMessage: 'Preferred',
        }),
        status: intl.formatMessage({
          description: 'status',
          defaultMessage: 'Status',
        }),
        finalScore: intl.formatMessage({
          description: 'finalScore',
          defaultMessage: 'Final Score',
        }),
      },
    },
  };
}
