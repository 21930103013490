export const translation = {
  payment: (intl) => ({
    page: {
      title: {
        cart: intl.formatMessage({
          description: 'cart title text',
          defaultMessage: 'Cart',
        }),
        checkout: intl.formatMessage({
          description: 'checkout title text',
          defaultMessage: 'Purchase',
        }),
      },
      back: {
        cart: {
          label: intl.formatMessage({
            description: 'back to payment options',
            defaultMessage: 'back',
          }),
          tooltip: intl.formatMessage({
            description: 'back to payment options',
            defaultMessage: 'Payment options',
          }),
        },
        checkout: {
          label: intl.formatMessage({
            description: 'back to payment',
            defaultMessage: 'back',
          }),
          tooltip: intl.formatMessage({
            description: 'back to payment',
            defaultMessage: 'Payment',
          }),
        },
      },
      // messages: {
      //   noBookings: {
      //     label: intl.formatMessage({
      //       description: 'no bookings message',
      //       defaultMessage: 'Cart is empty',
      //     }),
      //   },
      // },
      errors: {
        loadBookings: {
          label: intl.formatMessage({
            description: 'load bookings error',
            defaultMessage: 'Error loading bookings list',
          }),
        },
        product: {
          label: intl.formatMessage({
            description: 'product error',
            defaultMessage: 'Error during creating product',
          }),
        },
      },
    },
    checkoutTable: {
      buttons: {
        confirm: {
          label: intl.formatMessage({
            description: 'confirm checkout button',
            defaultMessage: 'Confirm',
          }),
        },
      },
      columns: {
        service: {
          label: intl.formatMessage({
            description: 'service column',
            defaultMessage: 'Service',
          }),
        },
        quantity: {
          label: intl.formatMessage({
            description: 'quantity column',
            defaultMessage: 'Quantity',
          }),
        },
        amount: {
          label: intl.formatMessage({
            description: 'amount column',
            defaultMessage: 'Amount (€)',
          }),
        },
      },
      summary: {
        netTotal: {
          label: intl.formatMessage({
            description: 'net total summary',
            defaultMessage: 'Net Total (€)',
          }),
        },
        vat: {
          label: intl.formatMessage({
            description: 'vat summary',
            defaultMessage: 'VAT',
          }),
        },
        total: {
          label: intl.formatMessage({
            description: 'net total summary',
            defaultMessage: 'Total (€)',
          }),
        },
      },
    },
    checkoutAlerts: {
      eventIsOver: {
        label: intl.formatMessage({
          description: "the event is over, you can't confirm the purchase",
          defaultMessage: "The event is over, you can't confirm the purchase",
        }),
      },
    },
  }),
  paymentOptions: (intl) => ({
    page: {
      back: {
        tooltip: intl.formatMessage({
          description: 'back button tooltip',
          defaultMessage: 'Go back',
        }),
        button: intl.formatMessage({
          description: 'back button tooltip',
          defaultMessage: 'back',
        }),
      },
      title: intl.formatMessage({
        description: 'page title',
        defaultMessage: 'Choose the payment method',
      }),
      bankTransfer: {
        label: intl.formatMessage({
          description: 'payemnt by bank transfer',
          defaultMessage: 'Bank Transfer',
        }),
      },
      creditCard: {
        label: intl.formatMessage({
          description: 'payment by credit card',
          defaultMessage: 'Credit Card',
        }),
      },
    },
  }),
};
