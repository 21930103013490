import { aws, appState } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

const getGrantsQuery = /* GraphQL */ `
  query GetEventGrants($id: ID!, $grantType: String!) {
    getEvent(id: $id) {
      grant(filter: { isDeleted: { ne: true }, type: { eq: $grantType } }) {
        items {
          id
          type
          title
          closingDate
        }
      }
    }
  }
`;

export const getGrants = (id, grantType) =>
  new Promise((resolve, reject) => {
    showLoader();

    API.graphql({ query: getGrantsQuery, variables: { id, grantType } })
      .then(({ data }) => resolve(data.getEvent.grant.items))
      .catch((e) => {
        console.error('get-grants-by-type', e);
        reject();
      })
      .finally(hideLoader);
  });
