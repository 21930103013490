import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';
import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import ArrowBack from '@material-ui/icons/ArrowBack';
import GetAppIcon from '@material-ui/icons/GetApp';
import {
  styled,
  theme,
  AimTypography,
  AimIconAndTextButton,
  CustomIntl,
} from '@aim/components';
import { fileHelper, appState, constants } from '@aim/common';
import useI18n from './i18n';
import { listCertifications } from '../shared/papersGqlHelper';
import { isAfter } from 'date-fns';
const { getS3Link } = fileHelper;

const HomeContainer = styled('div')({
  flex: 1,
  overflowX: 'hidden',
  display: 'flex',
});

const PageContainer = styled('div')({
  height: '100%',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  margin: '0 auto',
  padding: '10px',
  alignItems: 'center',
});

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  margin: '0 auto',
  background: theme.colors.greyScale.backgroundGrey,
  padding: 20,
  minHeight: 470,
  width: isMobileOnly ? 'auto' : 1280,
});

const Card = styled('div')({
  background: theme.colors.primary.white,
  boxShadow: '0 0 black !important',
  borderRadius: '10px !important',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: 10,
  padding: 10,
});

const CardHeader = styled('div')({
  paddingBottom: 2,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const handleDownload = async (link) => {
  try {
    window.open(link, '_blank');
  } catch (error) {
    console.error(error);
  }
};

const Certifications = () => {
  const intl = CustomIntl(useIntl());
  const i18n = useI18n(intl);
  const { eventId, participationId } = useParams();
  const history = useHistory();
  const [certifications, setCertifications] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await listCertifications(eventId);
      console.log('res.papers ', res.papers);
      const filledPapers = appState.user.getValue()?.userAndParticipation
        ?.participation?.filledPapers.items;
      console.log('filledPapers ', filledPapers);
      const papers = [
        ...res.papers.items
          .filter(
            (p) =>
              p.publishDate != null &&
              isAfter(new Date(), new Date(p.publishDate))
          )
          .map((p) => {
            console.log('paper ', p);
            return {
              id: p.id,
              name: p.name,
              isGeneric: p.isGeneric,
              file: p?.filledPapers?.items[0]?.file,
            };
          }),
        ...filledPapers
          .filter(
            (fp) =>
              (fp.paper.type.includes(constants.PapersTypologies.NOMINAL.id) ||
                fp.paper.type.includes(
                  constants.PapersTypologies.ABSTRACT.id
                )) &&
              !fp.paper.isDeleted
          )
          .filter(
            (fp) =>
              fp.paper.publishDate != null &&
              isAfter(new Date(), new Date(fp.paper.publishDate))
          )
          .map((fp) => ({
            id: fp.paper.id,
            name: fp.paper.name,
            isGeneric: fp.paper.isGeneric,
            file: fp.file,
          })),
      ];
      if (papers) {
        const res2 = await Promise.all(
          papers.map(async (p) => {
            console.log('p ', p, participationId);
            return {
              ...p,
              link: await getS3Link(
                `events/${eventId}/papers/certificates/filled/${
                  p.isGeneric ? '' : `${participationId}/`
                }`,
                {
                  id: p?.file?.id,
                  extension: '.pdf',
                  originalName: `${p.name?.replace(/ /g, '_')}.pdf`,
                }
              ),
            };
          })
        );
        setCertifications(res2);
      }
    };
    fetchData();
  }, []);

  return (
    <HomeContainer>
      <PageContainer>
        <div style={{ width: '100%' }}>
          <AimIconAndTextButton
            style={{
              padding: 0,
              margin: 0,
              marginLeft: -6,
              align_self: 'right',
            }}
            variant="none"
            onClick={() => {
              history.push(`/events/${eventId}/user/${participationId}`);
            }}
          >
            <ArrowBack style={{ color: theme.colors.greyScale.grey3 }} />
            <AimTypography variant="text">{i18n.actions.back}</AimTypography>
          </AimIconAndTextButton>
        </div>
        <Container>
          <Grid container>
            {certifications?.map((c, key) => (
              <Grid item key={key} sm={12} md={4}>
                <Card>
                  <CardHeader>
                    <AimTypography isUpperCase variant="h4">
                      {c?.name}
                    </AimTypography>
                  </CardHeader>
                  {/* {c?.isGeneric ? 'Generic' : 'Nominal'} */}
                  <AimTypography variant="text">
                    {`Click inside to download your certificate of participation`}
                  </AimTypography>
                  <AimIconAndTextButton
                    variant="primary"
                    typographyVariant="textBold"
                    disabled={!c?.link}
                    text="download"
                    isUpperCase
                    onClick={() => handleDownload(c.link)}
                  ></AimIconAndTextButton>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </PageContainer>
    </HomeContainer>
  );
};

export default Certifications;
