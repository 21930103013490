import React, { useEffect, useState } from 'react';

import { useIntl } from 'react-intl';

import {
  styled,
  AimTypography,
  CustomIntl,
  richTextToHTML,
  useAimMediaQuery,
} from '@aim/components';
import { appState } from '@aim/common';

import { HomeContainer } from './home/style';

import { translation } from './generalInfo/translation';
import { getEventConfigurationPageTitle } from '../shared/utilities';

const DescriptionContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
});

const PageContainer = styled('div')(({ isMobile }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: isMobile ? 'auto' : '70%',
  margin: '0 auto',
  flex: 1,
}));

export const PageContent = styled('div')({
  // background: theme.colors.greyScale.white,
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  width: '95%',
  margin: '0 auto',
  padding: '10px',
});

// const PageContent = styled('div')({
//   maxWidth: '1346px',
//   minWidth: '1346px',
// });

const GeneralInfo = () => {
  // i18n
  const intl = CustomIntl(useIntl());
  const i18n = translation.generalInfo(intl);
  const { isMobile } = useAimMediaQuery();

  // state
  const [eventConfiguration, setEventConfiguration] = useState({});
  const [description, setDescription] = useState();
  const [pageTitle, setPageTitle] = useState('');

  // effects
  useEffect(() => {
    const subscription = appState.eventConfiguration.subscribe(
      setEventConfiguration
    );
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (eventConfiguration?.generalInfoPageDescription) {
      const text = richTextToHTML(
        JSON.parse(eventConfiguration.generalInfoPageDescription)
      );
      setDescription(text);
    }

    const menuItemLabel = getEventConfigurationPageTitle(
      eventConfiguration?.eventGenericMenuItems?.items || [],
      intl,
      'info_page'
    );
    setPageTitle(menuItemLabel);
  }, [eventConfiguration]);

  return (
    <>
      <HomeContainer>
        <PageContainer isMobile={isMobile}>
          <PageContent>
            <AimTypography variant="h1">{pageTitle}</AimTypography>
            <DescriptionContainer>
              <div
                style={{
                  maxWidth: 1291,
                  textAlign: 'center',
                  marginLeft: 10,
                }}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </DescriptionContainer>
          </PageContent>
        </PageContainer>
      </HomeContainer>
    </>
  );
};

export default GeneralInfo;
