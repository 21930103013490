import { aws, appState, constants } from '@aim/common';
const { API } = aws;

const getEventSponsorFieldsQuery = /* GraphQL */ `
  query GetEvent(
    $id: ID!,
    $filter: ModelFieldContextFilterInput
    ) {
    getEvent(id: $id) {
      name
      services(filter: { serviceName: { eq: "${constants.Services.SPONSOR.key}" } }) {
        items {
          serviceName
          id
          customFields(filter: { isDeleted: { ne: false } }) {
            items {
              controlType
              id
              isDeleted
              label
              options
              placeholder
              contextsOfUse(filter: $filter) {
                items {
                  id
                  position
                  contextName
                  isRequired
                  isHidden
                }
              }
            }
          }
        }
      }
    }
  }
`;

const createParticipationGql = /* GraphQL */ `
  mutation CreateParticipation(
    $input: CreateParticipationInput!
    $condition: ModelParticipationConditionInput
  ) {
    createParticipation(input: $input, condition: $condition) {
      id
      createdAt
      cluster
      event {
        id
        name
      }
      paymentStatus
      registrationChannel
      isInvited
      status
      participationEventId
    }
  }
`;

const createFieldValueMutation = /* GraphQL */ `
  mutation CreateFieldValue(
    $input: CreateFieldValueInput!
    $condition: ModelFieldValueConditionInput
  ) {
    createFieldValue(input: $input, condition: $condition) {
      id
      value
      fieldValueFieldDefinitionId
      createdAt
      updatedAt
      fieldDefinition {
        id
        controlType
        label
        placeholder
        options
        isDeleted
        contextsOfUse {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;

// export const getFieldsListByContexts = ({ eventId, contextsOfUse }) =>
//   new Promise((resolve, reject) => {
//     showLoader();
//     aws.API.graphql({
//       query: getFieldsListByContextsQuery,
//       variables: {
//         id: eventId,
//         filter: { or: contextsOfUse.map((c) => ({ contextName: { eq: c } })) },
//       },
//     })
//       .then((response) => {
//         const out = {
//           customFields:
//             response.data.getEvent.services.items[0].customFields.items,
//           serviceId: response.data.getEvent.services.items[0].id,
//         };
//         resolve(out);
//       })
//       .catch((e) => {
//         console.error('list-event-field', e);
//         reject();
//       })
//       .finally(hideLoader);

export const getEventSponsorFields = (id, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    aws.API.graphql({
      query: getEventSponsorFieldsQuery,
      variables: {
        id,
        filter: { contextName: { eq: constants.Clusters.Sponsor.id } },
      },
    })
      .then((response) => {
        resolve({
          name: response?.data?.getEvent?.name,
          fields:
            response.data.getEvent?.services?.items?.[0]?.customFields?.items,
        });
      })
      .catch((e) => {
        console.error('list-event-sponsor-fields', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const createParticipation = (input) =>
  new Promise((resolve, reject) => {
    API.graphql({
      query: createParticipationGql,
      variables: { input },
    })
      .then((response) => {
        resolve(response.data.createParticipation);
      })
      .catch((e) => {
        console.error('create-participation', e);
        reject(e);
      });
  });

export const createFieldValue = (input) =>
  new Promise((resolve, reject) => {
    API.graphql({
      query: createFieldValueMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.createFieldValue))
      .catch((e) => {
        console.error('create-field-value', e);
        reject();
      });
  });

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);
