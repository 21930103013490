export default function (intl) {
  return {
    page: {
      title: intl.formatMessage({
        description: 'titlePage',
        defaultMessage: 'Edit Profile',
      }),
    },
    actions: {
      back: intl.formatMessage({
        description: 'back',
        defaultMessage: 'back',
      }),
      cancel: intl.formatMessage({
        description: 'cancel',
        defaultMessage: 'cancel',
      }),
      save: intl.formatMessage({
        description: 'save',
        defaultMessage: 'Save and close',
      }),
      changePassword: intl.formatMessage({
        description: 'change password settings user',
        defaultMessage: 'Change password',
      }),
      delete: intl.formatMessage({
        description: 'deleteContact',
        defaultMessage: 'Delete',
      }),
      saveSucces: intl.formatMessage({
        description: 'ProfileSavedSuccessMessage',
        defaultMessage: 'Profile edited successfully',
      }),
      saveFail: intl.formatMessage({
        description: 'ProfileSavedErrorMessage',
        defaultMessage: 'Error during profile update',
      }),
    },
    tabs: {
      settings: intl.formatMessage({
        description: 'settings',
        defaultMessage: 'Settings',
      }),
      personalData: intl.formatMessage({
        description: 'personalData',
        defaultMessage: 'Personal data',
      }),
      account: intl.formatMessage({
        description: 'account',
        defaultMessage: 'Account',
      }),
    },
    labels: {
      switch: {
        activationLabels: [
          intl.formatMessage({
            description: 'off',
            defaultMessage: 'Disabled',
          }),
          intl.formatMessage({
            description: 'on',
            defaultMessage: 'Active',
          }),
        ],
        visibilityLabels: [
          intl.formatMessage({
            description: 'off',
            defaultMessage: 'Invisible',
          }),
          intl.formatMessage({
            description: 'on',
            defaultMessage: 'Visible',
          }),
        ],
      },
      placeholder: intl.formatMessage({
        description: 'insert',
        defaultMessage: 'Insert',
      }),
      infoAndContacts: intl.formatMessage({
        description: 'infoAndContacts',
        defaultMessage: 'Info and contacts',
      }),
      givenName: intl.formatMessage({
        description: 'name',
        defaultMessage: 'Name',
      }),
      familyName: intl.formatMessage({
        description: 'lastname',
        defaultMessage: 'Last name',
      }),
      phone: intl.formatMessage({
        description: 'phone',
        defaultMessage: 'Phone',
      }),
      contactEmail: intl.formatMessage({
        description: 'email',
        defaultMessage: 'Email',
      }),
      facebook: intl.formatMessage({
        description: 'facebookProfile',
        defaultMessage: 'Facebook Profile',
      }),
      twitter: intl.formatMessage({
        description: 'twitterProfile',
        defaultMessage: 'Twitter Profile',
      }),
      linkedin: intl.formatMessage({
        description: 'linkedinProfile',
        defaultMessage: 'Linkedin Profile',
      }),
      instagram: intl.formatMessage({
        description: 'instagramrofile',
        defaultMessage: 'Instagram Profile',
      }),
      description: intl.formatMessage({
        description: 'description',
        defaultMessage: 'Description',
      }),
      profileImage: intl.formatMessage({
        description: 'profileImage',
        defaultMessage: 'Select profile image',
      }),
      coverImage: intl.formatMessage({
        description: 'coverImage',
        defaultMessage: 'Select cover image',
      }),
      birthDate: intl.formatMessage({
        description: 'birthDate',
        defaultMessage: 'Birthdate',
      }),
      nationality: intl.formatMessage({
        description: 'nationality',
        defaultMessage: 'Nationality',
      }),
      workJob: intl.formatMessage({
        description: 'workJob',
        defaultMessage: 'Job Title',
      }),
      businessPhone: intl.formatMessage({
        description: 'businessPhone',
        defaultMessage: 'Business phone',
      }),
      address: intl.formatMessage({
        description: 'address',
        defaultMessage: 'Address',
      }),
      companyName: intl.formatMessage({
        description: 'companyName',
        defaultMessage: 'Company name',
      }),
      password: intl.formatMessage({
        description: 'password',
        defaultMessage: 'New password',
      }),
      oldPassword: intl.formatMessage({
        description: 'oldPassword',
        defaultMessage: 'Current password',
      }),
      confirmPassword: intl.formatMessage({
        description: 'confirmPassword',
        defaultMessage: 'Confirm new password',
      }),
      isPublic: intl.formatMessage({
        description: 'isPublic',
        defaultMessage: 'PERSONAL PROFILE',
      }),
      isPublicDescription: intl.formatMessage({
        description: 'isPublicDescription',
        defaultMessage:
          'By disabling this feature, your personal profile will not be accessible to other participants. Furthermore, your info (name, surname, company, email user, work profile ...) will not be made available elsewhere on the site (Chat, etc)',
      }),
      showMyAgenda: intl.formatMessage({
        description: 'showMyAgenda',
        defaultMessage: 'VIEW MY AGENDA',
      }),
      showMyAgendaDescription: intl.formatMessage({
        description: 'showMyAgendaDescription',
        defaultMessage:
          'By activating this feature, you will allow other attendees to view the appointments and sessions you have subscribed to',
      }),

      showBiography: intl.formatMessage({
        description: 'showBiography',
        defaultMessage: 'BIOGRAPHY',
      }),
      showBiographyDescription: intl.formatMessage({
        description: 'showBiographyDescription',
        defaultMessage:
          'By enabling this section, other participants will be able to see the Biography section in your profile',
      }),
    },
  };
}
