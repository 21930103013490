import React, { useEffect, useState, useRef, Fragment } from 'react';

import { useForm } from 'react-hook-form';
import { isMobileOnly } from 'react-device-detect';
import { useHistory, useParams, useLocation } from 'react-router-dom';

import {
  AimIconAndTextButton,
  AimSnackbarSeverity,
  AimTitleAndButtons,
  AimTypography,
  AimSnackbar,
  CustomIntl,
  styled,
  AimRichText,
  // theme,
} from '@aim/components';
import { aws, constants, opensearch, appState } from '@aim/common';

import './allotmentHotelDetail/flickity.css';

import { getHotelData } from './allotmentHotelDetail/gqlHelper';

import Grid from '@material-ui/core/Grid';
import GradeIcon from '@material-ui/icons/Grade';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import ArrowBack from '@material-ui/icons/ArrowBack';
// import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { useIntl } from 'react-intl';
// import { Slide } from 'react-slideshow-image';
import Flickity from 'react-flickity-component';

import 'react-slideshow-image/dist/styles.css';
import { translation } from './allotmentHotelDetail/translation';
import WebAvailabilityBox from './allotmentHotelDetail/components/WebAvailabilityBox';
import MobileAvailabilityBox from './allotmentHotelDetail/components/MobileAvailabilityBox';

//--- CUSTOM COMPONENTS
const HotelDetailPage = styled('div')({
  width: '90%',
  height: '100%',
  margin: 'auto',
});

const SliderImageContainer = styled('div')({
  minWidth: 250,
  minHeight: 250,
  // border: '1px solid black',
  aspectRatio: 1,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '50% 50%',
});

const makeBookingOverviewQuery = ({
  eventId,
  hotelEventVentureId,
  checkIn,
  checkOut,
  nights,
}) => ({
  fullOpensearchResponse: true,
  index: 'allotmentbookingoverview',
  filterBody: {
    query: {
      bool: {
        filter: [
          {
            match_phrase: {
              eventId: eventId,
            },
          },
          {
            match_phrase: {
              hotelEventVentureId: hotelEventVentureId,
            },
          },
          {
            range: {
              hotelRoomByDateDate: {
                gte: checkIn,
                lt: checkOut,
              },
            },
          },
          {
            range: {
              freeRooms: {
                gt: 0,
              },
            },
          },
          {
            match_phrase: {
              hotelRoomAvailabilityChannelType:
                constants.AllotmentChannels.WEB.id,
            },
          },
        ],
      },
    },
    aggs: {
      rooms: {
        terms: {
          field: 'hotelRoomId.keyword',
          size: 10000,
        },
        aggs: {
          dates: {
            terms: {
              field: 'hotelRoomByDateDate',
              size: 10000,
            },
            aggs: {
              date_hits: {
                top_hits: {
                  sort: [
                    {
                      createdAt: 'desc',
                    },
                  ],
                  size: 1,
                },
              },
            },
          },
          all_night_filter: {
            bucket_selector: {
              buckets_path: {
                count: 'dates._bucket_count',
              },
              script: `params.count == ${nights}`,
            },
          },
          room_hits: {
            top_hits: {
              sort: [
                {
                  createdAt: 'desc',
                },
              ],
              size: 1,
            },
          },
        },
      },
    },
    size: 1,
    from: 0,
  },
});

const makeBookingOverviewQuerySpeaker = ({
  eventId,
  hotelEventVentureId,
  checkIn,
  checkOut,
  nights,
}) => ({
  fullOpensearchResponse: true,
  index: 'allotmentbookingoverview',
  filterBody: {
    query: {
      bool: {
        filter: [
          {
            match_phrase: {
              eventId: eventId,
            },
          },
          {
            match_phrase: {
              hotelEventVentureId: hotelEventVentureId,
            },
          },
          {
            range: {
              hotelRoomByDateDate: {
                gte: checkIn,
                lt: checkOut,
              },
            },
          },
          {
            range: {
              freeRooms: {
                gt: 0,
              },
            },
          },
          {
            match_phrase: {
              hotelRoomAvailabilityChannelType:
                constants.AllotmentChannels.SPEAKER.id,
            },
          },
        ],
      },
    },
    aggs: {
      rooms: {
        terms: {
          field: 'hotelRoomId.keyword',
          size: 10000,
        },
        aggs: {
          dates: {
            terms: {
              field: 'hotelRoomByDateDate',
              size: 10000,
            },
            aggs: {
              date_hits: {
                top_hits: {
                  sort: [
                    {
                      createdAt: 'desc',
                    },
                  ],
                  size: 1,
                },
              },
            },
          },
          all_night_filter: {
            bucket_selector: {
              buckets_path: {
                count: 'dates._bucket_count',
              },
              script: `params.count == ${nights}`,
            },
          },
          room_hits: {
            top_hits: {
              sort: [
                {
                  createdAt: 'desc',
                },
              ],
              size: 1,
            },
          },
        },
      },
    },
    size: 1,
    from: 0,
  },
});

// ALLOTMENT COMPONENT
const AllotmentHotelDetail = () => {
  const { control, handleSubmit, reset } = useForm({
    reValidateMode: 'onChange',
    shouldUnregister: false,
    defaultValues: { rooms: [] },
  });

  const [hotelDetails, setHotelDetails] = useState(null);
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [slideContainerWidth, setSlideContainerWidth] = useState(null);

  const intl = CustomIntl(useIntl());
  const i18n = translation.allotment(intl);
  const history = useHistory();
  const { eventId, hotelEventVentureId } = useParams();
  const { state } = useLocation();
  const slideContainerRef = useRef();

  const language = appState.locale.getValue();

  const [participation] = useState(appState.getCurrentParticipation());

  useEffect(() => {
    const hotelDetailsInfo = async () => {
      const queryFunction = participation.isSpeaker
        ? makeBookingOverviewQuerySpeaker
        : makeBookingOverviewQuery;

      const query = queryFunction({
        eventId,
        hotelEventVentureId,
        checkIn: state.checkIn,
        checkOut: state.checkOut,
        nights: state.nights,
      });

      // const query = makeBookingOverviewQuery({
      //   eventId,
      //   hotelEventVentureId,
      //   checkIn: state.checkIn,
      //   checkOut: state.checkOut,
      //   nights: state.nights,
      // });

      const res = await opensearch.sendStandardQuery(query);

      console.log('res', res);
      const hotelHit = res.hits.hits?.[0]?._source;
      if (!hotelHit) {
        return;
      } else {
        let __hotelData = await getHotelData(hotelEventVentureId);
        const returnedHotelDataData = __hotelData?.hotel;
        const hotelData = {
          ...returnedHotelDataData,
          descriptions: [...returnedHotelDataData?.descriptions?.items],
          images: [...returnedHotelDataData?.images?.items],
        };

        const totalRooms = res.aggregations.rooms.buckets.map((r) => {
          const roomData = r.room_hits.hits.hits[0]._source;
          const room = r.dates.buckets.reduce(
            (prev, curr) => {
              const {
                hotelRoomPrice,
                hotelRoomAvailabilityId,
              } = curr.date_hits.hits.hits[0]._source;

              hotelRoomPrice.forEach((p) => {
                prev.prices[p.occupancy] = {
                  ...(prev.prices?.[p.occupancy] || {}),
                  price: prev.prices[p.occupancy]?.price || 0 + p.price,
                  occupancy: p.occupancy,
                };
              });
              prev.hotelRoomAvailabilitiesIds = [
                ...prev.hotelRoomAvailabilitiesIds,
                hotelRoomAvailabilityId,
              ];
              return prev;
            },
            {
              id: roomData.hotelRoomId,
              name: roomData.hotelRoomName,
              frontofficeName:
                roomData.hotelRoomFrontofficeName || roomData.hotelRoomName,
              maxOccupancy: roomData.hotelRoomMaxOccupancy,
              freeRooms: roomData.freeRooms,
              hotelRoomAvailabilitiesIds: [],
              prices: {},
            }
          );
          return room;
        });
        console.log('totalRooms', totalRooms);
        // const language = appState.locale.getValue();
        // const hotelEventVenture = await getHotelInformations(state, language);
        await getS3Images(hotelData);
        console.log('hotelData ', hotelData);
        setHotelDetails({
          ...hotelData,
          highlightedRooms: totalRooms.filter(
            (r) => r.maxOccupancy === state.guestsNumber
          ),
          otherRooms: totalRooms
            .filter((r) => r.maxOccupancy !== state.guestsNumber)
            ?.sort((a, b) => {
              return a.maxOccupancy - b.maxOccupancy;
            }),
        });
      }
    };

    hotelDetailsInfo();
  }, []);

  useEffect(() => {
    slideContainerRef?.current?.clientWidth &&
      setSlideContainerWidth(slideContainerRef?.current?.clientWidth);
  }, []);

  const onClickSave = async (formData) => {
    const rooms = formData.rooms;
    if (rooms.length === 0) {
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.availability.confirmError,
      });
      return;
    }
    history.push({
      pathname: `/events/${eventId}/allotment/${hotelEventVentureId}/booking`,
      state: {
        ...state,
        rooms,
        hotelDetails,
      },
    });
  };

  const getS3Images = async (hotel) => {
    const s3Folder = `hotels/${hotel.id}/images/`;
    const hotelImages = await Promise.all(
      hotel?.images?.map(async (i) =>
        aws.Storage.get(`${s3Folder}${i.id}${i.extension}`)
      ) || []
    );
    hotel.imagesS3Link = hotelImages;
    return hotel;
  };

  const createHotelStars = () => {
    let rows = [];
    for (let i = 0; i < hotelDetails?.stars; i++) {
      rows.push(<GradeIcon key={i} style={{ color: 'orange' }} />);
    }
    return (
      <div>
        {hotelDetails?.name} {rows}
      </div>
    );
  };

  const flickityOptions = {
    initialIndex: 0,
    resize: true,
    cellAlign: 'center',
    contain: true,
    imagesLoaded: true,
    adaptiveHeight: true,
    pageDots: false,
    prevNextButtons: !isMobileOnly ? true : false,
  };

  const nextLanguage = hotelDetails?.descriptions?.find(
    (x) => x.language === language
  );

  return (
    <>
      <HotelDetailPage>
        <AimIconAndTextButton
          isUpperCase
          variant="none"
          text={i18n.page.back}
          style={{
            padding: 0,
          }}
          onClick={() => history.push(`/events/${eventId}/allotment`)}
        >
          <ArrowBack />
        </AimIconAndTextButton>
        <AimTitleAndButtons title={createHotelStars()} />
        <Grid
          container
          direction="column"
          style={{
            backgroundColor: '#F8F6FA',
            padding: '3%',
            marginBottom: '5%',
          }}
        >
          <Grid item xs={12} direction="column">
            <div>
              <b>{`${i18n.page.address}: `}</b>
              {hotelDetails?.address}
            </div>
            <div>
              <b>Distanza: </b> 2km
            </div>
            <div>
              {nextLanguage && !isMobileOnly && (
                <AimRichText
                  readOnly
                  value={JSON.parse(nextLanguage?.description)}
                  formControlStyle={{
                    margin: 0,
                  }}
                  editorStyleOverride={{
                    margin: 0,
                    padding: 0,
                    minHeight: 10,
                    // backgroundColor: '#F8F6FA',
                  }}
                />
              )}
            </div>
          </Grid>
          <Grid
            ref={slideContainerRef}
            container
            style={{ marginTop: '3%', marginBottom: '3%' }}
          >
            <Grid item xs={12}>
              {slideContainerWidth && hotelDetails?.imagesS3Link?.length > 0 && (
                // <Slide
                //   style={{
                //     width: slideContainerWidth,
                //   }}
                //   {...{
                //     duration: 500,
                //     autoplay: false,
                //     infinite: false,
                //     indicators: false,
                //     slidesToShow: isMobileOnly
                //       ? 1
                //       : hotelDetails?.imagesS3Link?.length > 6
                //       ? 6
                //       : hotelDetails?.imagesS3Link?.length,
                //     slidesToScroll: isMobileOnly
                //       ? 1
                //       : hotelDetails?.imagesS3Link?.length > 6
                //       ? 6
                //       : hotelDetails?.imagesS3Link?.length,
                //     transitionDuration: 300,
                //     prevArrow: <ArrowBack style={{ color: 'orange' }} />,
                //     nextArrow: <ArrowForwardIcon style={{ color: 'orange' }} />,
                //   }}
                // >
                //   {hotelDetails?.imagesS3Link?.map((link, idx) => {
                //     return (
                //       <SliderImageContainer
                //         key={idx}
                //         style={{
                //           maxWidth: 200,
                //           maxHeight: 200,
                //           margin: 'auto',
                //           backgroundImage: `url("${link}")`,
                //         }}
                //       />
                //     );
                //   })}
                // </Slide>
                <Flickity
                  className={'carousel'} // default ''
                  elementType={'div'} // default 'div'
                  options={flickityOptions} // takes flickity options {}
                  disableImagesLoaded={false} // default false
                  reloadOnUpdate // default false
                  static // default false
                >
                  {hotelDetails?.imagesS3Link?.map((link, idx) => {
                    return (
                      <SliderImageContainer
                        key={idx}
                        style={{
                          maxWidth: 500,
                          maxHeight: 500,
                          // margin: 'auto',
                          marginLeft: idx > 0 ? 5 : 0,
                          marginRight:
                            idx === hotelDetails?.imagesS3Link?.length - 1
                              ? 0
                              : 5,
                          backgroundImage: `url("${link}")`,
                        }}
                      />
                    );
                  })}
                </Flickity>
              )}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <AimTypography
                variant="h4"
                style={{
                  marginBottom: '3%',
                  borderBottom: '1px solid rgba(132, 129, 154, 0.5)',
                }}
              >
                {i18n.availability.title}
              </AimTypography>
            </Grid>
            <form
              style={{ width: '100%', margin: 'auto' }}
              onSubmit={handleSubmit(onClickSave)}
            >
              <Grid container direction="column" spacing={2}>
                {hotelDetails?.highlightedRooms?.length > 0 ||
                hotelDetails?.otherRooms?.length > 0 ? (
                  isMobileOnly ? (
                    <MobileAvailabilityBox
                      i18n={i18n}
                      control={control}
                      highlightedRooms={hotelDetails?.highlightedRooms}
                      otherRooms={hotelDetails?.otherRooms}
                    />
                  ) : (
                    <WebAvailabilityBox
                      i18n={i18n}
                      control={control}
                      highlightedRooms={hotelDetails?.highlightedRooms}
                      otherRooms={hotelDetails?.otherRooms}
                    />
                  )
                ) : (
                  <div>No rooms availabile</div>
                )}
                <Grid container justifyContent="flex-end">
                  <AimIconAndTextButton
                    onClick={() => {
                      reset({ rooms: [] });
                    }}
                    variant="secondary"
                    text={i18n.availability.cancel}
                  >
                    <ClearIcon />
                  </AimIconAndTextButton>
                  <AimIconAndTextButton
                    type="submit"
                    variant="primary"
                    text={i18n.availability.confirm}
                  >
                    <CheckIcon />
                  </AimIconAndTextButton>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </HotelDetailPage>
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </>
  );
};

export default AllotmentHotelDetail;
