import { aws } from '@aim/common';

export const callLibreOfficeLambda = async ({ paperId, participations }) => {
  return await aws.standardAPI.post('aimlambdaproxy', '/async/', {
    body: {
      lambdaName: 'aimlibreofficelambda',
      paperId,
      participations,
    },
  });
};
