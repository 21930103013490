import React, { useState, useEffect } from 'react';
import { appState } from '@aim/common';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import {
  theme,
  styled,
  AimIconAndTextButton,
  AimTypography,
  AimSnackbar,
  CustomIntl,
  useAimMediaQuery,
} from '@aim/components';

import useI18n from './profileEdit/i18n';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { useUserShowcaseHelper } from './shared/userShowcaseGqlHelper';
import { useUserSettingsHelper } from './shared/userSettingsGqlHelper';
import PersonalDataEdit from './profileEdit/PersonalDataEdit';
import ProfileSettings from './profileEdit/ProfileSettings';

const HomeContainer = styled('div')({
  background: theme.colors.greyScale.white,
  flex: 1,
  overflowX: 'hidden',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '1280px',
});

const PageContainer = styled('div')({
  background: theme.colors.greyScale.white,
  height: '100%',
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  margin: '0 auto',
  padding: '10px',
  alignItems: 'center',
});

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  width: '100%',
  margin: '0 auto',
  backgroundColor: theme.colors.greyScale.backgroundGrey,
  // padding: '16px',
  padding: '8px',
});

const ProfileEdit = () => {
  const intl = CustomIntl(useIntl());
  const i18n = useI18n(intl);
  const { isMobile } = useAimMediaQuery();

  const { eventId, participationId } = useParams();

  const history = useHistory();

  const showcaseHelper = useUserShowcaseHelper();
  const settingsHelper = useUserSettingsHelper();

  const [snackbar, setSnackbar] = useState({ isOpen: false });

  const [fetchedPersonalData, setFetchedPersonalData] = useState();
  const [fetchedSettingsData, setFetchedSettingsData] = useState();
  const [participation, setParticipation] = useState();
  const [tabController, setTabController] = useState(0);

  const tabs = ['personalData', 'settings'];

  const checkIsValidUser = async () => {
    const loggedParticipation = appState.user.getValue()?.userAndParticipation
      ?.participation;
    if (loggedParticipation?.id === participationId)
      setParticipation(loggedParticipation);
    else history.goBack();
  };

  useEffect(() => {
    if (!participation) checkIsValidUser();
    else {
      fetchProfileInfo();
      fetchSettingsInfo();
    }
  }, [participation]);

  const fetchProfileInfo = async () => {
    const user = participation;
    const showcase = await showcaseHelper.get(participation.id);
    const values = {
      givenName: user.givenName,
      familyName: user.familyName,
      ...showcase,
    };

    setFetchedPersonalData(values);
  };

  const fetchSettingsInfo = async () => {
    const settings = await settingsHelper.get(participation.id);
    const value = {
      ...settings,
    };
    setFetchedSettingsData(value);
  };

  const navigateBack = () => {
    history.push(`/events/${eventId}/user/${participationId}`);
  };

  const renderTab = () => {
    switch (tabController) {
      case 0:
        return (
          <PersonalDataEdit
            fetchProfileInfo={fetchProfileInfo}
            navigateBack={navigateBack}
            setSnackbar={setSnackbar}
            eventId={eventId}
            fetchedData={fetchedPersonalData}
            participationId={participationId}
            participation={participation}
          />
        );
      case 1:
        return (
          <ProfileSettings
            fetchSettingsInfo={fetchSettingsInfo}
            navigateBack={navigateBack}
            setSnackbar={setSnackbar}
            cluster={participation.cluster}
            eventId={eventId}
            fetchedData={fetchedSettingsData}
            participationId={participationId}
          />
        );
    }
  };

  return (
    <HomeContainer>
      <PageContainer>
        <div style={{ margin: '0 auto', marginTop: 15 }}>
          <AimIconAndTextButton
            style={{
              padding: 0,
              margin: 0,
              marginLeft: -6,
              align_self: 'right',
            }}
            variant="none"
            onClick={() => navigateBack()}
          >
            <ArrowBack style={{ color: theme.colors.greyScale.grey3 }} />
            <AimTypography variant="text">{i18n.actions.back}</AimTypography>
          </AimIconAndTextButton>
          <AimTypography variant="h1">{i18n.page.title}</AimTypography>
          <Container>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                alignContent: 'center',
                margin: '20px 0',
              }}
            >
              {tabs.map((tab, idx) => {
                return (
                  <AimTypography
                    key={tab + idx}
                    variant={isMobile ? 'text' : 'h2'}
                    boxStyle={{
                      fontWeight: tabController === idx ? 'bold' : 'unset',
                    }}
                    style={{
                      borderBottom:
                        tabController === idx
                          ? `3px solid ${
                              appState.eventConfiguration.getValue()
                                ?.primaryColor
                            } `
                          : `3px solid #F3F3F4`,

                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setTabController(idx);
                    }}
                  >
                    {i18n.tabs[tab]}
                  </AimTypography>
                );
              })}
            </div>
            <Grid container>{renderTab()}</Grid>
          </Container>
        </div>
      </PageContainer>
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </HomeContainer>
  );
};

export default ProfileEdit;
