import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { appState, aws } from '@aim/common';

const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

const CheckoutHandler = () => {
  const { paymentId } = useParams();
  const history = useHistory();

  useEffect(() => {
    showLoader();

    try {
      // const baseurl =
      //   window.location.hostname === 'localhost'
      //     ? 'localhost:9001'
      //     : window.location.hostname;
      aws.standardAPI
        .post('aimlambdaproxy', '/pay/result', {
          body: {
            paymentId,
          },
        })
        .then((response) => {
          console.log('response pay result ', response);
          hideLoader();
          history.push(response.redirect);
        });
    } catch (e) {
      hideLoader();
      console.error(e);
    }
  }, []);

  return <div>checkouthandler</div>;
};

export default CheckoutHandler;
