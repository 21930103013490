import React from 'react';

import { useHistory, useParams } from 'react-router-dom';

import {
  styled,
  AimIconAndTextButton,
  CustomIntl,
  AimTypography,
} from '@aim/components';
import { appState } from '@aim/common';

import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Popover from '@material-ui/core/Popover';

import { useIntl } from 'react-intl';
import useI18n from '../translation';

import BookingTable from '../../sponsorCart/BookingTable';

const ContentContainer = styled('div')({
  padding: 20,
});

const ActionsContainer = styled('div')({
  padding: 20,
  display: 'flex',
  justifyContent: 'flex-end',
});

const CartPopover = ({ cartItems, anchorEl, isOpen, handleClose }) => {
  const history = useHistory();
  const { eventId } = useParams();

  const intl = CustomIntl(useIntl());
  const i18nSponsorCart = useI18n.sponsorCart(intl);
  const i18n = useI18n.cartPopover(intl);

  const participation = appState.user.getValue()?.userAndParticipation
    ?.participation;

  const sponsor = participation?.sponsor; // || participation?.sponsorStaff?.sponsor;

  const onCartButtonClick = () => {
    history.push(`/events/${eventId}/${sponsor?.id}/cart`);
    handleClose();
  };

  return (
    <Popover
      open={isOpen}
      onClose={handleClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <ContentContainer>
        <AimTypography variant="h1">{i18n.title}</AimTypography>
        <BookingTable
          bookings={cartItems}
          i18n={i18nSponsorCart}
          sinteticMode
        />
      </ContentContainer>
      <ActionsContainer>
        <AimIconAndTextButton
          onClick={onCartButtonClick}
          variant="yellow"
          text={i18n.goToCart}
        >
          <ShoppingCartIcon />
        </AimIconAndTextButton>
      </ActionsContainer>
    </Popover>
  );
};

export { CartPopover };
