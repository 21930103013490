import { set, startOfDay, endOfDay, isAfter, isBefore } from 'date-fns';
import { useDataHelper, richTextToHTML } from '@aim/components';
import { aws, appState } from '@aim/common';

import MAIL_BODY from '../../assets/mail/breakoutRoom/MailBrHeader';
import commonFields from '../../assets/mail/breakoutRoom/CommonField';
import { mailBrInvitation } from '../../assets/mail/breakoutRoom/MailBrInvitation';
import { mailBrResultAccept } from '../../assets/mail/breakoutRoom/MailBrResultAccept';
import { mailBrResultReject } from '../../assets/mail/breakoutRoom/MailBrResultReject';
import { mailBrCancellation } from '../../assets/mail/breakoutRoom/MailBrCancellation';
import MAIL_FOOTER from '../../assets/mail/breakoutRoom/MailBrFooter';

export const useBreakoutRoomUtils = () => {
  /**
   * EXTENDS MAIL HELPER
   */
  const {
    data: invitationMailData,
    body: invitationMailBody,
  } = mailBrInvitation();
  const { data: acceptMailData, body: acceptMailBody } = mailBrResultAccept();
  const { data: rejectMailData, body: rejectMailBody } = mailBrResultReject();
  const { data: cancelMailData, body: cancelMailBody } = mailBrCancellation();
  /**
   * EXTENDS DATA HELPER
   */

  const { useBreakoutRoomDataHelpers } = useDataHelper();
  const {
    getHours: _getHours,
    parseBreakoutRoom: _parseBreakoutRoom,
    setDate: _setDate,
    getDate: _getDate,
  } = useBreakoutRoomDataHelpers();

  function getHours(hhmm) {
    return _getHours(hhmm);
  }

  function getDate(date) {
    return _getDate(date);
  }

  function parseBreakoutRoom(breakoutRoom, setPrice = true) {
    return _parseBreakoutRoom(breakoutRoom, setPrice);
  }

  function setDate(date, hhmm) {
    return _setDate(date, hhmm);
  }

  /**
   * BREAKOUT ROOM HELPERS
   */

  function checkIsPurchased(br, history) {
    if (br?.buyOperation?.purchase?.id) return;
    else history.goBack();
  }

  const states = {
    ACCEPTED: 'accepted',
    REJECTED: 'rejected',
    PENDING: 'pending',
    CANCELED: 'canceled',
  };

  function parseParticipation(participation) {
    const anagrafica = `${participation.givenName} ${participation.familyName}`;
    let userTxt = capitalize(`${anagrafica} (${participation.email})`);
    String.prototype.isEmpty = function () {
      return this.length === 0 || !this.trim();
    };
    if (anagrafica.isEmpty()) userTxt = participation.email;
    return {
      ...participation,
      userTxt: userTxt,
      value: participation.email,
    };
  }

  function parseInvitation(invitation) {
    const participation = invitation.participation;
    return {
      ...invitation,
      participation: parseParticipation(participation),
    };
  }

  function parseEmailInvitation(invitationunregistered) {
    return {
      state: invitationunregistered.state,
      ...(invitationunregistered.note && {
        note: invitationunregistered.note,
      }),
      participation: {
        userTxt: invitationunregistered.email,
        value: invitationunregistered.email,
        user: {
          email: invitationunregistered.email,
        },
      },
    };
  }

  function toDate(date) {
    try {
      return new Date(date);
    } catch (e) {
      return date;
    }
  }

  function isJoinVisible(event, isLimit = false) {
    try {
      const start = toDate(event.start);
      const now = new Date();
      const end = toDate(event.end);
      return (
        isAfter(now, isLimit ? startOfDay(start) : start) &&
        isBefore(now, isLimit ? endOfDay(end) : end)
      );
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  function getStatesOfInvitations(invitations) {
    const states = {};
    invitations.map((i) => {
      if (i.state) {
        Object.keys(states).find((k) => k === i.state)
          ? states[i.state]++
          : (states[i.state] = 1);
      } else {
        if (!states.undefined) states.undefined = 0;
        states.undefined += 1;
      }
    });
    return states;
  }

  /**
   * STRING MANIPULATION
   */

  function capitalizeFirst(str) {
    if (str) return str[0].toUpperCase() + str.slice(1).toLowerCase();
    return null;
  }

  function capitalize(str) {
    const arr = str.split(' ').map((s) => capitalizeFirst(s));
    if (arr.length > 0) return arr.join(' ');
    return null;
  }

  /**
   * ARRAY MANIPULATION
   */

  function splitArray(array, condition) {
    const [pass, fail] = array.reduce(
      ([p, f], e) => (condition(e) ? [[...p, e], f] : [p, [...f, e]]),
      [[], []]
    );
    return [pass, fail];
  }

  /**
   * EMAIL
   */
  // const mailSource = process.env.MAIL_SOURCE;

  const emailTypes = {
    INVITATION: 'invitation',
    RESULT_ACCEPT: 'result_accept',
    RESULT_REJECT: 'result_reject',
    CANCELLATION: 'cancellation',
  };

  const getEmailObject = (type, sponsor, i18n) => {
    let txt = `${i18n.mail.object[type]} ${sponsor.name}`;
    return txt;
  };

  const getTimeGreeting = () => {
    return new Date() < set(new Date(), { hours: 12, minutes: 0 })
      ? 'morning'
      : 'evening';
  };

  async function generateBreakoutRoomEmail(type, data) {
    const {
      eventId,
      breakoutRoom,
      slot,
      i18n,
      email,
      rawMessage,
      isPublic,
    } = data;
    const sponsor = breakoutRoom.sponsor;
    let replace = 'INVALID TYPE';
    let mailData = {
      [`${commonFields.TIME_GREETING}`]:
        '\n' + i18n.mail.invitation.timeGreeting[`${getTimeGreeting()}`],
      [`${commonFields.SPONSOR_NAME}`]: sponsor.name,
      [`${commonFields.GREATING}`]: '\n' + i18n.mail.invitation.greeting,
      [`${commonFields.SIGN}`]: i18n.mail.invitation.sign,
      [`${commonFields.EMAIL_MESSAGE_2}`]: i18n.mail.invitation.message_2,
      [`${commonFields.DATE}`]:
        getDate(slot.date || slot.start) +
        ' ' +
        i18n.mail.invitation.hourRangeMessage1 +
        ' ' +
        getHours(slot.start) +
        ' ' +
        i18n.mail.invitation.hourRangeMessage2 +
        ' ' +
        getHours(slot.end) +
        '\n',
    };
    let buttonLinkRegistrationBase = '';
    let buttonLinkBase = '';
    switch (type) {
      case emailTypes.INVITATION:
        buttonLinkBase = `${window.location.origin}/events/${eventId}/${slot.id}/${email}/mailResponse`;
        buttonLinkRegistrationBase = `${window.location.origin}/events/${eventId}/signup`;
        replace = invitationMailBody;
        mailData = {
          ...mailData,
          [`${commonFields.INVITE_TITLE}`]: i18n.mail.invitation.title,
          [`${commonFields.EMAIL_MESSAGE_1}`]: i18n.mail.invitation.message_1,
          [`${invitationMailData.LINK}`]: buttonLinkBase,
          [`${invitationMailData.LINK_TXT}`]: i18n.mail.invitation.linkText,
          [`${invitationMailData.EMAIL_REGISTRATION_MESSAGE}`]:
            '\n' + i18n.mail.invitation.emailRegistrationMessage,
          [`${invitationMailData.LINK_REGISTRATION}`]: buttonLinkRegistrationBase,
          [`${invitationMailData.LINK_REGISTRATION_TXT}`]: i18n.mail.invitation
            .linkRegistrationText,
          [`${invitationMailData.MESSAGE}`]: richTextToHTML(rawMessage),
        };
        Object.entries(mailData).map(([key, value]) => {
          replace = replace.replace(key, value);
        });
        break;
      case emailTypes.RESULT_ACCEPT:
        buttonLinkBase = `${window.location.origin}/events/${eventId}/sponsors/${sponsor.id}/slots/${slot.id}`;
        // const evnOption = {
        //   start: [2018, 5, 30, 6, 30],
        //   duration: { hours: 6, minutes: 30 },
        //   title: slot.title,
        //   description: `Event with ${sponsor.name}`,
        //   url: buttonLinkBase,
        //   status: 'CONFIRMED',
        //   organizer: { name: sponsor.name },
        // };
        // const eventCal = ics.createEvent(evnOption);
        replace = acceptMailBody;
        mailData = {
          ...mailData,
          [`${commonFields.INVITE_TITLE}`]: i18n.mail.invitation.accept_title,
          [`${commonFields.EMAIL_MESSAGE_1}`]: i18n.mail.invitation
            .accept_message_1,
          [`${acceptMailData.LINK}`]: buttonLinkBase,
          [`${acceptMailData.LINK_TXT}`]: i18n.mail.invitation.acceptLinkText,
          // [`${acceptMailData.ICS}`]: eventCal,
          // [`${acceptMailData.ICS_TXT}`]: i18n.mail.invitation.icsLinkText,
        };
        Object.entries(mailData).map(([key, value]) => {
          replace = replace.replace(key, value);
        });
        break;
      case emailTypes.RESULT_REJECT:
        replace = rejectMailBody;
        mailData = {
          ...mailData,
          [`${commonFields.INVITE_TITLE}`]: i18n.mail.invitation.reject_title,
          [`${commonFields.EMAIL_MESSAGE_1}`]: i18n.mail.invitation
            .reject_message_1,
          [`${commonFields.GREATING}`]: i18n.mail.invitation.reject_greeting,
        };
        Object.entries(mailData).map(([key, value]) => {
          replace = replace.replace(key, value);
        });
        break;
      case emailTypes.CANCELLATION:
        replace = cancelMailBody;
        mailData = {
          ...mailData,
          [`${commonFields.INVITE_TITLE}`]: i18n.mail.invitation.cancel_title,
          [`${commonFields.EMAIL_MESSAGE_1}`]: i18n.mail.invitation
            .cancel_message_1,
          [`${cancelMailData.MESSAGE}`]: `${richTextToHTML(rawMessage)} ${
            i18n.mail.invitation.cancel_message_2
          }`,
          [`${commonFields.GREATING}`]: i18n.mail.invitation.cancel_greeting,
        };
        Object.entries(mailData).map(([key, value]) => {
          replace = replace.replace(key, value);
        });
        break;
    }
    let mailBody = MAIL_BODY.replace('##MAIL_BODY##', replace);
    mailBody = mailBody.replace('##COPYRIGHT##', '');
    mailBody = mailBody.replace('##FOOTER##', MAIL_FOOTER);
    const logoUrl = `${process.env.TEMPLATE_FOLDER_URL}/template_image.png`;
    mailBody = mailBody.replace('##LOGO##', logoUrl);
    // var wnd = window.open('about:blank', '', '_blank');
    // wnd.document.write(mailBody);
    const mailObject = getEmailObject(type, sponsor, i18n);
    const mailParams = {
      addresses: [email],
      subject: mailObject,
      body: mailBody,
      language: appState.locale.getValue(),
    };
    let res;
    if (isPublic) {
      //TODO:abilitare
      console.warn('EMAIL NON INVIATA, MANCANO PERMESSI');

      res = await sendMailByLambda(mailParams);
      //TODO: parse della res
    } else {
      res = await aws.sendMail({ body: mailParams });
    }
    return res;
  }

  const apiName = 'breakoutRoomInvitations';

  const callLambda = async ({ email, action, slotId, eventId, motivation }) => {
    const input = {
      body: {
        email,
        action,
        slotId,
        eventId,
        ...(motivation && { motivation }),
      },
    };
    const path = '/handleInvitation';
    try {
      const res = await aws.standardAPI.post(apiName, path, input);
      return res;
    } catch (e) {
      const err = e.response.data.message;
      const otherData = e.response.data.message.errors;
      throw new Error(otherData ? otherData : err);
    }
  };

  const getSlotFromLambda = async (slotId) => {
    const input = {
      body: {
        slotId,
      },
    };
    try {
      const path = '/slotInfo';
      const res = await aws.standardAPI.post(apiName, path, input);
      return res.data;
    } catch (e) {
      const err = e.response.data.message;
      const otherData = e.response.data.message.errors;
      throw new Error(otherData ? otherData : err);
    }
  };

  const sendMailByLambda = async (mailParams) => {
    const input = {
      body: {
        mailParams,
      },
    };
    try {
      const path = '/sendBrEmail';
      const res = await aws.standardAPI.post(apiName, path, input);
      return res.data;
    } catch (e) {
      console.error(e);
      const err = e.response.data.message;
      const otherData = e.response.data.message.errors;
      throw new Error(otherData ? otherData : err);
    }
  };

  return {
    getHours,
    getDate,
    parseBreakoutRoom,
    setDate,
    splitArray,
    emailTypes,
    generateBreakoutRoomEmail,
    capitalize,
    capitalizeFirst,
    toDate,
    isJoinVisible,
    getStatesOfInvitations,
    parseParticipation,
    parseInvitation,
    parseEmailInvitation,
    checkIsPurchased,
    states,
    callLambda,
    getSlotFromLambda,
  };
};
