import React from 'react';

import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import { AimTypography, AimIconButton, theme } from '@aim/components';

const BoughtSpacesTable = ({ spaces, i18n }) => {
  // Hooks
  const { eventId, sponsorId } = useParams();
  const history = useHistory();
  console.log('spaces', spaces);

  return (
    <>
      <Grid
        container
        style={{
          backgroundColor: theme.colors.greyScale.backgroundGrey,
          // backgroundColor: 'white',
          marginBottom: 20,
          padding: 15,
        }}
      >
        <Grid item xs={2}>
          <AimTypography variant={'h5'} boxStyle={{ fontWeight: 700 }}>
            {i18n.table.headers.code}
          </AimTypography>
        </Grid>
        <Grid item xs={2}>
          <AimTypography variant={'h5'} boxStyle={{ fontWeight: 700 }}>
            {i18n.table.headers.name}
          </AimTypography>
        </Grid>
        <Grid item xs={2}>
          <AimTypography variant={'h5'} boxStyle={{ fontWeight: 700 }}>
            {i18n.table.headers.floorName}
          </AimTypography>
        </Grid>
        <Grid item xs={2}>
          <AimTypography variant={'h5'} boxStyle={{ fontWeight: 700 }}>
            {i18n.table.headers.floorCode}
          </AimTypography>
        </Grid>
        <Grid item xs={2}>
          <AimTypography variant={'h5'} boxStyle={{ fontWeight: 700 }}>
            {i18n.table.headers.purchasedPhysicalStandSpaces}
          </AimTypography>
        </Grid>
        <Grid itemxs={2}> </Grid>
      </Grid>
      {spaces.map((space, idx) => {
        return (
          <Grid
            container
            key={idx}
            style={{
              backgroundColor: theme.colors.greyScale.backgroundGrey,
              marginBottom: 20,
              padding: 15,
            }}
          >
            <Grid item xs={2}>
              <AimTypography variant={'h5Regular'}>{space.code}</AimTypography>
            </Grid>
            <Grid item xs={2}>
              <AimTypography variant={'h5Regular'}>{space.name}</AimTypography>
            </Grid>
            <Grid item xs={2}>
              <AimTypography variant={'h5Regular'}>
                {space.floor.name}
              </AimTypography>
            </Grid>
            <Grid item xs={2}>
              <AimTypography variant={'h5Regular'}>
                {space.floor.code}
              </AimTypography>
            </Grid>
            <Grid item xs={2}>
              <AimTypography variant={'h5Regular'}>
                {space?.physicalStandSpacesItems?.items?.length}
              </AimTypography>
            </Grid>
            <Grid item xs={2}>
              <AimIconButton
                variant="yellow"
                style={{ marginBottom: `10px !important` }}
                onClick={() =>
                  history.push(
                    `/events/${eventId}/${sponsorId}/services-configuration/physical-stands/${space.id}`
                  )
                }
              >
                <AddIcon />
              </AimIconButton>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                {space?.physicalStandSpacesItems?.items?.length ? (
                  <React.Fragment key={idx}>
                    <Grid item xs={2}>
                      <AimTypography
                        variant={'h5'}
                        boxStyle={{ fontWeight: 700 }}
                      >
                        {i18n.table.headers.category}
                      </AimTypography>
                    </Grid>
                    <Grid item xs={2}>
                      <AimTypography
                        variant={'h5'}
                        boxStyle={{ fontWeight: 700 }}
                      >
                        {i18n.table.headers.subCategory}
                      </AimTypography>
                    </Grid>
                    <Grid item xs={4}>
                      <AimTypography
                        variant={'h5'}
                        boxStyle={{ fontWeight: 700 }}
                      >
                        {i18n.table.headers.item}
                      </AimTypography>
                    </Grid>
                    <Grid item xs={2}>
                      <AimTypography
                        variant={'h5'}
                        boxStyle={{ fontWeight: 700 }}
                      >
                        {i18n.table.headers.quantity}
                      </AimTypography>
                    </Grid>
                    <Grid item xs={2}></Grid>
                    {space.physicalStandSpacesItems.items.map(
                      (spaceItem, idx) => {
                        return (
                          <React.Fragment key={idx}>
                            <Grid item xs={2}>
                              <AimTypography variant={'h5Regular'}>
                                {
                                  spaceItem?.item?.itemSubCategory?.itemCategory
                                    ?.name
                                }
                              </AimTypography>
                            </Grid>
                            <Grid item xs={2}>
                              <AimTypography variant={'h5Regular'}>
                                {spaceItem?.item?.itemSubCategory?.name}
                              </AimTypography>
                            </Grid>
                            <Grid item xs={4}>
                              <AimTypography variant={'h5Regular'}>
                                {spaceItem?.item?.name}
                              </AimTypography>
                            </Grid>
                            <Grid item xs={2}>
                              <AimTypography variant={'h5Regular'}>
                                {`${spaceItem?.quantity} ${
                                  spaceItem?.amountPerQuantity
                                    ? ' x ' + spaceItem?.amountPerQuantity
                                    : ''
                                } ${spaceItem?.item?.amountPerQuantityLabel}`}
                              </AimTypography>
                            </Grid>
                            <Grid item xs={2}>
                              <div
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                  history.push(
                                    `/events/${eventId}/${sponsorId}/my-purchases`
                                  )
                                }
                              >
                                <AimTypography variant={'h5'}>
                                  {i18n.table.viewOrder.label}
                                </AimTypography>
                              </div>
                            </Grid>
                          </React.Fragment>
                        );
                      }
                    )}
                  </React.Fragment>
                ) : (
                  <Grid item xs={12}>
                    <AimTypography variant={'h5'}>
                      {i18n.table.noSpaceItems.label}
                    </AimTypography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};

export { BoughtSpacesTable };
