import React from 'react';
import { useIntl } from 'react-intl';
import format from 'date-fns/format';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Clear';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { theme, AimTablePage } from '@aim/components';

import makeTranslation from './tablePage/makeTranslation';
// import { TablePage } from './tablePage/TablePro';
/**
 * @returns {import("./tablePage").TablePageProps} TablePageProps
 */

const getDataQuery = /* GraphQL */ `
  query GetSponsor($id: ID!) {
    getSponsor(id: $id) {
      id
      name
      sponsorStaff {
        id
        name
        participations(filter: { isDeleted: { ne: true } }) {
          items {
            id
            isDeleted
            givenName
            familyName
            email
            createdAt
            isParticipant
          }
        }
      }
    }
  }
`;

function later(delay) {
  return new Promise(function (resolve) {
    setTimeout(resolve, delay);
  });
}

const model = {
  header: {
    title: (odo) => 3 + 4,
    backButton: {
      onClick: () => alert('back'),
    },
    action: {
      add: {
        icon: <AddIcon />,
        onClick: () => alert('add'),
      },
      deleteSelected: {
        icon: <DeleteIcon />,
        variant: 'red',
        disabledWhen: (checkedItems) => !checkedItems.length,
        hiddenWhen: (checkedItems) => false,
        onClick: (checkedItems) => console.log(checkedItems),
      },
      test: {
        icon: <AddIcon />,
        variant: 'green',
        // dialog: 'dialogTest',
        snackbar: 'snackTest',
      },
      closeGroup: {
        icon: <DoneIcon />,
        variant: 'lightBlue',
        onClick: () => alert('close'),
      },
    },
  },
  preTableComponent: null,
  dataLoader: {
    query: getDataQuery,
    variables: { id: '515a8dc1-ed6d-4170-bc81-a2c6c3225dd1' },
    transform: (data) =>
      data.getSponsor.sponsorStaff.participations.items.map((item) => ({
        ...item,
        createdAt: format(new Date(item.createdAt), 'dd/MM/yyyy'),
        fullName: `${item.givenName} ${item.familyName}`,
      })),
    // bigStats: [{ currentMembers: (tData, data) => tData.length }],
    // stats: [{ currentMembers: (tData, data) => tData.length }],
    stats: [{ title: 'currentMembers', value: (tData, data) => tData.length }],
  },
  table: {
    isRowSelectable: true,
    columnsTemplate: [
      {
        id: 'givenName',
        numeric: false,
        disablePadding: false,
        isCheckbox: false,
        isDisabled: false,
      },
      {
        id: 'familyName',
        numeric: false,
        disablePadding: false,
        isCheckbox: false,
        isDisabled: false,
      },
      {
        id: 'email',
        numeric: false,
        disablePadding: false,
        isCheckbox: false,
        isDisabled: false,
      },
      {
        id: 'createdAt',
        numeric: false,
        disablePadding: false,
        isCheckbox: false,
        isDisabled: false,
      },
      {
        id: 'isParticipant',
        numeric: false,
        disablePadding: false,
        isCheckbox: true,
        isDisabled: true,
      },
    ],
    filters: [
      {
        column: 'givenName',
        type: 'string',
      },
      {
        column: 'familyName',
        type: 'select',
      },
      {
        column: 'isParticipant',
        type: 'checkbox',
      },
    ],
    rowAction: {
      view: {
        icon: <VisibilityIcon htmlColor={theme.colors.secondary.lightBlue} />,
        variant: 'lightBlueFill',
        disabledWhen: (row) => true,
        onClick: (row) => alert(JSON.stringify(row, null, 2)),
      },
      edit: {
        icon: <EditIcon />,
        variant: 'greenFill',
        onClick: (row) => alert(`edit ${row.id}`),
        hiddenWhen: (row) => row.id !== 1,
      },
      delete: {
        icon: <CloseIcon />,
        variant: 'redFill',
        onClick: 'dialog',
        dialog: 'delete',
        snackbar: 'delete',
      },
    },
  },
  dialog: {
    delete: {
      onAgree: (row) => later(5000),
      onDisagree: () => {},
    },
    dialogTest: {
      onAgree: (row) => {},
      onDisagree: () => {},
    },
  },
  snackbar: {
    delete: {},
    snackTest: {},
  },
};

// add filters
// add data refresh trigger method
const TablePro = () => {
  const intl = useIntl();
  const translation = makeTranslation(intl);

  return <AimTablePage model={model} translation={translation} intl={intl} />;
};

export default TablePro;
