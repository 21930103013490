import React, { useState } from 'react';

import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import ClearIcon from '@material-ui/icons/Clear';

import {
  AimTableNoPaging,
  AimTableRow,
  AimTableCell,
  AimIconButton,
  AimTypography,
  AimIconAndTextButton,
  AimDialog,
} from '@aim/components';

import { useDataHelper } from '../shared/dataHelper';

const BookingTable = ({
  title,
  bookings,
  i18n,
  onClickBuy,
  onClickDelete,
  sinteticMode,
}) => {
  // Hooks
  const { formatNumber, getFormattedDate } = useDataHelper();

  const [deleteDialog, setDeleteDialog] = useState({ isOpen: false });

  // Columns
  const headCells = [
    ...(!sinteticMode
      ? [
          {
            id: 'bookingId',
            numeric: false,
            disablePadding: false,
            label: i18n.bookingTable.columns.bookingId.label,
          },
        ]
      : []),
    {
      id: 'service',
      numeric: false,
      disablePadding: false,
      label: i18n.bookingTable.columns.service.label,
    },
    {
      id: 'instance',
      numeric: false,
      disablePadding: false,
      label: i18n.bookingTable.columns.instance.label,
    },
    ...(!sinteticMode
      ? [
          {
            id: 'date',
            numeric: false,
            disablePadding: false,
            label: i18n.bookingTable.columns.date.label,
          },
          {
            id: 'expiringDate',
            numeric: false,
            disablePadding: false,
            label: i18n.bookingTable.columns.expiringDate.label,
          },
          {
            id: 'netPrice',
            numeric: false,
            disablePadding: false,
            label: i18n.bookingTable.columns.netPrice.label,
          },
          {
            id: 'price',
            numeric: false,
            disablePadding: false,
            label: i18n.bookingTable.columns.price.label,
          },
        ]
      : []),
    {
      id: 'quantity',
      numeric: false,
      disablePadding: false,
      label: i18n.bookingTable.columns.quantity.label,
    },
    ...(!sinteticMode
      ? [
          {
            id: 'vatRate',
            numeric: false,
            disablePadding: false,
            label: i18n.bookingTable.columns.vatRate.label,
          },
          {
            id: 'vatAmount',
            numeric: false,
            disablePadding: false,
            label: i18n.bookingTable.columns.vatAmount.label,
          },
          {
            id: 'totalNetPrice',
            numeric: false,
            disablePadding: false,
            label: i18n.bookingTable.columns.totalNetPrice.label,
          },
        ]
      : []),
    {
      id: 'totalGrossPrice',
      numeric: false,
      disablePadding: false,
      label: i18n.bookingTable.columns.totalGrossPrice.label,
    },
    ...(!sinteticMode
      ? [
          {
            id: 'paymentType',
            numeric: false,
            disablePadding: false,
            label: i18n.bookingTable.columns.paymentType.label,
          },
          {
            numeric: false,
            disablePadding: true,
            label: '',
            id: 'actions',
          },
        ]
      : []),
  ];

  const Row = ({ row }) => {
    return row.isFooter ? (
      <TotalRow row={row} />
    ) : (
      <AimTableRow>
        {!sinteticMode && <AimTableCell>{row.bookingId}</AimTableCell>}
        <AimTableCell>{row.service}</AimTableCell>
        <AimTableCell>{row.instance}</AimTableCell>
        {!sinteticMode && (
          <>
            <AimTableCell>{getFormattedDate(row.date)}</AimTableCell>
            <AimTableCell>{getFormattedDate(row.expiringDate)}</AimTableCell>
            <AimTableCell>{formatNumber(row.netPrice)}</AimTableCell>
            <AimTableCell>{formatNumber(row.price)}</AimTableCell>
          </>
        )}
        <AimTableCell>{row.quantity}</AimTableCell>
        {!sinteticMode && (
          <>
            <AimTableCell>{row.vatRate}</AimTableCell>
            {/* vat euro */}
            <AimTableCell>
              {formatNumber(row.vatAmount * row.quantity)}
            </AimTableCell>
            {/* total net price */}
            <AimTableCell>
              {formatNumber(row.netPrice * row.quantity)}
            </AimTableCell>
          </>
        )}
        {/* total gross price */}
        <AimTableCell>{formatNumber(row.price * row.quantity)}</AimTableCell>
        {!sinteticMode && (
          <>
            <AimTableCell>
              {i18n.paymentTypes[row.paymentType]?.label}
            </AimTableCell>
            <AimTableCell style={{ minWidth: 110 }}>
              <AimIconButton
                variant="yellowFill"
                small="true"
                onClick={() => onClickBuy({ bookings: [row] })}
              >
                <SwapHorizIcon />
              </AimIconButton>
              <AimIconButton
                variant="redFill"
                small="true"
                onClick={() =>
                  setDeleteDialog({ isOpen: true, bookings: [row] })
                }
              >
                <ClearIcon />
              </AimIconButton>
            </AimTableCell>
          </>
        )}
      </AimTableRow>
    );
  };

  const TotalRow = ({ row }) => {
    return (
      <>
        <AimTableRow>
          <AimTableCell colSpan={sinteticMode ? 3 : 11}>
            <AimTypography style={{ fontSize: 18 }}>{row.text}</AimTypography>
          </AimTableCell>
          <AimTableCell>
            <AimTypography style={{ fontSize: 18 }}>
              {formatNumber(row.total)}
            </AimTypography>
          </AimTableCell>
        </AimTableRow>
      </>
    );
  };

  const totals = {
    id: 10000,
    total: bookings.reduce(
      (res, curr) => (res += curr.price * curr.quantity),
      0
    ),
    isFooter: true,
    text: sinteticMode
      ? i18n.bookingTable.totalText.labelSintetic
      : `${i18n.bookingTable.totalText.label} ${
          i18n.paymentTypes[bookings[0].paymentType].label
        }`,
  };
  const nextBookings = [...bookings, totals];

  return (
    <>
      <AimTypography variant="textBold">{title}</AimTypography>
      <AimTableNoPaging
        notCheckable
        hideToolbar
        hideEmptyRow
        title={i18n.bookingTable.title}
        headCells={headCells}
        rows={nextBookings}
        rowKey="id"
      >
        <Row />
      </AimTableNoPaging>
      {!sinteticMode && (
        <div style={{ textAlign: 'right', marginTop: 15 }}>
          <AimIconAndTextButton
            variant="yellow"
            text={i18n.bookingTable.buttons.buy.label}
            onClick={() => onClickBuy({ bookings })}
          />
        </div>
      )}
      <AimDialog
        title={i18n.dialog.delete.title}
        open={deleteDialog?.isOpen}
        agreeText={i18n.dialog.delete.agreeText}
        disagreeText={i18n.dialog.delete.disagreeText}
        onClose={() => setDeleteDialog({ isOpen: false })}
        onAgree={() => onClickDelete(deleteDialog.bookings)}
        onDisagree={() => setDeleteDialog({ isOpen: false })}
      >
        {i18n.dialog.delete.message}
      </AimDialog>
    </>
  );
};

export default BookingTable;
