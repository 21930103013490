import React, { useState, useEffect, useRef } from 'react';

import { useParams, useHistory } from 'react-router-dom';
import { isAfter, isEqual } from 'date-fns';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'swiper/swiper.scss';
import { useIntl } from 'react-intl';
import ReactCardCarousel from 'react-card-carousel';

import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';

import {
  OverlaySlider,
  SessionsContainer,
  SessionSliderContainer,
  SliderWrapper,
} from './sessions/style';
import { TitleSection } from './shared/style';
import './sessions/carouselStyle.css';
import { SlideSession } from './sessions/SlideSession';
import { UpNextList } from './sessions/UpNextList';

import { getSessions } from './shared/sessionsGqlHelper';
import { useAgenda } from './../shared/agenda/shared/agendaGqlHelper';

import { aws, appState, fileHelper } from '@aim/common';
import { AimTypography, CustomIntl, theme } from '@aim/components';

import translation from './shared/translation';
import { getEventConfigurationPageItem } from '../shared/utilities';
import defaultImage from './../../assets/images/default-image.jpg';

const getIntervetionImage = async (eventId, participation) => {
  if (participation?.userShowcase?.profileImage?.id) {
    return fileHelper.getPublicFileLink({
      dirPath: `events/${eventId}/user/${participation.id}/showcase/profileImage`,
      skipFileDataOnS3Link: true,
    });
  } else {
    return null;
  }
};

const ArrNext = (props) => {
  const { onClick } = props;
  return (
    <div
      style={{
        position: 'absolute',
        display: 'block',
        top: '50%',
        right: '1%',
        cursor: 'pointer',
        zIndex: 10,
      }}
      onClick={onClick}
    >
      <ArrowForward
        style={{ color: theme.colors.primary.yellow, width: 80, height: 40 }}
      />
    </div>
  );
};

const ArrPrev = (props) => {
  const { onClick } = props;
  return (
    <div
      style={{
        position: 'absolute',
        display: 'block',
        left: '1%',
        top: '50%',
        cursor: 'pointer',
        zIndex: 10,
      }}
      onClick={onClick}
    >
      <ArrowBack
        style={{ color: theme.colors.primary.yellow, width: 80, height: 40 }}
      />
    </div>
  );
};

const Sessions = () => {
  const [sessions, setSessions] = useState([]);
  const [sessionsUpNext, setSessionsUpNext] = useState();
  const [eventConfiguration] = useState(appState.eventConfiguration.getValue());
  const [genericMenuItem, setGenericMenuItem] = useState();

  const { eventId } = useParams();
  let carouselRef = useRef();

  const intl = CustomIntl(useIntl());
  const i18n = translation.sessions(intl);

  const { isJoinVisible } = useAgenda();

  const history = useHistory();

  const containerStyle = {
    position: 'relative',
    height: '65vh',
    marginTop: '3%',
    minWidth: '100%',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  };

  const carouselWrapper = {
    height: '65vh',
    minWidth: '100%',
    display: 'flex',
    flex: 1,
  };

  useEffect(() => {
    const menuItem = getEventConfigurationPageItem(
      eventConfiguration?.eventGenericMenuItems?.items || [],
      intl,
      'sessions'
    );
    setGenericMenuItem(menuItem);
  }, [eventConfiguration]);

  const initValues = async () => {
    try {
      const sessionList = await getSessions(eventId);

      if (!sessionList) return null;

      const newSessionsData = await Promise.all(
        sessionList.items.map(async (session) => {
          const s3FolderCover = `events/${eventId}/agendaSessions/${session.id}/coverImage/`;

          const coverURL = session.coverImage?.id
            ? await aws.Storage.get(
                `${s3FolderCover}${session.coverImage.id}${session.coverImage.extension}`
              )
            : null;

          if (session.coverImage) session.coverImage.url = coverURL;

          const attendeesProms = session.attendeeRoles.items.flatMap((ar) =>
            ar.attendees.items.map(async (a) => ({
              ...a.participation,
              image: await getIntervetionImage(eventId, a.participation),
            }))
          );

          const attendees = await Promise.all(attendeesProms);

          return { ...session, attendees };
        })
      );

      setSessions(newSessionsData);
      newSessionsData && upNextSessions(newSessionsData);
    } catch (error) {
      console.error('init values error', error);
    }
  };

  const upNextSessions = async (sessionsData) => {
    const now = new Date();
    const upNext = sessionsData
      .filter((s) => {
        const dateStart = new Date(s.start);
        return isAfter(dateStart, now) || isEqual(now, dateStart);
      })
      .map((s) => ({ ...s, room: s.room?.name }));

    setSessionsUpNext(upNext);
  };

  useEffect(() => {
    initValues();
  }, []);

  console.log('genericMenuItem', genericMenuItem);
  return (
    <SessionsContainer>
      <SessionSliderContainer>
        <SliderWrapper>
          <OverlaySlider />
          <TitleSection
            style={{
              color: 'white',
              opacity: '0.9',
              alignSelf: 'flex-start',
              display: 'flex',
              // paddingLeft: '25%',
            }}
            alignSelf="flex-start"
          >
            <AimTypography variant="h1" margin={'0px 0px 0px 30px'}>
              {genericMenuItem?.label || ''}
            </AimTypography>
          </TitleSection>
          <div style={carouselWrapper}>
            <ArrPrev onClick={() => carouselRef.current.prev()} />
            <ReactCardCarousel
              style={containerStyle}
              ref={(Carousel) => (carouselRef.current = Carousel)}
            >
              {sessions.map((session, index) => (
                <SlideSession
                  key={index}
                  session={session}
                  i18n={i18n}
                  isJoinVisible={isJoinVisible}
                  history={history}
                  eventId={eventId}
                  defaultImage={defaultImage}
                />
              ))}
            </ReactCardCarousel>
            <ArrNext onClick={() => carouselRef.current.next()} />
          </div>
        </SliderWrapper>
      </SessionSliderContainer>
      {genericMenuItem?.showUpNextFlagChecked ? (
        <UpNextList upNextSessions={sessionsUpNext} i18n={i18n} />
      ) : null}
    </SessionsContainer>
  );
};

export default Sessions;
