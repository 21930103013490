import React from 'react';
import { sortBy } from 'lodash';
import { useIntl } from 'react-intl';

import translation from '../shared/translation';
import {
  CustomIntl,
  AimIconAndTextButton,
  AimRichText,
  AimTypography,
  AimSnackbar,
  AimSnackbarSeverity,
  theme,
} from '@aim/components';

import { appState } from '@aim/common';

import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Visibility from '@material-ui/icons/Visibility';

import {
  MainContainer,
  InnerContainer,
} from '../shared/SponsorPagesContainers';
import { useHistory, useParams } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { listOtherSponsorizationsServiceSubcategory } from '../shared/otherSponsorizationsServiceGqlHelper';
import {
  OtherSponsRow,
  OtherSponsIconButton,
} from './otherSponsorizations/OtherSponsorizations.styles';

const OtherSponsorizationsSubcategory = () => {
  // Hooks
  const history = useHistory();
  const intl = CustomIntl(useIntl());
  const i18n = translation.otherSponsorizationsSub(intl);
  const { eventId, sponsorId, categoryId } = useParams();

  // State
  const [
    otherSponsorizationsService,
    setOtherSponsorizationsService,
  ] = useState();
  const [snackbar, setSnackbar] = useState({ isOpen: false });

  // Effects
  useEffect(() => {
    fetchSponsorAndService();
  }, []);

  // API
  const fetchSponsorAndService = async () => Promise.all([fetchService()]);

  const fetchService = async () => {
    const result = await listOtherSponsorizationsServiceSubcategory(
      eventId,
      categoryId
    );
    setOtherSponsorizationsService(result);
  };

  const handleNavigation = (detailId) => {
    history.push(
      `/events/${eventId}/${sponsorId}/my-services/other-sponsorizations/${categoryId}/${detailId}`
    );
  };

  const renderPageHeading = () => (
    <>
      <div
        style={{
          display: 'flex-root',
          marginTop: 20,
        }}
      >
        <Tooltip title={i18n.page.back.tooltip.label}>
          <AimIconAndTextButton
            variant="text"
            text={i18n.page.back.button.label}
            onClick={() =>
              history.push(
                `/events/${eventId}/${sponsorId}/my-services/other-sponsorizations/`
              )
            }
          >
            <ArrowBack />
          </AimIconAndTextButton>
        </Tooltip>
      </div>
      <Grid container>
        <Grid item xs={12}>
          <AimTypography variant={'h1'}>
            {otherSponsorizationsService?.name}
          </AimTypography>
        </Grid>
      </Grid>
    </>
  );

  const renderCategories = () =>
    otherSponsorizationsService && (
      <>
        <Grid
          container
          style={{ backgroundColor: theme.colors.greyScale.backgroundGrey }}
        >
          <Grid
            item
            xs={4}
            style={{
              marginTop: 16,
            }}
          >
            <AimTypography variant="h6" style={{ marginLeft: 16 }}>
              {i18n.page.services.label}
            </AimTypography>
          </Grid>
          <Grid
            item
            xs={8}
            style={{
              marginTop: 16,
            }}
          >
            <AimTypography variant="h6">{i18n.page.items.label}</AimTypography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} style={{ marginTop: 16 }}>
            <div style={{ margin: 4 }}>
              {otherSponsorizationsService?.itemSubcategories?.items?.length >
              0 ? (
                sortBy(
                  otherSponsorizationsService?.itemSubcategories?.items,
                  'name'
                )?.map((c, idx) => (
                  <OtherSponsRow key={idx}>
                    <Grid item xs={4}>
                      <AimTypography variant="h5Regular">
                        {c.name}
                      </AimTypography>
                    </Grid>
                    <Grid item xs={4}>
                      <AimTypography variant="h5Regular">
                        {c.childItems.items.length}
                      </AimTypography>
                    </Grid>
                    <OtherSponsIconButton
                      small
                      variant="lightBlueFill"
                      onClick={() => handleNavigation(c.id)}
                    >
                      <Visibility />
                    </OtherSponsIconButton>
                  </OtherSponsRow>
                ))
              ) : (
                <AimTypography variant="text" style={{ marginLeft: 12 }}>
                  {i18n.page.services.noServicesFound}
                </AimTypography>
              )}
            </div>
          </Grid>
        </Grid>
      </>
    );

  const renderSnackbar = () => (
    <AimSnackbar
      open={snackbar.isOpen}
      onClose={() => setSnackbar({ isOpen: false })}
      severity={snackbar.severity}
    >
      {snackbar.message}
    </AimSnackbar>
  );

  return (
    <MainContainer>
      <InnerContainer grow bottomSpacing>
        {renderPageHeading()}
        {renderCategories()}
      </InnerContainer>
      {renderSnackbar()}
    </MainContainer>
  );
};

export default OtherSponsorizationsSubcategory;
