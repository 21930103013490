import React, { useEffect, useImperativeHandle } from 'react';
import { useForm, Controller } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import CheckIcon from '@material-ui/icons/Check';
import ReplayIcon from '@material-ui/icons/Replay';

import { AimIconAndTextButton, AimTextFieldForm } from '@aim/components';

const FormCreateGroupBase = ({ onSave, onCancel, selectedItem, i18n }, ref) => {
  const { handleSubmit, errors, control, reset } = useForm({
    defaultValues: {
      groupId: '',
      name: '',
      client: '',
    },
  });

  useEffect(() => {
    if (!selectedItem) return;
    reset({
      groupId: selectedItem?.id || '',
      name: selectedItem?.name || '',
      client: selectedItem?.client || '',
    });
  }, [selectedItem]);

  useImperativeHandle(ref, () => ({ resetForm: reset }));

  const formControlStyle = { width: 'calc(100% - 15px)', minWidth: '400px' };

  return (
    <form onSubmit={handleSubmit(onSave)}>
      <Controller
        name={`groupId`}
        control={control}
        defaultValue={selectedItem?.groupId || null}
        as={<input type="hidden" />}
      />

      <Grid item xs={12}>
        <AimTextFieldForm
          control={control}
          name="name"
          label={i18n.name}
          formControlStyle={formControlStyle}
          isRequired
        />
      </Grid>
      <Grid item xs={12}>
        <AimTextFieldForm
          control={control}
          name="client"
          label={i18n.client}
          formControlStyle={formControlStyle}
          isRequired
        />
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <AimIconAndTextButton
              onClick={onCancel}
              variant="greyFill"
              text={i18n.cancel}
            >
              <ReplayIcon />
            </AimIconAndTextButton>
            <AimIconAndTextButton
              type="submit"
              variant="greenFill"
              text={i18n.save}
            >
              <CheckIcon />
            </AimIconAndTextButton>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};

export const FormCreateGroup = React.forwardRef(FormCreateGroupBase);
