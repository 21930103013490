import React from 'react';
import { useFieldArray, Controller } from 'react-hook-form';
import NestedArray from './nestedFieldArray';
import Grid from '@material-ui/core/Grid';
import ClearIcon from '@material-ui/icons/Clear';

import { AimIconButton, AimTypography, AimTextField } from '@aim/components';

export default function Fields({
  control,
  register,
  setValue,
  //   getValues,
  VATPercentage,
  trigger,
  roomsData,
  errors,
  clearErrors,
  participantsForTheEvent,
  watch,
  whoAmI,
  isMobileOnly,
  reset,
}) {
  const {
    fields,
    //  append,
    remove,
    // prepend
  } = useFieldArray({
    control,
    name: 'rooms',
  });

  const formControlStyle = {
    width: 'calc(100% - 5px)',
  };

  const calculatePercentage = (number, percentage) => {
    if (isNaN(+number) || isNaN(+percentage)) return 0;
    return (number / 100) * percentage;
  };

  const formatNumberToEuros = (amount, includeEuroSymbol = true) => {
    var res = new Intl.NumberFormat('it-IT', {
      style: 'currency',
      currency: 'EUR',
    }).format(!isNaN(+amount) ? amount : 0);
    return includeEuroSymbol ? res : res.slice(0, -2);
  };

  return (
    <Grid container xs={12} style={{ backgroundColor: 'white' }}>
      {fields.map((item, ndx) => {
        return (
          <Grid
            item
            container
            xs={12}
            key={ndx}
            style={{
              marginTop: 20,
              backgroundColor: '#F8F6FA',
              padding: '10px 10px 10px 10px',
              borderRadius: 3,
            }}
          >
            <input
              type="hidden"
              name={`rooms[${ndx}].maxOccupancy`}
              ref={register()}
              defaultValue={+item.maxOccupancy}
            />
            <input
              type="hidden"
              name={`rooms[${ndx}].id`}
              ref={register()}
              defaultValue={item.id}
            />
            <input
              type="hidden"
              name={`rooms[${ndx}].frontofficeName`}
              ref={register()}
              defaultValue={item.frontofficeName}
            />
            <input
              type="hidden"
              name={`rooms[${ndx}].guestsNumber`}
              ref={register()}
              defaultValue={item.guestsNumber}
            />
            <input
              type="hidden"
              name={`rooms[${ndx}].roomPrice`}
              ref={register()}
              defaultValue={item.roomPrice}
            />
            <input
              type="hidden"
              name={`rooms[${ndx}].hotelRoomAvailabilitiesIds`}
              ref={register()}
              defaultValue={item.hotelRoomAvailabilitiesIds}
            />
            {!isMobileOnly && (
              <Grid item container>
                <Grid item xs={3}>
                  <AimTypography variant="text">
                    <b>Room</b>
                  </AimTypography>
                </Grid>

                <Grid item xs={3}>
                  <AimTypography variant="text">
                    <b>N° Guest</b>
                  </AimTypography>
                </Grid>

                <Grid item xs={1}>
                  <AimTypography variant="text">
                    <b>Price (€)</b>
                  </AimTypography>
                </Grid>

                <Grid item xs={1}>
                  <AimTypography variant="text">
                    <b>VAT (%)</b>
                  </AimTypography>
                </Grid>

                <Grid item xs={3}>
                  <AimTypography variant="text">
                    <b>Price VAT Included (€)</b>
                  </AimTypography>
                </Grid>

                <Grid item xs={1} />
              </Grid>
            )}

            {!isMobileOnly && (
              <Grid item container xs={12} style={{ backgroundColor: 'white' }}>
                <Grid item xs={3}>
                  <AimTypography variant="text">
                    {item.frontofficeName}
                  </AimTypography>
                </Grid>

                <Grid item xs={3}>
                  <AimTypography variant="text">
                    {item.guestsNumber}
                  </AimTypography>
                </Grid>

                <Grid item xs={1}>
                  <AimTypography variant="text">
                    {formatNumberToEuros(item.roomPrice, false)}
                  </AimTypography>
                </Grid>

                <Grid item xs={1}>
                  <AimTypography variant="text">{VATPercentage}</AimTypography>
                </Grid>

                <Grid item xs={3}>
                  <AimTypography variant="text">
                    {formatNumberToEuros(
                      calculatePercentage(item.roomPrice, VATPercentage) +
                        +item.roomPrice,
                      false
                    )}
                  </AimTypography>
                </Grid>

                <Grid
                  item
                  xs={1}
                  style={{ justifyContent: 'flex-end', display: 'flex' }}
                >
                  <AimIconButton
                    disabled={roomsData.rooms?.length < 2 ? true : false}
                    style={
                      roomsData.rooms?.length < 2
                        ? { color: 'grey', backgroundColor: 'lightgrey' }
                        : { color: '#FE0C4E', backgroundColor: '#FFDCE2' }
                    } // there is no frontend variant for this, gonna use ...(style && style) in AimIconButton.js
                    small
                    onClick={async () => {
                      await remove(ndx);
                      trigger();
                    }}
                  >
                    <ClearIcon />
                  </AimIconButton>
                </Grid>
              </Grid>
            )}

            {isMobileOnly && (
              <Grid container xs={12}>
                <Grid item container xs={6}>
                  <Grid item xs={12} style={{ marginTop: 4 }}>
                    <Grid item xs={12}>
                      <b>Room</b>
                    </Grid>
                    <Grid item xs={12}>
                      {item.frontofficeName}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 4 }}>
                    <Grid item xs={12}>
                      <b>N° Guest</b>
                    </Grid>
                    <Grid item xs={12}>
                      {item.guestsNumber}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 4 }}>
                    <Grid item xs={12}>
                      <b>Price (€)</b>
                    </Grid>
                    <Grid item xs={12}>
                      {formatNumberToEuros(item.roomPrice, false)}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item container xs={6}>
                  <Grid item xs={12} style={{ marginTop: 4 }}>
                    <Grid item xs={12}>
                      <b>VAT (€)</b>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 4 }}>
                      {VATPercentage}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 4 }}>
                    <Grid item xs={12}>
                      <b>Price VAT Included (€)</b>
                    </Grid>
                    <Grid item xs={12}>
                      {formatNumberToEuros(
                        calculatePercentage(item.roomPrice, VATPercentage) +
                          +item.roomPrice,
                        false
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid
              item
              xs={12}
              style={{
                backgroundColor: 'white',
                marginTop: 20,
                borderRadius: 4,
                padding: '5px 5px 5px 5px',
              }}
            >
              <AimTypography variant="text">GUESTS</AimTypography>

              <NestedArray
                nestIndex={ndx}
                {...{
                  control,
                  register,
                  whoAmI,
                  isMobileOnly,
                  reset,
                  roomsData,
                  participantsForTheEvent,
                  errors,
                  setValue,
                  clearErrors,
                  trigger,
                  watch,
                }}
              />
              <Grid
                item
                xs={12}
                style={{ backgroundColor: '#F8F6FA', marginTop: 20 }}
              >
                <Controller
                  name={`rooms[${ndx}].notes`}
                  ref={register()}
                  defaultValue={item.notes}
                  control={control}
                  render={(props) => (
                    <AimTextField
                      value={props.value}
                      onChange={(e) => props.onChange(e.target.value)}
                      formControlStyle={formControlStyle}
                      style={{ backgroundColor: '#F8F6FA' }}
                      label="Notes"
                      type="text"
                      textfieldVariant={`white`}
                      multiline
                      rows={2}
                    />
                  )}
                />
              </Grid>
            </Grid>
            {isMobileOnly && (
              <Grid
                item
                xs={12}
                style={{
                  justifyContent: 'flex-end',
                  display: 'flex',
                  marginTop: 4,
                }}
              >
                <AimIconButton
                  disabled={roomsData.rooms?.length < 2 ? true : false}
                  style={
                    roomsData.rooms?.length < 2
                      ? { color: 'grey', backgroundColor: 'lightgrey' }
                      : { color: '#FE0C4E', backgroundColor: '#FFDCE2' }
                  } // there is no frontend variant for this, gonna use ...(style && style) in AimIconButton.js
                  small
                  onClick={async () => {
                    await remove(ndx);
                    trigger();
                  }}
                >
                  <ClearIcon />
                </AimIconButton>
              </Grid>
            )}
          </Grid>
        );
      })}
    </Grid>
  );
}
