import { aws, appState } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const ParticipantsByEventIdQuery = /* GraphQL */ `
  query ParticipantsByEventId($participationEventId: ID) {
    participantByEventId(
      participationEventId: $participationEventId
      filter: { isSpeaker: { eq: true } }
    ) {
      items {
        id
        userShowcase {
          profileImage {
            id
            extension
            originalName
            size
          }
          city
          country
        }
        givenName
        familyName
        isSpeaker
        type
        fieldValues {
          items {
            id
            value
            fieldDefinition {
              id
              key
              label
            }
          }
        }
      }
    }
  }
`;

export const GetParticipantsByEventId = (eventId, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: ParticipantsByEventIdQuery,
      variables: {
        participationEventId: eventId,
      },
    })
      .then((response) => {
        resolve(response.data.participantByEventId);
      })
      .catch((e) => {
        console.error('participants-by-eventId', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });