import React from 'react';

import Grid from '@material-ui/core/Grid';

import { useParams, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { CustomIntl, AimWallCard } from '@aim/components';

import translation from './shared/translation';
import { MainContainer, InnerContainer } from './shared/SponsorPagesContainers';
import { useWallCards } from './sponsorServiceConfigurationWall/cards';
import TitleAndBackButton from './shared/TitleAndBackButton';

const SponsorServiceConfigurationWall = () => {
  // Hooks
  const { eventId, sponsorId } = useParams();
  const history = useHistory();
  const intl = CustomIntl(useIntl());
  const i18n = translation.sponsorServicesConfigurationPage(intl);
  const wallCards = useWallCards(i18n.cards, eventId, sponsorId);

  return (
    <MainContainer>
      <TitleAndBackButton
        backOnClickFunction={() =>
          history.push(`/events/${eventId}/${sponsorId}/my-services`)
        }
        backOnHoverText={i18n.page.back.tooltip.label}
        title={i18n.page.title.label}
      />
      <InnerContainer grow bottomSpacing>
        <Grid container>
          {wallCards.map(({ id, label, link }) => (
            <Grid item xs={6} key={id}>
              <AimWallCard
                cardVariant="white"
                label={label}
                onIconClick={() => {
                  history.push(link);
                }}
              />
            </Grid>
          ))}
        </Grid>
      </InnerContainer>
    </MainContainer>
  );
};

export default SponsorServiceConfigurationWall;
