import { aws } from '@aim/common';
import { useLoaderHelper } from '@aim/components';
const { API } = aws;

export const breakoutRoomInvitationUnregisteredSchema = `
email
id
note
slot {
    id
}
state
token
`;

const createBreakoutRoomInvitationUnregistered = /* GraphQL */ `
  mutation CreateBreakoutRoomInvitationUnregistered(
    $input: CreateBreakoutRoomInvitationUnregisteredInput!
  ) {
    createBreakoutRoomInvitationUnregistered(input: $input) {
      ${breakoutRoomInvitationUnregisteredSchema}
    }
  }
`;

const deleteBreakoutRoomInvitationUnregistered = /* GraphQL */ `
  mutation DeleteBreakoutRoomInvitationUnregistered(
    $input: DeleteBreakoutRoomInvitationUnregisteredInput!
    $condition: ModelBreakoutRoomInvitationUnregisteredConditionInput
  ) {
    deleteBreakoutRoomInvitationUnregistered(
      input: $input
      condition: $condition
    ) {
      id
    }
  }
`;

const updateBreakoutRoomInvitationUnregistered = /* GraphQL */ `
  mutation UpdateBreakoutRoomInvitationUnregistered(
    $input: UpdateBreakoutRoomInvitationUnregisteredInput!
    $condition: ModelBreakoutRoomInvitationUnregisteredConditionInput
  ) {
    updateBreakoutRoomInvitationUnregistered(
      input: $input
      condition: $condition
    ) {
      id
    }
  }
`;

const listBreakoutRoomInvitationUnregisteredQuery = /* GraphQL */ `
  query listBreakoutRoomInvitationUnregistered {
    listBreakoutRoomInvitationUnregistereds {
      items {
        ${breakoutRoomInvitationUnregisteredSchema}
      }
    }
  }
`;

export function useBreakoutRoomInvitationUnregistered(useLoader = false) {
  const { showLoader, hideLoader } = useLoaderHelper();
  const create = (input) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: createBreakoutRoomInvitationUnregistered,
        variables: { input },
      })
        .then((response) =>
          resolve(response.data.createBreakoutRoomInvitationUnregistered)
        )
        .catch((e) => {
          console.error('create-breakout-room-invitation-unregistered', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const update = (input) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: updateBreakoutRoomInvitationUnregistered,
        variables: { input },
      })
        .then((response) =>
          resolve(response.data.updateBreakoutRoomInvitationUnregistered)
        )
        .catch((e) => {
          console.error('update-breakout-room-invitation-unregistered', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const remove = (id) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: deleteBreakoutRoomInvitationUnregistered,
        variables: { input: { id } },
      })
        .then((response) =>
          resolve(response.data.deleteBreakoutRoomInvitationUnregistered)
        )
        .catch((e) => {
          console.error('delete-breakout-room-invitation-unregistered', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  //   const list = () =>
  //     new Promise((resolve, reject) => {
  //       useLoader && showLoader();
  //       API.graphql({
  //         query: listBreakoutRoomInvitationUnregisteredQuery,
  //       })
  //         .then((response) =>
  //           resolve(response.data.listBreakoutRoomInvitationUnregistereds)
  //         )
  //         .catch((e) => {
  //           console.error('list-breakout-room-invitation-unregistered', e);
  //           reject();
  //         })
  //         .finally(() => useLoader && hideLoader());
  //     });

  return { create, update, remove };
}
