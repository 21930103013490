import { aws, appState, utilities, constants } from '@aim/common';
const { API } = aws;

const { isValidPayment } = utilities;

const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

const listProductsQuery = /* GraphQL */ `
  query getProductsByServiceIdServiceType(
    $serviceId: String
    $serviceType: String
  ) {
    productByServiceIdServiceType(
      serviceId: $serviceId
      serviceType: { eq: $serviceType }
    ) {
      items {
        id
        productId
        clientId
        clientType
        serviceId
        serviceType
        createdAt
        payment {
          id
          paymentId
          paymentType
          paymentIdMonetaOnLine
          paymentIdIngenico
          paymentIdGpWebpay
          paymentError
          amount
          paymentStatus
          createdAt
          updatedAt
        }
      }
    }
  }
`;

const getEventGrantsQuery = /* GraphQL */ `
  query getEventGrants($id: ID!) {
    getEvent(id: $id) {
      id
      grant(filter: { isDeleted: { ne: true } }) {
        items {
          id
          instruction
          type
          title
          closingDate
          participationGrant(filter: { isDeleted: { ne: true } }) {
            items {
              id
              participationGrantGrantId
              participationGrantParticipationId
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;

export const getEventGrants = (id, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: getEventGrantsQuery,
      variables: { id },
    })
      .then((response) => resolve(response))
      .catch((e) => {
        console.error('get-event-grants', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const listProductsRegistration = (id, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: listProductsQuery,
      variables: {
        serviceId: id,
        serviceType: constants.Services.REGISTRATION.key,
      },
    })
      .then(({ data }) => {
        const validPurchases = data.productByServiceIdServiceType?.items.filter(
          (product) => isValidPayment(product.payment)
        );
        resolve(validPurchases.length);
      })
      .catch((e) => {
        console.error('list-products', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });
