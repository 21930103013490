import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';

import Grid from '@material-ui/core/Grid';

import { fileHelper } from '@aim/common';

import {
  AimTypography,
  AimRadioForm,
  AimFormControlLabel,
  AimRadioButton,
  styled,
  theme,
} from '@aim/components';

const Card = styled('div')({
  padding: 20,
  marginBottom: 6,
  backgroundColor: theme.colors.greyScale.backgroundGrey,
});

const Image = styled('div')({
  width: 'calc(100% - 20px)',
  minHeight: 100,
  aspectRatio: 1,
  position: 'relative',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
});

const defaultOptions = [
  {
    label: 'Yes',
    value: 'yes',
  },
  {
    label: 'No',
    value: 'no',
  },
];

const ServiceCard = ({
  i18n,
  data,
  control,
  partipationOptions = defaultOptions,
  eventId,
  cardIdx,
}) => {
  // State
  const [serviceImageSrc, setServiceImageSrc] = useState();

  // Effects
  useEffect(() => {
    if (!data) return;
    getImageSrc();
  }, [data]);

  const { getS3Link } = fileHelper;

  const getImageSrc = async () => {
    const image = data.images.items[0];
    const link = await getS3Link(
      `events/${eventId}/additional-services/${data.id}/gallery`,
      {
        id: `${image.id}${image.extension}`,
        extension: '',
        originalName: ``,
      }
    );
    setServiceImageSrc(link);
  };

  return (
    <Card>
      <Grid container>
        <Grid item xs={2}>
          {serviceImageSrc && (
            <Image
              style={{
                backgroundImage: `url("${serviceImageSrc}")`,
              }}
            />
          )}
        </Grid>
        <Grid item xs={10}>
          <AimTypography variant="textBold" margin="0 0 0 8px">
            {data.title}
          </AimTypography>
          <AimTypography variant="text" margin="0 0 2px 8px">
            {format(new Date(data.dateService), 'dd LLLL yyyy')} at{' '}
            {data.startTime} - {data.locationName} - {data.locationAddress}
          </AimTypography>
          <AimTypography variant="text" margin="0 0 0 8px">
            {data.description}
          </AimTypography>

          <AimTypography variant="textBold">
            {i18n.cards.willParticipate}
          </AimTypography>

          <Grid container>
            <AimRadioForm
              control={control}
              name={`additionalServiceOptions[${cardIdx}].value`}
            >
              {partipationOptions.map(({ label, value }, index) => (
                <AimFormControlLabel
                  key={index}
                  value={value}
                  control={<AimRadioButton />}
                  label={label}
                />
              ))}
            </AimRadioForm>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ServiceCard;
