import React, { useState, Suspense, useEffect } from 'react';

import { isMobileOnly } from 'react-device-detect';
import { useHistory, useParams } from 'react-router-dom';
import { isAfter, isEqual, isValid, differenceInCalendarDays } from 'date-fns';

import {
  styled,
  CustomIntl,
  AimSnackbar,
  AimTypography,
  AimTitleAndButtons,
  AimSnackbarSeverity,
  AimIconAndTextButton,
} from '@aim/components';
import { aws, opensearch, constants, appState } from '@aim/common';

import ArrowBack from '@material-ui/icons/ArrowBack';
import FilterIcon from '@material-ui/icons/FilterList';

import { useIntl } from 'react-intl';
import translation from './allotment/translation';
import FilterRow from './allotment/components/FilterRow';
import { getEventNightsDate } from './allotment/gqlHelper';
const HotelCard = React.lazy(() => import('./allotment/components/HotelCard'));

//--- CUSTOM COMPONENTS
const AllotmentPage = styled('div')({
  width: '100%',
  backgroundColor: 'white',
});

const HotelCardsBox = styled('div')({
  margin: '25px 0',
});

const getQueryObj = ({ eventId, checkIn, checkOut }) => ({
  bool: {
    filter: [
      {
        match_phrase: {
          eventId: eventId,
        },
      },
      {
        range: {
          hotelRoomByDateDate: {
            gte: checkIn,
            lt: checkOut,
          },
        },
      },
      {
        range: {
          freeRooms: {
            gt: 0,
          },
        },
      },
      {
        match_phrase: {
          'hotelRoomAvailabilityChannelType.keyword':
            constants.AllotmentChannels.WEB.id,
        },
      },
    ],
  },
});

const getQueryObjSpeaker = ({ eventId, checkIn, checkOut }) => ({
  bool: {
    filter: [
      {
        match_phrase: {
          eventId: eventId,
        },
      },
      {
        range: {
          hotelRoomByDateDate: {
            gte: checkIn,
            lt: checkOut,
          },
        },
      },
      {
        range: {
          freeRooms: {
            gt: 0,
          },
        },
      },
      {
        match_phrase: {
          'hotelRoomAvailabilityChannelType.keyword':
            constants.AllotmentChannels.SPEAKER.id,
        },
      },
    ],
  },
});

const getAggregationObj = ({ nights, roomsNumber, guestsNumber }) => ({
  hotels: {
    terms: {
      field: 'hotelName.keyword',
    },
    aggs: {
      rooms: {
        terms: {
          field: 'hotelRoomName.keyword',
          size: 10000,
        },
        aggs: {
          dates: {
            terms: {
              field: 'hotelRoomByDateDate',
              size: 10000,
            },
            aggs: {
              prices: {
                nested: {
                  path: 'hotelRoomPrice',
                },
                aggs: {
                  filter_prices: {
                    filter: {
                      bool: {
                        filter: [
                          { range: { 'hotelRoomPrice.price': { gt: 0 } } },
                        ],
                      },
                    },
                    aggs: {
                      min_price: {
                        min: {
                          field: 'hotelRoomPrice.price',
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          free_rooms: {
            min: {
              field: 'freeRooms',
            },
          },
          max_occupancy: {
            min: {
              field: 'hotelRoomMaxOccupancy',
            },
          },
          range_room_price_from: {
            sum_bucket: {
              buckets_path: 'dates>prices>filter_prices>min_price',
            },
          },
          range_room_max_occupancy: {
            bucket_script: {
              buckets_path: {
                freeRooms: 'free_rooms.value',
                maxOccupancy: 'max_occupancy.value',
              },
              script: 'params.freeRooms * params.maxOccupancy',
            },
          },
          all_night_filter: {
            bucket_selector: {
              buckets_path: {
                count: 'dates._bucket_count',
              },
              script: `params.count == ${nights}`,
            },
          },
        },
      },
      range_rooms_max_occupancy: {
        sum_bucket: {
          buckets_path: 'rooms>range_room_max_occupancy',
        },
      },
      range_rooms_free_rooms: {
        sum_bucket: {
          buckets_path: 'rooms>free_rooms',
        },
      },
      range_rooms_price_from: {
        min_bucket: {
          buckets_path: 'rooms>range_room_price_from',
        },
      },
      hotel_max_occupancy_filter: {
        bucket_selector: {
          buckets_path: {
            rangeFreeRooms: 'range_rooms_free_rooms',
            rangeMaxOccupancy: 'range_rooms_max_occupancy',
          },
          script: `params.rangeFreeRooms >= ${roomsNumber} && params.rangeMaxOccupancy >= ${guestsNumber}`,
        },
      },
      hits: {
        top_hits: {
          sort: [
            {
              createdAt: 'desc',
            },
          ],
          size: 1,
        },
      },
    },
  },
});

// ALLOTMENT COMPONENT
const Allotment = () => {
  const [formData, setFormData] = useState(null);
  const [nightsDates, setNightsDates] = useState({
    startDate: '',
    endDate: '',
  });
  const [hotelsList, setHotelsList] = useState(null);
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [useMobileFilter, SetUseMobileFilter] = useState(false);

  const history = useHistory();
  const { eventId } = useParams();
  const intl = CustomIntl(useIntl());
  const i18n = translation.allotment(intl);
  const [participation] = useState(appState.getCurrentParticipation());

  useEffect(() => {
    const getDates = async () => {
      const dates = await getEventNightsDate(eventId);
      setNightsDates(dates);
    };
    getDates();
  }, []);

  const getS3Image = async (hotels) => {
    const hotelsList = await Promise.all(
      hotels.map(async (hotel) => {
        console.log('images', hotel.images);
        if (hotel.images.length > 0) {
          const image = hotel.images[0];
          const imageLink = await aws.Storage.get(
            `hotels/${hotel.id}/images/${image.id}${image.extension}`
          );
          hotel.imageLink = imageLink;
        }
        return hotel;
      })
    );
    return hotelsList;
  };

  const getHotelList = async (formData) => {
    // const language = appState.locale.getValue();
    // const hotelsList = await listEventHotels(eventId, language, formData);
    appState.isLoader.next(true);
    const hotelsResp = await opensearch.sendStandardQuery({
      fullOpensearchResponse: true,
      index: 'allotmentbookingoverview',
      filterBody: {
        query: participation.isSpeaker
          ? getQueryObjSpeaker({
              eventId,
              checkIn: formData.checkIn,
              checkOut: formData.checkOut,
              roomsNumber: formData.roomsNumber,
            })
          : getQueryObj({
              eventId,
              checkIn: formData.checkIn,
              checkOut: formData.checkOut,
              roomsNumber: formData.roomsNumber,
            }),
        aggs: getAggregationObj({
          nights: formData.nights,
          roomsNumber: formData.roomsNumber,
          guestsNumber: formData.guestsNumber,
        }),
        size: 0,
        from: 0,
      },
    });
    const mappedHotels = hotelsResp.aggregations.hotels.buckets.map(
      (hotel) => ({
        id: hotel.hits.hits.hits[0]._source.hotelId,
        name: hotel.hits.hits.hits[0]._source.hotelName,
        address: hotel.hits.hits.hits[0]._source.hotelAddress || '',
        descriptions: hotel.hits.hits.hits[0]._source.descriptions || [],
        stars: hotel.hits.hits.hits[0]._source.hotelStars || 0,
        images: hotel.hits.hits.hits[0]._source.images,
        hotelEventVentureId:
          hotel.hits.hits.hits[0]._source.hotelEventVentureId,
        priceFrom: hotel.range_rooms_price_from.value,
      })
    );
    const hotels = await getS3Image(mappedHotels);
    console.log('hotels', hotels);
    setHotelsList(hotels);
    appState.isLoader.next(false);
  };

  // MAIN FUNCTIONS
  const filterSearch = async (nextFormData) => {
    //is ok with timezone because we not showing nothing and both dates will be shifter in the same way
    const checkInDate = new Date(nextFormData.checkIn);
    const checkOutDate = new Date(nextFormData.checkOut);
    console.log(
      'checkInDate,checkInDate',
      checkInDate.toISOString(),
      checkOutDate.toISOString(),
      differenceInCalendarDays(checkOutDate, checkInDate)
    );
    const nights = differenceInCalendarDays(checkOutDate, checkInDate);

    const dateValidation = isAfter(checkOutDate, checkInDate);
    const isDateEqual = isEqual(checkInDate, checkOutDate);
    if (isDateEqual || dateValidation) {
      await getHotelList({ ...nextFormData, nights });
      setFormData({ ...nextFormData, nights });
      isMobileOnly && SetUseMobileFilter(true);
      return;
    }
    // errors
    if (!isValid(checkInDate)) {
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.page.checkInNotSelected,
      });
      return;
    }
    if (!isValid(checkOutDate)) {
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.page.checkOutNotSelected,
      });
      return;
    }
    if (!dateValidation) {
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.page.dateError,
      });
      return;
    }
  };

  const goToHotelDetail = (hotelEventVentureId) => {
    history.push({
      pathname: `/events/${eventId}/allotment/${hotelEventVentureId}`,
      state: formData,
    });
  };

  return (
    <>
      <AllotmentPage
        style={{
          paddingLeft: isMobileOnly ? '3%' : '10%',
          paddingRight: isMobileOnly ? '3%' : '10%',
        }}
      >
        {useMobileFilter ? (
          <AimIconAndTextButton
            isUpperCase
            variant="none"
            text={i18n.page.filter}
            style={{
              padding: 0,
              marginTop: '5%',
            }}
            onClick={() => SetUseMobileFilter(false)}
          >
            <FilterIcon />
          </AimIconAndTextButton>
        ) : (
          <AimIconAndTextButton
            isUpperCase
            variant="none"
            text={i18n.page.back}
            style={{
              padding: 0,
              marginTop: isMobileOnly ? '5%' : '2%',
            }}
            onClick={() => history.push(`/events/${eventId}/landing`)}
          >
            <ArrowBack />
          </AimIconAndTextButton>
        )}
        <AimTitleAndButtons title={i18n.page.title} />
        <FilterRow
          i18n={i18n}
          nightsDates={nightsDates}
          isMobileOnly={isMobileOnly}
          onSearchClick={filterSearch}
          useMobileFilter={useMobileFilter}
        />
        {(!isMobileOnly || useMobileFilter) && (
          <HotelCardsBox>
            {hotelsList?.filter((h) => h.priceFrom > 0).length > 0
              ? hotelsList?.map((hotel, idx) => {
                  return (
                    <Suspense key={idx} fallback={<div>Loading...</div>}>
                      <HotelCard
                        key={idx}
                        i18n={i18n}
                        hotel={hotel}
                        onClickGetHotelDetail={goToHotelDetail}
                      />
                    </Suspense>
                  );
                })
              : hotelsList != null && (
                  <AimTypography variant="text">
                    {i18n.page.filterNoResults}
                  </AimTypography>
                )}
          </HotelCardsBox>
        )}
      </AllotmentPage>
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </>
  );
};

export default Allotment;
