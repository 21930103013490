import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { isMobileOnly } from 'react-device-detect';
import Grid from '@material-ui/core/Grid';

import ArrowBack from '@material-ui/icons/ArrowBack';

import CheckIcon from '@material-ui/icons/Check';
import {
  theme,
  AimTypography,
  AimIconAndTextButton,
  AimTitleAndButtons,
  CustomIntl,
} from '@aim/components';
import useI18n from './i18n';
import { listCertifications } from '../shared/papersGqlHelper';
import { listBillingDocsByClientId as fetchMyInvoices } from '../shared/billingGqlHelper';
import {
  Card,
  CardHeader,
  CardStatus,
  Container,
  MainContainer,
  TabWrapper,
} from './Services/styles';

import {
  getOthersParticipantAvailableServices,
  getProductsByClientIdServiceId,
} from './profileHelper';
import { appState, constants, utilities } from '@aim/common';
import Allotment from '../Allotment';

const {
  REGISTRATION: { key: RegistrationKey },
  ALLOTMENT: { key: AllotmentKey },
  SOCIAL_EVENT: { key: SocialEventKey },
} = constants.Services;
const {
  ABSTRACT: { key: AbstractKey },
  EDUCATIONAL: { key: EducationalKey },
} = constants.GrantType;

const Services = () => {
  const intl = CustomIntl(useIntl());
  const i18n = useI18n(intl);
  const { eventId, participationId } = useParams();
  const history = useHistory();

  const [ticket, setTicket] = useState();
  const [, setProducts] = useState();
  const [certifications, setCertifications] = useState([]);
  const [servicesTypeInProds, setServicesTypeInProds] = useState([]);
  const [myInvoices, setMyInvoices] = useState([]);
  const [selectedTabValue, setSelectedTabValue] = useState(RegistrationKey);

  useEffect(() => {
    const fetchProductsByClientIdServiceId = async () => {
      try {
        const products = await getProductsByClientIdServiceId(
          participationId,
          eventId
        );

        const otherAvailableServices = await getOthersParticipantAvailableServices(
          participationId
        );
        if (products) {
          const ticketProduct = products
            .filter(
              (prod) => prod.serviceType === constants.Services.REGISTRATION.key
            )
            .filter((product) => utilities.isValidPayment(product.payment));
          setTicket((ticketProduct.length && ticketProduct[0].id) || false);
          setProducts(products);

          const uniqueServiceTypes = [
            ...new Set(
              [...products, ...otherAvailableServices].map(
                (item) => item.serviceType
              )
            ),
          ];

          setServicesTypeInProds(uniqueServiceTypes);
        }
      } catch (err) {
        console.error(err);
      }
    };
    const fetchCertificationsData = async () => {
      const event = await listCertifications(eventId, participationId);
      console.log('papers', event.papers);
      console.log(
        'papers',
        appState.user.getValue()?.userAndParticipation?.participation
          ?.filledPapers?.items
      );
      const nextPapers = [
        ...event.papers.items,
        ...(appState.user.getValue()?.userAndParticipation?.participation
          ?.filledPapers?.items || []),
      ];
      if (nextPapers.length) {
        setCertifications(nextPapers);
      }
    };
    fetchProductsByClientIdServiceId();
    fetchMyInvoices(participationId).then(setMyInvoices);

    fetchCertificationsData();
  }, []);

  const TabsData = {
    ...(servicesTypeInProds.includes(constants.Services.REGISTRATION.key) && {
      [RegistrationKey]: {
        tabTitle: i18n.page.registrations,
        children: <div>REGISTRATION</div>,
      },
    }),
    ...(servicesTypeInProds.includes(constants.GrantType.ABSTRACT.key) && {
      [AbstractKey]: {
        tabTitle: i18n.services.abstractGrantTitle,
        children: <div>ABSTRACT</div>,
      },
    }),
    ...(servicesTypeInProds.includes(constants.GrantType.EDUCATIONAL.key) && {
      [EducationalKey]: {
        tabTitle: i18n.services.educationalGrantTitle,
        children: <div>EDUCATIONAL</div>,
      },
    }),
    ...(servicesTypeInProds.includes(constants.Services.ALLOTMENT.key) && {
      [AllotmentKey]: {
        tabTitle: i18n.services.allotmentTitle,
        children: <div>ALLOTMENT</div>,
      },
    }),
    ...(servicesTypeInProds.includes(constants.Services.SOCIAL_EVENT.key) && {
      ['SOCIAL_EVENTS']: {
        tabTitle: i18n.services.socialEvents,
        children: <div>SOCIAL_EVENTS</div>,
      },
    }),
    ...(servicesTypeInProds.includes(
      constants.Services.SCIENTIFIC_EVENT.key
    ) && {
      ['SCIENTIFIC_EVENTS']: {
        tabTitle: i18n.services.scientificEvents,
        children: <div>SCIENTIFIC_EVENTS</div>,
      },
    }),
    ...(certifications.length > 0 && {
      ['CERTIFICATIONS']: {
        tabTitle: i18n.services.certificateListTitle,
        children: <div>CERTIFICATIONS</div>,
      },
    }),
  };

  // const listServices = (i18n) => [
  //   {
  //     ...(servicesTypeInProds.includes(constants.Services.REGISTRATION.key) && {
  //       key: RegistrationKey,
  //       title: i18n.page.registrations,
  //       description: i18n.services.myTicketDesc,
  //       ticket,
  //       disableFunc: () => !ticket,
  //       onClick: () =>
  //         history.push(`/events/${eventId}/user/${participationId}/my-tickets`),
  //     }),
  //   },

  //   {
  //     ...(servicesTypeInProds.includes(constants.GrantType.ABSTRACT.key) && {
  //       key: AbstractKey,
  //       title: i18n.services.abstractGrantTitle,
  //       description: i18n.services.abstractGrantDesc,
  //       onClick: () =>
  //         history.push(
  //           `/events/${eventId}/user/${participationId}/my-grants/${constants.GrantType.ABSTRACT.key}`
  //         ),
  //     }),
  //   },
  //   {
  //     ...(servicesTypeInProds.includes(constants.GrantType.EDUCATIONAL.key) && {
  //       key: EducationalKey,
  //       title: i18n.services.educationalGrantTitle,
  //       description: i18n.services.educationalGrantDesc,
  //       onClick: () =>
  //         history.push(
  //           `/events/${eventId}/user/${participationId}/my-grants/${constants.GrantType.EDUCATIONAL.key}`
  //         ),
  //     }),
  //   },
  //   {
  //     ...(servicesTypeInProds.includes(constants.Services.ALLOTMENT.key) && {
  //       key: AllotmentKey,
  //       title: i18n.services.allotmentTitle,
  //       description: i18n.services.allotmentDesc,
  //       ticket,
  //       disableFunc: () => !ticket,
  //       onClick: () =>
  //         history.push(
  //           `/events/${eventId}/user/${participationId}/my-allotment`
  //         ),
  //     }),
  //   },

  //   {
  //     ...((servicesTypeInProds.includes(constants.Services.SOCIAL_EVENT.key) ||
  //       servicesTypeInProds.includes(
  //         constants.Services.SCIENTIFIC_EVENT.key
  //       )) && {
  //       key: SocialEventKey,
  //       title: i18n.services.additionalServiceTitle,
  //       description: i18n.services.additionalServiceDesc,
  //       ticket,
  //       disableFunc: () => !ticket,
  //       onClick: () =>
  //         history.push(
  //           `/events/${eventId}/user/${participationId}/additional-services`
  //         ),
  //     }),
  //   },
  //   {
  //     ...(certifications.length > 0 && {
  //       title: i18n.services.certificateListTitle,
  //       description: i18n.services.certificateListDesc,
  //       key: 'CERTIFICATIONS',
  //       onClick: () =>
  //         history.push(
  //           `/events/${eventId}/user/${participationId}/certifications`
  //         ),
  //     }),
  //   },
  // ];

  function Tab({ value, children, ...other }) {
    return (
      <div
        {...other}
        style={{
          height: '50px',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          width: '100%',
          minWidth: 200,
          fontWeight: selectedTabValue === value ? 'bold' : 'normal',
          borderBottom: `${selectedTabValue === value ? 2 : 1}px solid ${
            selectedTabValue === value
              ? theme.colors.dark.yellow
              : theme.colors.secondary.lightGrey
          }`,
          fontSize: '18px',
        }}
      >
        {children}
      </div>
    );
  }

  return (
    <MainContainer isMobileOnly={isMobileOnly}>
      <div style={{ width: '100%' }}>
        <AimIconAndTextButton
          variant="none"
          onClick={() => {
            history.push(`/events/${eventId}/user/${participationId}`);
          }}
        >
          <ArrowBack style={{ color: theme.colors.greyScale.grey3 }} />
          <AimTypography variant="text">{i18n.actions.back}</AimTypography>
        </AimIconAndTextButton>
        <AimTitleAndButtons title={i18n.page.myServices} />
      </div>
      <TabWrapper>
        {Object.entries(TabsData).map(([key, value]) => (
          <Tab onClick={() => setSelectedTabValue(key)} value={key} key={key}>
            {value.tabTitle}
          </Tab>
        ))}
      </TabWrapper>
      {TabsData[selectedTabValue]?.children || <></>}
    </MainContainer>
  );
};

export default Services;
