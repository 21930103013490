import React from 'react';
import { theme, AimTypography } from '@aim/components';
import GroupIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';

export const BreakoutRoomCalendarSlot = ({ props, i18n }) => {
  const event = props.event;
  const accepted = event.invitations.filter((r) => r.state === 'accepted')
    .length;
  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <AimTypography
          variant={`${event.title ? 'h6' : 'text'}`}
          italic={!event.title}
          margin={0}
        >
          {event.title || i18n.placeholder}
        </AimTypography>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          {accepted > 0 ? (
            <AimTypography variant={'text'} margin={0}>
              {`${accepted} ${i18n.accepted}`}
            </AimTypography>
          ) : null}
          <div style={{ marginLeft: 8, color: theme.colors.greyScale.grey4 }}>
            {event.isOneToOne ? <PersonIcon /> : <GroupIcon />}
          </div>
        </div>
      </div>
    </div>
  );
};
