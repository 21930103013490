import React, { useState, useEffect } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';

import {
  styled,
  AimTextField,
  AimRichText,
  AimIconButton,
  AimIconAndTextButton,
  AimDragDropFileUploader,
  AimTypography,
  AimSelectForm,
  AimSelectMenuItem,
  AimFileSelector,
  AimSnackbarSeverity,
  CitiesAutocompleteByGeoDbDynamic,
  CountriesAutocompleteByGeoDbDynamic,
} from '@aim/components';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
// import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FileTypeEnum } from '../sponsorDetail/enums';
// import { isValidEmail99 } from '../sponsorDetail/regexpHelper';
// import { parseRichText } from '../sponsorDetail/sponsorDetailHelper';
import { appState, constants } from '@aim/common';

const formControlStyle = { width: 'calc(100% - 15px)' };

const CollapseContent = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  padding: '28px 28px 26px 28px',
  backgroundColor: '#30CFD021',
  marginBottom: '20px',
  flexWrap: 'wrap',
});

const videoProviders = [
  { key: 'youtube', label: 'Youtube' },
  { key: 'vimeo', label: 'Vimeo' },
];

const SponsorDetailEditForm = ({
  intl,
  control,
  register,
  setValue,
  getValues,
  downloadFile,
  setSnackbar,
  i18n,
  theme,
  deleteList,
  setDeleteList,
  watch,
  errors,
  fetchedData,
  clearErrors,
}) => {
  const [contactCardCtrl, setCollapsableCtrl] = useState([]);

  const useStyles = makeStyles({
    root: {
      '& .MuiFormControl-root': {
        width: '100%',
      },
    },
  });
  const classes = useStyles();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'contacts',
  });

  const profileImage = watch('profileImage');
  const videoCoverImage = watch('videoCoverImage');
  const profileCover = watch('profileCover');
  const documents = watch('documents');

  const isDefaultCoverEnabled = (
    appState.eventConfiguration?.getValue().defaultHeaderCoverImagePages || []
  ).includes(constants.WebsitePages.SPONSOR_PAGE.key);

  useEffect(() => {
    fields.map((field, i) => {
      setCollapsableCtrl((prevState) => ({
        ...prevState,
        [i]: {
          ...prevState[i],
          isVisible: false,
        },
      }));
    });
  }, []);

  const handleExpandClick = (index) => {
    setCollapsableCtrl((prevState) => ({
      ...prevState,
      [index]: { ...prevState[index], isVisible: !prevState[index]?.isVisible },
    }));
  };

  const handleRemoveClick = (idx) => {
    if (fields[idx].id) {
      deleteList.contacts.push(fields[idx].id);
      setDeleteList(deleteList);
    }
    remove(idx);
  };

  // const handleEmailOnChange = (email, index) => {
  //   const emailError = email ? !isValidEmail99(email) : false;
  //   setCollapsableCtrl((prevState) => ({
  //     ...prevState,
  //     [index]: { ...prevState[index], emailError: emailError },
  //   }));
  // };

  const handleAppendClick = () => {
    setCollapsableCtrl((prevState) => ({
      ...prevState,
      [fields.length]: { ...prevState[fields.length], isVisible: true },
    }));
    append({});
  };

  // const handleOnLoadDragDrop = (fileType) => {
  //   /*
  //    * Function to handle onLoad DragDrop
  //    */
  // };

  const handleOnUploadFile = (fileType, selectedFiles = null) => {
    if (selectedFiles) {
      let formObj = getValues(fileType);

      selectedFiles.forEach((file) => {
        const extension = file.name.match(/\.[0-9a-z]+$/i);
        const _file = {
          id: null,
          name: file.name || new Date().getTime().toString(),
          size: file.size || 0,
          extension: extension ? extension[0] : '',
          file: file,
        };
        if (fileType === FileTypeEnum.documents) {
          formObj.items.push(_file);
        } else formObj = _file;
      });

      setValue(fileType, formObj);
    }
  };

  const handleOnDownloadFile = (fileType, selectedFile = null) => {
    if (selectedFile) {
      if (selectedFile.id) downloadFile(selectedFile, fileType);
      else
        setSnackbar({
          isOpen: true,
          severity: AimSnackbarSeverity.error,
          message: 'You cannot download this file',
        });
    }
  };

  const handleOnRemoveFile = (fileType, selectedFile = null) => {
    if (selectedFile) {
      let formObj = getValues(fileType);

      if (fileType === FileTypeEnum.documents)
        formObj.items = formObj.items.filter((item) => {
          return selectedFile.id || item.id
            ? item.id != selectedFile.id
            : item.name != selectedFile.name;
        });
      else formObj = null;
      if (selectedFile.id) {
        if (fileType === FileTypeEnum.documents)
          deleteList[fileType].push(selectedFile);
        else deleteList[fileType] = selectedFile;
        setDeleteList(deleteList);
      }
      setValue(fileType, formObj);
    }
  };

  const renderError = (key) => {
    return (
      !!errors[key] && (
        <AimTypography variant="formError">
          {`${errors[key]?.message} `}
        </AimTypography>
      )
    );
  };

  const renderVideoCoverImageInput = ({ isRequired = false }) => {
    const key = 'videoCoverOrigImg';
    return (
      <>
        <Controller
          render={(props) => (
            <AimFileSelector
              label={i18n.labels.coverImage}
              selectFileLabel={`${
                i18n.labels.upload
              }, ${i18n.labels.coverImage.toLowerCase()}`}
              onUploadFile={(res) => {
                handleOnUploadFile(FileTypeEnum.videoCoverImage, [res.file]);
                props.onChange(res.parsed);
              }}
              onRemove={(file) =>
                handleOnRemoveFile(FileTypeEnum.videoCoverImage, file)
              }
              onDownload={(file) =>
                handleOnDownloadFile(FileTypeEnum.videoCoverImage, file)
              }
              // file={props.value}
              file={
                videoCoverImage && {
                  ...videoCoverImage,
                  ...{ originalName: videoCoverImage?.name },
                }
              }
              maxFiles={1}
              formControlStyle={{
                ...formControlStyle,
                margin: '8px',
              }}
              dropzoneAdditionalProps={{ accept: '.jpeg,.jpg,.png' }}
            />
          )}
          rules={{
            required: { isRequired },
          }}
          name={key}
          control={control}
        />
        {renderError(key)}
      </>
    );
  };

  return (
    <Grid container spacing={3}>
      <Grid
        item
        xs={12}
        spacing={3}
        style={{
          backgroundColor: theme.greyScale.backgroundGrey,
        }}
      >
        <Controller
          render={() => (
            <AimTextField
              textfieldVariant="secondary"
              inputRef={register}
              name="name"
              label={i18n.labels.sponsorName}
              type="text"
              formControlStyle={formControlStyle}
            />
          )}
          name={`name`}
          control={control}
        />
      </Grid>
      <Grid
        item
        spacing={3}
        xs={12}
        style={{
          paddingTop: 0,
          backgroundColor: theme.greyScale.backgroundGrey,
        }}
      >
        <AimTypography variant="textBold">
          {i18n.labels.profileImage}
        </AimTypography>
        <div
          style={{
            ...formControlStyle,
            backgroundColor: 'white',
            margin: 5,
          }}
        >
          <AimDragDropFileUploader
            variant="secondary"
            intl={intl}
            // label={i18n.labels.profileImage}
            uploadBtnWithText={i18n.labels.profileBtnText.toUpperCase()}
            style={{
              '& div.dropzone div': {
                justifyContent: 'flex-start !important',
              },
              '& div.innerContainer': {
                height: 70,
              },
              '& div.innerContainer>div': {
                height: 70,
              },
            }}
            // onLoad={() => handleOnLoadDragDrop(FileTypeEnum.profileImage)}
            onUploadFile={(files) =>
              handleOnUploadFile(FileTypeEnum.profileImage, files)
            }
            onRemove={(file) =>
              handleOnRemoveFile(FileTypeEnum.profileImage, file)
            }
            onDownload={(file) =>
              handleOnDownloadFile(FileTypeEnum.profileImage, file)
            }
            files={profileImage ? [profileImage] : []}
            maxFiles={1}
          />
        </div>
      </Grid>
      {!isDefaultCoverEnabled && (
        <Grid
          item
          spacing={3}
          xs={12}
          style={{
            paddingTop: 0,
            backgroundColor: theme.greyScale.backgroundGrey,
          }}
        >
          <AimTypography variant="textBold">
            {i18n.labels.coverImage}
          </AimTypography>
          <div
            style={{
              ...formControlStyle,
              backgroundColor: 'white',
              margin: 5,
              marginTop: 13,
            }}
          >
            <AimDragDropFileUploader
              intl={intl}
              uploadBtnWithText={i18n.labels.profileBtnText.toUpperCase()}
              style={{
                '& div.dropzone div': {
                  justifyContent: 'flex-start !important',
                },
                '& div.innerContainer': {
                  height: 70,
                },
                '& div.innerContainer>div': {
                  height: 70,
                },
              }}
              // onLoad={() => handleOnLoadDragDrop(FileTypeEnum.profileCover)}
              onUploadFile={(files) =>
                handleOnUploadFile(FileTypeEnum.profileCover, files)
              }
              onRemove={(file) =>
                handleOnRemoveFile(FileTypeEnum.profileCover, file)
              }
              onDownload={(file) =>
                handleOnDownloadFile(FileTypeEnum.profileCover, file)
              }
              files={profileCover ? [profileCover] : []}
              maxFiles={1}
            />
          </div>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        spacing={3}
        style={{
          backgroundColor: theme.greyScale.backgroundGrey,
        }}
      >
        {fetchedData && (
          <Controller
            render={(props) => (
              <AimRichText
                // value={parseRichText(getValues('description'))}
                value={
                  fetchedData.description
                    ? JSON.parse(fetchedData.description)
                    : ''
                }
                label={i18n.labels.description}
                style={formControlStyle}
                onChange={(e) => props.onChange(e)}
                defaultValue={null}
                variant="white"
              />
            )}
            name="description"
            control={control}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <AimTypography variant="h3" boxStyle={{ margin: 0 }}>
          {i18n.labels.info.toUpperCase()}
        </AimTypography>
      </Grid>

      <Grid
        item
        xs={12}
        style={{
          backgroundColor: theme.greyScale.backgroundGrey,
        }}
      >
        <div
          style={{
            ...formControlStyle,
            backgroundColor: theme.greyScale.backgroundGrey,
            margin: '10px 5px 5px 0px',
          }}
        >
          {fields.map((contact, contactIdx) => (
            <div
              key={contact.id || contactIdx}
              style={{ background: 'white', padding: 10, marginBottom: 8 }}
            >
              <Grid container alignContent="center">
                <Grid item xs={10}>
                  <AimTypography
                    variant="h6"
                    style={{ lineHeight: '48px', marginLeft: 8 }}
                  >
                    {`${contact.name || ''} ${contact.surname || ''}`}
                  </AimTypography>
                </Grid>
                <Grid item xs={2}>
                  <AimIconButton
                    variant="none"
                    onClick={() => handleExpandClick(contactIdx)}
                    style={{ float: 'right', marginRight: 15 }}
                  >
                    <ExpandMoreIcon />
                  </AimIconButton>
                </Grid>
              </Grid>

              <Collapse in={contactCardCtrl[contactIdx]?.isVisible}>
                <CollapseContent
                  style={{
                    backgroundColor: 'white',
                    margin: 5,
                    marginTop: 13,
                    padding: 5,
                  }}
                >
                  <Grid
                    container
                    alignItems="center"
                    spacing={1}
                    className={classes.root}
                  >
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <AimTextField
                        inputRef={register}
                        name={`contacts[${contactIdx}].name`}
                        label={i18n.labels.name}
                        type="text"
                        style={formControlStyle}
                        defaultValue={contact.name}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <AimTextField
                        inputRef={register}
                        name={`contacts[${contactIdx}].surname`}
                        label={i18n.labels.surname}
                        type="text"
                        style={formControlStyle}
                        defaultValue={contact.surname}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <AimTextField
                        inputRef={register}
                        name={`contacts[${contactIdx}].email`}
                        label={i18n.labels.email}
                        type="text"
                        style={formControlStyle}
                        //TODO: fare il controllo sulla email?
                        // inputPropsStyle={{
                        //   ...(!!contactCardCtrl[contactIdx]?.emailError && {
                        //     background: 'red',
                        //   }),
                        // }}
                        // onChange={(event) =>
                        //   handleEmailOnChange(event.target.value, contactIdx)
                        // }
                        defaultValue={contact.email}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <AimTextField
                        inputRef={register}
                        name={`contacts[${contactIdx}].phone`}
                        label={i18n.labels.phone}
                        type="text"
                        style={formControlStyle}
                        defaultValue={contact.phone}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <AimTextField
                        inputRef={register}
                        name={`contacts[${contactIdx}].address`}
                        label={i18n.labels.address}
                        type="text"
                        style={formControlStyle}
                        defaultValue={contact.address}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CitiesAutocompleteByGeoDbDynamic
                        clearErrors={clearErrors}
                        control={control}
                        register={register}
                        setValue={setValue}
                        errors={errors}
                        name={`contacts[${contactIdx}].city`}
                        label={i18n.labels.city}
                        formControlStyle={formControlStyle}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CountriesAutocompleteByGeoDbDynamic
                        clearErrors={clearErrors}
                        control={control}
                        register={register}
                        setValue={setValue}
                        errors={errors}
                        name={`contacts[${contactIdx}].country`}
                        label={i18n.labels.country}
                        formControlStyle={formControlStyle}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <AimTextField
                        inputRef={register}
                        name={`contacts[${contactIdx}].website`}
                        label={i18n.labels.website}
                        type="text"
                        style={formControlStyle}
                        defaultValue={contact.website}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <AimTextField
                        inputRef={register}
                        name={`contacts[${contactIdx}].linkedin`}
                        label={i18n.labels.linkedin}
                        type="text"
                        style={formControlStyle}
                        defaultValue={contact.linkedin}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <AimTextField
                        inputRef={register}
                        name={`contacts[${contactIdx}].facebook`}
                        label={i18n.labels.facebook}
                        type="text"
                        style={formControlStyle}
                        defaultValue={contact.facebook}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <AimTextField
                        inputRef={register}
                        name={`contacts[${contactIdx}].twitter`}
                        label={i18n.labels.twitter}
                        type="text"
                        style={formControlStyle}
                        defaultValue={contact.twitter}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <AimTextField
                        inputRef={register}
                        name={`contacts[${contactIdx}].instagram`}
                        label={i18n.labels.instagram}
                        type="text"
                        style={formControlStyle}
                        defaultValue={contact.instagram}
                      />
                    </Grid>
                    <Grid item xs={10}></Grid>
                    <Grid item xs={2}>
                      <AimIconAndTextButton
                        style={{ float: 'right' }}
                        variant={'lightBlueFill'}
                        onClick={() => handleRemoveClick(contactIdx, contact)}
                        text={i18n.actions.delete}
                      >
                        <ClearIcon />
                      </AimIconAndTextButton>
                    </Grid>
                  </Grid>
                </CollapseContent>
              </Collapse>
            </div>
          ))}
          <AimIconAndTextButton
            variant="yellowFill"
            onClick={() => handleAppendClick()}
            text={i18n.labels.addContact.toUpperCase()}
            large
          >
            {/* <AddIcon /> */}
          </AimIconAndTextButton>
        </div>
      </Grid>
      <Grid item xs={12}>
        <AimTypography variant="h3" boxStyle={{ margin: 0 }}>
          {i18n.labels.documents.toUpperCase()}
        </AimTypography>
      </Grid>
      <Grid
        item
        xs={12}
        spacing={3}
        style={{
          backgroundColor: theme.greyScale.backgroundGrey,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column-reverse',
          }}
        >
          <AimDragDropFileUploader
            variant="secondary"
            intl={intl}
            // label={i18n.labels.documents.toUpperCase()}
            uploadBtnWithText={i18n.labels.documentsBtnText.toUpperCase()}
            isSmallGridEnabled
            grid2ColsEnabled
            style={{
              '& div.dropzone div': {
                justifyContent: 'flex-start !important',
              },
              '& div.innerContainer': {
                height: 70,
              },
              '& div.innerContainer>div': {
                height: 70,
              },
            }}
            // onLoad={() => handleOnLoadDragDrop(FileTypeEnum.documents)}
            onUploadFile={(files) =>
              handleOnUploadFile(FileTypeEnum.documents, files)
            }
            onRemove={(file) =>
              handleOnRemoveFile(FileTypeEnum.documents, file)
            }
            onDownload={(file) =>
              handleOnDownloadFile(FileTypeEnum.documents, file)
            }
            files={documents?.items || []}
            dropzoneAdditionalProps={{
              multiple: true,
              maxFiles: 4,
            }}
          />
        </div>
      </Grid>
      <Grid item xs={12}>
        <AimTypography variant="h3" boxStyle={{ margin: 0 }}>
          {i18n.labels.video.toUpperCase()}
        </AimTypography>
      </Grid>
      <Grid
        item
        xs={12}
        spacing={3}
        style={{
          backgroundColor: theme.greyScale.backgroundGrey,
        }}
      >
        <Grid
          container
          alignItems="center"
          spacing={1}
          className={classes.root}
        >
          <Grid item xs={12} md={6}>
            <AimTextField
              textfieldVariant="secondary"
              inputRef={register}
              name="videoTitle"
              label={i18n.labels.title}
              type="text"
              style={formControlStyle}
              defaultValue={getValues('videoTitle')}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <AimTextField
              textfieldVariant="secondary"
              inputRef={register}
              name="videoSubtitle"
              label={i18n.labels.subtitle}
              type="text"
              style={formControlStyle}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <AimTextField
              textfieldVariant="secondary"
              inputRef={register}
              name="videoLink"
              label={i18n.labels.link}
              type="text"
              style={formControlStyle}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <AimTextField
              textfieldVariant="secondary"
              inputRef={register}
              name="videoEmbeddedLink"
              label={i18n.labels.embeddedLink}
              type="text"
              style={formControlStyle}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <AimSelectForm
              selectVariant="secondary"
              control={control}
              errors={errors}
              label={i18n.labels.provider.label}
              name="videoProvider"
              selectPlaceholder={i18n.labels.provider.placeholder}
              formControlStyle={formControlStyle}
            >
              {videoProviders.map((item) => {
                return (
                  <AimSelectMenuItem key={item.key} value={item.key}>
                    {item.label}
                  </AimSelectMenuItem>
                );
              })}
            </AimSelectForm>
          </Grid>
          <Grid item xs={12} md={6}>
            {renderVideoCoverImageInput({ isRequired: true })}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SponsorDetailEditForm;
