import React from 'react';

import { useIntl } from 'react-intl';
import useI18n from '../../shared/i18n';

import { CustomIntl, AimTypography, AimDialog } from '@aim/components';

const InvitationParticipationNoteDialog = ({ noteDialog, onClose }) => {
  const intl = CustomIntl(useIntl());
  const i18n = useI18n.breakoutRoomReservations(intl);

  if (!noteDialog.isOpen) return null;
  return (
    <AimDialog
      open={noteDialog.isOpen}
      title={`${i18n.dialog.note.title}`}
      hideCancelButton={true}
      agreeText={i18n.dialog.note.closeText}
      onAgree={() => {
        onClose();
      }}
    >
      <AimTypography variant={'text'}>{noteDialog.note}</AimTypography>
    </AimDialog>
  );
};

export default InvitationParticipationNoteDialog;
