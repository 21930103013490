import { aws } from '@aim/common';
const { API } = aws;

const getPremiumRangesQuery = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      sponsorPriceRanges {
        items {
          id
        }
      }
    }
  }
`;

const getSponsorStaffServiceByEventQuery = /* GraphQL */ `
  query GetSponsorStaffServiceByEvent($id: ID!) {
    getEvent(id: $id) {
      id
      sponsorStaffService {
        id
        vatRate
        hideMyArea
      }
    }
  }
`;

export const getPremiumRanges = (id) =>
  new Promise((resolve, reject) => {
    API.graphql({
      query: getPremiumRangesQuery,
      variables: { id },
    })
      .then((response) =>
        resolve(response?.data?.getEvent?.sponsorPriceRanges?.items)
      )
      .catch((e) => {
        console.error('get-premium-ranges', e);
        reject();
      });
  });

export const getSponsorStaffServiceByEvent = (id) =>
  new Promise((resolve, reject) => {
    API.graphql({
      query: getSponsorStaffServiceByEventQuery,
      variables: { id },
    })
      .then((response) => {
        return resolve({
          ...response.data.getEvent?.sponsorStaffService,
        });
      })
      .catch((e) => {
        console.error('event-sponsor-staff-service-by-event', e);
        reject();
      });
  });
