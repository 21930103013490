import React, { useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { appState, constants } from '@aim/common';
import {
  ParticipationEdit,
  defaultSaveData,
  createModificationRequestData,
  CustomIntl,
} from '@aim/components';

const ParticipationEditWrapperFrontoffice = ({ postDeadline }) => {
  const intl = CustomIntl(useIntl());
  const { eventId, participationId, cluster, clusterId } = useParams();
  const location = useLocation();

  const showLoader = () => appState.isLoader.next(true);
  const hideLoader = () => appState.isLoader.next(false);
  const history = useHistory();
  const historyState = history.location.state;

  const [user, setUser] = useState();
  const [participationEditInput, setParticipationEditInput] = useState();

  const getBackUrl = () => {
    if (cluster === constants.Clusters.SponsorStaff.id) {
      const sponsorId = user?.userAndParticipation?.participation?.sponsor?.id;
      return `/events/${eventId}/${sponsorId}/services-configuration/staff`;
    } else if (cluster === constants.Clusters.SponsorList.id) {
      const sponsorId = user?.userAndParticipation?.participation?.sponsor?.id;
      return `/events/${eventId}/${sponsorId}/services-configuration/list`;
    } else if (cluster === constants.Clusters.Groups.id) {
      const agencyId = user?.userAndParticipation?.participation?.agency?.id;
      if (clusterId) {
        const query = new URLSearchParams(location.search);
        const type = query.get('t');
        return `/events/${eventId}/agency/${agencyId}/group-list/${clusterId}${
          type ? '/' + type : ''
        }`;
      }
      return `/events/${eventId}/agency/${agencyId}/group-list`;
    }
  };

  useEffect(() => {
    const subscription = appState.user.subscribe(setUser);
    return () => subscription.unsubscribe();
  }, []);

  const onSaveCustom = async (
    submittedData,
    currentParticipation,
    backUrl,
    dirtyFields
  ) => {
    showLoader();

    console.log(submittedData, 'submittedData');
    await defaultSaveData({
      participation: currentParticipation,
      submittedData,
      dirtyFields,
    });

    history.push(backUrl);
    hideLoader();
  };

  const onRequest = async (submittedData, currentParticipation, backUrl) => {
    showLoader();

    await createModificationRequestData({
      submittedData,
      user,
      eventId,
      participationId,
      cluster,
      clusterId,
    });
    history.push(backUrl);

    //una volta fatta la richiesta di modifica bloccare ulteriori richieste?
    hideLoader();
  };

  const buildRequiredBaseFields = () => {
    let requiredFields = [];
    if (
      cluster === constants.Clusters.Groups.id ||
      cluster === constants.Clusters.SponsorStaff.id
    ) {
      requiredFields = ['profile'];
    }
    if (cluster === constants.Clusters.SponsorStaff.id) {
      requiredFields = ['email'];
    }
    return requiredFields;
  };

  const buildToHideBaseFields = () => {
    let hiddenFields = [];
    if (cluster === constants.Clusters.SponsorStaff.id) {
      hiddenFields = ['profile', 'feeDateRange', 'type', 'status'];
    }
    return hiddenFields;
  };

  const buildToSkipBaseFieldsIds = () => {
    return [
      'isParticipant',
      'isSpeaker',
      'isReviewer',
      'seg',
      'ana',
      'typology',
    ];
  };

  const getExtraProps = () => {
    if (!participationId && historyState?.newPaxTicketInfo) {
      return {
        profile: historyState?.newPaxTicketInfo.profile,
        feeDateRange: historyState?.newPaxTicketInfo.feeDateRange,
        type: historyState?.newPaxTicketInfo.participationMode,
      };
    }
  };

  return (
    <div
      style={{
        width: '100%',
        height: 'inherit',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          marginBottom: 0,
          flexGrow: 0,
          marginRight: '5%',
          marginLeft: '5%',
        }}
      >
        {postDeadline ? (
          <ParticipationEdit
            intl={intl}
            backUrl={getBackUrl()}
            history={history}
            onSave={onRequest}
            {...{
              eventId,
              participationData: {
                id: participationId,
                cluster,
                clusterId,
              },
              requiredBaseFields: buildRequiredBaseFields(),
              toSkipBaseFieldsIds: buildToSkipBaseFieldsIds(),
            }}
            isPostDeadline
            editFrontOffice
          />
        ) : (
          <ParticipationEdit
            intl={intl}
            backUrl={getBackUrl()}
            history={history}
            onSave={onSaveCustom}
            editFrontOffice
            {...{
              eventId,

              participationData: {
                id: participationId,
                cluster,
                clusterId,
                isParticipant: true,
                ...getExtraProps(),
              },
              requiredBaseFields: buildRequiredBaseFields(),
              toSkipBaseFieldsIds: buildToSkipBaseFieldsIds(),
              toHideBaseFields: buildToHideBaseFields(),
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ParticipationEditWrapperFrontoffice;
