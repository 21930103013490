import React from 'react';

import { AimTypography, styled, theme } from '@aim/components';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  // justifyContent: 'flex-end',
  backgroundColor: theme.colors.greyScale.backgroundGrey,
  // marginRight: 20,
});

const SponsorStaffCard = ({ title, style, children, ...rest }) => {
  return (
    <Container style={style} {...rest}>
      <div
        style={{
          borderBottom: '1px solid #84819A47',
          margin: '10px 10px',
          padding: '0px 10px',
          minWidth: '500px',
        }}
      >
        <AimTypography variant={'h3'} textAlign="center">
          {title}
        </AimTypography>
      </div>
      {children}
    </Container>
  );
};

export default SponsorStaffCard;
