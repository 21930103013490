export default function (intl) {
  return {
    page: {
      backButton: intl.formatMessage({
        description: 'grant detail back button label',
        defaultMessage: 'back',
      }),
      rejectButton: intl.formatMessage({
        description: 'reject button label',
        defaultMessage: 'reject',
      }),
      acceptButton: intl.formatMessage({
        description: 'accept button label',
        defaultMessage: 'accept',
      }),
      title: intl.formatMessage({
        description: 'grant detail page title',
        defaultMessage: 'Grant',
      }),
      abstractTitle: intl.formatMessage({
        description: 'Grant front title',
        defaultMessage: 'Abstract Grants',
      }),
      educationalTitle: intl.formatMessage({
        description: 'Grant front title',
        defaultMessage: 'Educational Grants',
      }),
      processed: intl.formatMessage({
        description: 'grant detail page subtitle',
        defaultMessage: 'Processed',
      }),
      toBeProcessed: intl.formatMessage({
        description: 'grant detail page subtitle',
        defaultMessage: 'To Be Processed',
      }),
      toBeProcessedMessage: intl.formatMessage({
        description: 'to be processed message',
        defaultMessage:
          'Dear user, we are processing your request. We will send you updates on this as soon as possible. Below is the summary of the initiative...',
      }),
      refusedMessage: intl.formatMessage({
        description: 'rejected message',
        defaultMessage:
          'Dear user, unfortunately your participation had a negative result. We thank for having participated and we remind you that you can now access the services present in the event on the ',
      }),
      successMessage: intl.formatMessage({
        description: 'success message',
        defaultMessage:
          'Dear user, congratulation! Your subscription was successful!',
      }),
      prizeMessage: intl.formatMessage({
        description: 'prize message',
        defaultMessage: 'Do you want to accept the prize?',
      }),
      authorise: intl.formatMessage({
        description: 'authorise message',
        defaultMessage:
          'I authorise AIM to proceed with the booking of my included Services',
      }),
      authoriseReq: intl.formatMessage({
        description: 'authorise required message',
        defaultMessage: 'Authorise required',
      }),
      listOfIncludedServices: intl.formatMessage({
        description: 'list of included services',
        defaultMessage: 'list of included services and preferences management',
      }),
      selectTheOptions: intl.formatMessage({
        description: 'select the available options',
        defaultMessage:
          'Select the available options below to manage your preferences',
      }),
    },
  };
}
