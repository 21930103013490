import React, { useState, useEffect } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import { useIntl } from 'react-intl';
import useI18n from './speakers/i18n';

import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';

import { fileHelper, appState } from '@aim/common';
import { useDataHelper } from './shared/dataHelper';

import {
  AimIconAndTextButton,
  AimTypography,
  AimSelect,
  AimSelectMenuItem,
  AimPagination,
  styled,
  CustomIntl,
  useAimMediaQuery,
} from '@aim/components';

import { HomeContainer } from './home/style';
import SpeakerFilter from './speakers/SpeakerFilter';
import { GetParticipantsByEventId } from './speakers/gqlHelper';
import { getEventConfigurationPageTitle } from '../shared/utilities';

const formControlStyle = { width: 'calc(100% - 15px)' };

const PageContainer = styled('div')({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const PageContent = styled('div')(({ isMobileOnly }) => ({
  // ...(!isMobileOnly && { width: 1280 }),
  width: isMobileOnly ? '95%' : '70%',
  minHeight: 'calc(100vh - 260px)',
}));

const StyledCardImg = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

const StyledRelatorCard = styled('div')(({ isMobileOnly }) => ({
  // ...(!isMobileOnly && { width: 310 }),
  height: 261,
  display: 'flex',
  margin: 5,
  paddingTop: '26px',
  alignItems: 'center',
  backgroundColor: '#F3F3F4',
  flexDirection: 'column',
  position: 'relative',
}));

export const Overlay = styled('div')({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: '#F3F3F4',
});

export const StyledParagraph = styled('div')({
  position: 'absolute',
  bottom: 5,
  left: 5,
});

export const StyledParagraphCenter = styled('div')({
  textTransform: 'uppercase',
});

const ButtonContainer = styled('div')({
  position: 'absolute',
  justifyContent: 'flex-end',
  margin: 10,
  bottom: '10px',
});

const getCityAndCountry = (att) => {
  let city;
  if (att?.userShowCase?.city) {
    city = att?.userShowCase?.city;
  } else {
    const nextCity = att?.fieldValues?.items?.find(
      (field) => field.fieldDefinition.label.toLowerCase() === 'city'
    );
    city = nextCity?.value && JSON.parse(nextCity.value);
  }
  let country;
  if (att?.userShowCase?.country) {
    country = att?.userShowCase?.country;
  } else {
    const nextCountry = att?.fieldValues?.items?.find(
      (field) => field.fieldDefinition.label.toLowerCase() === 'country'
    );
    country = nextCountry?.value && JSON.parse(nextCountry.value);
  }
  if (!city && !country) return '';
  console.log(
    'city, country',
    `${city?.value || city}`,
    `${country?.value?.toUpperCase() || country?.toUpperCase()}`
  );
  const cityCountry =
    city && country
      ? `${city?.value || city || ''}, ${
          country?.value?.toUpperCase() || country?.toUpperCase()
        }`
      : `${
          city?.value ||
          city ||
          country?.value?.toUpperCase() ||
          country?.toUpperCase()
        }` || '';
  return cityCountry;
};

const Speakers = () => {
  // Hooks
  const history = useHistory();
  const { eventId } = useParams();
  const intl = CustomIntl(useIntl());
  const i18n = useI18n(intl);
  // const { getEvent } = useAgenda();
  const { sortBy } = useDataHelper();

  // States
  // const [sessions, setSessions] = useState([]);
  const [speakers, setSpeakers] = useState([]);
  const [displaySpeakers, setDisplaySpeakers] = useState([]);
  const [sortByName, setSortByName] = useState();
  // const [dates, setDates] = useState([]);
  const [page, setPage] = useState(0);
  const [itemsPerPage, setRowsPerPage] = useState(16);
  const [eventConfiguration, setEventConfiguration] = useState({});
  const [pageTitle, setPageTitle] = useState('');

  const { isMobileOnly } = useAimMediaQuery();

  // Effects
  useEffect(() => {
    fetchSpeakers();
  }, []);

  useEffect(() => {
    if (speakers?.length) {
      setDisplaySpeakers(speakers);
    }
  }, [speakers]);

  useEffect(() => {
    setPage(0);
  }, [speakers]);

  useEffect(() => {
    const subscription = appState.eventConfiguration.subscribe(
      setEventConfiguration
    );
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    const menuItemLabel = getEventConfigurationPageTitle(
      eventConfiguration?.eventGenericMenuItems?.items || [],
      intl,
      'speakers'
    );
    setPageTitle(menuItemLabel);
  }, [eventConfiguration]);

  const getLink = (user) => {
    const link = fileHelper.getPublicFileLink({
      dirPath: `events/${eventId}/user/${user?.id}/showcase/profileImage`,
      skipFileDataOnS3Link: true,
    });
    return link;
  };

  const fetchSpeakers = async () => {
    const res = await GetParticipantsByEventId(eventId);
    if (res.items.length > 0) {
      const nextres = res.items.map((item) => {
        const profileImageLink =
          item.userShowcase?.profileImage && getLink(item);
        let filterName = `${item.givenName} ${item.familyName}`;

        return {
          ...item,
          givenName: item.givenName.toLowerCase(),
          familyName: item.familyName.toLowerCase(),
          filterName: filterName.toLowerCase(),
          profileImageLink,
          workJob: item.userShowcase?.workJob,
          cityCountry:
            console.log('getCityAndCountry(item)', getCityAndCountry(item)) ||
            getCityAndCountry(item),
        };
      });
      setSpeakers(nextres);
    }
  };

  // const getSessions = async () => {
  //   const res = await getEvent(eventId, constants.Clusters.Pax.id);

  //   // Handling dates Select for filter
  //   const listOfDates = populateDateSelect(res.start, res.end);
  //   setDates(listOfDates.map((d) => format(d, 'dd-MM-yyyy')));

  //   // Handling Sessions
  //   if (res.agenda.sessions.items.length > 0) {
  //     // setSessions(res.agenda.sessions.items);
  //   }
  // };

  // const populateDateSelect = (start, end) => {
  //   const diff = differenceInDays(new Date(end), new Date(start));
  //   const datesRanges = [];
  //   datesRanges.push(new Date(start));
  //   for (let index = 0; index < diff; index++) {
  //     const result = addDays(new Date(datesRanges[index]), 1);
  //     datesRanges.push(result);
  //   }
  //   return datesRanges;
  // };

  return (
    <HomeContainer>
      <PageContainer>
        <PageContent {...{ isMobileOnly }}>
          <AimTypography variant="h1">{pageTitle}</AimTypography>
          <Grid container>
            <Grid item xs={12} md={6}>
              <AimSelect
                label={i18n.sortBy.label}
                placeholder={i18n.sortBy.label}
                formControlStyle={formControlStyle}
                value={sortByName}
                onChange={(e) => {
                  let sorted = [];
                  if (e.target.value === 'asc') {
                    sorted = sortBy(displaySpeakers, 'familyName');
                  } else {
                    sorted = sortBy(displaySpeakers, 'familyName').reverse();
                  }
                  if (sorted.length > 0) {
                    setDisplaySpeakers(sorted);
                  }

                  setSortByName(e.target.value);
                }}
              >
                <AimSelectMenuItem key="asc" value="asc">
                  {i18n.sortBy.sortByNameAz.label}
                </AimSelectMenuItem>
                <AimSelectMenuItem key="desc" value="desc">
                  {i18n.sortBy.sortByNameZa.label}
                </AimSelectMenuItem>
              </AimSelect>
            </Grid>
            {/* */}
            <Grid item xs={12} md={6}>
              <SpeakerFilter
                i18n={i18n.filter}
                onFilterResult={(e) => {
                  setDisplaySpeakers(e);
                }}
                rows={speakers}
                // sortByName={sortByName()}
                formControlStyle={formControlStyle}
              />
            </Grid>

            {/* TODO 4 cards per row, fix margin/padding between cards */}
            {displaySpeakers.length ? (
              displaySpeakers
                ?.slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage)
                .map((s, idx) => {
                  return (
                    <Grid
                      item
                      xs={isMobileOnly ? 12 : 3}
                      style={{
                        cursor: 'pointer',
                        marginBottom: 10,
                      }}
                      key={s.id}
                      tabIndex={idx}
                      role="button"
                      // onClick={() => {
                      //   history.push(`user/${s.id}`);
                      // }}
                    >
                      <StyledRelatorCard {...{ isMobileOnly }}>
                        {/* <Overlay /> */}
                        <>
                          {/* <StyledCardImg src={s.profileImageLink} /> */}
                          <Avatar
                            style={{
                              border: '2px solid white',
                              height: '97px',
                              width: '97px',
                              fontSize: '25px',
                            }}
                          >
                            {s.profileImageLink ? (
                              <img
                                src={s.profileImageLink}
                                alt="avatar"
                                style={{
                                  objectFit: 'cover',
                                  width: 'inherit',
                                  height: 'inherit',
                                  backgroundColor: 'white',
                                }}
                              />
                            ) : (
                              <>
                                {s.givenName && s.givenName[0].toUpperCase()}
                                {s.familyName && s.familyName[0].toUpperCase()}
                              </>
                            )}
                            {/* <ChangeAvatarButton
                              variant={'lightBlueFill'}
                              onClick={onChangeAvatar}
                            >
                              {editMode && <PhotoCameraIcon />}
                            </ChangeAvatarButton> */}
                          </Avatar>
                          <StyledParagraphCenter>
                            <AimTypography
                              variant="textBold"
                              boxStyle={{ fontWeight: 'bold', marginBottom: 0 }}
                            >
                              {s.givenName} {s.familyName}
                            </AimTypography>
                          </StyledParagraphCenter>
                          <div>
                            <AimTypography
                              variant="text"
                              boxStyle={{ marginTop: 0 }}
                            >
                              {s.cityCountry}
                            </AimTypography>
                          </div>
                          {s.workJob && (
                            <AimTypography variant={'h5'} margin={0}>
                              {s.workJob}
                            </AimTypography>
                          )}
                          <ButtonContainer>
                            <AimIconAndTextButton
                              style={{ padding: '0px 30px' }}
                              isUpperCase
                              text={i18n.page.view}
                              // variant="greenFill"
                              onClick={() => {
                                history.push(`user/${s.id}`);
                              }}
                            ></AimIconAndTextButton>
                          </ButtonContainer>
                        </>
                      </StyledRelatorCard>
                    </Grid>
                  );
                })
            ) : (
              <div>No data available</div>
            )}
          </Grid>
        </PageContent>
        <AimPagination
          elementsCount={displaySpeakers.length}
          page={page}
          setPage={setPage}
          rowsPerPage={itemsPerPage}
          setRowsPerPage={setRowsPerPage}
          rowsPerPageOptions={[8, 16, 24]}
        />
      </PageContainer>
    </HomeContainer>
  );
};

export default Speakers;
