import { aws, appState } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const getParticipationServicesQuery = /* GraphQL */ `
  query ParticipationServices($id: ID!) {
    getParticipation(id: $id) {
      id
      familyName
      givenName
      email
      participationServices {
        isTravelIncluded
        isTransferIncluded
        note
        personIncluded
        id
        codeEventOrder
        eventOrderId
        travelFacultyResponse
      }
    }
  }
`;

const updateParticipationServicesQuery = /* GraphQL */ `
  mutation UpdateParticipationServices(
    $input: UpdateParticipationServicesInput!
    $condition: ModelParticipationServicesConditionInput
  ) {
    updateParticipationServices(input: $input, condition: $condition) {
      id
    }
  }
`;

const getEventOrderQuery = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      eventOrder {
        items {
          id
          code
          codeName
          sponsor {
            id
            name
            isDeleted
          }
          delegation {
            id
            name
          }
        }
      }
    }
  }
`;
const getEventAllotmentDateRangeQuery = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      allotmentDateRange {
        start
        end
      }
    }
  }
`;

const getHotelEventVentureRoomsQuery = /* GraphQL */ `
  query GetHotelEventVenture($id: ID!) {
    getHotelEventVenture(id: $id) {
      id
      hotel {
        name
        rooms(filter: { isDeleted: { ne: true } }) {
          items {
            id
            name
            frontofficeName
            maxOccupancy
            isDeleted
          }
        }
      }
    }
  }
`;

const getRoomQuery = /* GraphQL */ `
  query GetHotelRoom($id: ID!) {
    getHotelRoom(id: $id) {
      id
      name
      frontofficeName
    }
  }
`;

export const updateParticipationServices = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    aws.API.graphql({
      query: updateParticipationServicesQuery,
      variables: { input },
    })
      .then((response) => {
        const data = response.data.updateParticipationServices;
        resolve(data);
      })
      .catch((e) => {
        console.error('travel-transfer-update-participationServices', e);
        reject(e);
      })
      .finally(() => useLoader && hideLoader());
  });

export const getParticipationServices = (participationId) =>
  new Promise((resolve, reject) => {
    showLoader();

    API.graphql({
      query: getParticipationServicesQuery,
      variables: { id: participationId },
    })
      .then((response) => {
        const travelFaculty =
          (response.data?.getParticipation &&
            response.data?.getParticipation.participationServices &&
            response.data?.getParticipation.participationServices
              .travelFacultyResponse &&
            JSON.parse(
              response?.data?.getParticipation.participationServices
                .travelFacultyResponse
            )) ||
          {};
        return resolve({
          participantId: response.data?.getParticipation.id,
          id: response.data?.getParticipation?.participationServices?.id,
          ...travelFaculty,
        });
      })
      .catch((e) => {
        console.error('get-participation-services', e);
        reject();
      })
      .finally(hideLoader);
  });

export const getEventAllotmentDateRange = (id) =>
  new Promise((resolve, reject) => {
    showLoader();

    API.graphql({ query: getEventAllotmentDateRangeQuery, variables: { id } })
      .then((response) =>
        resolve({
          ...response?.data?.getEvent?.allotmentDateRange,
        })
      )
      .catch((e) => {
        console.error('get-event-allotment-date-range', e);
        reject();
      })
      .finally(hideLoader);
  });

export const getEventOrder = (id) =>
  new Promise((resolve, reject) => {
    showLoader();

    API.graphql({ query: getEventOrderQuery, variables: { id } })
      .then((response) =>
        resolve({
          ...response?.data?.getEvent?.eventOrder,
        })
      )
      .catch((e) => {
        console.error('get-event-order', e);
        reject();
      })
      .finally(hideLoader);
  });

export const getRoom = (id, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    API.graphql({
      query: getRoomQuery,
      variables: { id },
    })
      .then((response) => {
        resolve(response?.data?.getHotelRoom);
      })
      .catch((e) => {
        console.error('get-room', e);
        hideLoader();
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const getHotelEventVentureRooms = (id, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    API.graphql({
      query: getHotelEventVentureRoomsQuery,
      variables: { id },
    })
      .then((response) => {
        resolve(response?.data?.getHotelEventVenture);
      })
      .catch((e) => {
        console.error('get-hotel-event-venture-rooms', e);
        hideLoader();
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });
