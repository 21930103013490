import { aws, appState, constants } from '@aim/common';
const { API } = aws;

const getFieldsListByContextsQuery = /* GraphQL */ `
  query getFieldsListByContexts(
    $id: ID!
    $filter: ModelFieldContextFilterInput
  ) {
    getEvent(id: $id) {
      id
      standardFields(filter: { isDeleted: { ne: true } }) {
        items {
          controlType
          id
          key
          isDeleted
          label
          defaultValue
          options
          placeholder
          contextsOfUse(filter: $filter) {
            items {
              id
              position
              contextName
              isRequired
              isHidden
            }
          }
        }
      }
      services(filter: { serviceName: { eq: "${constants.Services.ABSTRACT.key}" } }) {
        items {
          serviceName
          id
          customFields(filter: { isDeleted: { ne: false } }) {
            items {
              controlType
              id
              key
              isDeleted
              defaultValue
              label
              options
              placeholder
              contextsOfUse(filter: $filter) {
                items {
                  id
                  position
                  contextName
                  isRequired
                  isHidden
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const getFieldsListByContexts = ({
  eventId,
  contextsOfUse,
  useLoader = false,
}) =>
  new Promise((resolve, reject) => {
    if (useLoader) showLoader();

    aws.API.graphql({
      query: getFieldsListByContextsQuery,
      variables: {
        id: eventId,
        filter: { or: contextsOfUse.map((c) => ({ contextName: { eq: c } })) },
      },
    })
      .then((response) => {
        const out = {
          standardFields: response.data.getEvent.standardFields.items,
          customFields:
            response.data.getEvent.services.items[0].customFields.items,
        };
        resolve(out);
      })
      .catch((e) => {
        console.error('list-event-field', e);
        reject();
      })
      .finally(useLoader ? hideLoader : null);
  });

export const unionToContextOfUse = (fields, contexts) => {
  const outFields = fields
    .filter((f) => f.contextsOfUse.items.length)
    .map((field) => {
      let fieldFirstContextOfUse;
      for (let i = 0; i < contexts.length; i++) {
        fieldFirstContextOfUse = field.contextsOfUse.items.find(
          (cu) => cu.contextName === contexts[i]
        );
        if (fieldFirstContextOfUse) break;
      }
      return {
        ...field,
        contextsOfUse: fieldFirstContextOfUse,
        isReadOnly:
          fieldFirstContextOfUse.contextName !== contexts[contexts.length - 1],
      };
    });
  return outFields;
};

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);
