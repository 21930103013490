export default {
  allotment: (intl) => ({
    page: {
      save: intl.formatMessage({
        description: 'allotment / save',
        defaultMessage: 'Save',
      }),
      saveDraft: intl.formatMessage({
        description: 'allotment / Save draft',
        defaultMessage: 'Save draft',
      }),
      cancel: intl.formatMessage({
        description: 'allotment / Cancel',
        defaultMessage: 'Cancel',
      }),
      selectedParticipants: intl.formatMessage({
        description: 'allotment / selected participants',
        defaultMessage: 'N° of selected participants: ',
      }),
      backButton: {
        label: intl.formatMessage({
          description: 'allotment / back',
          defaultMessage: 'Back',
        }),
        tooltip: intl.formatMessage({
          description: 'allotment / back',
          defaultMessage: 'Back',
        }),
      },
      name: intl.formatMessage({
        description: 'allotment / Name',
        defaultMessage: 'Name',
      }),
      surname: intl.formatMessage({
        description: 'allotment / Surname',
        defaultMessage: 'Surname',
      }),
      email: intl.formatMessage({
        description: 'allotment / Email user',
        defaultMessage: 'Email user',
      }),
      generalPrefs: intl.formatMessage({
        description: 'allotment / general preferences',
        defaultMessage: 'General preferences',
      }),
      authBooking: intl.formatMessage({
        description:
          'I authorise AIM Travel to proceed with the booking of my included Services',
        defaultMessage:
          'I authorise AIM Travel to proceed with the booking of my included Services',
      }),
      noBooking: intl.formatMessage({
        description: 'allotment / I do not need a hotel/accomodation',
        defaultMessage: 'I do not need a hotel/accomodation',
      }),
      makeYourChoice: intl.formatMessage({
        description: 'allotment / please make your choice',
        defaultMessage: 'Please make your choice',
      }),
      requestForDiffers: intl.formatMessage({
        description:
          '*if you request differs from what is included in the hospitality package, you will be contacted',
        defaultMessage:
          '*If you request differs from what is included in the hospitality package, you will be contacted',
      }),
      generalInfo: {
        title: intl.formatMessage({
          description: 'allotment / GENERAL INFO',
          defaultMessage: 'GENERAL INFO',
        }),
        accompanying: intl.formatMessage({
          description: 'allotment / accompanying person',
          defaultMessage: 'Accompanying person',
        }),
        businessUnit: intl.formatMessage({
          description: 'allotment / business unit label',
          defaultMessage: 'Business unit',
        }),
        orderCode: intl.formatMessage({
          description: 'allotment / order code label',
          defaultMessage: 'Order code',
        }),
        yes: intl.formatMessage({
          description: 'allotment / yes',
          defaultMessage: 'yes',
        }),
        no: intl.formatMessage({
          description: 'allotment / no',
          defaultMessage: 'no',
        }),
      },
      allotmentTable: {
        columns: {
          hotel: intl.formatMessage({
            description: 'allotment / hotel label',
            defaultMessage: 'Hotel',
          }),
          numberNightsIncluded: intl.formatMessage({
            description: 'allotment / number nights included label',
            defaultMessage: 'Number of nights included',
          }),
          roomType: intl.formatMessage({
            description: 'allotment / room type label',
            defaultMessage: 'Room type',
          }),
          checkInDate: intl.formatMessage({
            description: 'allotment / check-in date label',
            defaultMessage: 'Check-in date',
          }),
          checkOutDate: intl.formatMessage({
            description: 'allotment / check-out date label',
            defaultMessage: 'Check-out date',
          }),
        },
      },
      accomodation: {
        pleaseEnterAccomodationRequirements: intl.formatMessage({
          description:
            'please enter your accomodation requirements in the block below',
          defaultMessage:
            'Please enter your accomodation requirements in the block below*.',
        }),
        checkInDate: intl.formatMessage({
          description: 'allotment / check-in date',
          defaultMessage: 'Check-In Date',
        }),
        checkOutDate: intl.formatMessage({
          description: 'allotment / check-out date',
          defaultMessage: 'Check-Out Date',
        }),
        roomTypePrefs: intl.formatMessage({
          description: 'allotment / room type preferences',
          defaultMessage: 'Room Type Preferences',
        }),
        notes: intl.formatMessage({
          description: 'allotment / notes',
          defaultMessage: 'Notes',
        }),
      },
    },
  }),
};
