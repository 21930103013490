import React, { useRef, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ArrowForward from '@material-ui/icons/ArrowForward';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';

// import { constants, utilities, appState } from '@aim/common';
import {
  CustomIntl,
  // AimDrawerLeft,
  // AimDialog,
  AimTablePage,
  AimTypography,
  AimSnackbar,
  AimSnackbarSeverity,
  AimIconButton,
} from '@aim/components';

// import { constants } from '@aim/common';

import {
  translation,
  FormCreateGroup,
  getEventGroupsQuery,
  createGroup,
  updateGroup,
  removeGroup,
} from './agency/groupsList';

import { MainContainer, InnerContainer } from './agency/shared/Containers';
import agencyTimelineHelper from '../shared/agencyTimelineHelper';

const AgencyFeeList = () => {
  const { eventId, agencyId } = useParams();
  const history = useHistory();
  const formRef = useRef();
  const [timeline, setTimeline] = useState(null);

  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentGroup, setCurrentGroup] = useState();

  const intl = CustomIntl(useIntl());
  const i18n = translation.groupsList(intl);
  const loadDataRef = useRef();

  useEffect(() => {
    if (!eventId) return;

    const getTimeline = async () => {
      const nextTimeline = await agencyTimelineHelper({ eventId });
      setTimeline(nextTimeline);
    };

    getTimeline();
  }, [eventId]);

  const model = (loadData) => {
    loadDataRef.current = loadData;
    return {
      header: {
        title: (data) =>
          data?.getEvent?.agencies?.items[0].name
            ? data?.getEvent.agencies?.items[0].name
            : '',
        backButton: {
          onClick: () => history.push(`/events/${eventId}/agency/${agencyId}`),
        },
        action: {
          goToWelcomePage: {
            variant: 'lightBlue',
            icon: <ArrowForward />,
            onClick: handleGoToWelcomePage,
          },
          goToFeeTable: {
            variant: 'lightBlue',
            icon: <ArrowForward />,
            onClick: handleGoToFeeTable,
          },
          // close: {
          //   icon: <ArrowForward />,
          //   variant: 'green',
          //   // onClick: () => handleCloseGroup(),
          //   onClick: 'dialog',
          //   // disabledWhen: () => sponsorListRef.current?.closingDate,
          //   dialog: 'close',
          //   snackbar: 'close',
          // },
          add: {
            icon: <AddIcon />,
            variant: 'yellow',
            disabledWhen: () => (timeline ? !timeline.canAddGroup : false),
            onClick: () => handleAdd(),
          },
        },
      },
      preTableComponent: null,
      dataLoader: {
        query: getEventGroupsQuery,
        variables: {
          id: eventId,
          agencyId: agencyId,
        },
        transform: (data) =>
          data.getEvent.agencies?.items.reduce((res, curr) => {
            const currentGroups = curr.agencyGroups.items.map((group) => ({
              ...group,
              id: group.id,
              name: group.name,
              agencyName: curr.name,
              client: group.client, // `${curr.admins.items[0].givenName} ${curr.admins.items[0].familyName}`,
              typology: group.isPrepaid
                ? i18n.table.values.typology.prepaid
                : i18n.table.values.typology.standard,
              npax: group.participations.items.length,
              status: group.closingDate
                ? i18n.table.values.status.closed
                : i18n.table.values.status.open,
            }));
            return [...res, ...currentGroups];
          }, []),
      },
      table: {
        isRowSelectable: false,
        columnsTemplate: [
          {
            id: 'name',
            numeric: false,
            disablePadding: false,
            isCheckbox: false,
            isDisabled: false,
          },
          // {
          //   id: 'agency',
          //   numeric: false,
          //   disablePadding: false,
          //   isCheckbox: false,
          //   isDisabled: false,
          // },
          {
            id: 'client',
            numeric: false,
            disablePadding: false,
            isCheckbox: false,
            isDisabled: false,
          },
          {
            id: 'typology',
            numeric: false,
            disablePadding: false,
            isCheckbox: false,
            isDisabled: false,
          },
          {
            id: 'npax',
            numeric: false,
            disablePadding: false,
            isCheckbox: false,
            isDisabled: false,
          },
          {
            id: 'status',
            numeric: false,
            disablePadding: false,
            isCheckbox: false,
            isDisabled: false,
          },
        ],
        filters: [
          {
            column: 'name',
            type: 'string',
          },
          {
            column: 'client',
            type: 'string',
          },
          {
            column: 'typology',
            type: 'select',
          },
          {
            column: 'npax',
            type: 'string',
          },
          // {
          //   column: 'status',
          //   type: 'select',
          // },
        ],
        rowAction: {
          view: {
            icon: <VisibilityIcon />,
            variant: 'lightBlueFill',
            // hiddenWhen: (row) => row.status !== i18n.page.statuses.closed,
            onClick: (row) => handleView(row),
          },
          edit: {
            icon: <EditIcon />,
            variant: 'lightBlueFill',
            onClick: (data) => handleEdit(data),
          },
          delete: {
            icon: <ClearIcon />,
            variant: 'redFill',
            dialog: 'delete',
            snackbar: 'delete',
          },
        },
      },
      dialog: {
        delete: {
          onAgree: async (row) => {
            await handleRemove(row.id);
            await loadData();
          },
          onDisagree: () => {},
        },
      },
      snackbar: {
        delete: {},
      },
    };
  };

  const handleGoToFeeTable = async () => {
    history.push(`/events/${eventId}/agency/${agencyId}/fee`);
  };

  const handleGoToWelcomePage = async () => {
    history.push(`/events/${eventId}/agency/${agencyId}`);
  };

  const toggleDialog = () => {
    setDialogOpen(!dialogOpen);
  };

  const handleAdd = () => {
    setCurrentGroup(null);
    toggleDialog();
  };

  const handleView = ({ id, isPrepaid }) =>
    isPrepaid
      ? history.push(
          `/events/${eventId}/agency/${agencyId}/group-list/${id}/prepaid`
        )
      : history.push(
          `/events/${eventId}/agency/${agencyId}/group-list/${id}/standard`
        );

  const handleEdit = (data) => {
    setCurrentGroup(data);
    toggleDialog();
  };

  const handleRemove = async (id) => {
    await removeGroup({ id });
  };

  // const onClickRowView = (rowId) => {
  //   history.push(`/events/${eventId}/agency/${agencyId}/group-list/${rowId}`);
  // };

  const onSaveGroup = async (data) => {
    toggleDialog();

    const { groupId, name, client } = data;

    try {
      groupId
        ? await updateGroup({
            id: groupId,
            name,
            client,
          })
        : await createGroup({
            agencyGroupAgencyId: agencyId,
            name,
            client,
          });

      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.success,
        message: i18n.snackbar.success,
      });
      loadDataRef.current();
    } catch (e) {
      console.error(e);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.snackbar.error,
      });
    }
  };

  return (
    <>
      <MainContainer>
        <div style={{ flex: 1 }}>
          <InnerContainer>
            <AimTablePage model={model} translation={i18n} intl={intl} />
            <Dialog
              disableEnforceFocus
              PaperProps={{
                style: { padding: '20px' },
              }}
              open={dialogOpen}
              onClose={() => toggleDialog()}
            >
              <div style={{ marginLeft: 10, marginRight: 10 }}>
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <AimTypography variant="h3" margin={0}>
                    {i18n.dialog.form.title}
                  </AimTypography>
                  <AimIconButton variant="none" onClick={() => toggleDialog()}>
                    <CloseIcon />
                  </AimIconButton>
                </div>

                <Divider />
              </div>
              <div
                style={{
                  marginLeft: '3%',
                  marginRight: '3%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                >
                  <FormCreateGroup
                    ref={formRef}
                    selectedItem={currentGroup}
                    onSave={onSaveGroup}
                    onCancel={toggleDialog}
                    i18n={i18n.dialog.form}
                  />
                </div>
              </div>
            </Dialog>
          </InnerContainer>
        </div>
      </MainContainer>
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </>
  );
};

export default AgencyFeeList;
