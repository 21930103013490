export default function (intl) {
  return {
    actions: {
      backButton: intl.formatMessage({
        description: 'back',
        defaultMessage: 'Back',
      }),
    },
    page: {
      welcome: intl.formatMessage({
        description: 'welcome ',
        defaultMessage: 'Welcome!',
      }),
      registrationFee: intl.formatMessage({
        description: 'Registrations label',
        defaultMessage: 'Registrations',
      }),
      chooseTheTicket: intl.formatMessage({
        description: 'choose the ticket you want to buy label',
        defaultMessage: 'Choose the ticket you want to buy',
      }),
      thereAreNoTicket: intl.formatMessage({
        description: 'there are no ticket for you to buy label',
        defaultMessage: 'There are no ticket for you to buy',
      }),
      onSiteParticipate: intl.formatMessage({
        description: 'choose to participate physically',
        defaultMessage: 'Choose to participate physically',
      }),
      priceLabel: intl.formatMessage({
        description: 'price label',
        defaultMessage: '€ ',
      }),
      participantFee: intl.formatMessage({
        description: 'participant fee label',
        defaultMessage: 'participant fee',
      }),
      accompanyingPersonFee: intl.formatMessage({
        description: 'accompanying person fee label',
        defaultMessage: 'accompanying person fee',
      }),
      free: intl.formatMessage({
        description: 'free label',
        defaultMessage: 'Free',
      }),
      buy: intl.formatMessage({
        description: 'buy label',
        defaultMessage: 'buy',
      }),
      onLineParticipate: intl.formatMessage({
        description: 'choose to participate virtually',
        defaultMessage: 'Choose to participate virtually',
      }),
      cart: intl.formatMessage({
        description: 'shopping cart',
        defaultMessage: 'Shopping cart',
      }),
      service: intl.formatMessage({
        description: 'service cart label',
        defaultMessage: 'Service',
      }),
      ticket: intl.formatMessage({
        description: 'ticket cart label',
        defaultMessage: 'Ticket',
      }),
      additionalServices: intl.formatMessage({
        description: 'additional services label',
        defaultMessage: 'Additional services',
      }),
      vat: intl.formatMessage({
        description: 'vat cart label',
        defaultMessage: 'VAT',
      }),
      total: intl.formatMessage({
        description: 'total cart label',
        defaultMessage: 'Total (€)',
      }),
      readAll: intl.formatMessage({
        description: 'read all button to expand card content',
        defaultMessage: 'Read all',
      }),
    },
    snackbar: {
      buyTicketSuccess: intl.formatMessage({
        description: 'ticket successfully purchased',
        defaultMessage: 'Ticket successfully purchased',
      }),
      gatewayNotExist: intl.formatMessage({
        description: 'gateway not exist',
        defaultMessage: 'Gateway not exist',
      }),
      paymentTypeNotExist: intl.formatMessage({
        description: 'payment Type Not Exist',
        defaultMessage: 'Payment Type Not Exist',
      }),
    },
    dialog: {
      agreeText: intl.formatMessage({
        description: 'dialog agree button use merely ok',
        defaultMessage: 'OK',
      }),
    },
    checkout: {
      errors: {
        error1: intl.formatMessage({
          description: 'order data request processing error',
          defaultMessage: 'Order data request processing error',
        }),
        error2: intl.formatMessage({
          description: 'order data processing error',
          defaultMessage: 'Order data processing error',
        }),
        error3: intl.formatMessage({
          description: 'order payment initialization error',
          defaultMessage: 'Order payment initialization error',
        }),
        error4: intl.formatMessage({
          description: 'order payment error',
          defaultMessage: 'Order payment error',
        }),
        paymentExist: intl.formatMessage({
          description: 'payment exist',
          defaultMessage: 'Payment exist',
        }),
      },
    },
  };
}
