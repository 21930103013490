export default function (intl) {
  return {
    dialogs: {
      unPublishActionLable: intl.formatMessage({
        description: 'unPublishActionLable',
        defaultMessage: 'Published',
      }),
      publishActionLable: intl.formatMessage({
        description: 'publishActionLable',
        defaultMessage: 'Publish',
      }),
      publishActionDialogMessage: intl.formatMessage({
        description: 'publishActionDialogMessage',
        defaultMessage: 'Do you want to',
      }),
      unPublishActionMessage: intl.formatMessage({
        description: 'unPublishActionMessage',
        defaultMessage: 'unpublish your content?',
      }),
      publishActionMessage: intl.formatMessage({
        description: 'publishActionMessage',
        defaultMessage: 'publish your content?',
      }),
    },
    page: {
      sponsor: intl.formatMessage({
        description: 'sponsor',
        defaultMessage: 'Sponsor',
      }),
      backButton: intl.formatMessage({
        description: 'go back',
        defaultMessage: 'Back',
      }),
      noDataAvailable: intl.formatMessage({
        description: 'noDataAvailable',
        defaultMessage: 'No data available',
      }),
      pleaseEdit: intl.formatMessage({
        description: 'pleaseEdit',
        defaultMessage: ', please edit your profile',
      }),
      businessCard: intl.formatMessage({
        description: 'businessCard',
        defaultMessage: 'Business card',
      }),
      bookAppointment: intl.formatMessage({
        description: 'bookAppointment',
        defaultMessage: 'Book an appointment',
      }),
      visitStand: intl.formatMessage({
        description: 'visitStand',
        defaultMessage: 'Visit stand',
      }),
      exitStand: intl.formatMessage({
        description: 'exitStand',
        defaultMessage: 'Exit stand',
      }),
      sponsorDetailEdit: intl.formatMessage({
        description: 'sponsorDetailEdit',
        defaultMessage: 'Edit profile',
      }),
      sponsorDescription: intl.formatMessage({
        description: 'sponsorDescription',
        defaultMessage: 'Description',
      }),
      sponsorContacts: intl.formatMessage({
        description: 'sponsorContacts',
        defaultMessage: 'Contacts',
      }),
      sponsorInfo: intl.formatMessage({
        description: 'sponsorInfo',
        defaultMessage: 'Info',
      }),
      sponsorDocuments: intl.formatMessage({
        description: 'sponsorDocuments',
        defaultMessage: 'Documents',
      }),
      sponsorVirtualExhibitions: intl.formatMessage({
        description: 'sponsorVirtualExhibitions',
        defaultMessage: 'Virtual Exhibitions',
      }),
      noSponsorVirtualExhibitions: intl.formatMessage({
        description: 'Not avaiable for this event label',
        defaultMessage: 'Not avaiable for this event',
      }),
      title: intl.formatMessage({
        description: 'title',
        defaultMessage: 'Title',
      }),
      video: intl.formatMessage({
        description: 'video',
        defaultMessage: 'Video',
      }),
      name: intl.formatMessage({
        description: 'name label',
        defaultMessage: 'Name',
      }),
      surname: intl.formatMessage({
        description: 'surname label',
        defaultMessage: 'Surname',
      }),
      email: intl.formatMessage({
        description: 'email label',
        defaultMessage: 'Email',
      }),
      phone: intl.formatMessage({
        description: 'phone label',
        defaultMessage: 'Phone',
      }),
      country: intl.formatMessage({
        description: 'country label',
        defaultMessage: 'Country',
      }),
      city: intl.formatMessage({
        description: 'city label',
        defaultMessage: 'City',
      }),
      address: intl.formatMessage({
        description: 'address label',
        defaultMessage: 'Address',
      }),
      website: intl.formatMessage({
        description: 'website label',
        defaultMessage: 'Website',
      }),
    },
  };
}
