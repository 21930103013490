import { aws, appState } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const getItemsQueryByEvent = /* GraphQL */ `
  query getItemsQueryByEvent($id: ID!, $subCategoryId: ID!) {
    getEvent(id: $id) {
      id
      sponsorServiceBookingDuration
      otherSponsorizationsService {
        description
        isBankTransferEnabled
        isCreditCardEnabled
        id
        documents {
          items {
            createdAt
            extension
            id
            originalName
            size
            isMandatory
            updatedAt
          }
        }
        publicationDate
        name
      }
    }
    getItemSubcategory(id: $subCategoryId) {
      id
      name
      childItems {
        items {
          amountPerQuantityLabel
          code
          description
          id
          name
          netPrice
          note
          noteLabel
          isNoteEnabled
          isNoteRequired
          type
          vatRate
          cost
          availability
          otherSponsorizationsBookingItems {
            items {
              sponsor {
                id
                name
              }
              id
              quantity
              amountPerQuantity
              buyOperation {
                id
                isDeleted
                isExpired
              }
              sponsorPackageItem {
                id
                quantity
                package {
                  buyOperations {
                    items {
                      id
                      isDeleted
                      isExpired
                    }
                  }
                }
              }
            }
          }
          image {
            id
            originalName
            size
            extension
          }
        }
      }
    }
  }
`;

export const createOtherSponsBookingItemMutation = /* GraphQL */ `
  mutation CreateOtherSponsorizationsBookingItem(
    $input: CreateOtherSponsorizationsBookingItemInput!
    $condition: ModelOtherSponsorizationsBookingItemConditionInput
  ) {
    createOtherSponsorizationsBookingItem(
      input: $input
      condition: $condition
    ) {
      id
    }
  }
`;

export const updateOtherSponsBookingItemMutation = /* GraphQL */ `
  mutation UpdateOtherSponsorizationsBookingItem(
    $input: UpdateOtherSponsorizationsBookingItemInput!
    $condition: ModelOtherSponsorizationsBookingItemConditionInput
  ) {
    updateOtherSponsorizationsBookingItem(
      input: $input
      condition: $condition
    ) {
      id
    }
  }
`;

export const listItemsByEvent = (id, subCategoryId, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: getItemsQueryByEvent,
      variables: { id, subCategoryId },
    })
      .then((response) => {
        resolve({
          event: response.data.getEvent,
          subCategory: response.data?.getItemSubcategory,
        });
      })
      .catch((e) => {
        console.error('other-sponsorizations-list-items-event', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const createOtherSponsBookingItem = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: createOtherSponsBookingItemMutation,
      variables: { input },
    })
      .then((response) =>
        resolve(response.data.createOtherSponsorizationsBookingItem)
      )
      .catch((e) => {
        console.error('other-sponsorizations-booking-items-create', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const updateOtherSponsBookingItem = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: updateOtherSponsBookingItemMutation,
      variables: { input },
    })
      .then((response) =>
        resolve(response.data.updateOtherSponsorizationsBookingItem)
      )
      .catch((e) => {
        console.error('other-sponsorizations-booking-items-update', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });
