import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  AimTypography,
  AimIconAndTextButton,
  AimFormControlLabel,
  AimRadioButton,
  AimRadioForm,
  AimTextFieldForm,
  theme,
  styled,
} from '@aim/components';
import CheckIcon from '@material-ui/icons/Check';
import ReplayIcon from '@material-ui/icons/Replay';

import { constants } from '@aim/common';
import Grid from '@material-ui/core/Grid';
import { updateParticipationServices } from '../facultyGqlHelper';

// const formControlStyle = {
//   width: 'calc(100% - 15px)',
//   backgroundColor: 'white',
//   margin: '0px',
// };

const AimRadioCustom = styled(AimRadioForm)({
  '& .MuiIconButton-label': {
    '& span': {
      backgroundColor: theme.colors.greyScale.white,
    },
  },
});

const ParticipationConfirm = ({
  servicesState,
  onSuccess,
  onError,
  onCancel,
  i18n,
}) => {
  const _i18n = i18n.pax;

  //state
  const [confirmPax, setConfirmPax] = useState(true);
  const [paxOnSite, setPaxOnSite] = useState('no');
  const { control, handleSubmit, reset, setValue, getValues } = useForm({
    defaultValues: {
      transferId: null,
    },
  });

  const formControlStyle = { width: 'calc(100% - 20px)' };

  // Effects
  useEffect(() => {
    if (servicesState) {
      const raw = servicesState?.data?.participationFacultyResponse;
      let facultyResponse;
      if (raw) facultyResponse = JSON.parse(raw);
      if (facultyResponse && Object.keys(facultyResponse)?.length > 0) {
        reset({ ...facultyResponse });
        setPaxOnSite(facultyResponse.paxOnSite);
        setConfirmPax(facultyResponse.confirmPax);
      }
    }
  }, [servicesState]);

  const onSubmit = async (data) => {
    // participationFacultyResponse contains data of SCIENTIFIC COMMITMENT and PARTICIPATION, in backOffice are in the same page
    const prevData =
      JSON.parse(servicesState?.data?.participationFacultyResponse) || '';
    let dataToSend = {};
    if (Object.keys(data)?.length > 0) {
      dataToSend = {
        ...prevData,
        ...data,
        // responseStatus: constants.FacultyResponseStatus.PUBLISHED,
      };

      let participationFacultyResponse = JSON.stringify(dataToSend);

      try {
        await updateParticipationServices(
          {
            id: servicesState?.data.id,
            participationFacultyResponse,
          },
          true
        );
        onSuccess();
      } catch (err) {
        console.error('err', err);
        onError();
      }
    }
  };

  const handleConfirmPax = (e) => {
    setConfirmPax(e.target.value === 'true');
    setValue('confirmPax', e.target.value === 'true');
  };

  const handlePaxOnSite = (e) => {
    setPaxOnSite(e.target.value);
    setValue('paxOnSite', e.target.value);
  };

  const renderButtons = () => {
    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '10px auto',
            ...formControlStyle,
          }}
        >
          <div>
            <AimIconAndTextButton
              variant={'greyFill'}
              text={_i18n.buttons.cancel}
              onClick={onCancel}
            >
              <ReplayIcon />
            </AimIconAndTextButton>
          </div>
          <div>
            <AimIconAndTextButton
              variant={'greenFill'}
              text={_i18n.buttons.save}
              onClick={handleSubmit(onSubmit)}
            >
              <CheckIcon />
            </AimIconAndTextButton>
          </div>
        </div>
      </>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        {/* <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: 10,
          }}
        > */}
        <AimRadioCustom
          control={control}
          onChange={handleConfirmPax}
          formControlWidth="calc(100% - 10px)"
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
          name={'confirmPax'}
          defaultValue={confirmPax}
          value={confirmPax}
        >
          <AimFormControlLabel
            key={0}
            value={true}
            control={<AimRadioButton />}
            label={_i18n.confirmPax}
            formControlStyle={formControlStyle}
          />
        </AimRadioCustom>
        <div
          style={{
            marginLeft: '40px',
          }}
        >
          {servicesState?.data?.participationOnsiteOption && (
            <div
              style={{
                backgroundColor: 'white',
                padding: '10px',
              }}
            >
              <AimTypography variant={'textBold'} padding={0}>
                {_i18n.paxOnSite}
              </AimTypography>
              <AimRadioForm
                control={control}
                onClick={handlePaxOnSite}
                formControlWidth="calc(100% - 10px)"
                style={{
                  display: 'flex',
                }}
                name={'paxOnSite'}
                defaultValue={paxOnSite}
                value={paxOnSite}
              >
                <AimFormControlLabel
                  key={0}
                  value={'yes'}
                  control={<AimRadioButton />}
                  label={_i18n.yes}
                  formControlStyle={formControlStyle}
                />
                <AimFormControlLabel
                  key={1}
                  value={'no'}
                  control={<AimRadioButton />}
                  label={_i18n.no}
                  formControlStyle={formControlStyle}
                />
              </AimRadioForm>
            </div>
          )}
          <div>
            <AimTypography
              variant={'text'}
              boxStyle={{ margin: '15px 0px 0px 0px' }}
            >
              {_i18n.notes}
            </AimTypography>
            <AimTextFieldForm
              // inputRef={'notes'}
              control={control}
              name="notes"
              // label={_i18n.pax.notes}
              formControlStyle={{
                width: '100%',
                margin: '0px',
              }}
              style={{
                padding: '0px',
                height: 'auto',
              }}
              multiline
              rows={2}
              textfieldVariant={'secondary'}
            />
          </div>
          <div></div>
        </div>
        <AimRadioCustom
          control={control}
          onChange={handleConfirmPax}
          formControlWidth="calc(100% - 10px)"
          value={confirmPax}
          style={{
            display: 'flex',
            flexDirection: 'column',
            // backgroundColor: 'white',
          }}
          name={'confirmPax'}
          defaultValue={confirmPax}
        >
          <AimFormControlLabel
            key={1}
            value={false}
            control={<AimRadioButton />}
            label={_i18n.notConfirmPax}
            formControlStyle={formControlStyle}
          />
        </AimRadioCustom>
        {/* </div> */}
      </Grid>
      {console.log('servicesState', servicesState)}
      {renderButtons()}
      {/* {!servicesState?.data?.participationFacultyResponse
        ? renderButtons()
        : null} */}
    </Grid>
  );
};

export default ParticipationConfirm;
