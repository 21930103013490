import { aws, appState } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const createFavouriteAgendaSessionQuery = /* GraphQL */ `
  mutation CreateFavouriteAgendaSession(
    $input: CreateFavouriteAgendaSessionInput!
    $condition: ModelFavouriteAgendaSessionConditionInput
  ) {
    createFavouriteAgendaSession(input: $input, condition: $condition) {
      id
    }
  }
`;

const deleteFavouriteAgendaSessionQuery = /* GraphQL */ `
  mutation DeleteFavouriteAgendaSession(
    $input: DeleteFavouriteAgendaSessionInput!
    $condition: ModelFavouriteAgendaSessionConditionInput
  ) {
    deleteFavouriteAgendaSession(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createFavorite = (agendaSessionId, participationId) =>
  new Promise((resolve, reject) => {
    showLoader();
    API.graphql({
      query: createFavouriteAgendaSessionQuery,
      variables: {
        input: {
          favouriteAgendaSessionOwnerId: participationId,
          favouriteAgendaSessionSessionId: agendaSessionId,
        },
      },
    })
      .then((response) => {
        resolve(response.data.createFavouriteAgendaSession);
      })
      .catch((e) => {
        console.error('Error AgendaList-createFavorite', e);
        reject();
      })
      .finally(hideLoader);
  });

export const deleteFavorite = (favouriteAgendaSessionId) =>
  new Promise((resolve, reject) => {
    showLoader();
    API.graphql({
      query: deleteFavouriteAgendaSessionQuery,
      variables: {
        input: {
          id: favouriteAgendaSessionId,
        },
      },
    })
      .then((response) => {
        resolve(response.data.deleteFavouriteAgendaSession);
      })
      .catch((e) => {
        console.error('Error AgendaList-deleteFavorite', e);
        reject();
      })
      .finally(hideLoader);
  });
