import React from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { appState, aws, constants } from '@aim/common';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { CustomIntl, AimTypography } from '@aim/components';

import { translation } from './translation';
import { useEffect } from 'react';
import { isBefore } from 'date-fns';
import { useState } from 'react';

const useStyles = makeStyles({
  list: {
    minWidth: 250,
  },
  fullList: {
    width: 'auto',
  },
});

const links = ({ eventId, sponsorId, hasPremiumRanges, i18n }) => {
  return [
    {
      label: i18n.services,
      link: `/events/${eventId}/${sponsorId}/my-services`,
      visible: true,
    },
    {
      label: i18n.premiumRanges,
      link: `/events/${eventId}/${sponsorId}/price-ranges`,
      visible: hasPremiumRanges,
    },
    // {
    //   label: i18n.servicesConfiguration,
    //   link: `/events/${eventId}/${sponsorId}/my-services`,
    //   visible: true,
    // },
    {
      label: i18n.cart,
      link: `/events/${eventId}/${sponsorId}/cart`,
      visible: true,
    },
  ];
};

const ResponsiveDrawer = ({
  isOpen,
  setIsOpen,
  hasPremiumRanges,
  hideMyArea,
}) => {
  const { eventId } = useParams();
  const { pathname } = useLocation();
  const [headerMenuItems, setHeaderMenuItems] = useState([]);

  const classes = useStyles();
  const participation = appState.user.getValue()?.userAndParticipation
    ?.participation;
  const intl = CustomIntl(useIntl());
  const i18n = translation.drawer(intl);

  const sponsor = participation?.sponsor; // || participation?.sponsorStaff?.sponsor;

  const isValidSponsor =
    !participation?.isDeleted && sponsor?.id && !sponsor?.isDeleted;

  const isAgencyAdmin =
    !participation?.isDeleted &&
    participation?.agency?.id &&
    !participation?.agency?.isDeleted;

  const hideMyAreaForSponsorStaff = hideMyArea && participation.sponsorStaff;

  useEffect(() => {
    const fetchHeaderMenu = () => {
      // if (
      //   !appState.user.getValue()?.userAndParticipation?.participation
      //     ?.hasTicket
      // )
      //   return;
      const configuration = appState.eventConfiguration.getValue();
      if (!configuration?.eventGenericMenuItems?.items?.length) {
        return;
      }
      const nextHeaderMenu = Object.values(constants.EventGenericMenuItems)
        .filter((item) => {
          const configItem = configuration.eventGenericMenuItems.items.find(
            (configItem) => configItem.key === item.key
          );
          return (
            item.frontofficeLink &&
            configItem.activeFlagChecked &&
            (!item.dateOfActivation ||
              !isBefore(Date.now(), new Date(configItem.dateOfActivation)))
          );
        })
        .map((item) => {
          const configItem = configuration.eventGenericMenuItems.items.find(
            (configItem) => configItem.key === item.key
          );
          const label =
            configItem?.menuItemLabel ||
            Object.values(constants.EventGenericMenuItems)
              .find((menuItem) => menuItem.key === item.key)
              .label(intl);
          return { link: item.frontofficeLink, label };
        });
      setHeaderMenuItems(isValidSponsor ? [] : nextHeaderMenu);
    };

    fetchHeaderMenu();
  }, []);

  /*
    The following properties are used in this documentation website for optimal usability of the component:
    iOS is hosted on high-end devices. The backdrop transition can be enabled without dropping frames. The performance will be good enough.
    iOS has a "swipe to go back" feature that interferes with the discovery feature, so discovery has to be disabled.
  */
  const iOS =
    typeof navigator !== 'undefined' &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);

  const toggleDrawer = (opened) => {
    setIsOpen(opened);
  };

  const DrawerListItem = ({ link, label }) => (
    <ListItem>
      <Link
        style={{
          textDecoration: 'none',
          color: 'black',
        }}
        to={link}
      >
        <ListItemText disableTypography>
          <AimTypography
            variant="h4"
            boxStyle={{
              fontWeight: pathname === link ? 700 : 300,
            }}
          >
            {label}
          </AimTypography>
        </ListItemText>
      </Link>
    </ListItem>
  );

  const handleLogout = () => {
    aws.signOut().then(() => {
      appState.user.next(null);
      history.push(`/events/${eventId}/login`);
    });
  };

  const Lista = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={() => toggleDrawer(false)}
      onKeyDown={() => toggleDrawer(false)}
    >
      <CssBaseline />

      <List>
        {headerMenuItems?.map(({ label, link }, index) => (
          <DrawerListItem
            key={index}
            link={`/events/${eventId}/${link}`}
            label={label}
          ></DrawerListItem>
        ))}
        {!hideMyAreaForSponsorStaff && (
          <DrawerListItem
            link={`/events/${eventId}/user/${participation?.id}`}
            label={i18n.myArea}
          />
        )}
        <DrawerListItem
          link={`/events/${eventId}/landing`}
          label={i18n.landing}
        />

        {isValidSponsor &&
          links({
            eventId: eventId,
            sponsorId: sponsor?.id,
            hasPremiumRanges,
            i18n,
          })
            .filter((x) => x.visible)
            .map(({ link, label }, ndx) => {
              return (
                <DrawerListItem
                  key={`resp_drawer_link_${ndx}`}
                  label={label}
                  link={link}
                />
              );
            })}
        {isValidSponsor && (
          <DrawerListItem
            link={`/events/${eventId}/${sponsor?.id}/my-purchases`}
            label={i18n.purchases}
          />
        )}
        {isValidSponsor && (
          <DrawerListItem
            link={`/events/${eventId}/${sponsor?.id}/billing-informations`}
            label={i18n.billingInformation}
          />
        )}
        {isAgencyAdmin && (
          <DrawerListItem
            link={`/events/${eventId}/agency/${participation?.agency?.id}/group-list`}
            label={i18n.myGroups}
          />
        )}
        <ListItem onClick={handleLogout}>
          <ListItemText disableTypography>
            <AimTypography
              variant="h4"
              boxStyle={{
                fontWeight: 300,
              }}
            >
              {i18n.logout}
            </AimTypography>
          </ListItemText>
        </ListItem>
      </List>
    </div>
  );

  return (
    <SwipeableDrawer
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      anchor="right"
      open={isOpen}
      onClose={() => toggleDrawer(false)}
      onOpen={() => toggleDrawer(true)}
    >
      <Lista />
    </SwipeableDrawer>
  );
};

export default ResponsiveDrawer;
