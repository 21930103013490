import React, { useState, useEffect } from 'react';
import Handlebars from 'handlebars/dist/cjs/handlebars';
import { CustomIntl } from '@aim/components';
import { mailUpHelper } from '@aim/common';
import { useIntl } from 'react-intl';
import { translation } from './../participationServicesPublicPage/translation';

const GeneralInfoContent = ({ data, participation, preTitles }) => {
  // i18n
  const intl = CustomIntl(useIntl());
  const i18n = translation.participationServicesPublicPage(intl).generalInfo;

  // State
  const [body, setBody] = useState();

  useEffect(() => {
    if (data) {
      const parsedFields = JSON.parse(data.fieldMapping).body;
      const bodyMapping = Object.entries(parsedFields).reduce(
        (res, [key, valueObj]) => {
          return {
            ...res,
            [(key || '').replaceAll(' ', '_')]: mailUpHelper.findValue({
              obj: participation,
              valueObj,
              preTitles,
            }),
          };
        },
        {}
      );
      console.log('bodyMapping', bodyMapping);

      const template = Handlebars.compile(
        mailUpHelper.renderMarkdownToHtml(data.body)
      );
      const result = template(bodyMapping);
      setBody(result);
    }
  }, [data]);

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: body,
      }}
    />
  );
};

export { GeneralInfoContent };
