import React, { useState, useEffect } from 'react';

import { useIntl } from 'react-intl';
import translation from './../../shared/translation';

import Tooltip from '@material-ui/core/Tooltip';
import ArrowBack from '@material-ui/icons/ArrowBack';
import DoneIcon from '@material-ui/icons/Done';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { useParams, useHistory } from 'react-router-dom';
import {
  CustomIntl,
  styled,
  theme,
  AimIconAndTextButton,
  AimTypography,
  AimCard,
  AimIconButton,
} from '@aim/components';

import { useDataHelper } from '../../shared/dataHelper';
import { useSponsors } from './../../shared/sponsorGqlHelper';

export const MainContainer = styled('div')({
  width: '90%',
  display: 'flex',
  flexDirection: 'column',
  // background: theme.colors.greyScale.backgroundGrey,
});

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  backgroundColor: 'white',
  padding: 10,
  borderBottom: `30px solid ${theme.colors.greyScale.backgroundGrey}`,
});

const VirtualStands = () => {
  // Hooks
  const { eventId, sponsorId } = useParams();
  const history = useHistory();
  const intl = CustomIntl(useIntl());
  const i18n = translation.virtualStandConfiguration(intl);
  const {
    isValidPurchase,
    searchSponsorHighestRankingVirtualStand,
  } = useDataHelper();
  const sponsorHelper = useSponsors();

  // States
  const [sponsorVirtualStand, setSponsorVirtualStand] = useState();
  const [sponsor, setSponsor] = useState();

  //Effects
  useEffect(() => {
    fetchSponsor();
  }, []);

  useEffect(() => {
    if (sponsor) {
      const sponsorVirtualStands = sponsor?.sponsorVirtualStand?.items?.filter(
        (i) =>
          i?.buyOperation?.purchase &&
          isValidPurchase(i?.buyOperation?.purchase)
      );
      const vs = searchSponsorHighestRankingVirtualStand(sponsorVirtualStands);
      setSponsorVirtualStand(vs);
      console.log('vs', vs);
    }
  }, [sponsor]);

  // Functions
  const fetchSponsor = async () => {
    const result = await sponsorHelper.get(sponsorId);
    setSponsor(result);
  };

  return (
    <MainContainer>
      <div style={{ display: 'flex-root', marginTop: 20 }}>
        <Tooltip title={i18n.page.back.tooltip}>
          <AimIconAndTextButton
            variant="text"
            text={i18n.page.back.label}
            onClick={() =>
              history.push(`/events/${eventId}/${sponsorId}/my-services`)
            }
          >
            <ArrowBack />
          </AimIconAndTextButton>
        </Tooltip>
      </div>
      <AimTypography variant="pageTitle">{i18n.page.title.label}</AimTypography>
      <Container>
        {sponsorVirtualStand && (
          <AimCard
            actionChildren={
              <Tooltip
                title={
                  sponsorVirtualStand.virtualRoomLink
                    ? i18n.page.card.showVirtualStand.label
                    : i18n.page.card.showVirtualStand.disabled
                }
              >
                <div>
                  <AimIconAndTextButton
                    text={i18n.page.card.showVirtualStand.label}
                    variant="primary"
                    onClick={() =>
                      history.push({
                        pathname: `/events/${eventId}/sponsors/${sponsorId}`,
                        state: {
                          visitStand: true,
                        },
                      })
                    }
                    //disabled={!sponsorVirtualStand.virtualRoomLink}
                  >
                    <VisibilityIcon />
                  </AimIconAndTextButton>
                </div>
              </Tooltip>
            }
            title={sponsorVirtualStand?.virtualStand?.name}
            cardVariant="grey"
          >
            {sponsorVirtualStand?.virtualStand?.specificationPost?.map(
              (s, idx) => {
                return (
                  <div
                    key={idx}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <AimIconButton
                      variant="greenFill"
                      small
                      style={{ pointerEvents: 'none' }}
                    >
                      <DoneIcon />
                    </AimIconButton>
                    <AimTypography variant={'h5Regular'}>{s}</AimTypography>
                  </div>
                );
              }
            )}
          </AimCard>
        )}
        {!sponsorVirtualStand && (
          <AimTypography>{i18n.page.noData.label}</AimTypography>
        )}
      </Container>
    </MainContainer>
  );
};

export { VirtualStands };
