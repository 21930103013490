import { aws, appState } from '@aim/common';
const { API } = aws;

const createBannerServiceMutation = /* GraphQL */ `
  mutation CreateBannerService(
    $input: CreateBannerServiceInput!
    $condition: ModelBannerServiceConditionInput
  ) {
    createBannerService(input: $input, condition: $condition) {
      id
    }
  }
`;

const getEventBannerServiceQuery = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      sponsorServiceBookingDuration
      bannerService {
        id
        name
        description
        vatRate
        jsonDefinition
        isCreditCardEnabled
        isBankTransferEnabled
        documents {
          items {
            id
            name: originalName
            size
            extension
          }
        }
        createdAt
        updatedAt
      }
      configuration {
        id
        homepageSponsorBannerArea {
          items {
            id
            name
            price
            cost
            isExclusive
            sponsorPackageItems {
              items {
                package {
                  buyOperations {
                    items {
                      id
                      isDeleted
                      isExpired
                      sponsor {
                        id
                      }
                      purchase {
                        id
                        paymentType
                        payment {
                          paymentIdMonetaOnLine
                          paymentIdIngenico
                          paymentIdGpWebpay
                        }
                      }
                    }
                  }
                }
              }
            }
            buyOperations {
              items {
                id
                sponsor {
                  id
                }
                isDeleted
                isExpired
              }
            }
            sponsorAreas {
              items {
                id
                image
              }
            }
          }
        }
      }
    }
  }
`;

const createBannerPageMutation = /* GraphQL */ `
  mutation CreateBannerPage(
    $input: CreateBannerPageInput!
    $condition: ModelBannerPageConditionInput
  ) {
    createBannerPage(input: $input, condition: $condition) {
      id
    }
  }
`;

const createBannerAreaMutation = /* GraphQL */ `
  mutation CreateBannerArea(
    $input: CreateBannerAreaInput!
    $condition: ModelBannerAreaConditionInput
  ) {
    createBannerArea(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateBannerPageMutation = /* GraphQL */ `
  mutation UpdateBannerPage(
    $input: UpdateBannerPageInput!
    $condition: ModelBannerPageConditionInput
  ) {
    updateBannerPage(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateBannerAreaMutation = /* GraphQL */ `
  mutation UpdateBannerArea(
    $input: UpdateBannerAreaInput!
    $condition: ModelBannerAreaConditionInput
  ) {
    updateBannerArea(input: $input, condition: $condition) {
      id
    }
  }
`;

const deleteBannerPageMutation = /* GraphQL */ `
  mutation DeleteBannerPage(
    $input: DeleteBannerPageInput!
    $condition: ModelBannerPageConditionInput
  ) {
    deleteBannerPage(input: $input, condition: $condition) {
      id
      bannerAreas {
        items {
          id
        }
        nextToken
      }
    }
  }
`;

const deleteBannerAreaMutation = /* GraphQL */ `
  mutation DeleteBannerArea(
    $input: DeleteBannerAreaInput!
    $condition: ModelBannerAreaConditionInput
  ) {
    deleteBannerArea(input: $input, condition: $condition) {
      id
    }
  }
`;

const onUpdateBannerAreaSubscription = /* GraphQL */ `
  subscription OnUpdateBannerArea {
    onUpdateBannerArea {
      id
    }
  }
`;

const onDeleteBannerAreaSubscription = /* GraphQL */ `
  subscription OnDeleteBannerArea {
    onDeleteBannerArea {
      id
    }
  }
`;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

export const createBannerService = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({ query: createBannerServiceMutation, variables: { input } })
      .then((response) => resolve(response.data.createBannerService))
      .catch((e) => {
        console.error('create-event-sponsor-bannerService', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const getEventBannerService = (id, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({ query: getEventBannerServiceQuery, variables: { id } })
      .then((response) => {
        const bannerService = {
          ...response.data.getEvent,
          configuration: {
            ...response.data.getEvent?.configuration,
            homepageSponsorBannerArea: {
              items: response.data.getEvent?.configuration?.homepageSponsorBannerArea?.items?.map(
                (ba) => {
                  const filteredBuyOperations = [
                    ...ba.buyOperations?.items,
                    ...(ba.sponsorPackageItems?.items || []),
                  ].filter(
                    (bo) => bo.isDeleted !== 'true' && bo.isExpired !== 'true'
                  );
                  return {
                    ...ba,
                    buyOperations: {
                      items: filteredBuyOperations,
                    },
                  };
                }
              ),
            },
          },
        };
        resolve(bannerService);
      })
      .catch((e) => {
        console.error('get-event-sponsor-bannerService', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const createBannerPage = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({ query: createBannerPageMutation, variables: { input } })
      .then((response) => resolve(response.data.createBannerPage))
      .catch((e) => {
        console.error('create-event-sponsor-bannerPage', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const createBannerArea = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({ query: createBannerAreaMutation, variables: { input } })
      .then((response) => resolve(response.data.createBannerArea))
      .catch((e) => {
        console.error('create-event-sponsor-bannerArea', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const updateBannerPage = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({ query: updateBannerPageMutation, variables: { input } })
      .then((response) => resolve(response.data.updateBannerPage))
      .catch((e) => {
        console.error('update-event-sponsor-bannerPage', e);
        reject();
      })
      .finally(useLoader && hideLoader());
  });

export const updateBannerArea = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({ query: updateBannerAreaMutation, variables: { input } })
      .then((response) => resolve(response.data.updateBannerArea))
      .catch((e) => {
        console.error('update-event-sponsor-bannerArea', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const deleteBannerPage = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({ query: deleteBannerPageMutation, variables: { input } })
      .then((response) => resolve(response.data.deleteBannerPage))
      .catch((e) => {
        console.error('update-event-sponsor-bannerPage', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const deleteBannerArea = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({ query: deleteBannerAreaMutation, variables: { input } })
      .then((response) => resolve(response.data.deleteBannerArea))
      .catch((e) => {
        console.error('update-event-sponsor-bannerArea', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const onUpdateBannerArea = (callback) =>
  aws.standardAPI.graphql({ query: onUpdateBannerAreaSubscription }).subscribe({
    next: ({ value }) => callback(value.data.onUpdateBannerArea),
    error: (e) => {
      console.error('on-update-banner-area', e);
    },
  });

export const onDeleteBannerArea = (callback) =>
  aws.standardAPI.graphql({ query: onDeleteBannerAreaSubscription }).subscribe({
    next: ({ value }) => callback(value.data.onDeleteBannerArea),
    error: (e) => {
      console.error('on-delete-banner-area', e);
    },
  });
