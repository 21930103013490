import React, { useEffect, useState } from 'react';
import { aws, appState, fileHelper } from '@aim/common';

import { isMobile } from 'react-device-detect';
import { styled } from '@aim/components';
import { useParams } from 'react-router-dom';
import LoginImage from './../../../assets/images/login-image.jpg';
import LoginImageMobile from './../../../assets/images/login-image-mobile.png';
import Logo from './../../../assets/images/logo_2x.png';

const RootContainer = styled('div')({
  display: 'flex',
  flexDirection: isMobile ? 'column' : 'row',
  width: '100%',
  height: '100%',
});

const RightContainer = styled('div')({
  flex: 1,
  backgroundColor: '#FFFFFF',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: isMobile ? 20 : 40,
  //overflowY: 'scroll',
});

const TopRightContainer = styled('div')({
  flex: 1,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const UserFormContainer = styled('div')({
  width: '100%',
  paddingBottom: isMobile ? 20 : 60,
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  marginTop: isMobile ? 0 : 50,
});

const CopyrightContainer = styled('div')({
  textAlign: 'center',
});

const Copyright = styled('div')({
  fontSize: '0.875rem !important',
  color: '#5B5B5B',
});

const StandardCoverImage = () => (
  <img
    src={isMobile ? LoginImageMobile : LoginImage}
    style={{
      maxWidth: isMobile ? '100%' : null,
      width: isMobile ? null : '100%',
      height: isMobile ? 'auto' : '100%',
      maxHeight: isMobile ? '40vh' : null,
      objectFit: isMobile ? null : 'cover',
    }}
  />
);

const CoverImage = (props) => (
  <div
    style={{
      maxWidth: isMobile ? '100%' : null,
      width: isMobile ? '100vw' : '100%',
      height: isMobile ? '100vh' : '100%',
      maxHeight: isMobile ? '32vh' : null,
      objectFit: 'cover',
      backgroundSize: 'cover',
      backgroundImage: `url("${props.coverImage}")`,
      backgroundPosition: 'center',
    }}
  />
);

const BasePage = ({ children }) => {
  const { eventId } = useParams();
  const { getAwsS3Link } = fileHelper;
  const [eventConfiguration, setEventConfiguration] = useState({});
  const [coverImage, setCoverImage] = useState(null);

  useEffect(() => {
    const subscription = appState.eventConfiguration.subscribe(
      setEventConfiguration
    );
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    const getImages = async () => {
      if (
        eventConfiguration?.loginCover ||
        eventConfiguration?.loginCoverMobile
      ) {
        const link = getAwsS3Link(
          `events/${eventId}/configuration/generalInfo/registration/cover/`,
          isMobile
            ? eventConfiguration.loginCoverMobile
            : eventConfiguration.loginCover
        );
        const convertedLink = aws.s3.getS3ObjectUrl(link);

        setCoverImage(convertedLink);
      }
    };

    getImages();
  }, [eventConfiguration]);

  return (
    <RootContainer>
      <div style={{ ...(!isMobile && { flex: 1 }) }}>
        {!coverImage && <StandardCoverImage />}
        {coverImage && <CoverImage coverImage={coverImage} />}
      </div>
      <RightContainer>
        <TopRightContainer>
          <div style={{ backgroundColor: Logo }} />
          <UserFormContainer>{children}</UserFormContainer>
        </TopRightContainer>
        <CopyrightContainer>
          <Copyright>
            © Copyright {new Date().getFullYear()} AIM Communication
          </Copyright>
        </CopyrightContainer>
      </RightContainer>
    </RootContainer>
  );
};

export default BasePage;
