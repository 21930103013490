import { styled, theme } from '@aim/components';

export const MainContainer = styled('div')({
  width: '100%',
  height: 'inherit',
  display: 'flex',
  flexDirection: 'column',
  // background: theme.colors.greyScale.backgroundGrey,
});

export const InnerContainer = styled('div')(({ grow, bottomSpacing }) => ({
  marginBottom: bottomSpacing ? 20 : 0,
  marginTop: 20,
  flexGrow: grow ? 1 : 0,
  marginRight: '5%',
  marginLeft: '5%',
}));

export const QuickLinksContainer = styled('div')({
  width: '100%',
});
