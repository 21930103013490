import React from 'react';

import Divider from '@material-ui/core/Divider';
import { useIntl } from 'react-intl';

import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import DoneIcon from '@material-ui/icons/Done';

import {
  AimIconButton,
  AimTypography,
  CustomIntl,
  theme,
} from '@aim/components';

import { useDataHelper } from './../../shared/dataHelper';
import translation from './../../shared/translation';

const VirtualStandCard = ({ virtualStand, onAddToCart }) => {
  //Hooks
  const { formatNumber, decodeDbNumber } = useDataHelper();
  const intl = CustomIntl(useIntl());
  const i18n = translation.virtualStandCard(intl);

  //Functions
  const getTopText = () => {
    if (virtualStand?.clean) {
      return '';
    } else if (virtualStand?.upgrade) {
      return i18n.topText.upgrade.label;
    } else if (virtualStand?.purchased) {
      return i18n.topText.alreadyPurchased.label;
    }
  };
  return (
    <div
      style={{
        border: `1px solid ${
          virtualStand?.notPurchasable
            ? theme.colors.greyScale.grey2
            : theme.colors.primary.yellow
        }`,
        minHeight: 400,
        height: '100%',
        padding: 15,
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            minHeight: 40,
          }}
        >
          <div>
            <AimTypography variant={'h4regular'} style={{ color: 'grey' }}>
              {getTopText()}
            </AimTypography>
          </div>
          <div>
            {!virtualStand?.upgrade ? null : (
              <>
                <AimIconButton
                  variant="yellowFill"
                  small
                  onClick={() => onAddToCart(virtualStand.id, true)}
                >
                  <SwapHorizIcon />
                </AimIconButton>
                <AimIconButton
                  variant="violetFill"
                  small
                  onClick={() => onAddToCart(virtualStand.id)}
                >
                  <ShoppingCartIcon />
                </AimIconButton>
              </>
            )}
          </div>
        </div>
        <AimTypography variant={'h2'}>{virtualStand.name}</AimTypography>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {virtualStand?.specificationPost.map((s, idx) => {
            return (
              <div key={idx} style={{ display: 'flex', alignItems: 'center' }}>
                <AimIconButton
                  variant="greenFill"
                  small
                  style={{ pointerEvents: 'none' }}
                >
                  <DoneIcon />
                </AimIconButton>
                <AimTypography variant={'h5Regular'}>{s}</AimTypography>
              </div>
            );
          })}
        </div>
      </div>

      <div>
        <div style={{ marginTop: 10, marginBottom: 10 }}>
          <Divider />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <AimTypography variant={'h4'}>
              {i18n.bottomText.price.label}
            </AimTypography>
          </div>
          <div>
            <AimTypography variant={'h4regular'} style={{ color: 'grey' }}>
              {formatNumber(decodeDbNumber(virtualStand?.netPrice))}
            </AimTypography>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <AimTypography variant={'h4'}>
              {i18n.bottomText.vatRate.label}
            </AimTypography>
          </div>
          <div>
            <AimTypography variant={'h4regular'} style={{ color: 'grey' }}>
              {decodeDbNumber(virtualStand?.vatRate)}%
            </AimTypography>
          </div>
        </div>
      </div>
    </div>
  );
};

export { VirtualStandCard };
