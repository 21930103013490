import { aws, appState } from '@aim/common';

const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

export const getEventGroupsQuery = /* GraphQL */ `
  query GetEventGroups($id: ID!, $agencyId: ID!) {
    getEvent(id: $id) {
      id
      agencyService {
        id
      }
      agencies(
        filter: { and: { isDeleted: { ne: true }, id: { eq: $agencyId } } }
      ) {
        items {
          name
          admins {
            items {
              id
              givenName
              familyName
              email
            }
          }
          agencyGroups(filter: { isDeleted: { ne: true } }) {
            items {
              id
              isDeleted
              name
              client
              closingDate
              isPrepaid
              participations {
                items {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

const createGroupMutation = /* GraphQL */ `
  mutation CreateGroup(
    $input: CreateAgencyGroupInput!
    $condition: ModelAgencyGroupConditionInput
  ) {
    createAgencyGroup(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateGroupMutation = /* GraphQL */ `
  mutation UpdateAgencyGroup(
    $input: UpdateAgencyGroupInput!
    $condition: ModelAgencyGroupConditionInput
  ) {
    updateAgencyGroup(input: $input, condition: $condition) {
      id
    }
  }
`;

const deleteGroupMutation = /* GraphQL */ `
  mutation DeleteAgencyGroup(
    $input: DeleteAgencyGroupInput!
    $condition: ModelAgencyGroupConditionInput
  ) {
    deleteAgencyGroup(input: $input, condition: $condition) {
      id
    }
  }
`;

// const createReferentMutation = /* GraphQL */ `
//   mutation CreateParticipation(
//     $input: CreateParticipationInput!
//     $condition: ModelParticipationConditionInput
//   ) {
//     createParticipation(input: $input, condition: $condition) {
//       id
//     }
//   }
// `;

// const updateReferentMutation = /* GraphQL */ `
//   mutation CreateParticipation(
//     $input: UpdateParticipationInput!
//     $condition: ModelParticipationConditionInput
//   ) {
//     updateParticipation(input: $input, condition: $condition) {
//       id
//     }
//   }
// `;

export const createGroup = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    aws.API.graphql({ query: createGroupMutation, variables: { input } })
      .then(resolve)
      .catch((e) => {
        console.error('create-group', e);
        reject(e);
      })
      .finally(() => useLoader && hideLoader());
  });

export const updateGroup = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    aws.API.graphql({ query: updateGroupMutation, variables: { input } })
      .then(resolve)
      .catch((e) => {
        console.error('update-group', e);
        reject(e);
      })
      .finally(() => useLoader && hideLoader());
  });

export const removeGroup = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    aws.API.graphql({ query: deleteGroupMutation, variables: { input } })
      .then(resolve)
      .catch((e) => {
        console.error('delete-group', e);
        reject(e);
      })
      .finally(() => useLoader && hideLoader());
  });

// export const createReferent = (input, useLoader = true) =>
//   new Promise((resolve, reject) => {
//     useLoader && showLoader();

//     aws.API.graphql({ query: createReferentMutation, variables: { input } })
//       .then(resolve)
//       .catch((e) => {
//         console.error('create-referent', e);
//         reject(e);
//       })
//       .finally(() => useLoader && hideLoader());
//   });

// export const updateReferent = (input, useLoader = true) =>
//   new Promise((resolve, reject) => {
//     useLoader && showLoader();

//     aws.API.graphql({ query: updateReferentMutation, variables: { input } })
//       .then(resolve)
//       .catch((e) => {
//         console.error('update-referent', e);
//         reject(e);
//       })
//       .finally(() => useLoader && hideLoader());
//   });
