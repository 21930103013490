const translation = {
  paxAbstracts: (intl) => ({
    toolbar: {
      buttons: {
        add: intl.formatMessage({
          description: 'add',
          defaultMessage: 'Add',
        }),
        exportExcel: intl.formatMessage({
          description: 'exportExcel',
          defaultMessage: 'Export Excel',
        }),
        filter: intl.formatMessage({
          description: 'filter',
          defaultMessage: 'Filter',
        }),
      },
    },
    card: {
      presentationButton: intl.formatMessage({
        description: 'setup presentation',
        defaultMessage: 'Setup your presentation',
      }),
    },
    dialog: {
      text: intl.formatMessage({
        description: 'setup presentation',
        defaultMessage: 'Are you sure you want to delete the astract document?',
      }),
      title: intl.formatMessage({
        description: 'setup presentation',
        defaultMessage: 'Delete abstract document',
      }),
    },
    subtitles: {
      abstractsTerm: intl.formatMessage({
        description: 'submit your abstract',
        defaultMessage: 'You can submit your abstract up to',
      }),
      lateBreakersTerm: intl.formatMessage({
        description: 'late breackers',
        defaultMessage:
          'Late breackers: you can only submit your abstract as poster',
      }),
      deadline: intl.formatMessage({
        description: 'deadline',
        defaultMessage: 'deadline',
      }),
      subtitleMax1: intl.formatMessage({
        description: 'setup presentation',
        defaultMessage: 'You can send at least ',
      }),
      subtitleMax2: intl.formatMessage({
        description: 'setup presentation',
        defaultMessage: ' abstract',
      }),
      maxFile: intl.formatMessage({
        description: 'maxFile first string presentation',
        defaultMessage: 'You can upload a maximum of ',
      }),
      maxFileDocuments: intl.formatMessage({
        description: 'maxFile second string ',
        defaultMessage: ' documents per abstract',
      }),
      maxAbstracts: intl.formatMessage({
        description: 'maximum of abstracts',
        defaultMessage: 'You can submit up to a maximum of',
      }),
      maxSize: intl.formatMessage({
        description: 'max size presentation',
        defaultMessage: 'Each document must have a maximum size of',
      }),
    },
    aimTablePage: {
      header: {
        backButton: intl.formatMessage({
          description: 'back label',
          defaultMessage: 'Back',
        }),
        submitAbstract: {
          deadline: intl.formatMessage({
            description: 'submit your abstract',
            defaultMessage: 'You can submit your abstract up to',
          }),
        },
        title: intl.formatMessage({
          description: 'abstracts title',
          defaultMessage: 'Abstracts',
        }),
        action: {
          add: {
            tooltip: intl.formatMessage({
              description: 'add abstract tooltip',
              defaultMessage: 'Add',
            }),
          },
        },
      },
      card: {
        empty: intl.formatMessage({
          description: 'there are no abstract',
          defaultMessage: 'There are no abstract. Add one',
        }),
        labels: {
          title: intl.formatMessage({
            description: 'title label',
            defaultMessage: 'Title',
          }),
          status: intl.formatMessage({
            description: 'status services label',
            defaultMessage: 'Status',
          }),
          statusLabel: intl.formatMessage({
            description: 'status services label',
            defaultMessage: 'Status',
          }),
          presentationMode: intl.formatMessage({
            description: 'presentation mode label',
            defaultMessage: 'Presentation mode',
          }),
          acceptedAs: intl.formatMessage({
            description: 'accepted as label',
            defaultMessage: 'Accepted as',
          }),
        },
        buttons: {
          setupYourPresentation: intl.formatMessage({
            description: 'setup your presentation label',
            defaultMessage: 'Setup your presentation',
          }),
        },
        cardAction: {
          view: intl.formatMessage({
            description: 'view tooltip',
            defaultMessage: 'View',
          }),
          edit: intl.formatMessage({
            description: 'edit tooltip',
            defaultMessage: 'Edit',
          }),
          delete: intl.formatMessage({
            description: 'delete tooltip',
            defaultMessage: 'Delete',
          }),
        },
      },
      dialog: {
        delete: {
          title: intl.formatMessage({
            description: 'delete abstract title',
            defaultMessage: 'Delete abstract document',
          }),
          message: intl.formatMessage({
            description: 'dialog delete message',
            defaultMessage: 'Are you sure?',
          }),
          agreeText: intl.formatMessage({
            description: 'dialog delete onAgreeText',
            defaultMessage: 'Delete',
          }),
          disagreeText: intl.formatMessage({
            description: 'dialog delete onDisagreeText',
            defaultMessage: 'Cancel',
          }),
        },
      },
      snackbar: {
        delete: {
          success: intl.formatMessage({
            description: 'snackbar delete message',
            defaultMessage: 'Delete ok',
          }),
          error: intl.formatMessage({
            description: 'snackbar delete message',
            defaultMessage: 'Some error',
          }),
        },
      },
    },
  }),
};

export default translation;
