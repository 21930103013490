import { aws, appState, utilities, constants } from '@aim/common';
const { API } = aws;
const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

const getPurchaseInformationsQuery = /* GraphQL */ `
  query PurchaseInformations(
    $participationId: String
    $serviceType: String
    $serviceId: String
  ) {
    products: productByClientIdServiceTypeServiceId(
      clientId: $participationId
      filter: {
        serviceType: { eq: $serviceType }
        serviceId: { eq: $serviceId }
        isPublished: { eq: "true" }
      }
    ) {
      items {
        id
        productId
        payment {
          id
          date
          amount
          paymentId
          paymentIdGpWebpay
          paymentIdIngenico
          paymentIdMonetaOnLine
          paymentStatus
          paymentType
          paymentMetadata
        }
      }
    }
  }
`;

const getReservationsInformationsQuery = /* GraphQL */ `
  query getReservationsByProductId($participationId: ID!, $productId: ID) {
    reservations: getParticipation(id: $participationId) {
      hotelReservations(
        filter: { isDeleted: { ne: "true" }, id: { eq: $productId } }
      ) {
        items {
          id
          start
          end
          event {
            allotmentVat
          }
          roomReservation: hotelRoomReservation {
            items {
              id
              hotelRoom {
                id
                hotel {
                  id
                  name
                }
              }
              guests {
                items {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

const getEventGatewaysQuery = /* GraphQL */ `
  query GetEvent($id: ID!, $serviceType: String) {
    getEvent(id: $id) {
      id
      gateways(filter: { service: { eq: $serviceType } }) {
        items {
          id
          service
          paymentType
          ban
          ibanCausal
          addressedTo
          shop {
            id
            gatewayType
          }
        }
      }
    }
  }
`;

const getProfileFeeBracketQuery = /* GraphQL */ `
  query getProfileFeeBracketInfo($id: ID!) {
    getProfileFeeBracket(id: $id) {
      feeBracket {
        feeDateRange {
          start
          label
          end
        }
      }
    }
  }
`;

const getParticipantInfoByProductIdQuery = /* GraphQL */ `
  query getParticipantInfoByProductId($productId: ID!) {
    participation: getProduct(id: $productId) {
      payment {
        id
        paymentType
        amount
        cancellationStatus
        cancellationNotes
        billing: BillingInformation {
          id
          address
          city
          country
          postalCode
          province
          region
          vatCode
          uniqueCode
          name
          isVatEvent
          isSplitPayment
          ipaCode
          phone
          email
          pec
          reference
        }
      }
      productId
    }
  }
`;

const getHotelReservationByProductIdQuery = /* GraphQL */ `
  query getHotelReservationByProductId($productId: ID!) {
    hotelReservation: getHotelReservation(id: $productId) {
      id
      start
      end
      roomsReservation: hotelRoomReservation {
        items {
          id
          depositAmount
          balanceAmount
          startDate
          endDate
          isDeleted
          guests {
            items {
              familyName
              givenName
            }
          }
          hotelRoom {
            id
            name
            hotel {
              name
            }
          }
        }
      }
    }
  }
`;

export const getPurchaseInformations = (
  participationId,
  serviceId,
  serviceType
) =>
  new Promise((resolve, reject) => {
    showLoader();

    API.graphql({
      query: getPurchaseInformationsQuery,
      variables: {
        participationId,
        serviceId,
        serviceType,
      },
    })
      .then(({ data }) => {
        const products = data?.products?.items.filter((product) =>
          utilities.isValidPayment(product.payment)
        );
        resolve(products);
      })
      .catch((e) => {
        console.error('get products informations - my allotment', e);
        reject();
      })
      .finally(hideLoader);
  });

export const getHotelReservationInformations = (participationId, productId) =>
  new Promise((resolve, reject) => {
    showLoader();

    API.graphql({
      query: getReservationsInformationsQuery,
      variables: {
        participationId,
        productId,
      },
    })
      .then(({ data }) => {
        // const hotelReservations = data.reservations?.hotelReservations;
        const {
          reservations: { hotelReservations },
        } = data;
        console.log('Hotel reservations: ', hotelReservations);
        let hotelName = '';
        let guestsNumber = 0;
        let reservationInfo = {};
        hotelReservations?.items?.map((reservation) => {
          const {
            end,
            start,
            roomReservation,
            event: { allotmentVat },
          } = reservation;

          const roomsNumber = roomReservation.items?.length || 0;
          const vat = utilities.decodeDbNumber(allotmentVat);
          roomReservation.items?.map((roomRes) => {
            const {
              guests: { items: guestsItems },
              hotelRoom: { hotel },
            } = roomRes;
            guestsNumber += guestsItems?.length;
            hotelName = hotel.name;
          });

          reservationInfo = {
            ...reservationInfo,
            hotelName,
            roomsNumber,
            guestsNumber,
            checkOut: end,
            checkIn: start,
            allotmentVat: vat,
          };
        });

        console.log('Reservation back end', reservationInfo);
        resolve(reservationInfo);
      })
      .catch((e) => {
        console.error('get hotel reservation informations - my allotment', e);
        reject();
      })
      .finally(hideLoader);
  });

// export const getParticipationInfo = (participationId) =>
//   new Promise((resolve, reject) => {
//     showLoader();

//     API.graphql({
//       query: getParticipationInfoQuery,
//       variables: { id: participationId },
//     })
//       .then((response) =>
//         resolve({
//           ...response?.data?.getParticipation,
//         })
//       )
//       .catch((e) => {
//         console.erroahah dai io me fo certe gnaffate quando vado a correrer('get-participation-info-my-ticket', e);
//         reject();
//       })
//       .finally(hideLoader);
//   });

export const getEventGateway = (input) =>
  new Promise((resolve, reject) => {
    showLoader();
    aws.API.graphql({
      query: getEventGatewaysQuery,
      variables: { ...input },
    })
      .then((response) => {
        const { data } = response;
        resolve(data.getEvent.gateways.items[0]);
      })
      .catch((e) => {
        console.error('event-gateways', e);
        reject();
      });
  });

export const getProfileFeeBracket = (id, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    API.graphql({
      query: getProfileFeeBracketQuery,
      variables: {
        id,
      },
    })
      .then((response) =>
        resolve(response.data?.getProfileFeeBracket?.feeBracket)
      )
      .catch((e) => {
        console.error('get-profile-fee-bracket- my-ticket', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const getParticipationInfo = (productId) =>
  new Promise((resolve, reject) => {
    showLoader();

    API.graphql({
      query: getParticipantInfoByProductIdQuery,
      variables: {
        productId,
      },
    })
      .then((response) => {
        let participation;
        if (response?.data?.participation) {
          participation = { ...response?.data?.participation };
          const participationBilling = participation?.payment?.billing;
          // participationBilling &&
          participation = {
            ...participation,
            ...(participationBilling
              ? {
                  billing: {
                    column1: {
                      invoiceTo: participationBilling?.invoiceTo,
                      name: participationBilling?.name,
                      address: participationBilling?.address,
                      city: participationBilling?.city,
                      postalCode: participationBilling?.postalCode,
                      region: participationBilling?.region,
                      province: participationBilling?.province,
                      vatCode: participationBilling?.vatCode,
                      country: participationBilling?.country,
                      reference: participationBilling?.reference,
                      email: participationBilling?.email,
                      phone: participationBilling?.phone,
                    },
                    column2: {
                      invoiceToPublicAuthority:
                        participationBilling?.invoiceToPublicAuthority,
                      uniqueCode: participationBilling?.uniqueCode,
                      ipaCode: participationBilling?.ipaCode,
                      isSplitPayment: participationBilling?.isSplitPayment,
                      isVatExent: participationBilling?.isVatEvent,
                      taxCode: participationBilling?.taxCode,
                    },
                  },
                }
              : { billing: null }),
          };
          const paymentId = participation?.payment?.id;
          const status = participation?.payment?.cancellationStatus;
          const notes = participation?.payment?.cancellationNotes;
          participation?.payment &&
            (participation = {
              ...participation,
              cancellation: {
                id: paymentId,
                cancellationStatus: status
                  ? status
                  : constants.CancellationStatuses.PENDING.key,
                cancellationNotes: notes ? notes : '',
              },
            });
        }
        resolve(participation);
      })
      .catch((e) => {
        console.error('get product participant info', e);
        reject();
      })
      .finally(hideLoader);
  });

export const getReservationInfo = (productId) =>
  new Promise((resolve, reject) => {
    showLoader();

    API.graphql({
      query: getHotelReservationByProductIdQuery,
      variables: {
        productId,
      },
    })
      .then((response) => {
        let reservations;
        if (response?.data?.hotelReservation) {
          reservations =
            response?.data?.hotelReservation?.roomsReservation?.items;
          reservations = reservations.map((reservation) => {
            return {
              column1: {
                hotel: reservation?.hotelRoom?.hotel?.name,
                roomTipology: reservation?.hotelRoom?.name,
                guests: reservation?.guests?.items,
                startDate: response?.data?.hotelReservation?.start,
                endDate: response?.data?.hotelReservation?.end,
              },
              column2: {
                netAmount: null,
                tax: null,
                totalAmount: utilities.formatNumber(reservation?.balanceAmount),
              },
            };
          });
        }
        resolve(reservations);
      })
      .catch((e) => {
        console.error('get reservation info', e);
        reject();
      })
      .finally(hideLoader);
  });
