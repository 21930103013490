import { aws } from '@aim/common';
import { useLoaderHelper } from './loaderHelper';

const getParticipationSurveys = /* GraphQL */ `
  query GetParticipationSurveys($id: ID!) {
    getParticipation(id: $id) {
      surveyInvitations {
        items {
          id
          isCompleted
          survey {
            id
            model
            sentAt
            status
            title
            updatedAt
          }
        }
      }
    }
  }
`;

const useSurveys = (useLoader) => {
  const { showLoader, hideLoader } = useLoaderHelper();

  const get = async (participationId) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();
      aws.API.graphql({
        query: getParticipationSurveys,
        variables: { id: participationId },
      })
        .then((response) => {
          resolve(response.data.getParticipation.surveyInvitations);
        })
        .catch((e) => {
          console.error(e);
          reject(e);
        })
        .finally(() => useLoader && hideLoader());
    });

  return {
    get,
  };
};

export default useSurveys;
