import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import ArrowBack from '@material-ui/icons/ArrowBack';
import Tooltip from '@material-ui/core/Tooltip';

const { customAlphabet } = require('nanoid');
const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const nanoid = customAlphabet(alphabet, 6);

import { useDataHelper } from '../../shared/dataHelper';
import { paymentGqlHelper } from '../../shared/paymentGqlHelper';
import { agencyGqlHelper } from '../shared/agencyGqlHelper';
import { productGqlHelper } from '../shared/productGqlHelper';
// import { getAgencyGateway } from './agencyPaymentGqlHelper';

import {
  AimTypography,
  AimIconAndTextButton,
  AimSnackbarSeverity,
  AimSnackbar,
  CustomIntl,
  // styled,
} from '@aim/components';
import { constants, appState, aws } from '@aim/common';

import Checkout from './Checkout';
import { MainContainer, InnerContainer } from '../shared/Containers';
import { translation } from './translation';

const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

const AgencyPayment = () => {
  const history = useHistory();
  const { eventId, agencyId } = useParams();
  const intl = CustomIntl(useIntl());
  const i18n = translation.payment(intl);
  const paymentHelper = paymentGqlHelper();
  const agencyHelper = agencyGqlHelper();
  const productHelper = productGqlHelper();
  const { encodeDbNumber } = useDataHelper();

  const gateway = history.location.state.gateway;
  console.log('gateway', gateway);
  const paymentType = history.location.state.paymentType || gateway.paymentType;
  // get amount / paymentType for payment; get id / type for product
  const amount = history.location?.state?.amount || 0;
  const serviceId = history.location?.state?.serviceId;
  const serviceType = history.location?.state?.serviceType;
  const clientId = history.location?.state?.clientId;
  const clientType = history.location?.state?.clientType;
  const productId = history.location?.state?.prodId;
  const relUrl = history.location?.state?.relUrl;
  const serviceLabel = history.location?.state?.serviceLabel;

  // states
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [paymentGateway, setPaymentGateway] = React.useState(
    gateway?.shop?.gatewayType
  );
  console.log('paymentGateway', paymentGateway);
  const [responsePaymentIngenico, setResponsePaymentIngenico] = useState();

  const eventCodeRef = useRef();

  const PAYMENT_GATEWAY = {
    MONETAONLINE: 'MONETAONLINE',
    INGENICO: 'INGENICO',
    GPWEBPAY: 'GPWEBPAY',
  };

  useEffect(() => {
    const fetchEvent = async () => {
      const response = await productHelper.getEventCode(eventId);
      eventCodeRef.current = response.code;
    };
    fetchEvent();
  }, []);

  const handlePaymentChange = (event) => {
    setPaymentGateway(event.target.value);
  };

  const getPaymentIdMonetaOnLine = async (paymentId, relurl) => {
    // const testurl = 'https://test.d1zwmjatj8p4ws.amplifyapp.com';
    const baseurl = process.env.BASEURL.split('//')[1]; // testurl.split('//')[1] ||

    const apiName = 'aimMonetaOnLine';
    const path = '/monetaonline/initpayment';
    const queryData = {
      queryStringParameters: {
        paymentId,
        url: baseurl,
        relurl,
      },
      responseType: 'json',
    };

    const response = await aws.standardAPI.get(apiName, path, queryData);
    return response;
  };

  const initPaymentIngenico = async (paymentId, relurl) => {
    // const testurl = 'https://test.d1zwmjatj8p4ws.amplifyapp.com';
    const baseurl = process.env.BASEURL.split('//')[1]; // testurl.split('//')[1] ||

    const apiName = 'aimIngenico';
    const path = '/ingenico/initpayment';
    const queryData = {
      queryStringParameters: {
        paymentId,
        url: baseurl,
        relurl,
      },
      responseType: 'json',
    };

    const response = await aws.standardAPI.get(apiName, path, queryData);
    return response;
  };

  const initGpWebpay = async (paymentId, relurl) => {
    const baseurl = process.env.BASEURL.split('//')[1]; // testurl.split('//')[1] ||

    const apiName = 'aimGpWebPay';
    const path = '/gpwebpay/initpayment';

    const queryData = {
      queryStringParameters: {
        paymentId,
        url: baseurl,
        relurl,
      },
      responseType: 'json',
    };

    const response = await aws.standardAPI.get(apiName, path, queryData);
    return response;
  };

  const onClickConfirm = async () => {
    showLoader();
    let product;

    // calculate total amount of the purchase
    const amountEncoded = encodeDbNumber(amount);
    try {
      // create product
      const paymentId = history?.location?.state?.preselectedPaymentId
        ? history?.location?.state?.preselectedPaymentId
        : `${eventCodeRef.current}-${nanoid()}`;
      let tablePaymentId = history?.location?.state?.paymentData?.id; // to do
      product = await productHelper.create(
        {
          clientId,
          clientType,
          serviceId,
          serviceType,
          productId,
        },
        false
      );

      // if the payment type is credit card associate the payment to the product
      const payment = await paymentHelper.create(
        {
          paymentProductId: product.id,
          paymentType,
          date: new Date(),
          amount: amountEncoded,
          description: serviceLabel,
          paymentId,
          paymentStatus: constants.PaymentStatuses.PENDING.key,
        },
        false
      );

      tablePaymentId = payment.id;

      await productHelper.update(
        {
          id: product.id,
          productPaymentId: tablePaymentId,
        },
        false
      );

      if (paymentType === constants.PaymentTypes.CreditCard) {
        console.log('paymentGateway', paymentGateway);

        // init gpwebpay
        if (paymentGateway === PAYMENT_GATEWAY.GPWEBPAY) {
          // init payment GpWebpay
          const response = await initGpWebpay(
            payment.id,
            `events/${eventId}/agency/${agencyId}${relUrl ? '/' + relUrl : ''}`
          );
          if (response.url) {
            window.location = response.url;
            // setResponseGpWebpay(response.html);
          } else {
            // history.push(
            //   `/events/${eventId}/${sponsorId}/checkout-error?errorcode=${response.errorcode}&merchantorderid=${purchase.id}`
            // );
            history.push(`/events/${eventId}/agency/${agencyId}`);
          }
        }

        // init ingenico
        if (paymentGateway === PAYMENT_GATEWAY.INGENICO) {
          // init payment Ingenico
          const response = await initPaymentIngenico(
            payment.id,
            `events/${eventId}/agency/${agencyId}${relUrl ? '/' + relUrl : ''}`
          );
          setResponsePaymentIngenico(response.html);
        }

        // init monetaonline
        if (paymentGateway === PAYMENT_GATEWAY.MONETAONLINE) {
          // get payment id from MonetaOnLine
          const response = await getPaymentIdMonetaOnLine(
            payment.id,
            `events/${eventId}/agency/${agencyId}${relUrl ? '/' + relUrl : ''}`
          );

          if (response.error > 0) {
            history.push(
              `/events/${eventId}/agency/${agencyId}/checkout-error?errorcode=` +
                response.error
            );
          } else {
            const { paymentid, hostedpageurl } = response;
            // console.log("url ", `${hostedpageurl}?paymentid=${paymentid}`);
            window.location = `${hostedpageurl}?paymentid=${paymentid}`;
            // window.open(`${hostedpageurl}?paymentid=${paymentid}`, '_blank');
          }
        }
      } else {
        // bank transfer
        const res = await agencyHelper.getAgency(agencyId);
        const prepaidBudget = res.prepaidBudget;
        await agencyHelper.updateAgency(
          {
            id: agencyId,
            prepaidBudget:
              (parseInt(amountEncoded, 10) || 0) +
              (parseInt(prepaidBudget, 10) || 0),
          },
          false
        );
        history.push(
          `/events/${eventId}/agency/${agencyId}${
            relUrl ? `/${relUrl}/checkout-success` : `/checkout-success`
          }`
        );
      }
    } catch (e) {
      if (product?.id) {
        setSnackbar({
          isOpen: true,
          severity: AimSnackbarSeverity.error,
          message: i18n.page.errors.product.label,
        });
      }
    } finally {
      hideLoader();
    }
  };

  return (
    <>
      <MainContainer>
        <div style={{ display: 'flex-root', marginTop: 20 }}>
          <InnerContainer>
            <Tooltip title={i18n.page.back.checkout.tooltip}>
              <AimIconAndTextButton
                variant="text"
                text={i18n.page.back.checkout.label}
                onClick={() => {
                  if (gateway.paymentType === 'both') {
                    history.push(
                      `/events/${eventId}/agency/${agencyId}/payment-opt`
                    );
                  } else {
                    history.push(`/events/${eventId}/agency/${agencyId}`);
                  }
                }}
              >
                <ArrowBack />
              </AimIconAndTextButton>
            </Tooltip>
            <AimTypography variant={'h1'}>
              {i18n.page.title.checkout}
            </AimTypography>
          </InnerContainer>
        </div>
        <div>
          {parseInt(paymentGateway, 10) === PAYMENT_GATEWAY.INGENICO &&
          responsePaymentIngenico ? (
            <div
              className="content"
              dangerouslySetInnerHTML={{
                __html: responsePaymentIngenico,
              }}
            ></div>
          ) : (
            <InnerContainer bottomSpacing>
              <Checkout
                iban={gateway?.iban || ''}
                // checkoutBookings={checkoutBookings}
                // setCheckoutBookings={setCheckoutBookings}
                budget={amount || 0}
                onClickConfirm={onClickConfirm}
                onHandlePayment={handlePaymentChange}
                i18n={i18n}
                gatewayType={paymentGateway}
                paymentType={gateway?.paymentType}
                canChooseGateway={false}
              />
            </InnerContainer>
          )}
        </div>
      </MainContainer>

      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </>
  );
};

export default AgencyPayment;
