import React, { useState, useEffect } from 'react';
import { useFieldArray, Controller } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import {
  AimTextField,
  AimRadioButton,
  AimFormControlLabel,
  AimAutocompleteForm,
} from '@aim/components';
import RadioGroup from '@material-ui/core/RadioGroup';

export default ({
  nestIndex,
  control,
  register,
  setValue,
  errors,
  clearErrors,
  participantsForTheEvent,
  // roomsData,
  whoAmI,
  isMobileOnly,
  // trigger,
}) => {
  const formControlStyle = { width: 'calc(100% - 5px)' };

  const {
    fields,
    // remove, append
  } = useFieldArray({
    control,
    name: `rooms[${nestIndex}].guests`,
  });

  const [radioChoicesState, setRadioChoicesState] = useState([]);

  useEffect(() => {
    setRadioChoicesState([...fields]);
  }, []);

  return (
    <Grid
      container
      xs={12}
      style={{
        backgroundColor: 'white',
        padding: '10px 10px 10px 10px',
      }}
    >
      <Grid
        xs={12}
        style={{
          backgroundColor: '#F8F6FA',
          padding: '10px 10px 10px 10px',
          borderRadius: 4,
        }}
      >
        {fields.map((item, k) => {
          return (
            <Grid item container xs={12} key={item.id}>
              <RadioGroup
                aria-label="choice"
                name="choice"
                value={
                  radioChoicesState.length > 0 &&
                  radioChoicesState[k].radioChoice
                }
                style={{
                  width: '100%',
                  marginTop:
                    isMobileOnly && k > 0
                      ? 20
                      : !isMobileOnly && k > 0
                      ? 10
                      : 5,
                }}
              >
                <Grid item container xs={12}>
                  <Grid item xs={!isMobileOnly ? 4 : 12}>
                    <AimFormControlLabel
                      value="0"
                      control={<AimRadioButton color="default" />}
                      label="I am the guest"
                      checked={
                        radioChoicesState.length > 0 &&
                        radioChoicesState[k].radioChoice === '0'
                          ? true
                          : false
                      }
                      onChange={() => {
                        var c = [...radioChoicesState];
                        c[k].radioChoice = '0';
                        setRadioChoicesState(c);
                        setValue(
                          `rooms[${nestIndex}].guests[${k}].name`,
                          participantsForTheEvent.find(
                            (x) => x.value === whoAmI.value
                          )
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={!isMobileOnly ? 4 : 12}>
                    <AimFormControlLabel
                      value="1"
                      control={<AimRadioButton color="default" />}
                      label="The guest is a registered participant (insert mail)"
                      checked={
                        radioChoicesState.length > 0 &&
                        radioChoicesState[k].radioChoice === '1'
                          ? true
                          : false
                      }
                      onChange={() => {
                        var c = [...radioChoicesState];
                        c[k].radioChoice = '1';
                        setRadioChoicesState(c);
                        setValue(`rooms[${nestIndex}].guests[${k}].name`, null);
                      }}
                    />
                  </Grid>
                  <Grid item xs={!isMobileOnly ? 4 : 12}>
                    <AimFormControlLabel
                      value="2"
                      control={<AimRadioButton color="default" />}
                      checked={
                        radioChoicesState.length > 0 &&
                        radioChoicesState[k].radioChoice === '2'
                          ? true
                          : false
                      }
                      label="The guest is not registered"
                      onChange={() => {
                        var c = [...radioChoicesState];
                        c[k].radioChoice = '2';
                        setRadioChoicesState(c);
                        setValue(`rooms[${nestIndex}].guests[${k}].name`, null);
                      }}
                    />
                  </Grid>
                </Grid>
              </RadioGroup>

              <Grid item container xs={12}>
                <Grid
                  item
                  xs={!isMobileOnly ? 11 : 7}
                  style={{
                    display:
                      radioChoicesState.length > 0 &&
                      +radioChoicesState[k].radioChoice === 1
                        ? 'block'
                        : 'none',
                  }}
                ></Grid>

                <Grid
                  item
                  xs={
                    !isMobileOnly &&
                    radioChoicesState.length > 0 &&
                    +radioChoicesState[k].radioChoice > 1
                      ? 3
                      : 12
                  }
                >
                  {radioChoicesState.length > 0 &&
                    (+radioChoicesState[k].radioChoice === 0 ||
                      +radioChoicesState[k].radioChoice === 1) && (
                      <AimAutocompleteForm
                        control={control}
                        register={register}
                        label={
                          +radioChoicesState[k].radioChoice === 0
                            ? `Participant*`
                            : `Email*`
                        }
                        setValue={setValue}
                        errors={errors}
                        clearErrors={clearErrors}
                        textfieldVariant="white"
                        placeholder={'name, surname or email'}
                        name={`rooms[${nestIndex}].guests[${k}].name`}
                        options={participantsForTheEvent || []}
                        formControlStyle={formControlStyle}
                        disabled={
                          +radioChoicesState[k].radioChoice === 0 ? true : false
                        }
                        filterOptions={(options, state) =>
                          options.filter((o) => o.email === state.inputValue)
                        }
                      />
                    )}

                  <Controller
                    name={`rooms[${nestIndex}].guests[${k}].givenName`}
                    ref={register()}
                    defaultValue={item.givenName}
                    control={control}
                    render={(props) => (
                      <AimTextField
                        style={{
                          display:
                            radioChoicesState.length > 0 &&
                            +radioChoicesState[k].radioChoice === 2
                              ? 'block'
                              : 'none',
                        }}
                        value={props.value}
                        onChange={(e) => props.onChange(e.target.value)}
                        formControlStyle={formControlStyle}
                        label="Name*"
                        type="text"
                        textfieldVariant={`white`}
                        fieldStyle={{ width: '100%' }}
                        displayLabel={
                          radioChoicesState.length > 0 &&
                          +radioChoicesState[k].radioChoice === 2
                            ? true
                            : false
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={!isMobileOnly ? 3 : 12}
                  style={{
                    display:
                      radioChoicesState.length > 0 &&
                      +radioChoicesState[k].radioChoice > 1
                        ? 'block'
                        : 'none',
                  }}
                >
                  <Controller
                    name={`rooms[${nestIndex}].guests[${k}].familyName`}
                    ref={register()}
                    defaultValue={item.surname}
                    control={control}
                    render={(props) => (
                      <AimTextField
                        value={props.value}
                        onChange={(e) => props.onChange(e.target.value)}
                        formControlStyle={formControlStyle}
                        label="Surname*"
                        type="text"
                        textfieldVariant={`white`}
                        displayLabel={
                          radioChoicesState.length > 0 &&
                          +radioChoicesState[k].radioChoice > 1
                            ? true
                            : false
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={!isMobileOnly ? 3 : 12}
                  style={{
                    display:
                      radioChoicesState.length > 0 &&
                      +radioChoicesState[k].radioChoice > 1
                        ? 'block'
                        : 'none',
                  }}
                >
                  <Controller
                    name={`rooms[${nestIndex}].guests[${k}].email`}
                    ref={register()}
                    defaultValue={item.email}
                    control={control}
                    render={(props) => (
                      <AimTextField
                        value={props.value}
                        onChange={(e) => props.onChange(e.target.value)}
                        formControlStyle={formControlStyle}
                        label="Email"
                        type="text"
                        textfieldVariant={`white`}
                        displayLabel={
                          radioChoicesState.length > 0 &&
                          +radioChoicesState[k].radioChoice > 1
                            ? true
                            : false
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={!isMobileOnly ? 3 : 12}
                  style={{
                    display:
                      radioChoicesState.length > 0 &&
                      +radioChoicesState[k].radioChoice > 1
                        ? 'block'
                        : 'none',
                  }}
                >
                  <Controller
                    name={`rooms[${nestIndex}].guests[${k}].phone`}
                    ref={register()}
                    defaultValue={item.phone}
                    control={control}
                    render={(props) => (
                      <AimTextField
                        value={props.value}
                        onChange={(e) => props.onChange(e.target.value)}
                        formControlStyle={formControlStyle}
                        label="Phone"
                        type="text"
                        textfieldVariant={`white`}
                        displayLabel={
                          radioChoicesState.length > 0 &&
                          +radioChoicesState[k].radioChoice > 1
                            ? true
                            : false
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};
