import React, { useRef, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { utcToZonedTime, format } from 'date-fns-tz';
import {
  isWithinInterval,
  isAfter,
  parseISO,
  isBefore,
  subMilliseconds,
  startOfDay,
} from 'date-fns';

import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PublishIcon from '@material-ui/icons/Publish';

import { appState, constants, utilities } from '@aim/common';
import {
  CustomIntl,
  AimTablePage,
  MassiveImportDialog,
  AimSnackbarSeverity,
  AimSnackbar,
  AimTypography,
} from '@aim/components';

import { translation } from './agencyGroupMembers/index';

import {
  updateParticipationIsDeleted,
  updateAgencyBudget,
  updateGroup,
  closeAgencyGroup,
} from './agencyGroupMembers/gqlHelper';
import {
  getModificationData,
  getDeadlinesParsed,
  checkIsBeforeFreezeDeadline,
  addCreateReqToHistoricalOfParticipation,
  sortBy,
} from './agencyGroupMembers/dataHelper';
import { AgencyPrepaidBudgetDialog } from './agencyGroupMembers/AgencyPrepaidBudgetDialog';
// import MassiveImport from './shared/MassiveImport';
import { agencyPrepaidTimelineHelper } from '../shared/agencyTimelineHelper';
import { getNewPrepaidBudget } from './agency/shared/dataHelper';

const cluster = constants.Clusters.Groups.id;
const serviceType = constants.ProductType.agencyPrepaidGroup;
// const formattedDate = (date) => date && format(new Date(date), 'dd/MM/yyyy');

const getAgencyGroupQuery = /* GraphQL */ `
  query GetAgencyGroup(
    $id: ID!
    $agencyId: ID!
    $eventId: ID!
    $gatewayServiceId: String
    $serviceId: String
    $serviceType: String
    $clientId: String
  ) {
    getDeadline(externalId: $eventId, type: "agency") {
      externalId
      type
      value
    }
    getAgency(id: $agencyId) {
      id
      name
      prepaidBudget
    }
    getEvent(id: $eventId) {
      id
      gateways(filter: { service: { eq: $gatewayServiceId } }) {
        items {
          id
          service
          paymentType
          shop {
            id
            gatewayType
            iban
          }
        }
      }
    }
    getAgencyGroup(id: $id) {
      id
      name
      isPrepaid
      closingDate
      penaltiesPaidAt
      participations(filter: { isDeleted: { ne: true } }) {
        items {
          id
          username
          givenName
          familyName
          email
          createdAt
          updatedAt
          isDeleted
          profile {
            id
            name
            profileFeeBrackets {
              items {
                id
                priceOnAir
                priceOnSite
                feeBracket {
                  id
                  feeDateRange {
                    id
                    start
                    end
                  }
                  feeBracketModel {
                    id
                    start
                    end
                  }
                }
              }
            }
          }
          feeDateRange {
            id
            start
            end
          }
          participationHistorical {
            items {
              id
              modificationRequest {
                id
                requestStatus
                participationId
                createdAt
                updatedAt
              }
            }
          }
          cluster
          isParticipant
          isSpeaker
          isReviewer
        }
      }
      modificationRequests(filter: { isDeleted: { ne: true } }) {
        items {
          id
          familyName
          givenName
          email
          status
          createdAt
          updatedAt
          isDeleted
          modificationRequest {
            requestId
            participationId
            requestStatus
            createdAt
            updatedAt
            type
            participationRequest {
              isDeleted
              participationSubject {
                id
                isDeleted
              }
            }
          }
          profile {
            id
            name
          }
          cluster
          isParticipant
          isSpeaker
          isReviewer
        }
      }
    }
    productByClientIdServiceTypeServiceId(
      clientId: $clientId
      serviceTypeServiceId: {
        eq: { serviceId: $serviceId, serviceType: $serviceType }
      }
    ) {
      items {
        productId
        clientId
        clientType
        serviceId
        serviceType
        createdAt
        payment {
          id
          paymentId
          paymentType
          paymentIdMonetaOnLine
          paymentIdIngenico
          paymentIdGpWebpay
          paymentError
          amount
          paymentStatus
          createdAt
          updatedAt
          isDeleted
        }
      }
    }
  }
`;

const formatDate = (dateString) => {
  const currentTimezone = appState.eventInfo.getValue().timezone;
  const date = utcToZonedTime(new Date(dateString), currentTimezone);

  return format(date, 'dd/MM/yyyy', {
    timeZone: currentTimezone,
  });
};

const AgencyPrepaidMembers = () => {
  const result = new Date(Date.now());
  result.setDate(result.getDate() - 100);
  const { eventId, agencyId, groupId } = useParams();
  const history = useHistory();

  // ref
  const groupMembersRef = useRef();
  const agencyPrepaidBudgetRef = useRef();
  const deadlinesRef = useRef();
  const remainingPlacesRef = useRef();
  const sponsorListRef = useRef();
  const closingDateRef = useRef();
  // const penaltiesPaidAtRef = useRef();
  // const deadlineRef = useRef();
  const notEnoughBudgetRef = useRef(false);
  // const isMinNumberRef = useRef(false);
  // const modificationRequestsRefs = useRef();
  const agencyBudgetAvailable = useRef(0);
  // const numberOfModificationRequests = useRef(0);
  const totalPenaltiesRef = useRef(0);
  const gatewayRef = useRef();
  const modificationRequestsRef = useRef();
  const modificationReqsAcceptedRef = useRef();
  const isPostDeadlineRef = useRef();
  const isBeforeFreezeDeadline = useRef();

  // state
  const [timeline, setTimeline] = useState(null);
  const [isUseBudgetDialogOpen, setIsUseBudgetDialogOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  // const [agencyGroup, setAgencyGroup] = useState();

  // console.log('totalCostRef ', totalCostRef.current);

  // const fetchAgencyGroup = async () => {
  //   const agencyGroup = await getGroupData(groupId);
  //   setAgencyGroup(agencyGroup);
  // };

  // useEffect(() => {
  //   fetchAgencyGroup();
  // }, []);

  useEffect(() => {
    if (!eventId) return;

    const getTimeline = async () => {
      const nextTimeline = await agencyPrepaidTimelineHelper({ eventId });
      console.log({ nextTimeline });
      setTimeline(nextTimeline);
    };
    getTimeline();
  }, [eventId]);

  useEffect(() => {
    if (timeline) {
      checkoutResponse();
    }
  }, [timeline]);

  const [massiveDialog, setMassiveDialog] = useState({
    isOpen: false,
  });
  const [rowsLoaded, setRowsLoaded] = useState(false);

  // get translated objects for this route
  const intl = CustomIntl(useIntl());
  const i18n = translation.aimTablePage(intl);

  const handleUseBudgetClose = () => setIsUseBudgetDialogOpen(false);

  const checkoutResponse = async () => {
    const isCheckoutSuccess = location.pathname.includes('checkout-success');
    const isCheckoutError = location.pathname.includes('checkout-error');
    const query = new URLSearchParams(location.search);
    const errorcode = query.get('errorcode');
    const order = query.get('merchantorderid');
    const paymentId = query.get('pid');

    if (isCheckoutError && errorcode && errorcode > 0) {
      const messageError = '';
      switch (parseInt(errorcode)) {
        case 1: // query error in lambda
          messageError(`${i18n.checkout.errors.error1} ${order}`);
          break;
        case 2: // response error in lambda
          messageError(`${i18n.checkout.errors.error2} ${order}`);
          break;
        case 3: // error in lambda, init payment
          messageError(`${i18n.checkout.errors.error3} ${order}`);
          break;
        case 4: // duplicate order or other errors
          messageError(`${i18n.checkout.errors.error4} ${order}`);
          break;
        default:
          break;
      }
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: messageError,
      });
    } else if (isCheckoutSuccess) {
      // order by credit card
      // if (paymentId) {
      if (timeline.closePayingRequests) {
        // all at once: penalties paid
        await updateGroup({
          id: groupId,
          penaltiesPaidAt: new Date(),
        });
        setSnackbar({
          isOpen: true,
          severity: AimSnackbarSeverity.success,
          message: i18n.snackbar.payingPenaltiesSuccess,
        });
      }
      // fetchAgencyGroup();
      history.push(
        `/events/${eventId}/agency/${agencyId}/group-list/${groupId}/prepaid`
      );
    }
  };

  // const updatePrepaidBudget = async (price) => {
  //   const res = await agencyHelper.getAgency(agencyId);
  //   console.log('res agency ', res);
  //   await agencyHelper.updateAgency(
  //     {
  //       id: agencyId,
  //       prepaidBudget: price + res.prepaidBudget,
  //     },
  //     false
  //   );
  // };

  const handleAdd = () =>
    !timeline.makeRequest
      ? history.push(
          `/events/${eventId}/participation/${cluster}/${groupId}/new?t=prepaid`
        )
      : history.push(
          `/events/${eventId}/participation/${cluster}/${groupId}/new/post-deadline?t=prepaid`
        );

  const handleEdit = (row) =>
    !timeline.makeRequest
      ? history.push(
          `/events/${eventId}/participation/${cluster}/${groupId}/${row.id}?t=prepaid`
        )
      : history.push(
          `/events/${eventId}/participation/${cluster}/${groupId}/${row.id}/post-deadline?t=prepaid`
        );

  const handleDelete = async (id) => {
    await updateParticipationIsDeleted(id);
  };

  const closePrepaidGroup = async (loadData) => {
    await closeAgencyGroup(
      {
        id: groupId,
        closingDate: new Date(),
      },
      groupMembersRef.current.map((p) => p.id)
    );
    // await updateAgencyBudget(agencyId, agencyBudgetAvailable.current);
    await loadData();

    setSnackbar({
      isOpen: true,
      severity: AimSnackbarSeverity.success,
      message: i18n.snackbar.closingGroupSuccess,
    });
  };

  const handlePayAndClose = async () => {
    let amount = totalPenaltiesRef.current;
    amount = amount > 0 ? amount / 100 : 0;
    const isSomethingToPay = amount > 0; // totalCostRef.current > prepaidBudget;

    // payment by credit card or bank transfer
    if (isSomethingToPay) {
      if (gatewayRef.current.paymentType === 'both') {
        // in gateway setted both (in payment opt you can choose credit card or bank transfer)
        history.push({
          pathname: `/events/${eventId}/agency/${agencyId}/payment-opt`,
          state: {
            amount,
            prodId: groupId,
            serviceId: eventId,
            serviceType,
            clientId: agencyId,
            clientType: constants.Clusters.Agency.id,
            relUrl: `group-list/${groupId}/prepaid`,
            gateway: gatewayRef.current,
          },
        });
      } else {
        // in gateway setted one payment option (credit card or bank transfer)
        history.push({
          pathname: `/events/${eventId}/agency/${agencyId}/payment`,
          state: {
            amount,
            prodId: groupId,
            serviceId: eventId,
            serviceType,
            clientId: agencyId,
            clientType: constants.Clusters.Agency.id,
            relUrl: `group-list/${groupId}/prepaid`,
            gateway: gatewayRef.current,
          },
        });
      }
    } else {
      // payment by residual budget
      if (timeline.canCloseGroup) {
        // all at once: nothing to pay, set date to close group
        await updateGroup({ id: groupId, closingDate: new Date() });
        setSnackbar({
          isOpen: true,
          severity: AimSnackbarSeverity.success,
          message: i18n.snackbar.closingGroupSuccess,
        });
        // fetchAgencyGroup();
      } else if (timeline.closePayingRequests) {
        // all at once: nothing to pay, set date to close group
        await updateGroup({ id: groupId, penaltiesPaidAt: new Date() });
        setSnackbar({
          isOpen: true,
          severity: AimSnackbarSeverity.success,
          message: i18n.snackbar.payingPenaltiesSuccess,
        });
        // fetchAgencyGroup();
      }
      history.push(
        `/events/${eventId}/agency/${agencyId}/group-list/${groupId}/prepaid`
      );
    }
  };

  const checkIsPostDeadline = (deadline, isClosed) => {
    const currentTimezone = appState.eventInfo.getValue().timezone;
    //tolto un millisecondo allo start of day per evitare di controllare anche l'is equal
    const now = subMilliseconds(
      startOfDay(utcToZonedTime(new Date(), currentTimezone)),
      1
    );
    const deadlines = getDeadlinesParsed(deadline);
    const isAfterPrepaidDeadline = isAfter(
      now,
      utcToZonedTime(parseISO(deadlines?.prepaidDeadline), currentTimezone)
    );
    const isBeforeFreezeDeadline = isBefore(
      now,
      utcToZonedTime(parseISO(deadlines?.freezeDeadline), currentTimezone)
    );
    return (isAfterPrepaidDeadline || isClosed) && isBeforeFreezeDeadline;
  };

  const canNotAddMember = (checked, getAgencyGroup) => {
    // console.log(
    //   'canAddMember standard && ',
    //   timeline?.canAddMember,
    //   !agencyGroup?.closingDate
    // );
    // console.log(
    //   'canAddMember w/penalties && ',
    //   timeline?.canAddMember,
    //   timeline?.applyPenalties,
    //   !agencyGroup?.penaltiesPaidAt
    // );
    return !(
      (
        (timeline?.canAddMember && !getAgencyGroup?.closingDate) ||
        (timeline?.canAddMember && timeline?.applyPenalties)
      )
      // !agencyGroup?.penaltiesPaidAt)
    );
  };

  const canNotEditMember = (getAgencyGroup) => {
    return !(
      (
        (timeline?.canEditMember && !getAgencyGroup?.closingDate) ||
        (timeline?.canEditMember && timeline?.applyPenalties)
      )
      // !agencyGroup?.penaltiesPaidAt)
    );
  };

  // const canNotCloseGroup = () => {
  //   // (timeline && !timeline.canAddMember) ||
  //   // closingDateRef.current ||
  //   // notEnoughBudgetRef.current,
  //   return !(
  //     timeline?.canCloseGroup &&
  //     !agencyGroup?.closingDate &&
  //     isMinNumberRef.current
  //   );
  // };

  const canNotPayApprovedChanges = () => {
    return !timeline?.closePayingRequests;
  };

  const getPrepaidBudget = async () => {
    const newPrepaid = await getNewPrepaidBudget({
      prepaidBudget: agencyBudgetAvailable.current,
      agencyId,
    });
    return newPrepaid;
  };

  const dataLoader = {
    query: getAgencyGroupQuery,
    variables: {
      id: groupId,
      agencyId,
      eventId,
      gatewayServiceId: constants.GatewayServices().GROUP.key,
      serviceId: eventId,
      serviceType,
      clientId: agencyId,
    },
    transform: async (data) => {
      agencyPrepaidBudgetRef.current = data.getAgency?.prepaidBudget || 0;
      agencyBudgetAvailable.current = data.getAgency?.prepaidBudget || 0;
      closingDateRef.current = data?.getAgencyGroup.closingDate;
      // penaltiesPaidAtRef.current = data?.getAgencyGroup.penaltiesPaidAt;
      // isMinNumberRef.current =
      //   data.getAgencyGroup.participations.items.length >=
      //   getDeadlinesParsed(data?.getDeadline?.value).minNumber;
      const deadlines = getDeadlinesParsed(data?.getDeadline?.value);

      const newPrepaid = await getPrepaidBudget();
      // agencyBudgetAvailable.current = newPrepaid;
      agencyPrepaidBudgetRef.current = newPrepaid;

      // here timeline undefined (table page not accept conditional rendering),
      // check if a isPostDeadline
      isPostDeadlineRef.current = checkIsPostDeadline(data?.getDeadline?.value);
      isBeforeFreezeDeadline.current = checkIsBeforeFreezeDeadline(
        data?.getDeadline?.value
      );
      // console.log('timeline?.makeRequest ', timeline?.makeRequest);

      // set gateway for checking payment type to redirect user in payment options (credit card / bank transfer) or checkout payment
      gatewayRef.current = data?.getEvent.gateways.items[0];
      // console.log('gatewayRef.current', gatewayRef.current);
      modificationRequestsRef.current = 0;
      modificationReqsAcceptedRef.current = 0;
      totalPenaltiesRef.current = 0;

      // request for a new participation (type CREATE)
      let modificationRequests = [];
      if (isPostDeadlineRef.current) {
        modificationRequests = data.getAgencyGroup.modificationRequests?.items
          .filter(
            (x) =>
              x.modificationRequest.requestStatus ===
                constants.ModificationRequestStatus.PENDING &&
              x.modificationRequest.type ===
                constants.ModificationRequestTypes.CREATE
          )
          .map((req) => {
            modificationReqsAcceptedRef.current +=
              req.modificationRequest.requestStatus ===
              constants.ModificationRequestStatus.ACCEPTED
                ? 1
                : 0;
            modificationRequestsRef.current += 1;
            return {
              ...req,
              createdAt: format(new Date(req.createdAt), 'dd/MM/yyyy'),
              profile: req.profile?.name,
              isGroupClosed: !!data.getAgencyGroup.closingDate,
              isPenaltiesPaid: !!data.getAgencyGroup.penaltiesPaidAt,
              modification: req.modificationRequest.requestStatus,
              isReadOnly: true,
              noProfile: !req.profile,
            };
          });

        // only mod requests not paid (pax modified after last created product)
        const sortedProducts = sortBy(
          data.productByClientIdServiceTypeServiceId?.items.filter((product) =>
            utilities.isValidPaymentForAgency(product.payment)
          ),
          'createdAt'
        ).reverse();

        // console.log('sortedProducts ', sortedProducts);

        const modReqsToPay = data.getAgencyGroup.modificationRequests?.items
          .filter((req) => {
            // console.log('modReqsToPay req ', req);
            return (
              sortedProducts?.length < 1 ||
              (isAfter(
                parseISO(req.modificationRequest.updatedAt),
                parseISO(sortedProducts[0]?.createdAt)
              ) &&
                req.modificationRequest.requestStatus ===
                  constants.ModificationRequestStatus.ACCEPTED)
            );
          })
          .filter(
            (item) =>
              item.modificationRequest.requestStatus !==
              constants.ModificationRequestStatus.PENDING
          );

        // calc total penalties = penalty * reqs accepted
        // totalPenaltiesRef.current =
        //   deadlineRef.current.penalty * modificationReqsAcceptedRef.current;
        totalPenaltiesRef.current = deadlines.penalty * modReqsToPay.length;
      }

      const participations = data.getAgencyGroup.participations.items
        // .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
        .map((p) => {
          const price = utilities.getParticipationPrice(p);
          agencyBudgetAvailable.current -= price * 100;

          if (agencyBudgetAvailable.current < 0) {
            // remove participants or add prepaid budget
            notEnoughBudgetRef.current = true;
          } else {
            notEnoughBudgetRef.current = false;
          }

          // numberOfModificationRequests.current +=
          //   p.participationHistorical.items.length;

          // participationHistorical empty if creation of participants in post deadline was accepted
          // need to calc requests accepted and penalties to pay
          // added participation historical manually to current participation
          if (isPostDeadlineRef.current) {
            addCreateReqToHistoricalOfParticipation(
              data.getAgencyGroup.modificationRequests,
              p
            );
          }

          // n. of mod requests
          modificationRequestsRef.current +=
            p.participationHistorical.items.length;

          const modificationReqs = getModificationData(p);
          // num of modification reqs accepted
          modificationReqsAcceptedRef.current +=
            modificationReqs.modification.reqs[
              constants.ModificationRequestStatus.ACCEPTED
            ];

          // console.log('modificationReqs ', modificationReqs);

          return {
            ...p,
            createdAt: format(new Date(p.createdAt), 'dd/MM/yyyy'),
            profile: p.profile?.name,
            isGroupClosed: !!data.getAgencyGroup.closingDate,
            price,
            modification:
              modificationReqs.modification.label !== ''
                ? modificationReqs.modification.label
                : '-',
            noProfile: !p.profile,
          };
        });
      groupMembersRef.current = participations;
      return [...modificationRequests, ...participations];
    },
    bigStats: (tData, data) => {
      return [
        {
          // title: !agencyGroup?.closingDate ? 'budgetAvailable' : 'totalCost',
          title:
            !checkIsPostDeadline(data?.getDeadline?.value) ||
            (
              data.getAgencyGroup.closingDate &&
              new Date(data.getAgencyGroup.closingDate)?.valueOf() <=
                new Date(data?.getDeadline.value)
            )?.valueOf()
              ? 'budgetAvailable'
              : 'amountDue',

          value: () => {
            // data.getAgencyGroup.closingDate ||
            // checkIsPostDeadline(data?.getDeadline?.value)
            //   ? renderAmountDue()
            //   : renderPrepaid();

            // ex:
            // prepaidDeadline: '2021-11-04';
            // deadline: '2021-11-05';
            // freezeDeadline: '2021-11-18';
            // minNumber: '1';
            // penalty: 10000;

            let currValue = '- €';
            if (!isBeforeFreezeDeadline) {
              return currValue;
            }
            if (isPostDeadlineRef.current) {
              currValue = utilities.formatNumber(
                utilities.decodeDbNumber(totalPenaltiesRef.current)
              );
            } else if (data.getAgencyGroup.closingDate) {
              currValue = utilities.formatNumber(
                utilities.decodeDbNumber(agencyPrepaidBudgetRef.current)
              );
            } else {
              currValue = utilities.formatNumber(
                utilities.decodeDbNumber(agencyBudgetAvailable.current)
              );
            }
            return currValue;
          },
          // value: () =>
          //   dataHelper.formatNumber(
          //     dataHelper.decodeDbNumber(
          //       closingDateRef.current
          //         ? agencyPrepaidBudgetRef.current
          //         : agencyBudgetAvailable.current
          //     )
          //   ),
        },
        {
          title: 'nRecord',
          value: (tData) => tData.length,
        },
      ];
    },
    stats: (tData, data) => {
      return [
        {
          title: 'status',
          value: (_, data) =>
            data?.getAgencyGroup?.closingDate
              ? i18n.page.statuses.closed
              : i18n.page.statuses.open,
        },
        {
          title: 'minMembers',
          value: () => getDeadlinesParsed(data?.getDeadline?.value).minNumber,
        },
        {
          title: 'closingDate',
          value: () =>
            data?.getAgencyGroup?.closingDate
              ? formatDate(data.getAgencyGroup.closingDate)
              : '',
        },
        {
          title: 'postCloseChangeDeadline',
          value: () =>
            data?.getDeadline?.value
              ? formatDate(JSON.parse(data.getDeadline.value).freezeDeadline)
              : '',
        },
        {
          title: 'modificationRequests',
          value: () =>
            modificationRequestsRef.current
              ? modificationRequestsRef.current
              : '0',
        },
      ];
    },
  };

  const table = {
    isRowSelectable: false,
    columnsTemplate: [
      {
        id: 'username',
        numeric: false,
        disablePadding: false,
        isCheckbox: false,
        isDisabled: false,
      },
      {
        id: 'givenName',
        numeric: false,
        disablePadding: false,
        isCheckbox: false,
        isDisabled: false,
      },
      {
        id: 'familyName',
        numeric: false,
        disablePadding: false,
        isCheckbox: false,
        isDisabled: false,
      },
      {
        id: 'email',
        numeric: false,
        disablePadding: false,
        isCheckbox: false,
        isDisabled: false,
      },
      {
        id: 'profile',
        numeric: false,
        disablePadding: false,
        isCheckbox: false,
        isDisabled: false,
      },
      {
        id: 'price',
        numeric: false,
        disablePadding: false,
        isCheckbox: false,
        isDisabled: false,
      },
      {
        id: 'cluster',
        numeric: false,
        disablePadding: false,
        isCheckbox: false,
        isDisabled: false,
      },
      {
        id: 'createdAt',
        numeric: false,
        disablePadding: false,
        isCheckbox: false,
        isDisabled: false,
      },
      {
        id: 'modification',
        isCheckbox: false,
      },
    ],
    filters: [
      {
        column: 'username',
        type: 'string',
      },
      {
        column: 'givenName',
        type: 'string',
      },
      {
        column: 'familyName',
        type: 'string',
      },
      {
        column: 'email',
        type: 'string',
      },
      {
        column: 'profile',
        type: 'string',
      },
    ],
    rowAction: {
      edit: {
        icon: <EditIcon />,
        variant: 'lightBlueFill',
        // disabledWhen: ({ isGroupClosed }) => isGroupClosed,
        disabledWhen: ({ isReadOnly }, { getAgencyGroup }) =>
          isReadOnly || canNotEditMember(getAgencyGroup),
        onClick: handleEdit,
      },
      delete: {
        icon: <ClearIcon />,
        variant: 'redFill',
        // disabledWhen: ({ isGroupClosed }) => isGroupClosed,
        disabledWhen: ({ isReadOnly }, { getAgencyGroup }) =>
          isReadOnly || canNotEditMember(getAgencyGroup),
        // onClick: 'dialog',
        dialog: 'delete',
        snackbar: 'delete',
      },
    },
  };

  const model = (loadData) => ({
    header: {
      backButton: {
        onClick: () =>
          history.push(`/events/${eventId}/agency/${agencyId}/group-list`),
      },
      action: {
        goToWelcomePage: {
          icon: <ArrowForwardIcon />,
          variant: 'lightBlue',
          onClick: handleGoToWelcomePage,
        },
        goToFeeTable: {
          icon: <ArrowForwardIcon />,
          variant: 'lightBlue',
          onClick: handleGoToFeeTable,
        },
        add: {
          icon: <AddIcon />,
          variant: 'yellow',
          hiddenWhen: (checkedItems, { getAgencyGroup }) => {
            return canNotAddMember(checkedItems, getAgencyGroup);
          },
          onClick: handleAdd,
        },
        massiveImport: {
          icon: <PublishIcon />,
          variant: 'violet',
          hiddenWhen: (checkedItems, { getAgencyGroup }) => {
            return canNotAddMember(checkedItems, getAgencyGroup);
          },
          onClick: () =>
            setMassiveDialog({
              isOpen: true,
              deadlinePath: addDeadlinePath(),
            }),
        },
        // ...(!agencyGroup?.closingDate && {
        closePrepaidGroup: {
          icon: <ShoppingCartIcon />,
          variant: 'yellow',
          disabledWhen: (checkedItems, data, tData) => {
            // console.log('timeline', timeline);
            const noProfileFound = tData.findIndex((row) => row.noProfile);
            const isMinNumber =
              data?.getAgencyGroup?.participations?.items.length >=
              getDeadlinesParsed(data?.getDeadline?.value).minNumber;
            return (
              noProfileFound > -1 ||
              (timeline && !timeline.canCloseGroup) ||
              data?.getAgencyGroup?.closingDate ||
              notEnoughBudgetRef.current ||
              !isMinNumber
            );
          },
          hiddenWhen: (checkedItems, data, tData) => {
            // return data?.getAgencyGroup?.closingDate;
            return checkIsPostDeadline(data?.getDeadline?.value);
          },
          onClick: () => closePrepaidGroup(loadData),
        },
        // }),
        // ...(agencyGroup?.closingDate && {
        payApprovedChanges: {
          icon: <ShoppingCartIcon />,
          variant: 'yellow',
          disabledWhen: (checkedItems, data, tData) => {
            const noProfileFound = tData.findIndex((row) => row.noProfile);
            return (
              noProfileFound > -1 ||
              totalPenaltiesRef.current <= 0 ||
              canNotPayApprovedChanges()
            );
          },
          hiddenWhen: (checkedItems, data, tData) => {
            return !checkIsPostDeadline(data?.getDeadline?.value);
          },
          // onClick: () => setIsUseBudgetDialogOpen(true),
          onClick: () => handlePayAndClose(),
        },
        // }),
      },
    },
    preTableComponent: null,
    dataLoader,
    table,
    dialog: {
      delete: {
        onAgree: async (row) => {
          await handleDelete(row.id);
          await loadData();
        },
        onDisagree: () => {},
      },
      // deleteAll: {
      //   onAgree: async (checkedItems) => {
      //     await handleDeleteAll(checkedItems);
      //     await loadData();
      //   },
      //   onDisagree: () => {},
      // },
      // close: {
      //   onAgree: async () => {
      //     await handleCloseGroup();
      //     await loadData();
      //   },
      //   onDisagree: () => {},
      // },
    },
    snackbar: {
      delete: {},
    },
  });

  const handleGoToFeeTable = async () => {
    history.push(`/events/${eventId}/agency/${agencyId}/fee`);
  };

  const handleGoToWelcomePage = async () => {
    history.push(`/events/${eventId}/agency/${agencyId}`);
  };

  const isAfterDeadline = () => {
    if (!deadlinesRef.current?.deadline) return false;
    return isAfter(new Date(), new Date(deadlinesRef.current.deadline));
  };

  const isBetweenDeadlineAndFreezeDeadline = () => {
    if (
      !deadlinesRef.current?.deadline ||
      !deadlinesRef.current?.freezeDeadline
    )
      return false;
    return isWithinInterval(new Date(), {
      start: new Date(deadlinesRef.current.deadline),
      end: new Date(deadlinesRef.current.freezeDeadline),
    });
  };

  // const handleAdd = ({ isPostDeadline }) => {
  //   if (remainingPlacesRef.current > 0) {
  //     history.push(
  //       `/events/${eventId}/participation/${cluster}/${
  //         sponsorListRef.current.id
  //       }/new${isPostDeadline ? '/post-deadline' : ''}`
  //     );
  //     return;
  //   }
  // };

  // const handleEdit = ({ id, isPostDeadline }) => {
  //   history.push(
  //     `/events/${eventId}/participation/${cluster}/${
  //       sponsorListRef.current.id
  //     }/${id}${isPostDeadline ? '/post-deadline' : ''}`
  //   );
  // };

  // const handleCloseGroup = async () => {
  //   await closeListGroup(sponsorListRef.current.id);
  //   callLambdaConflictsOnCreate(eventId);
  // };

  // const handleDeleteAll = async (checkedItems) =>
  //   await Promise.all(
  //     checkedItems?.map(async (p) => await updateParticipationIsDeleted(p.id))
  //   );

  const addDeadlinePath = () => {
    return isPostDeadlineRef.current ? '/post-deadline' : '';
  };

  // const isDeadlinePassed = () => {
  //   return isAfter(Date.now(), new Date(deadlinesRef.current.deadline));
  // };

  const importBackUrl = `/events/${eventId}/agency/${agencyId}/services-configuration/list`;

  return (
    <div style={{ width: '90%' }}>
      {notEnoughBudgetRef.current && !closingDateRef.current && (
        <AimTypography variant="formError">
          {i18n.alert.notEnoughBudget}
        </AimTypography>
      )}
      <AimTablePage model={model} translation={i18n} intl={intl} />
      <MassiveImportDialog
        state={{
          remainingPlaces: remainingPlacesRef.current,
          backUrl: importBackUrl,
        }}
        eventId={eventId}
        cluster={cluster}
        clusterId={sponsorListRef.current?.id}
        massiveDialog={massiveDialog}
        setMassiveDialog={setMassiveDialog}
        intl={intl}
        history={history}
        disableEnforceFocus
        customPath={`/events/${eventId}/participation/${cluster}/${groupId}/import${
          isPostDeadlineRef.current ? '/post-deadline' : ''
        }`}
        customBackUrl={`/events/${eventId}/agency/${agencyId}/group-list/${groupId}/prepaid`}
      />

      <AgencyPrepaidBudgetDialog
        isDialogOpen={isUseBudgetDialogOpen}
        onAgree={(data) => closePrepaidGroup(data)}
        onDisagree={handleUseBudgetClose}
        i18n={i18n}
        prepaidBudget={agencyPrepaidBudgetRef.current}
        defChecked={true}
        agencyId={agencyId}
      />
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </div>
  );
};

export default AgencyPrepaidMembers;
