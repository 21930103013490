export const responseCodesTexts = [
  {
    // Transazione Autorizzata
    key: '000',
    label: (intl) =>
      intl.formatMessage({
        description: 'transaction Authorized label',
        defaultMessage: 'Transaction authorized',
      }),
  },
  {
    // Autorizzazione Negata (Generico)
    key: '100',
    label: (intl) =>
      intl.formatMessage({
        description: 'transaction denied label',
        defaultMessage: 'Transaction denied',
      }),
  },
  {
    // Carta Scaduta o Data Scadenza Invalida
    key: '101',
    label: (intl) =>
      intl.formatMessage({
        description: 'Card Expired or Invalid Expiry Date label',
        defaultMessage: 'Card Expired or Invalid Expiry Date',
      }),
  },
  {
    // Sospetta Frode
    key: '102',
    label: (intl) =>
      intl.formatMessage({
        description: 'suspected fraud label',
        defaultMessage: 'Suspected fraud',
      }),
  },
  {
    // Carta non Valida
    key: '104',
    label: (intl) =>
      intl.formatMessage({
        description: 'invalid card label',
        defaultMessage: 'Invalid card',
      }),
  },
  {
    // Numero Tentativi PIN Superato
    key: '106',
    label: (intl) =>
      intl.formatMessage({
        description: 'number of PIN attempts exceeded label',
        defaultMessage: 'Number of PIN attempts exceeded',
      }),
  },
  {
    // Merchant non Valido
    key: '109',
    label: (intl) =>
      intl.formatMessage({
        description: 'invalid merchant label',
        defaultMessage: 'Invalid merchant',
      }),
  },
  {
    // Importo non Valido
    key: '110',
    label: (intl) =>
      intl.formatMessage({
        description: 'invalid amount label',
        defaultMessage: 'Invalid amount',
      }),
  },
  {
    // Numero Carta non Valido
    key: '111',
    label: (intl) =>
      intl.formatMessage({
        description: 'invalid card number label',
        defaultMessage: 'Invalid card number',
      }),
  },
  {
    // Funzione Richiesta non Supportata
    key: '115',
    label: (intl) =>
      intl.formatMessage({
        description: 'requested feature not supported label',
        defaultMessage: 'Requested feature not supported',
      }),
  },
  {
    // Disponibilità Insufficiente
    key: '116',
    label: (intl) =>
      intl.formatMessage({
        description: 'not enough availability label',
        defaultMessage: 'Not enough availability',
      }),
  },
  {
    // Codice Segreto Errato
    key: '117',
    label: (intl) =>
      intl.formatMessage({
        description: 'wrong secret code label',
        defaultMessage: 'Wrong secret code',
      }),
  },
  {
    // Carta Inesistente
    key: '118',
    label: (intl) =>
      intl.formatMessage({
        description: 'card does not exist label',
        defaultMessage: 'Card does not exist',
      }),
  },
  {
    // Operazione non Permessa al Titolare Carta
    key: '119',
    label: (intl) =>
      intl.formatMessage({
        description: 'operation not permitted to the cardholder label',
        defaultMessage: 'Operation not permitted to the cardholder',
      }),
  },
  {
    // Operazione non Permessa al Terminale
    key: '120',
    label: (intl) =>
      intl.formatMessage({
        description: 'operation not allowed on the terminal label',
        defaultMessage: 'Operation not allowed on the terminal',
      }),
  },
  {
    // Limite Importo Superato
    key: '121',
    label: (intl) =>
      intl.formatMessage({
        description: 'amount limit exceeded label',
        defaultMessage: 'Amount limit exceeded',
      }),
  },
  {
    // Operazione non Permessa
    key: '122',
    label: (intl) =>
      intl.formatMessage({
        description: 'operation not permitted label',
        defaultMessage: 'Operation not permitted',
      }),
  },
  {
    // Numero Pagamenti Superato
    key: '123',
    label: (intl) =>
      intl.formatMessage({
        description: 'Number of payments exceeded label',
        defaultMessage: 'Number of payments exceeded',
      }),
  },
  {
    // Operazione non Permessa
    key: '124',
    label: (intl) =>
      intl.formatMessage({
        description: 'operation not permitted label',
        defaultMessage: 'Operation not permitted',
      }),
  },
  {
    // Carta Inattiva
    key: '125',
    label: (intl) =>
      intl.formatMessage({
        description: 'inactive card label',
        defaultMessage: 'Inactive card',
      }),
  },
  {
    // PIN Block Invalido
    key: '126',
    label: (intl) =>
      intl.formatMessage({
        description: 'invalid PIN block label',
        defaultMessage: 'Invalid PIN block',
      }),
  },
  {
    // Sospetta Carta Contraffatta
    key: '129',
    label: (intl) =>
      intl.formatMessage({
        description: 'suspect counterfeit card label',
        defaultMessage: 'Suspect counterfeit card',
      }),
  },
  {
    // Errore Wallet (Vedi tabella Codici Errori MonetaWallet per dettagli)
    key: '182',
    label: (intl) =>
      intl.formatMessage({
        description:
          'wallet error (see MonetaWallet error codes table for details) label',
        defaultMessage:
          'Wallet error (see MonetaWallet error codes table for details)',
      }),
  },
  {
    // Autorizzazione Negata
    key: '200',
    label: (intl) =>
      intl.formatMessage({
        description: 'Autorizzazione negata label',
        defaultMessage: 'Autorizzazione negata',
      }),
  },
  {
    // Sospetta Frode
    key: '202',
    label: (intl) =>
      intl.formatMessage({
        description: 'suspected fraud label',
        defaultMessage: 'Suspected fraud',
      }),
  },
  {
    // Carta Limitata
    key: '204',
    label: (intl) =>
      intl.formatMessage({
        description: 'limited card label',
        defaultMessage: 'Limited card',
      }),
  },
  {
    // Carta Smarrita
    key: '208',
    label: (intl) =>
      intl.formatMessage({
        description: 'lost card label',
        defaultMessage: 'Lost card',
      }),
  },
  {
    // Carta Rubata
    key: '209',
    label: (intl) =>
      intl.formatMessage({
        description: 'stoled card label',
        defaultMessage: 'Stoled card',
      }),
  },
  {
    // Sospetta Carta Contraffatta
    key: '210',
    label: (intl) =>
      intl.formatMessage({
        description: 'suspect counterfeit card label',
        defaultMessage: 'Suspect counterfeit card',
      }),
  },
  {
    // Pending
    key: '888',
    label: (intl) =>
      intl.formatMessage({
        description: 'pending label',
        defaultMessage: 'Pending',
      }),
  },
  {
    // Transazione Invalida
    key: '902',
    label: (intl) =>
      intl.formatMessage({
        description: 'invalid transaction label',
        defaultMessage: 'Invalid transaction',
      }),
  },
  {
    // Transazione Ripetuta
    key: '903',
    label: (intl) =>
      intl.formatMessage({
        description: 'repeated transaction label',
        defaultMessage: 'Repeated transaction',
      }),
  },
  {
    // Errore di Formato
    key: '904',
    label: (intl) =>
      intl.formatMessage({
        description: 'format error label',
        defaultMessage: 'Format error',
      }),
  },
  {
    // Cutover in Corso
    key: '906',
    label: (intl) =>
      intl.formatMessage({
        description: 'cutover in progress label',
        defaultMessage: 'Cutover in progress',
      }),
  },
  {
    // Malfunzionamento Emittente
    key: '907',
    label: (intl) =>
      intl.formatMessage({
        description: 'issuer malfunction label',
        defaultMessage: 'Issuer malfunction',
      }),
  },
  {
    // Routing non Disponibile
    key: '908',
    label: (intl) =>
      intl.formatMessage({
        description: 'routing not available label',
        defaultMessage: 'Routing not available',
      }),
  },
  {
    // Malfunzionamento di Sistema
    key: '909',
    label: (intl) =>
      intl.formatMessage({
        description: 'system malfunction label',
        defaultMessage: 'System malfunction',
      }),
  },
  {
    // Timeout Emittente
    key: '911',
    label: (intl) =>
      intl.formatMessage({
        description: 'issuer timeout label',
        defaultMessage: 'Issuer timeout',
      }),
  },
  {
    // Trasmissione Duplicata
    key: '913',
    label: (intl) =>
      intl.formatMessage({
        description: 'duplicate transmission label',
        defaultMessage: 'Duplicate Transmission',
      }),
  },
  {
    // Errore Creazione Contratto per Parametri Merchant
    key: '999',
    label: (intl) =>
      intl.formatMessage({
        description: 'contract creation error for merchant parameters label',
        defaultMessage: 'Contract creation error for merchant parameters',
      }),
  },
];
