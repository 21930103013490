import React from 'react';
import { AimTypography } from '@aim/components';
import ArrowBack from '@material-ui/icons/ArrowBack';

const SelectSlotWarning = ({ i18n }) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ArrowBack />
      <AimTypography variant="h3">
        {i18n.invitations.noBrSelected}
      </AimTypography>
    </div>
  );
};

export default SelectSlotWarning;
