import { aws, appState } from '@aim/common';
import { format } from 'date-fns';
import pdfIcon from '../../../images/pdfIcon.png';
import wordIcon from '../../../images/wordIcon.png';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

/**
 * downloadFromS3
 */

export const downloadFromS3 = async (file, subFolder, eventId, sponsorId) => {
  const link = await getFullBucketPath(eventId, sponsorId, subFolder, file);
  window.open(link);
};

/**
 * Parse richText string to JSON
 */

export const parseRichText = (str) => {
  if (str) {
    try {
      const jsonParsed = JSON.parse(str);
      return jsonParsed.blocks[0].text ? jsonParsed : null;
    } catch (e) {
      console.error(e);
    }
  }
  return null;
};

/**
 * Get file Thumbnails by extension
 */

export const getFileThumb = (ext) => {
  switch (ext) {
    case '.pdf':
      return pdfIcon;
    default:
      return wordIcon;
  }
};

/**
 * Fomrat Date
 */

export const getFormattedDate = (dateString, formatString = 'dd/MM/yyyy') => {
  const date = new Date(dateString);
  return format(date, formatString);
};

/**
 * Handling SponsorsContact
 */
export const getFullBucketPath = async (
  eventId,
  sponsorId,
  directory,
  file
) => {
  const s3FolderBase = `events/${eventId}/sponsor/frontoffice/${sponsorId}`;
  const handleDirectory = directory ? `${directory}/` : '';
  return await aws.Storage.get(
    `${s3FolderBase}/${handleDirectory}${file.id}${file.extension}`
  );
};

export const createSponsorContact = /* GraphQL */ `
  mutation CreateSponsorContact(
    $input: CreateSponsorContactInput!
    $condition: ModelSponsorContactConditionInput
  ) {
    createSponsorContact(input: $input, condition: $condition) {
      id
      name
      surname
      email
      phone
      address
      city
      country
      website
      facebook
      twitter
      linkedin
      instagram
    }
  }
`;

export const updateSponsorContact = /* GraphQL */ `
  mutation UpdateSponsorContact(
    $input: UpdateSponsorContactInput!
    $condition: ModelSponsorContactConditionInput
  ) {
    updateSponsorContact(input: $input, condition: $condition) {
      id
      name
      surname
      email
      phone
      address
      city
      country
      website
      facebook
      twitter
      linkedin
      createdAt
      updatedAt
      instagram
    }
  }
`;

export const deleteSponsorContact = /* GraphQL */ `
  mutation DeleteSponsorContact(
    $input: DeleteSponsorContactInput!
    $condition: ModelSponsorContactConditionInput
  ) {
    deleteSponsorContact(input: $input, condition: $condition) {
      id
    }
  }
`;

/**
 * End Handling SponsorsContact
 */

/**
 * Handling Sponsor ProfileImage, CoverImage, Documents
 */
export const createFile = /* GraphQL */ `
  mutation CreateFile(
    $input: CreateFileInput!
    $condition: ModelFileConditionInput
  ) {
    createFile(input: $input, condition: $condition) {
      id
    }
  }
`;

export const deleteFile = /* GraphQL */ `
  mutation DeleteFile(
    $input: DeleteFileInput!
    $condition: ModelFileConditionInput
  ) {
    deleteFile(input: $input, condition: $condition) {
      id
    }
  }
`;

/**
 * End Handling Sponsor ProfileImage, CoverImage, Documents
 */

export const updateSponsor = /* GraphQL */ `
  mutation UpdateSponsor(
    $input: UpdateSponsorInput!
    $condition: ModelSponsorConditionInput
  ) {
    updateSponsor(input: $input, condition: $condition) {
      id
    }
  }
`;

export const getSponsor = /* GraphQL */ `
  query GetSponsor($id: ID!) {
    getSponsor(id: $id) {
      id
      name
      description
      videoTitle
      videoSubtitle
      videoLink
      videoEmbeddedLink
      videoProvider
      sponsorVirtualStand {
        items {
          id
          virtualRoomLink
          virtualStand {
            id
            name
            ranking
            isEnabled
            specificationPost
          }
          sponsorPackageItem {
            package {
              buyOperations {
                items {
                  id
                  purchase {
                    id
                    paymentType
                    payment {
                      paymentIdMonetaOnLine
                      paymentIdIngenico
                      paymentIdGpWebpay
                    }
                  }
                }
              }
            }
          }
          buyOperation {
            id
            purchase {
              id
              paymentType
              payment {
                paymentIdIngenico
                paymentIdMonetaOnLine
              }
            }
          }
        }
      }
      publicPage {
        id
        isPublic
        sponsorPackageItem {
          package {
            buyOperations {
              items {
                id
                purchase {
                  id
                  paymentType
                  payment {
                    paymentIdMonetaOnLine
                    paymentIdIngenico
                    paymentIdGpWebpay
                  }
                }
              }
            }
          }
        }
        buyOperation {
          id
          purchase {
            id
            paymentType
            payment {
              paymentIdMonetaOnLine
              paymentIdIngenico
              paymentIdGpWebpay
            }
          }
        }
      }
      profileImage {
        id
        name: originalName
        size
        extension
        createdAt
        updatedAt
      }
      profileCover {
        id
        name: originalName
        size
        extension
        createdAt
        updatedAt
      }
      documents {
        items {
          id
          name: originalName
          size
          extension
          createdAt
          updatedAt
        }
      }
      contacts {
        items {
          id
          name
          surname
          email
          phone
          address
          city
          country
          website
          facebook
          twitter
          linkedin
          instagram
        }
      }
      videoCover {
        id
        name: originalName
        size
        extension
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export function useSponsorDetailHelper() {
  /**
   * Handling Sponsor CRUD
   */

  const get = (id) =>
    new Promise((resolve, reject) => {
      showLoader();

      API.graphql({ query: getSponsor, variables: { id } })
        .then((response) => {
          const publicPage = response.data.getSponsor?.publicPage;
          const nextSponsor = {
            ...response.data.getSponsor,
            publicPage: {
              ...publicPage,
              buyOperation: publicPage?.buyOperation
                ? publicPage.buyOperation
                : publicPage?.sponsorPackageItem.package.buyOperations
                    .items?.[0],
            },
            sponsorVirtualStand: {
              items: response.data.getSponsor?.sponsorVirtualStand?.items.map(
                (item) => ({
                  ...item,
                  buyOperation: item?.buyOperation
                    ? item.buyOperation
                    : item?.sponsorPackageItem?.package?.buyOperations
                        .items?.[0],
                })
              ),
            },
          };
          resolve(nextSponsor);
        })
        .catch((e) => {
          console.error('event-get-sponsor-detail', e);
          reject();
        })
        .finally(hideLoader);
    });

  const update = (input) =>
    new Promise((resolve, reject) => {
      API.graphql({ query: updateSponsor, variables: { input } })
        .then((response) => resolve(response.data.updateSponsor))
        .catch((e) => {
          console.error('update-sponsor', e);
          reject();
        });
    });

  /**
   * End Handling Sponsor CRUD
   */

  /**
   * Handling File CRUD
   */

  const createSponsorFile = (input, filePath, file) =>
    new Promise((resolve, reject) => {
      showLoader();

      API.graphql({ query: createFile, variables: { input } })
        .then(async (response) => {
          try {
            await aws.Storage.put(
              `${filePath}${response.data.createFile.id}${input.extension}`,
              file
            );
          } catch (e) {
            console.error('create-s3-file', e);
            reject();
          }
          resolve(response.data.createFile);
        })
        .catch((e) => {
          console.error('create-sponsor-file', e);
          reject();
        })
        .finally(hideLoader);
    });

  const removeFile = (id, filePath) =>
    new Promise((resolve, reject) => {
      showLoader();

      API.graphql({
        query: deleteFile,
        variables: { input: { id } },
      })
        .then(async (response) => {
          try {
            await aws.Storage.remove(`${filePath}`);
          } catch (e) {
            console.error('create-s3-file', e);
            reject();
          }
          resolve(response.data.deleteFile);
        })
        .catch((e) => {
          console.error('remove-sponsor-file', e);
          reject();
        })
        .finally(hideLoader);
    });

  /**
   * End Handling File CRUD
   */

  /**
   * Handling Contacts CRUD
   */

  const createContact = (input) =>
    new Promise((resolve, reject) => {
      showLoader();

      API.graphql({ query: createSponsorContact, variables: { input } })
        .then(async (response) => {
          resolve(response.data.createSponsorContact);
        })
        .catch((e) => {
          console.error('create-sponsor-contact', e);
          reject();
        })
        .finally(hideLoader);
    });

  const updateContact = (input) =>
    new Promise((resolve, reject) => {
      showLoader();

      API.graphql({ query: updateSponsorContact, variables: { input } })
        .then((response) => resolve(response.data.updateSponsorContact))
        .catch((e) => {
          console.error('update-sponsor-contact', e);
          reject();
        })
        .finally(hideLoader);
    });

  const removeContact = (id) =>
    new Promise((resolve, reject) => {
      showLoader();

      API.graphql({
        query: deleteSponsorContact,
        variables: { input: { id } },
      })
        .then((response) => resolve(response.data.deleteSponsorContact))
        .catch((e) => {
          console.error('remove-sponsor-contact', e);
          reject();
        })
        .finally(hideLoader);
    });

  /**
   * End Handling contacts CRUD
   */

  return {
    get,
    update,
    createContact,
    updateContact,
    removeContact,
    createSponsorFile,
    removeFile,
  };
}
