import React, { useState, useEffect, MouseEvent } from 'react';
import { HereNowResponse } from 'pubnub';
import pickerData from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { usePubNub } from 'pubnub-react';
import {
  ChannelEntity,
  ChannelEntityWithMembership,
  MemberList,
  MessageInput,
  TypingIndicator,
} from '@pubnub/react-chat-components';

import { CreateChatModal } from './components/create-chat-modal';
import { ReportUserModal } from './components/report-user-modal';
import { PublicChannelsModal } from './components/public-channels-modal';
import { pubNubTimeToken, isPresentMember } from './components/pubnub-utils';
import AimChannelList from './components/aim-channel-list';
import { MessageList } from './components/message-list';
import { AimMessageEnvelopeType } from './components/message-item';

type ChannelType = ChannelEntity;

interface UnreadMessages {
  [key: string]: number;
}

const defaultChannel = {
  id: 'default',
  name: 'No Active Chat',
  description: 'This is the default channel',
} as Pick<ChannelType, 'id' | 'name' | 'description'>;

const GroupChat = ({
  eventId,
  users,
  user,
  availableChannels,
  switchChannel,
  joinedChannels,
  channelMembers,
  totalChannelMembers,
  presenceData,
  unreadMessages,
  currentChannel,
  setCurrentChannel,
  showChannels,
  setShowChannels,
}: {
  eventId: string;
  users: any;
  user: any;
  availableChannels: ChannelType[];
  switchChannel: (
    newChannel: Pick<ChannelEntity, 'id' | 'name' | 'description'>,
    oldChannel: Pick<ChannelEntity, 'id' | 'name' | 'description'>
  ) => void;
  joinedChannels: ChannelEntityWithMembership[];
  channelMembers: ChannelEntityWithMembership[];
  totalChannelMembers: number;
  presenceData: HereNowResponse['channels'];
  unreadMessages: UnreadMessages;
  currentChannel: Pick<ChannelEntity, 'id' | 'name' | 'description'>;
  setCurrentChannel: (
    channel: Pick<ChannelEntity, 'id' | 'name' | 'description'>
  ) => void;
  showChannels: boolean;
  setShowChannels: (value: boolean) => void;
}): JSX.Element => {
  /**
   * Component state related hooks
   * Those mostly store the current channel, modals and side panels being shown
   */
  const [theme] = useState<'light'>('light');
  const [showMembers, setShowMembers] = useState(false);
  const [showPublicChannelsModal, setShowPublicChannelsModal] = useState(false);
  const [showCreateChatModal, setShowCreateChatModal] = useState(false);
  const [showReportUserModal, setShowReportUserModal] = useState(false);
  const [channelsFilter, setChannelsFilter] = useState('');
  const [membersFilter, setMembersFilter] = useState('');
  const [
    reportedMessage,
    setReportedMessage,
  ] = useState<AimMessageEnvelopeType>();
  const [presenceChannel] = useState<string>(`presence__${eventId}`);

  /**
   * All data related to Users, Channels and Memberships is stored within PubNub Objects API
   * It can be easily accessed using React Chat Components hooks
   */
  const pubnub = usePubNub();
  const [currentUser] = useState(user); //useUser({ uuid: user.id });
  // const [allUsers] = useUsers({ include: { customFields: true } });
  // const [allChannels] = useChannels({
  //   include: { customFields: true },
  // });

  /**
   * Some of the data related to current channel, current user and its' joined channels
   * has to be filtered down and mapped from the hooks data
   */
  const channelPresentUUIDs = presenceData[currentChannel.id]?.occupants?.map(
    (o) => o.uuid
  );
  const onlineUUIDs = presenceData[presenceChannel]?.occupants?.map(
    (o) => o.uuid
  );
  const groupChannels = joinedChannels.filter(
    (c) =>
      c.id?.startsWith('space.') &&
      (!channelsFilter ||
        c.name?.toLowerCase().includes(channelsFilter.toLowerCase()))
  );
  const groupChannelsToJoin = availableChannels.filter(
    (c) =>
      c.id.startsWith('space.') && !joinedChannels.some((b) => c.id === b.id)
  );
  const directChannels = joinedChannels
    .filter((c) => c.id?.startsWith('direct.') || c.id?.startsWith('group.'))
    .map((c) => {
      if (!c.id?.startsWith('direct.')) return c;
      const interlocutorId = c.id
        .replace(user.id, '')
        .replace('direct.', '')
        .replace('@', '');
      const interlocutor = users.find((u: any) => u.id === interlocutorId);
      if (interlocutor) {
        c.custom = { profileUrl: interlocutor.profileUrl || '' };
        c.name = interlocutor.name;
      }
      return c;
    })
    .filter((c) =>
      c.name?.toLowerCase().includes(channelsFilter.toLowerCase())
    );

  const isUserBanned = currentUser?.custom?.ban;
  const isUserMuted = (currentUser?.custom?.mutedChannels as string)
    ?.split(',')
    .includes(currentChannel.id);
  const isUserBlocked = (currentUser?.custom?.blockedChannels as string)
    ?.split(',')
    .includes(currentChannel.id);

  useEffect(() => {
    return () => {
      if (currentChannel.id !== defaultChannel.id) {
        pubnub.objects.setMemberships({
          channels: [
            {
              id: currentChannel.id,
              custom: {
                lastReadTimetoken: pubNubTimeToken(),
              },
            },
          ],
        });
      }
    };
  }, []);

  /**
   * Creating and removing channel memberships (not subscriptions!)
   */
  const leaveChannel = async (channel: ChannelType, event: MouseEvent) => {
    event.stopPropagation();
    await pubnub.objects.removeChannelMembers({
      channel: channel.id,
      uuids: [user.id],
    });
    await pubnub.objects.removeMemberships({ channels: [channel.id] });
    // setAnotherCurrentChannel(channel.id);
    setCurrentChannel(defaultChannel);
  };

  return (
    <>
      {showPublicChannelsModal && (
        <PublicChannelsModal
          {...{
            groupChannelsToJoin,
            hideModal: () => setShowPublicChannelsModal(false),
            setCurrentChannel,
          }}
        />
      )}
      {showCreateChatModal && (
        <CreateChatModal
          {...{
            currentUser,
            hideModal: () => setShowCreateChatModal(false),
            setCurrentChannel,
            users: users.filter((u: any) => u.id !== user.id),
            onlineUUIDs,
          }}
        />
      )}
      {showReportUserModal && (
        <ReportUserModal
          {...{
            currentUser,
            reportedMessage,
            hideModal: () => setShowReportUserModal(false),
            users: users,
          }}
        />
      )}
      {isUserBanned ? (
        <strong className="error">
          Unfortunately, you were banned from the chat.
        </strong>
      ) : (
        <>
          <div className={`channels-panel ${showChannels && 'shown'}`}>
            <div className="user-info">
              {currentUser && (
                <MemberList
                  members={[currentUser]}
                  selfText=""
                  presentMembers={onlineUUIDs}
                />
              )}
              <button
                className="mobile material-icons-outlined"
                onClick={() => setShowChannels(false)}
              >
                close
              </button>
            </div>

            <div className="filter-input">
              <input
                onChange={(e) => setChannelsFilter(e.target.value)}
                placeholder="Search in..."
                type="text"
                value={channelsFilter}
              />
              <i className="material-icons-outlined">search</i>
            </div>

            <div className="channel-lists">
              <h2>
                Channels
                <button
                  className="material-icons-outlined"
                  onClick={() => {
                    setShowPublicChannelsModal(true);
                    // actionCompleted({
                    //   action: "Select '+' to Add a New Channel",
                    // });
                  }}
                >
                  add_circle_outline
                </button>
              </h2>
              <div>
                <AimChannelList
                  {...{
                    channels: groupChannels
                      .map(
                        (ch) =>
                          availableChannels.find(
                            (c) => c.id === ch.id
                          ) as ChannelEntityWithMembership
                      )
                      // to prevent case of undefined channel when available is still loading
                      .filter((c) => c),
                    users,
                    currentUser,
                    currentChannel,
                    onlineUUIDs,
                    switchChannel,
                    isPresentMember,
                    unreadMessages,
                    leaveChannel,
                  }}
                />
                {/* <ChannelList
                  channels={groupChannels}
                  onChannelSwitched={(channel) => {
                    setCurrentChannel(channel);
                    //   actionCompleted({ action: 'Switch to a New Channel' });
                  }}
                  extraActionsRenderer={(c) => (
                    <div
                      onClick={(e) => {
                        leaveChannel(c, e);
                        //   actionCompleted({ action: 'Leave a Channel' });
                      }}
                      title="Leave channel"
                    >
                      <i className="material-icons-outlined small">logout</i>
                    </div>
                  )}
                /> */}
              </div>
              <h2>
                1:1 / Group chats
                <button
                  className="material-icons-outlined"
                  onClick={() => {
                    setShowCreateChatModal(true);
                    //   actionCompleted({
                    //     action: "Scroll to & Select '+' to Add a 1:1 Chat",
                    //   });
                  }}
                >
                  add_circle_outline
                </button>
              </h2>
              <div>
                <AimChannelList
                  {...{
                    channels: directChannels,
                    users,
                    currentUser,
                    currentChannel,
                    onlineUUIDs,
                    switchChannel,
                    isPresentMember,
                    leaveChannel,
                    unreadMessages,
                  }}
                />
              </div>
            </div>
          </div>

          <div className="chat-window">
            <div className="channel-info">
              <button
                className="mobile material-icons-outlined"
                onClick={() => setShowChannels(true)}
              >
                menu
              </button>
              <span
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  cursor:
                    currentChannel.id !== defaultChannel.id
                      ? 'pointer'
                      : 'default',
                }}
                onClick={() =>
                  currentChannel.id !== defaultChannel.id &&
                  setShowMembers(!showMembers)
                }
              >
                <strong>
                  {currentChannel.name || currentChannel.id}
                  {!isUserBlocked && (
                    <i
                      style={{
                        display:
                          currentChannel.id !== defaultChannel.id
                            ? undefined
                            : 'none',
                      }}
                      className="material-icons-outlined"
                    >
                      arrow_right
                    </i>
                  )}
                </strong>
                <p
                  style={{
                    visibility:
                      currentChannel.id === defaultChannel.id ||
                      !totalChannelMembers
                        ? 'hidden'
                        : undefined,
                  }}
                >
                  {totalChannelMembers === 1
                    ? `1 member`
                    : `${totalChannelMembers} members`}
                </p>
              </span>
              <hr />
            </div>

            <MessageList
              fetchMessages={5}
              enableReactions={!isUserMuted}
              reactionsPicker={
                // undefined
                isUserMuted ? undefined : (
                  <Picker data={pickerData} theme={theme} />
                )
              }
              extraActionsRenderer={(envelope: AimMessageEnvelopeType) => (
                <>
                  {envelope.messageType === 4 ? (
                    <div
                      onClick={() => {
                        const link = document.createElement('a');
                        link.href = (envelope.message.file as any).url;
                        link.download = (envelope.message.file as any).name;
                        link.click();
                        link.remove();
                      }}
                      title="Download file"
                    >
                      <i className="material-icons-outlined">file_download</i>
                    </div>
                  ) : (
                    <></>
                  )}
                  {/* {isUserMuted ? (
                    <></>
                  ) : (
                    <div
                      onClick={() => {
                        setReportedMessage(envelope);
                        setShowReportUserModal(true);
                      }}
                      title="Report user"
                    >
                      <i className="material-icons-outlined">campaign</i>
                    </div>
                  )} */}
                </>
              )}
            />
            <TypingIndicator />
            <hr />
            <MessageInput
              disabled={currentChannel.id === defaultChannel.id || isUserMuted}
              senderInfo
              typingIndicator
              fileUpload="all"
              emojiPicker={<Picker data={pickerData} theme={theme} />}
              placeholder={
                isUserMuted
                  ? 'You were muted from this channel'
                  : 'Send message'
              }
              onSend={async () => {
                await pubnub.objects.setMemberships({
                  channels: [
                    {
                      id: currentChannel.id,
                      custom: {
                        lastReadTimetoken: pubNubTimeToken(),
                      },
                    },
                  ],
                });
                //   actionCompleted({
                //     action: containsEmoji({
                //       testString: (message as MessagePayload).text,
                //     })
                //       ? 'Send a Message with an Emoji'
                //       : 'Send a Chat Message',
                //   });
              }}
            />
          </div>

          <div
            className={`members-panel ${
              showMembers && !isUserBlocked ? 'shown' : 'hidden'
            }`}
          >
            <h2>
              Members
              <button
                className="material-icons-outlined"
                onClick={() => setShowMembers(false)}
              >
                close
              </button>
            </h2>
            <div className="filter-input">
              <input
                onChange={(e) => setMembersFilter(e.target.value)}
                placeholder="Search in members"
                type="text"
                value={membersFilter}
              />
              <i className="material-icons-outlined">search</i>
            </div>
            <MemberList
              members={channelMembers
                .map((m) => users.find((u: any) => u.id === m.id))
                .filter(
                  (c) =>
                    c &&
                    c.nameToSearch
                      ?.toLowerCase()
                      .includes(membersFilter.toLowerCase())
                )}
              presentMembers={channelPresentUUIDs}
            />
          </div>
        </>
      )}
    </>
  );
};

export default GroupChat;
