import { aws, appState } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const getEventSponsorsQuery = /* GraphQL */ `
  query getEventConfiguration($id: ID!) {
    getEventConfiguration(id: $id) {
      id
      homepageSponsorBannerArea {
        items {
          id
          name
          sponsorAreas {
            items {
              sponsor {
                id
                name
                therapeuticArea
                priceRange {
                  id
                  price
                  name
                }
                profileImage {
                  id
                  originalName
                  size
                  extension
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const getSponsors = (configurationId, isLoader = true) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();
    API.graphql({
      query: getEventSponsorsQuery,
      variables: { id: configurationId },
    })
      .then((response) =>
        resolve(
          response.data.getEventConfiguration.homepageSponsorBannerArea.items
        )
      )
      .catch((e) => {
        console.error('get-event-sponsors', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });
