import { aws, appState } from '@aim/common';
import { format } from 'date-fns';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const getEventNightsDatesQuery = /* GraphQL */ `
  query getEventNightsDates($eventId: ID!) {
    event: getEvent(id: $eventId) {
      id
      dates: allotmentDateRange {
        start
        end
      }
    }
  }
`;

const getEventGatewaysQuery = /* GraphQL */ `
  query GetEvent($id: ID!, $serviceType: String) {
    getEvent(id: $id) {
      id
      gateways(filter: { service: { eq: $serviceType } }) {
        items {
          id
          service
          paymentType
          iban
          ibanCausal
          addressedTo
          shop {
            id
            gatewayType
          }
        }
      }
    }
  }
`;

const createBillingInformationMutation = /* GraphQL */ `
  mutation CreateBillingInformation(
    $input: CreateBillingInformationInput!
    $condition: ModelBillingInformationConditionInput
  ) {
    createBillingInformation(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateBillingInformationMutation = /* GraphQL */ `
  mutation UpdateBillingInformation(
    $input: UpdateBillingInformationInput!
    $condition: ModelBillingInformationConditionInput
  ) {
    updateBillingInformation(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateParticipationMutation = /* GraphQL */ `
  mutation UpdateParticipation(
    $input: UpdateParticipationInput!
    $condition: ModelParticipationConditionInput
  ) {
    updateParticipation(input: $input, condition: $condition) {
      id
    }
  }
`;

const getParticipationBillingInformationQuery = /* GraphQL */ `
  query GetParticipationBillingInformation($id: ID!) {
    getParticipation(id: $id) {
      billingInformation {
        id
        invoiceTo
        name
        surname
        address
        city
        postalCode
        region
        province
        country
        countryIsoCode
        vatCode
        taxCode
        reference
        email
        pec
        phone
        invoiceToPublicAuthority
        uniqueCode
        ipaCode
        isSplitPayment
        isVatEvent
      }
    }
  }
`;

// export const getHotelEventVentureQuery = /* GraphQL */ `
//   query GetHotelEventVenture($id: ID!) {
//     getHotelEventVenture(id: $id) {
//       id
//       eventId
//       event {
//         name
//       }
//       hotel {
//         id
//         name
//       }
//       hotelRoomByDate {
//         items {
//           id
//           date
//           room {
//             id
//             name
//             frontofficeName
//           }
//           room {
//             id
//             maxOccupancy
//             name
//             frontofficeName
//           }
//           roomAvailabilities {
//             items {
//               id
//               roomsBusy
//               hotelRoomPrices {
//                 items {
//                   id
//                   date
//                   price
//                   occupancy
//                 }
//               }
//             }
//           }
//         }
//       }
//       rooms {
//         items {
//           id
//           name
//           frontofficeName
//         }
//       }
//     }
//   }
// `;

// schema
// event 1 -> n hotels: hotelEventVentures n -> 1 hotel
// one event -> more hotel event ventures -> one hotel
export const getHotelEventVentureByEventQuery = /* GraphQL */ `
  query getEventVenture {
    getEvent(id: "5abed27d-bc3c-43bc-a37a-3ea12b626db0") {
      hotels {
        items {
          id
          hotel {
            id
            name
          }
          hotelRoomByDate {
            items {
              id
              date
              availableRoomCount
              room {
                id
                name
                frontofficeName
              }
              room {
                id
                maxOccupancy
                name
                frontofficeName
              }
              roomAvailabilities {
                items {
                  id
                  roomsBusy
                  channelType
                  channelId
                  hotelRoomPrices {
                    items {
                      id
                      date
                      price
                      occupancy
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const listHotelReservationsQuery = /* GraphQL */ `
  query listHotelReservations {
    listHotelReservations(filter: { status: { eq: "DONE" } }) {
      items {
        id
        status
        isDeleted
        start
        end
        hotelRoomReservation {
          items {
            id
            startDate
            endDate
            depositAmount
            availability
            hotelRoom {
              id
              name
              frontofficeName
              maxOccupancy
            }
          }
        }
      }
    }
  }
`;

export const getHotelReservationsByEventQuery = /* GraphQL */ `
  query HotelReservationByHotelReservationEventIdByIsDeletedByStatus(
    $eventId: ID
    $isDeleted: String
    $status: String
  ) {
    hotelReservationByHotelReservationEventIdByIsDeletedByStatus(
      hotelReservationEventId: $eventId
      isDeletedStatus: { eq: { isDeleted: $isDeleted, status: $status } }
    ) {
      items {
        id
        status
        hotelRoomReservation {
          items {
            id
            availability
          }
        }
      }
    }
  }
`;

// export const listEventHotels = (
//   eventId,
//   language,
//   formData,
//   useLoader = true
// ) =>
//   new Promise((resolve, reject) => {
//     useLoader && showLoader();

//     API.graphql({
//       query: listHotelsByEventIdQuery,
//       variables: { eventId, language },
//     })
//       .then((response) => {
//         const hotels = response?.data?.hotels;
//         const filteredHotels = hotels?.items?.filter((item) => {
//           const hotel = item?.hotel;
//           const roomByDate = item?.roomByDate;
//           const roomsChecked = [];
//           if (roomByDate?.items?.length > 0) {
//             roomByDate?.items?.map((room) => {
//               const roomDate = new Date(room?.date);
//               const checkIn = new Date(formData.checkIn);
//               const checkOut = new Date(formData.checkOut);
//               const dateValidation =
//                 (isAfter(roomDate, checkIn) || isSameDay(roomDate, checkIn)) &&
//                 (isBefore(roomDate, checkOut) || isSameDay(roomDate, checkOut));
//               // TODO: per validare la room correttamente controllare n° persone
//               if (dateValidation) roomsChecked.push(room);
//             });
//             // TODO: inserire controllo per le roomAvailability (quando ci saranno i dati)
//           }
//           if (
//             roomsChecked.length > 0 &&
//             roomsChecked.length >= formData.roomsNumber
//           )
//             return hotel;
//         });
//         resolve(filteredHotels);
//         // resolve(hotels.items);
//       })
//       .catch((e) => {
//         console.error('get event hotels list', e);
//         reject();
//       })
//       .finally(() => useLoader && hideLoader());
//   });

export const getEventNightsDate = (eventId) =>
  new Promise((resolve, reject) => {
    showLoader();
    aws.API.graphql({
      query: getEventNightsDatesQuery,
      variables: { eventId },
    })
      .then((response) => {
        const {
          data: {
            event: { dates },
          },
        } = response;
        const startDate = format(new Date(dates.start), 'yyyy-MM-dd');
        const endDate = format(new Date(dates.end), 'yyyy-MM-dd');
        resolve({
          startDate,
          endDate,
        });
      })
      .catch((e) => {
        console.error('event-nights-dates', e);
        reject();
      })
      .finally(hideLoader);
  });

export const getEventGateway = (input) =>
  new Promise((resolve, reject) => {
    showLoader();
    aws.API.graphql({
      query: getEventGatewaysQuery,
      variables: { ...input },
    })
      .then((response) => {
        const { data } = response;
        resolve(data.getEvent.gateways.items[0]);
      })
      .catch((e) => {
        console.error('event-gateways', e);
        reject();
      })
      .finally(hideLoader);
  });

export const getBillingInformations = (participationId, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    API.graphql({
      query: getParticipationBillingInformationQuery,
      variables: { id: participationId },
    })
      .then((response) =>
        resolve(response.data.getParticipation?.billingInformation)
      )
      .catch((e) => {
        console.error('get-participation-billing-information', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const createBillingInformation = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    API.graphql({
      query: createBillingInformationMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.createBillingInformation.id))
      .catch((e) => {
        console.error('create-billing-information', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const updateBillingInformation = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    API.graphql({
      query: updateBillingInformationMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.updateBillingInformation.id))
      .catch((e) => {
        console.error('update-billing-information', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const updateParticipation = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    API.graphql({
      query: updateParticipationMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.updateParticipation))
      .catch((e) => {
        console.error('update-participation', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const getHotelEventVentureRoomsAvailabilityByEvent = (
  eventId,
  useLoader = true
) =>
  new Promise((resolve, reject) => {
    API.graphql({
      query: getHotelEventVentureByEventQuery,
      variables: { id: eventId },
    })
      .then((response) => {
        console.log('response ', response);
        // get hotel event ventures
        resolve(response.data.getEvent.hotels.items);
      })
      .catch((e) => {
        console.error('get-room-availability-by-event-venture', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const getHotelReservationsRoomsAvailabilityByEvent = (
  eventId,
  useLoader = true
) =>
  new Promise((resolve, reject) => {
    API.graphql({
      query: getHotelReservationsByEventQuery, // listHotelReservationsQuery,
      variables: { eventId, isDeleted: 'false', status: 'DONE' },
    })
      .then((response) => {
        console.log('response ', response);
        resolve(
          response.data
            .hotelReservationByHotelReservationEventIdByIsDeletedByStatus.items
        );
      })
      .catch((e) => {
        console.error('list-room-availability-by-hotel-reservations', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });
