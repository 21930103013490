import { aws, appState, constants } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const getAgencySpecificationsDataQuery = /* GraphQL */ `
  query GetAgencySpecificationsData($id: ID!, $agencyId: ID!, $type: String!) {
    getAgency(id: $agencyId) {
      id
      name
      prepaidBudget
      event {
        fee {
          feeBracketModels {
            items {
              id
              name
              start
              end
            }
          }
        }
      }
      agencyGroups {
        items {
          id
          name
          closingDate
          isPrepaid
        }
      }
    }
    getDeadline(externalId: $id, type: $type) {
      value
      externalId
    }
  }
`;

const getAgencyQuery = /* GraphQL */ `
  query getAgency($id: ID!) {
    getAgency(id: $id) {
      id
      name
      prepaidBudget
    }
  }
`;

const updateAgencyServiceMutation = /* GraphQL */ `
  mutation UpdateSponsorListService(
    $input: UpdateSponsorListServiceInput!
    $condition: ModelSponsorListServiceConditionInput
  ) {
    updateSponsorListService(input: $input, condition: $condition) {
      id
    }
  }
`;

const createAgencyPurchaseMutation = /* GraphQL */ `
  mutation CreateAgencyPurchase(
    $input: CreateAgencyPurchaseInput!
    $condition: ModelAgencyPurchaseConditionInput
  ) {
    createAgencyPurchase(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateAgencyPurchaseMutation = /* GraphQL */ `
  mutation CreateAgencyPurchase(
    $input: UpdateAgencyPurchaseInput!
    $condition: ModelAgencyPurchaseConditionInput
  ) {
    updateAgencyPurchase(input: $input, condition: $condition) {
      id
    }
  }
`;

const getEventGatewaysQuery = /* GraphQL */ `
  query GetEvent($id: ID!, $serviceId: String) {
    getEvent(id: $id) {
      id
      gateways(filter: { service: { eq: $serviceId } }) {
        items {
          id
          service
          paymentType
          shop {
            id
            gatewayType
            iban
          }
        }
      }
    }
  }
`;

const getEventDataQuery = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      type
      profiles (filter: {cluster: {eq: "${constants.Clusters.Groups.id}"}, isDeleted: {ne: true}}) {
        items {
          id
          name
          profileFeeBrackets {
            items {
              id
              priceOnAir
              priceOnSite
              profileFeeConfiguration {
                id
                isActive
              }
              feeBracket {
                id
                createdAt
                feeBracketModel {
                  id
                  end
                  name
                  start
                }
                feeDateRange {
                  id
                  start
                  end
                  label
                }
              }
            }
          }
        }
      }
      fee {
        feeDateRanges {
          items {
            id
            start
            end
            label
          }
        }
      }
    }
  }
`;

const updateAgencyMutation = `
  mutation UpdateAgency($input: UpdateAgencyInput!, $condition: ModelAgencyConditionInput) {
    updateAgency(input: $input, condition: $condition) {
      id
      prepaidBudget
    }
  }
`;

const getPaymentQuery = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) {
      id
      paymentType
      amount
      paymentId
    }
  }
`;

const getAgencyGroupsQuery = /* GraphQL */ `
  query GetAgency($id: ID!) {
    getAgency(id: $id) {
      agencyGroups {
        items {
          id
          name
          isPrepaid
          closingDate
        }
      }
    }
  }
`;

const getGroupParticipationsQuery = /* GraphQL */ `
  query GetAgencyGroup($id: ID!) {
    getAgencyGroup(id: $id) {
      id
      name
      isPrepaid
      closingDate
      participations(filter: { isDeleted: { ne: true } }) {
        items {
          id
          createdAt
          profile {
            id
            name
            profileFeeBrackets {
              items {
                id
                priceOnAir
                priceOnSite
                feeBracket {
                  id
                  feeDateRange {
                    id
                    start
                    end
                  }
                  feeBracketModel {
                    id
                    start
                    end
                  }
                }
              }
            }
          }
          feeDateRange {
            id
            start
            end
          }
        }
      }
    }
  }
`;

export const agencyGqlHelper = () => {
  const getAgencySpecifications = (input, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: getAgencySpecificationsDataQuery,
        variables: { ...input, type: constants.Clusters.Agency.id },
      })
        .then((response) => {
          return resolve({
            ...response.data.getAgency,
            id: response.data.getAgency.id,
            firstDeadline:
              response.data.getAgency?.event?.fee?.feeBracketModels?.items[0]
                .end,
            deadlines: response.data?.getDeadline
              ? JSON.parse(response.data.getDeadline.value)
              : {},
            closingDatePrepaidGroup: response.data.getAgency?.agencyGroups.items.find(
              (g) => g.isPrepaid
            )?.closingDate,
          });
        })
        .catch((e) => {
          console.error('event-agency-purchase-specifications', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const updateAgency = (input, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: updateAgencyMutation,
        variables: { input },
      })
        .then((response) => resolve(response.data.updateAgency))
        .catch((e) => {
          console.error('update-agency', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const getAgency = (id, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: getAgencyQuery,
        variables: { id },
      })
        .then((response) => resolve(response.data.getAgency))
        .catch((e) => {
          console.error('get-agency', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const createAgencyPurchase = (input, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: createAgencyPurchaseMutation,
        variables: { input },
      })
        .then((response) => resolve(response.data.createSponsorListPurchase))
        .catch((e) => {
          console.error('create-agency-purchase', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const updateAgencyPurchase = (input, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: updateAgencyPurchaseMutation,
        variables: { input },
      })
        .then((response) => resolve(response.data.updateSponsorListPurchase))
        .catch((e) => {
          console.error('update-agency-purchase', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const getPayment = (id, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: getPaymentQuery,
        variables: { id },
      })
        .then((response) => resolve(response.data.getPayment))
        .catch((e) => {
          console.error('get-payment', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const getAgencyGroups = (id, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: getAgencyGroupsQuery,
        variables: { id },
      })
        .then((response) =>
          resolve(response.data.getAgency.agencyGroups?.items)
        )
        .catch((e) => {
          console.error('get-agency-gropus', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const updateAgencyService = (input, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: updateAgencyServiceMutation,
        variables: { input },
      })
        .then((response) => resolve(response.data.updateSponsorList))
        .catch((e) => {
          console.error('update-agency-service', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const getEventData = (id) =>
    new Promise((resolve, reject) => {
      showLoader();
      aws.API.graphql({
        query: getEventDataQuery,
        variables: { id },
      })
        .then((response) => {
          const { data } = response;
          resolve({
            type: data.getEvent.type,
            profiles: data.getEvent.profiles.items,
            feeDateRanges: data.getEvent.fee.feeDateRanges.items,
          });
        })
        .catch((e) => {
          console.error('event-profiles', e);
          reject();
        })
        .finally(hideLoader);
    });

  const getEventGateway = (input) =>
    new Promise((resolve, reject) => {
      showLoader();
      aws.API.graphql({
        query: getEventGatewaysQuery,
        variables: { ...input },
      })
        .then((response) => {
          const { data } = response;
          resolve(data.getEvent.gateways.items[0]);
        })
        .catch((e) => {
          console.error('event-gateways', e);
          reject();
        })
        .finally(hideLoader);
    });

  const getGroupParticipations = (id) =>
    new Promise((resolve, reject) => {
      showLoader();
      aws.API.graphql({
        query: getGroupParticipationsQuery,
        variables: { id },
      })
        .then((response) => {
          const { data } = response;
          resolve(data.getAgencyGroup.participations?.items);
        })
        .catch((e) => {
          console.error('agency-group-participations', e);
          reject();
        })
        .finally(hideLoader);
    });

  return {
    getAgencySpecifications,
    getAgency,
    updateAgency,
    createAgencyPurchase,
    updateAgencyPurchase,
    getPayment,
    updateAgencyService,
    getAgencyGroups,
    getEventData,
    getEventGateway,
    getGroupParticipations,
  };
};
