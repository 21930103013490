import React from 'react';
// import { ImageLogo, SponsorBox } from './style';
import { AimTypography } from '@aim/components';

const Sponsor = ({
  titleVariant = 'h4',
  name,
  img,
  isMobile,
  size = 240,
  ...rest
}) => {
  const { innerWidth: pageWidth } = window;
  const imageWidth = pageWidth - 50;

  return (
    <div
      {...rest}
      style={
        img
          ? {
              backgroundImage: `url(${img})`,
              width: isMobile ? imageWidth : size,
              height: isMobile ? imageWidth : size,
              backgroundSize: 'cover',
            }
          : {
              background: 'grey',
              width: isMobile ? imageWidth : size,
              height: isMobile ? imageWidth : size,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }
      }
    >
      {!img && (
        <AimTypography
          variant={titleVariant}
          margin={0}
          boxStyle={{ color: 'white' }}
        >
          {name}
        </AimTypography>
      )}
    </div>
  );
};

export default Sponsor;
