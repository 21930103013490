import { aws, appState } from '@aim/common';
const { API } = aws;
const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

const getParticipationsGrantQuery = /* GraphQL */ `
  query GetParticipation($id: ID!) {
    getParticipation(id: $id) {
      id
      participationGrant(filter: { isDeleted: { ne: true } }) {
        items {
          grant {
            id
            instruction
            isDeleted
            title
            type
            closingDate
            createdAt
            allotment
            ticket
            travel
            additionalServices
          }
          publicationDate
          requestStatus
          ticket
          additionalServices
          allotment
          travel
          bankTransfer
        }
      }
    }
  }
`;

export const getParticipationGrants = (id, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    API.graphql({
      query: getParticipationsGrantQuery,
      variables: {
        id,
      },
    })
      .then((response) =>
        resolve(response.data?.getParticipation?.participationGrant?.items)
      )
      .catch((e) => {
        console.error('get-participation-grants', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });
