import React from 'react';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import { isMobileOnly } from 'react-device-detect';

import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import xIcon from '../../../../assets/images/X.svg';

import { AimTypography, richTextToHTML, AimIconButton } from '@aim/components';
import { aws, fileHelper } from '@aim/common';
import EventMapSvg from '../Map/EventMapSvg';

const { getAwsS3Link } = fileHelper;

const SectionContainerGray = styled.div`
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: '#f8f6fa';
  @media (max-width: 600px) {
    min-height: 40vh;
    align-items: center;
    margin: 0;
  }
`;

let socialsLogosAndNames = [
  {
    id: 0,
    name: 'Facebook',
    logo: <FacebookIcon fontSize="small" />,
  },
  {
    id: 1,
    name: 'Linkedin',
    logo: <LinkedInIcon fontSize="small" />,
  },
  {
    id: 2,
    name: 'Instagram',
    logo: <InstagramIcon fontSize="small" />,
  },
  {
    id: 0,
    name: 'Twitter',
    logo: <img src={xIcon} style={{ height: 15 }} />,
  },
];

const center = {
  justifyContent: 'center',
  display: 'flex',
};

const GridContainerStyle = {
  justifyContent: 'center',
  display: 'flex',
  marginTop: 20,
  marginBottom: 20,
};

const PreFooter = ({ event }) => {
  console.log('event', event);
  const {
    homepagePrefooterSocialFacebookLink,
    homepagePrefooterSocialLinkedinLink,
    homepagePrefooterSocialInstagramLink,
    homepagePrefooterSocialTwitterLink,
    homepagePrefooterMapMobileModel,
    homepagePrefooterMapDesktopModel,
    homepagePrefooterContacts = '',
    homepagePrefooterType = 'info',
    homepagePrefooterMainPartnerImage: partnerLogo,
    homepagePrefooterMainPartnerLink: partnerLink = '',
  } = event.configuration;

  const socials = [
    { link: homepagePrefooterSocialFacebookLink, name: 'Facebook' },
    { link: homepagePrefooterSocialLinkedinLink, name: 'Linkedin' },
    { link: homepagePrefooterSocialInstagramLink, name: 'Instagram' },
    { link: homepagePrefooterSocialTwitterLink, name: 'Twitter' },
  ].filter((i) => i.link);

  socialsLogosAndNames = socialsLogosAndNames.filter((i) =>
    socials.find((x) => x.link && x.name.toLowerCase() === i.name.toLowerCase())
  );
  const contactsData =
    homepagePrefooterContacts && JSON.parse(homepagePrefooterContacts);
  console.log('contactsData', contactsData);
  const hasContacts = contactsData?.blocks?.length;

  let sectionsCounter = [];
  if (socials.length > 0) sectionsCounter.push('socials');
  if (partnerLogo || partnerLink) sectionsCounter.push('partner');
  if (hasContacts) sectionsCounter.push('contacts');

  if (sectionsCounter.length > 3) sectionsCounter.length = 3;

  sectionsCounter = sectionsCounter.length;

  const dirPath = `events/${event.id}/configuration/generalInfo/homepage/prefooter`;

  const link = partnerLogo
    ? getAwsS3Link(dirPath, {
        id: partnerLogo.id,
        extension: partnerLogo.extension,
      })
    : null;

  const partnerImgSrc = aws.s3.getS3ObjectUrl(link);

  // Renders
  const renderMap = () => (
    <EventMapSvg
      input={
        isMobileOnly
          ? homepagePrefooterMapMobileModel
          : homepagePrefooterMapDesktopModel
      }
      s3Folder={dirPath}
    />
  );

  const renderInfo = () => (
    <Grid container style={GridContainerStyle}>
      {hasContacts && (
        <Grid item xs={12} md={12 / sectionsCounter}>
          <Grid item container>
            <Grid item xs={12} style={center}>
              <AimTypography>{`Contacts`}</AimTypography>
            </Grid>
            <Grid item xs={12} style={center}>
              <div
                // style={{ maxWidth: 'calc(100% - 40px)' }}
                dangerouslySetInnerHTML={{
                  __html: richTextToHTML(contactsData),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      )}

      {partnerLogo && (
        <Grid item xs={12} md={12 / sectionsCounter}>
          <Grid item container>
            <Grid item xs={12} style={center}>
              <AimTypography>{`Partner`}</AimTypography>
            </Grid>
            <Grid item xs={12} style={center}>
              <a
                style={{
                  padding: 10,
                  ...(partnerLink && { cursor: 'pointer' }),
                }}
                target="_blank"
                rel="noreferrer"
                {...(partnerLink && { href: partnerLink })}
              >
                <img
                  src={partnerImgSrc}
                  style={{ display: 'block', maxWidth: '100%' }}
                ></img>
              </a>
            </Grid>
          </Grid>
        </Grid>
      )}

      {socials.length > 0 && (
        <Grid item xs={12} md={12 / sectionsCounter}>
          <Grid item container>
            <Grid item xs={12} style={center}>
              <AimTypography>{`Social`}</AimTypography>
            </Grid>
            <Grid item xs={12} container style={center}>
              {socialsLogosAndNames.map(({ logo, name }, ndx) => (
                <Grid item key={ndx}>
                  <AimIconButton
                    key={ndx}
                    size="small"
                    onClick={() =>
                      window.open(
                        `${
                          socials.find((social) => social.name === name).link
                        }`,
                        '_blank'
                      )
                    }
                    variant="none"
                  >
                    {logo}
                  </AimIconButton>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
  return (
    <SectionContainerGray>
      {homepagePrefooterType !== 'image' ? renderInfo() : renderMap()}
    </SectionContainerGray>
  );
};

export default PreFooter;
