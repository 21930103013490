import React from 'react';
import { format } from 'date-fns';

import { utilities } from '@aim/common';
import { AimTypography, AimIconButton, AimList } from '@aim/components';

import Grid from '@material-ui/core/Grid';
import VisibilityIcon from '@material-ui/icons/Visibility';

const { decodeDbNumber } = utilities;

const HeaderRow = ({ i18n }) => (
  <>
    <Grid container style={{ height: 80 }}>
      <Grid item xs={3}>
        <AimTypography variant="columnHeader">
          <b>{i18n.paymentId}</b>
        </AimTypography>
      </Grid>
      <Grid item xs={3}>
        <AimTypography variant="columnHeader">
          <b>{i18n.hotelName}</b>
        </AimTypography>
      </Grid>
      <Grid container item xs={1}>
        <Grid item xs={6}>
          <AimTypography variant="columnHeader">
            <b>{i18n.roomsNumber}</b>
          </AimTypography>
        </Grid>
        <Grid item xs={6}>
          <AimTypography variant="columnHeader">
            <b>{i18n.guestsNumber}</b>
          </AimTypography>
        </Grid>
      </Grid>
      {/* <Grid item xs={1}>
        
      </Grid> */}
      <Grid item xs={1}>
        <AimTypography variant="columnHeader">
          <b>{i18n.checkIn}</b>
        </AimTypography>
      </Grid>
      <Grid item xs={1}>
        <AimTypography variant="columnHeader">
          <b>{i18n.checkOut}</b>
        </AimTypography>
      </Grid>
      <Grid item xs={1}>
        <AimTypography variant="columnHeader">
          <b>{i18n.amount}</b>
        </AimTypography>
      </Grid>
      <Grid item xs={1}>
        <AimTypography variant="columnHeader">
          <b>{i18n.paymentDate}</b>
        </AimTypography>
      </Grid>
      <Grid container item xs={1}>
        <Grid item xs={6}>
          <AimTypography variant="columnHeader">
            <b>{i18n.paymentStatus}</b>
          </AimTypography>
        </Grid>
        <Grid item xs={6}>
          <AimTypography variant="columnHeader"> </AimTypography>
        </Grid>
      </Grid>
    </Grid>
  </>
);

const Row = ({ row, history, eventId, participationId }) => (
  <Grid
    container
    style={{ padding: '0px', margin: 0, backgroundColor: '#F8F6FA' }}
  >
    <Grid item xs={3}>
      <Grid container alignItems="center">
        <AimTypography variant="text">{row?.paymentId}</AimTypography>
      </Grid>
    </Grid>
    <Grid container item xs={3}>
      <Grid container alignItems="center">
        <AimTypography variant="text">{row?.hotelName}</AimTypography>
      </Grid>
    </Grid>
    <Grid container item xs={1}>
      <Grid item xs={6} alignItems="center">
        <AimTypography variant="text">{row?.roomsNumber}</AimTypography>
      </Grid>
      <Grid item xs={6} alignItems="center">
        <AimTypography variant="text">{row?.guestsNumber}</AimTypography>
      </Grid>
    </Grid>
    {/* <Grid container item xs={1}>
      
    </Grid> */}
    <Grid container item xs={1}>
      <Grid container alignItems="center">
        <AimTypography variant="text">
          {row?.checkIn && format(new Date(row.checkIn), 'dd/MM/yyyy')}
        </AimTypography>
      </Grid>
    </Grid>
    <Grid container item xs={1}>
      <Grid container alignItems="center">
        <AimTypography variant="text">
          {row?.checkOut && format(new Date(row.checkOut), 'dd/MM/yyyy')}
        </AimTypography>
      </Grid>
    </Grid>
    <Grid container item xs={1}>
      <Grid container alignItems="center">
        <AimTypography variant="text">
          {decodeDbNumber(row?.amount)}
        </AimTypography>
      </Grid>
    </Grid>
    <Grid container item xs={1}>
      <Grid container alignItems="center">
        <AimTypography variant="text">
          {row?.date && format(new Date(row.date), 'dd/MM/yyyy')}
        </AimTypography>
      </Grid>
    </Grid>
    <Grid container item xs={1}>
      <Grid container xs={6} alignItems="center">
        <AimTypography variant="text" boxStyle={{ margin: '8px 0px' }}>
          {row?.paymentStatus
            ? row?.paymentStatus === 'done'
              ? 'Paid'
              : 'Pending'
            : 'Cancelled'}
        </AimTypography>
      </Grid>
      <Grid item xs={6}>
        <Grid container justifyContent="flex-end" alignItems="center">
          <AimIconButton
            variant="primary"
            onClick={(e) =>
              history.push(
                `/events/${eventId}/user/${participationId}/my-allotment/${row.productId}`
              )
            }
          >
            <VisibilityIcon />
          </AimIconButton>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

const MyAllotmentWeb = ({
  i18n,
  history,
  eventId,
  purchases,
  participationId,
}) => {
  return (
    <>
      <Grid container direction="column" style={{ margin: '0px 0px 20px 0px' }}>
        {purchases.length > 0 && (
          <AimList
            virtualized
            listHeight={500}
            rows={purchases}
            deletable={false}
            HeaderRow={<HeaderRow i18n={i18n.header} />}
            headerStyle={{ margin: 0 }}
          >
            <Row
              i18n={i18n}
              eventId={eventId}
              history={history}
              participationId={participationId}
            />
          </AimList>
        )}
      </Grid>
    </>
  );
};

export default MyAllotmentWeb;
