import React, { useState } from 'react';
import { AimTypography, styled, useAimMediaQuery } from '@aim/components';
import Avatar from '@material-ui/core/Avatar';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { appState, aws, fileHelper } from '@aim/common';

const Container = styled('div')(({ isMobile }) => ({
  width: '100vw',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'transparent',
  // height: isMobile ? 330 : 250,
  marginBottom: 10,
  alignItems: isMobile ? 'center' : 'flex-start',
}));

const AvatarColumn = styled('div')(({ isMobile }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: isMobile ? 'column' : 'row',
  alignItems: 'center',
  paddingLeft: isMobile ? 0 : '14%',
  marginTop: -75,
}));
const Text = styled('div')(({ isMobile }) => ({
  // position: 'absolute',
  display: 'flex',
  alignItems: isMobile ? 'center' : 'flex-start',
  flexDirection: 'column',
  justifyContent: isMobile ? 'center' : 'flex-start',
  textAlign: 'center',
  paddingLeft: isMobile ? 0 : 20,
  paddingTop: isMobile ? 0 : 75,
  // width: isMobile ? '100%' : 'unset',
  // marginTop: '240px',
  // left: isMobile ? 'unset' : '22%',
  // top: isMobile ? 'unset' : '31px',
  // transform: isMobile ? 'unset' : 'translate(-35%,-40%)',
}));

const CustomHeaderPage = ({
  title,
  subtitle,
  preTitle,
  logo,
  headerImage,
  page,
}) => {
  const { eventId } = useParams();
  const { isMobile, isMobileOnly } = useAimMediaQuery();
  const s3Folder = `events/${eventId}/configuration/generalInfo/style-settings/`;
  const [coverImage, setCoverImage] = useState();
  const [coverImageMobile, setCoverImageMobile] = useState();
  const eventConfiguration = appState.eventConfiguration.getValue();

  useEffect(() => {
    const getDefaultImages = () => {
      // if ( eventConfiguration?.defaultHeaderCoverImage) {
      const coverImageLink = fileHelper.getAwsS3Link(
        s3Folder,
        eventConfiguration.defaultHeaderCoverImage
      );
      setCoverImage(aws.s3.getS3ObjectUrl(coverImageLink));

      // } else if (eventConfiguration?.defaultHeaderCoverImageMobile) {

      if (eventConfiguration.defaultHeaderCoverImageMobile) {
        const coverImageMobileLink = fileHelper.getAwsS3Link(
          s3Folder,
          eventConfiguration.defaultHeaderCoverImageMobile
        );
        setCoverImageMobile(aws.s3.getS3ObjectUrl(coverImageMobileLink));
      }
      // }
    };

    if (
      (eventConfiguration?.defaultHeaderCoverImagePages || []).includes(page)
    ) {
      getDefaultImages();
    } else {
      setCoverImage(headerImage);
      setCoverImageMobile(headerImage);
    }
  }, []);
  return (
    <Container isMobile={isMobile}>
      <img
        src={isMobileOnly ? coverImageMobile : coverImage}
        height={'162'}
        style={{
          objectFit: 'cover',
          width: '100%',
          maxWidth: '100%',
        }}
      />

      <AvatarColumn isMobile={isMobile}>
        <Avatar
          src={logo}
          style={{
            width: 134,
            height: 134,
            border: 0,
            // position: 'absolute',
            // left: isMobile ? '50%' : '18%',
            // top: isMobile ? '260px' : '270px',
            // transform: 'translate(-50%,-50%)',
          }}
          alt={'avatar logo'}
        />
        <Text isMobile={isMobile}>
          <AimTypography
            margin={0}
            variant="text"
            style={{ color: 'red', paddingTop: isMobileOnly ? 10 : 0 }}
          >
            {preTitle}
          </AimTypography>
          <AimTypography
            margin={0}
            variant="h2"
            boxStyle={{ fontWeight: 'bold' }}
          >
            {title}
          </AimTypography>
          <AimTypography margin={0} variant="text">
            {subtitle}
          </AimTypography>
        </Text>
      </AvatarColumn>
    </Container>
  );
};

export default CustomHeaderPage;
