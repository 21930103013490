const mail = `
  <html
    xmlns="http://www.w3.org/1999/xhtml"
    style="
    margin: 0;
    padding: 0;
    height: Calc(100% - 20px);
    width: Calc(100% - 20px);
    background-color: #f8f6fa;
    padding: 10px;
    font-family: Roboto, Arial, sans-serif;
  "
  >
    <head>
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
      <title>##TITLE##</title>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link
        href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap"
        rel="stylesheet"
      />
      <style>
        table {
            margin: 0 auto;
                font-family: Arial;
            }
            .text {
                font-size: 16px;
                font-family: Roboto, Arial, sans-serif;
            }
            .title {
                font-size: 28px;
                font-weight: 700;
                font-family: Roboto, Arial, sans-serif;
            }
            .cta {
                text-decoration: none;
                color: #000000 !important;
                font-size: 16px;
                text-transform: uppercase;
                padding: 14px 25px;
                text-align: center;
                text-decoration: none;
                display: inline-block;
                border: 1.5px #ffd400 solid;
            }

            @media screen and (max-width: 600px) {
                table {
                    width: 100% !important;
                }
            }
        </style>
    </head>

    <body style="margin: 0; padding: 0">
      <table class="body" cellspacing="0" width="600">
        <tr>
          <td
            style="
            padding-left: 50px;
            height: 90px;
            background-color: #1a171b;
            width: 100%;
            font-family: Roboto, Arial, sans-serif;
          "
          >
            <img src="##LOGO##" />
          </td>
        </tr>
        <tr>
          <td style="padding: 40px 50px 40px 50px; background-color: #ffffff">
            ##MAIL_BODY##
          </td>
        </tr>
        <tr>
            <td style="padding-top: 25px; text-align: center; color: #5b5b5b">
                © Copyright ${new Date().getFullYear()} AIM Communication
            </td>
        </tr>
        ##FOOTER##
      </table>
    </body>
  </html>`;
export default mail;
