import React, { useState, useEffect } from 'react';
import { MainContainer, Container } from '../serviceList/BreakoutRoomList';
import { useIntl } from 'react-intl';
import useI18n from '../shared/i18n';
import { startOfDay, endOfDay, addSeconds, isBefore } from 'date-fns';

import { useParams, useHistory } from 'react-router-dom';
import {
  theme,
  CustomIntl,
  AimCalendarOneDay,
  calendarVariants,
  useLoaderHelper,
  AimTypography,
  AimIconAndTextButton,
  AimIconButton,
  AimDialog,
  AimSnackbarSeverity,
  AimSnackbar,
  useDataHelper,
  renderLabel,
} from '@aim/components';
import { useBreakoutRoom } from '../../shared/breakoutRoomGqlHelper';
import { useBreakoutRoomAttending } from '../../shared/breakoutRoomAttendingGqlHelper';
import { useBreakoutRoomInvitationUnregistered } from '../../shared/breakoutRoomInvitationUnregisteredGqlHelper';
import { useBreakoutRoomUtils } from '../../../hooks/useBreakoutRoomUtils';

import { BreakoutRoomCalendarSlot } from './breakoutRoomReservations/BreakoutRoomCalendarSlot';

import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';

import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import FilterIcon from '@material-ui/icons/FilterList';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';

import SelectSlotWarning from './breakoutRoomReservations/SelectSlotWarning';
import ParticipationFilter from './breakoutRoomReservations/ParticipationFilter';
import InvitationParticipationCard from './breakoutRoomReservations/InvitationParticipationCard';
import InvitationParticipationNoteDialog from './breakoutRoomReservations/InvitationParticipationNoteDialog';
import SlotInfoForm from './breakoutRoomReservations/SlotInfoForm';
import TitleAndBackButton from '../../shared/TitleAndBackButton';

const { showLoader, hideLoader } = useLoaderHelper();
const { decodeDbNumber } = useDataHelper();

const tabs = {
  INVITED: 'invited',
  SELF: 'self',
};

const defaultTabProps = {
  participations: [],
  filteredParticipations: [],
};

const BreakoutRoomReservations = () => {
  const intl = CustomIntl(useIntl());
  const i18n = useI18n.breakoutRoomReservations(intl);
  const { eventId, sponsorId, breakoutRoomId } = useParams();
  const breakoutRoomHelper = useBreakoutRoom(true);
  const breakoutRoomAttendingHelper = useBreakoutRoomAttending(true);
  const breakoutRoomInvitationUnregisteredHelper = useBreakoutRoomInvitationUnregistered(
    true
  );
  const {
    parseBreakoutRoom,
    getHours,
    checkIsPurchased,
    states,
    isJoinVisible,
    splitArray,
    getStatesOfInvitations,
    parseInvitation,
    parseEmailInvitation,
  } = useBreakoutRoomUtils();
  const history = useHistory();

  const [breakoutRoom, setBreakoutRoom] = useState();
  const [selectedSlot, setSelectedSlot] = useState();
  const [isSearchBarVisible, setIsSearchBarVisible] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState({ isOpen: false });
  const [noteDialog, setNoteDialog] = useState({ isOpen: false });
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [selectedTab, setSelectedTab] = useState(tabs.SELF);
  const [tabsData, setTabsData] = useState({
    [`${tabs.INVITED}`]: defaultTabProps,
    [`${tabs.SELF}`]: defaultTabProps,
  });
  const [events, setEvents] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    resetFilter();
  }, [selectedSlot, selectedTab]);

  const resetFilter = () => {
    document.querySelector('[aria-label="reset"]')?.click();
  };

  const fetchData = async (forceDB = false) => {
    let res;
    let alreadyParsedData = false;
    if (!forceDB && history.location?.state?.breakoutRoom) {
      res = history.location.state.breakoutRoom;
      alreadyParsedData = history.location.state.alreadyParsedData;
      //stiamo tornando indietro da invite
      if (history.location.state.breakoutRoomSlot) {
        //mi è arrivato uno slot aggiornato da inviti
        const _slot = history.location.state.breakoutRoomSlot;
        //devo aggiornare anche la breakoutRoom
        const slots = res.slots.map((slot) => ({
          ...slot,
        }));
        const idx = slots.indexOf(slots.find((s) => s.id === _slot.id));
        if (idx >= 0) slots[idx] = _slot;
        res.slots = [...slots];
        //seleziono lo slot
        selectSlot(_slot, false, true);
      }
    } else {
      res = await breakoutRoomHelper.get(breakoutRoomId);
    }
    history.replace(history.location.pathname, null);
    if (!alreadyParsedData) {
      res = parseBreakoutRoom(res);
    }
    checkIsPurchased(res, history);
    setBreakoutRoom(res);
    setEvents(res.slots);
  };

  const goToBreakoutRoom = () => {
    history.push(
      `/events/${eventId}/breakoutroom/${sponsorId}/${selectedSlot.id}`
    );
  };

  const clearAll = () => {
    history.push({
      pathname: `/events/${eventId}/${sponsorId}/services-configuration/breakoutrooms/${breakoutRoomId}/delete`,
      state: {
        breakoutRoom: breakoutRoom,
        breakoutRoomSlot: selectedSlot,
        alreadyParsedInvitation: true,
      },
    });
  };

  const deleteReservation = (invitation, forceDelete = false) => {
    if (forceDelete) {
      setDeleteDialog({ isOpen: true, data: invitation });
    } else {
      history.push({
        pathname: `/events/${eventId}/${sponsorId}/services-configuration/breakoutrooms/${breakoutRoomId}/delete`,
        state: {
          invitation: invitation,
          breakoutRoom: breakoutRoom,
          breakoutRoomSlot: selectedSlot,
          alreadyParsedInvitation: true,
          forceDelete: forceDelete,
        },
      });
    }
  };

  const invite = () => {
    history.push({
      pathname: `/events/${eventId}/${sponsorId}/services-configuration/breakoutrooms/${breakoutRoom.id}/invite`,
      state: {
        breakoutRoom: breakoutRoom,
        breakoutRoomSlot: selectedSlot,
        alreadyParsedInvitation: true,
      },
    });
  };

  const selectSlot = (slot, parse = true, forceUpdate = false) => {
    if (slot.id !== selectedSlot?.id || forceUpdate) {
      const _slot = {
        ...slot,
        invitations: [
          ...(parse
            ? slot.invitations.map((a) => parseInvitation(a))
            : slot.invitations),
          ...(parse
            ? slot.emails?.map((email) => parseEmailInvitation(email)) ||
              slot.emails
            : []),
        ],
        emails: slot.emails,
      };
      const [self, invited] = splitArray(
        _slot.invitations,
        (item) => item.isSelfProposed
      );
      setTabsData({
        [`${tabs.INVITED}`]: {
          participations: invited,
          filteredParticipations: [],
        },
        [`${tabs.SELF}`]: {
          participations: self,
          filteredParticipations: [],
        },
      });
      setSelectedSlot(_slot);
    }
  };

  const renderCalendar = () => {
    const isEditEnabled = isInviteEditPermitted();
    return (
      <div style={{ backgroundColor: theme.colors.greyScale.backgroundGrey }}>
        <AimCalendarOneDay
          day={breakoutRoom.date && breakoutRoom.date}
          events={events}
          i18n={i18n.calendar}
          step={breakoutRoom.duration}
          style={{ height: selectedSlot && isEditEnabled ? 334 : 574 }}
          onSelectEvent={(slot) => {
            selectSlot(slot);
          }}
          selectedEvent={selectedSlot}
          timeslots={2}
          variant={calendarVariants.BREAKOUT_ROOM_SLOT}
          eventComponent={BreakoutRoomCalendarSlot}
        />
        {isEditEnabled ? (
          <SlotInfoForm
            selectedSlot={selectedSlot}
            fetchData={fetchData}
            selectSlot={selectSlot}
          />
        ) : null}
      </div>
    );
  };

  const renderInvitationParticipationCard = (invitation) => {
    return (
      <div style={{}}>
        <InvitationParticipationCard
          invitation={invitation}
          eventId={eventId}
          isDisabled={!isInviteEditPermitted()}
          deleteReservation={deleteReservation}
          onOpenNote={(note) => {
            setNoteDialog({
              note: note,
              isOpen: true,
            });
          }}
        />
      </div>
    );
  };

  const borderColor = (selected) => {
    return `${selected ? 3 : 3}px solid ${
      selected ? '#FFD400' : theme.colors.greyScale.grey3
    }`;
  };

  const isInviteEditPermitted = () => {
    //controllo se l'evento è ancora in corso
    if (selectedSlot) {
      const now = new Date();
      return isBefore(now, addSeconds(selectedSlot.end, -1));
    }
    return false;
  };

  const renderInvitations = () => {
    const load = selectedSlot.invitations.filter(
      (i) => i.state === states.ACCEPTED
    ).length;
    const max = selectedSlot.isOneToOne ? 1 : breakoutRoom.maxAttendants;
    const loadText = `${load}/${max}`;
    const isFull = load >= max;
    return (
      <div
        style={{
          padding: 10,
          backgroundColor: theme.colors.greyScale.backgroundGrey,
          height: 574,
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <AimTypography variant="text">
            {`Slot [${getHours(selectedSlot.start)} - ${getHours(
              selectedSlot.end
            )}] ${selectedSlot.title ? ' - ' + selectedSlot.title : ''}`}
          </AimTypography>
          <AimIconAndTextButton
            variant="none"
            text={`${i18n.invitations.slotLoad} ${loadText}`}
          >
            {isFull ? <CloseIcon /> : <CheckIcon />}
          </AimIconAndTextButton>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div style={{ width: '20%' }}>
            <AimIconButton
              variant="lightBlue"
              aria-label="filter"
              onClick={() => setIsSearchBarVisible(!isSearchBarVisible)}
            >
              <FilterIcon />
            </AimIconButton>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              width: '80%',
            }}
          >
            <AimIconAndTextButton
              variant="red"
              text={`${i18n.invitations.buttons.clear}`}
              disabled={!isInviteEditPermitted()}
              onClick={() => {
                clearAll();
              }}
              style={{
                marginLeft: 5,
              }}
            >
              <CloseIcon />
            </AimIconAndTextButton>
            <AimIconAndTextButton
              variant="blue"
              text={`${i18n.invitations.buttons.invite}`}
              disabled={!isInviteEditPermitted()}
              onClick={() => {
                if (isFull) {
                  setSnackbar({
                    isOpen: true,
                    severity: AimSnackbarSeverity.error,
                    message: i18n.snackbar.maxReached,
                  });
                } else {
                  invite();
                }
              }}
              style={{
                marginLeft: 5,
              }}
            >
              <PermIdentityIcon />
            </AimIconAndTextButton>
            <AimIconAndTextButton
              variant="yellow"
              text={`${i18n.invitations.buttons.goToBreakoutRoom}`}
              disabled={!isJoinVisible(selectedSlot)}
              onClick={() => {
                goToBreakoutRoom();
              }}
              style={{
                marginLeft: 5,
              }}
            >
              <PlayArrowIcon />
            </AimIconAndTextButton>
          </div>
        </div>
        <div>
          <Collapse in={isSearchBarVisible}>
            <ParticipationFilter
              onFilterResult={(res) => {
                setTabsData((pre) => ({
                  ...pre,
                  [`${selectedTab}`]: {
                    participations: pre[`${selectedTab}`].participations,
                    filteredParticipations: res,
                  },
                }));
              }}
              rows={tabsData[selectedTab].participations}
              i18n={i18n.invitations.filter}
            />
          </Collapse>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignContent: 'center',
              marginTop: isSearchBarVisible ? -24 : 0,
              marginBottom: 6,
            }}
          >
            {Object.keys(tabs).map((tab) => {
              return (
                <AimIconAndTextButton
                  key={tab}
                  variant="none"
                  onClick={() => {
                    setSelectedTab(tabs[tab]);
                  }}
                  style={{
                    flex: 1,
                    width: '100%',
                    borderBottom: borderColor(selectedTab === tabs[tab]),
                  }}
                  text={renderLabel(
                    `${i18n.calendar[tabs[tab]]} (${
                      tabsData[tabs[tab]].participations.filter((part) => {
                        //su self non voglio mostrare i pending
                        if (tabs[tab] === tabs.SELF) {
                          return part.state !== states.PENDING;
                        }
                        return true;
                      }).length
                    })`,
                    {
                      fontSize: '2rem',
                    }
                  )}
                />
              );
            })}
          </div>
          <div>
            {tabsData[selectedTab].participations.length > 0 ? (
              <div
                style={{
                  height: isSearchBarVisible ? 330 : 403,
                  overflow: 'auto',
                }}
              >
                {(tabsData[selectedTab].filteredParticipations.length > 0
                  ? tabsData[selectedTab].filteredParticipations
                  : tabsData[selectedTab].participations
                )
                  .filter((part) => {
                    //su self non voglio mostrare i pending
                    if (selectedTab === tabs.SELF) {
                      return part.state !== states.PENDING;
                    }
                    return true;
                  })
                  .map((participation) => {
                    return renderInvitationParticipationCard(participation);
                  })}
              </div>
            ) : (
              <AimTypography variant="h3" textAlign="center">
                {i18n.invitations.noReservations}
              </AimTypography>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderSelectSlotWarning = () => {
    return <SelectSlotWarning i18n={i18n} />;
  };

  const onConfirmDelete = async (data = null) => {
    try {
      const promises = [];

      let _selectedSlot = { ...selectedSlot };

      if (data.id) {
        promises.push(breakoutRoomAttendingHelper.remove(data.id));
        _selectedSlot.invitations = _selectedSlot.invitations.filter(
          (i) => i.id !== data.id
        );
      } else {
        const unregistered = _selectedSlot.emails.find(
          (em) => em.email === data.participation.email
        );
        promises.push(
          breakoutRoomInvitationUnregisteredHelper.remove(unregistered.id)
        );
        _selectedSlot.invitations = _selectedSlot.invitations.filter(
          (i) => i.email !== data.participation.email
        );
        _selectedSlot.emails = _selectedSlot.emails.filter(
          (i) => i.email !== data.participation.email
        );
      }
      await Promise.all(promises);
      await fetchData(true);
      selectSlot(_selectedSlot, false, true);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.success,
        message: i18n.snackbar.deleteSuccess,
      });
    } catch (e) {
      console.error(e);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.snackbar.deleteFail,
      });
    }
  };

  const renderDialog = () => {
    if (!deleteDialog.isOpen) return null;
    const invitation = deleteDialog.data;
    return (
      <AimDialog
        open={deleteDialog.isOpen}
        title={`${i18n.dialog.delete.title}`}
        onAgree={() => {
          onConfirmDelete(invitation);
        }}
        onClose={() => {
          setDeleteDialog({ isOpen: false });
        }}
      >
        <AimTypography variant={'text'}>
          {i18n.dialog.delete.invitationMessage1 +
            ' ' +
            invitation.participation.userTxt}
        </AimTypography>
        <AimTypography variant={'formError'}>
          {i18n.dialog.delete.invitationMessage2}
        </AimTypography>
      </AimDialog>
    );
  };

  const renderNoteDialog = () => {
    return (
      <InvitationParticipationNoteDialog
        noteDialog={noteDialog}
        onClose={() => {
          setNoteDialog({ isOpen: false });
        }}
      />
    );
  };

  if (!breakoutRoom) return <div>Loading...</div>;
  return (
    <MainContainer>
      <TitleAndBackButton
        backOnClickFunction={() =>
          history.push(
            `/events/${eventId}/${sponsorId}/services-configuration/breakoutrooms`
          )
        }
        backOnHoverText={i18n.page.backButton.tooltip}
        title={i18n.page.title}
      />
      <Container>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            {breakoutRoom && renderCalendar()}
          </Grid>
          <Grid item xs={6}>
            {selectedSlot ? renderInvitations() : renderSelectSlotWarning()}
          </Grid>
        </Grid>
      </Container>
      {renderDialog()}
      {renderNoteDialog()}
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </MainContainer>
  );
};

export default BreakoutRoomReservations;
