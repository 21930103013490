import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { constants } from '@aim/common';
import translation from './translation';

import ReplayIcon from '@material-ui/icons/Replay';
import CheckIcon from '@material-ui/icons/Check';

import Grid from '@material-ui/core/Grid';

import {
  AimDragDropFileUploader,
  AimRadioForm,
  AimFormControlLabel,
  AimRadioButton,
  AimTypography,
  AimRichText,
  Travel,
  Transfer,
  AimIconAndTextButton,
  styled,
  CustomIntl,
  useDataHelper,
  theme,
} from '@aim/components';

import { updateParticipationServices } from './travelTransferGqlHelper';
import { fileHelper, appState } from '@aim/common';

//Constants
const formControlStyle = { width: 'calc(100% - 15px)' };

const InnerContainer = styled('div')({
  padding: 20,
});

const Container = styled('div')({
  backgroundColor: 'white',
  margin: '10px 0px',
  display: 'flex',
});

const ButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  margin: 10,
});

const DragDropFileUploader = ({
  title,
  subTitle,
  clickText,
  fileLimitText,
  maxFiles,
  setValue,
  getValues,
  setFiles,
  files,
  uploadFiles,
  removeFile,
  downloadFile,
  control,
  filesKey,
}) => {
  return (
    <Controller
      name={filesKey}
      control={control}
      render={(props) => (
        <AimDragDropFileUploader
          style={{
            width: 'calc(100% - 10px)',
            alignSelf: 'center',
            marginLeft: 8,
            marginRight: 8,
          }}
          key={filesKey}
          multiple
          dragTitle={title}
          dragSubTitle={subTitle}
          clickText={clickText}
          fileLimitText={fileLimitText}
          // onUploadFile={(files) => props.onChange(files)}
          // onUploadFile={(filesToAdd) => {
          //   props.onChange(filesToAdd);
          //   // setValue('files', filesToAdd);
          //   setValue('files', [...files, ...filesToAdd]);
          //   setFiles([...files, ...filesToAdd]);
          // }}
          // onRemove={(file) => removeFile(file)}
          // onDownload={(file) => downloadFile(file)}
          // files={files}
          onUploadFile={(files) => {
            props.onChange(files);
            setValue(filesKey, files);
            // setFiles([...files, ...filesToAdd]);
            uploadFiles(files, filesKey);
          }}
          onRemove={(file) => removeFile(file, filesKey)}
          onDownload={(file) => downloadFile(file, filesKey)}
          files={files}
          dropzoneAdditionalProps={{
            accept: '.jpeg,.jpg,.png,.pdf,.docx',
            maxFiles: maxFiles,
            multiple: true,
          }}
        />
      )}
    />
  );
};

const TravelTransferPrefs = ({
  participationServices,
  travelTransferData,
  onSuccess,
  onError,
  onClose,
}) => {
  const intl = CustomIntl(useIntl());
  const i18n = translation.TravelTransfer(intl);
  const { parseRichText } = useDataHelper();
  const participationId = appState.user.getValue().userAndParticipation
    .participation.id;
  // const history = useHistory();
  const { downloadFromS3, createFile, deleteFile } = fileHelper;
  const filesKeyArray = [
    'prefsDocBookByAuth',
    'prefsDocBookByAgency',
    'prefsDocBookByMyself',
  ];

  const [files, setFiles] = useState({
    prefsDocBookByAuth: [],
    prefsDocBookByAgency: [],
    prefsDocBookByMyself: [],
  });

  const [dataToDelete, setDataToDelete] = useState({
    prefsDocBookByAuth: [],
    prefsDocBookByAgency: [],
    prefsDocBookByMyself: [],
  });
  const { eventId } = useParams();
  const [userParticipation, setUserParticipation] = useState();

  // const [dataFaculty, setDataFaculty] = useState();
  const [travels, setTravels] = useState([]);
  const [terminals, setTerminals] = useState();
  const [radioPanel, setRadioPanel] = useState(0);
  // const [files, setFiles] = useState([]);
  // const [filesToRemove, setFilesToRemove] = useState([]);
  const { control, handleSubmit, reset, setValue, getValues } = useForm({
    defaultValues: {
      transferId: null,
    },
  });

  const onSave = async (data, status) => {
    //files
    const nextFiles = Object.entries(files)
      .map(([key, value]) => {
        let files = value.map(async (f) => await uploadFilesToS3(f, key));
        return files;
      })
      .flat();

    let finalResult = await Promise.all(nextFiles);
    let filesToSend = {};
    Object.keys(files).forEach((k) => {
      // send only files of active panel
      if (radioPanel === filesKeyArray.indexOf(k)) {
        filesToSend[k] = finalResult
          .filter((i) => i.key === k)
          .map((i) => {
            let remapped = { ...i };
            delete remapped.key;
            return remapped;
          });
      }
    });

    // const dataTest = {
    //   prefsTravelOneWayTravelBy: 'airplane',
    //   chooseBooking: '2',
    //   prefsTravelOneWayLeavingFrom: 'Ancona',
    //   prefsTravelOneWayArrivingIn: 'Milano da bere',
    //   prefsTravelOneWayLeavingAt: '12:30',
    //   bookByAgency: false,
    //   prefsTravelReturnArrivingIn: 'Ancona',
    //   pprefsTransferOneWayFrom: 'airport',
    //   prefsTravelTelephoneNumber: '11111111',
    //   prefsTravelReturnLeavingAt: '15:30',
    //   prefsCheckedBaggage: 'no',
    //   prefsTransferReturnTime: '17:00',
    //   prefsTravelOneWayArrivingAt: '20:00',
    //   prefsTravelOneWayIntermediateAirport: 'Canicattì',
    //   prefsProposal: 'yes',
    //   prefsTransferReturnPickUpDestination: 'Mialno malpensa',
    //   prefsTravelNotes: 'è ora de lassa perde',
    //   prefsTransferOneWayPickUpLocation: 'Milano malpensa',
    //   prefsTransferOneWayPickUpDestination: 'Milano fiera',
    //   prefsTravelAirplaneFidelityCard: '35454543',
    //   prefsTravelOneWayFlightTrainN: '13213',
    //   prefsTransferReturnTo: 'airport',
    //   prefsTransferOneWayTime: '18:30',
    //   prefsTravelReturnIntermediateAirport: 'Vattelapesca',
    //   prefsTravelReturnTravelBy: 'airplane',
    //   prefsTransferReturnFrom: 'congress',
    //   bookByAuth: true,
    //   prefsTravelTrainFidelityCard: '55555555555',
    //   prefsTransferReturnDate: '2021-12-09',
    //   responseStatus: 'published',
    //   prefsTravelOneWayDate: '2021-12-02',
    //   prefsIssueTickets: 'yes',
    //   prefsTravelReturnLeavingFrom: 'Milano bevuta',
    //   prefsTransferOneWayTo: 'congress',
    //   bookByMyself: false,
    //   prefsTravelReturnArrivingAt: '19:00',
    //   prefsTransferOneWayDate: '2021-12-03',
    //   prefsTravelReturnDate: '2021-12-09',
    //   prefsTransferReturnPickUpLocation: 'Milano fiera',
    //   prefsTravelReturnFlightTrainN: '1234532',
    // };

    const input = {
      id: participationServices.id,
      travelFacultyResponse: JSON.stringify({
        ...data,
        ...filesToSend,
        bookByAuth: radioPanel === 0,
        bookByAgency: radioPanel === 1,
        bookByMyself: radioPanel === 2,
        responseStatus: status, // constants.FacultyResponseStatus.PUBLISHED,
      }),
    };

    try {
      await deleteFilesFromS3();
      if (input?.id) {
        await updateParticipationServices(input);
      }
      onSuccess();
    } catch (err) {
      console.error('err', err);
      onError();
    }
  };

  useEffect(() => {
    // fetchData();
    setRadioPanel(parseInt(travelTransferData.chooseBooking, 10));

    if (travelTransferData && Object.keys(travelTransferData)?.length > 0) {
      const foundFiles = Object.keys(travelTransferData)
        .filter((key) => filesKeyArray.includes(key))
        .reduce((obj, key) => {
          obj[key] = travelTransferData[key];
          return obj;
        }, {});
      setFiles(foundFiles);
    }
    reset(travelTransferData);
  }, []);

  // Helpers
  const uploadFilesToS3 = async (f, key) => {
    const originalName = f?.name || new Date().getTime().toString();
    const matchExtension = originalName.match(/\.[0-9a-z]+$/i);
    const extension = matchExtension ? matchExtension[0] : '';
    const fileSize = f?.size;

    const input = {
      originalName: originalName,
      extension: extension,
      size: Number(fileSize) || 0,
    };
    let id = await createFile({
      dirPath: `events/${eventId}/faculty/travel/${key}`,
      fileData: input,
      localFile: f,
    });
    return {
      ...input,
      name: originalName,
      id,
      key,
    };
  };

  const deleteFilesFromS3 = async () => {
    Object.entries(dataToDelete).forEach(([key, value]) => {
      const dirPath = `events/${eventId}/faculty/travel/${key}`;
      value.forEach(
        async (f) => await deleteFile({ dirPath, fileData: f }, false)
      );
    });
  };

  const uploadFiles = async (f, key) => {
    const file = f[0];
    setFiles({ ...files, [key]: files[key] ? [...files[key], file] : [file] });
  };

  const removeFile = async (f, key) => {
    let fileToDelete = files[key].filter((i) => i === f);
    setDataToDelete({
      ...dataToDelete,
      [key]: [...dataToDelete[key], ...fileToDelete],
    });
    const newfile = files[key].filter((i) => i !== f);
    setFiles({ ...files, [key]: [...newfile] });
  };

  const downloadFile = async (item, key) => {
    if (item.id) {
      await downloadFromS3({
        dirPath: `events/${eventId}/faculty/travel/${key}`,
        fileData: item,
      });
    } else {
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(item);
      link.download = item.name;
      link.click();
      link.remove();
    }
  };

  // const uploadFiles = () => {
  //   // Add new files into DB
  //   const newFiles = files.filter((f) => !f.id);
  //   // create files row on files DB table and upload to s3 dedicated folder
  //   newFiles && createFiles(newFiles, participationId);

  //   // if there are, remove files from s3 and from DB
  //   filesToRemove.length > 0 &&
  //     filesToRemove.forEach(async (file) => {
  //       const fileData = {
  //         id: file.id,
  //       };
  //       await deleteFile({
  //         dirPath: `events/${eventId}/faculty/travel/participant/${participationId}/files`,
  //         fileData,
  //       });
  //     });
  // };

  // const removeFile = async (file) => {
  //   userParticipation.id && setFilesToRemove([...filesToRemove, file]);
  //   const updatedFiles = files.filter((f) => f.name !== file.name);
  //   setFiles(updatedFiles);
  // };

  // const downloadFile = async (file) => {
  //   if (files[0].id) {
  //     await downloadFromS3({
  //       dirPath: `events/${eventId}/faculty/travel/participant/${participationId}/files`,
  //       fileData: files[0],
  //     });
  //   } else {
  //     var link = document.createElement('a');
  //     link.href = window.URL.createObjectURL(file);
  //     link.download = file.name;
  //     link.click();
  //     link.remove();
  //   }
  // };

  const handleRadio = (e) => {
    const res = parseInt(e.target.value, 10);
    setRadioPanel(res);
  };

  return (
    <InnerContainer>
      {/* <Tooltip title={i18n.page.backButton.tooltip}>
        <AimIconAndTextButton
          variant="text"
          style={{ padding: 0 }}
          text={i18n.page.backButton.label}
          onClick={() => {}}
        >
          <ArrowBack />
        </AimIconAndTextButton>
      </Tooltip> */}
      <Container style={{ flexDirection: 'column' }}>
        <div
          style={{
            zIndex: 10,
            backgroundColor: 'white',
          }}
        >
          <AimTypography variant="h3">{i18n.page.travel.title}</AimTypography>
          {/* <AimTypography variant="textBold" boxStyle={{ margin: '0px 10px' }}>
            {i18n.page.travel.myTravels}
          </AimTypography> */}
          {/* <AimTypography variant="text" boxStyle={{ margin: '0px 10px' }}>
            {i18n.page.travel.myTravelDescr}
          </AimTypography>
          <ul style={{ marginLeft: '40px' }}>
            <li>
              <AimTypography variant="text">
                {i18n.page.travel.myTravelItem1}
              </AimTypography>
            </li>
            <li>
              <AimTypography variant="text">
                {i18n.page.travel.myTravelItem2}
              </AimTypography>
            </li>
          </ul> */}

          <AimRichText
            value={parseRichText(participationServices?.note)}
            readOnly
            variant="white"
            editorStyleOverride={{
              minHeight: 'auto',
              maxHeight: 'fit-content',
              overflow: 'hidden !important',
            }}
          />
        </div>
        <div
        // style={{
        //   paddingTop: 180,
        //   position: 'absolute',
        //   top: 190,
        //   left: 16,
        //   right: 0,
        //   bottom: 0,
        //   overflow: 'auto',
        // }}
        >
          <AimTypography variant="textBold" boxStyle={{ margin: '0px 10px' }}>
            {i18n.page.travel.generalPrefs}
          </AimTypography>
          <div
            style={{
              display: 'flex',
              marginLeft: 16,
              marginTop: 10,
              alignItems: 'center',
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <AimRadioForm
                  control={control}
                  name="chooseBooking"
                  defaultValue={radioPanel}
                  style={{ display: 'flex' }}
                  onClick={handleRadio}
                  value={radioPanel}
                >
                  <AimFormControlLabel
                    key={0}
                    value={0}
                    control={<AimRadioButton />}
                    label={i18n.page.authBooking}
                  />
                </AimRadioForm>
              </Grid>
            </Grid>
          </div>
          {radioPanel === 0 && (
            <>
              {participationServices?.isTravelIncluded && (
                <>
                  <Travel
                    i18n={i18n}
                    formControlStyle={formControlStyle}
                    control={control}
                    intl={intl}
                    isEditable={true}
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <AimTypography
                        variant="textBold"
                        boxStyle={{ margin: '0px 5px' }}
                      >
                        {i18n.page.pleaseUploadYourIdNum}
                      </AimTypography>
                      <AimTypography
                        variant="text"
                        boxStyle={{ margin: '0px 5px' }}
                      >
                        {i18n.page.pleaseUploadYourIdNumDescr}
                      </AimTypography>

                      <DragDropFileUploader
                        control={control}
                        i18n={i18n}
                        subTitle={i18n.page.dragAndDrop.subTitle}
                        title={`${i18n.page.dragAndDrop.title} (.jpeg,.jpg,.png,.pdf,.docx)`}
                        clickText={i18n.page.dragAndDrop.clickText}
                        fileLimitText={i18n.page.dragAndDrop.fileLimitText}
                        maxFiles={10}
                        uploadFiles={uploadFiles}
                        removeFile={removeFile}
                        downloadFile={downloadFile}
                        files={files.prefsDocBookByAuth}
                        // setFiles={setFiles}
                        setValue={setValue}
                        getValues={getValues}
                        filesKey="prefsDocBookByAuth"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3}>
                      <AimRadioForm
                        control={control}
                        name="prefsCheckedBaggage"
                        defaultValue={'no'}
                        style={{ display: 'flex' }}
                        label={i18n.page.travel.consentForTheCheckedBaggage}
                      >
                        <AimFormControlLabel
                          key={0}
                          value={'yes'}
                          control={<AimRadioButton />}
                          label={i18n.page.travel.yes}
                        />
                        <AimFormControlLabel
                          key={1}
                          value={'no'}
                          control={<AimRadioButton />}
                          label={i18n.page.travel.no}
                        />
                      </AimRadioForm>
                    </Grid>
                  </Grid>
                </>
              )}
              {(participationServices?.isTransferAIncluded ||
                participationServices?.isTransferRIncluded) && (
                <Grid container>
                  {/* old transfer preferences */}
                  {/* <Grid item xs={12}>
                    <Transfer
                      i18n={i18n}
                      formControlStyle={formControlStyle}
                      control={control}
                      intl={intl}
                      isEditable={true}
                    />
                  </Grid> */}
                  <Grid item xs={12}>
                    <AimTypography variant="text">
                      {i18n.page.transfer.title}
                    </AimTypography>
                    <div
                      style={{
                        backgroundColor: theme.colors.greyScale.backgroundGrey,
                        margin: 10,
                      }}
                    >
                      <div style={{ display: 'flex' }}>
                        <AimTypography
                          variant="textBold"
                          // boxStyle={{ margin: '0px 5px' }}
                        >
                          {`${i18n.page.transfer.transferAIncluded}: `}
                        </AimTypography>
                        <AimTypography variant="text">
                          {participationServices.isTransferAIncluded
                            ? i18n.page.transfer.yes
                            : i18n.page.transfer.no}
                        </AimTypography>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <AimTypography
                          variant="textBold"
                          // boxStyle={{ margin: '0px 5px' }}
                        >
                          {`${i18n.page.transfer.transferRIncluded}: `}
                        </AimTypography>
                        <AimTypography variant="text">
                          {participationServices.isTransferRIncluded
                            ? i18n.page.transfer.yes
                            : i18n.page.transfer.no}
                        </AimTypography>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              )}

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                  <AimRadioForm
                    control={control}
                    name="prefsIssueTickets"
                    defaultValue={'yes'}
                    style={{ display: 'flex' }}
                    label={i18n.page.travel.issueTickets}
                  >
                    <AimFormControlLabel
                      key={0}
                      value={'yes'}
                      control={<AimRadioButton />}
                      label={i18n.page.travel.yes}
                    />
                    <AimFormControlLabel
                      key={1}
                      value={'no'}
                      control={<AimRadioButton />}
                      label={i18n.page.travel.no}
                    />
                  </AimRadioForm>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <AimRadioForm
                    control={control}
                    name="prefsProposal"
                    defaultValue={'yes'}
                    style={{ display: 'flex' }}
                    label={i18n.page.travel.proposal}
                  >
                    <AimFormControlLabel
                      key={0}
                      value={'yes'}
                      control={<AimRadioButton />}
                      label={i18n.page.travel.yes}
                    />
                    <AimFormControlLabel
                      key={1}
                      value={'no'}
                      control={<AimRadioButton />}
                      label={i18n.page.travel.no}
                    />
                  </AimRadioForm>
                </Grid>
              </Grid>
            </>
          )}

          <div
            style={{
              display: 'flex',
              marginLeft: 16,
              marginTop: 10,
              alignItems: 'center',
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <AimRadioForm
                  control={control}
                  name="chooseBooking"
                  defaultValue={radioPanel}
                  style={{ display: 'flex' }}
                  onClick={handleRadio}
                  value={radioPanel}
                >
                  <AimFormControlLabel
                    key={1}
                    value={1}
                    control={<AimRadioButton />}
                    label={i18n.page.useAgencyToBook}
                  />
                </AimRadioForm>
              </Grid>
            </Grid>
          </div>
          {radioPanel === 1 && (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <AimTypography
                    variant="textBold"
                    boxStyle={{ margin: '0px 5px' }}
                  >
                    {i18n.page.selectTheContentToUpload}
                  </AimTypography>
                  <DragDropFileUploader
                    control={control}
                    i18n={i18n}
                    subTitle={i18n.page.dragAndDrop.subTitle}
                    title={`${i18n.page.dragAndDrop.title} (.jpeg,.jpg,.png,.pdf,.docx)`}
                    clickText={i18n.page.dragAndDrop.clickText}
                    fileLimitText={i18n.page.dragAndDrop.fileLimitText}
                    maxFiles={10}
                    uploadFiles={uploadFiles}
                    removeFile={removeFile}
                    downloadFile={downloadFile}
                    files={files.prefsDocBookByAgency}
                    setFiles={setFiles}
                    setValue={setValue}
                    getValues={getValues}
                    filesKey="prefsDocBookByAgency"
                  />
                  <AimTypography
                    variant="text"
                    boxStyle={{ margin: '0px 5px' }}
                  >
                    {i18n.page.uploadNote1}
                  </AimTypography>
                  <AimTypography
                    variant="text"
                    boxStyle={{ margin: '0px 5px' }}
                  >
                    {i18n.page.uploadNote2}
                  </AimTypography>
                </Grid>
              </Grid>
            </>
          )}

          <div
            style={{
              display: 'flex',
              marginLeft: 16,
              marginTop: 10,
              alignItems: 'center',
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <AimRadioForm
                  control={control}
                  name="chooseBooking"
                  defaultValue={radioPanel}
                  style={{ display: 'flex' }}
                  onClick={handleRadio}
                  value={radioPanel}
                >
                  <AimFormControlLabel
                    key={2}
                    value={2}
                    control={<AimRadioButton />}
                    label={i18n.page.autonomousBooking}
                  />
                </AimRadioForm>
              </Grid>
            </Grid>
          </div>
          {radioPanel === 2 && (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <AimTypography
                    variant="textBold"
                    boxStyle={{ margin: '0px 5px' }}
                  >
                    {i18n.page.selectTheContentToUpload}
                  </AimTypography>
                  <DragDropFileUploader
                    control={control}
                    i18n={i18n}
                    subTitle={i18n.page.dragAndDrop.subTitle}
                    title={`${i18n.page.dragAndDrop.title} (.jpeg,.jpg,.png,.pdf,.docx)`}
                    clickText={i18n.page.dragAndDrop.clickText}
                    fileLimitText={i18n.page.dragAndDrop.fileLimitText}
                    maxFiles={10}
                    uploadFiles={uploadFiles}
                    removeFile={removeFile}
                    downloadFile={downloadFile}
                    files={files.prefsDocBookByMyself}
                    setFiles={setFiles}
                    setValue={setValue}
                    getValues={getValues}
                    filesKey="prefsDocBookByMyself"
                  />
                  <AimTypography
                    variant="text"
                    boxStyle={{ margin: '0px 5px' }}
                  >
                    {i18n.page.uploadNote1}
                  </AimTypography>
                  <AimTypography
                    variant="text"
                    boxStyle={{ margin: '0px 5px' }}
                  >
                    {i18n.page.uploadNote2}
                  </AimTypography>
                </Grid>
              </Grid>
            </>
          )}
        </div>

        {/* <AimCheckboxForm
            control={control}
            name="authBooking"
            defaultValue={''}
          ></AimCheckboxForm> */}
        {/* <AimTypography variant="text" boxStyle={{ margin: '0px 5px' }}>
            {i18n.page.travel.authBooking}
          </AimTypography> */}

        <ButtonContainer>
          <AimIconAndTextButton
            onClick={() => {}}
            variant="greyFill"
            text={i18n.page.cancel}
            type="submit"
          >
            <ReplayIcon />
          </AimIconAndTextButton>
          <AimIconAndTextButton
            text={i18n.page.save}
            variant="greenFill"
            // type="submit"
            // onClick={handleSubmit(onSave)}
            onClick={() => {
              onClose();
              handleSubmit(
                onSave(getValues(), constants.FacultyResponseStatus.PUBLISHED)
              );
            }}
          >
            <CheckIcon />
          </AimIconAndTextButton>
          <AimIconAndTextButton
            text={i18n.page.saveDraft}
            variant="greenFill"
            onClick={() => {
              onClose();
              handleSubmit(
                onSave(getValues(), constants.FacultyResponseStatus.DRAFT)
              );
            }}
          >
            <CheckIcon />
          </AimIconAndTextButton>
        </ButtonContainer>
      </Container>
    </InnerContainer>
  );
};

export default TravelTransferPrefs;
