/* eslint-disable prettier/prettier */

import styled from 'styled-components';
import backImageSlider from './backslider.png';
export const SessionsContainer = styled.div`
  background-color: #fff;
`;

export const SessionSliderContainer = styled.div`
  min-height: 67vh;
  min-width: 98.9vw;
  background-image: url(${backImageSlider});
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  flex: 1;
  color: var(--white);
`;

export const UpNextContainer = styled.div`
  min-height: 18vh;
  background-color: #fff;
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  max-width: 96vw;
  padding-left: 3vw;
  margin-top: 4vh;
`;

export const OverlaySlider = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--black);
  opacity: 0.5;
`;

export const SliderWrapper = styled.div`
  width: 85%;
`;

export const FlexerSpeakers = styled.div`
  display: flex;
  align-items: center;
  div {
    padding: 0.1rem 0;
  }
`;

export const SpeakerCard = styled.div`
  display: grid;
  grid-template-columns: 28% 1fr;
  min-width: 4rem;
  align-items: center;
`;

export const SpeakerAvatar = styled.div`
  img {
    width: 100%;
    border-radius: 20px;
  }
`;

export const AreaIndicatorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  fontweight: 100;
`;

export const SessionDescription = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-weight: 100;
  text-align: justify;
  word-break: break-all;
  width: 60%;
`;
