export const pubNubTimeToken = () => {
  const timetoken = Math.floor(new Date().getTime() * 10000);
  return timetoken;
};

export const pubNubTimeTokenToDate = (timetoken: number) => {
  return new Date(timetoken / 10000);
};

export const isPresentMember = (
  uuid: string,
  presentMembers: string[]
): boolean => {
  return presentMembers?.includes(uuid);
};
