import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { isMobile } from 'react-device-detect';

import Sponsor from './Sponsor';
import { getSponsors } from './gqlHelper';
import { extractSponsorInfo } from '../../../shared/utilities';

const SWITCH_SPONSOR_INTERVAL = 60000; // in ms

const Sponsors = ({ event }) => {
  const [sponsors, setSponsors] = useState([]);
  const [activeSponsorImagesIndexes, setActiveSponsorImagesIndexes] = useState(
    []
  );

  useEffect(() => {
    if (!event) return;
    const getSponsorData = async () => {
      const sponsorBanners = await getSponsors(event.configuration.id);

      // Retrieve sponsors from sponsorBanners
      const sponsorsList = sponsorBanners
        .flatMap((s) => s.sponsorAreas?.items.map((sa) => sa.sponsor))
        .filter((s) => !!s);

      // Remove possibile duplicated sponsors
      const uniqueSponsorsList = [...new Set(sponsorsList)];

      // Extract sponsorsInfo to get sponsors profile images
      const sponsorsInfo = await extractSponsorInfo({
        sponsorsList: uniqueSponsorsList,
        eventId: event.id,
      });

      // Attach profile imagaes of every sponsor to every sponsor banner
      const nextSponsors = sponsorBanners
        .map((sb) => ({
          ...sb,
          sponsorImages: sb.sponsorAreas.items
            .map(
              (sa) =>
                sponsorsInfo.find((si) => si.id === sa.sponsor.id)
                  ?.profileImage || null
            )
            .filter((si) => !!si),
        }))
        .filter((sb) => !!sb.sponsorImages.length)
        .sort((a, b) => a.name.localeCompare(b.name));

      setSponsors(nextSponsors);
      // Create an array of active sponsor images indexes (each starting random)
      // to cycle sponsor images for every banner at regular interval
      const activeSponsorImgIndexes = Array.from({
        length: nextSponsors.length,
      });
      // initial index random
      nextSponsors.map((sponsor, index) => {
        const max = sponsor.sponsorImages.length;
        const randomIndex = Math.floor(Math.random() * max);
        activeSponsorImgIndexes[index] = randomIndex;
      });

      setActiveSponsorImagesIndexes(activeSponsorImgIndexes);
    };

    getSponsorData();
  }, [event]);

  useEffect(() => {
    if (!sponsors.length || !activeSponsorImagesIndexes.length) return;

    const getNextImagesIndexes = (prevIndexes) =>
      prevIndexes.map(
        (activeImageIndex = 0, idx) =>
          (activeImageIndex + 1) % sponsors[idx]?.sponsorImages.length || 0
      );

    // Increase sponsor images active index at regular interval or reset at 0
    const switchImagesInterval = setInterval(
      () => setActiveSponsorImagesIndexes(getNextImagesIndexes),
      SWITCH_SPONSOR_INTERVAL
    );

    return () => clearInterval(switchImagesInterval);
  }, [sponsors, activeSponsorImagesIndexes]);

  return (
    <div>
      <Grid
        container
        direction="row"
        style={{ justifyContent: 'center', display: 'flex' }}
      >
        {sponsors.map((sponsor, ndx) => (
          <Grid
            item
            key={ndx}
            {...(isMobile && { xs: 12 })}
            style={{ display: 'flex', justifyContent: 'center', padding: 8 }}
          >
            <Sponsor
              key={ndx}
              {...sponsor}
              isMobile={isMobile}
              style={{ cursor: 'pointer' }}
              img={sponsor.sponsorImages[activeSponsorImagesIndexes[ndx]]}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Sponsors;
