import React from 'react';

import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { Controller, useFieldArray } from 'react-hook-form';

import { format } from 'date-fns';

import { ProfileFeeBracketsList } from './ProfileFeeBracketsList';

import { AimCard, AimTypography, AimCheckbox } from '@aim/components';

const DateRangesList = ({
  control,
  i18n,
  isReadOnly,
  profile,
  fieldArrayPrefix = '',
}) => {
  const { fields } = useFieldArray({
    control,
    name: `${fieldArrayPrefix}dateRanges`,
    keyName: '_id',
  });

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {fields.map((item, index) => {
        return (
          <AimCard
            key={item._id}
            title={
              <Controller
                name={`${fieldArrayPrefix}dateRanges[${index}].isActive`}
                defaultValue={item.isActive}
                control={control}
                render={(props) => (
                  <FormControlLabel
                    label={
                      <AimTypography variant="h5Regular">
                        {`${item.label} - ${format(
                          new Date(item.start),
                          'dd MMM yyyy'
                        )} - ${format(new Date(item.end), 'dd MMM yyyy')}`}
                      </AimTypography>
                    }
                    control={
                      <AimCheckbox
                        disabled={
                          isReadOnly ||
                          (item.isActive && profile.participations.items.length)
                        }
                        onChange={(e) => props.onChange(e.target.checked)}
                        checked={props.value}
                      />
                    }
                  />
                )}
              />
            }
          >
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs></Grid>
              <Grid item xs>
                <AimTypography variant="columnHeader">
                  {i18n.form.from.label}
                </AimTypography>
              </Grid>
              <Grid item xs>
                <AimTypography variant="columnHeader">
                  {i18n.form.to.label}
                </AimTypography>
              </Grid>
              <Grid item xs>
                <AimTypography variant="columnHeader">
                  {i18n.form.virtualPrice.label}
                </AimTypography>
              </Grid>
              <Grid item xs>
                <AimTypography variant="columnHeader">
                  {i18n.form.physicalPrice.label}
                </AimTypography>
              </Grid>
              <Grid item xs>
                <AimTypography variant="columnHeader">
                  {i18n.form.accompanyingPersonPrice.label}
                </AimTypography>
              </Grid>
            </Grid>
            <ProfileFeeBracketsList
              {...{
                control,
                profile,
                index,
                isReadOnly: isReadOnly || profile.participations.items.length,
                fieldArrayPrefix,
              }}
            />
          </AimCard>
        );
      })}
    </div>
  );
};

export { DateRangesList };
