import React from 'react';

import { isAfter, isBefore, isEqual, format } from 'date-fns';

import { AimIconAndTextButton, AimTypography, styled } from '@aim/components';

import { FlexerSpeakers, SpeakerCard, AreaIndicatorWrapper } from './style';

import { LiveNowBanner } from './LiveNowBanner';
import { uniqBy } from 'lodash';

import Avatar from '@material-ui/core/Avatar';

import ArrowForward from '@material-ui/icons/ArrowForward';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import RoomIconOutlined from '@material-ui/icons/RoomOutlined';

const SlideWrapper = styled('div')(({ cover }) => ({
  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3),rgba(0, 0, 0, 0.3)), url(${cover})`,
  backgroundSize: 'cover',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  height: '50vh',
  paddingLeft: '4%',
  paddingBottom: '6%',
  width: '57vw',
}));

const SlideSession = ({
  session,
  i18n,
  isJoinVisible,
  history,
  eventId,
  defaultImage,
}) => {
  const dateStart = new Date(session.start);
  const dateEnd = new Date(session.end);
  const now = new Date();

  const distinctSpeechesAttendee = uniqBy(session.attendees, (a) => a.id);

  const isAfterStart = isAfter(now, dateStart) || isEqual(now, dateStart);
  const isBeforeEnd = isBefore(now, dateEnd) || isEqual(now, dateEnd);

  const isLiveNow = isAfterStart && isBeforeEnd;

  return (
    <SlideWrapper cover={session.coverImage?.url || defaultImage}>
      {isLiveNow ? (
        <LiveNowBanner text={i18n.page.slide.liveNow.label} />
      ) : null}
      <AreaIndicatorWrapper>
        <RoomIconOutlined />
        <AimTypography variant="h4Regular" style={{ wordBreak: 'break-all' }}>
          {`${session.room.name.toUpperCase()} - ${
            dateStart ? format(dateStart, 'dd LLLL yyyy HH:mm') : ''
          } - ${dateEnd ? format(dateEnd, 'HH:mm') : ''} `}
        </AimTypography>
      </AreaIndicatorWrapper>

      <AimTypography variant="h1" style={{ wordBreak: 'break-all' }}>
        {session.name}
      </AimTypography>
      <AimTypography
        variant="text"
        style={{ maxWidth: '90%', wordBreak: 'break-all' }}
      >
        {session.shortDescription}
      </AimTypography>
      <FlexerSpeakers>
        {distinctSpeechesAttendee?.map((sp, index) => {
          return (
            <SpeakerCard key={index}>
              <Avatar src={sp.image} />
              <AimTypography variant="text">
                {sp?.givenName + ' ' + sp?.familyName}
              </AimTypography>
            </SpeakerCard>
          );
        })}
      </FlexerSpeakers>
      <div>
        {isJoinVisible(session) ? (
          <AimIconAndTextButton
            style={{ color: 'white' }}
            textColor={'white'}
            text={i18n.page.sessionsCarousel.joinButton.label.toUpperCase()}
            variant="yellow"
            onClick={() => {
              history.push(`/events/${eventId}/webinar/${session.id}`);
            }}
          >
            <ArrowForward />
          </AimIconAndTextButton>
        ) : null}
        <AimIconAndTextButton
          style={{ color: 'white' }}
          textColor={'white'}
          text={i18n.page.sessionsCarousel.detailButton.label.toUpperCase()}
          variant="lightBlue"
          onClick={() => {
            history.push(`/events/${eventId}/agenda/sessions/${session.id}`);
          }}
        >
          <MoreHorizIcon />
        </AimIconAndTextButton>
      </div>
    </SlideWrapper>
  );
};

export { SlideSession };
