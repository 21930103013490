import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams, useHistory } from 'react-router-dom';

import { useAgenda } from './shared/agendaGqlHelper';

import Tooltip from '@material-ui/core/Tooltip';
import ArrowBack from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import PinIcon from '@material-ui/icons/BookmarkBorder';
import PlayIcon from '@material-ui/icons/PlayArrow';

import {
  AimIconAndTextButton,
  CustomIntl,
  AimSnackbar,
  AimSnackbarSeverity,
  AgendaSessionTemplate,
} from '@aim/components';
import useI18n from './agendaEventDetail/i18n';
import { useFavouriteAgendaSession } from './shared/favouriteAgendaSessionGqlHelper';
import { appState } from '@aim/common';
import { loadUserData } from '../utilities';

const AgendaEventDetail = () => {
  const intl = CustomIntl(useIntl());
  const i18n = useI18n(intl);

  const { sessionId, eventId } = useParams();
  const { getAgendaSession, parseSession, isJoinVisible } = useAgenda();
  const [participation, setParticipation] = useState();
  const [favourite, setFavourite] = useState();
  const [agendaSession, setAgendaSession] = useState(null);
  const [snackbar, setSnackbar] = useState({ isOpen: false });

  const favouriteAgenda = useFavouriteAgendaSession();
  const history = useHistory();

  useEffect(() => {
    const fetchAgendaSession = async () => {
      const fetchedAgendaSession = await getAgendaSession(sessionId);
      const participation = appState.user.getValue()?.userAndParticipation
        ?.participation;

      const favouriteObj = participation.myAgenda.items?.find(
        (s) => s.session?.id === fetchedAgendaSession.id
      );

      if (favouriteObj) setFavourite(favouriteObj.id);
      setParticipation(participation);
      const parsedSession = await parseSession(fetchedAgendaSession, eventId);

      setAgendaSession(parsedSession);
    };

    fetchAgendaSession();
  }, []);

  const addToMyAgenda = async () => {
    const favouriteId = await favouriteAgenda.create(
      agendaSession.id,
      participation.id
    );
    loadUserData({ eventId, useLoader: false });
    if (favouriteId) {
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.success,
        message: i18n.actions.myAgenda.success,
      });
    }
    setFavourite(favouriteId.id);
  };

  const removeFromMyAgenda = async () => {
    const myAgendaRes = await favouriteAgenda.remove(favourite);
    if (myAgendaRes) {
      loadUserData({ eventId, useLoader: false });
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.success,
        message: i18n.actions.myAgenda.remove,
      });
    }
    setFavourite(null);
  };

  if (!agendaSession) return null;
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          overflowY: 'auto',
          width: 'calc(100vw - 20px)',
        }}
      >
        <div
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            backgroundColor: '#FFF',
            paddingBottom: '20px',
          }}
        >
          <div style={{ maxWidth: '70vw', margin: '0 auto' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Tooltip title={i18n.page.backButton.tooltip}>
                <AimIconAndTextButton
                  variant="text"
                  style={{ padding: 0 }}
                  text={i18n.page.backButton.label}
                  onClick={() => history.replace(`/events/${eventId}/homepage`)}
                >
                  <ArrowBack />
                </AimIconAndTextButton>
              </Tooltip>
              <div>
                {isJoinVisible(agendaSession) && (
                  <AimIconAndTextButton
                    variant={'lightBlue'}
                    style={{ padding: 0, marginRight: 5 }}
                    text={i18n.card.playEvent}
                    onClick={() => {
                      history.push(`/events/${eventId}/webinar/${sessionId}`);
                    }}
                  >
                    <PlayIcon />
                  </AimIconAndTextButton>
                )}
                <AimIconAndTextButton
                  variant={favourite ? 'red' : 'yellow'}
                  style={{ padding: 0 }}
                  text={favourite ? i18n.card.unPinEvent : i18n.card.pinEvent}
                  onClick={() => {
                    favourite ? removeFromMyAgenda() : addToMyAgenda();
                  }}
                >
                  {favourite ? <CloseIcon /> : <PinIcon />}
                </AimIconAndTextButton>
              </div>
            </div>
            {agendaSession && (
              <AgendaSessionTemplate
                session={agendaSession}
                i18n={i18n}
                intl={intl}
                onInterventionClick={(docId) =>
                  history.push(
                    `/events/${eventId}/agenda/sessions/${sessionId}/abstract/${docId}`
                  )
                }
              />
            )}
          </div>
        </div>

        <AimSnackbar
          open={snackbar.isOpen}
          onClose={() => setSnackbar({ isOpen: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </AimSnackbar>
      </div>
    </>
  );
};

export default AgendaEventDetail;
