import React, { useState, useEffect } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { addDays } from 'date-fns';

import { aws, appState, constants } from '@aim/common';

import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import ArrowBack from '@material-ui/icons/ArrowBack';

import {
  MainContainer,
  InnerContainer,
} from './../shared/SponsorPagesContainers';

import { useVirtualStandService } from './../shared/virtualStandServiceGqlHelper';
import { useSponsorVirtualStand } from './../shared/sponsorVirtualStandGqlHelper';
import { useBuyOperations } from './../shared/buyOperationsGqlHelper';
import { useSponsors } from './../shared/sponsorGqlHelper';

import { VirtualStandList } from './virtualStand/VirtualStandList';

import {
  AimSnackbar,
  AimSnackbarSeverity,
  AimIconAndTextButton,
  AimTypography,
  AimRichText,
  AimFileList,
  CustomIntl,
} from '@aim/components';

import translation from './../shared/translation';

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const VirtualStand = () => {
  //Hooks
  const history = useHistory();
  const { eventId, sponsorId, service } = useParams();
  const intl = CustomIntl(useIntl());
  const i18n = translation.virtualStand(intl);
  const virtualStandServiceHelper = useVirtualStandService();
  const sponsorVirtualStandHelper = useSponsorVirtualStand();
  const buyOperationHelper = useBuyOperations();
  const sponsorHelper = useSponsors();

  //State
  const [virtualStandService, setVirtualStandService] = useState();
  const [bookingDuration, setBookingDuration] = useState();
  const [paymentType, setPaymentType] = useState();
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [sponsor, setSponsor] = useState();

  //Effects
  useEffect(() => {
    fetchVirtualStandService();
    fetchSponsor();
  }, []);

  // Functions
  const fetchVirtualStandService = async () => {
    const result = await virtualStandServiceHelper.get(eventId);
    setVirtualStandService(result.virtualStandService);
    setBookingDuration(result.sponsorServiceBookingDuration || 10);

    const paymentType =
      result.virtualStandService.isCreditCardEnabled &&
      result.virtualStandService.isBankTransferEnabled
        ? 'both'
        : (result.virtualStandService.isCreditCardEnabled &&
            constants.PaymentTypes.CreditCard) ||
          (result.virtualStandService.isBankTransferEnabled &&
            constants.PaymentTypes.BankTransfer);
    setPaymentType(paymentType);
  };

  const fetchSponsor = async () => {
    const result = await sponsorHelper.get(sponsorId);
    setSponsor(result);
  };

  const downloadFile = async (item) => {
    const s3Folder = `events/${eventId}/sponsor/${service}/documents/`;
    const link = `${s3Folder}${item.id}${item.extension}`;

    var res = await aws.Storage.get(link);
    window.open(res);
  };

  //Functions
  const onAddToCart = async (virtualStandId, purchaseNow = false) => {
    showLoader();
    try {
      const inputSponsorVirtualStand = {
        sponsorVirtualStandVirtualStandId: virtualStandId,
      };

      const sponsorVirtualStand = await sponsorVirtualStandHelper.create(
        inputSponsorVirtualStand,
        false
      );

      const bookingDate = new Date();
      const bookingExpiringDate = addDays(bookingDate, bookingDuration);
      const inputBuyOperation = {
        eventId: eventId,
        buyOperationSponsorVirtualStandId: sponsorVirtualStand.id,
        buyOperationSponsorId: sponsorId,
        date: bookingDate,
        expiringDate: bookingExpiringDate,
        paymentType: paymentType,
        isExpired: 'false',
        isDeleted: 'false',
      };

      const response = await buyOperationHelper.create(
        inputBuyOperation,
        false
      );

      inputSponsorVirtualStand.id = sponsorVirtualStand.id;
      inputSponsorVirtualStand.sponsorVirtualStandBuyOperationId = response.id;

      await sponsorVirtualStandHelper.update(inputSponsorVirtualStand, false);

      const destUrl = `/events/${eventId}/${sponsorId}/cart`;
      if (purchaseNow) {
        history.push({
          pathname: destUrl,
          state: {
            bookings: [response],
          },
        });
      }
    } catch (e) {
      console.error(e);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.snackbar.addToCartFail.label,
      });
    } finally {
      hideLoader();
    }
  };

  return (
    <MainContainer>
      <InnerContainer grow bottomSpacing>
        <div style={{ display: 'flex-root', marginTop: 20 }}>
          <Tooltip title={i18n.page.back.tooltip.label}>
            <AimIconAndTextButton
              variant="text"
              text={i18n.page.back.button.label}
              onClick={() =>
                history.push(`/events/${eventId}/${sponsorId}/my-services`)
              }
            >
              <ArrowBack />
            </AimIconAndTextButton>
          </Tooltip>
        </div>
        <Grid container>
          <Grid item xs={12}>
            <AimTypography variant={'h1'}>
              {i18n.page.title.label}
            </AimTypography>
          </Grid>
          <Grid item xs={12}>
            {virtualStandService?.description && (
              <AimRichText
                label={i18n.page.instructions.label}
                value={JSON.parse(virtualStandService.description)}
                readOnly
                editorStyleOverride={{
                  minHeight: 'auto',
                  maxHeight: 'fit-content',
                  overflow: 'hidden !important',
                }}
              />
            )}
          </Grid>
        </Grid>
        {virtualStandService?.documents?.items?.length ? (
          <Grid container spacing={2} style={{ marginTop: 20 }}>
            <Grid
              item
              xs={12}
              style={{
                backgroundColor: 'white',
                maxWidth: '98%',
              }}
            >
              <Grid
                item
                container
                alignItems={'center'}
                style={{
                  backgroundColor: 'white',
                }}
              >
                <Grid
                  item
                  xs={10}
                  style={{
                    paddingLeft: 15,
                  }}
                >
                  <AimTypography variant={'h3'}>
                    {i18n.page.fileList.fileName.label}
                  </AimTypography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{
                    paddingLeft: 60,
                  }}
                >
                  <AimTypography variant={'h3'}>
                    {i18n.page.fileList.fileDimension.label}
                  </AimTypography>
                </Grid>
              </Grid>
              <AimFileList
                files={virtualStandService.documents.items}
                onDownload={downloadFile}
              />
            </Grid>
          </Grid>
        ) : null}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <VirtualStandList
              virtualStandService={virtualStandService}
              onAddToCart={onAddToCart}
              sponsor={sponsor}
            />
          </Grid>
        </Grid>
      </InnerContainer>

      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </MainContainer>
  );
};

export default VirtualStand;
