import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { orderBy } from 'lodash';

import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import Grid from '@material-ui/core/Grid';

import { appState } from '@aim/common';
import {
  AimTypography,
  AimIconButton,
  AimTextField,
  AimSelect,
  AimSelectMenuItem,
  styled,
  CustomIntl,
  useAimMediaQuery,
} from '@aim/components';
import useI18n from './webcastCardGrid/i18n';

import WebcastCardGridElem from './webcastCardGrid/WebcastCardGridElem';
import { getEventWebcasts } from './webcastCardGrid/gqlHelper';
import { addDays, isSameDay } from 'date-fns';

import { getEventConfigurationPageTitle } from '../shared/utilities';

const formControlStyle = { width: '100%' };

const StyledForm = styled('form')({
  marginTop: 5,
  marginBottom: 5,
  display: 'flex',
});

const WebcastCardGrid = () => {
  const intl = CustomIntl(useIntl());
  const i18n = useI18n(intl);
  const { isMobile, isMobileOnly } = useAimMediaQuery();
  const [dateFocused, setDateFocused] = useState(false);
  const [displayWebcasts, setDisplayWebcasts] = useState([]);
  const [filter, setFilter] = useState({
    searchFilter: '',
    speaker: '',
    date: '',
  });
  const [webcasts, setWebcasts] = useState([]);
  // const [availableSpeakers, setAvailableSpeakers] = useState([]);
  const [tags, setTags] = useState([]);
  const [eventConfiguration, setEventConfiguration] = useState({});
  const [pageTitle, setPageTitle] = useState('');

  const { eventId } = useParams();

  // effects
  useEffect(() => {
    const subscription = appState.eventConfiguration.subscribe(
      setEventConfiguration
    );
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    const menuItemLabel = getEventConfigurationPageTitle(
      eventConfiguration?.eventGenericMenuItems?.items || [],
      intl,
      'on_demand'
    );
    setPageTitle(menuItemLabel);
  }, [eventConfiguration]);

  const convertToWebcast = (data) => {
    const allTags = [];
    const nextData = data.getEvent.webcasts.items.map(
      ({ webcastSpeeches, tags, date, ...rest }) => {
        const speakers = webcastSpeeches.items.map(
          (item) =>
            `${item.participation?.user?.givenName} ${item.participation?.user?.familyName}`
        );
        allTags.push([...tags]);
        return {
          speakers,
          tags,
          date: new Date(date),
          ...rest,
        };
      }
    );
    const nextAlltags = allTags
      .flat(1)
      .filter((v, i, a) => a.indexOf(v) === i)
      .map((t) => t.trim());
    setTags([...new Set(nextAlltags)]); // Remove duplicated tags

    return orderBy(nextData, 'createdAt', 'desc');
  };

  const getWebcasts = async () => {
    appState.isLoader.next(true);

    try {
      const { data } = await getEventWebcasts(eventId, false);
      const result = convertToWebcast(data);
      setWebcasts(result);
      setDisplayWebcasts(result);
      //set webcast details for the filter
      //setDisplaySpeakers(result.reduce((webcast) => webcast.speakers));

      // const flatSpeakers = result.map((webcast) => webcast.speakers).flat(1);
      // setAvailableSpeakers([...new Set(flatSpeakers)]);
      appState.isLoader.next(false);
    } catch (err) {
      console.error(err);
      appState.isLoader.next(false);
    }
  };

  const handleClearFilter = async (e) => {
    setFilter({ searchFilter: '', speaker: '', date: '' });
    setDisplayWebcasts(webcasts);
  };

  useEffect(() => {
    getWebcasts();
  }, []);

  // const handleSelectSpeakerUpdate = async (e) => {
  //   setFilter({ ...filter, speaker: e.target.value });
  // };

  const handleSelectTagsUpdate = async (e) => {
    setFilter({ ...filter, tag: e.target.value });
  };

  const updateDisplayWebcasts = (e) => {
    e.preventDefault();

    // perché un giorno indietro?
    const selectedDate = filter.date
      ? addDays(new Date(filter.date), -1)
      : null;
    setDisplayWebcasts(
      webcasts
        // .filter(
        //   (webcast) =>
        //     !filter.speaker ||
        //     webcast.speakers
        //       .map((speaker) => speaker.toLowerCase())
        //       .includes(filter.speaker.toLowerCase())
        // )
        .filter(
          (webcast) =>
            !filter.tag ||
            webcast.tags
              ?.map((tag) => tag?.toLowerCase())
              ?.includes(filter?.tag?.toLowerCase())
        )
        .filter(
          (webcast) =>
            console.log(
              'filter.date, webcast.date, selectedDate',
              filter.date,
              webcast.date?.toISOString?.(),
              selectedDate?.toISOString?.()
            ) ||
            !filter.date ||
            isSameDay(new Date(webcast.createdAt), selectedDate)
        )
        .filter((webcast) => {
          return (
            webcast.title
              ?.toLowerCase()
              ?.includes(filter?.searchFilter?.toLowerCase()) ||
            webcast.subtitle
              ?.toLowerCase()
              ?.includes(filter?.searchFilter?.toLowerCase()) ||
            webcast.topic
              ?.toLowerCase()
              ?.includes(filter?.searchFilter?.toLowerCase())
          );
        })
    );
  };

  return (
    <div
      style={{
        width: isMobileOnly ? '95%' : '70%',
        display: 'flex',
        flexDirection: 'column',
        // height: '100%',
      }}
    >
      <AimTypography variant="h1">{i18n.page.title}</AimTypography>
      <StyledForm onSubmit={(e) => updateDisplayWebcasts(e)}>
        <Grid container alignItems="center">
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <AimTextField
              formControlStyle={formControlStyle}
              value={filter.searchFilter}
              onChange={(e) =>
                setFilter({ ...filter, searchFilter: e.target.value })
              }
              placeholder={i18n.page.search}
              displayLabel={false}
              textfieldVariant="grey"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <AimTextField
              onFocus={() => setDateFocused(true)}
              onBlur={() => setDateFocused(false)}
              formControlStyle={formControlStyle}
              value={filter.date}
              onChange={(e) => setFilter({ ...filter, date: e.target.value })}
              displayLabel={false}
              placeholder={i18n.page.searchbyDate}
              type={filter.date || dateFocused ? 'date' : 'text'}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <div
              style={{
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                padding: isMobileOnly ? '0px 3px' : '0px 0px',
              }}
            >
              <AimSelect
                formControlStyle={{ flex: 1 }}
                value={filter.tag || ''} // 'none'
                displayLabel={false}
                selectPlaceholder="Select webcast typology"
                onChange={(e) => handleSelectTagsUpdate(e)}
              >
                {/* <AimSelectMenuItem key={-1} value={'none'} disabled>
                  <span style={{ color: 'lightGrey' }}>Tag</span>
                </AimSelectMenuItem> */}
                {tags.map((item, idx) => {
                  return (
                    <AimSelectMenuItem key={idx} value={item}>
                      {item}
                    </AimSelectMenuItem>
                  );
                })}
              </AimSelect>
              <AimIconButton type="submit" variant="primary">
                <CheckIcon />
              </AimIconButton>
              <AimIconButton
                variant="secondary"
                aria-label="reset"
                onClick={handleClearFilter}
              >
                <ClearIcon />
              </AimIconButton>
            </div>
          </Grid>
        </Grid>
      </StyledForm>
      <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
        <Grid container style={{ width: '100%' }} spacing={2}>
          {displayWebcasts.map((webcast) => (
            <WebcastCardGridElem
              webcast={webcast}
              key={webcast.id}
            ></WebcastCardGridElem>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default WebcastCardGrid;
