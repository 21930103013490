import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { sortBy } from 'lodash';

import Grid from '@material-ui/core/Grid';

import {
  CustomIntl,
  AimDynamicForm,
  StepDivider,
  Title,
} from '@aim/components';

import {
  getFieldsListByContexts,
  unionToContextOfUse,
} from '../../shared/fieldsHelper';

const context = ['abstract'];

const AdditionalData = ({
  title,
  control,
  reset,
  register,
  setValue,
  getValues,
  errors,
  clearErrors,
  setStandardFields,
  setCustomFields,
  customFields,
  standardFields,
  participation,
}) => {
  // Hooks
  const intl = CustomIntl(useIntl());
  const { eventId } = useParams();

  // States
  // const [standardFields, setStandardFields] = useState();
  // const [customFields, setCustomFields] = useState();
  const [formValuesLoaded, setFormValuesLoaded] = useState();

  // // Effects
  // useEffect(() => {
  //   const fetchFields = async () => {
  //     const fieldsRes = await getFieldsListByContexts({
  //       useLoader: true,
  //       eventId,
  //       contextsOfUse: context,
  //     });

  //     const { standardFields: sFields, customFields: cFields } = fieldsRes;

  //     const outStandardFields = unionToContextOfUse(
  //       sFields.filter((field) => field.contextsOfUse.items.length),
  //       context
  //     );

  //     const outCustomFields = unionToContextOfUse(
  //       cFields.filter((field) => field.contextsOfUse.items.length),
  //       context
  //     );

  //     const nextStandardFields = createCollection(
  //       outStandardFields.map((f) => ({
  //         ...f,
  //         disabled: false,
  //         isRequired: f.contextsOfUse.isRequired,
  //         dirPath: null,
  //       })),
  //       context,
  //       true
  //     );

  //     const nextCustomFields = createCollection(
  //       outCustomFields.map((f) => ({
  //         ...f,
  //         disabled: false,
  //         isRequired: f.contextsOfUse.isRequired,
  //         dirPath: null,
  //       })),
  //       context,
  //       true
  //     );

  //     setStandardFields(nextStandardFields);
  //     setCustomFields(nextCustomFields);

  //     const { fieldValues } = participation;
  //     const nextStandardDefaultValues = createDefaultValues({
  //       fields: sFields,
  //       fieldValues: fieldValues ? fieldValues.items : [],
  //     });
  //     const nextCustomDefaultValues = createDefaultValues({
  //       fields: cFields,
  //       fieldValues: fieldValues ? fieldValues.items : [],
  //     });

  //     reset({
  //       ...getValues(),
  //       standardDefaultValues: nextStandardDefaultValues,
  //       customDefaultValues: nextCustomDefaultValues,
  //     });

  //     setFormValuesLoaded(true);
  //   };
  //   fetchFields();
  // }, [eventId]);
  const { createFormFields, createDefaultValues } = AimDynamicForm;

  const createCollection = (fields, contextsOfUse, showHiddenFields) =>
    contextsOfUse
      .map((ctx) => ctx)
      .reduce((res, curr) => {
        const filtered = fields.filter(
          (f) => f.contextsOfUse.contextName === curr
        );
        const nextFiltered = showHiddenFields
          ? filtered
          : filtered.filter((f) => !f.contextsOfUse.isHidden);

        const nextFields = sortBy(nextFiltered);
        return { ...res, [curr]: nextFields };
      }, {});

  const renderFormFields = (collection, prefix) => {
    return Object.values(collection).map((fields) =>
      fields.length ? (
        createFormFields({
          prefix,
          fields,
          register,
          setValue,
          getValues,
          control,
          errors,
          variant: 'white',
          intl,
          clearErrors,
        })
      ) : (
        <></>
      )
    );
  };
  return (
    <>
      <Title>{title}</Title>
      <StepDivider />
      {formValuesLoaded && (
        <Grid container>
          {renderFormFields(standardFields, 'standardDefaultValues')}
          {renderFormFields(customFields, 'customDefaultValues')}
        </Grid>
      )}
    </>
  );
};

export default AdditionalData;
