import isBefore from 'date-fns/isBefore';
import parseISO from 'date-fns/parseISO';

import { appState, aws } from '@aim/common';
import { startOfDay, subMilliseconds } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
const { API } = aws;

/*

# Agency

## Buttons

BP - buy prepaid places
BS - buy standard places
CP - close prepaid group
CS - close standard group
---
B - add group
C - edit groups
D - delete groups
---
E - add member
F - edit member
G - delete member

## Prepaid

can BCD only
0. first bracket starts
BP + CP + BCDEFG

1. first bracket ends
BP + CP + BCDEFG

2. prepaidDeadline
BCDEFG

4. freezeDeadline
-all disabled-

## Standard

can BCD only
0. first bracket starts
BCD

1. first bracket ends
BS + CS + BCDEFG

3. deadline
BCDEFG

4. freezeDeadline
-all disabled-

*/

const GetBracketAndDeadlineQuery = `
  query GetAgencySpecifications($id: ID!) {
    getEvent(id: $id) {
      id
      fee {
        feeBracketModels {
          items {
            id
            name
            start
            end
          }
        }
      }
    }
    getDeadline(externalId: $id, type: "agency") {
      value
      externalId
      type
    }
  }
`;

export const agencyTimelineHelper = async ({ eventId }) => {
  const currentTimezone = appState.eventInfo.getValue().timezone;
  //tolto un millisecondo allo start of day per evitare di controllare anche l'is equal
  const now = subMilliseconds(
    startOfDay(utcToZonedTime(new Date(), currentTimezone)),
    1
  );
  const { data } = await API.graphql({
    query: GetBracketAndDeadlineQuery,
    variables: { id: eventId },
  }).catch((e) => {
    console.log('error-agency-buttons-state', e);
    return null;
  });

  const firstBracket = data.getEvent.fee.feeBracketModels.items.reduce(
    (res, curr) => {
      if (!res) return curr;
      if (isBefore(parseISO(curr.start), parseISO(res.start))) return curr;
      return res;
    },
    null
  );

  const { prepaidDeadline, deadline, freezeDeadline } = data.getDeadline
    ? JSON.parse(data.getDeadline.value)
    : {
        prepaidDeadline: undefined,
        deadline: undefined,
        freezeDeadline: undefined,
      };

  const isBeforeFirstBracketStarts = isBefore(
    now,
    utcToZonedTime(firstBracket.start),
    currentTimezone
  );
  const isBeforePrepaidDeadline = isBefore(
    now,
    utcToZonedTime(parseISO(prepaidDeadline), currentTimezone)
  );
  const isBeforeStandardDeadline = isBefore(
    now,
    utcToZonedTime(parseISO(deadline), currentTimezone)
  );
  const isBeforeFreezeDeadline = isBefore(
    now,
    utcToZonedTime(parseISO(freezeDeadline), currentTimezone)
  );

  const state = {
    prepaidBtnActive: false,
    punctualBtnActive: false,
    makeRequest: false,
  };

  if (isBeforeFirstBracketStarts) {
    return state;
  }
  if (isBeforePrepaidDeadline) {
    state.prepaidBtnActive = true;
  }
  if (isBeforeStandardDeadline) {
    state.punctualBtnActive = true;
  }
  if (isBeforeFreezeDeadline) {
    state.punctualBtnActive = true;
  }
  console.log('state', state);
  return state;
};

export const agencyPrepaidTimelineHelper = async ({ eventId }) => {
  const currentTimezone = appState.eventInfo.getValue().timezone;
  //tolto un millisecondo allo start of day per evitare di controllare anche l'is equal
  const now = subMilliseconds(
    startOfDay(utcToZonedTime(new Date(), currentTimezone)),
    1
  );
  const { data } = await API.graphql({
    query: GetBracketAndDeadlineQuery,
    variables: { id: eventId },
  }).catch((e) => {
    console.log('error-agency-buttons-state', e);
    return null;
  });

  const firstBracket = data.getEvent.fee.feeBracketModels.items.reduce(
    (res, curr) => {
      if (!res) return curr;
      if (isBefore(parseISO(curr.start), parseISO(res.start))) return curr;
      return res;
    },
    null
  );

  const { prepaidDeadline, freezeDeadline } = data.getDeadline
    ? JSON.parse(data.getDeadline.value)
    : {
        prepaidDeadline: undefined,
        deadline: undefined,
        freezeDeadline: undefined,
      };

  const isBeforeFirstBracketStarts = isBefore(
    now,
    utcToZonedTime(firstBracket.start),
    currentTimezone
  );
  const isBeforePrepaidDeadline = isBefore(
    now,
    utcToZonedTime(parseISO(prepaidDeadline), currentTimezone)
  );
  const isBeforeFreezeDeadline = isBefore(
    now,
    utcToZonedTime(parseISO(freezeDeadline), currentTimezone)
  );

  const state = {
    canAddGroup: false,
    canCloseGroup: false,
    canAddMember: false,
    canEditMember: false,
    prepaidBtnActive: false,
    punctualBtnActive: false,
    makeRequest: false,
    applyPenalties: false,
    closePayingRequests: false,
  };

  if (isBeforeFirstBracketStarts) {
    return state;
  } else if (isBeforePrepaidDeadline) {
    return {
      ...state,
      canCloseGroup: true,
      canAddMember: true,
      canEditMember: true,
      prepaidBtnActive: true,
    };
  } else if (isBeforeFreezeDeadline) {
    return {
      ...state,
      canAddMember: true,
      canEditMember: true,
      makeRequest: true,
      applyPenalties: true,
      closePayingRequests: true,
    };
  } else {
    return state;
  }
};
export const agencyStandardGroupTimelineHelper = async ({ eventId }) => {
  const currentTimezone = appState.eventInfo.getValue().timezone;
  //tolto un millisecondo allo start of day per evitare di controllare anche l'is equal
  const now = subMilliseconds(
    startOfDay(utcToZonedTime(new Date(), currentTimezone)),
    1
  );
  const { data } = await API.graphql({
    query: GetBracketAndDeadlineQuery,
    variables: { id: eventId },
  }).catch((e) => {
    console.log('error-agency-buttons-state', e);
    return null;
  });

  const firstBracket = data.getEvent.fee.feeBracketModels.items.reduce(
    (res, curr) => {
      if (!res) return curr;
      if (isBefore(parseISO(curr.start), parseISO(res.start))) return curr;
      return res;
    },
    null
  );

  const { deadline, freezeDeadline } = data.getDeadline
    ? JSON.parse(data.getDeadline.value)
    : {
        prepaidDeadline: undefined,
        deadline: undefined,
        freezeDeadline: undefined,
      };

  const isBeforeFirstBracketStarts = isBefore(
    now,
    utcToZonedTime(firstBracket.start),
    currentTimezone
  );
  const isBeforeStandardDeadline = isBefore(
    now,
    utcToZonedTime(parseISO(deadline), currentTimezone)
  );
  const isBeforeFreezeDeadline = isBefore(
    now,
    utcToZonedTime(parseISO(freezeDeadline), currentTimezone)
  );

  const state = {
    canAddGroup: false,
    canCloseGroup: false,
    canAddMember: false,
    canEditMember: false,
    prepaidBtnActive: false,
    punctualBtnActive: false,
    makeRequest: false,
    applyPenalties: false,
    closePayingRequests: false,
  };

  if (isBeforeFirstBracketStarts) {
    return state;
  } else if (isBeforeStandardDeadline) {
    return {
      ...state,
      canAddGroup: true,
      canCloseGroup: true,
      canAddMember: true,
      canEditMember: true,
      punctualBtnActive: true,
    };
  } else if (isBeforeFreezeDeadline) {
    return {
      ...state,
      canAddMember: true,
      canEditMember: true,
      makeRequest: true,
      applyPenalties: true,
      closePayingRequests: true,
    };
  } else {
    return state;
  }
};
