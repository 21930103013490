import React, { useEffect, useState } from 'react';
import {
  AimDialog,
  AimTypography,
  AimIconAndTextButton,
} from '@aim/components';

import TravelAndTransfePrefs from './TravelTransferPrefs';

const TravelTransferDialog = ({
  dialogState,
  onClose,
  onSuccess,
  onError,
  i18n,
}) => {
  const _i18n = i18n.dialogs.travelAndTransfer;
  const [travelTransfer, setTravelTransfer] = useState();

  // Effects
  useEffect(() => {
    if (dialogState) {
      const rawFacultyResponse =
        dialogState?.participationServices?.travelFacultyResponse;
      let facultyResponse;
      if (rawFacultyResponse)
        facultyResponse = JSON.parse(rawFacultyResponse) || {};

      setTravelTransfer({
        id: dialogState?.participationServices?.id,
        ...facultyResponse,
      });
    }
  }, [dialogState]);

  if (!dialogState) return null;

  return (
    <AimDialog
      open={dialogState.isOpen || false}
      title={_i18n.title}
      onClose={onClose}
      disableEnforceFocus
      hideAgreeButton={true}
      hideCancelButton={true}
      style={{
        width: '85%',
        minWidth: '300px',
        maxWidth: '100%',
        margin: '0 auto',
      }}
      PaperProps={{
        style: {
          paddingRight: 30,
          paddingLeft: 30,
          paddingTop: 20,
          paddingBottom: 30,
          maxWidth: '100%',
          minWidth: 600,
        },
      }}
    >
      <TravelAndTransfePrefs
        onSuccess={onSuccess}
        onError={onError}
        onClose={onClose}
        participationServices={dialogState.participationServices}
        travelTransferData={travelTransfer}
      />
    </AimDialog>
  );
};

export default TravelTransferDialog;
