import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { useIntl } from 'react-intl';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Visibility from '@material-ui/icons/Visibility';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Tooltip from '@material-ui/core/Tooltip';

import { aws, appState } from '@aim/common';
import {
  styled,
  theme,
  AimTypography,
  AimIconButton,
  AimIconAndTextButton,
  CustomIntl,
} from '@aim/components';
import useI18n from './i18n';
import useSurveys from '../shared/surveyGqlHelper';
import { ColumnContainer } from '../chatComponent/StyledComponents';

const StyledCard = styled(({ isCompleted, ...rest }) => (
  <Card {...rest}></Card>
))(({ isCompleted }) => ({
  background: theme.colors.greyScale.backgroundGrey,
  boxShadow: '0 0 black !important',
  borderRadius: '0px !important',
  cursor: isCompleted === 'true' ? '' : 'pointer',
  width: 307,
  height: 307,
  margin: 10,
}));

const formattedDate = (date) => {
  if (date) return format(parseISO(date), 'dd/MM/yyyy');
};

const HomeContainer = styled('div')({
  background: theme.colors.greyScale.white,
  flex: 1,
  overflowX: 'hidden',
  flexDirection: 'column',
  width: '100%',
});

const PageContainer = styled('div')({
  background: theme.colors.greyScale.white,
  height: '100%',
  display: 'flex',
  width: 1280,
  flexDirection: 'column',
  margin: '0 auto',
  padding: '10px',
  alignItems: 'center',
});

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  width: '100%',
  margin: '0 auto',
  backgroundColor: theme.colors.greyScale.white,
  border: `15px solid ${theme.colors.greyScale.backgroundGrey}`,
  borderTop: `4px solid ${theme.colors.greyScale.backgroundGrey}`,
  padding: '16px',
});

const StyledCardContent = styled(CardContent)({
  background: theme.colors.greyScale.backgroundGrey,
  width: 287,
  height: 287,
  padding: '20px !important',
  //   paddingRight: '30px !important',
  //   paddingTop: '10px !important',
  //   paddingBottom: '10px !important',
});

const StyledDiv = styled('div')({
  height: 267,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'center',
});

const SurveyCard = ({ surveyInvitation, eventId, participationId }) => {
  const intl = CustomIntl(useIntl());
  const i18n = useI18n(intl);
  const history = useHistory();

  return (
    <Grid item>
      <StyledCard isCompleted={surveyInvitation.isCompleted === 'true'}>
        <StyledCardContent>
          <StyledDiv>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Tooltip title={surveyInvitation.survey.title}>
                <AimTypography
                  margin="0"
                  variant="h2"
                  ellipsis
                  style={{ maxWidth: 267 }}
                >
                  {surveyInvitation.survey.title}
                </AimTypography>
              </Tooltip>
              <AimTypography margin="0" variant="h4Regular">
                {formattedDate(surveyInvitation.survey.sentAt)}
              </AimTypography>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <AimTypography margin="0" variant="h4">
                {i18n.surveys.surveyStatusLabel}
              </AimTypography>
              <AimTypography margin="0" variant="h4Regular">
                {surveyInvitation.isCompleted === 'true'
                  ? i18n.surveys.completed
                  : i18n.surveys.readyToStart}
              </AimTypography>
            </div>
            <AimIconButton
              variant="lightBlueFill"
              disabled={surveyInvitation.isCompleted === 'true'}
              onClick={() =>
                history.push(
                  `/events/${eventId}/user/${participationId}/surveys/${surveyInvitation.survey.id}`
                )
              }
            >
              <Visibility />
            </AimIconButton>
          </StyledDiv>
        </StyledCardContent>
      </StyledCard>
    </Grid>
  );
};

const Surveys = () => {
  const [surveysInvitations, setSurveysInvititations] = useState([]);
  const [participation, setParticipation] = useState([]);
  const intl = CustomIntl(useIntl());
  const i18n = useI18n(intl);
  const surveyHelper = useSurveys(false);
  const { eventId } = useParams();
  const history = useHistory();

  const getSurveys = async () => {
    const user = appState.user.getValue();
    const userAndParticipation = user?.userAndParticipation;
    setParticipation(userAndParticipation.participation);
    try {
      const _surveyInvitations = await surveyHelper.get(
        userAndParticipation.participation.id
      );
      const publishedSurveys = _surveyInvitations.items.filter(
        (surveyInvitation) => surveyInvitation?.survey?.status === 'published'
      );
      setSurveysInvititations(publishedSurveys);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getSurveys();
  }, []);

  return (
    <HomeContainer>
      <PageContainer>
        <div style={{ width: '100%' }}>
          <AimTypography variant="h1Regular">{i18n.page.myArea}</AimTypography>
          <div
            style={{
              border: `15px solid ${theme.colors.greyScale.backgroundGrey}`,
              borderBottom: 0,
              padding: 16,
              width: '100%',
            }}
          >
            <AimIconAndTextButton
              variant="none"
              onClick={() => {
                history.push(`../${participation.id}`);
              }}
            >
              <ArrowBack style={{ color: theme.colors.greyScale.grey3 }} />
              <AimTypography variant="text">{i18n.actions.back}</AimTypography>
            </AimIconAndTextButton>
          </div>

          <Container>
            <Grid container>
              <Grid
                item
                xs={12}
                style={{
                  borderBottom: `2px solid ${theme.colors.greyScale.grey5}`,
                  marginBottom: 16,
                }}
              >
                <AimTypography variant={'columnHeader'}>
                  {i18n.surveys.mySurveys}
                </AimTypography>
              </Grid>
              {surveysInvitations.length ? (
                surveysInvitations.map((surveyInvitation) => (
                  <SurveyCard
                    key={surveyInvitation.survey.id}
                    surveyInvitation={surveyInvitation}
                    eventId={eventId}
                    participationId={participation.id}
                  ></SurveyCard>
                ))
              ) : (
                <Grid items xs={12}>
                  <div
                    style={{
                      display: 'flex',
                      flex: 1,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <AimTypography variant="h4Regular">
                      {i18n.surveys.noSurvey}
                    </AimTypography>
                  </div>
                </Grid>
              )}
            </Grid>
          </Container>
        </div>
      </PageContainer>
    </HomeContainer>
  );
};

export default Surveys;
