import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { sortBy } from 'lodash';
import { useForm } from 'react-hook-form';
import CheckIcon from '@material-ui/icons/Check';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

import { appState, constants } from '@aim/common';
import {
  AimIconAndTextButton,
  AimTypography,
  AimSelectForm,
  AimSelectMenuItem,
  CustomIntl,
  AimDynamicForm,
  theme,
} from '@aim/components';

import { translation } from '../index';
import {
  createFieldValue,
  getParticipationGrantFieldValues,
  getDynamicFields,
  createParticipationGrant,
  getParticipationGrants,
  getParticipationAbstracts,
} from '../grantGqlHelper';

const formControlStyle = { width: 'calc(100% - 15px)' };

const GrantForm = ({ eventId, grantId, grant }) => {
  // Hooks
  const intl = CustomIntl(useIntl());
  const i18n = translation.grant(intl);
  const history = useHistory();
  const showLoader = () => appState.isLoader.next(true);
  const hideLoader = () => appState.isLoader.next(false);

  // React Hook Form
  const {
    control,
    reset,
    handleSubmit,
    register,
    setValue,
    getValues,
    errors,
    clearErrors,
  } = useForm();

  // States
  const [participation, setParticipation] = useState();
  const [standardFields, setStandardFields] = useState();
  const [customFields, setCustomFields] = useState();
  const [formValuesLoaded, setFormValuesLoaded] = useState();
  const [participationGrant, setParticipationGrant] = useState();
  const [partipantAbstracts, setPartipantAbstracts] = useState([]);

  const { createFormFields, createDefaultValues } = AimDynamicForm;

  // Effects
  useEffect(() => {
    const participationData = appState.user.getValue().userAndParticipation
      .participation;
    if (participationData) {
      setParticipation(participationData);
    }
  }, []);

  useEffect(() => {
    if (participation && participation.id) {
      fetchGrantFields();
    }
  }, [participation]);

  const createCollection = (fields, contextsOfUse, showHiddenFields) =>
    contextsOfUse
      .map((ctx) => ctx)
      .reduce((res, curr) => {
        const filtered = fields.filter(
          (f) => f.contextsOfUse.contextName === curr
        );
        const nextFiltered = showHiddenFields
          ? filtered
          : filtered.filter((f) => !f.contextsOfUse.isHidden);

        const nextFields = sortBy(nextFiltered);
        return { ...res, [curr]: nextFields };
      }, {});

  // FETCH
  const fetchGrantFields = async () => {
    showLoader();
    const participationGrants = await getParticipationGrants(participation.id);
    const participationGrant = participationGrants.find(
      (g) => g.participationGrantGrantId === grantId
    );
    setParticipationGrant(participationGrant);
    const participationFields = await getParticipationGrantFieldValues(
      participation.id,
      false
    );

    const fieldValues =
      participationFields.participation || participationFields;

    const {
      contextsOfUse,
      fields: [sFields, cFields],
    } = await getDynamicFields(eventId, grantId);

    const nextStandardFields = createCollection(
      sFields.map((f) => ({
        ...f,
        disabled: !!participationGrant,
        isRequired: f.contextsOfUse.isRequired,
        dirPath: null,
      })),
      contextsOfUse,
      true
    );

    const nextCustomFields = createCollection(
      cFields.map((f) => ({
        ...f,
        disabled: !!participationGrant,
        isRequired: f.contextsOfUse.isRequired,
        dirPath: null,
      })),
      contextsOfUse,
      true
    );

    setStandardFields(nextStandardFields);
    setCustomFields(nextCustomFields);

    const nextStandardDefaultValues = createDefaultValues({
      fields: sFields,
      fieldValues: fieldValues || [],
    });

    const nextCustomDefaultValues = createDefaultValues({
      fields: cFields,
      fieldValues: fieldValues || [],
    });

    reset({
      standardDefaultValues: nextStandardDefaultValues,
      customDefaultValues: nextCustomDefaultValues,
      ...(grant.type === constants.GrantType.ABSTRACT.key &&
        history.location?.state?.abstractId && {
          abstract: history.location?.state?.abstractId,
        }),
      ...(grant.type === constants.GrantType.ABSTRACT.key &&
        participationGrant && {
          abstract: participationGrant.abstractId,
        }),
    });

    if (grant.type === constants.GrantType.ABSTRACT.key) {
      const resp = await getParticipationAbstracts(participation.id);
      setPartipantAbstracts(resp);
    }
    setFormValuesLoaded(true);
    hideLoader();
  };

  const onSave = async (submittedData) => {
    const allFields = {
      ...submittedData.standardDefaultValues,
      ...submittedData.customDefaultValues,
    };
    const fieldPromises = Object.entries(allFields).map(
      ([fieldDefinition, value]) => {
        createFieldValue({
          participationFieldValuesId: participation.id,
          fieldValueFieldDefinitionId: fieldDefinition,
          value: JSON.stringify(value),
        });
      }
    );
    try {
      await Promise.all(fieldPromises);
      const inputParticipation = {
        participationGrantGrantId: grantId,
        participationGrantParticipationId: participation.id,
        requestStatus: constants.GrantStatuses.TO_BE_PROCESSED.id,
        ...(grant.type === constants.GrantType.ABSTRACT.key && {
          abstractId: submittedData.abstract,
        }),
      };
      await createParticipationGrant(inputParticipation);

      history.push({
        pathname: `/events/${eventId}/grants/${grantId}/subscribe-success`,
        state: { grantType: grant.type },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const renderFormFields = (collection, prefix) => {
    return Object.values(collection).map((fields) =>
      fields.length ? (
        createFormFields({
          prefix,
          fields,
          register,
          setValue,
          getValues,
          control,
          errors,
          variant: 'white',
          intl,
          clearErrors,
        })
      ) : (
        <></>
      )
    );
  };

  const renderSelectAbstract = () => {
    if (partipantAbstracts.length) {
      return (
        <Grid item xs={12} sm={6}>
          <AimSelectForm
            control={control}
            errors={errors}
            label={i18n.abstract.label}
            name="abstract"
            selectPlaceholder={i18n.abstract.placeholder}
            formControlStyle={formControlStyle}
            isRequired
            disabled={!!participationGrant}
            selectVariant="white"
            // hasNoneValue
          >
            {partipantAbstracts.map((item) => {
              return (
                <AimSelectMenuItem key={item.id} value={item.id}>
                  {item.title}
                </AimSelectMenuItem>
              );
            })}
          </AimSelectForm>
        </Grid>
      );
    } else {
      return (
        <Grid item xs={6}>
          <AimTypography variant="textBold">
            {i18n.abstract.label}
          </AimTypography>
          <AimTypography> - {i18n.noAbstracts} - </AimTypography>
        </Grid>
      );
    }
  };

  return (
    <div
      style={{
        backgroundColor: theme.colors.greyScale.backgroundGrey,
        padding: 32,
      }}
    >
      {formValuesLoaded && (
        <form onSubmit={handleSubmit(onSave)}>
          <Grid container>
            <Grid item xs={12}>
              <AimTypography variant={'h2'}>
                {grant ? grant.title : i18n.title}
              </AimTypography>
              <AimTypography variant="text">
                {grant ? grant?.instruction : i18n.instructions}
              </AimTypography>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{ margin: '20px 0' }} />
            </Grid>
            {renderFormFields(standardFields, 'standardDefaultValues')}
            {renderFormFields(customFields, 'customDefaultValues')}
            {grant?.type === 'abstract' && renderSelectAbstract()}
          </Grid>
          <div
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'flex-end',
              marginTop: 30,
            }}
          >
            {/* <AimIconAndTextButton
                  variant="greyFill"
                  text={i18n.buttons.cancelButton}
                  onClick={() => history.push(`/events/${eventId}/landing`)}
                >
                  <ReplayRoundedIcon />
                </AimIconAndTextButton> */}

            {!participationGrant && (
              <AimIconAndTextButton
                variant="greenFill"
                text={i18n.buttons.saveButton}
                type="submit"
              >
                <CheckIcon />
              </AimIconAndTextButton>
            )}
          </div>
        </form>
      )}
    </div>
  );
};

export default GrantForm;
