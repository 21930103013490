import React, { useRef, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { format, isWithinInterval, isAfter, parseISO } from 'date-fns';

import CallMadeIcon from '@material-ui/icons/CallMade';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PublishIcon from '@material-ui/icons/Publish';

import { constants, utilities } from '@aim/common';
import {
  CustomIntl,
  AimTablePage,
  MassiveImportDialog,
  AimSnackbarSeverity,
  AimSnackbar,
} from '@aim/components';

import { translation } from './agencyGroupMembers/index';

import {
  updateParticipationIsDeleted,
  updateAgencyBudget,
  updateGroup,
  closeAgencyGroup,
} from './agencyGroupMembers/gqlHelper';
import {
  getModificationData,
  getDeadlinesParsed,
  checkIsPostDeadlineAndBeforeFreeze,
  checkIsBeforeFreezeDeadline,
  checkIsGroupDisabled,
  addCreateReqToHistoricalOfParticipation,
  sortBy,
} from './agencyGroupMembers/dataHelper';
import { AgencyPrepaidBudgetDialog } from './agencyGroupMembers/AgencyPrepaidBudgetDialog';
// import MassiveImport from './shared/MassiveImport';
import { agencyStandardGroupTimelineHelper } from '../shared/agencyTimelineHelper';

import { useDataHelper } from './shared/dataHelper';

const cluster = constants.Clusters.Groups.id;
const serviceType = constants.ProductType.agencyGroup;

const getAgencyGroupQuery = /* GraphQL */ `
  query GetAgencyGroup(
    $id: ID!
    $agencyId: ID!
    $eventId: ID!
    $gatewayServiceId: String
    $serviceId: String
    $serviceType: String
    $clientId: String
  ) {
    getDeadline(externalId: $eventId, type: "agency") {
      externalId
      type
      value
    }
    getAgency(id: $agencyId) {
      id
      name
      prepaidBudget
    }
    getEvent(id: $eventId) {
      id
      gateways(filter: { service: { eq: $gatewayServiceId } }) {
        items {
          id
          service
          paymentType
          shop {
            id
            gatewayType
            iban
          }
        }
      }
    }
    getAgencyGroup(id: $id) {
      id
      name
      isPrepaid
      closingDate
      penaltiesPaidAt
      isDeleted
      participations(filter: { isDeleted: { ne: true } }) {
        items {
          id
          givenName
          familyName
          email
          createdAt
          updatedAt
          isDeleted
          profile {
            id
            name
            profileFeeBrackets {
              items {
                id
                priceOnAir
                priceOnSite
                feeBracket {
                  id
                  feeDateRange {
                    id
                    start
                    end
                  }
                  feeBracketModel {
                    id
                    start
                    end
                  }
                }
              }
            }
          }
          feeDateRange {
            id
            start
            end
          }
          participationHistorical {
            items {
              id
              modificationRequest {
                id
                requestStatus
                participationId
                createdAt
                updatedAt
              }
            }
          }
          cluster
          isParticipant
          isSpeaker
          isReviewer
        }
      }
      modificationRequests(filter: { isDeleted: { ne: true } }) {
        items {
          id
          familyName
          givenName
          email
          status
          createdAt
          updatedAt
          isDeleted
          modificationRequest {
            requestId
            participationId
            requestStatus
            createdAt
            updatedAt
            type
            participationRequest {
              isDeleted
              participationSubject {
                id
                isDeleted
              }
            }
          }
          profile {
            id
            name
          }
          cluster
          isParticipant
          isSpeaker
          isReviewer
        }
      }
    }
    listProducts(
      filter: { serviceType: { eq: "agency" }, serviceId: { eq: $serviceId } }
    ) {
      items {
        productId
        clientId
        clientType
        serviceId
        serviceType
        createdAt
        payment {
          id
          paymentType
        }
      }
    }
    productByClientIdServiceTypeServiceId(
      clientId: $clientId
      serviceTypeServiceId: {
        eq: { serviceId: $serviceId, serviceType: $serviceType }
      }
    ) {
      items {
        productId
        clientId
        clientType
        serviceId
        serviceType
        createdAt
        payment {
          id
          paymentId
          paymentType
          paymentIdMonetaOnLine
          paymentIdIngenico
          paymentIdGpWebpay
          paymentError
          amount
          paymentStatus
          createdAt
          updatedAt
          isDeleted
        }
      }
    }
  }
`;

const AgencyStandardMembers = () => {
  const result = new Date(Date.now());
  result.setDate(result.getDate() - 100);
  const { eventId, agencyId, groupId } = useParams();
  const history = useHistory();
  const dataHelper = useDataHelper();

  // ref
  const groupMembersRef = useRef();
  const agencyPrepaidBudgetRef = useRef();
  const deadlinesRef = useRef();
  const remainingPlacesRef = useRef();
  const sponsorListRef = useRef();
  const closingDateRef = useRef();
  const penaltiesPaidAtRef = useRef();
  // const deadlineRef = useRef();
  const isMinNumberRef = useRef();
  // const applyPenaltiesRef = useRef();
  const useResidualBudgetRef = useRef();
  const totalCostRef = useRef(0);
  const totalPenaltiesRef = useRef(0);
  const gatewayRef = useRef();
  const modificationRequestsRef = useRef();
  const modificationReqsAcceptedRef = useRef();
  const isPostDeadlineRef = useRef();
  // const timelineRef = useRef();
  const modReqsToPayRef = useRef();

  const loadDataRef = useRef();

  // state
  const [timeline, setTimeline] = useState();
  const [isUseBudgetDialogOpen, setIsUseBudgetDialogOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  // const [agencyGroup, setAgencyGroup] = useState();
  // const [noProfileItems, setNoProfileItems] = useState([]);

  // const fetchAgencyGroup = async () => {
  //   const agencyGroup = await getGroupData(groupId);
  //   setAgencyGroup(agencyGroup);
  // };

  // useEffect(() => {
  //   fetchAgencyGroup();
  // }, []);

  useEffect(() => {
    if (!eventId) return;

    const getTimeline = async () => {
      const nextTimeline = await agencyStandardGroupTimelineHelper({ eventId });
      // console.log({ nextTimeline }, 'nexttimeline');
      setTimeline(nextTimeline);
    };
    getTimeline();
  }, [eventId]);

  useEffect(() => {
    if (timeline) {
      checkoutResponse();
    } else {
      // alert('no checkout response');
    }
  }, [timeline]);

  const [massiveDialog, setMassiveDialog] = useState({
    isOpen: false,
  });
  const [rowsLoaded, setRowsLoaded] = useState(false);

  // get translated objects for this route
  const intl = CustomIntl(useIntl());
  const i18n = translation.aimTablePage(intl);

  const checkoutResponse = async () => {
    const isCheckoutSuccess = location.pathname.includes('checkout-success');
    const isCheckoutError = location.pathname.includes('checkout-error');
    const query = new URLSearchParams(location.search);
    const errorcode = query.get('errorcode');
    const order = query.get('merchantorderid');
    const paymentId = query.get('pid');

    if (isCheckoutError && errorcode && errorcode > 0) {
      const messageError = '';
      switch (parseInt(errorcode)) {
        case 1: // query error in lambda
          messageError(`${i18n.checkout.errors.error1} ${order}`);
          break;
        case 2: // response error in lambda
          messageError(`${i18n.checkout.errors.error2} ${order}`);
          break;
        case 3: // error in lambda, init payment
          messageError(`${i18n.checkout.errors.error3} ${order}`);
          break;
        case 4: // duplicate order or other errors
          messageError(`${i18n.checkout.errors.error4} ${order}`);
          break;
        default:
          break;
      }
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: messageError,
      });
    } else if (isCheckoutSuccess) {
      // order by credit card
      // if (paymentId) {
      if (timeline.canCloseGroup) {
        // all at once: participants paid and group closed
        // await updateGroup({ id: groupId, closingDate: new Date() });

        await closeAgencyGroup(
          {
            id: groupId,
            closingDate: new Date(),
          },
          groupMembersRef.current.map((p) => p.id)
        );
        setSnackbar({
          isOpen: true,
          severity: AimSnackbarSeverity.success,
          message: i18n.snackbar.closingGroupSuccess,
        });
      } else if (timeline.closePayingRequests) {
        // all at once: penalties paid
        await updateGroup({
          id: groupId,
          penaltiesPaidAt: new Date(),
        });
        setSnackbar({
          isOpen: true,
          severity: AimSnackbarSeverity.success,
          message: i18n.snackbar.payingPenaltiesSuccess,
        });
      }
      // fetchAgencyGroup();
      // history.push(
      //   `/events/${eventId}/agency/${agencyId}/group-list/${groupId}/standard`
      // );

      loadDataRef.current();

      // }

      // const prepaidBudget = agencyPrepaidBudgetRef.current;
      // if (useResidualBudgetRef.current) {
      //   const residual =
      //     totalCostRef.current >= prepaidBudget
      //       ? 0
      //       : prepaidBudget - totalCostRef.current;
      //   // if (residual >= 0) await updateAgencyBudget(agencyId, residual);
      // }

      // history.push(location.pathname.split('/').slice(0, -1).join('/'));
    }
  };

  const handleAdd = () =>
    !timeline.makeRequest
      ? history.push(
          `/events/${eventId}/participation/${cluster}/${groupId}/new?t=standard`
        )
      : history.push(
          `/events/${eventId}/participation/${cluster}/${groupId}/new/post-deadline?t=standard`
        );

  const handleEdit = (row) =>
    !timeline.makeRequest
      ? history.push(
          `/events/${eventId}/participation/${cluster}/${groupId}/${row.id}?t=standard`
        )
      : history.push(
          `/events/${eventId}/participation/${cluster}/${groupId}/${row.id}/post-deadline?t=standard`
        );

  const handleDelete = async (id) => {
    await updateParticipationIsDeleted(id);
  };

  const handlePayAndClose = async (useResidualBudget) => {
    const prepaidBudget = agencyPrepaidBudgetRef.current;
    useResidualBudgetRef.current = useResidualBudget;
    // const totalCostToPay =
    //   closingDateRef?.current && !penaltiesPaidAtRef?.current
    //     ? totalPenaltiesRef.current
    //     : totalCostRef.current;
    const totalCostToPay = isPostDeadlineRef.current
      ? totalPenaltiesRef.current
      : totalCostRef.current;

    let amount = useResidualBudget
      ? totalCostToPay - prepaidBudget
      : totalCostToPay;
    amount = amount > 0 ? amount / 100 : 0;
    const isSomethingToPay = amount > 0; // totalCostRef.current > prepaidBudget;

    if (useResidualBudget) {
      const residual =
        totalCostToPay >= prepaidBudget ? 0 : prepaidBudget - totalCostToPay;
      if (residual >= 0) await updateAgencyBudget(agencyId, residual);
    }

    // payment by credit card or bank transfer
    if (isSomethingToPay) {
      if (gatewayRef.current.paymentType === 'both') {
        // in gateway setted both (in payment opt you can choose credit card or bank transfer)
        history.push({
          pathname: `/events/${eventId}/agency/${agencyId}/payment-opt`,
          state: {
            amount,
            prodId: groupId,
            serviceId: eventId,
            clientId: agencyId,
            serviceType,
            clientType: constants.Clusters.Agency.id,
            prodType: constants.ProductType.agency,
            // isService: true,
            relUrl: `group-list/${groupId}/standard`,
            gateway: gatewayRef.current,
          },
        });
      } else {
        // in gateway setted one payment option (credit card or bank transfer)
        history.push({
          pathname: `/events/${eventId}/agency/${agencyId}/payment`,
          state: {
            amount,
            prodId: groupId,
            serviceId: eventId,
            clientId: agencyId,
            serviceType,
            clientType: constants.Clusters.Agency.id,
            prodType: constants.ProductType.agency,
            // isService: true,
            relUrl: `group-list/${groupId}/standard`,
            gateway: gatewayRef.current,
          },
        });
      }
    } else {
      // payment by residual budget
      if (timeline.canCloseGroup) {
        // all at once: nothing to pay, set date to close group
        // const groupUpdated = await updateGroup({
        //   id: groupId,
        //   closingDate: new Date(),
        // });

        await closeAgencyGroup(
          {
            id: groupId,
            closingDate: new Date(),
          },
          groupMembersRef.current.map((p) => p.id)
        );
        setSnackbar({
          isOpen: true,
          severity: AimSnackbarSeverity.success,
          message: i18n.snackbar.closingGroupSuccess,
        });
        // fetchAgencyGroup();
        loadDataRef.current();
      } else if (timeline.closePayingRequests) {
        // all at once: nothing to pay, set date to close group
        const groupUpdated = await updateGroup({
          id: groupId,
          penaltiesPaidAt: new Date(),
        });
        setSnackbar({
          isOpen: true,
          severity: AimSnackbarSeverity.success,
          message: i18n.snackbar.payingPenaltiesSuccess,
        });
        // fetchAgencyGroup();
        loadDataRef.current();
      }
      history.push(
        `/events/${eventId}/agency/${agencyId}/group-list/${groupId}/standard`
      );
    }
  };

  const canNotAddMember = (checked, getAgencyGroup) => {
    return !(
      (
        (timeline?.canAddMember && !getAgencyGroup?.closingDate) ||
        (timeline?.canAddMember && timeline?.applyPenalties)
      )
      // !agencyGroup?.penaltiesPaidAt)
    );
  };

  const canNotEditMember = (getAgencyGroup) => {
    return !(
      (
        (timeline?.canEditMember && !getAgencyGroup?.closingDate) ||
        (timeline?.canEditMember && timeline?.applyPenalties)
      )
      // !agencyGroup?.penaltiesPaidAt)
    );
  };

  const canNotCloseGroup = (data) => {
    return !(
      timeline?.canCloseGroup &&
      !data?.getAgencyGroup?.closingDate &&
      isMinNumberRef.current
    );
  };

  const canNotPayApprovedChanges = () => {
    return !timeline?.closePayingRequests;
    // return !(timeline?.closePayingRequests && !agencyGroup?.penaltiesPaidAt);
  };

  const calcNModReqsToPay = (data) => {
    const sortedProducts = sortBy(
      data.productByClientIdServiceTypeServiceId?.items.filter((product) =>
        utilities.isValidPaymentForAgency(product.payment)
      ),
      'createdAt'
    ).reverse();

    const modReqsToPay = data?.getAgencyGroup?.modificationRequests?.items
      .filter((req) => {
        return (
          sortedProducts.length === 0 ||
          (isAfter(
            parseISO(req.modificationRequest.updatedAt),
            parseISO(sortedProducts[0]?.createdAt)
          ) &&
            req.modificationRequest.requestStatus ===
              constants.ModificationRequestStatus.ACCEPTED)
        );
      })
      .filter(
        (item) =>
          item.modificationRequest.requestStatus !==
          constants.ModificationRequestStatus.PENDING
      );

    // console.log('modReqsToPay ', modReqsToPay.length);
    return modReqsToPay.length;
  };

  const dataLoader = {
    query: getAgencyGroupQuery,
    variables: {
      id: groupId,
      agencyId,
      eventId,
      gatewayServiceId: constants.GatewayServices().GROUP.key,
      serviceId: eventId,
      serviceType,
      clientId: agencyId,
    },
    transform: (data) => {
      // console.log('agencyGroup ', data.getAgencyGroup);

      agencyPrepaidBudgetRef.current = data.getAgency?.prepaidBudget || 0;
      closingDateRef.current = data?.getAgencyGroup.closingDate;
      penaltiesPaidAtRef.current = data?.getAgencyGroup.penaltiesPaidAt;
      const deadlines = getDeadlinesParsed(data?.getDeadline?.value);

      // here timeline undefined (table page not accept conditional rendering),
      // check if a isPostDeadline
      const isPostDeadline = checkIsPostDeadlineAndBeforeFreeze(
        data?.getDeadline?.value
      );
      isPostDeadlineRef.current = isPostDeadline;
      // console.log('timeline?.makeRequest ', timeline?.makeRequest)

      // set gateway for checking payment type to redirect user in payment options (credit card / bank transfer) or checkout payment
      gatewayRef.current = data?.getEvent.gateways.items[0];

      // n of reqs accepted and n of total reqs
      // (all modification reqs including those of removed participants)
      // requestsAcceptedRef.current = data.getAgencyGroup.modificationRequests?.items.reduce(
      //   (res, curr) =>
      //     (res +=
      //       curr.modificationRequest.requestStatus === 'ACCEPTED' ? 1 : 0),
      //   0
      // );
      // modificationRequestsRef.current =
      //   data.getAgencyGroup.modificationRequests?.items.length;
      // totalPenaltiesRef.current =
      //   deadlineRef.current.penalty * requestsAcceptedRef.current;

      modificationRequestsRef.current = 0;
      modificationReqsAcceptedRef.current = 0;
      totalPenaltiesRef.current = 0;

      // request for a new participation (type CREATE)
      let modificationRequests = [];
      if (isPostDeadline) {
        modificationRequests = data.getAgencyGroup.modificationRequests?.items
          .filter(
            (x) =>
              x.modificationRequest.requestStatus ===
                constants.ModificationRequestStatus.PENDING &&
              x.modificationRequest.type ===
                constants.ModificationRequestTypes.CREATE
          )
          .map((req) => {
            modificationRequestsRef.current += 1;
            return {
              ...req,
              createdAt: format(new Date(req.createdAt), 'dd/MM/yyyy'),
              profile: req.profile?.name,
              isGroupClosed: !!data.getAgencyGroup.closingDate,
              isPenaltiesPaid: !!data.getAgencyGroup.penaltiesPaidAt,
              modification: req.modificationRequest.requestStatus,
              isReadOnly: true,
              noProfile: !req.profile,
            };
          });

        // only mod requests not paid (pax modified after last created product)
        // const sortedProducts = sortBy(
        //   data.listProducts?.items,
        //   'createdAt'
        // ).reverse();

        const nModReqsToPay = calcNModReqsToPay(data);

        // calc total penalties = penalty * reqs accepted
        // totalPenaltiesRef.current =
        //   deadlineRef.current.penalty * modificationReqsAcceptedRef.current;
        totalPenaltiesRef.current = deadlines.penalty * nModReqsToPay;
      }

      totalCostRef.current = 0;
      let modificationReqs = {};
      const participations = data.getAgencyGroup.participations?.items
        // .filter((x) => x.participationHistorical.items.length === 0)
        .map((p) => {
          // price for standard participation (if not deadpostline)
          const price = utilities.getParticipationPrice(p);
          totalCostRef.current += price * 100;

          // // calc total cost for standard pax (not postdeadline)
          // // p.participationHistorical.items.length > 0 ? has modification requests
          // // if has modification reqs add penalty for every modification (see below for calc)
          // // else add price of ticket
          // const hasModificationReqs =
          //   p.participationHistorical.items.length > 0;
          // totalCostRef.current += hasModificationReqs ? 0 : price * 100;

          // participationHistorical empty if creation of participants in post deadline was accepted
          // need to calc requests accepted and penalties to pay
          // added participation historical manually to current participation
          if (isPostDeadline) {
            addCreateReqToHistoricalOfParticipation(
              data.getAgencyGroup.modificationRequests,
              p
            );
          }

          // n. of mod requests
          modificationRequestsRef.current +=
            p.participationHistorical.items.length;

          modificationReqs = getModificationData(p);
          // num of modification reqs accepted
          modificationReqsAcceptedRef.current +=
            modificationReqs.modification.reqs[
              constants.ModificationRequestStatus.ACCEPTED
            ];

          // pax with modifications refused??
          // numOfPax += mods[constants.ModificationRequestStatus.PENDING] === 0;

          return {
            ...p,
            createdAt: format(new Date(p.createdAt), 'dd/MM/yyyy'),
            profile: p.profile?.name,
            noProfile: !p.profile,
            isGroupClosed: !!data.getAgencyGroup.closingDate,
            isPenaltiesPaid: !!data.getAgencyGroup.penaltiesPaidAt,
            isPostDeadline,
            price,
            modification:
              modificationReqs.modification.label !== ''
                ? modificationReqs.modification.label
                : '-',
          };
        });
      isMinNumberRef.current = participations.length >= deadlines.minNumber;

      groupMembersRef.current = participations;

      return [...modificationRequests, ...participations];
    },
    bigStats: (tData, data) => {
      return [
        {
          title: 'amountDue',
          value: () => {
            // return closingDateRef?.current && isPostDeadlineRef.current
            //   ? (!penaltiesPaidAtRef?.current &&
            //       dataHelper.formatNumber(
            //         dataHelper.decodeDbNumber(totalPenaltiesRef.current)
            //       )) ||
            //       '- €'
            //   : dataHelper.formatNumber(
            //       dataHelper.decodeDbNumber(totalCostRef.current)
            //     );

            const isPostDeadline = checkIsPostDeadlineAndBeforeFreeze(
              data?.getDeadline?.value
            );
            const isBeforeFreezeDeadline = checkIsBeforeFreezeDeadline(
              data?.getDeadline?.value
            );
            const isGroupDisabled = checkIsGroupDisabled(
              data?.getDeadline?.value,
              data?.getAgencyGroup?.closingDate
            );

            let currValue = '-';
            // is group active
            if (!isGroupDisabled) {
              if (!isBeforeFreezeDeadline) {
                return currValue;
              }
              if (isPostDeadline) {
                currValue = dataHelper.formatNumber(
                  dataHelper.decodeDbNumber(totalPenaltiesRef.current)
                );
              } else if (!data.getAgencyGroup?.closingDate) {
                currValue = dataHelper.formatNumber(
                  dataHelper.decodeDbNumber(totalCostRef.current)
                );
              }
            }
            return currValue;
          },
        },
        {
          title: 'nRecord',
          value: (tData) => tData.length,
        },
      ];
    },
    stats: (tData, data) => {
      return [
        {
          title: 'status',
          value: (_, data) =>
            data?.getAgencyGroup?.closingDate
              ? i18n.page.statuses.closed
              : i18n.page.statuses.open,
        },
        {
          title: 'minMembers',
          value: () => getDeadlinesParsed(data?.getDeadline?.value).minNumber,
        },
        {
          title: 'closingDate',
          value: () =>
            (data?.getAgencyGroup.closingDate &&
              dataHelper.getFormattedDate(data?.getAgencyGroup.closingDate)) ||
            '',
        },
        {
          title: 'postCloseChangeDeadline',
          value: () =>
            dataHelper.getFormattedDate(
              getDeadlinesParsed(data?.getDeadline?.value).freezeDeadline
            ) || '',
        },
        {
          title: 'modificationRequests',
          value: () =>
            modificationRequestsRef.current
              ? modificationRequestsRef.current
              : '',
        },
        {
          title: 'requestsAccepted',
          value: () => modificationReqsAcceptedRef.current || '',
        },
      ];
    },
  };

  const table = {
    isRowSelectable: false,
    columnsTemplate: [
      {
        id: 'givenName',
        numeric: false,
        disablePadding: false,
        isCheckbox: false,
        isDisabled: false,
      },
      {
        id: 'familyName',
        numeric: false,
        disablePadding: false,
        isCheckbox: false,
        isDisabled: false,
      },
      {
        id: 'email',
        numeric: false,
        disablePadding: false,
        isCheckbox: false,
        isDisabled: false,
      },
      {
        id: 'profile',
        numeric: false,
        disablePadding: false,
        isCheckbox: false,
        isDisabled: false,
      },
      {
        id: 'price',
        numeric: false,
        disablePadding: false,
        isCheckbox: false,
        isDisabled: false,
      },
      {
        id: 'cluster',
        numeric: false,
        disablePadding: false,
        isCheckbox: false,
        isDisabled: false,
      },
      {
        id: 'createdAt',
        numeric: false,
        disablePadding: false,
        isCheckbox: false,
        isDisabled: false,
      },
      // {
      //   id: 'isParticipant',
      //   isCheckbox: true,
      // },
      // {
      //   id: 'isSpeaker',
      //   isCheckbox: true,
      // },
      // {
      //   id: 'isReviewer',
      //   isCheckbox: true,
      // },
      {
        id: 'modification',
        isCheckbox: false,
      },
    ],
    filters: [
      {
        column: 'givenName',
        type: 'string',
      },
      {
        column: 'familyName',
        type: 'string',
      },
      {
        column: 'email',
        type: 'string',
      },
      {
        column: 'statusLabel',
        type: 'select',
      },
    ],
    rowAction: {
      // profile: {
      //   icon: <icon style={{ width: 0 }} />,
      //   variant: 'transparent',
      //   disabledWhen: ({ noProfile, nRows }) => {
      //     const noProfiles = [...noProfileItems];
      //     console.log('noProfiles nRows ', noProfiles, nRows);

      //     // setNoProfileItems([...noProfiles, noProfile]);
      //     return true;
      //   },
      //   // hiddenWhen: () => true,
      // },
      edit: {
        icon: <EditIcon />,
        variant: 'lightBlueFill',
        disabledWhen: ({ isReadOnly }, { getDeadline, getAgencyGroup }) =>
          isReadOnly ||
          checkIsGroupDisabled(
            getDeadline?.value,
            getAgencyGroup?.closingDate
          ) ||
          canNotEditMember(getAgencyGroup),
        onClick: handleEdit,
      },
      delete: {
        icon: <ClearIcon />,
        variant: 'redFill',
        disabledWhen: ({ isReadOnly }, { getDeadline, getAgencyGroup }) =>
          isReadOnly ||
          checkIsGroupDisabled(
            getDeadline?.value,
            getAgencyGroup?.closingDate
          ) ||
          canNotEditMember(getAgencyGroup),
        onClick: 'dialog',
        dialog: 'delete',
        snackbar: 'delete',
      },
    },
  };

  const model = (loadData) => {
    loadDataRef.current = loadData;
    return {
      header: {
        backButton: {
          onClick: () =>
            history.push(`/events/${eventId}/agency/${agencyId}/group-list`),
        },
        action: {
          goToWelcomePage: {
            icon: <ArrowForwardIcon />,
            variant: 'lightBlue',
            onClick: handleGoToWelcomePage,
          },
          goToFeeTable: {
            icon: <ArrowForwardIcon />,
            variant: 'lightBlue',
            onClick: handleGoToFeeTable,
          },
          export: {
            icon: <CallMadeIcon />,
            variant: 'lightBlue',
            onClick: (checkedItems, tData) =>
              handleExportExcel(checkedItems.length ? checkedItems : tData),
          },
          massiveImport: {
            icon: <PublishIcon />,
            variant: 'violet',
            hiddenWhen: (checkedItems, data) => {
              return data?.getAgencyGroup?.closingDate;
            },
            onClick: () =>
              setMassiveDialog({
                isOpen: true,
                deadlinePath: addDeadlinePath(),
              }),
          },
          //
          add: {
            icon: <AddIcon />,
            variant: 'yellow',
            disabledWhen: (checkedItems, data) =>
              // checkIsGroupDisabled(
              //   data?.getDeadline?.value,
              //   data?.getAgencyGroup?.closingDate
              // ) ||
              !checkIsBeforeFreezeDeadline(data?.getDeadline?.value) ||
              canNotAddMember(checkedItems, data?.getAgencyGroup),
            onClick: handleAdd,
          },

          // add: {
          //   icon: <AddIcon />,
          //   variant: 'yellow',
          //   hiddenWhen: (checkedItems, { getAgencyGroup }) => {
          //     return canNotAddMember(checkedItems, getAgencyGroup);
          //   },
          //   onClick: handleAdd,
          // },

          // ...(!agencyGroup?.closingDate && {
          payAndClose: {
            icon: <ShoppingCartIcon />,
            variant: 'yellow',
            disabledWhen: (checkedItems, data, tData) => {
              const noProfileFound = tData.findIndex((row) => row.noProfile);
              return noProfileFound > -1 || canNotCloseGroup(data);
            },
            hiddenWhen: (checkedItems, data, tData) => {
              // return data?.getAgencyGroup?.closingDate;
              return checkIsPostDeadlineAndBeforeFreeze(
                data?.getDeadline?.value
              );
            },
            onClick: () => setIsUseBudgetDialogOpen(true),
          },
          // }),
          // ...(agencyGroup?.closingDate && {
          payApprovedChanges: {
            icon: <ShoppingCartIcon />,
            variant: 'yellow',
            disabledWhen: (checkedItems, data, tData) => {
              const noProfileFound = tData.findIndex((row) => row.noProfile);
              const nModReqsToPay = calcNModReqsToPay(data);
              // const isPostDeadline = checkIsPostDeadlineAndBeforeFreeze(
              //   data?.getDeadline?.value
              // );
              return (
                noProfileFound > -1 ||
                nModReqsToPay === 0 || // if no reqs to pay
                checkIsGroupDisabled(
                  data?.getDeadline?.value,
                  data?.getAgencyGroup?.closingDate
                ) ||
                canNotPayApprovedChanges()
              );
            },
            hiddenWhen: (checkedItems, data, tData) => {
              return !checkIsPostDeadlineAndBeforeFreeze(
                data?.getDeadline?.value
              );
            },
            // onClick: () => setIsUseBudgetDialogOpen(true),
            onClick: () => handlePayAndClose(),
          },
          // }),
        },
      },
      preTableComponent: null,
      dataLoader,
      table,
      dialog: {
        delete: {
          onAgree: async (row) => {
            await handleDelete(row.id);
            await loadData();
          },
          onDisagree: () => {},
        },
        // deleteAll: {
        //   onAgree: async (checkedItems) => {
        //     await handleDeleteAll(checkedItems);
        //     await loadData();
        //   },
        //   onDisagree: () => {},
        // },
        // close: {
        //   onAgree: async () => {
        //     await handleCloseGroup();
        //     await loadData();
        //   },
        //   onDisagree: () => {},
        // },
      },
      snackbar: {
        delete: {},
      },
    };
  };

  const handleGoToFeeTable = async () => {
    history.push(`/events/${eventId}/agency/${agencyId}/fee`);
  };

  const handleGoToWelcomePage = async () => {
    history.push(`/events/${eventId}/agency/${agencyId}`);
  };

  const isAfterDeadline = () => {
    if (!deadlinesRef.current?.deadline) return false;
    return isAfter(new Date(), new Date(deadlinesRef.current.deadline));
  };

  const isBetweenDeadlineAndFreezeDeadline = () => {
    if (
      !deadlinesRef.current?.deadline ||
      !deadlinesRef.current?.freezeDeadline
    )
      return false;
    return isWithinInterval(new Date(), {
      start: new Date(deadlinesRef.current.deadline),
      end: new Date(deadlinesRef.current.freezeDeadline),
    });
  };

  // const handleAdd = ({ isPostDeadline }) => {
  //   if (remainingPlacesRef.current > 0) {
  //     history.push(
  //       `/events/${eventId}/participation/${cluster}/${
  //         sponsorListRef.current.id
  //       }/new${isPostDeadline ? '/post-deadline' : ''}`
  //     );
  //     return;
  //   }
  // };

  // const handleEdit = ({ id, isPostDeadline }) => {
  //   history.push(
  //     `/events/${eventId}/participation/${cluster}/${
  //       sponsorListRef.current.id
  //     }/${id}${isPostDeadline ? '/post-deadline' : ''}`
  //   );
  // };

  // const handleCloseGroup = async () => {
  //   await closeListGroup(sponsorListRef.current.id);
  //   callLambdaConflictsOnCreate(eventId);
  // };

  // const handleDeleteAll = async (checkedItems) =>
  //   await Promise.all(
  //     checkedItems?.map(async (p) => await updateParticipationIsDeleted(p.id))
  //   );

  const handleExportExcel = (rows) => {
    const header = [];
    const keys = [];
    model().table.columnsTemplate.forEach((column) => {
      // if (i === 0) return;
      header.push(i18n.table.columns?.[column.id] || column.id);
      keys.push(column.id);
    });
    const nextRows = rows.map((row) => {
      return {
        ...row,
        isParticipant: row.isParticipant ? 'true' : 'false',
        isSpeaker: row.isSpeaker ? 'true' : 'false',
        isReviewer: row.isReviewer ? 'true' : 'false',
      };
    });
    utilities.objectsToExcel({
      filename: 'participation',
      header,
      keys,
      objects: nextRows,
    });
  };

  const addDeadlinePath = () => {
    return isPostDeadlineRef.current ? '/post-deadline' : '';
  };

  // const isDeadlinePassed = () => {
  //   return isAfter(Date.now(), new Date(isPostDeadlineRef.current));
  // };

  const importBackUrl = `/events/${eventId}/agency/${agencyId}/services-configuration/list`;

  // console.log({ timeline });
  // if (!timeline) return null;
  return (
    <div style={{ width: '90%' }}>
      <AimTablePage model={model} translation={i18n} intl={intl} />
      <MassiveImportDialog
        state={{
          remainingPlaces: remainingPlacesRef?.current,
          backUrl: importBackUrl,
        }}
        eventId={eventId}
        cluster={cluster}
        clusterId={sponsorListRef.current?.id}
        massiveDialog={massiveDialog}
        setMassiveDialog={setMassiveDialog}
        intl={intl}
        history={history}
        disableEnforceFocus
        customPath={`/events/${eventId}/participation/${cluster}/${groupId}/import${
          isPostDeadlineRef.current ? '/post-deadline' : ''
        }`}
        customBackUrl={`/events/${eventId}/agency/${agencyId}/group-list/${groupId}/standard`}
      />

      <AgencyPrepaidBudgetDialog
        isDialogOpen={isUseBudgetDialogOpen}
        onAgree={handlePayAndClose}
        onDisagree={() => setIsUseBudgetDialogOpen(false)}
        i18n={i18n}
        prepaidBudget={agencyPrepaidBudgetRef.current}
        defChecked={true}
        agencyId={agencyId}
      />
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </div>
  );
};

export default AgencyStandardMembers;
