import { aws, appState } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const listBillingDocsQuery = /* GraphQL */ `
  query GetSponsorBillingDocs($billingDocSponsorId: ID!) {
    billingDocByBillingDocSponsorIdIsPublished(
      isPublished: { eq: "true" }
      billingDocSponsorId: $billingDocSponsorId
    ) {
      items {
        id
        docNumber
        billingCustomer
        isPublished
        type
        billingDocSponsorId
        amount
        sponsor {
          id
        }
        document {
          id
          originalName
          extension
          isMandatory
          size
        }
        billingFlow {
          id
          flowNumber
          docType
        }
      }
    }
  }
`;

export const listSponsorBillingDocs = (sponsorId, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    API.graphql({
      query: listBillingDocsQuery,
      variables: { billingDocSponsorId: sponsorId },
    })
      .then((response) =>
        resolve(response.data.billingDocByBillingDocSponsorIdIsPublished)
      )
      .catch((e) => {
        console.error('get-sponsor-invoices', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });
