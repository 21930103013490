import React, { useRef, useState, useEffect } from 'react';
import { format, isAfter } from 'date-fns';

import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { constants, utilities } from '@aim/common';

import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import ArrowBack from '@material-ui/icons/ArrowBack';
import CartIcon from '@material-ui/icons/ShoppingCart';
import ArrowForward from '@material-ui/icons/ArrowForward';
import ViewIcon from '@material-ui/icons/Visibility';

import { MainContainer, InnerContainer } from './agency/shared/Containers';

import { useDataHelper } from './shared/dataHelper';
import { getNewPrepaidBudget } from './agency/shared/dataHelper';

import { agencyGqlHelper } from './agency/shared/agencyGqlHelper';
import { agencyTimelineHelper } from '../shared/agencyTimelineHelper';

import {
  translation,
  AgencyCard,
  // AgencyDialog,
  AgencyBudgetDialog,
  AgencyCalcBudgetDialog,
  AgencyGroupsDialog,
} from './agency/agencyPurchase';
// import { useBuyOperations } from './shared/buyOperationsGqlHelper';

import {
  AimIconAndTextButton,
  AimTypography,
  AimSnackbarSeverity,
  AimSnackbar,
  CustomIntl,
} from '@aim/components';
const { encodeDbNumber, decodeDbNumber } = useDataHelper();

// const showLoader = () => appState.isLoader.next(true);
// const hideLoader = () => appState.isLoader.next(false);

const cluster = constants.Clusters.Groups.id;

const AgencyPurchase = () => {
  //Hooks
  const history = useHistory();
  const location = useLocation();
  const { eventId, agencyId, service } = useParams();
  const intl = CustomIntl(useIntl());
  const i18n = translation.agencyPurchase(intl);
  const agencyHelper = agencyGqlHelper();
  const dataHelper = useDataHelper();

  //State
  const [agency, setAgency] = useState([]);
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  // const [paymentType, setPaymentType] = useState();
  // const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isBudgetDialogOpen, setIsBudgetDialogOpen] = useState(false);
  const [isCalcBudgetDialogOpen, setIsCalcBudgetDialogOpen] = useState(false);
  const [isGroupsDialogOpen, setIsGroupsDialogOpen] = useState(false);
  const [timeline, setTimeline] = useState();

  //Ref
  const gatewayRef = useRef();
  const agencyPrepaidGroup = useRef();

  //Effects
  useEffect(() => {
    if (!isBudgetDialogOpen || !isCalcBudgetDialogOpen) {
      fetchAgency();
    }
    checkoutResponse();
  }, []);

  useEffect(() => {
    if (eventId) {
      fetchEventGateway();
    }
  }, [eventId]);

  // useEffect(() => {
  //   fetchAgencyPrepaidGroup();
  // });

  // todo: move this update of prepaid budget into lambda payment
  // when payment lambdas will be fixed by creating one with the layer
  const updatePrepaidBudget = async (paymentId) => {
    const resAgency = await agencyHelper.getAgency(agencyId);
    const resPayment = await agencyHelper.getPayment(paymentId);
    await agencyHelper.updateAgency(
      {
        id: agencyId,
        prepaidBudget:
          (parseInt(resPayment?.amount, 10) || 0) +
          (parseInt(resAgency?.prepaidBudget, 10) || 0),
      },
      false
    );
    fetchAgency();
  };

  const checkoutResponse = () => {
    const isCheckoutSuccess = location.pathname.includes('checkout-success');
    const isCheckoutError = location.pathname.includes('checkout-error');
    const query = new URLSearchParams(location.search);
    const errorcode = query.get('errorcode');
    const order = query.get('merchantorderid');
    const paymentId = query.get('pid');

    if (isCheckoutError && errorcode && errorcode > 0) {
      const messageError = '';
      switch (parseInt(errorcode)) {
        case 1: // query error in lambda
          messageError(`${i18n.checkout.errors.error1} ${order}`);
          break;
        case 2: // response error in lambda
          messageError(`${i18n.checkout.errors.error2} ${order}`);
          break;
        case 3: // error in lambda, init payment
          messageError(`${i18n.checkout.errors.error3} ${order}`);
          break;
        case 4: // duplicate order or other errors
          messageError(`${i18n.checkout.errors.error4} ${order}`);
          break;
        default:
          break;
      }
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: messageError,
      });
    } else if (isCheckoutSuccess) {
      // order by credit card
      if (paymentId) {
        updatePrepaidBudget(paymentId).then(() => {
          setSnackbar({
            isOpen: true,
            severity: AimSnackbarSeverity.success,
            message: i18n.snackbar.updateBudgetSuccess,
          });
          history.push(`/events/${eventId}/agency/${agencyId}`);
        });
      }
    }
  };

  // const fetchAgencyPrepaidGroup = async () => {
  //   const respGroups = await agencyHelper.getAgencyGroups(agencyId);
  //   const prepaidGroup = respGroups.find((g) => g.isPrepaid);
  //   agencyPrepaidGroup.current = prepaidGroup;
  // };

  const fetchEventGateway = async () => {
    const nextGateway = await agencyHelper.getEventGateway({
      id: eventId,
      serviceId: constants.GatewayServices().GROUP.key,
    });

    gatewayRef.current = nextGateway;
  };

  const fetchAgency = async () => {
    const respAgency = await agencyHelper.getAgencySpecifications({
      id: eventId,
      agencyId,
    });

    const prepaidBudgetNew = await getNewPrepaidBudget({
      prepaidBudget: respAgency.prepaidBudget,
      // agency: respAgency,
      agencyId: respAgency.id,
    });
    setAgency({ ...respAgency, prepaidBudgetNew });

    const timeline = await agencyTimelineHelper({
      eventId,
    });
    setTimeline(timeline);

    // get penalties
    // calc budget: subtract penalties if closing prepaid group has passed
  };

  const purchaseBudget = async (
    { prepaidBudget } /* , purchaseNow = true */
  ) => {
    // const gateway = await getAgencyGateway();

    if (!parseInt(prepaidBudget, 10)) {
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: 'budget is not a valid number',
      });
      return;
    }

    if (gatewayRef.current === undefined) {
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.snackbar.gatewayNotExist,
      });
      return;
    }

    if (gatewayRef.current.paymentType === undefined) {
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.snackbar.paymentTypeNotExist,
      });
      return;
    }

    if (gatewayRef.current.paymentType === 'both') {
      history.push({
        pathname: `/events/${eventId}/agency/${agencyId}/payment-opt`,
        state: {
          amount: prepaidBudget,
          gateway: gatewayRef.current,
          prodId: agencyId,
          serviceId: eventId,
          serviceType: constants.ProductType.agencyPrepaidBudget,
          clientId: agencyId,
          clientType: constants.Clusters.Agency.id,
        },
      });
    } else {
      history.push({
        pathname: `/events/${eventId}/agency/${agencyId}/payment`,
        state: {
          amount: prepaidBudget,
          gateway: gatewayRef.current,
          prodId: agencyId,
          serviceId: eventId,
          serviceType: constants.ProductType.agencyPrepaidBudget,
          clientId: agencyId,
          clientType: constants.Clusters.Agency.id,
        },
      });
    }
  };

  // const handleClose = () => setIsDialogOpen(false);
  const handleBudgetClose = () => setIsBudgetDialogOpen(false);
  const handleCalcBudgetClose = () => setIsCalcBudgetDialogOpen(false);
  const handleGroupsClose = () => setIsGroupsDialogOpen(false);

  const renderPrepaidHeader = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
        }}
      >
        <AimTypography variant="h3">{i18n.page.budgetPrepaid}</AimTypography>
        <AimIconAndTextButton
          variant="lightBlueFill"
          onClick={() => setIsCalcBudgetDialogOpen(true)}
          text={i18n.page.seeBudget.title}
          style={{ margin: 10 }}
        >
          <ViewIcon />
        </AimIconAndTextButton>
      </div>
    );
  };

  const addParticipant = (groupId) => {
    history.push(
      `/events/${eventId}/participation/${cluster}/${groupId}/new${
        // timeline?.puntual?.standardGroup?.buy
        timeline?.makeRequest ? '/post-deadline?t=standard' : '?t=standard'
      }`
    );
  };

  const renderPrepaidBudget = ({ agency }) => {
    // {dataHelper.formatNumber(
    //   dataHelper.decodeDbNumber(
    //     agency.closingDatePrepaidGroup
    //       ? agency.prepaidBudget
    //       : agency.prepaidBudgetNew
    //   )
    // )}
    const { prepaidBudget, prepaidBudgetNew, deadlines } = agency;

    if (deadlines) {
      let { penalty, prepaidDeadline } = deadlines;
      prepaidDeadline = new Date(prepaidDeadline);

      if (isAfter(new Date(), prepaidDeadline)) {
        // price + penalties

        // if (agency.closingDatePrepaidGroup) {
        //   return dataHelper.formatNumber(
        //     dataHelper.decodeDbNumber(agency.prepaidBudget)
        //   );
        // } else {
        return dataHelper.formatNumber(
          dataHelper.decodeDbNumber(agency.prepaidBudgetNew)
        );
        // }
      }
    }

    // get penalties
    // calc budget: subtract penalties if closing prepaid group has passed
  };

  return (
    <MainContainer>
      <div>
        <InnerContainer>
          <div style={{ display: 'flex-root', marginTop: 20 }}>
            <Tooltip title={i18n.page.back.tooltip}>
              <AimIconAndTextButton
                variant="text"
                text={i18n.page.back.button}
                onClick={() => history.push(`/events/${eventId}/landing`)}
              >
                <ArrowBack />
              </AimIconAndTextButton>
            </Tooltip>
          </div>
          <Grid container>
            <Grid item xs={12}>
              <AimTypography variant={'h2'}>{i18n.page.title}</AimTypography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <AgencyCard
                title={renderPrepaidHeader()}
                style={{ whiteSpace: 'nowrap' }}
                textStyle={{
                  fontWeight: 'bold',
                  textAlign: 'left',
                }}
                variant="h3"
              >
                <AimTypography
                  variant="h1"
                  boxStyle={{
                    fontWeight: 'normal',
                    fontSize: '40px',
                    textAlign: 'left',
                    padding: '0px 10px',
                  }}
                >
                  {dataHelper.formatNumber(
                    dataHelper.decodeDbNumber(agency.prepaidBudgetNew ?? 0)
                  )}
                </AimTypography>
              </AgencyCard>
            </Grid>
          </Grid>
          <Grid container>
            {/* <Grid
              item
              xs={12}
              sm={6}
              lg={3}
              style={{ display: 'flex', height: '140px', marginTop: 10 }}
            >
              <AgencyCard
                title={i18n.page.prepaidUntil}
                style={{ whiteSpace: 'nowrap', flex: 1, marginRight: '10px' }}
                textStyle={{
                  textAlign: 'center',
                }}
              >
                <AimTypography
                  variant="h1"
                  boxStyle={{ fontWeight: 'normal', textAlign: 'center' }}
                >
                  {agency?.firstDeadline &&
                    format(new Date(agency?.firstDeadline), 'dd/MM/yyyy')}
                </AimTypography>
              </AgencyCard>
            </Grid> */}
            <Grid
              item
              xs={12}
              sm={6}
              lg={4}
              style={{ display: 'flex', height: '140px', marginTop: 10 }}
            >
              <AgencyCard
                title={i18n.page.closingPrepaidGroup}
                style={{ flex: 1, marginRight: '10px' }}
                textStyle={{
                  textAlign: 'center',
                }}
              >
                <AimTypography
                  variant="h1"
                  boxStyle={{ fontWeight: 'normal', textAlign: 'center' }}
                >
                  {agency?.deadlines?.prepaidDeadline &&
                    format(
                      new Date(agency?.deadlines.prepaidDeadline),
                      'dd/MM/yyyy'
                    )}
                </AimTypography>
              </AgencyCard>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={4}
              style={{ display: 'flex', height: '140px', marginTop: 10 }}
            >
              <AgencyCard
                title={i18n.page.closingGroupNoPrepaid}
                style={{ flex: 1, marginRight: '10px' }}
                textStyle={{
                  textAlign: 'center',
                }}
              >
                <AimTypography
                  variant="h1"
                  boxStyle={{ fontWeight: 'normal', textAlign: 'center' }}
                >
                  {agency?.deadlines?.deadline &&
                    format(new Date(agency?.deadlines.deadline), 'dd/MM/yyyy')}
                </AimTypography>
              </AgencyCard>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={4}
              style={{ display: 'flex', height: '140px', marginTop: 10 }}
            >
              <AgencyCard
                title={i18n.page.paidChangesUntil}
                style={{ flex: 1 }}
                textStyle={{
                  textAlign: 'center',
                }}
              >
                <AimTypography
                  variant="h1"
                  boxStyle={{ fontWeight: 'normal', textAlign: 'center' }}
                >
                  {agency?.deadlines?.freezeDeadline &&
                    format(
                      new Date(agency?.deadlines.freezeDeadline),
                      'dd/MM/yyyy'
                    )}
                </AimTypography>
              </AgencyCard>
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={6}
              lg={6}
              style={{ display: 'flex', marginTop: 10 }}
            >
              <AgencyCard
                title={i18n.page.purchase}
                style={{ flex: 1, marginRight: 10 }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    flexWrap: 'wrap',
                    width: '100%',
                    padding: 10,
                  }}
                >
                  <div style={{ paddingLeft: 20, paddingBottom: 20 }}>
                    <ul>
                      <AimTypography variant="text">
                        {i18n.page.purchaseList}
                      </AimTypography>
                      <li style={{ marginLeft: 25 }}>
                        <AimTypography variant="text">
                          <b>{i18n.page.prepaid.label}: </b>
                          {i18n.page.purchasePrepaidItem}
                        </AimTypography>
                      </li>
                      <li style={{ marginLeft: 25 }}>
                        <AimTypography variant="text">
                          <b>{i18n.page.purchasePunctualItem}</b>
                        </AimTypography>
                      </li>
                    </ul>
                  </div>
                  <AgencyCard
                    description={i18n.page.prepaidDescr}
                    noBorder
                    style={{
                      flex: 1,
                      marginRight: '10px',
                      backgroundColor: '#84819A47',
                    }}
                    textStyle={{
                      textAlign: 'center',
                    }}
                  >
                    <Tooltip
                      title={i18n.page.prepaidButton.tooltip}
                      style={{
                        width: '120px',
                        display: 'flex',
                        alignSelf: 'center',
                        margin: '20px 0px',
                      }}
                    >
                      <AimIconAndTextButton
                        variant="yellowFill"
                        onClick={() => setIsBudgetDialogOpen(true)}
                        text={i18n.page.prepaidButton.title}
                        disabled={
                          !timeline?.prepaidBtnActive ||
                          agency.closingDatePrepaidGroup
                        }
                      >
                        <CartIcon />
                      </AimIconAndTextButton>
                    </Tooltip>
                  </AgencyCard>
                  <AgencyCard
                    description={i18n.page.punctualDescr}
                    noBorder
                    style={{
                      flex: 1,
                      backgroundColor: '#84819A47',
                    }}
                    textStyle={{
                      textAlign: 'center',
                    }}
                  >
                    <Tooltip
                      title={i18n.page.punctualButton.tooltip}
                      style={{
                        width: '120px',
                        display: 'flex',
                        alignSelf: 'center',
                        margin: '20px 0px',
                      }}
                    >
                      <AimIconAndTextButton
                        variant="yellowFill"
                        onClick={() => setIsGroupsDialogOpen(true)}
                        text={i18n.page.punctualButton.title}
                        disabled={!timeline?.punctualBtnActive}
                      >
                        <CartIcon />
                      </AimIconAndTextButton>
                    </Tooltip>
                  </AgencyCard>
                </div>
              </AgencyCard>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={6}
              style={{ display: 'flex', marginTop: 10 }}
            >
              <AgencyCard
                title={i18n.page.manageYourGroups}
                style={{
                  flex: 1,
                }}
              >
                <div style={{ paddingLeft: 20, paddingBottom: 20 }}>
                  <ul>
                    <AimTypography variant="text">
                      {i18n.page.manageYourGroupsList}
                    </AimTypography>
                    <li style={{ marginLeft: 25 }}>
                      <AimTypography variant="text">
                        {i18n.page.manageYourGroupsItem1}
                      </AimTypography>
                    </li>
                    <li style={{ marginLeft: 25 }}>
                      <AimTypography variant="text">
                        {i18n.page.manageYourGroupsItem2}
                      </AimTypography>
                    </li>
                  </ul>
                </div>
                <div
                  style={{
                    flex: 1,
                    flexDirection: 'column',
                    alignItems: 'start',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: '20px',
                  }}
                >
                  <AimIconAndTextButton
                    variant="lightBlueFill"
                    onClick={() =>
                      history.push({
                        pathname: `/events/${eventId}/agency/${agencyId}/group-list`,
                        state: { prepaidBudget: agency.prepaidBudget },
                      })
                    }
                    text={i18n.page.goToGroupsButton.title}
                  >
                    <ArrowForward />
                  </AimIconAndTextButton>
                </div>
              </AgencyCard>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: 'flex', margin: '10px 0px 20px' }}
            >
              <AgencyCard
                noBorder
                title={i18n.page.seeFeeTable}
                style={{
                  flex: 1,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <AimIconAndTextButton
                  variant="lightBlueFill"
                  onClick={() =>
                    history.push(`/events/${eventId}/agency/${agencyId}/fee`)
                  }
                  text={i18n.page.seeFeeTableButton.title}
                  style={{ margin: 10 }}
                >
                  <ViewIcon />
                </AimIconAndTextButton>
              </AgencyCard>
            </Grid>
          </Grid>
          {/* <AgencyDialog
            isDialogOpen={isDialogOpen}
            onAgree={(data) => addToCart(data, true)}
            onDisagree={handleClose}
            i18n={i18n}
            eventId={eventId}
          /> */}
          <AgencyBudgetDialog
            isDialogOpen={isBudgetDialogOpen}
            onAgree={(data) => purchaseBudget(data, true)}
            onDisagree={handleBudgetClose}
            i18n={i18n}
            budget={agency.prepaidBudget}
          />
          <AgencyCalcBudgetDialog
            isDialogOpen={isCalcBudgetDialogOpen}
            onDisagree={handleCalcBudgetClose}
            i18n={i18n}
            eventId={eventId}
            intl={intl}
          />
          <AgencyGroupsDialog
            isDialogOpen={isGroupsDialogOpen}
            onAgree={(data) => addParticipant(data)}
            onDisagree={handleGroupsClose}
            i18n={i18n}
            agencyId={agencyId}
          />
          <AimSnackbar
            open={snackbar.isOpen}
            onClose={() => setSnackbar({ isOpen: false })}
            severity={snackbar.severity}
          >
            {snackbar.message}
          </AimSnackbar>
        </InnerContainer>
      </div>
    </MainContainer>
  );
};

export default AgencyPurchase;
