/* Signup page */
import React, { useEffect, useState } from 'react';
import { aws, appState } from '@aim/common';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import LoginLayout from './shared/LoginLayout';
import Grid from '@material-ui/core/Grid';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {
  AimTextField,
  AimTypography,
  AimIconAndTextButton,
  AimSnackbar,
  AimSnackbarSeverity,
  CustomIntl,
} from '@aim/components';
import { translations } from './shared/translations';
import { useIntl } from 'react-intl';

const { isLoader } = appState;

const showLoader = () => {
  isLoader.next(true);
};

const hideLoader = () => {
  isLoader.next(false);
};

const IdentityVerification = () => {
  const intl = CustomIntl(useIntl());
  const i18n = translations.identityVerification(intl);
  const { eventId } = useParams();
  const { search } = useLocation();
  const [errorMessage, setErrorMessage] = useState();
  const [username, setUsername] = useState();
  const [verificationSuccess, setVerificationSuccess] = useState();
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const history = useHistory();

  useEffect(() => {
    const params = new URLSearchParams(search);
    const username = params.get('username');
    const code = params.get('code');
    if (!code) setUsername(username);
    else
      verify({
        code,
        username,
      });
  }, []);

  const goToLogin = () => {
    history.push(`/events/${eventId}/login`);
  };

  const validationSchemaCode = {
    code: yup.string().required(i18n.codeRequired),
  };

  const {
    register: registerCode,
    handleSubmit: handleSubmitCode,
    errors: errorsCode,
  } = useForm({
    reValidateMode: 'onChange',
    mode: 'onChange',
    shouldUnregister: false,
    defaultValues: {},
    resolver: yupResolver(yup.object().shape(validationSchemaCode)),
  });

  const formControlStyle = { width: 'calc(100% - 10px)' };

  const verify = async (submittedData) => {
    showLoader();
    try {
      const usernameToVerify = username || submittedData.username;
      const verificationCode = submittedData.code;
      let result = await aws.Auth.confirmSignUp(
        usernameToVerify,
        verificationCode
      );
      if (result) {
        setVerificationSuccess('true');
        window.dataLayer.push({
          event: 'user-confirmed',
          event_id: eventId,
          username: username || submittedData.username,
        });
      }
    } catch (error) {
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: error.message,
      });
    }
    hideLoader();
  };

  const resendCode = async () => {
    showLoader();
    try {
      await aws.Auth.resendSignUp(username);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.success,
        message: i18n.codeSent,
      });
    } catch (error) {
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: error.message,
      });
    }
    hideLoader();
  };

  return (
    <>
      <LoginLayout title={i18n.pageTitle}>
        {username && !verificationSuccess && (
          <>
            <AimTypography variant="h4Regular">
              {`${i18n.subtitle1} `} <b>{username}</b>
              {`${i18n.subtitle2}`}
            </AimTypography>
            <AimTypography variant="h4Regular">{i18n.subtitle3}</AimTypography>
            <form onSubmit={handleSubmitCode(verify)}>
              <Grid container>
                <Grid item xs={12}>
                  <AimTextField
                    inputRef={registerCode}
                    textfieldVariant="grey"
                    name="code"
                    placeholder={i18n.code}
                    formControlStyle={formControlStyle}
                  />
                  {errorsCode['code'] && (
                    <AimTypography variant="formError">
                      *{errorsCode['code'].message}
                    </AimTypography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  {errorMessage && (
                    <AimTypography variant="formError">
                      {errorMessage ? (
                        <p style={{ color: 'red' }}>{errorMessage}</p>
                      ) : null}
                    </AimTypography>
                  )}
                </Grid>
                <AimTypography>
                  {i18n.notReceivedCode}
                  <b style={{ cursor: 'pointer' }} onClick={resendCode}>
                    {` ${i18n.sendAgain}`}
                  </b>
                </AimTypography>
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: '20px 14px 0px',
                  }}
                >
                  <AimIconAndTextButton
                    text={i18n.confirm}
                    type="submit"
                    isUpperCase
                    style={{ width: '100%' }}
                  ></AimIconAndTextButton>
                </Grid>
              </Grid>
            </form>
          </>
        )}
        {verificationSuccess && (
          <div>
            <AimTypography variant="h4Regular">
              {i18n.welcomeToOurPlatform}
            </AimTypography>
            <Grid container>
              <Grid
                item
                xs={12}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <AimIconAndTextButton text={i18n.login} onClick={goToLogin}>
                  <ArrowForwardIcon />
                </AimIconAndTextButton>
              </Grid>
            </Grid>
          </div>
        )}
      </LoginLayout>
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </>
  );
};

export default IdentityVerification;
