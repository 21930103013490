import { appState, aws } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const getEventWebcastsQuery = /* GraphQL */ `
  query getEventWebcasts($id: ID!) {
    getEvent(id: $id) {
      webcasts(filter: { isDeleted: { ne: true } }) {
        items {
          clusters
          createdAt
          description
          event {
            id
          }
          id
          image
          link
          videoId
          subtitle
          tags
          title
          topic
          views
          date
          webcastSpeeches {
            items {
              participation {
                familyName
                givenName
                businessName
              }
            }
          }
        }
      }
    }
  }
`;

const getWebcastQuery = /* GraphQL */ `
  query getWebcast($id: ID!) {
    getWebcast(id: $id) {
      id
      event {
        id
      }
      title
      subtitle
      description
      image
      tags
      topic
      link
      videoId
      videoProvider
      clusters
      views
      webcastSpeeches {
        items {
          id
          speakerType
          createdAt
          updatedAt
          participation {
            id
            businessName
            familyName
            givenName
            email
          }
        }
        nextToken
      }
      isDeleted
      createdAt
      updatedAt
    }
  }
`;

const updateWebcastMutation = /* GraphQL */ `
  mutation UpdateWebcast(
    $input: UpdateWebcastInput!
    $condition: ModelWebcastConditionInput
  ) {
    updateWebcast(input: $input, condition: $condition) {
      id
    }
  }
`;

export const getEventWebcasts = (eventId, isLoader = true) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();
    API.graphql({
      query: getEventWebcastsQuery,
      variables: { id: eventId },
    })
      .then((resp) => {
        resolve(resp);
      })
      .catch((e) => {
        console.error('get-event-webcasts', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });

export const updateWebcastView = async (webcastId, isLoader = true) => {
  const webcast = await getWebcast(webcastId, isLoader);
  webcast.views += 1;
  await updateWebcast({ id: webcast.id, views: webcast.views }, isLoader);
};

export const getWebcast = (id, isLoader = true) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();
    API.graphql({
      query: getWebcastQuery,
      variables: { id },
    })
      .then(({ data }) => {
        resolve(data.getWebcast);
      })
      .catch((e) => {
        console.error('get-webcast', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });

const updateWebcast = (input, isLoader = true) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();
    API.graphql({
      query: updateWebcastMutation,
      variables: { input },
    })
      .then(({ data }) => {
        resolve(data.updateWebcast.id);
      })
      .catch((e) => {
        console.error('update-webcast', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });
