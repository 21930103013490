export default {
  myGrants: (intl) => ({
    back: intl.formatMessage({
      description: 'my grants page Back button label',
      defaultMessage: 'back',
    }),
    title: intl.formatMessage({
      description: 'table column title',
      defaultMessage: 'Title',
    }),
    submissionDate: intl.formatMessage({
      description: 'table column submission date',
      defaultMessage: 'Submission Date',
    }),
    status: intl.formatMessage({
      description: 'table column status',
      defaultMessage: 'Status',
    }),
    viewDetail: intl.formatMessage({
      description: 'grant view detail',
      defaultMessage: 'Detail',
    }),
  }),
};
