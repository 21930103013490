import React, { useState, useEffect } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
// import { isMobile } from 'react-device-detect';
import { sortBy } from 'lodash';
import { useElementSize } from 'usehooks-ts';

import {
  getFormattedDate,
  useSponsorDetailHelper,
  parseRichText,
  getFullBucketPath,
  getFileThumb,
  downloadFromS3,
} from './sponsorDetail/sponsorDetailHelper';
import xIcon from '../../assets/images/X.svg';

import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';
import PublishIcon from '@material-ui/icons/Publish';
import CheckIcon from '@material-ui/icons/Check';
// import BusinessIcon from '@material-ui/icons/Business';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import ProfileHeader from './shared/profile/ProfileHeader';
// import ProfileBackground from './../../assets/images/profile-background.png';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { usePublicPage } from './shared/publicPageGqlHelper';
import { useDataHelper } from './shared/dataHelper';
import { FileTypeEnum } from './sponsorDetail/enums';
import { VirtualStandPreview } from './VirtualStandPreview';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import CustomHeaderPage from './shared/CustomHeaderPage';

import useI18n from './sponsorDetail/i18n';
import { appState, constants } from '@aim/common';

import {
  theme,
  styled,
  AimIconAndTextButton,
  AimTypography,
  AimCard,
  AimIconButton,
  AimRichText,
  AimDialog,
  AimSnackbar,
  AimLink,
  CustomIntl,
  useAimMediaQuery,
} from '@aim/components';

const MainContainer = styled('div')({
  width: '100%',
  height: 'inherit',
  display: 'flex',
  flexDirection: 'column',
  background: theme.colors.greyScale.backgroundGrey,
  alignItems: 'center',
});

const Container = styled('div')(({ isMobile }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  width: isMobile ? 'auto' : '70%',
  padding: '10px',
}));

export const DocumentsItem = styled('div')({
  width: '100%',
  height: '50px',
  backgroundColor: '#F8F6FA',
  borderRadius: 4,
  // marginBottom: 5,
  padding: 10,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'pointer',
});

export const ImageTitle = styled('div')({
  height: '50px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const ContactCardContent = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'start',
});

const StyledPreviewDiv = styled(({ imageUrl, ...rest }) => <div {...rest} />)(
  ({ isMobile, imageUrl }) => ({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    // width: isMobile ? '100%' : 1228,
    height: isMobile ? 'auto' : 688,
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: 'cover',
    backgroundColor: '#cccccc',
    backgroundPositionX: 'center',
  })
);

const SponsorDetail = () => {
  //Hooks
  const intl = CustomIntl(useIntl());
  const i18n = useI18n(intl);
  const history = useHistory();
  const { sponsorId, eventId } = useParams();
  const { isMobile } = useAimMediaQuery();
  const { get } = useSponsorDetailHelper();
  const publicPageHelper = usePublicPage();
  const {
    isValidPurchase,
    searchSponsorHighestRankingVirtualStand,
  } = useDataHelper();
  const [previewDivRef, { width, height }] = useElementSize();

  //State
  const [user] = useState(appState.user.getValue());
  const [participation, setParticipation] = useState();
  const [collapsableCtrl, setCollapsableCtrl] = useState({});
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [fetchedInfo, setFetchedInfo] = useState({});
  const [createDialog, setDialog] = useState({ isOpen: false });
  const [
    isVirtualStandPreviewEnabled,
    setIsVirtualStandPreviewEnabled,
  ] = useState(false);
  const [sponsorVirtualStand, setSponsorVirtualStand] = useState();
  const [isvideoStarted, setIsVideoStarted] = useState(false);

  //Effects
  useEffect(() => {
    if (!fetchedInfo.id) {
      getUserParticipation();
      fetchInfoSponsor();
    } else {
      if (
        participation &&
        ((!isEditMode && !fetchedInfo.isPublished) ||
          (isEditMode && !fetchedInfo.isPurchased))
      ) {
        // redirect if the page is not public and is not mine or if the page is mine and not purchased
        history.push(`/events/${eventId}/sponsors`);
      }
    }
  }, [fetchedInfo, participation]);

  const handleResize = () => {
    location.reload();
  };
  React.useEffect(() => {
    window.addEventListener('resize', handleResize, false);
  }, []);

  useEffect(() => {
    if (sponsorVirtualStand && history?.location?.state?.visitStand)
      setIsVirtualStandPreviewEnabled(true);
  }, [sponsorVirtualStand]);

  // Functions
  const getUserParticipation = async () => {
    try {
      const nextParticipation = appState.user.getValue()?.userAndParticipation
        ?.participation;
      setParticipation(nextParticipation);
    } catch (err) {
      console.error(err);
    }
  };

  const youtube_parser = (url) => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[7].length == 11 ? match[7] : false;
  };

  const vimeo_parser = (url) => {
    const regExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
    const match = url.match(regExp);
    return match && match[5].length ? match[5] : false;
  };

  const isValidURL = (str) => {
    var pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
    return !!pattern.test(str);
  };

  const fetchInfoSponsor = async () => {
    const res = await get(sponsorId);
    if (res) {
      console.log('res.documents ', res.documents);
      findVirtualStand(res?.sponsorVirtualStand?.items);
      const data = {
        id: res.id,
        businessName: res.businessName,
        name: res.name,
        // contacts: res.contacts.items.length > 0 ? res.contacts.items : null,
        contacts:
          res.contacts.items.length > 0
            ? res.contacts.items.map((c) => {
                const city = { label: c.city, value: c.city };
                const country = { label: c.country, value: c.country };
                return { ...c, city, country };
              })
            : null,
        description: res.description,
        isPublished: res?.publicPage?.isPublic || false,
        isPurchased: isValidPurchase(res?.publicPage?.buyOperation?.purchase),
        publicPage: res?.publicPage,
        videoTitle: res.videoTitle,
        videoSubtitle: res.videoSubtitle,
        videoLink: res.videoLink,
        videoEmbeddedLink: res.videoEmbeddedLink,
        videoProvider: res.videoProvider,
        videoId:
          res.videoProvider && res.videoLink
            ? res.videoProvider === 'youtube'
              ? youtube_parser(res.videoLink)
              : vimeo_parser(res.videoLink)
            : null,
        documents: {
          items:
            res.documents.items.length > 0
              ? sortBy(res.documents.items, 'name').filter(
                  (doc) =>
                    doc.id !== res.profileImage?.id &&
                    doc.id !== res.profileCover?.id &&
                    doc.id !== res.videoCover?.id
                )
              : [],
        },
        profileCover: res.profileCover
          ? await getFullBucketPath(
              eventId,
              sponsorId,
              FileTypeEnum.profileCover,
              res.profileCover
            )
          : null,
        profileImage: res.profileImage
          ? await getFullBucketPath(
              eventId,
              sponsorId,
              FileTypeEnum.profileImage,
              res.profileImage
            )
          : null,
        videoCover: res.videoCover
          ? await getFullBucketPath(
              eventId,
              sponsorId,
              FileTypeEnum.videoCoverImage,
              res.videoCover
            )
          : null,
      };
      setFetchedInfo(data);
      res.contacts.items?.map((contact, idx) => {
        setCollapsableCtrl((prevState) => ({
          ...prevState,
          [idx]: false,
        }));
      });

      //analytics
      window.dataLayer.push({
        event: 'sponsor',
        sponsor_id: res?.id,
        sponsor_name: res?.name,
        event_id: eventId,
        username: user?.userAndParticipation?.participation?.username,
        name: user?.userAndParticipation?.participation?.givenName,
        surname: user?.userAndParticipation?.participation?.familyName,
        email: user?.userAndParticipation?.participation?.email,
      });
    }
  };

  const handleExpandClick = (index) => {
    setCollapsableCtrl((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const handlePublishClick = () => {
    const input = {
      id: fetchedInfo.publicPage.id,
      isPublic: !fetchedInfo.isPublished,
    };
    publicPageHelper.update(input).finally(() => {
      setFetchedInfo((prevState) => ({
        ...prevState,
        isPublished: !prevState.isPublished,
      }));
    });
  };

  const toggleVirtualStandPreview = () => {
    setIsVirtualStandPreviewEnabled(!isVirtualStandPreviewEnabled);
  };

  const findVirtualStand = (sponsorVirtualStandItems) => {
    const sponsorVirtualStands = sponsorVirtualStandItems?.filter(
      (i) =>
        i?.buyOperation?.purchase && isValidPurchase(i?.buyOperation?.purchase)
    );
    const vs = searchSponsorHighestRankingVirtualStand(sponsorVirtualStands);
    if (vs?.virtualRoomLink) setSponsorVirtualStand(vs);
  };

  //Constants
  const styleCard = {
    height: 310,
    margin: 0,
  };
  const contactCard = {
    borderRadius: 4,
    // paddingBottom: 10,
    position: 'relative',
  };

  const GetIFrameByProvider = ({ video }) => {
    return (
      ((video.videoProvider === 'vimeo' || !video.videoProvider) && (
        <iframe
          // style={isMobile ? { height: 300 } : { width: 1228, height: 688 }}
          src={
            `${video.videoEmbeddedLink}` ||
            (video.videoId && `https://player.vimeo.com/video/${video.videoId}`)
          }
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            width: '100%',
            height: '100%',
            border: 'none',
          }}
          // frameBorder="0"
          // allow="autoplay"
          // allowFullScreen="allowfullscreen"
          // mozallowfullscreen="mozallowfullscreen"
          // msallowfullscreen="msallowfullscreen"
          // oallowfullscreen="oallowfullscreen"
          // webkitallowfullscreen="webkitallowfullscreen"
        />
      )) ||
      (video.videoProvider === 'youtube' && (
        <iframe
          style={isMobile ? { height: 300 } : { width: width, height: height }}
          src={
            `${video.videoEmbeddedLink}` ||
            (video.videoId && `https://www.youtube.com/embed/${video.videoId}`)
          }
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      ))
    );
  };

  const isEditMode = participation?.sponsor?.id === sponsorId;
  // const routeFromSponsorList = history?.location?.state?.fromSponsorList;

  const renderContactRow = (title, label) => {
    return (
      <div style={{ display: 'flex' }}>
        <AimTypography variant="textBold" margin="0">
          {title}:
        </AimTypography>
        {isValidURL(label) ? (
          <AimLink
            to={label.indexOf('http') > -1 ? label : `http://${label}`}
            text={label}
            style={{
              marginLeft: '5px',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            color="black"
            margin={0}
          />
        ) : (
          <AimTypography
            variant="text"
            margin="0"
            style={{ marginLeft: '5px' }}
          >
            {label}
          </AimTypography>
        )}
      </div>
    );
  };

  return (
    <MainContainer>
      {fetchedInfo.id && (
        <CustomHeaderPage
          logo={fetchedInfo.profileImage}
          title={fetchedInfo.name}
          subtitle={user?.address}
          // preTitle={i18n.page.sponsor}
          headerImage={fetchedInfo.profileCover}
          page={constants.WebsitePages.SPONSOR_PAGE.key}
        />
      )}
      <Container isMobile={isMobile}>
        <div
          style={{
            ...(isMobile && {
              flexDirection: isMobile ? 'column-reverse' : 'column',
            }),
            position: 'relative',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          {isEditMode ? (
            <>
              {!isVirtualStandPreviewEnabled && (
                <>
                  <AimIconAndTextButton
                    style={{ margin: 5 }}
                    variant={'lightBlueFill'}
                    onClick={() =>
                      history.push(
                        `/events/${eventId}/sponsors/${sponsorId}/edit`
                      )
                    }
                    text={i18n.page.sponsorDetailEdit}
                  >
                    <EditIcon />
                  </AimIconAndTextButton>
                  <AimIconAndTextButton
                    style={{ margin: 5 }}
                    variant={fetchedInfo.isPublished ? 'greenFill' : 'redFill'}
                    onClick={() => setDialog({ isOpen: true })}
                    text={
                      fetchedInfo.isPublished
                        ? i18n.dialogs.unPublishActionLable
                        : i18n.dialogs.publishActionLable
                    }
                  >
                    {fetchedInfo.isPublished ? <CheckIcon /> : <PublishIcon />}
                  </AimIconAndTextButton>
                </>
              )}
            </>
          ) : (
            <></>
            // hidden for ESH
            // <AimIconAndTextButton
            //   style={{ margin: 5 }}
            //   variant={'lightBlueFill'}
            //   onClick={() =>
            //     history.push(`/events/${eventId}/sponsors/${sponsorId}/book`)
            //   }
            //   text={i18n.page.bookAppointment}
            // >
            //   <CalendarTodayIcon />
            // </AimIconAndTextButton>
          )}
          {/* <AimIconAndTextButton
                style={{ margin: 5 }}
                variant={'lightBlueFill'}
                onClick={() => alert('TODO')}
                text={i18n.page.businessCard}
                disabled
              >
                <BusinessIcon />
              </AimIconAndTextButton> */}

          {/* hidden for ESH */}
          {/* <AimIconAndTextButton
            style={{ margin: 5 }}
            variant={'lightBlueFill'}
            onClick={toggleVirtualStandPreview}
            text={
              isVirtualStandPreviewEnabled
                ? i18n.page.exitStand
                : i18n.page.visitStand
            }
            disabled={!sponsorVirtualStand?.virtualRoomLink}
          >
            {isVirtualStandPreviewEnabled ? (
              <ArrowBackIcon />
            ) : (
              <FlagOutlinedIcon />
            )}
          </AimIconAndTextButton> */}
        </div>
        {/* {!routeFromSponsorList && isEditMode ? (
          <div>
            <AimIconAndTextButton
              variant={'text'}
              onClick={() =>
                history.push(`/events/${eventId}/${sponsorId}/my-services`)
              }
              text={i18n.page.backButton}
            >
              <ArrowBackIcon />
            </AimIconAndTextButton>
          </div>
        ) : null} */}

        {isVirtualStandPreviewEnabled ? (
          <VirtualStandPreview url={sponsorVirtualStand?.virtualRoomLink} />
        ) : (
          <Grid
            container
            justify="center"
            alignItems="center"
            spacing={2}
            style={{
              marginTop: 0,
              backgroundColor: theme.colors.greyScale.backgroundGrey,
              marginBottom: 10,
            }}
          >
            <Grid item xs={12} md={6}>
              <AimCard
                cardVariant="white"
                title={i18n.page.sponsorDescription}
                titleVariant="h3" // h5
                // titleBoxStyle={{ fontWeight: 'bold' }}
                // innerHeight={'100%'}
                style={styleCard}
                typographyProps={{ isUpperCase: true }}
                // style={{
                //   width: isMobile ? '342px' : '670px',
                //   height: isMobile ? '361px' : '320px',
                //   minHeight: isMobile ? '361px' : '320px',
                // }}
              >
                {fetchedInfo && parseRichText(fetchedInfo.description) ? (
                  <div
                    style={{
                      height: 200,
                      overflow: 'auto',
                    }}
                  >
                    <AimRichText
                      value={parseRichText(fetchedInfo.description)}
                      variant={'white'}
                      readOnly
                      formControlStyle={{
                        height: '100%',
                      }}
                      editorStyleOverride={{
                        minHeight: '100%',
                        overflow: 'visible',
                      }}
                    />
                  </div>
                ) : (
                  <AimTypography variant="body1" component="h2">
                    {`${i18n.page.noDataAvailable}${
                      isEditMode ? i18n.page.pleaseEdit : ''
                    }`}
                  </AimTypography>
                )}
              </AimCard>
            </Grid>
            <Grid item xs={12} md={6}>
              <AimCard
                title={i18n.page.sponsorContacts}
                titleVariant="h3"
                style={styleCard}
                typographyProps={{ isUpperCase: true }}
              >
                <div style={{ height: 200, overflow: 'auto' }}>
                  {fetchedInfo && fetchedInfo.contacts ? (
                    fetchedInfo.contacts.map((contact, i) => (
                      <div key={i} style={{ marginBottom: '10px' }}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <AimTypography margin="0" variant="text">
                            {`${contact.name} ${contact.surname}`}
                          </AimTypography>
                          <ArrowDropDownIcon
                            variant="none"
                            onClick={() => handleExpandClick(i)}
                            style={{ marginRight: 10 }}
                          />
                        </div>
                        <Divider style={{ marginRight: 10, marginBottom: 5 }} />

                        <Collapse
                          in={
                            i === 0 ? !collapsableCtrl[i] : collapsableCtrl[i]
                          }
                        >
                          <ContactCardContent>
                            <div>
                              {contact.phone &&
                                renderContactRow(
                                  i18n.page.phone,
                                  contact.phone
                                )}
                              {contact.email &&
                                renderContactRow(
                                  i18n.page.email,
                                  contact.email
                                )}
                              {contact.country &&
                                renderContactRow(
                                  i18n.page.country,
                                  contact.country.label
                                )}
                              {contact.city &&
                                renderContactRow(
                                  i18n.page.city,
                                  contact.city.label
                                )}
                              {contact.address &&
                                renderContactRow(
                                  i18n.page.address,
                                  contact.address
                                )}
                              {contact.website &&
                                renderContactRow(
                                  i18n.page.website,
                                  contact.website
                                )}
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center',
                                }}
                              >
                                {contact.facebook && (
                                  <AimIconButton
                                    size="small"
                                    onClick={() =>
                                      window.open(
                                        `${contact.facebook}`,
                                        '_blank'
                                      )
                                    }
                                    variant="none"
                                  >
                                    <FacebookIcon fontSize="small" />
                                  </AimIconButton>
                                )}
                                {contact.linkedin && (
                                  <AimIconButton
                                    size="small"
                                    onClick={() =>
                                      window.open(
                                        `${contact.linkedin}`,
                                        '_blank'
                                      )
                                    }
                                    variant="none"
                                  >
                                    <LinkedInIcon fontSize="small" />
                                  </AimIconButton>
                                )}
                                {contact.twitter && (
                                  <AimIconButton
                                    size="small"
                                    onClick={() =>
                                      window.open(
                                        `${contact.twitter}`,
                                        '_blank'
                                      )
                                    }
                                    variant="none"
                                  >
                                    <img src={xIcon} style={{ height: 15 }} />
                                  </AimIconButton>
                                )}
                                {contact.instagram && (
                                  <AimIconButton
                                    size="small"
                                    onClick={() =>
                                      window.open(
                                        `${contact.instagram}`,
                                        '_blank'
                                      )
                                    }
                                    variant="none"
                                  >
                                    <InstagramIcon fontSize="small" />
                                  </AimIconButton>
                                )}
                              </div>
                            </div>
                          </ContactCardContent>
                        </Collapse>
                      </div>
                    ))
                  ) : (
                    <AimTypography variant="body1" component="h2">
                      {`${i18n.page.noDataAvailable}${
                        isEditMode ? i18n.page.pleaseEdit : ''
                      }`}{' '}
                    </AimTypography>
                  )}
                </div>
              </AimCard>
            </Grid>
            <Grid item xs={12} md={12}>
              <AimCard
                title={i18n.page.sponsorDocuments}
                titleVariant="h3"
                style={{ margin: 0 }}
                typographyProps={{ isUpperCase: true }}
              >
                {fetchedInfo && fetchedInfo.documents?.items?.length > 0 ? (
                  // <div style={{ height: 200, overflow: 'auto' }}>
                  <Grid container spacing={2}>
                    {fetchedInfo.documents.items.map((document, i) => (
                      <Grid item key={i} xs={12} md={6}>
                        <DocumentsItem
                          onClick={() => {
                            downloadFromS3(
                              document,
                              FileTypeEnum.documents,
                              eventId,
                              sponsorId
                            );
                          }}
                        >
                          <ImageTitle>
                            <img
                              src={getFileThumb(document.extension)}
                              style={{
                                width: 30,
                                height: 30,
                                marginRight: 10,
                              }}
                              alt="fileThumb"
                            />
                            <div>{document.name}</div>
                          </ImageTitle>
                          <div style={{ color: '#84819A' }}>
                            {getFormattedDate(document.updatedAt)}
                          </div>
                        </DocumentsItem>
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <AimTypography variant="body1" component="h2">
                    {`${i18n.page.noDataAvailable}${
                      isEditMode ? i18n.page.pleaseEdit : ''
                    }`}{' '}
                  </AimTypography>
                )}
              </AimCard>
            </Grid>
            {(fetchedInfo.videoId || fetchedInfo.videoEmbeddedLink) && (
              <Grid item xs={12} md={12}>
                <AimCard
                  title={i18n.page.video}
                  titleVariant="h3"
                  style={{
                    height: 'auto',
                    margin: 0,
                    // backgroundColor: '#DDDCE3',
                  }}
                  typographyProps={{ isUpperCase: true }}
                >
                  {/* <AimTypography variant="h4">{i18n.page.title}</AimTypography> */}
                  <AimTypography variant="text">
                    {fetchedInfo && fetchedInfo.videoTitle}
                  </AimTypography>
                  <AimTypography variant="text" style={{ marginTop: '10' }}>
                    {fetchedInfo && fetchedInfo.videoSubtitle}
                  </AimTypography>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      flex: 1,
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    {fetchedInfo && (
                      <div style={{ width: '100%' }}>
                        <div
                          style={{
                            display: isvideoStarted ? 'flex' : 'none',
                          }}
                        >
                          <GetIFrameByProvider
                            video={{
                              videoProvider: fetchedInfo.videoProvider,
                              videoId: fetchedInfo.videoId,
                              videoEmbeddedLink: fetchedInfo.videoEmbeddedLink,
                            }}
                          />
                        </div>
                        <div
                          ref={previewDivRef}
                          style={{
                            display: isvideoStarted ? 'none' : 'flex',
                          }}
                        >
                          <StyledPreviewDiv
                            onClick={() => {
                              // if (onVideoClick) onVideoClick();
                              setIsVideoStarted(true);
                            }}
                            isMobile={isMobile}
                            imageUrl={fetchedInfo && fetchedInfo.videoCover}
                          >
                            <div
                              style={{
                                ...(isMobile
                                  ? { height: 300, justifyContent: 'center' }
                                  : {
                                      height: '53%',
                                      marginBottom: 100,
                                      justifyContent: 'flex-end',
                                    }),
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                              }}
                            >
                              <PlayCircleFilledWhiteIcon
                                style={{
                                  fontSize: 90,
                                  color: 'white',
                                  cursor: 'pointer',
                                }}
                              />
                            </div>
                          </StyledPreviewDiv>
                        </div>
                      </div>
                    )}
                  </div>
                </AimCard>
              </Grid>
            )}
            {/* <Grid item xs={12} md={12}>
              <AimCard
                title={i18n.page.sponsorVirtualExhibitions}
                titleVariant="h3"
                style={{
                  ...styleCard,
                  backgroundColor: '#DDDCE3',
                }}
                typographyProps={{ isUpperCase: true }}
              >
                <AimTypography variant="body1" component="h2">
                  {i18n.page.noSponsorVirtualExhibitions}
                </AimTypography>
              </AimCard>
            </Grid> */}
          </Grid>
        )}
      </Container>
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
      <AimDialog
        open={createDialog.isOpen}
        title={
          fetchedInfo.isPublished
            ? i18n.dialogs.unPublishActionLable
            : i18n.dialogs.publishActionLable
        }
        onAgree={() => {
          handlePublishClick();
        }}
        onClose={() => {
          setDialog({ isOpen: false });
        }}
      >
        <AimTypography>
          {`${i18n.dialogs.publishActionDialogMessage} ${
            fetchedInfo.isPublished
              ? i18n.dialogs.unPublishActionMessage
              : i18n.dialogs.publishActionMessage
          }`}
        </AimTypography>
      </AimDialog>
    </MainContainer>
  );
};
export default SponsorDetail;
