import React, { useState, useEffect } from 'react';

import {
  theme,
  CustomIntl,
  AimIconAndTextButton,
  AimTypography,
  AimSnackbar,
  AimSnackbarSeverity,
  useLoaderHelper,
  useDataHelper,
} from '@aim/components';
import useI18n from './shared/i18n';
import useMailI18n from '../sponsorServiceConfigurationWall/shared/i18n';
import { useIntl } from 'react-intl';
import { useParams, useHistory } from 'react-router-dom';

import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

import { useBreakoutRoomUtils } from '../../hooks/useBreakoutRoomUtils';
import BreakoutRoomRejectMotivationSelector from './component/BreakoutRoomRejectMotivationSelector';
const MailLandingPage = () => {
  const intl = CustomIntl(useIntl());
  const i18n = useI18n.mailLandingPage(intl);
  const { eventId, breakoutRoomSlotId, email } = useParams();
  const history = useHistory();

  const [rejectCollapsable, setRejectCollapsable] = useState(false);
  const [customMotivation, setCustomMotivation] = useState();
  const [selectedResponse, setselectedResponse] = useState();
  const [sendButtonDisabled, setSendButtonDisabled] = useState(false);
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [data, setData] = useState();

  const { showLoader, hideLoader } = useLoaderHelper();
  const { getFormattedDate, useBreakoutRoomDataHelpers } = useDataHelper();
  const {
    states,
    callLambda,
    getSlotFromLambda,
    emailTypes,
    generateBreakoutRoomEmail,
  } = useBreakoutRoomUtils();
  const { getHours } = useBreakoutRoomDataHelpers();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    showLoader();
    const breakoutRoomSlot = await getSlotFromLambda(breakoutRoomSlotId);

    if (breakoutRoomSlot) {
      const _data = {
        sponsor: {
          id: breakoutRoomSlot.breakoutRoom.sponsor.id,
          name: breakoutRoomSlot.breakoutRoom.sponsor.name,
        },
        slot: {
          id: breakoutRoomSlotId,
          date: getFormattedDate(breakoutRoomSlot.start),
          start: getHours(breakoutRoomSlot.start),
          end: getHours(breakoutRoomSlot.end),
          title: breakoutRoomSlot.title,
        },
      };
      setData(_data);
      hideLoader();
    }
  };

  //pagina di atterraggio delle email di invito a slot breakoutRoom
  //accept: lambda per accettazione
  //reject: apre un collapsable e permette di definire una motivazione

  const sentToHome = () => {
    setTimeout(() => {
      history.push(
        `/events/${eventId}/sponsors/${data.sponsor.id}/slots/${breakoutRoomSlotId}`
      );
    }, 5000);
  };

  const accept = async () => {
    try {
      showLoader();
      const response = await callLambda({
        action: states.ACCEPTED,
        email: email,
        slotId: breakoutRoomSlotId,
        eventId: eventId,
      });
      setselectedResponse(states.ACCEPTED);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.success,
        message: 'ok',
      });
      const emailData = {
        breakoutRoom: {
          date: data.slot.start,
          sponsor: data.sponsor,
        },
        slot: data.slot,
        eventId: eventId,
        i18n: useMailI18n.breakoutRoomMail(intl),
      };
      generateBreakoutRoomEmail(emailTypes.RESULT_ACCEPT, {
        ...emailData,
        email: email,
        isPublic: true,
      });
      sentToHome();
    } catch (e) {
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: e.message,
      });
      setselectedResponse(states.CANCELED);
    }
    hideLoader();
  };

  const reject = async () => {
    try {
      showLoader();
      const response = await callLambda({
        action: states.REJECTED,
        email: email,
        slotId: breakoutRoomSlotId,
        eventId: eventId,
      });
      setselectedResponse(states.REJECTED);
      const emailData = {
        breakoutRoom: {
          date: data.slot.start,
          sponsor: data.sponsor,
        },
        slot: data.slot,
        eventId: eventId,
        i18n: useMailI18n.breakoutRoomMail(intl),
      };
      generateBreakoutRoomEmail(emailTypes.RESULT_REJECT, {
        ...emailData,
        email: email,
        isPublic: true,
      });
      setRejectCollapsable(!rejectCollapsable);
    } catch (e) {
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: e.message,
      });
      setselectedResponse(states.CANCELED);
    }
    hideLoader();
  };

  if (!data) return <div>Loading...</div>;
  return (
    <div
      style={{
        margin: 'auto',
        backgroundColor: theme.colors.greyScale.white,
        padding: 20,
        borderRadius: 3,
      }}
    >
      <div>
        <AimTypography>{i18n.page.hello}</AimTypography>
        <AimTypography>{`${i18n.page.msg1} ${data.slot.title} ${i18n.page.msg2} ${data.sponsor.name}`}</AimTypography>
        <AimTypography>{`${i18n.page.msg3} ${data.slot.date} ${i18n.page.msg4} ${data.slot.start} ${i18n.page.msg5} ${data.slot.end}`}</AimTypography>
        <div>
          <AimIconAndTextButton
            disabled={
              selectedResponse === states.ACCEPTED ||
              selectedResponse === states.CANCELED
            }
            variant={'redFill'}
            onClick={() => {
              if (!selectedResponse) reject();
            }}
            text={i18n.buttons.reject}
          >
            <ClearIcon />
          </AimIconAndTextButton>
          <AimIconAndTextButton
            disabled={
              selectedResponse === states.REJECTED ||
              selectedResponse === states.CANCELED
            }
            variant={'greenFill'}
            onClick={() => {
              if (!selectedResponse) accept();
            }}
            text={i18n.buttons.accept}
          >
            <CheckIcon />
          </AimIconAndTextButton>
        </div>
      </div>
      {rejectCollapsable ? (
        <BreakoutRoomRejectMotivationSelector
          setSnackbar={setSnackbar}
          eventId={eventId}
          breakoutRoomSlotId={breakoutRoomSlotId}
          email={email}
          onRejectComplete={() => {
            setSnackbar({
              isOpen: true,
              severity: AimSnackbarSeverity.success,
              message: i18n.rejectMotivations.successRejected,
            });
            setRejectCollapsable(false);
            setselectedResponse(states.CANCELED);
          }}
        />
      ) : null}
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </div>
  );
};

export default MailLandingPage;
