// import './styles.css';
import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
// import { isMobile } from 'react-device-detect';
import { format } from 'date-fns-tz';
import enGB from 'date-fns/locale/en-GB';

import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import {
  AimTypography,
  theme,
  AimIconButton,
  styled,
  useAimMediaQuery,
} from '@aim/components';
// import { isMobileOnly } from 'react-device-detect';
import ListSubheader from '@material-ui/core/ListSubheader';

const RelStyled = styled('div')({
  '& :hover': { textDecoration: 'underline' },
});

const Header = ({ children }) => (
  <>
    <Divider />
    <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
      <AimTypography
        variant="h4"
        boxStyle={{ color: theme.palette.primary }}
        isUpperCase
      >
        {children}
      </AimTypography>
    </div>
    <Divider />
  </>
);

const AgendaMontlhy = ({
  sessions,
  handleBookmarkClick,
  tabState,
  setTabState,
}) => {
  const [months, setMonths] = useState();

  const { eventId } = useParams();
  const history = useHistory();

  const { isMobile, isMobileOnly } = useAimMediaQuery();

  //format Data
  useEffect(() => {
    const reducedSessions = sessions
      .sort((a, b) => (a.start <= b.start && b.end <= b.end ? -1 : 1))
      .reduce((prev, curr) => {
        const startTimeString = format(curr.start, 'HH:mm a');
        const endTimeString = format(curr.end, 'HH:mm a');
        const timezoneAcronym = format(curr.end, 'zzz', { locale: enGB });
        const monthString = format(curr.date, 'LLLL yyyy');
        const dayString = format(curr.date, 'E dd');
        const nextSession = {
          ...curr,
          startTimeString,
          endTimeString,
          timezoneAcronym,
        };

        if (prev?.[monthString] && prev?.[monthString]?.[dayString]) {
          prev[monthString][dayString].push(nextSession);
        } else if (prev?.[monthString]) {
          prev[monthString][dayString] = [nextSession];
        } else {
          prev[monthString] = { [dayString]: [nextSession] };
        }
        return prev;
      }, {});
    setMonths(reducedSessions);
  }, [sessions]);

  console.log('sessions', sessions);
  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        padding: isMobileOnly ? 25 : 50,
        //background: theme.colors.greyScale.backgroundGrey,
      }}
    >
      {months ? (
        <List
          style={{
            overflow: 'auto',
            maxHeight: 'calc(100vh - 330px)',
            padding: 0,
            width: isMobile ? '95%' : '70%',
          }}
          subHeader={<li />}
        >
          {Object.entries(months).map(([monthKey, daysObj]) => (
            <li key={`section-${monthKey}`}>
              <ListSubheader
                style={{
                  background: theme.colors.greyScale.backgroundGrey,
                  padding: 0,
                  zIndex: 100,
                }}
              >
                <Header>{monthKey}</Header>
              </ListSubheader>
              {Object.entries(daysObj).map(([dayKey, daySessions]) => (
                <>
                  <AimTypography
                    variant="h4"
                    boxStyle={{ color: theme.palette.primary }}
                  >
                    {dayKey}
                  </AimTypography>
                  {daySessions.map((session) => (
                    <>
                      <div
                        style={{
                          minHeight: 170,
                          background: theme.colors.primary.white,
                          borderRadius: 27,
                          marginBottom: 40,
                          padding: 20,
                        }}
                      >
                        <Grid container spacing={4}>
                          <Grid item xs={2}>
                            <AvatarGroup max={4} spacing="small">
                              {session.attendees.map((as) => (
                                <Tooltip
                                  key={as.id}
                                  title={`${as.participation.givenName}${
                                    as.participation.familyName
                                      ? ` ${as.participation.familyName}`
                                      : ''
                                  }`}
                                >
                                  <Avatar
                                    style={{
                                      width: 58,
                                      height: 58,
                                      cursor: 'pointer',
                                    }}
                                    alt={`${as.participation.givenName}${
                                      as.participation.familyName
                                        ? ` ${as.participation.familyName}`
                                        : ''
                                    }`}
                                    src={as.participation.profileImageLink}
                                    onClick={() =>
                                      history.push(
                                        `/events/${eventId}/user/${as.participation.id}`
                                      )
                                    }
                                  />
                                </Tooltip>
                              ))}
                            </AvatarGroup>
                          </Grid>
                          <Grid
                            container
                            item
                            direction="column"
                            xs
                            style={
                              isMobileOnly
                                ? {
                                    padding: '80px 0px 0px 0px',
                                    width: '55vw',
                                  }
                                : {}
                            }
                          >
                            <AimTypography
                              variant="h5"
                              boxStyle={{
                                marginTop: 0,
                                marginBottom: 0,
                              }}
                            >
                              {`${session.startTimeString} - ${session.endTimeString} ${session.timezoneAcronym}`}
                            </AimTypography>
                            <AimTypography
                              variant="text"
                              boxStyle={{
                                marginTop: 0,
                                marginBottom: 0,
                              }}
                            >
                              {session.type}
                            </AimTypography>
                            <AimTypography
                              variant="h3"
                              boxStyle={
                                isMobileOnly
                                  ? {
                                      marginTop: 0,
                                      marginBottom: 0,
                                      fontSize: '3vw',
                                    }
                                  : {
                                      marginTop: 0,
                                      marginBottom: 0,
                                    }
                              }
                              onClick={() => {
                                history.push(
                                  `/events/${eventId}/agenda/sessions/${session.id}`
                                );
                              }}
                              style={{ cursor: 'pointer' }}
                            >
                              {session.title}
                            </AimTypography>
                            <AimTypography variant="text">
                              {session.room.name}
                            </AimTypography>

                            {session.attendees.map((as) => (
                              <div style={{ display: 'flex' }} key={as.id}>
                                <RelStyled>
                                  <AimTypography
                                    onClick={() =>
                                      history.push(
                                        `/events/${eventId}/user/${as.participation.id}`
                                      )
                                    }
                                    variant="text"
                                    boxStyle={{
                                      color: '#707070',
                                      marginTop: 0,
                                      marginBottom: 0,
                                      cursor: 'pointer',
                                    }}
                                  >
                                    {`${as.participation.givenName}${
                                      as.participation.familyName
                                        ? ` ${as.participation.familyName}`
                                        : ''
                                    }`}
                                  </AimTypography>
                                </RelStyled>
                                <AimTypography
                                  variant="text"
                                  boxStyle={{
                                    color: theme.palette.primary,
                                    marginTop: 0,
                                    marginBottom: 0,
                                  }}
                                >
                                  {as?.role?.name || ''}
                                </AimTypography>
                              </div>
                            ))}
                          </Grid>
                          <Grid item xs={2}>
                            {tabState.timetable && !session.isPinned ? (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  cursor: 'pointer',
                                  justifyContent: 'flex-end',
                                  flexDirection: isMobile ? 'column' : 'row',
                                }}
                                onClick={() => handleBookmarkClick(session)}
                              >
                                <Tooltip title="Add to favourites">
                                  <AimIconButton variant="text">
                                    <BookmarkBorderIcon
                                      style={{
                                        color: theme.palette.primary,
                                      }}
                                    />
                                  </AimIconButton>
                                </Tooltip>
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  cursor: 'pointer',
                                  justifyContent: 'flex-end',
                                  flexDirection: isMobile ? 'column' : 'row',
                                }}
                                onClick={() => handleBookmarkClick(session)}
                              >
                                <Tooltip title="Remove from favourites">
                                  <AimIconButton variant="text">
                                    <BookmarkIcon
                                      style={{
                                        color: theme.palette.primary,
                                      }}
                                    />
                                  </AimIconButton>
                                </Tooltip>
                              </div>
                            )}
                          </Grid>
                        </Grid>
                      </div>
                    </>
                  ))}
                </>
              ))}
            </li>
          ))}
        </List>
      ) : null}
    </div>
  );
};

export default AgendaMontlhy;
