import React, { useEffect, useState } from 'react';
import { useBreakoutRoomUtils } from '../../../../hooks/useBreakoutRoomUtils';
import { useIntl } from 'react-intl';

import {
  theme,
  CustomIntl,
  styled,
  AimTypography,
  AimIconButton,
  AimCircularLoader,
  useFileHelper,
} from '@aim/components';
import { fileHelper } from '@aim/common';
import NotesIcon from '@material-ui/icons/Notes';
import CloseIcon from '@material-ui/icons/Close';
import TrashIcon from '@material-ui/icons/Delete';

import Tooltip from '@material-ui/core/Tooltip';
import useI18n from '../../shared/i18n';

const InvitationDetail = styled('div')({
  width: '50%',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
});

const InvitationStatus = styled(InvitationDetail)({
  justifyContent: 'flex-end',
});

const InvitationCard = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0px 10px',
  backgroundColor: theme.colors.greyScale.white,
  marginBottom: '6px',
  borderRadius: '5px',
});

const InvitationParticipationCard = ({
  invitation,
  isDisabled = false,
  deleteReservation,
  eventId,
  onOpenNote,
}) => {
  const intl = CustomIntl(useIntl());
  const i18n = useI18n.breakoutRoomReservations(intl);
  const participation = invitation.participation;
  const { states, capitalizeFirst } = useBreakoutRoomUtils();
  const [loadedSrc, setLoadedSrc] = useState();

  const getStateVariant = (state) => {
    switch (state) {
      case states.CANCELED:
      case states.REJECTED:
        return 'formError';
    }
    return 'text';
  };

  useEffect(() => {
    setLoadedSrc(null);
    if (invitation.participation.userShowcase?.profileImage) {
      getPhoto();
    } else {
      setLoadedSrc(
        'https://cdn.pixabay.com/photo/2018/08/28/12/41/avatar-3637425_960_720.png'
      );
    }
  }, [participation]);

  const getPhoto = () => {
    const link = fileHelper.getPublicFileLink({
      dirPath: `events/${eventId}/user/${participation.id}/showcase/profileImage`,
      skipFileDataOnS3Link: true,
    });
    setLoadedSrc(link);
  };
  return (
    <InvitationCard key={participation.id || participation.userTxt}>
      <InvitationDetail>
        {loadedSrc ? (
          <img
            src={loadedSrc}
            style={{
              width: 40,
              height: 40,
              borderRadius: '50%',
              objectFit: 'cover',
            }}
          />
        ) : (
          <AimCircularLoader />
        )}

        <AimTypography variant="text">{participation.userTxt}</AimTypography>
      </InvitationDetail>
      <InvitationStatus>
        <AimTypography variant={getStateVariant(invitation.state)}>
          {capitalizeFirst(invitation.state)}
        </AimTypography>
        {invitation.note ? (
          <Tooltip
            title={
              invitation.state === states.ACCEPTED
                ? i18n.invitations.cards.noteSelf
                : i18n.invitations.cards.noteReject
            }
          >
            <AimIconButton
              variant="blueFill"
              onClick={() => {
                onOpenNote(invitation.note);
              }}
            >
              <NotesIcon />
            </AimIconButton>
          </Tooltip>
        ) : null}
        {invitation.state === states.ACCEPTED ||
        invitation.state === states.PENDING ? (
          <Tooltip title={i18n.invitations.cards.cancel}>
            <AimIconButton
              variant="redFill"
              disabled={isDisabled}
              onClick={() => {
                !isDisabled && deleteReservation(invitation);
              }}
            >
              <CloseIcon />
            </AimIconButton>
          </Tooltip>
        ) : (
          <Tooltip title={i18n.invitations.cards.delete}>
            <AimIconButton
              variant="violetFill"
              disabled={isDisabled}
              onClick={() => {
                !isDisabled && deleteReservation(invitation, true);
              }}
            >
              <TrashIcon />
            </AimIconButton>
          </Tooltip>
        )}
      </InvitationStatus>
    </InvitationCard>
  );
};

export default InvitationParticipationCard;
