import React from 'react';
import { useIntl } from 'react-intl';

import { AimTypography, AimIconButton, CustomIntl } from '@aim/components';
import GetAppIcon from '@material-ui/icons/GetApp';

import { fileHelper } from '@aim/common';

import { translations } from './i18n';
import {
  DocumenstListWrapper as Wrapper,
  DocumenstListContainer as Container,
  DocumenstListHeader as Header,
  DocRow,
  DocCol,
  ActionsCol,
} from './DocumentsList.styles';

const DocumentsList = ({ dirPath, documents, onFileDownloaded }) => {
  const { downloadFromS3 } = fileHelper;
  const intl = CustomIntl(useIntl());
  const i18n = translations.root(intl);

  if (!documents?.length) return null;

  // Helpers
  const handleDownload = async (fileData) => {
    await downloadFromS3({ dirPath, fileData, useLoader: true });
    onFileDownloaded(fileData);
  };

  return (
    <Wrapper>
      <AimTypography variant="h6">{i18n.title}</AimTypography>
      <AimTypography variant="text">{i18n.subtitle}</AimTypography>

      <Container>
        <Header>
          <DocCol>{i18n.name}</DocCol>
        </Header>
        {documents.map((d, idx) => (
          <DocRow key={idx}>
            <DocCol>{`${d.originalName}${d.isMandatory ? '*' : ''}`}</DocCol>
            <ActionsCol>
              <AimIconButton
                small
                variant="lightBlueFill"
                onClick={() => handleDownload(d)}
              >
                <GetAppIcon />
              </AimIconButton>
            </ActionsCol>
          </DocRow>
        ))}
      </Container>
    </Wrapper>
  );
};

export default DocumentsList;
