import React from 'react';

import { useIntl } from 'react-intl';
import useI18n from './shared/translation';

import { useHistory, useParams } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import {
  AimTypography,
  AimIconAndTextButton,
  CustomIntl,
  theme,
  styled,
} from '@aim/components';

const MainContainer = styled('div')({
  width: '90%',
  height: '100%',
  marginTop: 80,
  marginBottom: 120,
  background: theme.colors.greyScale.white,
  position: 'relative',
});

const InnerContainer = styled('div')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

const CheckoutSuccess = () => {
  // Hooks
  const history = useHistory();
  const { eventId, sponsorId } = useParams();
  const intl = CustomIntl(useIntl());
  const i18n = useI18n.checkoutSuccess(intl);

  return (
    <MainContainer>
      <InnerContainer>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12}>
            <AimTypography textAlign="center">
              {i18n.page.messages.firstRow}
            </AimTypography>
          </Grid>
          <Grid item xs={12}>
            <AimTypography textAlign="center">
              {i18n.page.messages.secondRow}
            </AimTypography>
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <AimIconAndTextButton
              isUpperCase
              variant="black"
              text={i18n.page.buttons.purchases.label}
              style={{
                marginRight: '5%',
                paddingLeft: 30,
                paddingRight: 30,
              }}
              onClick={() =>
                history.push(`/events/${eventId}/${sponsorId}/my-purchases`)
              }
            />
            <AimIconAndTextButton
              isUpperCase
              variant="yellow"
              text={i18n.page.buttons.services.label}
              style={{
                marginRight: '5%',
                paddingLeft: 30,
                paddingRight: 30,
              }}
              onClick={() =>
                history.push(`/events/${eventId}/${sponsorId}/my-services`)
              }
            />
          </Grid>
        </Grid>
      </InnerContainer>
    </MainContainer>
  );
};

export default CheckoutSuccess;
