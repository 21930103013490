import { aws, appState } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const updateHasAcceptedToBeReviewerQuery = /* GraphQL */ `
  mutation UpdateParticipation($input: UpdateParticipationInput!) {
    updateParticipation(input: $input) {
      id
    }
  }
`;

export const updateHasAcceptedToBeReviewer = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    API.graphql({
      query: updateHasAcceptedToBeReviewerQuery,
      variables: { input },
    })
      .then((response) => resolve(response))
      .catch((e) => {
        console.error('update-has-accepted-to-be-reviewer', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });
