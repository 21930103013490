import { aws, appState } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

export const getEventPublicPageService = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      sponsorServiceBookingDuration
      publicPageService {
        id
        name
        description
        vatRate
        isCreditCardEnabled
        isBankTransferEnabled
        publicationDate
        netPrice
        documents {
          items {
            id
            name: originalName
            size
            extension
            isMandatory
          }
        }
        publicPages {
          items {
            sponsorPackageItem {
              package {
                buyOperations {
                  items {
                    id
                    isDeleted
                    isExpired
                    sponsor {
                      id
                    }
                  }
                }
              }
            }
            buyOperation {
              id
              isDeleted
              isExpired
              sponsor {
                id
              }
            }
          }
        }
      }
    }
  }
`;

export function usePublicPageService() {
  const get = (id, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: getEventPublicPageService,
        variables: { id },
      })
        .then((response) => {
          const outEvent = {
            ...response.data.getEvent,
            publicPageService: {
              ...(response.data.getEvent?.publicPageService || {}),
              publicPages: {
                items: response.data.getEvent?.publicPageService?.publicPages?.items?.map(
                  (publicPage) => ({
                    buyOperation:
                      publicPage?.buyOperation ||
                      publicPage?.sponsorPackageItem?.package?.buyOperations
                        ?.items?.[0],
                  })
                ),
              },
            },
          };
          resolve(outEvent);
        })
        .catch((e) => {
          console.error('get-event-public-page-service', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  return { get };
}
