import React, { useState, useEffect } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import useI18n from './../shared/translation';

import { addDays } from 'date-fns';

import { appState, constants } from '@aim/common';

import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';

import {
  MainContainer,
  InnerContainer,
} from './../shared/SponsorPagesContainers';

import {
  AimIconAndTextButton,
  AimTypography,
  AimRichText,
  AimFileList,
  CustomIntl,
  AimIconButton,
  AimSnackbar,
  AimSnackbarSeverity,
  AimNumericInput,
} from '@aim/components';

import { useBreakoutRoomService } from './../shared/breakoutRoomServiceGqlHelper';
import { useBreakoutRoom } from './../shared/breakoutRoomGqlHelper';
import { useBuyOperations } from './../shared/buyOperationsGqlHelper';
import { useFileHelper } from '../shared/fileHelper';
import { useSponsors } from '../shared/sponsorGqlHelper';

const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

const BreakoutRoom = () => {
  //Hooks
  const history = useHistory();
  const { eventId, sponsorId, service } = useParams();
  const intl = CustomIntl(useIntl());
  const i18n = useI18n.breakoutRoomPageService(intl);
  const breakoutRoomServiceHelper = useBreakoutRoomService();
  const breakoutRoomHelper = useBreakoutRoom();
  const buyOperationsHelper = useBuyOperations();
  const { get: getSponsor, update: updateSponsor } = useSponsors();

  //State
  const [sponsorData, setSponsorData] = useState();
  const [breakoutRoomService, setBreakoutRoomService] = useState();
  const [quantity, setQuantity] = useState(1);
  const [bookingDuration, setBookingDuration] = useState();
  const [paymentType, setPaymentType] = useState();
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const { downloadFromS3 } = useFileHelper(
    `events/${eventId}/sponsor/${service}/documents/`,
    true
  );

  const mandatoryDocs = breakoutRoomService?.documents.filter(
    (d) => d.isMandatory
  );
  const hasReadMandatoryDocs =
    !mandatoryDocs?.length ||
    mandatoryDocs?.every(
      (md) => !!sponsorData?.requiredDocumentsIds.find((rd) => rd === md.id)
    );

  // Effects
  useEffect(() => {
    getBreakoutRoomService();
    fetchSponsor();
  }, []);

  const fetchSponsor = async () => {
    const userAndParticipation = appState.user.getValue()?.userAndParticipation;
    const sponsor =
      userAndParticipation?.participation?.sponsor ||
      userAndParticipation?.participation?.sponsorStaff?.sponsor;

    const sponsorData = await getSponsor(sponsor.id);
    setSponsorData(sponsorData);
  };

  // Functions
  const getBreakoutRoomService = () => {
    breakoutRoomServiceHelper.get(eventId).then((res) => {
      setBreakoutRoomService({
        ...res.breakoutRoomService,
        documents: res.breakoutRoomService?.documents?.items?.map((doc) => ({
          ...doc,
          name: doc.originalName,
        })),
      });
      setBookingDuration(res.sponsorServiceBookingDuration || 10);

      const paymentType =
        res.breakoutRoomService.isCreditCardEnabled &&
        res.breakoutRoomService.isBankTransferEnabled
          ? 'both'
          : (res.breakoutRoomService.isCreditCardEnabled &&
              constants.PaymentTypes.CreditCard) ||
            (res.breakoutRoomService.isBankTransferEnabled &&
              constants.PaymentTypes.BankTransfer);
      setPaymentType(paymentType);
    });
  };

  const handleClick = (value) => {
    const nextQuantity = quantity + value;
    setQuantity(nextQuantity);
  };

  const handleChange = (value) => {
    setQuantity(value);
  };

  const onAddToCart = async (purchaseNow = false) => {
    if (!hasReadMandatoryDocs)
      return setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.snackbar.missingDownloadsError,
      });
    showLoader();
    try {
      const bookings = [];
      for (let i = 0; i < quantity; i++) {
        const breakoutRoomCreateResponse = await breakoutRoomHelper.create(
          {
            breakoutRoomBreakoutRoomServiceId: breakoutRoomService.id,
            breakoutRoomSponsorId: sponsorId,
            purchasedHours: breakoutRoomService.maxAvailableHours,
          },
          false
        );

        const bookingDate = new Date();
        const bookingExpiringDate = addDays(bookingDate, bookingDuration);
        const inputBuyOperation = {
          eventId: eventId,
          buyOperationBreakoutRoomId: breakoutRoomCreateResponse.id,
          buyOperationSponsorId: sponsorId,
          date: bookingDate,
          expiringDate: bookingExpiringDate,
          paymentType: paymentType,
          isExpired: 'false',
          isDeleted: 'false',
        };

        const response = await buyOperationsHelper.create(
          inputBuyOperation,
          false
        );

        await breakoutRoomHelper.update(
          {
            id: breakoutRoomCreateResponse.id,
            breakoutRoomBuyOperationId: response.id,
          },
          false
        );
        bookings.push(response);
      }

      const destUrl = `/events/${eventId}/${sponsorId}/cart`;
      if (purchaseNow) {
        history.push({
          pathname: destUrl,
          state: {
            bookings: bookings,
          },
        });
      }
    } catch (e) {
      console.error(e);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.snackbar.addToCartFail.label,
      });
    } finally {
      hideLoader();
    }
  };

  // Helpers
  const onDownload = async (fileData) => {
    const documentId = fileData.id;
    if (
      fileData.isMandatory &&
      !sponsorData?.requiredDocumentsIds.includes(documentId)
    ) {
      const nextRequiredDocumentsIds = [
        ...sponsorData?.requiredDocumentsIds,
        documentId,
      ];
      await updateSponsor({
        id: sponsorData.id,
        requiredDocumentsIds: nextRequiredDocumentsIds,
      });

      fetchSponsor();
    }
  };

  return (
    <MainContainer>
      <InnerContainer grow bottomSpacing>
        <div style={{ display: 'flex-root', marginTop: 20 }}>
          <Tooltip title={i18n.page.back.tooltip.label}>
            <AimIconAndTextButton
              variant="text"
              text={i18n.page.back.button.label}
              onClick={() =>
                history.push(`/events/${eventId}/${sponsorId}/my-services`)
              }
            >
              <ArrowBack />
            </AimIconAndTextButton>
          </Tooltip>
        </div>
        <Grid container>
          <Grid item xs={12}>
            <AimTypography variant={'h1'}>
              {i18n.page.title.label}
            </AimTypography>
          </Grid>
          <Grid item xs={12}>
            {breakoutRoomService?.description && (
              <AimRichText
                label={i18n.page.instructions.label}
                value={JSON.parse(breakoutRoomService.description)}
                readOnly
                editorStyleOverride={{
                  minHeight: 'auto',
                  maxHeight: 'fit-content',
                  overflow: 'hidden !important',
                }}
              />
            )}
          </Grid>
        </Grid>
        {breakoutRoomService?.documents?.length ? (
          <Grid container spacing={2} style={{ marginTop: 20 }}>
            <Grid
              item
              xs={12}
              style={{
                backgroundColor: 'white',
              }}
            >
              <AimFileList
                files={breakoutRoomService.documents}
                onDownload={async (file) => {
                  await downloadFromS3(null, file);
                  onDownload(file);
                }}
              />
            </Grid>
          </Grid>
        ) : null}
        {breakoutRoomService ? (
          <Grid
            container
            spacing={2}
            style={{ marginTop: 20, backgroundColor: 'white' }}
          >
            {/* Headers */}
            <Grid item xs={3}>
              <AimTypography variant="columnHeader">
                {i18n.columns.name.label}
              </AimTypography>
            </Grid>
            <Grid item xs={2}>
              <AimTypography variant="columnHeader">
                {i18n.columns.price.label}
              </AimTypography>
            </Grid>
            <Grid item xs={2}>
              <AimTypography variant="columnHeader">
                {i18n.columns.vatRate.label}
              </AimTypography>
            </Grid>
            <Grid item xs={2}>
              <AimTypography variant="columnHeader">
                {i18n.columns.taxedPrice.label}
              </AimTypography>
            </Grid>
            <Grid item xs={1}>
              <AimTypography variant="columnHeader" textAlign="center">
                {i18n.columns.quantity.label}
              </AimTypography>
            </Grid>
            <Grid item xs={2}>
              {/* Actions column */}
            </Grid>
            {/* Columns */}
            <Grid item xs={3}>
              <AimTypography variant="text">
                {i18n.columns.name.message}
              </AimTypography>
            </Grid>
            <Grid item xs={2}>
              <AimTypography variant="text">
                {`${(breakoutRoomService.price ?? 0) / 100} €`}
              </AimTypography>
            </Grid>
            <Grid item xs={2}>
              <AimTypography variant="text">
                {`${(breakoutRoomService.vatRate ?? 100) / 100} %`}
              </AimTypography>
            </Grid>
            <Grid item xs={2}>
              <AimTypography variant="text">
                {`${
                  (breakoutRoomService.price * quantity ?? 0) / 100 +
                  (((breakoutRoomService.price * quantity ?? 0) / 100) *
                    ((breakoutRoomService.vatRate ?? 100) / 100)) /
                    100
                } €`}
              </AimTypography>
            </Grid>
            <Grid item xs={1}>
              <AimNumericInput
                value={quantity}
                editableInput
                onChange={(value) => handleChange(value)}
                onClickPlus={() => handleClick(1)}
                onClickMinus={() => handleClick(-1)}
                //disabledMinus={quantity < 2}
              />
            </Grid>
            <Grid item xs={2} style={{ textAlign: 'center' }}>
              <AimIconButton
                variant="yellowFill"
                small
                onClick={() => onAddToCart(true)}
              >
                <SwapHorizIcon />
              </AimIconButton>
              <AimIconButton
                variant="violetFill"
                small
                onClick={() => onAddToCart()}
              >
                <ShoppingCartIcon />
              </AimIconButton>
            </Grid>
          </Grid>
        ) : null}
      </InnerContainer>

      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </MainContainer>
  );
};

export default BreakoutRoom;
