import React, { useEffect, useState } from 'react';

import { useIntl } from 'react-intl';
import { isMobileOnly } from 'react-device-detect';
import { useHistory, useParams } from 'react-router-dom';

import {
  styled,
  CustomIntl,
  AimTitleAndButtons,
  AimIconAndTextButton,
  AimList,
  AimTypography,
} from '@aim/components';
import Grid from '@material-ui/core/Grid';

// import { constants } from '@aim/common';

import ArrowBack from '@material-ui/icons/ArrowBack';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ArrowForward from '@material-ui/icons/ArrowForward';

import { getParticipationGrants } from './myGrants/myGrantsGqlHelper';

import translation from './myGrants/translation';
import { constants } from '@aim/common';
import { isBefore, parseISO, startOfDay } from 'date-fns';

const MainContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  width: isMobileOnly ? '100%' : '80%',
  padding: '10px',
});

const StyledRow = styled('div')({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-evenly',
  backgroundColor: '#F3F2F6',
  borderRadius: 5,
  // cursor: 'pointer',
  paddingLeft: 5,
  minHeight: 50,
  alignItems: 'center',
});

const fetchParticipationsGrants = async (id) => {
  const res = await getParticipationGrants(id);
  if (res) return res;
  return false;
};

const renderGrantStatus = (
  status,
  id,
  eventId,
  isMobileOnly,
  history,
  intl,
  participationId
) => {
  return (
    <Grid container spacing={1}>
      <Grid
        item
        xs={!isMobileOnly ? 1 : 2}
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        <div
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <span
            style={{
              height: 15,
              width: 15,
              backgroundColor: status?.color,
              borderRadius: '50%',
              display: 'inline-block',
            }}
          />
        </div>
      </Grid>
      <Grid
        item
        xs={!isMobileOnly ? 9 : 10}
        style={{
          alignItems: 'center',
          justifyContent: 'flex-start',
          display: 'flex',
        }}
      >
        <div>{status?.label(intl)}</div>
      </Grid>
      {isMobileOnly ? (
        <></>
      ) : (
        <Grid
          item
          xs={2}
          onClick={() => {
            history.push({
              pathname: `/events/${eventId}/user/${participationId}/grants/${id}`,
              // state: {
              //   backUrl: `/events/${eventId}/grants/my-grants}`,
              // },
            });
          }}
          style={{
            cursor: 'pointer',
            alignItems: 'center',
            justifyContent: 'flex-start',
            display: 'flex',
          }}
        >
          <VisibilityIcon style={{ color: '#30cfd0' }} />
        </Grid>
      )}
    </Grid>
  );
};

const getRequestStatus = (participationGrant) => {
  if (
    participationGrant.requestStatus !== constants.GrantStatuses.PROCESSED.id
  ) {
    return Object.values(constants.GrantStatuses).find(
      (type) => type.id === participationGrant.requestStatus
    );
  } else {
    const startToday = startOfDay(new Date());
    if (
      !participationGrant.publicationDate ||
      (participationGrant.publicationDate &&
        isBefore(startToday, parseISO(participationGrant.publicationDate)))
    ) {
      return constants.GrantStatuses.TO_BE_PROCESSED;
    } else {
      const hasWon =
        participationGrant.ticket ||
        participationGrant.additionalServices ||
        participationGrant.allotment ||
        participationGrant.travel ||
        participationGrant.bankTransfer;

      return hasWon
        ? constants.GrantStatuses.SUCCESSFUL
        : constants.GrantStatuses.REFUSED;
    }
  }
};

const MyGrants = () => {
  const { eventId, participationId, grantType } = useParams();

  const intl = CustomIntl(useIntl());
  const i18n = translation.myGrants(intl);
  const history = useHistory();

  const headers = [
    // {
    //   id: 'order',
    //   name: 'Order',
    //   numeric: false,
    //   disablePadding: true,
    //   sortable: true,
    // },
    {
      id: 'title',
      name: 'Title',
      label: i18n.title,
      numeric: false,
      disablePadding: true,
      sortable: false,
    },
    {
      id: 'submissionDate',
      name: 'Submission Date',
      label: i18n.submissionDate,
      numeric: false,
      disablePadding: true,
      sortable: false,
    },
    {
      id: 'status',
      name: 'Status',
      label: i18n.status,
      numeric: false,
      disablePadding: true,
      sortable: true,
    },
  ];

  const Row = ({ row, eventId, history, intl, participationId }) => {
    return (
      <StyledRow>
        {headers.map((key) => {
          const style = {
            display: 'inline-block',
            width: `calc(${100 / headers.length}%)`,
          };
          return (
            <AimTypography ellipsis style={style} variant={'text'} key={key.id}>
              {key.id !== 'status'
                ? row[key.id]
                : renderGrantStatus(
                    row['status'],
                    row['id'],
                    eventId,
                    isMobileOnly,
                    history,
                    intl,
                    participationId
                  )}
            </AimTypography>
          );
        })}
      </StyledRow>
    );
  };

  const [grantsRows, setGrantsRows] = useState([]);

  useEffect(() => {
    fetchParticipationsGrants(participationId).then((result) => {
      if (result.length > 0) {
        result = result.reduce((res, y) => {
          if (!y.grant.isDeleted && y?.grant?.type === grantType) {
            res.push({
              ...y.grant,
              title: y.grant.title,
              // order: '??',
              submissionDate: y.grant.createdAt
                .split('T')[0]
                .split('-')
                .reverse()
                .join('/'),
              status: getRequestStatus(y),
              publicationDate: y.publicationDate,
            });
          }
          return res;
        }, []);
        setGrantsRows(result);
      }
    });
  }, []);

  return (
    <MainContainer>
      <AimIconAndTextButton
        style={{ justifyContent: 'flex-start' }}
        variant="none"
        text={i18n.back}
        onClick={() =>
          history.push(`/events/${eventId}/user/${participationId}/services`)
        }
      >
        <ArrowBack />
      </AimIconAndTextButton>
      <AimTitleAndButtons
        title={`My Services - ${grantType[0].toUpperCase()}${grantType
          .slice(1, grantType.length)
          .toLowerCase()} Grants`}
      />
      {!isMobileOnly ? (
        <>
          <AimList
            checkable={false}
            deletable={false}
            rows={grantsRows}
            rowKey="id"
            childrenProps={{ i18n }}
            style={{ paddingTop: 0, paddingBottom: 0 }}
            HeaderRow={headers.map((head) => (
              <AimTypography
                key={head.id}
                variant="textBold"
                style={{
                  width: `calc(${100 / headers.length}%)`,
                }}
              >
                {head.label}
              </AimTypography>
            ))}
          >
            <Row
              eventId={eventId}
              history={history}
              intl={intl}
              participationId={participationId}
            />
          </AimList>
        </>
      ) : (
        <>
          <div
            style={{
              backgroundColor: '#F3F2F6',
              padding: '15px 15px 15px 15px',
            }}
          >
            {grantsRows.map((x) => {
              return (
                <Grid item xs={12} key={x.id}>
                  <div
                    style={{
                      padding: 10,
                      textAlign: 'center',
                      backgroundColor: 'white',
                      alignItems: 'center',
                      justifyContent: 'center',
                      display: 'flex',
                    }}
                  >
                    <Grid item container>
                      <Grid
                        item
                        xs={12}
                        style={{
                          justifyContent: 'flex-start',
                          display: 'flex',
                          fontWeight: 'bold',
                          paddingBottom: 10,
                        }}
                      >
                        {x.title}
                      </Grid>
                      <Grid item xs={12} style={{ paddingBottom: 10 }}>
                        <hr
                          style={{
                            backgroundColor: '#e2e2e2',
                            height: 0.5,
                            width: '100%',
                            borderWidth: 0,
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        style={{
                          justifyContent: 'flex-start',
                          display: 'flex',
                          fontWeight: 'bold',
                          paddingBottom: 10,
                        }}
                      >
                        {i18n.submissionDate}
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        style={{
                          justifyContent: 'flex-start',
                          display: 'flex',
                        }}
                      >
                        {x.submissionDate}
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        style={{
                          justifyContent: 'flex-start',
                          display: 'flex',
                          fontWeight: 'bold',
                          paddingBottom: 10,
                        }}
                      >
                        {i18n.status}
                      </Grid>
                      <Grid item xs={6}>
                        {renderGrantStatus(
                          x.status,
                          x.id,
                          eventId,
                          isMobileOnly,
                          history,
                          intl,
                          participationId
                        )}
                      </Grid>
                      <Grid item xs={12} style={{ paddingTop: 10 }}>
                        <AimIconAndTextButton
                          text={i18n.viewDetail}
                          style={{
                            backgroundColor: '#c7f2f2',
                            color: 'black',
                            width: '90%',
                          }}
                          onClick={() => {
                            history.push({
                              pathname: `/events/${eventId}/user/${participationId}/grants/${x.id}`,
                              // state: {
                              //   backUrl: `/events/${eventId}/grants/my-grants/${grantType}}`,
                              // },
                            });
                          }}
                        >
                          <ArrowForward />
                        </AimIconAndTextButton>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              );
            })}
          </div>
        </>
      )}
    </MainContainer>
  );
};

export default MyGrants;
