import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { CustomIntl, ImportFromFile } from '@aim/components';

import { MainContainer, InnerContainer } from './shared/SponsorPagesContainers';

const ImportFromFileFrontofficeWrapper = ({ isPostDeadline }) => {
  const history = useHistory();
  const intl = CustomIntl(useIntl());
  const { eventId, cluster, clusterId } = useParams();

  return (
    <MainContainer>
      <div style={{ flex: 1 }}>
        <InnerContainer>
          <ImportFromFile
            {...{ isPostDeadline, history, intl, eventId, cluster, clusterId }}
          ></ImportFromFile>
        </InnerContainer>
      </div>
    </MainContainer>
  );
};

export default ImportFromFileFrontofficeWrapper;
