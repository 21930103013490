import { aws, appState, utilities } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

// ModelProductProductClientIdServiceTypeServiceIdCompositeKeyInput
const getProductByClientIdServiceTypeServiceIdQuery = /* GraphQL */ `
  query getProductByClientIdServiceTypeServiceId(
    $clientId: String
    $serviceId: String
    $serviceType: String
  ) {
    productByClientIdServiceTypeServiceId(
      clientId: $clientId
      serviceTypeServiceId: {
        eq: { serviceId: $serviceId, serviceType: $serviceType }
      }
      filter: { isPublished: { eq: "true" } }
    ) {
      items {
        id
        productId
        clientId
        clientType
        serviceId
        serviceType
        createdAt
        isPublished
        payment {
          id
          isDeleted
          serviceId
          serviceType
          paymentId
          paymentType
          paymentIdMonetaOnLine
          paymentIdIngenico
          paymentIdGpWebpay
          paymentError
          amount
          paymentStatus
        }
      }
    }
  }
`;

const listEventAdditionalServicesQuery = /* GraphQL */ `
  query EventAdditionalServices($id: ID!) {
    getEvent(id: $id) {
      id
      additionalServices {
        items {
          id
          title
          locationName
          typology
          categories
          dateService
          additionalServicesConfigurationId
          configuration {
            id
            name
          }
          images {
            items {
              id
              extension
              size
              originalName
            }
          }
        }
      }
    }
  }
`;

export const listProductByClientIdServiceTypeServiceId = (
  clientId,
  serviceId,
  serviceType
) =>
  new Promise((resolve, reject) => {
    showLoader();

    API.graphql({
      query: getProductByClientIdServiceTypeServiceIdQuery,
      variables: {
        clientId,
        serviceId,
        serviceType,
      },
    })
      .then(({ data }) =>
        resolve(
          data.productByClientIdServiceTypeServiceId?.items.filter((product) =>
            utilities.isValidPayment(product.payment)
          )
        )
      )
      .catch((e) => {
        console.error('event-product', e);
        reject();
      })
      .finally(hideLoader);
  });

export const listEventAdditionalServices = (eventId, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    aws.API.graphql({
      query: listEventAdditionalServicesQuery,
      variables: { id: eventId },
    })
      .then((response) => {
        resolve(response.data.getEvent.additionalServices.items);
      })
      .catch((e) => {
        console.error('get-event-additional-services-by-typology', e);
        reject(e);
      })
      .finally(() => useLoader && hideLoader());
  });
