export const translation = {
  symposiumServices: (intl) => ({
    back: intl.formatMessage({
      description: 'back label',
      defaultMessage: 'back',
    }),
    page: {
      title: intl.formatMessage({
        description: 'page title',
        defaultMessage: 'Symposium services',
      }),
      instructions: intl.formatMessage({
        description: 'instructions label',
        defaultMessage: 'Instructions',
      }),
      infoCard: {
        days: intl.formatMessage({
          description: 'validity',
          defaultMessage: 'Validity days',
        }),
        deadline: intl.formatMessage({
          description: 'deadline',
          defaultMessage: 'Deadline',
        }),
      },
      fileList: {
        fileName: intl.formatMessage({
          description: 'file name',
          defaultMessage: 'Name',
        }),
        fileDimension: intl.formatMessage({
          description: 'file dimension',
          defaultMessage: 'Dimension',
        }),
      },
    },
    table: {
      noData: intl.formatMessage({
        description: 'there are symposium services',
        defaultMessage: 'There are symposium services.',
      }),
      unlimitedQuantity: intl.formatMessage({
        description: 'unlimited quantity label',
        defaultMessage: 'Unlimited',
      }),
      soldOutQuantity: intl.formatMessage({
        description: 'sold out quantity label',
        defaultMessage: 'Sold out',
      }),
      columns: {
        code: intl.formatMessage({
          description: 'code',
          defaultMessage: 'Code',
        }),
        name: intl.formatMessage({
          description: 'name',
          defaultMessage: 'Name',
        }),
        netPrice: intl.formatMessage({
          description: 'net price',
          defaultMessage: 'Net Price',
        }),
        vatRate: intl.formatMessage({
          description: 'vat rate',
          defaultMessage: 'Vat Rate',
        }),
        taxedPrice: intl.formatMessage({
          description: 'taxed price',
          defaultMessage: 'Taxed Price',
        }),
        availability: intl.formatMessage({
          description: 'availability',
          defaultMessage: 'Availability',
        }),
        purchasedBy: intl.formatMessage({
          description: 'purchased by',
          defaultMessage: 'Purchased By',
        }),
        actions: intl.formatMessage({
          description: 'actions',
          defaultMessage: 'Actions',
        }),
      },
      status: {
        free: intl.formatMessage({
          description: 'status free',
          defaultMessage: 'Free',
        }),
        booked: intl.formatMessage({
          description: 'status booked',
          defaultMessage: 'Booked',
        }),
        purchased: intl.formatMessage({
          description: 'status purchased',
          defaultMessage: 'Purchased',
        }),
      },
    },
    dialog: {
      title: intl.formatMessage({
        description: 'add to cart dialog title',
        defaultMessage: 'Add to Cart',
      }),
      add: intl.formatMessage({
        description: 'add to cart button',
        defaultMessage: 'Add to cart',
      }),
      close: intl.formatMessage({
        description: 'close button',
        defaultMessage: 'Close',
      }),
      quantity: intl.formatMessage({
        description: 'quantity label',
        defaultMessage: 'Quantity',
      }),
    },
    snackbar: {
      addToCartFail: {
        label: intl.formatMessage({
          description: 'add to cart fail',
          defaultMessage: 'Error during add to cart operation',
        }),
      },
    },
  }),
};
