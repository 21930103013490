import React from 'react';
import { useIntl } from 'react-intl';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

// import { appState } from '@aim/common';
import { AimTypography, CustomIntl, AimCard, theme } from '@aim/components';

import useI18n from '../i18n';

const useStyles = makeStyles(() => ({
  card: {
    // display: 'flex',
    height: 150,
    borderRadius: 4,
    // '&:hover': {
    //   border: `3px solid ${theme.colors.primary.yellow}`,
    // },
  },
}));

const GrantServices = ({ participationGrant }) => {
  const classes = useStyles();
  const intl = CustomIntl(useIntl());
  const i18n = useI18n(intl);
  // const history = useHistory();
  console.log('booleans', participationGrant.booleans);

  return (
    <div
      style={{
        backgroundColor: theme.colors.greyScale.backgroundGrey,
        padding: 32,
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <AimTypography margin={0}>
            {i18n.page.listOfIncludedServices.toUpperCase()}
          </AimTypography>
          <Divider style={{ margin: '10px 0' }} />
          <AimTypography margin={0}>{i18n.page.selectTheOptions}</AimTypography>
          <Grid container>
            {participationGrant?.booleans?.map((service) => {
              if (service.value) {
                return (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={service.id}>
                    <AimCard className={classes.card}>
                      <AimTypography variant="textBold">
                        {service.label(intl)}
                      </AimTypography>
                      <Divider style={{ margin: '10px 0' }} />
                      <AimTypography>
                        {participationGrant.grant[`${service.id}Detail`]}
                      </AimTypography>
                    </AimCard>
                  </Grid>
                );
              }
            })}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default GrantServices;
