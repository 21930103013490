export default function (intl) {
  return {
    page: {
      titleScientific: intl.formatMessage({
        description: 'Social events page  Scientific title',
        defaultMessage: 'Scientific event',
      }),
      titleSocial: intl.formatMessage({
        description: 'Social events page Social title',
        defaultMessage: 'Social event',
      }),
      tabs: {
        socialEvents: intl.formatMessage({
          description: 'Social events tab label',
          defaultMessage: 'Social Events',
        }),
        scientificEvents: intl.formatMessage({
          description: 'Scientific events tab label',
          defaultMessage: 'Scientific Events',
        }),
      },
    },
    card: {
      soldOut: intl.formatMessage({
        description: 'sold out card overlay label',
        defaultMessage: 'Sold out',
      }),
      notAvailable: intl.formatMessage({
        description: 'additional service not card overlay label',
        defaultMessage: 'Not Available',
      }),
    },
    filter: {
      title: intl.formatMessage({
        description: 'additionalServices filter by title',
        defaultMessage: 'Title',
      }),
      dateService: intl.formatMessage({
        description: 'additionalServices filter by date',
        defaultMessage: 'date',
      }),
    },
  };
}
