const renderButton = (txt, link) => `
<div style="
    cursor: pointer;
    margin: 15px 0;
    border: 1px solid #FFD400;
    background-color: transparent;
    width: 166px;
    height: 40px;
    text-transform: uppercase;
    border-radius: 3px;
    text-align: center;
    line-height: 40px;"
>
    <a style="text-decoration: none; color: black; display: inline-block; width: 100%; height: 100%;" href="${link}">
        ${txt}
    </a>
</div>`;

export default renderButton;
