import commonFields from './CommonField';
import renderButton from './MailButton';

export const mailBrResultAccept = () => {
  const data = {
    ...commonFields,
    LINK: '##ACCEPT_LINK##',
    LINK_TXT: '##ACCEPT_TXT##',
    ICS: '##ICS##',
    ICS_TXT: '##ICS_TXT##',
  };

  const body = `
<table
  class="body"
  cellspacing="0"
  width="600"
  style="
font-family: Roboto, Arial, sans-serif;
"
>
  <tr>
    <td style="padding-left: 50px;width: 100%; font-weight: bold; font-size: 24px">${
      data.INVITE_TITLE
    }</td>
  </tr>
  <tr>
    <td style="padding-left: 50px;width: 100%;">
    ${data.TIME_GREETING},
    </td>
  </tr>
  <tr>
    <td style="padding-left: 50px;width: 100%;">
    ${data.EMAIL_MESSAGE_1} "${data.SPONSOR_NAME}" ${data.EMAIL_MESSAGE_2} ${
    data.DATE
  }.
    </td>
  </tr>
  <tr>
    <td style="padding-left: 50px;width: 100%;">     
      <a style="text-decoration: none; font-weight: bold; font-size:18px;" href="${
        data.LINK
      }">${data.LINK_TXT}</a>
    </td>
  </tr>  
  <!-- <tr>
    <td style="padding-left: 50px;width: 100%;">
      ${renderButton(data.ICS_TXT, data.ICS)}  
    </td>
  </tr> -->  
  <tr>
    <td style="padding-left: 50px;width: 100%;">
      ${data.GREATING}    
    </td>
  </tr>
  <tr>
    <td style="padding-left: 50px;width: 100%;">
      ${data.SIGN}   
    </td>
  </tr>
</table>`;

  return {
    data,
    body,
  };
};
