import React, { useRef, useState, useEffect } from 'react';
import StarIcon from '@material-ui/icons/Star';

import { AimTypography, AimIconAndTextButton, styled } from '@aim/components';

const Container = styled('div')({
  aspectRatio: 1,
  // position: 'relative',
  // backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
});

const TextOverlay = styled('div')({
  color: 'black',
  padding: '1.5rem',
  width: '100%',
  // width: isMobileOnly ? '95%' : '100vw',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& div': {
    '& div::first-letter': {
      textTransform: 'capitalize',
    },
  },
});

const StyledButton = styled(AimIconAndTextButton)(
  ({ backgroundColor, textColor }) => ({
    height: '30px',
    backgroundColor,
    margin: 0,
    '& span': {
      '& > div': {
        fontSize: '8px',
      },
    },
    '&:hover': {
      backgroundColor,
      color: textColor,
    },
    '& span div div': {
      marginTop: 11,
    },
    color: textColor,
  })
);

const Range = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const SponsorCard = ({
  title,
  subTitle,
  img,
  titleVariant = 'h4',
  subTitleVariant = 'h5Regular',
  style,
  range,
  priceRange,
  ...rest
}) => {
  const widthRef = useRef(null);
  const [height, setHeight] = useState(175);

  useEffect(() => {
    setHeight(((widthRef.current?.clientWidth || 100) / 3) * 2);
  });

  return (
    <div
      ref={widthRef}
      style={{
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        ...style,
      }}
      {...{ ...rest }}
    >
      {/* {img ? (
        <AimImage
          src={img}
          alt={title}
          style={{ width: size, height: (size / 3) * 2, objectFit: 'cover' }}
        />
      ) : null} */}
      <Container
        style={{
          ...(img && { backgroundImage: `url("${img}")` }),
          display: 'flex',
          width: '100%',
          height,
          // objectFit: 'cover',
        }}
      >
        <TextOverlay>
          {!img && (
            <AimTypography
              margin={0}
              variant={titleVariant}
              // style={{ textTransform: 'capitalize' }}
            >
              {title}
            </AimTypography>
          )}
        </TextOverlay>
        {/* <Ranges>{getRangeImage(range.name)}</Ranges> */}
      </Container>
      {range ? (
        <Range>
          <div
            style={{
              width: '100%', // '80%',
              height: '55px',
              position: 'absolute',
              bottom: 0,
              left: 0,
              // background:
              //   //'linear-gradient(to left, transparent 0%, rgba(255, 255, 255, 0.8) 30%, gray 100%)',
              //   `linear-gradient(to left, transparent 0%, ${theme.colors.greyScale.grey1}80 30%, gray 100%)`,
            }}
          >
            <div
              style={{
                backgroundColor: range.color?.colorBckgr,
                position: 'absolute',
                left: 15,
                bottom: 15,
                borderRadius: 6,
                opacity: 1,
                zIndex: 10,
                ...(priceRange && { boxShadow: '0 6px 8px rgb(0 0 0 / 12%)' }),
              }}
            >
              <StyledButton
                variant="primary"
                // backgroundColor={range.color ? range.color : '#FFDF00CC'}
                backgroundColor="transparent"
                textColor={range.color?.colorIcon}
                isIconLeft
                // onClick={() => history.push('/events/')}
                text={range.name}
              >
                {/* <StarIcon style={{ color: '#DAA520FF' }} /> */}
                <StarIcon style={{ color: range.color?.colorIcon }} />
              </StyledButton>
            </div>
          </div>
        </Range>
      ) : null}
    </div>
  );
};
