import React from 'react';

import { format } from 'date-fns';

import Grid from '@material-ui/core/Grid';

import { AimTypography } from '@aim/components';

// const PresenterImage = styled('div')(({ background }) => ({
//   backgroundImage: `url(${background})`,
//   backgroundSize: 'cover',
//   backgroundRepeat: 'no-repeat',
//   marginBottom: '2%',
//   height: '60%',
//   minHeight: 300,
// }));

const UpNextTile = ({ tileData: intervention }) => {
  return (
    <Grid item xs={3}>
      {/* <PresenterImage background={intervention.coverImage} /> */}
      <AimTypography variant="text" margin={0}>
        {intervention.start
          ? format(new Date(intervention.start), 'dd LLLL - HH:mm')
          : ''}{' '}
        - {intervention.end ? format(new Date(intervention.end), 'HH:mm') : ''}{' '}
        - {intervention.room}
      </AimTypography>
      <AimTypography variant="h2" margin={0}>
        {intervention.name}
      </AimTypography>
      {/* <AimTypography variant="text">
        {intervention.abstract || '-'}
      </AimTypography> */}
    </Grid>
  );
};

export { UpNextTile };
