import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { appState } from '@aim/common';

export const useWelcomePageModulesRedirects = () => {
  const { eventId } = useParams();

  const getServiceRedirectLinkEvent = useCallback(
    (key) => {
      const link = appState.eventConfiguration.getValue()?.[
        `welcomePage${key}LinkEvent`
      ];
      return link ? `/events/${eventId}/${link}` : null;
    },
    [eventId]
  );

  return { getServiceRedirectLinkEvent };
};
