import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import {
  AimTypography,
  AimIconAndTextButton,
  AimSelect,
  AimSelectMenuItem,
} from '@aim/components';
import { constants } from '@aim/common';

import { getEndDateOfEvent } from './agencyPaymentGqlHelper';
import { isBefore } from 'date-fns';

const PAYMENT_GATEWAY = {
  MONETAONLINE: 1,
  INGENICO: 2,
  GPWEBPAY: 3,
};

const paymentOptions = [
  {
    value: PAYMENT_GATEWAY.MONETAONLINE,
    name: 'MonetaOnline',
  },
  {
    value: PAYMENT_GATEWAY.INGENICO,
    name: 'Ingenico',
  },
  {
    value: PAYMENT_GATEWAY.GPWEBPAY,
    name: 'Gp Webpay',
  },
];

const Checkout = ({
  //   checkoutBookings,
  //   setCheckoutBookings,
  budget,
  onClickConfirm,
  onHandlePayment,
  gatewayType,
  paymentType,
  i18n,
  iban,
  canChooseGateway = true,
}) => {
  // Hooks
  const { eventId } = useParams();

  // States
  const [isEventEnd, setIsEventEnd] = useState([]);

  //Effects
  useEffect(() => {
    fetchEndDateOfEvent();
  }, []);

  // Functions
  const fetchEndDateOfEvent = async () => {
    const res = await getEndDateOfEvent(eventId);
    const isEndBeforeToday = isBefore(new Date(res.end), new Date());
    setIsEventEnd(isEndBeforeToday);
  };

  //   const onDeleteRow = (id) =>
  //     setCheckoutBookings([...checkoutBookings].filter((cb) => cb.id !== id));
  return (
    <div>
      {isEventEnd && (
        <div>
          <AimTypography variant="text" style={{ color: 'red' }}>
            {i18n.checkoutAlerts.eventIsOver.label}
          </AimTypography>
        </div>
      )}
      <div style={{ backgroundColor: 'white', margin: 10 }}>
        <Grid
          container
          style={{
            paddingLeft: 35,
            paddingRight: 35,
            paddingTop: 10,
          }}
        >
          <Grid item xs={9}>
            <AimTypography variant={'h5'}>
              {i18n.checkoutTable.columns.service.label}
            </AimTypography>
          </Grid>
          <Grid item xs={1}>
            <AimTypography variant={'h5'}>
              {i18n.checkoutTable.columns.quantity.label}
            </AimTypography>
          </Grid>
          <Grid item xs={1}>
            <AimTypography variant={'h5'}>
              {i18n.checkoutTable.columns.amount.label}
            </AimTypography>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
        {/* {checkoutBookings.map((cb) => (
          <CheckoutRow key={cb.id} booking={cb} onDelete={onDeleteRow} />
        ))} */}
        {paymentType === constants.PaymentTypes.BankTransfer ? (
          <div style={{ padding: 20, marginLeft: 10 }}>
            <AimTypography>IBAN {iban}</AimTypography>
          </div>
        ) : null}
        <div style={{ marginTop: '5%' }}>
          <Grid
            container
            style={{ paddingLeft: 35, paddingRight: 35, paddingTop: 10 }}
          >
            <Grid item xs={9}></Grid>
            <Grid item xs={1}>
              <AimTypography variant={'h5'}>
                {i18n.checkoutTable.summary.netTotal.label}
              </AimTypography>
            </Grid>
            <Grid item xs={1}>
              <AimTypography variant={'h4Regular'}>
                {Number(budget).toFixed(2)}
              </AimTypography>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={8}></Grid>
            <Grid item xs={3}>
              <Divider variant="inset" />
            </Grid>
          </Grid>
        </div>
      </div>
      <Grid container alignItems="center">
        <Grid item xs={9}></Grid>
        <Grid item xs={2}>
          {paymentType === constants.PaymentTypes.CreditCard &&
            canChooseGateway && (
              <AimSelect
                value={gatewayType}
                onChange={onHandlePayment}
                displayEmpty
                formControlStyle={{
                  width: 220,
                  marginTop: 10,
                }}
                inputProps={{ 'aria-label': 'Without label' }}
                selectVariant="white"
                selectPlaceholder="Choose payment method"
              >
                {paymentOptions &&
                  paymentOptions.map((item) => (
                    <AimSelectMenuItem
                      key={item.value}
                      value={item.value}
                      disabled={item.disabled}
                    >
                      {item.name}
                    </AimSelectMenuItem>
                  ))}
              </AimSelect>
            )}
        </Grid>
        <Grid item xs={1}>
          <AimIconAndTextButton
            isUpperCase
            variant="yellow"
            text={i18n.checkoutTable.buttons.confirm.label}
            style={{
              marginRight: '5%',
              paddingLeft: 30,
              paddingRight: 30,
            }}
            disabled={
              isEventEnd ||
              (paymentType === constants.PaymentTypes.CreditCard &&
                (!gatewayType || gatewayType === ''))
            }
            onClick={onClickConfirm}
          ></AimIconAndTextButton>
        </Grid>
      </Grid>
    </div>
  );
};

export default Checkout;
