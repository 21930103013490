export const translation = {
  landing: (intl) => ({
    buttons: {
      joinEvent: intl.formatMessage({
        description: 'join the event btn',
        defaultMessage: 'join the event',
      }),
      myArea: intl.formatMessage({
        description: 'my area btn',
        defaultMessage: 'my area',
      }),
      plan: intl.formatMessage({
        description: 'plan participation btn',
        defaultMessage: 'plan your participation',
      }),
    },
  }),
  defaultLanding: (intl) => ({
    registration: intl.formatMessage({
      description: 'register card',
      defaultMessage: 'Register',
    }),
    abstract: intl.formatMessage({
      description: 'abstract card',
      defaultMessage: 'Send abstract',
    }),
    abstractGrant: intl.formatMessage({
      description: 'abstract grant card',
      defaultMessage: 'Submit to Abstract Grant',
    }),
    educationalGrant: intl.formatMessage({
      description: 'educational grant card',
      defaultMessage: 'Submit to Educational Grant',
    }),
    allotment: intl.formatMessage({
      description: 'allotment card',
      defaultMessage: 'Plan Allotment',
    }),
    attendScientificEvents: intl.formatMessage({
      description: 'attend scientific events card',
      defaultMessage: 'Attend Scientific events',
    }),
    attendScientificEventsSubTitle: intl.formatMessage({
      description: 'courses and pre-courses card',
      defaultMessage: 'Courses and pre-courses',
    }),
    attendSocialEvents: intl.formatMessage({
      description: 'attend social events card',
      defaultMessage: 'Attend Social events',
    }),
    attendSocialEventsSubTitle: intl.formatMessage({
      description: 'lunch, tours and social events card',
      defaultMessage: 'Lunch, tours and social events',
    }),
    visa: intl.formatMessage({
      description: 'visa card',
      defaultMessage: 'Request Visa',
    }),
  }),
  sponsorLanding: (intl) => ({
    sponsorProspectus: intl.formatMessage({
      description: 'sponsor landing sponsor prospectus label',
      defaultMessage: 'Sponsor Prospectus',
    }),
    accessToServices: intl.formatMessage({
      description: 'sponsor landing access to services button label',
      defaultMessage: 'Access to services',
    }),
  }),
};
