import React, { useRef } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { isAfter, isSameDay, isWithinInterval, format } from 'date-fns';
import translation from './paxAbstracts/translation';

import AddIcon from '@material-ui/icons/Add';
import ViewIcon from '@material-ui/icons/Visibility';
import ClearIcon from '@material-ui/icons/Clear';
import ArrowForward from '@material-ui/icons/ArrowForward';
import EditIcon from '@material-ui/icons/Edit';
// import CallMadeIcon from '@material-ui/icons/CallMade';

import { appState, constants } from '@aim/common';
import { AimTypography, CustomIntl, theme, AimCardPage } from '@aim/components';

import { updateAbstractDocument } from './shared/participationGqlHelper';

const PaxAbstracts = () => {
  const intl = CustomIntl(useIntl());
  const i18n = translation.paxAbstracts(intl);
  const history = useHistory();
  const userRef = useRef(appState.user.getValue());
  const { eventId } = useParams();

  const getAbstracts = /* GraphQL */ `
    query getUserParticipationsAbstracts($id: ID!, $eventId: ID!) {
      getParticipation(id: $id) {
        isReviewer
        abstractDocuments(filter: { isDeleted: { ne: true } }) {
          items {
            cod0
            cod1
            cod2
            createdAt
            images
            id
            isDeleted
            isReadyToReview
            preferredExposure
            processingStatus
            abstractService {
              id
              maxAbstractsPerAuthor
            }
            status
            tags
            title
            url
            updatedAt
            acceptedPresentationTypology {
              createdAt
              description
              name
              id
              presentationMode
              presentationRequired
              updatedAt
            }
            proposedPresentationTypology {
              createdAt
              description
              id
              name
              presentationMode
              presentationRequired
              updatedAt
            }
            reviews {
              items {
                id
                updatedAt
              }
              nextToken
            }
            keywords
            presenter {
              participant {
                id
              }
            }
          }
        }
      }
      getEvent(id: $eventId) {
        id
        isEditAbstractAfterSubmissionEnabled
        abstractService {
          id
          instructions
          isPublished
          reviewers {
            items {
              id
              participant {
                id
                email
                givenName
              }
              reviewerCategories {
                items {     
                  reviewerCategoryReviewerId
                  category {   
                    id
                    name
                  }
                  reviewerCategoryCategoryId
                }
              }
            }
          }
          abstractDocuments {
            items {
              id 
              presenter {
                participant {
                  id
                }
              }
            }
          }
          presentationTypologies {
            items {
              id
              name
              presentationMode
              validationDate
              expirationDate
              isLateBreaker
              categories {
                items {
                  id
                  category {
                    id
                    name
                    subCategories {
                      items {
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
          }
          categories {
            items {
              id
              name
              subCategories {
                items {
                  id
                  name
                }
              }
            }
          }
          tags
          agreement
          event {
            services(filter: { serviceName: { eq: "${constants.Services.ABSTRACT.key}" } }) {
              items {
                id
                serviceName
                customFields(filter: { isDeleted: { ne: true } }) {
                  items {
                    contextsOfUse {
                      items {
                        isRequired
                        position
                        isHidden
                        contextName
                        id
                      }
                    }
                    label
                    options
                    id
                    placeholder
                  }
                }
              }
            }
          }
          abstractTerm
          lateBreakersTerm
          maxAbstractsPerAuthor
          maxCategoriesPerReviewer
          isKeywordAddEnabled
          minNumberKeyword
          maxNumberKeyword
          isImageUploadEnabled
          maxTitleCharacters
          maxBodyCharacters
          isExternalUploadEnabled
          maxNumberExternalUpload
          extensionsExternalUpload
          maxDimensionExternalUpload
          isRichTextUploadEnabled
          maxNumberRichTextUpload
          extensionsRichTextUpload
          maxDimensionRichTextUpload
        }
      }
    }
  `;

  const handleDelete = async (row) => {
    try {
      await updateAbstractDocument({
        isDeleted: true,
        id: row.id,
      });
    } catch {
      console.error('abstract-card-soft-delete');
    }
  };

  const renderDeadlines = (tData, data) => {
    if (data?.getEvent?.abstractService) {
      const {
        maxAbstractsPerAuthor,
        lateBreakersTerm,
        abstractTerm,
        presentationTypologies,
        maxDimensionExternalUpload,
      } = data?.getEvent?.abstractService;

      const isThereAtLeastOneLateBreackers = presentationTypologies.items.find(
        (x) =>
          x.isLateBreaker &&
          isWithinInterval(new Date(), {
            start: new Date(x.validationDate),
            end: new Date(x.expirationDate),
          })
      );
      const renderMaxAbstracts = (
        <AimTypography variant="text">
          {`${i18n.subtitles.maxAbstracts} ${maxAbstractsPerAuthor} abstracts`}
        </AimTypography>
      );
      const maxSizeUploads =
        maxDimensionExternalUpload > 0 ? (
          <AimTypography variant="text">
            {i18n.subtitles.maxSize + ' ' + maxDimensionExternalUpload + ' MB'}
          </AimTypography>
        ) : null;
      return (
        <div
          style={{ backgroundColor: 'white', padding: 20, margin: '20px 0px' }}
        >
          {isThereAtLeastOneLateBreackers && lateBreakersTerm ? (
            <>
              <AimTypography variant="textBold">
                {i18n.subtitles.lateBreakersTerm}
              </AimTypography>
              <AimTypography variant="textBold">
                {`${i18n.subtitles.deadline}: ${format(
                  new Date(lateBreakersTerm),
                  'dd/MM/yyyy' //'P'
                )}`}
              </AimTypography>
              {maxAbstractsPerAuthor > 0 && renderMaxAbstracts}
              {maxSizeUploads}
            </>
          ) : (
            <>
              <AimTypography variant="textBold">
                {`${i18n.subtitles.abstractsTerm} ${format(
                  new Date(abstractTerm),
                  'dd/MM/yyyy' //'P'
                )}`}
              </AimTypography>
              {maxAbstractsPerAuthor > 0 && renderMaxAbstracts}
              {maxSizeUploads}
            </>
          )}
        </div>
      );
    }
  };

  const isAbstractTermPassed = (abstractTerm) => {
    return (
      isAfter(new Date(), new Date(abstractTerm)) &&
      !isSameDay(new Date(), new Date(abstractTerm))
    );
  };

  const isLateBreakersTermPassed = (lateBreakersTerm) => {
    return (
      isAfter(Date.now(), new Date(lateBreakersTerm)) &&
      !isSameDay(new Date(), new Date(lateBreakersTerm))
    );
  };

  const model = (loadData) => ({
    header: {
      backButton: {
        onClick: () => history.replace(`/events/${eventId}/landing`),
      },
      action: {
        add: {
          icon: <AddIcon />,
          variant: 'secondary',
          onClick: () => history.push(`/events/${eventId}/abstracts/create`),
          disabledWhen: (rows, data, tData) => {
            if (data?.getEvent?.abstractService) {
              const {
                isPublished: isAbstractServicePublished,
                maxAbstractsPerAuthor,
                lateBreakersTerm,
                abstractTerm,
              } = data.getEvent.abstractService;

              let isMaxAbsPerAuthor = maxAbstractsPerAuthor === tData?.length;
              if (maxAbstractsPerAuthor === 0) {
                isMaxAbsPerAuthor = false;
              }

              const isDisabled =
                (isAbstractTermPassed(abstractTerm) &&
                  isLateBreakersTermPassed(lateBreakersTerm)) ||
                !isAbstractServicePublished ||
                isMaxAbsPerAuthor;
              return isDisabled;
            }
          },
        },
      },
    },
    preTableComponent: (tData, data) => renderDeadlines(tData, data),
    dataLoader: {
      query: getAbstracts,
      variables: {
        id: userRef.current.userAndParticipation.participation.id,
        eventId,
      },
      transform: (data) => {
        const abstractDocuments = data.getParticipation.abstractDocuments;
        // const abstractService = data.getEvent.abstractService;

        const {
          lateBreakersTerm,
          abstractTerm,
        } = data.getEvent.abstractService;

        return abstractDocuments.items.map((abstract) => {
          // const isMaxAbsPerAuthor =
          //   abstractService.maxAbstractsPerAuthor === abstractDocuments.length;
          return {
            ...abstract,
            statusLabel: Object.values(constants.AbstractStatus)
              .find((x) => x.id === abstract.status)
              .label(intl),
            presentationMode: abstract.proposedPresentationTypology?.name || '',
            acceptedAs: abstract.acceptedPresentationTypology?.name || '',
            isEditAfterSubmissionEnabled:
              data.getEvent.isEditAbstractAfterSubmissionEnabled,
            isAbstractTermPassed: isAbstractTermPassed(abstractTerm),
            isLateBreakersTermPassed: isLateBreakersTermPassed(
              lateBreakersTerm
            ),
            // isMaxAbsPerAuthor,
            setupYourPresentation: {
              variant: 'secondary',
              link: () =>
                history.push(
                  `/events/${eventId}/abstracts/${abstract.id}/presentation`
                ),
              icon: <ArrowForward />,
            },
          };
        });
      },
    },
    card: {
      isRowSelectable: true,
      cardsTemplate: [
        // 'status',
        'statusLabel',
        'presentationMode',
        'acceptedAs',
        // { buttons: ['setupYourPresentation'] },
      ],
      filters: [
        {
          column: 'title',
          type: 'string',
        },
        {
          column: 'statusLabel',
          type: 'string',
        },
        {
          column: 'presentationMode',
          type: 'string',
        },
        {
          column: 'acceptedAs',
          type: 'string',
        },
      ],
      cardAction: {
        view: {
          icon: <ViewIcon />,
          variant: 'primary',
          onClick: (row) =>
            history.push(`/events/${eventId}/abstracts/${row.id}/view`),
        },
        edit: {
          icon: <EditIcon />,
          variant: 'primary',
          onClick: (row) =>
            history.push(`/events/${eventId}/abstracts/${row.id}/edit`),
          disabledWhen: (row) => {
            const canEdit =
              row.status === 'draft' ||
              row.status === 'sent' ||
              (row.isEditAfterSubmissionEnabled &&
                !row.isAbstractTermPassed &&
                !row.isLateBreakersTermPassed);
            return !canEdit;
          },
        },
        delete: {
          icon: <ClearIcon />,
          variant: 'secondary',
          onClick: 'dialog',
          dialog: 'delete',
          snackbar: 'delete',
          disabledWhen: (row) => {
            // const deleteDisabled =
            //   row.abstractService?.maxAbstractsPerAuthor == tData?.length;

            const isDeleteDisabled =
              row.isAbstractTermPassed && row.isLateBreakersTermPassed;
            return isDeleteDisabled;
            // (row.status !== 'draft' && row.status !== 'sent')
          },
        },
      },
    },
    dialog: {
      delete: {
        onAgree: async (row) => {
          await handleDelete(row);
          await loadData();
        },
        onDisagree: () => {},
      },
    },
    snackbar: {
      delete: {},
    },
  });

  return (
    <>
      <div
        style={{
          flex: 1,
          padding: 10,
          minWidth: 'calc(100vw - 120px)',
          backgroundColor: theme.colors.greyScale.backgroundGrey,
        }}
      >
        <AimCardPage model={model} translation={i18n.aimTablePage} />
      </div>
    </>
  );
};

export default PaxAbstracts;
