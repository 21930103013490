import React, { useState, useEffect } from 'react';
import { sortBy } from 'lodash';

import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import ArrowBack from '@material-ui/icons/ArrowBack';

import { useParams, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { useOtherServicesService } from '../../shared/otherServicesServiceGqlHelper';
import translation from '../../shared/translation';
import { useSponsors } from '../../shared/sponsorGqlHelper';

import {
  AimIconAndTextButton,
  AimTitleAndButtons,
  // AimCard,
  AimWallCard,
  AimTypography,
  AimSnackbar,
  CustomIntl,
  AimRichText,
  AimFileList,
  AimSnackbarSeverity,
} from '@aim/components';
import { appState, aws } from '@aim/common';

import {
  MainContainer,
  InnerContainer,
} from '../../shared/SponsorPagesContainers';

// const styleCard = {
//   backgroundColor: theme.colors.greyScale.backgroundGrey,
//   cursor: 'pointer',
// };

const PhysicalStandCategoriesList = () => {
  //Hooks
  const { eventId, sponsorId, spaceId } = useParams();

  const history = useHistory();
  const { list } = useOtherServicesService();
  const { get: getSponsor, update: updateSponsor } = useSponsors();

  const intl = CustomIntl(useIntl());
  const i18n = translation.physicalStandCategories(intl);

  //State
  const [sponsorData, setSponsorData] = useState();
  const [displayOtherStandServices, setDisplayOtherStandServices] = useState(
    []
  );
  const [
    otherServicesServiceService,
    setOtherServicesServiceService,
  ] = useState();
  const [snackbar, setSnackbar] = useState({ isOpen: false });

  const mandatoryDocs = otherServicesServiceService?.documents.items?.filter(
    (d) => d.isMandatory
  );
  const hasReadMandatoryDocs =
    !mandatoryDocs?.length ||
    mandatoryDocs?.every(
      (md) => !!sponsorData?.requiredDocumentsIds.find((rd) => rd === md.id)
    );

  //Effects
  useEffect(() => {
    fetchOtherStandServices();
    fetchSponsor();
  }, []);

  //Functions
  const fetchOtherStandServices = async () => {
    const result = await list(eventId);
    const sortedItems = sortBy(
      result.otherStandServicesService.itemCategories?.items,
      'name'
    );
    const otherStandServices = sortedItems.map((s) => {
      return {
        id: s.id,
        name: s.name,
        nSubCategories: s.itemSubcategories?.items?.length || 0,
        nServices: s.itemSubcategories?.items?.reduce(
          (a, b) => a + (b?.childItems?.items?.length || 0),
          0
        ),
      };
    });
    otherStandServices && setDisplayOtherStandServices(otherStandServices);
    setOtherServicesServiceService({
      ...result.otherStandServicesService,
      documents: {
        items: result.otherStandServicesService.documents.items.sort((a, b) =>
          a.name.localeCompare(b.name)
        ),
      },
    });
  };

  const downloadFile = async (item) => {
    const s3Folder = `events/${eventId}/sponsor/other-services/documents/`;
    const link = `${s3Folder}${item.id}${item.extension}`;

    var res = await aws.Storage.get(link);
    window.open(res);
    onDownload(item);
  };

  const fetchSponsor = async () => {
    const userAndParticipation = appState.user.getValue()?.userAndParticipation;
    const sponsor =
      userAndParticipation?.participation?.sponsor ||
      userAndParticipation?.participation?.sponsorStaff?.sponsor;

    const sponsorData = await getSponsor(sponsor.id);
    setSponsorData(sponsorData);
  };

  // Helpers
  const onDownload = async (fileData) => {
    const documentId = fileData.id;
    if (
      fileData.isMandatory &&
      !sponsorData?.requiredDocumentsIds.includes(documentId)
    ) {
      const nextRequiredDocumentsIds = [
        ...sponsorData?.requiredDocumentsIds,
        documentId,
      ];
      await updateSponsor({
        id: sponsorData.id,
        requiredDocumentsIds: nextRequiredDocumentsIds,
      });

      fetchSponsor();
    }
  };

  const handleNavigation = (rowId) => {
    if (!hasReadMandatoryDocs)
      return setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.snackbar.missingDownloadsError,
      });

    history.push(
      `/events/${eventId}/${sponsorId}/services-configuration/physical-stands/${spaceId}/category/${rowId}`
    );
  };

  return (
    <MainContainer>
      <div style={{ flex: 1 }}>
        <InnerContainer>
          <div style={{ display: 'flex', flex: 1 }}>
            <Tooltip title={i18n.page.back.tooltip.label}>
              <AimIconAndTextButton
                style={{
                  padding: 0,
                }}
                variant="text"
                text={i18n.page.back.button.label}
                onClick={() =>
                  history.push(`/events/${eventId}/${sponsorId}/my-services`)
                }
              >
                <ArrowBack />
              </AimIconAndTextButton>
            </Tooltip>
          </div>
          <AimTitleAndButtons title={i18n.page.title.label} />
          <Grid item xs={12}>
            {otherServicesServiceService?.description && (
              <AimRichText
                label={i18n.page.instructions}
                value={JSON.parse(otherServicesServiceService.description)}
                readOnly
                editorStyleOverride={{
                  minHeight: 'auto',
                  maxHeight: 'fit-content',
                  overflow: 'hidden !important',
                }}
              />
            )}
          </Grid>

          {otherServicesServiceService?.documents?.items?.length ? (
            <Grid container spacing={2} style={{ marginTop: 20 }}>
              <Grid
                item
                xs={12}
                style={{
                  backgroundColor: 'white',
                  maxWidth: '98%',
                }}
              >
                <Grid
                  item
                  container
                  alignItems={'center'}
                  style={{
                    backgroundColor: 'white',
                  }}
                >
                  <Grid
                    item
                    xs={10}
                    style={{
                      paddingLeft: 15,
                    }}
                  >
                    <AimTypography variant={'h3'}>
                      {i18n.page.fileList.fileName}
                    </AimTypography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    style={{
                      paddingLeft: 60,
                    }}
                  >
                    <AimTypography variant={'h3'}>
                      {i18n.page.fileList.fileDimension}
                    </AimTypography>
                  </Grid>
                </Grid>
                <AimFileList
                  files={otherServicesServiceService.documents.items}
                  onDownload={downloadFile}
                />
              </Grid>
            </Grid>
          ) : null}

          <Grid container spacing={2}>
            {displayOtherStandServices.length > 0 ? (
              <>
                {displayOtherStandServices.map((i) => (
                  <Grid item xs={3} key={i.id}>
                    <AimWallCard
                      cardVariant="grey"
                      label={i.name}
                      onIconClick={() => handleNavigation(i.id)}
                    />
                    {/* <AimCard
                      title={i.name}
                      titleVariant="h5"
                      onClick={() => handleNavigation(i.id)}
                      style={styleCard}
                    >
                      <Grid container style={{ height: 80 }}>
                       <Grid item xs={12}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <div>
                              <AimTypography>
                                {i18n.cards.subCategories.label}
                              </AimTypography>
                            </div>
                            <div>
                              <AimTypography>{i?.nSubCategories}</AimTypography>
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <div>
                              <AimTypography>
                                {i18n.cards.services.label}
                              </AimTypography>
                            </div>
                            <div>
                              <AimTypography>{i?.nServices}</AimTypography>
                            </div>
                          </div>
                        </Grid>
                      </Grid> 
                    </AimCard>
                    */}
                  </Grid>
                ))}
              </>
            ) : (
              <>
                <AimTypography>{i18n.page.noCategories.label}</AimTypography>
              </>
            )}
          </Grid>
        </InnerContainer>
      </div>
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </MainContainer>
  );
};

export default PhysicalStandCategoriesList;
