import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams, useHistory } from 'react-router-dom';

import Tooltip from '@material-ui/core/Tooltip';
import ArrowBack from '@material-ui/icons/ArrowBack';

import {
  AimIconAndTextButton,
  CustomIntl,
  AimTitleAndButtons,
  AbstractDetailPage,
} from '@aim/components';

import { fileHelper } from '@aim/common';

import useI18n from './agendaEventDetail/i18n';

import { getAbstractDocument } from '../../hooks/useAbstractDocument';

const InterventionAbstract = () => {
  const intl = CustomIntl(useIntl());
  const i18n = useI18n(intl);

  const { getS3Link } = fileHelper;

  const { sessionId, eventId, abstractId } = useParams();
  const [abstract, setAbstract] = useState();
  const history = useHistory();

  const getAttachmentURL = async (path, fileData) =>
    await getS3Link(path, fileData);

  useEffect(() => {
    const fetchAbstract = async () => {
      // Fetching AbstractDocument
      const abstractRes = await getAbstractDocument(abstractId);

      setAbstract(abstractRes);
    };
    if (!abstract) fetchAbstract();
  }, []);

  const handleClick = async (promiseArray) => {
    const res = await Promise.all(promiseArray);
    window.open(res[0], '_blank');
  };

  if (!abstract) return null;

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          overflowY: 'auto',
          width: 'calc(100vw - 20px)',
        }}
      >
        <div
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            backgroundColor: '#FFF',
            paddingBottom: '20px',
          }}
        >
          <div style={{ maxWidth: '80vw', margin: '0 auto' }}>
            <div
              style={{
                width: '100%',
              }}
            >
              <Tooltip title={i18n.page.backButton.tooltip}>
                <AimIconAndTextButton
                  variant="text"
                  style={{ padding: 0 }}
                  text={i18n.page.backButton.posterDetailLabel}
                  onClick={() =>
                    history.replace(
                      `/events/${eventId}/agenda/sessions/${sessionId}`
                    )
                  }
                >
                  <ArrowBack />
                </AimIconAndTextButton>
              </Tooltip>
              <div>
                <AimTitleAndButtons title={abstract?.title} />
              </div>
              <div>
                {abstract && (
                  <AbstractDetailPage
                    intl={intl}
                    abstractDocument={abstract}
                    maxHeight={'unset'}
                    hideAbstractInfo
                    onDownloadAttachments={(fileData) =>
                      handleClick([
                        getAttachmentURL(
                          `events/${eventId}/abstracts/${poster.abstract.id}/`,
                          {
                            id: fileData.id + fileData.extension,
                            extension: '',
                            originalName:
                              poster.abstract.title.replace(/\s+/g, '_') +
                              fileData.extension,
                          }
                        ),
                      ])
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InterventionAbstract;
