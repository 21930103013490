import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import useI18n from './paxAbstractsCategoriesSelection/i18n';

import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { makeStyles } from '@material-ui/core/styles';

import { appState } from '@aim/common';

import {
  AimCard,
  AimTypography,
  AimIconAndTextButton,
  CustomIntl,
  // theme,
} from '@aim/components';

import { getEventAbstractService } from './shared/participationGqlHelper';
import {
  // updateCategory,
  createReviewerCategory,
} from './shared/paxReviewerGqlHelper';

const highlightedStyle = (configuration) => ({
  border: `3px solid ${configuration.primaryColor}`, // ${theme.colors.primary.yellow}`,
  padding: '0px !important',
});

const useStyles = makeStyles(() => {
  return {
    card: (configuration) => ({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 210,
      height: 115,
      borderRadius: 4,
      textTransform: 'uppercase',
      cursor: 'pointer',
      '& > div': { padding: '0px !important' },

      '&:hover': highlightedStyle(configuration),
      // '& div:first-child': { padding: '0px' },
    }),
  };
});

const PaxAbstractsCategoriesSelection = () => {
  const eventConfiguration = appState.eventConfiguration.getValue();
  const classes = useStyles(eventConfiguration);
  const intl = CustomIntl(useIntl());
  const i18n = useI18n(intl);
  const history = useHistory();
  const { eventId } = useParams();

  const [abstractService, setAbstractService] = useState();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [prevSelectedCategories, setPrevSelectedCategories] = useState([]);
  const [configuration] = useState(appState.eventConfiguration.getValue());

  useEffect(() => {
    getAbstractServiceCategories(eventId);
  }, []);

  useEffect(() => {
    if (abstractService) {
      const reviewerData = getCurrentReviewerData();
      setPrevSelectedCategories(reviewerData.reviewerCategories?.items);
    }
  }, [abstractService]);

  const getAbstractServiceCategories = async () => {
    try {
      const abstractService = await getEventAbstractService(eventId);
      setAbstractService(abstractService);
    } catch (err) {
      console.error(err);
    }
  };

  const getCurrentReviewerData = () => {
    if (!abstractService) return null;

    const participationId = appState.user.getValue().userAndParticipation
      .participation.id;
    const reviewer = abstractService?.reviewers?.items?.find(
      (r) => r.participant.id === participationId
    );
    return reviewer;
  };

  const handleCategorySelection = (cat) => {
    const categoryPreviouslySelected = !!prevSelectedCategories.find(
      (prevCat) => prevCat?.category?.id === cat.id
    );
    if (categoryPreviouslySelected) {
      return;
    }

    const categoryTemporarySelected = selectedCategories.some(
      (selCat) => selCat.id === cat.id
    );

    if (categoryTemporarySelected) {
      setSelectedCategories(
        [...selectedCategories].filter((selCat) => selCat.id !== cat.id)
      );
    } else {
      const maxCatNumberReached =
        abstractService.maxCategoriesPerReviewer === 0
          ? false
          : selectedCategories.length + prevSelectedCategories.length >=
            abstractService.maxCategoriesPerReviewer;
      if (maxCatNumberReached) {
        return;
      }
      setSelectedCategories([...selectedCategories, cat]);
    }
  };

  const goToAbstracsList = async () => {
    appState.isLoader.next(true);
    const reviewerCategoriesUpdateOperations = [];

    const reviewer = getCurrentReviewerData();

    selectedCategories.forEach((cat) =>
      reviewerCategoriesUpdateOperations.push(
        createReviewerCategory({
          reviewerCategoryCategoryId: cat.id,
          reviewerCategoryReviewerId: reviewer.id,
        })
      )
    );

    await Promise.all(reviewerCategoriesUpdateOperations);
    appState.isLoader.next(false);
    history.push(`/events/${eventId}/abstracts-reviewers`);
  };

  const renderCategoryCard = (cat) => (
    <AimCard
      cardVariant="grey"
      className={classes.card}
      style={getExtraStyle(cat.id)}
      onClick={() => handleCategorySelection(cat)}
    >
      <AimTypography
        style={{
          display: '-webkit-box',
          maxWidth: '200px',
          '-webkit-line-clamp': '4',
          '-webkit-box-orient': 'vertical',
          overflow: 'hidden',
        }}
        boxStyle={{ padding: 0 }}
      >
        {cat.name}
      </AimTypography>
    </AimCard>
  );

  const getExtraStyle = (categoryId) => {
    if (selectedCategories.find((selCat) => selCat.id === categoryId))
      return highlightedStyle(configuration);
    else if (
      prevSelectedCategories.find(
        (prevSelCat) => prevSelCat?.category?.id === categoryId
      )
    )
      return {
        ...highlightedStyle(configuration),
        opacity: 0.6,
        cursor: 'default',
      };
    else return {};
  };

  return (
    <>
      <div style={{ flex: 1, maxWidth: '80vw' }}>
        <Tooltip
          title={intl.formatMessage({
            description: 'back to webcast list',
            defaultMessage: 'Back to Homepage',
          })}
        >
          <AimIconAndTextButton
            isUpperCase
            variant="none"
            text={intl.formatMessage({
              description: 'homepage',
              defaultMessage: 'homepage',
            })}
            style={{
              padding: 0,
              marginLeft: 20,
            }}
            onClick={() =>
              history.replace(`/events/${eventId}/abstracts-reviewers`)
            }
          >
            <ArrowBack />
          </AimIconAndTextButton>
        </Tooltip>

        <div style={{ margin: '100px auto 20px' }}>
          {abstractService?.maxCategoriesPerReviewer > 0 ? (
            <>
              <AimTypography
                variant="h4Regular"
                style={{ fontWeight: 300, display: 'inline-flex' }}
              >
                {i18n.page.introTextPartOne}
              </AimTypography>
              {abstractService?.maxCategoriesPerReviewer || 0}
              <AimTypography
                variant="h4Regular"
                style={{ fontWeight: 300, display: 'inline-flex' }}
              >
                {i18n.page.introTextPartTwo}
              </AimTypography>
            </>
          ) : (
            <>
              <AimTypography
                variant="h4Regular"
                style={{ fontWeight: 300, display: 'inline-flex' }}
              >
                {i18n.page.textUnlimited}
              </AimTypography>
            </>
          )}
        </div>

        <Grid
          container
          style={{ maxHeight: 'calc(100vh - 260px)' }}
          spacing={2}
        >
          {abstractService?.categories?.items?.map(renderCategoryCard)}
        </Grid>

        <Grid
          container
          style={{ maxHeight: 'calc(100vh - 260px)' }}
          spacing={2}
          justify="flex-end"
        >
          <AimIconAndTextButton
            variant="greyFill"
            disabled={selectedCategories.length < 1}
            text={i18n.actions.forwardButton}
            style={{ marginTop: 50 }}
            onClick={goToAbstracsList}
          >
            <ArrowForwardIcon />
          </AimIconAndTextButton>
        </Grid>
      </div>
    </>
  );
};

export default PaxAbstractsCategoriesSelection;
