import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { translation } from './visa/index';
import { appState, constants, fileHelper } from '@aim/common';
import {
  AimIconAndTextButton,
  AimTypography,
  AimTextFieldForm,
  CustomIntl,
} from '@aim/components';
import { useForm } from 'react-hook-form';

import ArrowBack from '@material-ui/icons/ArrowBack';
import ReplayRoundedIcon from '@material-ui/icons/ReplayRounded';
import CheckIcon from '@material-ui/icons/Check';
import GetAppIcon from '@material-ui/icons/GetApp';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import {
  createFieldValue,
  getEventFields,
  getParticipationVisaFieldValues,
  getEventPapers,
  getParticipationFilledPapers,
} from './visa/visaGqlHelper';
import { callLibreOfficeLambda } from './visa/paperLambdaHelper';
import { useWelcomePageModulesRedirects } from '../hooks/useWelcomePageModulesRedirects';

const formControlStyle = { width: 'calc(100% - 15px)' };

const Visa = () => {
  const { getServiceRedirectLinkEvent } = useWelcomePageModulesRedirects();
  // Hooks
  const intl = CustomIntl(useIntl());
  const i18n = translation.visa(intl);
  const history = useHistory();
  const location = useLocation();
  const { eventId } = useParams();
  const showLoader = () => appState.isLoader.next(true);
  const hideLoader = () => appState.isLoader.next(false);
  const { getS3Link } = fileHelper;

  // React Hook Form
  const { handleSubmit, errors, control } = useForm();

  // States
  const [participation, setParticipation] = useState();
  const [fields, setFields] = useState();
  // const [basicFields, setBasicFields] = useState();
  const [isFormFilled, setIsFormFilled] = useState(true);
  const [isReadyForDownload, setIsReadyForDownload] = useState(false);
  const [paxTemplate, setPaxTemplate] = useState(false);
  const [timerSetted, setTimerSetted] = useState(false);

  const [paxPdfLink, setPaxPdfLink] = useState();
  const [paxTimeLeft, setPaxTimeLeft] = useState(0);

  // Effects
  useEffect(() => {
    const participationData = appState.user.getValue().userAndParticipation
      .participation;
    if (participationData) {
      setParticipation(participationData);
    }
  }, []);

  useEffect(() => {
    if (participation && participation.id) {
      fetchVisaFields();
    }
  }, [participation]);

  useEffect(() => {
    console.log('isReadyForDownload && paxTemplate && !timerSetted', {
      isReadyForDownload,
      paxTemplate,
      timerSetted,
    });
    if (isReadyForDownload && paxTemplate && !timerSetted) {
      handlePapers();
    }
  }, [isReadyForDownload, paxTemplate, timerSetted]);

  const handlePapers = async () => {
    const _papers = await getParticipationFilledPapers(participation.id);
    const foundPaxFilled = _papers.find(
      (p) =>
        p.type ===
        `${constants.PapersTypologies.PAX.paperType}|${constants.PapersTypologies.PAX.id}`
    );
    if (foundPaxFilled) {
      setTimerSetted(false);
      setPaxTimeLeft(0);
      setPaxPdfLink(
        await getS3Link(
          `events/${eventId}/papers/visa/filled/${participation.id}/pax`,
          {
            id: `${foundPaxFilled.file?.id}.pdf`,
            extension: '',
            originalName: `visa.pdf`,
          }
        )
      );
    } else {
      await callLibreOfficeLambda({
        paperId: paxTemplate,
        participations: [participation],
      });
      // setPaxTimeLeft(10);
    }
  };

  useEffect(() => {
    if (paxTimeLeft > 0) {
      setTimeout(() => setPaxTimeLeft((prev) => prev - 1), 1000);
    } else {
      setTimerSetted(false);
    }
  }, [paxTimeLeft]);

  const fetchVisaFields = async () => {
    showLoader();
    const eventFields = await getEventFields(eventId, false);
    const { customFields: _visaFields, paper } = eventFields;
    const _participationFields = await getParticipationVisaFieldValues(
      participation.id,
      false
    );
    const matchingFieldsArray = _participationFields.filter((f) =>
      _visaFields.find((vf) => vf.id === f.fieldDefinition.id)
    );
    console.log(
      'matchingFieldsArray',
      _visaFields,
      matchingFieldsArray,
      _visaFields
        .filter((field) => !['date', 'embassy'].includes(field.key))
        .every((field) =>
          matchingFieldsArray.find((m) => m?.fieldDefinition?.id === field.id)
        )
    );
    if (
      _visaFields
        .filter((field) => !['date', 'embassy'].includes(field.key))
        .every((field) =>
          matchingFieldsArray.find((m) => m?.fieldDefinition?.id === field.id)
        )
    ) {
      // all fields are compiled, proceed to download
      setIsReadyForDownload(true);
    } else {
      // fields missing, need to compile the form
      setIsFormFilled(false);
    }
    const templatesVisa = await getEventPapers(eventId);
    console.log('templatesVisa', templatesVisa);
    setPaxTemplate(
      (templatesVisa.length &&
        templatesVisa?.find((t) => t.type === 'visa|pax')?.id) ||
        false
    );
    if (_visaFields) setFields(_visaFields);
    hideLoader();
  };

  const onSave = async (submittedData) => {
    const fieldPromises = Object.entries(submittedData).map(
      ([fieldDefinition, value]) =>
        createFieldValue({
          participationFieldValuesId: participation.id,
          fieldValueFieldDefinitionId: fieldDefinition,
          value: JSON.stringify(value),
        })
    );
    try {
      await Promise.all(fieldPromises);
      setIsFormFilled(true);
      setIsReadyForDownload(true);
      setTimerSetted(true);
      setPaxTimeLeft(20);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDownload = async () => {
    try {
      window.open(paxPdfLink, '_blank');
      const link = getServiceRedirectLinkEvent('Visa');
      link && history.push(link);
    } catch (error) {
      console.error(error);
    }
  };

  console.log('paxPdfLink', paxPdfLink);

  if (!fields) return null;
  return (
    <>
      <div
        style={{ flex: 1, minWidth: 'calc(100vw - 120px)', marginBottom: 20 }}
      >
        <AimIconAndTextButton
          variant="none"
          text={i18n.backButton}
          style={{
            padding: 0,
          }}
          onClick={() =>
            location.state?.back
              ? history.push(location.state.back)
              : history.replace(`/events/${eventId}/landing`)
          }
        >
          <ArrowBack />
        </AimIconAndTextButton>{' '}
        {paxTemplate ? (
          <>
            <AimTypography variant={'h1'}>{i18n.title}</AimTypography>
            <div style={{ background: 'white', padding: 32 }}>
              {!isFormFilled ? (
                <form onSubmit={handleSubmit(onSave)}>
                  <Grid container>
                    <Grid item xs={12}>
                      <AimTypography variant={'h2'}>
                        {i18n.titleH2}
                      </AimTypography>
                      <AimTypography variant={'textBold'}>
                        {i18n.subTitle}
                      </AimTypography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider style={{ margin: '20px 0' }} />
                    </Grid>
                    {fields?.map((f) => {
                      if (f.key !== 'embassy' && f.key !== 'date') {
                        return (
                          <>
                            <Grid item xs={6} key={f.id}>
                              <AimTextFieldForm
                                control={control}
                                errors={errors}
                                name={f.id}
                                type={f.controlType}
                                label={f.label}
                                placeholder={f.placeholder}
                                formControlStyle={formControlStyle}
                                isRequired
                              />
                            </Grid>
                          </>
                        );
                      }
                    })}
                  </Grid>
                  <div
                    style={{
                      display: 'flex',
                      flex: 1,
                      justifyContent: 'flex-end',
                      marginTop: 30,
                    }}
                  >
                    <AimIconAndTextButton
                      variant="greyFill"
                      text={i18n.buttons.cancelButton}
                      onClick={() => history.push(`/events/${eventId}/landing`)}
                    >
                      <ReplayRoundedIcon />
                    </AimIconAndTextButton>
                    <AimIconAndTextButton
                      variant="greenFill"
                      text={i18n.buttons.saveButton}
                      type="submit"
                    >
                      <CheckIcon />
                    </AimIconAndTextButton>
                  </div>
                </form>
              ) : isReadyForDownload ? (
                <Grid container>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <AimTypography>{i18n.download.pax}</AimTypography>
                      <AimIconAndTextButton
                        variant="lightBlueFill"
                        text={`${i18n.buttons.downloadButton} ${
                          paxTimeLeft > 0 ? 'ready in ' + paxTimeLeft + 's' : ''
                        }`}
                        onClick={() => handleDownload()}
                        disabled={paxTimeLeft > 0 || !paxTemplate}
                      >
                        <GetAppIcon />
                      </AimIconAndTextButton>
                    </div>
                  </Grid>
                </Grid>
              ) : null}
            </div>
          </>
        ) : (
          <AimTypography>{'No visa template configured'}</AimTypography>
        )}
      </div>
    </>
  );
};

export default Visa;
