export const translation = {
  groupsList: (intl) => ({
    page: {
      statuses: {
        open: intl.formatMessage({
          description: 'open status',
          defaultMessage: 'Open',
        }),
        closed: intl.formatMessage({
          description: 'closes status',
          defaultMessage: 'Closed',
        }),
      },
    },
    header: {
      title: intl.formatMessage({
        description: 'my groups',
        defaultMessage: 'My groups',
      }),
      backButton: intl.formatMessage({
        description: 'Back button label',
        defaultMessage: 'back',
      }),
      action: {
        add: {
          tooltip: intl.formatMessage({
            description: 'add member tooltip',
            defaultMessage: 'Add',
          }),
        },
        goToFeeTable: {
          label: intl.formatMessage({
            description: 'go to fee table button',
            defaultMessage: 'GO TO FEE TABLE',
          }),
          tooltip: intl.formatMessage({
            description: 'go to fee table button',
            defaultMessage: 'go to fee table',
          }),
        },
        goToWelcomePage: {
          label: intl.formatMessage({
            description: 'go to welcome page button',
            defaultMessage: 'GO TO WELCOME PAGE',
          }),
          tooltip: intl.formatMessage({
            description: 'go to welcome page button',
            defaultMessage: 'go to welcome page',
          }),
        },
      },
    },
    // stats: {
    //   onboard: intl.formatMessage({
    //     description: 'onboard agencies',
    //     defaultMessage: 'N. agencies onboard',
    //   }),
    //   prepaidGroups: intl.formatMessage({
    //     description: 'number of prepaid groups',
    //     defaultMessage: 'N. prepaid groups',
    //   }),
    //   groups: intl.formatMessage({
    //     description: 'number on non prepaid groups',
    //     defaultMessage: 'N. non prepaid groups',
    //   }),
    //   deadline: intl.formatMessage({
    //     description: 'deadline date',
    //     defaultMessage: 'Groups publication date',
    //   }),
    // },
    table: {
      empty: intl.formatMessage({
        description: 'Table empty',
        defaultMessage: 'No data',
      }),
      columns: {
        name: intl.formatMessage({
          description: 'group name',
          defaultMessage: 'Name',
        }),
        agency: intl.formatMessage({
          description: 'agency name',
          defaultMessage: 'Agency',
        }),
        client: intl.formatMessage({
          description: 'client full name',
          defaultMessage: 'Client',
        }),
        typology: intl.formatMessage({
          description: 'group typology',
          defaultMessage: 'Typology',
        }),
        npax: intl.formatMessage({
          description: 'number of participations',
          defaultMessage: 'N. pax',
        }),
        status: intl.formatMessage({
          description: 'group status',
          defaultMessage: 'Status',
        }),
      },
      values: {
        typology: {
          prepaid: intl.formatMessage({
            description: 'prepaid group',
            defaultMessage: 'Prepaid',
          }),
          standard: intl.formatMessage({
            description: 'standard group',
            defaultMessage: 'Standard',
          }),
        },
        status: {
          open: intl.formatMessage({
            description: 'group open',
            defaultMessage: 'Open',
          }),
          closed: intl.formatMessage({
            description: 'closed group',
            defaultMessage: 'Closed',
          }),
        },
      },
      rowAction: {
        view: {
          tooltip: intl.formatMessage({
            description: 'view tooltip',
            defaultMessage: 'View',
          }),
        },
        edit: {
          tooltip: intl.formatMessage({
            description: 'edit tooltip',
            defaultMessage: 'Edit',
          }),
        },
        delete: {
          tooltip: intl.formatMessage({
            description: 'delete tooltip',
            defaultMessage: 'Delete',
          }),
        },
      },
    },
    dialog: {
      delete: {
        title: intl.formatMessage({
          description: 'Delete group title',
          defaultMessage: 'Delete Group',
        }),
        message: intl.formatMessage({
          description: 'Dialog delete message',
          defaultMessage: 'Are you sure?',
        }),
        agreeText: intl.formatMessage({
          description: 'Dialog delete onAgreeText',
          defaultMessage: 'Delete',
        }),
        disagreeText: intl.formatMessage({
          description: 'Dialog delete onDisagreeText',
          defaultMessage: 'Cancel',
        }),
      },
      form: {
        title: intl.formatMessage({
          description: 'new group title',
          defaultMessage: 'New group',
        }),
        name: intl.formatMessage({
          description: 'group name field',
          defaultMessage: 'Group name',
        }),
        client: intl.formatMessage({
          description: 'client field',
          defaultMessage: 'Client',
        }),
        save: intl.formatMessage({
          description: 'save button',
          defaultMessage: 'Save',
        }),
        cancel: intl.formatMessage({
          description: 'cancel button',
          defaultMessage: 'Cancel',
        }),
      },
    },
    snackbar: {
      delete: {
        success: intl.formatMessage({
          description: 'snackbar delete message',
          defaultMessage: 'Delete success',
        }),
        error: intl.formatMessage({
          description: 'snackbar delete error message',
          defaultMessage: 'Delete error',
        }),
      },
      success: intl.formatMessage({
        description: 'snackbar success',
        defaultMessage: 'Success',
      }),
      error: intl.formatMessage({
        description: 'snackbar error',
        defaultMessage: 'Error',
      }),
    },
  }),
};
