import React, { useEffect, useState } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';

// import useScrollTrigger from '@material-ui/core/useScrollTrigger';

import { appState } from '@aim/common';
import { theme, useAimMediaQuery } from '@aim/components';

import Header from '../header/Header';
import { LayoutContainer } from './style';
import Footer from '../Footer';

const useStyles = makeStyles(() => ({
  toolbar: {
    minHeight: 80,
    backgroundColor: theme.colors.primary.white,
    paddingLeft: (props) =>
      props.isMobileOnly ? 5 : props.isMobile ? 20 : 100,
    paddingRight: (props) =>
      props.isMobileOnly ? 0 : props.isMobile ? 20 : 100,
  },
}));

const Layout = (props) => {
  const [eventConfiguration, setEventConfiguration] = useState({});
  const { isMobile, isMobileOnly } = useAimMediaQuery();

  const classes = useStyles({ isMobile, isMobileOnly });

  useEffect(() => {
    const subscription = appState.eventConfiguration.subscribe(
      setEventConfiguration
    );
    return () => subscription.unsubscribe();
  }, []);
  return (
    <LayoutContainer
      style={{
        background: props.children.props.customBackgroundColour
          ? props.children.props.customBackgroundColour
          : eventConfiguration.background,
      }}
    >
      {/* <div style={{ background: 'white' }}>
        <Header />
      </div> */}
      <CssBaseline />
      <AppBar elevation={4}>
        <Toolbar className={classes.toolbar}>
          <Header />
          {/* <AimIconButton variant="text" fontSize="large">
            <MenuIcon />
          </AimIconButton> */}
        </Toolbar>
      </AppBar>

      <Toolbar className={classes.toolbar} />
      {/* <SubMenu /> */}
      <div style={{ flex: 1 }}>{props.children}</div>
      <Footer />
    </LayoutContainer>
  );
};

export default Layout;
