export const translation = {
  grant: (intl) => ({
    abstractTitle: intl.formatMessage({
      description: 'Grant front title',
      defaultMessage: 'Abstract Grants',
    }),
    educationalTitle: intl.formatMessage({
      description: 'Grant front title',
      defaultMessage: 'Educational Grants',
    }),
    title: intl.formatMessage({
      description: 'title',
      defaultMessage: 'Title',
    }),
    instructions: intl.formatMessage({
      description: 'Instructions',
      defaultMessage: 'Instructions',
    }),
    subTitle: intl.formatMessage({
      description: 'fill Grant subtitle',
      defaultMessage: 'Fill in the form to subscribe grant',
    }),
    backButton: intl.formatMessage({
      description: 'Grant front back button label',
      defaultMessage: 'back',
    }),
    abstract: {
      label: intl.formatMessage({
        description: 'abstract label',
        defaultMessage: 'Abstract',
      }),
      placeholder: intl.formatMessage({
        description: 'select abstract label',
        defaultMessage: 'select abstract',
      }),
    },
    noAbstracts: intl.formatMessage({
      description: 'no abstracts label',
      defaultMessage: 'no abstracts',
    }),
    buttons: {
      cancelButton: intl.formatMessage({
        description: 'cancel button',
        defaultMessage: 'Cancel',
      }),
      saveButton: intl.formatMessage({
        description: 'save button',
        defaultMessage: 'save and continue',
      }),
      downloadButton: intl.formatMessage({
        description: 'download button',
        defaultMessage: 'download',
      }),
    },
  }),
  subscribeSuccess: (intl) => ({
    goToPersonalArea: intl.formatMessage({
      description: 'ticket purchase success go to personal area button',
      defaultMessage: 'Go to personal area',
    }),
    goToServices: intl.formatMessage({
      description: 'ticket purchase success go to services button',
      defaultMessage: 'Go to services',
    }),
    message: intl.formatMessage({
      description: 'subscribe success message',
      defaultMessage:
        'Wait for your bonus, you can still customize your experience for the event by purchasing the other service',
    }),
    subscribeStatusMessage: intl.formatMessage({
      description: 'subscribe status message',
      defaultMessage: 'To see the status of your request go to My services.',
    }),
  }),
};
