export const translation = {
  fee: (intl) => ({
    page: {
      back: {
        tooltip: intl.formatMessage({
          description: 'back button tooltip',
          defaultMessage: 'Go back',
        }),
        button: intl.formatMessage({
          description: 'back button tooltip',
          defaultMessage: 'back',
        }),
      },
      title: intl.formatMessage({
        description: 'page title',
        defaultMessage: 'Groups',
      }),
      info: {
        currency: {
          label: intl.formatMessage({
            description: 'currency info label',
            defaultMessage: 'Currency',
          }),
        },
        cluster: {
          label: intl.formatMessage({
            description: 'cluster info label',
            defaultMessage: 'Cluster',
          }),
        },
        name: {
          label: intl.formatMessage({
            description: 'profile name info label',
            defaultMessage: 'Profile name',
          }),
        },
        category: {
          label: intl.formatMessage({
            description: 'category info label',
            defaultMessage: 'Category',
          }),
        },
        participantQuoteInfo: {
          label: intl.formatMessage({
            description: 'participant quote info info label',
            defaultMessage:
              'Enter the value of the fee to be displayed to the participants',
          }),
        },
        copyFrom: {
          label: intl.formatMessage({
            description: 'copy from info info label',
            defaultMessage: 'Copy From',
          }),
        },
        applyToAll: {
          label: intl.formatMessage({
            description: 'apply to all profiles info info label',
            defaultMessage: 'Apply to all profiles',
          }),
        },
      },
    },
    form: {
      from: {
        label: intl.formatMessage({
          description: 'form from field',
          defaultMessage: 'From',
        }),
      },
      to: {
        label: intl.formatMessage({
          description: 'form to field',
          defaultMessage: 'To',
        }),
      },
      virtualPrice: {
        label: intl.formatMessage({
          description: 'form virtual price field',
          defaultMessage: 'Virtual Price',
        }),
      },
      physicalPrice: {
        label: intl.formatMessage({
          description: 'form physical price field',
          defaultMessage: 'Physical Price',
        }),
      },
      accompanyingPersonPrice: {
        label: intl.formatMessage({
          description: 'form accompanying person price field',
          defaultMessage: 'Accompanying Person Price',
        }),
      },
    },
  }),
};
