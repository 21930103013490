import React, { useEffect, useState } from 'react';
import { aws, appState, fileHelper } from '@aim/common';
import { isMobile } from 'react-device-detect';

import { PublicLandingPageTemplate, CustomIntl } from '@aim/components';
import { useHistory, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import translation from './publicLandingPage/translation';

const PublicLandingPage = () => {
  const history = useHistory();
  const { eventId } = useParams();
  const { getAwsS3Link } = fileHelper;
  const [eventConfiguration, setEventConfiguration] = useState({});
  const [coverImage, setCoverImage] = useState(null);
  const [description, setDescription] = useState();
  const intl = CustomIntl(useIntl());
  const i18n = translation.PublicLandingPage(intl);

  useEffect(() => {
    const subscription = appState.eventConfiguration.subscribe(
      setEventConfiguration
    );
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    const getImages = () => {
      if (isMobile && eventConfiguration?.publicLandingPageMobileCoverImage) {
        const link = getAwsS3Link(
          `events/${eventId}/configuration/publicLandingPageSettings/`,
          eventConfiguration.publicLandingPageMobileCoverImage
        );
        const convertedLink = aws.s3.getS3ObjectUrl(link);

        setCoverImage(convertedLink);
      } else if (eventConfiguration?.publicLandingPageCoverImage) {
        const link = getAwsS3Link(
          `events/${eventId}/configuration/publicLandingPageSettings/`,
          eventConfiguration.publicLandingPageCoverImage
        );
        const convertedLink = aws.s3.getS3ObjectUrl(link);

        setCoverImage(convertedLink);
      }
    };

    getImages();
    if (eventConfiguration?.publicLandingPageDescription) {
      const text = JSON.parse(eventConfiguration.publicLandingPageDescription);

      setDescription(text);
    }
  }, [eventConfiguration]);

  return (
    <PublicLandingPageTemplate
      history={history}
      model={{
        title: eventConfiguration?.publicLandingPageTitle,
        titleColor: eventConfiguration?.publicLandingPageTitleColor,
        description,
        descriptionColor: eventConfiguration?.publicLandingPageDescriptionColor,
        coverImage,
        registerText: i18n.page.register,
        surveyText: i18n.page.survey,
        registerLink: `/events/${eventId}/signup`,
        loginText: i18n.page.login,
        loginLink: `/events/${eventId}/login`,
        loginButtonLabel: eventConfiguration.publicLandingPageLoginButtonLabel,
        registrationButtonLabel:
          eventConfiguration.publicLandingPageButtonLabel,
        registrationActivationDate:
          eventConfiguration.publicLandingPageActivationDate,
        registrationDeactivationDate:
          eventConfiguration.publicLandingPageDeactivationDate,
        surveyButtonLabel:
          eventConfiguration.publicLandingPageSurveyButtonLabel,
        surveyActivationDate:
          eventConfiguration.publicLandingPageSurveyActivationDate,
        surveyDeactivationDate:
          eventConfiguration.publicLandingPageSurveyDeactivationDate,
        publicLandingPageSurveyExist:
          eventConfiguration.publicLandingPageSurvey,
        surveyLink: `/events/${eventId}/survey`,
      }}
    />
  );
};

export default PublicLandingPage;
