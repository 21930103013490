import React from 'react';

import Grid from '@material-ui/core/Grid';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';

import { AimTypography, AimIconButton, theme, styled } from '@aim/components';

const Row = styled('div')({
  width: '100%',
  display: 'flex',
  padding: '5px 0px 5px 10px',
  justifyContent: 'flex-start',
  '&:hover': {
    backgroundColor: theme.colors.greyScale.backgroundGrey,
  },
});

const safeNum = (value) => (isNaN(Number(value)) ? 0 : Number(value));

const SponsorBannerAreas = ({ area, vat, onClickAddToCart, paymentType }) => {
  const disablePurchase = (area) =>
    !paymentType ||
    (area?.isExclusive &&
      area?.buyOperations?.items.find((i) => i.sponsor?.id !== null));

  return (
    <Grid
      item
      xs={12}
      container
      style={{
        background: theme.colors.greyScale.white,
        paddingTop: 5,
        paddingBottom: 5,
        alignItems: 'center',
      }}
    >
      <Row>
        <Grid item xs={4}>
          <AimTypography variant="text">{area?.name}</AimTypography>
        </Grid>
        <Grid item xs={2}>
          <AimTypography variant="text">
            {safeNum(area?.price / 100).toFixed(2)} €
          </AimTypography>
        </Grid>
        <Grid item xs={2}>
          <AimTypography variant="text">
            {safeNum(vat / 100).toFixed(2)} %
          </AimTypography>
        </Grid>
        <Grid item xs={2}>
          <AimTypography variant="text">
            {(
              safeNum(area.price / 100) +
              (safeNum(area.price / 100) * safeNum(vat / 100)) / 100
            ).toFixed(2)}{' '}
            €
          </AimTypography>
        </Grid>

        <Grid
          item
          xs={2}
          style={{
            // background: theme.colors.greyScale.white,
            paddingTop: 5,
            paddingRight: 16,
            paddingBottom: 5,
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <AimIconButton
            variant="yellowFill"
            small
            onClick={() => onClickAddToCart(area, true)}
            disabled={disablePurchase(area)}
          >
            <SwapHorizIcon />
          </AimIconButton>
          <AimIconButton
            variant="violetFill"
            small
            onClick={() => onClickAddToCart(area)}
            disabled={disablePurchase(area)}
          >
            <ShoppingCartIcon />
          </AimIconButton>
        </Grid>
      </Row>
    </Grid>
  );
};

export default SponsorBannerAreas;
