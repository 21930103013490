import React, { useState, useEffect } from 'react';
import { aws, appState, fileHelper } from '@aim/common';
import { useParams, useHistory } from 'react-router-dom';
import { LogoContainer } from './style';
import logoAim from './logo-aim-international.png';
import { isMobileOnly } from 'react-device-detect';

const Logo = () => {
  const history = useHistory();
  const [eventConfiguration, setEventConfiguration] = useState({});
  const [logo, setLogo] = useState(null);
  const { getAwsS3Link } = fileHelper;
  const { eventId } = useParams();
  useEffect(() => {
    const subscription = appState.eventConfiguration.subscribe(
      setEventConfiguration
    );
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    const getImages = async () => {
      if (eventConfiguration?.logo) {
        const link = await getAwsS3Link(
          `events/${eventId}/configuration/generalInfo/registration/logo/`,
          eventConfiguration.logo
        );
        const convertedLink = aws.s3.getS3ObjectUrl(link);

        setLogo(convertedLink);
      }
    };

    getImages();
  }, [eventConfiguration]);

  return (
    <LogoContainer
      style={{
        cursor: eventConfiguration?.homepageItemsOrder?.length
          ? 'pointer'
          : 'auto',
      }}
      onClick={() => {
        eventConfiguration?.homepageItemsOrder?.length &&
          history.push(`/events/${eventId}/homepage`);
        {
          /* history.push(
          appState.user.getValue()?.userAndParticipation?.participation
            ?.hasTicket
            ? `/events/${eventId}/homepage`
            : `/events/${eventId}/landing`
        ); */
        }
      }}
    >
      {!logo && (
        <img
          src={logoAim}
          alt="logo"
          width={isMobileOnly ? '65' : '94'}
          height={isMobileOnly ? '47' : '67'}
          style={{ objectFit: 'cover' }}
        />
      )}
      {logo && (
        <img
          width="auto"
          height={isMobileOnly ? '47' : '67'}
          src={logo}
          style={{
            backgroundSize: 'cover',
            objectFit: 'cover',
            // backgroundImage: `url("${logo}")`,
          }}
        />
      )}
    </LogoContainer>
  );
};

Logo.propTypes = {};

export default Logo;
