import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import Grid from '@material-ui/core/Grid';
import FileDownloadIcon from '@material-ui/icons/GetApp';

import {
  StepDivider,
  Title,
  AimTypography,
  AimIconAndTextButton,
  AimSnackbar,
  AimFileSelector,
  theme,
  // styled,
} from '@aim/components';
import { fileHelper } from '@aim/common';

// const CustomFileSelector = styled(AimFileSelector)({
//   backgroundColor: 'red',
//   color: 'red',
//   '& .container': {
//     alignItems: 'flex-end',
//     backgroundColor: 'red',
//     color: 'red',
//   },
//   '& div': {
//     alignItems: 'flex-end',
//     backgroundColor: 'red',
//   },
// });

const formControlStyle = { width: 'calc(100% - 10px)' };
const dragDropStyleOverride = (variant) => ({
  background:
    variant === 'white' ? 'white' : theme.colors.greyScale.backgroundGrey,
});

const AifaData = ({
  control,
  title,
  i18n,
  setValue,
  abstractService,
  abstractId,
  s3Folder,
  // watch,
  errors,
  variant = 'grey',
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  // const [fileToRemove, setFileToRemove] = useState({});

  const getAIFADocumentDirPath = () => {
    return `${s3Folder}/abstracts/${abstractId}/`;
  };
  const getAIFATemplateDirPath = () => {
    return `${s3Folder}/abstract-service/${abstractService.id}/aifa/`;
  };

  return (
    <>
      <Title>{title}</Title>
      <StepDivider />
      <Grid container>
        <Grid item xs={12}>
          <AimTypography style={{ paddingBottom: 10 }}>
            {i18n.wizard.labels.aifaWarningText}
          </AimTypography>
          <AimTypography>{i18n.wizard.labels.aifaCVText}</AimTypography>
          <AimTypography style={{ paddingBottom: 10 }}>
            {i18n.wizard.labels.aifaDisclosureText}
          </AimTypography>
          <AimTypography>{i18n.wizard.labels.aifaBottomText}</AimTypography>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={'aifaEuropeanCv'}
            control={control}
            errors={errors}
            render={(props) => (
              <AimFileSelector
                variant={variant}
                label={`${i18n.wizard.labels.uploadCV} *`}
                selectFileLabel={i18n.wizard.labels.browseLabel}
                onUploadFile={(res) => {
                  setValue(`aifaEuropeanCvFile`, res.file);
                  props.onChange(res.parsed);
                }}
                onRemove={(file) => {
                  // if (file.id) {
                  //   setFileToRemove({
                  //     ...fileToRemove,
                  //     file,
                  //   });
                  // }
                  setValue(`aifaEuropeanCv`, null);
                  props.onChange(null);
                }}
                onDownload={(fileData) => {
                  {
                    fileHelper.downloadFromS3({
                      dirPath: getAIFADocumentDirPath(),
                      fileData,
                    });
                  }
                }}
                file={props.value}
                maxFiles={1}
                dropzoneAdditionalProps={{ accept: '.pdf' }}
                dragDropStyleOverride={dragDropStyleOverride(variant)}
                formControlStyle={formControlStyle}
              />
            )}
          />
          {errors && (
            <ErrorMessage
              errors={errors}
              name={`aifaEuropeanCv`}
              render={() => (
                <AimTypography variant="formError">
                  {i18n.wizard.errors.labels.aifaEuropeanCv}
                </AimTypography>
              )}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={`aifaDisclosure`}
            control={control}
            errors={errors}
            render={(props) => (
              <AimFileSelector
                variant={variant}
                label={`${i18n.wizard.labels.uploadDisclosure} *`}
                selectFileLabel={i18n.wizard.labels.browseLabel}
                onUploadFile={(res) => {
                  setValue(`aifaDisclosureFile`, res.file);
                  props.onChange(res.parsed);
                }}
                onRemove={(file) => {
                  // if (file.id) {
                  //   setFileToRemove({
                  //     ...fileToRemove,
                  //     file,
                  //   });
                  // }
                  setValue(`aifaDisclosure`, null);
                  props.onChange(null);
                }}
                onDownload={(fileData) => {
                  {
                    fileHelper.downloadFromS3({
                      dirPath: getAIFADocumentDirPath(),
                      fileData,
                    });
                  }
                }}
                file={props.value}
                maxFiles={1}
                dropzoneAdditionalProps={{ accept: '.pdf' }}
                dragDropStyleOverride={dragDropStyleOverride}
                formControlStyle={formControlStyle}
                // addAsterisk={true}
              />
            )}
          />
          {errors && (
            <ErrorMessage
              errors={errors}
              name={`aifaDisclosure`}
              render={() => (
                <AimTypography variant="formError">
                  {i18n.wizard.errors.labels.aifaDisclosure}
                </AimTypography>
              )}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <AimTypography style={{ paddingBottom: 10 }}>
            {i18n.wizard.labels.uploadFilesTypologies}
          </AimTypography>
        </Grid>
        <Grid item xs={12}>
          {abstractService.aifaEuropeanCvTemplate ? (
            <AimIconAndTextButton
              variant="secondary"
              text={i18n.wizard.labels.downloadCV}
              onClick={() => {
                fileHelper.downloadFromS3({
                  dirPath: getAIFATemplateDirPath(),
                  fileData: abstractService.aifaEuropeanCvTemplate,
                });
              }}
            >
              <FileDownloadIcon />
            </AimIconAndTextButton>
          ) : null}
          {abstractService.aifaDisclosureTemplate ? (
            <AimIconAndTextButton
              variant="secondary"
              text={i18n.wizard.labels.downloadDisclosure}
              onClick={() => {
                fileHelper.downloadFromS3({
                  dirPath: getAIFATemplateDirPath(),
                  fileData: abstractService.aifaDisclosureTemplate,
                });
              }}
            >
              <FileDownloadIcon />
            </AimIconAndTextButton>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <AimTypography style={{ paddingBottom: 10 }}>
            {i18n.wizard.labels.downloadDocumentsNote}
          </AimTypography>
        </Grid>
      </Grid>

      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </>
  );
};

export default AifaData;
