import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import Tooltip from '@material-ui/core/Tooltip';
import ArrowBack from '@material-ui/icons/ArrowBack';

import { aws, appState } from '@aim/common';
import {
  WebcastPreview,
  AimIconAndTextButton,
  CustomIntl,
} from '@aim/components';
import { getWebcast, updateWebcastView } from './webcastCardGrid/gqlHelper';

const WebcastCardDetail = () => {
  const [webcast, setWebcast] = useState({});
  const [imageUrl, setImageUrl] = useState('');
  const [user] = useState(appState.user.getValue());
  const history = useHistory();
  const intl = CustomIntl(useIntl());
  const { eventId, webcastId } = useParams();

  const incrementViews = async () => {
    await updateWebcastView(webcastId, false);
  };

  useEffect(() => {
    const loadWebcast = async () => {
      appState.isLoader.next(true);

      try {
        const nextWebcast = await getWebcast(webcastId, false);
        let newItems = nextWebcast.webcastSpeeches.items.map((item) => ({
          ...item,
          participation: {
            ...item.participation,
            user: {
              familyName: item.participation.familyName,
              givenName: item.participation.givenName,
            },
          },
        }));
        let remappedWebcast = {
          ...nextWebcast,
          webcastSpeeches: {
            items: newItems,
          },
        };
        aws.Storage.get(
          'events/' +
            eventId +
            '/webcasts/' +
            nextWebcast.id +
            '/' +
            nextWebcast.image
        ).then((url) => {
          setWebcast(remappedWebcast);
          setImageUrl(url);
        });

        // analytics
        window.dataLayer.push({
          event: 'on-demand',
          webcast_id: nextWebcast?.id,
          webcast_name: nextWebcast?.title,
          event_id: eventId,
          event_name: appState.eventInfo.getValue()?.name,
          username: user?.userAndParticipation?.participation?.username,
          name: user?.userAndParticipation?.participation?.givenName,
          surname: user?.userAndParticipation?.participation?.familyName,
          email: user?.userAndParticipation?.participation?.email,
        });

        appState.isLoader.next(false);
      } catch (err) {
        console.error(err);
        appState.isLoader.next(false);
      }
    };

    loadWebcast();
  }, []);

  return (
    <>
      {' '}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flex: 1,
          width: '1310px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',

            // paddingLeft: 200,
            // paddingRight: 200,
          }}
        >
          <Tooltip
            title={intl.formatMessage({
              description: 'back to webcast list',
              defaultMessage: 'Back to Webcast list',
            })}
          >
            <AimIconAndTextButton
              isUpperCase
              variant="none"
              text={intl.formatMessage({
                description: 'webcasts label',
                defaultMessage: 'webcasts',
              })}
              style={{
                padding: 0,
                marginLeft: 20,
              }}
              onClick={() => history.replace(`/events/${eventId}/webcast-list`)}
            >
              <ArrowBack />
            </AimIconAndTextButton>
          </Tooltip>
          {webcast && Object.keys(webcast).length > 0 && (
            <WebcastPreview
              onVideoClick={incrementViews}
              intl={intl}
              webcast={webcast}
              imageUrl={imageUrl}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default WebcastCardDetail;
