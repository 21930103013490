import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  cardRoot: (props) => ({
    // width: 'Calc(100% - 10)',
    width: '100%',
    height: '100px',
    borderRadius: 1,
    boxShadow: '0 0 black',
    display: 'flex',
    cursor: 'pointer',
    boxSizing: 'border-box',
    padding: '0 20px!important',
    border: props.isSelected ? 'solid 2px yellow' : 'none',
  }),
  cardContentRoot: {
    flex: 1,
    padding: '0!important',
    display: 'inline-block',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'space-between',
  },
  cardContentRootText: {
    flex: 1,
    padding: '0!important',
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    color: 'lightBlue',
  },
}));

const AnalyticsCard = (props) => {
  const propsStyle = { isSelected: props.isSelected };
  const classes = useStyles(propsStyle);

  const editClickHandler = () => {
    if (props.onClick) props.onClick();
  };

  const viewDetailClickHandler = (e) => {
    e.stopPropagation();
    if (props.onViewDetail) props.onViewDetail();
  };

  return (
    <Grid item xs={12} sm={2} md={2} onClick={editClickHandler}>
      <Card className={classes.cardRoot}>
        <CardContent classes={{ root: classes.cardContentRoot }}>
          <div classes={{ root: classes.cardContentRootText }}>
            <Typography
              style={{
                fontWeight: 500,
                fontSize: '0.850rem',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {props.title}
            </Typography>
            <Typography
              style={{
                fontWeight: 400,
                fontSize: '1.400rem',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {props.subtitle}
            </Typography>
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default AnalyticsCard;
