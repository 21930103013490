import { aws, appState, utilities } from '@aim/common';

const { API } = aws;
const {
  decodeDbNumber,
  // encodeDbNumber
} = utilities;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const getHotelByHotelIdQuery = /* GraphQL */ `
  query getHotelByHotelEventVentureId($HotelEventVentureId: ID!) {
    hotelEventVenture: getHotelEventVenture(id: $HotelEventVentureId) {
      hotel {
        id
        name
      }
      rooms {
        items {
          frontofficeName
          maxOccupancy
          roomByDate {
            items {
              date
              roomAvailabilities {
                items {
                  channelType
                  roomsBusy
                  id
                }
              }
              roomsPrice {
                items {
                  channelType
                  occupancy
                  price
                }
              }
            }
          }
        }
      }
      hotelRoomByDate {
        items {
          date
          roomAvailabilities(filter: { channelType: { eq: "web" } }) {
            items {
              channelType
              roomsBusy
              hotelRoomPrices {
                items {
                  occupancy
                  price
                  channelType
                }
              }
              id
            }
          }
          room {
            frontofficeName
            maxOccupancy
            id
          }
        }
      }
      event {
        allotmentVat
        allotmentEventVat {
          id
        }
      }
    }
  }
`;

const getParticipantsForTheEventQuery = /* GraphQL */ `
  query ParticipantByEventId($participationEventId: ID) {
    participantByEventId(participationEventId: $participationEventId) {
      items {
        id
        familyName
        givenName
        isDeleted
        email
        phone
      }
    }
  }
`;

export const getParticipantsForTheEvent = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: getParticipantsForTheEventQuery,
      variables: input,
    })
      .then((response) => {
        resolve(response.data?.participantByEventId?.items);
      })
      .catch((e) => {
        console.error('get-hotel-participants', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const getHotelEventVentureInformations = (
  state,
  hotelEventVentureId,
  language,
  useLoader = true
) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    const HotelEventVentureId = hotelEventVentureId;

    API.graphql({
      query: getHotelByHotelIdQuery,
      variables: { HotelEventVentureId, language },
    })
      .then((response) => {
        const eventVenture = response?.data?.hotelEventVenture;
        const parsedVat = decodeDbNumber(eventVenture.event.allotmentVat);
        eventVenture.event.allotmentVat = parsedVat;
        // calculating total net price, iva, total price
        const totalNetPrice = state.rooms.reduce(
          (totalValue, currValue) => totalValue + currValue.roomPrice,
          0
        );
        const ivaPrice = (totalNetPrice * parsedVat) / 100;
        const totalPrice = totalNetPrice + ivaPrice;
        const priceInformations = {
          totalNetPrice,
          ivaPrice,
          totalPrice,
        };
        resolve({
          ...eventVenture,
          priceInformations,
        });
      })
      .catch((e) => {
        console.error('get hotel details', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });
