import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';

import { utilities } from '@aim/common';
import {
  AimTableRow,
  AimTableCell,
  AimTableNoPaging,
  AimIconButton,
  CustomIntl,
  AimDialog,
} from '@aim/components';

import { translation } from './translation';
import AddToCartDialogContent from './AddToCartDialogContent';
const { formatNumber } = utilities;
const SymposiumServicesList = ({ symposiumServices, onAddToCart }) => {
  const intl = CustomIntl(useIntl());
  const i18n = translation.symposiumServices(intl);
  const [addToCartDialog, setAddToCartDialog] = useState({ isOpen: false });

  const isPlusDisabled =
    addToCartDialog?.item?.availability &&
    addToCartDialog?.quantity > addToCartDialog?.item?.bookableQuantity;

  // Columns
  const headCells = [
    {
      id: 'code',
      numeric: false,
      disablePadding: false,
      label: i18n.table.columns.code,
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: i18n.table.columns.name,
    },
    {
      id: 'netPrice',
      numeric: false,
      disablePadding: false,
      label: i18n.table.columns.netPrice,
    },
    {
      id: 'vatRate',
      numeric: false,
      disablePadding: false,
      label: i18n.table.columns.vatRate,
    },
    {
      id: 'taxedPrice',
      numeric: false,
      disablePadding: false,
      label: i18n.table.columns.taxedPrice,
    },
    {
      id: 'availability',
      numeric: false,
      disablePadding: false,
      label: i18n.table.columns.availability,
    },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: '',
    },
  ];

  //Functions
  const Row = ({ row }) => {
    const { purchaseDisabled } = row;

    const quantityLabel = !row.availability
      ? i18n.table.unlimitedQuantity
      : row.bookableQuantity <= 0
      ? i18n.table.soldOutQuantity
      : row.bookableQuantity;

    return (
      <>
        <AimTableRow hover>
          <AimTableCell>{row?.code}</AimTableCell>
          <AimTableCell>{row?.name}</AimTableCell>
          <AimTableCell>{formatNumber(row?.netPrice)}</AimTableCell>
          <AimTableCell>{`${row.vatRate} %`}</AimTableCell>
          <AimTableCell>{formatNumber(row?.taxedPrice)}</AimTableCell>
          <AimTableCell>{quantityLabel}</AimTableCell>
          <AimTableCell>
            <AimIconButton
              variant="primary"
              small
              onClick={() => {
                //onAddToCart(row[rowKey], true)}
                setAddToCartDialog({
                  isOpen: true,
                  item: row,
                  quantity: 1,
                  purchaseNow: true,
                });
              }}
              disabled={purchaseDisabled}
            >
              <SwapHorizIcon />
            </AimIconButton>
            <AimIconButton
              variant="primary"
              small
              onClick={() =>
                setAddToCartDialog({
                  isOpen: true,
                  item: row,
                  quantity: 1,
                })
              }
              disabled={purchaseDisabled}
            >
              <ShoppingCartIcon />
            </AimIconButton>
          </AimTableCell>
        </AimTableRow>
      </>
    );
  };

  return (
    <>
      <AimTableNoPaging
        title={i18n.table.title}
        headCells={headCells}
        rows={symposiumServices}
        notCheckable={true}
        rowKey="id"
      >
        <Row />
      </AimTableNoPaging>
      <AimDialog
        open={addToCartDialog.isOpen}
        title={addToCartDialog?.item?.name || i18n.dialog.title}
        agreeText={i18n.dialog.add}
        disagreeText={i18n.dialog.close}
        onClose={() => setAddToCartDialog({ isOpen: false })}
        maxWidth={'xl'}
        onAgree={() => {
          onAddToCart(
            addToCartDialog.item.id,
            addToCartDialog.quantity,
            addToCartDialog.purchaseNow
          );
        }}
        onAgreeDisabled={isPlusDisabled}
      >
        <AddToCartDialogContent
          i18n={i18n}
          addToCartDialog={addToCartDialog}
          setAddToCartDialog={setAddToCartDialog}
          isPlusDisabled={isPlusDisabled}
        />
      </AimDialog>
    </>
  );
};

export default SymposiumServicesList;
