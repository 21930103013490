import { styled, theme } from '@aim/components';

const flexVCenterStyles = { display: 'flex', alignItems: 'center' };

export const DocumenstListWrapper = styled('div')({
  width: '100%',
});

export const DocumenstListContainer = styled('div')({
  backgroundColor: '#FFF',
  padding: '10px 20px 20px',
  marginTop: 16,
});

export const DocumenstListHeader = styled('div')({
  ...flexVCenterStyles,
  fontWeight: 'bold',
  padding: '10px 20px',
});

export const DocRow = styled('div')({
  ...flexVCenterStyles,
  backgroundColor: theme.colors.greyScale.backgroundGrey,
  padding: '10px 20px',
  marginBottom: 8,
});

export const DocCol = styled('div')({
  padding: '0 10px',
});

export const IconCol = styled(DocCol)({
  ...flexVCenterStyles,
  width: 40,
  height: 40,

  '&:first-child': {
    paddingLeft: 0,
  },

  '&:last-child': {
    paddingRight: 0,
  },
});

export const ActionsCol = styled(DocCol)({
  ...flexVCenterStyles,
  marginLeft: 'auto',
  paddingRight: 0,
});
