import React from 'react';

import { useParams } from 'react-router-dom';

import Symposium from './Symposium';
import SponsorBanner from './SponsorBanner';
import PublicPage from './PublicPage';
import BreakoutRoom from './BreakoutRoom';
import PhysicalStand from './PhysicalStand';
import VirtualStand from './VirtualStand';
import SponsorStaff from './SponsorStaff';
import SponsorList from './SponsorList';
import SymposiumServices from './SymposiumServices';
import OtherSponsorizations from './OtherSponsorizations';
import { PhysicalStandSpaces } from '../sponsorServiceConfigurationWall/services/PhysicalStandSpaces';

const WallDisabiguation = () => {
  const { service } = useParams();

  const renderComponent = () => {
    switch (service) {
      case 'sponsor-banner':
        return <SponsorBanner />;
      case 'breakout-room':
        return <BreakoutRoom />;
      case 'symposium':
        return <Symposium />;
      case 'public-page':
        return <PublicPage />;
      case 'physical-stand':
        return <PhysicalStand />;
      case 'other-services':
        return <PhysicalStandSpaces />;
      case 'virtual-stand':
        return <VirtualStand />;
      case 'sponsor-staff':
        return <SponsorStaff />;
      case 'sponsor-list':
        return <SponsorList />;
      case 'symposium-services':
        return <SymposiumServices />;
      case 'other-sponsorizations':
        return <OtherSponsorizations />;

      default:
        break;
    }
  };

  return renderComponent();
};

export default WallDisabiguation;
