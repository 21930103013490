export default function (intl) {
  return {
    page: {
      backButton: intl.formatMessage({
        description: 'back to social events list',
        defaultMessage: 'Back',
      }),
      buyTicket: intl.formatMessage({
        description: 'buy ticket',
        defaultMessage: 'Buy ticket',
      }),
      minSubscription: intl.formatMessage({
        description: 'Minimum participants',
        defaultMessage: 'Minimum participants',
      }),
      maxSubscription: intl.formatMessage({
        description: 'Maximum participants',
        defaultMessage: 'Maximum participants',
      }),
      availableSubscription: intl.formatMessage({
        description: 'Available participants',
        defaultMessage: 'Available participants',
      }),
      prices: intl.formatMessage({
        description: 'Prices',
        defaultMessage: 'prices',
      }),
      cost: intl.formatMessage({
        description: 'Cost',
        defaultMessage: 'Cost (VAT excluded)',
      }),
      vat: intl.formatMessage({
        description: 'VAT',
        defaultMessage: 'VAT',
      }),
      total: intl.formatMessage({
        description: 'Total',
        defaultMessage: 'Total (VAT Included)',
      }),
      confirmationPolicy: intl.formatMessage({
        description: 'Confirmation policy',
        defaultMessage: 'confirmation policy',
      }),
      servicesIncluded: intl.formatMessage({
        description: 'Services included',
        defaultMessage: 'services included',
      }),
    },
    user: {
      invoiceDetails: intl.formatMessage({
        description: 'invoice details',
        defaultMessage: 'Invoice details',
      }),
      voucher: intl.formatMessage({
        description: 'here is your voucher',
        defaultMessage: 'Here is your voucher!',
      }),
      address: intl.formatMessage({
        description: 'address',
        defaultMessage: 'Address',
      }),
      birthDate: intl.formatMessage({
        description: 'birthDAte',
        defaultMessage: 'Birth Date',
      }),
      contactEmail: intl.formatMessage({
        description: 'Contact email',
        defaultMessage: 'Contact email',
      }),
      nationality: intl.formatMessage({
        description: 'Nationality',
        defaultMessage: 'Nationality',
      }),
      phone: intl.formatMessage({
        description: 'phone',
        defaultMessage: 'Phone',
      }),
      businessPhone: intl.formatMessage({
        description: 'Business Phone',
        defaultMessage: 'Business Phone',
      }),
      servicesIncluded: intl.formatMessage({
        description: 'Services included',
        defaultMessage: 'Included Services',
      }),
      paymentType: intl.formatMessage({
        description: 'Payment type',
        defaultMessage: 'Method of payment',
      }),
      cost: intl.formatMessage({
        description: 'Cost vat included',
        defaultMessage: 'Cost (VAT included)',
      }),
      vat: intl.formatMessage({
        description: 'vat',
        defaultMessage: 'VAT',
      }),
    },
    snackbar: {
      buyTicketSuccess: intl.formatMessage({
        description: 'ticket successfully purchased',
        defaultMessage: 'Ticket successfully purchased',
      }),
      gatewayNotExist: intl.formatMessage({
        description: 'gateway not exist',
        defaultMessage: 'Gateway not exist',
      }),
      paymentTypeNotExist: intl.formatMessage({
        description: 'payment Type Not Exist',
        defaultMessage: 'Payment Type Not Exist',
      }),
    },
    checkout: {
      errors: {
        error1: intl.formatMessage({
          description: 'order data request processing error',
          defaultMessage: 'Order data request processing error',
        }),
        error2: intl.formatMessage({
          description: 'order data processing error',
          defaultMessage: 'Order data processing error',
        }),
        error3: intl.formatMessage({
          description: 'order payment initialization error',
          defaultMessage: 'Order payment initialization error',
        }),
        error4: intl.formatMessage({
          description: 'order payment error',
          defaultMessage: 'Order payment error',
        }),
        paymentExist: intl.formatMessage({
          description: 'payment exist',
          defaultMessage: 'Payment exist',
        }),
      },
    },
  };
}
