import React, { useEffect, useState } from 'react';
import {
  AimDialog,
  AimTypography,
  AimIconAndTextButton,
  AimDragDropFileUploader,
  AimTextField,
  AimRichText,
  styled,
} from '@aim/components';
import CheckIcon from '@material-ui/icons/Check';
import ReplayIcon from '@material-ui/icons/Replay';
import GetAppIcon from '@material-ui/icons/GetApp';

import { fileHelper, constants } from '@aim/common';
import Grid from '@material-ui/core/Grid';
import { updateParticipationServices } from '../facultyGqlHelper';

const ButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  margin: 10,
});

const AccreditationMaterialDialog = ({
  dialogState,
  onClose,
  onSuccess,
  onError,
  i18n,
}) => {
  const _i18n = i18n.dialogs.accreditationMaterial;
  const { downloadFromS3, createFile, deleteFile } = fileHelper;

  const [files, setFiles] = useState({
    accreditationCV: [],
    accreditationCOI: [],
    accreditationSurvey: [],
  });

  const [dataToDelete, setDataToDelete] = useState({
    accreditationCV: [],
    accreditationCOI: [],
    accreditationSurvey: [],
  });
  const [fiscalCode, setFiscalCode] = useState();
  const [templatesFiles, setTemplatesFiles] = useState();
  const [participation, setParticipation] = useState();
  const formControlStyle = { width: 'calc(100% - 20px)' };

  // Effects
  useEffect(() => {
    if (dialogState) {
      const rawAccreditation =
        dialogState?.data?.accreditationMaterialFacultyResponse;
      let facultyResponse;
      if (rawAccreditation) facultyResponse = JSON.parse(rawAccreditation);
      if (facultyResponse && Object.keys(facultyResponse)?.length > 0) {
        delete facultyResponse.responseStatus;

        setFiles(facultyResponse?.documents || []);
        setFiscalCode(facultyResponse?.fiscalCode);
      }

      setParticipation(dialogState?.data?.participation);
      if (dialogState?.accreditationMaterialTemplates) {
        const templates = dialogState?.accreditationMaterialTemplates.map(
          (file) => ({
            name: file.name,
            extension: file.extension,
          })
        );
        setTemplatesFiles(templates);
      }
    }
  }, [dialogState]);

  // Helpers
  const uploadFilesToS3 = async (f, key) => {
    const originalName = f?.name || new Date().getTime().toString();

    const matchExtension = originalName.match(/\.[0-9a-z]+$/i);
    const extension = matchExtension ? matchExtension[0] : '';

    const fileSize = f?.size;

    const input = {
      originalName: originalName,
      extension: extension,
      size: Number(fileSize) || 0,
    };
    let id = await createFile({
      dirPath: `events/${dialogState?.eventId}/faculty/accreditationMaterial/${key}`,
      fileData: input,
      localFile: f,
    });
    return {
      ...input,
      name: originalName,
      id,
      key,
    };
  };

  const deleteFilesFromS3 = async () => {
    Object.entries(dataToDelete).forEach(([key, value]) => {
      const dirPath = `events/${dialogState?.eventId}/faculty/accreditationMaterial/${key}`;
      value.forEach(
        async (f) => await deleteFile({ dirPath, fileData: f }, false)
      );
    });
  };

  const onSubmit = async (status) => {
    const prepareData = Object.entries(files)
      .map(([key, value]) => {
        let files = value.map(async (f) => await uploadFilesToS3(f, key));
        return files;
      })
      .flat();
    let finalResult = await Promise.all(prepareData);
    let docsToSend = {};
    Object.keys(files).forEach((k) => {
      docsToSend[k] = finalResult
        .filter((i) => i.key === k)
        .map((i) => {
          let remapped = { ...i };
          delete remapped.key;
          return remapped;
        });
    });

    let input = { documents: docsToSend, fiscalCode };
    if (Object.keys(input)?.length > 0)
      input = {
        ...input,
        responseStatus: status,
      };
    let accreditationMaterialFacultyResponse = JSON.stringify(input);

    try {
      await deleteFilesFromS3();
      await updateParticipationServices(
        {
          id: dialogState?.data.id,
          accreditationMaterialFacultyResponse,
        },
        true
      );
      onSuccess();
      onClose();
    } catch (err) {
      console.error('err', err);
      onError();
    }
  };

  const uploadFiles = async (f, key) => {
    const file = f[0];
    setFiles({ ...files, [key]: [...(files[key] ? files[key] : []), file] });
  };

  const removeFile = async (f, key) => {
    let fileToDelete = files[key].filter((i) => i === f);
    setDataToDelete({
      ...dataToDelete,
      [key]: [...dataToDelete[key], ...fileToDelete],
    });
    const newfile = files[key].filter((i) => i !== f);
    setFiles({ ...files, [key]: [...newfile] });
  };

  const downloadFile = async (item, key) => {
    if (item.id) {
      await downloadFromS3({
        dirPath: `events/${dialogState?.eventId}/faculty/accreditationMaterial/${key}`,
        fileData: item,
      });
    } else {
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(item);
      link.download = item.name;
      link.click();
      link.remove();
    }
  };

  const downloadTemplateFile = async (item, participation, key) => {
    if (item.id) {
      await downloadFromS3({
        dirPath: `events/${dialogState?.eventId}/participations/${participation.id}/faculty/templates`,
        fileData: item,
      });
    } else {
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(item);
      link.download = item.name;
      link.click();
      link.remove();
    }
  };

  const renderButtons = () => {
    return (
      <ButtonContainer>
        <AimIconAndTextButton
          variant="greyFill"
          text={_i18n.buttons.cancel}
          onClick={onClose}
        >
          <ReplayIcon />
        </AimIconAndTextButton>
        <AimIconAndTextButton
          text={_i18n.buttons.save}
          variant="greenFill"
          onClick={() => onSubmit(constants.FacultyResponseStatus.PUBLISHED)}
        >
          <CheckIcon />
        </AimIconAndTextButton>
        <AimIconAndTextButton
          text={_i18n.buttons.saveDraft}
          variant="greenFill"
          onClick={() => {
            onClose();
            onSubmit(constants.FacultyResponseStatus.DRAFT);
          }}
        >
          <CheckIcon />
        </AimIconAndTextButton>
      </ButtonContainer>
    );
  };

  if (!dialogState) return null;

  return (
    <AimDialog
      open={dialogState.isOpen || false}
      title={_i18n.title}
      onClose={onClose}
      disableEnforceFocus
      hideAgreeButton={true}
      hideCancelButton={true}
      style={{
        width: '85%',
        minWidth: '300px',
        maxWidth: '100%',
        margin: '0 auto',
      }}
      PaperProps={{
        style: {
          paddingRight: 30,
          paddingLeft: 30,
          paddingTop: 20,
          paddingBottom: 30,
          maxWidth: '100%',
          minWidth: 600,
        },
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <AimRichText
            value={dialogState?.accreditationMaterialDescription}
            readOnly
            variant="white"
            editorStyleOverride={{
              minHeight: 'auto',
              maxHeight: 'fit-content',
              overflow: 'hidden !important',
            }}
          />
        </Grid>
        {dialogState?.data?.accreditationMaterialFiscalCodeIncluded && (
          <Grid item xs={12}>
            <AimTextField
              label={_i18n.sectionTitles.fiscalCode}
              formControlStyle={formControlStyle}
              value={fiscalCode}
              onChange={(e) => setFiscalCode(e.target.value)}
            />
          </Grid>
        )}
        {dialogState?.accreditationMaterialTemplates &&
          participation &&
          dialogState?.accreditationMaterialTemplates.map((file) => (
            <AimIconAndTextButton
              key={file.id}
              variant={'greyFill'}
              text={file.originalName}
              onClick={() => downloadTemplateFile(file, participation)}
            >
              <GetAppIcon />
            </AimIconAndTextButton>
          ))}
        {dialogState?.data?.accreditationMaterialCVIncluded && (
          <Grid item xs={12}>
            <AimTypography variant="h4">
              {_i18n.sectionTitles.accreditationMaterialCV}
            </AimTypography>
            <AimTypography variant="text">
              {_i18n.sectionTitles.accreditationMaterialCVFormatAccepted}
            </AimTypography>
            <div style={{ backgroundColor: 'white', margin: 5 }}>
              <AimDragDropFileUploader
                key="xx"
                dragTitle={_i18n.dragAndDrop.title}
                dragSubTitle={_i18n.dragAndDrop.subTitle}
                clickText={_i18n.dragAndDrop.clickText}
                onUploadFile={(files) => uploadFiles(files, 'accreditationCV')}
                onRemove={(file) => removeFile(file, 'accreditationCV')}
                onDownload={(file) => downloadFile(file, 'accreditationCV')}
                files={files.accreditationCV}
              />
            </div>
          </Grid>
        )}
        {dialogState?.data?.accreditationMaterialCOIIncluded && (
          <Grid item xs={12}>
            <AimTypography variant="h4">
              {_i18n.sectionTitles.accreditationMaterialCOI}
            </AimTypography>
            <AimTypography variant="text">
              {_i18n.sectionTitles.accreditationMaterialCOIFormatAccepted}
            </AimTypography>
            <div style={{ backgroundColor: 'white', margin: 5 }}>
              <AimDragDropFileUploader
                key="xdrrx"
                dragTitle={_i18n.dragAndDrop.title}
                dragSubTitle={_i18n.dragAndDrop.subTitle}
                clickText={_i18n.dragAndDrop.clickText}
                onUploadFile={(files) => uploadFiles(files, 'accreditationCOI')}
                onRemove={(file) => removeFile(file, 'accreditationCOI')}
                onDownload={(file) => downloadFile(file, 'accreditationCOI')}
                files={files.accreditationCOI}
              />
            </div>
          </Grid>
        )}
        {dialogState?.data?.accreditationMaterialSurveyIncluded && (
          <Grid item xs={12}>
            <AimTypography variant="h4">
              {_i18n.sectionTitles.accreditationMaterialSurvey}
            </AimTypography>
            <AimTypography variant="text">
              {_i18n.sectionTitles.accreditationMaterialSurveyFormatAccepted}
            </AimTypography>
            <div style={{ backgroundColor: 'white', margin: 5 }}>
              <AimDragDropFileUploader
                key="xuioijox"
                dragTitle={_i18n.dragAndDrop.title}
                dragSubTitle={_i18n.dragAndDrop.subTitle}
                clickText={_i18n.dragAndDrop.clickText}
                onUploadFile={(files) =>
                  uploadFiles(files, 'accreditationSurvey')
                }
                onRemove={(file) => removeFile(file, 'accreditationSurvey')}
                onDownload={(file) => downloadFile(file, 'accreditationSurvey')}
                files={files.accreditationSurvey}
              />
            </div>
          </Grid>
        )}
      </Grid>
      {renderButtons()}
    </AimDialog>
  );
};

export default AccreditationMaterialDialog;
