export default {
  breakoutRoomMail: (intl) => ({
    mail: {
      object: {
        invitation: intl.formatMessage({
          description: 'invitation',
          defaultMessage: 'Appointment invitation from',
        }),
        result_accept: intl.formatMessage({
          description: 'result_accept',
          defaultMessage: 'Appointment confirmation with',
        }),
        result_reject: intl.formatMessage({
          description: 'result_reject',
          defaultMessage: 'Rejection of appointment with',
        }),
        cancellation: intl.formatMessage({
          description: 'cancellation',
          defaultMessage: 'Cancelation of appointment with',
        }),
      },
      invitation: {
        title: intl.formatMessage({
          description: 'invitationEmailTitle',
          defaultMessage: 'Appointment invitation',
        }),
        accept_title: intl.formatMessage({
          description: 'invitationEmailTitle',
          defaultMessage: 'Confirmed appointment',
        }),
        reject_title: intl.formatMessage({
          description: 'invitationEmailTitle',
          defaultMessage: 'Rejected appointment',
        }),
        cancel_title: intl.formatMessage({
          description: 'invitationEmailTitle',
          defaultMessage: 'Canceled appointment',
        }),
        timeGreeting: {
          morning: intl.formatMessage({
            description: 'morning',
            defaultMessage: 'Good morning',
          }),
          evening: intl.formatMessage({
            description: 'evening',
            defaultMessage: 'Good evening',
          }),
        },
        hourRangeMessage1: intl.formatMessage({
          description: 'hourRangeMessage1',
          defaultMessage: 'from',
        }),
        hourRangeMessage2: intl.formatMessage({
          description: 'hourRangeMessage2',
          defaultMessage: 'to ',
        }),
        emailRegistrationMessage: intl.formatMessage({
          description: 'emailRegistrationMessage',
          defaultMessage:
            'If you have not yet registered, click below to complete your event profiling and registration. \nYou will then be able to return to this email to reply to the Sponsor.',
        }),
        message_1: intl.formatMessage({
          description: 'message_1',
          defaultMessage:
            'you have received an invitation for an appointment with the Sponsor',
        }),
        accept_message_1: intl.formatMessage({
          description: 'accept_message_1',
          defaultMessage:
            'we write to you to confirm the appointment with the Sponsor',
        }),
        reject_message_1: intl.formatMessage({
          description: 'reject_message_1',
          defaultMessage: 'you refused the appointment with the Sponsor',
        }),
        cancel_message_1: intl.formatMessage({
          description: 'cancel_message_1',
          defaultMessage:
            'we are writing to inform you that the appointment with the Sponsor',
        }),
        cancel_message_2: intl.formatMessage({
          description: 'cancel_message_2',
          defaultMessage: 'has been cancelled',
        }),
        message_2: intl.formatMessage({
          description: 'message_2',
          defaultMessage: 'for the day',
        }),
        linkText: intl.formatMessage({
          description: 'linkText',
          defaultMessage: 'Reply',
        }),
        linkRegistrationText: intl.formatMessage({
          description: 'linkRegistrationText',
          defaultMessage: 'Register',
        }),
        acceptLinkText: intl.formatMessage({
          description: 'acceptLinkText',
          defaultMessage: 'Click here to view booking details',
        }),
        icsLinkText: intl.formatMessage({
          description: 'icsLinkText',
          defaultMessage:
            'Save this appointment in your calendar by clicking here',
        }),
        greeting: intl.formatMessage({
          description: 'greeting',
          defaultMessage: 'Greetings',
        }),
        reject_greeting: intl.formatMessage({
          description: 'reject_greeting',
          defaultMessage: 'Best regards',
        }),
        cancel_greeting: intl.formatMessage({
          description: 'cancel_greeting',
          defaultMessage: 'We apologize for the inconvenience, best regards',
        }),
        sign: intl.formatMessage({
          description: 'sign',
          defaultMessage: 'The AIM Communication team',
        }),
      },
    },
  }),
  breakoutRoomList: (intl) => ({
    page: {
      backButton: {
        label: intl.formatMessage({
          description: 'serviceConfiguration',
          defaultMessage: 'Services Configurations',
        }),
        tooltip: intl.formatMessage({
          description: 'backtoServiceConfiguration',
          defaultMessage: 'Back to Services Configurations',
        }),
      },
      title: intl.formatMessage({
        description: 'myBreakoutRooms',
        defaultMessage: 'My Breakout Rooms',
      }),
      icons: {
        edit: intl.formatMessage({
          description: 'edit',
          defaultMessage: 'Configure',
        }),
        preview: intl.formatMessage({
          description: 'preview',
          defaultMessage: 'Show',
        }),
      },
    },
    cardContent: {
      breakoutRoom: intl.formatMessage({
        description: 'breakoutRoom',
        defaultMessage: 'Breakout room',
      }),
      date: intl.formatMessage({
        description: 'date',
        defaultMessage: 'Activation Date',
      }),
      timetable: intl.formatMessage({
        description: 'timetable',
        defaultMessage: 'Timetable',
      }),
      maxAttendants: intl.formatMessage({
        description: 'maxAttendants',
        defaultMessage: 'Max attendants per slot',
      }),
      duration: intl.formatMessage({
        description: 'duration',
        defaultMessage: 'Single Slot Duration',
      }),
      notAvailable: intl.formatMessage({
        description: 'notAvailable',
        defaultMessage: 'Not Available',
      }),
      purchasedAt: intl.formatMessage({
        description: 'purchasedAt',
        defaultMessage: 'not configured yet - purchased at',
      }),
      tooltip: {
        tooLate: intl.formatMessage({
          description: 'purchasedAt',
          defaultMessage:
            'This breakoutroom is finished, it is no longer possible to modify it',
        }),
        evendEnd: intl.formatMessage({
          description: 'purchasedAt',
          defaultMessage:
            'The event has ended, it is no longer possible to modify this BreakoutRoom',
        }),
      },
    },
  }),
  breakoutRoomConfiguration: (intl) => ({
    calendar: {
      pending: intl.formatMessage({
        description: 'pending',
        defaultMessage: 'Pending',
      }),
      accepted: intl.formatMessage({
        description: 'accepted',
        defaultMessage: 'Accepted',
      }),
    },
    form: {
      title: {
        label: intl.formatMessage({
          description: 'BreakoutRooms drawer title',
          defaultMessage: 'Breakout rooms',
        }),
      },
      labels: {
        rangeA: intl.formatMessage({
          description: 'rangeAlabel',
          defaultMessage: 'Morning',
        }),
        rangeB: intl.formatMessage({
          description: 'rangeBlabel',
          defaultMessage: 'Evening',
        }),
        placeholder: intl.formatMessage({
          description: 'placeholder',
          defaultMessage: 'Insert',
        }),
        date: intl.formatMessage({
          description: 'date',
          defaultMessage: 'Activation date',
        }),
        duration: intl.formatMessage({
          description: 'duration',
          defaultMessage: 'Standard slot duration',
        }),
        start1: intl.formatMessage({
          description: 'start1',
          defaultMessage: 'Start morning',
        }),
        start2: intl.formatMessage({
          description: 'start2',
          defaultMessage: 'Start evening',
        }),
        end1: intl.formatMessage({
          description: 'end1',
          defaultMessage: 'End morning',
        }),
        end2: intl.formatMessage({
          description: 'end2',
          defaultMessage: 'End evening',
        }),
        maxAttendants: intl.formatMessage({
          description: 'maxAttendants',
          defaultMessage: 'Max n° reservation per slot',
        }),
      },
      warnings: {
        label: intl.formatMessage({
          description: 'label',
          defaultMessage: 'Warning:',
        }),
        labelError: intl.formatMessage({
          description: 'labelError',
          defaultMessage: 'Error:',
        }),
        uselessHours: intl.formatMessage({
          description: 'uselessHours',
          defaultMessage: 'You are purchasing less hours then available',
        }),
        exceededHours: intl.formatMessage({
          description: 'exceededHours',
          defaultMessage: 'You are purchasing more hours then available',
        }),
        selectedHours: intl.formatMessage({
          description: 'selectedHours',
          defaultMessage: 'Total selected minutes ',
        }),
        maxAvailableHours: intl.formatMessage({
          description: 'maxAvailableHours',
          defaultMessage: 'Current specification max',
        }),
        purchasedHours: intl.formatMessage({
          description: 'purchasedHours',
          defaultMessage: 'Purchased',
        }),
        inRange1: intl.formatMessage({
          description: 'inRange1',
          defaultMessage: `in morning range`,
        }),
        inRange2: intl.formatMessage({
          description: 'inRange2',
          defaultMessage: `in evening range`,
        }),
        allocatedHours: intl.formatMessage({
          description: 'allocatedHours',
          defaultMessage: 'Allocated',
        }),
        notAllocatedHours: intl.formatMessage({
          description: 'notAllocatedHours',
          defaultMessage: 'Not allocated',
        }),
      },
    },
    dialog: {
      edit: {
        title: intl.formatMessage({
          description: 'deleteBreakoutRoomDialogTitle',
          defaultMessage: 'Edit breakout room slot',
        }),
        message1: intl.formatMessage({
          description: 'deleteBreakoutRoomDialogMessage1',
          defaultMessage:
            'You are trying to edit a breakout room with registered bookings.',
        }),
        message2: intl.formatMessage({
          description: 'deleteBreakoutRoomDialogMessage2',
          defaultMessage:
            'If you continue, all bookings will be deleted and reserved users will be notified by email',
        }),
      },
    },
    page: {
      backButton: {
        label: intl.formatMessage({
          description: 'serviceConfiguration',
          defaultMessage: 'Breakout Rooms Configurations',
        }),
        tooltip: intl.formatMessage({
          description: 'backtoBreakoutRoomConfiguration',
          defaultMessage: 'Back to Breakout Rooms Configurations',
        }),
      },
      title: intl.formatMessage({
        description: 'configureBreakoutRoom',
        defaultMessage: 'Configure Breakout Room',
      }),
      forwardButton: intl.formatMessage({
        description: 'goToCalendar',
        defaultMessage: 'Go to Calendar',
      }),
    },
    buttons: {
      save: {
        label: intl.formatMessage({
          description: 'save',
          defaultMessage: 'Save',
        }),
      },
      cancel: {
        label: intl.formatMessage({
          description: 'cancel',
          defaultMessage: 'Cancel',
        }),
      },
    },
    snackbar: {
      editSuccess: intl.formatMessage({
        description: 'purchase synpotic edit success',
        defaultMessage: 'Edit success',
      }),
      editFail: intl.formatMessage({
        description: 'purchase synpotic edit fail',
        defaultMessage: 'Error during edit operation',
      }),
    },
  }),
  breakoutRoomReservations: (intl) => ({
    calendar: {
      pending: intl.formatMessage({
        description: 'pending',
        defaultMessage: 'Pending',
      }),
      accepted: intl.formatMessage({
        description: 'accepted',
        defaultMessage: 'Accepted',
      }),
      placeholder: intl.formatMessage({
        description: 'placeholder',
        defaultMessage: 'Set topic...',
      }),
      self: intl.formatMessage({
        description: 'self',
        defaultMessage: 'Booked users',
      }),
      invited: intl.formatMessage({
        description: 'invited',
        defaultMessage: 'Invited users',
      }),
    },
    dialog: {
      note: {
        title: intl.formatMessage({
          description: 'bookingNote',
          defaultMessage: 'Booking note',
        }),
        closeText: intl.formatMessage({
          description: 'closeText',
          defaultMessage: 'close',
        }),
      },
      delete: {
        title: intl.formatMessage({
          description: 'deleteBreakoutRoomDialogTitle',
          defaultMessage: 'Removing user from slot',
        }),
        invitationMessage1: intl.formatMessage({
          description: 'deleteBreakoutRoomDialogMessage1',
          defaultMessage: 'You are trying to remove the booking of',
        }),
        invitationMessage2: intl.formatMessage({
          description: 'deleteBreakoutRoomDialogMessage2',
          defaultMessage: 'If you continue this booking will be deleted',
        }),
      },
    },
    invitations: {
      filter: {
        name: {
          label: intl.formatMessage({
            description: 'nameFilter',
            defaultMessage: 'Name',
          }),
        },
      },
      slotLoad: intl.formatMessage({
        description: 'slotLoad',
        defaultMessage: 'Actual participants',
      }),
      buttons: {
        clear: intl.formatMessage({
          description: 'clear',
          defaultMessage: 'Remove all',
        }),
        invite: intl.formatMessage({
          description: 'invite',
          defaultMessage: 'Invite',
        }),
        goToBreakoutRoom: intl.formatMessage({
          description: 'goToBreakoutRoom',
          defaultMessage: 'Enter BR',
        }),
      },
      noBrSelected: intl.formatMessage({
        description: 'noBrSelected',
        defaultMessage: 'Please, start selecting a slot',
      }),
      noReservations: intl.formatMessage({
        description: 'noReservations',
        defaultMessage: "There aren't reservations here",
      }),
      cards: {
        noteReject: intl.formatMessage({
          description: 'noteReject',
          defaultMessage: 'Reject motivation',
        }),
        noteSelf: intl.formatMessage({
          description: 'noteSelf',
          defaultMessage: 'Message from the user',
        }),
        cancel: intl.formatMessage({
          description: 'cancel',
          defaultMessage: 'Block the user from attending this slot',
        }),
        delete: intl.formatMessage({
          description: 'delete',
          defaultMessage: 'Delete the user invitation',
        }),
      },
    },
    page: {
      backButton: {
        label: intl.formatMessage({
          description: 'backLabel',
          defaultMessage: 'back',
        }),
        tooltip: intl.formatMessage({
          description: 'backTooltip',
          defaultMessage: 'Back',
        }),
      },
      title: intl.formatMessage({
        description: 'breakoutRoomReservation',
        defaultMessage: 'Breakout Room',
      }),
    },
    snackbar: {
      updateSuccess: intl.formatMessage({
        description: 'updateSuccess',
        defaultMessage: 'Succesfully update slot',
      }),
      updateFail: intl.formatMessage({
        description: 'updateFail',
        defaultMessage: 'An error as occurred during updating slot process',
      }),
      deleteSuccess: intl.formatMessage({
        description: 'deleteSuccess',
        defaultMessage: 'Succesfully delete invitations',
      }),
      deleteFail: intl.formatMessage({
        description: 'deleteFail',
        defaultMessage:
          'An error as occurred during deleting invitation process',
      }),
      maxReached: intl.formatMessage({
        description: 'maxReached',
        defaultMessage:
          'Cannot send other invitations, max reservations reached',
      }),
    },
    labels: {
      slots: intl.formatMessage({
        description: 'slots',
        defaultMessage: 'Slots',
      }),
      infoSlot: intl.formatMessage({
        description: 'infoSlot',
        defaultMessage: 'Info Slot',
      }),
    },
    form: {
      placeholder: intl.formatMessage({
        description: 'placeholder',
        defaultMessage: 'Insert',
      }),
      buttons: {
        save: {
          label: intl.formatMessage({
            description: 'save',
            defaultMessage: 'Save',
          }),
        },
        cancel: {
          label: intl.formatMessage({
            description: 'cancel',
            defaultMessage: 'Cancel',
          }),
        },
      },
      title: intl.formatMessage({
        description: 'title',
        defaultMessage: 'Topic',
      }),
      types: {
        label: intl.formatMessage({
          description: 'type',
          defaultMessage: 'Type',
        }),
        oneToOne: intl.formatMessage({
          description: 'oneToOne',
          defaultMessage: 'One to one',
        }),
        groups: intl.formatMessage({
          description: 'groups',
          defaultMessage: 'Groups',
        }),
      },
    },
  }),
  breakoutRoomInvite: (intl) => ({
    form: {
      placeholder: intl.formatMessage({
        description: 'placeholder',
        defaultMessage: 'Insert',
      }),
      emails: intl.formatMessage({
        description: 'emails',
        defaultMessage: 'Invite',
      }),
      note: intl.formatMessage({
        description: 'placeholder',
        defaultMessage: 'Message',
      }),
      buttons: {
        save: {
          label: intl.formatMessage({
            description: 'save',
            defaultMessage: 'Send',
          }),
        },
        cancel: {
          label: intl.formatMessage({
            description: 'cancel',
            defaultMessage: 'Cancel',
          }),
        },
      },
    },
    page: {
      backButton: {
        label: intl.formatMessage({
          description: 'serviceConfiguration',
          defaultMessage: 'Breakout Rooms Reservations',
        }),
        tooltip: intl.formatMessage({
          description: 'backtoBreakoutRoomReservations',
          defaultMessage: 'Back to Breakout Room Reservations',
        }),
      },
      title: intl.formatMessage({
        description: 'invitationsManaging',
        defaultMessage: 'Invitations Managing',
      }),
      subTitle: intl.formatMessage({
        description: 'invitationsManaging',
        defaultMessage:
          "Select users to whom you'll send an invitation message for the event",
      }),
    },
    snackbar: {
      sendSuccess: intl.formatMessage({
        description: 'sendSuccess',
        defaultMessage: 'Succesfully send invitations',
      }),
      sendFail: intl.formatMessage({
        description: 'sendFail',
        defaultMessage: 'An error as occurred during invitation process',
      }),
      sendInvalid: intl.formatMessage({
        description: 'sendFail',
        defaultMessage:
          'No email has been specified or you are trying to invite only previously invited contacts',
      }),
      maxReached: intl.formatMessage({
        description: 'maxReached',
        defaultMessage:
          'Cannot send other invitations, max reservations reached',
      }),
    },
  }),
  breakoutRoomSendDelete: (intl) => ({
    page: {
      backButton: {
        label: intl.formatMessage({
          description: 'breakoutRoom',
          defaultMessage: 'Breakout Room',
        }),
        tooltip: intl.formatMessage({
          description: 'backtoBreakoutRoomReservations',
          defaultMessage: 'Back to Breakout Room Reservations',
        }),
      },
      titleSingular: intl.formatMessage({
        description: 'removingOneManaging',
        defaultMessage: 'Remove participant',
      }),
      titleSingularBan: intl.formatMessage({
        description: 'titleSingularBan',
        defaultMessage: 'Block participant',
      }),
      titlePlural: intl.formatMessage({
        description: 'rmeobingAllManaging',
        defaultMessage: 'Remove participants',
      }),
      subTitlePlural: intl.formatMessage({
        description: 'invitationsManaging',
        defaultMessage:
          "Participants invited and self proposed will be removed. You'll be able to customize the notification message to send to participants.",
      }),
      subTitleSingular: intl.formatMessage({
        description: 'invitationsManaging',
        defaultMessage:
          "This participant will be removed. You'll be able to customize the notification message to send.",
      }),
      subTitleSingularBan: intl.formatMessage({
        description: 'subTitleSingularBan',
        defaultMessage:
          "This participant will be blocked. You'll be able to customize the notification message to send.",
      }),
    },
    form: {
      nameParticipantSingular: intl.formatMessage({
        description: 'nameParticipantSingular',
        defaultMessage: 'Participant name',
      }),
      nameParticipantPlural: intl.formatMessage({
        description: 'nameParticipantPlural',
        defaultMessage: 'Participants name',
      }),
      note: intl.formatMessage({
        description: 'placeholder',
        defaultMessage: 'Message',
      }),
      buttons: {
        save: {
          label: intl.formatMessage({
            description: 'save',
            defaultMessage: 'Send',
          }),
        },
        cancel: {
          label: intl.formatMessage({
            description: 'cancel',
            defaultMessage: 'Cancel',
          }),
        },
      },
    },
    snackbar: {
      deleteSuccess: intl.formatMessage({
        description: 'sendSuccess',
        defaultMessage: 'Succesfully send notifications',
      }),
      sendFail: intl.formatMessage({
        description: 'sendFail',
        defaultMessage:
          'An error as occurred during sending notifications process',
      }),
    },
    confirmPage: {
      confirm: intl.formatMessage({
        description: 'confirm',
        defaultMessage:
          'The following users have been removed and succesfully notified',
      }),
      confirmBan: intl.formatMessage({
        description: 'confirmBan',
        defaultMessage:
          'The following users have been blocked and succesfully notified',
      }),
    },
  }),
};
