import React from 'react';
import { format } from 'date-fns';

import { utilities } from '@aim/common';
import { AimIconAndTextButton, AimTypography } from '@aim/components';

import Grid from '@material-ui/core/Grid';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const { decodeDbNumber } = utilities;

const PurchaseCard = ({ row, history, i18n, eventId, participationId }) => (
  <Grid
    container
    style={{ padding: '3%', marginBottom: '3%', backgroundColor: 'white' }}
    direction="column"
  >
    <Grid container direction="row" justifyContent="space-between">
      <Grid item xs={7}>
        <AimTypography variant="h4">{i18n.header.paymentId}</AimTypography>
      </Grid>
      <Grid item xs={5}>
        <AimTypography variant="columnHeader">{row?.paymentId}</AimTypography>
      </Grid>
    </Grid>
    <Grid container direction="row" justifyContent="space-between">
      <Grid item xs={7}>
        <AimTypography variant="h4">{i18n.header.hotelName}</AimTypography>
      </Grid>
      <Grid item xs={5}>
        <AimTypography variant="columnHeader">{row?.hotelName}</AimTypography>
      </Grid>
    </Grid>
    <Grid container direction="row" justifyContent="space-between">
      <Grid item xs={7}>
        <AimTypography variant="h4">{i18n.header.roomsNumber}</AimTypography>
      </Grid>
      <Grid item xs={5}>
        <AimTypography variant="columnHeader">{row?.roomsNumber}</AimTypography>
      </Grid>
    </Grid>
    <Grid container direction="row" justifyContent="space-between">
      <Grid item xs={7}>
        <AimTypography variant="h4">{i18n.header.guestsNumber}</AimTypography>
      </Grid>
      <Grid item xs={5}>
        <AimTypography variant="columnHeader">
          {row?.guestsNumber}
        </AimTypography>
      </Grid>
    </Grid>
    <Grid container direction="row" justifyContent="space-between">
      <Grid item xs={7}>
        <AimTypography variant="h4">{i18n.header.checkIn}</AimTypography>
      </Grid>
      <Grid item xs={5}>
        <AimTypography variant="columnHeader">
          {row?.checkIn && format(new Date(row.checkIn), 'dd/MM/yyyy')}
        </AimTypography>
      </Grid>
    </Grid>
    <Grid container direction="row" justifyContent="space-between">
      <Grid item xs={7}>
        <AimTypography variant="h4">{i18n.header.checkOut}</AimTypography>
      </Grid>
      <Grid item xs={5}>
        <AimTypography variant="columnHeader">
          {row?.checkOut && format(new Date(row.checkOut), 'dd/MM/yyyy')}
        </AimTypography>
      </Grid>
    </Grid>
    <Grid container direction="row" justifyContent="space-between">
      <Grid item xs={7}>
        <AimTypography variant="h4">{i18n.header.amount}</AimTypography>
      </Grid>
      <Grid item xs={5}>
        <AimTypography variant="columnHeader">
          {decodeDbNumber(row?.amount)}
        </AimTypography>
      </Grid>
    </Grid>
    <Grid container direction="row" justifyContent="space-between">
      <Grid item xs={7}>
        <AimTypography variant="h4">{i18n.header.paymentDate}</AimTypography>
      </Grid>
      <Grid item xs={5}>
        <AimTypography variant="columnHeader">
          {row?.date && format(new Date(row.date), 'dd/MM/yyyy')}
        </AimTypography>
      </Grid>
    </Grid>
    <Grid container direction="row" justifyContent="space-between">
      <Grid item xs={7}>
        <AimTypography variant="h4">{i18n.header.paymentStatus}</AimTypography>
      </Grid>
      <Grid item xs={5}>
        <AimTypography variant="columnHeader">
          {row?.paymentStatus
            ? row?.paymentStatus === 'done'
              ? 'Paid'
              : 'Pending'
            : 'Cancelled'}
        </AimTypography>
      </Grid>
    </Grid>
    <Grid container direction="column" alignItems="stretch">
      <AimIconAndTextButton
        variant="yellowFill"
        text={i18n.detail}
        style={{
          padding: 0,
        }}
        onClick={(e) =>
          history.push(
            `/events/${eventId}/user/${participationId}/my-allotment/${row.productId}`
          )
        }
      >
        <ArrowForwardIcon />
      </AimIconAndTextButton>
    </Grid>
  </Grid>
);

const MyAllotmentMobile = ({
  i18n,
  history,
  eventId,
  purchases,
  participationId,
}) => {
  return (
    <Grid
      container
      direction="column"
      style={{
        padding: '3%',
        marginBottom: '10%',
        backgroundColor: '#e8e8e8',
      }}
    >
      {purchases.length > 0 &&
        purchases.map((purchase, idx) => (
          <PurchaseCard
            key={idx}
            i18n={i18n}
            eventId={eventId}
            history={history}
            row={purchase}
            participationId={participationId}
          />
        ))}
    </Grid>
  );
};

export default MyAllotmentMobile;
