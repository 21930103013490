export default {
  myTicket: (intl) => ({
    page: {
      tooltip: intl.formatMessage({
        description: 'Back my ticket page',
        defaultMessage: 'Back',
      }),
      clientInformations: intl.formatMessage({
        description: ' Client informations my ticket page',
        defaultMessage: 'Client informations',
      }),
      name: intl.formatMessage({
        description: ' Name: my ticket page',
        defaultMessage: 'Name:',
      }),
      surname: intl.formatMessage({
        description: ' Surname my ticket page',
        defaultMessage: 'Surname:',
      }),
      email: intl.formatMessage({
        description: ' Email my ticket page',
        defaultMessage: 'Email:',
      }),
      pec: intl.formatMessage({
        description: ' Pec my ticket page',
        defaultMessage: 'Pec:',
      }),
      phone: intl.formatMessage({
        description: ' Phone my ticket page',
        defaultMessage: 'Telephone:',
      }),
      yes: intl.formatMessage({
        description: 'yes label',
        defaultMessage: 'Yes',
      }),
      no: intl.formatMessage({
        description: 'no label',
        defaultMessage: 'No',
      }),
      invoiceToPublicAuthority: intl.formatMessage({
        description: 'invoice to public authority description',
        defaultMessage: 'Invoice to public authority:',
      }),
      uniqueCode: intl.formatMessage({
        description: 'unique code description',
        defaultMessage: 'Unique code:',
      }),
      ipaCode: intl.formatMessage({
        description: 'ipa code description',
        defaultMessage: 'Ipa code:',
      }),
      isSplitPayment: intl.formatMessage({
        description: 'split payment description',
        defaultMessage: 'Split payment:',
      }),
      isVatExent: intl.formatMessage({
        description: 'Vat event description',
        defaultMessage: 'Vat exent:',
      }),
      profile: intl.formatMessage({
        description: ' Profile my ticket Page ',
        defaultMessage: 'Profile:',
      }),
      accompanyingTitle: intl.formatMessage({
        description: ' Accompanying persons title my ticket page',
        defaultMessage: 'Accompanying persons',
      }),
      accompanyingPersonName: intl.formatMessage({
        description: ' Accompanying person name my ticket page',
        defaultMessage: 'Accompanying person name:',
      }),
      accompanyingPersonSurname: intl.formatMessage({
        description: ' Accompanying person surname my ticket page',
        defaultMessage: 'Accompanying person surname:',
      }),
      accompanyingPersonCity: intl.formatMessage({
        description: ' Accompanying person city my ticket page',
        defaultMessage: 'Accompanying person city:',
      }),
      //needs to be equals to billing info... translations on common not working
      company: intl.formatMessage({
        description: 'Company label billing informations form',
        defaultMessage: 'Company',
      }),
      individual: intl.formatMessage({
        description: 'Individual label billing informations form',
        defaultMessage: 'Individual',
      }),
      ticketId: intl.formatMessage({
        description: ' TicketId my ticket page',
        defaultMessage: 'Ticket Id:',
      }),
      billingInformations: intl.formatMessage({
        description: ' Billing informations my ticket page',
        defaultMessage: 'Billing informations',
      }),
      invoiceTo: intl.formatMessage({
        description: ' Invoice to my ticket page',
        defaultMessage: 'Invoice to:',
      }),
      address: intl.formatMessage({
        description: 'Address my ticket page',
        defaultMessage: 'Address:',
      }),
      postalCode: intl.formatMessage({
        description: ' Postal code my ticket page',
        defaultMessage: 'Postal code:',
      }),
      city: intl.formatMessage({
        description: ' City my ticket page',
        defaultMessage: 'City:',
      }),
      regionProvinceState: intl.formatMessage({
        description: ' Region/Province/State: my ticket page',
        defaultMessage: 'Region/Province/State:',
      }),
      region: intl.formatMessage({
        description: ' Region: my ticket page',
        defaultMessage: 'Region:',
      }),
      province: intl.formatMessage({
        description: ' Province: my ticket page',
        defaultMessage: 'Province:',
      }),
      country: intl.formatMessage({
        description: 'Country  my ticket page',
        defaultMessage: 'Country :',
      }),
      vatCode: intl.formatMessage({
        description: 'VAT Number my ticket page',
        defaultMessage: 'VAT Number :',
      }),
      taxCode: intl.formatMessage({
        description: 'Fiscal Code my ticket page',
        defaultMessage: 'Fiscal Code :',
      }),
      reference: intl.formatMessage({
        description: 'Reference : my ticket page',
        defaultMessage: 'Reference:',
      }),
      companyName: intl.formatMessage({
        description: " Company's name (Individual name)  my ticket page",
        defaultMessage: "Company's name (Individual name) :",
      }),
      paymentData: intl.formatMessage({
        description: ' PaymentData my ticket page',
        defaultMessage: 'Payment Data ',
      }),
      paymentType: intl.formatMessage({
        description: ' Method of payment my ticket page',
        defaultMessage: 'Method of payment: ',
      }),
      amount: intl.formatMessage({
        description: ' Cost my ticket page',
        defaultMessage: 'Cost: ',
      }),
      paymentStatus: intl.formatMessage({
        description: 'payment stauts my ticket page',
        defaultMessage: 'Payment status',
      }),
      download: intl.formatMessage({
        description: ' Download my ticket page',
        defaultMessage: 'Download ',
      }),
      paymentTypes: {
        bankTransfer: intl.formatMessage({
          description: 'bank transfer payment type',
          defaultMessage: 'Bank Transfer',
        }),
        bankTransferIndication: intl.formatMessage({
          description: 'bank transfer indication label',
          defaultMessage: 'Bank transfer indication',
        }),
        iban: intl.formatMessage({
          description: 'IBAN label',
          defaultMessage: 'IBAN',
        }),
        reasonForPayment: intl.formatMessage({
          description: 'Reason for payment label',
          defaultMessage: 'Reason for payment',
        }),
        addressedTo: intl.formatMessage({
          description: 'addressed to label label',
          defaultMessage: 'Addressed to',
        }),
        creditCard: intl.formatMessage({
          description: 'credit card payment type',
          defaultMessage: 'Credit Card',
        }),

        both: intl.formatMessage({
          description: 'credit card/bank transfer label',
          defaultMessage: 'Credit Card/Bank Transfer',
        }),
      },
    },
  }),
};
