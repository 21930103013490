export const translation = {
  sponsorPackagesDetails: (intl) => ({
    back: intl.formatMessage({
      description: 'back label',
      defaultMessage: 'back',
    }),
    description: intl.formatMessage({
      description: 'description label',
      defaultMessage: 'Description',
    }),
    availability: intl.formatMessage({
      description: 'availability label',
      defaultMessage: 'Availability',
    }),
    prices: intl.formatMessage({
      description: 'prices label',
      defaultMessage: 'Prices',
    }),
    services: intl.formatMessage({
      description: 'services label',
      defaultMessage: 'Services',
    }),
    unitaryPrice: intl.formatMessage({
      description: 'unitary price label',
      defaultMessage: 'Unitary Price (without VAT rate)',
    }),
    quantity: intl.formatMessage({
      description: 'quantity label',
      defaultMessage: 'Quantity',
    }),
    originalPrice: intl.formatMessage({
      description: 'unitary price label',
      defaultMessage: 'Original Price (without VAT rate)',
    }),
    newPrice: intl.formatMessage({
      description: 'unitary price label',
      defaultMessage: 'New Price (without VAT rate)',
    }),
    vatRate: intl.formatMessage({
      description: 'vat rate label',
      defaultMessage: 'VAT (%)',
    }),
    totalCost: intl.formatMessage({
      description: 'total cost label',
      defaultMessage: 'Total Cost (with VAT rate)',
    }),
    totalNetPrice: intl.formatMessage({
      description: 'total price label',
      defaultMessage: 'Total Price (€)',
    }),
    totalVat: intl.formatMessage({
      description: 'total cost label',
      defaultMessage: 'VAT (€)',
    }),
    totalPrice: intl.formatMessage({
      description: 'total cost label',
      defaultMessage: 'Total (€)',
    }),
    purchase: intl.formatMessage({
      description: 'purchase label',
      defaultMessage: 'Purchase',
    }),
    addToCartFail: intl.formatMessage({
      description: 'add to cart fail',
      defaultMessage: 'Error during add to cart operation',
    }),
  }),
};
