import { aws, appState } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const getPhysicalStand = /* GraphQL */ `
  query GetPhysicalStand($id: ID!) {
    getEvent(id: $id) {
      physicalStandService {
        description
        extraPriceDate
        extraPriceRate
        id
        isBankTransferEnabled
        isCreditCardEnabled
        publicationDate
        name
        vatRate
        documents {
          items {
            id
            name: originalName
            size
            extension
            isMandatory
          }
        }
        floors {
          items {
            code
            id
            name
            spaces {
              items {
                code
                extraPriceDisabled
                id
                name
                netPrice
                noVat
                note
                spaceHeight
                spaceLength
                spaceDepth
                surface
                plantImage {
                  extension
                  id
                  originalName
                  size
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const getSponsorSpaces = /* GraphQL */ `
  query GetSponsorSpaces($id: ID!) {
    getSponsor(id: $id) {
      physicalStandSpaces {
        items {
          code
          extraPriceDisabled
          id
          name
          netPrice
          noVat
          note
          surface
          spaceLength
          spaceHeight
          spaceDepth
          floor {
            id
            code
            name
          }
          plantImage {
            id
            originalName
            size
            extension
          }
          physicalStandSpacesItems {
            items {
              id
              amountPerQuantity
              quantity
              sponsorPackageItem {
                package {
                  buyOperations {
                    items {
                      id
                      purchase {
                        id
                        paymentType
                        payment {
                          paymentIdMonetaOnLine
                          paymentIdIngenico
                          paymentIdGpWebpay
                        }
                      }
                    }
                  }
                }
              }
              buyOperation {
                purchase {
                  id
                  paymentType
                  payment {
                    paymentIdIngenico
                    paymentIdMonetaOnLine
                    paymentIdGpWebpay
                  }
                }
              }
              item {
                code
                id
                description
                amountPerQuantityLabel
                name
                netPrice
                note
                type
                vatRate
                itemSubCategory {
                  id
                  name
                  itemCategory {
                    id
                    name
                  }
                }
              }
            }
          }
          buyOperation {
            id
            purchase {
              id
              paymentType
              payment {
                paymentIdMonetaOnLine
                paymentIdIngenico
                paymentIdGpWebpay
              }
            }
          }
          sponsorPackageItem {
            package {
              buyOperations {
                items {
                  id
                  purchase {
                    id
                    paymentType
                    payment {
                      paymentIdMonetaOnLine
                      paymentIdIngenico
                      paymentIdGpWebpay
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export function usePhysicalStandService() {
  const list = (id, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({ query: getPhysicalStand, variables: { id } })
        .then((response) => resolve(response.data.getEvent))
        .catch((e) => {
          console.error('event-sponsor-physical-stand-service', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const listBySponsor = (id, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({ query: getSponsorSpaces, variables: { id } })
        .then((response) =>
          resolve(response.data.getSponsor.physicalStandSpaces.items)
        )
        .catch((e) => {
          console.error('list-physical-stand-spaces-by-sponsor', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  return { list, listBySponsor };
}
