import { aws, appState } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const getPhysicalStandSpaces = /* GraphQL */ `
  query GetPhysicalStandFloor($id: ID!) {
    getPhysicalStandFloor(id: $id) {
      id
      name
      spaces {
        items {
          code
          extraPriceDisabled
          id
          name
          netPrice
          noVat
          note
          spaceHeight
          spaceLength
          spaceDepth
          surface
          plantImage {
            extension
            id
            originalName
            size
          }
          sponsorPackageItem {
            package {
              buyOperations {
                items {
                  id
                  sponsor {
                    name
                  }
                  isDeleted
                  isExpired
                  purchase {
                    id
                  }
                }
              }
            }
          }
          buyOperation {
            id
            sponsor {
              name
            }
            isDeleted
            isExpired
            purchase {
              id
            }
          }
        }
      }
      physicalStandService {
        isBankTransferEnabled
        isCreditCardEnabled
        vatRate
        extraPriceDate
        extraPriceRate
        event {
          sponsorServiceBookingDuration
        }
      }
    }
  }
`;

const updatePhysicalStand = /* GraphQL */ `
  mutation UpdatePhysicalStand(
    $input: UpdatePhysicalStandSpaceInput!
    $condition: ModelPhysicalStandSpaceConditionInput
  ) {
    updatePhysicalStandSpace(input: $input, condition: $condition) {
      id
    }
  }
`;

const onUpdatePhysicalStandSpace = /* GraphQL */ `
  subscription OnUpdatePhysicalStandSpace {
    onUpdatePhysicalStandSpace {
      id
    }
  }
`;

const onDeletePhysicalStandSpace = /* GraphQL */ `
  subscription OnDeletePhysicalStandSpace {
    onDeletePhysicalStandSpace {
      id
    }
  }
`;

export function usePhysicalStandFloorService() {
  const list = (id, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({ query: getPhysicalStandSpaces, variables: { id } })
        .then((response) => {
          const nextFloors = {
            ...response.data.getPhysicalStandFloor,
            spaces: {
              items: response.data.getPhysicalStandFloor?.spaces?.items?.map(
                (space) => {
                  const buyOperation =
                    space?.buyOperation ||
                    space?.sponsorPackageItem?.package?.buyOperations
                      ?.items?.[0];
                  return {
                    ...space,
                    buyOperation:
                      buyOperation &&
                      buyOperation.isDeleted !== 'true' &&
                      buyOperation.isExpired !== 'true'
                        ? buyOperation
                        : null,
                  };
                }
              ),
            },
          };
          resolve(nextFloors);
        })
        .catch((e) => {
          console.error('event-list-agenda-sessions-list', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const update = (input, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({ query: updatePhysicalStand, variables: { input } })
        .then((response) => {
          resolve(response.data.updatePhysicalStand);
        })
        .catch((e) => {
          console.error('event-update-sponsor-physical-stand', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const onUpdateSpace = (callback) =>
    aws.standardAPI.graphql({ query: onUpdatePhysicalStandSpace }).subscribe({
      next: ({ value }) => callback(value.data.onUpdatePhysicalStandSpace),
      error: (e) => {
        console.error('on-update-physical-stand', e);
      },
    });

  const onDeleteSpace = (callback) =>
    aws.standardAPI.graphql({ query: onDeletePhysicalStandSpace }).subscribe({
      next: ({ value }) => callback(value.data.onDeletePhysicalStandSpace),
      error: (e) => {
        console.error('on-delete-physical-stand', e);
      },
    });

  return { list, update, onUpdateSpace, onDeleteSpace };
}
