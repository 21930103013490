import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { addDays } from 'date-fns';

import Grid from '@material-ui/core/Grid';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import {
  AimTypography,
  AimIconAndTextButton,
  CustomIntl,
  AimSnackbar,
  AimSnackbarSeverity,
  theme,
} from '@aim/components';
import { utilities, appState, constants } from '@aim/common';

import { getSponsorPackage } from './sponsorPackages/sponsorPackagesGqlHelper';
import { translation } from './sponsorPackages/translation';
import {
  MainContainer,
  InnerContainer,
} from '../shared/SponsorPagesContainers';
import { useBuyOperations } from '../shared/buyOperationsGqlHelper';

const {
  decodeDbNumber,
  isValidPurchase,
  vatCalc,
  roundTo2Decimal,
  safeNum,
} = utilities;

const Header = ({ i18n }) => {
  return (
    <>
      <Grid item xs={3}>
        <AimTypography variant="columnHeader">{i18n.services}</AimTypography>
      </Grid>
      <Grid item xs={1}>
        <AimTypography variant="columnHeader">
          {i18n.unitaryPrice}
        </AimTypography>
      </Grid>
      <Grid item xs={1}>
        <AimTypography variant="columnHeader">{i18n.quantity}</AimTypography>
      </Grid>
      <Grid item xs={2}>
        <AimTypography variant="columnHeader">
          {i18n.originalPrice}
        </AimTypography>
      </Grid>
      <Grid item xs={2}>
        <AimTypography variant="columnHeader">{i18n.newPrice}</AimTypography>
      </Grid>
      <Grid item xs={1}>
        <AimTypography variant="columnHeader">{i18n.vatRate}</AimTypography>
      </Grid>
      <Grid item xs={2}>
        <AimTypography variant="columnHeader">{i18n.newPrice}</AimTypography>
      </Grid>
    </>
  );
};

const RigthInfo = ({ label, value, style, withDivider = true }) => (
  <>
    <Grid item xs={8} style={style}></Grid>
    <Grid item xs={2} style={style}>
      <AimTypography variant="columnHeader">{label}</AimTypography>
    </Grid>
    <Grid item xs={2} style={style}>
      <AimTypography variant="text">{value}</AimTypography>
    </Grid>
    <Grid item xs={8}></Grid>
    <Grid item xs={4}>
      {withDivider && <div style={{ borderBottom: '1px solid #84819A47' }} />}
    </Grid>
  </>
);

const SponsorPackageDetails = () => {
  const { eventId, sponsorId, sponsorPackageId } = useParams();
  const history = useHistory();
  const intl = CustomIntl(useIntl());
  const i18n = translation.sponsorPackagesDetails(intl);
  const { update: updateBuyOperation } = useBuyOperations();

  const [paymentType, setPaymentType] = useState();
  const [sponsorPackage, setSponsorPackage] = useState();
  const [snackbar, setSnackbar] = useState({ isOpen: false });

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const res = await getSponsorPackage(sponsorPackageId);
    const nextSponsorPackages = {
      ...res,
      validPurchases: res.buyOperations.items.filter((op) => op.sponsor),
      services: res.services.items.map((s) => ({
        ...s,
        originalNetPrice: decodeDbNumber(s.originalNetPrice),
        netPrice: decodeDbNumber(s.netPrice),
        vatRate: decodeDbNumber(s.vatRate),
      })),
    };
    const totalNetPrice = safeNum(
      nextSponsorPackages.services.reduce(
        (prev, curr) => prev + safeNum(curr.netPrice),
        0
      )
    ).toFixed(2);
    const totalPrice = safeNum(
      nextSponsorPackages.services.reduce(
        (prev, curr) => prev + vatCalc(curr.netPrice, curr.vatRate),
        0
      )
    ).toFixed(2);
    const totalVat = safeNum(
      roundTo2Decimal(totalPrice - totalNetPrice)
    ).toFixed(2);

    const paymentType =
      res.packageService.isCreditCardEnabled &&
      res.packageService.isBankTransferEnabled
        ? 'both'
        : (res.packageService.isCreditCardEnabled &&
            constants.PaymentTypes.CreditCard) ||
          (res.packageService.isBankTransferEnabled &&
            constants.PaymentTypes.BankTransfer);
    setPaymentType(paymentType);

    setSponsorPackage({
      ...nextSponsorPackages,
      totalNetPrice,
      totalPrice,
      totalVat,
    });
  };

  const onAddToCart = async (quantity) => {
    try {
      appState.isLoader.next(true);
      const bookingDate = new Date();
      const bookingExpiringDate = addDays(
        bookingDate,
        sponsorPackage.packageService.event.sponsorServiceBookingDuration
      );

      const inputBuyOperation = {
        buyOperationSponsorId: sponsorId,
        date: bookingDate,
        expiringDate: bookingExpiringDate,
        paymentType: paymentType,
      };

      const responses = await Promise.all(
        Array.apply(null, Array(quantity)).map(async () => {
          const booking = sponsorPackage.buyOperations.items.find(
            (bo) => !bo.sponsor
          );
          await updateBuyOperation(
            { ...inputBuyOperation, id: booking.id },
            false
          );
          return { id: booking.id };
        })
      );

      const destUrl = `/events/${eventId}/${sponsorId}/cart`;
      history.push({
        pathname: destUrl,
        state: {
          bookings: responses,
        },
      });
    } catch (e) {
      console.error(e);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.addToCartFail,
      });
    } finally {
      appState.isLoader.next(false);
    }
  };

  return sponsorPackage ? (
    <>
      <MainContainer>
        <InnerContainer grow bottomSpacing>
          <div style={{ display: 'flex-root', marginTop: 20 }}>
            <AimIconAndTextButton
              variant="text"
              text={i18n.back}
              onClick={() =>
                history.push(`/events/${eventId}/${sponsorId}/my-services`)
              }
            >
              <ArrowBack />
            </AimIconAndTextButton>
          </div>
          <AimTypography variant={'h2'}>{sponsorPackage.name}</AimTypography>
          <div
            style={{
              background: theme.colors.greyScale.backgroundGrey,
              borderRadius: 3,
              padding: 30,
            }}
          >
            <AimTypography variant="columnHeader">
              {i18n.description}
            </AimTypography>
            <AimTypography variant="text">
              {sponsorPackage.description}
            </AimTypography>
            <AimTypography variant="columnHeader">
              {i18n.availability}
            </AimTypography>
            <AimTypography variant="text">
              {sponsorPackage.quantity - sponsorPackage.validPurchases.length}
            </AimTypography>
            {sponsorPackage.services?.length > 0 && (
              <>
                <Grid container alignItems="flex-end">
                  <Header i18n={i18n} />
                  {sponsorPackage.services.map((item) => (
                    <>
                      <Grid item xs={3}>
                        <AimTypography variant="text">
                          {item.name}
                        </AimTypography>
                      </Grid>
                      <Grid item xs={1}>
                        <AimTypography variant="text">
                          {item.originalNetPrice.toFixed(2)}
                        </AimTypography>
                      </Grid>
                      <Grid item xs={1}>
                        <AimTypography variant="text">
                          {item.quantity}
                        </AimTypography>
                      </Grid>
                      <Grid item xs={2}>
                        <AimTypography variant="text">
                          {safeNum(
                            item.originalNetPrice * item.quantity
                          ).toFixed(2)}
                        </AimTypography>
                      </Grid>
                      <Grid item xs={2}>
                        <AimTypography variant="text">
                          {safeNum(item.netPrice).toFixed(2)}
                        </AimTypography>
                      </Grid>
                      <Grid item xs={1}>
                        <AimTypography variant="text">
                          {item.vatRate}
                        </AimTypography>
                      </Grid>
                      <Grid item xs={2}>
                        <AimTypography variant="text">
                          {safeNum(
                            vatCalc(item.netPrice, item.vatRate)
                          ).toFixed(2)}
                        </AimTypography>
                      </Grid>
                      <Grid item xs={12}>
                        <div
                          style={{ borderBottom: '1px solid #84819A47' }}
                        ></div>
                      </Grid>
                    </>
                  ))}
                  <RigthInfo
                    style={{ marginTop: 40 }}
                    label={i18n.totalNetPrice}
                    value={sponsorPackage.totalNetPrice}
                  />
                  <RigthInfo
                    label={i18n.totalVat}
                    value={sponsorPackage.totalVat}
                  />
                  <RigthInfo
                    label={i18n.totalPrice}
                    value={sponsorPackage.totalPrice}
                    withDivider={false}
                  />
                </Grid>
              </>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'flex-end',
              marginTop: 20,
            }}
          >
            <AimIconAndTextButton
              isUpperCase
              variant="yellow"
              text={i18n.purchase}
              disabled={
                sponsorPackage.quantity - sponsorPackage.validPurchases.length <
                1
              }
              onClick={() => onAddToCart(1)}
            >
              <ShoppingCartIcon />
            </AimIconAndTextButton>
          </div>
          <AimSnackbar
            open={snackbar.isOpen}
            onClose={() => setSnackbar({ isOpen: false })}
            severity={snackbar.severity}
          >
            {snackbar.message}
          </AimSnackbar>
        </InnerContainer>
      </MainContainer>
    </>
  ) : null;
};

export default SponsorPackageDetails;
