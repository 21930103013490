import React from 'react';

import Divider from '@material-ui/core/Divider';

import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SettingsIcon from '@material-ui/icons/Settings';
import { useIntl } from 'react-intl';
import i18n from '../i18n';
import Tooltip from '@material-ui/core/Tooltip';

import {
  AimTypography,
  AimIconAndTextButton,
  AimCard,
  styled,
  theme,
  CustomIntl,
} from '@aim/components';

const StyledCard = styled(AimCard)(({ showCardContent }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  padding: 10,
  height: showCardContent ? 160 : 120,
  justifyContent: 'center',
  textAlign: 'center',
  backgroundColor: theme.colors.greyScale.backgroundGrey,
}));

const StyledCardHeader = styled('div')({
  alignItems: 'baseline',
  fontWeight: 'bold',
  textAlign: 'center',
});

const StyledCardContent = styled('div')({
  fontSize: 30,
  fontWeight: 'lighter',
});

const StyledCardActions = styled('div')(({ noCursorPointer }) => ({
  display: 'flex',
  marginTop: 15,
  color: theme.colors.secondary.lightBlue,
  fontWeight: 'bold',
  cursor: noCursorPointer ? 'default' : 'pointer',
  '& button': {
    flex: 0.5,
  },
}));

const StyledDivider = styled(Divider)({
  margin: '4px 0px 8px 0px !important',
});

const SponsorFrontServiceCard = ({
  children,
  title,
  onClickBuy,
  subtitle,
  bordered,
  buyDisabled,
  onClickConfiguration,
  configurationVisible,
  configurationDisabled,
  customTooltip = null,
  showCardContent = false,
}) => {
  const intl = CustomIntl(useIntl());

  const styleBordered = {
    border: `2px solid ${theme.colors.primary.yellow}`,
    borderRadius: 5,
  };

  return (
    <StyledCard
      style={bordered ? styleBordered : null}
      showCardContent={showCardContent}
    >
      <StyledCardHeader>
        <AimTypography variant="h4" margin={0}>
          {title}
        </AimTypography>
        <AimTypography variant="h5" margin={0}>
          {subtitle}
        </AimTypography>
      </StyledCardHeader>
      <StyledDivider />
      {showCardContent && <StyledCardContent>{children}</StyledCardContent>}

      <StyledCardActions disableSpacing noCursorPointer={buyDisabled}>
        {onClickBuy ? (
          <Tooltip title={customTooltip ? customTooltip : ''}>
            <span>
              <AimIconAndTextButton
                variant="primary"
                text={i18n(intl).buttons.buy}
                onClick={onClickBuy}
                disabled={buyDisabled}
              >
                <ShoppingCartIcon />
              </AimIconAndTextButton>
            </span>
          </Tooltip>
        ) : null}
        {configurationVisible && (
          <AimIconAndTextButton
            noCursorPointer={buyDisabled}
            variant="secondary"
            text={i18n(intl).buttons.configure}
            onClick={onClickConfiguration}
            disabled={configurationDisabled}
          >
            <SettingsIcon />
          </AimIconAndTextButton>
        )}
      </StyledCardActions>
    </StyledCard>
  );
};

export default SponsorFrontServiceCard;
