import { utilities } from '@aim/common';
import { agencyGqlHelper } from './agencyGqlHelper';
const agencyHelper = agencyGqlHelper();

export const getNewPrepaidBudget = async ({
  prepaidBudget,
  // agency,
  agencyId,
}) => {
  const respGroups = await agencyHelper.getAgencyGroups(agencyId);
  const prepaidGroup = respGroups.find((g) => g.isPrepaid);
  const respParticipations = await agencyHelper.getGroupParticipations(
    prepaidGroup.id
  );

  // const { deadlines } = agency;
  // let { penalty, prepaidDeadline } = deadlines;
  // prepaidDeadline = new Date(prepaidDeadline);

  console.log(respGroups, 'respGroups');
  console.log(prepaidGroup, 'prepaidGroup');
  console.log(respParticipations, 'respParticipations');

  const tot = respParticipations.reduce((res, curr) => {
    const price = utilities.getParticipationPrice(curr);

    console.log(curr, 'curr');
    console.log(price, 'price');
    console.log(prepaidBudget, 'prepaidBudget');

    return (res += price * 100);
  }, 0);

  console.log(tot, 'tot');

  return prepaidBudget - tot;
};
